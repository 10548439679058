import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import React from 'react'
import { StyleSheet, TouchableOpacity, Text, Dimensions } from 'react-native'

const { width, height } = Dimensions.get('screen');

const Button = ({ title, onPress, disabled, variant }) => {
    const accentColor = UseBrandingAccentColor();
    return (
        <TouchableOpacity onPress={onPress} style={[styles.button,
        variant == "primary" && { backgroundColor: disabled ? "rgba(240, 83, 67, 0.5)" : accentColor }]}>
            <Text style={[
                styles.text,
                variant == "primary" && { color: 'white' }
            ]}>{title}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    button: {
        width: width / 2.6,
        alignItems: "center",
        justifyContent: 'center',
        padding: 10,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#aaa'
    },
    text: {}
})

export default Button;