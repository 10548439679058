import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
import { isMobile } from '@/styles'
import { Caption1, Paragraph2 } from '@/styles/fonts'

const { width } = Dimensions.get('window')


export const Container = styled.View`
	flex-direction: row;
	align-self: flex-start;
	margin: 16px;
	width: 100%;
`
export const InfoContainer = styled.View`
	margin: 0 0 0 8px;

`

export const CommentInfoContainer = styled.View`
	min-height: 80px ;
	padding: 10px;
	min-width: ${isMobile ? (width - 45) * 0.8 : '235'}px;
	max-width: ${isMobile ? (width - 45) * 0.9 : '436'}px;
	background-color: ${({ theme }) => theme.basics[200]};
	border-radius: 5px;
	margin: 0 0 8px;
	position: relative;
`
export const LikesContainer = styled.View`
	flex-direction: row;
	justify-content: flex-end;
`
export const LikeContainer = styled.View`
	flex-direction:row;
	align-items: ${isMobile ? 'center' : 'baseline'};
`
export const LikeButton = styled.TouchableOpacity``

export const ButtonText = styled.Text`
	margin: 0 4px 0 0 ;
	padding: 0 0 ${isMobile ? 0 : 4}px;
	font-family: 'WorkSans_400Regular';
	font-size: 12px;
	color: ${({ theme }) => theme.basics.brand};

`
export const ButtonTextLike = styled.Text`
	margin: 0 4px ;
	padding: 0 0 ${isMobile ? 0 : 2}px;
	font-family: 'WorkSans_400Regular';
	font-size: 12px;
	color: ${({ theme }) => theme.info.standard};
`

export const TimeAgo = styled(Paragraph2)`
	color: ${({ theme }) => theme.basics[400]};
	padding: 0 0 2px;
	margin: 0 9px 0 0;
`

export const AuthorName = styled.Text`
	color: ${({ theme }) => theme.basics.brand};
	font-family: 'WorkSans_400Regular';
	font-size: 15px;
	line-height: 18px;
`

export const AuthorOffice = styled(Caption1)`
	color: ${({ theme }) => theme.basics[400]};
`
export const CommentText = styled(Paragraph2)`
	margin: 8px 0 0;
	color: ${({ theme }) => theme.basics.brand};
`

export const ButtonNavigation = styled.TouchableOpacity``
