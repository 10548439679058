import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { Dimensions, useWindowDimensions } from 'react-native'
import { isMobile } from '@/styles'
import { useSideDrawer } from '@/hooks/sideDrawer'
const screenWidth = Dimensions.get('window').width
import PropTypes from 'prop-types'

const ChartBox = ({ title, chartRender }) => {
    const { open } = useSideDrawer(true)
    const [isOpenDrawer, setDrawer] = useState(false)

    const window = useWindowDimensions();
    useEffect(() => {

    }, [window])

    useEffect(() => {
        setDrawer(open)
    }, [open, setDrawer])

    return (
        <View
            style={{
                elevation: 5,
                backgroundColor: 'white',
                alignItems: 'center',
                justifyContent: 'center',
                alignSelf: 'center',
                marginTop: 15,
                elevation: 10,
                shadowColor: '#000',
                borderRadius: 6,
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                width: '100%',
                height: '100%',
                overflowY: 'hidden',
                overflowX: 'scroll'
            }}
        >
            <Text
                style={{
                    alignSelf: 'flex-start',
                    paddingHorizontal: 20,
                    marginVertical: 8,
                    fontSize: 16,
                    fontFamily: 'WorkSans_400Regular'
                }}
            >
                {title}
            </Text>
            <ScrollView
                style={{ flex: 1, width: '100%' }}
                contentContainerStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                horizontal={true}>

                {chartRender}

            </ScrollView>
        </View>
    )
}

ChartBox.propTypes = {
    title: PropTypes.string,
    chartRender: PropTypes.any
}

export default ChartBox
