import React, { useContext, useEffect, useState } from 'react'
import { Modal, View, SafeAreaView, FlatList, TouchableOpacity, Text, Dimensions, Image } from 'react-native';
import Button from '@/components/Chat/Button';
import ChatContext from '@/contexts/ChatContext';
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth';
import { formatUserName } from '@/utils/format';
import { useIsMobile } from '@/styles';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate';
import { removeParticipant } from '../../api';
import ModalV2 from '@/components/ModalV2'

const { width, height } = Dimensions.get('window');

const ManageParticipantsModal = ({ convo, canRemoveParticipants, canAddParticipants, showInviteModal, visible, setVisible }) => {
    const { workspaceMembers, participants, } = useContext(ChatContext);
    const [_participants, setParticipants] = useState([]);
    const user = useSelector(getMember);
    const isMobile = useIsMobile();
    const accentColor = UseBrandingAccentColor();

    const getParticipants = async () => {
        const __participants = participants[convo.sid];

        const participantsToAdd = workspaceMembers.filter(member => __participants.find(participant => member.id.toString() == participant.identity.toString()))

        const ___participants = participantsToAdd.map(part => {
            const actual = __participants.find(user => user.identity.toString() == part.id);

            return { ...part, identity: actual.identity };
        })

        setParticipants(___participants ?? []);
    }

    useEffect(() => {
        getParticipants();
    }, [convo, participants])

    return (
        <ModalV2
            isVisible={visible}
            setVisibility={setVisible}
            title={'Participants'}
        >
            <View>
                <FlatList
                    ItemSeparatorComponent={() =>
                        <View style={{ borderBottomColor: "#aaa", borderBottomWidth: 1, width: '100%' }} />
                    }
                    contentContainerStyle={{ paddingBottom: height / 6 }}
                    ListHeaderComponent={() => canAddParticipants ? (
                        <TouchableOpacity onPress={() => {
                            setVisible(prev => {
                                showInviteModal(prev => true);
                                return false;
                            });
                        }} style={{ borderWidth: 1, borderColor: "#555", padding: 15, width: isMobile ? width / 1.1 : width / 3, borderRadius: 8, alignItems: 'center', justifyContent: 'center', alignSelf: 'center', marginVertical: 20 }}>
                            <Text style={{ fontWeight: '600' }}>Add Participant</Text>
                        </TouchableOpacity>
                    ) : null}
                    removeClippedSubviews
                    scrollEventThrottle={1600}
                    data={_participants}
                    renderItem={({ item, index }) => (
                        <View style={{ flexDirection: 'row', alignItems: 'center', padding: isMobile ? 15 : 10 }}>
                            {item.avatar ? (
                                <Image source={{ uri: item.avatar }} style={{ width: 50, height: 50, backgroundColor: accentColor, borderRadius: 50 }} />
                            ) : <AvatarTemplate letter={item?.first_name?.charAt(0)} size={50} />}
                            <Text style={{ left: !item.avatar ? -15 : 0, width: '75%', paddingLeft: 20 }}>{formatUserName(item?.first_name, item?.last_name)}</Text>
                            {item.id !== user.id && canRemoveParticipants && (
                                <Button icon="close" onPress={async () => await removeParticipant(convo, item.identity)} />
                            )}
                        </View>
                    )} />
            </View>
        </ModalV2>
    )
}

export default ManageParticipantsModal;