import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";

import ConversationDetails from "./ConversationDetails";
import MessagesBox from "../message/MessagesBox";
import MessageInputField from "../message/MessageInputField";
import styles from "../../styles";
import { useContext, useEffect, useState } from "react";
import ChatContext from "@/contexts/ChatContext";
import { useSelector } from "react-redux";
import { getMember } from "@/redux/selectors/auth";
import { getUserRoleInCurrentConversation } from "@/utils/getUserRoleInCurrentConversation";

const ConversationContainer = (props) => {
  const theme = useTheme();
  const { sid, loadingStatus, roles, updateRoles, participants, messages, typingData, lastReadIndex, pushMessages } = useContext(ChatContext);
  const user = useSelector(getMember);


  return (
    <Box style={styles.convosWrapperBox}>
      {sid && props.conversation && props.client ? (
        <>
          <ConversationDetails
            convoSid={sid}
            convo={props.conversation}
            participants={participants[sid] ?? []}
          />

          <MessagesBox
            key={sid}
            convoSid={sid}
            convo={props.conversation}
            addMessage={pushMessages}
            client={props.client}
            messages={messages[sid]}
            loadingState={loadingStatus}
            participants={participants[sid] ?? []}
            lastReadIndex={lastReadIndex}
          />

          <MessageInputField
            convoSid={sid}
            client={props.client}
            messages={messages[sid]}
            convo={props.conversation}
            typingData={typingData}
          />
        </>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              height: "100%",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              fontFamily: 'WorkSans_400Regular',
              fontSize: theme.fontSizes.fontSize30,
              fontWeight: theme.fontWeights.fontWeightNormal,
              lineHeight: "20px",
              color: theme.textColors.colorTextIcon,
            }}
          >
            Select a conversation on the left to get started.
          </Box>
        </>
      )}
    </Box>
  );
};

export default ConversationContainer;