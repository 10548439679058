/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
  useEffect,
} from 'react'
import _ from 'lodash'
import { ScrollView, View, useWindowDimensions, TouchableOpacity, RefreshControl } from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '@/redux/reducers/groups'
import * as profileSelectors from '@/redux/selectors/profile'
import * as selectors from '@/redux/selectors/groups'
import { useRoute } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import {
  Avatar,
  Icon,
  InviteMemberWeb,
  KebabMenuMobile,
  KebabMenuWeb,
  LeaveModalMobile,
  LeaveModalWeb,
  MemberListWeb,
  CreatePost,
  FeedPostCard,
  AvatarWriteButton,
  GroupTeamsMemberCard,
  BackArrow,
} from '@/components'
import PostComposer from '@/components/Feed/Card/Content/Post/Components/PostComposer'
import * as S from './styles'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import { KebabeMenuWebContext } from '@/contexts/Community/Group/kebabMenuWeb'
import { KebabeMenuMobileContext } from '@/contexts/Community/Group/kebabMenuMobile'
import CreateEditGroup from '@/pages/Community/CreateEditGroup'
import { store } from '@/redux/store'
import { ProgressBar } from 'react-native-paper'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import ManageMemberModal from '@/components/ManageMemberModal/ManageMemberModal'
import * as brandSelectors from '@/redux/selectors/branding'
import { getMember } from '@/redux/selectors/auth'
import moment from 'moment'
import FeedCard from '@/components/Feed/Card'

const GroupPage = ({
  navigation
}) => {
  const dispatch = useDispatch()
  const group = useSelector(selectors.group)
  const posts = useSelector(selectors.posts)
  const userProfile = useSelector(profileSelectors.getProfile)
  const loading = useSelector(selectors.loading)
  const current_page = useSelector(selectors.current_page)
  const can_load_more = useSelector(selectors.can_load_more)
  const next_page = useSelector(selectors.next_page)

  const [refreshing, setRefreshing] = useState(false)
  const [isCreatePostVisible, setCreatePostVisible] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const [isLeaveWebVisible, setLeaveWebVisible] = useState(false)
  const [isDeleteWebVisible, setDeleteWebVisible] = useState(false)
  const [isInviteMemberWebVisible, setInviteMemberWebVisible] = useState(false)
  const [isLeaveMobileVisible, setLeaveMobileVisible] = useState(false)
  const [isDeleteMobileVisible, setDeleteMobileVisible] = useState(false)
  const [isMemberGroupVisible, setMemberGroupVisible] = useState(false)
  const [isCreateEditVisible, setCreateEditVisible] = useState(false)
  const [isManageMemberViewVisible, setManageMemberViewVisible] = useState(false)
  const [blockJoin, setBlockJoin] = useState(false)
  const memberLenght = (group?.members ?? []).length
  const members = group?.members ?? []
  const memberWord = memberLenght > 1 ? 'members' : 'member'
  const { height } = useWindowDimensions()
  const route = useRoute()
  const id = route?.params?.id
  const user = useSelector(getMember)
  const isMember = members?.some(member => member.id === user.id)
  const isOwner = group?.owner_id == user.id


  const branding = useSelector(brandSelectors.getSettings);
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  useEffect(() => {
    if (!id) {
      navigation.navigate('Community')
    }
  }, [id])

  useEffect(() => {
    if (id) {
      dispatch(actions.show({ id }, (error) => {
        if (error) {
          navigation.navigate('Community')
        }
      }))
      dispatch(actions.posts({ id, current_page: 1 }))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (!loading && !isMember && members.length === 0) {
      setBlockJoin(false)
    } else {
      setBlockJoin(true)
    }
  }, [members])

  const handleListMember = useCallback(() => {
    if (isMobile) {
      navigation.navigate('GroupMembers', {
        // members: members.filter(member => member.id != user.id),
        isTeam: false,
        // handleRemoveMember: () => handleRemoveMember,
        isOwner: isOwner,
        onBack: () => setManageMemberViewVisible(false),
        team_id: id,
        id: id,
      })
      return
    }
    setManageMemberViewVisible(true)
  }, [navigation, isMobile, setMemberGroupVisible])

  const handleCreatePost = useCallback(() => {
    setCreatePostVisible(true)
  }, [setCreatePostVisible, isMobile])

  const handleClosePostActions = useCallback(() => {
    dispatch(actions.posts({ id, current_page: 1 }))
  }, [setCreatePostVisible, isMobile, dispatch])

  const isCloseToBottom = useCallback(({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = height * 0.5
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
  }, [height])

  const updateInfinityScroll = useCallback(() => {
    if (can_load_more) {
      dispatch(actions.posts({ id, page: next_page }, () => { }))
    }
  }, [next_page, current_page, dispatch])

  const onBackEditGroup = useCallback(() => {
    dispatch(actions.show({ id }, () => { }))
    setCreateEditVisible(false)
  }, [dispatch, setCreateEditVisible])

  const handleJoin = useCallback(() => {
    if (!blockJoin) {
      dispatch(actions.join({ id }, () => {
        dispatch(actions.show({ id }))
      }))
      setBlockJoin(true)
    }
  }, [dispatch])

  const handleLeave = useCallback(() => {
    dispatch(actions.leave({ id }, () => {
      dispatch(actions.show({ id }))
    }))
    navigation.reset({
      index: 0,
      routes: [{ name: 'Community' }]
    })
    // navigation.navigate('Community')
  }, [navigation, dispatch])

  const handleRemoveMember = useCallback((member_id) => {
    dispatch(actions.removeMember({ group_id: id, member_id: member_id }, () => {
      dispatch(actions.show({ id }))
    }))
  }, [dispatch])

  const handleDelete = useCallback(() => {
    dispatch(actions.remove({ id }, () => {
      // navigation.navigate('Community')
      navigation.reset({
        index: 0,
        routes: [{ name: 'Community' }]
      })
    }))
  }, [navigation, dispatch])
  const handleEditInvite = useCallback(() => {
    dispatch(actions.show({ id }, () => { }))
  }, [dispatch, setCreateEditVisible])
  useEffect(() => {
    if (isMobile && isManageMemberViewVisible) {

      handleListMember()
    }
  }, [isManageMemberViewVisible])


  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // dispatch(actions.posts({id, page: 1 }, () => {}))
    })
    return unsubscribe
  }, [navigation])

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    dispatch(
      actions.posts({ id, page: 1 }, () => {
        setRefreshing(false)
      })
    )
  }, [])

  return (
    <>
      <ProgressBar indeterminate={true} visible={loading} />
      <S.Container>
        <ScrollView
          contentContainerStyle={S.styles.contentScrollView}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              updateInfinityScroll()
            }
          }}
          scrollEventThrottle={10}
          bounces={false}
          scrollEnabled={true}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              title=""
            />
          }
        >
          <S.Header>
            <S.ImageCoverContainer>
              {isMobile ? <S.MenuHeader>
                <BackArrow color={theme.basics.background_white} />
                <S.SectionMenuHeader>
                  <S.IconButton>
                    <Icon
                      icon="searchIcon"
                      color={theme.basics.background_white}
                      height={16}
                      width={16}
                    />
                  </S.IconButton>
                  <S.IconButton onPress={() => setModalVisible(true)}>
                    <Icon
                      icon="ellipsis"
                      color={theme.basics.background_white}
                      height={16}
                      width={16}
                    />
                  </S.IconButton>
                </S.SectionMenuHeader>
              </S.MenuHeader>
                :
                <S.MenuHeader>
                  <BackArrow color={theme.basics.background_white} />
                </S.MenuHeader>
              }
              <>
                <LinearGradient
                  colors={['rgba(0,0,0,0.6)', 'transparent']}
                  style={S.styles.gradiente}
                />
                <S.CoverImage source={group?.image ? { uri: group?.image } : PlaceholderImageCover} resizeMode="cover" />
              </>
            </S.ImageCoverContainer>
            <S.SubHeader>
              <S.Title>{group?.name ?? ''}</S.Title>
              <S.Description>{group?.description}
                {"\n"}
                <S.TitleListMember>{moment(group?.created_at).format("MMM D, YYYY")}</S.TitleListMember>
              </S.Description>
            </S.SubHeader>
            <S.BottomHeader>
              <S.MembersContainer>
                <S.TitleListMember>{memberLenght} {memberWord}</S.TitleListMember>
                <S.MembersList>
                  {(members ?? [])?.length === 5 && _.slice(members, 0, 5).map((employee, key) => (
                    <TouchableOpacity onPress={handleListMember} key={key}>
                      <Avatar
                        style={{ marginRight: 12 }}
                        source={employee?.avatar ? { uri: employee?.avatar } : null}
                        size={isMobile ? 45 : 75}
                      />
                    </TouchableOpacity>
                  ))}
                  {(members)?.length < 5 && _.slice(members, 0, 4).map((employee, key) => (
                    <TouchableOpacity onPress={handleListMember} key={key}>
                      <Avatar
                        style={{ marginRight: 12 }}
                        source={employee?.avatar ? { uri: employee?.avatar } : null}
                        size={isMobile ? 45 : 75}
                      />
                    </TouchableOpacity>
                  ))}
                  {(members ?? [])?.length > 5 && _.slice(members, 0, 4).map((employee, key) => (
                    <TouchableOpacity onPress={handleListMember} key={key}>
                      <Avatar
                        style={{ marginRight: 12 }}
                        key={key}
                        source={employee?.avatar ? { uri: employee?.avatar } : null}
                        size={isMobile ? 45 : 75}
                      />
                    </TouchableOpacity>
                  ))}
                  {(members ?? []).length > 5 && (
                    <S.AvatarMoreContainer>
                      <Avatar
                        style={{ marginRight: 12 }}
                        key={_.last(members)?.avatar}
                        source={_.last(members)?.avatar}
                        size={isMobile ? 45 : 75}
                      />
                      <S.AvatarMoreButton onPress={handleListMember}>
                        <S.TextMoreButton>
                          +{(members ?? []).length - 5}
                        </S.TextMoreButton>
                      </S.AvatarMoreButton>
                    </S.AvatarMoreContainer>
                  )}
                </S.MembersList>
              </S.MembersContainer>
              <S.ContainerButtons>
                {!isMember && (
                  <S.ParticipateButton color={color_scheme_primary_button_background} onPress={handleJoin} blockJoin={blockJoin}>
                    <S.ParticipateText color={color_scheme_primary_button_background} blockJoin={blockJoin}>Participate</S.ParticipateText>
                  </S.ParticipateButton>
                )}
                {(!isMobile) ?
                  <KebabeMenuWebContext.Provider value={{
                    setDeleteWebVisible,
                    setLeaveWebVisible,
                    setInviteMemberWebVisible,
                    setCreateEditVisible,
                    setManageMemberViewVisible,
                    isOwner
                  }}>
                    <KebabMenuWeb />
                  </KebabeMenuWebContext.Provider>
                  : null
                }
              </S.ContainerButtons>
            </S.BottomHeader>
          </S.Header>
          {/* <GroupTeamsMemberCard 
            isTeam={false}
            isOwner={isOwner}
            members={members.filter(member => member.id != user.id)}
            onBack={() => setManageMemberViewVisible(false)}
            isManageMemberViewVisible={isManageMemberViewVisible}
            handleRemoveMember={handleRemoveMember} /> */}
          {isMember && !isManageMemberViewVisible ? (
            <S.Main>
              {isMemberGroupVisible ? (
                <MemberListWeb closeList={setMemberGroupVisible} />
              ) : (
                <View style={S.styles.flatListContent}>
                  <AvatarWriteButton
                    avatarSize={50}
                    text="Write a post"
                    avatarSource={user?.avatar}
                    onPress={handleCreatePost}
                  />
                  {(posts.data ?? []).map(post => (
                    <FeedCard
                      key={'groups' + post.id}
                      post={post}
                    />
                  ))}
                </View>
              )}
            </S.Main>
          ) : (
            null
          )}
        </ScrollView>
        <KebabeMenuMobileContext.Provider value={{
          setDeleteMobileVisible,
          setLeaveMobileVisible,
          setInviteMemberWebVisible,
          setManageMemberViewVisible,
          title: group?.name ?? '',
          description: group?.description ?? '',
          source: group?.image,
          isOwner: isOwner
        }}>
          <KebabMenuMobile
            screenType={'Group'}
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
            handleLeave={handleLeave}
            setCreateEditVisible={setCreateEditVisible}
          />
        </KebabeMenuMobileContext.Provider>
        <LeaveModalWeb
          isLeaveWebVisible={isDeleteWebVisible}
          setLeaveWebVisible={setDeleteWebVisible}
          onClose={handleDelete}
          type="delete"
          screenType="group"
        />
        <LeaveModalWeb
          isLeaveWebVisible={isLeaveWebVisible}
          setLeaveWebVisible={setLeaveWebVisible}
          onClose={handleLeave}
          type="leave"
          screenType="group"
        />
        <LeaveModalMobile
          setLeaveMobileVisible={setDeleteMobileVisible}
          isLeaveMobileVisible={isDeleteMobileVisible}
          onClose={handleDelete}
          type="delete"
          screenType={'group'}
        />
        <LeaveModalMobile
          setLeaveMobileVisible={setLeaveMobileVisible}
          isLeaveMobileVisible={isLeaveMobileVisible}
          onClose={handleLeave}
          type="leave"
          screenType={'group'}
        />

        <InviteMemberWeb
          isVisible={isInviteMemberWebVisible}
          setVisible={setInviteMemberWebVisible}
          data={group}
          joinedMembers={members}
          type='group'
          onClose={handleEditInvite}
        />
        {!isMobile && //if platform is web modal will show else will navigate to another screen
          <ManageMemberModal
            isTeam={false}
            isOwner={isOwner}
            members={members.filter(member => member.id != user.id)}
            onBack={() => setManageMemberViewVisible(false)}
            isManageMemberViewVisible={isManageMemberViewVisible}
            setManageMemberViewVisible={setManageMemberViewVisible}
            handleRemoveMember={handleRemoveMember}
          // members={members.filter(member => member.id != user.id)}
          />}
        <PostComposer
          isVisible={isCreatePostVisible}
          setVisible={setCreatePostVisible}
          onClose={handleClosePostActions}
          attachmentId={group?.id}
          attachmentType='App\Group'
        />
        <CreateEditGroup
          type='group'
          data={group}
          isVisible={isCreateEditVisible}
          setVisible={setCreateEditVisible}
          onBack={onBackEditGroup}
        />
      </S.Container>
    </>
  )
}

GroupPage.propTypes = {
  title: PropTypes.string,
  source: PropTypes.number,
  description: PropTypes.string,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  })
}

export default GroupPage
