import ChatContext from '@/contexts/ChatContext';
import React, { useContext, useState, useEffect, useRef } from 'react'
import { View, FlatList, Dimensions, Alert, TouchableOpacity, Text, KeyboardAvoidingView, Platform } from 'react-native'
import Message from './Message';
import Input from '@/components/Chat/RTM/Input'
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth';
import { useIsMobile } from '@/styles';
import Header from '@/components/Chat/RTM/Header'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import { addParticipant, getMessageStatus, removeParticipant } from '../../api'
import {
    CONVERSATION_MESSAGES,
    ERROR_MODAL_MESSAGES,
    UNEXPECTED_ERROR_MESSAGE,
    SMS_PREFIX,
    WHATSAPP_PREFIX,
    NOTIFICATION_LEVEL,
} from "../../helpers/constants";
import ActionErrorModal from '../modals/ActionErrorModal';
import InviteUsersModal from '../modals/InviteUsersModal';
import ManageParticipantsModal from '../modals/ParticipantsModal';
import * as ImagePicker from 'expo-image-picker'
import { getMessageTime, updateCurrentConvo } from '../../helpers';
import Horizon from './Message/Horizon';
import RBSheet from 'react-native-raw-bottom-sheet';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { getUserRoleInCurrentConversation } from '@/utils/getUserRoleInCurrentConversation';
import { useNavigation } from '@react-navigation/native';
import ConversationTitleModal from '../modals/ConversationTitleModal';
import PageHeader from '@/components/Layout/Page/Header'

const { width, height } = Dimensions.get('window')

const MenuItem = ({ icon, title, onPress, danger }) => {
    return (
        <TouchableOpacity onPress={onPress} style={{ flexDirection: 'row', padding: 15, alignItems: 'center' }}>
            <MaterialCommunityIcons name={icon} size={22} color={danger ? 'red' : '#555'} />
            <Text style={{ marginLeft: 20, color: danger ? 'red' : '#555', fontSize: 18 }}>{title}</Text>
        </TouchableOpacity>
    )
}

const MessageList = () => {
    const { sid, messages, workspaceMembers, addMessages, updateConversation, updateCurrentConversation, roles, updateRoles, client, addNotifications, lastReadIndex, participants } = useContext(ChatContext);
    const listRef = useRef(null);
    const [messageText, setMessageText] = useState("");
    const [convo, setConvo] = useState(null);
    const user = useSelector(getMember);
    const isMobile = useIsMobile();
    const [loading, setLoading] = useState(false);
    const [showingParticipantsModal, setShowingParticipantsModal] = useState(false);
    const [showingInviteModal, setShowingInviteModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const accentColor = UseBrandingAccentColor();
    const [showError, setErrorToShow] = useState();
    const [errorData, setErrorData] = useState();
    const [_messages, _setMessages] = useState([]);
    const [horizonAmount, setHorizonAmount] = useState(0);
    const [showHorizonIndex, setShowHorizonIndex] = useState(0);
    const sheetRef = useRef(null);
    const navigation = useNavigation();
    const [muted, setMuted] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [invitableUsers, setInvitableUsers] = useState([]);
    let inputSheetRef;

    const setRef = (ref) => (inputSheetRef = ref);

    const openSheet = () => {
        inputSheetRef?.open();
    }

    const getUserRoles = async () => {
        const roles = await getUserRoleInCurrentConversation(user.id, convo);
        if (roles?.length) {
            updateRoles(roles, sid);
        }
    }

    useEffect(() => {
        if (!roles[sid]) {
            getUserRoles();
        }

    }, [sid, convo])


    useEffect(() => {
        if (sid && messages[sid].length) {
            _setMessages(prev => messages[sid]);
        }
    }, [messages[sid]])

    useEffect(() => {
        client?.getConversationBySid(sid).then(convo => {
            setConvo(convo);
            listRef.current?.scrollToEnd({ animated: false })

            const _participants = participants[convo?.sid];
            const formatted = _participants.map(part => part.identity?.toString());
            const _users = workspaceMembers.filter(member => !formatted.includes(member?.id?.toString()))
            const formattedUsers = _users?.map(user => ({ ...user, id: String(user.id) }));
            setInvitableUsers(prev => formattedUsers);
        })
    }, [sid])

    useEffect(() => {
        if (convo) {
            const { friendlyName, notificationLevel } = convo;
            let _muted = notificationLevel == NOTIFICATION_LEVEL.MUTED;
            setMuted(_muted);
        }
    }, [convo])

    const toggleMuteConversation = async () => {
        await convo.setUserNotificationLevel(
            muted
                ? (NOTIFICATION_LEVEL.DEFAULT)
                : (NOTIFICATION_LEVEL.MUTED)
        )

        setMuted(!muted);
    }

    useEffect(() => {
        if (lastReadIndex === -1 || horizonAmount) {
            return;
        }
        let showIndex = 0;

        setHorizonAmount(
            messages[sid]?.filter(({ index }) => {
                if (index > lastReadIndex && !showIndex) {
                    showIndex = index;
                }
                return index > lastReadIndex;
            }).length
        );

        setShowHorizonIndex(showIndex);
    }, [messages[sid], lastReadIndex]);

    const sendMessage = async () => {
        const messagesToSend = [];
        const messagesData = [];
        const currentDate = new Date();

        if (messageText.trim() !== "") {
            const newMessage = Object.assign({}, messages[messages.length], {
                ...(messages[messages.length]),
                author: client.user.identity,
                body: messageText,
                attributes: {},
                dateCreated: currentDate,
                index: -1,
                participantSid: "",
                sid: convo.sid,
                aggregatedDeliveryReceipt: null,
            });
            //add message to state
            messagesToSend.push(newMessage);
            messagesData.push(messageText);
            //if promise is filled then is sent. If not failed. Update state of message
            //change state for the message to sent (or failed)
        }

        addMessages(convo.sid, messagesToSend);
        setMessageText("");

        try {
            const indexes = [];
            for (const msg of messagesData) {
                const index = await convo.sendMessage(msg);
                indexes.push(index);
            }
            await convo.updateLastReadMessageIndex(Math.max(...indexes));
        } catch (e) {
            return Promise.reject(e);
        }
    };

    const sendMediaMessage = async (res) => {
        const messagesData = [];
        const fileType = res.type == "image" ? "image/jpeg" : "video/mp4";
        const response = await fetch(res.uri);
        const result = await response.blob();

        messagesData.push(1);

        setMessageText("");

        try {
            const indexes = [];
            for (const _ in messagesData) {
                const index = await convo.sendMessage({
                    contentType: fileType,
                    media: result
                });
                indexes.push(index);
            }
            await convo.updateLastReadMessageIndex(Math.max(...indexes));
        } catch (e) {
            return Promise.reject(e);
        }

    }

    const onWebAttachment = () => {
        alert("Web Attachment using Document Picker")
    }

    const selectWorkSpaceMember = (id) => {
        if (selectedUsers.includes(id?.toString())) {
            setSelectedUsers(prev => selectedUsers.filter(usr => usr?.toString() !== id?.toString()))
        } else {
            setSelectedUsers(prev => [...prev, id?.toString()])
        }
    }

    const addMembersToConversation = async () => {
        try {
            for (let i = 0; i < selectedUsers.length; i++) {
                await addParticipant(
                    selectedUsers[i]?.toString(),
                    "",
                    true,
                    convo,
                    addNotifications
                );
            }
            setShowingInviteModal(false);
        } catch (e) {
            setShowingInviteModal(false);
            setErrorToShow(ERROR_MODAL_MESSAGES.ADD_PARTICIPANT);
            setErrorData(e);
        }
    }


    const onPressCamera = async () => {
        let permissionStatus;
        let { status } = await ImagePicker.getCameraPermissionsAsync();
        permissionStatus = status;
        if (permissionStatus !== 'granted') {
            let { status } = await ImagePicker.requestCameraPermissionsAsync();
            permissionStatus = status;
        }
        if (permissionStatus !== 'granted') {
            return;
        }

        const res = await ImagePicker.launchCameraAsync({ mediaTypes: ImagePicker.MediaTypeOptions.All, videoMaxDuration: 60 });

        if (!res.cancelled) {

            sendMediaMessage(res);
        }

        inputSheetRef?.close();

    }


    const onPressVideo = async () => {
        let permissionStatus;
        let { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
        permissionStatus = status;
        if (status != 'granted') {
            let { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            permissionStatus = status;
        }

        if (permissionStatus !== 'granted') {
            return;
        }

        const res = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Videos, allowsEditing: true, videoMaxDuration: 60 });

        if (!res.cancelled) {
            sendMediaMessage(res);
        }

        inputSheetRef?.close();


    }

    const onPressImage = async () => {
        let permissionStatus;
        let { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
        permissionStatus = status;
        if (permissionStatus != 'granted') {
            let { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
            permissionStatus = status;
        }

        if (permissionStatus !== 'granted') {
            return;
        }

        const res = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });

        if (!res.cancelled) {
            sendMediaMessage(res);
        }

        inputSheetRef?.close();


    }

    let menu = [];
    if (roles[sid]?.includes("editConversationName")) {
        menu.push({
            title: `Edit Conversation`,
            icon: 'searchIcon',
            permissions: [

            ],
            onPress: () => {
                setIsModalOpen(true)
            }
        })
    }
    if (roles[sid]?.includes("editNotificationLevel")) {
        menu.push({
            title: `${muted ? "Unmute" : "Mute"} Conversation`,
            icon: 'searchIcon',
            permissions: [

            ],
            onPress: () => {
                toggleMuteConversation()
            }
        })
    }
    menu.push({
        title: 'Participants',
        icon: 'searchIcon',
        permissions: [

        ],
        onPress: () => {
            setShowingParticipantsModal(true)
        }
    })
    menu.push({
        title: 'Leave Conversation',
        icon: 'searchIcon',
        permissions: [

        ],
        onPress: () => {
            try {
                convo.leave();
                updateCurrentConversation("");
            } catch (error) {
                Alert.alert(error);
            }
        }
    })

    return (
        <View style={{ backgroundColor: "white", flex: 1, position: 'relative' }}>
            {isMobile &&
                <PageHeader
                    title={convo?.friendlyName}
                    icon={false}
                    backEnabled={true}
                    actions={
                        [
                            {
                                title: 'Manage',
                                type: 'menu',
                                permissions: [

                                ],
                                menu: menu
                            }
                        ]
                    }
                />
            }

            <FlatList decelerationRate={0}
                snapToAlignment="end"
                refreshing={loading}
                onContentSizeChange={() => listRef.current.scrollToEnd({ animated: false })}
                scrollEventThrottle={1600}
                ListEmptyComponent={() => (
                    <View style={{ flex: 1, minHeight: height / 1.3, justifyContent: 'center', alignItems: 'center' }}>
                        <Text>No Messages Found</Text>
                    </View>
                )}
                extraData={messages[sid]}
                contentContainerStyle={{ paddingBottom: isMobile ? height / 8 : width / 10, paddingHorizontal: 10 }}
                style={{ paddingBottom: isMobile ? height / 5 : width / 10 }}
                ref={listRef} data={_messages} renderItem={({ item, index }) => (
                    <>
                        {lastReadIndex !== -1 &&
                            horizonAmount &&
                            showHorizonIndex === item.index ? (
                            <Horizon amount={horizonAmount} />
                        ) : null}
                        <Message messageTime={getMessageTime(item)}
                            message={item}
                            reactions={item.attributes["reactions"]}
                            onDeleteMessage={async () => {
                                try {
                                    await item.remove();
                                    listRef.current.scrollToEnd({ animated: true })
                                } catch (e) {
                                    Alert.alert(e);
                                }
                            }}
                            getStatus={getMessageStatus(convo, item, participants[sid] ?? [], user.id.toString())}
                            updateAttributes={(attribute) =>
                                item.updateAttributes({
                                    ...item.attributes,
                                    ...attribute,
                                })
                            }
                        />
                    </>
                )} />

            <Input openSheet={openSheet} setRef={setRef} width={width / 1.7} multiline letter={user?.first_name?.charAt(0)} onPressImage={onPressImage} onPressCamera={onPressCamera} onPressVideo={onPressVideo} onWebAttachment={onWebAttachment} value={messageText} avatar={user?.avatar} onChangeText={(text) => {
                convo.typing();
                setMessageText(text);
            }} onSendMessage={sendMessage} />

            {convo && (
                <ManageParticipantsModal canAddParticipants={roles[sid]?.includes("addParticipant")} canRemoveParticipants={roles[sid]?.includes("removeParticipant")} showInviteModal={setShowingInviteModal} convo={convo} visible={showingParticipantsModal} setVisible={setShowingParticipantsModal} />
            )}
            <InviteUsersModal invitableUsers={invitableUsers} onAddMembers={addMembersToConversation} selectedUsers={selectedUsers} selectWorkSpaceMember={selectWorkSpaceMember} visible={showingInviteModal} setVisible={setShowingInviteModal} />
            <ActionErrorModal
                errorText={showError || ERROR_MODAL_MESSAGES.CHANGE_CONVERSATION_NAME}
                isOpened={!!showError}
                onClose={() => {
                    setErrorToShow(false);
                    setErrorData(undefined);
                }}
                error={errorData}
            />
            <ConversationTitleModal
                title={convo?.friendlyName ?? ""}
                isModalOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onSave={async (val) => {
                    try {
                        convo
                            .updateFriendlyName(val)
                            .then((_convo) => {
                                updateConversation(_convo.sid, _convo);
                            })
                    } catch (error) {
                        Alert.alert(error);
                    }
                    setIsModalOpen(false);
                }}
            />


            <RBSheet ref={sheetRef}>
                <View>
                    {roles[sid]?.includes("editConversationName") && (
                        <MenuItem onPress={() => {
                            sheetRef.current?.close();
                            setTimeout(() => {
                                setIsModalOpen(true)
                            }, 250)
                        }} icon="lead-pencil" title={"Edit Conversation Name"} />

                    )}
                    {roles[sid]?.includes("editNotificationLevel") && (
                        <MenuItem onPress={toggleMuteConversation} icon={muted ? "bell-outline" : "bell-off-outline"} title={`${muted ? "Unmute" : "Mute"} Conversation`} />
                    )}
                    <MenuItem onPress={() => {
                        sheetRef.current?.close();
                        setTimeout(() => {
                            setShowingParticipantsModal(true)
                        }, 250)
                    }} icon="account-outline" title={"Manage Participants"} />
                    <View style={{ width: '100%', borderBottomColor: '#555', borderBottomWidth: 1 }} />
                    <MenuItem onPress={async () => {
                        try {
                            await convo.leave();
                            updateCurrentConversation("");
                        } catch (error) {
                            Alert.alert(error);
                        }
                    }} icon="arrow-left" title={"Leave Conversation"} danger />
                </View>
            </RBSheet>
        </View>
    )




}

export default MessageList;