import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import React, { useEffect, useState } from 'react'
import { BadgeContainer, BadgeText, Container } from './styles'

const UnreadBadge = ({ children, forMeeting, meetingId }) => {
  const accentColor = UseBrandingAccentColor();
  const [unreadMessages, setUnreadMessages] = useState(0);

  const getUnreadMessagesForMeetingId = async (meetingId) => {

  }

  const getUnreadCount = async () => {

  }

  return (
    <Container>
      {unreadMessages > 0 && <BadgeContainer forMeeting={forMeeting} accentColor={accentColor}>
        <BadgeText>{unreadMessages}</BadgeText>
      </BadgeContainer>}
      {children}
    </Container>
  )
}

export default UnreadBadge;