import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { AnimatedModal } from '@/components'
import { isMobile } from '@/styles'
import {
  Warning,
  LeaveButton,
  LeaveButtonText,
} from './styles'

const LeaveModalMobile = ({
  setLeaveMobileVisible,
  isLeaveMobileVisible,
  onClose,
  type = 'leave',
  screenType
}) => {
  const modalRef = useRef(null)

  const leave = useCallback(() => {
    onClose()
    setLeaveMobileVisible(false)
    modalRef?.current?.close()
  }, [])

  if (isMobile) {
    return (
      <AnimatedModal
        ref={modalRef}
        setModalVisible={setLeaveMobileVisible}
        isModalVisible={isLeaveMobileVisible}
        modalBackgroundColor="transparent"
        justifyContent="flex-start"
        height={208}
      >
        <Warning>Are you sure you want to {(type === 'leave') ? 'leave' : 'delete'} the {screenType}?</Warning>
        <LeaveButton onPress={leave} width={288} style={{ marginBottom: 12 }}>
          <LeaveButtonText>{(type === 'leave') ? 'Leave' : 'Delete'}</LeaveButtonText>
        </LeaveButton>
        <LeaveButton cancel width={288} onPress={() => modalRef?.current?.close()}>
          <LeaveButtonText cancel	>
            Cancel
          </LeaveButtonText>
        </LeaveButton>
      </AnimatedModal>
    )
  }
  return null
}

LeaveModalMobile.propTypes = {
  setLeaveMobileVisible: PropTypes.func.isRequired,
  isLeaveMobileVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
}

export default LeaveModalMobile
