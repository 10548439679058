import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        marginTop: 14
    },
    MoreButtonContainer: {
        alignSelf: 'center'
    },
    MoreButtonText: {
        width: '100%'
    },
    FlatListContentContainer: {
        paddingHorizontal: 16
    }
});
