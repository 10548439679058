export const data = [
  {
    id: 1,
    title: "Color Scheme",
    description: "Customize the color Scheme within your workspace",
    value: false,
    showAnswer: false,
  },
  {
    id: 2,
    title: "Logo",
    description: "Upload a custom logo for your workspace",
    value: false,
    showAnswer: false,
  },
  {
    id: 3,
    title: "Taxonomy",
    description: "Customize the taxonomy throughout your workspace",
    value: false,
    showAnswer: false,
  },
];

export const colors = [
  {
    id: 1,
    title: "Color Scheme",
    description: "Main Navigation Background",
    value: false,
    showAnswer: false,
    key: "color_scheme_main_navigation_background"
  },
  {
    id: 2,
    title: "Logo",
    description: "Main Navigation Text",
    value: false,
    showAnswer: false,
    key: "color_scheme_main_navigation_text"
  },
  {
    id: 3,
    title: "Taxonomy",
    description: "Primary Button Background",
    value: false,
    showAnswer: false,
    key: "color_scheme_primary_button_background"
  },
  {
    id: 4,
    title: "Color Scheme",
    description: "Primary Button Text",
    value: false,
    showAnswer: false,
    key: "color_scheme_primary_button_text"
  },
  {
    id: 5,
    title: "Logo",
    description: "Accents",
    value: false,
    showAnswer: false,
    key: "color_scheme_accents"
  },
  {
    id: 6,
    title: "Taxonomy",
    description: "Page Background",
    value: false,
    showAnswer: false,
    key: "color_scheme_page_background"
  },
  {
    id: 6,
    title: "Taxonomy",
    description: "Page Topbar",
    value: false,
    showAnswer: false,
    key: "color_scheme_page_topbar"
  }
];
