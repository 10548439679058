import React, { useCallback, useState } from 'react'
import { FlatList } from 'react-native'
import PropTypes from 'prop-types'
import { TextField, Icon, MemberList } from '@/components'
import theme from '@/styles/themes/black'
import {
  Container,
  Title,
  Header,
  WrapperField,
  IconView,
  styles,
  CoseButton,
  WrapperTitle,
} from './styles'

const MemberListWeb = ({ closeList }) => {
  const [valueField, setValueField] = useState('')

  const renderItem = useCallback(({ item }) => (
    <MemberList {...item} />
  ))

  return (
    <Container>
      <Header>
        <WrapperTitle>
          <Title>Members</Title>
          <CoseButton onPress={() => closeList(false)}>
            <Icon
              icon="close"
              height={14}
              width={14}
              color={theme.basics.brand}
            />
          </CoseButton>
        </WrapperTitle>
        <WrapperField>
          <TextField
            label="Search members"
            value={valueField}
            autoCapitalize="none"
            onChangeText={setValueField}
          />
          <IconView>
            <Icon
              icon="searchIcon"
              width={17}
              height={17}
              color={theme.basics[600]}
            />
          </IconView>
        </WrapperField>
      </Header>
      <FlatList
        renderItem={renderItem}
        data={data}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.flatListContent}
      />
    </Container>
  )
}

MemberListWeb.propTypes = {
  closeList: PropTypes.func.isRequired,
}

export default MemberListWeb
