import React, { useState, useEffect } from 'react';
import { View, Text, Platform, Dimensions, ScrollView } from 'react-native';
import Switch from '@/components/Switch';
import ApiHandler from '@/api/ApiHandler';
import ButtonPrimary from '@/components/ButtonPrimary';
import Toast from 'react-native-toast-message';

const Notifications = () => {
    const [notificationsData, setNotificationsData] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        getSettingsNotifications();
    }, []);

    const getSettingsNotifications = async () => {
        try {
            const response = await new ApiHandler().getSettingsNotifications();
            if (response?.data) {
                setNotificationsData(response?.data?.data);
            }
        } catch (error) {
            console.log('GET SETTINGS NOTIFICATIONS ERROR', error);
        }
    }

    const toggleSwitch = (switchValue, eventId, option) => {
        const tempNotificationsData = [...notificationsData];
        tempNotificationsData.forEach(element => {
            element.group_events.forEach(e => {
                if (e.event_id === eventId) {
                    if (option == 'email') {
                        e.options.email = switchValue;
                    } else if (option == 'push') {
                        e.options.push = switchValue;
                    } else {
                        e.options.app = switchValue;
                    }
                }
            })
        });
        setNotificationsData(tempNotificationsData);
    };

    const updateSettingsNotifications = async () => {
        setBtnLoading(true);
        try {
            const data = {};
            notificationsData.forEach(element => {
                element.group_events.forEach(e => {
                    const { event_id } = e;
                    const { email, push, app } = e.options;
                    data[event_id] = {
                        email: email ? 1 : 0,
                        push: push ? 1 : 0,
                        app: app ? 1 : 0
                    };
                })
            });
            const response = await new ApiHandler().updateSettingsNotifications(data);
            setBtnLoading(false);
            if (response?.data) {
                Toast.show({
                    position: 'top',
                    text1: response?.data?.message
                });
            }
        } catch (error) {
            console.log('UPDATE SETTINGS NOTIFICATIONS ERROR', error);
        }
    };

    return (
        Platform.OS == 'web'
            ?
            <>
                {
                    notificationsData.map((item, index) => {
                        return (
                            <View
                                key={index}
                                style={{
                                    backgroundColor: '#ffffff',
                                    marginRight: 47,
                                    marginBottom: 38,
                                    borderRadius: 5,
                                    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.05)",
                                }}
                            >
                                <View style={{
                                    marginTop: 22,
                                    marginLeft: 18,
                                    marginRight: 30,
                                    backgroundColor: '#ffffff',
                                }}>
                                    <View style={{
                                        flexDirection: 'row'
                                    }}>
                                        <View style={{
                                            flex: 2
                                        }}>
                                            <Text style={{
                                                color: '#000000',
                                                fontSize: 18,
                                                fontFamily: 'WorkSans_500Medium',
                                                paddingBottom: 29
                                            }}>
                                                {item.group_name}
                                            </Text>
                                        </View>
                                        <View style={{
                                            flex: 1,
                                        }}>
                                            <View style={{
                                                flexDirection: 'row',
                                                justifyContent: 'space-between'
                                            }}>
                                                <Text style={{
                                                    color: '#000000',
                                                    fontSize: 15,
                                                    fontFamily: 'WorkSans_400Regular',
                                                    paddingBottom: 29
                                                }}>
                                                    Email
                                                </Text>
                                                <Text style={{
                                                    color: '#000000',
                                                    fontSize: 15,
                                                    fontFamily: 'WorkSans_400Regular',
                                                    paddingBottom: 29
                                                }}>
                                                    Push Notification
                                                </Text>
                                                <Text style={{
                                                    color: '#000000',
                                                    fontSize: 15,
                                                    fontFamily: 'WorkSans_400Regular',
                                                    paddingBottom: 29
                                                }}>
                                                    In-App
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                    {
                                        item.group_events.map((event, eventIndex) => {
                                            return (
                                                <View
                                                    key={eventIndex}
                                                    style={{
                                                        flexDirection: 'row'
                                                    }}
                                                >
                                                    <View style={{
                                                        flex: 2
                                                    }}>
                                                        <Text style={{
                                                            color: '#000000',
                                                            fontSize: 15,
                                                            fontFamily: 'WorkSans_400Regular',
                                                            paddingBottom: 29
                                                        }}>
                                                            {event.event_name}
                                                        </Text>
                                                    </View>
                                                    <View style={{
                                                        flex: 1,
                                                    }}>
                                                        <View style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            paddingBottom: 29
                                                        }}>
                                                            <Switch
                                                                isOn={event.options.email}
                                                                onToggle={(v) => toggleSwitch(v, event.event_id, 'email')}
                                                            />
                                                            <Switch
                                                                isOn={event.options.push}
                                                                onToggle={(v) => toggleSwitch(v, event.event_id, 'push')}
                                                            />
                                                            <Switch
                                                                isOn={event.options.app}
                                                                onToggle={(v) => toggleSwitch(v, event.event_id, 'app')}
                                                            />
                                                        </View>
                                                    </View>
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        )
                    })
                }
                <ButtonPrimary
                    label="Update"
                    onPress={() => updateSettingsNotifications()}
                    loading={btnLoading}
                    customStyles={{ width: 130, marginRight: 47, alignSelf: 'flex-end' }}
                />
            </>
            :
            <>
                {
                    notificationsData.map((item, index) => {
                        return (
                            <View
                                key={index}
                                style={{
                                    backgroundColor: '#ffffff',
                                    marginBottom: 10,
                                    borderRadius: 5,
                                    boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.05)",
                                    width: '100%'
                                }}
                            >
                                <View style={{
                                    marginBottom: 10,
                                }}>
                                    <Text style={{
                                        fontSize: 18,
                                        fontFamily: 'WorkSans_500Medium',
                                        color: '#000000',
                                        paddingBottom: 22
                                    }}>
                                        {item.group_name}
                                    </Text>
                                    {
                                        item.group_events.map((event, eventIndex) => {
                                            return (
                                                <View
                                                    key={eventIndex}
                                                    style={{ paddingBottom: 20 }}>
                                                    <Text style={{
                                                        fontSize: 15,
                                                        fontFamily: 'WorkSans_500Medium',
                                                        color: '#000000',
                                                        paddingBottom: 22
                                                    }}>
                                                        {event.event_name}
                                                    </Text>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: 20
                                                    }}>
                                                        <Text style={{
                                                            fontSize: 15,
                                                            fontFamily: 'WorkSans_400Regular',
                                                            color: '#000000',
                                                            paddingRight: 12
                                                        }}>Email</Text>
                                                        <Switch
                                                            isOn={event.options.email}
                                                            onToggle={(v) => toggleSwitch(v, event.event_id, 'email')}
                                                        />
                                                    </View>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: 20
                                                    }}>
                                                        <Text style={{
                                                            fontSize: 15,
                                                            fontFamily: 'WorkSans_400Regular',
                                                            color: '#000000',
                                                            paddingRight: 12
                                                        }}>Push Notification</Text>
                                                        <Switch
                                                            isOn={event.options.push}
                                                            onToggle={(v) => toggleSwitch(v, event.event_id, 'push')}
                                                        />
                                                    </View>
                                                    <View style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: 20
                                                    }}>
                                                        <Text style={{
                                                            fontSize: 15,
                                                            fontFamily: 'WorkSans_400Regular',
                                                            color: '#000000',
                                                            paddingRight: 12
                                                        }}>In-App</Text>
                                                        <Switch
                                                            isOn={event.options.app}
                                                            onToggle={(v) => toggleSwitch(v, event.event_id, 'app')}
                                                        />
                                                    </View>
                                                </View>
                                            )
                                        })
                                    }
                                </View>
                            </View>
                        )
                    })
                }
                <ButtonPrimary
                    label="Update"
                    onPress={() => updateSettingsNotifications()}
                    loading={btnLoading}
                    customStyles={{
                        width: '100%',
                        marginVertical: 20
                    }}
                />
            </>
    );
};

export default Notifications;