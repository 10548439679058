import { store } from '@/redux/store'
import axios from 'axios'

import getEnv from '../../environment'
const { apiUrl } = getEnv()
const getAuthorization = () => {
  const state = store.getState()
  const token = state.auth && state.auth.token
  return token ? `Bearer ${token}` : void 0
}

const getSelectedWorkspace = () => {
  const state = store.getState();
  const selectedWorkspace = state.auth && state.auth.workspace.id;
  return selectedWorkspace ? selectedWorkspace : void 0;
};

const request = async (path, options) => {
  try {
    const { body, qsParams, method } = options || {}
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAuthorization(),
      "Workspace": getSelectedWorkspace()
    }

    const instance = axios.create({
      baseURL: apiUrl,
      timeout: 999999,
      headers,
      responseType: 'json',
    })

    const response = await instance.request({
      url: path,
      method: method,
      params: qsParams,
      data: body,
    })
    return response.data
  } catch (error) {
    console.log('error', error)
    if (error.response?.data) {
      throw error.response?.data
    }
    throw new Error(error)
  }
}

export default request
