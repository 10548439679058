import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import { getMember } from "@/redux/selectors/auth";
import { useRoute } from "@react-navigation/native";
import React, { useState, useCallback, useEffect, useContext, useReducer, useRef, useMemo } from "react";
import { View, Text, Dimensions, TouchableOpacity, Platform } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useSelector } from "react-redux";
import Video, { LocalDataTrack, LocalVideoTrack, VideoProcessor } from "twilio-video";
import Room from '../Room'
import { UIButton } from "./styles";
import { Entypo, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { first } from 'lodash'
import FloatingMessage from "./FloatingMessage";
import { Modals } from './Modals'
import ApiHandler from "@/api/ApiHandler";
import VideoContext from "../context";
import { recordingEndEvent, recordingStartEvent } from "@/constants/recordingEvent";
import { GaussianBlurBackgroundProcessor, isSupported, VirtualBackgroundProcessor } from '@twilio/video-processors'
import EffectsModal from "./EffectsModal";
import useSideBarContext from '@/contexts/SidebarContext/useSideBarContext'
import { Icon } from '@/components'

const api = new ApiHandler();

const { width, height } = Dimensions.get('window');

const ModalTypes = {
    settings: 'settings',
    participants: 'participants',
    messages: 'messages'
}

const WaitingRoom = ({ onRemoveProcessors, onAddImageFilter, onAddBlurFilter, connect, localVideoTrack, _toggleLocalAudio, _toggleLocalVideo, VideoSection, color, videoEnabled, audioEnabled, currentVideoFilter, imageFilterImages }) => {
    const videoRef = useRef();
    const [effectsModalVisible, setEffectsModalVisible] = useState(false);

    useEffect(() => {
        if (localVideoTrack) {
            localVideoTrack.attach(videoRef.current);
            videoRef.current.style.transform = 'scale(-1, 1)';
        }

        return () => localVideoTrack?.detach();
    }, [localVideoTrack])

    useEffect(() => {
        if (effectsModalVisible && localVideoTrack) {
            //localVideoTrack?.detach();
        } else {
            //localVideoTrack?.attach(videoRef.current);
        }
    }, [effectsModalVisible, localVideoTrack])

    return (
        <>
            <View style={{ flexDirection: 'row', height, width }}>
                <View style={{ flex: 1.5, justifyContent: 'center', paddingHorizontal: '5%' }}>
                    <View style={{ width: '100%', aspectRatio: 16 / 10 }}>
                        <video style={{ objectFit: 'cover', width: '100%', height: '100%', aspectRatio: 16 / 10, backgroundColor: '#000', borderRadius: 8 }} ref={videoRef} />
                        <View style={{ position: 'absolute', paddingHorizontal: 10, bottom: 30, left: 0, flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <View style={{ flex: 1 }}></View>
                                <View style={{ flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                                    <UIButton style={{ borderWidth: 2, width: '70px', height: '70px' }} onPress={_toggleLocalVideo} bg="transparent" active={!videoEnabled}>
                                        <MaterialIcons name={videoEnabled ? "videocam" : "videocam-off"} size={28} color="white" />
                                    </UIButton>
                                    <UIButton style={{ borderWidth: 2, width: '70px', height: '70px' }} onPress={_toggleLocalAudio} bg="transparent" active={!audioEnabled}>
                                        <MaterialIcons name={audioEnabled ? "mic" : "mic-off"} size={28} color="white" />
                                    </UIButton>
                                </View>
                                <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'flex-end' }}>
                                    {isSupported && (
                                        <UIButton style={{ borderWidth: 2, width: '70px', height: '70px' }} onPress={() => setEffectsModalVisible(true)} bg="transparent">
                                            <Entypo name="dots-three-vertical" size={28} color="white" />
                                        </UIButton>
                                    )}
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={{ paddingRight: '10%', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 30, fontWeight: '500' }}>The Meeting Is Ready</Text>
                    <TouchableOpacity onPress={connect} style={{
                        paddingHorizontal: 40,
                        backgroundColor: color,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: 4,
                        paddingVertical: 10,
                        marginTop: 20
                    }}>
                        <Text style={{ fontSize: 18, color: "white", fontWeight: '500' }}>Join Meeting</Text>
                    </TouchableOpacity>
                </View>
            </View>

            <EffectsModal setModalVisible={setEffectsModalVisible} onRemoveProcessors={onRemoveProcessors} visible={effectsModalVisible} localVideoTrack={localVideoTrack} onAddBlurFilter={onAddBlurFilter} onAddImageFilter={onAddImageFilter} showTabs={true} currentVideoFilter={currentVideoFilter} imageFilterImages={imageFilterImages} />
        </>
    )
}

const VideoChat = () => {
    const route = useRoute();
    const [room, setRoom] = useState(null);
    const [connecting, setConnecting] = useState(false);
    const user = useSelector(getMember);
    const roomId = route.params.id;
    const color = UseBrandingAccentColor();
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [deviceError, setDeviceError] = useState(null);
    const [screenTrack, setScreenTrack] = useState(null);
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [localDataTrack, setLocalDataTrack] = useState(null);
    const navigation = useNavigation()
    const [messages, setMessages] = useState(new Map());
    const [hasUnreadMessages, setUnreadMessages] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [floatingMessage, setFloatingMessage] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [modal, setModal] = useState(null);
    const { getSetting, addParticipant, updateSetting, presentationId, setPresentationId } = useContext(VideoContext);
    const [duration, setDuration] = useState({ s: 0, m: 0, h: 0 });
    const [initialRender, setInitialRender] = useState(true);
    const [IamHost, setIamHost] = useState(false);
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [token, setToken] = useState("");
    const [currentVideoFilter, setCurrentVideoFilter] = useState();
    const [settingsModalVisible, setSettingsModalVisible] = useState(false);
    const { setSidebarHidden } = useSideBarContext();

    useEffect(() => {
        let interval;
        if (initialRender) {
            setInitialRender(false);
        } else if (isRecording) {
            setFloatingMessage({
                sender: "system",
                message: "Recording was started by the host"
            })

            interval = setInterval(() => {
                setDuration(prev => {
                    const { s, m, h } = prev;

                    if (s < 60) {
                        return { ...prev, s: s + 1 }
                    } else if (s == 60) {
                        return { ...prev, s: 0, m: m + 1 }
                    } else if (m == 60) {
                        return { ...prev, s: 0, m: 0, h: h + 1 }
                    }
                });
            }, 1000)
        } else {
            setFloatingMessage({
                sender: "system",
                message: "Recording was stopped by the host"
            })
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        }
    }, [isRecording])

    useEffect(() => {
        setDuration(prev => {
            return { h: 0, m: 0, s: 0 }
        })
    }, [isRecording])

    const toggleLocalAudio = async () => {
        room.localParticipant.audioTracks.forEach(async audio => {
            const track = audio?.track;
            const isEnabled = track.isEnabled;
            isEnabled ? await track.disable() : await track.enable();
            setAudioEnabled(prev => !isEnabled);
        })
    }

    const handleFloatingMessage = () => {
        if (floatingMessage) {
            setTimeout(() => {
                setFloatingMessage(null);
            }, 3000)
        }
    }

    useEffect(() => {
        const unsubscribe = handleFloatingMessage();
        return unsubscribe;
    }, [floatingMessage])

    const sendMessage = async () => {
        if (messageText.trim() !== "") {
            const message = [
                Date.now(),
                {
                    sender: user.id,
                    message: messageText
                }
            ]

            setMessages(new Map([...messages, message]));

            localDataTrack.send(messageText);

            setMessageText("");
        }
    }

    const toggleLocalVideo = async () => {

        room.localParticipant.videoTracks.forEach(publication => {
            const track = publication?.track;
            if (track.name !== "screen") {
                const isEnabled = track.isEnabled;
                isEnabled ? track.disable() : track.enable();
                setVideoEnabled(!isEnabled);
            }
        })
    }



    const shareScreen = async () => {
        if (!screenTrack) {
            if (presentationId) {
                alert("Screen Share is already running");
                return;
            } else {
                const stream = await navigator.mediaDevices.getDisplayMedia();

                const newScreenTrack = first(stream.getVideoTracks());

                const track = new Video.LocalVideoTrack(newScreenTrack, {
                    name: 'screen'
                });

                track.on('stopped', () => {
                    stopScreenShare(track).then(() => {
                        setPresentationId(null);
                    })
                })

                setScreenTrack(track);

                const screenshare = await room.localParticipant.publishTrack(track, {
                    name: "screen"
                });

                // await room.localParticipant.unpublishTrack(localVideoTrack);

                setTimeout(() => {
                    forceUpdate();
                }, 1500)
            }

        } else {
            stopScreenShare(screenTrack);
        }

    }

    const stopScreenShare = async (screenTrack) => {
        try {
            await room.localParticipant.unpublishTrack(screenTrack);
            // await room.localParticipant.publishTrack(localVideoTrack);
            screenTrack.stop();
            setScreenTrack(null);

            setPresentationId(null);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (presentationId) {
            const msg = {
                sender: presentationId,
                message: "Started Presentation"
            }
            setFloatingMessage(msg);
        } else {
            const msg = {
                sender: presentationId,
                message: "Stopped Presentation"
            }
            setFloatingMessage(msg);
        }
    }, [presentationId])


    const getToken = async () => {
        const res = await api.authenticateWithTwilio({ meeting_id: roomId });
        const data = res?.data;
        if (data) {
            return data.data;
        }

        return undefined;

    }

    // const getToken = async () => {
    //     const res = await fetch(`http://192.168.14.10:5000/twilio?id=${user.id}&room=${roomId}`);
    //     const result = await res.json();
    //     console.log(result);
    //     return result.token;
    // }

    const initVideoTrack = useCallback(() => {
        Video.createLocalVideoTrack({
            facingMode: 'user',
            aspectRatio: 16 / 9
        }).then(videoTrack => setLocalVideoTrack(videoTrack)).catch(error => console.log(error))
    }, [])

    const initAudioTrack = useCallback(() => {
        Video.createLocalAudioTrack().then((audioTrack) => setLocalAudioTrack(audioTrack));
    })

    const initDataTrack = useCallback(() => {
        let dataTrack = new LocalDataTrack;
        setLocalDataTrack(dataTrack);
    }, [])


    const init = useCallback(async () => {
        try {

            if (localAudioTrack) {
                updateSetting('audioInput', "default");
                updateSetting("audioOutput", 'default');
            }

        } catch (error) {
            console.log(error);
        }
    }, [localAudioTrack])

    const connect = useCallback(async () => {
        try {
            const tracks = [localAudioTrack, localVideoTrack, localDataTrack]
            console.log(tracks);

            const _token = await getToken();

            setToken(_token);

            setConnecting(true);


            Video.connect(_token, {
                name: roomId,
                tracks,
                dominantSpeaker: true,
            })
                .then((room) => {

                    api.getParticipant({ id: user?.id?.toString() }).then(res => {
                        const data = res.data?.data ?? {};
                        addParticipant(user.id?.toString(), data);
                    })

                    setConnecting(prev => {
                        setToken(_token);
                        setRoom(room);
                        return false;
                    })
                })
                .catch((err) => {
                    alert(err);
                    setConnecting(false);
                })
        } catch (error) {
            if (error && error.toString().includes("Requested device not found")) {
                setDeviceError(true);
            }

            alert(error.message ?? error);
        }
    }, [roomId, user, localAudioTrack, localVideoTrack, localDataTrack])

    useEffect(() => {
        initAudioTrack();
        initVideoTrack();
        initDataTrack();
    }, [])

    useEffect(() => {
        init();
    }, [localAudioTrack])


    useEffect(() => {
        const unsubscribe = navigation.addListener('beforeRemove', () => {
            handleLogout();
        })
        return unsubscribe;
    }, [])

    const handleLogout = useCallback(() => {
        setRoom((prevRoom) => {
            if (prevRoom) {

                prevRoom.localParticipant.tracks.forEach(publication => {
                    if (publication.track.isEnabled) {
                        publication.track.stop();
                    }
                })

                prevRoom.disconnect();

                navigation.navigate('Calendar')

                setSidebarHidden(false);
            }
            return null;
        });
    }, []);

    const handleUnreadMessages = () => {
        if (modal == ModalTypes.messages) {
            setUnreadMessages(false);
        }
    }

    useEffect(() => {
        const unsubscribe = handleUnreadMessages();
        return unsubscribe;
    }, [modal])

    const recordMeeting = async () => {
        if (isRecording) {
            // stop the recording
            const res = await api.stopRecordMeeting(roomId);
            if (res.status == 200) {
                localDataTrack.send(recordingEndEvent);
                setIsRecording(false);
            }
        } else {
            // start the recording
            const res = await api.startRecordMeeting(roomId);
            if (res.status == 200) {
                localDataTrack.send(recordingStartEvent);
                setIsRecording(true);
            }
        }
    }

    const getRoomInfo = async () => {
        const res = await api.getMeetingInfo(roomId);
        const data = res?.data?.data;

        if (data?.host_id == user.id) {
            setIamHost(true);
        }
    }

    useEffect(() => {
        if (room) {
            getRoomInfo();
        }
    }, [room])

    const onRemoveProcessors = useCallback(async () => {
        if (currentVideoFilter) {
            localVideoTrack.removeProcessor(currentVideoFilter);
        }

        setCurrentVideoFilter(null);
    }, [localVideoTrack, currentVideoFilter])

    const imageFilterImages = [
        {
            name: 'color_reframe_red.jpeg',
            image: require('@/assets/meeting/effects/backgrounds/color_reframe_red.jpeg'),
        },
        {
            name: 'scene_bookshelves.jpg',
            image: require('@/assets/meeting/effects/backgrounds/scene_bookshelves.jpg'),
        },
        {
            name: 'scene_lobby.jpg',
            image: require('@/assets/meeting/effects/backgrounds/scene_lobby.jpg'),
        },
        {
            name: 'scene_cafe.jpg',
            image: require('@/assets/meeting/effects/backgrounds/scene_cafe.jpg'),
        },
    ]

    const addImageFilter = useCallback(async (imageName, imageFormat) => {
        if (currentVideoFilter) {
            //console.log(currentVideoFilter?.name);
            localVideoTrack.removeProcessor(currentVideoFilter);
        }

        const image = new Image();

        image.src = imageFilterImages.find(e => e.name === imageName + '.' + imageFormat).image;

        image.onload = async () => {
            const bg = new VirtualBackgroundProcessor({
                assetsPath: 'https://platform.getreframe.com/twilio/',
                backgroundImage: image,
                fitType: "fit",
                maskBlurRadius: 5,
            })
            await bg.loadModel();
            localVideoTrack.addProcessor(bg);

            setCurrentVideoFilter(bg);

        }

    }, [localVideoTrack, currentVideoFilter])

    const addBlurFilter = useCallback(async (intensity) => {
        if (currentVideoFilter) {
            localVideoTrack.removeProcessor(currentVideoFilter);
        }

        const bg = new GaussianBlurBackgroundProcessor({
            assetsPath: 'https://app-backend-production.getreframe.com/twilio/',
            maskBlurRadius: 10,
            blurFilterRadius: intensity,
            //debounce: false
        });
        await bg.loadModel();
        localVideoTrack.addProcessor(bg);
        setCurrentVideoFilter(bg);

        if (room) {
            room.localParticipant.unpublishTrack(localVideoTrack);
            setTimeout(() => {
                room.localParticipant.publishTrack(localVideoTrack);
            }, 1000)
        }

    }, [localVideoTrack, currentVideoFilter])

    useEffect(() => {
        if (room) {
            const tidyUp = (event) => {
                if (event.persisted) {
                    return;
                }
                if (room) {
                    handleLogout();
                }
            };
            window.addEventListener("pagehide", tidyUp);
            window.addEventListener("beforeunload", tidyUp);
            return () => {
                window.removeEventListener("pagehide", tidyUp);
                window.removeEventListener("beforeunload", tidyUp);
            };
        }
    }, [room, handleLogout]);

    const _toggleLocalVideo = useCallback(() => {
        if (videoEnabled) {
            localVideoTrack.disable();
            setVideoEnabled(false);
            // setLocalVideoTrack(null);

            if (currentVideoFilter) {
                localVideoTrack.removeProcessor(currentVideoFilter);
            }
        } else {
            localVideoTrack.enable();
            setVideoEnabled(true);

            if (currentVideoFilter) {
                if (currentVideoFilter?._name === 'GaussianBlurBackgroundProcessor') {
                    addBlurFilter()
                }

                if (currentVideoFilter?._name === 'VirtualBackgroundProcessor') {
                    addImageFilter()
                }
            }
        }

    }, [localVideoTrack, videoEnabled, setVideoEnabled])

    const _toggleLocalAudio = useCallback(() => {
        if (audioEnabled) {
            localAudioTrack.disable();
            setAudioEnabled(false);
            // setLocalAudioTrack(null)
        } else {
            localAudioTrack.enable();
            setAudioEnabled(true);
        }
    }, [localAudioTrack, audioEnabled])

    // const WaitingRoom = useCallback(() => (

    // ), [localAudioTrack, localVideoTrack, videoEnabled])

    return deviceError ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontSize: 24, fontWeight: '600' }}>Looks Like You don't have the required devices</Text>
            <Text style={{ marginVertical: 10 }}>Your system must be equipped with a mic and a camera to use our video call services</Text>
        </View>
    ) : room ? (
        <View style={{ flex: 1, position: 'relative', backgroundColor: '#000' }}>
            <Room setIsRecording={setIsRecording} selectedAudioDeviceId={getSetting('audioOutput')} setUnreadMessages={setUnreadMessages} messages={messages} setFloatingMessage={setFloatingMessage} setMessages={setMessages} roomName={roomId} room={room} handleLogout={handleLogout} />
            <View style={{ position: 'absolute', bottom: 30, left: 0, width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flex: 3 }}>

                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'center', flex: 4 }}>
                    <UIButton onPress={toggleLocalVideo} active={!videoEnabled} bg={'#3c4043'}>
                        <MaterialIcons name={videoEnabled ? "videocam" : "videocam-off"} size={18} color="white" />
                    </UIButton>
                    <UIButton onPress={toggleLocalAudio} active={!audioEnabled} bg={'#3c4043'}>
                        <MaterialIcons name={audioEnabled ? "mic" : "mic-off"} size={18} color="white" />
                    </UIButton>
                    <UIButton onPress={handleLogout} bg={'#3c4043'}>
                        <MaterialIcons name="call-end" size={18} color="white" />
                    </UIButton>
                    <UIButton onPress={shareScreen} active={screenTrack} bg={'#3c4043'}>
                        <MaterialIcons name='airplay' size={18} color="white" />
                    </UIButton>

                </View>
                <View style={{ position: "relative", flex: 3, flexDirection: 'row', justifyContent: 'flex-end', paddingHorizontal: 20 }}>
                    {floatingMessage && (
                        <FloatingMessage message={floatingMessage?.message} sender={floatingMessage?.sender} timeStamp={Date.now()} />
                    )}
                    <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                        <UIButton onPress={() => setModal(ModalTypes.participants)} bg={'#3c4043'}>
                            <MaterialIcons name="person-add-alt-1" size={18} color="white" />
                        </UIButton>
                        <UIButton onPress={() => setModal(ModalTypes.messages)} bg={'#3c4043'}>
                            {hasUnreadMessages && (
                                <View style={{ width: 10, height: 10, borderRadius: 50, backgroundColor: 'red', position: 'absolute', top: 0, right: 0 }} />
                            )}
                            <MaterialIcons name="mode-comment" size={18} color="white" />
                        </UIButton>
                        {IamHost && (
                            <UIButton onPress={recordMeeting} active={isRecording} bg={'#3c4043'}>
                                <MaterialCommunityIcons name="record-rec" size={18} color={"white"} />
                            </UIButton>
                        )}
                        <UIButton onPress={() => setSettingsModalVisible(true)} bg={'#3c4043'}>
                            <MaterialIcons name="settings" size={18} color="white" />
                        </UIButton>
                    </View>
                </View >
            </View >
            <EffectsModal
                localVideoTrack={localVideoTrack}
                onAddBlurFilter={addBlurFilter}
                onAddImageFilter={addImageFilter}
                onRemoveProcessors={onRemoveProcessors}
                setModalVisible={setSettingsModalVisible}
                showTabs
                setLocalAudioTrack={setLocalAudioTrack}
                setLocalVideoTrack={setLocalVideoTrack}
                room={room}
                visible={settingsModalVisible}
                currentVideoFilter={currentVideoFilter}
                imageFilterImages={imageFilterImages}
            />
            {!!modal && (
                <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, flex: 1, backgroundColor: "transparent" }}>
                    <View style={{ flex: 1, backgroundColor: "transparent", paddingHorizontal: 20, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <View style={{ backgroundColor: "#FFFFFF", borderRadius: 8, width: width / 3, maxWidth: '700px', height: height / 1.2, maxHeight: '900px', marginBottom: 80, padding: 17 }}>
                            <View style={{
                                marginBottom: 25,
                                flexDirection: 'row'
                            }}>
                                <View style={{
                                    justifyContent: 'center'
                                }}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setModal(null)
                                        }}
                                        style={{
                                            marginRight: 15,
                                            backgroundColor: '#1b1b1b',
                                            borderRadius: 50,
                                            justifyContent: 'center',
                                            width: 25,
                                            height: 25,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Icon
                                            icon="arrowLeft"
                                            color={'#FFF'}
                                            height={8}
                                        />
                                    </TouchableOpacity>
                                </View>

                                <View style={{
                                    flexGrow: 1,
                                    flexShrink: 1,
                                    flexBasis: '65%',
                                    justifyContent: 'center'
                                }}>
                                    <Text style={{
                                        fontFamily: "WorkSans_400Regular",
                                        fontSize: 22,
                                        lineHeight: 22,
                                    }}>
                                        {modal == ModalTypes.messages && 'Messages'}
                                        {modal == ModalTypes.participants && 'Participants'}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ position: 'relative', flex: 9 }}>
                                {modal == ModalTypes.messages ? (
                                    <Modals.Messages messageText={messageText} setMessageText={setMessageText} messages={messages} sendMessage={sendMessage} user={user} />
                                ) : modal == ModalTypes.participants ? (
                                    <Modals.Participants participants={room?.participants} />
                                ) : <View />}
                            </View>
                        </View>
                    </View>
                </View>
            )}
            {
                isRecording && (
                    <View style={{ backgroundColor: "#1b1b1b", borderRadius: 8, position: "absolute", top: 10, right: 10, width: 200, height: 70, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                        <MaterialCommunityIcons style={{ marginRight: 10 }} name="record-rec" size={24} color="red" />
                        <Text style={{ fontSize: 26, color: 'white', letterSpacing: 5 }}>{duration["h"] < 10 ? `0${duration['h']}` : duration['h']}:{duration["m"] < 10 ? `0${duration['m']}` : duration['m']}:{duration["s"] < 10 ? `0${duration['s']}` : duration['s']}</Text>
                    </View>
                )
            }
        </View >
    ) : connecting ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator />
        </View>
    ) : <WaitingRoom
        localVideoTrack={localVideoTrack}
        _toggleLocalAudio={_toggleLocalAudio}
        _toggleLocalVideo={_toggleLocalVideo}
        audioEnabled={audioEnabled}
        onRemoveProcessors={onRemoveProcessors}
        videoEnabled={videoEnabled}
        color={color}
        onAddBlurFilter={addBlurFilter}
        onAddImageFilter={addImageFilter}
        currentVideoFilter={currentVideoFilter}
        connect={connect}
        imageFilterImages={imageFilterImages}
    />
};

export default VideoChat;