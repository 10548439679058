import styled from 'styled-components/native'
import { TextInput, View, TouchableOpacity } from 'react-native'

export const Container = styled(View)`
  height: 40px;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
  border-color: #d6d6d6;
  border-width: 1px;
  flex: 1;
  margin-left: 20px;
`
export const IconContainer = styled(TouchableOpacity)`
  height: 40px;
  width: 35px;
  background-color: #1b1b1b;
  align-items: center;
  justify-content: center;
`
export const CloseIconContainer = styled(TouchableOpacity)`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`
export const InputContainer = styled(View)`
  flex: 1;
`
export const InputText = styled(TextInput)`
  font-size: 15px;
  font-weight: 400;
  color: #6d6d6d;
  margin-left: 15px;
`
