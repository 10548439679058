import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Comment(props) {
  return (
    <Svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M16.3999 0.618164H1.99995C1.00995 0.618164 0.208951 1.46962 0.208951 2.51028L0.199951 19.5393L3.79995 15.7551H16.3999C17.3899 15.7551 18.1999 14.9036 18.1999 13.863V2.51028C18.1999 1.46962 17.3899 0.618164 16.3999 0.618164ZM6.49995 9.13269H4.69995V7.24057H6.49995V9.13269ZM10.0999 9.13269H8.29995V7.24057H10.0999V9.13269ZM13.6999 9.13269H11.8999V7.24057H13.6999V9.13269Z"  />
    </Svg>
  )
}

Comment.propTypes = SvgProps

export default Comment
