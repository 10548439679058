import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Toast from 'react-native-toast-message'

import { vw } from 'react-native-expo-viewport-units'
import { TextField } from '@/components'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { actions as authActions } from '@/redux/reducers/auth'
import { ActivityIndicator } from 'react-native-paper'
import { Entypo } from '@expo/vector-icons'
import * as Yup from 'yup'
import { useScreenSize } from '@/styles'
import {
  TextButton,
  SubmitButton,
  Instructions,
  SendCodePageContent,
  ResendEmailButton,
  ResendEmailText
} from './styles'
import OnboardingPage from '@/components/OnboardingPage'
import ForgotPasswordDecoration from '@/assets/onboarding/ForgotPasswordDecoration'

const initialValues = {
  code: '',
}

const validationSchema = Yup.object().shape({
  code: Yup.number()
    .test(
      'len',
      'Must be exactly 4 characters',
      val => val?.toString().length === 4)
    .required()
})

function SendPasswordCode({ navigation }) {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true)

      dispatch(
        authActions.sendCodePassword(
          {
            code: values.code,
          },
          (error) => {
            setLoading(false)
            if (error) {
              Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Error, please try again.',
              })
            } else {
              navigation.navigate('ResetPassword')
            }
          },
        ),
      )
    },
    [navigation],
  )

  return (
    <OnboardingPage
      invert
      header={<OnboardingHeader withBackButton invertedContent />}
      decoration={<ForgotPasswordDecoration width={vw(45)} height="auto" />}
    >
      <SendCodePageContent screenSize={screenSize}>
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <OnboardingFormWrapper title={'Reset your\npassword!'}>
              <Instructions>
								Enter the received code that was sended to your associated e-mail to you reset your password.
              </Instructions>
              <TextField
                label="Code"
                value={values.code}
                autoCapitalize="none"
                keyboardType="numeric"
                onChangeText={handleChange('code')}
              />
              <SubmitButton
                color="black"
                disabled={!isValid}
                onPress={handleSubmit}
              >
                {loading && (
                  <ActivityIndicator
                    size={20}
                    animating
                    color="white"
                    style={{ marginRight: 10 }}
                  />
                )}
                <TextButton>Send code</TextButton>
                <Entypo name="chevron-right" size={24} color="white" />
              </SubmitButton>
              <ResendEmailButton>
                <ResendEmailText>Resend Email</ResendEmailText>
              </ResendEmailButton>
            </OnboardingFormWrapper>
          )}
        </Formik>
      </SendCodePageContent>
    </OnboardingPage>
  )
}

SendPasswordCode.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default SendPasswordCode
