import * as ImagePicker from 'expo-image-picker'
import { Alert } from 'react-native'
import * as FileSystem from 'expo-file-system'

export const openGallery = async (options) => {
  const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()

  if (!permissionResult.granted) {
    Alert.alert(
      'You need to grant permission for Reframe to access your photo gallery',
    )
    return Promise.reject(new Error('Permission denied'))
  }

  const imageResult = await ImagePicker.launchImageLibraryAsync(options)
  if (imageResult.cancelled) {
    return
  }


  return imageResult.uri
}

export const openCamera = async (options) => {
  const permissionResult = await ImagePicker.requestCameraPermissionsAsync()

  if (!permissionResult.granted) {
    Alert.alert(
      'You need to grant permission for Reframe to access your camera',
    )
    return Promise.reject(new Error('Permission denied'))
  }

  const videoresult = await ImagePicker.launchCameraAsync(options)
  if (videoresult.cancelled) {
    return
  }

  console.log('Media Result', videoresult)

  return videoresult.uri
}

export async function ConvertToBase64(file) {
  const b = await FileSystem.readAsStringAsync(file, {
    encoding: FileSystem.EncodingType.Base64,
  })
    .then((res) => {
      return res
    })
    .catch((err) => {
      console.log(err, 'Error')
    })
  return b
}
