import styled from 'styled-components/native'
import { StyleSheet, Dimensions } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import themes from '@/styles/themes/black'
import { Subtitle2 } from '@/styles/fonts'
import { ScreenSize } from '@/styles'

const { width } = Dimensions.get('window')

export const styles = StyleSheet.create({
  communityScroll: {
    width: '100%',
    flexGrow: 1,
  }
})

export const Container = styled.View`
	flex: 1;
  background-color: ${themes.basics.background_button};
  padding-left: ${({ isMobile }) => isMobile ? 15 : 45}px;
`

export const HeadLine = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding-right:${width <= ScreenSize.Medium ? 15 : 56}px;

`

export const TitleWrapper = styled.View`
	flex-direction: row;
	align-items: center;
`

export const Header = styled.View`
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 40px 0 28px;
	padding-right: ${({ isMobile }) => isMobile ? 0 : 28}px;
	z-index: 1;

`

export const SubTitle = styled.Text`
	font-family: 'WorkSans_500Medium';
	font-size: ${({ isMobile }) => isMobile ? 18 : 24}px;
	line-height: ${({ isMobile }) => isMobile ? 20 : 27}px;
	color: ${themes.basics.black_text};
	padding: 0 0 8px;
`

export const Title = styled.Text`
	margin: 0 0 0 ${({ isMobile }) => isMobile ? 0 : 10}px;
	font-family: 'WorkSans_400Regular';
	font-size: ${({ isMobile }) => isMobile ? 22 : 40}px;
	line-height: ${({ isMobile }) => isMobile ? 25 : 40}px;
	color: ${themes.basics.black_text};
`

export const LinkButton = styled.TouchableOpacity`
	padding: 0 ${({ isMobile }) => isMobile ? 16 : 0}px 0 0;
	align-items: center;
	justify-content: center;
`

export const LinkText = styled(Subtitle2)`
	color: ${themes.danger.standard};
`

export const SearchButton = styled(TouchableOpacity)`
	height: 24px;
	width: 24px;
`

export const WrapperCollapse = styled.View`
	align-self: flex-end;
	margin: 0 44px 0 0;
`
