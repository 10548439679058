import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView, Platform } from 'react-native';
import ReframeRoundLogo from '@/assets/brand/ReframeRoundLogo.png';
import ApiHandler from '@/api/ApiHandler';
import { useDispatch } from 'react-redux';
import { actions as authActions } from '@/redux/reducers/auth';
import { useNavigation, useRoute } from '@react-navigation/native'
import { actions as brandSetingsActions } from '@/redux/reducers/branding'
import { actions as subscriptionActions } from '@/redux/reducers/subscription'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const Workspaces = () => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const [listWorkspaces, setListWorkspaces] = useState([]);

    useEffect(async () => {
        try {
            const response = await new ApiHandler().getWorkspaces();
            if (response?.data) {
                setListWorkspaces(response?.data?.data);
            }
        } catch (error) {
            console.log('GET WORKSPACES ERROR', error);
        }
    }, []);

    const selectedWorkspace = async (workspaceId) => {
        try {
            const data = {
                workspace_id: workspaceId
            };
            const response = await new ApiHandler().selectWorkspace(data);
            if (response?.data) {
                dispatch(authActions.selectedWorkspace(response?.data?.data));

                navigation.navigate('Home');

                try {
                    const response = await new ApiHandler().getBrandingSettings()
                    if (response?.data) {
                        const settings = response?.data?.data || {}
                        dispatch(brandSetingsActions.updateSettings(settings));
                    }
                } catch (error) {

                }
            }
        } catch (error) {
            console.log('SELECT WORKSPACE ERROR', error);
        }
    }

    return (
        <>
            <PageHeader
                title={'Workspaces'}
                icon={'workspace'}
                backEnabled={false}
            />

            <PageContent>
                <View style={{
                    width: '100%',
                    maxWidth: 618,
                    alignSelf: 'center',
                }}>
                    <ContentBlockV1
                        title={'Select A Workspace'}
                        subtitle={'Your account is associated with multiple workspaces. Select the workspace that you’d like to access.'}
                    >
                        {
                            listWorkspaces.map((item, index) => {
                                return (
                                    <TouchableOpacity
                                        key={index}
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 25,
                                            height: 44,
                                        }}
                                        onPress={() => selectedWorkspace(item.id)}
                                    >
                                        <View
                                            style={{
                                                width: 50,
                                                height: 50,
                                                padding: 10,
                                                borderRadius: 50,
                                                borderWidth: 1,
                                                borderColor: '#e5e5e5',
                                                backgroundColor: item.icon_background ? item.icon_background : 'transparent'
                                            }}
                                        >
                                            <Image
                                                source={item.icon == null ? ReframeRoundLogo : { uri: item.icon }}
                                                style={{
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                            />
                                        </View>

                                        <Text
                                            style={{
                                                paddingLeft: 15,
                                                color: '#000000',
                                                fontSize: 20,
                                                fontFamily: 'WorkSans_400Regular',
                                            }}>
                                            {item.name}
                                        </Text>
                                    </TouchableOpacity>
                                )
                            })
                        }
                    </ContentBlockV1>
                </View>
            </PageContent>
        </>

    );
};

export default Workspaces;