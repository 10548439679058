/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { ActivityIndicator } from 'react-native-paper'
import { Text, View } from "react-native";
import {
    Container,
    Header,
    OptionView,
    OptionIndex,
    ContentCard1,
    SubmitButton,
    PostButtonWrapper,
    PrecedingQuestion
} from './styles'
import {
    Button,
} from '@/components'
import { PostInterface } from '@/interfaces/post'
import { actions } from '@/redux/reducers/posts'
import { getMember } from '@/redux/selectors/auth'
import { CaptionMedium } from '@/styles/fonts'
import * as brandSelectors from '@/redux/selectors/branding'
import { ScreenSize, useScreenSize } from '@/styles/screenSize'
import * as selectors from '@/redux/selectors/posts'

import {
    Q_TYPE_FREE_TEXT,
    Q_TYPE_MULTIPLE,
    Q_TYPE_SINGLE
} from "@/pages/Assessments/consts"
import { TextField } from '@/components'

import FeedCard from '@/components/Feed/Card'

const AssessmentCard = ({
    post
}) => {
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const user = useSelector(getMember)
    const screenSize = useScreenSize()
    const branding = useSelector(brandSelectors.getSettings)

    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text,
    } = branding

    const loading = useSelector(selectors.loading)

    const [selectedOptions, setSelectedOptions] = useState([])
    const [submitDisabled, setSubmitDisabled] = React.useState(false);
    const [quizAnswer, setQuizAnswer] = React.useState('');

    const handleResponse = options => {
        dispatch(actions.reponse_assessments({
            question_id: post?.question.id,
            post_id: post?.assessment?.id,
            post_type: post?.assessment?.type,
            options: options,
            text: quizAnswer,
        }, () => {
            dispatch(actions.feeds_index({ page: 1 }, () => {
                setSelectedOptions([]);
                setSubmitDisabled(false)
                setQuizAnswer("");
            }))
        }))
    }

    return (
        <>
            <Container>
                {post?.preceding_question && (
                    <PrecedingQuestion>You were previously asked "{post?.preceding_question.question}" and you responded "{post?.preceding_question.response}".</PrecedingQuestion>
                )}
                <Header>{post?.question.text}</Header>

                {post?.question.format == Q_TYPE_FREE_TEXT && (
                    <View>
                        <TextField
                            multiline
                            numberOfLines={4}
                            label="Your Answer"
                            value={quizAnswer}
                            keyboardType="text"
                            onChangeText={(text) => setQuizAnswer(text)}
                        />

                    </View>
                )}

                {(post?.question.format == Q_TYPE_SINGLE || post?.question.format == Q_TYPE_MULTIPLE) && (
                    <OptionView>
                        {_.keys(post?.options).map((option, index) => {
                            return (
                                <OptionIndex
                                    selected={selectedOptions.indexOf(option) > -1}
                                    onPress={() => {
                                        if (post?.question.format == Q_TYPE_SINGLE) {
                                            setSelectedOptions([option]);
                                        } else if (post?.question.format == Q_TYPE_MULTIPLE) {
                                            if (selectedOptions.indexOf(option) > -1) {
                                                const newOptionsAfterRemove = selectedOptions.filter((item, j) => selectedOptions.indexOf(option) !== j);
                                                setSelectedOptions(newOptionsAfterRemove);
                                            } else {
                                                const newOptionsAfterAdd = selectedOptions.concat(option);
                                                setSelectedOptions(newOptionsAfterAdd);
                                            }
                                        }
                                    }}
                                    key={index + ''}
                                    color={color_scheme_primary_button_background ? color_scheme_primary_button_background : 'rgba(240, 83, 67, 1)'}
                                >
                                    <Text>{post?.options[option]}</Text>
                                </OptionIndex>
                            )
                        })}
                    </OptionView>
                )}

                <PostButtonWrapper>
                    <Button
                        color={color_scheme_primary_button_background ? color_scheme_primary_button_background : 'black'}
                        onPress={() => {
                            setSubmitDisabled(true)
                            handleResponse(selectedOptions)
                        }}
                        disabled={(quizAnswer.length == 0 && selectedOptions.length == 0) || submitDisabled}
                        style={{
                            width: screenSize <= ScreenSize.Medium ? '100%' : undefined,
                        }}
                    >
                        {loading && (
                            <ActivityIndicator
                                size={20}
                                animating
                                color="white"
                                style={{ marginRight: 10 }}
                            />
                        )}
                        <CaptionMedium
                            color={color_scheme_primary_button_text ? color_scheme_primary_button_text : 'white'}
                            style={{ fontSize: 17 }}
                        >
                            Submit
                        </CaptionMedium>
                    </Button>
                </PostButtonWrapper>
            </Container>
        </>
    )
}


AssessmentCard.propTypes = {
    // post
    post: PostInterface,
    postType: PropTypes.string,
    idPresent: PropTypes.number,
    post_id: PropTypes.number,
}

export default AssessmentCard
