import React, { useCallback, useEffect, useRef, useState } from 'react'
import { View, Text, Platform, FlatList, TouchableOpacity, TouchableHighlight, StyleSheet, Dimensions, Linking, ScrollView as RNScrollView } from 'react-native'
import { useDispatch } from 'react-redux'
import { Button, Icon } from '@/components'
import theme from '@/styles/themes/black'
import ApiHandler from '@/api/ApiHandler'
import { TextInput } from 'react-native-paper';
import { CheckBox } from 'react-native-elements';
import SimpleModal from '@/pages/SharedFolders/SimpleModal'
import { ProgressBar, Avatar } from 'react-native-paper'
import Toast from 'react-native-toast-message'
import SearchableDropDown from 'react-native-searchable-dropdown'
import {
  ScrollView,
  styles,
  CreateFolderContainer,
  HeaderCreateFolder,
  CloseButtonCreateFolder,
  HeaderTitleCreateFolder,
  HeaderSubTitleCreateFolder,
  HeaderSubTitleInfo,
  HeaderTitleInfoFolder,
  Tabs,
  Tab,
  TabTitle,
} from './styles'
import { isMobile } from '@/styles'
import { MultiSelect } from 'react-native-element-dropdown';
import Clipboard from '@react-native-clipboard/clipboard';
import {
  MenuProvider,
  Menu,
  MenuTrigger,
  MenuOptions,
  MenuOption,
  renderers,
} from 'react-native-popup-menu';
import getEnv from '../../../../environment'
import FloatingModal from '@/components/FloatingModal'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import { ButtonText, ClearButton } from '../styles'
import InputField from '@/components/Chat/components/InputField'
import useLayoutContext from '../Context/useLayout'
import { Layouts } from '../Main/Layouts'
import { formatUserName } from '@/utils/format'
const { apiUrl } = getEnv()

const { height, width } = Dimensions.get('window')

const SideMenusWeb = (props) => {

  const [nodeIdToshare, setNodeIdToshare] = useState([]);
  const [isNodeShared, setIsNodeShared] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState([]);
  const [communityGroup, setCommunityGroup] = useState([]);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [communityTeam, setCommunityTeam] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [communityEmployee, setCommunityEmployee] = useState([]);

  const [resizeModelHeight, setResizeModelHeight] = useState('0.7');
  const accentColor = UseBrandingAccentColor();

  const renderItem = (item) => {
    return (
      <View style={dropdownStyles.item}>
        <Text style={dropdownStyles.textItem}>{item.first_name} {item.last_name}</Text>
      </View>
    );
  };

  const dispatch = useDispatch()
  const [isRenameModalVisible, setRenameModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [nodeVal, setNode] = useState(null)
  const [deleteNode, setDeleteNode] = useState(null)
  const [isInfoModalVisible, setInfoModalVisible] = useState(false)
  const [isMoveToModalVisible, setMoveToModalVisible] = useState(false)
  const [isShareModalVisibleOld, setShareModalVisibleOld] = useState(false)

  const [isShareModalVisible, setShareModalVisible] = useState(false)


  const [getFolderInfo, setFolderInfo] = useState(null)
  const [foldername, setFolderName] = useState('')
  const [errorTextCreate, setCreateError] = useState('')
  const [loading, setloading] = useState(false)

  const [getSelectedFolderToMove, setSelectedFolderToMove] = useState(null)
  const [getFoldersToMove, setFoldersToMove] = useState(null)
  const [isHighlighted, setHighlighted] = useState(null)

  const [tab, setTab] = useState('group')

  const [sharedWithEntireWorkspace, setSharedWithEntireWorkspace] = React.useState(false);
  const { layout } = useLayoutContext();

  const groupInput = useRef();
  const teamInput = useRef();
  const employeeInput = useRef();

  useEffect(() => {
    setCommunityGroup(props.communityGroup);
    setCommunityTeam(props.communityTeam);
    setCommunityEmployee(props.communityEmployee);
  }, [])


  const formatDate = (date) => {
    //  var d = new Date(date),
    var d = new Date(date.replace(' ', 'T')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
    // return [year, month, day].join('-');
  }


  const handleRenameFolder = useCallback((val) => {
    setCreateError('');
    setNode(val);
    setFolderName(val.name)
    setRenameModalVisible(true)
  }, [])

  const renameFolder = async () => {
    try {
      setCreateError('');
      if (foldername && nodeVal) {
        let data = {
          name: foldername,
        }
        const response = await new ApiHandler().renameFolder(nodeVal.id, data)
        console.log(response)
        setRenameModalVisible(false)
        props.getMyFolders();
      }
      else {
        setCreateError('Please input folder name');
      }
    } catch (error) {
      if (Platform.OS !== "web") {
        Toast.show({
          type: 'error',
          position: 'bottom',
          text1: error,
        })
      } else {
        console.log(error);
      }
    }
  }

  const deleteFolderConfirm = async (item) => {
    setDeleteNode(item);
    setDeleteModalVisible(true);
  }


  const deleteFolder = async () => {
    try {
      if (deleteNode) {
        const response = await new ApiHandler().deleteFolder(deleteNode.id)
        setDeleteNode(null);
        setDeleteModalVisible(false);
        props.getMyFolders();
      }
      else {
        setDeleteModalVisible(false);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Nothing is selected',
        })
      }
    } catch (error) {
      setDeleteModalVisible(false);
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getFolderInfoAsync = async (id) => {
    try {
      setloading(true);
      const response = await new ApiHandler().showFolders(id);
      if (response?.data) {
        setloading(false);
        setFolderInfo(response.data);
        setInfoModalVisible(true);
      }

    } catch (error) {
      if (Platform.OS !== "web") {
        Toast.show({
          type: 'error',
          position: 'bottom',
          text1: error,
        })
      } else {
        console.log(error);
      }
    }
  }

  const getFolderListToMoveAsync = async (id) => {
    try {
      setloading(true);
      // let requestData = JSON.stringify([id]);
      var requestData = id;
      const response = await new ApiHandler().folderListToMoveOn(id);
      console.log('moveto', response?.data)
      if (response?.data) {
        setloading(false);
        setFoldersToMove(response.data);
      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }


  const handleMoveToFolder = useCallback(async (id) => {
    setMoveToModalVisible(true)
    setSelectedFolderToMove(id);
    getFolderListToMoveAsync(id);
  }, [])


  const moveFolderAsync = async (id) => {
    try {
      if (getSelectedFolderToMove && isHighlighted && isHighlighted.id) {
        setloading(true);
        let dataTomove = {
          node_id: getSelectedFolderToMove,
          move_to: isHighlighted.id
        }
        const response = await new ApiHandler().moveFolder(dataTomove);
        console.log(response)
        if (response?.success) {
          setloading(false);
          setFoldersToMove(null);
          setHighlighted(null);
          setSelectedFolderToMove(null);
          setMoveToModalVisible(false);
          props.getMyFolders();
        }
      }


    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }


  const getSharedMembersAsync = async (nodeId) => {
    try {
      setloading(true);
      const response = await new ApiHandler().getSharedMemebers(nodeId);
      setloading(false);
      if (response?.data) {
        let responseData = response.data.data;
        let employees = responseData && responseData.employees ? responseData.employees.map((e) => e.id) : [];
        let groups = responseData && responseData.groups ? responseData.groups.map((g) => g.id) : [];
        let teams = responseData && responseData.teams ? responseData.teams.map((t) => t.id) : [];
        setSelectedGroup(groups);
        setSelectedTeam(teams);
        setSelectedEmployee(employees);

      }

    } catch (error) {
      // Toast.show({
      //   type: 'error',
      //   position: 'bottom',
      //   text1: error,
      // })
    }
  }

  const getSharedFolderFileInfoAsync = async (id) => {
    try {
      setloading(true);
      const response = await new ApiHandler().showFolders(id);
      console.log(response)
      if (response?.data) {
        setloading(false);
        const isSharedWithEntireWorkspace = response?.data.shared.length > 0 ? true : false;
        setSharedWithEntireWorkspace(isSharedWithEntireWorkspace);
      }

    } catch (error) {
      console.log('error')
    }
  }

  const getShareModalAsync = async (item) => {
    getSharedFolderFileInfoAsync(item.id);
    setSharedWithEntireWorkspace(false);
    setCreateError('')
    setIsNodeShared(false);
    setNodeIdToshare(item.id);
    setShareModalVisible(true)
    if (item.is_shared) {
      setIsNodeShared(true);
      getSharedMembersAsync(item.id);
    }
  }

  const shareFolderAsync = async (id) => {
    try {
      setCreateError('')
      if (isNodeShared) {
        setloading(true);
        const dataToshare = {
          "share":
          {
            "App\\Employee": selectedEmployee,
            "App\\Team": selectedTeam,
            "App\\Group": selectedGroup
          },
          "workspace": sharedWithEntireWorkspace == true ? 1 : 0,
          "update": 1
        }

        const nodeId = nodeIdToshare;
        const response = await new ApiHandler().shareFolder(nodeId, dataToshare);
        console.log(response)
        if (response?.data.success) {
          setloading(false);
          setShareModalVisible(false)
          setIsNodeShared(false);

          setSelectedGroup([]);
          setSelectedTeam([]);
          setSelectedEmployee([]);

          props.getMyFolders();
        }
        else {
          setloading(false);
          setCreateError(response?.data.message)
        }

      }
      else {
        if (selectedGroup.length || selectedTeam.length || selectedEmployee.length || sharedWithEntireWorkspace == true) {
          setloading(true);
          const dataToshare = {
            "share":
            {
              "App\\Employee": selectedEmployee,
              "App\\Team": selectedTeam,
              "App\\Group": selectedGroup
            },
            "workspace": sharedWithEntireWorkspace == true ? 1 : 0,
          }


          const nodeId = nodeIdToshare;
          const response = await new ApiHandler().shareFolder(nodeId, dataToshare);

          if (response?.data.success) {
            setloading(false);
            setShareModalVisible(false)
            setIsNodeShared(false);

            setSelectedGroup([]);
            setSelectedTeam([]);
            setSelectedEmployee([]);

            props.getMyFolders();
          }
          else {
            setloading(false);
            setCreateError(response?.data.message)
          }
        }
        else {
          setCreateError('Please Select Employee Team or Group')
        }
      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }



  const handleDownloadFile = (item) => {
    console.log(item)
    console.log(apiUrl + '/' + item.link)
    var fileName = item.link.substring(item.link.lastIndexOf('/') + 1);
    var a = document.createElement('a');
    a.href = item.link;
    a.target = '_black'
    a.download = fileName;
    a.click();
  }

  const copyToClipBoard = (link) => Clipboard.setString(link)

  return (
    <>
      <ProgressBar indeterminate={true} visible={loading} />

      <Menu style={{ paddingTop: 10 }} onSelect={value => console.log(value)}>
        <MenuTrigger customStyles={props.padded && {
          triggerWrapper: {
            paddingHorizontal: 5,
          }
        }}>
          <Icon
            color={'#000'}
            icon={'ellipsis'}
            width={18}
            height={18}
          />
        </MenuTrigger>

        <MenuOptions optionsContainerStyle={[{ borderRadius: 4, width: 150, paddingTop: 15, paddingBottom: 15, paddingLeft: 20, boxShadow: "rgb(0 0 0 / 30%) 3px 4px 10px" }, layout === Layouts.list ? { marginLeft: -20, marginTop: 25, marginRight: -20, } : { marginTop: 25, marginLeft: -150 }]}>

          {props.item.owner_id == props.auth.member.id &&
            <MenuOption value={props.item.id} onSelect={(value) => handleMoveToFolder(value)}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.2 }}>
                  <Icon
                    color={'#000'}
                    icon={'movedto'}
                    width={18}
                    height={18}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.8 }}>
                  <Text>Move to   </Text>
                </View>
              </View>
            </MenuOption>
          }

          {props.item.owner_id == props.auth.member.id &&
            <MenuOption value={props.item} onSelect={(value) => handleRenameFolder(value)} >
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.2 }}>
                  <Icon
                    color={'#000'}
                    icon={'rename'}
                    width={18}
                    height={18}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.8 }}>
                  <Text>Rename   </Text>
                </View>
              </View>
            </MenuOption>
          }

          {props.item.type != 'folder' &&
            <MenuOption value={props.item} onSelect={(value) => handleDownloadFile(value)}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.2 }}>
                  <Icon
                    color={'#000'}
                    icon={'downloadicon'}
                    width={18}
                    height={18}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.8 }}>
                  <Text>Download   </Text>
                </View>
              </View>
            </MenuOption>
          }

          {props.item.owner_id == props.auth.member.id &&
            <MenuOption value={props.item} onSelect={(val) => deleteFolderConfirm(val)}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.2 }}>
                  <Icon
                    color={'#000'}
                    icon={'deleteicon'}
                    width={18}
                    height={18}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.8 }}>
                  <Text>Delete   </Text>
                </View>
              </View>
            </MenuOption>
          }

          {props.item.owner_id == props.auth.member.id &&
            <MenuOption value={props.item} onSelect={(val) => getShareModalAsync(val)}>
              <View style={{ flexDirection: "row" }}>
                <View style={{ flex: 0.2 }}>
                  <Icon
                    color={'#000'}
                    icon={'shareicon'}
                    width={18}
                    height={18}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.8 }}>
                  <Text>Share   </Text>
                </View>
              </View>
            </MenuOption>
          }
          <MenuOption value={props.item.id} onSelect={(val) => getFolderInfoAsync(val)}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flex: 0.2 }}>
                <Icon
                  color={'#000'}
                  icon={'informationicon'}
                  width={18}
                  height={18}
                  style={{ marginTop: 15 }}
                />
              </View>
              <View style={{ flex: 0.8 }}>
                <Text>Information   </Text>
              </View>
            </View>
          </MenuOption>
        </MenuOptions>
      </Menu>


      {/* .............Modals.......... */}

      <FloatingModal visible={isRenameModalVisible}>
        <View style={{ flex: 1 }}>
          <View style={{ width: '100%', flexDirection: 'row', paddingBottom: 10 }}>
            <HeaderTitleCreateFolder><Text>{nodeVal && nodeVal.type == 'folder' ? 'Rename Folder' : 'Rename File'}</Text></HeaderTitleCreateFolder>
            <TouchableOpacity onPress={() => setRenameModalVisible(false)}>
              <Icon
                icon="close"
                width={18}
                height={18}
                color="black"
              />
            </TouchableOpacity>
          </View>
          <View style={{ flex: 2, justifyContent: 'center' }}>
            <TextInput
              style={{ backgroundColor: '#fff', paddingHorizontal: 0, paddingVertical: 0 }}
              placeholder="Folder name"
              onChangeText={(v) => setFolderName(v)}
              value={foldername}
            />
            <Text style={{ color: "red" }}>{errorTextCreate}</Text>
          </View>

          <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
            <ClearButton style={styles.createFolderSubmit} onPress={() => renameFolder()}>
              <ButtonText color="white">Rename</ButtonText>
            </ClearButton>

          </View>
        </View>
      </FloatingModal>

      <FloatingModal visible={isDeleteModalVisible}>
        <View style={{ flex: 1 }}>
          <View style={{ width: '100%', flexDirection: 'row', paddingBottom: 10 }}>
            <HeaderTitleCreateFolder><Text>{deleteNode && deleteNode.type == 'folder' ? 'Delete Folder' : 'Delete File'}</Text></HeaderTitleCreateFolder>
            <TouchableOpacity onPress={() => setDeleteModalVisible(false)}>
              <Icon
                icon="close"
                width={18}
                height={18}
                color="black"
              />
            </TouchableOpacity>
          </View>
          <View style={{ flex: 2, justifyContent: 'center' }}>
            <HeaderSubTitleCreateFolder><Text style={{ width: '100%' }}>{deleteNode && deleteNode.type == 'folder' ? 'Are you sure you want to delete this folder?' : 'Are you sure you want to delete this file?'}</Text></HeaderSubTitleCreateFolder>
            <Text style={{ color: "red" }}>{errorTextCreate}</Text>
          </View>
          <View style={{ flex: 1, justifyContent: 'center' }}>
            <ClearButton full onPress={() => deleteFolder()}>
              <ButtonText color="white">Delete</ButtonText>
            </ClearButton>
          </View>
        </View>
      </FloatingModal>

      <FloatingModal visible={isInfoModalVisible}>
        <View style={{ flex: 1 }}>
          <View style={{ width: '100%', flexDirection: 'row', paddingBottom: 10 }}>
            <HeaderTitleInfoFolder><Text style={{ textAlign: 'left', width: '100%' }}>Information</Text></HeaderTitleInfoFolder>
            <TouchableOpacity onPress={() => setInfoModalVisible(false)}>
              <Icon
                icon="close"
                width={18}
                height={18}
                color="black"
              />
            </TouchableOpacity>
          </View>
          <View style={{ flex: 0.8, justifyContent: 'center' }}>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Created: {getFolderInfo && formatDate(getFolderInfo.created_at)}</Text></HeaderSubTitleInfo>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Last updated: {getFolderInfo && formatDate(getFolderInfo.last_modified_at)}</Text></HeaderSubTitleInfo>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Owner: {getFolderInfo && getFolderInfo.owner.first_name} {getFolderInfo && getFolderInfo.owner.last_name}</Text></HeaderSubTitleInfo>
            {props.item.type !== 'folder' &&
              // <HeaderSubTitleInfo>
              <View style={{ flexDirection: "row", paddingTop: 10, paddingLeft: 3, width: '100%' }}>
                <View style={{ flex: 1 }}>
                  <View style={{ width: '100%' }}>
                    <Text style={{ flexDirection: 'column' }}>
                      <TouchableOpacity ><Text > File:</Text></TouchableOpacity>  <TouchableOpacity onPress={() => Linking.openURL(props.item.link)} style={{ marginTop: Platform.OS == 'android' ? -3 : 0, padding: 0 }}><Text  >{props.item && props.item.name.length <= 10 ? `${props.item.name}` : `${props.item.name.substring(0, 10)}(..)`}.{props.item && props.item.extension}</Text></TouchableOpacity>      <TouchableOpacity onPress={() => copyToClipBoard(props.item.link)} style={{ flexDirection: 'column', marginTop: Platform.OS == 'android' ? -2 : 0, padding: 0 }}><Icon color={'#000'} icon={'copy'} width={15} height={20} style={{ marginTop: 5 }} /></TouchableOpacity>
                    </Text>
                  </View>
                </View>
              </View>
            }
          </View>
        </View>
      </FloatingModal>

      {/* <SimpleModal isVisible={isInfoModalVisible}>
        <CreateFolderContainer style={{ height: props.item.type != 'folder' ? 250 : 200 }}>
          <HeaderCreateFolder>
            <CloseButtonCreateFolder onPress={() => setInfoModalVisible(false)}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleInfoFolder><Text style={{ textAlign: 'left', width: '100%' }}>Information</Text></HeaderTitleInfoFolder>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Created: {getFolderInfo && formatDate(getFolderInfo.created_at)}</Text></HeaderSubTitleInfo>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Last updated: {getFolderInfo && formatDate(getFolderInfo.last_modified_at)}</Text></HeaderSubTitleInfo>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Owner: {getFolderInfo && getFolderInfo.owner.first_name} {getFolderInfo && getFolderInfo.owner.last_name}</Text></HeaderSubTitleInfo>
            {props.item.type != 'folder' &&
              // <HeaderSubTitleInfo>
              <View style={{ flexDirection: "row", paddingTop: 10, paddingLeft: 3, width: '100%' }}>
                <View style={{ flex: 1 }}>
                  <View style={{ width: '100%' }}>
                    <Text style={{ flexDirection: 'column' }}>
                      <TouchableOpacity ><Text > File:</Text></TouchableOpacity>  <TouchableOpacity onPress={() => Linking.openURL(props.item.link)} style={{ marginTop: Platform.OS == 'android' ? -3 : 0, padding: 0 }}><Text  >{props.item && props.item.name.length <= 10 ? `${props.item.name}` : `${props.item.name.substring(0, 10)}(..)`}.{props.item && props.item.extension}</Text></TouchableOpacity>      <TouchableOpacity onPress={() => copyToClipBoard(props.item.link)} style={{ flexDirection: 'column', marginTop: Platform.OS == 'android' ? -2 : 0, padding: 0 }}><Icon color={'#000'} icon={'copy'} width={18} height={18} style={{ marginTop: 5 }} /></TouchableOpacity>
                    </Text>
                  </View>
                </View>
              </View>

              // </HeaderSubTitleInfo>
            }
          </HeaderCreateFolder>

        </CreateFolderContainer>


      </SimpleModal> */}



      <FloatingModal visible={isMoveToModalVisible}>
        <View>
          <ProgressBar indeterminate={true} visible={loading} />
          <View style={{ width: '100%', flexDirection: 'row', paddingBottom: 10 }}>
            <HeaderTitleCreateFolder><Text>Move to</Text></HeaderTitleCreateFolder>
            <TouchableOpacity onPress={() => setMoveToModalVisible(false)}>
              <Icon
                icon="close"
                width={18}
                height={18}
                color="black"
              />
            </TouchableOpacity>
          </View>
          <ScrollView style={{ width: '100%', height: 150, marginTop: 10, paddingVertical: 20 }}>
            <FlatList
              data={getFoldersToMove}
              ListHeaderComponent={() => (getFoldersToMove && !getFoldersToMove.length ?
                <Text style={{ textAlign: 'center', padding: 50, backgroundColor: '#fff', marginRight: 20 }}>No folders found</Text>
                : null)}
              keyExtractor={item => item.id}
              contentContainerStyle={{
                flex: 1,
                marginBottom: 20,
                justifyContent: 'center'

              }}
              renderItem={({ item, index, separators }) => (
                <TouchableHighlight
                  key={item.id}
                  style={{ backgroundColor: isHighlighted && isHighlighted.id == item.id ? 'lightgrey' : 'transparent', marginBottom: 10, color: '#fff' }}
                  onPress={() => isHighlighted && isHighlighted.id == item.id ? setHighlighted(null) : setHighlighted({ id: item.id })}

                >
                  <View >
                    {item.is_shared
                      ? <Text style={{ color: isHighlighted && isHighlighted.id == item.id ? '#000' : '#000' }}><Avatar.Icon color={'#000'} style={{ backgroundColor: 'transparent' }} size={25} height={25} icon="folder-account" />  {item.name}</Text>
                      : <Text style={{ color: isHighlighted && isHighlighted.id == item.id ? '#000' : '#000' }}><Avatar.Icon color={'#000'} style={{ backgroundColor: 'transparent' }} size={25} height={25} icon="folder" />  {item.name}</Text>
                    }
                  </View>
                </TouchableHighlight>
              )}
            />
          </ScrollView>
          <View style={{ paddingVertical: 20, alignItems: 'flex-end', justifyContent: 'center' }}>
            <ClearButton full onPress={() => moveFolderAsync()}>
              <ButtonText color="white">Move</ButtonText>
            </ClearButton>
          </View>
        </View>
      </FloatingModal>




      <SimpleModal isVisible={isShareModalVisible} >
        {/* <CreateFolderContainer style={{ width: width - (width / 2 + 100), height: height - 280, zIndex: 50, paddingBottom: 0 }}> */}
        <CreateFolderContainer style={{ width: width - (width / 2 + 100), height: height - 280, flex: resizeModelHeight, zIndex: 50, paddingBottom: 0 }}>
          <ProgressBar indeterminate={true} visible={loading} />
          <HeaderCreateFolder>
            <CloseButtonCreateFolder onPress={() => { setShareModalVisible(false); }} style={{ right: 0 }}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleCreateFolder><Text>Sharing</Text></HeaderTitleCreateFolder>
            <HeaderSubTitleCreateFolder style={{ textAlign: 'center', color: '#6D6D6D' }}><Text style={{ width: '100%', textAlign: 'center' }}>Manage sharing with Members, Teams, and Groups.</Text></HeaderSubTitleCreateFolder>

          </HeaderCreateFolder>

          <View
            style={{
              flexDirection: "row",
              paddingBottom: 10,
              marginTop: 20,
            }}
          >
            <Tabs horizontal={true} style={{ height: isMobile ? 20 : 20, alignItems: 'center', justifyContent: 'center', flex: 1 }}>

              <Tab onPress={() => { setTab('group') }} >
                <TabTitle selected={tab === 'group'} isMobile={true} >Group</TabTitle>
                <View style={{ backgroundColor: tab === 'group' ? '#F05343' : null, marginLeft: 25 }}>
                </View>
              </Tab>

              <Tab onPress={() => { setTab('team') }}>
                <TabTitle selected={tab === 'team'} isMobile={true} >Team</TabTitle>
                <View style={{ height: 2, backgroundColor: tab === 'team' ? '#F05343' : null, marginLeft: 25 }}></View>
              </Tab>

              <Tab onPress={() => { setTab('memeber') }}>
                <TabTitle selected={tab === 'memeber'} isMobile={true} >Member</TabTitle>
                <View style={{ height: 2, backgroundColor: tab === 'memeber' ? '#F05343' : null, marginLeft: 25 }}></View>
              </Tab>

            </Tabs>
          </View>

          <View
            style={{
              flexDirection: "row",
              paddingBottom: 20
            }}
          >
            {tab === 'group' &&
              <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, width: width - 100, zIndex: 100 }}>
                <View style={dropdownStyles.container}>


                  <SearchableDropDown
                    items={communityGroup}
                    selectedItems={selectedGroup}
                    defaultIndex={2}
                    onRemoveItem={item => {
                      const items = selectedGroup.filter(file => file.id !== item.id);
                      setSelectedGroup(items);
                    }}
                    onItemSelect={item => {
                      const items = selectedGroup;
                      items.push(item);
                      setSelectedGroup(items);

                      if (selectedGroup.length > 5) setResizeModelHeight(50)
                      else if (selectedTeam.length > 5) setResizeModelHeight(50)
                      else if (selectedEmployee.length > 5) setResizeModelHeight(50)
                      else setResizeModelHeight(200)
                    }}
                    chip={true}
                    multi
                    resetValue={false}
                    itemStyle={{
                      padding: 10,
                      backgroundColor: '#fff',
                      borderColor: '#bbb',
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                    }}
                    listProps={
                      {
                      }
                    }
                    textInputProps={
                      {
                        placeholder: "Search Groups",
                        underlineColorAndroid: "transparent",
                        style: {
                          padding: 12,
                          borderWidth: 1,
                          width: "100%",
                          borderColor: '#ccc',
                          borderRadius: 5,
                        },
                        // onTextChange: text => alert(text)
                      }
                    }
                  />
                </View>
              </View>
            }

            {tab === 'team' &&
              <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, width: width - 100, zIndex: 100 }}>
                <View style={dropdownStyles.container}>
                  <SearchableDropDown
                    items={communityTeam}
                    selectedItems={selectedTeam}
                    defaultIndex={2}
                    onRemoveItem={item => {
                      const items = selectedTeam.filter(file => file.id !== item.id);
                      setSelectedTeam(items);
                    }}
                    onItemSelect={item => {
                      const items = selectedTeam;
                      items.push(item);
                      setSelectedTeam(items);

                      if (selectedGroup.length > 5) setResizeModelHeight(50)
                      else if (selectedTeam.length > 5) setResizeModelHeight(50)
                      else if (selectedEmployee.length > 5) setResizeModelHeight(50)
                      else setResizeModelHeight(200)
                    }}
                    chip={true}
                    multi
                    resetValue={false}
                    itemStyle={{
                      padding: 10,
                      backgroundColor: '#fff',
                      borderColor: '#bbb',
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                    }}
                    listProps={
                      {
                      }
                    }
                    textInputProps={
                      {
                        placeholder: "Search Teams",
                        underlineColorAndroid: "transparent",
                        style: {
                          padding: 12,
                          borderWidth: 1,
                          width: "100%",
                          borderColor: '#ccc',
                          borderRadius: 5,
                        },
                        // onTextChange: text => alert(text)
                      }
                    }
                  />
                </View>
              </View>
            }

            {tab === 'memeber' &&
              <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, width: width - 100, zIndex: 100 }}>
                <View style={dropdownStyles.container}>
                  <SearchableDropDown
                    items={communityEmployee.map(employee => ({ ...employee, name: formatUserName(employee.first_name, employee.last_name) }))}
                    selectedItems={selectedEmployee}
                    defaultIndex={2}
                    onRemoveItem={item => {
                      const items = selectedEmployee.filter(file => file.id !== item.id);
                      setSelectedEmployee(items);
                    }}
                    onItemSelect={item => {
                      const items = selectedEmployee;
                      items.push(item);
                      setSelectedEmployee(items);

                      if (selectedGroup.length > 5) setResizeModelHeight(50)
                      else if (selectedTeam.length > 5) setResizeModelHeight(50)
                      else if (selectedEmployee.length > 5) setResizeModelHeight(50)
                      else setResizeModelHeight(200)
                    }}
                    chip={true}
                    multi
                    resetValue={false}
                    itemStyle={{
                      padding: 10,
                      backgroundColor: '#fff',
                      borderColor: '#bbb',
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                    }}
                    listProps={
                      {
                      }
                    }
                    textInputProps={
                      {
                        placeholder: "Search Members",
                        underlineColorAndroid: "transparent",
                        style: {
                          padding: 12,
                          borderWidth: 1,
                          width: "100%",
                          borderColor: '#ccc',
                          borderRadius: 5,
                        },
                        // onTextChange: text => alert(text)
                      }
                    }
                  />
                </View>
              </View>
            }

          </View>

          <Text style={{ color: "red", textAlign: 'center' }}>{errorTextCreate}</Text>
          <View style={{ width: '100%', padding: 0, margin: 0 }}>
            <CheckBox
              center={true}
              checkedColor={'#000'}
              containerStyle={{ backgroundColor: 'transparent', borderWidth: 0, padding: 0, margin: 0 }}
              wrapperStyle={{ padding: 0, margin: 0 }}
              textStyle={{ marginTop: -4 }}
              //containerStyle={{width:'100%',backgroundColor:'transparent',borderWidth:0,padding:0,margin:0,marginLeft:-1}}
              // wrapperStyle={{padding:0,width:'100%',margin:0}}
              title="Share with entire workspace"
              checked={sharedWithEntireWorkspace}
              onPress={() => setSharedWithEntireWorkspace(!sharedWithEntireWorkspace)}
            />
          </View>
          <View style={styles.createfolderfooter}>

            <TouchableOpacity style={styles.createFolderSubmit} onPress={() => shareFolderAsync()} >
              <Text style={{ fontSize: 16, color: '#fff' }}>Share</Text>
            </TouchableOpacity>

          </View>
        </CreateFolderContainer>
      </SimpleModal>

    </>
  )
}

export default SideMenusWeb

const dropdownStyles = StyleSheet.create({
  container: { flex: 1, width: '100%' },
  dropdown: {
    height: 50,
    backgroundColor: 'transparent',
    borderBottomColor: 'gray',
    borderBottomWidth: 0.5,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 16,
  },
  icon: {
    marginRight: 5,
  },
  selectedStyle: {
    backgroundColor: '#F05343',
    borderColor: '#F05343',
    color: '#fff',
    //borderRadius: 12,
  },
  selectedTextStyle: {
    fontSize: 14,
    color: '#fff'
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textItem: {
    flex: 1,
    fontSize: 16,
  },
});

