import { Dimensions } from 'react-native'
import styled from 'styled-components/native'
const { width } = Dimensions.get('screen');

export const Input = styled.TextInput`
  padding: 15px;
  width: ${props => props.width ?? width / 1.45}px;
  background-color: white;
`

export const InputWeb = styled.TextInput`
    padding: 15px;
    outline-style: none;
`