import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'
import { SimpleModal, Icon } from '@/components'
import {
  LeaveCard,
  CloseLeaveModalButton,
  LeaveText,
  ButtonsContainer,
  LeaveButton,
  LeaveButtonText,
} from './styles'

const LeaveModalWeb = ({
  isLeaveWebVisible,
  setLeaveWebVisible,
  onClose,
  type = 'leave',
  screenType,
}) => {
  const leave = useCallback(() => {
    onClose()
    setLeaveWebVisible(false)
  }, [onClose])

  if (!isMobile) {
    return (
      <SimpleModal isVisible={isLeaveWebVisible}>
        <LeaveCard>
          <CloseLeaveModalButton onPress={() => setLeaveWebVisible(false)}>
            <Icon
              icon="close"
              color={theme.basics.brand}
              height={14}
              width={14}
            />
          </CloseLeaveModalButton>
          <LeaveText>
            Are you sure you want to {(type === 'leave') ? 'leave' : 'delete'} the {screenType}?
          </LeaveText>
          <ButtonsContainer>
            <LeaveButton cancel onPress={() => setLeaveWebVisible(false)}>
              <LeaveButtonText cancel>
                Cancel
              </LeaveButtonText>
            </LeaveButton>
            <LeaveButton onPress={leave}>
              <LeaveButtonText>
                {(type === 'leave') ? 'Leave' : 'Delete'} the {screenType}
              </LeaveButtonText>
            </LeaveButton>
          </ButtonsContainer>
        </LeaveCard>
      </SimpleModal>
    )
  }
  return null
}

LeaveModalWeb.propTypes = {
  isLeaveWebVisible: PropTypes.bool.isRequired,
  setLeaveWebVisible: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

export default LeaveModalWeb

