
import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
    PageHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 15
    },
    PageTitle: {
        fontFamily: 'WorkSans_400Regular',
        flexWrap: 'wrap',

        ...Platform.select({
            ios: {
                fontSize: 25,
            },
            android: {
                fontSize: 25,
            },
            web: {
                fontSize: 40,
            }
        })
    }
})

export default styles