import React from 'react'
import PropTypes from 'prop-types'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { getUser, getMember } from '@/redux/selectors/auth'
import { useSelector } from 'react-redux'

import PersonalInfo from './PersonalInfo'
import LastActivities from './LastActivities'
import { isMobile, ScreenSize, useScreenSize } from '@/styles'
import { TopTabBar } from '@/components'

import {
  Container,
  ContentWrapper,
  ProfileWrapper,
  LastActivitiesWrapper,
} from './styles'

const Tab = createMaterialTopTabNavigator()

export default function Profile({ route }) {
  let id = ""
  if (route?.params?.id) {
    id = route.params?.id + ""
    delete route?.params?.id;
  } else {
    const user = useSelector(getMember)
    id = user?.id
  }
  const screenSize = useScreenSize()

  return (
    <Container>
      {isMobile || !isMobile && screenSize <= ScreenSize.Medium ? (
        // <Tab.Navigator
        //   independent={true}
        //   initialRouteName="MyProfile"
        //   tabBar={(props) => <TopTabBar {...props} />}
        // >
        //   <Tab.Screen
        //     name="MyProfile"
        //     component={() => <PersonalInfo id={id} />}
        //     options={{
        //       tabBarLabel: 'Personal information',
        //     }}
        //   />
        //   {/* <Tab.Screen
        //     name="LastActivities"
        //     component={() => <LastActivities id={id} />}
        //     options={{ tabBarLabel: 'Last activities' }}
        //   /> */}
        // </Tab.Navigator>
        <PersonalInfo id={id} />
      ) : (
        <ProfileWrapper>
          <PersonalInfo id={id} />
        </ProfileWrapper>
      )}
    </Container>
  )
}

Profile.propTypes = {
  route: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.number })
  })
}
