import ApiHandler from '@/api/ApiHandler';
import React, { useContext, useEffect, useState } from 'react'
import { Dimensions, StyleSheet, Platform, View, Text, Image, TouchableOpacity } from 'react-native';
import VideoContext from '../Meeting/context';
import { MaterialIcons } from '@expo/vector-icons'
import * as Twilio from 'react-native-twilio-video-webrtc';
import { formatUserName } from '@/utils/format';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';



const TwilioVideoParticipantView = Platform.OS == 'web' ? View : Twilio.TwilioVideoParticipantView;

const { width, height } = Dimensions.get('screen');

const RemoteVideo = ({ activeSpeaker, trackSid, trackIdentifier, user, tile_width }) => {
    const [userInfo, setUserInfo] = useState();
    const { getParticipant, addParticipant } = useContext(VideoContext);
    const color = UseBrandingAccentColor();

    useEffect(() => {
        const identity = user?.identity;
        const _user = getParticipant(identity);
        if (!_user) {
            new ApiHandler().getParticipant({ id: identity }).then(res => {
                const data = res.data?.data ?? {};
                addParticipant(identity, data);
                setUserInfo(data);
            })
        } else {
            setUserInfo(_user);
        }
    }, [user])

    return (
        <View
            key={trackSid}
            style={{
                marginVertical: 10,
                backgroundColor: '#1b1b1b',
                alignSelf: 'center',
                alignItems: 'center',
                justifyContent: "center",
                borderRadius: 8,
                overflow: 'hidden',
                width: '95%',
                aspectRatio: 16 / 9,
                backgroundColor: '#202124',
            }}
        >
            {activeSpeaker && (
                <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', width: '100%', zIndex: 9, justifyContent: 'flex-end', paddingHorizontal: 15, paddingVertical: 10 }}>
                    <TouchableOpacity
                        style={{
                            width: 30,
                            height: 30,
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                            borderRadius: 50,
                            backgroundColor: '#ea4335',
                            borderColor: "#ea4335"
                        }}
                    >
                        <MaterialIcons name={"multitrack-audio"} size={18} color="#FFFFFF" />
                    </TouchableOpacity>
                </View>
            )}

            {user?.videoEnabled || trackIdentifier.isScreenShare ? (
                <TwilioVideoParticipantView
                    style={{
                        //objectFit: 'cover',
                        aspectRatio: 16 / 9,
                        height: '100%',
                        width: '100%',
                    }}
                    scaleType="fit"
                    key={trackSid}
                    trackIdentifier={trackIdentifier}
                />
            ) : (
                <View style={[styles.remoteVideo, { width: tile_width, aspectRatio: 16 / 9, alignItems: 'center', justifyContent: 'center' }]}>
                    {userInfo?.avatar_url ? (
                        <Image source={{ uri: userInfo?.avatar_url }} style={{ width: 60, height: 60, borderRadius: 50 }} />
                    ) : (
                        <View style={{ width: 60, height: 60, backgroundColor: 'rgba(255,255,255,0.1)', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}>
                            <Text style={{ fontSize: 40, color: 'white' }}>{userInfo?.avatar_initials}</Text>
                        </View>
                    )}
                </View>
            )}

            <View style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 15,
                paddingVertical: 10,
                alignItems: 'center'
            }}>
                <Text style={{
                    color: '#FFFFFF',
                    fontFamily: 'WorkSans_400Regular',
                    fontSize: 15,
                    fontWeight: '600'
                }}>
                    {formatUserName(userInfo?.first_name, userInfo?.last_name)} {trackIdentifier.isScreenShare && "(Presentation))"}
                </Text>

                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {!user?.audioEnabled && (
                        <TouchableOpacity
                            style={{
                                width: 30,
                                height: 30,
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                borderRadius: 50,
                                //marginHorizontal: 15,
                                backgroundColor: !user?.audioEnabled ? '#ea4335' : '#3c4043',
                                borderColor: !user?.audioEnabled ? "#ea4335" : 'white'
                            }}
                        >
                            <MaterialIcons name={user?.audioEnabled ? "mic" : "mic-off"} color="#FFFFFF" size={15} />
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        </View>
    )
}

export default RemoteVideo;

const styles = StyleSheet.create({
    remoteVideo: {
        // marginTop: 20,
        // marginLeft: 10,
        // marginRight: 10,
        // width: width / 1.2,
        // maxWidth: width / 1.2,
        aspectRatio: 16 / 9
    },
})