import React, { useState, useCallback, useEffect } from 'react'
import {
    ScrollView,
    Text,
    KeyboardAvoidingView,
    View,
    TouchableOpacity,
    FlatList,
    StyleSheet,
    Dimensions,
} from "react-native";
import Hyperlink from 'react-native-hyperlink'
import {
    Container,
    InfoContainer,
    AuthorName,
    AuthorOffice,
    CommentInfoContainer,
    CommentText,
    TimeAgo,
    LikesContainer,
    LikeContainer,
    LikeButton,
    ButtonText,
    ButtonTextLike,
    ButtonNavigation,
    MenuContainer,
} from './styles'
import { useNavigation } from '@react-navigation/native'
// Add Actions - replace 'Your' with whatever your reducer is called :)
// import YourActions from '../Redux/YourRedux'
import * as brandSelectors from '@/redux/selectors/branding'
import { getMember } from '@/redux/selectors/auth'
import ApiHandler from '@/api/ApiHandler'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from '@/styles'
import { MentionsInput, Mention } from 'react-mentions'
const { width, height } = Dimensions.get("window");
const screenWidth = width < height ? width : height;
const screenHeight = width < height ? height : width;


const MentionsTextView = ({ str, feedType, startword, endword, innerFormatStart, innerFormatEnd }) => {
    const navigation = useNavigation()
    const user = useSelector(getMember);
    const branding = useSelector(brandSelectors.getSettings)
    const [mentionTextColor, setMentionTextColor] = React.useState('rgb(240, 83, 67)');

    useEffect(() => {
        if (branding && branding.color_scheme_accents) setMentionTextColor(branding.color_scheme_accents);
    }, [])

    const isFeedTypePost = feedType === 'App\\Post';


    if (str && str.length) {
        let resultString = '';
        let string = str.split(startword).map(el => el.split(endword)).reduce((acc, curr) => acc.concat(curr))
        return (
            <View style={{ flex: 1 }}>
                {
                    string.map((substr) => {
                        // let trimmedStr = substr.replaceAll("^\"|\"$", "");
                        let trimmedStr = substr.replace(/(^"|"$)/g, '')
                        let strArray = trimmedStr.split(innerFormatStart).map(el => el.split(innerFormatEnd)).reduce((acc, curr) => acc.concat(curr));
                        if (strArray.length >= 3) {
                            let mentionArray = strArray[1].split(',');
                            return (
                                <View style={{ paddingLeft: isMobile ? 15 : 0 }}>
                                    <Text>
                                        <Text
                                            style={{ cursor: 'pointer' }}
                                            onPress={() => {
                                                navigation.navigate('Profile', { id: mentionArray[0] })
                                            }}
                                            style={{
                                                color: mentionTextColor,
                                                fontWeight: 'bold',
                                                lineHeight: 20,

                                            }}
                                        >
                                            @{mentionArray[1].replace(/['"]+/g, '')}
                                        </Text>
                                    </Text>
                                </View>
                            )
                        }
                        else {
                            return (
                                <Hyperlink
                                    linkDefault={isFeedTypePost ? true : false}
                                    linkStyle={{
                                        color: isFeedTypePost ? mentionTextColor : ""
                                    }}
                                >
                                    <Text
                                        style={{
                                            lineHeight: 20,
                                            paddingLeft: isMobile ? 15 : 0,
                                            color: '#000000'
                                        }}
                                    >
                                        {substr}

                                    </Text>
                                </Hyperlink>
                            )
                        }

                    })
                }
            </View>
        )

    } else {
        return (
            <Hyperlink
                linkDefault={isFeedTypePost ? true : false}
                linkStyle={{
                    color: isFeedTypePost ? mentionTextColor : ""
                }}
            >
                <Text>
                    {str}

                </Text>
            </Hyperlink>
        )
    }


}

export default MentionsTextView;

// const getUserMentions = (str, startword, endword) => {
  //   console.log('str', str)
  //   console.log('startword', startword)
  //   console.log('endword', endword)
  //   let resultString = '';
  //   let string = str.split('##').map(el => el.split('@@')).reduce((acc, curr) => acc.concat(curr))
  //   string && string.length && string.map((substr) => {
  //     let trimmedStr = substr.replaceAll("^\"|\"$", "");
  //     //let strArray = trimmedStr.split();
  //     let strArray = trimmedStr.split('[').map(el => el.split(']')).reduce((acc, curr) => acc.concat(curr))
  //     // console.log('strArray', strArray)
  //     if (strArray.length >= 3) {
  //       let mentionArray = strArray[1].split(',');

  //       // resultString += '@' + mentionArray[1].replace(/['"]+/g, '');
  //       //resultString += mentionButton(mentionArray[0], mentionArray[1]);
  //       resultString += '@' + mentionArray[1].replace(/['"]+/g, '');
  //     }
  //     else {
  //       resultString += substr;
  //     }

  //   })
  //   console.log(resultString)
  //   return resultString;

  // }