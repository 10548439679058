import React, { useEffect } from 'react'
import { Alert, Platform } from 'react-native'
import AppRoutes, { linking } from '@/routes'
import { navigationRef } from '@/routes/navigation'
import { NavigationContainer } from '@react-navigation/native'
import { Provider } from 'react-redux'
import { store, persistor } from '@/redux/store'
import { Provider as PaperProvider } from 'react-native-paper'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components/native'
import * as updates from 'expo-updates'
import { MenuProvider } from 'react-native-popup-menu'
import Toast from 'react-native-toast-message'
import { toastConfig } from '@/components/Toast'
import {
  en,
  registerTranslation,
} from 'react-native-paper-dates'
import "intl";
registerTranslation('en', en)

import * as Linking from "expo-linking";

import "intl/locale-data/jsonp/en";
if (Platform.OS === "android") {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  if (typeof (Intl).__disableRegExpRestore === "function") {
    (Intl).__disableRegExpRestore();
  }
}


import AppLoading from 'expo-app-loading'
import black from '@/styles/themes/black'
import { defaultTheme as PaperDefaultTheme } from '@/components'
import {
  useFonts,
  WorkSans_400Regular,
  WorkSans_500Medium,
  WorkSans_600SemiBold,
} from '@expo-google-fonts/work-sans'

function App() {

  let [fontsLoaded] = useFonts({
    WorkSans_400Regular,
    WorkSans_500Medium,
    WorkSans_600SemiBold,
  })

  useEffect(() => {
    updates.addListener((event) => {
      updates.checkForUpdateAsync().then((update) => {
        if (update.isAvailable) {
          updates.fetchUpdateAsync().then(async () => {
            // ... notify user of update ...
            Alert.alert(
              'Updates Available',
              'The application will be reloaded with these new updates.',
              [
                { text: 'OK', onPress: () => updates.reloadAsync() }
              ])
          })
        }
      })
    })
  }, [])

  function handleDeepLink(event) {
    const data = Linking.parse(event.url);

    console.log(">>>>>>>>>> deep_link_data", data);

  }
  useEffect(() => {
    if (Platform.OS !== "web") {
      Linking.addEventListener("url", handleDeepLink);

      Linking.getInitialURL().then(url => {

        console.log(">>>>>>> first_open", url)

      })
    }
    return () => {
      Linking.removeEventListener("url");
    };
  }, []);

  if (!fontsLoaded) return <AppLoading />

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PaperProvider theme={PaperDefaultTheme}>
          <ThemeProvider theme={black}>
            <NavigationContainer linking={linking} ref={navigationRef}>
              <MenuProvider>
                <AppRoutes />
              </MenuProvider>
            </NavigationContainer>
            <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
          </ThemeProvider>
        </PaperProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
