import styled from 'styled-components/native'
import { Headline6SemiBold, Subtitle1 } from '@/styles/fonts'
import {Platform} from 'react-native'

export const Content = styled(Subtitle1)`
	background: ${({ theme }) =>  theme.basics.background_white};
	padding: 10px;
	align-items: stretch
	height: 70px;

`
export const Container = styled.View`
background: ${({ theme }) =>  theme.basics.background_white};
	padding: 10px;
	height: 490px;
	width: ${Platform.select({ ios: `100%`,android: `100%`, web: ` 699px` })}
	align-items: center;
	margin-top:30
`

export const Actions = styled.View`
     flex-direction: ${( isMobile ) => isMobile ? 'column' : 'row'};

	margin-top: 20px;
`

export const Message = styled(Subtitle1)`
	color: ${({ theme }) => theme.basics.brand};
	margin: 0 0 20px;
	font-size: 20px;
	textAlign: center;
    lineHeight: 25;

`

export const LeaveButton = styled.TouchableOpacity`
	background-color: ${({ theme, cancel }) => cancel ? theme.basics.background_white : theme.basics.brand};
	align-items: center;
	justify-content: center;
	width: ${({ width }) => width || 190}px;
	height: 40px;
	margin: 0 8px;
	border: 1px solid ${({theme}) => theme.basics.brand};
	border-radius: 3px;
	
`

export const LeaveButtonText = styled(Headline6SemiBold)`
	width: 100%;
	text-align: center;
	color: ${({ theme, cancel }) => cancel ? theme.basics.brand : theme.basics.background_white};
`
