import React, { useState, useRef } from "react";
import { Text, TouchableOpacity, View, StyleSheet, Image, Dimensions, Platform } from "react-native";
import Swiper from "react-native-web-swiper";
import ReframeHorizontalLogo from '@/assets/brand/reframe-brand.png';
import { images } from "@/assets/onboarding/index"
import { isMobile } from "@/styles";
import { AntDesign } from '@expo/vector-icons';
import { actions as authActions } from '@/redux/reducers/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native'

const { width, height } = Dimensions.get('window');

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'
import Icon from "@/components/Icon";

export default function GuidedTutorialWeb() {
    const dispatch = useDispatch()
    const scrollRef = useRef(null);
    const [index, setIndex] = useState(0);
    const navigation = useNavigation();
    const route = useRoute();

    const SecondarySlides = [
        {
            title: "Home",
            head1: `Your "Home" will keep you up to date on what's happening with your community`,
            head2: `You will see recent posts from other members of your community and be able to engage with your community. You'll also see the most recent learning content available to you.`,
            head3: `This will be your hub for what's new in your community`,
            icon: images.home
        },
        {
            title: "Community",
            head1: `Within your "Community", you will find your Teams and Groups, and be able to communicatea and collaborate with the team members within those Teams and Groups in a closed space.`,
            icon: images.group
        },
        {
            title: "Chat",
            head1: `Communicate in real-time with members of your community using 1-on-1 instant messaging, along with group messaging.`,
            icon: images.chat
        },
        {
            title: "Calendar",
            head1: `Your calendar allows you to stay on top of your agenda!`,
            head2: `This will provide you with streamlined approach to managing your day allowing you to manage personal tasks, to-dos, and appointments, as well as keeping you aware of company and team meetings and events`,
            head3: `Attend meetings and events virtual using video conferencing!`,
            icon: images.calendar
        },
        {
            title: "Video Conferencing",
            head1: `With video Conferencing, you will be able to attend virtual meetings 1-on-1, or with your community members`,
            head2: `You will also be able to immersive virtual events hosted by your community.`,
            icon: images.video
        },
        {
            title: "File Storage & Sharing",
            head1: `Your File Storage allows you to manage your files conveniently, and manage sharing of files with other members of your community.`,
            head2: `Instantly gain access to files that you've uploaded, along with files shared with you by other members of your community.`,
            icon: images.folder
        },
        {
            title: "Learning",
            head1: `Within your "Learning" library, you will have access to fresh and relevant articles, courses and events that are available for you to read, participate in, and attend.`,
            head2: `These items will be specifically personalized for you based on the content that you enjoy consuming!`,
            icon: images.hat
        },
        {
            title: "Rewarding",
            head1: `Get Rewarded!`,
            head2: `Track your progress towards earning rewards, as you engage with your community.`,
            head3: `You will have access at all times to redeem rewards that you would've received from the leaders within your community.`,
            icon: images.reward
        },
        {
            title: "Notification",
            head1: `Stay on top of what's new in your community!`,
            head2: `Receive notification directly to your mobile device when there's movement in your community, along with access to all notifications directly within the app, as that your never miss anything!`,
            icon: images.bell
        },
        {
            title: "Support",
            head1: `Are you stuck or have a question on using any of the features available in your community?`,
            head2: `You will have 24/7 access to a library of support content that will assist with resolving your questions. If needed, you will also have access to ticketing allowing you to submit a ticket for help, along with live chat with one of our support agents who will be happy to assist.`,
            icon: images.support
        },
        {
            title: ""
        }
    ]

    const MainSlide = ({ style }) => {

        return (
            <View style={styles.SlideContainer} >
                <ContentBlockV1>
                    <View style={styles.Slide}>
                        <Image source={ReframeHorizontalLogo} resizeMode="contain" style={{ width: 220, height: 100, alignSelf: 'center' }} />

                        <View style={{ flex: 0.7, justifyContent: "center", maxWidth: 540, }} >
                            <Text
                                style={{
                                    fontFamily: 'WorkSans_600SemiBold',
                                    fontSize: 34,
                                    textAlign: "center",
                                    flexWrap: "wrap"
                                }}
                            >
                                <Text
                                    style={{
                                        fontSize: 44,
                                    }}
                                >{"👋"}</Text>
                                {"\nLet's go through a quick tutorial!"}
                            </Text>

                        </View>
                    </View>
                </ContentBlockV1>
            </View>
        )
    }
    const OtherSlides = ({ title, text, icon }) => {
        return (
            <View
                style={styles.SlideContainer}
            >
                <ContentBlockV1>
                    <View style={styles.Slide}>
                        <View style={styles.SlideHeader}>
                            {icon ? (
                                <Icon
                                    icon={icon}
                                    color={'#de614e'}
                                    width={25}
                                />
                            ) : null}
                            <Text style={styles.SlideHeaderText}>{title}</Text>
                        </View>

                        <View style={styles.SlideBody}>
                            <Text style={styles.SlideBodyText}>{text}</Text>
                        </View>
                    </View>
                </ContentBlockV1>

            </View>
        )
    }

    const goNextIndex = () => {
        if (index === SecondarySlides.length - 1) {
            dispatch(authActions.toggleFirstAccess())
        } else {
            const prevIdx = scrollRef.current.getActiveIndex() + 1;
            setIndex(prevIdx);
        }
    }

    const goPrevIndex = () => {

        const prevIdx = scrollRef.current.getActiveIndex() - 1;
        scrollRef.current.gotoPrev;
        setIndex(prevIdx);
    }

    return (

        <TheaterLayout>
            <View
                style={styles.Container}
            >
                <View style={styles.SlidesContainer}>
                    <Swiper
                        ref={scrollRef}
                        from={0}
                        minDistanceForAction={0.1}
                        controlsProps={{
                            dotActiveStyle: {
                                backgroundColor: '#de614e'
                            },
                            dotsTouchable: true,
                            prevPos: 'bottom-left',
                            nextPos: 'bottom-right',
                            PrevComponent: ({ onPress }) => (
                                <TouchableOpacity onPress={() => {
                                    onPress()
                                    goPrevIndex()
                                }} style={styles.Button}>
                                    <Icon
                                        icon="chevronLeft"
                                        color={'#FFF'}
                                        height={Platform.OS == 'web' ? 13 : 10}
                                    />
                                </TouchableOpacity>
                            ),
                            NextComponent: ({ onPress }) => (
                                <TouchableOpacity onPress={() => {
                                    onPress()
                                    goNextIndex()
                                }} style={styles.Button}>
                                    <Icon
                                        icon="chevronRight"
                                        color={'#FFF'}
                                        height={Platform.OS == 'web' ? 13 : 10}
                                    />
                                </TouchableOpacity>
                            ),
                        }}
                    >
                        <MainSlide />

                        <OtherSlides
                            title={"Feed"}
                            text={`Your feed will keep you up to date on what's happening with your community\n\nYou will see recent posts from other members of your community and be able to engage with your community. You'll also see the most recent learning content available to you.\n\nThis will be your hub for what's new in your community`}
                            icon={'home'}
                        />

                        <OtherSlides
                            title={"Chat"}
                            text={`Communicate in real-time with members of your community using 1-on-1 instant messaging, along with group messaging.`}
                            icon={'comment'}
                        />

                        <OtherSlides
                            title={"Video Conferencing"}
                            text={`With video Conferencing, you will be able to attend virtual meetings 1-on-1, or with your community members.\n\nYou will also be able to immersive virtual events hosted by your community.`}
                            icon={'video'}
                        />

                        <OtherSlides
                            title={"Community"}
                            text={`Within your "Community", you will find your Teams and Groups, and be able to communicatea and collaborate with the team members within those Teams and Groups in a closed space.`}
                            icon={'community'}
                        />

                        <OtherSlides
                            title={"Calendar"}
                            text={`Your calendar allows you to stay on top of your agenda!\n\nThis will provide you with streamlined approach to managing your day allowing you to manage personal tasks, to-dos, and appointments, as well as keeping you aware of company and team meetings and events.\n\nAttend meetings and events virtual using video conferencing!`}
                            icon={'calendar'}
                        />

                        <OtherSlides
                            title={"Learning"}
                            text={'Within your "Learning" library, you will have access to fresh and relevant articles, courses and events that are available for you to read, participate in, and attend.\n\nThese items will be specifically personalized for you based on the content that you enjoy consuming!'}
                            icon={'education'}
                        />

                        <OtherSlides
                            title={"Rewarding"}
                            text={`Get Rewarded!\n\nTrack your progress towards earning rewards, as you engage with your community.\n\nYou will have access at all times to redeem rewards that you would've received from the leaders within your community.`}
                            icon={'rewards'}
                        />

                        <OtherSlides
                            title={"File Storage & Sharing"}
                            text={`Your File Storage allows you to manage your files conveniently, and manage sharing of files with other members of your community.\n\nInstantly gain access to files that you've uploaded, along with files shared with you by other members of your community.`}
                            icon={'sharedfolderleft'}
                        />

                        <OtherSlides
                            title={"Support"}
                            text={`Are you stuck or have a question on using any of the features available in your community?\n\nYou will have 24/7 access to a library of support content that will assist with resolving your questions. If needed, you will also have access to ticketing allowing you to submit a ticket for help, along with live chat with one of our support agents who will be happy to assist.`}
                            icon={'help'}
                        />

                        <OtherSlides
                            title={"Notification"}
                            text={`Stay on top of what's new in your community!\n\nReceive notification directly to your mobile device when there's movement in your community, along with access to all notifications directly within the app, as that your never miss anything!`}
                            icon={'notifications'}
                        />

                        <OtherSlides />

                    </Swiper>
                </View>
            </View>
        </TheaterLayout>
    )
}


const styles = StyleSheet.create({
    Container: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        maxWidth: 600,
        minWidth: 600,
        minHeight: 600,
        width: '100%',
        marginVertical: 0,
        marginHorizontal: 'auto'
    },
    SlidesContainer: {
        flex: 1,
        width: '100%',
    },
    SlideContainer: {
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'center',
        alignItems: "center",
        marginBottom: 40,
        paddingHorizontal: 20,
        flex: 1
    },
    Slide: {
        flex: 1,
        justifyContent: 'center',
        alignItems: "center",
    },
    SlideHeader: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        flexWrap: 'wrap'
    },
    SlideHeaderText: {
        marginLeft: 10,
        fontSize: 25,
        lineHeight: 30,
        fontFamily: 'WorkSans_600SemiBold',
        color: '#de614e'
    },
    SlideBody: {

    },
    SlideBodyText: {
        fontSize: 18,
        textAlign: 'center',
        fontFamily: 'WorkSans_400Regular',
        lineHeight: 23
    },
    ControlsContainer: {
        flex: 1,
        //bottom: 0,
        marginTop: 10,
        flexDirection: "row",
        justifyContent: "center",
        //paddingHorizontal: 20
    },
    Button: {
        backgroundColor: '#1b1b1b',
        borderRadius: 50,
        justifyContent: 'center',
        marginHorizontal: 10,

        ...Platform.select({
            ios: {
                width: 30,
                height: 30,
            },
            android: {
                width: 30,
                height: 30,
            },
            web: {
                width: 35,
                height: 35,
                alignItems: 'center',
            }
        })
    },
    previousNextBtn: {
        borderRadius: 4,
        backgroundColor: "#00000044",
        width: 70,
        height: 30,
        alignItems: "center",
        justifyContent: "center",
    },
    previousNextTxt: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
    },

    slideIcons: {
        width: 40,
        height: 40
    },
    navIcons: {
        width: 28,
        height: 28
    }
});