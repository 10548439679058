import { MenuTrigger, Menu, MenuOptions, MenuOption } from 'react-native-popup-menu'
import { Text, View } from 'react-native'
import { UIColors } from '@/constants/colors'
import { Icon } from '@/components'

const PopupMenu = ({ onSelect, selectedValue, items }) => {
    return (
        <Menu onSelect={(item) => onSelect(item)}>
            <MenuTrigger customStyles={{
                triggerWrapper: {
                    borderWidth: 2,
                    borderColor: UIColors.border,
                    paddingVertical: 14,
                    paddingHorizontal: 30,
                    borderRadius: 5,
                    flexDirection: 'row',
                    alignItems: 'center'
                }
            }}>
                <Text style={{ fontWeight: '500', paddingRight: 5 }}>{selectedValue}</Text>
                <Icon icon='arrowDown' height={10} width={10} />
            </MenuTrigger>
            <MenuOptions optionsContainerStyle={{
                marginTop: "4%",
                borderRadius: 5,
                paddingVertical: 5
            }}>
                {items.map((item, i) => {
                    // if (i == items.length - 1) {
                    //     return null
                    // }
                    return (
                        <MenuOption
                            value={item}
                            customStyles={{
                                optionWrapper: {
                                    width: 200,
                                    padding: 10,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between'
                                }
                            }}>
                            <Text>{item.title}</Text>
                            <Text>{item.title.charAt(0).toUpperCase()}</Text>
                        </MenuOption>
                    )
                })}
            </MenuOptions>
        </Menu>
    )
}

export default PopupMenu;