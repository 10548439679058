import { useRoute } from "@react-navigation/native";
import React, { useRef, useEffect, useCallback } from "react";
import { Animated, StyleSheet, Image } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useScreenSize } from "@/styles/screenSize";
import { actions as authActions } from "@/redux/reducers/auth";

import Icon from "@/components/Icon";

import DrawerItem from "../../components/DrawerItem";
import DrawerSection from "../../components/DrawerSection";
import { useSideDrawer } from "@/hooks/sideDrawer";
import theme from "@/styles/themes/black";
import Logo from "@/assets/Icon_1024.png";
import {
    Container,
    MainMenuWrapper,
    ContainerLogo,
    ConstainerToogleDrawer,
    ToggleButton,
} from "./styles";
import canAccess from "@/helpers/permissions";
import hasPlan from "@/helpers/subscription";
import { permissions } from "@/helpers/permissionConstants";
import * as brandSelectors from "@/redux/selectors/branding";
import * as authSelectors from '@/redux/selectors/auth';

const SIDE_DRAWER_WIDTH_OPEN = 250;
const SIDE_DRAWER_WIDTH_CLOSED = 90;

export default function Drawer() {
    const route = useRoute();
    const screenSize = useScreenSize();
    const { open, setOpen } = useSideDrawer(true);
    const dispatch = useDispatch();

    const animatedWidth = useRef(
        new Animated.Value(open ? SIDE_DRAWER_WIDTH_OPEN : SIDE_DRAWER_WIDTH_CLOSED)
    ).current;

    const animatedCloseButtonOpacity = useRef(
        new Animated.Value(open ? 1 : 0)
    ).current;

    const handleToggleMenu = useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);

    useEffect(() => {
        Animated.parallel([
            Animated.timing(animatedWidth, {
                duration: 350,
                useNativeDriver: false,
                toValue: open ? SIDE_DRAWER_WIDTH_OPEN : SIDE_DRAWER_WIDTH_CLOSED,
            }),
            Animated.timing(animatedCloseButtonOpacity, {
                duration: 350,
                toValue: open ? 1 : 0,
                useNativeDriver: false,
            }),
        ]).start();
    }, [open]);

    const handleLogout = useCallback(() => {
        dispatch(authActions.logout());
    }, [dispatch]);

    const branding = useSelector(brandSelectors.getSettings);
    const hasMultipleWorkspaces = useSelector(authSelectors.getHasMultipleWorkspaces)

    const { color_scheme_main_navigation_background } = branding;

    return (
        <Container
            screenSize={screenSize}
            style={{
                width: animatedWidth,
            }}
            bg={color_scheme_main_navigation_background}
            contentContainerStyle={styles.scrollViewContentContainer}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
        >
            <MainMenuWrapper>
                <ContainerLogo>
                    <Image
                        style={{ width: 35, height: 35 }}
                        source={branding?.logo ? { uri: branding.logo } : Logo}
                        resizeMode="contain"
                    />
                </ContainerLogo>
                <ConstainerToogleDrawer>
                    <ToggleButton onPress={handleToggleMenu}>
                        <Icon
                            icon={open ? "chevronLeft" : "chevronRight"}
                            width={7}
                            height={12}
                            color={theme.basics.background_white}
                        />
                    </ToggleButton>
                </ConstainerToogleDrawer>
                {hasMultipleWorkspaces ? (
                    <DrawerItem
                        iconSize={24}
                        label="Select Workspace"
                        icon="workspace"
                        destinationRoute={"Workspaces"}
                        selected={route.name === "Workspaces"}
                    />
                ) : null}
                <DrawerItem
                    icon="home"
                    label="Home"
                    destinationRoute="Home"
                    selected={route.name === "Home"}
                />
                {hasPlan([
                    'collaboration_hub',
                    'change_and_growth_hub',
                    'personalization_hub'
                ]) ? (
                    <DrawerItem
                        iconSize={20}
                        icon="comment"
                        label="Chat"
                        destinationRoute="chat"
                        selected={route.name == "chat"}
                    />
                ) : null}

                {hasPlan([
                    'collaboration_hub',
                    'change_and_growth_hub',
                    'personalization_hub'
                ]) ? (
                    <DrawerItem
                        iconSize={23}
                        icon="community"
                        label="Community"
                        selected={route.name === "Community"}
                        destinationRoute="Community"
                    />
                ) : null}

                {hasPlan([
                    'collaboration_hub',
                    'change_and_growth_hub',
                    'personalization_hub'
                ]) ? (
                    <DrawerItem
                        iconSize={21}
                        icon="calendar"
                        label="Calendar"
                        selected={route.name === "Calendar"}
                        destinationRoute="Calendar"
                    />
                ) : null}

                {hasPlan([
                    'change_and_growth_hub',
                    'personalization_hub'
                ]) ? (
                    <DrawerItem
                        iconSize={24}
                        icon="education"
                        label="Learning"
                        selected={route.name === "Learning"}
                        destinationRoute="Learning"
                    />
                ) : null}

                {hasPlan([
                    'personalization_hub'
                ]) ? (
                    <DrawerItem
                        icon="rewards"
                        label="Rewards"
                        selected={route.name === "MemberWeb"}
                        destinationRoute="MemberWeb"
                    />
                ) : null}

                {hasPlan([
                    'collaboration_hub',
                    'change_and_growth_hub',
                    'personalization_hub'
                ]) ? (
                    <DrawerItem
                        iconSize={21}
                        icon="sharedfolderleft"
                        label={`Storage & File ${"\n"}Sharing`}
                        destinationRoute="SharedFolders"
                        selected={route.name === "SharedFolders"}
                    />
                ) : null}

                <DrawerItem
                    iconSize={20}
                    icon="help"
                    label="Support"
                    destinationRoute={"Support"}
                    selected={route.name === "Support"}
                />
                {
                    canAccess(permissions.CREATE_ASSESSMENTS) && canAccess(permissions.VIEW_ALL_ANALYTICS) && hasPlan(['change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerSection title="Admin" />
                    )
                }
                {
                    canAccess(permissions.CREATE_ASSESSMENTS) && hasPlan(['change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerItem
                            iconSize={24}
                            icon="assessments"
                            label="Assessments"
                            selected={route.name === "Assessments"}
                            destinationRoute={"Assessments"}
                        />
                    )
                }
                {
                    canAccess(permissions.VIEW_ALL_ANALYTICS) && hasPlan(['personalization_hub']) && (
                        <DrawerItem
                            iconSize={20}
                            icon="analytics"
                            label="Analytics"
                            destinationRoute="Analytics"
                            selected={route.name === "Analytics"}
                        />
                    )
                }
                {
                    canAccess(permissions.VIEW_ALL_ANALYTICS) && hasPlan(['personalization_hub']) && (
                        <DrawerItem
                            icon="rewards"
                            label="Rewards"
                            selected={route.name === "ManagerWeb"}
                            destinationRoute="ManagerWeb"
                        />
                    )
                }
            </MainMenuWrapper>
            <DrawerSection />
            <DrawerItem
                iconSize={18}
                icon="profile"
                label="Profile"
                destinationRoute="Profile"
                selected={route.name === "Profile"}
            />
            <DrawerItem
                iconSize={20}
                icon="settings"
                label="Settings"
                destinationRoute="Settings"
                selected={route.name === "Settings"}
            />
            <DrawerItem
                iconSize={23}
                icon="notifications"
                label="Notifications"
                selected={route.name === "Notifications"}
                destinationRoute={"Notifications"}
            />
            <DrawerSection />
            <DrawerItem
                iconSize={20}
                label="Logout"
                icon="logOut"
                onPress={handleLogout}
            />
        </Container>
    );
}

const styles = StyleSheet.create({
    scrollViewContentContainer: {
        flex: 1,
        flexGrow: 1,
    },
});

{
    /* <Header open={open}>
      <ReframeIcon width={50} height={50} />
      <TouchableOpacity
        onPress={handleToggleMenu}
        style={{ width: 40, height: 40, backgroundColor: 'orange' }}
      />
      <CloseIcon style={{ opacity: animatedCloseButtonOpacity }}>
        <TouchableOpacity onPress={handleToggleMenu}>
        <ChevronLeft width={20} height={20} />
        </TouchableOpacity>
      </CloseIcon>
      </Header> */
}