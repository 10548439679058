import React, { useCallback, useEffect, useState } from 'react'
import { Dimensions, KeyboardAvoidingView, Platform, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { ActivityIndicator } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import * as profileSelectors from '@/redux/selectors/profile'
import { actions as authActions } from '@/redux/reducers/auth'
import { actions as profileActions } from '@/redux/reducers/profile'
import { actions as actions } from '@/redux/reducers/posts'
import Toast from 'react-native-toast-message'
import { Icon } from '@/components'
import Avatar from '../Avatar'
import { openGallery } from '@/utils/gallery'
import { ScreenSize, useScreenSize, isMobile } from '@/styles'
import theme from '@/styles/themes/black'
import Dropdown from '../Dropdown'
import {
  AvatarWrapper,
  CircleCamera,
  EditButton,
  EditButtonText,
  ConatinerButtons,
  AvatarButton,
  TextField,
} from './styles'
import ApiHandler from '@/api/ApiHandler'
import * as FileSystem from 'expo-file-system';

import * as brandSelectors from '@/redux/selectors/branding'
import AppLoading from '../Chat/components/AppLoading'

const validationSchema = Yup.object().shape({
  profileImageUri: Yup.string(),
  first_name: Yup.string().min(2).required(),
  last_name: Yup.string().min(2),
  internal_id: Yup.string(),
  role: Yup.string().min(2).required(),
  city: Yup.string().min(2).required(),
  timezone: Yup.number(),
  language: Yup.number()
})

const keyboardVerticalOffset = Platform.OS === 'ios' ? 0 : 100
const api = new ApiHandler();

const EditProfile = ({ cancel, userId }) => {

  const brandSettings = useSelector(brandSelectors.getSettings);
  const {
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = brandSettings

  const [loading, setLoading] = useState()
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const userProfile = useSelector(profileSelectors.getProfile)
  const [languages, setLanguages] = useState([]);
  const [timeZones, setTimeZones] = useState([]);
  const isSmallSizeDevice = Dimensions.get('screen').height <= 700;


  const initialValues = {
    profileImageUri: '',
    first_name: userProfile?.first_name ?? '',
    last_name: userProfile?.last_name ?? '',
    internal_id: userProfile?.interal_id ?? '',
    role: userProfile?.role ?? '',
    city: userProfile?.city ?? '',
    language: userProfile?.language_id ?? '',
    timezone: userProfile?.timezone_id ?? '',
  }

  const fetchUserProfile = useCallback(() => {

  }, [])

  const handleSubmit = useCallback(async (values) => {
    // legacy code has been uncommented for now
    // dispatch(
    //   authActions.onboardingUpdateProfile(
    //     {
    //       profileImageUri: values.profileImageUri ?? '',
    //       name: values.first_name,
    //       last_name: values.last_name,
    //       role: values.role,
    //       city: values.city,
    //       occupation: 'Occupation',
    //     },
    //     (error) => {
    //       dispatch(profileActions.fetchProfile(6))
    //       setLoading(false)
    //       cancel()
    //       if (error) {
    //         Toast.show({
    //           type: 'error',
    //           position: 'bottom',
    //           text1: 'Something went wrong, try again later.',
    //         })
    //       }
    //     },
    //   ),
    // )
    setLoading(true)
    const data = {
      image: values.profileImageUri ?? '',
      first_name: values.first_name,
      last_name: values.last_name,
      internal_id: values.internal_id,
      role: values.role,
      city: values.city,
      timezone: values.timezone,
      language: values.language,
      occupation: 'Occupation',
    }

    try {
      const response = await api.onboardingUpdateUserProfile(data)
      if (response?.data) {
        dispatch(authActions.onboardingUpdateProfile(response?.data?.data))
        dispatch(profileActions.fetchProfile(userId))
        setLoading(false)
        cancel()
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      setLoading(false);
    }
  })

  const getLanguages = async () => {
    const res = await api.getLanguages();
    setLanguages(res);
  }

  const getTimeZones = async () => {
    const res = await api.getTimeZones();
    setTimeZones(res);
  }

  useEffect(() => {
    if (languages.length == 0) {
      getLanguages();
    }
  }, [])

  useEffect(() => {
    if (timeZones.length == 0) {
      getTimeZones();
    }
  }, [])


  return (
    <Formik
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, isValid, handleChange, handleSubmit, setFieldValue }) => (
        <>
          <KeyboardAvoidingView behavior='height' keyboardVerticalOffset={keyboardVerticalOffset}>
            {isMobile ? (
              <ScrollView scrollEnabled={isSmallSizeDevice} showsVerticalScrollIndicator={false} contentContainerStyle={[isSmallSizeDevice && { paddingBottom: 100 }]}>
                <AvatarButton
                  onPress={async () => {
                    try {
                      const imageUri = await openGallery()
                      if (isMobile) {
                        handleChange('profileImageUri')('data:image/jpeg;base64,' + await FileSystem.readAsStringAsync(imageUri, { encoding: 'base64' }))
                        return
                      }
                      handleChange('profileImageUri')(imageUri)
                    } catch (e) {
                      console.log('Error opening gallery')
                    }
                  }}
                >
                  <AvatarWrapper screenSize={screenSize}>
                    <Avatar
                      style={{ backgroundColor: '#F1F1F1' }}
                      size={screenSize <= ScreenSize.Medium ? 90 : 120}
                      source={
                        values?.profileImageUri
                          ? { uri: values?.profileImageUri }
                          : { uri: userProfile?.avatar ? userProfile?.avatar : undefined }
                      }
                    />
                    <CircleCamera isMobile={isMobile}>
                      <Icon icon="addPhoto" color="#323232" height={isMobile ? 12 : 22} width={isMobile ? 12 : 22} />
                    </CircleCamera>
                  </AvatarWrapper>
                </AvatarButton>
                <TextField
                  label="First name"
                  value={values.first_name}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('first_name')}
                />
                <TextField
                  label="Last name"
                  value={values.last_name}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('last_name')}
                />
                <TextField
                  label="Internal ID"
                  value={values.internal_id}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('internal_id')}
                />
                <TextField
                  label="Role"
                  value={values.role}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('role')}
                />
                <TextField
                  label="City"
                  value={values.city}
                  keyboardType="default"
                  autoCapitalize="none"
                  onChangeText={handleChange('city')}
                />
                <Dropdown placeholder="Select Your Language" flatListProps={{ inverted: false }} autoScroll={false} onChange={item => setFieldValue("language", item.id)} value={userProfile?.language_id ?? 40} data={languages} label="Language" labelField="display_name" valueField="id" />
                <Dropdown placeholder="Select Your Timezone" flatListProps={{ inverted: false }} autoScroll={false} onChange={item => setFieldValue("timezone", item.id)} data={timeZones} value={userProfile.timezone_id ?? 100} label="Timezone" labelField="display_name" valueField="id" />
                <ConatinerButtons direction={isMobile ? 0 : 1}>
                  <EditButton
                    color={color_scheme_primary_button_background ? color_scheme_primary_button_background : theme.basics.brand}
                    isMobile={isMobile}
                    disabled={!isValid}
                    onPress={handleSubmit}
                  >
                    {loading && (
                      <ActivityIndicator
                        size={20}
                        animating
                        color={theme.basics.background_white}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <EditButtonText color={color_scheme_primary_button_text} >Save</EditButtonText>
                  </EditButton>
                </ConatinerButtons>
              </ScrollView>
            ) : (
              <>
                <AvatarButton
                  onPress={async () => {
                    try {
                      const imageUri = await openGallery()
                      if (isMobile) {
                        handleChange('profileImageUri')('data:image/jpeg;base64,' + await FileSystem.readAsStringAsync(imageUri, { encoding: 'base64' }))
                        return
                      }
                      handleChange('profileImageUri')(imageUri)
                    } catch (e) {
                      console.log('Error opening gallery')
                    }
                  }}
                >
                  <AvatarWrapper screenSize={screenSize}>
                    <Avatar
                      style={{ backgroundColor: '#F1F1F1' }}
                      size={screenSize <= ScreenSize.Medium ? 90 : 120}
                      source={
                        values?.profileImageUri
                          ? { uri: values?.profileImageUri }
                          : { uri: userProfile?.avatar ? userProfile?.avatar : undefined }
                      }
                    />
                    <CircleCamera isMobile={isMobile}>
                      <Icon icon="addPhoto" color="#323232" height={isMobile ? 12 : 22} width={isMobile ? 12 : 22} />
                    </CircleCamera>
                  </AvatarWrapper>
                </AvatarButton>
                <TextField
                  label="First name"
                  value={values.first_name}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('first_name')}
                />
                <TextField
                  label="Last name"
                  value={values.last_name}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('last_name')}
                />
                <TextField
                  label="Internal ID"
                  value={values.internal_id}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('internal_id')}
                />
                <TextField
                  label="Role"
                  value={values.role}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('role')}
                />
                <TextField
                  label="City"
                  value={values.city}
                  keyboardType="default"
                  autoCapitalize="none"
                  onChangeText={handleChange('city')}
                />
                <Dropdown placeholder="Select Your Language" flatListProps={{ inverted: false }} autoScroll={false} onChange={item => setFieldValue("language", item.id)} value={userProfile?.language_id ?? 40} data={languages} label="Language" labelField="display_name" valueField="id" />
                <Dropdown placeholder="Select Your Timezone" flatListProps={{ inverted: false }} autoScroll={false} onChange={item => setFieldValue("timezone", item.id)} data={timeZones} value={userProfile.timezone_id ?? 100} label="Timezone" labelField="display_name" valueField="id" />
              </>
            )}
          </KeyboardAvoidingView>

          <ConatinerButtons direction={isMobile ? 0 : 1}>
            {
              !isMobile && (
                <>
                  <EditButton
                    color={color_scheme_primary_button_background ? color_scheme_primary_button_background : theme.basics.brand}
                    isMobile={isMobile}
                    disabled={!isValid}
                    onPress={handleSubmit}
                  >
                    {loading && (
                      <ActivityIndicator
                        size={20}
                        animating
                        color={theme.basics.background_white}
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <EditButtonText color={color_scheme_primary_button_text} >Save</EditButtonText>
                  </EditButton>
                </>
              )
            }
          </ConatinerButtons>
        </>
      )
      }
    </Formik >
  )
}

EditProfile.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
  cancel: PropTypes.func,
}

EditProfile.defaultProps = {
  cancel: () => null,
}

export default EditProfile
