import styled from 'styled-components/native';

export const Container = styled.TouchableOpacity`
  flex-direction: row;
  
  align-items: flex-start;
`;

export const ModalText = styled.Text`
  margin-left: 12px;
  margin-bottom: 12px;
`;

