import React from 'react'
import PropTypes from 'prop-types'

import { Container, ContentWrapper } from './styles'

export default function Card({ icon, color, style, children }) {
  return (
    <Container color={color} style={style}>
      {icon}
      <ContentWrapper>{children}</ContentWrapper>
    </Container>
  )
}

Card.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}
