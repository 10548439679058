import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { Headline6 } from '@/styles/fonts'

export const Container = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 5px 20px;
`

export const IconContainer = styled.View`
  width: 50px;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Label = styled(Headline6)`
  color: ${({ color }) => (color ? color : 'white')};
  flex-grow: 1;
  margin-left: 10px;
  font-weight: ${({ selected }) => (selected ? '600' : '500')};
`
