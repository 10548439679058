import React from 'react'
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import { Message, Container } from './styles'

const defaultToast = (props, type) => {
  const isSuccess = type === 'success'
  return (
    <Container type={type}>
      <Icon
        width={22}
        icon={isSuccess ? 'checkOutline' : 'closeOutline'}
        color={isSuccess ? theme.success[900] : theme.danger.standard}
      />
      <Message type={type}>{props.text1}</Message>
    </Container>
  )
}

export const toastConfig = {
  success: function SuccessToast(props) {
    return defaultToast(props, 'success')
  },
  error: function SuccessToast(props) {
    return defaultToast(props, 'error')
  },
}
