import styled from 'styled-components/native'
import { ScreenSize } from '@/styles'
import { Paragraph1, Caption1 } from '@/styles/fonts'

export const Container = styled.View`
  display: flex;
  flex-direction: column;

  padding-top: 5px;
`

export const Title = styled(Paragraph1)`
  color: black;
`

export const Subtitle = styled(Caption1)`
  color: ${({ theme }) => theme.basics[400]};
`

export const SubtitleContainer = styled.View`
  display: flex;
  margin-top: 1px;
  flex-direction: ${({ screenSize }) =>
    screenSize <= ScreenSize.Small ? 'column' : 'row'};
`

export const Accessory = styled(Caption1)`
  color: black;
  margin-top: ${({ screenSize }) => (screenSize <= ScreenSize.Small ? 3 : 0)}px;
  margin-left: ${({ screenSize }) =>
    screenSize <= ScreenSize.Small ? 0 : 5}px;
`
export const ButtonNavigation = styled.TouchableOpacity``
