import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dimensions, Modal, View, Text, TouchableOpacity, Image } from "react-native";
import { isSupported } from '@twilio/video-processors'
import { UIButton } from "./styles";
import { Entypo, MaterialCommunityIcons } from "@expo/vector-icons";
import { Tooltip } from "@material-ui/core";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import { Modals } from "./Modals";
import ModalV2 from "@/components/ModalV2"


const { width, height } = Dimensions.get('window');

const Tabs = { effects: 0, audio: 1 };

const EffectsModal = ({ room, showTabs, setModalVisible, visible, onRemoveProcessors, localVideoTrack, onAddBlurFilter, onAddImageFilter, setLocalVideoTrack, setLocalAudioTrack, currentVideoFilter, imageFilterImages }) => {
    const videoRef = useRef();
    const [currentTab, setCurrentTab] = useState(Tabs.audio);
    const color = UseBrandingAccentColor();

    useEffect(() => {
        if (visible) {
            if (currentTab == Tabs.effects) {
                if (localVideoTrack) {
                    /*
                    setTimeout(() => {
                        localVideoTrack?.attach(videoRef.current)
                        videoRef.current.style.transform = 'scale(-1, 1)'
                    }, 1500)
                    */

                    localVideoTrack?.attach(videoRef.current)
                    videoRef.current.style.transform = 'scale(-1, 1)'
                }

                //return () => localVideoTrack?.detach();
            }
        }
    }, [visible, currentTab])

    useEffect(() => {
        if (currentVideoFilter) {
            //console.log(currentVideoFilter?._name)
        } else {
            //console.log(currentVideoFilter)
        }
    }, [currentVideoFilter])

    const handleCloseButton = useCallback(() => {
        if (showTabs && room) {
            //room.localParticipant?.unpublishTrack(localVideoTrack);

            setTimeout(() => {
                //room.localParticipant.publishTrack(localVideoTrack);
            }, 500)
        }

        setModalVisible(false);
    }, [showTabs, room, localVideoTrack])

    const visualEffects = {
        blurs: [
            {
                name: 'light',
                icon: 'blur',
                intensity: 10
            },
            {
                name: 'medium',
                icon: 'blur',
                intensity: 15
            },
            {
                name: 'heavy',
                icon: 'blur',
                intensity: 25
            }
        ],
        images: [
            {
                name: 'reframe_red',
                image_name: 'color_reframe_red',
                image_format: 'jpeg'
            },
            {
                name: 'scene_bookshelves',
                image_name: 'scene_bookshelves',
                image_format: 'jpg'
            },
            {
                name: 'scene_lobby',
                image_name: 'scene_lobby',
                image_format: 'jpg'
            },
            {
                name: 'scene_cafe',
                image_name: 'scene_cafe',
                image_format: 'jpg'
            }
        ]
    }

    return (
        <ModalV2
            title={'Settings'}
            isVisible={visible}
            setVisibility={handleCloseButton}
        >
            {showTabs ? (
                <View style={{
                    flexDirection: 'row',
                    paddingHorizontal: '20%',
                    flex: 1,
                    alignItems: 'center',
                    borderBottomColor: '#e7e7e7',
                    borderBottomWidth: 1,
                    marginBottom: 20
                }}>
                    <TouchableOpacity
                        onPress={() => setCurrentTab(Tabs.audio)}
                        style={[{
                            flex: 1,
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        currentTab === Tabs.audio && {
                            borderBottomColor: '#f05343',
                            borderBottomWidth: 2,
                        }]}
                    >
                        <Text
                            style={[{
                                fontSize: 14,
                                fontWeight: '600',
                                color: '#000000'
                            },
                            currentTab === Tabs.audio && {
                                color: "#f05343"
                            }]}
                        >
                            {'Audio & Video'}
                        </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => setCurrentTab(Tabs.effects)}
                        style={[{
                            flex: 1,
                            height: 50,
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                        currentTab === Tabs.effects && {
                            borderBottomColor: '#f05343',
                            borderBottomWidth: 2,
                        }]}
                    >
                        <Text
                            style={[{
                                fontSize: 14,
                                fontWeight: '600',
                                color: '#000000'
                            },
                            currentTab === Tabs.effects && {
                                color: "#f05343"
                            }]}
                        >
                            {'Effects'}
                        </Text>
                    </TouchableOpacity>
                </View>
            ) : <View style={{ flex: 1 }} />}

            <View style={{}}>
                {currentTab === Tabs.audio && (
                    <View>
                        <Modals.Settings
                            local={room?.localParticipant}
                            setLocalAudioTrack={setLocalAudioTrack}
                            setLocalVideoTrack={setLocalVideoTrack}
                        />
                    </View>
                )}

                {currentTab === Tabs.effects && (
                    <View style={{
                        flexDirection: 'row'
                    }}>
                        <View style={{ width: '60%', justifyContent: 'center', alignItems: 'center' }}>
                            <video
                                style={{
                                    objectFit: 'contain',
                                    width: '100%',
                                    aspectRatio: 16 / 10,
                                    backgroundColor: '#000',
                                    borderRadius: 8
                                }}
                                ref={videoRef}
                            />
                        </View>
                        <View style={{ width: '40%', paddingHorizontal: 15 }}>
                            {isSupported && (
                                <>
                                    <View
                                        style={{
                                            marginBottom: 15
                                        }}
                                    >
                                        <Text style={{
                                            fontSize: 12,
                                            fontWeight: '600',
                                            fontFamily: 'WorkSans_400Regular',
                                            marginBottom: 10,
                                            color: '#f05343'
                                        }}>
                                            {'Blurs'}
                                        </Text>
                                        <View style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap'
                                        }}>
                                            <TouchableOpacity
                                                style={{
                                                    borderRadius: 8,
                                                    borderWidth: 1,
                                                    width: 50,
                                                    height: 50,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    marginHorizontal: 5,
                                                    backgroundColor: !currentVideoFilter ? '#EA4335' : 'transparent',
                                                    borderColor: !currentVideoFilter ? '#EA4335' : '#000000',
                                                    marginBottom: 10
                                                }}
                                                onPress={onRemoveProcessors}
                                                active={!currentVideoFilter}
                                            >
                                                <MaterialCommunityIcons name="block-helper" size={24} color={!currentVideoFilter ? '#FFF' : '#000'} />
                                            </TouchableOpacity>

                                            {
                                                visualEffects.blurs.map((object) => (
                                                    <TouchableOpacity
                                                        style={{
                                                            borderRadius: 8,
                                                            borderWidth: 1,
                                                            width: 50,
                                                            height: 50,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: 'relative',
                                                            marginHorizontal: 5,
                                                            backgroundColor: (currentVideoFilter?._name == 'GaussianBlurBackgroundProcessor' && currentVideoFilter?._blurFilterRadius == object.intensity) ? '#EA4335' : 'transparent',
                                                            borderColor: (currentVideoFilter?._name == 'GaussianBlurBackgroundProcessor' && currentVideoFilter?._blurFilterRadius == object.intensity) ? '#EA4335' : '#000000',
                                                            marginBottom: 10
                                                        }}
                                                        onPress={() => {
                                                            onAddBlurFilter(object.intensity)
                                                        }}
                                                    >
                                                        <MaterialCommunityIcons name={object.icon} size={24} color={(currentVideoFilter?._name == 'GaussianBlurBackgroundProcessor' && currentVideoFilter?._blurFilterRadius == object.intensity) ? '#FFF' : '#000'} />

                                                        <Text
                                                            style={{
                                                                fontSize: 10,
                                                                fontWeight: '600',
                                                                fontFamily: 'WorkSans_400Regular',
                                                                color: (currentVideoFilter?._name == 'GaussianBlurBackgroundProcessor' && currentVideoFilter?._blurFilterRadius == object.intensity) ? '#FFF' : '#000'
                                                            }}
                                                        >
                                                            {object.intensity}
                                                        </Text>
                                                    </TouchableOpacity>
                                                ))
                                            }
                                        </View>
                                    </View>

                                    <View>
                                        <Text style={{
                                            fontSize: 12,
                                            fontWeight: '600',
                                            fontFamily: 'WorkSans_400Regular',
                                            marginBottom: 10,
                                            color: '#f05343'
                                        }}>
                                            {'Backgrounds'}
                                        </Text>
                                        <View style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            flexWrap: 'wrap'
                                        }}>
                                            <TouchableOpacity
                                                style={{
                                                    borderRadius: 8,
                                                    borderWidth: 1,
                                                    width: 50,
                                                    height: 50,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    marginHorizontal: 5,
                                                    backgroundColor: !currentVideoFilter ? '#EA4335' : 'transparent',
                                                    borderColor: !currentVideoFilter ? '#EA4335' : '#000000',
                                                    marginBottom: 10
                                                }}
                                                onPress={onRemoveProcessors}
                                                active={!currentVideoFilter}
                                            >
                                                <MaterialCommunityIcons name="block-helper" size={24} color={!currentVideoFilter ? '#FFF' : '#000'} />
                                            </TouchableOpacity>

                                            {
                                                visualEffects.images.map((object) => (
                                                    <TouchableOpacity
                                                        style={{
                                                            borderRadius: 8,
                                                            borderWidth: 1,
                                                            width: 50,
                                                            height: 50,
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            position: 'relative',
                                                            marginHorizontal: 5,
                                                            backgroundColor: 'transparent',
                                                            borderColor: (currentVideoFilter?._name == 'VirtualBackgroundProcessor') ? '#EA4335' : '#000000',
                                                            marginBottom: 10
                                                        }}
                                                        onPress={() => {
                                                            onAddImageFilter(object.image_name, object.image_format)
                                                        }}
                                                    >
                                                        <Image
                                                            style={{
                                                                width: '100%',
                                                                height: '100%',
                                                                borderRadius: 8
                                                            }}
                                                            source={imageFilterImages.find(e => e.name === object.image_name + '.' + object.image_format) ? imageFilterImages.find(e => e.name === object.image_name + '.' + object.image_format).image : null}
                                                            contentFit="cover"
                                                        //transition={1000}
                                                        />
                                                    </TouchableOpacity>
                                                ))
                                            }
                                        </View>
                                    </View>
                                </>
                            )}

                            {!isSupported && (
                                <Text style={{
                                    fontSize: 12,
                                    fontWeight: '600',
                                    fontFamily: 'WorkSans_400Regular',
                                    marginBottom: 10,
                                    color: '#f05343'
                                }}>
                                    There are no effects available that are supported by your device.
                                </Text>
                            )}
                        </View>
                    </View>
                )}
            </View>
        </ModalV2>
    )
}

export default EffectsModal;