import { Animated, ScrollView, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { SafeAreaView } from 'react-native-safe-area-context'

export const Container = styled(SafeAreaView)`
  flex: 1;
  position: relative;
`

export const PageContentWrapper = styled(Animated.View)`
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  position: absolute;
  background-color: #f2f2f2;
`

export const RightSideMenuWrapper = styled(ScrollView)`
  flex: 1;
  padding: 13px 13px 0;
  align-self: flex-end;
  background-color: #f2f2f2;
  width: ${({ width }) => `${width}px`};
`

export const CloseButton = styled(TouchableOpacity)`
  padding: 0 3px;
  margin-bottom: 30px;
  align-self: flex-end;
`
