import React, { useEffect, useState } from 'react'
import { View, ScrollView, Text, Image, useWindowDimensions, SafeAreaView, RefreshControl, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/article'
import { actions } from '@/redux/reducers/article'
import { getMember } from '@/redux/selectors/auth'
import { Avatar, BackArrow } from '@/components'
import { ProgressBar } from 'react-native-paper'
import { WebView } from 'react-native-webview'
import HTML from 'react-native-render-html';
import tableRenderers, { useHtmlTableProps, HTMLTable } from '@native-html/heuristic-table-plugin';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import Toast from 'react-native-toast-message'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import ApiHandler from '@/api/ApiHandler'

import ContentBlockV1 from '@/components/ContentBlockV1'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import LearningView from '@/pages/Learning/Components/View'

import styles from './styles.js';

const ViewArticle = ({ route }) => {
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const loading = useSelector(selectors.loading)
    // const article = useSelector(selectors.article)
    const user = useSelector(getMember)
    const window = useWindowDimensions();
    const articleSelector = useSelector(selectors.article)
    const [article, setArticle] = useState(selectors.article)
    const [refreshing, setRefreshing] = React.useState(false);
    const languageId = route.params && route.params.language_id ? route?.params?.language_id : 40;

    useEffect(() => {
        // dispatch(actions.show({ id: route?.params?.id,language_id:languageId}, () => { }))
        dispatch(actions.show({ id: route?.params?.id }, () => { }))
    }, [route?.params?.id])

    useEffect(() => {
        setArticle(articleSelector?.data)
        // setArticle(articleSelector)
        setRefreshing(false)
    }, [articleSelector])

    const updateArticle = () => {
        // console.log('article data',article.language_id)
        navigation.navigate('CreateArticle', { id: article?.id })
        // navigation.navigate('CreateArticle', { id: article?.id,language_id:languageId })
    }

    const deleteArticle = () => {
        dispatch(actions.remove({ id: route?.params?.id }, onDelete))
    }

    const toggleStatus = async () => {
        const data = {
            id: article?.id,
        }

        try {
            const response = await new ApiHandler().toggleArticleStatus(data)
            if (response?.data) {
                // dispatch(actions.show({ id: route?.params?.id,language_id:languageId }, () => { }))
                dispatch(actions.show({ id: route?.params?.id }, () => { }))

                Toast.show({
                    type: 'success',
                    position: 'bottom',
                    text1: response?.data?.message,
                })
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: error,
            })
        }
    }

    const toggleFeatured = async () => {
        const data = {
            id: article?.id,
        }

        try {
            const response = await new ApiHandler().toggleArticleFeatured(data)
            if (response?.data) {
                // dispatch(actions.show({ id: route?.params?.id,language_id:languageId }, () => { }))
                dispatch(actions.show({ id: route?.params?.id }, () => { }))

                Toast.show({
                    type: 'success',
                    position: 'bottom',
                    text1: response?.data?.message,
                })
            }
        } catch (error) {
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: error,
            })
        }
    }

    const onDelete = (error, success) => {
        Toast.show({
            type: error ? 'error' : 'success',
            position: 'bottom',
            text1: error ? error.message : success.message,
        })
        if (success) {
            navigation.navigate('Learning')
        }
    }


    const expansiveMenuItems = [
        { text: 'Edit Article', icon: 'pencil', onPress: updateArticle },
        { text: 'Publish/Unpublish', icon: 'send', onPress: toggleStatus },
        { text: 'Feature/Unfeature', icon: 'star', onPress: toggleFeatured },
        { text: 'Delete Article', icon: 'close', onPress: deleteArticle },
    ]



    const ContentData = article?.content ?? ''

    const TableRenderer = (props) => {
        const tableProps = useHtmlTableProps(props, {/* config */ });
        tableProps.layout.totalWidth = window.width - 24.95
        // Do customize the props here; wrap with your own container...
        return <HTMLTable {...tableProps} />;
    };

    const renderers = {
        ...tableRenderers,
        table: TableRenderer,
        iframe: IframeRenderer,
    }

    const customHTMLElementModels = {
        iframe: iframeModel
    };

    const htmlProps = {
        WebView,
        renderers: renderers,
        renderersProps: {
            table: {
                // Put the table config here
                getStyleForCell(cell) {
                    cell.width = window.width - 25
                    cell.height = '100%'
                    return cell;
                }
            },
            iframe: {
                scalesPageToFit: true,
                webViewProps: {
                    /* Any prop you want to pass to iframe WebViews */
                }
            }
        },
        tagsStyles: {
            table: { height: '100%', width: '95%', paddingBottom: 50, overFlowX: 'hidden' },
        }
    };

    return (
        <>
            <PageHeader
                title={'Article'}
                icon={false}
                backEnabled={true}
                actions={
                    [
                        {
                            title: 'Manage',
                            type: 'menu',
                            permissions: [
                                'MANAGE_LEARNING_ARTICLES'
                            ],
                            menu: [
                                {
                                    title: 'Edit',
                                    icon: 'pencil',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        updateArticle()
                                    }
                                },
                                {
                                    title: 'Publish/Unpublish',
                                    icon: 'send',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        toggleStatus()
                                    }
                                },
                                {
                                    title: 'Feature/Unfeature',
                                    icon: 'star',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        toggleFeatured()
                                    }
                                },
                                {
                                    title: 'Delete',
                                    icon: 'close',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        deleteArticle()
                                    }
                                }
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <LearningView
                    id={route?.params?.id}
                    type={'article'}
                    image={article?.image}
                    title={article?.name}
                    excerpt={article?.excerpt}
                    employee_lfecycle_stage={article?.employee_lifecycle_stage?.name}
                    five_s_category={article?.five_s_category?.name}
                    creator={article?.creator}
                    created_at={article?.created_at}
                >
                    {Platform.OS == 'web' ? (
                        <div dangerouslySetInnerHTML={{
                            __html: `
                                    <html>
                                    <head>
                                    <meta content="width=width, initial-scale=1, maximum-scale=1" name="viewport"></meta>
                                    <style>
                                    table {width: '100%' !important;white-space: inherit !important;}
                                    tr {width: '100%' !important;white-space: inherit !important;}
                                    td {width: '100%' !important;white-space: inherit !important;}
                                    </style>
                                    </head>
                                    <body >
                                    ${ContentData}
                                    </body>
                                    </html>
                                    `}}
                        />
                    ) : (
                        <HTML baseStyle={{ width: '100%', height: '100%' }} contentWidth={'100%'} source={{ html: ContentData }} customHTMLElementModels={customHTMLElementModels} {...htmlProps} originWhitelist={['*']} />
                    )}
                </LearningView>
            </PageContent>
        </>
    )
}

ViewArticle.propTypes = {
    route: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.number
        })
    })
}

export default ViewArticle
