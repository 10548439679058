import React, { useEffect, useRef } from 'react'
import { Animated, Easing } from 'react-native'
import PropTypes from 'prop-types'
import { Icon } from '@/components'
import { Container, Message } from './styles'
import theme from '@/styles/themes/black'

const ToastWeb = ({ isSuccess, text, visible, setVisible, duration }) => {
  const opacity = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (visible) {
      Animated.timing(opacity, {
        useNativeDriver: true,
        delay: 500,
        easing: Easing.inOut(Easing.ease),
        toValue: 1
      }).start(() => {
        setTimeout(() => {
          setVisible(false)
        }, duration)
      })
      return
    }
    Animated.timing(opacity, {
      useNativeDriver: true,
      delay: 500,
      easing: Easing.inOut(Easing.ease),
      toValue: 0
    }).start()
  }, [visible])

  return (
    <Container
      style={{
        opacity,
      }}
    >
      <Icon
        width={22}
        icon={isSuccess ? 'checkOutline' : 'closeOutline'}
        color={isSuccess ? theme.success[900] : theme.danger.standard}
      />
      <Message>{text}</Message>
    </Container>
  )
}

ToastWeb.propTypes = {
  visible: PropTypes.bool.isRequired,
  duration: PropTypes.oneOfType([PropTypes.func, PropTypes.number]),
  setVisible: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
  text: PropTypes.text
}

ToastWeb.propTypes = {
  duration: 1000
}

export default ToastWeb
