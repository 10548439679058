import styled from 'styled-components/native'
import {
  StyleSheet,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
  Image,
} from 'react-native'
import { Paragraph1, Paragraph2 } from '@/styles/fonts'
import themes from '@/styles/themes/black'

export const ModuleView = styled(View)`
  margin-bottom: 30px;
`

export const ModuleButton = styled(TouchableOpacity)`
  background-color: black;
  border-radius: 5px;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`
export const AddButton = styled(TouchableOpacity)`
  background-color: black;
  border-radius: 5px;
  padding: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const ModuleButtonText = styled(Text)`
  color: white;
  font-size: 18px;
  font-weight: 400;
`

export const ThumbImageView = styled(View)`
  width: 100%;
  height: 460px;
`
export const ThumbImage = styled(Image)`
  width: 100%;
  height: 100%;
`

export const Container = styled(View)`
  background-color: black;
  height: 100%;
  display: flex;
  justify-content: center;
`

export const BackButton = styled(TouchableOpacity)`
  position: absolute;
  top: 10px;
  left: 10px;
`
