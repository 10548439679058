import React, { useCallback, useState, useEffect } from 'react'
import { FlatList } from 'react-native-gesture-handler'
import { useNavigation, useRoute } from '@react-navigation/native'
import PropTypes from 'prop-types'
import canAccess from '@/helpers/permissions'
import Avatar from '../Avatar'
import Group from '../Group'
import {
  Container,
  flatListStyles,
  ButtonNavigation
} from './styles'
import { ScreenSize, useScreenSize, useNumColumns, isMobile } from '@/styles'
import { useWindowDimensions } from 'react-native'

const ListViewEmployee = ({ employeeListData, setEditableEmployee,...props }) => {
  const screenSize = useWindowDimensions()
  const numColumns = useNumColumns()
  const navigation = useNavigation()
  const route = useRoute()
  
  const renderItem = useCallback(({ item }) => {

    const handleProfileButton = () => {
      if (setEditableEmployee && route.name === 'Employee') {
        setEditableEmployee({employee: item, visible: true})
        return
      }
      navigation.navigate('Profile', { id: item?.id })
    }

    return (
      <Container screenSize={screenSize.width}>
        <ButtonNavigation onPress={handleProfileButton}>
          <Avatar
            size={screenSize <= ScreenSize.Medium ? 45 : 75}
            elevation={1}
            source={item?.avatar ? { uri : item?.avatar}: null}
          />
        </ButtonNavigation>
        <Group
          handleProfileButton={handleProfileButton}
          title={item?.first_name + ' ' + item?.last_name}
          internalID={item?.internal_id}
          subTitle={item?.role}
          updatedAt={item?.updated_at}
          deactivated={item?.status_id !== 1}
          id={item?.id}
        />
      </Container>
    )
  }, [navigation, route.name, screenSize])

  return (
    <FlatList
      {...props}
      key={numColumns}
      data={employeeListData}
      keyExtractor={(item) => String(item.id)}
      renderItem={renderItem}
      numColumns={numColumns}
      contentContainerStyle={flatListStyles.content}
      // columnWrapperStyle={ !isMobile  && screen >= ScreenSize.Medium ? flatListStyles.columnWrapper : null}
      showsVerticalScrollIndicator={!isMobile}
    />
  )
}

ListViewEmployee.propTypes = {
  employeeListData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company: PropTypes.any,
    updated_at: PropTypes.string,
    deleted_at: PropTypes.string
  })),
  setEditableEmployee: PropTypes.func
}
export default ListViewEmployee
