import React from 'react'
import {
    View,
} from 'react-native'

import styles from './styles.js';

const ColumnsContainer = ({
    children
}) => {

    return (
        <View style={styles.ColumnsContainer}>
            {children}
        </View>
    )

}

export default ColumnsContainer