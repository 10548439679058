import * as React from 'react'
import { Checkbox as PaperCheckbox, useTheme } from 'react-native-paper'

const Checkbox = (props) => {
  const { colors } = useTheme()

  return (
    <PaperCheckbox.Android disableRipple color={colors.primary} {...props} />
  )
}

export default Checkbox
