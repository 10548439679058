import React, { useEffect, useState } from "react";
import ModalInputField from "./ModalInputField";
import { ModalFooter, ModalFooterActions } from "@twilio-paste/modal";
import { ModalBody, Box } from "@twilio-paste/core";
import { Button } from "@twilio-paste/button";

import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import ModalV2 from '@/components/ModalV2'
import DefaultButton from '@/components/Buttons/Default'


const ConversationTitleModal = (
  props
) => {
  const [title, setTitle] = useState(props.title);
  const [error, setError] = useState("");
  const [isFormDirty, setFormDirty] = useState(false);
  const accentColor = UseBrandingAccentColor();

  useEffect(() => {
    if (props.title !== title) {
      setTitle(props.title);
    }
  }, [props.title]);

  const onCancel = () => {
    setError("");
    setTitle(props.title);
    props.onCancel();
  };

  const onSave = async () => {
    if (title.length < 1) {
      return;
    }

    setError("");

    try {
      await props.onSave(title);
    } catch (e) {
      setError(e.message ?? "");
    }

    setTitle(props.title);
  };

  return (
    <>
      <ModalV2
        isVisible={props.isModalOpen}
        setVisibility={onCancel}
        title={props.type == "new" ? "New Conversation" : "Edit Conversation"}
      >
        <ModalBody>
          <Box as="form">
            <ModalInputField
              isFocused={true}
              label="Conversation Name"
              input={title}
              placeholder="Conversation Name"
              onChange={setTitle}
              onBlur={() => setFormDirty(true)}
              error={
                error
                  ? error
                  : isFormDirty && !title
                    ? "Add a conversation title to create a conversation."
                    : ""
              }
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <ModalFooterActions>
            <DefaultButton
              disabled={title.length < 1 || title.trim() === props.title}
              //isLoading={isLoading}
              label={'Create'}
              onPress={() => {
                onSave()
              }}
            />
          </ModalFooterActions>
        </ModalFooter>
      </ModalV2>
    </>
  );
};

export default ConversationTitleModal;
