import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/learning'
import * as api from '@/redux/api/learning'

function* getFeatured(action) {
  try {
    const payload = yield call(api.getFeatured, action)
    yield put({ type: constants.GET_FEATURED.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_FEATURED.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetFeatured() {
  yield takeLatest(constants.GET_FEATURED.ACTION, getFeatured)
}

function* getFiltered(action) {
  try {
    const payload = yield call(api.getFilters, action)
    yield put({ type: constants.GET_FILTERED.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_FILTERED.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetFiltered() {
  yield takeLatest(constants.GET_FILTERED.ACTION, getFiltered)
}

function* getArticles(action) {
  try {
    const payload = yield call(api.getArticles, action)
    yield put({ type: constants.GET_ARTICLES.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_ARTICLES.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetArticles() {
  yield takeLatest(constants.GET_ARTICLES.ACTION, getArticles)
}

function* getCourses(action) {
  try {
    const payload = yield call(api.getCourses, action.payload)
    yield put({ type: constants.GET_COURSES.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_COURSES.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetCourses() {
  yield takeLatest(constants.GET_COURSES.ACTION, getCourses)
}

function* getEvents(action) {
  try {
    const payload = yield call(api.getEvents, action.payload)
    yield put({ type: constants.GET_EVENTS.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_EVENTS.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetEvents() {
  yield takeLatest(constants.GET_EVENTS.ACTION, getEvents)
}

function* setReadLater(action) {
  try {
    const payload = yield call(api.readLater, action)
    yield put({ type: constants.SET_READLATER.SUCCESS, action })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.SET_READLATER.FAILED,
      message: error.message || error,
    })
  }
}

function* watchSetReadLater() {
  yield takeLatest(constants.SET_READLATER.ACTION, setReadLater)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetFeatured),
    fork(watchGetFiltered),
    fork(watchGetArticles),
    fork(watchSetReadLater),
    fork(watchGetCourses),
    fork(watchGetEvents),
  ])
}
