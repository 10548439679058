const { createContext } = require("react");

export const MessageStatus = {
    Sending: "Sending",
    Sent: "Sent",
    Delivered: "Delivered",
    Failed: "Failed",
    None: "none (incoming)",
    Read: "Read",
}

const ChatContext = createContext({
    listConversations: (convos) => { },
    updateCurrentConversation: (sid) => { },
    setLastReadIndex: (index) => { },
    removeConversation: (sid) => { },
    addMessages: (channelSid, messages) => { },
    pushMessages: (channelSid, messages) => { },
    removeMessages: (channelSid, messages) => { },
    updateLoadingState: (loadingState) => { },
    updateParticipants: (participants, sid) => { },
    updateUnreadMessages: (channel, unreadCount) => { },
    updateConversation: (channelId, parameters) => { },
    addAttachment: (channelSid, participant) => { },
    startTyping: (channelSid, participant) => { },
    endTyping: (channelSid, participant) => { },
    addNotifications: (notifications) => { },
    removeNotifications: (toIndex) => { },
    getParticipant: (id) => { },
    updateToken: (token, next) => { },
    setClient: (client) => { },
    updateRoles: (roles, sid) => { },
    addConvo: (convo) => { },

    participantsInfo: [],
    client: null,
    roles: {},

    conversations: [],
    attachments: {},
    lastReadIndex: -1,
    loadingStatus: true,
    messages: new Map(),
    participants: {},
    unreadMessages: {},
    typingData: {},
    notifications: [],
    sid: "",
    token: "",
    workspaceMembers: [],





});

export default ChatContext