import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        ...Platform.select({
            ios: {
                paddingHorizontal: 15,
                flex: 1
            },
            android: {
                paddingHorizontal: 15,
                flex: 1
            },
            web: {
                paddingHorizontal: 20,
            }
        })
    }
});
