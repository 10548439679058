import React, { useEffect, useLayoutEffect, useState } from 'react'
import { SafeAreaView, View } from "react-native";
import { createStackNavigator } from '@react-navigation/stack'
import { PageContentWrapper } from '@/components/Layout/Page/Layouts/RightSideDrawer/styles'
import VideoCall from '@/pages/VideoCall'
import { getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native'
import TwilioConversations from '@/pages/TwilioConversations/Mobile'
import MessageList from '@/pages/TwilioConversations/Mobile/components/MessageList'
import RightSideDrawerLayout from "@/components/Layout/Page/Layouts/RightSideDrawer/index";


const { Navigator, Screen } = createStackNavigator()

const ChatStack = () => {
  const navigation = useNavigation();
  const route = useRoute();

  useLayoutEffect(() => {
    const routeName = getFocusedRouteNameFromRoute(route);
    if (routeName == 'videocall') {
      navigation.setOptions({ tabBarVisible: false })
    } else {
      navigation.setOptions({ tabBarVisible: true })
    }
  }, [navigation, route])



  return (
    <Navigator screenOptions={{ headerShown: false }}>
      <Screen name="conversation">
        {(props) => (
          <RightSideDrawerLayout>
            <TwilioConversations {...props} />
          </RightSideDrawerLayout>
        )}
      </Screen>
      <Screen name="messages">
        {(props) => (
          <RightSideDrawerLayout
            scrollDisabled={true}
          >
            <MessageList {...props} />
          </RightSideDrawerLayout>
        )}
      </Screen>
      <Screen name="videocall">
        {(props) => (
          <SafeAreaView
            style={{
              flex: 1,
            }}
          >
            <View style={{
              flex: 1,
            }}>
              <VideoCall {...props} />
            </View>
          </SafeAreaView>
        )}
      </Screen>
    </Navigator>
  )
}

export default ChatStack