import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        width: '100%',
        flexDirection: 'column',
        maxWidth: 700,
        marginHorizontal: 'auto',
    },
    ImageContainer: {
        position: 'relative',
        width: '100%',
        aspectRatio: 1.5,
        borderRadius: 10,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
        marginBottom: 20
    },
    Image: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
    },
    TitleContainer: {

    },
    Title: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 20,
    },
    ExcerptContainer: {
        marginTop: 10
    },
    Excerpt: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 12,
    },
    TagsContainer: {
        flexDirection: 'row',
        marginTop: 10
    },
    Tag: {
        backgroundColor: 'rgba(27, 27, 27, 0.8)',
        paddingVertical: 3,
        paddingHorizontal: 3,
        fontSize: 10,
        borderRadius: 4,
        color: '#FFFFFF',
        marginRight: 5
    },
    AuthorContainer: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 15
    },
    AuthorAvatarContainer: {
        marginRight: 15
    },
    AuthorDetailsContainer: {
        justifyContent: 'center'
    },
    AuthorName: {
        fontSize: 13,
        fontWeight: '500'
    },
    AuthorRole: {
        fontSize: 12
    },
    ContentContainer: {
        marginTop: 20,
        fontFamily: 'WorkSans_400Regular',
        flex: 1
    },
    DateContainer: {

    },
    Date: {
        fontSize: 12
    }
});
