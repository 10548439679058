import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { Caption1 } from '@/styles/fonts'

export const Container = styled(TouchableOpacity)`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px 20px 10px 20px;
`

export const Ruler = styled.View`
  flex-grow: 1;
  height: 1px;
  margin-top: 3px;
  background-color: rgba(255, 255, 255, 0.4);
`

export const Label = styled(Caption1)`
  text-align: left;
  margin-left: 7px;
  color: ${({ color }) => (color ? color : 'rgba(255, 255, 255, 0.4)')};
`
