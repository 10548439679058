import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Toast from 'react-native-toast-message'

import { vw } from 'react-native-expo-viewport-units'
import { TextField } from '@/components'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { actions as authActions } from '@/redux/reducers/auth'
import { ActivityIndicator } from 'react-native-paper'
import { Entypo } from '@expo/vector-icons'
import * as Yup from 'yup'
import { isMobile, useScreenSize } from '@/styles'
import {
  TextButton,
  SubmitButton,
  Instructions,
  ForgotPasswordPageContent,
} from './styles'
import OnboardingPage from '@/components/OnboardingPage'
import ForgotPasswordDecoration from '@/assets/onboarding/ForgotPasswordDecoration'
import ToastWeb from '@/components/ToastWeb'

const initialValues = {
  email: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
})

function ForgotPassword({ navigation }) {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true)

      dispatch(
        authActions.forgotPassword(
          {
            email: values.email,
          },
          (error) => {
            setLoading(false)

            if (error) {
              if (isMobile) {
                Toast.show({
                  type: 'error',
                  position: 'bottom',
                  text1: 'Error, please try again.',
                })
              }
              setError(true)
            } else {
              navigation.navigate('OpenEmailApp')
            }
          },
        ),
      )
    },
    [navigation],
  )

  return (
    <OnboardingPage
      invert
      header={<OnboardingHeader withBackButton invertedContent />}
      decoration={<ForgotPasswordDecoration width={vw(45)} height="auto" />}
    >
      <ForgotPasswordPageContent screenSize={screenSize}>
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <OnboardingFormWrapper title={'Reset your\npassword!'}>
              <Instructions>
								Enter the e-mail associated with your account and we&apos;ll
								send an email with instructions to reset your password.
              </Instructions>

              <TextField
                label="Email"
                value={values.email}
                autoCapitalize="none"
                autoCompleteType="email"
                keyboardType="email-address"
                onChangeText={handleChange('email')}
              />

              <SubmitButton
                color="black"
                disabled={!isValid}
                onPress={handleSubmit}
              >
                {loading && (
                  <ActivityIndicator
                    size={20}
                    animating
                    color="white"
                    style={{ marginRight: 10 }}
                  />
                )}
                <TextButton>Send instructions</TextButton>
                <Entypo name="chevron-right" size={24} color="white" />
              </SubmitButton>
            </OnboardingFormWrapper>
          )}
        </Formik>
        <ToastWeb
          text="Error, please try again."
          visible={error}
          setVisible={setError}
          duration={3500}
        />
      </ForgotPasswordPageContent>
    </OnboardingPage>
  )
}

ForgotPassword.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default ForgotPassword
