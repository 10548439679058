import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Text,
    View,
    Modal,
    Button,
    Platform,
    TouchableOpacity,
    ScrollView,
    SafeAreaView
} from 'react-native'

import * as brandSelectors from '@/redux/selectors/branding'
import { permissions } from '@/helpers/permissionConstants'
import canAccess from '@/helpers/permissions'
import Icon from "@/components/Icon";
import { isMobile } from '@/styles'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import theme from '@/styles/themes/black'
import ReframeHorizontalLogo from '@/assets/brand/reframe-brand.png'
import { shadow2 } from '@/styles/shadows'

import styles from './styles.js';

const ModalV2 = ({
    isVisible,
    setVisibility,
    title,
    subtitle,
    children,
    actions
}) => {
    useEffect(() => {

    }, [])

    const branding = useSelector(brandSelectors.getSettings)

    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text,
    } = branding

    let animationType = 'none';
    if (Platform.OS == 'web') {
        animationType = 'fade';
    } else if (Platform.OS == 'ios' || Platform.OS == 'android') {
        animationType = 'slide';
    }

    let actionsOutput = new Array();
    if (actions) {
        actions.forEach(function (action) {
            let requiredActionPermissions = action.permissions;
            let actionPermitted = false;

            if (requiredActionPermissions.length == 0) {
                actionPermitted = true
            } else {
                requiredActionPermissions.forEach(function (permission) {
                    if (canAccess(permissions[permission])) {
                        actionPermitted = true
                    }
                })
            }

            if (actionPermitted) {
                let temporaryMenu = [];
                action.menu.forEach(function (menuItem) {
                    let requiredMenuItemPermissions = menuItem.permissions;
                    let menuItemPermitted = false;

                    if (requiredMenuItemPermissions.length == 0) {
                        menuItemPermitted = true
                    } else {
                        requiredMenuItemPermissions.forEach(function (permission) {
                            if (canAccess(permissions[permission])) {
                                menuItemPermitted = true
                            }
                        })
                    }

                    if (menuItemPermitted) {
                        temporaryMenu.push(
                            {
                                text: menuItem.title,
                                icon: menuItem.icon,
                                onPress: menuItem.onPress,
                            }
                        )
                    }
                })

                actionsOutput.push((
                    <ExpansiveMenu
                        mainIconColor={
                            isMobile
                                ? theme.basics.brand
                                : color_scheme_primary_button_text
                                    ? color_scheme_primary_button_text
                                    : theme.basics.background_white
                        }
                        mainIconBackground={
                            isMobile
                                ? 'transparent'
                                : color_scheme_primary_button_background
                                    ? color_scheme_primary_button_background
                                    : theme.basics.brand
                        }
                        desktopText={action.title}
                        btnTextColor={color_scheme_primary_button_text}
                        expansiveChildrens={temporaryMenu}
                    />
                ))
            }
        })
    }

    return (
        <Modal
            visible={isVisible}
            animationType={animationType}
            transparent={true}
        >
            <SafeAreaView style={{
                flex: 1
            }}>
                <View style={styles.Container}>
                    <View style={styles.Inner}>
                        <ScrollView>
                            <View style={[
                                styles.InnerHeader,
                                {}
                            ]}>
                                <View style={[
                                    styles.BackContainer,
                                    {}
                                ]}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setVisibility(false)
                                        }}
                                        style={[
                                            styles.BackButton,
                                            {}
                                        ]}
                                    >
                                        <Icon
                                            icon="arrowLeft"
                                            color={'#FFF'}
                                            height={Platform.OS == 'web' ? 8 : 8}
                                        />
                                    </TouchableOpacity>
                                </View>

                                {title ? (
                                    <View style={[
                                        styles.TitleContainer,
                                        {}
                                    ]}>
                                        <Text style={[
                                            styles.Title,
                                            {}
                                        ]}>
                                            {title}
                                        </Text>
                                    </View>
                                ) : null}

                                {actionsOutput ? (
                                    <View style={[
                                        styles.ActionsContainer,
                                        {}
                                    ]}>
                                        {actionsOutput}
                                    </View>
                                ) : null}
                            </View>

                            <View style={[
                                styles.InnerBody,
                                {}
                            ]}>
                                {children}
                            </View>
                        </ScrollView>
                    </View>
                </View>
            </SafeAreaView>
        </Modal>
    )

}

export default ModalV2