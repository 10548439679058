import React from 'react'
import { StatusBar, TouchableWithoutFeedback, Keyboard } from 'react-native'
import PropTypes from 'prop-types'
import { EditProfile as Form, Icon } from '@/components'
import theme from '@/styles/themes/black'
import { Container, Header, BackButton, Title } from './styles'

const EditProfile = ({ navigation }) => {
  return (
    <>
      <StatusBar backgroundColor={theme.basics.background_white} barStyle="dark-content" />
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <Container>
          <Header>
            <BackButton onPress={navigation.goBack}>
              <Icon icon="arrowLeft" height={14} width={14} color={theme.basics[900]} />
            </BackButton>
            <Title>Edit Profile</Title>
          </Header>
          <Form cancel={navigation.goBack} />
        </Container>
      </TouchableWithoutFeedback>
    </>
  )
}

EditProfile.propTypes = {
  navigation: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired
}

export default EditProfile
