import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@/components'
import { isMobile } from '@/styles'
import {
  Container,
  MemberContainer,
  MemberInfo,
  Name,
  Office,
  Button,
  ButtonText
} from './styles'

export const MemberList = ({
  source,
  name,
  office,
}) => {
  return (
    <Container>
      <MemberContainer>
        {source && <Avatar size={isMobile ? 45: 75} source={source ? {uri: source}: null} />}
        <MemberInfo>
          <Name>{name ?? ''}</Name>
          <Office>{office ?? ''}</Office>
        </MemberInfo>
      </MemberContainer>
      <Button>
        <ButtonText>Chat</ButtonText>
      </Button>
    </Container>
  )
}

MemberList.propTypes = {
  source: PropTypes.number,
  name: PropTypes.string,
  office: PropTypes.string,
}

export default MemberList
