import { Svg, Path } from 'react-native-svg'
const Read = ({
    color = "#606B85",
    style = {},
}) => (
    <Svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="#fff"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM13.7062 11.2113L9.7103 15.2047C9.61742 15.2983 9.50694 15.3725 9.3852 15.4232C9.26347 15.4739 9.1329 15.5 9.00103 15.5C8.86914 15.5 8.73857 15.4739 8.61684 15.4232C8.4951 15.3725 8.38462 15.2983 8.29175 15.2047L6.2938 13.208C6.10568 13.02 6 12.765 6 12.4992C6 12.2333 6.10568 11.9783 6.2938 11.7903C6.4819 11.6023 6.73703 11.4967 7.00307 11.4967C7.2691 11.4967 7.52423 11.6023 7.71234 11.7903L9.00103 13.0882L12.2877 9.79361C12.4758 9.60561 12.7309 9.5 12.9969 9.5C13.263 9.5 13.5181 9.60561 13.7062 9.79361C13.8943 9.9816 14 10.2366 14 10.5024C14 10.7683 13.8943 11.0233 13.7062 11.2113ZM9.7103 10.7047L13.7062 6.71128C13.8943 6.52328 14 6.26831 14 6.00244C14 5.73657 13.8943 5.4816 13.7062 5.29361C13.5181 5.10561 13.263 5 12.9969 5C12.7309 5 12.4758 5.10561 12.2877 5.29361L9.00103 8.58818L7.71234 7.29032C7.52423 7.10232 7.2691 6.99672 7.00307 6.99672C6.73703 6.99672 6.4819 7.10232 6.2938 7.29032C6.10568 7.47831 6 7.73328 6 7.99915C6 8.26502 6.10568 8.51999 6.2938 8.70799L8.29175 10.7047C8.38462 10.7983 8.4951 10.8725 8.61684 10.9232C8.73857 10.9739 8.86914 11 9.00103 11C9.1329 11 9.26347 10.9739 9.3852 10.9232C9.50694 10.8725 9.61742 10.7983 9.7103 10.7047Z"
            fill={color}
        />
    </Svg>
);

export default Read;
