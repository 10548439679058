import React, { useEffect, useState } from 'react'
import {
  Text,
  StyleSheet,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Dimensions,
  useWindowDimensions,
  Platform
} from 'react-native'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import Icon from '@/components/Icon'
import Line from './dotedLine'
//import MobileAnalytics from './index.mob'
import { isMobile } from '@/styles'
import ApiHandler from '@/api/ApiHandler'
import { useSideDrawer } from '@/hooks/sideDrawer'
import LineCustom from './dotedLineCustom'
import { Tooltip } from 'react-native-elements'
const screenWidth = Dimensions.get('window').width;
const screenHeight = Dimensions.get('window').height;
const OpsAnalytics = [
  {
    id: 1,
    title: 'Community Analytics',
    description:
      "Track your community's usage of Reframe based on number of teams, groups, members (seats), etc. ",
    navigationTo: 'CommunityAnalytics',
  },
  {
    id: 2,
    title: 'Member Engagement Analytics',
    description:
      'Track the level of engagement within your community, team and groups.  ',
    navigationTo: 'MemberAnalytics',
  },
  {
    id: 3,
    title: 'Publishing Analytics',
    description:
      'Track what articles and panels you are reading, top panels by company, trams, groups, by structure, strategy, segments, systems and solutions.',
    navigationTo: 'PublishingAnalytics',
  },
  {
    id: 4,
    title: 'Learning & Development Analytics',
    description:
      'Track what learning and development panels, curriculum and content you are reading across teams and groups by structure, strategy, segments, systems and solutions.  ',
    navigationTo: 'LearningAnalytics',
  },
  {
    id: 5,
    title: 'Event Analytics',
    description:
      'Track whats event and panels and content you are consuming across teams and groups by structure, strategy, segments, and solutions.  ',
    navigationTo: 'EventAnalytics',
  },

  {
    id: 6,
    title: 'Assessment Analytics',
    description:
      'See how members of your community are responding to assessments. ',
    navigationTo: 'AssessmentAnalytics',
  },
]

const Analytics = ({ navigation }) => {
  const [dashboardData, setDashboardData] = useState()
  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  const [noOfColumn, setNoOfColumn] = useState(2)
  const [trapeziumItemStartingWidth, setTrapeziumItemStartingWidth] = useState(100);

  const [windowMobHeight, setWindowMobHeight] = useState(150)

  useEffect(() => {
    getDashboardAnalyticss()
  }, [])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  useEffect(() => {
    let numCol = window.width > 900 ? 2 : 1;
    setNoOfColumn(numCol);
    if (window.width > 500 && window.width < 900) setWindowMobHeight(120);
    if (window.width < 550) setWindowMobHeight(160);
    if (window.width < 465) setWindowMobHeight(200);
  }, [window])

  const getDashboardAnalyticss = async () => {
    try {
      const response = await new ApiHandler().getDashboardAnalytics()
      if (response?.data) {
        setDashboardData(response?.data)
      }
    } catch (error) {
      console.log('ERRROR', error)
    } finally {
    }
  }

  const TrapeziumItem = (
    Value,
    Title,
    Description,
    BackgroundColor,
    Width
  ) => {
    return (
      <View style={[
        styles.TrapeziumItem,
        {
          width: Width
        }
      ]}>
        <View
          style={[
            styles.TrapeziumItemBackground,
            {
              borderTopColor: BackgroundColor
            }
          ]}
        />

        <View
          style={[
            styles.TrapeziumItemInner,
            {

            }
          ]}
        >
          <Tooltip
            skipAndroidStatusBar={true}
            height={90}
            width={250}
            backgroundColor="#FFFFFF"
            withOverlay={false}
            withPointer={true}
            overlayColor={'rgba(250, 250, 250, 0.00)'}
            alignItems={'center'}
            containerStyle={{
              borderRadius: 4,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              shadowColor: 'rgba(2, 2, 2, 0.31)',
              shadowOffset: { width: 4, height: 4 },
              shadowOpacity: 0.5,
              shadowRadius: 20,
            }}
            style={styles.TrapeziumItemInnerTooltip}
            popover={
              <View style={[
                styles.TrapeziumItemInnerTooltipPopover,
                {}
              ]}>
                <Text style={styles.TrapeziumItemInnerTooltipPopoverTitle}>
                  {Title}
                </Text>
                <Text style={[
                  styles.TrapeziumItemInnerTooltipPopoverDescription,
                  {
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }
                ]}>
                  {Description}
                </Text>
              </View>
            }
          >
            <View style={[
              styles.TrapeziumItemInnerTooltipBadge,
              {

              }
            ]}>
              <Text>{Value}</Text>
            </View>
          </Tooltip>
        </View>
      </View>
    )
  }

  return (
    <>
      <PageHeader
        title={'Analytics'}
        icon={'analytics'}
        backEnabled={false}
      />

      <PageContent>
        <ColumnsContainer>
          <Column
            flexBasis={'30%'}
          >
            <ContentBlockV1
              title={'Snapshot'}
            >
              <View style={styles.Trapezium}>
                {TrapeziumItem(
                  dashboardData?.number_of_community_members,
                  `Community Members`,
                  `The number of members in your community.`,
                  `#F3524E`,
                  '100%'
                )}
                {TrapeziumItem(
                  dashboardData?.number_of_platform_members,
                  `Subscriptions`,
                  `The number of employees included in your subscription.`,
                  `#F3524E`,
                  '90%'
                )}
                {TrapeziumItem(
                  dashboardData?.number_of_onboardings_completed,
                  `Onboardings Completed`,
                  `The number of members that completed onboarding.`,
                  `#2965BB`,
                  '80%'
                )}
                {TrapeziumItem(
                  dashboardData?.number_of_engaged_members,
                  `Engaged Members`,
                  `The number of members that have met the minimum engagement level.`,
                  `#2965BB`,
                  '70%'
                )}
                {TrapeziumItem(
                  dashboardData?.number_of_assessment_completions,
                  `Assessment Completions`,
                  `The number of members that have completed the Cultural Maturity Assessment.`,
                  `#8c7cde`,
                  '60%'
                )}
                {/*
                {TrapeziumItem(
                  dashboardData?.sentiment_satisfaction,
                  `Sentiment (Satisfaction)`,
                  `How satisfied your members are on average.`,
                  `#8c7cde`,
                )}
                {TrapeziumItem(
                  dashboardData?.sentiment_empowerment,
                  `Sentiment (Empowerment)`,
                  `How empowered your members feet on average.`,
                  `#8c7cde`,
                )}
                {TrapeziumItem(
                  dashboardData?.sentiment_encouragement,
                  `Sentiment (Encouragement)`,
                  `How encouraged your members feet on average.`,
                  `#8c7cde`,
                )}
                {TrapeziumItem(
                  dashboardData?.sentiment_enablement,
                  `Sentiment (Enablement)`,
                  `How enabled your members feet on average.`,
                  `#8c7cde`,
                )}
                */}
                {TrapeziumItem(
                  dashboardData?.number_of_exits,
                  `Exits`,
                  `The number of members that exited your community.`,
                  `#ffb94d`,
                  '50%'
                )}
              </View>
            </ContentBlockV1>
          </Column>

          <Column
            flexBasis={'70%'}
          >
            <ContentBlockV1
              title={'All Analytics'}
            >
              <View style={{ marginTop: 10 }}>
                <FlatList
                  data={OpsAnalytics}
                  renderItem={({ item }) => {
                    return (
                      <TouchableOpacity
                        onPress={() => {
                          navigation.navigate(item.navigationTo)
                        }}
                        style={[
                          styles.AnalyticsCategoryCard,
                          {

                          }
                        ]}
                      >
                        <Text style={styles.AnalyticsCategoryCardTitle}>{item.title}</Text>
                        <Text style={styles.AnalyticsCategoryCardDescription}>{item.description}</Text>
                      </TouchableOpacity>
                    )
                  }}
                  keyExtractor={(item) => item.id}
                  numColumns={Platform.OS === 'web' ? 2 : 1}
                  key={noOfColumn}
                />
              </View>
            </ContentBlockV1>
          </Column>
        </ColumnsContainer>
      </PageContent>
    </>
  )
}

export default Analytics
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 25,
  },
  analyticsTitleView: {
    flexDirection: 'row',
    marginLeft: 10,
    marginBottom: 20,
  },
  analyticsTitle: {
    fontSize: 40,
    lineHeight: 40,
    marginLeft: 6,
    fontFamily: "WorkSans_400Regular",
  },

  AnalyticsCategoryCard: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#f1524f',
    borderStyle: 'solid',
    padding: 20,
    flex: 1,
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20
  },
  AnalyticsCategoryCardTitle: {
    fontWeight: "600",
    fontSize: 15,
    color: '#f1524f',
    textAlign: 'left',
    fontFamily: "WorkSans_400Regular",
    marginBottom: 5
  },
  AnalyticsCategoryCardDescription: {
    fontSize: 13,
    fontWeight: '400',
    textAlign: 'left',
    color: '#1b1b1b',
    fontFamily: "WorkSans_400Regular",
  },

  Trapezium: {
    alignItems: 'center',
    alignSelf: 'flex-start',
    width: '100%',
  },
  TrapeziumItem: {
    marginTop: 4,
    flex: 1,
  },
  TrapeziumItemBackground: {
    height: 0,
    borderTopWidth: 58,
    borderRadius: 4,
    borderLeftWidth: 8,
    borderLeftColor: 'transparent',
    borderRightWidth: 8,
    borderRightColor: 'transparent',
    borderStyle: 'solid',
  },
  TrapeziumItemInner: {
    position: 'absolute',
    flexDirection: 'row',
    top: 5,
    width: '100%',
    justifyContent: 'center'
  },
  TrapeziumItemInnerTooltip: {

  },
  TrapeziumItemInnerTooltipBadge: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  TrapeziumItemInnerTooltipPopover: {
    width: '100%',
  },
  TrapeziumItemInnerTooltipPopoverTitle: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 16,
    marginBottom: 6,
    fontFamily: "WorkSans_400Regular",
    color: '#f3524e'
  },
  TrapeziumItemInnerTooltipPopoverDescription: {
    fontSize: 13,
    color: '#1b1b1b',
    lineHeight: 16,
    fontFamily: "WorkSans_400Regular",
  },
})
