import _ from 'lodash'

export const loading = (state) => _.get(state, 'teams.loading')

export const team = (state) => _.get(state, 'teams.team')
export const data = (state) => _.get(state, 'teams.data')
export const otherTeams = (state) => _.get(state, 'teams.otherTeams')
export const posts = (state) => _.get(state, 'teams.posts')
export const members = (state) => _.get(state, 'teams.members')
export const can_load_more = (state) => _.get(state, 'teams.page') < _.get(state, 'teams.last_page')
export const next_page = (state) => _.get(state, 'teams.page') + 1
export const current_page = (state) => _.get(state, 'teams.page')