import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import {
    TouchableOpacity,
    View,
    Image,
    StyleSheet,
    Modal,
    ActivityIndicator,
    SafeAreaView,
    Text,
    Platform,
} from "react-native";


import moment from 'moment'
import { FontAwesome } from '@expo/vector-icons'

import { actions as articleActions } from '@/redux/reducers/article'
import { actions as eventActions } from '@/redux/reducers/event'
import { actions as courseActions } from '@/redux/reducers/course'
import { actions as postActions } from '@/redux/reducers/posts'

import PlaceholderImageCover from '@/assets/cover_placeholder.png'

import GenericContentCard from '@/components/Feed/Card/Content'


import styles from './styles.js';

const LearningContentCard = ({
    type,
    post
}) => {
    const dispatch = useDispatch()
    const navigation = useNavigation()

    const toggleReadLater = () => {
        if (type == 'App\\Article') {
            dispatch(articleActions.setReadLater(post, () => {
                dispatch(
                    postActions.feeds_index({ page: 1 }, (err) => {
                    })
                )
            }))
        }

        if (type == 'App\\Event') {
            dispatch(eventActions.toggleReadLaterAction(post, () => {
                dispatch(
                    postActions.feeds_index({ page: 1 }, (err) => {
                    })
                )
            }))
        }

        if (type == 'App\\Course') {
            dispatch(courseActions.toggleReadLaterAction(post, () => {
                dispatch(
                    postActions.feeds_index({ page: 1 }, (err) => {
                    })
                )
            }))
        }
    }

    const navigateToLearningContentView = () => {
        if (type == 'App\\Article') {
            navigation.navigate('ViewArticle', { id: post?.id })
        }

        if (type == 'App\\Event') {
            navigation.navigate('ViewEvent', { id: post?.id })
        }

        if (type == 'App\\Course') {
            navigation.navigate('ViewCourse', { id: post?.id })
        }
    }

    return (
        <GenericContentCard
            type={type}
            post={post}
        >
            <View style={styles.Container}>
                <TouchableOpacity
                    style={styles.Card}
                    onPress={navigateToLearningContentView}
                >
                    <View style={styles.CoverContainer}>
                        <TouchableOpacity
                            style={styles.CoverButton}
                            onPress={toggleReadLater}
                        >
                            <FontAwesome
                                name={post?.read_later ? 'bookmark' : 'bookmark-o'}
                                size={19}
                                color={post?.read_later ? '#fff' : '#fff'}
                            />
                        </TouchableOpacity>

                        {type == 'App\\Event' ? (
                            <View style={styles.CoverDateContainer}>
                                <Text style={styles.CoverDate}>{moment(post?.start_date).format('MMM DD')}</Text>
                            </View>
                        ) : null}

                        <Image
                            style={styles.CoverImage}
                            source={post?.image ? { uri: post?.image } : PlaceholderImageCover}
                            resizeMode="cover"
                        />
                    </View>

                    <View style={styles.MetaContainer}>
                        <View style={styles.TitleContainer}>
                            <Text style={styles.Title}>{post?.name ?? ''}</Text>
                        </View>

                        <View style={styles.ExcerptContainer}>
                            <Text numberOfLines={2} style={styles.Excerpt}>{post?.excerpt ? post?.excerpt : ''}</Text>
                        </View>

                        {post?.employee_lifecycle_stage || post?.five_s_category ? (
                            <View style={styles.TagsContainer}>
                                {post?.employee_lifecycle_stage?.name ? (
                                    <Text style={styles.Tag}>{post?.employee_lifecycle_stage?.name}</Text>
                                ) : null}

                                {post?.five_s_category?.name ? (
                                    <Text style={styles.Tag}>{post?.five_s_category?.name}</Text>
                                ) : null}
                            </View>
                        ) : null}
                    </View>
                </TouchableOpacity>
            </View>
        </GenericContentCard>
    )
}

export default LearningContentCard