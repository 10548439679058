import React from 'react'
import PropTypes from 'prop-types'

import icons from './icons'

export default function Icon({ icon, color, width, height, style }) {
  const IconComponent = icons[icon]

  if (!IconComponent) {
    console.error(`${icon} icon does not exist`)
    return undefined
  }

  return <IconComponent color={color} width={width} height={height || width} style={style} />
}

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
