import { isMobile } from '@/styles'
import { Dimensions, Platform, SafeAreaView, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

const { width } = Dimensions.get('window')

export const Container = styled(SafeAreaView)`
   width: ${width}px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 3px #cfcfcf;
    padding-top: 15px;
    min-height: 100px;
    padding-bottom: 15px;
    background-color: white;
`

export const ContainerView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: ${width / 1.1}px;
  align-self: center;
`

export const Title = styled.Text`
  font-size: 20;
  font-weight: 500;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: ${({ withSearch }) =>
    isMobile ? (withSearch ? width / 1.5 + 'px' : width / 1.33 + 'px') : '90%'};
`
export const SearchView = styled.View`
  width: ${isMobile ? "90%" : '100%'};
  flex-direction: row;
`

export const SearchButton = styled(TouchableOpacity)`
  background-color: ${isMobile ? "black" : "transparent"};
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
`

export const SearchBoxMobile = styled.View`
  width: ${width / 1.1};
  align-self: center;
  flex-direction: row;
  /* border-width: 1px;
  border-color: #ccc; */
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
`

export const Horizontal = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  /* min-width: 100px; */
`
