import React, { useEffect, useState } from 'react';
import {
    ScrollView,
    SafeAreaView,
    View,
    Text,
    FlatList,
    TouchableOpacity
} from 'react-native'
import { formatDistance } from 'date-fns'

import { Icon, Avatar, FeedCardAuthorInfo } from '@/components'
import { Commenter, Comment, CreatedOn } from "./styles"


export const fileSharedWithYou = (data, created_at) => {
    return <Text>{"New file shared with you"}</Text>
    // Needs To Be Setup When Feature Is Built
}

export const invitedToAGroup = (data, created_at) => {
    const { message, group, inviter } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Group Invite</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const invitedToATeam = (data, created_at) => {
    const { message, team, inviter } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Team Invite</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const newArticlePublished = (data, created_at) => {
    const { message, article, author } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Article Published</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const newCoursePublished = (data, created_at) => {
    const { message } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Course Published</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}
export const newEventPublished = (data, created_at) => {
    const { message } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Event Published</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}
export const newCalendarInvite = (data, created_at) => {
    const { message, calendar_entry } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Calendar Invite</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const calendarInviteUpdated = (data, created_at) => {
    const { message } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>Calendar Invite Updated</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const calendarEntryDeleted = (data, created_at) => {
    const { message } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>Calendar Cancellation</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const newPostComment = (data, created_at) => {
    const { message, commenter, post, comment } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Post Comment</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const newCommentLike = (data, created_at) => {
    const { message, liker, comment } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Comment Like</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const newPostLike = (data, created_at) => {
    const { message, liker, post } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Post Like</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const newWorkspaceMember = (data, created_at) => {
    const { message, member } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>New Community Member</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

export const otherActivity = (data, created_at) => {
    const { message } = data;

    return (
        <View style={{ flex: 1, flexDirection: "row", }} >
            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >
                    <Commenter>-</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>
                </View>
                <Comment  >{`${message}`.trim()}</Comment>
            </View>
        </View>
    )
}

{/*
export const otherActivityBackup = (data, created_at) => {
    return <Text>{"Other Activity"}</Text>
}

export const newPostCommentBackup = (data, created_at) => {

    const { message, comment, commenter } = data;

    const first_name = commenter?.first_name || "";
    const last_name = commenter?.last_name || "";
    const avatarSource = commenter?.avatar;


    return (
        <View style={{ flex: 1, flexDirection: "row", }} >


            <Avatar size={50} elevation={2} source={avatarSource} />

            <View
                style={{
                    flex: 1,
                    justifyContent: "center",
                    marginLeft: 12
                }}
            >
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}
                >

                    <Commenter>{`${first_name} ${last_name}`.trim()}</Commenter>
                    <CreatedOn>
                        {String(formatDistance(new Date(created_at), Date.now())) ?? ''}
                    </CreatedOn>

                </View>

                <Comment  >{`${message}`.trim()}</Comment>

            </View>




        </View>
    )

}
*/}
