import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute, useNavigation } from "@react-navigation/native";
import {
    Text,
    View,
    TouchableOpacity,
    Platform,
    Image
} from 'react-native'

import * as brandSelectors from '@/redux/selectors/branding'
import { permissions } from '@/helpers/permissionConstants'
import canAccess from '@/helpers/permissions'
import Icon from "@/components/Icon";
import { isMobile } from '@/styles'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import theme from '@/styles/themes/black'
import ReframeHorizontalLogo from '@/assets/brand/reframe-brand.png'
import { shadow2 } from '@/styles/shadows'
import {
    Breadcrumb,
} from '@/components'

import styles from './styles.js';

const PageHeader = ({
    title,
    icon,
    backEnabled,
    breadcrumbs,
    actions
}) => {
    const route = useRoute();
    const navigation = useNavigation();
    const branding = useSelector(brandSelectors.getSettings)

    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text,
        color_scheme_page_topbar,
        logo
    } = branding

    let actionsOutput = new Array();
    if (actions) {
        actions.forEach(function (action) {
            let requiredActionPermissions = action.permissions;
            let actionPermitted = false;

            if (requiredActionPermissions.length == 0) {
                actionPermitted = true
            } else {
                requiredActionPermissions.forEach(function (permission) {
                    if (canAccess(permissions[permission])) {
                        actionPermitted = true
                    }
                })
            }

            if (actionPermitted) {
                let temporaryMenu = [];
                action.menu.forEach(function (menuItem) {
                    let requiredMenuItemPermissions = menuItem.permissions;
                    let menuItemPermitted = false;

                    if (requiredMenuItemPermissions.length == 0) {
                        menuItemPermitted = true
                    } else {
                        requiredMenuItemPermissions.forEach(function (permission) {
                            if (canAccess(permissions[permission])) {
                                menuItemPermitted = true
                            }
                        })
                    }

                    if (menuItemPermitted) {
                        temporaryMenu.push(
                            {
                                text: menuItem.title,
                                icon: menuItem.icon,
                                onPress: menuItem.onPress,
                            }
                        )
                    }
                })

                actionsOutput.push((
                    <ExpansiveMenu
                        mainIconColor={
                            isMobile
                                ? theme.basics.brand
                                : color_scheme_primary_button_text
                                    ? color_scheme_primary_button_text
                                    : theme.basics.background_white
                        }
                        mainIconBackground={
                            isMobile
                                ? 'transparent'
                                : color_scheme_primary_button_background
                                    ? color_scheme_primary_button_background
                                    : theme.basics.brand
                        }
                        desktopText={action.title}
                        btnTextColor={color_scheme_primary_button_text}
                        expansiveChildrens={temporaryMenu}
                    />
                ))
            }
        })
    }

    return (
        <View style={[
            styles.Container,
            {}
        ]}>
            <View style={[
                styles.FullBleedContainer,
                {}
            ]}>
                {route.name == 'Home' && (Platform.OS == 'ios' || Platform.OS == 'android') ? (
                    <View style={{
                        width: '100%',
                        height: 60,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: color_scheme_page_topbar ? color_scheme_page_topbar : theme.basics.background_white,
                        shadow2
                    }}>
                        <Image
                            resizeMode="contain"
                            source={
                                logo ? { uri: logo } : ReframeHorizontalLogo
                            }
                            style={{
                                width: '100%',
                                height: 45
                            }}
                        />
                    </View>
                ) : null}
            </View>

            <View style={[
                styles.PaddedContainer,
                {}
            ]}>
                <View style={[
                    styles.PaddedContainerTop,
                    {}
                ]}>
                    {backEnabled ? (
                        <View style={[
                            styles.BackContainer,
                            {}
                        ]}>
                            <TouchableOpacity
                                onPress={() => navigation?.goBack?.()}
                                style={[
                                    styles.BackButton,
                                    {}
                                ]}
                            >
                                <Icon
                                    icon="arrowLeft"
                                    color={'#FFF'}
                                    height={Platform.OS == 'web' ? 13 : 10}
                                />
                            </TouchableOpacity>
                        </View>
                    ) : null}

                    {icon ? (
                        <View style={[
                            styles.IconContainer,
                            {}
                        ]}>
                            <Icon icon={icon} height={Platform.OS == 'web' ? 26 : 23} width={Platform.OS == 'web' ? 26 : 23} color={'#000'} style={[
                                styles.Icon,
                                {}
                            ]} />

                        </View>
                    ) : null}

                    {title ? (
                        <View style={[
                            styles.TitleContainer,
                            {}
                        ]}>
                            <Text style={[
                                styles.Title,
                                {}
                            ]}>
                                {title}
                            </Text>
                        </View>
                    ) : null}

                    {actionsOutput ? (
                        <View style={[
                            styles.ActionsContainer,
                            {}
                        ]}>
                            {actionsOutput}
                        </View>
                    ) : null}
                </View>

                {breadcrumbs ? (
                    <View style={[
                        styles.PaddedContainerBottom,
                        {}
                    ]}>
                        <Breadcrumb routes={breadcrumbs} />
                    </View>
                ) : null}

            </View>
        </View>
    )

}

export default PageHeader