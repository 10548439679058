import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, useWindowDimensions } from 'react-native'

import ApiHandler from '@/api/ApiHandler'
import CategoryView from '@/pages/Analytics/Components/Views/Category'
import { BackArrow } from '@/components'
import { useSideDrawer } from '@/hooks/sideDrawer'

import styles from './styles.js';

const CulturalAnalytics = () => {
  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  const items = {
    cultural_maturity_score_by_category: {
      label: 'Cultural Maturity Score By Category',
      code: 'cultural_maturity_score_by_category',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        structure_cultural_maturity_score: {
          key: 'structure_cultural_maturity_score',
          label: 'Structure',
          color: 'rgba(240, 83, 67, 1)'
        },
        segments_cultural_maturity_score: {
          key: 'segments_cultural_maturity_score',
          label: 'Segments',
          color: 'rgba(237, 129, 118, 1)'
        },
        strategy_cultural_maturity_score: {
          key: 'strategy_cultural_maturity_score',
          label: 'Strategy',
          color: 'rgba(218, 159, 53, 1)'
        },
        systems_cultural_maturity_score: {
          key: 'systems_cultural_maturity_score',
          label: 'Systems',
          color: 'rgba(219, 181, 114, 1)'
        },
        solutions_cultural_maturity_score: {
          key: 'solutions_cultural_maturity_score',
          label: 'Solutions',
          color: 'rgba(15, 15, 15, 0.95)'
        }
      },
      type_config: {
        yAxisMin: 1,
        yAxisMax: 5
      }
    },
    total_cultural_maturity_score: {
      label: 'Overall Cultural Maturity Score',
      code: 'total_cultural_maturity_score            ',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        total_cultural_maturity_score: {
          key: 'total_cultural_maturity_score',
          label: 'Score'
        }
      },
      type_config: {
        yAxisMin: 1,
        yAxisMax: 5
      }
    },
    engagement_level: {
      label: 'Engagement Level',
      code: 'engagement_level',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        engagement_level: {
          key: 'engagement_level',
          label: 'Engagement Level'
        }
      },
      type_config: {
        yAxisMin: 1,
        yAxisMax: 12
      }
    },
    segment: {
      label: 'Segment',
      code: 'segment',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        segment: {
          key: 'segment',
          label: 'Segment'
        }
      },
      type_config: {
        yAxisMin: 1,
        yAxisMax: 9
      }
    },
  };

  const filters = {
    scope: true
  }

  return (
    <CategoryView
      title={'Cultural Maturity Analytics'}
      apiHandlerFunction={new ApiHandler().getAssesmentCulturalData}
      items={items}
      filters={filters}
    />
  )
}

export default CulturalAnalytics
