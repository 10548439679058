import styled from 'styled-components/native'
import { Headline6SemiBold, Subtitle1 } from '@/styles/fonts'

export const Warning = styled(Subtitle1)`
	color: ${({ theme }) =>  theme.basics.brand};
	margin: 0 0 20px;
`

export const LeaveButton = styled.TouchableOpacity`
	background-color: ${({ theme, cancel }) => cancel ? theme.basics.background_white : theme.basics.brand};
	align-items: center;
	justify-content: center;
	width: ${({ width }) => width || 205}px;
	height: 40px;
	margin: 0 8px;
	border: 1px solid ${({theme}) => theme.basics.brand};
	border-radius: 3px;
`

export const LeaveButtonText = styled(Headline6SemiBold)`
	width: 100%;
	text-align: center;
	color: ${({ theme, cancel }) => cancel ? theme.basics.brand : theme.basics.background_white};
`
