import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/common'
import * as api from '@/redux/api/common'

function* getFiveSCategories(action) {
  try {
    const payload = yield call(api.getFiveSCategories, action)
    yield put({ type: constants.GET_FIVESCATEGORIES.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_FIVESCATEGORIES.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetFiveCategories() {
  yield takeLatest(constants.GET_FIVESCATEGORIES.ACTION, getFiveSCategories)
}

function* getLifecycleStages(action) {
  try {
    const payload = yield call(api.getLifecycleStages, action)
    yield put({ type: constants.GET_LIFECIRCLESTAGES.SUCCESS, payload })
  } catch (error) {
    console.log('----', error)
    yield put({
      type: constants.GET_LIFECIRCLESTAGES.FAILED,
      message: error.message || error,
    })
  }
}

function* watchGetLifeCircleStages() {
  yield takeLatest(constants.GET_LIFECIRCLESTAGES.ACTION, getLifecycleStages)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchGetFiveCategories), fork(watchGetLifeCircleStages)])
}
