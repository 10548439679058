import { all, put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects'
import { constants } from '@/redux/reducers/pushNotifications'
import * as api from '@/redux/api/pushNotifications'

// STORE TOKEN
function* store(action) {
    try {
        const payload = yield call(api.storeToken, action) // call used for call function
        yield put({ type: constants.STORE_TOKEN.SUCCESS, action }) // put used for dispach action  
        action.next && action.next(undefined, payload)
    } catch (e) {
        yield put({
            type: constants.STORE_TOKEN.FAILED,
            message: e.message || e,
        })
        action.next && action.next(e)
    }
}
function* watchStore() {
    yield takeLatest(constants.STORE_TOKEN.ACTION, store)
}
function* remove(action) {
    try {
        const payload = yield call(api.removeToken, action)
        yield put({ type: constants.REMOVE_TOKEN.SUCCESS, payload })
        action.next && action.next(undefined, payload)
    } catch (e) {
        yield put({
            type: constants.REMOVE_TOKEN.FAILED,
            message: e.message || e,
        })
        action.next && action.next(e)
    }
}
function* watchRemove() {
    yield takeLatest(constants.REMOVE_TOKEN.ACTION, remove)
}

// REMOVE
// function* remove(action) {
//     try {
//       const payload = yield call(api.remove, action)
//       yield put({ type: constants.REMOVE.SUCCESS, payload, action })
//       action.next && action.next(undefined, payload)
//     } catch (error) {
//       yield put({
//         type: constants.REMOVE.FAILED,
//         message: error.message || error,
//       })
//       action.next && action.next(error)
//     }
//   }

//   function* watchRemove() {
//     yield takeLatest(constants.REMOVE.ACTION, remove)
//   }

export function* rootSaga() {
    yield all([
        fork(watchStore),
        fork(watchRemove)
    ])
}