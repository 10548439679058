import Button from '@/components/Button'
import { Headline4 } from '@/styles/fonts'
import { shadow2 } from '@/styles/shadows'
import styled from 'styled-components/native'

export const Container = styled.View`
	width: 328px;
	height: 472px;
	background: ${({ theme }) => theme.basics.background_white};
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
	align-items: center;
`

export const CloseModalButton = styled.TouchableOpacity`
	align-self: flex-end;
	margin: 13px 13px 0 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`

export const Title = styled(Headline4)`
	color: ${({theme}) => theme.basics.text_black};
`

export const ContainerCoverImage = styled.View`
	width: 282px;
	height: 82px;
	position: relative;
	margin: 30px 0;
`

export const CoverImage = styled.Image`
	height: 100%;
	width: 100%;
`

export const CoverButton = styled.TouchableOpacity`
	position: absolute;
	bottom: -10px;
	right: -10px;
	width: 36px;
	height: 36px;
	border-radius: 36px;
	background-color: ${({ theme }) => theme.basics.background_white};
	justify-content: center;
	align-items: center;
	${shadow2};
`

export const SubmitButton = styled(Button)`
  align-items: center;
  justify-content: center;
	background-color: ${({ theme }) => theme.basics.brand};
	width: 127px;
	height: 40px;
	border-radius: 3px;
	align-self: flex-end;
`
export const TextButton = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`

export const FormikContainer = styled.View`
	flex: 1;
	height: 100%;
	width: 100%;
	justify-content: space-around;
	padding: 0 20px 20px;
`
