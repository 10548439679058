import React, { useState, useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Image,
	TouchableOpacity,
	Dimensions,
	Platform,
	FlatList,
	TextInput,
} from "react-native";
import Button from "./Button";
import { isMobile } from "@/styles";
import Modal from "react-native-modal";
import BottomSheet from "./BottomSheet";
import ApiHandler from "@/api/ApiHandler";
import OutlineButton from "./OutlineButton";
import { Ionicons } from "@expo/vector-icons";
import { BackArrow, Icon } from "@/components";
const deviceHeight = Dimensions.get("window").height;
import EventCard from "@/components/Learning/EventCard";
import { ProgressBar, Colors } from "react-native-paper";
import { ScrollView } from "react-native-gesture-handler";
import CourseCard from "@/components/Learning/CourseCard";
import ArticleCard from "@/components/Learning/ArticleCard";
import CreateRewardModal from "../components/CreateRewardModal";
import { Carousel, SearchHeader, SearchWeb } from "@/components";
import ExpansiveMenu from "../../../components/ExpansiveMenu/index";
import CreateMilestoneModal from "../components/CreateMilestoneModal";
import Toast from "react-native-toast-message";
import SendManualRewardModal from "./SendManualReward";
import PlaceholderImageCover from "@/assets/cover_placeholder.png";
import { right } from "styled-system";
import ModalV2 from '@/components/ModalV2'
import ContentBlockV1 from '@/components/ContentBlockV1'

export const MilestoneComponent = ({
	width,
	height,
	background,
	description,
	title,
	profileImg,
	rewardedTxt,
	pendingTxt,
	startTxt,
	importedTxt,
	type = "manager",
	index,
	milestoneId,
	avatar,
	awarded_employees,
	completed,
	pending,
	yet_to_start,
	criteriaTypeLabel,
}) => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [milestones, setMilestones] = useState([]);
	const [selectedMilestone, setSelectedMilestone] = useState({});
	const [completeEmployees, setCompleteEmployees] = useState([]);
	const [pendingEmployees, setPendingEmployees] = useState([]);
	const [yetToStart, setYetToStart] = useState([]);

	const toggleModal = (index) => {
		setModalVisible(!isModalVisible);
	};
	const hideModal = () => {
		setModalVisible(false);
	};

	useEffect(() => {
		getMilestoneById();
	}, []);

	const getMilestoneById = async () => {
		try {
			const response = await new ApiHandler().getMilestoneById(milestoneId);

			if (response?.data) {
				setSelectedMilestone(response?.data?.data);
				setCompleteEmployees(response?.data?.data?.completed_employees);
				setPendingEmployees(response?.data?.data?.pending_employees);
				setYetToStart(response?.data?.data?.yet_to_start_employees);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	const deleteMilestone = async () => {
		try {
			const response = await new ApiHandler().deleteMilestones(milestoneId);
			console.log("@@@%%%Delete Milestone%%%????", response);

			Toast.show({
				type: "success",
				position: "top",
				text1: "Your Milestone is successfully Deleted",
			});
		} catch (error) {
			console.log(">>>>", error);
		} finally {
		}
	};

	const EditModal = () => {
		const [deleteModal, setDeleteModal] = useState(false);
		const [isMilestoneModalVisible, setMilestoneModalVisible] = useState(false);

		const toggleRightModal = () => {
			setMilestoneModalVisible(!isMilestoneModalVisible);
		};
		const toggleDeleteModal = () => {
			setDeleteModal(!deleteModal);
		};
		const DeleteModal = () => {
			return (
				<View style={{ flex: 1 }}>
					<Modal
						isVisible={deleteModal}
						animationIn="slideInUp"
						animationOut="slideOutDown"
					>
						<View style={styles.modal}>
							<View style={styles.deleteModalContainer}>
								<View style={styles.deleteModalHeader}>
									<Text style={styles.deleteRewardTitle}>
										{"Delete Reward"}
									</Text>
									<TouchableOpacity onPress={toggleDeleteModal}>
										<Ionicons
											name="close-outline"
											size={25}
											color={"#323232"}
										/>
									</TouchableOpacity>
								</View>
								<Text style={styles.deleteModalDescription}>
									Do you want to delete the Milestone?
								</Text>
								<View style={styles.deleteModalButtons}>
									<Button
										label="Delete"
										onPress={() => {
											deleteMilestone();
											toggleDeleteModal();
											hideModal();
										}}
										containerStyle={{
											backgroundColor: "#B40027",
											width: 127,
											height: 40,
											marginTop: 0,
											marginRight: 5,
										}}
									/>
									<OutlineButton
										label="Cancel"
										onPress={toggleDeleteModal}
										containerStyle={{
											borderColor: "#1B1B1B",
											width: 127,
											height: 40,
											marginTop: 0,
											marginLeft: 5,
										}}
										labelStyle={{
											color: "#1B1B1B",
										}}
									/>
								</View>
							</View>
						</View>
					</Modal>
				</View>
			);
		};
		const expansiveChildren = [
			{ text: "Edit", onPress: () => toggleRightModal() },
			{ text: "Delete", onPress: () => toggleDeleteModal() },
		];

		return (
			<>
				<ModalV2
					isVisible={isModalVisible}
					setVisibility={hideModal}
					title={selectedMilestone?.name}
					actions={
						[
							{
								title: 'Manage',
								type: 'menu',
								permissions: [

								],
								menu: [
									{
										title: 'Edit',
										icon: false,
										permissions: [

										],
										onPress: () => {
											toggleRightModal()
										}
									},
									{
										title: 'Delete',
										icon: false,
										permissions: [

										],
										onPress: () => {
											toggleDeleteModal()
										}
									}
								]
							}
						]
					}
				>
					<View style={styles.descriptionView}>
						<Text style={styles.modalDescription}>
							{selectedMilestone && selectedMilestone?.description}
						</Text>
					</View>

					<View style={styles.modalSection}>
						<Text style={styles.modalSectionTitle}>{"Analytics"}</Text>
						<Text style={styles.modalSectionDescription}>{"Keep track of how your members are tracking towards completing the milestone."}</Text>

						<View style={{ flexDirection: "row", zIndex: -999 }}>
							<View>
								<View style={styles.photoView}>
									<Image
										source={{ uri: completeEmployees[0]?.avatar }}
										style={styles.photo}
									/>
									<Image
										source={{ uri: completeEmployees[1]?.avatar }}
										style={styles.photo}
									/>
									<View>
										<Image
											source={{ uri: completeEmployees[2]?.avatar }}
											style={styles.photo}
										/>
										<Text style={styles.imgCountModal}>
											{"+"}
											{
												completeEmployees &&
												completeEmployees?.completed?.length
											}
										</Text>
									</View>
								</View>
								<Text style={styles.completeTxt}>{"Completed"}</Text>
							</View>
							<View>
								<View style={styles.photoView}>
									<Image
										source={{
											uri: pendingEmployees[0]?.avatar,
										}}
										style={styles.photo}
									/>
									<View>
										<Image
											source={{
												uri: pendingEmployees[1]?.avatar,
											}}
											style={styles.photo}
										/>
										<Text style={styles.imgCountModal}>
											{"+"}
											{selectedMilestone &&
												selectedMilestone?.pending_employees?.length}
										</Text>
									</View>
								</View>
								<Text style={styles.completeTxt}>{"Pending"}</Text>
							</View>
							<View>
								<View style={styles.photoView}>
									<Image
										source={{
											uri: yetToStart[0]?.avatar,
										}}
										style={styles.photo}
									/>
									<View>
										<Image
											source={{
												uri: yetToStart[1]?.avatar,
											}}
											style={styles.photo}
										/>
										<Text style={styles.imgCountModal}>
											{"+"}
											{selectedMilestone &&
												selectedMilestone?.yet_to_start_employees?.length}
										</Text>
									</View>
								</View>
								<Text style={styles.completeTxt}>{"Yet to start"}</Text>
							</View>
						</View>
					</View>

					<View style={styles.modalSection}>
						<Text style={styles.modalSectionTitle}>{"Criteria"}</Text>
						<Text style={styles.modalSectionDescription}>{"The specified criterias below must be met by a member to consider the milestone completed by that member."}</Text>
						<View style={styles.pointsView}>
							<View>
								<Text style={styles.points}>
									{selectedMilestone && selectedMilestone?.engagement_points ? selectedMilestone?.engagement_points : '-'}
								</Text>
								<Text style={styles.pointsTitle}>{"Engagement Points"}</Text>
							</View>
							<View>
								<Text style={styles.points}>
									{selectedMilestone && selectedMilestone?.cultural_maturity_points ? selectedMilestone?.cultural_maturity_points : '-'}
								</Text>
								<Text style={styles.pointsTitle}>
									{"Cultural Maturity Points"}
								</Text>
							</View>
							<View>
								<Text style={styles.points}>
									{selectedMilestone && selectedMilestone?.total_points ? selectedMilestone?.total_points : '-'}
								</Text>
								<Text style={styles.pointsTitle}>{"Total Points"}</Text>
							</View>
						</View>
					</View>

					<View style={styles.modalSection}>
						<Text style={styles.modalSectionTitle}>{"Reward"}</Text>
						<Text style={styles.modalSectionDescription}>{"The reward below will be automatically rewarded to a member upon their completion of the milestone."}</Text>
						<View>
							<Image
								source={selectedMilestone && selectedMilestone?.reward?.image ? { uri: selectedMilestone?.reward?.image } : PlaceholderImageCover}
								style={styles.modalRewardImage}
							/>
							<Text style={styles.modalRewardTitle}>{selectedMilestone?.reward?.title ? selectedMilestone?.reward?.title : 'No Reward Associated'}</Text>
						</View>
					</View>
				</ModalV2>

				<DeleteModal />
				<CreateMilestoneModal
					selectedMilestoneId={selectedMilestone && selectedMilestone?.id}
					isMilestoneModalVisible={isMilestoneModalVisible}
					toggleRightModal={toggleRightModal}
					update={true}
				/>

			</>
		)
	};
	return (
		<View style={styles.MilestoneContainer}>
			<TouchableOpacity
				onPress={() => toggleModal(index)}
				style={styles.MilestoneCard}
			>
				<View
					style={[
						styles.headerView,
						{ justifyContent: "space-between" },
					]}
				>
					<View
						style={{ flex: 0.6, flexDirection: "row", alignItems: "center" }}
					>
						<Text style={styles.milestoneTitle}>{title}</Text>
					</View>
					<View style={{ flex: 0.4 }}>
						<TouchableOpacity
							style={{
								backgroundColor: "#f9675a",
								paddingHorizontal: 6,
								paddingVertical: 3,
								borderRadius: 4,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Text
								numberOfLines={1}
								ellipsizeMode="tail"
								style={styles.memberMileStoneTypeText}
							>
								{criteriaTypeLabel}
							</Text>
						</TouchableOpacity>
					</View>
				</View>
				<Text
					style={styles.description}
					adjustsFontSizeToFit={false}
					numberOfLines={3}
					ellipsizeMode={'tail'}
				>
					{description}
				</Text>
				{type === "manager" ? (
					<View style={styles.bottomView}>
						<Text style={styles.bottomTxt}>
							{completed} {"Completed"}
						</Text>
						<Text style={[styles.bottomTxt, { color: "#FB9358" }]}>
							{pending} {"Pending"}
						</Text>
						<Text style={[styles.bottomTxt, { color: "#FF1800" }]}>
							{yet_to_start} {"Yet to start"}
						</Text>
						<Text style={[styles.bottomTxt, { color: "#000" }]}>
							{importedTxt}
						</Text>
					</View>
				) : (
					<View>
						<ProgressBar
							progress={0.7}
							color={"#00B15C"}
							style={styles.progressBar}
						/>
						<Text style={styles.progressBarCount}>{"70 pts"}</Text>
					</View>
				)}
			</TouchableOpacity>
			{/* {type === "manager" ? (
				<TouchableOpacity>
					<Text style={styles.seeTxt}>{"See Criteria"}</Text>
				</TouchableOpacity>
			) : null} */}
			{type === "manager" ? <EditModal /> : null}
		</View >
	);
};

export const MemberMilestoneComponent = ({
	description,
	title,
	criteriaTypeLabel,
	memberMilestoneId,
}) => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [selectedMemberMilestone, setSelectedMemberMilestone] = useState([]);
	const [completeEmployees, setCompleteEmployees] = useState([]);
	const [pendingEmployees, setPendingEmployees] = useState([]);
	const [yetToStart, setYetToStart] = useState([]);

	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};

	const getMemberMilestoneById = async () => {
		try {
			const response = await new ApiHandler().getMemberMilestoneById(
				memberMilestoneId
			);
			if (response?.data) {
				setSelectedMemberMilestone(response?.data?.data);
				setCompleteEmployees(response?.data?.data?.completed_employees);
				setPendingEmployees(response?.data?.data?.pending_employees);
				setYetToStart(response?.data?.data?.yet_to_start_employees);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	useEffect(() => {
		getMemberMilestoneById();
	}, []);
	const MemberMilestoneModal = () => {
		const screenHeight = Dimensions.get("screen").height;

		return (
			<ModalV2
				isVisible={isModalVisible}
				setVisibility={setModalVisible}
				title={selectedMemberMilestone?.name}
			>

				{selectedMemberMilestone?.description &&
					<ContentBlockV1
						title={'More About This Milestone'}
					>
						<Text style={styles.modalDescription}>
							{selectedMemberMilestone &&
								selectedMemberMilestone?.description}
						</Text>
					</ContentBlockV1>
				}

				<ContentBlockV1
					title={'Your Progress'}
					subtitle={"Track your progress towards completing this milestone."}
				>
					<View>
						<View style={styles.pointsView}>
							<View>
								<Text style={styles.points}>
									{[selectedMemberMilestone ? selectedMemberMilestone?.member_engagement_points : 0, '/', selectedMemberMilestone?.engagement_points ? selectedMemberMilestone?.engagement_points : "-"]}
								</Text>
								<Text style={styles.pointsTitle}>{"Engagement Points"}</Text>

								<View style={{ marginTop: 15 }}>
									<View style={styles.progressBarMain}>
										<View
											style={{ flex: 0.6, flexDirection: "row", alignItems: "center" }}
										>
											<View
												style={[
													styles.progressBarGreen,
													{ flex: selectedMemberMilestone?.member_engagement_percent_completed ? selectedMemberMilestone?.member_engagement_percent_completed / 100 : 0 },
												]}
											/>
										</View>
										<View style={{ flex: 0.4 }}>
											<Text style={styles.progressBarCount}>
												{[selectedMemberMilestone?.member_engagement_percent_completed ? selectedMemberMilestone?.member_engagement_percent_completed : '-', "%"]}
											</Text>
										</View>
									</View>
								</View>
							</View>

							<View>
								<Text style={styles.points}>
									{[selectedMemberMilestone ? selectedMemberMilestone?.member_cultural_maturity_points : 0, '/', selectedMemberMilestone?.cultural_maturity_points ? selectedMemberMilestone?.cultural_maturity_points : "-"]}
								</Text>
								<Text style={styles.pointsTitle}>{"Cultural Maturity Points"}</Text>

								<View style={{ marginTop: 15 }}>
									<View style={styles.progressBarMain}>
										<View
											style={{ flex: 0.6, flexDirection: "row", alignItems: "center" }}
										>
											<View
												style={[
													styles.progressBarGreen,
													{ flex: selectedMemberMilestone?.member_cultural_maturity_percent_completed ? selectedMemberMilestone?.member_cultural_maturity_percent_completed / 100 : 0 },
												]}
											/>
										</View>
										<View style={{ flex: 0.4 }}>
											<Text style={styles.progressBarCount}>
												{[selectedMemberMilestone?.member_cultural_maturity_percent_completed ? selectedMemberMilestone?.member_cultural_maturity_percent_completed : '-', "%"]}
											</Text>
										</View>
									</View>
								</View>
							</View>

							<View>
								<Text style={styles.points}>
									{[selectedMemberMilestone ? selectedMemberMilestone?.member_total_points : 0, '/', selectedMemberMilestone?.total_points ? selectedMemberMilestone?.total_points : "-"]}
								</Text>
								<Text style={styles.pointsTitle}>{"Total Points"}</Text>

								<View style={{ marginTop: 15 }}>
									<View style={styles.progressBarMain}>
										<View
											style={{ flex: 0.6, flexDirection: "row", alignItems: "center" }}
										>
											<View
												style={[
													styles.progressBarGreen,
													{ flex: selectedMemberMilestone?.member_total_percent_completed ? selectedMemberMilestone?.member_total_percent_completed / 100 : 0 },
												]}
											/>
										</View>
										<View style={{ flex: 0.4 }}>
											<Text style={styles.progressBarCount}>
												{[selectedMemberMilestone?.member_total_percent_completed ? selectedMemberMilestone?.member_total_percent_completed : '-', "%"]}
											</Text>
										</View>
									</View>
								</View>
							</View>
						</View>
					</View>
				</ContentBlockV1>

				<ContentBlockV1
					title={'Milestone Reward'}
					subtitle={"You will receive the reward below upon completing this milestone."}
				>
					<View>
						<Image
							source={selectedMemberMilestone && selectedMemberMilestone?.reward?.image ? { uri: selectedMemberMilestone?.reward?.image } : PlaceholderImageCover}
							style={styles.modalRewardImage}
						/>
						<Text style={styles.modalRewardTitle}>{selectedMemberMilestone?.reward?.title ? selectedMemberMilestone?.reward?.title : 'No Reward Associated'}</Text>
					</View>
				</ContentBlockV1>
			</ModalV2>
		)
	};

	return (
		<View style={styles.MilestoneContainer}>
			<TouchableOpacity
				onPress={toggleModal}
				style={styles.MilestoneCard}
			>
				<View style={styles.MilestoneCardContentContainer}>
					<View
						style={styles.MilestoneCardContentContainerHeaderContainer}
					>
						<View
							style={styles.MilestoneCardContentContainerHeaderContainerTitleContainer}
						>
							<Text style={styles.MilestoneCardContentContainerHeaderContainerTitleContainerTitle}>{title}</Text>
						</View>
						<View style={styles.MilestoneCardContentContainerHeaderContainerCriteriaContainer}>
							<TouchableOpacity
								style={styles.MilestoneCardContentContainerHeaderContainerCriteriaContainerBadge}
							>
								<Text
									numberOfLines={1}
									ellipsizeMode="tail"
									style={styles.MilestoneCardContentContainerHeaderContainerCriteriaContainerBadgeText}
								>
									{criteriaTypeLabel}
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					<View
						style={styles.MilestoneCardContentContainerDescriptionContainer}
					>
						<Text
							style={styles.MilestoneCardContentContainerDescriptionContainerText}
							adjustsFontSizeToFit={false}
							numberOfLines={3}
							ellipsizeMode={'tail'}
						>
							{description}
						</Text>
					</View>
				</View>

				<View style={styles.MilestoneCardProgressContainer}>
					<View
						style={styles.MilestoneCardProgressBarContainer}
					>
						<View
							style={[
								styles.MilestoneCardProgressBar,
								{ flex: selectedMemberMilestone?.member_overall_percent_completed ? selectedMemberMilestone?.member_overall_percent_completed / 100 : 0 },
							]}
						/>
					</View>
					<View style={styles.MilestoneCardProgressPercentageContainer}>
						<Text style={styles.MilestoneCardProgressPercentageText}>
							{[selectedMemberMilestone?.member_overall_percent_completed ? selectedMemberMilestone?.member_overall_percent_completed : '-', "%"]}
						</Text>
					</View>
				</View>
			</TouchableOpacity>

			<MemberMilestoneModal />
		</View>
	);
};
export const MemberRewardDetailsComponent = ({
	width,
	background,
	order,
	description,
	title,
	profileImg,
	rewardedTxt,
	pendingTxt,
	startTxt,
	importedTxt,
	memberRewardId,
}) => {
	const [isModalVisible, setModalVisible] = useState(false);
	const [selectedMemberRewards, setSelectedMemberRewards] = useState([]);
	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};

	const getMemberRewardById = async () => {
		try {
			const response = await new ApiHandler().getMemberRewardById(
				memberRewardId
			);
			if (response?.data) {
				setSelectedMemberRewards(response?.data?.data);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	useEffect(() => {
		getMemberRewardById();
	}, []);

	const MemberRewardDetailsModal = () => {
		const screenHeight = Dimensions.get("screen").height;

		return (
			<ModalV2
				isVisible={isModalVisible}
				setVisibility={toggleModal}
				title={selectedMemberRewards?.title}
			>
				<Text style={styles.modalSectionTitle}>{"Redemption Instructions"}</Text>
				<Text style={styles.modalSectionDescription}>{"Follow the instructions below to redeem your reward."}</Text>

				{order?.redemption_instructions ? (
					<Text style={{
						backgroundColor: "#f1f1f1",
						padding: 10,
						borderRadius: 5,
						color: "#000000",
						minHeight: 150,
						fontSize: 12
					}}>
						{order?.redemption_instructions}
					</Text>
				) : (
					<Text>{"This order has no redemption instructions. Please contact your community manager."}</Text>
				)}
			</ModalV2>
		)
	};
	return (
		<View style={{
			backgroundColor: '#FFFFFF',
			borderRadius: 10,
			paddingHorizontal: 10,
			paddingVertical: 10,
			shadowColor: 'rgba(2, 2, 2, 1)',
			shadowOffset: { width: 1, height: 1 },
			shadowOpacity: 0.10,
			shadowRadius: 8,
			elevation: 3,
			...Platform.select({
				ios: {
					width: '100%',
					marginBottom: 10,
					marginTop: 2,
					marginHorizontal: 2
				},
				android: {
					flex: 1,
					marginBottom: 10,
					marginTop: 2,
					marginHorizontal: 2
				},
				web: {
					margin: 5,
					width: 350,
					minHeight: 150
				}
			})
		}}>
			<TouchableOpacity
				onPress={toggleModal}
				style={{
					width: '100%',
					...Platform.select({
						ios: {

						},
						android: {

						},
						web: {
							height: '100%'
						}
					})
				}}
			>
				<View
					style={[
						styles.headerView,
						{ justifyContent: "space-between", marginTop: !isMobile ? 0 : 10 },
					]}
				>
					<View style={{ flexDirection: "row", alignItems: "center", width: '100%' }}>
						<Image source={profileImg ? { uri: profileImg } : PlaceholderImageCover} style={styles.img} />
						<Text style={styles.title}>{title}</Text>
					</View>
				</View>
				<Text style={styles.description}>{description}</Text>
				{!isMobile ? (
					<View style={styles.bottomView}>
						<Text style={styles.bottomTxt}>{rewardedTxt}</Text>
						<Text style={[styles.bottomTxt, { color: "#FB9358" }]}>
							{pendingTxt}
						</Text>
						<Text style={[styles.bottomTxt, { color: "#FF1800" }]}>
							{startTxt}
						</Text>
						<Text style={[styles.bottomTxt, { color: "#000" }]}>
							{importedTxt}
						</Text>
					</View>
				) : null}
			</TouchableOpacity>
			<MemberRewardDetailsModal />
		</View>
	);
};

export const RewardDetailsComponent = ({
	height,
	width,
	background,
	description,
	title,
	profileImg,
	type = "manager",
	index,
	rewardId,
	number_of_distributions
}) => {
	const [selectedRewardIndex, setSelectedRewardIndex] = useState(0);
	const [isModalVisible, setModalVisible] = useState(false);
	const [workSpaceId, setWorkSpaceId] = useState([1]);
	const [milestones, setMilestones] = useState([]);
	const [reward, setReward] = useState([]);
	const [selectedReward, setSelectedReward] = useState({});
	const [awardees, setAwardees] = useState([]);

	const toggleModal = () => {
		setModalVisible(!isModalVisible);
	};
	const hideModal = () => {
		setModalVisible(false);
	};

	const getReward = async () => {
		try {
			let queryParams = "";
			queryParams = `workspace_id=${workSpaceId}`;
			const response = await new ApiHandler().getRewards(queryParams);
			// console.log("********getRewardResponse by id****", response?.data?.data?.data);
			if (response?.data) {
				setReward(response?.data?.data?.data);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	const deleteReward = async () => {
		try {
			const response = await new ApiHandler().deleteReward(rewardId);
			if (response?.data == "success") {
				Toast.show({
					type: "success",
					position: "top",
					text1: "Your Reward is Deleted Successfully",
				});
			}
		} catch (error) {
			console.log(">>>> ", error);
		} finally {
		}
	};

	const getRewardById = async () => {
		try {
			const response = await new ApiHandler().getRewardById(rewardId);
			if (response?.data) {
				console.log(
					"RESPONSE *****getRewardById ====== ^^^^^^^^ REWARD BY ID",
					response?.data
				);
				setSelectedReward(response?.data?.data);
				setAwardees(response?.data?.data?.awardees);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	useEffect(() => {
		getRewardById();
	}, []);

	const EditModal = () => {
		const [isRewardModalVisible, setRewardModalVisible] = useState(false);
		const [deleteModal, setDeleteModal] = useState(false);
		const [isOrderModalVisible, setOrderModalVisible] = useState(false);

		const toggleDeleteModal = () => {
			setDeleteModal(!deleteModal);
		};
		const toggleRewardModal = () => {
			setRewardModalVisible(!isRewardModalVisible);
		};
		const toggleOrderModal = () => {
			setOrderModalVisible(!isOrderModalVisible);
		};

		const DeleteModal = () => {
			return (
				<ModalV2
					isVisible={deleteModal}
					setVisibility={toggleDeleteModal}
					title={'Delete Reward'}
				>
					<>
						<Text style={styles.deleteModalDescription}>
							Do you want to delete the reward?
						</Text>
						<View style={styles.deleteModalButtons}>
							<Button
								onPress={() => {
									deleteReward();
									toggleModal();
									hideModal();
								}}
								label="Delete"
								containerStyle={{
									backgroundColor: "#B40027",
									width: 127,
									height: 40,
									marginTop: 0,
									marginRight: 5,
								}}
							/>
						</View>
					</>
				</ModalV2>
			);
		};
		const expansiveChildren = [
			{ text: "Edit", onPress: () => toggleRewardModal() },
			{ text: "Delete", onPress: () => toggleDeleteModal() },
		];

		return (
			<>
				<ModalV2
					isVisible={isModalVisible}
					setVisibility={hideModal}
					title={selectedReward?.title}
				>
					<>
						<View
							style={[styles.modalHeaderView, { marginTop: 20, zIndex: 999 }]}
						>
							<Image
								source={selectedReward?.image ? { uri: selectedReward?.image } : PlaceholderImageCover}
								style={styles.modalProfilePic}
							/>
							<Text style={styles.modalTitle}>{selectedReward?.title}</Text>
							{/* <Text style={styles.expireDate}>{"Expires 12/23/2021"}</Text> */}
							<ExpansiveMenu
								mainIconColor="#C4C4C4"
								mainIconBackground="transparent"
								expansiveChildrens={expansiveChildren}
							/>
						</View>
						<View style={styles.descriptionView}>
							<Text style={styles.modalDescription}>
								{selectedReward?.description}
							</Text>
						</View>

						<View style={styles.modalSection}>
							<Text style={styles.modalSectionTitle}>{"Distribution (Orders)"}</Text>
							<Text style={styles.modalSectionDescription}>{"Keep track of how this reward is being distributed to your members."}</Text>

							<FlatList
								data={selectedReward?.reward_orders}
								renderItem={({ item, index }) => {
									return (
										<View style={{
											padding: 10,
											width: '100%',
											flex: 1,
											marginBottom: 15,
											shadowColor: '#171717',
											shadowOffset: { width: 1, height: 2 },
											shadowOpacity: 0.2,
											shadowRadius: 8,
											borderRadius: 5
										}}>
											<View style={{
												justifyContent: "space-between",
												flexDirection: "row",
												alignItems: "center",
												marginBottom: 10
											}}>
												<View style={{
													flex: 0.6
												}}>
													<Text style={{
														fontSize: 16,
														fontWeight: "500",
														letterSpacing: -0.8,
														flex: 1,
													}}>
														{"Order " + item?.id}
													</Text>
												</View>
												<View style={{
													flex: 0.4,
													alignItems: right
												}}>
													<TouchableOpacity
														style={{
															width: 80,
															backgroundColor: "#f9675a",
															paddingHorizontal: 6,
															paddingVertical: 3,
															borderRadius: 4,
															justifyContent: "center",
															alignItems: "center",
														}}
													>
														<Text
															numberOfLines={1}
															ellipsizeMode="tail"
															style={styles.memberMileStoneTypeText}
														>
															{item?.distribution_method}
														</Text>
													</TouchableOpacity>
												</View>
											</View>

											<View style={{
												justifyContent: "space-between",
												flexDirection: "row",
												alignItems: "center",
											}}>
												<View style={{
													flex: 0.5
												}}>
													<Text style={{
														fontSize: 14,
														fontWeight: "500",
														letterSpacing: -0.8,
														flex: 1,
													}}>
														{"Recipient"}
													</Text>
													<Text style={{
														fontSize: 13,
														fontWeight: "400",
														flex: 1,
													}}>
														{item?.recipient?.first_name + " " + item?.recipient?.last_name}
													</Text>
												</View>
												{item?.distribution_method == 'Manual' ? (
													<View style={{
														flex: 0.5,
													}}>
														<Text style={{
															fontSize: 14,
															fontWeight: "500",
															letterSpacing: -0.8,
															flex: 1,
														}}>
															{"Sender"}
														</Text>
														<Text style={{
															fontSize: 13,
															fontWeight: "400",
															flex: 1,
														}}>
															{item?.sender?.first_name && item?.sender?.last_name ? item?.sender?.first_name + " " + item?.recipient?.last_name : 'Automated Via Milestone'}
														</Text>
													</View>
												) : null}
												{item?.distribution_method == 'Milestone' ? (
													<View style={{
														flex: 0.5,
													}}>
														<Text style={{
															fontSize: 14,
															fontWeight: "500",
															letterSpacing: -0.8,
															flex: 1,
														}}>
															{"Milestone"}
														</Text>
														<Text style={{
															fontSize: 13,
															fontWeight: "400",
															flex: 1,
														}}>
															{item?.milestone?.name}
														</Text>
													</View>
												) : null}
											</View>

										</View>
									);
								}}
								keyExtractor={(item) => item.id}
							/>
						</View>

						<TouchableOpacity
							onPress={() => toggleOrderModal()}
							style={styles.editButton}
						>
							<Text style={styles.editButtonText}>{"Send Reward To Members"}</Text>
						</TouchableOpacity>
					</>

					<DeleteModal />
					<CreateRewardModal
						isRewardModalVisible={isRewardModalVisible}
						toggleRewardModal={toggleRewardModal}
						update={true}
						rewardId={rewardId}
					/>
					<SendManualRewardModal
						isModalVisible={isOrderModalVisible}
						toggleModal={toggleOrderModal}
						rewardId={rewardId}
					/>
				</ModalV2>
			</>
		)
	};
	return (
		<View style={{
			backgroundColor: '#FFFFFF',
			borderRadius: 10,
			paddingHorizontal: 10,
			paddingVertical: 10,
			shadowColor: 'rgba(2, 2, 2, 1)',
			shadowOffset: { width: 1, height: 1 },
			shadowOpacity: 0.10,
			shadowRadius: 8,
			elevation: 3,
			...Platform.select({
				ios: {
					width: '100%',
					marginBottom: 10,
					marginTop: 2,
					marginHorizontal: 2
				},
				android: {
					flex: 1,
					marginBottom: 10,
					marginTop: 2,
					marginHorizontal: 2
				},
				web: {
					margin: 5,
					width: 350,
					minHeight: 150
				}
			})
		}}>
			<TouchableOpacity
				onPress={() => toggleModal(index)}
				style={{
					width: '100%',
					...Platform.select({
						ios: {

						},
						android: {

						},
						web: {
							height: '100%'
						}
					})
				}}
			>
				<View
					style={[
						styles.headerView,
						{ justifyContent: "space-between", marginTop: !isMobile ? 0 : 10 },
					]}
				>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						<Image source={profileImg ? { uri: profileImg } : PlaceholderImageCover} style={styles.img} />
						<Text style={styles.title}>{title}</Text>
					</View>
					{/* {isMobile ? (
						<View>
							<Text style={styles.expireDate}>Expires 12/23/2021</Text>
						</View>
					) : null} */}
				</View>
				<Text
					style={styles.description}
					numberOfLines={3}
					ellipsizeMode={'tail'}
				>
					{description}
				</Text>
				{type === "manager" ? (
					<View style={styles.bottomView}>
						<Text style={styles.bottomTxt}>
							{number_of_distributions} Distributed
						</Text>
					</View>
				) : null}
			</TouchableOpacity>
			<EditModal />
		</View>
	);
};

export const OrderComponent = ({
	background,
	width,
	id,
	order_id,
	status
}) => {
	const [isViewModalVisible, setViewModalVisible] = useState(false);
	const screenHeight = Dimensions.get("screen").height;

	console.log("View Modal " + isViewModalVisible);

	const toggleViewModal = () => {
		setViewModalVisible(!isViewModalVisible);
	};

	const hideViewModal = () => {
		setViewModalVisible(false);
	};

	const ViewModal = () => {
		const [isEditModalVisible, setEditModalVisible] = useState(false);
		const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
		const [order, setOrder] = useState();

		const toggleEditModal = () => {
			setEditModalVisible(!isEditModalVisible);
		};

		const toggleDeleteModal = () => {
			setDeleteModalVisible(!isDeleteModalVisible);
		};

		const expansiveChildren = [
			{ text: "Edit", onPress: () => toggleEditModal() },
			{ text: "Delete", onPress: () => toggleDeleteModal() },
		];

		const getOrder = async () => {
			try {
				const response = await new ApiHandler().getRewardsOrderById(id);

				if (response?.data) {
					setOrder(response?.data?.data);
				}
			} catch (error) {

			}
		};

		useEffect(() => {
			getOrder();
		}, []);

		const EditModal = () => {
			const [redemptionInstructions, setRedemptionInstructions] = useState(order?.redemption_instructions);

			const updateOrder = async () => {
				try {
					const data = {
						redemption_instructions: redemptionInstructions,
					};

					const response = await new ApiHandler().updateRewardsOrder(
						id,
						data
					);

					if (response?.data) {
						Toast.show({
							type: "success",
							position: "bottom",
							text1: "The order was updated successfully.",
						});
					}
				} catch (error) {
					Toast.show({
						type: "error",
						position: "bottom",
						text1: error,
					});
				}

				getOrder();
			};

			return (
				<ModalV2
					isVisible={isEditModalVisible}
					setVisibility={toggleEditModal}
					title={'Update Order'}
				>
					<>
						<View style={{ marginTop: 20 }}>
							<Text style={styles.rightSideModalPointsTitle}>
								{"Redemption Instructions"}
							</Text>

							<TextInput
								placeholder="Provide redemption details for the reward attached to this order, for example, in the case of a Gift Card, provide the Gift Card Code."
								placeholderTextColor="#989898"
								multiline
								numberOfLines={5}
								value={redemptionInstructions}
								keyboardType="default"
								onChangeText={(text) => setRedemptionInstructions(text)}
								style={styles.modalInput}
							/>
						</View>

						<TouchableOpacity
							style={styles.modalBtn}
							onPress={() => {
								{
									updateOrder(),
										toggleEditModal()
								}
							}}
						>
							<Text style={styles.modalBtnText}>
								{"Update"}
							</Text>
						</TouchableOpacity>
					</>
				</ModalV2>
			)
		};

		const DeleteModal = () => {
			const deleteOrder = async () => {
				try {
					const response = await new ApiHandler().deleteRewardsOrder(id);
					console.log("@@@%%%Delete Order%%%????", response);

					Toast.show({
						type: "success",
						position: "top",
						text1: "The order was deleted.",
					});
				} catch (error) {
					console.log(">>>>", error);
				} finally {
				}
			};

			return (
				<ModalV2
					isVisible={isDeleteModalVisible}
					setVisibility={toggleDeleteModal}
					title={'Delete Order'}
				>
					<>
						<Text style={styles.deleteModalDescription}>
							Do you want to delete this order?
						</Text>
						<View style={styles.deleteModalButtons}>
							<Button
								label="Delete"
								onPress={() => {
									deleteOrder();
									toggleDeleteModal();
									hideViewModal();
								}}
								containerStyle={{
									backgroundColor: "#B40027",
									width: 127,
									height: 40,
									marginTop: 0,
									marginRight: 5,
								}}
							/>
						</View>
					</>
				</ModalV2>
			);
		};

		return (
			<>
				<ModalV2
					isVisible={isViewModalVisible}
					setVisibility={toggleViewModal}
					title={["Order ", order?.order_id ? order?.order_id : ""]}
				>
					<>
						<View style={[styles.modalHeaderView, { marginTop: 10, zIndex: 9 }]}>
							<Text style={styles.modalTitle}>{["Order ", order?.order_id ? order?.order_id : ""]}</Text>
							<ExpansiveMenu
								mainIconColor="#C4C4C4"
								mainIconBackground="transparent"
								expansiveChildrens={expansiveChildren}
							/>
						</View>

						<View style={styles.modalSection}>
							<Text style={styles.modalSectionTitle}>{"Status"}</Text>
							<Text style={styles.modalSectionDescription}>{"An order can be 'Open' (Pending Fulfillment), 'Completed' (Fulfilled) or 'Cancelled' (Won't Be Fulfilled)."}</Text>

							{order?.status_taxonomy ? (
								<TouchableOpacity
									style={{
										backgroundColor: "#f9675a",
										paddingHorizontal: 10,
										paddingVertical: 10,
										borderRadius: 4,
										justifyContent: "center",
										alignItems: "center",
										alignSelf: "flex-start"
									}}
								>
									<Text
										numberOfLines={1}
										ellipsizeMode="tail"
										style={{
											fontSize: 12,
											lineHeight: 12,
											color: "#FFFFFF"
										}}
									>
										{order?.status_taxonomy?.default_label}
									</Text>
								</TouchableOpacity>
							) : null}
						</View>

						<View style={styles.modalSection}>
							<Text style={styles.modalSectionTitle}>{"Sender/Milestone"}</Text>
							<Text style={styles.modalSectionDescription}>{"An order can either be created manually by another member in your workspace with the appropriate permissions by sending a reward to a member, or automatically upon a member completing a milestone."}</Text>

							{order?.sender ? (
								<View>
									<Text style={{
										marginBottom: 10
									}}>
										{"This order was created manually by:"}
									</Text>
									<TouchableOpacity
										style={{
											backgroundColor: "#f9675a",
											paddingHorizontal: 10,
											paddingVertical: 10,
											borderRadius: 4,
											justifyContent: "center",
											alignItems: "center",
											alignSelf: "flex-start"
										}}
									>
										<Text
											numberOfLines={1}
											ellipsizeMode="tail"
											style={{
												fontSize: 12,
												lineHeight: 12,
												color: "#FFFFFF"
											}}
										>
											{order?.sender?.first_name + " " + order?.sender?.last_name}
										</Text>
									</TouchableOpacity>
								</View>
							) : null}

							{order?.milestone ? (
								<View>
									<Text style={{
										marginBottom: 10
									}}>
										{"This order was created automatically due to the recipient completing milestone:"}
									</Text>
									<TouchableOpacity
										style={{
											backgroundColor: "#f9675a",
											paddingHorizontal: 10,
											paddingVertical: 10,
											borderRadius: 4,
											justifyContent: "center",
											alignItems: "center",
											alignSelf: "flex-start"
										}}
									>
										<Text
											numberOfLines={1}
											ellipsizeMode="tail"
											style={{
												fontSize: 12,
												lineHeight: 12,
												color: "#FFFFFF"
											}}
										>
											{order?.milestone?.name}
										</Text>
									</TouchableOpacity>
								</View>
							) : null}
						</View>

						<View style={styles.modalSection}>
							<Text style={styles.modalSectionTitle}>{"Recipient"}</Text>
							<Text style={styles.modalSectionDescription}>{""}</Text>

							{order?.recipient ? (
								<TouchableOpacity
									style={{
										backgroundColor: "#f9675a",
										paddingHorizontal: 10,
										paddingVertical: 10,
										borderRadius: 4,
										justifyContent: "center",
										alignItems: "center",
										alignSelf: "flex-start"
									}}
								>
									<Text
										numberOfLines={1}
										ellipsizeMode="tail"
										style={{
											fontSize: 12,
											lineHeight: 12,
											color: "#FFFFFF"
										}}
									>
										{order?.recipient?.first_name + " " + order?.recipient?.last_name}
									</Text>
								</TouchableOpacity>
							) : null}
						</View>

						<View style={styles.modalSection}>
							<Text style={styles.modalSectionTitle}>{"Reward"}</Text>
							<Text style={styles.modalSectionDescription}>{""}</Text>

							{order?.reward ? (
								<View>
									<Image
										source={order?.reward && order?.reward?.image ? { uri: order?.reward?.image } : PlaceholderImageCover}
										style={styles.modalRewardImage}
									/>
									<Text style={styles.modalRewardTitle}>{order?.reward?.title ? order?.reward?.title : ""}</Text>
								</View>
							) : null}
						</View>

						<View style={styles.modalSection}>
							<Text style={styles.modalSectionTitle}>{"Redemption Instructions"}</Text>
							<Text style={styles.modalSectionDescription}>{"The redemption instructions are instructions to the recipient on how to redeem their reward. The order will only be 'Completed' once it has redemption instructions."}</Text>

							{order?.redemption_instructions ? (
								<Text style={{
									backgroundColor: "#f1f1f1",
									padding: 10,
									borderRadius: 5,
									color: "#000000",
									minHeight: 150,
									fontSize: 12
								}}>
									{order?.redemption_instructions}
								</Text>
							) : (
								<Text>{"This order and reward has no redemption instructions. Once this order is ready to be fulfilled, edit the order and add redemption instructions for the recipient."}</Text>
							)}
						</View>
					</>

					<EditModal />
					<DeleteModal />
				</ModalV2>
			</>
		)
	};

	return (
		<View style={{
			backgroundColor: '#FFFFFF',
			borderRadius: 10,
			paddingHorizontal: 10,
			paddingVertical: 10,
			shadowColor: 'rgba(2, 2, 2, 1)',
			shadowOffset: { width: 1, height: 1 },
			shadowOpacity: 0.10,
			shadowRadius: 8,
			elevation: 3,
			...Platform.select({
				ios: {
					width: '100%',
					marginBottom: 10,
					marginTop: 2,
					marginHorizontal: 2
				},
				android: {
					flex: 1,
					marginBottom: 10,
					marginTop: 2,
					marginHorizontal: 2
				},
				web: {
					margin: 5,
					width: 350,
					minHeight: 75
				}
			})
		}}>
			<TouchableOpacity
				onPress={toggleViewModal}
				style={{
					width: '100%',
					...Platform.select({
						ios: {

						},
						android: {

						},
						web: {
							height: '100%'
						}
					})
				}}
			>
				<View
					style={[
						styles.headerView,
						{ justifyContent: "space-between" },
					]}
				>
					<View
						style={{ flex: 0.6, flexDirection: "row", alignItems: "center" }}
					>
						<Text style={styles.milestoneTitle}>{"Order " + order_id}</Text>
					</View>
					<View style={{ flex: 0.4 }}>
						<TouchableOpacity
							style={{
								backgroundColor: "#f9675a",
								paddingHorizontal: 6,
								paddingVertical: 3,
								borderRadius: 4,
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Text
								numberOfLines={1}
								ellipsizeMode="tail"
								style={styles.memberMileStoneTypeText}
							>
								{status}
							</Text>
						</TouchableOpacity>
					</View>
				</View>
			</TouchableOpacity>

			<ViewModal />
		</View>
	);
};

const styles = StyleSheet.create({
	MilestoneContainer: {
		backgroundColor: '#FFFFFF',
		borderRadius: 10,
		paddingHorizontal: 10,
		paddingVertical: 10,
		shadowColor: 'rgba(2, 2, 2, 1)',
		shadowOffset: { width: 1, height: 1 },
		shadowOpacity: 0.10,
		shadowRadius: 8,
		elevation: 3,
		...Platform.select({
			ios: {
				width: '100%',
				marginBottom: 10,
				marginTop: 2,
				marginHorizontal: 1
			},
			android: {
				flex: 1,
				marginBottom: 10,
				marginTop: 2,
				marginHorizontal: 2
			},
			web: {
				margin: 5,
				width: 350,
				minHeight: 150
			}
		})
	},
	MilestoneCard: {
		width: '100%',
		...Platform.select({
			ios: {

			},
			android: {

			},
			web: {
				height: '100%'
			}
		})
	},
	MilestoneCardContentContainer: {
		marginBottom: 20
	},
	MilestoneCardContentContainerHeaderContainer: {
	},
	MilestoneCardContentContainerHeaderContainerTitleContainer: {
		marginBottom: 10
	},
	MilestoneCardContentContainerHeaderContainerTitleContainerTitle: {
		fontSize: 13,
		fontWeight: "500",
		fontFamily: 'WorkSans_400Regular',
		lineHeight: 17
	},
	MilestoneCardContentContainerHeaderContainerCriteriaContainer: {
		marginBottom: 10,
		alignItems: 'flex-start',
	},
	MilestoneCardContentContainerHeaderContainerCriteriaContainerBadge: {
		backgroundColor: "#f9675a",
		paddingHorizontal: 6,
		paddingVertical: 3,
		borderRadius: 4,
		justifyContent: "center",
		alignItems: "center",
	},
	MilestoneCardContentContainerHeaderContainerCriteriaContainerBadgeText: {
		fontSize: 10,
		lineHeight: 12,
		color: "#FFFFFF",
	},
	MilestoneCardContentContainerDescriptionContainer: {

	},
	MilestoneCardContentContainerDescriptionContainerText: {
		fontSize: 12,
		lineHeight: 17,
		fontWeight: "400",
		marginVertical: 5,
		width: '100%',
	},
	MilestoneCardProgressContainer: {
		position: "absolute",
		bottom: 2,
		right: 4,
		left: 4,
		flex: 1,
		backgroundColor: "#fff",
		flexDirection: "row",
		height: 12,
	},
	MilestoneCardProgressBarContainer: {
		flex: 0.8,
		flexDirection: "row",
		alignItems: "center"
	},
	MilestoneCardProgressBar: {
		borderRadius: 7,
		backgroundColor: "#00B15C",
		height: 12,
	},
	MilestoneCardProgressPercentageContainer: {
		flex: 0.2,
	},
	MilestoneCardProgressPercentageText: {
		textAlign: "right",
		position: "absolute",
		right: 0,
		bottom: 0,
		fontSize: 12,
		width: 100
	},
	completeTxt: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		marginTop: 5,
	},
	pointsView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	descriptionView: { paddingVertical: 10 },
	modalDescription: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
	},
	criteria: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
		paddingVertical: 10,
	},
	progressText: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
		paddingVertical: 15,
	},
	points: {
		fontSize: 20,
		fontWeight: "500",
		lineHeight: 40,
		lineHeight: -0.8,
	},
	pointsTitle: {
		fontSize: 10,
		fontWeight: "400",
		lineHeight: 13,
	},
	btnView: {
		flex: 0.2,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	modal: {
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 30,
		paddingVertical: 40,
	},
	editButton: {
		backgroundColor: "#FB6758",
		height: 40,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
	},
	editButtonText: {
		fontSize: 18,
		fontWeight: "500",
		lineHeight: 24,
		color: "#fff",
	},
	awardeesTxt: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
	},
	photo: {
		height: !isMobile ? 45 : 35,
		width: !isMobile ? 45 : 35,
		borderRadius: 33,
		marginEnd: 8,
	},
	swiper: {
		width: 10,
		height: 10,
		marginTop: 70,
	},
	photoView: {
		flexDirection: "row",
		alignItems: "center",
		marginEnd: 15,
	},
	modalProfilePic: {
		height: 22,
		width: 35,
		borderRadius: 4,
	},
	modalHeaderView: {
		flexDirection: "row",
		alignItems: "center",
		// justifyContent: "space-between",
	},
	cross: {
		height: 28,
		width: 28,
	},
	importedBtn: {
		backgroundColor: "#FFCF70",
		borderRadius: 4,
		height: 16,
		width: 45,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 15,
	},
	importedBtnTxt: {
		fontSize: 8,
		fontWeight: "500",
		lineHeight: 10,
	},
	closeBtn: {
		position: "absolute",
		left: -2,
		top: 1,
	},
	modalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		flex: 1,
	},
	modalSection: {
		marginTop: 40
	},
	modalSectionTitle: {
		fontSize: 16,
		fontWeight: "500",
		letterSpacing: -0.8,
		flex: 1,
		marginBottom: 5
	},
	modalSectionDescription: {
		fontSize: 14,
		fontWeight: "400",
		flex: 1,
		marginBottom: 15
	},
	modalRewardImage: {
		height: 150,
		width: 250,
		borderRadius: 4,
		marginBottom: 5
	},
	modalRewardTitle: {
		fontSize: 14,
		fontWeight: "400",
		flex: 1,
	},
	modalInput: {
		//height: 40,
		width: "100%",
		backgroundColor: "#F9F9F9",
		borderRadius: 8,
		marginTop: 5,
		fontSize: 16,
		paddingHorizontal: 10,
	},
	modalBtn: {
		backgroundColor: "#FB6758",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		height: 40,
		marginVertical: 10,
	},
	modalBtnText: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	PTO: {
		flexDirection: "row",
		alignItems: "center",
	},
	expireDate: {
		fontSize: 9,
		lineHeight: 12,
		fontWeight: "400",
	},
	orderContainer: {
		backgroundColor: "#E8E8E8",
		height: 172,
		width: !isMobile ? 255 : 350,
		borderRadius: 4,
		paddingVertical: 20,
		paddingHorizontal: 15,
		margin: 5,
	},
	container: {
		backgroundColor: !isMobile ? "#E8E8E8" : "#fff",
		height: 140,
		paddingVertical: isMobile ? 5 : null,
		width: !isMobile ? 255 : 350,
		borderRadius: 4,
		paddingHorizontal: 12,
	},
	memberMilestoneContainer: {
		height: !isMobile ? 140 : 108,
		paddingVertical: 0,
		borderRadius: 4,
		paddingHorizontal: 0,
		marginBottom: 4,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 0.5,
		},
		shadowOpacity: 0.15,
		shadowRadius: 4,
		elevation: 5,
	},
	memberMilestoneContent: {
		marginHorizontal: 12,
	},
	memberRewardContainer: {
		marginBottom: 4,
		height: !isMobile ? 140 : 108,
		paddingVertical: 5,
		borderRadius: 4,
		paddingHorizontal: 12,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.15,
		shadowRadius: 3.84,
		elevation: 5,
	},
	title: {
		marginLeft: 5,
		fontSize: 15,
		lineHeight: 25,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	milestoneTitle: {
		fontSize: 15,
		fontWeight: "500",
		fontFamily: 'WorkSans_400Regular',
		//letterSpacing: -0.8,
	},
	description: {
		fontSize: 12,
		lineHeight: 15.62,
		fontWeight: "400",
		marginVertical: 5,
		width: isMobile ? "100%" : null,
	},
	muchImg: { height: 32, width: 32, borderRadius: 16 },
	bottomTxt: {
		fontSize: 10,
		lineHeight: 14,
		fontWeight: "500",
		color: "#00B15C",
	},
	headerView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 10,
	},
	img: {
		height: 22,
		width: 35,
		borderRadius: 4,
	},
	bottomView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	nameTitle: {
		fontSize: 18,
		lineHeight: 26,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	subTitle: {
		marginLeft: 5,
		fontSize: 8,
		lineHeight: 10,
		fontWeight: "400",
		letterSpacing: -0.3,
		color: "#A9AEBE",
	},
	unfilledTxt: {
		fontSize: 15,
		lineHeight: 19.5,
		fontWeight: "400",
		color: "#FB6758",
	},
	seeTxt: {
		fontSize: 14,
		lineHeight: 18.5,
		fontWeight: "500",
		marginTop: 5,
	},
	imgCount: {
		fontSize: 11,
		lineHeight: 18,
		color: "#fff",
		fontWeight: "400",
		position: "absolute",
		top: 9,
		left: 5,
	},
	imgCountModal: {
		fontSize: 15,
		lineHeight: 20,
		color: "#fff",
		fontWeight: "400",
		position: "absolute",
		top: 10,
		left: 6,
	},
	deleteBtn: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#F05343",
	},
	sendBtn: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#F05343",
	},
	editBtn: {
		backgroundColor: "#F05343",
		height: 44,
		width: 133,
		borderRadius: 4,
		justifyContent: "center",
		alignItems: "center",
	},
	editBtnTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	rightSideModal: {
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderBottomLeftRadius: 20,
		alignSelf: "flex-end",
		padding: 20,
		margin: 0,
		width: "38%",
		// height: "100%",
	},
	engagementBtn: {
		backgroundColor: "#FFCF70",
		paddingVertical: 2,
		paddingHorizontal: 4,
		borderRadius: 4,
	},
	progressBar: {
		height: 8,
		marginTop: 10,
		borderRadius: 4,
		backgroundColor: "#fff",
		marginBottom: 2,
	},
	progressBarCount: {
		textAlign: "right",
		position: "absolute",
		right: 0,
		bottom: 0,
		fontSize: 12,
		width: 100
	},
	orderHeader: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
	},
	bottomSheetHeader: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},
	bottomSheetHeaderTitle: {
		flex: 1,
		fontSize: 18,
		lineHeight: 20,
		color: "#000000",
		fontFamily: "WorkSans_400Regular",
	},
	bottomSheetHeaderCloseIcon: {
		marginRight: 16,
	},
	bottomSheetContainer: {
		marginHorizontal: 16,
		flex: 1,
	},
	bottomSheetButton: {
		flex: 1,
		marginHorizontal: 16,
		justifyContent: "center",
	},
	membersCard: {
		marginEnd: !isMobile ? 8 : 12,
		marginTop: !isMobile ? 8 : 12,
	},
	recentActivityTxt: {
		fontSize: 12,
		lineHeight: 67,
		fontWeight: "400",
		marginTop: 15,
		// textAlign: "left",
	},
	memberMileStoneTypeText: {
		fontSize: 10,
		lineHeight: 12,
		color: "#FFFFFF",
	},
	bottomSheetCloseBtn: {
		alignSelf: "flex-end",
		marginTop: 18,
	},
	modal: {
		width: Platform.OS !== "web" ? 304 : 457,
		height: 211,
		backgroundColor: "#ffffff",
		borderRadius: 20,
		alignSelf: "center",
	},
	deleteModalContainer: {
		marginHorizontal: 16,
		flex: 1,
	},
	deleteModalHeader: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 20,
	},
	deleteRewardTitle: {
		fontSize: 20,
		lineHeight: 40,
		color: "#141414",
		fontWeight: "500",
	},
	deleteModalDescription: {
		fontSize: 14,
		color: "#141414",
		paddingTop: 13,
	},
	deleteModalButtons: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "flex-end",
		marginBottom: 25,
	},
	progressBarMain: {
		flex: 1,
		borderRadius: 7,
		backgroundColor: "#fff",
		flexDirection: "row",
		height: 12,
	},
	progressBarGreen: {
		borderRadius: 7,
		backgroundColor: "#00B15C",
		height: 12,
	},
	prog: {
		flex: 0.3,
		borderRadius: 7,
		backgroundColor: "#fff",
		height: 9,
	},
	closeModal: { justifyContent: 'center', alignItems: 'center', height: 30, width: 30 }
});
