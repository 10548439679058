import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

const defaultContextValue = {
  open: false,
  setOpen: () => {},
}

export const Context = createContext(defaultContextValue)

export const ContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false)

  return (
    <Context.Provider value={{ open, setOpen }}>{children}</Context.Provider>
  )
}

ContextProvider.propTypes = {
  children: PropTypes.node,
}
