import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigation } from '@react-navigation/native'
import { CollapseButtonMobile } from '@/components'
import { KebabeMenuMobileContext } from '@/contexts/Community/Group/kebabMenuMobile'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

const KebabMenuMobile = ({
  isModalVisible,
  setModalVisible,
  screenType,
  handleDelete,
  setCreateEditVisible,
}) => {
  const {
    setDeleteMobileVisible,
    setLeaveMobileVisible,
    setInviteMemberWebVisible,
    setManageMemberViewVisible,
    title,
    description,
    id,
    source,
    isOwner,
  } = useContext(KebabeMenuMobileContext)
  const navigation = useNavigation()

  const navigate = useCallback((path, params) => {
    setModalVisible(false)
    navigation.navigate(path, { ...params })
  }, [navigation])

  if (isMobile) {
    return (
      <CollapseButtonMobile
        isCollapseVisible={isModalVisible}
        setCollapseVisible={setModalVisible}
      >
        {(isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS))? <CollapseButtonMobile.Item
          onPress={() => {
            setModalVisible(false)
            setCreateEditVisible(true)
          }}
          color={theme.basics.black_text}
          icon="pencil"
          iconSize={16}
          text="Edit information"
        />
          : null}
        {(isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS)) ? <CollapseButtonMobile.Item

          onPress={() => {
            setModalVisible(false)
            setInviteMemberWebVisible(true)
          }}
          color={theme.basics.black_text}
          icon="addUser"
          iconSize={18}
          text="Invite members"
        />
          : null}
        {(isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS)) ? <CollapseButtonMobile.Item

          onPress={() => {
            setModalVisible(false)
            setManageMemberViewVisible(true)
          }}
          color={theme.basics.black_text}
          icon="community"
          iconSize={18}
          text="Manage members"
        />
          : null}
        {(isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS)) ? <CollapseButtonMobile.Item
          // onPress={() => setDeleteMobileVisible(false)}
          onPress={() => {
            setDeleteMobileVisible(true)
            setModalVisible(false)
          }}
          color={theme.basics.black_text}
          icon="delete"
          iconSize={16}
          text={screenType === 'Group' ? 'Delete group' : 'Delete team'}
        />
          : null}
        {(!isOwner && !canAccess(permissions.MANAGE_TEAMS) && !canAccess(permissions.MANAGE_GROUPS)) ? <CollapseButtonMobile.Item
          onPress={() => {
            setLeaveMobileVisible(true)
            setModalVisible(false)
          }}
          color={theme.basics.black_text}
          icon="leave"
          iconSize={16}
          text="Leave group"
        />
          : null}

      </CollapseButtonMobile>
    )
  }
  return null
}

KebabMenuMobile.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
}

export default KebabMenuMobile