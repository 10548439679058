import React, { useState, useEffect } from 'react';
import { isMobile, ScreenSize } from '@/styles';
import { View, Text, StyleSheet, Image, SafeAreaView, ScrollView, Platform } from 'react-native';
import theme from '@/styles/themes/black';
import RoundLogo from '@/assets/brand/ReframeRoundLogo.png';
import RenderHtml from 'react-native-render-html';
import ApiHandler from '@/api/ApiHandler';
import Button from '@/components/Button';
import { Entypo } from '@expo/vector-icons';

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'

const CommunityGuidelines = ({ navigation }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        getCommunityGuidelines();
    }, []);

    const getCommunityGuidelines = async () => {
        try {
            const response = await new ApiHandler().getCommunityGuidelines();
            if (response?.data) {
                setContent(response?.data?.data?.content);
            }
        } catch (error) {
            console.log('GET COMMUNITY GUIDELINES ERROR', error);
        }
    };

    return (
        <TheaterLayout>
            <View style={styles.Container}>
                <ContentBlockV1>
                    <Text style={styles.Headline}>{'Review Your Community Guidelines'}</Text>
                    <RenderHtml
                        contentWidth={620}
                        source={{ html: content }}
                        baseStyle={{
                            fontFamily: 'WorkSans_400Regular',
                        }}
                    />
                    <View style={styles.btnsView}>
                        <Button
                            onPress={() => navigation.navigate('PersonalInformation')}
                            color="white"
                            contentStyle={styles.previousBtn}>
                            <Entypo name="chevron-left" size={16} color="black" />
                            <Text style={styles.previousBtnText}>Previous</Text>
                        </Button>
                        <Button
                            onPress={() => navigation.navigate('guidedTutorial')}
                            color="black"
                            contentStyle={styles.acceptBtn}>
                            <Text style={styles.acceptBtnText}>Accept</Text>
                            <Entypo name="chevron-right" size={16} color="white" />
                        </Button>
                    </View>
                </ContentBlockV1>
            </View>
        </TheaterLayout>
    );
};

export default CommunityGuidelines;

const styles = StyleSheet.create({
    safeAreaView: {
        flex: 1,
        backgroundColor: theme.basics.background_white,
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
    },
    Container: {
        flex: 1,
        maxWidth: 800,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 0,
        marginHorizontal: 'auto',

        ...Platform.select({
            ios: {

            },
            android: {

            },
            web: {
                minWidth: 800
            }
        })
    },
    Headline: {
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 32,
        textAlign: 'center',
        marginBottom: 30,
        marginTop: 30,
        color: '#000'
    },
    logo: {
        width: isMobile ? 26 : 60,
        height: isMobile ? 26 : 60,
        alignSelf: 'flex-end',
        marginTop: isMobile ? 15 : 56,
        marginRight: isMobile ? 5 : 60,
    },
    title: {
        fontFamily: 'WorkSans_600SemiBold',
        fontWeight: '600',
        fontSize: ScreenSize.Medium ? 32 : 42,
        lineHeight: 46,
        color: theme.basics.black_text,
        alignSelf: isMobile ? 'flex-start' : 'center'
    },
    scrollView: {
        width: isMobile ? '100%' : 620,
        height: 554,
        marginTop: 25,
    },
    btnsView: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        ...Platform.select({
            ios: {
                flexDirection: 'column'
            },
            android: {
                flexDirection: 'column'
            },
            web: {
                flexDirection: 'row'
            }
        })
    },
    previousBtn: {
        borderWidth: 1,
        borderColor: theme.basics[700],
        height: 48,
        alignItems: 'center',
        ...Platform.select({
            ios: {
                width: '100%',
                marginBottom: 30
            },
            android: {
                width: '100%',
                marginBottom: 30
            },
            web: {
                width: 288
            }
        })
    },
    previousBtnText: {
        color: theme.basics.black_text,
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 17,
        textAlign: 'center'
    },
    acceptBtn: {
        height: 50,
        alignItems: 'center',
        ...Platform.select({
            ios: {
                width: '100%'
            },
            android: {
                width: '100%'
            },
            web: {
                width: 288
            }
        })
    },
    acceptBtnText: {
        color: theme.basics.background_white,
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 17,
        textAlign: 'center',
    }
});