import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function deleteIcon(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M10.25 9H13.25V10.5H10.25V9ZM10.25 3H15.5V4.5H10.25V3ZM10.25 6H14.75V7.5H10.25V6ZM1.25 10.5C1.25 11.325 1.925 12 2.75 12H7.25C8.075 12 8.75 11.325 8.75 10.5V3H1.25V10.5ZM9.5 0.75H7.25L6.5 0H3.5L2.75 0.75H0.5V2.25H9.5V0.75Z" fill="#1B1B1B"/>
    </Svg>
    
  )
}

deleteIcon.propTypes = Svg.propTypes
