import React from 'react'

import Event from './Event';
import { EVENT_MODE } from "./consts";



const EventCreate = () => {

    return <Event title={"Create Event"} mode={EVENT_MODE.create} />
}

export default EventCreate