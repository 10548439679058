import styled from 'styled-components/native'
import { Animated, Dimensions } from 'react-native'

const { width, height } = Dimensions.get('window')

export const Main = styled(Animated.View)`
	height:${height}px;
	width: ${width}px;
	align-items: flex-end;
	justify-content: flex-end;
`
export const CloseButton = styled.TouchableOpacity`
	width: 20px;
	height: 20px;
	margin: 17px 17px 0 0;
	align-self: flex-end;
`

export const Card = styled.View`
	height: ${({height}) => height || 180}px;
	width: ${width}px;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	background-color: #fff;
	position: relative;
	align-items: ${({ alignItems }) => alignItems || 'center'};
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
	z-index: 9999;
`

export const Container = styled.View`
	align-items: center;
	width: ${props => props.width}px;
	position: relative;
`

export const ContainerModal = styled.View`
	align-items: center;
	justify-content: center;
	height: ${height}px;
	width: ${width}px;
	background-color: ${({ backgroundColor }) => backgroundColor || 'rgba(0,0,0,0.4)'};
	z-index: 9999;
`
