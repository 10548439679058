import React, { useEffect, useReducer, useState } from "react";
import { uniq } from "lodash";
import ApiHandler from "@/api/ApiHandler";
import ChatContext from "@/contexts/ChatContext";
import { getToken } from "../api";
import { useSelector } from "react-redux";
import { getMember } from '@/redux/selectors/auth'
import { Platform, View } from 'react-native';
import { ActivityIndicator } from "react-native-paper";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";


const initialState = {
    messages: {},
    attachments: {},
    participants: {},
    typingData: {},
    unreadMessages: {},
    roles: {}
}

const messagesReducer = (state, action) => {
    switch (action.type) {
        case "pushMessages": {
            const { channelSid, messages: messagesToAdd } = action.payload;
            const existingMessages = state[channelSid] ?? [];

            return Object.assign({}, state, {
                [channelSid]: existingMessages.concat(messagesToAdd),
            });
        }
        case "addMessages": {
            //get convo sid and messages to add from payload
            const { channelSid, messages: messagesToAdd } = action.payload;

            //get existing messages for the convo
            const existingMessages = state[channelSid] ?? [];

            const filteredExistingMessages = existingMessages.filter(
                (message) => {
                    return !messagesToAdd.find(
                        (value) =>
                            value.body === message.body &&
                            value.author === message.author &&
                            value.media?.filename === message.media?.filename &&
                            value.media?.size === message.media?.size &&
                            (message.index === -1 || value.index === message.index)
                    );
                }
            );

            //add new messages to exisiting, ignore duplicates
            const messagesUnique = [...filteredExistingMessages, ...messagesToAdd];

            const sortedMessages = messagesUnique.sort(
                (a, b) => a.dateCreated.getTime() - b.dateCreated.getTime()
            );

            //overwrite the channelSid messages
            return Object.assign({}, state, {
                [channelSid]: sortedMessages,
            });
        }

        case "removeMessages": {
            const { channelSid, messages: messagesToRemove } = action.payload;
            const existingMessages = state[channelSid] ?? [];
            const messages = existingMessages.filter(
                ({ index }) =>
                    !messagesToRemove.find(
                        ({ index: messageIndex }) => messageIndex === index
                    )
            );

            return Object.assign({}, state, {
                [channelSid]: messages,
            });
        }
        default:
            return state;
    }
}

const attachmentReducer = (state, action) => {
    switch (action.type) {
        case "addAttachment":
            const { channelSid, messageIndex, attachment } = action.payload;
            return {
                ...state,
                [channelSid]: {
                    ...(state[channelSid] || {}),
                    [messageIndex]: attachment,
                },
            };
        default:
            return state;
    }
}

const participantsReducer = (state, action) => {
    switch (action.type) {
        case "updateParticipant":
            const { participants, sid } = action.payload;
            return Object.assign({}, state, { [sid]: participants });
        default:
            return state;
    }
}

const roleReducer = (state, action) => {
    switch (action.type) {
        case "updateRoles":
            const { roles, sid } = action.payload;
            return Object.assign({}, state, { [sid]: roles })
        default:
            return state;
    }
}

const typingReducer = (state, action) => {
    switch (action.type) {
        case "typingStarted": {
            const { channelSid, participant } = action.payload;
            const existedUsers = state[channelSid] ?? [];
            existedUsers.push(participant);

            const unique = existedUsers.filter((item, index) => existedUsers.indexOf(item) === index);

            return Object.assign({}, state, { [channelSid]: unique });
        }
        case "typingEnded": {
            const { channelSid, participant } = action.payload;
            const filteredUsers = (state[channelSid] ?? []).filter(
                (user) => user !== participant
            );
            return Object.assign({}, state, { [channelSid]: filteredUsers });
        }
        default:
            return state;
    }
}

const unreadMessagesReducer = (state, action) => {
    switch (action.type) {
        case "updateUnreadMessages":
            //get convo sid and messages to add from payload
            const { channelSid, unreadCount } = action.payload;
            //overwrite the channelSid unread count
            return Object.assign({}, state, { [channelSid]: unreadCount });
        default:
            return state;
    }
}


const ChatWrapper = ({ children }) => {
    const [workSpaceMembers, setWorkSpaceMembers] = useState([]);
    const [token, setToken] = useState("");
    const [sid, setSid] = useState("");
    const [messages, dispatchMessages] = useReducer(messagesReducer, initialState.messages);
    const [attachments, dispatchAttachments] = useReducer(attachmentReducer, initialState.attachments);
    const [participants, dispatchParticipants] = useReducer(participantsReducer, initialState.participants);
    const [conversations, setConversations] = useState([]);
    const [unreadMessages, dispatchUnread] = useReducer(unreadMessagesReducer, initialState.unreadMessages);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [typingData, dispatchTyping] = useReducer(typingReducer, initialState.typingData);
    const [lastReadIndex, updateLastReadIndex] = useState(-1);
    const [notifications, setNotificiations] = useState([]);
    const user = useSelector(getMember);
    const accentColor = UseBrandingAccentColor();
    const [client, setClient] = useState();
    const [roles, dispatchRoles] = useReducer(roleReducer, initialState.roles);

    useEffect(() => {
        if (!token) {
            getToken(user.id.toString()).then(token => {
                setLoadingStatus(prev => {
                    setToken(token);
                    return false;
                })
            })
        }
    }, [])

    useEffect(() => {
        setToken("");
        getToken(user.id.toString()).then(token => {
            setLoadingStatus(prev => {
                setToken(token);
                return false;
            })
        })
    }, [user])



    const addMessages = (channelSid, messages) => dispatchMessages({ type: "addMessages", payload: { channelSid, messages } })

    const pushMessages = (channelSid, messages) => dispatchMessages({ type: "pushMessages", payload: { channelSid, messages } })

    const removeMessages = (channelSid, messages) => dispatchMessages({ type: "removeMessages", payload: { channelSid, messages } });


    const getWorkSpaceMembers = async () => {
        try {
            const res = await new ApiHandler().getWorkSpaceMembers();
            const data = res.data;

            if (data?.length) {
                setWorkSpaceMembers(data);
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!workSpaceMembers.length && token) {
            getWorkSpaceMembers();
        }
    }, [token])

    const updateRoles = (roles, sid) => dispatchRoles({ type: "updateRoles", payload: { roles, sid } })

    const _getUser = (id) => workSpaceMembers?.find(member => member.id == id);

    const addAttachment = (channelSid, messageIndex, attachment) => dispatchAttachments({ type: "addAttachment", payload: { channelSid, messageIndex, attachment } })

    const addNotifications = (notification) => {
        setNotificiations(prev => [...notifications, notification])
    }

    const removeNotifications = (removeCount) => {
        if (removeCount + 1 > notifications.length) {
            return [];
        }
        setNotificiations(prev => {
            return notifications.slice(removeCount, notifications.length)
        });
    }

    const listConversations = (convos) => {
        setConversations(prev => {
            return convos.sort((a, b) => {
                return (
                    (b.lastMessage?.dateCreated || b.dateUpdated) -
                    (a.lastMessage?.dateCreated || a.dateUpdated)
                );
            });
        })
    }

    const addConversation = (convo) => {
        setConversations(prev => {
            const temp = conversations ?? [];
            temp.push(convo);
            return temp.sort((a, b) => {
                return (
                    (b.lastMessage?.dateCreated || b.dateUpdated) -
                    (a.lastMessage?.dateCreated || a.dateUpdated)
                )
            })
        })
    }

    const updateConversation = (channelSid, parameters) => {
        const stateCopy = [...conversations];
        let target = stateCopy.find(
            (convo) => convo.sid === channelSid
        );

        target =
            target &&
            ({
                ...target,
                ...parameters,
            });

        setConversations(prev => [...stateCopy]);
    }

    const removeConversation = (sid) => {
        const stateCopy = [...conversations];

        setConversations(prev => {
            return stateCopy.filter(
                (convo) => convo.sid !== sid
            );
        })
    }

    const updateCurrentConversation = (sid) => {
        setSid(prev => sid);
    }

    const setLastReadIndex = (index) => {
        updateLastReadIndex(prev => index)
    }

    const updateLoadingState = (state) => {
        setLoadingStatus(prev => state ?? false);
    }

    const updateParticipants = (participants, sid) => dispatchParticipants({ type: "updateParticipant", payload: { participants, sid } })

    const updateToken = (token) => {
        setToken(prev => token);
    }

    const startTyping = (channelSid, participant) => dispatchTyping({ type: "typingStarted", payload: { channelSid, participant } })
    const endTyping = (channelSid, participant) => dispatchTyping({ type: "typingEnded", payload: { channelSid, participant } })

    const updateUnreadMessages = (channelSid, unreadCount) => dispatchUnread({ type: "updateUnread", payload: { channelSid, unreadCount } })


    return (
        <View style={{ flex: 1, minHeight: "100%" }}>
            <ChatContext.Provider value={{
                addAttachment,
                addNotifications,
                listConversations,
                removeConversation,
                updateConversation,
                updateCurrentConversation,
                setClient,
                endTyping,
                getParticipant: _getUser,
                setLastReadIndex,
                updateLoadingState,
                removeMessages,
                addMessages,
                pushMessages,
                updateUnreadMessages,
                removeNotifications,
                updateParticipants,
                startTyping,
                updateToken,
                updateRoles,
                addConvo: addConversation,

                lastReadIndex,
                loadingStatus,
                unreadMessages,
                workspaceMembers: workSpaceMembers,
                notifications,
                messages,
                client,
                participants,
                sid,
                token,
                conversations,
                attachments,
                typingData,
                roles,

            }}>
                {loadingStatus || !token ? (
                    <View
                        style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}
                    >
                        <ActivityIndicator color={accentColor} />
                    </View>
                ) : (
                    <>
                        {children}
                    </>
                )}
            </ChatContext.Provider>
        </View>
    )
}

export default ChatWrapper;
