import React, { useContext, useEffect, useState } from 'react'
import { Dimensions, View, TouchableOpacity, Text } from 'react-native'
import DropDown from "react-native-paper-dropdown"
import styled from 'styled-components/native'
import { createLocalAudioTrack, createLocalVideoTrack } from 'twilio-video';
import VideoContext from '../../context';
import { MaterialCommunityIcons } from "@expo/vector-icons";

const { width, height } = Dimensions.get('window');

const Spacer = styled.View`
    margin-top: 20;
`


const SettingsModal = ({ local, setLocalAudioTrack, setLocalVideoTrack }) => {
    const [audioInputDropdownVisible, setAudioInputDropdownVisible] = useState(false);
    const [audioOutputDropdownVisible, setAudioOutputDropdownVisible] = useState(false);
    const [videoInputDropdownVisible, setVideoInputDropdownVisible] = useState(false);
    const { audioInputDevices, audioOutputDevices, videoInputDevices, getSetting, updateSetting } = useContext(VideoContext);


    function stopTracks(tracks) {
        tracks.forEach(function (track) {
            if (track) { track.stop(); }
        })
    }

    const switchAudio = async (deviceId) => {
        const tracks = Array.from(local.audioTracks.values()).map(track => {
            return track.track;
        })

        local.unpublishTracks(tracks)

        stopTracks(tracks);

        const LocalAudio = await createLocalAudioTrack({
            deviceId: { exact: deviceId }
        });

        await local.publishTrack(LocalAudio);

        updateSetting("audioInput", deviceId);

        setLocalAudioTrack(LocalAudio);
    }

    const switchVideo = async (deviceId) => {

        const tracks = Array.from(local.videoTracks.values()).map(track => {
            return track.track;
        })


        local.unpublishTracks(tracks)

        stopTracks(tracks);

        const LocalVideo = await createLocalVideoTrack({
            deviceId: { exact: deviceId },
            aspectRatio: 16 / 9,
        })

        await local.publishTrack(LocalVideo);


        updateSetting("videoInput", deviceId);

        setLocalVideoTrack(LocalVideo);

    }

    console.log(Array.from(audioInputDevices).map(item => ({ label: item.label, value: item.deviceId })))



    return (
        <>
            <View style={{
                marginBottom: 20
            }}>
                <Text style={{
                    fontSize: 16,
                    fontWeight: '600',
                    fontFamily: 'WorkSans_400Regular',
                    marginBottom: 10,
                    color: '#f05343',
                }}>
                    {'Microphone'}
                </Text>

                <View>
                    {Array.from(audioInputDevices).map(
                        item => (
                            <TouchableOpacity
                                onPress={() => switchAudio(item.deviceId)}
                                style={{
                                    marginBottom: 10,
                                    flexDirection: 'row'
                                }}
                            >
                                <Text style={{
                                    fontSize: 13
                                }}>{item.label}</Text>

                                {getSetting("audioInput") == item.deviceId && (
                                    <MaterialCommunityIcons name="check-circle-outline" size={17} color={'#f05343'} style={{ marginLeft: 7 }} />
                                )}
                            </TouchableOpacity>
                        )
                    )}
                </View>
            </View>

            <View style={{
                marginBottom: 20
            }}>
                <Text style={{
                    fontSize: 16,
                    fontWeight: '600',
                    fontFamily: 'WorkSans_400Regular',
                    marginBottom: 10,
                    color: '#f05343'
                }}>
                    {'Speaker/Headset'}
                </Text>

                <View>
                    {Array.from(audioOutputDevices).map(
                        item => (
                            <TouchableOpacity
                                onPress={() => updateSetting("audioOutput", item.deviceId)}
                                style={{
                                    marginBottom: 10,
                                    flexDirection: 'row'
                                }}
                            >
                                <Text style={{
                                    fontSize: 13
                                }}>{item.label}</Text>

                                {getSetting("audioOutput") == item.deviceId && (
                                    <MaterialCommunityIcons name="check-circle-outline" size={17} color={'#f05343'} style={{ marginLeft: 7 }} />
                                )}
                            </TouchableOpacity>
                        )
                    )}
                </View>
            </View>

            <View style={{
                marginBottom: 20
            }}>
                <Text style={{
                    fontSize: 16,
                    fontWeight: '600',
                    fontFamily: 'WorkSans_400Regular',
                    marginBottom: 10,
                    color: '#f05343'
                }}>
                    {'Camera'}
                </Text>

                <View>
                    {Array.from(videoInputDevices).map(
                        item => (
                            <TouchableOpacity
                                onPress={() => switchVideo(item.deviceId)}
                                style={{
                                    marginBottom: 10,
                                    flexDirection: 'row'
                                }}
                            >
                                <Text style={{
                                    fontSize: 13
                                }}>{item.label}</Text>

                                {getSetting("videoInput") == item.deviceId && (
                                    <MaterialCommunityIcons name="check-circle-outline" size={17} color={'#f05343'} style={{ marginLeft: 7 }} />
                                )}
                            </TouchableOpacity>
                        )
                    )}
                </View>
            </View>
        </>
    )
}



export default SettingsModal;



