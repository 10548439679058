import React from 'react'

import { useRoute } from '@react-navigation/native'

import Event from './Event';
import { EVENT_MODE } from "./consts";



const EventDuplicate = () => {

    const { params } = useRoute();

    return <Event title={"Duplicate Event"} mode={EVENT_MODE.duplicate} eventInfo={params || {}} />
}

export default EventDuplicate