import React, { useState, useEffect } from "react";
import { View, Text, Image, StyleSheet, FlatList } from "react-native";
import { MembersTopPerformers } from "./TopPerformers";
import ApiHandler from "@/api/ApiHandler";
import { isMobile } from "@/styles";
import { ProgressBar, Colors } from "react-native-paper";
import DotedLine from "./DotedLine";

const LeaderBoardComponent = ({
    image,
    points,
    badge,
    badges,
    percent,
    headerPoints,
    member
}) => {
    return (
        <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
            <View style={{ flex: 0.2 }}>
                <MembersTopPerformers image={image} badge={badge} text={"200"} />
            </View>
            <View style={{ flex: 0.8 }}>
                <View style={[styles.badgesCard, { width: "100%" }]}>
                    <View style={styles.pointsCardHeader}>
                        <Text>{member?.first_name} {member?.last_name}</Text>
                        <Text style={styles.pointsCounterTxt}>
                            {points}
                            <Text style={styles.pointsTxt}>{" Points"}</Text>
                        </Text>
                        {/*<Text style={styles.unlockTxt}>{"Unlock next Milestone"}</Text>*/}
                    </View>
                    <View style={styles.progressBar}>
                        <View style={[styles.progressBarBlue, { flex: points / badges[badges.length - 1]?.value ? points / badges[badges.length - 1]?.value : 0 }]} />
                        {/* <View style={styles.progressBarGrey} /> */}
                    </View>
                    <DotedLine />
                    <View style={styles.badgesView}>
                        <View style={[styles.pointCardBadges]}>
                            <Image
                                source={{ uri: badges[0]?.badge_image_url }}
                                style={styles.badgeSize}
                            />
                            <Text style={styles.numOfBadges}> {badges[0]?.value}</Text>
                        </View>
                        <View style={[styles.pointCardBadges]}>
                            <Image
                                source={{ uri: badges[1]?.badge_image_url }}
                                style={styles.badgeSize}
                            />
                            <Text style={styles.numOfBadges}> {badges[1]?.value}</Text>
                        </View>
                        <View style={[styles.pointCardBadges]}>
                            <Image
                                source={{ uri: badges[2]?.badge_image_url }}
                                style={styles.badgeSize}
                            />
                            <Text style={styles.numOfBadges}> {badges[2]?.value}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default LeaderBoardComponent;

const styles = StyleSheet.create({
    rightSideModal: {
        backgroundColor: "#fff",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        alignSelf: "flex-end",
        padding: 20,
        margin: 0,
        width: "32%",
        // height: "100%",
    },
    closeBtn: {
        position: "absolute",
        left: -2,
        top: 1,
    },
    modalHeaderView: {
        flexDirection: "row",
        alignItems: "center",
        // justifyContent: "space-between",
    },
    mobContainerHeader: {
        flexDirection: "row",
        paddingTop: isMobile ? 30 : null,
        alignItems: "center",
        justifyContent: !isMobile ? "space-between" : null,
    },
    headerBadge: {
        height: isMobile ? 30 : 80,
        width: isMobile ? 30 : 80,
        marginEnd: 5,
    },
    mobTitle: { flexDirection: "row", alignItems: "center" },
    titleName: {
        fontSize: isMobile ? 20 : 24,
        lineHeight: isMobile ? 26 : 40,
        fontWeight: "500",
        letterSpacing: -0.8,
        marginEnd: !isMobile ? 10 : null,
    },
    subTitle: {
        fontSize: 12,
        lineHeight: 15.62,
        fontWeight: "500",
        letterSpacing: -0.8,
        color: "#C80101",
        marginEnd: 10,
    },
    share: {
        height: 18,
        width: 18,
    },
    xView: {
        height: 20,
        width: 20,
        borderRadius: 10,
        backgroundColor: "#fff",
        marginEnd: 3,
        justifyContent: "center",
        alignItems: "center",
    },
    badgesCard: {
        // height:98,
        width: !isMobile ? "65%" : null,
        backgroundColor: "#fff",
        borderRadius: 7,
        marginTop: 10,
        paddingVertical: 10,
        paddingHorizontal: 10,
    },
    pointsCardHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    pointsCounterTxt: {
        fontSize: 20,
        lineHeight: 22,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    pointsTxt: {
        fontSize: 12,
        lineHeight: 22,
        fontWeight: "500",
        letterSpacing: -0.8,
        color: "gray",
    },
    unlockTxt: {
        fontSize: 10,
        lineHeight: 12,
        fontWeight: "400",
        letterSpacing: -0.8,
        color: "gray",
        // textAlign:'right'
        marginTop: 5,
    },
    // progressBar: {
    // 	height: 10,
    // 	marginVertical: 10,
    // 	borderRadius: 4,
    // 	backgroundColor: "#E7E7E7",
    // },
    badgesView: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    pointCardBadges: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
    },
    badgeSize: {
        height: 30,
        width: 30,
    },
    numOfBadges: {
        color: "#A0A0A0",
        fontSize: 12,
        lineHeight: 15,
        fontWeight: "500",
    },
    trackerBadgeBorder: {
        height: 80,
        width: 80,
        borderRadius: 50,
        borderWidth: 5,
        borderColor: "#000",
        justifyContent: "center",
        alignItems: "center",
        position: isMobile ? "absolute" : null,
        right: isMobile ? 10 : null,
        top: isMobile ? 1 : null,
        marginVertical: isMobile ? null : 10,
    },
    trackerBadge: {
        height: 63,
        width: 63,
        borderRadius: 35,
        backgroundColor: "#F05343",
        justifyContent: "center",
        alignItems: "center",
    },
    pointsTracker: {
        fontSize: 8,
        lineHeight: 9,
        fontWeight: "400",
        textAlign: "center",
        color: "#fff",
        marginTop: 5,
    },
    pointsTrackCounter: {
        fontSize: 20,
        lineHeight: 22,
        fontWeight: "700",
        textAlign: "center",
        color: "#fff",
    },
    pointsViews: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
    },
    pointsTitle: {
        fontSize: 24,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
        textAlign: "left",
        // marginTop: 5,
    },
    monthView: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 20,
        marginTop: 10,
        marginEnd: 8,
    },
    monthTxt: {
        fontSize: 14,
        lineHeight: 18.3,
        fontWeight: "500",
        letterSpacing: -0.8,
        marginEnd: 5,
    },
    cardView: {
        flexDirection: "row",
    },
    pointsTitle: {
        fontSize: 24,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
        textAlign: "left",
        // marginTop: 5,
    },
    allMember: {
        fontSize: 14,
        lineHeight: 16,
        fontWeight: "400",
        letterSpacing: -0.8,
        marginTop: 8,
        textAlign: "right",
    },
    leaderBoardView: {
        flex: 1,
        flexDirection: "row",
        marginTop: 10,
    },
    memberCardTitleView: {
        marginTop: 30,
        flexDirection: "row",
    },
    memberCardTitle: {
        fontSize: 18,
        lineHeight: 21.09,
        fontWeight: "400",
        marginEnd: 12,
        textAlign: "left",
    },
    memberCardSubTitle: {
        fontSize: 14,
        lineHeight: 17,
        fontWeight: "400",
        marginTop: 3,
        // textAlign: "left",
    },
    titleView: {
        paddingHorizontal: 5,
    },
    milestoneModalTitle: {
        fontSize: 20,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
        marginTop: 2,
    },
    monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
    progressBar: {
        flex: 1,
        borderRadius: 6,
        backgroundColor: "#E7E7E7",
        height: 10,
        marginVertical: 7,
        flexDirection: "row",
    },
    progressBarBlue: { backgroundColor: "#624AD1", borderRadius: 6, height: 10 },
    progressBarGrey: {
        flex: 0.1,
        backgroundColor: "#E7E7E7",
        borderRadius: 6,
        height: 10,
    },
});
