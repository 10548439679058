import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, Text, View, Image } from 'react-native'
import AutoLink from 'react-native-autolink'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate'
import VideoContext from '../context'
import { formatUserName } from '@/utils/format'
const FloatingMessage = ({ timeStamp, message, sender }) => {
    const color = UseBrandingAccentColor();
    const { getParticipant } = useContext(VideoContext);
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        if (sender !== "system") {
            const user = getParticipant(sender);
            setUserInfo(user);
        }
    }, [])

    return (
        <View style={{ display: userInfo || sender == 'system' ? 'flex' : "none", position: 'absolute', padding: 10, flexDirection: 'row', top: -90, right: "12%", borderRadius: 5, backgroundColor: "white", width: '60%', height: 80 }}>
            <View style={{ flex: 1, justifyContent: 'center' }}>
                {sender == "system" ? (
                    <Image source={require("@/assets/brand/Icon_96.png")} style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: color }} />
                ) : userInfo?.avatar_url ? (
                    <Image source={{ uri: userInfo?.avatar_url }} style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: color }} />
                ) : (
                    <AvatarTemplate letter={userInfo?.avatar_initials} />
                )}
            </View>
            <View style={{ flex: 3, justifyContent: 'center' }}>
                <Text numberOfLines={1} style={[styles.text, { fontWeight: 'bold' }]}>{sender == 'system' ? "System" : formatUserName(userInfo?.first_name, userInfo?.last_name)}</Text>
                <AutoLink linkProps={{ style: { color } }} textProps={{ style: styles.text, numberOfLines: 2 }} text={message} />
            </View>
        </View>
    )
}

export default FloatingMessage;

const styles = StyleSheet.create({
    text: {
    }
})