import _ from 'lodash'
import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import { updateInfiniteScroll, updateInfiniteScrollDetailPage } from '@/redux/helper'

// Constants
export const constants = {
  FEEDS_INDEX: createSagaAction('FEEDS_INDEX'),
  FEEDS_NEXT_INDEX: createSagaAction('FEEDS_NEXT_INDEX'),
  STORE: createSagaAction('POSTS_STORE'),
  INDEX: createSagaAction('POSTS_INDEX'),
  LIKE: createSagaAction('POSTS_LIKE'),
  LIKE_ARTICLE: createSagaAction('ARTICLE_LIKE'),
  COMMENT_LIST: createSagaAction('COMMENT_LIST'),
  COMMENT: createSagaAction('POSTS_COMMENT'),
  COMMENT_ARTICLE: createSagaAction('ARTICLE_COMMENT'),
  DELETE_COMMENT: createSagaAction('DELETE_COMMENT'),
  DELETE_ARTICLE_COMMENT: createSagaAction('DELETE_ARTICLE_COMMENT'),
  DELETE: createSagaAction('POSTS_DELETE'),
  UPDATE: createSagaAction('POSTS_UPDATE'),
  RESONSE_ASSESSMENT: createSagaAction('RESONSE_ASSESSMENT'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  store: ({ text, attachmentType, attachmentId, file }, next) => ({
    type: constants.STORE.ACTION,
    text,
    attachmentType,
    attachmentId,
    file,
    next,
  }),
  comment: ({ comment, contentType, contentId }, next) => ({
    type: constants.COMMENT.ACTION,
    comment,
    contentType,
    contentId,
    next,
  }),
  comment_list: ({ id, postType }, next) => ({
    type: constants.COMMENT_LIST.ACTION,
    id,
    postType,
    next,
  }),
  comment_in_article: ({ comment, contentType, contentId }, next) => ({
    type: constants.COMMENT_ARTICLE.ACTION,
    comment,
    contentType,
    contentId,
    next,
  }),
  index: ({ page, attachmentType, attachmentId }, next) => ({
    type: constants.INDEX.ACTION,
    page,
    attachmentType,
    attachmentId,
    next,
  }),
  feeds_index: ({ page }, next) => ({
    type: constants.FEEDS_INDEX.ACTION,
    page,
    next,
  }),
  feeds_next_index: (next) => ({
    type: constants.FEEDS_NEXT_INDEX.ACTION,
    next,
  }),
  like: ({ id, user, postType, post_id }, next) => ({
    type: constants.LIKE.ACTION,
    id,
    user,
    postType,
    post_id,
    next,
  }),
  like_article: ({ id, user, postType, post_id }, next) => ({
    type: constants.LIKE_ARTICLE.ACTION,
    id,
    user,
    postType,
    post_id,
    next,
  }),
  delete: ({ id }, next) => ({
    type: constants.DELETE.ACTION,
    id,
    next,
  }),
  update: ({ id, text, file }, next) => ({
    type: constants.UPDATE.ACTION,
    id,
    text,
    file,
    next,
  }),
  delete_comment: ({ id }, next) => ({
    type: constants.DELETE_COMMENT.ACTION,
    id,
    next,
  }),
  delete_article_comment: ({ id, post_id, post_type }, next) => ({
    type: constants.DELETE_ARTICLE_COMMENT.ACTION,
    id,
    post_id,
    post_type,
    next,
  }),
  reponse_assessments: ({ question_id, post_id, post_type, options, text }, next) => ({
    type: constants.RESONSE_ASSESSMENT.ACTION,
    question_id,
    post_id,
    post_type,
    options,
    text,
    next,
  }),
}

const updatedFeed = (state, { action, payload }) => {
  if (action.postType === 'App\\Post' || action.postType === 'App\\Article' || action.postType === 'App\\Event' || action.postType === 'App\\Course') {
    return state.posts.data
      .map(post => {
        if (post.ID === action?.id) {
          if (payload.data !== undefined) {
            post.Data.likes.push(payload.data)
            post.Data.liked = payload.data
            return post
          }
          post.Data.likes = post.Data.likes.filter(like => like?.author_id !== action.user?.id)
          post.Data.liked = null
          return post
        }
        return post
      })
  }

  if (action.post_type === 'App\\CustomAssessment') {
    const allPosts = state.posts.data.slice()
    const index = state.posts.data.findIndex(p => p.ID == action.post_id)
    if (index > -1) {
      allPosts.splice(index, 1)
    }
    return allPosts
  }

  if (action.postType === 'App\\Comment') {
    const postIndex = state.posts.data.findIndex((post) => post.id === action.post_id)
    return state.posts.data
      .map((post, index) => {
        if (index === postIndex)
          return {
            ...post,
            comments: payload?.data ? (
              post.Data.comments.map((comment, idx) => {
                if (comment.id === action.id) {
                  post.Data.comments[idx].likes.push(payload.data)
                  return post.Data.comments[idx]
                }
                return post.Data.comments[idx]
              })
            ) : (
              post.Data.comments.map((comment, idx) => {
                if (comment.id === action.id) {
                  post.Data.comments[idx].likes = post.Data.comments[idx].likes
                    .filter(({ author_id }) => author_id !== action?.user?.id)
                  return post.Data.comments[idx]
                }
                return post.Data.comments[idx]
              })
            )
          }
        return post
      })
  }
  // COMMENTS LIKE
  return state.posts.data
}

const updatedFeedAfterDeleteAPost = (state, { action }) => {
  state.posts.data = _.filter(state.posts.data, post => {
    return post.id !== action.id
  })
  return state.posts.data
}


const updatedFeedAfterEditPost = (state, { action }) => {
  return state.posts.data
    .map(post => post.id === action.payload.data.id ? action.payload.data : post)
}

const updatedFeedAfterCommentOnMobile = (state, { action, payload }) => {
  return state.posts.data
    .map(post => {
      if (post.ID === action?.id) {
        if (payload.data !== undefined) {
          post.Data.comments = payload.data
          return post
        }
        return post
      }
      return post
    })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.LIKE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      posts: {
        ...state.posts,
        data: updatedFeed(state, action)
      }
    }
  },
  [constants.LIKE_ARTICLE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      posts: {
        ...state.posts,
        data: updatedFeed(state, action)
      }
    }
  },
  [constants.RESONSE_ASSESSMENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      posts: {
        ...state.posts,
        data: updatedFeed(state, action)
      }
    }
  },
  [constants.INDEX.ACTION]: (state) => ({
    ...state,
    loading: true,
  }),
  [constants.COMMENT_LIST.SUCCESS]: (state, action) => ({
    ...state,
    posts: {
      ...state.posts,
      data: updatedFeedAfterCommentOnMobile(state, action)
    }
  }),
  [constants.INDEX.SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    page: action?.payload.current_page,
    next_page_url: action?.payload.next_page_url,
    last_page: action?.payload.last_page,
    per_page: action?.payload.per_page,
    posts: updateInfiniteScroll(state.posts, action),
  }),
  [constants.FEEDS_INDEX.ACTION]: (state) => ({
    ...state,
    loading: true,
  }),
  [constants.FEEDS_NEXT_INDEX.SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    posts: updateInfiniteScrollDetailPage(state.posts, state.per_page, action),
  }),
  [constants.COMMENT.ACTION]: (state) => {
    return {
      ...state,
      loading: true,
    }
  },
  [constants.COMMENT.SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.COMMENT_ARTICLE.ACTION]: (state) => {
    return {
      ...state,
      loading: true,
    }
  },
  [constants.COMMENT_ARTICLE.SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.DELETE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      posts: {
        ...state.posts,
        data: updatedFeedAfterDeleteAPost(state, action)
      }
    }
  },
  [constants.UPDATE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      posts: {
        ...state.posts,
        data: updatedFeedAfterEditPost(state, action)
      }
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: false,
  posts: {
    data: [],
    page: 1,
    last_page: 1,
    loading: false,
    next_page_url: null,
    per_page: 10,
  },
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})