import React, { useCallback } from 'react'
import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import { SimpleModal, Icon } from '@/components'
import {
  LeaveCard,
  CloseLeaveModalButton,
  LeaveText,
  ButtonsContainer,
  LeaveButton,
  LeaveButtonText,
} from './styles'
import { actions } from '@/redux/reducers/posts'
import { isMobile } from '@/styles'

const DeleteCommentModal = ({
  setVisible,
  isVisible,
  id,
  post_id,
  contentType,
  onClose,
  onCloseMobile,
  fetchPost
}) => {
  const dispatch = useDispatch()

  const handleOnlyClose = useCallback(() => {
    setVisible(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleDelete = useCallback(() => {
    if (contentType == 'App\\Article') {
      dispatch(actions.delete_article_comment({ id, post_type: contentType, post_id}, () => {
        setVisible(false)
        if (isMobile) {
          onCloseMobile()
          return
        }
        if (onClose) {
          onClose()
        }
      }))
      return
    }
    dispatch(actions.delete_comment({ id: id,}, () => {
    dispatch(actions.comment_list({postType: contentType, id: post_id }))
      setVisible(false)
       if (isMobile) {
       fetchPost()
        onCloseMobile()
        return
      }
      if (onClose) {
        onClose()
      }
    }))
  }, [id, setVisible, onClose, onCloseMobile])

  return (
    <SimpleModal isVisible={isVisible}>
      <LeaveCard>
        <CloseLeaveModalButton onPress={handleOnlyClose}>
          <Icon
            icon="close"
            color={theme.basics.brand}
            height={14}
            width={14}
          />
        </CloseLeaveModalButton>
        <LeaveText>
					Are you sure you want to delete this comment?
        </LeaveText>
        <ButtonsContainer>
          <LeaveButton cancel onPress={handleOnlyClose}>
            <LeaveButtonText cancel >
							Cancel
            </LeaveButtonText>
          </LeaveButton>
          <LeaveButton onPress={handleDelete}>
            <LeaveButtonText>
							Delete the comment
            </LeaveButtonText>
          </LeaveButton>
        </ButtonsContainer>
      </LeaveCard>
    </SimpleModal>
  )
}

DeleteCommentModal.propTypes = {
  id: PropTypes.number.isRequired,
  post_id: PropTypes.number,
  contentType: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onCloseMobile: PropTypes.func,
}

export default DeleteCommentModal
