import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { LineChart } from 'react-native-chart-kit'
import { Dimensions, useWindowDimensions, Platform } from 'react-native'
import { isMobile } from '@/styles'
import { useSideDrawer } from '@/hooks/sideDrawer'
const screenWidth = Dimensions.get('window').width
import PropTypes from 'prop-types'
import ChartBox from '../ChartBox'
import { Rect, Text as TextSVG, Svg } from "react-native-svg";

const LineChartRender = ({ width, title, labels, datasets, yAxisMin, yAxisMax, yAxisPrecision }) => {
    var finalDatasets = []
    var finalLabels = []
    var legends = []
    var datasetLength = 0
    var fromZero = false
    var decimalPlaces = 0
    var trueNumberOfDatasets = 0

    let desiredNumberOfItems = 3
    if (Platform.OS == 'web') {
        desiredNumberOfItems = 7
    } else {
        desiredNumberOfItems = 4
    }

    datasets.map((dataset) => {
        let datasetData = dataset.data

        let finalData = []

        datasetData.forEach((data, index) => {
            finalData.push(data)
        });

        finalDatasets.push({
            data: finalData,
            color: (opacity = 1) => dataset.color,
            flatColor: dataset.color
        });

        trueNumberOfDatasets++;

        legends.push(dataset.legend);
        datasetLength = dataset.length
    })

    let outputEveryOtherLabel = Math.round(labels.length / desiredNumberOfItems)
    let nextLabelIndex = 0;

    labels.forEach((label, index) => {
        if (index === 0) {
            finalLabels.push(label)

            nextLabelIndex = outputEveryOtherLabel + index
        } else if (index === nextLabelIndex) {
            finalLabels.push(label)

            nextLabelIndex = outputEveryOtherLabel + index
        } else {
            finalLabels.push('')
        }
    });

    if (yAxisMin) {
        fromZero = false

        finalDatasets.push({
            data: new Array(datasetLength).fill(yAxisMin),
            color: () => 'transparent',
            strokeWidth: 0,
            withDots: false,
        });
    }
    if (yAxisMax) {
        finalDatasets.push({
            data: new Array(datasetLength).fill(yAxisMax),
            color: () => 'transparent',
            strokeWidth: 0,
            withDots: false,
        });
    }
    if (yAxisPrecision) {
        decimalPlaces = yAxisPrecision
    }


    const chartData = {
        labels: finalLabels,
        datasets: finalDatasets,
        legend: legends
    }

    const { open } = useSideDrawer(true)
    const [isOpenDrawer, setDrawer] = useState(false)

    const window = useWindowDimensions();

    const [chartContainerWidth, setChartContainerWidth] = useState(0);
    const [chartContainerHeight, setChartContainerHeight] = useState(0);

    let [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0, visible: false, index: 0 })
    const [chartX, setChartX] = useState(0)
    const [chartY, setChartY] = useState(0)

    useEffect(() => {

    }, [window])

    useEffect(() => {
        setDrawer(open)
    }, [open, setDrawer])

    return (
        <View
            onLayout={({ nativeEvent }) => {
                setChartContainerWidth(nativeEvent.layout.width)
                setChartContainerHeight(nativeEvent.layout.height)
                setChartX(nativeEvent.layout.x)
                setChartY(nativeEvent.layout.y)
            }}
        >
            <LineChart
                data={chartData}
                width={chartContainerWidth}
                height={isMobile ? 200 : 280}
                withDots={true}
                fromZero={fromZero}
                withShadow={false}
                withVerticalLines={false}
                withHorizontalLines={false}
                bezier={false}
                style={
                    {
                        fontFamily: 'WorkSans_400Regular',
                        marginHorizontal: -20,
                    }
                }
                chartConfig={{
                    backgroundGradientFrom: '#fff',
                    backgroundGradientTo: '#fff',
                    color: (opacity = 0.1) => `rgba(54, 54, 54, ${opacity})`,
                    decimalPlaces: decimalPlaces,
                    propsForBackgroundLines: {
                        stroke: 0, // solid background lines with no dashes
                    },
                    propsForLabels: {
                        fontFamily: 'WorkSans_400Regular',
                    },
                }}
                onDataPointClick={(data) => {
                    let isSamePoint = (tooltipPos.x === data.x && tooltipPos.y === data.y)

                    isSamePoint ?
                        setTooltipPos((previousState) => {
                            return {
                                ...previousState,
                                //value: data.value,
                                index: data.index,
                                visible: !previousState.visible
                            }
                        })
                        :
                        setTooltipPos({
                            x: data.x,
                            //value: data.value,
                            index: data.index,
                            y: data.y,
                            visible: true
                        });
                }}
                decorator={() => {
                    var textItems = [];

                    let containerWidth = 150;
                    let containerHeightIncreasePerItem = 20

                    let containerHeight = 30 + (containerHeightIncreasePerItem * (trueNumberOfDatasets - 1))

                    let chartXStart = chartX;
                    let chartXEnd = chartX + chartContainerWidth;
                    let chartYStart = chartY;
                    let chartYEnd = chartY + chartContainerHeight;

                    let containerX = tooltipPos.x
                    if ((tooltipPos.x + containerWidth) > chartXEnd) {
                        containerX = containerX - containerWidth
                    }
                    let containerY = tooltipPos.y - (containerHeight + 15)
                    if ((tooltipPos.y - containerHeight) < chartYStart) {
                        containerY = containerY + (containerHeight + 25)
                    }

                    let currentTextItemX = containerX + 10
                    let currentTextItemY = containerY + 20

                    //console.log('Chart X Start:' + chartXStart)
                    //console.log('Chart X End:' + chartXEnd)
                    //console.log('Chart Y Start:' + chartYStart)
                    //console.log('Chart Y End:' + chartYEnd)

                    finalDatasets.forEach((dataset, index) => {
                        let withDots = dataset.withDots;

                        if (withDots != undefined && withDots == false) {
                            //
                        } else {
                            let value = dataset.data[tooltipPos.index];

                            if (value || value == 0) {
                                let legend = legends[index]
                                let color = dataset.flatColor;

                                textItems.push((
                                    <TextSVG
                                        x={currentTextItemX}
                                        y={currentTextItemY}
                                        fill={color}
                                        fontSize="12"
                                    //fontWeight="bold"
                                    //textAnchor="left"
                                    >
                                        {'\u2022'} {legend}: {value}
                                    </TextSVG>
                                ));

                                currentTextItemY = currentTextItemY + 20
                            }
                        }
                    });

                    return tooltipPos.visible ?
                        <React.Fragment>
                            <Svg>
                                <Rect
                                    x={containerX}
                                    y={containerY}
                                    width={containerWidth}
                                    height={containerHeight}
                                    fill="white"
                                    stroke={'rgba(141, 141, 141, 1)'}
                                    strokeWidth="1"
                                    rx={5}
                                />

                                {textItems}
                            </Svg>
                        </React.Fragment> : null
                }}
            />
        </View>
    )
}

LineChartRender.propTypes = {
    title: PropTypes.string,
    labels: PropTypes.array,
    datasets: PropTypes.array,
    yAxisMin: PropTypes.number,
    yAxisMax: PropTypes.number,
}

export default LineChartRender
