import React, { useState, useEffect, useRef, useReducer, useContext } from "react";
import { H3 } from "../Room/styles";
import { ParticipantView, Avatar } from "./styles";
import { useSelector } from 'react-redux'
import { getMember } from '@/redux/selectors/auth'
import { formatUserName } from "@/utils/format";
import { MaterialIcons } from "@expo/vector-icons";
import { Dimensions, Text, View } from "react-native";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import ApiHandler from "@/api/ApiHandler";
import VideoContext from '../context'
import { recordingEndEvent, recordingStartEvent } from "@/constants/recordingEvent";
import { UIButton } from "../VideoChat/styles";
const { width } = Dimensions.get('window');

const tile_size = width <= 1200 ? width / 2.5 : width / 3.5;

const Participant = ({ isScreenshare, setIsRecording, userIsPresenter, setUnreadMessages, isActiveSpeaker, selectedAudioOutputDeviceId, size = tile_size, participant, setFloatingMessage, setMessages, messages, }) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);
    const [micEnabled, setMicEnabled] = useState(false);
    const [videoEnabled, setVideoEnabled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const user = useSelector(getMember);
    const accentColor = UseBrandingAccentColor();
    const { addParticipant, getParticipant, setPresentationId, presentationId } = useContext(VideoContext);
    const [userInfo, setUserInfo] = useState(getParticipant(participant.identity));
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    const videoRef = useRef();
    const audioRef = useRef();

    const getUserInfo = async () => {
        const { identity } = participant;

        let _user = getParticipant(identity);

        if (!_user) {
            const res = await new ApiHandler().getParticipant({ id: identity })
            const result = res?.data?.data ?? {};
            setUserInfo(result);
        } else {
            setUserInfo(_user);
        }


    }


    const eventHandler = (e) => {
        // forcing the UI to re-render
        forceUpdate();
    }

    useEffect(() => {
        if (videoEnabled) {
            if (!isScreenshare) {
                videoRef.current.style.transform = 'scale(-1, 1)';
            }

            videoRef.current.addEventListener("loadeddata", eventHandler);

            return () => {
                videoRef.current?.removeEventListener("loadeddata", eventHandler);
            };
        }
    }, [videoEnabled])

    useEffect(() => {
        getUserInfo();
    }, [])

    useEffect(() => {
        participant.tracks.forEach(publication => {
            if (publication.trackName !== 'screen') {

                // check if the track is from mobile or desktop
                // if width > height --> desktop
                // if height > width --> mobile
                if (publication.kind == 'video') {

                    if (publication?.track?.isEnabled) {
                        setVideoEnabled(prev => true);
                    }

                    const dimensions = publication?.track?.dimensions;

                    const width = dimensions?.width;
                    const height = dimensions?.height;

                    // if the video track is enabled but not receiving dimensions then it means
                    // the track is being received from the mobile webrtc library
                    if (publication?.track?.isEnabled && (width == null || height == null)) {
                        setIsMobile(true)
                    } else if (height > width) {
                        setIsMobile(true)
                    } else if (isMobile) {
                        setIsMobile(false)
                    }
                } else if (publication.kind == 'audio') {
                    if (publication?.track?.isEnabled) {
                        setMicEnabled(prev => true)
                    }
                }
            } else {
                if (publication.track && publication.track?.name == 'screen') {
                    setPresentationId(participant.identity)
                }
            }
        })
    })

    const trackpubsToTracks = (trackMap) =>
        Array.from(trackMap.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);

    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));

        participant.tracks.forEach(track => {
            if (track.kind == 'video' && !track.isTrackEnabled) {
                setVideoEnabled(false)
            }

            if (track.kind == "audio" && !track.isTrackEnabled) {
                setMicEnabled(false);
            }

            track.on("trackDisabled", () => {
                if (track.kind == 'video') {
                    if (track.name !== 'screen')
                        setVideoEnabled(false);
                }
                if (track.kind == 'audio') {
                    setMicEnabled(false);
                }
            })

            track.on("trackEnabled", () => {
                if (track.kind == 'video') {
                    setVideoEnabled(true);
                }
                if (track.kind == 'audio') {
                    setMicEnabled(true);
                }
            })
        })


        const trackSubscribed = (track) => {
            if (track.kind === "video") {
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => [...audioTracks, track]);
            } else if (track.kind == 'data') {
                track.on('message', data => {
                    if (data == recordingStartEvent) {
                        setIsRecording(true);
                    } else if (data == recordingEndEvent) {
                        setIsRecording(false);
                    } else {
                        const message = {
                            sender: participant.identity,
                            message: data
                        };

                        setMessages(prev => {
                            setUnreadMessages(true);
                            setFloatingMessage(message);
                            return new Map([...prev, [Date.now(), message]])
                        });
                    }
                })
            }
        };


        const trackPublished = (publication) => {
            const { track } = publication;
            if (track?.kind == 'video') {
                track.attach(videoRef.current);
            } else if (track?.kind == 'audio') {
                track.attach(audioRef.current);
            }
        }

        const trackUnsubscribed = (track) => {
            if (track.name == "screen") {
                setPresentationId(null);
            }

            if (track.kind === "video") {
                setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
            } else if (track.kind === "audio") {
                setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
            }
        };

        participant.on("trackSubscribed", trackSubscribed);
        participant.on("trackUnsubscribed", trackUnsubscribed);
        participant.on("trackPublished", trackPublished)

        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            // participant.removeAllListeners();
        };
    }, [participant]);

    useEffect(() => {
        const videoTrack = videoTracks[userIsPresenter ? 0 : videoTracks.length - 1];
        if (videoTrack) {
            videoTrack.attach(videoRef.current);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTracks, videoEnabled]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            if (selectedAudioOutputDeviceId) {
                if (!!navigator.userAgentData && navigator.userAgentData.brands.some(data => data.brand == 'Chromium')) {
                    audioTrack.attach(audioRef.current).setSinkId(selectedAudioOutputDeviceId).then(() => {
                        console.log("Playing Audio with " + selectedAudioOutputDeviceId);
                    })
                } else {
                    audioTrack.attach(audioRef.current);
                }
            } else {
                audioTrack.attach(audioRef.current)
            }
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks]);

    return (
        <ParticipantView accentColor={accentColor} size={presentationId == participant.identity && !userIsPresenter ? "100%" : size}>
            {isActiveSpeaker && (
                <View style={{ position: 'absolute', top: "0", right: 0, flexDirection: 'row', width: '100%', zIndex: 9, justifyContent: 'flex-end', paddingHorizontal: 15, paddingVertical: 10 }}>
                    <UIButton style={{ width: '30px', height: '30px', marginHorizontal: 0 }} active={true} bg={'#3c4043'}>
                        <MaterialIcons name={"multitrack-audio"} size={18} color="#FFFFFF" />
                    </UIButton>
                </View>
            )}

            {(presentationId == participant.identity && !userIsPresenter) || videoEnabled ? (
                <View style={{ width: '100%', overflow: 'hidden', borderRadius: 8, height: '100%' }}>
                    <video style={{
                        objectFit: 'contain',
                        aspectRatio: 9 / 16,
                        height: '100%',
                        width: '100%',
                        display: "block",
                        margin: "0 auto",
                    }}
                        ref={videoRef} autoPlay={true} />
                </View>
            ) : (
                <View style={{ width: size, aspectRatio: (16 / 10), alignItems: 'center', justifyContent: 'center' }}>
                    {userInfo?.avatar_url ? (
                        <Avatar
                            style={{ backgroundColor: accentColor }}
                            source={
                                { uri: userInfo?.avatar_url }
                            }
                        />
                    ) : (
                        <View style={{ width: 60, height: 60, backgroundColor: 'rgba(255,255,255,0.1)', borderRadius: 50, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontSize: 40, color: 'white' }}>{userInfo?.avatar_initials}</Text>
                        </View>
                    )}
                </View>
            )}
            <audio ref={audioRef} autoPlay={true} muted={false} />
            <View style={{ position: 'absolute', bottom: "0", left: 0, right: 0, flexDirection: 'row', width: '100%', alignSelf: 'center', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 15, paddingVertical: 10 }}>
                <Text style={{
                    color: '#FFFFFF',
                    fontFamily: 'WorkSans_400Regular',
                    fontSize: 15,
                    fontWeight: 600
                }}>
                    {formatUserName(userInfo?.first_name, userInfo?.last_name)} {presentationId == participant?.identity && !userIsPresenter ? "(Presentation)" : formatUserName(userInfo?.first_name, userInfo?.last_name) == formatUserName(user.first_name, user.last_name) && "(You)"}
                </Text>
                {(presentationId !== participant?.identity || userIsPresenter) && (
                    <>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {!micEnabled && (
                                <UIButton style={{ width: '30px', height: '30px', marginHorizontal: 0 }} active={true} bg={'#3c4043'}>
                                    <MaterialIcons name={"mic-off"} size={18} color="#FFFFFF" />
                                </UIButton>
                            )}

                            {/*
                            <MaterialIcons name={videoEnabled ? "videocam" : "videocam-off"} size={size == width / 8 ? 18 : 24} color="white" />
                            */}
                        </View>
                    </>
                )}
            </View>
        </ParticipantView>
    );
};

export default Participant;