import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
import { Headline4, Headline6SemiBold } from '@/styles/fonts'
import { isMobile } from '@/styles'
const { height, width } = Dimensions.get('window')

export const LeaveCard = styled.View`
	position: relative;
	width: ${isMobile ? width : 421}px;
	height: ${isMobile ? height : 184}px;
	justify-content: flex-start;
	align-items: center;
	background-color: ${({ theme }) => theme.basics.background_white};
	border-radius: 5px;
	marginTop:20px

	
`

export const CloseLeaveModalButton = styled.TouchableOpacity`
	align-self: flex-end;
	margin: 13px 13px 0 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`

export const LeaveText = styled(Headline4)`
	color: ${({ theme }) => theme.basics.brand};
	text-align: center;
	padding: 0 50px 20px;
`

export const ButtonsContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const LeaveButton = styled.TouchableOpacity`
	background-color: ${({ theme, cancel }) => cancel ? theme.basics.background_white : theme.basics.brand};
	align-items: center;
	justify-content: center;
	width: ${({ width }) => width || 175}px;
	height: 40px;
	margin: 0 8px;
	border: 1px solid ${({ theme }) => theme.basics.brand};
	border-radius: 3px;
`

export const LeaveButtonText = styled(Headline6SemiBold)`
	width: 100%;
	text-align: center;
	color: ${({ theme, cancel }) => cancel ? theme.basics.brand : theme.basics.background_white};
`
