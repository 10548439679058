import React, { useEffect, useCallback, useState } from 'react'
import { FlatList, RefreshControl, View } from 'react-native'
import { StatusBar } from 'expo-status-bar'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/posts'
import * as profileSelectors from '@/redux/selectors/profile'
import * as pushTokenSelector from '@/redux/selectors/pushNotifications'
import { actions } from '@/redux/reducers/posts'
import { actions as articleActions } from '@/redux/reducers/article'
import { FeedPostCard, CreatePost, AvatarWriteButton } from '@/components'
import PostComposer from '@/components/Feed/Card/Content/Post/Components/PostComposer'
import { ProgressBar } from 'react-native-paper'
import ReframeHorizontalLogo from '@/assets/brand/reframe-brand.png'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles/screenSize'
import * as Permissions from 'expo-permissions'
import { Linking } from 'expo'
import * as Notifications from 'expo-notifications'
import Constants from 'expo-constants'
import Toast from 'react-native-toast-message'
import { useNavigation } from '@react-navigation/native'

import { Container, ReframeLogo, ReframeHeader, styles } from './styles'
import { actions as tokenActions } from '@/redux/reducers/pushNotifications'
import { getMember } from '@/redux/selectors/auth'
import * as brandSelectors from '@/redux/selectors/branding'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import FeedCard from '@/components/Feed/Card'
const Home = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [isCreatePostVisible, setCreatePostVisible] = useState(false)
  const [pushToken, setPushToken] = useState('')
  const loading = useSelector(selectors.loading)
  const posts = useSelector(selectors.posts)
  const can_load_more = useSelector(selectors.can_load_more)
  const next_page = useSelector(selectors.next_page)

  const branding = useSelector(brandSelectors.getSettings)

  const member = useSelector(getMember)
  const tokenInRedux = useSelector(pushTokenSelector.getPushToken)
  const [refreshing, setRefreshing] = useState(false)
  useEffect(() => {
    dispatch(actions.feeds_index({ page: 1 }, () => { }))
    dispatch(articleActions.readLater({ page: 1 }, () => { }))
  }, [])

  const handleCreatePost = useCallback(() => {
    setCreatePostVisible(true)
  }, [setCreatePostVisible])

  const handleCloseAction = useCallback(() => {
    dispatch(
      actions.feeds_index({ page: 1 }, (err) => {
        console.log('err', err)
      })
    )
    setCreatePostVisible(false)
  }, [setCreatePostVisible])
  // console.log("%%%%%%%%%%TOKEN IN REDUx", tokenInRedux);
  // get user permission for push notifications

  const handleDeleteComment = () => {
    dispatch(
      actions.feeds_index({ page: 1 }, (err) => {
        console.log('err', err)
      })
    )
  }

  const handleReleateLaterArticle = () => {
    dispatch(
      actions.feeds_index({ page: 1 }, (err) => {
        console.log('err', err)
      })
    )
  }

  const registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(
        Permissions.NOTIFICATIONS
      )
      let finalStatus = existingStatus
      //console.log("*******FINAL Status", finalStatus)
      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(
          Permissions.NOTIFICATIONS
        )
        finalStatus = status
      }
      if (finalStatus !== 'granted') {
        //alert('Failed to get push token for push notification!');
        return
      }
      let token = await Notifications.getExpoPushTokenAsync()

      uploadToken(token)
      setPushToken(token)
    }
    // else {
    //   Alert.alert('Must use physical device for Push Notifications');
    // }
  }

  // posting push token to backend
  const uploadToken = useCallback((token) => {
    dispatch(
      tokenActions.storeToken(token, (error) => {
        if (error) {
          Toast.show({
            type: 'error',
            position: 'top',
            text1: 'Error With Token, please try again.',
          })
        }
      })
    )
  }, [])
  // const uploadToken = useCallback((token, callback) => {
  //   return tokenActions.storeToken({ token }, (err, success) => {
  //     callback(err, success)
  //   })
  // }, [])

  useEffect(() => {
    {
      isMobile && registerForPushNotificationsAsync()
    }
  }, [])

  const renderItem = useCallback(({ item }) => {
    return (
      <FeedCard
        key={'posts' + item.id}
        post={item}
      />
    )
  }, [])

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    dispatch(
      actions.feeds_index({ page: 1 }, () => {
        setRefreshing(false)
      })
    )
  }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setRefreshing(true)

      dispatch(actions.feeds_index({ page: 1 }, () => {
        setRefreshing(false)
      }))
      dispatch(articleActions.readLater({ page: 1 }, () => { }))
    })
    return unsubscribe
  }, [navigation])

  const onEndReached = () => {
    if (can_load_more) {
      dispatch(actions.feeds_index({ page: next_page }, () => { }))
    }
  }

  const { color_scheme_page_topbar } = branding

  return (
    <>
      <PageHeader />

      <FlatList
        data={posts}
        keyExtractor={(item, index) => 'post' + index}
        style={styles.flatList}
        contentContainerStyle={styles.flatListContent}
        renderItem={renderItem}
        listKey="posts"
        ListHeaderComponent={
          <AvatarWriteButton
            avatarSize={50}
            text="Write A Post"
            avatarSource={member?.avatar}
            onPress={handleCreatePost}
          />
        }
        onEndReached={onEndReached}
        onEndReachedThreshold={0.5}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={onRefresh}
            title=""
          />
        }
      />

      <PostComposer
        onClose={handleCloseAction}
        isVisible={isCreatePostVisible}
        setVisible={setCreatePostVisible}
      />
    </>
  )
}

export default Home
