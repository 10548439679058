import request from '@/helpers/request'

export async function getList({
  page,
  featured = 0,
  read_later = 0,
  employee_lifecycle_stage_id = null,
  five_s_category_id = null,
  search,
}) {
  return await request('education/courses', {
    method: 'GET',
    qsParams: {
      page,
      employee_lifecycle_stage_id,
      five_s_category_id,
      search,
      featured,
      read_later,
    },
  })
}

export async function createCourse(object) {
  return await request('education/courses', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function updateCourse({ id, ...course }) {
  return await request(`education/courses/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...course }),
  })
}

export async function getCourseWithId({ id,language_id=null }) {
  return await request(`education/courses/${id}`, {
    method: 'GET',
    qsParams: { id,language_id },
  })
}

export async function toggleFeatured(object) {
  return await request('course/toggleFeatured', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function toggleStatus(object) {
  return await request('course/toggleStatus', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function toggleReadLater(object) {
  return await request('course/readLater', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function deleteCourse({ id }) {
  return await request(`education/courses/${id}`, {
    method: 'DELETE',
  })
}

export async function trackActiveCourseTime({ courseId, timeTracked }) {
  return await request('learning/tracking/recordView', {
    method: 'POST',
    body: {
      content_type: "App\\Course",
      content_id: parseInt(courseId),
      seconds_viewed_for: parseInt(timeTracked),
    },
  })
}
