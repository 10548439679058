import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { constants } from '@/redux/reducers/groups'
import * as api from '@/redux/api/groups'

// STORE
function* store(action) {
  try {
    const payload = yield call(api.store, action)
    yield put({ type: constants.STORE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.STORE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchStore() {
  yield takeLatest(constants.STORE.ACTION, store)
}

// COMMENT LIST
function* comment_list(action) {
  try {
    const payload = yield call(api.comment_list, action)
    yield put({ type: constants.COMMENT_LIST.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.COMMENT_LIST.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchCommentList() {
  yield takeLatest(constants.COMMENT_LIST.ACTION, comment_list)
}

// UPDATE
function* update(action) {
  try {
    const payload = yield call(api.update, action)
    yield put({ type: constants.UPDATE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.UPDATE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchUpdate() {
  yield takeLatest(constants.UPDATE.ACTION, update)
}

// INDEX
function* index(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.INDEX.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.INDEX.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchIndex() {
  yield takeLatest(constants.INDEX.ACTION, index)
}


// ALL
function* otherTeams(action) {
  try {
    const payload = yield call(api.other, action)
    yield put({ type: constants.OTHER_GROUPS.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.OTHER_GROUPS.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchOtherTeams() {
  yield takeLatest(constants.OTHER_GROUPS.ACTION, otherTeams)
}

// SHOW
function* show(action) {
  try {
    const payload = yield call(api.show, action)
    yield put({ type: constants.SHOW.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.SHOW.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchShow() {
  yield takeLatest(constants.SHOW.ACTION, show)
}

// REMOVE
function* remove(action) {
  try {
    const payload = yield call(api.remove, action)
    yield put({ type: constants.REMOVE.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.REMOVE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchRemove() {
  yield takeLatest(constants.REMOVE.ACTION, remove)
}

// MEMBERS
function* members(action) {
  try {
    const payload = yield call(api.members, action)
    yield put({ type: constants.MEMBERS.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.MEMBERS.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchMembers() {
  yield takeLatest(constants.MEMBERS.ACTION, members)
}

// REMOVE MEMBER
function* removeMember(action) {
  try {
    const payload = yield call(api.removeMember, action)
    yield put({ type: constants.REMOVE_MEMBER.SUCCESS, payload, action })
    yield call(members, action.team_id)
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.REMOVE_MEMBER.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchRemoveMember() {
  yield takeLatest(constants.REMOVE_MEMBER.ACTION, removeMember)
}

// INVITE
function* invite(action) {
  try {
    const payload = yield call(api.invite, action)
    yield put({ type: constants.INVITE.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.INVITE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchInvite() {
  yield takeLatest(constants.INVITE.ACTION, invite)
}

// INVITE
function* posts(action) {
  try {
    let payload = yield call(api.posts, action)
    payload = {
      ...payload,
      data: _.values(payload.data)
    }
    yield put({ type: constants.POSTS.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.POSTS.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchPosts() {
  yield takeLatest(constants.POSTS.ACTION, posts)
}


// INVITE
function* join(action) {
  try {
    const payload = yield call(api.join, action)
    yield put({ type: constants.JOIN.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.JOIN.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchJoin() {
  yield takeLatest(constants.JOIN.ACTION, join)
}


// INVITE
function* leave(action) {
  try {
    const payload = yield call(api.leave, action)
    yield put({ type: constants.LEAVE.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.LEAVE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchLeave() {
  yield takeLatest(constants.LEAVE.ACTION, leave)
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchStore),
    fork(watchUpdate),
    fork(watchIndex),
    fork(watchShow),
    fork(watchRemove),
    fork(watchOtherTeams),
    fork(watchMembers),
    fork(watchRemoveMember),
    fork(watchInvite),
    fork(watchPosts),
    fork(watchJoin),
    fork(watchLeave),
    fork(watchCommentList),
  ])
}
