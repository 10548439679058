import styled from 'styled-components/native'
import {
  StyleSheet,
  Dimensions,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import themes from '@/styles/themes/black'
// import { Paragraph1, Paragraph2, Subtitle2 } from '@/styles/fonts'
import { Paragraph1, Paragraph2, Subtitle2, Headline4, Headline2 } from '@/styles/fonts'
import Button from '../../../components/Button'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'

const { height, width } = Dimensions.get('window')

export const styles = StyleSheet.create({
  scrollContent: {
    backgroundColor: theme.basics.background_white,
    flexGrow: 1,
  },
  scroll: {
    flex: 1,
    backgroundColor: theme.basics.background_white,
  },
  container: {
    flex: 1,
    width: '100%',
    minHeight: height * 0.5,
  },
  otherArticles: {
    flexDirection: 'row',
    flex: 1,
    maxWidth: '100%',
    flexWrap: 'wrap',
  },
  select: {
    marginRight: 20,
  },
  headerView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownView: {
    flexDirection: 'row',
    marginTop: 16,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  lookingFor: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  createfolderfooter: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    // paddingBottom: 10,
    flexDirection: 'row',
  },
  createFolderCancel: {
    width: 120,
    height: '35%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 3,
    marginEnd: 10,
  },
  createFolderSubmit: {
    backgroundColor: '#000',
    paddingVertical: 10,
    width: '100%',
    marginBottom: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  trigger: {
    padding: 5,
    margin: 5,
  },
  text: {
    fontSize: 18,
  },
  triggerText: {
    color: 'white',
  },
  slideInOption: {
    padding: 5,
  },

  flexContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  boxWithShadow: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.8,
    shadowRadius: 2,
    elevation: 5,
    paddingTop: 10,
    paddingBottom: 40,
    paddingLeft: 20,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  item: {
    width: "90%",
    alignSelf: 'center',
    flexDirection: "row",
    borderBottomColor: "#cfcfcf",
    borderBottomWidth: 1,
    paddingVertical: 10
  },
  itemTitle: {
    fontSize: 16,
    marginLeft: "-20%"
  },
  itemTitleContainer: {
    flex: 0.8,
    alignItems: 'center'
  }
})


export const CreateFolderContainer = styled.View`
	position: relative;
	width:  ${isMobile ? width : '280px'};
	height: ${isMobile ? height - 300 : '200px'};
	justify-content: space-between;
	padding: 0 20px 5%;
	background: ${({ theme }) => theme.basics.background_white};
  align-self: center;
`
export const HeaderCreateFolder = styled.View`
	padding:30px 0px 0px 0px;
	position: relative;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
`
export const CloseButtonCreateFolder = styled.TouchableOpacity`
	z-index: 1;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 8px;
	right: -12px;
`
export const HeaderTitleCreateFolder = styled(Headline4)`
	color: ${({ theme }) => theme.basics.black_text};
  width:100%;
  text-align:center;
`

export const HeaderTitleInfoFolder = styled(Headline4)`
	color: ${({ theme }) => theme.basics.black_text};
  width:100%;
`

export const HeaderSubTitleCreateFolder = styled(Headline2)`
	color: ${({ theme }) => theme.basics.black_text};
  width:100%;
  font-size:16px;
  padding-top:10px;
  padding-left:5px;
  text-align:center;
  line-height: 25px;
`

export const HeaderSubTitleInfo = styled(Headline2)`
	color: ${({ theme }) => theme.basics.black_text};
  width:100%;
  font-size:15px;
  padding-top:5px;
  padding-left:5px;
`


export const ContentArticleWeb = styled.View`
  margin-top: 1px solid #d3d3d3;
  padding: 20px 0 0;
  height: 100%;
  background-color: ${themes.basics.background_white};
`

export const Container = styled.View`
  background-color: ${themes.basics.background_white};
  padding-left: ${({ isMobile }) => (isMobile ? 15 : 45)}px;
  margin: 0 0 0px;
  flex: 1;
`

export const WebMenuContainer = styled.View`
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 10px 16px;
		position: relative;
		width: 100%;
    background-color: ${themes.basics.background_white};
    margin-bottom:12;
    padding-top:10;
    padding-left:20;
`

export const CentralizedContainer = styled.View`
  margin-left: auto;
  margin-right: auto;
  background-color: ${themes.basics.background_white};
  width: 100%;
  padding: 0 ${({ isMobile }) => (isMobile ? 15 : 0)}px;
  max-width: 700px;
`
export const Actions = styled.View`
  flex: 1;
  background-color: ${themes.basics.background_button};
  width: ${({ isMobile }) => (isMobile ? '100%' : '200px')};
  max-width: 700px;
  margin-top: 15px;
  padding-bottom: 20px;
  align-self: flex-end;
`

export const ScrollView = styled.ScrollView``

export const HeadLine = styled.View`
  flex-direction: row;
  padding-right: ${({ isMobile }) => (isMobile ? 15 : 56)}px;
  align-items: center;
`
export const Publish = styled.Text`
  font-size: 14px;
  margin-top: 20px;
  text-align: ${({ isMobile }) => (isMobile ? 'center' : 'left')};
  color: ${themes.basics.special_gray};
`

export const TitleWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`

export const Header = styled.View`
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 44px;
  margin-bottom: ${({ isMobile }) => (isMobile ? 25 : 0)}px;
  padding-right: ${({ isMobile }) => (isMobile ? 15 : 44)}px;
  z-index: 1;
`

export const SubTitle = styled.Text`
  font-family: 'WorkSans_500Medium';
  font-size: ${({ isMobile }) => (isMobile ? 18 : 24)}px;
  line-height: ${({ isMobile }) => (isMobile ? 20 : 27)}px;
  color: ${themes.basics.black_text};
  margin-right: 10px;
`

export const Title = styled.Text`
  margin: 0 0 0 0;
  font-family: 'WorkSans_400Regular';
  font-size: ${({ isMobile }) => (isMobile ? 22 : 40)}px;
  line-height: ${({ isMobile }) => (isMobile ? 25 : 40)}px;
  margin-left: ${({ isMobile }) => (isMobile ? 0 : 16)}px;
  color: ${themes.basics.black_text};
`

export const Status = styled.View``

export const PublishStatus = styled.Text`
  background: #1b1b1bcf;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 11px;
  color: #fff;
  margin: 0 0 6px 0;
  font-weight: bold;
  text-align: center;
`

export const FeatureStatus = styled.Text`
  background: #1b1b1bcf;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 11px;
  color: #fff;
  margin: 0 0 6px 0;
  font-weight: bold;
  text-align: center;
`

export const LinkButton = styled.TouchableOpacity`
  padding: 0 ${({ isMobile }) => (isMobile ? 16 : 0)}px 0 0;
  align-items: center;
  justify-content: center;
`

export const LinkText = styled(Subtitle2)`
  color: ${themes.danger.standard};
`

export const SearchButton = styled(TouchableOpacity)`
  height: 24px;
  width: 24px;
`

export const WrapperCollapse = styled.View`
  align-self: flex-end;
  margin: 0 44px 0 0;
`

export const Select = styled.View`
  margin-right: 15px;
`

export const SubmitButton = styled(Button)`
  margin: 15px 0;
`

export const CreateButton = styled(Button)``

export const TextButton = styled.Text`
  color: ${({ theme, color }) =>
    color ? color : theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`

export const TopImage = styled.Image`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  aspect-ratio: 2;
  border-radius: 5px;
  margin-top: ${isMobile ? '0px' : '20px'};
`

export const TopButtons = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
`

export const ContentText = styled(Paragraph2)`
  width: 100%;
  margin: 20px 0 12px;
  color: ${({ theme }) => theme.basics[600]};
`

export const Category = styled.Text`
  flex: 1;
  border: 1px solid ${({ theme }) => theme.basics[300]};
  border-radius: 5px;
  padding: 3px;
  text-align: center;
  margin-right: 10px;
`

export const AuthorInfoContainer = styled.View`
  padding: 16px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
`

export const AvatarContainer = styled.View`
  align-items: center;
  justify-content: center;
  margin: 16px;
  position: relative;
`

export const AuthorName = styled(Paragraph1)``

export const Footer = styled.View`
  width: 100%;
  flex-direction: row;
  align-content: center;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.basics[300]};
`

export const SubFooterInfo = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const SubInfoText = styled(Paragraph2)`
  color: ${({ theme }) => theme.basics[400]};
`

export const CompanyImage = styled.Image`
  width: 22px;
  height: 22px;
  border-radius: 22px;
  position: absolute;
  right: ${({ isMobile }) => (isMobile ? -5 : 5)}px;
  bottom: ${({ isMobile }) => (isMobile ? -5 : 5)}px;
`

export const TopButton = styled.TouchableOpacity`
  display: flex;
  margin-left: 5px;
  height: 40px;
  width: 40px;
  padding: 10px;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ theme }) => theme.basics.black_text};
`
export const Bakcground = styled.View`
  background: ${({ theme }) => theme.basics.background_white};
`

export const MoreText = styled(Text)`
  color: #f05343;
  font-size: 13px;
  font-weight: 500;
`
export const ContentView = styled.View`
  margin-top: ${({ isMobile }) => (isMobile ? 0 : 45)}px;
  margin-bottom: ${({ isMobile }) => (isMobile ? 0 : 45)}px;
  margin-right: 15px;
`

export const ClearButton = styled(TouchableOpacity)`
  background-color: black;
  border-radius: 2px;
  padding: 5px 10px;
`

export const Tabs = styled.View`
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;
`

export const Tab = styled.TouchableOpacity`
	margin-right: 10px;
	
	
	

`

export const TabTitle = styled.Text`
	font-size: 18px;
	padding-left: ${({ isMobile }) => isMobile ? 15 : 0}px;
	color: ${({ selected }) => selected ? '#000' : '#959595'};
	padding-bottom: 5px !important;
	
`