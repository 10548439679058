export default {
  title: 'black',
  spacing: 8,
  space: [0, 4, 8, 16, 20, 20, 20, 20, 20, 20, 20, 20],
  success: {
    50: '#EDF7ED',
    100: '#C1F0CD',
    200: '#97E6AD',
    300: '#64DC8B',
    400: '#64DC8B',
    standard: '#00CA53',
    600: '#00BA49',
    700: '#00A73C',
    800: '#009530',
    900: '#00751B',
    alpha_8: 'rgba(0, 202, 83, 0.08)',
    alpha_16: 'rgba(0, 202, 83, 0.16)',
    alpha_24: 'rgba(0, 202, 83, 0.24);',
    alpha_32: 'rgba(0, 202, 83, 0.32)',
    alpha_40: 'rgba(0, 202, 83, 0.4)',
    alpha_48: 'rgba(0, 202, 83, 0.48)',
  },
  info: {
    100: '#BBDDFF',
    200: '#91C7FD',
    300: '#65B1FB',
    400: '#46A1FB',
    standard: '#2D91F9',
    600: '#2E83EA',
    700: '#2B71D6',
    800: '#2960C4',
    900: '#2960C4',
    alpha_8: 'rgba(45, 145, 249, 0.08)',
    alpha_16: 'rgba(45, 145, 249, 0.16)',
    alpha_24: 'rgba(45, 145, 249, 0.24)',
    alpha_32: 'rgba(45, 145, 249, 0.32)',
    alpha_40: 'rgba(45, 145, 249, 0.4)',
    alpha_48: 'rgba(45, 145, 249, 0.48)',
  },
  warning: {
    100: '#FFF1C9',
    200: '#FFDD7B',
    300: '#FFD143',
    400: '#FFC409',
    standard: '#FFBC00',
    600: '#FFAE00',
    700: '#FF9A00',
    800: '#FF8800',
    900: '#FF6600',
    alpha_8: 'rgba(255, 188, 0, 0.08)',
    alpha_16: 'rgba(255, 188, 0, 0.16)',
    alpha_24: 'rgba(255, 188, 0, 0.24)',
    alpha_32: 'rgba(255, 188, 0, 0.32)',
    alpha_40: 'rgba(255, 188, 0, 0.40)',
    alpha_48: 'rgba(255, 188, 0, 0.48)',
  },
  danger: {
    50: '#FEF7F6',
    100: '#FCD0D4',
    200: '#EBA09F',
    300: '#E17C7B',
    400: '#EB605A',
    standard: '#F05343',
    600: '#E14B41',
    700: '#CF423B',
    800: '#C13C34',
    900: '#B1342A',
    alpha_8: 'rgba(240, 83, 67, 0.08)',
    alpha_16: 'rgba(240, 83, 67, 0.16)',
    alpha_24: 'rgba(240, 83, 67, 0.24)',
    alpha_32: 'rgba(240, 83, 67, 0.32)',
    alpha_40: 'rgba(240, 83, 67, 0.40)',
    alpha_48: 'rgba(240, 83, 67, 0.48)',
  },
  primary: {
    50: '#FEF7F6',
    100: '#FCD0D4',
    200: '#EBA09F',
    300: '#E17C7B',
    400: '#EB605A',
    standard: '#F05343',
    600: '#E14B41',
    700: '#CF423B',
    800: '#C13C34',
    900: '#B1342A',
    alpha_8: 'rgba(240, 83, 67, 0.08)',
    alpha_16: 'rgba(240, 83, 67, 0.16)',
    alpha_24: 'rgba(240, 83, 67, 0.24)',
    alpha_32: 'rgba(240, 83, 67, 0.32)',
    alpha_40: 'rgba(240, 83, 67, 0.40)',
    alpha_48: 'rgba(240, 83, 67, 0.48)',
  },
  basics: {
    black_text: '#000000',
    brand: '#1B1B1B',
    special_gray: '#545B62',
    background_white: '#FFFFFF',
    background_button: '#F2F2F2',
    200: '#E9E9E9',
    300: '#D9D9D9',
    400: '#B5B5B5',
    500: '#959595',
    600: '#6D6D6D',
    700: '#5A5A5A',
    800: '#3B3B3B',
    900: '#323232',
  },
  support: {
    standard_low: '#A0C3F8',
    standard_medium: '#2D91F9',
    standard_high: '#2D91F9',
    optional_1: '#FFBC00',
    optional_2: '#8B77E8',
  },
}
