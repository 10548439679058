import React, { useEffect, useState } from 'react'
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    ScrollView,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    ActivityIndicator,
    Platform
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import ApiHandler from '@/api/ApiHandler'

import * as brandSelectors from '@/redux/selectors/branding'

import { Icon, Carousel, ArticleCard, BackArrow } from '@/components'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import ButtonPrimary from '@/components/ButtonPrimary'

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

import {
    TYPE_CULTURE_MATURITY,
    TYPE_CUSTOM,
    TYPE_PREBUILT,

    isActiveAssessment,
    isNotActiveAssessment
} from "./consts";

import { ScreenSize, useScreenSize } from '@/styles'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ContentBlockV1 from '@/components/ContentBlockV1'
import AssessmentCard from '@/pages/Assessments/Components/AssessmentCard'

import { getSubscriptionMeta } from '@/redux/selectors/subscription'

const { width } = Dimensions.get('window')

export default function Assessments() {

    const screenSize = useScreenSize()
    const navigation = useNavigation()
    const isFocused = useIsFocused();

    const branding = useSelector(brandSelectors.getSettings);

    const [customLoader, setCustomLoader] = useState(false);
    const [prebuiltLoader, setPrebuiltLoader] = useState(false);
    const [culturalMaturityLoader, setCulturalMaturityLoader] = useState(false);

    const [activeCustomAssessments, setActiveCustomAssessments] = useState(true);
    const [activePrebuiltAssessments, setActivePrebuiltAssessments] = useState(true);
    const [activeCulturalMaturityAssessments, setActiveCulturalMaturityAssessments] = useState(true);

    const [customAssessments, setCustomAssessments] = useState([]);
    const [preBuiltAssessments, setPreBuiltAssessments] = useState([]);
    const [culturalMaturity, setCulturalMaturityAssessments] = useState([]);

    const subscriptionMeta = useSelector(getSubscriptionMeta)

    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text
    } = branding;

    useEffect(() => {
        if (isFocused) {
            fetchCustomsAssessments();
            fetchPreBuiltAssessments();
            fetchCulturalMaturityAssessments();
        }
    }, [isFocused])

    const fetchCustomsAssessments = async () => {

        setCustomLoader(true)

        try {
            const response = await new ApiHandler().getCustomAssessments();
            setCustomAssessments(response?.data?.data || []);

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setCustomLoader(false)
        }

    }

    const fetchPreBuiltAssessments = async () => {

        setPrebuiltLoader(true)
        try {
            const response = await new ApiHandler().getPreBuiltAssessments();
            setPreBuiltAssessments(response?.data?.data || []);

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setPrebuiltLoader(false)
        }

    }

    const fetchCulturalMaturityAssessments = async () => {
        if (subscriptionMeta?.trial?.active === false) {
            setCulturalMaturityLoader(true)
            try {
                const response = await new ApiHandler().getCulturalMaturityAssessments();
                setCulturalMaturityAssessments(response?.data?.data || []);

            } catch (error) {
                console.log(">>>> createCustom assessment error", error)
            } finally {
                setCulturalMaturityLoader(false)
            }
        }

    }

    const createAssessment = () => {
        navigation.navigate("assessment",
            // { id: 23, type: "custom" }
        )
    }

    const ActionButtons = ({ active, setActive }) => {
        return (
            <View style={styles.customAssessmentBtns} >

                <TouchableOpacity
                    onPress={() => { setActive(false) }}
                    style={[
                        styles.btnContainer,
                        !active && { backgroundColor: "#F05343", }
                    ]}
                >
                    <Text
                        style={[
                            styles.btnText,
                            active && { color: "#000", }
                        ]}
                    >
                        {"Deactivated".toUpperCase()}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    onPress={() => { setActive(true) }}
                    style={[
                        styles.btnContainer, { marginLeft: 8 },
                        active && { backgroundColor: "#F05343", }
                    ]}
                >
                    <Text
                        style={[
                            styles.btnText,
                            !active && { color: "#000", }
                        ]}
                    >
                        {"Active".toUpperCase()}
                    </Text>
                </TouchableOpacity>

            </View>
        )
    }

    const RenderPreBuiltAssessments = () => {

        let assessments = []
        if (activePrebuiltAssessments) {
            assessments = preBuiltAssessments.filter((item) => {
                return isActiveAssessment(item)
            });
        } else {
            assessments = preBuiltAssessments.filter((item) => {
                return isNotActiveAssessment(item)
            });
        }

        return (
            <FlatList
                numColumns={isMobile ? 1 : 3}
                data={assessments}
                keyExtractor={item => item.id}
                renderItem={({ item, index }) => {
                    return _renderAssessment(item, index, TYPE_PREBUILT)
                }}
                contentContainerStyle={styles.contentContainer}
                ListHeaderComponent={() => {
                    return (
                        preBuiltAssessments.length > 0
                        &&
                        <>
                            <ActionButtons active={activePrebuiltAssessments} setActive={setActivePrebuiltAssessments} />
                        </>
                    )
                }}
            />
        )
    }

    const RenderCustomAssessments = () => {

        let assessments = []
        if (activeCustomAssessments) {
            assessments = customAssessments.filter((item) => {
                return isActiveAssessment(item)
            });
        } else {
            assessments = customAssessments.filter((item) => {
                return isNotActiveAssessment(item)
            });
        }

        return (

            <FlatList
                numColumns={isMobile ? 1 : 3}
                data={assessments}
                keyExtractor={item => item.id}
                renderItem={({ item, index }) => {
                    return _renderAssessment(item, index, TYPE_CUSTOM)
                }}
                contentContainerStyle={styles.contentContainer}
                ListHeaderComponent={() => {
                    return (
                        customAssessments.length > 0 &&
                        <>
                            <ActionButtons active={activeCustomAssessments} setActive={setActiveCustomAssessments} />
                        </>
                    )
                }}
            />
        )
    }

    const RenderCulturalMaturityAssessments = () => {

        let assessments = []
        if (activeCulturalMaturityAssessments) {
            assessments = culturalMaturity.filter((item) => {
                return isActiveAssessment(item)
            });
        } else {
            assessments = culturalMaturity.filter((item) => {
                return isNotActiveAssessment(item)
            });
        }

        return (

            <FlatList
                numColumns={isMobile ? 1 : 3}
                data={assessments}
                keyExtractor={item => item.id}
                renderItem={({ item, index }) => {
                    return _renderAssessment(item, index, TYPE_CULTURE_MATURITY)
                }}
                contentContainerStyle={styles.contentContainer}
                ListHeaderComponent={() => {

                    return (
                        culturalMaturity.length > 0 &&
                        <>
                            <ActionButtons
                                active={activeCulturalMaturityAssessments}
                                setActive={setActiveCulturalMaturityAssessments}
                            />
                        </>


                    )
                }}
            />
        )
    }
    const WIDTH = (666) / 3
    const _renderAssessment = (item, index, type) => {

        const count = index + 1;

        const marginsHorizontal = isMobile ? false : count % 3 === 2;
        return (
            <AssessmentCard
                index={index}
                item={item}
                type={type}
            />
        )
    }

    return (
        <>
            <PageHeader
                title={'Assessments'}
                icon={'assessments'}
                backEnabled={false}
                actions={
                    [
                        {
                            title: 'Create',
                            type: 'menu',
                            permissions: [

                            ],
                            menu: [
                                {
                                    title: `Create Custom Assessment`,
                                    icon: 'assessments',
                                    permissions: [

                                    ],
                                    onPress: () => {
                                        createAssessment()
                                    }
                                },
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <ColumnsContainer>
                    {subscriptionMeta?.trial?.active === false && (
                        <Column
                            flexBasis={'50%'}
                        >
                            <ContentBlockV1
                                title={'Cultural Maturity Assessment(s)'}
                                subtitle={"Your Cultural Maturity Assessment(s), once activated, allows you to monitor your community's level of cultural maturity."}
                            >
                                <RenderCulturalMaturityAssessments />
                            </ContentBlockV1>
                        </Column>
                    )}

                    <Column
                        flexBasis={'50%'}
                    >
                        <ContentBlockV1
                            title={'Prebuilt Assessments'}
                            subtitle={"Use Prebuilt Assessments out-of-box to track key sentiments among your community members."}
                        >
                            <RenderPreBuiltAssessments />
                        </ContentBlockV1>
                    </Column>

                    <Column
                        flexBasis={'50%'}
                    >
                        <ContentBlockV1
                            title={'Custom Assessments'}
                            subtitle={"Create your own custom surveys and receive responses from your community members."}
                        >
                            <RenderCustomAssessments />
                        </ContentBlockV1>
                    </Column>
                </ColumnsContainer>
            </PageContent>
        </>
    )
}


export const styles = StyleSheet.create({

    spacer: {
        paddingHorizontal: 45,
        paddingVertical: 40,
    },
    spacerMobile: {
        paddingHorizontal: 15,
        paddingVertical: 20,
    },
    container: {
        flex: 1,
        maxWidth: 700
    },
    mobContainer: {
        flex: 1,
    },

    screenTitle: {
        marginLeft: 10,
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics.black_text,
        fontSize: 40,
        lineHeight: 40,
    },

    mobScreenTitle: {
        fontSize: 22,
        lineHeight: 25,
    },
    assessmentsHeader: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 18,
        lineHeight: 20,
    },

    contentContainer: {
    },

    assessmentContainer: {
        // flex: 1,

        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        borderTopColor: "#F05343",
        borderTopWidth: 3,

        marginBottom: 16,
        padding: 16,
        width: Platform.OS !== 'web' ? width - 32 : '100%',

        shadowColor: "rgba(0, 0, 0, 0.05)",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },

    assessmentTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 18,
        lineHeight: 20,
        color: "#000000",
    },

    assessmentType: {
        marginTop: 16,
        fontFamily: "WorkSans_600SemiBold",
        fontWeight: "500",
        fontSize: 13,
        lineHeight: 15,

        color: "#F05343",
    },

    assessmentStage: {
        fontFamily: "WorkSans_400Regular",

        fontSize: 13,
        lineHeight: 18,
        color: "#959595",
    },

    divider: {
        marginTop: 20
    },

    customAssessmentBtns: {
        flexDirection: "row",
        marginBottom: 12
    },
    btnContainer: {
        height: 24,
        borderRadius: 3,
        paddingHorizontal: 16,
        paddingVertical: 4,
        backgroundColor: "#fff"
    },
    btnText: {
        color: "#fff"
    }
})
