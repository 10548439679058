import React  from 'react'
import PropTypes from 'prop-types'
import {SimpleModal} from '@/components'
import {
  Message,
  LeaveButton,
  LeaveButtonText,
  Content,
  Actions,
  Container
} from './styles'
import {isMobile} from "@/styles";


const ConfirmationModal = ({
  isVisible,
  setVisible,
  message,
  actionText,
  onAction
}) => {

  const handleAction = () => {
    setVisible(false)
    onAction()
  }

  return (
    <SimpleModal isVisible={isVisible}>
        <Container>
          <Content>
        <Message>{ message }</Message>
        </Content>
       	<Actions isMobile={isMobile}>
          <LeaveButton onPress={handleAction} style={{ marginBottom: 12 }}>
            <LeaveButtonText>{ actionText }</LeaveButtonText>
          </LeaveButton>
          <LeaveButton cancel onPress={() => setVisible(false)}>
            <LeaveButtonText cancel	>
							Cancel
            </LeaveButtonText>
          </LeaveButton>
        </Actions>
        </Container>
    </SimpleModal>
  )
}

ConfirmationModal.propTypes = {
  setVisible: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  message: PropTypes.string,
  actionText: PropTypes.string,
}

export default ConfirmationModal
