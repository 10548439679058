import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        marginBottom: 20,
        flexDirection: 'column',
        zIndex: 9
    },
    FullBleedContainer: {

    },
    PaddedContainer: {
        paddingTop: 20,
        paddingHorizontal: 20,
        flexDirection: 'column'
    },
    PaddedContainerTop: {
        flexDirection: 'row',
    },
    PaddedContainerBottom: {

    },
    BackContainer: {
        justifyContent: 'center',
    },
    BackButton: {
        marginRight: 15,
        backgroundColor: '#1b1b1b',
        borderRadius: 50,
        justifyContent: 'center',

        ...Platform.select({
            ios: {
                width: 25,
                height: 25,
            },
            android: {
                width: 25,
                height: 25,
            },
            web: {
                width: 30,
                height: 30,
                alignItems: 'center',
            }
        })
    },
    IconContainer: {
        marginRight: 10,
        justifyContent: 'center'
    },
    Icon: {

    },
    TitleContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '65%',
        justifyContent: 'center'
    },
    Title: {
        fontFamily: "WorkSans_400Regular",
        ...Platform.select({
            ios: {
                fontSize: 25,
                lineHeight: 25,
            },
            android: {
                fontSize: 25,
                lineHeight: 25,
            },
            web: {
                fontSize: 30,
                lineHeight: 30,
            }
        })
    },
    ActionsContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        flex: 1
    }
});