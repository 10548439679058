import React, { useState, useEffect, useReducer } from 'react'
import VideoChat from './VideoChat'
import VideoContext from './context'
import _ from 'lodash'
import useSideBarContext from '@/contexts/SidebarContext/useSideBarContext'
import { useNavigation } from '@react-navigation/native'
import { Dimensions, View } from 'react-native'

const { width, height } = Dimensions.get('window');

const presentationReducer = (state, action) => {
    switch (action.type) {
        case "set_presentation_id":
            return action.payload;
        case "clear_presentation_id":
            return null
        default: return state;
    }
}

const VideoCall = () => {
    const [participantsInfo, setParticipantsInfo] = useState(new Map());
    const [audioInputDevices, setAudioInputDevices] = useState(new Map());
    const [audioOutputDevices, setAudioOutputDevices] = useState(new Map());
    const [videoInputDevices, setVideoInputDevices] = useState(new Map());
    const [presentationId, dispatch] = useReducer(presentationReducer, null);
    const [settings, setSettings] = useState({
        audioInput: null,
        audioOutput: null,
        videoInput: null
    })
    const { setSidebarHidden } = useSideBarContext();
    const navigation = useNavigation();


    const setPresentationId = (id) => {
        dispatch({ type: "set_presentation_id", payload: id })
    }


    const updateSetting = (key, value) => {
        switch (key) {
            case 'audioInput':
                setSettings(prev => ({ ...prev, audioInput: value }));
                break;
            case 'audioOutput':
                setSettings(prev => ({ ...prev, audioOutput: value }));
                break;
            case 'videoInput':
                setSettings(prev => ({ ...prev, videoInput: value }));
                break;
            default: return;
        }
    }

    const getSetting = (key) => {
        return settings[key];
    }

    const getIODevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoInput = devices.filter(device => device.kind == "videoinput");
        const audioInput = devices.filter(device => device.kind == 'audioinput');
        const audioOutput = devices.filter(device => device.kind == 'audiooutput');


        updateSetting("videoInput", videoInput[0]?.deviceId);

        setAudioInputDevices(audioInput);
        setAudioOutputDevices(audioOutput);
        setVideoInputDevices(videoInput);
    }

    useEffect(() => {
        getIODevices();
        setSidebarHidden(true);
    }, [])

    useEffect(() => {
        navigation.addListener("focus", () => setSidebarHidden(true));
    }, [])


    const getParticipant = (id) => {
        const user = participantsInfo.get(id);
        return user;
    }

    const getAudioInputDevice = (label) => {
        const device = audioInputDevices.get(label);
        return device;
    }
    const getAudioOutputDevice = (label) => {
        const device = audioOutputDevices.get(label);
        return device;
    }
    const getVideoInputDevice = (label) => {
        const device = videoInputDevices.get(label);
        return device;
    }

    const addParticipant = (id, data) => {
        const local = participantsInfo;
        setParticipantsInfo(new Map([...participantsInfo, [id, data]]));
    }


    return (
        <VideoContext.Provider value={{ participantsInfo, addParticipant, getParticipant, getAudioInputDevice, getVideoInputDevice, getAudioOutputDevice, audioInputDevices, audioOutputDevices, videoInputDevices, updateSetting, getSetting, presentationId, setPresentationId }}>
            <View style={{ width, height }}>
                <VideoChat />
            </View>
        </VideoContext.Provider>
    )
}

export default VideoCall;