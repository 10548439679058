import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        flexDirection: 'column',
        width: 'auto',
        alignSelf: 'stretch',
        shadowColor: 'rgb(2, 2, 2)',
        shadowOpacity: 0.10,
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 5,
        borderRadius: 10,
        marginHorizontal: 15
    },
    Card: {
        width: '100%',
        flexDirection: 'column',
        borderRadius: 10,
        alignSelf: 'stretch',
        elevation: 1,
        backgroundColor: "#FFFFFF",
        paddingBottom: 20
    },
    CoverContainer: {
        position: 'relative',
        width: '100%',
        aspectRatio: 1.5,
        borderRadius: 10,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
    },
    CoverButton: {
        position: 'absolute',
        right: 16,
        top: 16,
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
        backgroundColor: 'rgba(27, 27, 27, 0.63)',
        borderRadius: 50
    },
    CoverDateContainer: {
        zIndex: 1,
        paddingVertical: 4,
        paddingHorizontal: 16,
        backgroundColor: '#8B77E8',
        position: 'absolute',
        top: 16,
        left: 16,
        borderRadius: 3
    },
    CoverDate: {
        fontWeight: '400',
        fontSize: 12,
        color: '#FFFFFF'
    },
    CoverImage: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
    },
    MetaContainer: {
        flex: 1,
        marginTop: 15,
        paddingLeft: 10,
        paddingRight: 10
    },
    TitleContainer: {
        marginBottom: 10
    },
    Title: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 16,
    },
    TagsContainer: {
        flexDirection: 'row',
        marginTop: 10
    },
    Tag: {
        backgroundColor: 'rgba(27, 27, 27, 0.8)',
        paddingVertical: 3,
        paddingHorizontal: 3,
        fontSize: 10,
        borderRadius: 4,
        color: '#FFFFFF',
        marginRight: 5
    },
    ExcerptContainer: {
    },
    Excerpt: {
        fontSize: 12
    },
});
