import routes from './routes'
export default routes

export const linking = {
  config: {
    screens: {
      Splash: 'loading',
      Onboarding: {
        path: 'auth',
        screens: {
          Login: 'login',
          SignUp: 'signUp',
          ForgotPassword: 'forgotPassword',
          ResetPassword: 'resetPassword',
          SendPasswordCode: 'sendPasswordCode',
          Welcome: 'welcome',
          WelcomeFlow: 'welcomeFlow',
          PersonalInformation: 'personalInformation',
          CommunityGuidelines: 'communityGuidelines',
          SignupWorkspaceCode: 'signUpWorkspaceCode',
          MultiWorkspace: 'multiWorkspace',
          Subscribe: 'subscribe'
        },
      },
      Main: {
        path: '/',
        screens: {
          Home: 'home',
          Community: 'community',
          Employee: 'employee',
          Page: 'page',
          Teams: 'teams',
          Groups: 'groups',
          Learning: 'learning',
          SubFolders: 'sub_folders',
          ViewArticle: 'view_article',
          CreateArticle: 'create_article',
          TeamPage: 'team',
          GroupPage: 'group',
          Settings: 'settings',
          Subscribe: 'subscribe',
          meeting: "meeting/:id",
          Profile: {
            path: 'profile',
            screens: {
              MyProfile: 'me',
              LastActivities: 'lastActivities',
            },
          },
          Workspaces: 'workspaces'
        },
      },
    },
  },
}
