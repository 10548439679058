import React, { useCallback, useRef, useContext } from 'react'
import {
  Modal,
  Animated,
  useWindowDimensions,
  Easing,
} from 'react-native'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import Item from './Item'
import { Icon } from '@/components'
import { CloseButton, ContainerModal, Main, MainBottom } from './styles'

export default function CollapseButtonMobile({ isCollapseVisible, setCollapseVisible, children }) {
  const { height } = useWindowDimensions()
  const translateY = useRef(new Animated.Value(height)).current

  const open = useCallback(() => {
    Animated.timing(translateY, {
      useNativeDriver: true,
      // delay: 500,
      // easing: Easing.inOut(Easing.ease),
      toValue: 0
    }).start()
  }, [isCollapseVisible, setCollapseVisible])

  const close = useCallback(() => {
    Animated.timing(translateY, {
      useNativeDriver: true,
      // delay: 500,
      toValue: height / 2
    }).start(() => setCollapseVisible(false))
  }, [isCollapseVisible, setCollapseVisible])

  return (
    <Modal
      visible={isCollapseVisible}
      transparent
      animationType="fade"
      onShow={open}
    >
      <ContainerModal>
        <Main style={{
          transform: [{ translateY }]
        }}>
          <MainBottom>
            <CloseButton onPress={close}>
              <Icon icon="close" color={theme.basics[900]} width={14} height={14} />
            </CloseButton>
            {children}
          </MainBottom>
        </Main>
      </ContainerModal>
    </Modal>
  )
}

CollapseButtonMobile.Item = Item

CollapseButtonMobile.propTypes = {
  isCollapseVisible: PropTypes.bool.isRequired,
  setCollapseVisible: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}
