import React, { useState } from 'react'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import {
    Card,
    CloseButton,
    Title,
    ContainerButtons,
    Button,
    ButtonText,
    styles,
    MemberContainer,
    ButtonContainer,
    IconContainer,
    TitleText
} from './styles'
import { SimpleModal, MemberWeb } from '@/components'
import { isMobile } from '@/styles'
import { Icon } from '@/components'
import { Paragraph1 } from '@/styles/fonts'
import GroupTeamsMemberItem from '../GroupTeamsMemberItem'
import { ScrollView, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler'

const ManageMemberModal = ({
    members,
    isTeam,
    isOwner,
    isManageMemberViewVisible,
    setManageMemberViewVisible,
    handleRemoveMember,
    onBack,
}) => {

    if (!isManageMemberViewVisible) {
        return null
    }
    const [isDeleteModalVisible, seIsDeleteModalVisible] = useState(false)
    const [deletingUserId, setDeletingUserId] = useState()

    return (
        <SimpleModal isVisible={isManageMemberViewVisible}>
            {!isDeleteModalVisible &&
                <Card>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Title>Members</Title>
                        <CloseButton onPress={() => setManageMemberViewVisible(!isManageMemberViewVisible)}>
                            <Icon icon="close" height={14} width={14} color={theme.basics.brand} />
                        </CloseButton>
                    </View>
                    <ScrollView style={{ marginTop: 20 }} showsVerticalScrollIndicator={false}>
                        {(members ?? []).map(member => (
                            <GroupTeamsMemberItem
                                key={member?.id}
                                member={member}
                                isTeam={isTeam}
                                isOwner={isOwner}
                                onBack={onBack}
                                handleRemoveMember={handleRemoveMember}
                                seIsDeleteModalVisible={seIsDeleteModalVisible}
                                setDeletingUserId={setDeletingUserId}
                            />
                        ))}
                    </ScrollView>
                    {/* <ContainerButtons>
                    <Button cancel onPress={() => setManageMemberViewVisible(!isManageMemberViewVisible)}>
                        <ButtonText cancel>
                            Cancel
                        </ButtonText>
                    </Button>
                    <Button >
                        <ButtonText>
                            Send
                        </ButtonText>
                    </Button>
                </ContainerButtons> */}
                </Card>}
            {isDeleteModalVisible && <Card>
                <IconContainer>
                    <CloseButton onPress={() => setManageMemberViewVisible(!isManageMemberViewVisible)}>
                        <Icon icon="close" height={14} width={14} color={theme.basics.brand} />
                    </CloseButton>
                </IconContainer>
                <TitleText>Are you sure you want to delete this user?</TitleText>
                <ButtonContainer>
                    <ContainerButtons>
                        <Button cancel onPress={() => setManageMemberViewVisible(!isManageMemberViewVisible)}>
                            <ButtonText cancel>
                                Cancel
                            </ButtonText>
                        </Button>
                        <Button onPress={() => {
                            handleRemoveMember(deletingUserId)
                            setManageMemberViewVisible(!isManageMemberViewVisible)
                        }}>
                            <ButtonText>
                                Delete
                            </ButtonText>
                        </Button>
                    </ContainerButtons>
                </ButtonContainer>
            </Card>}
        </SimpleModal>
    )
}

ManageMemberModal.propTypes = {
    members: PropTypes.array,
    isTeam: PropTypes.bool,
    isOwner: PropTypes.bool,
    isManageMemberViewVisible: PropTypes.bool,
    handleRemoveMember: PropTypes.func.isRequired,
    onBack: PropTypes.func
}

ManageMemberModal.defaultProps = {
    members: [],
    isTeam: true,
    isOwner: false,
    isManageMemberViewVisible: false,
}
export default ManageMemberModal
