import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, View, Text, Image } from 'react-native'
import moment from 'moment'
import Autolink from 'react-native-autolink'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import VideoContext from '@/pages/Meeting/context'
import { formatUserName } from '@/utils/format'
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate'
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth'

const CallMessage = ({ timeStamp, message, sender }) => {
    const color = UseBrandingAccentColor();
    const user = useSelector(getMember);
    const [userInfo, setUserInfo] = useState();
    const { getParticipant } = useContext(VideoContext);

    useEffect(() => {
        const user = getParticipant(sender.toString());
        setUserInfo(user);
    }, [])

    return (
        <View style={{ flexDirection: 'row', paddingHorizontal: 10, marginVertical: 10, justifyContent: 'space-between', alignItems: 'center' }}>
            <View style={{ width: "100%" }}>
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 15, width: '100%' }}>
                    <View style={{ flexDirection: "row", justifyContent: "space-between", width: '33%', alignItems: 'center', marginHorizontal: 5 }}>
                        {userInfo?.avatar_url ? (
                            <Image source={{ uri: userInfo?.avatar_url }} style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: color, marginRight: 5 }} />) : (
                            <AvatarTemplate size={40} letter={userInfo?.avatar_initials} />
                        )}
                        <Text style={[styles.text, { paddingRight: 10, fontWeight: 'bold' }]}>{formatUserName(userInfo?.first_name, userInfo?.last_name)}</Text>
                    </View>
                    <Text>{moment(timeStamp).fromNow()}</Text>
                </View>
                <View style={{ backgroundColor: sender == user.id ? color : 'lightgray', marginVertical: 5, padding: 8, width: '70%', borderRadius: 8, marginHorizontal: 22 }}>
                    <Autolink linkProps={{ style: { color } }} textProps={{ style: [styles.text, { color: sender == user.id ? "#fff" : "#000" }] }} text={message} />
                </View>
            </View>
        </View>
    )

}

export default CallMessage;

const styles = StyleSheet.create({
    text: {
        color: 'black',
    }
})