import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Image, StyleSheet, KeyboardAvoidingView, Platform, SafeAreaView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'

import * as profileSelectors from '@/redux/selectors/profile'
import { actions as profileActions } from '@/redux/reducers/profile'
import { getUser, getMember } from '@/redux/selectors/auth'

import EventsIcon from '@/assets/svgs/EventsIcon'
import CoursesIcon from '@/assets/svgs/CoursesIcon'
import StarBuksLogo from '@/assets/svgs/StarBuksLogo'

import * as S from './styles'
import theme from '@/styles/themes/black'

import { isMobile } from '@/styles'
import { Avatar, Icon, EditProfile, SimpleModal, TextField } from '@/components'

// import TopicsList from './components/TopicsList'

import { formatUserName } from '@/utils/format'
import * as brandSelectors from '@/redux/selectors/branding'
import ButtonPrimary from '@/components/ButtonPrimary'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Toast from 'react-native-toast-message';
import ApiHandler from '@/api/ApiHandler'

const initialValues = {
  email: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required()
});

const PersonalInfo = ({ id }) => {
  const [isEditModalVisible, setEditModalVisible] = useState(false)
  const [isMemberReferralModalVisible, setIsMemberReferralModalVisible] = useState(false)
  const [isBtnLoading, setBtnLoading] = useState(false)
  const navigation = useNavigation()
  const dispatch = useDispatch()


  const brandSettings = useSelector(brandSelectors.getSettings);
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = brandSettings

  let userProfile = useSelector(profileSelectors.getProfile)
  // currentUser
  const user = useSelector(getMember)

  // const preferredTopics = useSelector(profileSelectors.getPreferredTopics)

  useEffect(() => {
    if (user?.id == id) {
      userProfile = user
      dispatch(profileActions.fetchProfile(id))
      dispatch(profileActions.fetchRecentRewards())
      dispatch(profileActions.fetchPreferredTopics())
    } else {
      dispatch(profileActions.fetchProfile(id))
    }
  }, [id, dispatch])

  const handleEditPerfil = useCallback(() => {
    // if (isMobile) {
    //   navigation.navigate('EditProfile')
    //   return
    // }
    setEditModalVisible(true)
  }, [isMobile, setEditModalVisible, navigation])

  // Send a referral email
  const handleSubmit = async (values) => {
    setBtnLoading(true);
    try {
      const data = {
        email: values.email
      };
      const response = await new ApiHandler().sendAReferral(data);
      if (response?.data) {
        setBtnLoading(false);
        setIsMemberReferralModalVisible(false);
        Toast.show({
          position: isMobile ? 'bottom' : 'top',
          text1: response?.data?.message
        });
      }
    } catch (error) {
      setBtnLoading(false);
      setIsMemberReferralModalVisible(false);
      console.log('SEND A REFERRAL ERROR', error);
    }
  };

  const renderReferAFriend = () => {
    return (
      <Formik
        validateOnMount
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, isValid, handleChange, handleSubmit }) => (
          <>
            <TextField
              label="Email"
              value={values?.email}
              autoCapitalize="none"
              autoCompleteType="email"
              keyboardType="email-address"
              onChangeText={handleChange('email')}
            />
            <ButtonPrimary
              label="Send"
              onPress={handleSubmit}
              _disabled={!isValid}
              loading={isBtnLoading}
              customStyles={{
                marginTop: 20
              }}
            />
          </>
        )}
      </Formik>
    );
  }

  return (
    <View style={{ width: '100%' }}>
      <S.AvatarContainer>
        <Avatar size={130} elevation={2} source={userProfile?.avatar ? { uri: userProfile?.avatar } : null} />
        <S.TitleAvatar>
          {formatUserName(userProfile?.first_name, userProfile?.last_name)}
        </S.TitleAvatar>
      </S.AvatarContainer>
      <S.DadosContainer>
        <S.SubTitleText>{userProfile?.internal_id}</S.SubTitleText>
        <S.SubTitleText>{userProfile?.role}</S.SubTitleText>
        <S.SubTitleText>{userProfile?.city}</S.SubTitleText>
        <S.SubTitleText>{userProfile?.email}</S.SubTitleText>
        {/* {
          id && (
            <S.SendMessageButton>
              <S.SendButtonText>Send Message</S.SendButtonText>
            </S.SendMessageButton>
          )
        } */}
      </S.DadosContainer>
      <S.Divider />

      {/* <S.TopicsTitle>Preferred topics</S.TopicsTitle> */}

      {/* <TopicsList topics={preferredTopics} /> */}

      {/* <TouchableOpacity style={{ paddingHorizontal: 18 }}>
        <EditTopicsButton />
      </TouchableOpacity> */}

      {/* <S.TopicsTitle>Last rewards</S.TopicsTitle> */}
      {/*
      <Image
        // source={require('@/assets/Medal.png')}
        source={MedalIcon}
        style={{ marginLeft: 16, marginBottom: 20 }}
      /> */}

      {/* <S.RewardsContainer>
        <S.HorizontalRewardsContainer>
          <S.RewardCard
            color={theme.info.standard}
            icon={
              <View style={styles.wrongSvgIconContainer}>
                <View style={styles.wrongSvgIconContentContainer}>
                  <EventsIcon width={80} height={80} />
                </View>
              </View>
            }
          >
            <CaptionMedium color="white">6 events watched</CaptionMedium>
          </S.RewardCard>

          <S.RewardCard
            icon={
              <View style={styles.wrongSvgIconContainer}>
                <View style={styles.wrongSvgIconContentContainer}>
                  <CoursesIcon width={80} height={80} />
                </View>
              </View>
            }
          >
            <CaptionMedium color="white">6 courses completed</CaptionMedium>
          </S.RewardCard>
        </S.HorizontalRewardsContainer>

        <Card icon={<StarBuksLogo />} color={theme.support.optional_2}>
          <Caption1 color="white">Won a coffee from Starbucks!</Caption1>
        </Card>
      </S.RewardsContainer> */}
      {user?.id == id && (
        <S.EditeProfileButton onPress={handleEditPerfil} color={color_scheme_primary_button_background} >
          <S.EditText color={color_scheme_primary_button_text} >Edit Profile</S.EditText>
        </S.EditeProfileButton>
      )}
      <S.Divider />
      <S.ReferAFriendView>
        <S.ReferAFriendTitle>
          Refer A Friend
        </S.ReferAFriendTitle>
        <S.ReferAFriendSubTitle>
          Send a referral to a friend via email to join your community.
        </S.ReferAFriendSubTitle>

        <S.EditeProfileButton
          onPress={handleEditPerfil}
        //  onPress={() => setIsMemberReferralModalVisible(true)}
        >
          <S.EditText color={color_scheme_primary_button_text} >Refer A Friend</S.EditText>
        </S.EditeProfileButton>
      </S.ReferAFriendView>
      <SimpleModal isVisible={isMemberReferralModalVisible}>
        <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 30}>
          <S.ReferAFriendContainer>
            <S.HeaderEditProfile>
              <S.CloseButton onPress={() => setIsMemberReferralModalVisible(false)}>
                <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
              </S.CloseButton>
              <S.HeaderTitle>Refer A Friend</S.HeaderTitle>
            </S.HeaderEditProfile>
            {renderReferAFriend()}
          </S.ReferAFriendContainer>
        </KeyboardAvoidingView>
      </SimpleModal>
      <SimpleModal isVisible={isEditModalVisible}>
        <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={Platform.OS === 'ios' ? 0 : 30}>
          <S.EditProfileContainer>
            <S.HeaderEditProfile>
              <S.Offset />
              <S.HeaderTitle>Edit Profile</S.HeaderTitle>
              <S.CloseButton onPress={() => setEditModalVisible(false)}>
                <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
              </S.CloseButton>
            </S.HeaderEditProfile>
            <EditProfile cancel={() => setEditModalVisible(false)} navigation={navigation} userId={id} />
          </S.EditProfileContainer>
        </KeyboardAvoidingView>
      </SimpleModal>
    </View>
  )
}

// TODO: fix this (export svgs correctly)
const styles = StyleSheet.create({
  wrongSvgIconContainer: {
    width: 60,
    height: 60,
    position: 'relative',
  },
  wrongSvgIconContentContainer: {
    width: 60,
    height: 60,
    left: -5,
  },
})

PersonalInfo.propTypes = {
  id: PropTypes.number
}

export default PersonalInfo
