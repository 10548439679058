import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Workspace(props) {
  return (
    <Svg width="24" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <Path d="M9.01 14H2V16H9.01V19L13 15L9.01 11V14ZM14.99 13V10H22V8H14.99V5L11 9L14.99 13Z" fill={props.color} />
    </Svg>
  )
}

Workspace.propTypes = SvgProps

export default Workspace
