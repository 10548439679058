import { useEffect, useState } from 'react'
import { Dimensions, useWindowDimensions, Platform } from 'react-native'

export const ScreenSize = {
  XSmall: 0,
  Small: 576,
  Medium: 768,
  Large: 992,
  XLarge: 1280,
}


const getScreenSize = (screenWidth) => {
  if (screenWidth >= ScreenSize.XLarge) return ScreenSize.XLarge
  else if (screenWidth >= ScreenSize.Large) return ScreenSize.Large
  else if (screenWidth >= ScreenSize.Medium) return ScreenSize.Medium
  else if (screenWidth >= ScreenSize.Small) return ScreenSize.Small

  return ScreenSize.XSmall
}

export const useScreenSize = () => {
  const width = useWindowDimensions().width
  const [screenSize, setScreenSize] = useState(getScreenSize(width))

  useEffect(() => {
    const size = getScreenSize(width)
    if (size !== screenSize) setScreenSize(size)
  }, [width])

  return screenSize
}

const getNumColumn = (screenWidth) => {
  if (screenWidth >= ScreenSize.XLarge) return 4
  else if (screenWidth >= ScreenSize.Large) return 3
  else if (screenWidth >= ScreenSize.Medium) return 2
  else if (screenWidth >= ScreenSize.Small) return 1

  return 1
}

export const useNumColumns = () => {
  const width = useWindowDimensions().width
  const [numColumns, setNumColumns] = useState(getNumColumn(width))

  useEffect(() => {
    const size = getNumColumn(width)
    if (size !== numColumns) setNumColumns(size)
  }, [width])

  return numColumns
}

export const isMobile = Platform.OS !== 'web'

export const useIsMobile = () => {
  const width = useWindowDimensions().width
  const [isMobile, setIsMoblie] = useState(width <= ScreenSize.Medium)

  useEffect(() => {
    if (width <= ScreenSize.Medium) {
      setIsMoblie(true)
    } else {
      setIsMoblie(false)
    }
  }, [width])

  return isMobile
}
