import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'

import { Formik } from 'formik'
import Toast from 'react-native-toast-message'
import * as Yup from 'yup'
import { ActivityIndicator } from 'react-native-paper'
import { vw } from 'react-native-expo-viewport-units'
import { TextField } from '@/components'
import OnboardingPage from '@/components/OnboardingPage'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import { actions as authActions } from '@/redux/reducers/auth'
import { Entypo } from '@expo/vector-icons'
import { ScreenSize, useScreenSize } from '@/styles'
import { NextButton, TextButton, SignUpPageContent } from './styles'
import SignupDecoration from '@/assets/onboarding/SignupDecoration'
import SocialMediaContainer from '@/components/SocialMediaContainer'

const initialValues = {
  name: '',
  email: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required(),
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required(),
})

function SignUp({ navigation }) {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [loading, setLoading] = useState(false)

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true)

      dispatch(
        authActions.signUp(values, (error) => {
          setLoading(false)

          if (error) {
            Toast.show({
              type: 'error',
              position: 'bottom',
              text1: 'Error creating account, please try again.',
            })
          }
        }),
      )
    },
    [dispatch],
  )

  return (
    <OnboardingPage
      header={
        <OnboardingHeader withBackButton={screenSize < ScreenSize.Medium} />
      }
      decoration={<SignupDecoration width={vw(45)} height="auto" />}
    >
      <SignUpPageContent screenSize={screenSize}>
        <OnboardingFormWrapper title={'Sign up and\nstart exploring!'}>
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, isValid, handleChange, handleSubmit }) => (
              <View>
                <View>
                  <TextField
                    label="Name"
                    value={values.name}
                    autoCapitalize="words"
                    autoCompleteType="name"
                    onChangeText={handleChange('name')}
                  />
                  <TextField
                    value={values.email}
                    label="E-mail adress"
                    autoCapitalize="none"
                    autoCompleteType="email"
                    keyboardType="email-address"
                    onChangeText={handleChange('email')}
                  />
                  <TextField
                    secureTextEntry
                    label="Password"
                    autoCapitalize="none"
                    value={values.password}
                    autoCompleteType="password"
                    onChangeText={handleChange('password')}
                  />
                </View>
                <NextButton
                  color="black"
                  disabled={!isValid}
                  onPress={handleSubmit}
                >
                  {loading && (
                    <ActivityIndicator
                      size={20}
                      animating
                      color="white"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <TextButton>Let&apos;s Go</TextButton>
                  <Entypo name="chevron-right" size={24} color="white" />
                </NextButton>
                <SocialMediaContainer
                  linkText=" Login"
                  text="Already a member?"
                  navigate={() => navigation.navigate('Login')}
                />
              </View>
            )}
          </Formik>
        </OnboardingFormWrapper>
      </SignUpPageContent>
    </OnboardingPage>
  )
}

SignUp.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default SignUp
