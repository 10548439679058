import React, { useState, useCallback } from 'react'
import {
    TouchableOpacity,
    View,
    Image,
    StyleSheet,
    Modal,
    ActivityIndicator,
    SafeAreaView,
    Text,
    Platform,
} from "react-native";
import { useRoute } from '@react-navigation/native';

import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import moment from 'moment';

import { getMember } from "@/redux/selectors/auth";
import { actions as postActions } from "@/redux/reducers/posts";

import { Avatar, Icon } from '@/components'
import ExpansiveMenu from "@/components/ExpansiveMenu";
import CommentList from "@/components/Feed/Card/Content/Components/Comments";

import styles from './styles.js';

const GenericContentCard = ({
    type,
    post,
    menu,
    children
}) => {
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const route = useRoute();
    const user = useSelector(getMember);

    const formatTimestamp = useCallback((timestamp) => {
        return moment(timestamp).format("MMM D, YYYY");
    })

    const navigateToPost = useCallback(() => {
        if (route.name != 'CommentPost') {
            navigation.navigate("CommentPost", {
                post_id: post.id,
                contentType: type
            });
        }
    });

    const toggleLike = useCallback(() => {
        if (type == 'App\\Post') {
            dispatch(
                postActions.like(
                    {
                        id: post.id,
                        user,
                        postType: type,
                    },
                    () => {

                    }
                )
            );
        }

        if (
            type == 'App\\Article' ||
            type == 'App\\Event' ||
            type == 'App\\Course'
        ) {
            dispatch(
                postActions.like_article(
                    {
                        id: post.id,
                        user,
                        postType: type,
                    },
                    () => {
                        //handleReleateLaterArticle()
                    }
                )
            );
        }

        if (route.name == 'CommentPost') {
            navigation.setParams({ stamp: Math.random() })
        }
    }, [dispatch, post]);

    return (
        <>
            <View style={styles.HeaderContainer}>
                <View style={styles.AuthorAvatarContainer}>
                    <Avatar
                        source={post?.creator?.avatar ? { uri: post?.creator?.avatar } : null}
                        resizeMode="cover"
                        size={45}
                    />
                </View>

                <View style={styles.AuthorDetailsContainer}>
                    <Text style={styles.AuthorName}>{`${post?.creator?.first_name ?? ''} ${post?.creator?.last_name ?? ''}`}</Text>

                    {post?.creator?.role ? (
                        <Text style={styles.AuthorRole}>{`${post?.creator?.role ?? ''}`}</Text>
                    ) : null}

                    <Text style={styles.AuthorTimestamp}>{formatTimestamp(post?.created_at)}</Text>
                </View>

                <View style={styles.MenuContainer}>
                    {menu?.length > 0 ? (
                        <ExpansiveMenu
                            mainIconColor="#000"
                            mainIconBackground="transparent"
                            expansiveChildrens={menu}
                        />
                    ) : null}
                </View>
            </View>

            <View style={styles.Body}>
                {children}
            </View>

            <View style={styles.InteractionsStatsContainer}>
                <View style={styles.InteractionsStatContainer}>
                    <TouchableOpacity
                        style={[
                            styles.InteractionsStatButtonContainer,
                            {
                                backgroundColor: '#FFFFFF',
                                borderRadius: 10,
                                paddingVertical: 5,
                                paddingHorizontal: 10,
                                shadowColor: 'rgba(2, 2, 2, 1)',
                                shadowOffset: { width: 2, height: 1 },
                                shadowOpacity: 0.10,
                                shadowRadius: 20,
                                elevation: 3,
                            }
                        ]}
                        onPress={() => {

                        }}
                    >
                        <Icon
                            icon="like"
                            height={22}
                            width={19}
                            color={'#f05343'}
                        />

                        <Text style={[
                            styles.InteractionsStatButtonLabel,
                            {
                                fontWeight: '600'
                            }
                        ]}>{post?.likes?.length}</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.InteractionsStatContainer}>
                    <TouchableOpacity
                        style={styles.InteractionsStatButtonContainer}
                        onPress={navigateToPost}
                    >
                        <Text style={styles.InteractionsStatButtonLabel}>{post?.comments?.length} {'Comment(s)'}</Text>
                    </TouchableOpacity>
                </View>
            </View>

            <View style={styles.InteractionsActionsContainer}>
                <View style={styles.InteractionsActionContainer}>
                    <TouchableOpacity
                        style={styles.InteractionsActionButtonContainer}
                        onPress={() => {
                            toggleLike()
                        }}
                    >
                        <Icon
                            icon="like"
                            height={22}
                            width={19}
                            color={post?.liked ? '#f05343' : '#1B1B1B'}
                        />

                        <Text style={styles.InteractionsActionButtonLabel}>{'Like'}</Text>
                    </TouchableOpacity>
                </View>

                {route.name != 'CommentPost' ? (
                    <View style={styles.InteractionsActionContainer}>
                        <TouchableOpacity
                            style={styles.InteractionsActionButtonContainer}
                            onPress={navigateToPost}
                        >
                            <Icon
                                icon="comment"
                                height={22}
                                width={19}
                                color={'#1B1B1B'}
                            />

                            <Text style={styles.InteractionsActionButtonLabel}>{'Comment'}</Text>
                        </TouchableOpacity>
                    </View>
                ) : null}
            </View>

            {Platform.OS == 'web' || route.name == 'CommentPost' ? (
                <View style={styles.CommentsContainer}>
                    <CommentList
                        post={post}
                        contentType={type}
                        postType={type}
                    />
                </View>
            ) : null}

        </>
    )
}

export default GenericContentCard