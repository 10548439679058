import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { Caption1 } from '@/styles/fonts'

export const Container = styled(TouchableOpacity)`
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 13px 0 10px 30px;
`

export const Ruler = styled.View`
  width: 100%;
  height: 1px;
  background-color: rgba(50, 50, 50, 0.1);
`

export const Label = styled(Caption1)`
  flex-grow: 1;
  margin-top: 4px;
  text-align: right;
  color: rgba(50, 50, 50, 0.4);
`
