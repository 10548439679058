import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Pencil(props) {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M0.25 10.9377V13.7502H3.0625L11.3575 5.45517L8.545 2.64267L0.25 10.9377ZM13.5325 3.28017C13.825 2.98767 13.825 2.51517 13.5325 2.22267L11.7775 0.467666C11.485 0.175166 11.0125 0.175166 10.72 0.467666L9.3475 1.84017L12.16 4.65267L13.5325 3.28017Z" />
    </Svg>
  )
}

Pencil.propTypes = SvgProps

export default Pencil
