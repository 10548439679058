import React, { useCallback, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import PropTypes from 'prop-types'
// import { useDispatch } from 'react-redux'
import { Formik } from 'formik'
import * as Yup from 'yup'
import {
  SimpleModal,
  Icon,
  TextField,
} from '@/components'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import {
  Container,
  CloseModalButton,
  Title,
  ContainerCoverImage,
  CoverImage,
  CoverButton,
  SubmitButton,
  TextButton,
  FormikContainer
} from './styles'
import { openGallery } from '@/utils/gallery'

const validationSchema = Yup.object({
  image_cover: Yup.string()
    .required('Image cover is required'),
  group_name: Yup.string()
    .required('Group name is required'),
  description: Yup.string()
    .required('Description is required')
})

const EditInformation = ({
  isEditInformationVisible,
  setEditInformationVisible,
  group_name,
  description,
  source,
}) => {
  const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()

  const handleaCoverImage = useCallback(async (handleChange) => {
    try {
      const imageUri = await openGallery()
      handleChange('image_cover')(imageUri)
    } catch (e) {
      console.log('Error opening gallery')
    }
  }, [])

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true)
    },
    [],
  )

  const initialValues = {
    group_name,
    description,
    image_cover: source,
  }

  if (!isMobile) {
    return (
      <SimpleModal
        isVisible={isEditInformationVisible}
      >
        <Container>
          <CloseModalButton onPress={() => setEditInformationVisible(false)}>
            <Icon
              icon="close"
              height={14}
              width={14}
              color={theme.basics.brand}
            />
          </CloseModalButton>
          <Title>Edit information</Title>
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, isValid, handleChange, handleSubmit }) => (
              <>
                <ContainerCoverImage>
                  {values.image_cover && <CoverImage source={values.image_cover} resizeMode="cover" />}
                  <CoverButton onPress={() => handleaCoverImage(handleChange)}>
                    <Icon
                      icon="addPhoto"
                      color={theme.basics.brand}
                      width={15}
                      height={14}
                    />
                  </CoverButton>
                </ContainerCoverImage>
                <FormikContainer>
                  <TextField
                    label="Group Name"
                    value={values.group_name}
                    autoCapitalize="none"
                    onChangeText={handleChange('group_name')}
                  />
                  <TextField
                    selectionColor='blue'
                    style={{ bottom: 10 }}
                    label="Description"
                    value={values.description}
                    autoCapitalize="none"
                    onChangeText={handleChange('description')}
                    multiline
                    numberOfLines={4}
                  />
                  <SubmitButton
                    color="black"
                    disabled={!isValid}
                    style={{ marginTop: 24 }}
                    onPress={handleSubmit}
                  >
                    {loading && (
                      <ActivityIndicator
                        size={20}
                        animating
                        color="white"
                        style={{ marginRight: 10 }}
                      />
                    )}
                    <TextButton>Update</TextButton>
                  </SubmitButton>
                </FormikContainer>
              </>
            )}
          </Formik>
        </Container>
      </SimpleModal>
    )
  }
  return null
}

EditInformation.propTypes = {
  isEditInformationVisible: PropTypes.bool,
  setEditInformationVisible: PropTypes.func,
  group_name: PropTypes.string,
  description: PropTypes.string,
  source: PropTypes.string,
}

export default EditInformation
