import React from 'react'
import App from './components/App'


const TwilioConversations = () => {
  return (
    <>
      <App />
    </>
  )
}

export default TwilioConversations;