import React, { useContext, useEffect, useState } from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import AutoLink from 'react-native-autolink'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import VideoContext from '../../context';
import { formatUserName } from '@/utils/format'
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate';
import moment from 'moment'
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth';
import ApiHandler from '@/api/ApiHandler';


const CallMessage = ({ timeStamp, message, sender }) => {
    const color = UseBrandingAccentColor();
    const { getParticipant, addParticipant } = useContext(VideoContext);
    const [userInfo, setUserInfo] = useState();
    const user = useSelector(getMember);


    useEffect(() => {
        const _user = getParticipant(sender.toString());

        if (!_user) {
            new ApiHandler().getParticipant({ id: sender }).then(res => {
                const result = res?.data?.data ?? {};

                addParticipant(sender?.toString(), result);
                setUserInfo(result);
            })
        } else {
            setUserInfo(_user);
        }

    }, [])

    return (
        <View style={{ paddingHorizontal: 10, marginVertical: 10, }}>
            <View style={{ width: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 15, width: '100%' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '40%', alignItems: 'center', marginHorizontal: 5 }}>
                        {userInfo?.avatar_url !== "" ? (
                            <Image source={{ uri: userInfo?.avatar_url }} style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: color }} />
                        ) : (
                            <AvatarTemplate letter={userInfo?.avatar_initials} />
                        )}
                        <Text style={[styles.text, { fontWeight: 'bold' }]}>{formatUserName(userInfo?.first_name, userInfo?.last_name)}</Text>

                    </View>
                    <Text>{moment(timeStamp).fromNow()}</Text>
                </View>
                <View style={{ backgroundColor: sender == user.id ? color : "lightgray", width: '70%', marginVertical: 5, padding: 10, borderRadius: 8, overflow: "hidden", marginHorizontal: 22 }}>
                    <AutoLink linkProps={{ style: { color } }} textProps={{ style: [styles.text, { color: sender == user.id ? "#fff" : "black" }] }} text={message} />
                </View>
            </View>
        </View>
    )
}

export default CallMessage;

const styles = StyleSheet.create({
    text: {
        width: '100%',
        paddingLeft: 5
    }
})