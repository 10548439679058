import React from 'react'
import { ActivityIndicator } from 'react-native'

const Loader = () => {
    return (
        <ActivityIndicator
            size={20}
            animating
            color="#F05343"
        />
    )
}

export { Loader };
