import React, { useCallback, useEffect, useState } from 'react'
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    ScrollView,
    SafeAreaView,
    Dimensions,
    Platform,
    TouchableOpacity
} from 'react-native'
import moment from "moment";
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useIsFocused } from '@react-navigation/native'
import ApiHandler from '@/api/ApiHandler'

import { Icon, Button } from '@/components'
import ButtonPrimary from '@/components/ButtonPrimary'

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import {
    LIST_TYPE,
    ATTEND_STATUS,

} from './consts';


import ModalCreate from "./ModalCreate";
import ModalEdit from "./ModalEdit";
import ModalDuplicate from "./ModalDuplicate";
import ViewEventModal from "./ViewEventModal"
import { ScreenSize, useScreenSize } from '@/styles'

import PopupMenu from './Menu/index.web';
import { Entypo } from '@expo/vector-icons'
import ContentBlockV1 from '@/components/ContentBlockV1'
import { UIColors } from '@/constants/colors';
import { List, Portal } from 'react-native-paper';
import EventDetail from './EventDetail.web';
import { getMember, getUser } from '@/redux/selectors/auth';

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

const { width, height } = Dimensions.get('window')

const COLOR_PRIMARY = "#F05343";
const SELECTED_CELL_BORDER_WIDTH = 4


const MONTH_YEAR_FORMAT = "MMMM YYYY";
const YEAR_DATE_FORMAT = "MMM DD, YYYY";

const WebContainerStyles = {
    height: "70vh", maxHeight: 700, minHeight: 450,
}

const formatTime = (time) => {
    const arr = time.split(" ");
    const hour = +arr[0];
    const apm = arr[1];

    if (apm.toLowerCase() === "pm" && hour < 12)
        return hour + 12
    return hour;
}

const isLastEventSlotItem = (event, item) => event?.eventSlots?.indexOf(formatTime(item?.hour ?? item)) == event?.eventSlots?.length - 1;
const isFirstEventSlotItem = (event, item) => event?.eventSlots?.indexOf(formatTime(item?.hour ?? item)) == 0;
const median = (values) => {
    if (values?.length === 0) throw new Error("No inputs");

    var half = Math.floor(values?.length / 2);

    if (values?.length % 2)
        return values?.[half];

    return Math.round((values?.[half - 1] + values?.[half]) / 2.0);
}

const CalendarAndEvents = () => {
    const screenSize = useScreenSize()

    const isWeb = Platform.OS == "web";
    const currentUser = useSelector(getMember);

    const navigation = useNavigation();
    const isFocused = useIsFocused();

    const [selectedDay, setSelectedDay] = useState(new Date());
    const [selectedWeek, setSelectedWeek] = useState(new Date());
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [listType, setListType] = useState(LIST_TYPE[2]);

    const [eventsList, setEventsList] = useState([]);


    const [eventModal, showEventModal] = useState(false);
    const [viewEventId, setViewEventId] = useState(-1);

    const [viewEventModal, showViewEventModal] = useState(false);
    const [duplicateEventModal, showDuplicateModal] = useState(false);
    const [editEventModal, showEditModal] = useState(false);

    const [reloading, setReloading] = useState(true);


    const weekday = moment().isoWeekday();

    const onPressToday = useCallback(() => {
        switch (listType.key) {
            case 0:
                setSelectedDay(moment(new Date(), MONTH_YEAR_FORMAT));
                break;
            case 1:
                setSelectedWeek(moment(new Date(), MONTH_YEAR_FORMAT));
                break;
            case 2:
                setSelectedMonth(moment(new Date(), MONTH_YEAR_FORMAT));
                break;
            default:
                return;
        }
    }, [listType, setSelectedDay, setSelectedMonth, setSelectedWeek])



    useEffect(() => {

        const eventList = async () => {
            const response = await new ApiHandler().getCalendarEntries();
            let list = response?.data?.data || []

            try {
                list?.sort(function (a, b) {
                    return new Date(a.start) - new Date(b.start);
                });
            } catch (error) {

                const objectList = Object.values(list);
                list = [...objectList];
                list?.sort?.(function (a, b) {
                    return new Date(a.start) - new Date(b.start);
                });
            }


            const events = [];

            const today = moment(moment().format("YYYY-MM-DD"));
            for (let i = 0; i < list.length; i++) {
                const item = list[i];

                const { start } = item;

                const momentStart = moment(moment(start).format("YYYY-MM-DD"));

                const diff = momentStart.diff(today, "days");

                item["day"] = diff;
                item["start_date"] = moment(start).format("YYYY-MM-DD")
                events.push(item);


            }


            setEventsList(events);
        }
        if (!isWeb && isFocused || isWeb && screenSize <= ScreenSize.Medium && isFocused) {
            eventList();
        }

        if (isWeb && screenSize >= ScreenSize.Medium) {

            if (reloading) {
                setReloading(false);
                eventList();
            }
        }

    }, [isFocused, reloading])


    const Header = () => {
        return (

            <View style={{ flexDirection: "row", justifyContent: "space-between", zIndex: 1 }} >
                <View style={{ flexDirection: "row" }} >
                    <Text style={[styles.screenTitle, isMobile && styles.mobScreenTitle]} >{"Calendar"}</Text>
                </View>

            </View>
        )
    }

    const Divider = ({ horizontal = false }) => {
        return <View
            style={horizontal ? styles.horizontalDivider : styles.divider}
        />
    }

    const DayEvents = () => {
        return (
            <View style={{ flexDirection: "row", alignItems: "center", }} >
                <TouchableOpacity
                    onPress={() => {

                        const _moment = moment(selectedDay).subtract(1, "day")
                        setSelectedDay(_moment);

                    }}
                    style={styles.arrowBtnPadd}

                >

                    <Entypo
                        name="chevron-left"
                        size={isMobile ? 20 : 24}
                        color={theme?.basics[900]}
                    />
                </TouchableOpacity>

                <Divider horizontal={true} />
                <TouchableOpacity
                    onPress={() => {

                        const _moment = moment(selectedDay).add(1, "day")
                        setSelectedDay(_moment);
                    }}
                    style={styles.arrowBtnPadd}
                >

                    <Entypo
                        name="chevron-right"
                        size={isMobile ? 20 : 24}
                        color={theme?.basics[900]}
                    />
                </TouchableOpacity>
                <Text
                    style={
                        [
                            styles.monthTitle,
                            { width: 250 },
                            isMobile && { width: width / 2, fontSize: 18, }
                        ]
                    }
                >
                    {`${moment(selectedDay).format(YEAR_DATE_FORMAT)}`}
                </Text>
            </View>
        )
    }
    const WeekEvents = () => {

        const _weekEndDate = moment(selectedWeek).endOf("isoWeek");

        return (
            <View style={{ flexDirection: "row", alignItems: "center", }} >
                <TouchableOpacity
                    onPress={() => {

                        const _moment = moment(selectedWeek).subtract(1, "weeks")
                        setSelectedWeek(_moment);

                    }}
                    style={styles.arrowBtnPadd}
                >

                    <Entypo
                        name="chevron-left"
                        size={isMobile ? 20 : 24}
                        color={theme?.basics[900]}
                    />
                </TouchableOpacity>

                <Divider horizontal={true} />
                <TouchableOpacity
                    onPress={() => {

                        const _moment = moment(selectedWeek).add(1, "weeks")
                        setSelectedWeek(_moment);
                    }}
                    style={styles.arrowBtnPadd}
                >

                    <Entypo
                        name="chevron-right"
                        size={isMobile ? 20 : 24}
                        color={theme?.basics[900]}
                    />
                </TouchableOpacity>
                <Text
                    style={
                        [
                            styles.monthTitle,
                            { width: 350 },
                            isMobile && { fontSize: 18, width: width / 2 }
                        ]
                    }
                >
                    {`Week Ending  ${isMobile ? "\n" : ""}${_weekEndDate.format(YEAR_DATE_FORMAT)}`}
                </Text>
            </View>
        )
    }

    const MonthEvents = () => {

        return (
            <View style={{ flexDirection: "row", alignItems: "center", }} >
                <TouchableOpacity
                    onPress={() => {

                        const _moment = moment(selectedMonth, MONTH_YEAR_FORMAT).subtract(1, "months")
                        setSelectedMonth(_moment);
                    }}
                    style={styles.arrowBtnPadd}
                >

                    <Entypo
                        name="chevron-left"
                        size={isMobile ? 20 : 24}
                        color={theme?.basics[900]}
                    />
                </TouchableOpacity>

                <Divider horizontal={true} />
                <TouchableOpacity
                    onPress={() => {

                        const _moment = moment(selectedMonth, MONTH_YEAR_FORMAT).add(1, "months")
                        setSelectedMonth(_moment);
                    }}
                    style={styles.arrowBtnPadd}
                >

                    <Entypo
                        name="chevron-right"
                        size={isMobile ? 20 : 24}
                        color={theme?.basics[900]}
                    />
                </TouchableOpacity>
                <Text
                    style={
                        [
                            styles.monthTitle,
                            { width: 250 },
                            isMobile && { fontSize: 18, width: width / 2 }
                        ]
                    }
                >

                    {moment(selectedMonth).format(MONTH_YEAR_FORMAT)}
                </Text>
            </View>
        )
    }


    const WeekView = () => {
        const currentMoment = moment.parseZone(selectedWeek);

        const _weekStartDate = currentMoment.startOf('isoWeek');

        const weekStartDate = _weekStartDate.get("date");

        currentMoment.set("date", weekStartDate);

        const weekDays = [];
        for (let i = 0; i <= 6; i++) {

            const data = {
                date: currentMoment.format("DD"),
                day: currentMoment.format("ddd")
            }

            weekDays.push(data);
            currentMoment.add(1, "day");
        }

        return (
            <>
                <View style={[{ flexDirection: "row", marginTop: 20 }, isMobile && { marginTop: 25, marginBottom: 15 }]} >

                    <View style={[
                        isWeb ? styles.webDayHourBox : isWeb && screenSize <= ScreenSize.Medium ? styles.dayHourBox : !isWeb && styles.dayHourBox,
                        {
                            minWidth: 48, maxWidth: 60,
                        }

                    ]} />
                    {
                        weekDays.map((item, i) => {
                            return (
                                <View key={i} style={[
                                    // { minHeight: 48, maxHeight: 48 },
                                    // isWeb ? styles.webDayHourBox : styles.dayHourBox,
                                    styles.webDayHourBox,
                                    { marginTop: -10 }
                                ]}
                                >
                                    <Text
                                        style={[
                                            styles.weekViewText,
                                            { textTransform: 'uppercase', fontSize: 12, marginBottom: 5 },
                                            i === weekday - 1 && { color: COLOR_PRIMARY }
                                        ]}
                                    >
                                        {`${item.day}`}
                                    </Text>
                                    <View style={[{ width: 40, height: 40, borderRadius: 50, alignItems: 'center', justifyContent: 'center' }, i === weekday - 1 && { backgroundColor: COLOR_PRIMARY }]}>
                                        <Text style={[{ fontSize: 20 }, i === weekday - 1 && { color: 'white' }]}>
                                            {item.date}
                                        </Text>
                                    </View>
                                </View>
                            )
                        })
                    }

                </View>
            </>
        )
    }

    const DayHoursSlots = React.useMemo(() => {

        const currentMoment = moment.parseZone(selectedWeek);


        const weekday = currentMoment.isoWeekday();

        const _weekStartDate = currentMoment.startOf('isoWeek');

        const weekStartDate = _weekStartDate.get("date");

        currentMoment.set("date", weekStartDate);


        const FILTER_FORMAT = "YYYY-MM-DD";


        const numberOfDays = 7;
        const dayHours = [];

        for (let i = 0; i < 24; i++) {
            let hour = i;

            if (hour == 0) {
                hour = `12 AM`;
            } else if (hour < 12) {
                hour = `${i} AM`;
            } else if (hour == 12) {
                hour = `${i} PM`;
            } else if (hour >= 12) {
                hour = `${i % 12} PM`;
            }

            dayHours.push(hour);

        }


        return isMobile ? (
            <View style={{ marginTop: 10, backgroundColor: "#fff" }} >
                <View style={{ flexDirection: "row" }} >

                    <View>
                        {
                            dayHours.map((item, i) => {
                                return (
                                    <View
                                        key={i}
                                        style={[
                                            styles.dayHourBoxContainer,
                                            [isWeb ? styles.webDayHourBox : isWeb && screenSize <= ScreenSize.Medium ? styles.dayHourBox : !isWeb && styles.dayHourBox],
                                            {
                                                minHeight: 48, maxHeight: 48,
                                                borderWidth: 1,
                                                borderColor: UIColors.border,
                                            },
                                        ]}
                                    >
                                        <Text style={styles.dayHoursText} >
                                            {`${item}`}
                                        </Text>

                                    </View>
                                )
                            })
                        }
                        {!isWeb || !isWeb && screenSize <= ScreenSize.Medium && <View style={{ height: 70 }} />}
                    </View>



                    {(
                        () => {
                            const dayMoment = moment.parseZone(selectedWeek);

                            const _weekStartDate = dayMoment.startOf('isoWeek');

                            const weekStartDate = _weekStartDate.get("date");

                            dayMoment.set("date", weekStartDate);

                            return new Array(numberOfDays).fill("").map((item, j) => {

                                const today = moment.parseZone(dayMoment).format(FILTER_FORMAT);

                                const thisDayEvents = eventsList.filter(event => {


                                    if (event.start_date == today) {
                                        return event
                                    } else if (moment.parseZone(event.end).format(FILTER_FORMAT) == today) {
                                        return event
                                    }
                                })

                                const events = []
                                for (let y = 0; y < thisDayEvents.length; y++) {
                                    const { start, end } = thisDayEvents[y];

                                    const startHour = Number(moment.parseZone(start).format("H"))
                                    const endHour = Number(moment.parseZone(end).format("H"))


                                    const startDate = moment.parseZone(start).format(FILTER_FORMAT);
                                    const endDate = moment.parseZone(end).format(FILTER_FORMAT);


                                    const eventSlots = [];

                                    if (startDate == endDate) {

                                        if (endHour !== startHour) {
                                            const meetingEndMinutes = moment.parseZone(end).format("m");

                                            if (meetingEndMinutes > 0) {
                                                // check
                                                let x = startHour;
                                                while (x <= endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            } else {

                                                let x = startHour;
                                                while (x < endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            }



                                        } else {
                                            eventSlots.push(startHour);
                                        }

                                    } else {

                                        if (today === startDate) {

                                            let x = startHour;
                                            while (x < 24) {
                                                eventSlots.push(x);
                                                x++
                                            }

                                        } else if (today === endDate) {
                                            const meetingEndMinutes = moment.parseZone(end).format("m");
                                            if (meetingEndMinutes > 0) {

                                                let x = 0;
                                                while (x <= endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            } else {

                                                let x = 0;
                                                while (x < endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            }
                                        }
                                    }

                                    events.push({

                                        eventSlots,
                                        ...thisDayEvents[y]
                                    })
                                }


                                dayMoment.add(1, "day");


                                return (
                                    <View
                                        key={j}
                                        style={{ flex: 1 }}
                                    >
                                        {
                                            dayHours.map((item, i) => {
                                                const slotEvents = []

                                                let next = false;
                                                let last = false;


                                                if (events.length) {
                                                    for (let q = 0; q < events.length; q++) {
                                                        const { eventSlots = [], title, id } = events[q];

                                                        if (eventSlots.includes(i)) {

                                                            slotEvents.push(events[q])

                                                        }
                                                        if ((i + 1) != undefined) {
                                                            if (eventSlots.includes(i + 1)) {

                                                                next = true
                                                            }
                                                        }

                                                        if ((i - 1) != undefined) {
                                                            if (eventSlots.includes(i - 1)) {

                                                                last = true
                                                            }
                                                        }

                                                    }

                                                }



                                                return (
                                                    <View
                                                        key={i}
                                                        style={
                                                            [
                                                                { minHeight: 48, maxHeight: 48 },
                                                                styles.dayHourBoxContainer,
                                                                [isWeb ? styles.webDayHourBox : isWeb && screenSize <= ScreenSize.Medium ? styles.webDayHourBox : !isWeb && styles.webDayHourBox],
                                                                { borderColor: UIColors.border, borderWidth: 1 },
                                                                j == numberOfDays - 1 && { borderRightWidth: 0 },

                                                            ]

                                                        }
                                                    >
                                                        {
                                                            slotEvents.length > 0
                                                            &&
                                                            slotEvents.map((_evnt, key) => {
                                                                return (
                                                                    <TouchableOpacity
                                                                        key={key}
                                                                        activeOpacity={1}
                                                                        onPress={() => {
                                                                            if (isWeb && screenSize >= ScreenSize.Medium) {
                                                                                setViewEventId(_evnt.id)
                                                                                showViewEventModal(true)
                                                                            } else {
                                                                                navigation.navigate("EventDetail", { id: _evnt.id });
                                                                            }
                                                                        }}

                                                                        // check

                                                                        style={_evnt.eventSlots?.length > 1 ? {
                                                                            height: isLastEventSlotItem(_evnt, item) ? 40 : 48,
                                                                            borderTopStartRadius: isFirstEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            borderTopEndRadius: isFirstEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            borderBottomStartRadius: isLastEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            borderBottomEndRadius: isLastEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            width: 48,
                                                                            top: 0,
                                                                            left: 0,
                                                                            position: 'absolute',
                                                                            borderRadius: 0,
                                                                            paddingHorizontal: 4,
                                                                            marginHorizontal: 2,
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: "center",
                                                                            backgroundColor: COLOR_PRIMARY,
                                                                        } : {
                                                                            height: 10,
                                                                            backgroundColor: COLOR_PRIMARY,
                                                                            marginTop: key != 0 ? 1 : 0,
                                                                            width: "100%",
                                                                            borderRadius: 2,
                                                                            alignItems: "center"

                                                                        }}
                                                                    >
                                                                        {median(_evnt?.eventSlots) == formatTime(item) && (
                                                                            <Text style={{ fontSize: 9, color: "#fff" }} >{_evnt?.title}</Text>
                                                                        )}
                                                                    </TouchableOpacity>
                                                                )
                                                            })

                                                        }
                                                    </View>
                                                )
                                            })
                                        }

                                    </View>
                                )
                            })

                        }
                    )()

                    }
                </View>
            </View >
        ) : (
            <View style={[{ marginTop: 10, backgroundColor: "#fff" }]} >
                <View style={{ flexDirection: "row" }} >

                    <View>
                        {
                            dayHours.map((item, i) => {
                                return (
                                    <View
                                        key={i}
                                        style={[
                                            styles.dayHourBoxContainer,
                                            [isWeb ? styles.webDayHourBox : isWeb && screenSize <= ScreenSize.Medium ? styles.dayHourBox : !isWeb && styles.dayHourBox],
                                            {
                                                minHeight: 48, maxHeight: 48,
                                            },
                                        ]}
                                    >
                                        <Text style={[styles.dayHoursText]} >
                                            {`${item}`}
                                        </Text>
                                    </View>
                                )
                            })
                        }
                        {!isWeb || !isWeb && screenSize <= ScreenSize.Medium && <View style={{ height: 70 }} />}
                    </View>



                    {(
                        () => {
                            const dayMoment = moment.parseZone(selectedWeek);

                            const _weekStartDate = dayMoment.startOf('isoWeek');

                            const weekStartDate = _weekStartDate.get("date");

                            dayMoment.set("date", weekStartDate);

                            return new Array(numberOfDays).fill("").map((item, j) => {

                                const today = moment.parseZone(dayMoment).format(FILTER_FORMAT);

                                const thisDayEvents = eventsList.filter(event => {


                                    if (event.start_date == today) {
                                        return event
                                    } else if (moment.parseZone(event.end).format(FILTER_FORMAT) == today) {
                                        return event
                                    }
                                })

                                const events = []
                                for (let y = 0; y < thisDayEvents.length; y++) {
                                    const { start, end } = thisDayEvents[y];

                                    const startHour = Number(moment.parseZone(start).format("H"))
                                    const endHour = Number(moment.parseZone(end).format("H"))


                                    const startDate = moment.parseZone(start).format(FILTER_FORMAT);
                                    const endDate = moment.parseZone(end).format(FILTER_FORMAT);


                                    const eventSlots = [];

                                    if (startDate == endDate) {

                                        if (endHour !== startHour) {
                                            const meetingEndMinutes = moment.parseZone(end).format("m");

                                            if (meetingEndMinutes > 0) {

                                                let x = startHour;
                                                while (x <= endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            } else {

                                                let x = startHour;
                                                while (x < endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            }



                                        } else {
                                            eventSlots.push(startHour);
                                        }

                                    } else {

                                        if (today === startDate) {

                                            let x = startHour;
                                            while (x < 24) {
                                                eventSlots.push(x);
                                                x++
                                            }

                                        } else if (today === endDate) {
                                            const meetingEndMinutes = moment.parseZone(end).format("m");
                                            if (meetingEndMinutes > 0) {

                                                let x = 0;
                                                while (x <= endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            } else {

                                                let x = 0;
                                                while (x < endHour) {
                                                    eventSlots.push(x);
                                                    x++
                                                }
                                            }
                                        }
                                    }

                                    events.push({

                                        eventSlots,
                                        ...thisDayEvents[y]
                                    })
                                }


                                dayMoment.add(1, "day");


                                return (
                                    <View
                                        key={j}
                                        style={{ flex: 1 }}
                                    >
                                        {
                                            dayHours.map((item, i) => {
                                                const slotEvents = []

                                                let next = false;
                                                let last = false;


                                                if (events.length) {
                                                    for (let q = 0; q < events.length; q++) {
                                                        const { eventSlots = [], title, id } = events[q];

                                                        if (eventSlots.includes(i)) {

                                                            slotEvents.push(events[q])

                                                        }
                                                        if ((i + 1) != undefined) {
                                                            if (eventSlots.includes(i + 1)) {

                                                                next = true
                                                            }
                                                        }

                                                        if ((i - 1) != undefined) {
                                                            if (eventSlots.includes(i - 1)) {

                                                                last = true
                                                            }
                                                        }

                                                    }

                                                }



                                                return (
                                                    <View
                                                        key={i}
                                                        style={
                                                            [
                                                                { minHeight: 48, maxHeight: 48, borderTopWidth: 0.25, borderBottomWidth: 0.25 },
                                                                styles.dayHourBoxContainer,
                                                                [isWeb ? styles.webDayHourBox : styles.dayHourBox],
                                                                [isWeb ? styles.webDayHourBox : isWeb && screenSize <= ScreenSize.Medium ? styles.webDayHourBox : !isWeb && styles.webDayHourBox],
                                                                isMobile && { width: 52, height: 48, borderWidth: 1, borderColor: UIColors.border },
                                                                j == numberOfDays - 1 && { borderRightWidth: 0 },
                                                                { position: 'relative' },

                                                                // j === weekday - 1 &&
                                                                // {

                                                                //     // borderRightWidth: 1,
                                                                //     // borderRightColor: COLOR_PRIMARY,

                                                                //     // borderLeftWidth: 1,
                                                                //     // borderLeftColor: COLOR_PRIMARY,
                                                                // },
                                                                // i == 0
                                                                // &&
                                                                // j === weekday - 1
                                                                // &&
                                                                // {
                                                                //     borderTopWidth: 1,
                                                                //     borderTopColor: COLOR_PRIMARY,
                                                                // },
                                                                // i == dayHours.length - 1
                                                                // &&
                                                                // j === weekday - 1
                                                                // &&
                                                                // {
                                                                //     borderBottomWidth: 1,
                                                                //     borderBottomColor: COLOR_PRIMARY,
                                                                // },

                                                            ]

                                                        }
                                                    >
                                                        {
                                                            slotEvents.length > 0
                                                            &&
                                                            slotEvents.map((_evnt, key) => {
                                                                return isMobile ? (
                                                                    <TouchableOpacity
                                                                        key={key}
                                                                        onPress={() => { navigation.navigate("EventDetail", { id: _evnt.id }) }}
                                                                        // check
                                                                        style={{
                                                                            height: isLastEventSlotItem(_evnt, item) ? 40 : 48,
                                                                            borderTopStartRadius: isFirstEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            borderTopEndRadius: isFirstEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            borderBottomStartRadius: isLastEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            borderBottomEndRadius: isLastEventSlotItem(_evnt, item) ? 4 : 0,
                                                                            width: 120,
                                                                            top: -20,
                                                                            left: -60,
                                                                            position: 'absolute',
                                                                            borderRadius: 0,
                                                                            paddingHorizontal: 4,
                                                                            marginHorizontal: 2,
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: "center",
                                                                            backgroundColor: COLOR_PRIMARY,
                                                                        }}
                                                                    >
                                                                        {median(_evnt?.eventSlots) == formatTime(item) && (
                                                                            <Text numberOfLines={1} style={{ fontSize: 9, color: "#fff" }} >{_evnt?.title}</Text>
                                                                        )}
                                                                    </TouchableOpacity>
                                                                ) : (
                                                                    <Menu>
                                                                        <MenuTrigger>
                                                                            <TouchableOpacity
                                                                                key={key}
                                                                                disabled={Platform.OS === 'web'}
                                                                                // onPress={() => alert(JSON.stringify(item))}
                                                                                hitSlop={{ top: 100, bottom: 100 }}
                                                                                style={_evnt.eventSlots?.length > 1 ? {
                                                                                    height: isLastEventSlotItem(_evnt, item) ? 40 : 48,
                                                                                    borderTopStartRadius: isFirstEventSlotItem(_evnt, item) ? 4 : 0,
                                                                                    borderTopEndRadius: isFirstEventSlotItem(_evnt, item) ? 4 : 0,
                                                                                    borderBottomStartRadius: isLastEventSlotItem(_evnt, item) ? 4 : 0,
                                                                                    borderBottomEndRadius: isLastEventSlotItem(_evnt, item) ? 4 : 0,
                                                                                    width: 120,
                                                                                    top: -20,
                                                                                    left: -60,
                                                                                    position: 'absolute',
                                                                                    borderRadius: 0,
                                                                                    paddingHorizontal: 4,
                                                                                    marginHorizontal: 2,
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: "center",
                                                                                    backgroundColor: COLOR_PRIMARY,
                                                                                } : {
                                                                                    height: 20,
                                                                                    paddingHorizontal: 4,
                                                                                    marginHorizontal: 2,
                                                                                    borderRadius: 4,
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: "center",
                                                                                    borderWidth: 1,
                                                                                    borderColor: COLOR_PRIMARY,
                                                                                    backgroundColor: COLOR_PRIMARY
                                                                                }}
                                                                            >
                                                                                {median(_evnt?.eventSlots) == formatTime(item) && (
                                                                                    <Text numberOfLines={1} style={{ fontSize: 11, color: "#fff" }} >{_evnt?.title}</Text>
                                                                                )}
                                                                            </TouchableOpacity>
                                                                        </MenuTrigger>
                                                                        <MenuOptions customStyles={{
                                                                            optionsWrapper: {
                                                                                width: width / 3
                                                                            },
                                                                            optionsContainer: {
                                                                                height: height - (height * 0.1),
                                                                                marginTop: height / 21.5,
                                                                                width: width / 3,
                                                                                shadowColor: "#ccc",
                                                                                shadowOffset: { width: 3, height: 3 },
                                                                                shadowOpacity: 0.9,
                                                                                shadowRadius: 10,
                                                                                borderRadius: 8,
                                                                            }
                                                                        }}>
                                                                            <EventDetail
                                                                                id={_evnt.id}
                                                                                navigation={navigation}
                                                                                setReloading={setReloading}
                                                                                setShowModal={showViewEventModal}
                                                                                showDuplicateModal={showDuplicateModal}
                                                                                showEditModal={showEditModal}
                                                                            />
                                                                        </MenuOptions>
                                                                    </Menu>
                                                                )
                                                            })

                                                        }
                                                    </View>
                                                )
                                            })
                                        }

                                    </View>
                                )
                            })

                        }
                    )()

                    }
                </View>
            </View >
        )

    }, [eventsList, eventsList.length, selectedWeek])

    const DayView = () => {

        const FILTER_FORMAT = "YYYY-MM-DD"
        const today = moment(selectedDay).format(FILTER_FORMAT);

        const todayEvents = eventsList.filter(item => {

            if (item.start_date == today) {
                return item
            } else if (moment(item.end).format(FILTER_FORMAT) == today) {
                return item
            }
        })

        const eventTimes = []
        for (let i = 0; i < todayEvents.length; i++) {
            const { start, end } = todayEvents[i];

            const startHour = Number(moment.parseZone(start).format("H"))
            const endHour = Number(moment.parseZone(end).format("H"))


            const startDate = moment.parseZone(start).format(FILTER_FORMAT);
            const endDate = moment.parseZone(end).format(FILTER_FORMAT);


            const eventSlots = [];


            if (startDate == endDate) {

                if (endHour !== startHour) {
                    const meetingEndMinutes = moment.parseZone(end).format("m");

                    if (meetingEndMinutes > 0) {

                        let x = startHour;
                        while (x <= endHour) {
                            eventSlots.push(x);
                            x++
                        }
                    } else {

                        let x = startHour;
                        while (x < endHour) {
                            eventSlots.push(x);
                            x++
                        }
                    }



                } else {
                    eventSlots.push(startHour);
                }

            } else {

                if (today === startDate) {

                    let x = startHour;
                    while (x < 24) {
                        eventSlots.push(x);
                        x++
                    }

                } else if (today === endDate) {
                    const meetingEndMinutes = moment.parseZone(end).format("m");
                    if (meetingEndMinutes > 0) {

                        let x = 0;
                        while (x <= endHour) {
                            eventSlots.push(x);
                            x++
                        }
                    } else {

                        let x = 0;
                        while (x < endHour) {
                            eventSlots.push(x);
                            x++
                        }
                    }
                }
            }

            eventTimes.push({

                eventSlots,
                ...todayEvents[i]
            })
        }


        const dayHours = [];
        for (let i = 0; i < 24; i++) {

            let hour = i;

            if (hour == 0) {
                hour = `12 AM`;
            } else if (hour < 12) {
                hour = `${i} AM`;
            } else if (hour == 12) {
                hour = `${i} PM`;
            } else if (hour >= 12) {
                hour = `${i % 12} PM`;
            }
            const event = eventTimes.filter(item => {
                return item.eventSlots.includes(i);
            })

            const dataObj = { hour }
            if (event?.length) {
                dataObj['events'] = event
            }
            dayHours.push(dataObj);

        }

        return (

            <View style={{ backgroundColor: "#fff", paddingBottom: isWeb && screenSize >= ScreenSize.Medium ? 0 : isWeb && screenSize <= ScreenSize.Medium ? 70 : !isWeb && 70, marginTop: !isWeb && 25 }} >
                <View style={[{ flexDirection: 'row', height: isWeb ? 100 : 70, alignItems: 'center' }, isMobile && { borderBottomWidth: 1, borderBottomColor: UIColors.border }]}>

                    <View style={{ marginLeft: 10, marginBottom: 5, alignItems: 'center' }}>
                        <Text style={[{ fontSize: 12, color: COLOR_PRIMARY, textTransform: 'uppercase' }, isMobile && { fontSize: 10 }]}>{moment(selectedDay).format("ddd")}</Text>
                        <View style={[{ marginTop: 3, backgroundColor: COLOR_PRIMARY, width: 50, height: 50, borderRadius: 50, alignItems: 'center', justifyContent: 'center' }, isMobile && { width: 30, height: 30, marginTop: 0 }]}>
                            <Text style={[{ fontSize: 25, color: 'white', fontWeight: '500' }, isMobile && { fontSize: 18 }]}>{moment(selectedDay).format("D")}</Text>
                        </View>
                    </View>
                </View>
                <FlatList
                    data={dayHours}
                    keyExtractor={item => item?.hour}
                    scrollEnabled={Platform.OS !== "web"}
                    renderItem={({ item, index }) => {

                        return (
                            <View
                                key={index}
                                style={[{
                                    flexDirection: "row",
                                    height: 60
                                }, isMobile && { borderBottomWidth: 1, borderBottomColor: UIColors.border }]}
                            >
                                <View

                                    style={[
                                        styles.dayHourBoxContainer,
                                        styles.dayHourBox
                                    ]}
                                >
                                    <Text style={[styles.dayHoursText]} >
                                        {`${item?.hour}`}
                                    </Text>


                                </View>

                                <View
                                    style={[
                                        {
                                            flex: 1,
                                            borderTopColor: UIColors.border,
                                            borderTopWidth: 0.25,
                                            position: 'relative'
                                        },

                                        index == dayHours.length - 1
                                        &&
                                        {
                                            borderBottomColor: UIColors.border,
                                            borderBottomWidth: 0.25,
                                        }
                                    ]}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            paddingVertical: 10
                                        }}
                                    >
                                        {
                                            item.events?.map((event, index) => {

                                                return isMobile ? (
                                                    <TouchableOpacity
                                                        key={event.id}
                                                        disabled={Platform.OS === "web"}
                                                        onPress={() => { navigation.navigate("EventDetail", { id: event.id }) }}
                                                        activeOpacity={1}
                                                        style={[
                                                            event?.eventSlots?.length > 1 ? {
                                                                height: isLastEventSlotItem(event, item) ? 40 : 48,
                                                                borderTopStartRadius: isFirstEventSlotItem(event, item) ? 4 : 0,
                                                                borderTopEndRadius: isFirstEventSlotItem(event, item) ? 4 : 0,
                                                                borderBottomStartRadius: isLastEventSlotItem(event, item) ? 4 : 0,
                                                                borderBottomEndRadius: isLastEventSlotItem(event, item) ? 4 : 0,
                                                                width: 120,
                                                                top: 3,
                                                                position: 'absolute',
                                                                borderRadius: 0,
                                                                paddingHorizontal: 4,
                                                                marginHorizontal: 2,
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: "center",
                                                                backgroundColor: COLOR_PRIMARY
                                                            } :
                                                                {
                                                                    height: 20,
                                                                    paddingHorizontal: 4,
                                                                    marginHorizontal: 2,
                                                                    borderRadius: 4,
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: "center",
                                                                    borderWidth: 1,
                                                                    borderColor: COLOR_PRIMARY,
                                                                    backgroundColor: COLOR_PRIMARY
                                                                }]}
                                                    >
                                                        {median(event?.eventSlots) == formatTime(item.hour) && (
                                                            <Text style={[styles.dayHoursSlotText, { color: "white" }]} >
                                                                {`${event?.title}`}
                                                            </Text>
                                                        )}

                                                    </TouchableOpacity>
                                                ) : (
                                                    <Menu>
                                                        <MenuTrigger>
                                                            <TouchableOpacity
                                                                key={event.id}
                                                                disabled
                                                                onPress={() => { navigation.navigate("EventDetail", { id: event.id }) }}
                                                                activeOpacity={1}
                                                                style={[
                                                                    event?.eventSlots?.length > 1 ? {
                                                                        height: isLastEventSlotItem(event, item) ? 40 : 48,
                                                                        borderTopStartRadius: isFirstEventSlotItem(event, item) ? 4 : 0,
                                                                        borderTopEndRadius: isFirstEventSlotItem(event, item) ? 4 : 0,
                                                                        borderBottomStartRadius: isLastEventSlotItem(event, item) ? 4 : 0,
                                                                        borderBottomEndRadius: isLastEventSlotItem(event, item) ? 4 : 0,
                                                                        width: 120,
                                                                        top: isMobile ? -6 : undefined,
                                                                        position: 'absolute',
                                                                        borderRadius: 0,
                                                                        paddingHorizontal: 4,
                                                                        marginHorizontal: 2,
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: "center",
                                                                        backgroundColor: COLOR_PRIMARY
                                                                    } :
                                                                        {
                                                                            height: 20,
                                                                            paddingHorizontal: 4,
                                                                            marginHorizontal: 2,
                                                                            borderRadius: 4,
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            justifyContent: "center",
                                                                            borderWidth: 1,
                                                                            borderColor: COLOR_PRIMARY,
                                                                            backgroundColor: COLOR_PRIMARY
                                                                        }]}
                                                            >
                                                                {median(event?.eventSlots) == formatTime(item.hour) && (
                                                                    <Text style={[styles.dayHoursSlotText, { color: "white" }]} >
                                                                        {`${event?.title}`}
                                                                    </Text>
                                                                )}

                                                            </TouchableOpacity>
                                                        </MenuTrigger>
                                                        <MenuOptions customStyles={{
                                                            optionsWrapper: {
                                                                width: width / 3
                                                            },
                                                            optionsContainer: {
                                                                height: height - (height * 0.1),
                                                                marginTop: 50,
                                                                width: width / 3,
                                                                shadowColor: "#ccc",
                                                                shadowOffset: { width: 3, height: 3 },
                                                                shadowOpacity: 0.9,
                                                                shadowRadius: 10,
                                                                borderRadius: 8,
                                                            }
                                                        }}>
                                                            <EventDetail
                                                                id={event.id}
                                                                navigation={navigation}
                                                                setReloading={setReloading}
                                                                setShowModal={showViewEventModal}
                                                                showDuplicateModal={showDuplicateModal}
                                                                showEditModal={showEditModal}
                                                            />
                                                        </MenuOptions>
                                                    </Menu>
                                                )
                                            })



                                        }

                                    </View>
                                </View>

                            </View>
                        )
                    }}

                />

            </View>

        )
    }

    const MonthView = () => {

        const yearMonthFormat = "YYYY-MM"

        const yearAndMonth = moment(selectedMonth).format(yearMonthFormat);
        const currentMoment = moment(yearAndMonth, yearMonthFormat);

        const daysInMonth = currentMoment.daysInMonth();

        const checkFormat = "YYYY-MM-DD";

        const getDaysArrayByMonth = () => {
            const arrDays = [];

            for (let i = 1; i <= daysInMonth; i++) {
                const current = moment(yearAndMonth, yearMonthFormat).date(i);

                const dayEvents = eventsList.filter((item => {

                    return item.start_date == current.format(checkFormat)
                }))

                arrDays.push({
                    time: current,
                    events: dayEvents?.length > 0 ? dayEvents : undefined
                });



            }

            return arrDays;
        }

        const monthDates = getDaysArrayByMonth();
        let monthStartsOn = monthDates[0]["time"].format("d");

        if (monthStartsOn == 0) {
            monthStartsOn = 7
        }

        let x = 1;
        while (x < Number(monthStartsOn)) {

            monthDates.unshift("");
            x++;
        }

        const _weekStartDate = currentMoment.startOf('isoWeek');

        const weekStartDate = _weekStartDate.get("date");

        currentMoment.set("date", weekStartDate);

        const weekDays = [];
        for (let i = 0; i <= 6; i++) {

            const data = {
                day: currentMoment.format("ddd")
            }

            weekDays.push(data);
            currentMoment.add(1, "day");
        }

        const monthCellsLength = monthDates?.length || 0;

        if (monthCellsLength % 7 != 0) {
            const itemsToAdd = 7 - monthCellsLength % 7;

            for (let x = 1; x <= itemsToAdd; x++) {
                monthDates.push("")
            }
        }


        return (
            <>
                <View
                    style={[
                        {
                            flexDirection: "row",
                            marginTop: 20,
                            width: '100%'
                        },
                        // isWeb && { justifyContent: "center" }
                    ]}
                >


                    {
                        weekDays.map((item, i) => {
                            return (
                                <View
                                    key={i}
                                    style={[isWeb && screenSize >= ScreenSize.Medium ? styles.webMonthDayBox : isWeb && screenSize <= ScreenSize.Medium ? styles.monthDayBox : !isWeb && styles.monthDayBox]}
                                >
                                    <Text
                                        style={[
                                            styles.weekViewText,
                                            { textTransform: 'uppercase', fontSize: 14 },
                                            i === weekday - 1 && { color: COLOR_PRIMARY }
                                        ]}
                                    >
                                        {`${item.day}`}
                                    </Text>
                                </View>
                            )
                        })
                    }

                </View>

                <FlatList
                    data={monthDates}
                    keyExtractor={item => item?.time ? moment(item?.time) : new Date().getTime()}
                    renderItem={({ item, index }) => {


                        // const showBottomBorder = Number(index) + 7 > daysInMonth;
                        const showBottomBorder = true
                        const dayEvents = item?.events?.length > 0 ? item?.events : []

                        return (

                            <View
                                key={new Date().getTime()}
                                style={
                                    [
                                        { overflow: 'hidden' },
                                        isWeb && screenSize >= ScreenSize.Medium ? styles.webMonthDayBox : isWeb && screenSize <= ScreenSize.Medium ? styles.monthDayBox : !isWeb && styles.monthDayBox,
                                        {
                                            paddingTop: 4,
                                            height: 100,
                                            borderWidth: 1,
                                            borderColor: UIColors.border,

                                        },
                                        moment(monthDates[index + 7]).isValid()
                                        &&
                                        {
                                            borderBottomWidth: 0,
                                            borderRightWidth: 0
                                        },
                                        showBottomBorder
                                        &&
                                        {
                                            borderBottomWidth: 1,
                                            borderRightWidth: 1
                                        }

                                    ]
                                }
                            >
                                <View style={[{ width: 40, height: 40, alignItems: 'center', justifyContent: 'center', borderRadius: 50 }, item.time && moment(item.time).format(YEAR_DATE_FORMAT) == moment(new Date()).format(YEAR_DATE_FORMAT) && { backgroundColor: COLOR_PRIMARY, marginBottom: 10, }]}>
                                    <Text
                                        style={[
                                            styles.weekViewText,
                                            item.time && moment(item.time).format(YEAR_DATE_FORMAT) == moment(new Date()).format(YEAR_DATE_FORMAT) && { color: 'white' }
                                        ]}
                                    >
                                        {
                                            (
                                                () => {
                                                    if (item?.time && moment(item?.time).isValid())
                                                        return `${item?.time?.format?.("D")}`

                                                    return ""
                                                }
                                            )()
                                        }
                                    </Text>
                                </View>
                                {
                                    dayEvents.map((eventInfo, key) => {
                                        return isMobile ? (
                                            <TouchableOpacity
                                                disabled={Platform.OS === "web"}
                                                key={eventInfo.id}
                                                onPress={() => Platform.OS !== "web" && navigation.navigate("EventDetail", { id: eventInfo.id })}
                                                style={{
                                                    backgroundColor: COLOR_PRIMARY,
                                                    marginTop: key != 0 ? 1 : 0,
                                                    width: width / 7.5,
                                                    borderRadius: 2,
                                                    alignItems: "center",
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Text numberOfLines={2} style={{ fontSize: 10, color: "#fff" }} >{isWeb && moment(eventInfo?.start).format("hh:mm a")} {eventInfo?.title}</Text>
                                            </TouchableOpacity>
                                        ) : (
                                            <Menu>
                                                <MenuTrigger>
                                                    <TouchableOpacity
                                                        disabled={Platform.OS === "web"}
                                                        key={eventInfo.id}
                                                        onPress={() => Platform.OS !== "web" && navigation.navigate("EventDetail", { id: eventInfo.id })}
                                                        style={{
                                                            height: 12,
                                                            backgroundColor: COLOR_PRIMARY,
                                                            marginTop: key != 0 ? 3 : 0,
                                                            width: isMobile ? width / 8 : width / 9,
                                                            borderRadius: 4,
                                                            alignItems: "center",
                                                            flexDirection: 'row',
                                                            paddingHorizontal: 5,
                                                            paddingVertical: 10

                                                        }}
                                                    >
                                                        <Text numberOfLines={1} style={{ overflow: "hidden", fontSize: 10, color: "#fff" }} >{isWeb && moment.parseZone(eventInfo?.start).format("hh:mm a")} {eventInfo?.title}</Text>
                                                    </TouchableOpacity>
                                                </MenuTrigger>
                                                <MenuOptions customStyles={{
                                                    optionsWrapper: {
                                                    },
                                                    optionsContainer: {
                                                        height: height - (height * 0.1),
                                                        marginTop: height / 21.5,
                                                        width: width / 3,
                                                        shadowColor: "#ccc",
                                                        shadowOffset: { width: 3, height: 3 },
                                                        shadowOpacity: 0.9,
                                                        shadowRadius: 10,
                                                        borderRadius: 8,
                                                        overflow: 'scroll'
                                                    }
                                                }}>
                                                    <EventDetail
                                                        id={eventInfo.id}
                                                        navigation={navigation}
                                                        setReloading={setReloading}
                                                        setShowModal={showViewEventModal}
                                                        showDuplicateModal={showDuplicateModal}
                                                        showEditModal={showEditModal}
                                                    />
                                                </MenuOptions>
                                            </Menu>
                                        )
                                    })
                                }

                            </View>

                        )
                    }}
                    numColumns={7}
                    contentContainerStyle={[
                        !isWeb || isWeb && screenSize <= ScreenSize.Medium && {
                            paddingBottom: 60
                        },
                        // isWeb && { alignItems: "center" }
                    ]}
                />
            </>
        )
    }

    const ListView = React.useMemo(() => {

        let currentDay;

        const Event = ({ evt, index }) => {
            let going = false;

            const guest = evt.guests.find(item => item.guest_id == currentUser.id);
            const attending_statuses = guest?.guest?.calendar_attending_statuses || [];

            if (attending_statuses?.length > 0) {
                const lastAttendingStatus = attending_statuses[attending_statuses.length - 1];

                const Intvite_status = lastAttendingStatus?.calendar_entry_guest_attending_status_id;

                if (Intvite_status === ATTEND_STATUS.going) {
                    going = true;
                } else if (Intvite_status === ATTEND_STATUS.not_going) {
                    going = false;
                }
            }

            return isMobile ? (
                <TouchableOpacity
                    onPress={() => {
                        navigation.navigate("EventDetail", { id: evt.id });
                    }}
                    style={{ flexDirection: 'row', flex: 1.2, padding: 10, alignItems: 'center' }}>
                    <View style={{ flex: 3, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 }}>
                        <View style={[{ marginHorizontal: 10, width: 10, height: 10, borderRadius: 50, borderColor: COLOR_PRIMARY, borderWidth: 1 }, going && { backgroundColor: COLOR_PRIMARY }]} />
                        <Text style={{ fontSize: 11 }}>{moment(evt.start).format("a") === moment(evt.end).format("a") ? moment(evt.start).format("h:m") : moment(evt.start).format("h:ma")}-{moment(evt.end).format("mm") === 0 ? moment(evt.end).format("ha") : moment(evt.end).format("h:ma")}</Text>
                    </View>
                    {/* <View style={{ flex: 0.3, backgroundColor: "red" }} /> */}
                    <View style={{ flex: 6, flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: 12 }}>{evt.title}</Text>
                    </View >
                </TouchableOpacity >
            ) : (

                <Menu>
                    <MenuTrigger>
                        <TouchableOpacity
                            disabled
                            style={{ flexDirection: 'row', flex: 1.2, padding: 10, alignItems: 'center' }}>
                            <View style={{ flex: 2, flexDirection: 'row', alignItems: 'center', paddingHorizontal: 10 }}>
                                <View style={[{ marginHorizontal: 10, width: 10, height: 10, borderRadius: 50, borderColor: COLOR_PRIMARY, borderWidth: 1 }, going && { backgroundColor: COLOR_PRIMARY }]} />
                                <Text>{moment(evt.start).format("a") === moment(evt.end).format("a") ? moment(evt.start).format("hh:mm") : moment(evt.start).format("hh:mma")}-{moment(evt.end).format("mm") === 0 ? moment(evt.end).format("hha") : moment(evt.end).format("hh:mma")}</Text>
                            </View>
                            {/* <View style={{ flex: 0.3, backgroundColor: "red" }} /> */}
                            <View style={{ flex: 8, flexDirection: 'row' }}>
                                <Text style={{ fontWeight: '500' }}>{evt.title}</Text>
                            </View>
                        </TouchableOpacity>
                    </MenuTrigger>
                    <MenuOptions customStyles={{
                        optionsWrapper: {
                        },
                        optionsContainer: {
                            height: height - (height * 0.1),
                            marginTop: height / 21.5,
                            width: width / 3,
                            shadowColor: "#ccc",
                            shadowOffset: { width: 3, height: 3 },
                            shadowOpacity: 0.9,
                            shadowRadius: 10,
                            borderRadius: 8,
                        }
                    }}>
                        <EventDetail
                            id={evt.id}
                            navigation={navigation}
                            setReloading={setReloading}
                            setShowModal={showViewEventModal}
                            showDuplicateModal={showDuplicateModal}
                            showEditModal={showEditModal}
                        />
                    </MenuOptions>
                </Menu>
            )
        }

        const _renderListItem = ({ item, index }) => {
            const day = item.day;

            const start = moment(item.start);
            const end = moment(item.end);

            let showDayName = false;
            let dayNameStr = "";

            if (currentDay != day) {
                currentDay = day;
                showDayName = true;
                dayNameStr = `${moment(item.start).format("dddd, MMMM Do")}`

                if (day === 0) {
                    dayNameStr = `Today, ${dayNameStr}`
                } else if (day === 1) {
                    dayNameStr = `Tomorrow, ${dayNameStr}`
                }
            }

            const startEndTimeStr = `${start.format("hh:mm A")} - ${end.format("hh:mm A")}`

            return (
                <View style={{ paddingVertical: 10, flexDirection: "row", width: '100%', paddingRight: isMobile ? 10 : 0 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', flex: isMobile ? 1.5 : 1 }}>
                        {isMobile ? (
                            <View style={[{ borderRadius: 50, alignItems: 'center', justifyContent: 'center', height: 30, width: 30 }, start.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") && { backgroundColor: COLOR_PRIMARY }]}>
                                <Text style={[{ fontSize: 20, color: "black" }, start.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") && { color: "white" }]}>{start.format("DD")}</Text>
                            </View>
                        ) : (
                            <View style={[{ borderRadius: 50, alignItems: 'center', justifyContent: 'center', height: 40, width: 40 }, start.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") && { backgroundColor: COLOR_PRIMARY }]}>
                                <Text style={[{ fontSize: 24, color: "black" }, start.format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") && { color: "white" }]}>{start.format("DD")}</Text>
                            </View>
                        )}
                        <Text style={{ paddingLeft: isMobile ? 8 : 15, textTransform: 'uppercase', color: "#888", fontSize: isMobile ? 10 : 12, fontWeight: "500" }}>{start.format("MMM, ddd")}</Text>
                    </View>
                    <View style={{ paddingHorizontal: 5, flex: 8 }}>
                        {item.events?.map((evt) => (
                            <Event evt={evt} />
                        ))}
                    </View>
                </View>
            )


            // return (
            //     <>
            //         {
            //             showDayName
            //             &&
            //             <Text style={[styles.startEndTime, { marginVertical: 8 }]} >{dayNameStr}</Text>
            //         }
            //         <TouchableOpacity
            //             style={[styles.eventListItem, isWeb && screenSize >= ScreenSize.Medium ? styles.shadows : null]}
            //             key={item.id}
            //             onPress={() => {
            //                 if (isWeb && screenSize >= ScreenSize.Medium) {
            //                     setViewEventId(item.id)
            //                     showViewEventModal(true)
            //                 } else {
            //                     navigation.navigate("EventDetail", { id: item.id });
            //                 }
            //             }}
            //         >
            //             <View style={{ flexDirection: "row", justifyContent: "space-between" }} >

            //                 <Text style={styles.listItemTitle} >{item.title}</Text>
            //             </View>
            //             <Text style={[styles.startEndTime, { marginTop: 4 }]}  >{startEndTimeStr}</Text>

            //             {
            //                 (
            //                     () => {

            //                         const guestsInfo = []

            //                         for (let i = 0; i < item?.guests?.length; i++) {
            //                             if ((isWeb && screenSize <= ScreenSize.Medium) && i === 5) {
            //                                 break;

            //                             } else if (i === 6) {
            //                                 break;
            //                             }

            //                             const guest = item?.guests[i];
            //                             const guestType = guest?.guest_type;

            //                             const avatarSource =
            //                                 guest?.guest?.avatar
            //                                     ?
            //                                     guest?.guest?.avatar
            //                                     :
            //                                     guest?.guest?.image
            //                                         ?
            //                                         guest?.guest?.image
            //                                         :
            //                                         null;



            //                             let nameLbl = "";

            //                             if (guestType === INVITEE_TYPE.employee) {
            //                                 const f_name = guest?.guest?.first_name?.charAt(0).trim() || "";

            //                                 const l_name = guest?.guest?.last_name?.charAt(0).trim() || "";

            //                                 nameLbl = `${f_name}${l_name}`;
            //                             } else if (guestType === INVITEE_TYPE.group) {

            //                                 const name = guest?.guest?.name?.split(" ").map(function (item) { return item[0] }).join('');;
            //                                 nameLbl = name

            //                             } else if (guestType === INVITEE_TYPE.team) {

            //                                 const name = guest?.guest?.name?.split(" ").map(function (item) { return item[0] }).join('');;

            //                                 nameLbl = name
            //                             }


            //                             guestsInfo.push(
            //                                 {
            //                                     avatarSource,
            //                                     name: nameLbl
            //                                 }
            //                             )

            //                         }

            //                         if (isWeb && screenSize >= ScreenSize.Medium) {

            //                             if (item?.guests?.length > 5) {
            //                                 guestsInfo[4] = {
            //                                     avatarSource: guestsInfo[4].avatarSource,
            //                                     name: guestsInfo[4].name,
            //                                     more: item?.guests?.length - 5
            //                                 }
            //                             }
            //                         } else {

            //                             if (item?.guests?.length > 6) {
            //                                 guestsInfo[5] = {
            //                                     avatarSource: guestsInfo[5].avatarSource,
            //                                     name: guestsInfo[5].name,
            //                                     more: item?.guests?.length - 6
            //                                 }
            //                             }
            //                         }

            //                         return <View style={{ flexDirection: "row" }} >
            //                             {
            //                                 guestsInfo?.map((guest, i) => {

            //                                     return (
            //                                         <View
            //                                             key={i}

            //                                             style={[
            //                                                 i != 0
            //                                                 &&
            //                                                 {
            //                                                     marginLeft: 6
            //                                                 },

            //                                                 {
            //                                                     alignItems: "center",
            //                                                     justifyContent: "center",
            //                                                     width: width / 8,
            //                                                     height: width / 8,
            //                                                     borderRadius: width / 2,

            //                                                     maxWidth: 48,
            //                                                     maxHeight: 48
            //                                                 },
            //                                                 guest?.avatarSource
            //                                                     ?
            //                                                     {

            //                                                     }
            //                                                     :
            //                                                     {
            //                                                         backgroundColor: "COLOR_PRIMARY",
            //                                                     }
            //                                             ]}
            //                                         >

            //                                             {
            //                                                 guest?.avatarSource
            //                                                     ?
            //                                                     <Image
            //                                                         source={{ uri: guest?.avatarSource }}
            //                                                         style={{
            //                                                             width: width / 8,
            //                                                             height: width / 8,
            //                                                             borderRadius: 24,
            //                                                             backgroundColor: COLOR_PRIMARY,
            //                                                             maxWidth: 48,
            //                                                             maxHeight: 48
            //                                                         }}
            //                                                     />
            //                                                     :
            //                                                     !guest?.more
            //                                                     &&
            //                                                     <Text style={styles.listItemDesc} >{`${guest?.name}`}</Text>
            //                                             }
            //                                             {
            //                                                 guest?.more
            //                                                 &&
            //                                                 <View
            //                                                     style={{
            //                                                         width: width / 8,
            //                                                         height: width / 8,
            //                                                         borderRadius: width / 2,
            //                                                         maxWidth: 48,
            //                                                         maxHeight: 48,
            //                                                         borderRadius: 24,
            //                                                         backgroundColor: "#0004",
            //                                                         position: "absolute",
            //                                                         alignItems: "center",
            //                                                         justifyContent: "center",

            //                                                     }}
            //                                                 >
            //                                                     <Text style={[styles.listItemDesc, { color: "#fff" }]} >+{guest?.more}</Text>
            //                                                 </View>
            //                                             }


            //                                         </View>
            //                                     )
            //                                 })
            //                             }
            //                         </View>


            //                     }
            //                 )()
            //             }

            //         </TouchableOpacity>
            //     </>
            // )
        }

        const validEvents = () => {

            // get the list of all upcoming events
            const list = eventsList.filter(item => item.day > -1);

            // remove duplicate dates from the above list
            const c1 = list.filter((item, index, self) => self.findIndex(a => moment(a.start).format("YYYY-MM-DD") === moment(item.start).format("YYYY-MM-DD")) === index);

            // add events associated to each date
            const c2 = c1.map(item => ({ start: item.start, events: [...list.filter(x => moment(x.start).format("YYYY-MM-DD") === moment(item.start).format("YYYY-MM-DD"))] }))

            // return the final array
            return c2;
        }

        return (

            <FlatList
                data={validEvents()}
                keyExtractor={item => item.id}
                renderItem={_renderListItem}
                ItemSeparatorComponent={() => {
                    return (
                        <View
                            style={{
                                height: 1,
                                backgroundColor: "#D9D9D9",
                                marginVertical: 4
                            }}
                        />
                    )
                }}
                contentContainerStyle={styles.contentContainer}
                ListEmptyComponent={() => <NoEventsFound />}
            />

        )
    }, [eventsList, eventsList.length])


    const SelectedDayWeekMonth = () => {
        // this functions returns event for next/last day/week/month.
        return (
            <>
                <View style={{ width: "100%", flexDirection: 'row', alignItems: 'center' }}>
                    {Platform.OS === "web" && listType.key !== 3 && (
                        <Button style={{ marginRight: 10 }} onPress={onPressToday} outline >
                            <Text style={{ color: theme?.basics[900], fontWeight: '500' }}>Today</Text>
                        </Button>
                    )}
                    <View
                        style={{
                            marginLeft: 5,
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >

                        {
                            listType.key == 0
                            &&
                            <>
                                <DayEvents />
                            </>
                        }

                        {
                            listType.key == 1
                            &&
                            <WeekEvents />
                        }

                        {
                            listType.key == 2
                            &&
                            <MonthEvents />

                        }
                        {
                            listType.key == 3
                            &&
                            <View />
                        }

                        {
                            isWeb && screenSize > ScreenSize.Medium ?
                                <View style={{ paddingHorizontal: 5, flexDirection: "row", flex: 0.6, padding: 1 }}>
                                    <View style={{ flex: 1 }} />
                                    <PopupMenu items={LIST_TYPE} onSelect={(item) => setListType(item)} selectedValue={listType.title} />
                                    <ButtonPrimary
                                        label={"Create +"}
                                        onPress={() => {
                                            showEventModal(true)
                                        }}

                                        customStyles={{
                                            marginLeft: 8,
                                        }}

                                    />
                                </View>
                                :
                                <Menu>
                                    <MenuTrigger>
                                        <View style={[styles.dropDown, { alignItems: 'center' }]} >

                                            <Text style={[styles.monthTitle, { marginRight: 10, fontSize: 16 }]}>
                                                {listType.title}
                                            </Text>
                                            <Icon icon={'chevronDown'} color={theme.basics.black_text} height={14} width={14} />
                                        </View>
                                    </MenuTrigger>


                                    <MenuOptions>
                                        {
                                            LIST_TYPE.map((item, i) => {
                                                return (

                                                    <MenuOption
                                                        key={i}
                                                        onSelect={() => setListType(item)}
                                                    >
                                                        <Text style={styles.dropDownMenuItem} >{item.title}</Text>
                                                    </MenuOption>
                                                )
                                            })
                                        }


                                    </MenuOptions>
                                </Menu>

                        }
                    </View>
                </View>

                <Text style={{ fontSize: 12, fontFamily: 'WorkSans_400Regular', marginTop: 5 }}>{`All dates and times are displayed in ${currentUser?.timezone?.display_name}.`}</Text>
            </>
        )
    }

    const NoEventsFound = () => (

        <Text style={styles.noEventsText}>
            {"There are no events to display."}
        </Text>

    )


    return (
        <>
            <PageHeader
                title={'Calendar'}
                icon={'calendar'}
                backEnabled={false}

            />

            <PageContent>
                {isMobile ? (
                    <>
                        <View style={{ flex: 1 }} >
                            <View>
                                <SelectedDayWeekMonth />
                            </View>
                            {
                                isWeb && screenSize >= ScreenSize.Medium
                                    ?
                                    <View style={{ marginHorizontal: 20, marginBottom: 20 }}>
                                        <ContentBlockV1>
                                            <View
                                                style={[
                                                    {
                                                        paddingHorizontal: 45,
                                                        ...WebContainerStyles,
                                                        flexDirection: "row"
                                                    },

                                                ]}
                                            >


                                                {/* <View
                                        style={{
                                            flex: 0.25,
                                            // ...styles.shadows,
                                            // ...WebContainerStyles,
                                            backgroundColor: "#fff"
                                        }}
                                    >
                                        {ListView}
                                    </View> */}

                                                {/* <View style={{ flex: 0.05 }} /> */}
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        ...styles.shadows,
                                                        ...WebContainerStyles,
                                                        backgroundColor: "#fff"
                                                    }}
                                                >

                                                    {
                                                        listType.key == 0
                                                        &&
                                                        <ScrollView>
                                                            <DayView />
                                                        </ScrollView>
                                                    }

                                                    {
                                                        listType.key == 1
                                                        &&
                                                        <>
                                                            <WeekView />
                                                            <ScrollView>
                                                                {DayHoursSlots}
                                                            </ScrollView>
                                                        </>
                                                    }
                                                    {
                                                        listType.key == 2
                                                        &&
                                                        <MonthView />
                                                    }
                                                    {
                                                        listType.key == 3
                                                        &&
                                                        ListView
                                                    }

                                                </View>



                                            </View>
                                        </ContentBlockV1>
                                    </View>
                                    :
                                    <>

                                        {
                                            listType.key == 1
                                            &&
                                            <WeekView />
                                        }
                                        {
                                            listType.key == 2
                                            &&
                                            <MonthView />
                                        }
                                        {
                                            listType.key == 3
                                            &&
                                            ListView
                                        }

                                    </>

                            }

                            <ScrollView
                                style={isMobile ? styles.mobContainer : styles.container}
                            >
                                {
                                    isWeb && screenSize >= ScreenSize.Medium
                                        ?
                                        <></>
                                        :
                                        <>
                                            {
                                                listType.key == 0
                                                &&
                                                <DayView />
                                            }
                                            {
                                                listType.key == 1
                                                &&
                                                DayHoursSlots
                                            }

                                        </>
                                }

                            </ScrollView>


                            {/* {
                    viewEventId > -1
                    &&
                    <ViewEventModal
                        setShowModal={showViewEventModal}
                        showModal={viewEventModal}
                        eventId={viewEventId}
                        showDuplicateModal={showDuplicateModal}
                        showEditModal={showEditModal}
                        setReloading={setReloading}
                    />
                } */}

                            <ModalCreate
                                setShowModal={showEventModal}
                                showModal={eventModal}
                                setReloading={setReloading}
                            />

                            <ModalEdit
                                setShowModal={showEditModal}
                                showModal={editEventModal?.calendar_entry ? true : false}
                                eventInfo={editEventModal}
                                setReloading={setReloading}
                            />

                            <ModalDuplicate
                                setShowModal={showDuplicateModal}
                                showModal={duplicateEventModal?.calendar_entry ? true : false}
                                eventInfo={duplicateEventModal}
                                setReloading={setReloading}
                            />

                        </View>
                        {
                            !isWeb
                                ?
                                <ButtonPrimary
                                    label={"Create +"}
                                    onPress={() => navigation.navigate("EventCreate")}
                                    customStyles={{
                                        position: "absolute",
                                        bottom: 10,
                                        left: 20,
                                        right: 20,
                                        zIndex: 999,
                                        fontSize: 22
                                    }}
                                />
                                : isWeb && screenSize <= ScreenSize.Medium ?
                                    <ButtonPrimary
                                        label={"Create +"}
                                        onPress={() => navigation.navigate("EventCreate")}
                                        customStyles={{
                                            position: "absolute",
                                            bottom: 10,
                                            left: 20,
                                            right: 20,
                                            zIndex: 999,
                                            fontSize: 22
                                        }}
                                    />
                                    : null
                        }
                    </>
                ) : (
                    <>
                        <View style={{ flex: 1 }} >
                            <View style={{ marginHorizontal: 20, marginBottom: 20 }}>
                                <ContentBlockV1>
                                    <SelectedDayWeekMonth />
                                    <View style={{ marginTop: "2%", marginLeft: "-2%", height: 5, width, backgroundColor: "#f2f2f2" }} />
                                    {
                                        isWeb && screenSize >= ScreenSize.Medium
                                            ?
                                            <View style={{}}>
                                                <View
                                                    style={[
                                                        {
                                                            paddingHorizontal: 45,
                                                            ...WebContainerStyles,
                                                            flexDirection: "row"
                                                        },

                                                    ]}
                                                >


                                                    {/* <View
                                        style={{
                                            flex: 0.25,
                                            // ...styles.shadows,
                                            // ...WebContainerStyles,
                                            backgroundColor: "#fff"
                                        }}
                                    >
                                        {ListView}
                                    </View> */}

                                                    {/* <View style={{ flex: 0.05 }} /> */}
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            ...styles.shadows,
                                                            ...WebContainerStyles,
                                                            backgroundColor: "#fff"
                                                        }}
                                                    >

                                                        {
                                                            listType.key == 0
                                                            &&
                                                            <ScrollView>
                                                                <DayView />
                                                            </ScrollView>
                                                        }

                                                        {
                                                            listType.key == 1
                                                            &&
                                                            <>
                                                                <WeekView />
                                                                <ScrollView>
                                                                    {DayHoursSlots}
                                                                </ScrollView>
                                                            </>
                                                        }
                                                        {
                                                            listType.key == 2
                                                            &&
                                                            <MonthView />
                                                        }
                                                        {
                                                            listType.key == 3
                                                            &&
                                                            ListView
                                                        }

                                                    </View>



                                                </View>
                                            </View>
                                            :
                                            <>

                                                {
                                                    listType.key == 1
                                                    &&
                                                    <WeekView />
                                                }
                                                {
                                                    listType.key == 2
                                                    &&
                                                    <MonthView />
                                                }
                                                {
                                                    listType.key == 3
                                                    &&
                                                    ListView
                                                }

                                            </>

                                    }

                                    <ScrollView
                                        style={isMobile ? styles.mobContainer : styles.container}
                                    >
                                        {
                                            isWeb && screenSize >= ScreenSize.Medium
                                                ?
                                                <></>
                                                :
                                                <>
                                                    {
                                                        listType.key == 0
                                                        &&
                                                        <DayView />
                                                    }
                                                    {
                                                        listType.key == 1
                                                        &&
                                                        DayHoursSlots
                                                    }

                                                </>
                                        }

                                    </ScrollView>


                                    {/* {
                    viewEventId > -1
                    &&
                    <ViewEventModal
                        setShowModal={showViewEventModal}
                        showModal={viewEventModal}
                        eventId={viewEventId}
                        showDuplicateModal={showDuplicateModal}
                        showEditModal={showEditModal}
                        setReloading={setReloading}

                    />
                } */}

                                    <ModalCreate
                                        setShowModal={showEventModal}
                                        showModal={eventModal}
                                        setReloading={setReloading}
                                    />

                                    <ModalEdit
                                        setShowModal={showEditModal}
                                        showModal={editEventModal?.calendar_entry ? true : false}
                                        eventInfo={editEventModal}
                                        setReloading={setReloading}
                                    />

                                    <ModalDuplicate
                                        setShowModal={showDuplicateModal}
                                        showModal={duplicateEventModal?.calendar_entry ? true : false}
                                        eventInfo={duplicateEventModal}
                                        setReloading={setReloading}
                                    />
                                </ContentBlockV1>
                            </View>

                        </View>
                        {
                            !isWeb
                                ?
                                <ButtonPrimary
                                    label={"Create +"}
                                    onPress={() => navigation.navigate("EventCreate")}
                                    customStyles={{
                                        position: "absolute",
                                        bottom: 10,
                                        left: 20,
                                        right: 20,
                                        zIndex: 999,
                                        fontSize: 22
                                    }}
                                />
                                : isWeb && screenSize <= ScreenSize.Medium ?
                                    <ButtonPrimary
                                        label={"Create +"}
                                        onPress={() => navigation.navigate("EventCreate")}
                                        customStyles={{
                                            position: "absolute",
                                            bottom: 10,
                                            left: 20,
                                            right: 20,
                                            zIndex: 999,
                                            fontSize: 22
                                        }}
                                    />
                                    : null
                        }
                    </>
                )}
            </PageContent>
        </>
    )
}

export default CalendarAndEvents


export const styles = StyleSheet.create({

    spacer: {
        paddingHorizontal: 45,
        paddingTop: 40,
        paddingBottom: 20
    },
    spacerMobile: {
        paddingHorizontal: 15,
        paddingVertical: 20,
        // marginBottom: 70
    },
    container: {
        flex: 1,
        maxWidth: 700
    },
    mobContainer: {
        flex: 1,
    },

    screenTitle: {
        marginLeft: 10,
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics.black_text,
        fontSize: 40,
        lineHeight: 40,
    },

    mobScreenTitle: {
        fontSize: 22,
        lineHeight: 25,
    },
    divider: {
        marginVertical: 6
    },
    horizontalDivider: {
        marginHorizontal: 6
    },
    arrowBtnPadd: {
        padding: 4,
    },



    monthTitle: {
        marginLeft: 10,
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics.black_text,
        fontSize: 24,
        fontWeight: '500'
    },

    dropDown: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    dropDownMenuItem: {

        color: theme.basics['black_text'],
        padding: 5
    },

    weekViewText: {
        fontSize: 16,
        textAlign: "center",
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics["700"],
    },

    dayHoursText: {
        fontSize: 12,
        textAlign: "center",
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics["700"],
    },
    dayHourBox: {
        width: 48,
        height: 60,
        alignItems: "center"
    },
    dayHourBoxContainer: {
        justifyContent: "center",
        borderColor: Platform.OS === "web" ? UIColors.border : "#a9a9a9",
        borderRightWidth: 0.25,
        position: 'relative'
    },
    contentContainer: {
        padding: 16,
    },
    eventListItem: {
        borderRadius: 5,
        borderColor: Platform.OS === "web" ? UIColors.border : "#a9a9a9",
        borderTopWidth: 8,
        height: 130,
        marginVertical: 12,
        backgroundColor: "#fff",
        padding: 16,
        justifyContent: "space-evenly"
    },
    listItemTitle: {
        fontSize: 13,
        fontFamily: 'WorkSans_600SemiBold',
        color: theme.basics["700"],
    },
    listItemDesc: {
        fontSize: 13,
        fontFamily: 'WorkSans_600SemiBold',
        color: "#FFF",
    },
    startEndTime: {
        fontSize: 13,
        fontFamily: 'WorkSans_400Regular',
        color: "#6D6D6D",
    },


    monthDayBox: {

        width: width / 7.6,
        height: 48,
        alignItems: "center",
    },


    dayHoursSlotText: {
        fontSize: 12,
        color: COLOR_PRIMARY,
        textAlign: "center",
        fontFamily: 'WorkSans_400Regular',
    },

    webOptionPicker: {
        fontSize: 17,
        textAlign: "center",
        fontFamily: 'WorkSans_600SemiBold',
        color: "#5A5A5A",
        flex: 1,
    },
    webSelectionOption: {
        color: COLOR_PRIMARY,
    },
    shadows: {
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.15,
        shadowRadius: 10,
        backgroundColor: "#fff",
        borderRadius: 6,
    },

    webMonthDayBox: {

        flex: 1,
        height: 48,
        alignItems: "center",
    },
    webDayHourBox: {
        minWidth: 48,
        flex: 1,
        height: 48,
        alignItems: "center"
    },

    noEventsText: {
        marginVertical: 4,
        fontSize: 22,
        textAlign: "center",
        fontFamily: 'WorkSans_400Regular',
        color: "#5A5A5A",

    }
})