import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/posts'
import * as api from '@/redux/api/posts'
import _ from 'lodash'


// FEED_POST
function* store(action) {
  try {
    const payload = yield call(api.post, action)
    yield put({ type: constants.STORE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.STORE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchStore() {
  yield takeLatest(constants.STORE.ACTION, store)
}

// FEED_INDEX
function* index(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.INDEX.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.INDEX.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchIndex() {
  yield takeLatest(constants.INDEX.ACTION, index)
}

// FEED_INDEX
function* feeds_index(action) {
  try {
    let payload = yield call(api.feeds, action)
    payload = {
      ...payload,
      data: _.values(payload.data)
    }
    yield put({ type: constants.INDEX.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.INDEX.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchFeedsIndex() {
  yield takeLatest(constants.FEEDS_INDEX.ACTION, feeds_index)
}

// FEED_NEXT_INDEX
function* feeds_next_index(action) {
  try {
    let payload = yield call(api.getNextRequest, action)
    payload = {
      ...payload,
      data: _.values(payload.data)
    }
    yield put({ type: constants.FEEDS_NEXT_INDEX.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.INDEX.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchFeedsNextIndex() {
  yield takeLatest(constants.FEEDS_NEXT_INDEX.ACTION, feeds_next_index)
}

// FEED_LIKE
function* like(action) {
  try {
    const payload = yield call(api.like, action)
    yield put({ type: constants.LIKE.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    console.log(e)
    action.next && action.next(e)
  }
}

function* watchLike() {
  yield takeLatest(constants.LIKE.ACTION, like)
}

// FEED_LIKE
function* like_article(action) {
  try {
    const payload = yield call(api.like_article, action)
    yield put({ type: constants.LIKE_ARTICLE.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    console.log(e)
    action.next && action.next(e)
  }
}

function* watchLikeArticle() {
  yield takeLatest(constants.LIKE_ARTICLE.ACTION, like_article)
}


// FEED_COMMENT
function* comment(action) {
  try {
    const payload = yield call(api.comment, action)
    yield put({ type: constants.COMMENT.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.COMMENT.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchComment() {
  yield takeLatest(constants.COMMENT.ACTION, comment)
}

// FEED_COMMENT
function* comment_article(action) {
  try {
    const payload = yield call(api.comment_article, action)
    yield put({ type: constants.COMMENT_ARTICLE.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.COMMENT_ARTICLE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchCommentCommon() {
  yield takeLatest(constants.COMMENT_ARTICLE.ACTION, comment_article)
}

// DELETE_POST
function* remove(action) {
  try {
    yield call(api.remove, action)
    yield put({ type: constants.DELETE.SUCCESS, action })
    action.next && action.next(undefined)
  } catch (e) {
    yield put({
      type: constants.DELETE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchRemove() {
  yield takeLatest(constants.DELETE.ACTION, remove)
}


// EDIT_POST
function* update(action) {
  try {
    const payload = yield call(api.update, action)
    yield put({ type: constants.UPDATE.SUCCESS, action, payload  })
    action.next && action.next(undefined)
  } catch (e) {
    yield put({
      type: constants.UPDATE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchUpdate() {
  yield takeLatest(constants.UPDATE.ACTION, update)
}

// DELETE_COMMENT
function* delete_comment(action) {
  try {
    yield call(api.delete_comment, action)
    yield put({ type: constants.DELETE_COMMENT.SUCCESS, action })
    action.next && action.next(undefined)
  } catch (e) {
    yield put({
      type: constants.DELETE_COMMENT.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchDeleteComment() {
  yield takeLatest(constants.DELETE_COMMENT.ACTION, delete_comment)
}

// DELETE_COMMENT
function* delete_article_comment(action) {
  try {
    yield call(api.delete_article_comment, action)
    yield put({ type: constants.DELETE_ARTICLE_COMMENT.SUCCESS, action })
    action.next && action.next(undefined)
  } catch (e) {
    yield put({
      type: constants.DELETE_ARTICLE_COMMENT.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchDeleteArticleComment() {
  yield takeLatest(constants.DELETE_ARTICLE_COMMENT.ACTION, delete_article_comment)
}

// RESPNSE ASSESSMENT
function* response_assessment(action) {
  try {
    yield call(api.reponse_assessments, action)
    yield put({ type: constants.RESONSE_ASSESSMENT.SUCCESS, action })
    action.next && action.next(undefined)
  } catch (e) {
    yield put({
      type: constants.RESONSE_ASSESSMENT.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchResponseAssessment() {
  yield takeLatest(constants.RESONSE_ASSESSMENT.ACTION, response_assessment)
}

// COMMENT LIST
function* comment_list(action) {
  try {
    const payload = yield call(api.comment_list, action)
    yield put({ type: constants.COMMENT_LIST.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.COMMENT_LIST.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchCommentList() {
  yield takeLatest(constants.COMMENT_LIST.ACTION, comment_list)
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchStore),
    fork(watchIndex),
    fork(watchFeedsIndex),
    fork(watchFeedsNextIndex),
    fork(watchLike),
    fork(watchLikeArticle),
    fork(watchComment),
    fork(watchCommentCommon),
    fork(watchRemove),
    fork(watchUpdate),
    fork(watchDeleteComment),
    fork(watchDeleteArticleComment),
    fork(watchResponseAssessment),
    fork(watchCommentList),
  ])
}
