import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    DateAndLocationContainer: {
        flexDirection: 'column',
        marginBottom: 20,
    },
    DateContainer: {
        flexDirection: 'row',
        marginBottom: 20,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    LocationContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    }
});
