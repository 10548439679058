import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

const OutlineButton = (props) => {
    const { label = '', onPress = () => {}, containerStyle = {}, labelStyle = {} } = props || {};

    return (
        <TouchableOpacity onPress={onPress} style={[styles.button, containerStyle]}>
            <Text style={[styles.label, labelStyle]}>{label}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        height: 44,
        backgroundColor: "#ffffff",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
        marginTop: 8,
        borderWidth: 1,
        borderColor: '#FB6758'
    },
    label: {
        color: "#FB6758",
		fontSize: 18,
		lineHeight: 35,
		fontWeight: "500",
		letterSpacing: -0.8,
    }
});

export default OutlineButton;