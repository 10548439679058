import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import _ from 'lodash'

// Constants
export const constants = {
  GET_LIFECIRCLESTAGES: createSagaAction('GET_LIFECIRCLESTAGES'),
  GET_FIVESCATEGORIES: createSagaAction('GET_FIVESCATEGORIES'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  getFiveSCategoriesAction: (object, next) => ({
    type: constants.GET_FIVESCATEGORIES.ACTION,
    ...object,
    next,
  }),
  getLifecycleStagesAction: (object, next) => ({
    type: constants.GET_LIFECIRCLESTAGES.ACTION,
    ...object,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.GET_FIVESCATEGORIES.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_FIVESCATEGORIES.SUCCESS]: (state, action) => {
    return {
      ...state,
      categories: action.payload,
      loading: false,
      error: null,
    }
  },
  [constants.GET_LIFECIRCLESTAGES.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_LIFECIRCLESTAGES.SUCCESS]: (state, action) => {
    return {
      ...state,
      lifecycles: action.payload,
      loading: false,
      error: null,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: false,
  lifecycles: [],
  categories: [],
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, loading: false }
})
