import React, { useEffect, useState, useCallback } from 'react'
import { Text, View, Image, TouchableOpacity, FlatList } from 'react-native'
import { useRoute } from '@react-navigation/native'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { actions as TeamActions } from '@/redux/reducers/teams'
import * as TeamSelectors from '@/redux/selectors/teams'
import { actions as GroupActions } from '@/redux/reducers/groups'
import * as GroupSelectors from '@/redux/selectors/groups'
import * as AuthSelectors from '@/redux/selectors/auth'

import PlaceholderImageCover from '@/assets/cover_placeholder.png'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ModalV2 from '@/components/ModalV2'
import DefaultButton from '@/components/Buttons/Default'
import FeedCard from '@/components/Feed/Card'
import PostComposer from '@/components/Feed/Card/Content/Post/Components/PostComposer'
import CreateEditGroup from '@/pages/Community/CreateEditGroup'

import {
    Avatar,
    AvatarWriteButton,
    InviteMemberWeb
} from '@/components'

import styles from './styles.js';

const TeamsAndGroupsView = (
) => {
    const dispatch = useDispatch()
    const route = useRoute()
    const navigation = useNavigation()

    const [postComposerVisible, setPostComposerVisible] = useState(false)
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [membersModalVisible, setMembersModalVisible] = useState(false)
    const [inviteMembersModalVisible, setInviteMembersModalVisible] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [leaveModalVisible, setLeaveModalVisible] = useState(false)

    const type = route?.params?.type
    const id = route?.params?.id

    const entity = type == 'teams' ? useSelector(TeamSelectors.team) : type == 'groups' ? useSelector(GroupSelectors.group) : null
    const posts = type == 'teams' ? useSelector(TeamSelectors.posts) : type == 'groups' ? useSelector(GroupSelectors.posts) : null

    const members = entity?.members ?? []
    const user = useSelector(AuthSelectors.getMember)
    const isMember = members?.some(member => member.id === user?.id)
    const isOwner = entity?.owner_id == user?.id

    const menu = [
    ]

    if (isOwner) {
        menu.push({
            title: 'Edit Details',
            icon: 'pencil',
            permissions: [

            ],
            onPress: () => {
                setEditModalVisible(true)
            }
        })
    }

    menu.push({
        title: 'Members',
        icon: 'community',
        permissions: [

        ],
        onPress: () => {
            setMembersModalVisible(true)
        }
    })

    if (isOwner) {
        menu.push({
            title: 'Invite',
            icon: 'addUser',
            permissions: [

            ],
            onPress: () => {
                setInviteMembersModalVisible(true)
            }
        })
    }

    if (isOwner) {
        menu.push({
            title: 'Delete',
            icon: 'leave',
            permissions: [

            ],
            onPress: () => {
                setDeleteModalVisible(true)
            }
        })
    }

    if (!isOwner && isMember) {
        menu.push({
            title: 'Leave',
            icon: 'leave',
            permissions: [

            ],
            onPress: () => {
                setLeaveModalVisible(true)
            }
        })
    }

    if (!isOwner && !isMember && type == 'groups') {
        menu.push({
            title: 'Join',
            icon: 'addUser',
            permissions: [

            ],
            onPress: () => {
                handleJoin()
            }
        })
    }

    const actions = [
        {
            title: 'Manage',
            type: 'menu',
            permissions: [

            ],
            menu: menu
        }
    ];

    useEffect(() => {
        if (id) {
            if (type == 'teams') {
                dispatch(TeamActions.show({ id }, (error) => {
                    if (error) {
                        navigation.navigate('Community')
                    }
                }))
                dispatch(TeamActions.posts({ id, current_page: 1 }))
            }

            if (type == 'groups') {
                dispatch(GroupActions.show({ id }, (error) => {
                    if (error) {
                        navigation.navigate('Community')
                    }
                }))
                dispatch(GroupActions.posts({ id, current_page: 1 }))
            }
        }
    }, [id, dispatch])

    const handlePostComposerClick = useCallback(() => {
        setPostComposerVisible(true)
    }, [setPostComposerVisible])

    const handlePostComposerClose = useCallback(() => {
        if (type == 'teams') {
            dispatch(TeamActions.show({ id }))
            dispatch(TeamActions.posts({ id, page: 1 }))
        }

        if (type == 'groups') {
            dispatch(GroupActions.show({ id }))
            dispatch(GroupActions.posts({ id, page: 1 }))
        }

        setPostComposerVisible(false)
    }, [setPostComposerVisible])

    const handleEditModalClose = useCallback(() => {
        if (type == 'teams') {
            dispatch(TeamActions.show({ id }, () => { }))
        }

        if (type == 'groups') {
            dispatch(GroupActions.show({ id }, () => { }))
        }

        setEditModalVisible(false)
    }, [editModalVisible])

    const handleInviteMembersModalClose = useCallback(() => {
        if (type == 'teams') {
            dispatch(TeamActions.show({ id }, () => { }))
        }

        if (type == 'groups') {
            dispatch(GroupActions.show({ id }, () => { }))
        }
    }, [dispatch, setInviteMembersModalVisible])

    const handleRemoveMember = useCallback((member_id) => {
        if (type == 'teams') {
            dispatch(TeamActions.removeMember({ team_id: id, member_id: member_id }, () => {
                dispatch(TeamActions.show({ id }))
            }))
        }

        if (type == 'groups') {
            dispatch(GroupActions.removeMember({ group_id: id, member_id: member_id }, () => {
                dispatch(GroupActions.show({ id }))
            }))
        }
    }, [dispatch])

    const handleDelete = useCallback(() => {
        if (type == 'teams') {
            dispatch(TeamActions.remove({ id }, () => {
                setDeleteModalVisible(false)

                navigation.navigate('Community')
            }))
        }

        if (type == 'groups') {
            dispatch(GroupActions.remove({ id }, () => {
                setDeleteModalVisible(false)

                navigation.navigate('Community')
            }))
        }
    }, [navigation, dispatch])

    const handleLeave = useCallback(() => {
        if (type == 'teams') {
            dispatch(TeamActions.leave({ id }, () => {
                setLeaveModalVisible(false)

                //dispatch(TeamActions.show({ id }))
                navigation.navigate('Community')
            }))
        }

        if (type == 'groups') {
            dispatch(GroupActions.leave({ id }, () => {
                setLeaveModalVisible(false)

                dispatch(GroupActions.show({ id }))
            }))
        }
    }, [dispatch, navigation])

    const handleJoin = useCallback(() => {
        if (type == 'groups') {
            dispatch(GroupActions.join({ id }, () => {
                dispatch(GroupActions.show({ id }))
            }))
        }
    }, [dispatch])


    return (
        <>
            <PageHeader
                title={type == 'teams' ? 'Team' : type == 'groups' ? 'Group' : null}
                icon={false}
                backEnabled={true}
                actions={actions}
            />

            <PageContent>
                <ColumnsContainer>
                    <Column
                        flexBasis={'30%'}
                    >
                        <ContentBlockV1>
                            <View style={styles.CoverContainer}>
                                <Image
                                    style={styles.CoverImage}
                                    source={entity?.image ? { uri: entity?.image } : PlaceholderImageCover}
                                    resizeMode="cover"
                                />
                            </View>

                            <ContentBlockV1>
                                <View style={styles.DetailsContainer}>
                                    <Text style={styles.DetailsName}>{entity?.name}</Text>
                                    <Text style={styles.DetailsDescription}>{entity?.description}</Text>
                                </View>
                            </ContentBlockV1>

                            <ContentBlockV1
                                title={'Members'}
                                action={{
                                    onPress: () => {
                                        setMembersModalVisible(true)
                                    },
                                    icon: 'chevronRight'
                                }}
                            >
                                <View style={styles.MembersContainer}>
                                    {members.slice(0, 4).map((member, key) => (
                                        <Avatar
                                            style={{ marginRight: 8 }}
                                            source={member?.avatar ? { uri: member?.avatar } : null}
                                            size={50}
                                        />
                                    ))}

                                    {members.length > 5 && (
                                        <View>
                                            <Text>+{members.length - 5}</Text>
                                        </View>
                                    )}
                                </View>
                            </ContentBlockV1>
                        </ContentBlockV1>
                    </Column>

                    <Column
                        flexBasis={'70%'}
                    >
                        <View style={styles.FeedContainer}>
                            <AvatarWriteButton
                                avatarSize={50}
                                text="Write a post"
                                avatarSource={user?.avatar}
                                onPress={handlePostComposerClick}
                            />

                            {(posts.data ?? []).map(post => (
                                <FeedCard
                                    key={post.id}
                                    post={post}
                                />
                            ))}
                        </View>
                    </Column>
                </ColumnsContainer>
            </PageContent>

            <PostComposer
                isVisible={postComposerVisible}
                setVisible={setPostComposerVisible}
                onClose={handlePostComposerClose}
                attachmentId={id}
                attachmentType={type == 'teams' ? 'App\\Team' : type == 'groups' ? 'App\\Group' : null}
            />

            <CreateEditGroup
                type={type == 'teams' ? 'team' : type == 'groups' ? 'group' : null}
                data={entity}
                isVisible={editModalVisible}
                setVisible={setEditModalVisible}
                onClose={handleEditModalClose}
            />

            <InviteMemberWeb
                isVisible={inviteMembersModalVisible}
                setVisible={setInviteMembersModalVisible}
                data={entity}
                joinedMembers={members}
                type={type == 'teams' ? 'team' : type == 'groups' ? 'group' : null}
                onClose={handleInviteMembersModalClose}
            />

            <ModalV2
                isVisible={membersModalVisible}
                setVisibility={setMembersModalVisible}
                title={'Members'}
                subtitle={false}
            >
                <FlatList
                    data={members}
                    renderItem={({ item }) =>
                        <View style={styles.MemberCardContainer}>
                            <View style={styles.MemberCardAvatarContainer}>
                                <Avatar size={50} source={item?.avatar ? { uri: item?.avatar } : null} />
                            </View>

                            <View style={styles.MemberCardDetailsContainer}>
                                <Text style={styles.MemberCardDetailsName}>{`${item?.first_name} ${item?.last_name}`}</Text>
                                <Text style={styles.MemberCardDetailsRole}>{item?.role}</Text>
                            </View>

                            {isOwner && item?.id != entity?.owner_id && (
                                <View style={styles.MemberCardRemoveContainer}>
                                    <DefaultButton
                                        label={'Remove'}
                                        onPress={() => {
                                            handleRemoveMember(item?.id)
                                        }}
                                    />
                                </View>
                            )}
                        </View>
                    }
                    keyExtractor={item => item.id}
                />
            </ModalV2>

            <ModalV2
                isVisible={deleteModalVisible}
                setVisibility={setDeleteModalVisible}
                title={'Delete'}
                subtitle={false}
            >
                <View>
                    <Text style={{
                        fontSize: 16,
                        marginBottom: 18
                    }}>Are you sure you want to delete?</Text>

                    <DefaultButton
                        label={'Delete'}
                        onPress={() => {
                            handleDelete()
                        }}
                    />
                </View>
            </ModalV2>

            <ModalV2
                isVisible={leaveModalVisible}
                setVisibility={setLeaveModalVisible}
                title={'Leave'}
                subtitle={false}
            >
                <View>
                    <Text style={{
                        fontSize: 16,
                        marginBottom: 18
                    }}>Are you sure you want to leave?</Text>

                    <DefaultButton
                        label={'Leave'}
                        onPress={() => {
                            handleLeave()
                        }}
                    />
                </View>
            </ModalV2>
        </>
    )
}

export default TeamsAndGroupsView