import styled from 'styled-components/native'
import { SafeAreaView } from 'react-native-safe-area-context'

export const Container = styled(SafeAreaView)`
  flex: 1;
`

export const ContentWrapper = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
`

export const ProfileWrapper = styled.View`
  width: 30%;
  background-color: #fff;
`

export const LastActivitiesWrapper = styled.View`
  flex-grow: 1;
  flex-shrink: 1;
  padding: 15px 40px;
`
