export const TYPE_CUSTOM = "custom";
export const TYPE_PREBUILT = "prebuilt";
export const TYPE_CULTURE_MATURITY = "culture-maturity";

export const Q_TYPE_SINGLE = "single_select";
export const Q_TYPE_MULTIPLE = "multi_select";
export const Q_TYPE_FREE_TEXT = "text";




const ACTIVE_STATUS = 1;

export const isActiveAssessment = (item) => {

    if (item?.assessment_status_entry?.length > 0) {
        // const status_entry = item?.assessment_status_entry[0];
        const status_entry = item?.assessment_status_entry[item?.assessment_status_entry?.length - 1];

        if (status_entry?.assessment_status_id === ACTIVE_STATUS) {
            return item;
        }
    }

}
export const isNotActiveAssessment = (item) => {

    if (item?.assessment_status_entry?.length > 0) {
        // const status_entry = item?.assessment_status_entry[0];
        const status_entry = item?.assessment_status_entry[item?.assessment_status_entry?.length - 1];
        if (status_entry) {
            if (status_entry?.assessment_status_id !== ACTIVE_STATUS) {
                return item;
            }
        } else {
            return item
        }
    } else {
        return item
    }

}