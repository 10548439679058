import { createRef } from "react";
import { Modal, ModalHeader, ModalHeading } from "@twilio-paste/modal";
import ModalV2 from '@/components/ModalV2'



const ConvoModal = (props) => {
  const nameInputRef = createRef();

  return (
    <ModalV2
      isVisible={props.isModalOpen}
      setVisibility={props.handleClose}
      title={props.title}
    >
      {props.modalBody}
      {props.modalFooter}
    </ModalV2>
  );
};

export default ConvoModal;
