import React, { useCallback } from 'react'
import { FlatList } from 'react-native'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import { Icon, MemberMobile } from '@/components'
import {
  Container,
  Header,
  LeftIconButton,
  TitleHeader,
  IconButton,
  styles
} from './styles'

const InviteMemberMobile = ({ navigation }) => {
	const data = []
  const renderItem = useCallback(({item}) => (<MemberMobile {...item} />))

  return (
    <Container>
      <Header>
        <LeftIconButton onPress={() => navigation.goBack()}>
          <Icon
            icon="arrowLeft"
            color={theme.basics[900]}
            height={17}
            width={16}
          />
        </LeftIconButton>
        <TitleHeader>Invite members</TitleHeader>
        <IconButton>
          <Icon
            icon="searchIcon"
            color={theme.basics[900]}
            height={17}
            width={17}
          />
        </IconButton>
      </Header>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.flatListContent}
      />
    </Container>
  )
}

InviteMemberMobile.propTypes = {
  navigation: PropTypes.shape({
    goBack: PropTypes.func
  })
}

export default InviteMemberMobile
