import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function informationIcon(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM8.75 11.75H7.25V7.25H8.75V11.75ZM8.75 5.75H7.25V4.25H8.75V5.75Z" fill="#1B1B1B"/>
    </Svg>
    
  )
}

informationIcon.propTypes = Svg.propTypes
