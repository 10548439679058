import React, { useEffect, useState } from 'react'
import { View, ScrollView, Text, Image, RefreshControl, Platform, TouchableOpacity, Modal, SafeAreaView } from 'react-native'
import { FontAwesome } from '@expo/vector-icons'
import Toast from 'react-native-toast-message'
import { Video } from 'expo-av'

import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/course'
import { actions } from '@/redux/reducers/course'
import { useIsMobile } from '@/styles'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import Icon from "@/components/Icon";

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import LearningView from '@/pages/Learning/Components/View'

import styles from './styles.js';

const ViewCourse = ({ route }) => {
    const navigation = useNavigation()
    const dispatch = useDispatch()

    const loading = useSelector(selectors.loading)
    const isMobile = useIsMobile()
    const isDesktop = !isMobile

    const courseSelector = useSelector(selectors.course)
    const [course, setCourse] = useState(courseSelector)
    const [selectedModule, setSelectedModule] = useState(false)

    const updateCourse = () => {
        navigation.navigate('CreateCourse', { course })
    }

    const [refreshing, setRefreshing] = React.useState(false);

    const video = React.useRef(null)

    useEffect(() => {

    }, [])

    useEffect(() => {
        // dispatch(actions.getCourseWithIdAction({ id: route?.params?.id,language_id: languageId }, () => { }))
        dispatch(actions.getCourseWithIdAction({ id: route?.params?.id }, () => { }))
    }, [route?.params?.id])

    useEffect(() => {
        setCourse(courseSelector)
        setRefreshing(false)
    }, [courseSelector])

    const toggleStatus = () => {
        dispatch(
            actions.toggleStatusAction({ id: route?.params?.id }, (message) => {
                console.log('response message of Toggle Status', message)
                Toast.show({
                    type: message == 'success' ? 'success' : 'error',
                    position: 'bottom',
                    text1: message,
                })
            }),
        )
    }
    const toggleFeatured = () => {
        dispatch(
            actions.toggleFeaturedAction({ id: route?.params?.id }, (message) => {
                console.log('response message of Toggle Featured', message)
                Toast.show({
                    type: message == 'success' ? 'success' : 'error',
                    position: 'bottom',
                    text1: message,
                })
            }),
        )
    }
    const deleteCourse = () => {
        dispatch(
            actions.deleteCourseAction({ id: route?.params?.id }, (message) => {
                console.log('response message of Delete Course', message)
                navigation.navigate('Courses')
            }),
        )
    }

    return (
        <>
            <PageHeader
                title={'Course'}
                icon={false}
                backEnabled={true}
                actions={
                    [
                        {
                            title: 'Manage',
                            type: 'menu',
                            permissions: [
                                'MANAGE_LEARNING_ARTICLES'
                            ],
                            menu: [
                                {
                                    title: 'Edit',
                                    icon: 'pencil',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        updateCourse()
                                    }
                                },
                                {
                                    title: 'Publish/Unpublish',
                                    icon: 'send',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        toggleStatus()
                                    }
                                },
                                {
                                    title: 'Feature/Unfeature',
                                    icon: 'star',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        toggleFeatured()
                                    }
                                },
                                {
                                    title: 'Delete',
                                    icon: 'close',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        deleteCourse()
                                    }
                                }
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <LearningView
                    id={route?.params?.id}
                    type={'course'}
                    image={course?.image}
                    title={course?.name}
                    excerpt={course?.excerpt}
                    employee_lfecycle_stage={course?.employee_lifecycle_stage?.name}
                    five_s_category={course?.five_s_category?.name}
                    creator={course?.creator}
                    created_at={course?.created_at}
                >
                    <View style={styles.ExcerptContainer}>
                        <Text style={styles.Excerpt}>{course?.excerpt}</Text>
                    </View>

                    <View style={styles.EpisodesContainer}>
                        <Text style={styles.EpisodesHeadline}>{`Episodes (${course?.modules?.length})`}</Text>

                        <View style={styles.EpisodesList}>
                            {course?.modules && course?.modules.map((item, index) => {
                                return (
                                    <View style={styles.EpisodeCardContainer}>
                                        <TouchableOpacity
                                            style={styles.EpisodeCard}
                                            onPress={() => {
                                                setSelectedModule(index)
                                            }}
                                        >
                                            <View style={styles.EpisodeImageContainer}>
                                                <Image
                                                    style={styles.EpisodeImage}
                                                    resizeMode="cover"
                                                    source={
                                                        item?.image
                                                            ? {
                                                                uri: `${item.image}`,
                                                            }
                                                            : PlaceholderImageCover
                                                    }
                                                />
                                                <View style={styles.EpisodeImageOverlay}>
                                                    <FontAwesome name="play-circle" size={19} color="#fff" />
                                                </View>
                                            </View>

                                            <View style={styles.EpisodeDetailsContainer}>
                                                <Text style={styles.EpisodeNumber}>Episode {index + 1}</Text>
                                                <Text style={styles.EpisodeTitle}>{item?.title}</Text>
                                                <Text style={styles.EpisodeContent}>{item?.content}</Text>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </LearningView>
            </PageContent>

            <Modal
                style={styles.EpisodeViewerModal}
                animationType="fade"
                visible={selectedModule !== false ? true : false}
            >
                <SafeAreaView style={{ flex: 1 }}>
                    <View style={{
                        flex: 1,
                    }}>
                        <View style={styles.EpisodeViewerContainer}>
                            <View style={styles.EpisodeViewerHeaderContainer}>
                                <View style={styles.EpisodeViewerDismissContainer}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setSelectedModule(false)
                                        }}
                                        style={[
                                            styles.EpisodeViewerDismissButton,
                                            {}
                                        ]}
                                    >
                                        <Icon
                                            icon="arrowLeft"
                                            color={'#1b1b1b'}
                                            height={Platform.OS == 'web' ? 13 : 10}
                                        />
                                    </TouchableOpacity>
                                </View>

                                <View style={styles.EpisodeViewerTitleContainer}>
                                    <Text style={styles.EpisodeViewerTitle}>{course?.modules[selectedModule]?.title}</Text>
                                </View>
                            </View>

                            <View style={styles.EpisodeViewerVideoContainer}>
                                <Video
                                    ref={video}
                                    source={{ uri: course?.modules[selectedModule]?.video }}
                                    isLooping
                                    resizeMode={Video.RESIZE_MODE_CONTAIN}
                                    useNativeControls
                                    style={styles.EpisodeViewerVideo}
                                />
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        </>
    )

}

export default ViewCourse
