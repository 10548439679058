import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ChevronLeft(props) {
  return (
    <Svg width="12" height="20" viewBox="0 0 12 20" fill="none" {...props}>
      <Path
        d="M11.67 1.8701L9.9 0.100098L0 10.0001L9.9 19.9001L11.67 18.1301L3.54 10.0001L11.67 1.8701Z"
        fill={props.color || 'white'}
      />
    </Svg>
  )
}

ChevronLeft.propTypes = Svg.propTypes
