import { ScreenSize } from '@/styles'
import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1;
  display: flex;
  flex-direction: ${({ invert }) => (invert ? 'row-reverse' : 'row')};
`

export const ContentWrapper = styled.View`
  display: flex;
  align-items: ${({ invert }) => (invert ? 'flex-start' : 'center')};
  width: ${({ screenSize }) =>
    screenSize >= ScreenSize.Medium ? '40%' : '100%'};
`

export const DecorationContainer = styled.View`
  flex-grow: 1;
  position: relative;
`

export const DecorationWrapper = styled.View`
  bottom: 0;
  right: 10%;
  position: absolute;
`
