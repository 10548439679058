import React from 'react'
import {
    View,
    Platform,
    ScrollView
} from 'react-native'

import styles from './styles.js';

const PageContent = ({
    children
}) => {

    return (
        <View style={[
            styles.Container,
            {}
        ]}>
            {children}
        </View>
    )

}

export default PageContent