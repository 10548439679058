import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
import { StyleSheet } from 'react-native'
import { ScreenSize } from '@/styles'

const { width } = Dimensions.get('window')
const isMobile = width <= ScreenSize.Medium
export const flatListStyles = StyleSheet.create({
  columnWrapper: {
    width: '100%',
    flexWrap:'wrap'
  },
  content: {
    width: isMobile ? width : '100%',
    paddingBottom: isMobile? 52 : 82,
    paddingTop: width <= ScreenSize.Medium ? 16 : 36,
  }
})

export const Container = styled.View`
	flex-direction: row;
	width: ${({ isMobile, screenSize }) => {
    return isMobile ? '100%' : screenSize >= ScreenSize.XLarge ? '25%' : screenSize >= ScreenSize.Large ? '33%' : '100%'
  }};
	height: ${props => props.screenSize <= ScreenSize.Medium ? 'auto' : '75px'};
	margin: 25px 0 0 0;
	padding: 0 32px 0 0;
	align-items: ${({ screenSize }) => screenSize <= ScreenSize.Medium ? ' center' : "flex-start"};
`

export const RightContainer = styled.View`
	flex: 1;
	align-items: ${({ screenSize }) => screenSize <= ScreenSize.Medium ? 'flex-end' : 'flex-start' };
	padding-top: 10px;
`

export const ButtonNavigation = styled.TouchableOpacity`
`
