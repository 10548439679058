import { ScreenSize } from '@/styles'
import Button from '@/components/Button'
import { Subtitle1 } from '@/styles/fonts'
import styled from 'styled-components/native'
import { vh, vw } from 'react-native-expo-viewport-units'

export const ForgetText = styled(Subtitle1)`
  color: ${({ theme }) => theme.danger.standard};
  margin-top: 20px;
`

export const CheckboxContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: -7px;
  margin-top: 15px;
`

export const TextButton = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`

export const SubmitButton = styled(Button)`
  margin: 15px 0;
`

export const LoginPageContent = styled.View`
  height: 100%;
  padding-top: ${({ screenSize }) =>
    screenSize >= ScreenSize.Medium ? `${vh(5)}` : '0'}px;
  padding-left: ${({ screenSize }) =>
    screenSize >= ScreenSize.Medium && screenSize <= ScreenSize.Large
      ? `${vw(10)}`
      : '0'};
`

export const WorkspaceCodeText = styled(Subtitle1)`
  width: 283px;
  font-family: 'WorkSans_400Regular';
  font-size: 15px;
  line-height: 23px;
  color: ${({ theme }) => theme.basics[600]};
`

export const GetStartedText = styled(WorkspaceCodeText)`
  color: ${({ theme }) => theme.danger.standard};
`
