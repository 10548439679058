import styled from 'styled-components/native'
import { shadow1 } from '@/styles/shadows'

import themes from '@/styles/themes/black'
import { Caption1, Paragraph2 } from '@/styles/fonts'

export const Container = styled.View`
  margin-left: 16px;
  margin-right: 16px;

  padding-top: 8px;
  padding-left: 14px;
  padding-right: 8px;

  background: ${themes.basics.background_white};
  /* shadow 1 */
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  ${shadow1}
`

export const HeaderCard = styled.View`
  flex-direction: row;
  justify-content: center;
`

export const Person = styled.View`
  flex: 1;
  margin-top: 4px;
  align-items: flex-start;
  justify-content: center;
`

export const MoreVert = styled.TouchableOpacity`
  height: 24px;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  opacity: 100;
`

export const PublishedOn = styled(Caption1)`
  align-self: center;
  color: ${themes.basics.black_text};
`

export const ContentCard = styled(Paragraph2)`
  align-items: center;
  justify-content: center;
  color: ${themes.basics.black_text};
`

export const FooterCardTop = styled.View`
  padding-top: 8px;
  flex-direction: row;
  justify-content: space-between;
`

export const ButtonLinkGray = styled(Paragraph2)`
  color: ${themes.basics[500]};
`

export const LikeLove = styled.View`
  height: 24px;
  width: 90px;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  margin-bottom: 14px;

  border-radius: 90px;

  background-color: ${themes.basics.background_button};
  padding-left: 10px;
  padding-right: 10px;
`

export const Like = styled(Caption1)`
  margin-top: 3px;
  color: ${themes.support.standard_high};
`

export const Love = styled(Caption1)`
  margin-top: 3px;
  margin-left: 4px;
  color: ${themes.primary.standard};
`
