import React from 'react'
import { StyleSheet } from 'react-native'
import PropTypes from 'prop-types'
import { Button as MuiButton } from './styles'

const Button = ({ outline, color, disabled, mode = 'text', ...rest }) => {
  return (
    <MuiButton
      mode={mode}
      color={outline ? "transparent" : color}
      uppercase={false}
      outline={outline}
      disabled={disabled}
      labelStyle={styles.labelStyle}
      {...rest}
    />
  )
}

const styles = StyleSheet.create({
  labelStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

Button.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Button
