import { StyleSheet, Platform } from 'react-native'
import { ScreenSize, isMobile } from '@/styles'
import { shadow2 } from '@/styles/shadows'
import Button from '@/components/Button'
import { Headline2 } from '@/styles/fonts'
import styled from 'styled-components/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import theme from '@/styles/themes/black'

export const styles = StyleSheet.create({
  scrollContent: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.basics.background_white,
    //paddingBottom: '50%',
    paddingTop: '5',
    flexGrow: 1,
  },
  scrollView: {
    backgroundColor: theme.basics.background_white,
  },
  scrollContentMobile: {
    flexGrow: 1,
    position: 'relative',
    backgroundColor: theme.basics.background_white,
  },
  keyboardStyle: {
    flex: 1
  },
  Container: {
    flex: 1,
    maxWidth: 600,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 0,
    marginHorizontal: 'auto',

    ...Platform.select({
      ios: {

      },
      android: {

      },
      web: {
        minWidth: 600
      }
    })
  },
  Headline: {
    fontFamily: 'WorkSans_600SemiBold',
    fontSize: 32,
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 30
  },
})

export const FormWrapper = styled.View`
  flex: 1 1 100%;
  width: 100%;
	background-color: ${({ theme }) => theme.basics.background_white};
	height: 100%;
`

export const GreetingMessage = styled(Headline2)`
  margin-top: 10px;
  font-family: 'WorkSans_600SemiBold';
  font-size: ${({ screenSize }) =>
    screenSize <= ScreenSize.Medium ? 32 : 42}px;
`

export const ImageLogo = styled.Image`
	width:  ${isMobile ? 26 : 60}px;
	height:  ${isMobile ? 26 : 60}px;
	align-self: flex-end;
	margin-top: ${isMobile ? 5 : 56}px;
	margin-right: ${isMobile ? 5 : 60}px;
`

export const FormContent = styled.View`
  flex: 1 1 100%;
	height: 100%;

  display: flex;
	background-color: ${({ theme }) => theme.basics.background_white};
  align-items: center;
  flex-direction: column;
  margin-top: ${({ screenSize }) =>
    screenSize <= ScreenSize.Medium ? 15 : 40}px;
`

export const FieldsWrapper = styled.View`
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ screenSize }) =>
    screenSize <= ScreenSize.Medium ? 15 : 30}px;
	background-color: ${({ theme }) => theme.basics.background_white};

`

export const AvatarWrapper = styled.View`
  position: relative;
  width: ${({ screenSize }) => (screenSize <= ScreenSize.Medium ? 90 : 120)}px;
`

export const FieldWrapper = styled.View`
  background-color: white;
  margin-top: ${({ screenSize }) =>
    screenSize <= ScreenSize.Medium ? 10 : 25}px;
`

export const CircleCamera = styled.View`
	position: absolute;
	bottom: ${({ isMobile }) => isMobile ? -5 : -5}px;
	right: ${({ isMobile }) => isMobile ? -5 : -15}px;

	width: ${({ isMobile }) => isMobile ? 32 : 50}px;
	height: ${({ isMobile }) => isMobile ? 32 : 50}px;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	background-color: #fff;
	${shadow2};
	elevation: 3;
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 15px 0;
	align-items: center;

`

export const SubmitButtonText = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`
