import request from '@/helpers/request'

export async function index({ page, filter, withDeactivated }) {
  return await request('community/employees/index', {
    method: 'GET',
    qsParams: { page, filter, with_deactivated: withDeactivated ? 1 : 0 },
  })
}

export async function dataprovider() {
  return await request('community/employees/dataprovider', {
    method: 'GET',
  })
}

export async function invite({ first_name, last_name, internal_id, email, role_txt, city, role_id }) {
  return await request('community/employees/invite', {
    method: 'POST',
    body: { first_name, last_name, internal_id, email, role_txt, city, role_id }
  })
}

export async function update(body) {
  return await request('community/employees/edit', {
    method: 'PUT',
    body
  })
}

export async function deleteEmployee(params) {
  return await request('community/employees/delete', {
    method: 'DELETE',
    body: { id: params?.id }
  })
}


// /api/v1/community/employees/edit
// /api/v1/community/employees/delete
