import request from '@/helpers/request'

export async function post({ text, attachmentType, attachmentId, file }) {
  return await request('posts/store', {
    method: 'POST',
    body: JSON.stringify({
      text,
      attachment_type: attachmentType,
      attachment_id: attachmentId,
      file: file
    }),
  })
}

export async function comment_article({ comment, contentType, contentId }) {
  return await request('common/comments/createComment', {
    method: 'POST',
    body: JSON.stringify({
      comment,
      type: contentType,
      id: contentId
    }),
  })
}

export async function comment({ comment, contentType, contentId }) {
  return await request('posts/comment', {
    method: 'POST',
    body: JSON.stringify({
      comment,
      content_type: contentType,
      content_id: contentId
    }),
  })
}

export async function index({ page, attachmentType, attachmentId }) {
  return await request('posts/index', {
    qsParams: {
      page,
      attachment_type: attachmentType,
      attachment_id: attachmentId
    }
  })
}

export async function feeds({ page }) {
  return await request('home/feed/getItems', {
    qsParams: {
      page,
    }
  })
}

export async function getNextRequest(url) {
  return await request({
    url: url,
    method: 'GET',
  })
}

export async function comment_list({ id, postType }) {
  return await request('common/comments/getComments', {
    method: 'GET',
    qsParams: {
      id,
      type: postType
    }
  })
}

export async function like({ id, postType }) {
  return await request('posts/like', {
    method: 'PUT',
    body: JSON.stringify(
      {
        id: id,
        type: postType
      }
    ),
  })
}

export async function like_article({ id, postType }) {
  return await request('common/likes/toggleLike', {
    method: 'GET',
    qsParams: {
      id,
      type: postType
    }
  })
}

export async function remove({ id }) {
  return await request('posts/delete', {
    method: 'DELETE',
    qsParams: { id },
  })
}

export async function update({ id, text, file }) {
  return await request('posts/update', {
    method: 'PUT',
    body: { id, text, file }
  })
}

export async function delete_comment({ id }) {
  return await request('posts/deleteComment', {
    method: 'DELETE',
    qsParams: { id },
  })
}

export async function delete_article_comment({ id, post_id, post_type }) {
  return await request('common/comments/deleteComment', {
    method: 'DELETE',
    qsParams: {
      id: post_id,
      type: post_type,
      comment_id: id
    },
  })
}

export async function reponse_assessments({ question_id, post_id, post_type, options, text }) {

  return await request('assessments/respond', {
    method: 'POST',
    body: JSON.stringify({
      assessment_id: post_id,
      assessment_type: post_type,
      question_id: question_id,
      options: options,
      text
    }),
  })
}