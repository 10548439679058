import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function AlignLeft(props) {
    return (
        <Svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
            <Path d="M14 4.5V1C14 0.45 13.55 0 13 0H1C0.45 0 0 0.45 0 1V11C0 11.55 0.45 12 1 12H13C13.55 12 14 11.55 14 11V7.5L18 11.5V0.5L14 4.5ZM11 7H8V10H6V7H3V5H6V2H8V5H11V7Z" fill={props.color || '#323232'} />

        </Svg>
    )
}

AlignLeft.propTypes = Svg.propTypes