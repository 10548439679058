import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
  Container: {
    width: '100%',
    maxWidth: 700,
    alignSelf: 'center',
    alignItems: 'stretch'
  },
  Card: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    paddingVertical: 20,
    shadowColor: 'rgba(2, 2, 2, 1)',
    shadowOffset: { width: 2, height: 1 },
    shadowOpacity: 0.10,
    shadowRadius: 20,
    elevation: 3,
    marginBottom: 10,
  },
});