import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { FlatList, ScrollView, View } from 'react-native'
import { useRoute } from '@react-navigation/native'
import {
  Comment,
  AvatarWriteButton,
  CreatePostComment,
  Avatar,
  Icon,
} from '@/components'
import theme from '@/styles/themes/black'
import {
  Container,
  MoreButton,
  MoreButtonText,
  ContainerMobile,
  CreateCommentWrapper,
  SendButton,
  TextInput,
  TextInputWrapper,
  style,
} from './styles'
import { isMobile } from '@/styles'
import { useDispatch, useSelector } from 'react-redux'
import { PostInterface } from '@/interfaces/post'
import PropTypes from 'prop-types'
import { actions } from '@/redux/reducers/posts'
import { actions as teamActions } from '@/redux/reducers/teams'
import { actions as groupActions } from '@/redux/reducers/groups'
import * as profileSelectors from '@/redux/selectors/profile'
import { getMember } from '@/redux/selectors/auth'

const CommentList = ({
  post,
  postType,
  idPresent,
  contentType,
  onClosePostComment,
  handleDeleteComment,
  fetchPost
}) => {
  const [isMoreVisible, setMoreVisible] = useState(false)
  const [isCreateCommentVisible, setCreateCommentVisible] = useState(false)
  const [isToogleInputVisible, setToogleInputVisible] = useState(false)
  const [valueInput, setValueInput] = useState('')
  const userProfile = useSelector(profileSelectors.getProfile)
  const user = useSelector(getMember)
  const route = useRoute()
  const dispatch = useDispatch()
  const renderItem = useCallback(({ item }) => {
    return (
      <Comment
        id={item?.id}
        author_id={item?.author?.id}
        post_id={item?.post_id}
        contentType={contentType}
        authorSource={item?.author?.avatar}
        creatorName={`${item?.author?.first_name} ${item.author?.last_name}`}
        authorOffice={item?.author?.role}
        comment={item?.comment}
        likesCount={item?.likes?.length}
        createdAt={item?.created_at}
        handleDeleteComment={handleDeleteComment}
        postType={postType}
        idPresent={idPresent}
        onCloseMobile={() => {
          fetchPost();
          if (postType == 'team') {
            dispatch(teamActions.comment_list({ postType: contentType, id: post?.id }))
          } else if (postType == 'group') {
            dispatch(groupActions.comment_list({ postType: contentType, id: post?.id }))
          } else {
            // dispatch(actions.feeds_index({ page: 1 }))
            dispatch(actions.comment_list({ postType: contentType, id: post?.id }))
          }
        }}
        fetchPost={fetchPost}
      />
    )
  }
    , [post?.comments])

  const commentsDataWithPostId = post?.comments?.map((comment) => ({ ...comment, post_id: post?.id }))

  const handleCreatePostComment = useCallback(() => {
    if (isMobile) {
      //setToogleInputVisible(true)
      setCreateCommentVisible(true)
      return
    }
    setCreateCommentVisible(true)
  }, [setCreateCommentVisible, setToogleInputVisible])

  const handleCommentPost = useCallback(() => {
    if (valueInput === '') {
      setToogleInputVisible(false)
      return
    }
    setValueInput('')
    setToogleInputVisible(false)
  }, [setToogleInputVisible, setValueInput, dispatch, valueInput])

  if (!isMobile) {

    return (
      <>
        <Container>
          <MoreButton onPress={() => setMoreVisible(!isMoreVisible)}>
            <MoreButtonText>Show more</MoreButtonText>
          </MoreButton>
          <ScrollView>
            <FlatList
              keyExtractor={item => item.id}
              renderItem={renderItem}
              data={isMoreVisible ? commentsDataWithPostId : _.take(commentsDataWithPostId, 2)}
              contentContainerStyle={style.contentFlatList}
              showsVerticalScrollIndicator={false}
              scrollEnabled={false}
              listKey={post?.id}
            />
            <AvatarWriteButton
              onPress={handleCreatePostComment}
              avatarSize={45}
              avatarSource={user?.avatar}
              text="Write a comment"
            />
          </ScrollView>
        </Container>
        <CreatePostComment
          contentId={post?.id}
          contentType={contentType}
          onClose={onClosePostComment}
          isVisible={isCreateCommentVisible}
          setVisible={setCreateCommentVisible}
          onCloseMobile={onClosePostComment}
        />
      </>
    )
  }
  // ANCHOR
  if (isMobile && route.name === 'CommentPost') {

    return (
      <ContainerMobile>
        <FlatList
          keyExtractor={item => item.id}
          renderItem={renderItem}
          data={commentsDataWithPostId}
          contentContainerStyle={style.contentFlatListMobile}
        />
        {
          isToogleInputVisible ? (
            <CreateCommentWrapper>
              <Avatar
                size={45}
                elevation={2}
                source={userProfile?.avatar ? { uri: userProfile?.avatar } : null}
              />
              <TextInputWrapper>
                <TextInput
                  value={valueInput}
                  onChangeText={text => setValueInput(text)}
                  selectionColor={theme.basics.brand}
                />
              </TextInputWrapper>
              <SendButton onPress={handleCommentPost}>
                <Icon
                  icon="send"
                  width={22} />
              </SendButton>
            </CreateCommentWrapper>
          ) : (
            <View>
              <AvatarWriteButton
                onPress={handleCreatePostComment}
                avatarSize={45}
                avatarSource={user?.avatar}
                text="Write a comment"
              />
              <CreatePostComment
                contentId={post?.id}
                contentType={contentType}
                onCloseMobile={() => {
                  setCreateCommentVisible(false)
                  if (postType == 'team') {
                    dispatch(teamActions.comment_list({ postType: contentType, id: post?.id }))
                  } else if (postType == 'group') {
                    dispatch(teamActions.comment_list({ postType: contentType, id: post?.id }))
                  } else {
                    dispatch(actions.comment_list({ postType: contentType, id: post?.id }))
                  }
                }}
                isVisible={isCreateCommentVisible}
                setVisible={setCreateCommentVisible}
                fetchPost={fetchPost}
              />
            </View>
          )
        }
      </ContainerMobile >
    )
  }
  return null
}

CommentList.defaultProps = {
  isCommentsVisible: false
}

CommentList.propTypes = {
  post: PostInterface,
  contentType: PropTypes.string,
  postType: PropTypes.string,
  idPresent: PropTypes.number,
  onClosePostComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
  isCommentsVisible: PropTypes.bool
}

export default CommentList