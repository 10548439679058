import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        flex: 1,
        maxWidth: 600,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 0,
        marginHorizontal: 'auto',

        ...Platform.select({
            ios: {

            },
            android: {

            },
            web: {
                minWidth: 600
            }
        })
    },
    LogoContainer: {
        alignItems: 'center',
        marginTop: 20
    },
    ContentContainer: {
        alignItems: 'center',
        marginTop: 30
    },
    WaveIcon: {
        fontSize: 50
    },
    Headline: {
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 32,
        textAlign: 'center',
        marginBottom: 20
    },
    Subheadline: {
        fontSize: 20,
        textAlign: 'center'
    },
    FooterContainer: {
        marginTop: 30,
        marginBottom: 20
    },
    Button: {
    },
    ButtonText: {
        color: '#FFFFFF',
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 17,
        textAlign: 'center',
    }
});