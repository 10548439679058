import React, { useMemo, useEffect, useCallback } from 'react'
import { Text, View } from "react-native";
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Entypo } from '@expo/vector-icons'

import { actions as authActions } from '@/redux/reducers/profile'

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import Icon from '@/components/Icon'
import ContentBlockV1 from '@/components/ContentBlockV1'
import Button from '@/components/Button'
import DefaultButton from '@/components/Buttons/Default'

import ApiHandler from '@/api/ApiHandler'

import styles from './styles.js';

function Welcome({ navigation }) {
    const dispatch = useDispatch()

    useEffect(() => {
        const getUser = async () => {
            const response = await new ApiHandler().getCurrentUser()
            if (response.status == 200 && response.data) {
                dispatch(authActions.fetchProfile(response?.data?.id))
            }
        }
        getUser()
    }, [])

    const handleSubmit = useCallback(() => {
        navigation.navigate('PersonalInformation')
    })

    return (
        <TheaterLayout>
            <View style={styles.Container}>
                <ContentBlockV1>
                    <View style={styles.LogoContainer}>
                        <Icon
                            icon="reframeHorizontalLogo"
                            height={50}
                            width={170}
                        />
                    </View>

                    <View style={styles.ContentContainer}>
                        <Text style={styles.WaveIcon}>👋</Text>

                        <Text style={styles.Headline}>
                            {'Welcome to\nReframe!'}
                        </Text>

                        <Text style={styles.Subheadline}>
                            Let&apos;s get started.
                        </Text>
                    </View>

                    <View style={styles.FooterContainer}>
                        <DefaultButton
                            label={(
                                <>
                                    <Text>{'Get Started'}</Text>
                                    <Entypo name="chevron-right" size={16} color="white" />
                                </>
                            )}
                            onPress={handleSubmit}
                        />
                        {/*
                        <Button color="black" contentStyle={{ width: 291 }} style={styles.Button} onPress={handleSubmit}>
                            <Text style={styles.ButtonText}>Get started</Text>
                            <Entypo name="chevron-right" size={24} color="white" />
                        </Button>
                        */}
                    </View>
                </ContentBlockV1>
            </View>
        </TheaterLayout>
    )
}

Welcome.propTypes = {
    navigation: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }).isRequired,
}

export default Welcome
