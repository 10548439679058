import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function ArrowDown(props) {
  return (
      <Svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M11 1L6 6L1 1" fill={props.color} stroke="#33475B" stroke-width="2" />
      </Svg>
    )
}

ArrowDown.propTypes = SvgProps

export default ArrowDown