import request from '@/helpers/request'

export async function getEventList({ 
  page,
  featured = 0,
  read_later = 0,
  employee_lifecycle_stage_id = null,
  five_s_category_id = null,
  search
}) {
  return await request(`education/events`, {
    method: 'GET',
    qsParams: {
      page,
      employee_lifecycle_stage_id,
      five_s_category_id,
      search,
      featured,
      read_later,
    },
  })
}

export async function createEvent(object) {
  return await request('education/events', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function updateEvent({ id, ...event }) {
  return await request(`education/events/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...event }),
  })
}

export async function getEventWithId({ id ,language_id=null}) {
  return await request(`education/events/${id}`, {
    method: 'GET',
    qsParams: { id,language_id },
  })
}

export async function toggleFeatured(object) {
  return await request('event/toggleFeatured', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function toggleStatus(object) {
  return await request('event/toggleStatus', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function toggleReadLater(object) {
  return await request('event/readLater', {
    method: 'POST',
    body: JSON.stringify({ ...object }),
  })
}

export async function deleteEvent({ id }) {
  return await request(`education/events/${id}`, {
    method: 'DELETE',
  })
}

export async function trackActiveEventTime({ eventId, timeTracked }) {
  return await request('learning/tracking/recordView', {
    method: 'POST',
    body: {
      content_type: "App\\Event",
      content_id: parseInt(eventId),
      seconds_viewed_for: parseInt(timeTracked),
    },
  })
}