import React, { useState, useCallback } from 'react'
import {
  TextInput,
  Keyboard,
  KeyboardAvoidingView
} from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { ActivityIndicator } from 'react-native-paper'
import * as brandSelectors from '@/redux/selectors/branding'
import * as selectors from '@/redux/selectors/posts'
import { getMember } from '@/redux/selectors/auth'
import { actions } from '@/redux/reducers/posts'
import {
  Icon,
  Avatar,
  Button,
  FeedCardAuthorInfo, SimpleModal
} from '@/components'
import { CaptionMedium } from '@/styles/fonts'
import theme from '@/styles/themes/black'
import { ScreenSize, useScreenSize } from '@/styles/screenSize'
import {
  HeaderLabel,
  CloseButton,
  AuthorContainer,
  ContentContainer,
  PostInputWrapper,
  PostButtonWrapper,
  AuthorInfoContainer,
  KeyboardDismiss,
  HeaderContainer,
  styles,
} from './styles'
import { isMobile } from '@/styles'

import MentionsTextBox from '@/components/MentionsTextBox'

const CreatePostComment = ({ onClose, onCloseMobile, contentId, contentType, isVisible, setVisible, fetchPost }) => {


  const branding = useSelector(brandSelectors.getSettings);

  const {
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [comment, setComment] = useState('')

  const loading = useSelector(selectors.loading)
  const member = useSelector(getMember)
  const handleClose = useCallback(() => {
    setVisible(false)
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const handleCloseMobile = useCallback(() => {
    onCloseMobile()
    setComment('')
  }, [onCloseMobile])

  const handleSubmit = useCallback(() => {
    if (contentType == 'App\\Article' || contentType == 'App\\Course' || contentType == 'App\\Event') {
      dispatch(actions.comment_in_article({
        comment,
        contentType,
        contentId
      }, () => {
        if (isMobile) {
          fetchPost();
          handleCloseMobile()
          return
        }
        handleClose()
      }))
      return
    }
    dispatch(actions.comment({
      comment,
      contentType,
      contentId
    }, () => {
      if (isMobile) {
        fetchPost();
        handleCloseMobile()
        return
      }
      dispatch(actions.comment_list({ postType: contentType, id: contentId }))
      handleClose()
    }))
  }, [dispatch, comment, handleClose, handleCloseMobile])

  return (
    <SimpleModal isVisible={isVisible}>
      <KeyboardAvoidingView behavior="position">
        <ContentContainer isMobile={isMobile}>
          <CloseButton onPress={isMobile ? handleCloseMobile : handleClose}>
            <Icon
              icon="close"
              width={14}
              height={12}
              color={theme.basics.brand}
            />
          </CloseButton>
          <KeyboardDismiss onPress={Keyboard.dismiss}>
            <HeaderContainer>
              <HeaderLabel textAlign="center">
                Create a comment
              </HeaderLabel>
              <AuthorContainer>
                <Avatar size={50} source={member?.avatar ? { uri: member?.avatar } : null} />
                <AuthorInfoContainer>
                  <FeedCardAuthorInfo author={member} />
                </AuthorInfoContainer>
              </AuthorContainer>
            </HeaderContainer>
          </KeyboardDismiss>
          <PostInputWrapper>
            <MentionsTextBox placeholder="Write a comment" text={comment} setText={setComment} />
            {/* <TextInput
              multiline
              textAlignVertical="top"
              value={comment}
              placeholder="Write a comment"
              style={styles.textInput}
              onChangeText={setComment}
            /> */}
          </PostInputWrapper>
          <PostButtonWrapper>
            <Button
              color={color_scheme_primary_button_background ? color_scheme_primary_button_background : "black"}
              onPress={handleSubmit}
              disabled={!comment || loading}
              style={{
                width: screenSize <= ScreenSize.Medium ? '100%' : undefined,
              }}
            >
              {loading && (
                <ActivityIndicator
                  size={20}
                  animating
                  color="white"
                  style={{ marginRight: 10 }}
                />
              )}
              <CaptionMedium
                color={color_scheme_primary_button_text ? color_scheme_primary_button_text : "white"}
                style={{ fontSize: 17 }}
              >
                Publish
              </CaptionMedium>
            </Button>
          </PostButtonWrapper>
        </ContentContainer>
      </KeyboardAvoidingView>
    </SimpleModal>
  )
}

CreatePostComment.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onCloseMobile: PropTypes.func,
  contentId: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired
}

export default CreatePostComment
