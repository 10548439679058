import React, { useEffect } from 'react'
import { Linking } from 'react-native'
import { useNavigation } from '@react-navigation/native'

const Index = () => {
  const navigation = useNavigation()
  useEffect(() => {
    gotoNew()
  }, [])

  const gotoNew = async () => {
    const supported = await Linking.canOpenURL('https://support.getreframe.com')
    if (supported) {
      await Linking.openURL('https://support.getreframe.com')
      navigation.goBack()
    } else {
      Alert.alert(
        `We are unable to open the support center from the app. Please visit support.getreframe.com in your web browser for support.`,
      )
    }
  }

  return (
    <>
    </>
  )
}

export default Index
