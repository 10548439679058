import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, Platform, StyleSheet, TouchableOpacity, Alert } from 'react-native'
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import {
  Title,
  Header,
  TitleWrapper,
  Tabs,
  Tab,
  TabTitle,
} from './styles'
import PropTypes from 'prop-types'
// import ExpansiveMenu from '../ExpansiveMenu'
import Toast from 'react-native-toast-message'
import { ScreenSize, useScreenSize } from '@/styles'
import { Layouts } from './Layouts'
import { Ionicons } from '@expo/vector-icons'
import ExpansiveMenu from '@/pages/SharedFolders/ExpansiveMenu'

const TabsHeader = ({ setTab, tab, onLayout, layout }) => {
  const Item = ({ title }) => (
    <View style={{ padding: 20, width: '100%' }}>
      <Text style={{ fontSize: 32 }}>{title}</Text>
    </View>
  );

  const renderItem = ({ item }) => (
    <Item title={item.title} />
  );

  useEffect(() => {
    // setTab('myfolders')
    //setTab('sharedwithme')
  }, [])

  const expansiveChildrens = []

  const screenSize = useScreenSize()

  return (
    <>
      <Tabs horizontal={true} style={{ height: isMobile ? 60 : 60 }}>

        <Tab onPress={() => { setTab('myfolders') }} >
          <TabTitle selected={tab === 'myfolders'} isMobile={true} >My Files</TabTitle>
        </Tab>

        <Tab onPress={() => { setTab('sharedwithme') }}>
          <TabTitle selected={tab === 'sharedwithme'} isMobile={true} >Shared With Me</TabTitle>
        </Tab>

      </Tabs>
    </>
  )
}

TabsHeader.propTypes = {
  setTab: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
}

export default TabsHeader

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 10,
    paddingRight: 30,
  },
  createfolderfooter: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    marginTop: 20,
    // paddingBottom: 10,
    flexDirection: 'row',
  },
  createFolderCancel: {
    width: 120,
    height: '35%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 3,
    marginEnd: 10,
  },
  createFolderSubmit: {
    backgroundColor: '#000',
    width: '100%',
    height: '35%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
})

