import _ from 'lodash'

export const loading = (state) => _.get(state, 'article.loading')

export const lifecycles = (state) => _.get(state, 'article.lifecycles')

export const categories = (state) => _.get(state, 'article.categories')

export const data = (state) => _.get(state, 'article.data')
export const dataNextPage = (state) => _.get(state, 'article.dataNextPage')
export const dataLoading = (state) => _.get(state, 'article.dataLoading')

export const featured = (state) => _.get(state, 'article.featured')
export const featuredNextPage = (state) => _.get(state, 'article.featuredNextPage')
export const featuredLoading = (state) => _.get(state, 'article.featuredLoading')

export const readLater = (state) => _.get(state, 'article.readLater')
export const readLaterNextPage = (state) => _.get(state, 'article.readLaterNextPage')
export const readLaterLoading = (state) => _.get(state, 'article.readLaterLoading')

export const article = (state) => _.get(state, 'article.article')
export const articleTimeTrack = (state) => _.get(state, 'article.timeTracked')
