import React, { useRef, useEffect, useCallback } from "react";
import { Animated, TouchableOpacity, Text, Linking, Platform, SafeAreaView, View, ScrollView, Image } from "react-native";
import PropTypes from "prop-types";

export default function LeftSideDrawerLayout({
    children,
    scrollDisabled
}) {
    return (
        <View
            style={{
                flex: 1,
                position: 'relative',
                height: '100%'
            }}
        >
            {scrollDisabled ? (
                children
            ) : (
                <ScrollView>
                    {children}
                </ScrollView>
            )}
        </View>
    );
}

LeftSideDrawerLayout.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    backEnabled: PropTypes.bool,
    children: PropTypes.node,
};