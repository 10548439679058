import ApiHandler from "@/api/ApiHandler";
import { MessageStatus } from "@/contexts/ChatContext";
import {
    CONVERSATION_MESSAGES,
    CONVERSATION_PAGE_SIZE,
    PARTICIPANT_MESSAGES,
    UNEXPECTED_ERROR_MESSAGE,
} from "./helpers/constants";
import { successNotification, unexpectedErrorNotification } from "./helpers";

export async function addConversation(
    name,
    updateParticipants,
    client,
) {
    if (name.length > 0 && client !== undefined) {
        try {
            const conversation = await client.createConversation({
                friendlyName: name,
            });
            await conversation.join();

            const participants = await getConversationParticipants(conversation);
            updateParticipants(participants, conversation.sid);
            return conversation;
        } catch (e) {
            alert(e);
            return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
        }
    }
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function addParticipant(
    name,
    proxyName,
    chatParticipant,
    convo,
    addNotifications
) {
    if (chatParticipant && name.length > 0 && convo !== undefined) {
        try {
            const result = await convo.add(name);
            successNotification({
                message: PARTICIPANT_MESSAGES.ADDED,
                addNotifications,
            });
            return result;
        } catch (e) {
            console.log(e);
            return Promise.reject(e);
        }
    }
    if (
        !chatParticipant &&
        name.length > 0 &&
        proxyName.length > 0 &&
        convo !== undefined
    ) {
        try {
            const result = await convo.addNonChatParticipant(proxyName, name, {
                friendlyName: name,
            });
            successNotification({
                message: PARTICIPANT_MESSAGES.ADDED,
                addNotifications,
            });

            return result;
        } catch (e) {
            console.log(e);
            unexpectedErrorNotification(addNotifications);

            return Promise.reject(e);
        }
    }
    return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
}

export async function getToken(userID) {
    const res = await new ApiHandler().authenticateWithTwilioChat({ id: userID });
    return res.data?.data ?? null;
}

export async function getMessageStatus(
    conversation,
    message,
    channelParticipants,
    loggedInUser
) {
    const statuses = {
        [MessageStatus.Delivered]: 0,
        [MessageStatus.Read]: 0,
        [MessageStatus.Failed]: 0,
        [MessageStatus.Sending]: 0,
    };

    if (message.index === -1) {
        return Promise.resolve({
            ...statuses,
            [MessageStatus.Sending]: 1,
        });
    }

    channelParticipants.forEach((participant) => {
        if (
            participant.identity == loggedInUser ||
            participant.type !== "chat"
        ) {
            return;
        }

        if (
            participant.lastReadMessageIndex &&
            participant.lastReadMessageIndex >= message.index
        ) {
            statuses[MessageStatus.Read] += 1;
        } else if (participant.lastReadMessageIndex !== -1) {
            statuses[MessageStatus.Delivered] += 1;
        }
    });

    if (message.aggregatedDeliveryReceipt) {
        const receipts = await message.getDetailedDeliveryReceipts();
        receipts.forEach((receipt) => {
            if (receipt.status === "read") {
                statuses[MessageStatus.Read] += 1;
            }

            if (receipt.status === "delivered") {
                statuses[MessageStatus.Delivered] += 1;
            }

            if (receipt.status === "failed" || receipt.status === "undelivered") {
                statuses[MessageStatus.Failed] += 1;
            }

            if (receipt.status === "sent" || receipt.status === "queued") {
                statuses[MessageStatus.Sending] += 1;
            }
        });
    }

    return statuses;
}

export const getConversationParticipants = async (
    conversation
) => await conversation.getParticipants();

export const removeParticipant = async (
    conversation,
    participant,
) => {
    try {
        await conversation.removeParticipant(participant);
    } catch (e) {
        console.log(e);
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
};

export const getBlobFile = async (
    media,
    addNotifications
) => {
    try {
        const url = await getFileUrl(media);
        const response = await fetch(url);
        return response.blob();
    } catch (e) {
        unexpectedErrorNotification(addNotifications);
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
};

export const getFileUrl = async (media) => {
    return await media.getContentTemporaryUrl()
};

export const getMessages = async (
    conversation
) => await conversation.getMessages(CONVERSATION_PAGE_SIZE);
