import request from '@/helpers/request'

export async function store(object) {
  return await request('articles/store', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: object,
  })
}

export async function storeDraft() {
  return await request('articles/draft', {
    method: 'POST',
  })
}

export async function storeImages(object) {
  return await request('articles/upload', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: object,
  })
}

export async function deleteImage(object) {
  return await request('articles/delete_file', {
    method: 'DELETE',
    body: object,
  })
}

export async function update(object) {
  return await request('articles/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: object,
  })
}

export async function index({ page, featured, read_later, filter, search }) {
  return await request('articles/index', {
    method: 'GET',
    qsParams: {
      page,
      featured: featured ? 1 : 0,
      read_later: read_later ? 1 : 0,
      ...filter,
      search,
    },
  })
}

export async function dataprovider() {
  return await request('articles/dataprovider', {
    method: 'GET',
  })
}

export async function readLater(article) {
  return await request('articles/read_later', {
    method: 'PUT',
    body: article,
  })
}

export async function show({ id,language_id=null }) {
  return await request('articles/show', {
    method: 'GET',
    qsParams: { id,language_id },
  })
}

export async function remove({ id }) {
  return await request('articles/delete', {
    method: 'DELETE',
    qsParams: { id },
  })
}

export async function trackActiveArticleTime({ articleId, timeTracked }) {
  return await request('learning/tracking/recordView', {
    method: 'POST',
    body: {
      content_type: "App\\Article",
      content_id: parseInt(articleId),
      seconds_viewed_for: parseInt(timeTracked),
    },
  })
}
