import { useEffect, useMemo, useRef, useContext, useState } from "react";
import { Theme } from '@twilio-paste/core/theme'
import { CustomizationProvider } from '@twilio-paste/core/customization'
import { Client } from "@twilio/conversations";
import { Box } from "@twilio-paste/core";
import ConversationContainer from "./components/conversations/ConversationContainer";
import ConversationsContainer from "./components/conversations/ConversationsContainer";
import { getConversationParticipants, getToken } from "../api";
import stylesheet from "./styles";
import { handlePromiseRejection } from "../helpers";
import ChatContext from "@/contexts/ChatContext";
import { useSelector } from "react-redux";
import { getMember } from "@/redux/selectors/auth";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import ApiHandler from "@/api/ApiHandler";

async function loadUnreadMessagesCount(
  convo,
  updateUnreadMessages
) {
  const count = await convo.getUnreadMessagesCount();
  updateUnreadMessages(convo.sid, count ?? 0);
}

async function handleParticipantsUpdate(participant, updateParticipants) {
  const result = await getConversationParticipants(participant.conversation);
  updateParticipants(result, participant.conversation.sid);
}

async function updateConvoList(
  client,
  conversation,
  setConvos,
  addMessages,
  updateUnreadMessages,
) {
  if (conversation.status === "joined") {
    const messages = await conversation.getMessages();
    addMessages(conversation.sid, messages.items);
  } else {
    addMessages(conversation.sid, []);
  }

  loadUnreadMessagesCount(conversation, updateUnreadMessages);

  const subscribedConversations = await client.getSubscribedConversations();
  setConvos(subscribedConversations.items);
}

const AppContainer = () => {
  const [client, setClient] = useState();
  const user = useSelector(getMember);
  const accentColor = UseBrandingAccentColor();

  const {
    token,
    conversations,
    sid,
    addMessages,
    updateToken,
    updateLoadingState,
    updateParticipants,
    updateUnreadMessages,
    startTyping,
    endTyping,
    listConversations,
    removeMessages,
    removeConversation,
    updateCurrentConversation,
    addNotifications,
    workspaceMembers
  } = useContext(ChatContext);

  const sidRef = useRef("");
  sidRef.current = sid;



  const updateTypingIndicator = async (
    participant,
    sid,
    callback
  ) => {
    const { identity, } = participant;

    if (identity === user?.id?.toString()) {
      return;
    }

    let userInfo = workspaceMembers?.find(member => member.id == identity);

    if (!userInfo) {
      const res = await new ApiHandler().getChatParticipant({ id: identity });
      userInfo = res?.data?.data;
    }


    callback(sid, userInfo?.first_name);
  };
  useEffect(() => {
    const client = new Client(token);
    setClient(client);

    client.on("conversationAdded", async (conversation) => {
      conversation.on("typingStarted", (participant) => {
        handlePromiseRejection(
          () =>
            updateTypingIndicator(participant, conversation.sid, startTyping),
          addNotifications
        );
      });

      conversation.on("typingEnded", (participant) => {
        handlePromiseRejection(
          () => updateTypingIndicator(participant, conversation.sid, endTyping),
          addNotifications
        );
      });

      if (conversation.status === "joined") {
        const result = await getConversationParticipants(conversation);
        updateParticipants(result, conversation.sid);
      }

      updateConvoList(
        client,
        conversation,
        listConversations,
        addMessages,
        updateUnreadMessages,
      );


    });

    client.on("conversationRemoved", (conversation) => {
      updateCurrentConversation("");
      handlePromiseRejection(() => {
        removeConversation(conversation.sid);
        updateParticipants([], conversation.sid);
      }, addNotifications);
    });

    client.on("conversationLeft", (conversation) => {
      updateConvoList(client, conversation, listConversations, addMessages, updateUnreadMessages);
    });


    client.on("messageAdded", (message) => {
      addMessage(event, addMessages, updateUnreadMessages);
    });
    client.on("participantLeft", (participant) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications
      );
    });
    client.on("participantUpdated", (event) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(event.participant, updateParticipants),
        addNotifications
      );
    });
    client.on("participantJoined", (participant) => {
      handlePromiseRejection(
        () => handleParticipantsUpdate(participant, updateParticipants),
        addNotifications
      );
    });
    client.on("conversationUpdated", ({ conversation }) => {
      updateConvoList(
        client,
        conversation,
        listConversations,
        addMessages,
        updateUnreadMessages,
      )
    });

    client.on("messageUpdated", ({ message }) => {
      updateConvoList(
        client,
        message.conversation,
        listConversations,
        addMessages,
        updateUnreadMessages,
      )
    });

    client.on("conversationJoined", (conversation) => {
      updateConvoList(
        client,
        conversation,
        listConversations,
        addMessages,
        updateUnreadMessages,
      )
    })

    client.on("messageRemoved", (message) => {
      handlePromiseRejection(
        () => removeMessages(message.conversation.sid, [message]),
        addNotifications
      );
    });

    client.on("tokenExpired", () => {
      getToken(user.id).then(token => {
        updateToken(token);
      })
    });

    updateLoadingState(false);

    return () => {
      client?.removeAllListeners();
    };
  }, []);

  function addMessage(
    message,
    addMessages,
    updateUnreadMessages
  ) {
    //transform the message and add it to redux
    handlePromiseRejection(() => {
      if (sidRef.current === message.conversation.sid) {
        message.conversation.updateLastReadMessageIndex(message.index);
      }
      addMessages(message.conversation.sid, [message]);
      loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
    }, addNotifications);
  }

  const openedConversation = useMemo(
    () => conversations.find((convo) => convo.sid === sid),
    [sid, conversations]
  );

  return (
    <Box style={{ flex: 1, position: 'relative' }}>
      <CustomizationProvider baseTheme="default" theme={{
        "backgroundColors": {
          "colorBackgroundPrimary": "#f05343",
          "colorBackgroundPrimaryWeak": "rgba(240, 83, 67, 0.5)",
        },
      }}>
        <Box style={{ flex: 1, position: 'absolute', top: 0, left: 0, bottom: 0, right: 0 }}>
          <Box style={stylesheet.appWrapper}>
            <Box style={stylesheet.appContainer}>
              <ConversationsContainer client={client} />
              <Box style={stylesheet.messagesWrapper}>
                <ConversationContainer
                  conversation={openedConversation}
                  client={client}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomizationProvider>
    </Box>
  );
};

export default AppContainer;




