import ApiHandler from "@/api/ApiHandler";
import { getConversationParticipants } from "@/pages/TwilioConversations/api";

export const getUserRoleInCurrentConversation = async (userId, convo) => {
    try {
        const participants = await getConversationParticipants(convo);
        const currentUser = participants?.find(part => part.identity == userId.toString());
        const userRole = currentUser?.roleSid;

        if (userRole) {
            const res = await new ApiHandler().getChatRole(userRole);
            const roles = res.data;
            return roles;
        } else return undefined;


    } catch (error) {
        console.log(error);
    }
}
