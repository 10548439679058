import styled from 'styled-components/native'
import { Dimensions, StyleSheet } from 'react-native'
import { Headline6 } from '@/styles/fonts'
import { isMobile } from '@/styles'

const { width, height } = Dimensions.get('window')

export const styles = StyleSheet.create({
	contentScroll: {
		width,
		height,
		alignSelf: 'center'
	},
	scroll: {
		width,
		height,
	}
})

export const Container = styled.View`
	width: 100%;
	max-width: 600px;
	padding: 0 20px 20px 20px;
	background: ${({ theme }) => theme.basics.background_white};
`
export const Header = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 16px 0 24px;
	z-index: 1;
	position: relative;

`
export const BackButton = styled.TouchableOpacity`
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	left: 0;
	position: absolute;
`
export const Title = styled(Headline6)``

export const ContainerCoverImage = styled.View`
	width: 168px;
	height: 151.2px;
	position: relative;
	background-color: #D9D9D9;
	border-radius: 5px;
`

export const CoverButton = styled.TouchableOpacity`
	position: absolute;
	bottom: -10px;
	right: -10px;
	width: 36px;
	height: 36px;
	border-radius: 36px;
	background-color: ${({ theme }) => theme.basics.background_white};
	justify-content: center;
	align-items: center;
	elevation: 7;
`

export const CoverImage = styled.Image`
	height: 100%;
	width: 100%;
`

export const CoverWrapper = styled.View`
	align-self: center;
	align-items: center;
	border-top-width: 1px;
	border-top-color: #D9D9D9;
	border-bottom-width: 1px;
	border-bottom-color: #D9D9D9;
	padding: 0 0 22px;
`
