export default {
  'roles': [
    {
      'id': 1,
      'name': 'Super Administrator',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T14:06:44.000000Z',
      'updated_at': '2021-08-31T14:06:44.000000Z'
    },
    {
      'id': 2,
      'name': 'Administrator',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T14:06:44.000000Z',
      'updated_at': '2021-08-31T14:06:44.000000Z'
    },
    {
      'id': 3,
      'name': 'Community Manager',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T14:06:44.000000Z',
      'updated_at': '2021-08-31T14:06:44.000000Z'
    },
    {
      'id': 4,
      'name': 'Learning Editor',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T14:06:44.000000Z',
      'updated_at': '2021-08-31T14:06:44.000000Z'
    },
    {
      'id': 5,
      'name': 'Reporting',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T14:06:44.000000Z',
      'updated_at': '2021-08-31T14:06:44.000000Z'
    },
    {
      'id': 6,
      'name': 'Assessments Manager',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T14:06:44.000000Z',
      'updated_at': '2021-08-31T14:06:44.000000Z'
    },
    {
      'id': 7,
      'name': 'Standard',
      'guard_name': 'api',
      'company_id': null,
      'created_at': '2021-08-31T19:23:01.000000Z',
      'updated_at': '2021-08-31T19:23:01.000000Z'
    }
  ]
}
