import ChatContext from '@/contexts/ChatContext';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import React, { useContext } from 'react'
import { ActivityIndicator } from 'react-native-paper';
import AppContainerMobile from './AppContainerMobile';
import { View } from 'react-native';

const App = () => {
    const token = useContext(ChatContext);
    const accentColor = UseBrandingAccentColor();

    return (
        <>
            {!token ? (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <ActivityIndicator color={accentColor} />
                </View>
            ) : <AppContainerMobile />
            }
        </>
    )
}

export default App;