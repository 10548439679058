import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Generic(props) {
    return (
        <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2H6ZM13 9V3.5L18.5 9H13Z" fill={props.color} />
        </Svg>
    )
}

Generic.propTypes = SvgProps

export default Generic;
