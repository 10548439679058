import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'


// Constants
export const constants = {
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    SUBSCRIPTION_META: createSagaAction('SUBSCRIPTION_META'),

}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {

    updateMeta: (meta) => {
        return {
            type: constants.SUBSCRIPTION_META.ACTION,
            payload: meta,
        }
    }

}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
    subscription: false
}

export default createReducer(initialState, (state, action) => {
    switch (action.type) {
        case constants.SUBSCRIPTION_META.ACTION: {
            return {
                ...state,
                subscription: action.payload
            }
        }

        case constants.AUTH_LOGOUT: {
            return initialState
        }

        default: {
            return state;
        }
    }
})
