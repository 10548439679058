
import ChatContext from "@/contexts/ChatContext";
import { getMember } from "@/redux/selectors/auth";
import { getUserRoleInCurrentConversation } from "@/utils/getUserRoleInCurrentConversation";
import { Box } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";

import Settings from "../settings/Settings";



const ConversationDetails = (
  props
) => {
  const theme = useTheme();
  const user = useSelector(getMember);
  const { updateRoles, sid, roles } = useContext(ChatContext);

  const getUserRoles = async () => {
    const roles = await getUserRoleInCurrentConversation(user.id, props.convo);
    if (roles) {
      updateRoles(roles, sid);
    }
  }

  useEffect(() => {
    if (!roles[sid]) {
      getUserRoles();
    }
  }, [sid])


  return (
    <Box
      style={{
        minHeight: 65,
        maxHeight: 65,
        paddingLeft: 16,
        paddingRight: 16,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColors.colorBorderWeak,
      }}
    >
      <Box
        style={{
          height: "7vh",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
          color="colorText"
          fontFamily="fontFamilyText"
          fontSize="fontSize50"
          lineHeight="lineHeight80"
          fontWeight="fontWeightBold"
          maxHeight="100%"
        >
          {props.convo.friendlyName ?? props.convo.sid}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
            color="colorTextWeak"
            fontFamily="fontFamilyText"
            fontSize="fontSize30"
            lineHeight="lineHeight40"
            fontWeight="fontWeightNormal"
            paddingRight="space60"
          >
            {`${props.participants.length}`}
            {props.participants.length > 1 ? " participants" : " participant"}
          </Box>
          <Settings convo={props.convo} participants={props.participants} />
        </Box>
      </Box>
    </Box>
  );
};

export default ConversationDetails;
