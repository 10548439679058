
import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

function GroupMob() {
    return (
        <Svg width="40" height="47" viewBox="0 -6 40 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <Path d="M22.652 10.923L20.8085 9.32918C20.5956 9.14562 20.5147 8.85181 20.6101 8.58739L21.4107 6.28865C21.5503 5.89213 21.3005 5.4735 20.8967 5.39263L18.495 4.92982C18.2233 4.87834 18.0028 4.66539 17.9516 4.3864L17.4963 1.99226C17.4154 1.58093 16.9968 1.3386 16.6003 1.47822L14.3015 2.27878C14.0371 2.36692 13.7433 2.29334 13.5597 2.0804L11.9586 0.236931C11.6869 -0.078977 11.1947 -0.078977 10.923 0.236931L9.3292 2.0804C9.14563 2.29335 8.85182 2.3742 8.5874 2.27878L6.28865 1.47822C5.89214 1.3386 5.47351 1.58846 5.39263 1.99226L4.92982 4.3864C4.87835 4.65811 4.6654 4.87859 4.38641 4.92982L1.99226 5.39263C1.58094 5.47349 1.3386 5.8921 1.47823 6.28865L2.27878 8.58739C2.36692 8.85182 2.29335 9.14562 2.0804 9.32918L0.236931 10.923C-0.0789771 11.1947 -0.0789771 11.6869 0.236931 11.9586L2.0804 13.5525C2.29335 13.736 2.37421 14.0298 2.27878 14.2943L1.47823 16.593C1.3386 16.9895 1.58846 17.4081 1.99226 17.489L4.38641 17.9518C4.65812 18.0033 4.87859 18.2163 4.92982 18.4952L5.39263 20.8894C5.47349 21.3007 5.89211 21.543 6.28865 21.4034L8.5874 20.6029C8.85182 20.5147 9.14563 20.5883 9.3292 20.8012L10.923 22.6447C11.1948 22.9606 11.687 22.9606 11.9586 22.6447L13.5525 20.8012C13.736 20.5883 14.0298 20.5074 14.2943 20.6029L16.593 21.4034C16.9895 21.543 17.4082 21.2932 17.489 20.8894L17.9518 18.4952C18.0033 18.2235 18.2163 18.0031 18.4953 17.9518L20.8894 17.489C21.3007 17.4082 21.5431 16.9895 21.4034 16.593L20.6029 14.2943C20.5147 14.0298 20.5883 13.736 20.8013 13.5525L22.6447 11.9586C22.9679 11.6869 22.9679 11.1947 22.652 10.923H22.652ZM16.424 10.8864L14.1692 12.7886L14.8744 15.6529C15.0065 16.189 14.4191 16.6149 13.949 16.3286L11.4446 14.7642L8.94021 16.3211C8.47011 16.6149 7.88251 16.189 8.01481 15.6453L8.71996 12.7811L6.46519 10.8788C6.03929 10.519 6.26681 9.82867 6.81776 9.79177L9.75557 9.57882L10.8645 6.84666C11.0702 6.33262 11.7972 6.33262 12.0101 6.84666L13.119 9.57882L16.0568 9.79177C16.6224 9.83622 16.8426 10.5266 16.424 10.8864H16.424Z" fill="white"/>
        <Path d="M14.5527 23.041L13.6419 24.0987C13.091 24.7376 12.2906 25.1049 11.4459 25.1049C10.6014 25.1049 9.80085 24.7378 9.24992 24.0987L8.33912 23.041L7.01727 23.4963C6.76766 23.5844 6.51051 23.6359 6.24609 23.6505V32.2288C6.24609 32.8016 6.54718 33.3377 7.03938 33.6315L10.5939 35.7688C11.1155 36.0772 11.7616 36.0772 12.2832 35.7688L15.8377 33.6315C16.3299 33.3377 16.631 32.8016 16.631 32.2288V23.6505C16.3666 23.6359 16.1095 23.5844 15.8599 23.4963L14.5527 23.041Z" fill="white"/>
        </Svg>



    );
}

export default GroupMob;