import { css } from 'styled-components'

export const shadow1 = css`
  box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.05);
`
export const shadow2 = css`
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
`
export const shadow3 = css`
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.15);
`
export const shadowLearningCard = css`
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
`
