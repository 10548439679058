import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import React from 'react'
import { Container, Letter } from './styles'
const AvatarTemplate = ({ size, letter, fontSize, style={}, }) => {
  const accentColor = UseBrandingAccentColor();
  return (
    <Container size={size} style={[{backgroundColor: accentColor}, style]}>
      <Letter fontSize={fontSize}>{letter}</Letter>
    </Container>
  )
}

export default AvatarTemplate
