import request from '@/helpers/request'

export async function index() {
  return await request('admin/roles/index', {})
}

export async function store({ name, permissions }) {
  return await request('admin/roles/store', {
    method: 'POST',
    body: JSON.stringify({
      name,
      permissions
    }),
  })
}

export async function update({ id, name, permissions }) {
  return await request('admin/roles/update', {
    method: 'PUT',
    body: JSON.stringify({
      id,
      name,
      permissions
    }),
  })
}

export async function remove({ id  }) {
  return await request('admin/roles/delete', {
    method: 'DELETE',
    qsParams: {
      id,
    },
  })
}

export async function dataprovider() {
  return await request('admin/roles/dataprovider', {})
}
