import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/employees'
import * as api from '@/redux/api/employees'

// INDEX
function* index(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.INDEX.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.INDEX.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchIndex() {
  yield takeLatest(constants.INDEX.ACTION, index)
}

// SHOW
function* show(action) {
  try {
    const payload = yield call(api.show, action)
    yield put({ type: constants.SHOW.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.SHOW.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchShow() {
  yield takeLatest(constants.SHOW.ACTION, show)
}

// DATAPROVIDER
function* dataprovider(action) {
  try {
    const payload = yield call(api.dataprovider, action)
    yield put({ type: constants.DATAPROVIDER.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.DATAPROVIDER.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchDataprovider() {
  yield takeLatest(constants.DATAPROVIDER.ACTION, dataprovider)
}


// DATAPROVIDER
function* invite(action) {
  try {
    const payload = yield call(api.invite, action)
    yield put({ type: constants.INVITE.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.INVITE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchInvite() {
  yield takeLatest(constants.INVITE.ACTION, invite)
}

// DELETE EMPLOYEE
function* deleteEmployee(action) {
  try {
    const payload = yield call(api.deleteEmployee, action)
    yield put({ type: constants.DELETE.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.DELETE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchDeleteEmployee() {
  yield takeLatest(constants.DELETE.ACTION, deleteEmployee)
}

// EDIT EMPLOYEE
function* edit(action) {
  try {
    const payload = yield call(api.update, action)
    yield put({ type: constants.EDIT.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.EDIT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}


function* watchEditEmployee() {
  yield takeLatest(constants.EDIT.ACTION, edit)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchIndex),
    fork(watchShow),
    fork(watchDataprovider),
    fork(watchInvite),
    fork(watchDeleteEmployee),
    fork(watchEditEmployee)
  ])
}
