import React from 'react';
import FOLDER from '@/assets/icons/storage-icons/folder';
import GENERIC from '@/assets/icons/storage-icons/generic';
import IMAGE from '@/assets/icons/storage-icons/image';
import PDF from '@/assets/icons/storage-icons/pdf';
import SHARED from '@/assets/icons/storage-icons/Shared';
import TEXT from '@/assets/icons/storage-icons/text';
import VIDEO from '@/assets/icons/storage-icons/video';
import WORD from '@/assets/icons/storage-icons/wordDoc';

import { View } from 'react-native';

export const Types = {
    IMAGE: "file-image",
    GENERIC: "GENERIC",
    FOLDER: "folder",
    PDF: "file-pdf",
    TEXT: "file-text",
    VIDEO: "file-video",
    WORD: "file-word"
}

const Icon = ({ type, shared }) => {
    const renderIcon = () => {
        switch (type) {
            case Types.FOLDER:
                return <FOLDER width={25} height={25} color="black" />
            case Types.IMAGE:
                return <IMAGE width={25} height={25} color="black" />
            case Types.PDF:
                return <PDF width={25} height={25} color="black" />
            case Types.TEXT:
                return <TEXT width={25} height={25} color="black" />
            case Types.VIDEO:
                return <VIDEO width={25} height={25} color="black" />
            case Types.WORD:
                return <WORD width={25} height={25} color="black" />
            default:
                return <GENERIC width={25} height={25} color="black" />
        }
    }

    return (
        <View style={{ width: 25, height: 25, position: 'relative' }}>
            {renderIcon()}
            {shared && (
                <View style={{ padding: 4, position: 'absolute', bottom: -6, right: -6, backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', borderRadius: 50, shadowColor: "#ccc", shadowOffset: { width: 1, height: 1 }, elevation: 5, shadowOpacity: 0.9, shadowRadius: 7 }}>
                    <SHARED width={10} height={10} color="black" />
                </View>
            )}
        </View>
    )
}

export default Icon;
