import { useIsMobile } from '@/styles'
import React from 'react'
import { Dimensions, Modal, View } from 'react-native'

const { width, height } = Dimensions.get('screen');

const FloatingModal = ({ visible, onDismiss, children }) => {
    const isMobile = useIsMobile();
    return (
        <Modal visible={visible} transparent>
            <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.4)', alignItems: 'center', justifyContent: 'center' }}>
                <View style={isMobile ? { width: width / 1.1, alignSelf: 'center', aspectRatio: 16 / 9, backgroundColor: 'white', padding: 20, borderRadius: 10 } : { height: '40%', padding: 20, paddingHorizontal: 30, aspectRatio: 16 / 9, backgroundColor: "white", borderRadius: 10, }}>
                    {children}
                </View>
            </View>
        </Modal>
    )
}

export default FloatingModal;