import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import {
  ListViewEmployee,
  Icon,
  Checkbox,
  Breadcrumb,
  BackArrow,
} from '@/components'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import canAccess from '@/helpers/permissions'
import { useDispatch, useSelector } from 'react-redux'
import * as employeesSelectors from '@/redux/selectors/employees'
import * as brandSelectors from '@/redux/selectors/branding'
import { actions as employeesActions } from '@/redux/reducers/employees'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import {
  Container,
  Header,
  LeftIconButton,
  SectionHeader,
  TitleHeader,
  SectionTitle,
  FilterContainer,
  TextFilter
} from '@/pages/Community/styles/innerPages'
import { ProgressBar } from 'react-native-paper'
import InviteEmployee from '@/pages/Community/Employee/InviteEmployee'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const Employee = ({ navigation }) => {
  const [withDeactivated, setWithDeactivated] = useState('unchecked')
  const [inviteEmployeeModal, setInviteEmployeeModal] = useState(false)
  const [editableEmployee, setEditableEmployee] = useState({
    employee: {},
    visible: false,
    confirmation: false,
  })
  const branding = useSelector(brandSelectors.getSettings);
  const { member } = branding?.taxonomies;

  const dispatch = useDispatch()

  const employees = useSelector(employeesSelectors.data)

  const closeEditeEmployee = useCallback(() => {
    setEditableEmployee(prevState => {
      return {
        ...prevState,
        visible: false,
        confirmation: false
      }
    })
  }, [setEditableEmployee])

  useEffect(() => {
    dispatch(employeesActions.index({}, (err) => { console.log('err', err) }))
  }, [dispatch])

  const toggleWithDeactivated = useCallback(() => {
    if (withDeactivated === 'checked') {
      setWithDeactivated('unchecked')
      dispatch(employeesActions.index({
        page: 1,
        withDeactivated: false
      }, () => { }))
      return
    }
    setWithDeactivated('checked')
    dispatch(employeesActions.index({
      page: 1,
      withDeactivated: true
    }, () => { }))
  }, [withDeactivated, setWithDeactivated])

  const employeesNextPage = () => {
    if (employees.loading || !employees.nextPage) {
      return
    }
    dispatch(employeesActions.index({
      page: employees.page + 1,
      withDeactivated: withDeactivated === 'checked'
    }, () => { }))
  }

  const toggleEmployeeModal = () => {
    setInviteEmployeeModal(!inviteEmployeeModal)
  }

  const expansiveChildrens = [
    {
      text: `Invite ${member?.singular}`,
      icon: 'email',
      onPress: () => {
        setInviteEmployeeModal(true)
      }
    },
    // { text: 'Import from Slack', icon: 'importExport', onPress: () => {}},
  ]

  const breadcrumbs = [
    { name: 'Community', navigation: 'Community' },
    { name: 'Employees' },
  ]

  const {
    color_scheme_primary_button_background,
    color_scheme_primary_button_text
  } = branding;
  return (
    <>
      <PageHeader
        title={member?.plural}
        icon={'community'}
        backEnabled={true}
        breadcrumbs={[
          { name: 'Community', navigation: 'Community' },
          { name: member?.plural },
        ]}
        actions={
          [
            {
              title: 'Invite',
              type: 'menu',
              permissions: [
                'CREATE_GROUPS',
              ],
              menu: [
                {
                  title: `Invite ${member?.singular}`,
                  icon: 'email',
                  permissions: [
                    'CREATE_GROUPS'
                  ],
                  onPress: () => {
                    setInviteEmployeeModal(true)
                  }
                },
              ]
            }
          ]
        }
      />

      <PageContent>
        <ContentBlockV1>
          <FilterContainer>
            <Checkbox status={withDeactivated} onPress={toggleWithDeactivated} />
            <TextFilter>Show deactivated {member?.plural}</TextFilter>
          </FilterContainer>
          <ListViewEmployee
            employeeListData={employees.data}
            onEndReachedThreshold={0.5}
            onEndReached={employeesNextPage}
            setEditableEmployee={setEditableEmployee}
          />
        </ContentBlockV1>
      </PageContent>

      <InviteEmployee
        isVisible={inviteEmployeeModal}
        onBack={toggleEmployeeModal}
      />
      <InviteEmployee
        isVisible={editableEmployee?.visible}
        onBack={closeEditeEmployee}
        editableEmployee={editableEmployee}
        setEditableEmployee={setEditableEmployee}
        edit
      />
    </>
  )
}

Employee.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  })
}

export default Employee
