import _ from 'lodash'

export const loading = (state) => _.get(state, 'learning.loading')
export const featured = (state) => _.get(state, 'learning.featured')
export const filtered = (state) => _.get(state, 'learning.filtered')
export const articles = (state) => _.get(state, 'learning.articles')
export const courses = (state) => _.get(state, 'learning.courses')
export const events = (state) => _.get(state, 'learning.events')

export const readLaterLoading = (state) =>
  _.get(state, 'learning.readLaterLoading')
