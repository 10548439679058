import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Toast from 'react-native-toast-message'

import { vh, vw } from 'react-native-expo-viewport-units'
import { TextField } from '@/components'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { actions as authActions } from '@/redux/reducers/auth'
import { ActivityIndicator } from 'react-native-paper'
import { Entypo } from '@expo/vector-icons'
import * as Yup from 'yup'
import { isMobile, useScreenSize } from '@/styles'
import {
  TextButton,
  SubmitButton,
  CreatePasswordPageContent,
} from './styles'
import OnboardingPage from '@/components/OnboardingPage'
import ResetPasswordDecoration from '@/assets/onboarding/ResetPasswordDecoration'
import ToastWeb from '@/components/ToastWeb'

const initialValues = {
  password: '',
  password_confirmation: '',
}

const validationSchema = Yup.object({
  password: Yup.string().required('Password is required')
    .min(8, 'Required min 8 characters'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required()
    .min(8, 'Required min 8 characters')
})

function ResetPassword({ navigation }) {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = useCallback(
    (values) => {
      setLoading(true)
      dispatch(
        authActions.resetPassword(
          {
            password: values.password,
            password_confirmation: values.password_confirmation,
          },
          (error) => {
            setLoading(false)
            if (error) {
              if (isMobile) {
                Toast.show({
                  type: 'error',
                  position: 'bottom',
                  text1: 'Error, please try again.',
                })
                return
              }
              setError(true)
            } else {
              navigation.navigate('Welcome')
              //dispatch(authActions.toggleFirstAccess())
            }
          },
        ),
      )
    },
    [navigation],
  )

  return (
    <OnboardingPage
      invert
      styleDecorationWrapper={{ left: 200 }}
      header={<OnboardingHeader justifyContent="flex-end" />}
      decoration={<ResetPasswordDecoration width={vw(45)} height={vh(100)} />}
    >
      <CreatePasswordPageContent screenSize={screenSize}>
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <OnboardingFormWrapper title={'Create your\npassword'}>
              <TextField
                label="Password"
                style={{ paddingTop: 46 }}
                value={values.password}
                autoCapitalize="none"
                secureTextEntry
                onChangeText={handleChange('password')}
              />
              <TextField
                style={{ paddingTop: 17 }}
                label="Confirm password"
                value={values.password_confirmation}
                autoCapitalize="none"
                secureTextEntry
                onChangeText={handleChange('password_confirmation')}
              />
              <SubmitButton
                color="black"
                disabled={!isValid}
                style={{ marginTop: 24 }}
                onPress={handleSubmit}
              >
                {loading && (
                  <ActivityIndicator
                    size={20}
                    animating
                    color="white"
                    style={{ marginRight: 10 }}
                  />
                )}
                <TextButton>Create password</TextButton>
                <Entypo name="chevron-right" size={24} color="white" />
              </SubmitButton>
              <ToastWeb
                text="Error, please try again."
                visible={error}
                setVisible={setError}
                duration={3500}
              />
            </OnboardingFormWrapper>
          )}
        </Formik>
      </CreatePasswordPageContent>
    </OnboardingPage>
  )
}

ResetPassword.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default ResetPassword
