import React, { useEffect, useState } from "react";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import { Modal, View, Text, Dimensions, TouchableOpacity } from 'react-native'
import { TextInput } from 'react-native-paper'
import Button from "@/components/Chat/components/Button";
import DefaultButton from '@/components/Buttons/Default'
import { Entypo, MaterialIcons } from "@expo/vector-icons";
import ModalV2 from '@/components/ModalV2'

const { width, height } = Dimensions.get('screen');

const ConversationTitleModal = (props) => {
    const [title, setTitle] = useState(props.title);
    const [error, setError] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const accentColor = UseBrandingAccentColor();


    useEffect(() => {
        if (props.title !== title) {
            setTitle(props.title);
        }
        setIsLoading(false);
    }, [props.title]);

    const onCancel = () => {
        setError("");
        setTitle(props.title);
        setIsLoading(false);
        props.onCancel();
    };

    const onSave = async () => {
        if (title.length < 1) {
            return;
        }

        setError("");

        try {
            setIsLoading(true);
            await props.onSave(title);
        } catch (e) {
            setIsLoading(false);
            setError(e.message ?? "");
        }

        setIsLoading(false);
        setTitle(props.title);
    };

    return (
        <ModalV2
            isVisible={props.isModalOpen}
            setVisibility={onCancel}
            title={props.type == "new" ? "New Conversation" : "Edit Conversation"}
        >
            <View style={{}}>
                <TextInput
                    style={{ backgroundColor: "white", fontFamily: 'WorkSans_400Regular' }}
                    label="Conversation Name"
                    placeholder="Conversation Name"
                    placeholderTextColor="#888"
                    onBlur={() => setIsFormDirty(true)}
                    onChangeText={setTitle}
                    value={title}
                />
                {!!error ? (
                    <View style={{ marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
                        <MaterialIcons name="error" color="red" size={12} />
                        <Text style={{ fontSize: 12, color: "red", padding: 5 }}>{error}</Text>
                    </View>
                ) : isFormDirty && !title ? (
                    <View style={{ marginVertical: 5, flexDirection: 'row', alignItems: 'center' }}>
                        <MaterialIcons name="error" color="red" size={12} />
                        <Text style={{ fontSize: 12, color: "red", padding: 5 }}>Add a conversation title to create a conversation.</Text>
                    </View>
                ) : null}
            </View>
            <View style={{ marginTop: 10 }}>
                <DefaultButton
                    disabled={title.length < 1 || title.trim() === props.title}
                    isLoading={isLoading}
                    label={'Create'}
                    onPress={() => {
                        onSave()
                    }}
                />
            </View>
        </ModalV2>
    )
}

export default ConversationTitleModal;