import { Dimensions } from 'react-native'
import styled from 'styled-components/native'

const { width } = Dimensions.get('window');

export const ParticipantView = styled.View`
    background: transparent;
    margin: ${7}px;
    border-radius: 8px;
    display: inline-block;
    overflow: hidden;
    width: ${props => props.size}px;
    aspect-ratio: ${16 / 10}px;
    height: ${props => props.size == width / 4 ? width / 6 + "px" : props.size == width / 8 ? width / 12 + 'px' : undefined};
    position: relative;
    float: left;
    background: #202124;

`

export const Avatar = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 50px;
`