import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { View, Text, FlatList, TouchableOpacity, Platform } from 'react-native';

import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    useStripe,
    useElements,
    CardElement,
    PaymentRequestButtonElement
} from '@stripe/react-stripe-js';

import getEnv from "../../../environment";
import ApiHandler from '@/api/ApiHandler';

import { actions as authActions } from "@/redux/reducers/auth";
import { actions as subscriptionActions } from '@/redux/reducers/subscription'

import { getSubscriptionMeta } from '@/redux/selectors/subscription'
import { getHasMultipleWorkspaces } from '@/redux/selectors/auth'

import canAccess from "@/helpers/permissions";

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'
import DefaultButton from '@/components/Buttons/Default'

import styles from './styles.js';

const Paywall = () => {
    const { stripePublishableKey } = getEnv()
    const stripePromise = loadStripe(stripePublishableKey.toString())

    return (
        <Elements stripe={stripePromise} >
            <Content />
        </Elements>
    )
}

const Content = () => {
    const dispatch = useDispatch();

    const stripe = useStripe();
    const elements = useElements();

    const [hasMultipleWorkspaces, setHasMultipleWorkspaces] = useState(useSelector(getHasMultipleWorkspaces));
    const [subscriptionMeta, setSubscriptionMeta] = useState(useSelector(getSubscriptionMeta));
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [currentPlan, setCurrentPlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(false);
    const [subscriptionSummary, setSubscriptionSummary] = useState(false);

    useEffect(() => {
        getSubscriptionPlans()
    }, []);

    useEffect(() => {
        setCurrentPlan(subscriptionMeta?.plan)
    }, [subscriptionMeta]);

    useEffect(() => {
        handleSelectPlan(currentPlan?.id)
    }, [currentPlan]);

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                //   fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                fontWeight: "500",
                "::placeholder": {
                    color: "#E3E3E3",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    const getSubscriptionPlans = async () => {
        try {
            const response = await new ApiHandler().getSubscriptionPlans();
            if (response?.data) {
                setSubscriptionPlans(response?.data?.data);
            }
        } catch (error) {

        }
    }

    const getSubscriptionSummary = async (plan_id) => {
        try {
            const response = await new ApiHandler().getSubscriptionSummary(plan_id);
            if (response?.data) {
                setSubscriptionSummary(response?.data?.data);
            }
        } catch (error) {

        }
    }

    const handleLogout = useCallback(() => {
        dispatch(authActions.logout());
    }, [dispatch]);

    const handleSwitchWorkspace = useCallback(() => {
        dispatch(authActions.selectedWorkspace(false));
    }, [dispatch]);

    const handleSelectPlan = (id) => {
        setSelectedPlan(id)
        getSubscriptionSummary(id)
    };

    const handleSubscribe = () => {
        const card = elements.getElement(CardElement);
        stripe.createPaymentMethod('card', card).then(async (result) => {
            if (result?.paymentMethod?.id) {
                const data = {
                    plan_id: selectedPlan,
                    payment_method_id: result?.paymentMethod?.id
                }

                try {
                    const response = await new ApiHandler().processSubscription(data);
                    if (response?.data) {
                        try {
                            const metaresponse = await new ApiHandler().getSubscriptionMeta()
                            if (metaresponse?.data) {
                                const meta = metaresponse?.data?.data || {}
                                dispatch(subscriptionActions.updateMeta(meta));
                            }
                        } catch (error) {
                            console.log("error", error)
                        }
                    }
                } catch (error) {
                    Toast.show({
                        type: 'error',
                        position: 'bottom',
                        text1: "Something went wrong!",
                    })
                }
            } else {
                Toast.show({
                    type: 'error',
                    position: 'bottom',
                    text1: "There was an issue with the card provided. Please try again.",
                })
            }
        })
    }

    const renderPlanCard = ({ item }) => {
        return (
            <TouchableOpacity
                onPress={() => {
                    handleSelectPlan(item?.id)
                }}
                style={[
                    styles.PlanCard,
                    {
                        backgroundColor: item.id == selectedPlan && '#f05343',
                        color: item.id == selectedPlan && '#FFFFFF'
                    }
                ]}
            >
                <Text style={styles.PlanCardPlanName}>{item?.name}</Text>
                <Text style={styles.PlanCardBillingTerm}>{item?.billing_term}</Text>
            </TouchableOpacity>
        )
    }

    return (
        <TheaterLayout>
            <View style={styles.Container}>
                <ContentBlockV1>
                    {canAccess('ManageBilling') ? (
                        <>
                            {Platform.OS == 'web' ? (
                                <>
                                    <View style={styles.HeaderContainer}>
                                        <Text style={styles.Headline}>{'It Looks Like Your Trial Has Expired!'}</Text>
                                        <Text style={styles.Subheadline}>{'Subscribe to your current plan, or select a new subscription plan below to continue using Reframe. Additionally, you may logout or switch to another active workspace, if associated with multiple workspaces.'}</Text>
                                    </View>

                                    <View style={styles.SubscribeContainer}>
                                        <ContentBlockV1>
                                            <View style={styles.Section}>
                                                <ContentBlockV1
                                                    title={'Select A Plan'}
                                                >
                                                    <View style={styles.Subsection}>
                                                        <Text style={styles.SubsectionHeadline}>Continue With Your Current Plan</Text>
                                                        <Text style={styles.SubsectionSubheadline}>{'Subheadline'}</Text>

                                                        <View style={styles.SubsectionBody}>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    handleSelectPlan(currentPlan?.id)
                                                                }}
                                                                style={[
                                                                    styles.PlanCard,
                                                                    {
                                                                        backgroundColor: currentPlan.id == selectedPlan && '#f05343',
                                                                        color: currentPlan.id == selectedPlan && '#FFFFFF'
                                                                    }
                                                                ]}
                                                            >
                                                                <Text style={styles.PlanCardPlanName}>{currentPlan?.name}</Text>
                                                                <Text style={styles.PlanCardBillingTerm}>{currentPlan?.billing_term}</Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>

                                                    <View style={styles.Subsection}>
                                                        <Text style={styles.SubsectionHeadline}>Or, Select A Different Plan</Text>
                                                        <Text style={styles.SubsectionSubheadline}>{'Subheadline'}</Text>

                                                        <View style={styles.SubsectionBody}>
                                                            <FlatList
                                                                data={subscriptionPlans}
                                                                renderItem={renderPlanCard}
                                                                keyExtractor={item => item.id}
                                                            />
                                                        </View>
                                                    </View>
                                                </ContentBlockV1>
                                            </View>

                                            <View style={styles.Section}>
                                                <ContentBlockV1
                                                    title={'Payment'}
                                                >
                                                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                                                </ContentBlockV1>
                                            </View>

                                            <View style={styles.Section}>
                                                <ContentBlockV1
                                                    title={'Summary'}
                                                >
                                                    <View style={styles.SummaryPlanCard}>
                                                        <View
                                                            style={[
                                                                styles.PlanCard
                                                            ]}
                                                        >
                                                            <Text style={styles.PlanCardPlanName}>{subscriptionSummary?.plan_name}</Text>
                                                            <Text style={styles.PlanCardBillingTerm}>{subscriptionSummary?.billing_term}</Text>
                                                        </View>
                                                    </View>

                                                    <Text style={styles.SummaryTotal}>
                                                        <Text>{'Total Due Today: '}</Text>
                                                        <Text>{subscriptionSummary?.total_due_today}</Text>
                                                    </Text>

                                                    <Text style={styles.SummaryAdditionalNotes}>{subscriptionSummary?.additional_note}</Text>
                                                </ContentBlockV1>
                                            </View>

                                            <View style={styles.Section}>
                                                <DefaultButton
                                                    label={'Subscribe'}
                                                    onPress={handleSubscribe}
                                                />
                                            </View>
                                        </ContentBlockV1>
                                    </View>
                                </>
                            ) : (
                                <>
                                    <View style={styles.HeaderContainer}>
                                        <Text style={styles.Headline}>{'It Looks Like Your Trial Has Expired!'}</Text>
                                        <Text style={styles.Subheadline}>{'To continue your current plan or to select a new plan, visit app.getreframe.com on a desktop browser and login to your account to continue. Additionally, you may logout or switch to another active workspace, if associated with multiple workspaces.'}</Text>
                                    </View>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <View style={styles.HeaderContainer}>
                                <Text style={styles.Headline}>{"It Looks Like Your Workspace's Subscription Has Expired!"}</Text>
                                <Text style={styles.Subheadline}>{'Contact your workspace administrator for more information. Additionally, you may logout or switch to another active workspace, if associated with multiple workspaces.'}</Text>
                            </View>
                        </>
                    )}

                    <View style={styles.ActionsContainer}>
                        {hasMultipleWorkspaces ? (
                            <View style={styles.Action}>
                                <DefaultButton
                                    label={'Switch Workspace'}
                                    onPress={handleSwitchWorkspace}
                                />
                            </View>
                        ) : null}

                        <View style={styles.Action}>
                            <DefaultButton
                                label={'Logout'}
                                onPress={handleLogout}
                            />
                        </View>
                    </View>
                </ContentBlockV1>
            </View>
        </TheaterLayout>
    )
}

export default Paywall;