import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        flex: 1,
        alignItems: "center",

        ...Platform.select({
            ios: {
                justifyContent: "flex-end",
            },
            android: {
                justifyContent: "flex-end",
            },
            web: {
                justifyContent: "center",
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }
        })
    },
    Inner: {
        backgroundColor: "white",
        elevation: 5,

        ...Platform.select({
            ios: {
                paddingVertical: 15,
                paddingHorizontal: 15,
                width: '100%',
                height: '100%',
            },
            android: {
                paddingVertical: 15,
                paddingHorizontal: 15,
                width: '100%',
                height: '100%',
            },
            web: {
                padding: 17,
                maxWidth: 700,
                maxHeight: '90%',
                width: '90%',
                borderRadius: 10,
                shadowColor: 'rgba(2, 2, 2, 0.31)',
                shadowOffset: { width: 2, height: 1 },
                shadowOpacity: 0.2,
                shadowRadius: 20,
            }
        })
    },
    InnerHeader: {
        marginBottom: 25,
        flexDirection: 'row'
    },
    BackContainer: {
        justifyContent: 'center',
    },
    BackButton: {
        marginRight: 15,
        backgroundColor: '#1b1b1b',
        borderRadius: 50,
        justifyContent: 'center',

        ...Platform.select({
            ios: {
                width: 25,
                height: 25,
            },
            android: {
                width: 25,
                height: 25,
            },
            web: {
                width: 25,
                height: 25,
                alignItems: 'center',
            }
        })
    },
    TitleContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '65%',
        justifyContent: 'center'
    },
    Title: {
        fontFamily: "WorkSans_400Regular",
        ...Platform.select({
            ios: {
                fontSize: 20,
                lineHeight: 20,
            },
            android: {
                fontSize: 20,
                lineHeight: 20,
            },
            web: {
                fontSize: 22,
                lineHeight: 22,
            }
        })
    },
    ActionsContainer: {
        justifyContent: 'center',
        alignItems: 'flex-end',
        flex: 1
    },
    InnerBody: {

    }
});
