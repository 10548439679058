import { MaterialIcons } from '@expo/vector-icons';
import React, { useContext, useEffect, useState } from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import VideoContext from '../context';
import { formatUserName } from '@/utils/format';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate';

const ParticipantView = ({ isMobile, participant, onPress }) => {
    const { getParticipant } = useContext(VideoContext);
    const [userInfo, setUserInfo] = useState();
    const accentColor = UseBrandingAccentColor();

    useEffect(() => {
        const user = getParticipant(participant.identity);
        setUserInfo(user);
    }, [])

    return (
        <TouchableOpacity disabled onPress={onPress} style={{ padding: 10, flexDirection: 'row', marginVertical: 10, width: '100%', paddingHorizontal: 20, alignItems: 'center' }}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                {userInfo?.avatar_url ? (
                    <Image source={{ uri: userInfo?.avatar_url }} style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: accentColor }} />
                ) : (
                    <AvatarTemplate letter={userInfo?.avatar_initials} />
                )}
                <View style={{ marginHorizontal: 10 }} />
                <Text>{formatUserName(userInfo?.first_name, userInfo?.last_name)}</Text>
            </View>
            <View style={{ flex: 1, alignItems: 'flex-end' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '18%' }}>
                    <MaterialIcons name="mic" size={20} color={"black"} />
                    <MaterialIcons name="videocam" size={20} color={"black"} />
                </View>
            </View>
        </TouchableOpacity>
    )
}

export default ParticipantView;