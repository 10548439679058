import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Analytics(props) {
  return (
    <Svg width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
      <Path
        d="M14 8V0H6V6H0V18H20V8H14ZM8 2H12V16H8V2ZM2 8H6V16H2V8ZM18 16H14V10H18V16Z"
        fill={props.color || 'white'}
      />
    </Svg>
  )
}

Analytics.propTypes = Svg.propTypes
