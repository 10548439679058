/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from '@/styles'
import { Icon } from '@/components'
import { Paragraph1 } from '@/styles/fonts'
import theme from '@/styles/themes/black'
import {
  Container,
  CoverContainer,
  MemberContainer,
  Title,
  FakeInput,
  BackButton,
  RowContainer,
} from './styles'
import GroupTeamsMemberItem from '../GroupTeamsMemberItem'

const GroupTeamsMemberCard = ({
  members,
  isTeam,
  isOwner,
  isManageMemberViewVisible,
  handleRemoveMember,
  onBack,
}) => {
  if (!isManageMemberViewVisible) {
    return null
  }

  return (
    <Container isMobile={isMobile}>
      <CoverContainer isMobile={isMobile}>
        <RowContainer>
          <BackButton onPress={onBack}>
            <Icon icon="arrowLeft" height={14} width={14} color={theme.basics[900]} />
          </BackButton>
          <Title>Members</Title>
        </RowContainer>
        <FakeInput>
          <Paragraph1 color={theme.basics[600]}>{'Search members'}</Paragraph1>
          <Icon icon="searchIcon" width={22} />
        </FakeInput>
      </CoverContainer>
      <MemberContainer>
        <ScrollView>
          {(members ?? []).map(member => (
            <GroupTeamsMemberItem
              key={member?.id}
              member={member}
              isTeam={isTeam}
              isOwner={isOwner}
              handleRemoveMember={handleRemoveMember}
            />
          ))}
        </ScrollView>
      </MemberContainer>
    </Container>
  )
}

GroupTeamsMemberCard.propTypes = {
  members: PropTypes.array,
  isTeam: PropTypes.bool,
  isOwner: PropTypes.bool,
  isManageMemberViewVisible: PropTypes.bool,
  handleRemoveMember: PropTypes.func.isRequired,
  onBack: PropTypes.func
}

GroupTeamsMemberCard.defaultProps = {
  members: [],
  isTeam: true,
  isOwner: false,
  isManageMemberViewVisible: false,
}

export default GroupTeamsMemberCard
