import React, { useEffect, useState } from 'react'
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    ScrollView,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    ActivityIndicator,
    Image,
    Platform,
    Linking
} from 'react-native'
import moment from "moment";
import Modal from "react-native-modal";
import Toast from 'react-native-toast-message'
import isUrl from 'is-url';
import { MaterialCommunityIcons } from '@expo/vector-icons'

import { getMember } from '@/redux/selectors/auth'
import { useDispatch, useSelector } from 'react-redux'
import ApiHandler from '@/api/ApiHandler'

import * as brandSelectors from '@/redux/selectors/branding'

import { Icon } from '@/components'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import ButtonPrimary from '@/components/ButtonPrimary'

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import { Loader } from '@/components/Loader'
import { ATTEND_STATUS, INVITEE_TYPE } from './consts';

import getEnv from '../../../environment'
const { frontendBaseURL } = getEnv()

const COLOR_PRIMARY = "#F05343";

const EventDetail = ({
    id,
    navigation,
    setShowModal = () => { },
    showEditModal = () => { },
    showDuplicateModal = () => { },
    setReloading = () => { },
}) => {
    const user = useSelector(getMember)

    const branding = useSelector(brandSelectors.getSettings);

    const eventId = id;


    const [isLoading, setLoading] = useState(false);
    const [eventInfo, setMeetingInfo] = useState({});

    const [confirmRemove, setConfirmRemove] = useState(false);


    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text
    } = branding;

    const currentUserId = user?.id || -1;
    const eventOrganiserId = eventInfo?.calendar_entry?.organizer_id || -2;

    const isOwner = currentUserId == eventOrganiserId

    useEffect(() => {
        getEventInfo()
    }, [eventId])


    const expansiveChildrens = isOwner ? [
        {
            text: 'Edit',
            onPress: () => {
                setShowModal(false)
                showEditModal(eventInfo)

            }
        },
        {
            text: 'Delete',
            onPress: () => { setConfirmRemove(true) }
        },
        {
            text: 'Duplicate',
            onPress: () => {
                setShowModal(false)
                showDuplicateModal(eventInfo)
            }
        }
    ] : []



    const getEventInfo = async () => {

        setLoading(true);
        try {

            const response = await new ApiHandler().getCalendarEntries(eventId);

            setMeetingInfo(response?.data?.data || {});
        } catch (error) {

        } finally {
            setLoading(false);
        }

    }

    const deleteEvent = async (recurring = 0) => {

        setLoading(true)

        try {
            const data = {
                if_change_recurring: recurring
            }
            const response = await new ApiHandler().deleteCalendarEntry(eventId, data);

            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
                visibilityTime: 2000
            })
            setConfirmRemove(false)
            setShowModal(false)
            setReloading(true)
        } catch (error) {

        } finally {
            setLoading(false)
        }


    }


    const setEventAttendStatus = async (status) => {


        setLoading(true)

        try {
            const data = {
                calendar_entry_id: eventId,
                status_id: status,
                external: 0
            }
            const response = await new ApiHandler().setEventAttendStatus(data);

            getEventInfo()
            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
                visibilityTime: 2000
            })


        } catch (error) {

        } finally {
            setLoading(false)
        }


    }

    const Header = () => {
        return (
            <View style={{ flexDirection: "row", justifyContent: "space-between", zIndex: 11 }} >
                {
                    isOwner
                    &&
                    <ExpansiveMenu
                        mainIconColor={color_scheme_primary_button_background ? color_scheme_primary_button_background : theme.basics.brand}
                        mainIconBackground={'transparent'}
                        btnTextColor={color_scheme_primary_button_text}
                        expansiveChildrens={expansiveChildrens}
                    />

                }
            </View >

        )
    }

    const Border = () => (
        <View style={{ borderBottomColor: "#959595", borderBottomWidth: 0.75, flex: 1 }} />
    )

    const UpperSection = () => {
        const calendar_entry = eventInfo?.calendar_entry || {};

        const startEndTimeStr = `${moment.parseZone(calendar_entry.start).format("MMM, DD, YYYY hh:mm A")} - ${moment.parseZone(calendar_entry.end).format("hh:mm A")}`


        return (
            <View>

                <View style={{ flexDirection: "row" }} >
                    <View style={styles.circle} />
                    <View style={{ flex: 1 }} >
                        <Text style={styles.meetingTitle} >{calendar_entry?.title}</Text>
                        <Border />
                    </View>

                </View>

                <View style={{ flexDirection: "row", marginTop: 30 }} >
                    <Icon
                        icon="calendar"
                        width={22}
                        height={22}
                    />
                    <View style={{ marginLeft: 16, flex: 1 }} >
                        <Text style={styles.sectionHead} >{"Date and Time"}</Text>
                        <Text style={styles.sectionHeadInfo} >{calendar_entry?.id && startEndTimeStr}</Text>
                        <Text style={{ fontSize: 12, fontFamily: 'WorkSans_400Regular', marginTop: 5 }}>{user?.timezone?.display_name}</Text>
                    </View>

                </View>

                <View style={{ flexDirection: "row", marginTop: 30 }} >
                    <Icon
                        icon="locationPin"
                        width={22}
                        height={22}
                    />
                    <View style={{ marginLeft: 16, flex: 1 }} >
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }} >
                            <Text style={styles.sectionHead} >{"Location"}</Text>


                            {/* <TouchableOpacity
                                onPress={() => {
                                    Clipboard.setString(calendar_entry.location);
                                    Toast.show({
                                        type: 'success',
                                        position: 'top',
                                        text1: "Copied to clipboard",
                                        visibilityTime: 2000
                                    })
                                }}
                            >
                                <Icon
                                    icon="copy"
                                    width={19}
                                    height={27}
                                />
                            </TouchableOpacity> */}
                        </View>
                        {
                            isUrl(calendar_entry.location)
                                ?
                                <TouchableOpacity
                                    onPress={() => {
                                        Linking.canOpenURL(calendar_entry.location).then(supported => {
                                            if (supported) {
                                                Linking.openURL(calendar_entry.location);
                                            }
                                        })

                                    }}
                                >
                                    <Text style={[styles.sectionHeadInfo, { color: "#F05343" }]} >{calendar_entry?.location?.toLowerCase()}</Text>
                                </TouchableOpacity>
                                :
                                <Text style={styles.sectionHeadInfo} >{calendar_entry.location}</Text>
                        }

                    </View>

                </View>

                <View
                    style={{
                        flexDirection: "row",
                        marginTop: 30,
                        alignItems: "flex-start"
                    }}
                >
                    <Icon
                        icon="alignLeft"
                        width={22}
                        height={22}
                    />
                    <View style={{ marginLeft: 16, flex: 1 }} >
                        <Text style={[styles.sectionHeadInfo, { fontSize: 15, marginTop: 0 }]} >{calendar_entry.description}</Text>


                    </View>

                </View>



            </View>
        )
    }

    const LowerSection = () => {
        const guests = eventInfo?.calendar_entry?.guests || [];
        const external_guests = eventInfo?.calendar_entry?.external_guests || [];
        const twilioMeetingId = eventInfo?.calendar_entry?.video_conferencing?.twilio_meeting_id;


        const countGoing = eventInfo?.yes || 0;
        const countNo = eventInfo?.no || 0;
        const countpending = eventInfo?.pending || 0;
        const countGuests = eventInfo?.guests_count || 0;

        return (
            <>
                {
                    twilioMeetingId
                    &&
                    <View style={{ flexDirection: "row", alignItems: "center" }} >
                        <Icon
                            icon="video"
                            width={22}
                            height={20}
                        />
                        {/* <TouchableOpacity
                            onPress={() => {

                                const path = Platform.OS == "web" ? "videocall-web" : "videocall";

                                if (isWeb) {
                                    setShowModal(false)
                                    navigation.navigate(path, { meetingId: agoraMeetingId.toString() });
                                } else {

                                    navigation.navigate("chat", {
                                        screen: path,
                                        params: {
                                            meetingId: agoraMeetingId.toString()
                                        }
                                    });
                                }

                            }}
                            style={{
                                marginLeft: 16,
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between"
                            }}
                        >
                            <Text style={styles.meetingTitle} >{"Join Meeting"}</Text>


                            <Icon
                                icon="copy"
                                width={19}
                                height={27}
                            />
                        </TouchableOpacity> */}

                        <ButtonPrimary
                            label={"Join Meeting"}
                            customStyles={{ flex: 1, marginLeft: 20, minHeight: 40 }}
                            onPress={() => {
                                const path = "meeting";
                                setShowModal(false);
                                Linking.openURL(frontendBaseURL + 'meeting/' + twilioMeetingId.toString())
                                //navigation.navigate(path, { id: twilioMeetingId.toString() });
                            }}
                        />

                    </View>
                }
                <View style={{ flexDirection: "row", marginTop: 30 }} >
                    <Icon
                        icon="community"
                        width={22}
                        height={22}
                    />
                    <View style={{ marginLeft: 16, flex: 1 }} >

                        <Text style={[styles.sectionHeadInfo, { fontSize: 15 }]} >{`${countGuests} Guests`}</Text>
                        {
                            (
                                () => {

                                    return <Text style={styles.respondents} >{`${countGoing}: Yes\n${countNo}: No\n${countpending}: Pending`}</Text>
                                }
                            )()

                        }
                        <FlatList
                            data={guests}
                            keyExtractor={item => item?.id}

                            renderItem={({ item, index }) => {

                                const guest = item;
                                const guestType = guest?.guest_type;

                                const avatarSource =
                                    guest?.guest?.avatar
                                        ?
                                        guest?.guest?.avatar
                                        :
                                        guest?.guest?.image
                                            ?
                                            guest?.guest?.image
                                            :
                                            null;


                                let user = "";
                                let nameThumbnail = "";

                                let isEmployee = false;
                                let icon, color;

                                if (guestType === INVITEE_TYPE.employee) {
                                    const f_name = guest?.guest?.first_name?.trim() || "";

                                    const l_name = guest?.guest?.last_name?.trim() || "";

                                    nameThumbnail = `${f_name.charAt(0)}${l_name.charAt(0)}`;


                                    user = `${f_name} ${l_name}`.trim();


                                    const attending_statuses = guest?.guest?.calendar_attending_statuses || [];

                                    icon = "help";
                                    color = "#ff7700"

                                    isEmployee = true;

                                    if (attending_statuses?.length > 0) {

                                        const lastAttendingStatus = attending_statuses[attending_statuses.length - 1];

                                        const Intvite_status = lastAttendingStatus?.calendar_entry_guest_attending_status_id;

                                        if (Intvite_status === ATTEND_STATUS.going) {
                                            icon = "check";
                                            color = "#00872f"
                                        } else if (Intvite_status === ATTEND_STATUS.not_going) {
                                            icon = "close";
                                            color = "#f00"
                                        }
                                    }

                                }
                                else if (guestType === INVITEE_TYPE.group) {

                                    user = guest?.guest?.name?.trim();

                                    const name = user?.split(" ").map(function (item) { return item[0] }).join('');;
                                    nameThumbnail = name


                                } else if (guestType === INVITEE_TYPE.team) {

                                    user = guest?.guest?.name?.trim();

                                    const name = user?.split(" ").map(function (item) { return item[0] }).join('');;

                                    nameThumbnail = name

                                }

                                return (
                                    <View
                                        key={index}


                                        style={{
                                            marginTop: 8,
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}
                                    >
                                        <View


                                            style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: 30,
                                                height: 30,
                                                borderRadius: 50,

                                                backgroundColor: COLOR_PRIMARY,
                                            }}
                                        >
                                            {
                                                avatarSource
                                                    ?
                                                    <Image
                                                        source={{ uri: avatarSource }}
                                                        style={{
                                                            width: 30,
                                                            height: 30,
                                                            borderRadius: 50,
                                                            backgroundColor: COLOR_PRIMARY,
                                                        }}
                                                    />
                                                    :
                                                    <Text style={styles.userNameThumbs} >{nameThumbnail}</Text>
                                            }

                                            {
                                                isEmployee
                                                &&
                                                <View
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: -8,
                                                        backgroundColor: color,
                                                        borderRadius: 20,
                                                        padding: 2
                                                    }}
                                                >
                                                    <MaterialCommunityIcons
                                                        name={icon}
                                                        size={16}
                                                        color={"#fff"}
                                                    />
                                                </View>
                                            }

                                        </View>

                                        <Text style={[styles.userName, { marginLeft: 15 }]} >{user}</Text>
                                    </View>
                                )
                            }}
                        />

                        <FlatList
                            data={external_guests}
                            keyExtractor={item => item?.id}
                            renderItem={({ item, index }) => {

                                const guest = item;

                                let icon, color;


                                const f_name = guest?.name?.trim() || "";


                                const nameThumbnail = `${f_name.charAt(0)}`.toUpperCase();


                                const user = `${f_name}`.trim();


                                const attending_statuses = guest?.attending_statuses || [];

                                icon = "help";
                                color = "#ff7700"


                                if (attending_statuses?.length > 0) {

                                    const lastAttendingStatus = attending_statuses[attending_statuses.length - 1];

                                    const Intvite_status = lastAttendingStatus?.calendar_entry_guest_attending_status_id;

                                    if (Intvite_status === ATTEND_STATUS.going) {
                                        icon = "check";
                                        color = "#00872f"
                                    } else if (Intvite_status === ATTEND_STATUS.not_going) {
                                        icon = "close";
                                        color = "#f00"
                                    }
                                }



                                return (
                                    <View
                                        key={index}


                                        style={{

                                            marginTop: 8,
                                            flexDirection: "row",
                                            alignItems: "center"
                                        }}
                                    >
                                        <View

                                            style={{
                                                alignItems: "center",
                                                justifyContent: "center",
                                                width: 30,
                                                height: 30,
                                                borderRadius: 50,

                                                backgroundColor: COLOR_PRIMARY,
                                            }}
                                        >

                                            <Text style={styles.userNameThumbs} >{nameThumbnail}</Text>

                                            {
                                                true
                                                &&
                                                <View
                                                    style={{
                                                        position: "absolute",
                                                        top: 0,
                                                        right: -8,
                                                        backgroundColor: color,
                                                        borderRadius: 20,
                                                        padding: 2
                                                    }}
                                                >
                                                    <MaterialCommunityIcons
                                                        name={icon}
                                                        size={16}
                                                        color={"#fff"}
                                                    />
                                                </View>

                                            }
                                        </View>

                                        <Text style={[styles.userName, { marginLeft: 15 }]} >{user}</Text>


                                    </View>
                                )
                            }}
                        />
                    </View>

                </View>

            </>
        )
    }

    const ActionButtons = () => {
        return (
            <View
                style={{
                    paddingHorizontal: 12,
                    position: "absolute",
                    paddingBottom: 8,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderTopColor: "#a9a9a9",
                    borderTopWidth: 1.5,
                    paddingTop: 16,
                    zIndex: 999,
                    backgroundColor: "#fff"
                }}
            >
                <View style={{ flexDirection: "row" }} >

                    <ButtonPrimary
                        loading={isLoading}
                        label={"Not Going"}
                        customStyles={styles.actionBtnOutlinedContainer}
                        textStyles={styles.actionBtnOutlinedTxt}
                        onPress={() => {
                            setEventAttendStatus(ATTEND_STATUS.not_going);
                        }}
                    />
                    <View style={{ width: 14 }} />
                    <ButtonPrimary
                        loading={isLoading}
                        label={"Maybe"}
                        customStyles={styles.actionBtnOutlinedContainer}
                        textStyles={styles.actionBtnOutlinedTxt}
                        onPress={() => {
                            setEventAttendStatus(ATTEND_STATUS.maybe);
                        }}
                    />

                </View>
                <View style={{ height: 14 }} />
                <ButtonPrimary
                    loading={isLoading}
                    label={"Going"}
                    customStyles={styles.actionBtnContainer}
                    textStyles={styles.actionBtnTxt}
                    onPress={() => {
                        setEventAttendStatus(ATTEND_STATUS.going);
                    }}
                />

            </View>
        )
    }

    const calendar_entry = eventInfo?.calendar_entry || {};
    const DeleteConfirmModal = React.useMemo(() => {


        let infoMsg = `Are you sure you want to Delete the Event?`
        let if_change_recurring = 0

        const {
            recurring_calendar_entry
        } = calendar_entry


        if (recurring_calendar_entry) {
            infoMsg = `Delete the Current Event only\nor\nEntire Series?`
            if_change_recurring = 1
        } else {
            // this means the is one time only (Do Not Repeat)
        }


        return (

            <Modal isVisible={!!confirmRemove} style={styles.modal} >

                <View style={styles.modalBody} >

                    <Text style={styles.confirmDelete} >{infoMsg}</Text>


                    {
                        if_change_recurring == 1
                            ?
                            <View style={{ flex: 1 }} >
                                <View style={{ flexDirection: "row", marginTop: 24, }} >
                                    <ButtonPrimary
                                        label={"Current Event"}
                                        customStyles={{
                                        }}
                                        onPress={() => deleteEvent(0)}
                                        loading={isLoading}
                                    />
                                    <ButtonPrimary
                                        label={"Entire Series"}
                                        customStyles={{
                                            marginLeft: 12
                                        }}
                                        onPress={() => deleteEvent(1)}
                                        loading={isLoading}
                                    />
                                </View>
                                <ButtonPrimary
                                    label={"Cancel"}

                                    customStyles={styles.cancelBtnOutlinedContainer}
                                    textStyles={styles.confirmDelete}
                                    onPress={() => { setConfirmRemove(false) }}
                                />

                            </View>
                            :
                            <View style={{ flexDirection: "row", marginTop: 24 }} >
                                <ButtonPrimary
                                    label={"Cancel"}
                                    onPress={() => { setConfirmRemove(false) }}
                                />
                                <ButtonPrimary
                                    label={"Confirm"}
                                    customStyles={{
                                        marginLeft: 12
                                    }}
                                    onPress={() => deleteEvent(0)}
                                    loading={isLoading}
                                />
                            </View>

                    }
                </View>
            </Modal >
        )
    }, [isLoading, confirmRemove])

    return (
        <SafeAreaView style={{ flex: 1, backgroundColor: "#fff" }} >

            <View style={{ flex: 1 }} >

                {DeleteConfirmModal}

                <View style={[isMobile ? styles.spacerMobile : styles.spacer]}>

                    <Header />

                </View>

                {isLoading && <Loader />}


                <ScrollView
                    style={isMobile ? styles.mobContainer : styles.container}
                >
                    <View style={[isMobile ? styles.spacerMobile : styles.spacer]}>

                        <UpperSection />

                        <View style={{ flex: 1, marginTop: 32, marginBottom: 24, marginLeft: 38 }} >

                            <Border />

                        </View>

                        <LowerSection />

                        <View style={{ height: 150 }} />
                    </View>

                </ScrollView>


                <ActionButtons />
            </View>

        </SafeAreaView>
    )
}

export default EventDetail

const styles = StyleSheet.create({
    spacer: {
        paddingHorizontal: 45,
        paddingVertical: 25,
        zIndex: 1
    },
    spacerMobile: {
        paddingHorizontal: 15,
        paddingVertical: 20,
    },
    container: {
        flex: 1,
        maxWidth: 700
    },
    mobContainer: {
        flex: 1,
    },

    dropDown: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    dropDownMenuItem: {

        color: theme.basics['black_text'],
        padding: 5,
        fontFamily: 'WorkSans_400Regular',
    },

    circle: {
        height: 24,
        width: 24,
        borderRadius: 12,
        backgroundColor: "#F05343",
        marginRight: 16
    },
    meetingTitle: {
        fontSize: 15,
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics.black_text,
        lineHeight: 25,
        flex: 1,
    },

    sectionHead: {
        fontSize: 13,
        lineHeight: 18,
        fontFamily: 'WorkSans_400Regular',
        color: "#959595"
    },
    sectionHeadInfo: {
        fontSize: 12,
        lineHeight: 16,
        color: "#000000",
        fontFamily: 'WorkSans_400Regular',
        marginTop: 6
    },
    respondents: {
        fontSize: 12,
        fontFamily: 'WorkSans_400Regular',
        color: "#000",
        marginTop: 5

    },
    actionBtnContainer: {
        borderRadius: 3,
        flex: 1,
        backgroundColor: "#F05343",
    },
    actionBtnTxt: {
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 17,
        lineHeight: 20,
        textAlign: "center",
        color: "#fff"

    },
    actionBtnOutlinedContainer: {
        borderColor: "#F05343",
        borderWidth: 1.25,
        borderRadius: 6,
        flex: 1,
        backgroundColor: "transparent",
    },
    actionBtnOutlinedTxt: {
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 17,
        lineHeight: 20,
        textAlign: "center",
        color: "#F05343"

    },


    modal: {
        paddingVertical: 16,
        paddingHorizontal: 12,
        alignItems: "center",
        justifyContent: "center",
    },
    modalBody: {
        alignItems: "center",
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 10,

    },
    cancelBtnOutlinedContainer: {
        borderColor: "#1B1B1B",
        borderWidth: 1.25,
        borderRadius: 6,
        flex: 1,
        backgroundColor: "transparent",
        marginTop: 8
    },

    confirmDelete: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 18,
        color: "#1B1B1B",
        textAlign: "center"
    },
    userNameThumbs: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
        color: "#fff"
    },
    userName: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
        color: "#000"
    }

})
