import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        flex: 1,
        maxWidth: 700,
        width: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 0,
        marginHorizontal: 'auto',

        ...Platform.select({
            ios: {

            },
            android: {

            },
            web: {
                minWidth: 700
            }
        })
    },
    HeaderContainer: {
        marginBottom: 20
    },
    Headline: {
        fontFamily: "WorkSans_400Regular",
        alignSelf: 'center',
        fontSize: 27,
        maxWidth: 300,
        textAlign: 'center',
        fontWeight: '600',
        color: '#f05343',
        marginTop: 25
    },
    Subheadline: {
        fontFamily: "WorkSans_400Regular",
        alignSelf: 'center',
        fontSize: 17,
        maxWidth: 500,
        textAlign: 'center',
        marginTop: 20,
        color: '#545454'
    },
    Section: {
        marginBottom: 20
    },
    Subsection: {
        marginBottom: 15
    },
    SubsectionHeadline: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 14,
        fontWeight: '600',
        color: '#545454'
    },
    SubsectionSubheadline: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 12,
        marginTop: 5
    },
    SubsectionBody: {
        marginTop: 15
    },
    SubscribeContainer: {

    },
    PlanCard: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#f05343',
        padding: 20,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
        marginBottom: 10,
        color: '#000',
        fontFamily: "WorkSans_400Regular",
    },
    PlanCardPlanName: {
        fontFamily: 'inherit',
        fontWeight: '600',
        //color: 'inherit',
        marginBottom: 5
    },
    PlanCardBillingTerm: {
        fontFamily: 'inherit',
        //color: 'inherit'
    },
    SummaryPlanCard: {
        marginBottom: 15
    },
    SummaryTotal: {
        fontFamily: "WorkSans_400Regular",
        fontWeight: '600',
        fontSize: 18,
        marginBottom: 10,
        alignSelf: 'flex-end'
    },
    SummaryAdditionalNotes: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 16
    },
    ActionsContainer: {

    },
    Action: {
        marginBottom: 10
    }
});
