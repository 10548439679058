import { isMobile, ScreenSize } from "@/styles";
import { Dimensions, Platform, StyleSheet, TouchableOpacity } from "react-native";
import styled from "styled-components/native";

const { width } = Dimensions.get('screen')

export const MenuButton = styled.View`
	flex: ${({ flex }) => (flex ? flex : 0.7)};
	align-items: center;
	justify-content: center;
`;

export const Container = styled.View`
	flex-direction: row;
	justify-content: space-evenly;
	background-color: white;
	width: 100%;
	align-self: center;
	border-top-color: #888;
	border-top-width: 1;
	padding-top: 10px;
	padding-left: 5%;
	padding-right: 5%;
	margin-bottom: ${isMobile ? 0 : "20px"};
`;

export const InputContainer = styled.View`
	flex: 3.5;
	padding-right: 10px;
	overflow: hidden;
`;

export const AvatarContainer = styled.View`
	flex: 1;
`;

export const Avatar = styled.Image`
	width: 50px;
	height: 50px;
	align-self: center;
	background-color: ${({ accentColor }) =>
		accentColor ? accentColor : undefined};
	border-radius: 50px;
`;

export const ButtonContainer = styled.View`
	flex: ${({ hideAttachment }) => isMobile ? hideAttachment ? 0.5 : 1 : 0.5};
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const MainView = styled.View`
	flex: 6;
	flex-direction: row;
`;

export const Title = styled.Text`
	font-weight: bold;
	color: white;
`;
