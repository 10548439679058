import { isMobile } from '@/styles'
import { StyleSheet, Platform } from 'react-native';

const styles = StyleSheet.create({
  PageHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 15
  },
  PageTitle: {
    fontFamily: 'WorkSans_400Regular',
    flexWrap: 'wrap',

    ...Platform.select({
      ios: {
        fontSize: 25,
      },
      android: {
        fontSize: 25,
      },
      web: {
        fontSize: 40,
      }
    })
  },
  AssessmentCard: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#F1524F',
    borderStyle: 'solid',
    padding: 20,
    flex: 1,
    marginBottom: 20,

    ...Platform.select({
      ios: {
      },
      android: {
      },
      web: {
        marginLeft: 10,
        marginRight: 10,
      }
    })
  },
  AssessmentCardTitle: {
    fontWeight: "700",
    fontSize: 15,
    color: '#1B1B1B',
    textAlign: 'left',
    fontFamily: "WorkSans_400Regular",
    marginBottom: 3,
  },
  AssessmentCardType: {
    fontSize: 13,
    color: '#F05343',
    lineHeight: 15,
    marginBottom: 5,
    fontFamily: 'WorkSans_400Regular'
  },
  AssessmentCardLifecycleStage: {
    fontSize: 13,
    lineHeight: 18,
    color: 'grey',
    fontFamily: 'WorkSans_400Regular'
  },
  AssessmentCardCategory: {
    fontSize: 13,
    color: 'grey',
    lineHeight: 18,
    fontFamily: 'WorkSans_400Regular'
  },
  AssessmentCardQuestionsCount: {
    fontSize: 13,
    lineHeight: 18,
    color: 'grey',
    fontFamily: 'WorkSans_400Regular'
  },

  title: {
    fontSize: 40,
    fontFamily: 'WorkSans_400Regular'
  },
  assessmentAnalyticsView: {
    backgroundColor: '#fff',
    height: 130,
    width: isMobile ? '96%' : '31%',
    borderTopColor: '#F05343',
    borderTopWidth: 3,
    padding: 10,
    borderRadius: 4,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginVertical: 10,
    marginRight: 10
  },
  assessmentAnalyticsTitle: {
    fontSize: 18,
    lineHeight: 20,
    fontFamily: 'WorkSans_400Regular'
  },
  assessmentAnalyticsPrebuilt: {
    fontSize: 13,
    color: '#F05343',
    lineHeight: 15,
    marginVertical: 3,
    fontFamily: 'WorkSans_400Regular'
  },
  lifeStage: {
    fontSize: 13,
    lineHeight: 18,
    color: 'grey',
    fontFamily: 'WorkSans_400Regular'
  },
  category: {
    fontSize: 13,
    color: 'grey',
    lineHeight: 18,
    fontFamily: 'WorkSans_400Regular'
  },
  questions: {
    fontSize: 13,
    lineHeight: 18,
    color: 'grey',
    fontFamily: 'WorkSans_400Regular'
  },
})

export default styles
