import styled from 'styled-components/native';
import themes from '@/styles/themes/black'
import { shadow2 } from '@/styles/shadows';

export const Container = styled.TouchableOpacity.attrs((/* props */) => ({
    activeOpacity: .7
}))`
    position: absolute;
    right: 0px;
    bottom: 0px;

    height: 52px;
    width: 52px;

    align-items: center;
    justify-content: center;

    margin-bottom: 13px;
    margin-right: 18px;

    border-radius: 52px;

    background-color: ${themes.danger.standard};
    ${shadow2}
`;