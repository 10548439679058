import { store } from "@/redux/store";
import axios from "axios";
import axiosRetry from "axios-retry";

import getEnv from "../../environment";
const { apiUrl } = getEnv();

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";
const request_timeout = 99999;
const connection_abort_timeout = 99999;

const getAuthorization = () => {
  const state = store.getState();
  const token = state.auth && state.auth.token;
  return token ? `Bearer ${token}` : void 0;
};

const getSelectedWorkspace = () => {
  const state = store.getState();
  const selectedWorkspace = state.auth && state.auth.workspace.id;
  return selectedWorkspace ? selectedWorkspace : void 0;
};

const getHeaders = () => {
  return {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: getAuthorization(),
    "Workspace": getSelectedWorkspace()
  };
};
const callAxios = async (_path, method, data = null) => {
  axiosRetry(axios, { retries: 2, shouldResetTimeout: true });
  let response = null;
  let source = axios.CancelToken.source();
  const abortTimeOut = setTimeout(() => {
    // if the response is null after timeout abort the request
    if (response === null) {
      source.cancel && source.cancel();
    }
  }, connection_abort_timeout);

  response = await axios({
    baseURL: apiUrl,
    url: _path,
    method: method,
    headers: getHeaders(),
    timeout: request_timeout,
    data: data ? data : undefined,
    cancelToken: source.token,
    validateStatus: function (status) {
      return status < 500; // Reject only if the status code is greater than or equal to 500
    },
  })
    .catch(function (error) {
      if (axios.isCancel(error)) {
        return {
          name: "Error",
          message: "Request_cancelled",
        };
      }
      console.log("@@@@", error.toJSON());
      return error.toJSON();
    })
    .finally(() => {
      if (abortTimeOut) clearTimeout(abortTimeOut);
    });
  if (response != null) {
    return response;
  } else {
    return {};
  }
};

export default class ApiHandler {
  getUnreadNotiications = async ({ page }) => {
    const response = await callAxios(
      `notifications/retrieve/unread?page=${page}`,
      GET
    );
    return response;
  };

  getReadNotiications = async ({ page }) => {
    const response = await callAxios(
      `notifications/retrieve/read?page=${page}`,
      GET
    );
    return response;
  };

  markNotiicationsAsRead = async () => {
    const response = await callAxios("notifications/update/mark", GET);
    return response;
  };

  onboardingUpdateUserProfile = async (data) => {
    const response = await callAxios("onboarding/profile", PUT, data);
    return response;
  };

  getCurrentUser = async () => {
    const response = await callAxios("auth/me", POST);
    return response;
  };
  updateWorkspaceLogo = async (data) => {
    const response = await callAxios("admin/branding/update-logo", POST, data);
    return response;
  };
  getWorkspaceLogo = async (data) => {
    const response = await callAxios("admin/branding/update-logo", GET, data);
    return response;
  };

  removeWorkspaceLogo = async () => {
    const response = await callAxios("/admin/branding/remove-logo", GET);
    return response;
  };

  getBrandingSettings = async () => {
    const response = await callAxios("admin/branding/index", GET);
    return response;
  };

  getSubscriptionMeta = async () => {
    const response = await callAxios("settings/billing/subscription", GET);
    return response;
  };

  updateWorkspaceColorScheme = async (brandColors) => {
    const response = await callAxios(
      "admin/branding/update-color-scheme",
      POST,
      brandColors
    );
    return response;
  };
  resetWorkspaceColorScheme = async () => {
    const response = await callAxios("admin/branding/reset-color-scheme", GET);
    return response;
  };

  toggleArticleStatus = async (data) => {
    const response = await callAxios("articles/toggleStatus", POST, data);
    return response;
  };

  toggleArticleFeatured = async (data) => {
    const response = await callAxios("articles/toggleFeatured", POST, data);
    return response;
  };

  getDashboardAnalytics = async () => {
    const response = await callAxios(
      "analytics/dashboard/getData?scope=company",
      GET
    );
    return response;
  };

  getCommunityAnalysis = async (queryParams) => {
    const response = await callAxios(
      `analytics/category/community/getData?${queryParams}`,
      GET
    );
    return response;
  };

  getMemberAnalytics = async (queryParams) => {
    const response = await callAxios(
      `analytics/category/engagement/getData?${queryParams}`,
      GET
    );
    return response;
  };

  getPublishingAnalytics = async (queryParams) => {
    const response = await callAxios(
      `analytics/category/article/getData?${queryParams}`,
      GET
    );
    return response;
  };

  getLearningAnalytics = async (queryParams) => {
    const response = await callAxios(
      `analytics/category/course/getData?${queryParams}`,
      GET
    );
    return response;
  };

  getEventAnalytics = async (queryParams) => {
    const response = await callAxios(
      `analytics/category/event/getData?${queryParams}`,
      GET
    );
    return response;
  };

  getTeams = async () => {
    const response = await callAxios("community/teams/getTeams", GET);
    return response;
  };
  getGroups = async () => {
    const response = await callAxios("community/groups/getGroups", GET);
    return response;
  };
  getEmployees = async () => {
    const response = await callAxios("community/employees/getEmployees", GET);
    return response;
  };

  //Assesment Analytics Api Handlers for all categores listings
  getAssesmentPrebuildList = async () => {
    //get prebuild list
    const response = await callAxios(
      "/assessments/prebuilt/getList?status=active",
      GET
    );
    return response;
  };
  getAssesmentCustomList = async () => {
    //get custom list
    const response = await callAxios(
      "/assessments/custom/getList?status=active",
      GET
    );
    return response;
  };
  getAssesmentCulturalList = async () => {
    //get cultural list
    const response = await callAxios(
      "/assessments/culturalmaturity/getList?status=active",
      GET
    );
    return response;
  };
  //Assesment Analytics Api Handlers for individual list
  getAssesmentCulturalData = async (queryParams) => {
    const response = await callAxios(
      `/analytics/assessment/culturalmaturity/getData?${queryParams}`,
      GET
    );
    return response;
  };
  getAssesmentPrebuildData = async (type, id) => {
    const response = await callAxios(
      `/analytics/assessment/standard/getData?type=${type}&id=${id}`,
      GET
    );
    return response;
  };
  getLifecycles = async () => {
    const response = await callAxios("common/provider/getLifecycleStages", GET);
    return response;
  };
  getCategories = async () => {
    const response = await callAxios("common/provider/getFiveSCategories", GET);
    return response;
  };
  getSquads = async () => {
    const response = await callAxios("community/teams/getTeams", GET);
    return response;
  };
  getChapters = async () => {
    const response = await callAxios("community/groups/getGroups", GET);
    return response;
  };

  getCustomAssessment = async (assessment_id) => {
    return await callAxios(
      `assessments/custom/getAssessment?assessment_id=${assessment_id}`,
      GET
    );
  };

  getCulturalMaturityAssessment = async (assessment_id) => {
    return await callAxios(
      `assessments/culturalmaturity/getAssessment?assessment_id=${assessment_id}`,
      GET
    );
  };

  getPrebuiltAssessment = async (assessment_id) => {
    return await callAxios(
      `assessments/prebuilt/getAssessment?assessment_id=${assessment_id}`,
      GET
    );
  };

  getCustomAssessments = async () => {
    return await callAxios("assessments/custom/getList", GET);
  };

  getPreBuiltAssessments = async () => {
    return await callAxios("assessments/prebuilt/getList", GET);
  };

  getCulturalMaturityAssessments = async () => {
    return await callAxios("assessments/culturalmaturity/getList", GET);
  };

  createCustomAssessment = async (data) => {
    const response = await callAxios(
      "assessments/custom/createAssessment",
      POST,
      data
    );
    return response;
  };

  updateCustomAssessment = async (data) => {
    const response = await callAxios(
      "assessments/custom/updateAssessment",
      POST,
      data
    );
    return response;
  };

  deleteCustomAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/custom/deleteAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  addCustomWorkspaceTarget = async (assessment_id) => {
    const response = await callAxios(
      `assessments/custom/addWorkspaceTarget?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  addCulturalMaturityWorkspaceTarget = async (assessment_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/addWorkspaceTarget?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  addPrebuiltWorkspaceTarget = async (assessment_id) => {
    const response = await callAxios(
      `assessments/prebuilt/addWorkspaceTarget?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  removeCustomWorkspaceTarget = async (assessment_id) => {
    const response = await callAxios(
      `assessments/custom/removeWorkspaceTarget?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  removeCulturalMaturityWorkspaceTarget = async (assessment_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/removeWorkspaceTarget?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  removePrebuiltWorkspaceTarget = async (assessment_id) => {
    const response = await callAxios(
      `assessments/prebuilt/removeWorkspaceTarget?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  addCustomGroupTarget = async (assessment_id, group_id) => {
    const response = await callAxios(
      `assessments/custom/addGroupTarget?assessment_id=${assessment_id}&group_id=${group_id}`,
      GET
    );
    return response;
  };

  addCulturalMaturityGroupTarget = async (assessment_id, group_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/addGroupTarget?assessment_id=${assessment_id}&group_id=${group_id}`,
      GET
    );
    return response;
  };

  addPrebuiltGroupTarget = async (assessment_id, group_id) => {
    const response = await callAxios(
      `assessments/prebuilt/addGroupTarget?assessment_id=${assessment_id}&group_id=${group_id}`,
      GET
    );
    return response;
  };

  addCustomTeamTarget = async (assessment_id, team_id) => {
    const response = await callAxios(
      `assessments/custom/addTeamTarget?assessment_id=${assessment_id}&team_id=${team_id}`,
      GET
    );
    return response;
  };

  addCulturalMaturityTeamTarget = async (assessment_id, team_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/addTeamTarget?assessment_id=${assessment_id}&team_id=${team_id}`,
      GET
    );
    return response;
  };

  addPrebuiltTeamTarget = async (assessment_id, team_id) => {
    const response = await callAxios(
      `assessments/prebuilt/addTeamTarget?assessment_id=${assessment_id}&team_id=${team_id}`,
      GET
    );
    return response;
  };

  removeCustomTeamTarget = async (assessment_id, team_id) => {
    const response = await callAxios(
      `assessments/custom/removeTeamTarget?assessment_id=${assessment_id}&team_id=${team_id}`,
      GET
    );
    return response;
  };

  removeCulturalMaturityTeamTarget = async (assessment_id, team_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/removeTeamTarget?assessment_id=${assessment_id}&team_id=${team_id}`,
      GET
    );
    return response;
  };

  removePrebuiltTeamTarget = async (assessment_id, team_id) => {
    const response = await callAxios(
      `assessments/prebuilt/removeTeamTarget?assessment_id=${assessment_id}&team_id=${team_id}`,
      GET
    );
    return response;
  };

  removeCustomGroupTarget = async (assessment_id, group_id) => {
    const response = await callAxios(
      `assessments/custom/removeGroupTarget?assessment_id=${assessment_id}&group_id=${group_id}`,
      GET
    );
    return response;
  };

  removeCulturalMaturityGroupTarget = async (assessment_id, group_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/removeGroupTarget?assessment_id=${assessment_id}&group_id=${group_id}`,
      GET
    );
    return response;
  };

  removePrebuiltGroupTarget = async (assessment_id, group_id) => {
    const response = await callAxios(
      `assessments/prebuilt/removeGroupTarget?assessment_id=${assessment_id}&group_id=${group_id}`,
      GET
    );
    return response;
  };

  createCustomAssessmentQuestion = async (data) => {
    const response = await callAxios(
      "assessments/custom/createQuestion",
      POST,
      data
    );
    return response;
  };

  removeCustomAssessmentQuestion = async (assessment_id, question_id) => {
    const response = await callAxios(
      `assessments/custom/removeQuestion?assessment_id=${assessment_id}&question_id=${question_id}`,
      GET
    );
    return response;
  };

  removeCustomAssessmentOption = async (
    assessment_id,
    question_id,
    option_id
  ) => {
    const response = await callAxios(
      `assessments/custom/removeOption?assessment_id=${assessment_id}&question_id=${question_id}&option_id=${option_id}`,
      GET
    );
    return response;
  };

  activateCustomAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/custom/activateAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  deActivateCustomAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/custom/deactivateAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  activatePrebuiltAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/prebuilt/activateAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  deActivatePrebuiltAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/prebuilt/deactivateAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  activateCulturalMaturityAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/activateAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  deActivateCulturalMaturityAssessment = async (assessment_id) => {
    const response = await callAxios(
      `assessments/culturalmaturity/deactivateAssessment?assessment_id=${assessment_id}`,
      GET
    );
    return response;
  };

  addNewOption = async (assessment_id, question_id, text) => {
    const response = await callAxios(
      `assessments/custom/createOption?assessment_id=${assessment_id}&question_id=${question_id}&text=${text}`,
      GET
    );
    return response;
  };


  getOutboundRecipients = async (assessment_type, assessment_id) => {
    const response = await callAxios(
      `assessments/distribution/recipients?assessment_type=${assessment_type}&assessment_id=${assessment_id}`,
      GET
    );
    return response;
  }

  sendOutboundAssessment = async (data) => {
    const response = await callAxios(
      `assessments/distribution/send`,
      POST,
      data
    );
    return response;
  };

  resendOutboundAssessment = async (id) => {
    const response = await callAxios(
      `assessments/distribution/resend?id=${id}`,
      GET
    );
    return response;
  };

  getOutboundAssessmentLink = async (assessment_type, assessment_id) => {
    const response = await callAxios(
      `assessments/distribution/link?assessment_type=${assessment_type}&assessment_id=${assessment_id}`,
      GET
    );
    return response;
  }

  createOutboundAssessmentLink = async (data) => {
    const response = await callAxios(
      `assessments/distribution/link`,
      POST,
      data
    );
    return response;
  };

  getLinkedAccounts = async () => {
    const response = await callAxios(`/calendar/accounts`, GET);
    return response
  }

  removeLinkedAccount = async (account_id) => {
    return await callAxios(`/calendar/accounts/${account_id}`, DELETE)
  }

  getCalendarEntryTypes = async () => {
    const response = await callAxios(`/calendar/get-calendar-entry-types`, GET);
    return response;
  };

  getCalendarEntries = async (eventId = "") => {
    let url = `/calendar/entries`;
    if (eventId) {
      url = `${url}/${eventId}`;
    }
    return await callAxios(url, GET);
  };

  createCalendarEvent = async (data) => {
    const response = await callAxios(`/calendar/entries`, POST, data);
    return response;
  };

  editCalendarEvent = async (eventId, data) => {
    const response = await callAxios(`/calendar/entries/${eventId}`, PUT, data);
    return response;
  };

  deleteCalendarEntry = async (eventId = "", data) => {
    return await callAxios(`/calendar/delete/${eventId}`, POST, data);
  };

  setEventAttendStatus = async (data) => {
    return await callAxios(`/calendar/update-attending-status`, POST, data);
  };

  getWorkSpaceMembers = async () => {
    try {
      const response = await callAxios(
        "/community/employees/getEmployees",
        GET,
        ""
      );
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  uploadImageToServer = async (image) => {
    try {
      const response = await callAxios("/chat/store", POST, { image });

      return response;
    } catch (error) {
      alert(JSON.stringify(error));
    }
  };

  getMyFolders = async (parentNodeId = "") => {
    if (parentNodeId) {
      let response = await callAxios(
        "node/index/" + parentNodeId + "?node_type=everything&index=1",
        GET,
        undefined
      );
      return response.data;
    } else {
      let response = await callAxios(
        "node/index?node_type=everything&index=1",
        GET,
        undefined
      );
      return response.data;
    }
  };

  getFoldersSharedWithMe = async (parentNodeId = "") => {
    if (parentNodeId) {
      let response = await callAxios(
        "node/index/" + parentNodeId + "?node_type=everything&shared=1&index=1",
        GET,
        undefined
      );
      return response.data;
    } else {
      let response = await callAxios(
        "node/index?node_type=everything&shared=1&index=1",
        GET,
        undefined
      );
      return response.data;
    }
  };

  createFolder = async (data) => {
    const response = await callAxios("node/store", POST, data);
    return response;
  };

  renameFolder = async (nodeId, data) => {
    const response = await callAxios("node/update/" + nodeId, PUT, data);
    return response;
  };

  deleteFolder = async (nodeId) => {
    const response = await callAxios(
      "node/delete/" + nodeId,
      "delete",
      undefined
    );
    return response;
  };

  getSubFolders = async (parentNodeId, shared = 0) => {
    if (shared == 1) {
      const response = await callAxios(
        "node/index/" + parentNodeId + "?node_type=everything&shared=1",
        GET,
        undefined
      );
      return response.data;
    } else {
      const response = await callAxios(
        "node/index/" + parentNodeId + "?node_type=everything",
        GET,
        undefined
      );
      return response.data;
    }
  };

  showFolders = async (parentNodeId) => {
    const response = await callAxios(
      "node/show/" + parentNodeId,
      GET,
      undefined
    );
    return response.data;
  };

  folderListToMoveOn = async (data) => {
    const response = await callAxios(
      `node/index?node_type=folders&shared=0&exclude_node_ids[]=${data}`,
      GET,
      undefined
    );
    return response.data;
  };

  moveFolder = async (data) => {
    const response = await callAxios("node/move", POST, data);
    return response.data;
  };

  getWorkspaceGroup = async () => {
    const response = await callAxios(
      "community/groups/getGroups",
      GET,
      undefined
    );
    return response;
  };

  getWorkspaceTeam = async () => {
    const response = await callAxios(
      "community/teams/getTeams",
      GET,
      undefined
    );
    return response;
  };

  getWorkspaceEmployee = async () => {
    const response = await callAxios(
      "community/employees/getEmployees",
      GET,
      undefined
    );
    return response;
  };

  shareFolder = async (nodeId, data) => {
    const response = await callAxios(`/node/share/${nodeId}`, POST, data);
    return response;
  };

  uploadFile = async (parentNodeId = "", data) => {
    if (parentNodeId) {
      let response = await callAxios("node/upload/" + parentNodeId, POST, data);
      return response.data;
    } else {
      let response = await callAxios("node/upload", POST, data);
      return response.data;
    }
  };

  getSharedMemebers = async (nodeId) => {
    const response = await callAxios(
      `node/get-shared-members/${nodeId}`,
      GET,
      undefined
    );
    return response;
  };

  getCommunityGuidelines = async () => {
    const response = await callAxios(
      "/settings/configuration/guidelines/retrieve",
      GET
    );
    return response;
  };

  updateCommunityGuidelines = async (data) => {
    const response = await callAxios(
      "/settings/configuration/guidelines/update",
      POST,
      data
    );
    return response;
  };

  signupUsingWorkspaceCode = async (data) => {
    const response = await callAxios("/signup/workspacecode", POST, data);
    return response;
  };

  sendAReferral = async (data) => {
    const response = await callAxios("/referrals/send", POST, data);
    return response;
  };

  updateTaxonomies = async (data) => {
    const response = await callAxios(
      "/admin/branding/update-taxonomy",
      POST,
      data
    );
    return response;
  };
  resetTaxonomies = async () => {
    const response = await callAxios("/admin/branding/reset-taxonomy", GET);
    return response;
  };

  getRewardActivities = async (queryParams) => {
    const response = await callAxios(
      `rewarding/management/dashboard/activity?${queryParams}`,
      GET
    );
    return response;
  };

  getTopPerformers = async (queryParams) => {
    const response = await callAxios(
      `rewarding/management/dashboard/leaderboard?${queryParams}`,
      GET
    );
    return response;
  };

  getLastTopPerformers = async (queryParams) => {
    const response = await callAxios(
      `rewarding/management/dashboard/leaderboard?${queryParams}`,
      GET
    );
    return response;
  };

  createRewardOrders = async (data) => {
    const response = await callAxios("rewarding/management/orders", POST, data);
    return response;
  };
  getRewardOrders = async (queryParams) => {
    const response = await callAxios(`rewarding/management/orders?${queryParams}`, GET);
    return response;
  };
  getRewardsOrderById = async (id) => {
    if (id) {
      const response = await callAxios(
        `rewarding/management/orders/${id}`,
        GET
      );
      return response;
    }
  };
  deleteRewardsOrder = async (id) => {
    const response = await callAxios(`rewarding/management/orders/${id}`, DELETE);
    return response;
  };
  updateRewardsOrder = async (id, data) => {
    const response = await callAxios(
      `rewarding/management/orders/${id}`,
      PUT,
      data
    );
    return response;
  };
  createMilestones = async (data) => {
    const response = await callAxios("rewarding/management/milestones", POST, data);
    return response;
  };
  getLatestMilestones = async (latest) => {
    const response = await callAxios(`rewarding/management/milestones?${latest}`, GET);
    return response;
  };
  getMilestones = async () => {
    const response = await callAxios("rewarding/management/milestones", GET);
    return response;
  };
  getMilestoneById = async (milestoneId) => {
    if (milestoneId) {
      const response = await callAxios(
        `rewarding/management/milestones/${milestoneId}`,
        GET
      );
      return response;
    }
  };
  deleteMilestones = async (id) => {
    const response = await callAxios(`rewarding/management/milestones/${id}`, DELETE);
    return response;
  };
  updateMilestones = async (id, data) => {
    const response = await callAxios(
      `rewarding/management/milestones/${id}`,
      PUT,
      data
    );
    return response;
  };
  createRewards = async (data) => {
    const response = await callAxios("rewarding/management/rewards", POST, data);
    return response;
  };
  getRewards = async (queryParams) => {
    const response = await callAxios(`rewarding/management/rewards?${queryParams}`, GET);
    return response;
  };

  getLatestRewards = async (queryParams, latest) => {
    const response = await callAxios(
      `rewarding/management/rewards?${queryParams}?${latest}`,
      GET
    );
    return response;
  };

  deleteReward = async (id) => {
    const response = await callAxios(`rewarding/management/rewards/${id}`, DELETE);
    return response;
  };
  updateReward = async (id, data) => {
    const response = await callAxios(`rewarding/management/rewards/${id}`, PUT, data);
    return response;
  };
  getRewardById = async (id) => {
    const response = await callAxios(`rewarding/management/rewards/${id}`);
    return response;
  };
  createMeeting = async () => {
    try {
      const { data } = await callAxios("/meetings/create", GET);
      return data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  getMemberInfo = async (queryParams) => {
    const response = await callAxios(
      "rewarding/member/dashboard/member-info",
      GET
    );
    return response;
  };

  getBadgePoints = async (queryParams) => {
    const response = await callAxios(
      `rewarding/member/dashboard/badgepoints?${queryParams}`,
      GET
    );
    return response;
  };

  getLatestLeaderBoard = async (queryParams) => {
    const response = await callAxios(
      `rewarding/member/dashboard/leaderboard?${queryParams}`,
      GET
    );
    return response;
  };

  getLeaderBoard = async () => {
    const response = await callAxios(
      "rewarding/member/dashboard/leaderboard",
      GET
    );
    return response;
  };

  getCategorizedPoints = async (queryParams) => {
    const response = await callAxios(
      `rewarding/member/dashboard/categorizedpoints?${queryParams}`,
      GET
    );
    return response;
  };

  getLatestMemberMilestones = async (latest) => {
    const response = await callAxios(
      `rewarding/member/milestones?${latest}`,
      GET
    );
    return response;
  };

  getMemberMilestones = async () => {
    const response = await callAxios("rewarding/member/milestones", GET);
    return response;
  };

  getMemberMilestoneById = async (id) => {
    const response = await callAxios(`rewarding/member/milestones/${id}`, GET);
    return response;
  };

  getLatestMemberRewards = async (queryParams, latest) => {
    const response = await callAxios(
      `rewarding/member/rewards?${queryParams}?${latest}`,
      GET
    );
    return response;
  };

  getMemberRewards = async (queryParams) => {
    const response = await callAxios(
      `rewarding/member/rewards?${queryParams}`,
      GET
    );
    return response;
  };

  getMemberRewardById = async (id) => {
    const response = await callAxios(`rewarding/member/rewards/${id}`, GET);
    return response;
  };

  getRewardSharing = async (queryParams) => {
    const response = await callAxios(
      `rewarding/member/sharing/share?${queryParams}`,
      GET
    );
    return response;
  };

  getChatAuthToken = async () => {
    try {
      const { data } = await callAxios("/chat/getAuthToken", GET);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  getCommonPosts = async (id) => {
    const response = await callAxios(`/common/posts/getPost?id=${id}`, GET);
    return response;
  };

  getArticleById = async (id) => {
    const response = await callAxios(`/learning/articles/getArticle?id=${id}`, GET);
    return response;
  };

  getEventById = async (id) => {
    const response = await callAxios(`/learning/events/getEvent?id=${id}`, GET);
    return response;
  };

  getCourseById = async (id) => {
    const response = await callAxios(`/learning/courses/getCourse?id=${id}`, GET);
    return response;
  };

  getLanguages = async () => {
    try {
      const { data } = await callAxios('/common/provider/getLanguages', GET);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  getTimeZones = async () => {
    try {
      const { data } = await callAxios('/common/provider/getTimezones', GET);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  getWorkspaces = async () => {
    const response = await callAxios("/workspaces/list", GET);
    return response;
  };

  selectWorkspace = async (data) => {
    const response = await callAxios("/workspaces/select", POST, data);
    return response;
  }

  getTagsLearning = async () => {
    try {
      const response = await callAxios('/learning/providers/tags', GET);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  getStripePlans = async () => {
    const response = await callAxios("/stripe/plans", GET);
    return response;
  };

  getSubscriptionPlans = async () => {
    const response = await callAxios("/settings/billing/plans", GET);
    return response;
  };

  getSubscriptionSummary = async (plan_id) => {
    const response = await callAxios(`/settings/billing/summary?plan_id=${plan_id}`, GET);
    return response;
  }

  processSubscription = async (data) => {
    const response = await callAxios("/settings/billing/subscribe", POST, data);
    return response;
  };

  getPlanSummary = async (code, billingTerm, trial) => {
    const response = await callAxios(`/stripe/plan?code=${code}&billing_term=${billingTerm}&trial=${trial}`, GET);
    return response;
  };

  stripeSubscription = async (data) => {
    const response = await callAxios("/stripe/subscribe", POST, data);
    return response;
  };



  getAllLanguages = async () => {
    try {
      const response = await callAxios('/common/provider/getLanguages', GET);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  getTagsLearning = async () => {
    try {
      const response = await callAxios('/learning/providers/tags', GET);
      return response;
    } catch (error) {
      console.log(error);
    }
  }



  getSettingsNotifications = async () => {
    const response = await callAxios("/settings/notifications/retrieve", GET);
    return response;
  }

  updateSettingsNotifications = async (data) => {
    const response = await callAxios("/settings/notifications/update", POST, data);
    return response;
  }

  authenticateWithTwilio = async ({ meeting_id }) => {
    const response = await callAxios(`/meetings/authenticate`, POST, { meeting_id });
    return response;
  }


  getParticipant = async ({ id }) => {
    const response = await callAxios(`/meetings/participant?id=${id}`, GET);
    return response;
  }

  authenticateWithTwilioChat = async ({ id }) => {
    const response = await callAxios(`/chat/authenticate?id=${id}`, GET);
    return response;
  }

  getChatParticipant = async ({ id }) => {
    const response = await callAxios(`/chat/participant?id=${id}`, GET)
    return response;
  }

  startRecordMeeting = async (meeting_id) => {
    const response = await callAxios("/meetings/recording/start", POST, { meeting_id });
    return response;
  }

  stopRecordMeeting = async (meeting_id) => {
    const response = await callAxios("/meetings/recording/stop", POST, { meeting_id });
    return response;
  }

  getMeetingInfo = async (meeting_id) => {
    const response = await callAxios(`/meetings/retrieve?twilio_meeting_id=${meeting_id}`, GET);
    return response;
  }

  getChatRole = async (role_sid) => {
    const response = await callAxios(`/chat/role/permissions?role_sid=${role_sid}`, GET);
    return response;
  }

}
