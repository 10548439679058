import React from "react";
import { Modal, View, Text, Dimensions } from 'react-native'
import Button from '@/components/ButtonPrimary';


const { width } = Dimensions.get('window');

const ActionErrorModal = ({
    errorText,
    isOpened,
    onClose,
    error = {},
}) => (
    <Modal
        visible={isOpened}
        transparent
    >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>
            <View style={{ backgroundColor: 'white', shadowColor: "#ccc", shadowOffset: { width: 3, height: 3 }, elevation: 8, shadowOpacity: 0.9, shadowRadius: 10, borderRadius: 8, padding: 10, width: width / 1.1, height: width / 1.2 }}>
                <View style={{ flex: 7, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ padding: 5, fontSize: 18, marginVertical: 20, fontWeight: 'bold' }}>{errorText.title}</Text>

                    <Text>{errorText.description}</Text>
                    {error ? (
                        <>
                            <Text style={{ marginTop: 10 }}>Error code {error.code}: {error.message}</Text>
                        </>
                    ) : null}
                </View>
                <View style={{ flex: 1.5 }}>
                    <Button onPress={onClose} label="Clone" />
                </View>
            </View>
        </View>
    </Modal>
);

export default ActionErrorModal;
