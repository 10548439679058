import React from "react";
import { KeyboardAvoidingView, Platform } from "react-native";
import BottomSheet from "./bottomSheet";
import {
	Avatar,
	AvatarContainer,
	ButtonContainer,
	Container,
	InputContainer,
	MainView,
} from "./styles";
import Button from "../../Button";
import AvatarTemplate from "../../components/AvatarTemplate";
import InputField from "../../components/InputField";
import { isMobile } from "@/styles";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';

const Input = ({
	onChangeText,
	onSendMessage,
	avatar,
	onPressImage,
	onPressVideo,
	onPressCamera,
	onFocus,
	onBlur,
	letter = "P",
	multiline,
	value,
	width,
	onWebAttachment,
	onKeyPress,
	hideAttachment,
	setRef,
	openSheet
}) => {
	let bottomSheetRef;

	const accentColor = UseBrandingAccentColor();

	const setLocalRef = (ref) => (bottomSheetRef = ref);


	const pressHandler = (type) => {
		Platform.OS == 'android' && bottomSheetRef.close();

		switch (type) {
			case 'camera':
				onPressCamera();
				break;
			case 'image':
				onPressImage();
				break;
			case 'video':
				onPressVideo();
				break;
			default: return;
		}

	}

	let tabBarHeight = 0

	try {
		tabBarHeight = useBottomTabBarHeight();
	} catch (e) {

	}


	return (
		<KeyboardAvoidingView
			behavior={Platform.OS == "ios" ? "padding" : "height"}
			keyboardVerticalOffset={tabBarHeight}
		>
			<Container>
				{/*
				<AvatarContainer>
					{avatar ? (
						<Avatar
							source={{ uri: avatar }}
							accentColor={accentColor}
							resizeMode="cover"
						/>
					) : (
						<AvatarTemplate letter={letter} />
					)}
				</AvatarContainer>
				*/}
				<MainView>
					<InputContainer>
						<InputField
							onChangeText={onChangeText}
							onFocus={onFocus}
							onKeyPress={onKeyPress}
							onBlur={onBlur}
							width={width}
							value={value}
							multiline={multiline}
							placeholder="Type your message here..."
						/>
					</InputContainer>
					<ButtonContainer hideAttachment={hideAttachment}>
						{!hideAttachment && <Button icon="attachment" onPress={() => isMobile ? bottomSheetRef?.open() ?? openSheet() : onWebAttachment()} />}
						<Button type="fontawesome" icon="send" onPress={onSendMessage} />
					</ButtonContainer>
				</MainView>
			</Container>
			<BottomSheet
				setRef={setRef ?? setLocalRef}
				onPressCamera={() => pressHandler('camera')}
				onPressVideo={() => pressHandler('video')}
				onPressImage={() => pressHandler('image')}
			/>
		</KeyboardAvoidingView>
	);
};

export default Input;
