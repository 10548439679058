import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function LoginDecoration(props) {
  return (
    <Svg width="695" height="556" viewBox="0 0 695 556" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.4726 489.78C52.1382 489.78 43.7303 481.372 43.7303 471.038C43.7303 460.705 52.1382 452.297 62.4726 452.297C72.8056 452.297 81.2135 460.705 81.2135 471.038C81.2135 481.372 72.8056 489.78 62.4726 489.78ZM62.4727 454.864C53.5528 454.864 46.2974 462.119 46.2974 471.038C46.2974 479.958 53.5528 487.213 62.4727 487.213C71.3914 487.213 78.6468 479.958 78.6468 471.038C78.6468 462.119 71.3914 454.864 62.4727 454.864Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 556H123.694V471.038H0V556Z"
        fill="#CDCDCD"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.2 556H123.694V471.038H111.2V556Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 514.769H111.2V512.27H0V514.769Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.4786 523.515H64.9708V512.27H47.4786V523.515Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M489.781 216.406C489.781 222.538 485.719 227.702 480.149 229.38L444.655 240.185V247.389H413.711V240.185L378.178 229.38H378.229C372.646 227.702 368.585 222.538 368.585 216.406C368.585 210.507 372.35 205.511 377.61 203.652V98.7896C372.35 96.9307 368.585 91.9219 368.585 86.0223C368.585 78.5478 374.645 72.4675 382.123 72.4675H476.243C483.721 72.4675 489.781 78.5478 489.781 86.0223C489.781 91.9219 486.003 96.9307 480.755 98.7896V203.652C486.003 205.511 489.781 210.507 489.781 216.406Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.03 114.948H376.431V99.454C370.954 97.1643 367.335 91.8392 367.335 85.9283C367.335 77.8172 374.039 71.218 382.278 71.218H399.82V73.7763H382.278C375.472 73.7763 369.934 79.2281 369.934 85.9283C369.934 91.0628 373.241 95.6626 378.163 97.3728L379.03 97.6734V114.948Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M489.781 216.531C489.781 222.639 485.719 227.781 480.149 229.453L444.655 240.215V247.389H413.711V240.215L378.178 229.453H378.229C372.646 227.781 368.585 222.639 368.585 216.531C368.585 210.656 372.35 205.68 377.61 203.828V127.443H480.755V203.828C486.003 205.68 489.781 210.656 489.781 216.531Z"
        fill="#CDCDCD"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M368.585 556H489.781V247.389H368.585V556Z"
        fill="#E1E1E1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.574 401.07H463.542V272.378H393.574V401.07Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M421.061 299.865C421.061 304.696 417.146 308.611 412.315 308.611C407.485 308.611 403.569 304.696 403.569 299.865C403.569 295.035 407.485 291.119 412.315 291.119C417.146 291.119 421.061 295.035 421.061 299.865Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M454.796 299.865C454.796 304.696 450.881 308.611 446.05 308.611C441.22 308.611 437.304 304.696 437.304 299.865C437.304 295.035 441.22 291.119 446.05 291.119C450.881 291.119 454.796 295.035 454.796 299.865Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.066 322.355H413.565V299.865H411.066V322.355Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.801 322.355H447.3V299.865H444.801V322.355Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.057 183.668C431.057 185.048 429.938 186.166 428.558 186.166C427.178 186.166 426.059 185.048 426.059 183.668C426.059 182.287 427.178 181.169 428.558 181.169C429.938 181.169 431.057 182.287 431.057 183.668Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.057 202.409C431.057 203.788 429.938 204.908 428.558 204.908C427.178 204.908 426.059 203.788 426.059 202.409C426.059 201.028 427.178 199.91 428.558 199.91C429.938 199.91 431.057 201.028 431.057 202.409Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.057 223.65C431.057 225.029 429.938 226.148 428.558 226.148C427.178 226.148 426.059 225.029 426.059 223.65C426.059 222.269 427.178 221.151 428.558 221.151C429.938 221.151 431.057 222.269 431.057 223.65Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M458.545 171.173C458.545 172.554 457.426 173.672 456.046 173.672C454.666 173.672 453.547 172.554 453.547 171.173C453.547 169.794 454.666 168.674 456.046 168.674C457.426 168.674 458.545 169.794 458.545 171.173Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M458.545 183.668C458.545 185.048 457.426 186.166 456.046 186.166C454.666 186.166 453.547 185.048 453.547 183.668C453.547 182.287 454.666 181.169 456.046 181.169C457.426 181.169 458.545 182.287 458.545 183.668Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M458.545 202.409C458.545 203.788 457.426 204.908 456.046 204.908C454.666 204.908 453.547 203.788 453.547 202.409C453.547 201.028 454.666 199.91 456.046 199.91C457.426 199.91 458.545 201.028 458.545 202.409Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.819 146.184C404.819 147.565 403.699 148.683 402.32 148.683C400.939 148.683 399.821 147.565 399.821 146.184C399.821 144.805 400.939 143.686 402.32 143.686C403.699 143.686 404.819 144.805 404.819 146.184Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.819 157.429C404.819 158.809 403.699 159.928 402.32 159.928C400.939 159.928 399.821 158.809 399.821 157.429C399.821 156.049 400.939 154.93 402.32 154.93C403.699 154.93 404.819 156.049 404.819 157.429Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.819 183.668C404.819 185.048 403.699 186.166 402.32 186.166C400.939 186.166 399.821 185.048 399.821 183.668C399.821 182.287 400.939 181.169 402.32 181.169C403.699 181.169 404.819 182.287 404.819 183.668Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.574 401.07H463.542V398.571H393.574V401.07Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M446.118 247.389H443.551V239.261L479.788 228.223C484.978 226.658 488.464 221.96 488.464 216.531C488.464 211.381 485.198 206.764 480.336 205.041L479.481 204.737V127.443H482.048V202.95C487.456 205.254 491.03 210.6 491.03 216.531C491.03 223.103 486.811 228.79 480.531 230.683L446.118 241.167V247.389Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M482.058 118.697H479.495V97.6251L480.348 97.3238C485.205 95.613 488.467 91.0217 488.467 85.9007C488.467 79.2131 483.004 73.7715 476.29 73.7715H443.551V71.218H476.29C484.418 71.218 491.03 77.8048 491.03 85.9007C491.03 91.7967 487.46 97.1106 482.058 99.3998V118.697Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M428.558 73.7169H436.055V71.218H428.558V73.7169Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M475.53 81.2136H446.557C444.207 81.2136 442.302 83.1717 442.302 85.5867C442.302 88.0017 444.207 89.9597 446.557 89.9597H475.53C477.88 89.9597 479.785 88.0017 479.785 85.5867C479.785 83.1717 477.88 81.2136 475.53 81.2136Z"
        fill="#F1F1F1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.681 81.2136C429.266 81.2136 427.309 83.1717 427.309 85.5867C427.309 88.0017 429.266 89.9597 431.681 89.9597C434.096 89.9597 436.055 88.0017 436.055 85.5867C436.055 83.1717 434.096 81.2136 431.681 81.2136Z"
        fill="#F1F1F1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.084 418.562H373.583V247.389H371.084V418.562Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.084 431.056H373.583V423.56H371.084V431.056Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M577.961 221.151H557.778L554.75 182.418H580.988L577.961 221.151Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M552.406 288.818C550.227 286.143 550.628 282.207 553.303 280.028C555.978 277.849 559.914 278.251 562.093 280.927C564.272 283.602 563.869 287.536 561.194 289.715C558.519 291.894 554.585 291.492 552.406 288.818Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M540.555 283.893L540.56 283.9C539.665 284.231 538.806 284.705 538.024 285.343C534.23 288.441 533.659 294.036 536.751 297.841C539.842 301.645 545.424 302.217 549.219 299.118L560.997 289.499L554.809 278.625L540.555 283.893Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.046 219.003C601.416 222.762 608.816 221.458 612.575 216.088C616.336 210.718 615.03 203.318 609.662 199.559C604.292 195.798 596.891 197.104 593.131 202.474C589.372 207.842 590.677 215.243 596.046 219.003Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M598.887 137.438L590.984 208.157L615.202 212.404L632.215 143.284L598.887 137.438Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M592.918 203.658L552.251 281.967L563.132 288.62L613.474 216.226L592.918 203.658Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M618.725 122.79C627.849 124.654 633.735 133.563 631.871 142.688C630.005 151.814 621.096 157.7 611.971 155.835C602.847 153.97 596.961 145.061 598.825 135.936C600.689 126.81 609.599 120.924 618.725 122.79Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M632.215 143.181L621.408 187.416L594.732 182.7L599.746 137.438L632.215 143.181Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M633.303 15.0736L639.712 15.4992L633.299 109.95L614.896 108.725C598.951 107.664 587.433 93.1828 590.126 77.5867L595.881 44.2572C598.953 26.4685 615.117 13.8622 633.303 15.0736Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M597.3 232.882C596.188 249.406 608.682 263.7 625.207 264.812C641.73 265.922 656.025 253.429 657.136 236.906C658.246 220.382 645.752 206.088 629.229 204.976C612.706 203.866 598.41 216.36 597.3 232.882Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M598.576 137.536C597.595 146.799 604.309 155.104 613.572 156.085C622.834 157.065 631.138 150.351 632.12 141.089C633.101 131.826 626.387 123.521 617.124 122.54C607.861 121.558 599.558 128.272 598.576 137.536Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M657.204 544.13C657.204 548.616 653.567 552.251 649.083 552.251C644.597 552.251 640.962 548.616 640.962 544.13C640.962 539.646 644.597 536.009 649.083 536.009C653.567 536.009 657.204 539.646 657.204 544.13Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M619.689 543.419C620.082 547.887 616.778 551.828 612.311 552.22C607.843 552.612 603.902 549.309 603.51 544.841C603.118 540.373 606.421 536.433 610.889 536.04C615.357 535.648 619.297 538.951 619.689 543.419Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M599.181 138.774L597.231 232.141L656.761 236.144L687.933 140.049C689.995 133.691 685.528 127.091 678.858 126.642L616.976 122.483C607.483 121.844 599.38 129.263 599.181 138.774Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M617.184 172.928C616.558 182.223 608.518 189.252 599.223 188.626C589.93 188.002 582.902 179.961 583.526 170.666C584.151 161.372 592.193 154.343 601.486 154.969C610.781 155.593 617.808 163.635 617.184 172.928Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M597.403 234.894H623.705H656.877L660.082 237.405C670.88 245.864 677.195 258.877 677.195 272.665V285.561C677.195 295.531 669.179 303.613 659.29 303.613H607.64C597.751 303.613 589.735 295.53 589.735 285.559L589.736 267.9C589.736 256.449 592.36 245.153 597.403 234.894Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M585.986 164.717L600.645 132.44L630.966 146.392L616.309 178.67L585.986 164.717Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M585.993 203.367C585.832 206.812 588.494 209.737 591.942 209.898C595.387 210.061 598.312 207.397 598.474 203.951C598.636 200.504 595.972 197.579 592.526 197.418C589.079 197.257 586.156 199.919 585.993 203.367Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M678.571 159.807C669.322 160.912 660.929 154.31 659.824 145.061C658.719 135.812 665.321 127.419 674.57 126.314C683.819 125.209 692.212 131.811 693.317 141.06C694.423 150.309 687.82 158.702 678.571 159.807Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M577.043 214.894C578.062 214.94 579.044 214.812 579.972 214.548V214.557L594.732 210.362L593.24 198.123L577.882 197.421C572.934 197.196 568.736 200.923 568.504 205.748C568.272 210.573 572.095 214.668 577.043 214.894Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M694.687 143.685L693.944 214.842L669.43 216.153L660.953 145.488L694.687 143.685Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M660.953 145.478L666.48 191.164L694.195 189.694L694.687 143.685L660.953 145.478Z"
        fill="#FFD259"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M679.479 226.148L592.233 210.39L593.669 197.411L682.193 201.627L679.479 226.148Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M624.281 289.438C622.105 299.202 612.425 305.353 602.661 303.175C592.896 300.999 586.746 291.319 588.923 281.555C591.099 271.792 600.78 265.641 610.544 267.817C620.307 269.993 626.457 279.674 624.281 289.438Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M677.195 285.496C677.195 295.502 669.083 303.613 659.078 303.613C649.073 303.613 640.962 295.502 640.962 285.496C640.962 275.491 649.073 267.38 659.078 267.38C669.083 267.38 677.195 275.491 677.195 285.496Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M624.719 123.069L652.412 124.944L654.705 90.585L627.011 88.71L624.719 123.069Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M641.439 544.755H658.454V556H634.614C632.6 556 630.966 554.442 630.966 552.518C630.966 551.272 631.664 550.12 632.796 549.499L641.439 544.755Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M640.962 377.955C640.962 385.891 647.395 392.324 655.33 392.324C663.266 392.324 669.699 385.891 669.699 377.955C669.699 370.02 663.266 363.586 655.33 363.586C647.395 363.586 640.962 370.02 640.962 377.955Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M669.699 378.58L657.477 543.505H640.962V378.58H669.699Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M640.964 284.872L640.962 378.725L670.233 379.829L677.195 286.238L640.964 284.872Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M604.672 375.447C605.367 383.353 599.522 390.323 591.616 391.018C583.712 391.713 576.742 385.868 576.047 377.962C575.352 370.058 581.197 363.088 589.101 362.393C597.006 361.698 603.977 367.543 604.672 375.447Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M605.017 376.081L619.721 543.291L603.04 544.755L575.991 378.631L605.017 376.081Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M624.719 288.881L604.512 379.829L575.991 374.572L589.412 282.373L624.719 288.881Z"
        fill="#FF2626"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M611.351 33.7346L659.703 37.0116L655.507 99.9548L625.478 97.9184C613.455 97.1031 604.605 86.2317 606.153 74.1809L611.351 33.7346Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M656.995 72.4529L650.957 72.0346L651.362 65.8017C651.587 62.3583 654.471 59.7556 657.806 59.9872C661.141 60.2189 663.662 63.1963 663.438 66.6398C663.213 70.0819 660.329 72.6846 656.995 72.4529Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M675.919 90.766L673.718 48.5831C672.79 30.7822 658.661 16.4815 640.821 15.2852L636.453 14.9932L634.795 39.5784C634.348 46.2123 635.762 52.8397 638.877 58.7185L646.011 72.1817C652.489 84.4075 651.333 99.26 643.039 110.342V110.343L655.155 111.155C666.807 111.936 676.525 102.392 675.919 90.766Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M647.209 23.7973L629.411 43.0116C623.031 49.8978 613.929 53.5249 604.615 52.8927L603.478 69.9684L605.256 43.2616C606.072 30.9931 616.576 21.7156 628.716 22.5408L647.209 23.7973Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M623.502 123.356C622.971 131.272 628.957 138.122 636.874 138.655C644.792 139.186 651.642 133.2 652.173 125.282C652.706 117.366 646.72 110.516 638.802 109.983C630.885 109.452 624.035 115.438 623.502 123.356Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M602.706 544.755H619.721V556H595.881C593.867 556 592.233 554.442 592.233 552.518C592.233 551.272 592.931 550.12 594.063 549.499L602.706 544.755Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M669.712 213.723C670.018 207.176 675.576 202.116 682.124 202.422C688.671 202.729 693.731 208.286 693.425 214.834C693.118 221.383 687.561 226.442 681.013 226.135C674.464 225.828 669.405 220.271 669.712 213.723Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M628.467 79.5753C628.236 83.4705 624.974 86.4355 621.183 86.1979C617.391 85.9602 614.505 82.6098 614.736 78.7146L628.467 79.5753Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M665.675 197.411L662.202 171.475L664.976 171.173L668.449 197.109L665.675 197.411Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.076 250.793C276.359 256.981 275.421 262.929 273.471 268.435L186.665 272.377C184.224 267.07 182.748 261.232 182.467 255.044C181.29 229.24 201.287 207.381 227.142 206.206C252.983 205.033 274.9 224.99 276.076 250.793Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.377 252.415C242.706 259.653 237.106 265.787 229.868 266.116C222.63 266.446 216.496 260.845 216.166 253.607C215.837 246.37 221.438 240.235 228.675 239.906C235.913 239.577 242.048 245.177 242.377 252.415Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M402.231 128.629C402.956 134.1 399.108 139.124 393.636 139.849C388.164 140.574 383.141 136.726 382.415 131.255C381.691 125.783 385.538 120.76 391.01 120.034C396.482 119.309 401.505 123.157 402.231 128.629Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M382.262 133.955C381.718 129.851 377.95 126.965 373.847 127.508C369.743 128.053 366.857 131.821 367.4 135.925C367.945 140.029 371.713 142.915 375.817 142.37C379.921 141.826 382.806 138.059 382.262 133.955Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M387.93 121.196L371.083 128.632L375.315 143.685L393.573 141.268L387.93 121.196Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M311.632 190.716C304.486 184.218 293.423 184.743 286.923 191.89C280.424 199.038 280.95 210.1 288.097 216.599C295.244 223.1 306.307 222.574 312.806 215.426C319.306 208.278 318.78 197.217 311.632 190.716Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.393 143.482L285.25 213.654L316.107 196.75L278.778 119.514L237.393 143.482Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M369.555 128.692L379.829 140.073L311.971 214.903L288.62 189.026L359.226 136.397L365.386 131.802L369.555 128.692Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M370.415 128.692L381.078 139.951L376.336 144.935L366.085 131.768L370.415 128.692Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M272.438 150.477C260.752 158.446 244.818 155.431 236.849 143.745C228.88 132.06 231.893 116.126 243.58 108.157C255.266 100.188 271.2 103.202 279.169 114.888C287.137 126.574 284.123 142.508 272.438 150.477Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.642 144.555L278.068 202.409L311.11 184.286L281.639 120.327L238.642 144.555Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M274.876 269.879L269.545 378.58H239.905L239.892 269.879H274.876Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.577 271.48C217.693 281.1 209.178 288.18 199.558 287.296C189.938 286.412 182.857 277.897 183.742 268.278C184.626 258.659 193.141 251.577 202.76 252.461C212.38 253.345 219.461 261.861 218.577 271.48Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M274.876 269.879C274.876 279.54 267.045 287.371 257.384 287.371C247.724 287.371 239.892 279.54 239.892 269.879C239.892 260.219 247.724 252.386 257.384 252.386C267.045 252.386 274.876 260.219 274.876 269.879Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.125 121.835L275.672 250.607L182.099 254.885L170.005 126.686C169.035 116.407 176.823 107.402 187.091 106.933L257.325 103.722C267.592 103.253 276.163 111.508 276.125 121.835Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.125 252.386L275.264 269.878L275.223 270.737C274.765 280.052 267.055 287.371 257.703 287.371H200.842C191.488 287.371 183.78 280.052 183.321 270.735L183.279 269.878L182.418 252.386H276.125Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.651 271.861L208.579 381.079L178.669 378.337L183.361 268.629L218.651 271.861Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.368 543.853C191.832 549.693 186.661 553.992 180.821 553.456C174.98 552.919 170.681 547.75 171.218 541.909C171.755 536.069 176.924 531.77 182.764 532.306C188.605 532.843 192.905 538.012 192.368 543.853Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M261.132 542.88C261.132 548.746 256.377 553.501 250.512 553.501C244.647 553.501 239.892 548.746 239.892 542.88C239.892 537.015 244.647 532.261 250.512 532.261C256.377 532.261 261.132 537.015 261.132 542.88Z"
        fill="#828282"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.878 378.58C269.878 386.86 263.165 393.573 254.885 393.573C246.605 393.573 239.892 386.86 239.892 378.58C239.892 370.299 246.605 363.586 254.885 363.586C263.165 363.586 269.878 370.299 269.878 378.58Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208.592 379.952C207.835 388.198 200.536 394.267 192.29 393.509C184.046 392.751 177.976 385.452 178.733 377.208C179.491 368.963 186.79 362.892 195.035 363.65C203.281 364.409 209.35 371.707 208.592 379.952Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.892 543.506V379.829H269.878L261.774 543.506H239.892Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.418 542.256L197.411 378.58L182.418 542.256Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.173 541.514L178.085 377.33L207.406 380.059L192.569 543.506L171.173 541.514Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M272.865 556H239.892V543.506H261.834L274.426 550.11C275.473 550.66 276.125 551.717 276.125 552.864C276.125 554.597 274.665 556 272.865 556Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.144 556H171.173V543.506H193.115L205.706 550.11C206.754 550.66 207.406 551.717 207.406 552.864C207.406 554.597 205.946 556 204.144 556Z"
        fill="#FF5656"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M253.674 40.9964L249.01 41.2316L247.388 10.2528L252.054 10.0164C260.748 9.57653 268.159 16.155 268.608 24.7094C269.055 33.2638 262.368 40.5553 253.674 40.9964Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.402 73.1026L205.546 73.7011C199 74.0318 193.419 69.0912 193.082 62.6679L192.413 49.925L216.123 48.728L217.402 73.1026Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.892 104.559L207.938 106.202L206.157 71.6115L238.11 69.9685L239.892 104.559Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.6 40.577L238.188 41.2315L236.143 0.602575L247.241 0.0189599C254.818 -0.380424 261.275 5.57812 261.666 13.3287L262.365 27.2376C262.718 34.2448 257.45 40.2175 250.6 40.577Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.793 82.8298L207.345 84.9617L204.942 38.3013C204.212 24.1082 215.129 12.0098 229.326 11.2795C243.04 10.5735 254.892 20.7548 256.261 34.4155L259.824 69.9946C260.489 76.6372 255.462 82.4865 248.793 82.8298Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.234 7.67526L217.676 7.49658L219.901 51.1044L193.487 52.4764L192.444 32.0355C191.791 19.2396 201.546 8.33382 214.234 7.67526Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.35 56.2155L213.654 55.8626L213.278 48.9994C213.07 45.2098 209.633 42.2949 205.598 42.4902C201.564 42.6856 198.463 45.9156 198.67 49.7064C198.878 53.4972 202.317 56.4108 206.35 56.2155Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.883 27.3307L233.173 28.7105C222.289 29.2733 212.977 20.8403 212.417 9.91359C212.151 4.71349 216.133 0.279782 221.313 0.0126444C226.493 -0.254493 230.91 3.74349 231.176 8.94359C231.205 9.48695 231.667 9.9071 232.208 9.87858L258.916 8.4988L259.883 27.3307Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.609 19.2751L236.151 19.9781C230.904 20.2513 226.43 16.162 226.161 10.8418C225.89 5.52285 229.925 0.989172 235.172 0.715931L248.632 0.0129333C253.879 -0.260307 258.351 3.82902 258.62 9.14925C258.891 14.4682 254.856 19.0018 249.609 19.2751Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.119 104.118C241.581 113.077 234.691 120.712 225.733 121.174C216.774 121.635 209.139 114.746 208.678 105.788C208.216 96.8298 215.106 89.1934 224.064 88.7321C233.022 88.2708 240.658 95.1601 241.119 104.118Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M261.085 79.9423C261.622 85.2671 257.551 89.9537 252.15 90.229L233.398 91.1839C222.968 91.7153 214.078 83.7795 213.542 73.4593L212.404 51.5874L219.481 51.2271C219.705 55.5276 223.41 58.8347 227.758 58.6134L258.772 57.0344L261.085 79.9423Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.406 149.855L149.982 211.155L122.445 189.904L167.031 118.697L207.406 149.855Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.406 150.009L159.684 201.159L129.941 178.104L166.988 118.697L207.406 150.009Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.762 151.742C182.077 161.422 198.287 160.908 207.966 150.593C217.647 140.277 217.132 124.068 206.817 114.388C196.502 104.708 180.292 105.223 170.613 115.537C160.932 125.853 161.447 142.063 171.762 151.742Z"
        fill="#B3B3B3"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.911 199.106C155.356 208.756 147.893 216.94 138.243 217.383C128.592 217.828 120.409 210.365 119.964 200.715C119.521 191.064 126.983 182.882 136.634 182.437C146.283 181.993 154.467 189.455 154.911 199.106Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.188 182.017L237.689 177.42L239.892 192.436L234.805 193.659L227.291 195.461L141.197 216.153L136.188 182.017Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.892 192.44L237.76 177.42L231.146 177.73L234.969 193.663L239.892 192.44Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M274.347 203.658H254.164L251.137 164.926H277.375L274.347 203.658Z"
        fill="#5E5E5E"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.119 186.955C265.866 181.442 261.189 177.176 255.675 177.431C250.16 177.685 245.896 182.361 246.15 187.875C246.403 193.39 251.08 197.654 256.595 197.4C262.109 197.147 266.373 192.47 266.119 186.955Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.881 184.573C245.073 188.708 241.874 192.215 237.738 192.405C233.603 192.596 230.095 189.398 229.904 185.262C229.714 181.127 232.913 177.618 237.049 177.428C241.184 177.238 244.691 180.437 244.881 184.573Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.12 197.411L236.143 193.228L237.654 178.235L256.134 177.42L254.12 197.411Z"
        fill="#FFCF70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.885 69.9679C254.885 72.0386 253.207 73.7168 251.136 73.7168C249.067 73.7168 247.388 72.0386 247.388 69.9679C247.388 67.8984 249.067 66.2202 251.136 66.2202C253.207 66.2202 254.885 67.8984 254.885 69.9679Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.008 178.67L181.168 176.89L194.321 162.427L196.161 164.207L183.008 178.67Z"
        fill="#E2E2E2"
      />
    </Svg>
  )
}
