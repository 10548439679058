import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        margin: 16,
        width: '100%'
    },
    AvatarButton: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40
    },
    CommentContainer: {
        marginLeft: 15,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto'
    },
    CommentBubbleContainer: {
        minHeight: 80,
        padding: 10,
        backgroundColor: 'rgb(233, 233, 233)',
        borderRadius: 10,
        marginBottom: 8,
        position: 'relative',
        //shadowColor: 'rgba(2, 2, 2, 1)',
        //shadowOffset: { width: 2, height: 1 },
        //shadowOpacity: 0.10,
        //shadowRadius: 20,
        //elevation: 3,
    },
    CommentBubbleAuthorButton: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 15,
        lineHeight: 18
    },
    CommentBubbleAuthorButtonName: {
        fontSize: 13,
        fontWeight: '500'
    },
    CommentBubbleAuthorButtonRole: {
        fontSize: 12
    },
    CommentBubbleCommentContainer: {
        marginTop: 8
    },
    CommentInteractionsContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    CommentTimestamp: {
        marginRight: 9
    },
    CommentLikeButtonContainer: {

    },
    CommentLikeButton: {
        flexDirection: 'row',
        alignItems: 'center',
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
        backgroundColor: '#FFF',
        borderRadius: 10,
        paddingVertical: 6,
        paddingHorizontal: 10
    },
    CommentLikeButtonLabel: {
        marginRight: 4,
        fontFamily: 'WorkSans_400Regular',
        fontSize: 12
    },
    CommentLikeButtonCount: {
        marginLeft: 4,
        fontFamily: 'WorkSans_400Regular',
        fontSize: 12
    },
    MenuContainer: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 90
    }
});
