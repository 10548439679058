import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { ScrollView, TouchableOpacity, KeyboardAvoidingView, Platform, View, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik } from 'formik'
import RoundLogo from '@/assets/brand/ReframeRoundLogo.png'
import Toast from 'react-native-toast-message'
import { Entypo } from '@expo/vector-icons'
import { ActivityIndicator } from 'react-native-paper'
import * as authSelectors from '@/redux/selectors/auth'
import { actions as authActions } from '@/redux/reducers/auth'
import { actions as profileActions } from '@/redux/reducers/profile'
import { actions as actions } from '@/redux/reducers/posts'
import { openGallery } from '@/utils/gallery'
import { Avatar, TextField, Icon, PasswordTextField } from '@/components'
import { ScreenSize, useScreenSize } from '@/styles'
import { NavigationActions, StackActions } from 'react-navigation'
import { store } from '@/redux/store'
import {
  Container,
  FormWrapper,
  FormContent,
  FieldWrapper,
  SubmitButton,
  AvatarWrapper,
  FieldsWrapper,
  GreetingMessage,
  SubmitButtonText,
  CircleCamera,
  ImageLogo,
  styles
} from './styles'
import ToastWeb from '@/components/ToastWeb'
import ApiHandler from '@/api/ApiHandler'
import { navigationRef } from '@/routes/navigation'
import * as FileSystem from 'expo-file-system';
import Community from '@/assets/drawer/Community'
import Onboarding from '@/routes/onboarding/welcome'
import Dropdown from '@/components/Dropdown'

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'

const api = new ApiHandler();

const validationSchema = Yup.object().shape({
  // profileImageUri: Yup.string().required(),
  name: Yup.string().min(2).required(),
  last_name: Yup.string().min(2),
  internal_id: Yup.string(),
  role: Yup.string().min(2).required(),
  city: Yup.string().min(2).required(),
  timezone: Yup.number(),
  language: Yup.number()
})

const PersonalInformation = ({ navigation }) => {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
  const member = useSelector(authSelectors.getMember)
  const [languages, setLanguages] = useState([]);
  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {
    getCommunityGuidelines();
  }, []);

  const getCommunityGuidelines = async () => {
    try {
      const response = await api.getCommunityGuidelines();
      if (response?.data) {
        setShowCommunityGuidelines(response?.data?.data?.status);
      }
    } catch (error) {
      console.log('GET COMMUNITY GUIDELINES ERROR', error);
    }
  };

  const getLanguages = async () => {
    const res = await api.getLanguages();
    setLanguages(res);
  }

  const getTimeZones = async () => {
    const res = await api.getTimeZones();
    setTimeZones(res);
  }

  useEffect(() => {
    if (languages.length == 0) {
      getLanguages();
    }
  }, [])

  useEffect(() => {
    if (timeZones.length == 0) {
      getTimeZones();
    }
  }, [])

  const handleSubmit = useCallback(async (values) => {
    // dispatch(
    //   authActions.onboardingUpdateProfile(
    //     {
    //       profileImageUri: values.profileImageUri ?? '',
    //       name: values.name,
    //       last_name: values.last_name,
    //       role: values.role,
    //       city: values.city,
    //       occupation: 'Occupation',
    //     },
    //     (error) => {
    //       setLoading(false)
    //       dispatch(profileActions.fetchProfile(userProfile.id))
    //       dispatch(actions.index({ page: 1 }, (err) => { console.log('err', err) }))
    //       if (error) {
    //         if (isMobile) {
    //           Toast.show({
    //             type: 'error',
    //             position: 'bottom',
    //             text1: 'Something went wrong, try again later.',
    //           })
    //           return
    //         }
    //         setError(true)
    //       }
    //     },
    //   ),
    // ) 



    setLoading(true)
    const data = {
      image: values.profileImageUri ?? '',
      first_name: values.name,
      last_name: values.last_name,
      internal_id: values.internal_id,
      role: values.role,
      city: values.city,
      occupation: 'Occupation',
      timezone: values.timezone,
      language: values.language
    }
    try {
      const response = await api.onboardingUpdateUserProfile(data)
      if (response?.data) {
        setLoading(false)

        dispatch(authActions.onboardingUpdateProfile(response?.data?.data))
        // dispatch(authActions.toggleFirstAccess())
        if (showCommunityGuidelines) {
          navigation.navigate('CommunityGuidelines')
        } else {
          navigation.navigate('guidedTutorial')
        }
        // cancel()
      }
    } catch (error) {
      console.log("error", error)
      if (isMobile) {
        Toast.show({
          type: 'error',
          position: 'bottom',
          text1: 'Something went wrong, try again later.',
        })
      }
    } finally {
      setLoading(false);
    }
  })

  const initialValues = useMemo(() => ({
    profileImageUri: '',
    name: member?.first_name ?? '',
    last_name: member?.last_name ?? '',
    internal_id: member?.internal_id ?? '',
    role: member?.role ?? '',
    city: member?.city ?? '',
    language: member?.language_id ?? '',
    timezone: member?.timezone_id ?? '',
  }))

  const isMobile = screenSize <= ScreenSize.Medium

  return (
    <TheaterLayout>
      <View style={styles.Container}>
        <ContentBlockV1>
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, isValid, setFieldValue, handleChange, handleSubmit }) => (
              <FormWrapper>
                <Text style={styles.Headline}>
                  {`Hey, ${values.name}!\nTell us more about you!`}
                </Text>
                <FormContent screenSize={screenSize}>
                  <TouchableOpacity
                    onPress={async () => {
                      try {
                        const imageUri = await openGallery()
                        if (isMobile) {
                          handleChange('profileImageUri')('data:image/jpeg;base64,' + await FileSystem.readAsStringAsync(imageUri, { encoding: 'base64' }))
                          return
                        }
                        handleChange('profileImageUri')(imageUri)
                      } catch (e) {
                        console.log('Error opening gallery')
                      }
                    }}
                  >
                    <AvatarWrapper screenSize={screenSize}>
                      <Avatar
                        style={{ backgroundColor: '#F1F1F1' }}
                        size={screenSize <= ScreenSize.Medium ? 90 : 120}
                        source={values.profileImageUri ? { uri: values?.profileImageUri } : null}
                      />
                      <CircleCamera isMobile={isMobile}>
                        <Icon icon="addPhoto" color="#323232" height={isMobile ? 12 : 22} width={isMobile ? 12 : 22} />
                      </CircleCamera>
                    </AvatarWrapper>
                  </TouchableOpacity>
                  <ToastWeb
                    text="Something went wrong, try again later."
                    visible={error}
                    setVisible={setError}
                    duration={3500}
                  />
                  <FieldsWrapper screenSize={screenSize}>
                    <FieldWrapper screenSize={screenSize}>
                      <TextField
                        label="First Name"
                        value={values.name}
                        autoCapitalize="words"
                        autoCompleteType="name"
                        onChangeText={handleChange('name')}
                      />
                    </FieldWrapper>
                    <FieldWrapper screenSize={screenSize}>
                      <TextField
                        label="Last Name"
                        value={values.last_name}
                        autoCapitalize="words"
                        onChangeText={handleChange('last_name')}
                      />
                    </FieldWrapper>
                    <FieldWrapper screenSize={screenSize}>
                      <TextField
                        label="Internal ID"
                        value={values.internal_id}
                        autoCapitalize="words"
                        onChangeText={handleChange('internal_id')}
                      />
                    </FieldWrapper>
                    <FieldWrapper screenSize={screenSize}>
                      <TextField
                        label="Role/Position"
                        autoCapitalize="words"
                        value={values.role}
                        onChangeText={handleChange('role')}
                      />
                    </FieldWrapper>
                    <FieldWrapper screenSize={screenSize}>
                      <TextField
                        label="City"
                        value={values.city}
                        onChangeText={handleChange('city')}
                      />
                    </FieldWrapper>
                    <FieldWrapper screenSize={screenSize}>
                      <Dropdown placeholder="Select Your Language" flatListProps={{ inverted: false }} autoScroll={false} onChange={item => setFieldValue("language", item.id)} value={values?.language || 40} data={languages} label="Language" labelField="display_name" valueField="id" />
                    </FieldWrapper>
                    <FieldWrapper screenSize={screenSize}>
                      <Dropdown placeholder="Select Your Timezone" flatListProps={{ inverted: false }} autoScroll={false} onChange={item => setFieldValue("timezone", item.id)} value={values?.timezone || 100} data={timeZones} label="Timezone" labelField="display_name" valueField="id" />
                    </FieldWrapper>
                    <KeyboardAvoidingView
                      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
                      style={styles.keyboardStyle}
                    >
                      <SubmitButton
                        color="black"
                        disabled={!isValid}
                        onPress={handleSubmit}
                      >
                        <SubmitButtonText>Submit</SubmitButtonText>
                        <Entypo name="chevron-right" size={16} color="white" />
                      </SubmitButton>
                    </KeyboardAvoidingView>
                  </FieldsWrapper>

                </FormContent>
              </FormWrapper>
            )}
          </Formik>
        </ContentBlockV1>
      </View>
    </TheaterLayout>
  )
}

export default PersonalInformation
