import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	Image,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	TextInput,
	FlatList,
	Dimensions,
	CheckBox,
	Platform,
} from "react-native";
import { isMobile } from "@/styles";
import Modal from "react-native-modal";
import ApiHandler from "@/api/ApiHandler";
import { Ionicons } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import * as ImagePicker from "expo-image-picker";
const deviceHeight = Dimensions.get("window").height;
import { ActivityIndicator, RadioButton } from "react-native-paper";
import BottomSheet from "./BottomSheet";
import Button from "./Button";
import OutlineButton from "./OutlineButton";

import ModalV2 from '@/components/ModalV2'

const selectRewards = [
	{
		id: 1,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 2,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 3,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 4,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 5,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 6,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 5,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 6,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
];

const CreateRewardModal = ({
	isRewardModalVisible,
	toggleRewardModal,
	update = false,
	rewardId = null,
}) => {
	const [activeTab, setActiveTab] = useState("create");
	const [arrayData, setArrayData] = useState(selectRewards);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [image, setImage] = useState("");
	const [loading, setLoading] = useState(false);
	const [selectedReward, setSelectedReward] = useState({});

	async function openImage() {
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.All,
			allowsEditing: true,
			aspect: [4, 3],
			quality: 1,
			base64: true,
		});
		if (!result.cancelled) {
			const str = result.uri;
			const path = str.split(",");
			setImage(Platform.OS === "web" ? path[1] : result.base64);
		}
	}

	const createRewards = async () => {
		setLoading(true);
		const data = {
			workspace_id: 1,
			type: "manual",
			title: title,
			description: description,
			image: image,
		};

		try {
			const response = await new ApiHandler().createRewards(data);
			console.log("createRewardsRes......", response?.data);
			if (response?.data) {
				// dispatch(actions.show({ id: route?.params?.id }, () => { }))
				setLoading(false);
				Toast.show({
					type: "success",
					position: "bottom",
					text1: "Reward Created Successfully",
				});
			}
		} catch (error) {
			console.log("error", error);
			setLoading(false);
			Toast.show({
				type: "error",
				position: "bottom",
				text1: error,
			});
		}
	};

	const updateRewards = async () => {
		setLoading(true);
		const data = {
			workspace_id: 1,
			type: "manual",
			title: title,
			description: description,
			image: image,
		};

		try {
			const response = await new ApiHandler().updateReward(rewardId, data);
			console.log("updateRewardsRes......", response?.data);
			if (response?.data) {
				// dispatch(actions.show({ id: route?.params?.id }, () => { }))
				setLoading(false);
				Toast.show({
					type: "success",
					position: "bottom",
					text1: "Reward Updated Successfully",
				});
			}
		} catch (error) {
			console.log("error", error);
			setLoading(false);
			Toast.show({
				type: "error",
				position: "bottom",
				text1: error,
			});
		}
	};

	const getRewardById = async () => {
		try {
			const response = await new ApiHandler().getRewardById(rewardId);
			if (response?.data) {
				console.log("EDIT ***** ====== ^^^^^^^^ REWARD BY ID", response?.data);
				setSelectedReward(response?.data?.data);
				setTitle(response?.data?.data?.title);
				setDescription(response?.data?.data?.description);
				setImage(response?.data?.data?.image);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	useEffect(() => {
		getRewardById();
	}, []);

	return Platform.OS == "web" ? (
		<ModalV2
			isVisible={isRewardModalVisible}
			setVisibility={toggleRewardModal}
			title={update == true
				? "Update Reward"
				: "Create Reward"}
		>
			<>
				<View>
					<Text style={styles.rewardModalTitle}>
						{update == true
							? "Update Reward"
							: "Create A Reward For Your Workspace"}
					</Text>
					<Text style={styles.rewardModalDes}>
						Create rewards within your workspace's reward library, which are then available for rewarding to your community members.
					</Text>


				</View>

				<>
					<View style={styles.imgContainer}>
						<View style={{ flex: 0.45 }}>
							<Text style={styles.titleText}>{"Title"}</Text>
							<TextInput
								style={styles.giftCard}
								placeholder="i.e $10 Starbucks Gift Card"
								placeholderTextColor="#989898"
								value={title}
								onChangeText={(text) => {
									setTitle(text);
								}}
							/>
						</View>
						<View style={{ flex: 0.45 }}>
							<Text style={styles.titleText}>{"Upload image"}</Text>
							{/* {image ?
                                                <View style={[styles.giftImgCard]} >
                                               <Image source={{uri:images}} style={{height:50,width:50}} />
                                                </View>
                                                : */}
							<TouchableOpacity
								style={[styles.giftImgCard]}
								onPress={() => {
									openImage();
								}}
							>
								<Text
									style={[styles.giftCardTxt, { color: "#FB6758" }]}
								>
									Upload{" "}
									<Text style={styles.imgSizeTxt}>
										(2mb file, png, jpeg)
									</Text>
								</Text>
							</TouchableOpacity>
							{/* //} */}
						</View>
					</View>
					<View style={{ marginVertical: 20 }}>
						<Text style={styles.titleText}>{"Description"}</Text>
						<View style={styles.rewardTxtInput}>
							<TextInput
								placeholder="Provide a description of this reward."
								placeholderTextColor="#989898"
								multiline
								numberOfLines={5}
								value={description}
								onChangeText={(text) => {
									setDescription(text);
								}}
							/>
						</View>
						{loading == true && (
							<ActivityIndicator
								size={30}
								animating
								color="#FB6758"
							// style={{ marginRight: 10 }}
							/>
						)}
					</View>
				</>

				<View style={styles.bottomView}>
					<Text style={styles.saveCreateTxt}>
						{/* {"Save & Create another"} */}
					</Text>
					<View style={styles.btnAlignment}>
						<TouchableOpacity
							style={styles.rewardModalBtn}
							onPress={() => {
								update == true ? updateRewards() : createRewards();
								toggleRewardModal();
							}}
						>
							<Text style={styles.createTxt}>
								{update == true ? "Update" : "Create"}
							</Text>
						</TouchableOpacity>
						<TouchableOpacity>
							{/* <Text style={styles.saveCreateTxt}>{"Send as a Reward"}</Text> */}
						</TouchableOpacity>
					</View>
				</View>

			</>
		</ModalV2>
	) : (
		<ModalV2
			isVisible={isRewardModalVisible}
			setVisibility={toggleRewardModal}
			title={update == true
				? "Update Reward"
				: "Create Reward"}
		>
			<>
				<View>
					<Text style={styles.rewardModalTitle}>
						{update == true
							? "Update A Reward"
							: "Create A Reward"}
					</Text>
					<Text style={styles.rewardModalDescription}>

					</Text>
				</View>

				<>
					<Text style={styles.modalInputLabel}>{"Title"}</Text>
					<TextInput
						style={styles.modalTextInput}
						placeholder="$10 Gift Card"
						placeholderTextColor="#989898"
						value={title}
						onChangeText={(text) => {
							setTitle(text);
						}}
					/>
					<Text style={styles.modalInputLabel}>{"Upload image"}</Text>
					<TouchableOpacity
						style={styles.rewardUploadImg}
						onPress={() => {
							openImage();
						}}
					>
						<Text style={styles.rewardUploadText}>
							Upload{" "}
							<Text style={styles.rewardImgSizeText}>
								(2mb file, png, jpeg)
							</Text>
						</Text>
					</TouchableOpacity>
					<Text style={styles.modalInputLabel}>{"Description"}</Text>
					<TextInput
						style={[styles.modalTextInput, { height: 91 }]}
						placeholder="Write here"
						placeholderTextColor="#989898"
						multiline
						numberOfLines={5}
						value={description}
						onChangeText={(text) => {
							setDescription(text);
						}}
					/>
					{loading == true && (
						<ActivityIndicator size={30} animating color="#FB6758" />
					)}
					{/* <TouchableOpacity>
								<Text style={styles.rewardSendText}>Send as a Reward</Text>
							</TouchableOpacity> */}
				</>

				<View style={styles.rewardButtonsView}>
					<Button
						label={update == true ? "Update" : "Create"}
						onPress={() => {
							{
								update == true ? updateRewards() : createRewards();
								toggleRewardModal();
							}
						}}
					/>
					{/* <OutlineButton 
							label="Save & Create another"
						/> */}
				</View>
			</>
		</ModalV2>
	);
};

export default CreateRewardModal;
const styles = StyleSheet.create({
	closeBtn: {
		position: "absolute",
		right: 8,
		top: 8,
	},
	headerTitle: {
		fontSize: 32,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	modalHeaderView: {
		flex: 0.15,
		flexDirection: "row",
		alignItems: "center",
	},
	modalProfilePic: {
		height: 22,
		width: 35,
		borderRadius: 4,
	},
	modalTitle: {
		marginLeft: 5,
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	importedBtn: {
		backgroundColor: "#FFCF70",
		borderRadius: 4,
		height: 16,
		width: 45,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 15,
	},
	importedBtnTxt: {
		fontSize: 8,
		fontWeight: "500",
		lineHeight: 10,
	},
	photoView: {
		flexDirection: "row",
		alignItems: "center",
	},
	photo: {
		height: 50,
		width: 50,
		borderRadius: 33,
		marginEnd: 8,
	},

	imgCountModal: {
		fontSize: 15,
		lineHeight: 20,
		color: "#fff",
		fontWeight: "400",
		position: "absolute",
		top: 15,
		left: 10,
	},
	awardeesTxt: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
	},
	input: {
		borderBottomWidth: 1,
		borderBottomColor: "#959595",
		marginTop: 25,
		marginBottom: 10,
		height: 40,
		padding: 5,
	},
	searchIcon: {
		position: "absolute",
		right: 20,
		bottom: 20,
	},
	descriptionView: {
		flex: 0.45,
		paddingVertical: 10,
	},
	selectCheck: {
		flexDirection: "row",
		marginVertical: 8,
		alignItems: "center",
	},
	memberName: {
		fontSize: 15,
		lineHeight: 20,
		fontWeight: "400",
	},
	memberPost: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#B5B5B5",
	},
	editBtn: {
		backgroundColor: "#000",
		height: 40,
		width: 133,
		borderRadius: 3,
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#000",
		borderWidth: 1,
	},
	cancelBtn: {
		marginEnd: 10,
		backgroundColor: "#fff",
	},
	editBtnTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	milestoneModalHeader: {
		flex: 1,
		paddingHorizontal: 25,
	},

	rightSideModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rightSideModalDec: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
	},
	dropDownView: {
		marginTop: 20,
		paddingVertical: 15,
	},
	rightSideModalPointsTitle: {
		fontSize: 18,
		lineHeight: 21.09,
		fontWeight: "400",
	},
	selectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	selectReward: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
		color: "#FB6758",
	},
	selectedContainer: {
		backgroundColor: "#E8E8E8",
		height: 117,
		// width: "35,",
		borderRadius: 4,
		paddingHorizontal: 12,
		marginVertical: 5,
		paddingVertical: 5,
	},
	selectedCardView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	selectedCardHeader: {
		flexDirection: "row",
		alignItems: "center",
	},
	radioBtn: {
		height: 12,
		width: 12,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#000",
		marginEnd: 5,
	},
	cardImg: {
		height: 22,
		width: 35,
		borderRadius: 3,
		marginEnd: 5,
	},
	selectedCardTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	criteriaTxt: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#FB6758",
	},
	selectedCardDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#000",
		marginVertical: 5,
	},
	cardBottom: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 5,
	},
	awardTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#00B15C",
	},
	ipmTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#000",
	},
	rightModalBtn: {
		backgroundColor: "#FB6758",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		height: 40,
		marginVertical: 10,
	},
	rightModalBtnText: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	rightSideModal: {
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderBottomLeftRadius: 20,
		alignSelf: "flex-end",
		paddingVertical: 15,
		margin: 0,
		width: "40%",
		// height: "100%",
	},
	closeBtn: {
		position: "absolute",
		right: 8,
		top: 8,
	},
	rewardModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rewardModalDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#141414",
	},
	modal: {
		height: "70%",
		width: "55%",
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 20,
		paddingVertical: 15,
	},
	rewardTabView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	rewardTab: {
		justifyContent: "center",
		alignItems: "center",
		borderBottomWidth: 2,
		paddingVertical: 5,
		width: "48%",
		flex: 0.5,
		marginHorizontal: 2,
		marginVertical: 20,
	},
	rewardBtnTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "400",
		color: "#141414",
	},
	imgContainer: {
		flex: 1,
		justifyContent: "space-between",
		flexDirection: "row",
		marginVertical: 10,
	},
	titleText: {
		fontSize: 18,
		lineHeight: 21,
		fontWeight: "400",
		color: "#141414",
		marginVertical: 5,
	},
	giftCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		paddingHorizontal: 10,
	},

	giftImgCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		borderColor: "#FB6758",
		borderWidth: 1,
		borderStyle: "dashed",
	},
	giftCardTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
	},
	imgSizeTxt: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: "500",
		color: "gray",
	},

	rewardTxtInput: {
		padding: 10,
		height: 100,
		marginVertical: 10,
		backgroundColor: "#F9F9F9",
	},
	importView: {
		flex: 1,
		alignItems: "center",
		alignSelf: "center",
	},

	rewardModalSelectionList: { height: 138, width: "98%", marginEnd: 5 },

	bottomView: {
		justifyContent: "space-between",
		flexDirection: "row",
		// marginTop: 150,
		position: "absolute",
		bottom: 5,
		right: 20,
		left: 20,
	},
	saveCreateTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		color: "#FB6758",
	},
	btnAlignment: {
		alignItems: "center",
	},
	rewardModalBtn: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#FB6758",
		height: 40,
		width: 180,
		borderRadius: 4,
	},
	createTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	workspaceBtn: {
		backgroundColor: "#FB6758",
		height: 40,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		position: "absolute",
		width: 227,
		alignSelf: "flex-end",
		bottom: 5,
		right: 20,
	},

	container: {
		padding: 15,
		flex: 1,
	},
	rewardTitle: {
		fontSize: 36,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	subTitleView: {
		flexDirection: "row",
	},
	activityTxt: {
		fontSize: 24,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	monthView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 20,
		marginTop: 10,
		marginEnd: 8,
	},
	monthTxt: {
		fontSize: 14,
		lineHeight: 18.3,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginEnd: 5,
	},
	cardView: {
		flexDirection: "row",
	},
	topPerformersView: { flex: 0.4, paddingLeft: 15 },

	lastMonthTxt: {
		color: "#909090",
		fontSize: 14,
		lineHeight: 18.23,
		fontWeight: "400",
		marginTop: 40,
	},
	headerTitleBtnView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 30,
		marginBottom: 10,
		justifyContent: "space-between",
	},
	titleView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},

	Button: {
		backgroundColor: "#FB6758",
		paddingHorizontal: 50,
		paddingVertical: 1.2,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
	},

	BtnText: {
		color: "#fff",
		fontSize: 18,
		lineHeight: 35,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	btnView: {
		flex: 0.1,
		flexDirection: "row",
		alignSelf: "flex-end",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	calenderView: {
		width: !isMobile ? 410 : null,
		height: !isMobile ? 390 : null,
		borderRadius: 10,
		backgroundColor: "#FFFFFF",
		margin: 10,
		marginTop: 10,
		paddingVertical: 10,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	dropDownModal: {
		backgroundColor: "#fff",
		width: 167,
		height: 183,
		borderRadius: 10,
		marginTop: "10%",
		marginLeft: "8%",
		paddingHorizontal: 16,
		paddingVertical: 20,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		position: "absolute",
		top: -20,
		left: 10,
	},
	dropDownModalContainer: {
		flex: 1,
		flexDirection: "column",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	dropDown: {
		height: 187,
		width: 167,
		backgroundColor: "#fff",
		zIndex: 999,
		position: "absolute",
		top: 35,
		left: 20,
		borderRadius: 8,
		paddingVertical: 20,
		paddingHorizontal: 16,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	inputField: {
		height: 40,
		width: "100%",
		backgroundColor: "#F9F9F9",
		borderRadius: 8,
		marginTop: 5,
		fontSize: 16,
		paddingHorizontal: 10,
	},
	rewardContainer: {
		flex: 1,
		marginHorizontal: 16,
	},
	modalCloseBtn: {
		alignSelf: "flex-end",
		marginTop: 20,
	},
	rewardModalTitle: {
		fontSize: 18,
		lineHeight: 20,
		fontFamily: "WorkSans_400Regular",
		letterSpacing: -0.8,
		color: "#000000",
	},
	rewardModalDescription: {
		fontSize: 12,
		lineHeight: 16,
		fontFamily: "WorkSans_400Regular",
		color: "#141414",
		paddingTop: 8,
		paddingBottom: 16,
	},
	modalInputLabel: {
		fontSize: 12,
		lineHeight: 16,
		fontFamily: "WorkSans_400Regular",
		color: "#000000",
		marginBottom: 7,
	},
	modalTextInput: {
		height: 40,
		backgroundColor: "#F9F9F9",
		borderRadius: 8,
		paddingLeft: 15,
		marginBottom: 16,
	},
	rewardUploadImg: {
		height: 40,
		backgroundColor: "#F9F9F9",
		borderWidth: 1,
		borderStyle: "dashed",
		borderColor: "#FB6758",
		borderRadius: 8,
		justifyContent: "center",
		alignItems: "center",
		marginBottom: 16,
	},
	rewardUploadText: {
		fontSize: 14,
		lineHeight: 18,
		fontFamily: "WorkSans_500Medium",
		color: "#FB6758",
	},
	rewardImgSizeText: {
		fontSize: 11,
		lineHeight: 14,
		fontFamily: "WorkSans_500Medium",
		color: "#6F6F6F",
	},
	rewardSendText: {
		fontSize: 16,
		lineHeight: 21,
		color: "#FB6758",
		fontFamily: "WorkSans_500Medium",
	},
	rewardButtonsView: {
		flexDirection: "column",
		flex: 1,
		justifyContent: "center",
	},
});
