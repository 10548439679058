import styled from 'styled-components/native'
import { TextInput, View } from 'react-native'

export const Container = styled(View)`
  height: 45px;
  border: 2px solid #d9d9d9;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
`
export const InputText = styled(TextInput)`
  font-size: 24px;
  font-weight: 400;
  color: #000000;
  width: 270px;
  height: 45px;
  padding-left: 15px;
`
