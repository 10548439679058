import React from 'react'
import Svg, { Path, Defs, LinearGradient, Stop, SvgProps } from 'react-native-svg'

function ResetPasswordDecoration(props) {
  return (
    <Svg width="822" height="1024" viewBox="0 0 822 1024" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M-497.382 26.5715L-124.482 1280.29L770.132 1012.17C770.132 1012.17 731.538 944.14 717.572 909.389C695.769 855.137 675.034 803.397 660.302 757.932C634.576 678.541 621.399 615.188 611.539 568.8C576.874 405.72 620.215 323.626 555.384 107.312C498.35 -82.9827 374.651 -234.786 374.651 -234.786L-497.382 26.5715Z" fill="url(#paint0_linear)" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M209.605 651.904L255.534 707.032L282.148 707.208C284.515 704.097 287.286 701.266 290.449 698.817L293.428 696.511L271.878 624.398C271.878 624.398 256.083 591.727 251.516 589.567C244.657 586.322 245.603 606.606 241.064 608.611C229.723 613.621 209.605 651.904 209.605 651.904Z" fill="#323A44" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M212.982 654.88C212.645 654.478 212.323 654.059 212.035 653.597C211.224 652.314 213.273 647.4 212.982 646C212.486 646.939 209.435 651.445 209.002 652.457C200.966 671.207 208.104 706.831 256.457 707.208L212.967 654.892L212.982 654.88Z" fill="#323A44" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M257.62 596.304L264.669 589.493L264.681 589.508C265.199 589.006 265.742 588.523 266.332 588.08C269.353 585.837 272.879 584.774 276.383 584.774V584.755H285.779L285.774 584.752H296.473L292.922 543.888L292.026 544.047C288.517 544.665 285.161 542.335 284.54 538.843C283.915 535.351 286.263 532.02 289.779 531.403L291.047 531.178C299.5 529.692 307.238 525.505 313.078 519.253C319.361 512.535 327.347 507.687 336.148 505.171L344.094 537.88C346.925 549.545 339.273 561.169 327.382 563.259L323.206 563.995L325.006 584.752H334.483V584.755H338.241L338.259 584.769C341.202 584.772 344.129 585.538 346.712 587.035C344.837 582.418 343.504 577.336 345.333 569.57C345.338 569.545 345.343 569.522 345.349 569.498C346.117 566.256 347.858 563.344 350.098 560.87C351.318 559.527 352.463 558.114 353.531 556.634L353.549 556.624C353.844 556.201 354.139 555.792 354.422 555.365C357.36 551.034 359.641 546.167 361.111 540.871C368.796 513.244 351.391 484.6 322.545 478.928C298.154 474.133 273.552 488.348 265.653 511.779C263.577 517.934 262.763 524.184 263.087 530.267C263.111 530.777 263.146 531.305 263.193 531.825C263.346 533.634 263.601 535.428 263.949 537.197C264.597 540.464 264.585 543.85 263.571 547.024C263.564 547.048 263.554 547.071 263.548 547.096C257.625 565.483 243.953 560.938 238.03 579.324C232.533 596.384 239.14 600.214 235.989 615.525L257.5 596.5L257.62 596.304Z" fill="#323A44" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M330.157 550.343C334.208 549.614 336.912 545.695 336.195 541.587L321.522 544.222C322.239 548.329 326.106 551.07 330.157 550.343Z" fill="white" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M264.588 936.652L263.304 936.581L259.421 1012.31C259.414 1012.45 259.414 1012.6 259.414 1012.75H276.09V1013.31L297.862 841.776L269.451 841.794L264.588 936.652Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M435.386 778.194V778.189H392.07V778.264H389.521V778.189H373.635L370.598 819.06H422.647V821.613H379.323V830.558H369.742L367.363 862.479H422.652V778.194H435.386Z" fill="#FF5656" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M436.852 778.189H423.556V862.472V862.479H436.865V778.189H436.852Z" fill="#5E5E5E" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M362.097 830.136V821.073H357.019L354.056 862.479H367.952L370.323 830.136H362.097Z" fill="#FF2626" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M325.959 841.828V841.776L312.65 841.781V862.479H324.55L324.531 841.828H325.959Z" fill="#FF2626" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M355.534 818.116H370.121L373.279 776.71H358.633L355.534 818.116Z" fill="#FF2626" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M357.013 821.073H362.132V818.116H380.047V821.073H423.558V818.116H371.282H357.2L357.013 821.073Z" fill="#5E5E5E" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M361.448 818.116V820.743V829.946H369.66H379.194V820.743V818.116H361.448Z" fill="white" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M383.909 764.386H389.73V776.638V776.71H392.503V776.638V761.923H383.63C383.801 762.546 383.928 763.183 383.934 763.852C383.934 764.032 383.921 764.211 383.909 764.386Z" fill="#5E5E5E" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M365.862 764.468L365.839 761.923H360.157L359.971 764.88H365.886C365.88 764.744 365.868 764.61 365.862 764.468Z" fill="#5E5E5E" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M356.404 818.609L359.371 777.457L360.291 764.67L360.478 762.099L361.45 748.62L361.446 748.614C361.446 748.62 361.44 748.625 361.44 748.632L361.45 748.638L354.626 841.464L325.959 841.482V841.535H327.114L327.178 920.998H325.959V932.898L327.183 932.893L327.213 972.437H325.959V979.341H327.218L327.224 984.595H325.959V1011.82H342.457L342.48 1011.83L353.258 862.327L356.219 821.18L356.404 818.609Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M366.011 748.975L366.153 762.435L366.176 764.688C366.182 764.814 366.193 764.933 366.198 765.052C366.439 769.944 370.302 773.806 375.004 773.752C379.636 773.699 383.381 769.853 383.61 765.052C383.621 764.867 383.631 764.675 383.631 764.484C383.626 763.773 383.513 763.098 383.359 762.435C383.285 762.129 383.221 761.812 383.119 761.519L383.13 761.513L378.606 748.15L379.801 737.572L365.886 736.784L366.011 748.975Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M386.244 654.066L386.232 654.071L386.861 655.142C387.814 656.76 388.329 658.594 388.346 660.458C388.352 660.875 388.322 661.322 388.257 661.869L384.44 693.599L385.747 693.747L387.046 693.9L390.88 662.175L391.024 660.835H390.969V660.428C390.947 658.541 390.521 656.671 389.711 654.96L389.803 654.908L374.059 628.834L372.926 629.493L371.801 630.145L386.244 654.066Z" fill="#FF7070" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M384.033 698.336L382.153 714.294L383.427 714.45L384.709 714.602L386.589 698.637L385.308 698.483L384.033 698.336Z" fill="#FF7070" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M354.529 841.728L361.403 748.796L361.391 748.789C361.391 748.784 361.397 748.778 361.397 748.771C361.415 748.525 361.415 748.273 361.419 748.019C361.425 747.825 361.45 747.637 361.45 747.438V729.805C361.45 718.456 358.859 707.261 353.887 697.074V698.139H337.63V696.857H333.136V698.139H329.197V696.857H327.5H294.35L294.07 697.069L291.147 699.362C288.042 701.794 285.323 704.61 283.001 707.7C281.723 709.405 280.568 711.186 279.547 713.05L274.796 726.426C274.441 728.342 274.206 730.294 274.108 732.262H274.101L273.109 751.482L274.446 751.552L274.201 756.365L272.864 756.295L272.392 765.463L273.67 765.528L269.766 841.776L298.062 841.757L312.667 841.752L325.661 841.746L354.529 841.728Z" fill="#9D9D9D" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M327.43 979.301H325.968H324.48L324.487 985.216H325.968H327.437L327.43 979.301Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M327.404 931.981L325.994 931.986H324.48L324.513 971.907H325.994H327.437L327.404 931.981Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M327.364 841.776H326.053H324.48L324.5 862.672L324.552 921.629H326.053H327.437L327.364 841.776Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M366.601 621.44L367.678 620.817L368.843 620.141L365.164 614.046L363.992 614.725L362.928 615.345L366.601 621.44Z" fill="#FF7070" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M353.933 695.378H337.789V696.864V698.336H354.056V697.114V695.378H353.933Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M259.211 1011.83H259.2L250.833 1016.83C249.734 1017.48 249.062 1018.69 249.062 1020C249.062 1022.03 250.642 1023.66 252.592 1023.66H275.68V1012.4V1011.83H259.211Z" fill="#5E5E5E" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M342.434 1011.83H325.959V1023.66H349.041C350.991 1023.66 352.577 1022.03 352.577 1020C352.577 1018.69 351.9 1017.48 350.805 1016.83L342.457 1011.85L342.434 1011.83Z" fill="#5E5E5E" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M273.219 624.552L294.352 696.094L294.633 695.883H327.759H329.456V694.592H333.392V695.883H337.883V694.592H354.007L354.175 651.867C351.032 654.623 346.934 656.307 342.449 656.307C340.344 656.307 338.279 655.944 336.326 655.223L337.201 652.811C338.88 653.427 340.642 653.738 342.449 653.738C350.899 653.738 357.774 646.821 357.774 638.32C357.774 637.476 357.704 636.625 357.564 635.792L360.082 635.359C360.245 636.338 360.328 637.33 360.328 638.32C360.328 643.178 358.398 647.59 355.272 650.829L365.256 663.653L366.054 736.009L380.318 736.784L383.076 713.388L381.809 713.235L383.676 697.408L384.942 697.555L385.537 692.509L384.265 692.362L387.98 660.708C388.043 660.162 388.074 659.716 388.068 659.3C388.05 657.44 387.548 655.609 386.621 653.996L386.009 652.928L386.02 652.923L371.962 629.058L373.057 628.407L368.288 620.306L367.232 620.933L363.628 614.793L364.673 614.169L352.012 592.676L352 592.687C351.632 592.068 351.243 591.458 350.788 590.877C349.452 589.159 347.842 587.788 346.076 586.753C343.522 585.255 340.629 584.489 337.719 584.486L337.701 584.471H333.987L335.242 614.139L285.838 584.471H276.549V584.49C273.085 584.49 269.599 585.554 266.613 587.799C266.03 588.241 265.493 588.725 264.98 589.227L264.969 589.214L258 596.028L258.996 597.065L273.219 624.552ZM331.315 652.389C330.185 651.479 329.164 650.43 328.279 649.273L330.302 647.708C331.065 648.698 331.94 649.602 332.907 650.377L331.315 652.389Z" fill="#FFAFAF" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M329.376 648.058L327.437 649.543C328.286 650.637 329.264 651.632 330.348 652.494L331.874 650.587C330.946 649.854 330.108 648.997 329.376 648.058Z" fill="#FF7070" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M354.985 651.515C358.067 648.316 359.97 643.956 359.97 639.155C359.97 638.176 359.888 637.195 359.727 636.228L357.245 636.656C357.383 637.481 357.452 638.32 357.452 639.155C357.452 647.555 350.674 654.39 342.345 654.39C340.563 654.39 338.827 654.083 337.172 653.475L336.309 655.858C338.236 656.57 340.27 656.93 342.345 656.93C346.766 656.93 350.806 655.267 353.905 652.541C354.278 652.217 354.642 651.874 354.985 651.515Z" fill="#FF7070" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M323.899 584.342L322.088 563.548L326.291 562.81C338.259 560.716 345.959 549.072 343.109 537.387L335.114 504.618C326.255 507.14 318.218 511.996 311.896 518.725C306.018 524.988 298.23 529.183 289.724 530.673L288.446 530.897C284.909 531.515 282.546 534.854 283.175 538.351C283.799 541.849 287.178 544.183 290.709 543.564L291.611 543.406L295.185 584.342H284.417L284.422 584.345L334.715 614.046L333.439 584.345V584.342H323.899ZM335.475 540.81C336.163 544.657 333.568 548.329 329.68 549.011C325.792 549.692 322.082 547.125 321.393 543.277L335.475 540.81Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M268.636 841.504L272.722 765.011L271.385 764.946L270.053 764.88L260.892 936.28L262.229 936.346L263.568 936.417L268.636 841.504Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M273.944 756.008L274.202 751.692L272.792 751.629L271.503 751.571L271.244 755.887L272.533 755.944L273.944 756.008Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M328.917 695.378V696.864V698.336H333.353V696.864V695.378H328.917Z" fill="#7A7A7A" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M262.558 748.445C259.465 750.195 255.537 749.109 253.784 746.021C252.031 742.932 253.118 739.009 256.213 737.261C259.306 735.511 263.234 736.597 264.987 739.683C266.74 742.772 265.653 746.697 262.558 748.445Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M242.5 662.5L253.539 744.664L265.517 743.614L263.549 660.741L242.5 662.5Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M251.831 733.159L265.185 731.987L263.49 660.691L242 662.5L251.831 733.159Z" fill="#FFAFAF" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M257.885 759.389C257.418 758.577 257.112 757.72 256.941 756.853L256.934 756.856L254.216 743.054L265.037 739.204L272.084 751.464C274.355 755.414 273.015 760.39 269.094 762.578C265.173 764.767 260.155 763.339 257.885 759.389Z" fill="#B58D69" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M265.2 617.713C273.767 620.084 283.857 614.764 287.74 605.832C291.62 596.897 287.822 587.734 279.258 585.362C270.692 582.991 260.601 588.31 256.719 597.244C252.837 606.178 256.634 615.342 265.2 617.713Z" fill="#FFAFAF" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M264.121 667.219C265.026 661.226 260.958 655.625 255.036 654.71C249.113 653.795 243.579 657.912 242.676 663.906C241.771 669.898 245.838 675.5 251.76 676.414C257.683 677.331 263.217 673.213 264.121 667.219Z" fill="#FFAFAF" />
      <Path fill-rule="evenodd" clip-rule="evenodd" d="M256 600L241.948 662.802L263.81 669.367L285.238 606.51L256 600Z" fill="#FFAFAF" />
      <Defs>
        <LinearGradient id="paint0_linear" x1="-13.8098" y1="259.018" x2="363.975" y2="81.9897" gradientUnits="userSpaceOnUse">
          <Stop stop-color="white" />
          <Stop offset="1" stop-color="#F7F9FA" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

ResetPasswordDecoration.propTypes = SvgProps

export default ResetPasswordDecoration
