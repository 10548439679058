import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    TextContainer: {
        marginHorizontal: 15
    },
    ImageContainer: {
        width: '100%',
        marginTop: 20,
    },
    Image: {
        aspectRatio: 1,
        width: "100%",
        resizeMode: "cover",
    },
    VideoContainer: {
        width: '100%',
        marginTop: 20,
    },
    Video: {
        width: "100%",
        maxHeight: 500,
        ...Platform.select({
            ios: {
                aspectRatio: 1
            },
            android: {
                aspectRatio: 1
            },
            web: {

            }
        })
    },
    ViewerContainer: {
        padding: 20,
        backgroundColor: 'rgb(27, 27, 27)',
        flex: 1
    },
    ViewerHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 20
    },
    ViewerDismissContainer: {
        marginRight: 15,
    },
    ViewerDismissButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: 50,
        justifyContent: 'center',

        ...Platform.select({
            ios: {
                width: 25,
                height: 25,
            },
            android: {
                width: 25,
                height: 25,
            },
            web: {
                width: 30,
                height: 30,
                alignItems: 'center',
            }
        })
    },
    ViewerTitleContainer: {

    },
    ViewerTitle: {
        fontFamily: "WorkSans_400Regular",
        color: '#FFFFFF',
        ...Platform.select({
            ios: {
                fontSize: 25,
                lineHeight: 25,
            },
            android: {
                fontSize: 25,
                lineHeight: 25,
            },
            web: {
                fontSize: 30,
                lineHeight: 30,
            }
        })
    },
    ViewerImageContainer: {
        flex: 1
    },
    ViewerImage: {
        aspectRatio: 1,
        width: "100%",
        height: "100%",
        maxWidth: "100%",
        maxHeight: "100%",
        resizeMode: "contain",
        borderRadius: 10
    }
});
