import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function CommunityIcon(props) {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M27.7833 21.8834C30.0666 23.4334 31.6666 25.5334 31.6666 28.3334V33.3334H38.3333V28.3334C38.3333 24.7 32.3833 22.55 27.7833 21.8834Z" />
      <Path fill={props.color} d="M24.9999 20C28.6833 20 31.6666 17.0167 31.6666 13.3334C31.6666 9.65002 28.6833 6.66669 24.9999 6.66669C24.2166 6.66669 23.4833 6.83335 22.7833 7.06669C24.1666 8.78335 24.9999 10.9667 24.9999 13.3334C24.9999 15.7 24.1666 17.8834 22.7833 19.6C23.4833 19.8334 24.2166 20 24.9999 20Z" />
      <Path fill={props.color} d="M15 20C18.6833 20 21.6667 17.0167 21.6667 13.3334C21.6667 9.65002 18.6833 6.66669 15 6.66669C11.3167 6.66669 8.33333 9.65002 8.33333 13.3334C8.33333 17.0167 11.3167 20 15 20ZM15 10C16.8333 10 18.3333 11.5 18.3333 13.3334C18.3333 15.1667 16.8333 16.6667 15 16.6667C13.1667 16.6667 11.6667 15.1667 11.6667 13.3334C11.6667 11.5 13.1667 10 15 10Z" />
      <Path fill={props.color} d="M15 21.6667C10.55 21.6667 1.66666 23.9 1.66666 28.3334V33.3334H28.3333V28.3334C28.3333 23.9 19.45 21.6667 15 21.6667ZM25 30H5V28.35C5.33333 27.15 10.5 25 15 25C19.5 25 24.6667 27.15 25 28.3334V30Z" />
    </Svg>
  )
}

CommunityIcon.propTypes = SvgProps

export default CommunityIcon
