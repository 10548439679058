import React, { useCallback, useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  ScrollView,
  RefreshControl,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/learning'
import * as selectorsCourse from '@/redux/selectors/course'
import * as selectorsEvent from '@/redux/selectors/event'

import { actions } from '@/redux/reducers/learning'
import { actions as actionArticle } from '@/redux/reducers/article'
import { actions as actionCourse } from '@/redux/reducers/course'
import { actions as actionEvent } from '@/redux/reducers/event'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Icon, Carousel, SearchHeader, SearchWeb } from '@/components'
import CourseCard from '@/components/Learning/CourseCard'
import ArticleCard from '@/components/Learning/ArticleCard'
import EventCard from '@/components/Learning/EventCard'
import Card from '@/components/Learning/Card'

import SimpleSelect from '@/components/SimpleSelect'
import { ProgressBar } from 'react-native-paper'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import theme from '@/styles/themes/black'
import { useIsMobile } from '@/styles'
import {
  Container,
  Title,
  HeadLine,
  SubTitle,
  Header,
  TitleWrapper,
  Select,
  styles,
  MoreText,
  ContentView,
} from '../styles'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import * as brandSelectors from '@/redux/selectors/branding'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const Learning = () => {
  console.log('????????/////////', actions)
  const branding = useSelector(brandSelectors.getSettings)
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const [filtered, setFiltered] = useState({
    employee_lifecycle_stage_id: null,
    five_s_category_id: null,
  })

  const [searching, setSearching] = useState(false)
  const [searchTxt, setSearchTxt] = useState('')
  const [searchTxtTemp, setSearchTxtTemp] = useState('')
  const navigation = useNavigation()
  const route = useRoute()

  const featured = useSelector(selectors.featured)
  const articles = useSelector(selectors.articles)
  const courses = useSelector(selectors.courses)
  const events = useSelector(selectors.events)
  const readLaterLoading = useSelector(selectors.readLaterLoading)
  const readLaterLoadingCourse = useSelector(selectorsCourse.readLaterLoading)
  const readLaterLoadingEvent = useSelector(selectorsEvent.readLaterLoading)
  const [refreshing, setRefreshing] = useState(false)

  const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout))
  }

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    dispatch(actions.featured({}, () => { }))
    dispatch(actions.filtered({}, () => { }))
    dispatch(actions.articles({}, () => { }))
    dispatch(actions.coursesAction({}, () => { }))
    dispatch(actions.eventsAction({}, () => { }))
    wait(1000).then(() => setRefreshing(false))
  }, [])

  const loading =
    useSelector(selectors.loading) ||
    readLaterLoading ||
    readLaterLoadingCourse ||
    readLaterLoadingEvent

  const handleFilterChange = (type, value) => {
    filtered[type] = value
    setFiltered({ ...filtered })
  }
  const setReadLater = (article) => {
    dispatch(actions.setReadLater(article, () => { }))
  }

  useEffect(() => {
    navigation.addListener('focus', () => {
      const filterDetail = {
        search: searchTxt,
        lifecycle_stage_id: filtered.employee_lifecycle_stage_id,
        five_s_category_id: filtered.five_s_category_id,
      }
      dispatch(actions.featured({}, () => { }))
      dispatch(actions.filtered({}, () => { }))
      dispatch(actions.articles(filterDetail, () => { }))
      dispatch(actions.coursesAction(filterDetail, () => { }))
      dispatch(actions.eventsAction(filterDetail, () => { }))
    })
  }, [])

  useEffect(() => {
    if (!readLaterLoading) {
      const filterDetail = {
        search: searchTxt,
        lifecycle_stage_id: filtered.employee_lifecycle_stage_id,
        five_s_category_id: filtered.five_s_category_id,
      }
      dispatch(actions.featured({}, () => { }))
      dispatch(actions.articles(filterDetail, () => { }))
    }
  }, [readLaterLoading])

  useEffect(() => {
    if (!readLaterLoadingCourse) {
      const filterDetail = {
        search: searchTxt,
        lifecycle_stage_id: filtered.employee_lifecycle_stage_id,
        five_s_category_id: filtered.five_s_category_id,
      }
      dispatch(actions.featured({}, () => { }))
      dispatch(actions.coursesAction(filterDetail, () => { }))
    }
  }, [readLaterLoadingCourse])

  useEffect(() => {
    if (!readLaterLoadingEvent) {
      const filterDetail = {
        search: searchTxt,
        lifecycle_stage_id: filtered.employee_lifecycle_stage_id,
        five_s_category_id: filtered.five_s_category_id,
      }
      dispatch(actions.featured({}, () => { }))
      dispatch(actions.eventsAction(filterDetail, () => { }))
    }
  }, [readLaterLoadingEvent])

  /**
   * @param {type : string}
   */
  const handleCreate = useCallback((routeName) => {
    if (routeName == 'CreateArticle') {
      dispatch(actionArticle.clearArticle())
      dispatch(
        actionArticle.storeDraft({}, (err, success) => {
          console.log(err, success)
        })
      )
    }
    navigation.navigate(routeName, {
      previus_route_name: route?.name || '',
      id: null,
    })
  }, [])

  const handleMore = useCallback((routeName) => {
    navigation.navigate(routeName, {
      previus_route_name: route?.name || '',
    })
  }, [])

  useEffect(() => {
    const filterDetail = {
      search: searchTxt,
      lifecycle_stage_id: filtered.employee_lifecycle_stage_id,
      five_s_category_id: filtered.five_s_category_id,
    }
    dispatch(actions.articles(filterDetail, () => { }))
    dispatch(actions.coursesAction(filterDetail, () => { }))
    dispatch(actions.eventsAction(filterDetail, () => { }))
  }, [filtered, searchTxt])

  const expansiveChildrens = [
    {
      text: 'Article',
      onPress: handleCreate,
      routeName: 'CreateArticle',
    },
    {
      text: 'Course',
      onPress: handleCreate,
      routeName: 'CreateCourse',
    },
    {
      text: 'Event',
      onPress: handleCreate,
      routeName: 'CreateEvent',
    },
  ]
  return (
    <>
      <PageHeader
        title={'Learning'}
        icon={'education'}
        backEnabled={false}
        actions={
          [
            {
              title: 'Create',
              type: 'menu',
              permissions: [
                'CREATE_LEARNING_ARTICLES',
              ],
              menu: [
                {
                  title: 'Create Article',
                  icon: false,
                  permissions: [
                    'CREATE_LEARNING_ARTICLES'
                  ],
                  onPress: () => {
                    handleCreate('CreateArticle')
                  }
                },
                {
                  title: 'Create Course',
                  icon: false,
                  permissions: [
                    'CREATE_LEARNING_ARTICLES'
                  ],
                  onPress: () => {
                    handleCreate('CreateCourse')
                  }
                },
                {
                  title: 'Create Event',
                  icon: false,
                  permissions: [
                    'CREATE_LEARNING_ARTICLES'
                  ],
                  onPress: () => {
                    handleCreate('CreateEvent')
                  }
                },
              ]
            }
          ]
        }
      />

      <PageContent>
        <ContentBlockV1
          title={"Featured"}
        >
          <Carousel>
            {featured &&
              Object.values(featured).map((learning, key) => {
                if (learning.Type == 'App\\Article') {
                  return (
                    <Card
                      type={'article'}
                      setReadLater={() => {
                        setReadLater(learning.Data)
                      }}
                      brandingSetting={branding}
                      key={'feat_learning_' + key + '_' + learning.id}
                      {...learning.Data}
                    />
                  )
                } else if (learning.Type == 'App\\Course') {
                  return (
                    <Card
                      type={'course'}
                      setReadLater={() => {
                        dispatch(
                          actionCourse.toggleReadLaterAction(
                            { id: learning.Data.id },
                            () => { }
                          )
                        )
                      }}
                      brandingSetting={branding}
                      key={'course_' + key + '_' + learning.id}
                      {...learning.Data}
                    />
                  )
                } else if (learning.Type == 'App\\Event') {
                  return (
                    <Card
                      type={'event'}
                      setReadLater={() => {
                        dispatch(
                          actionEvent.toggleReadLaterAction(
                            { id: learning.Data.id },
                            () => { }
                          )
                        )
                      }}
                      brandingSetting={branding}
                      key={'event_' + key + '_' + learning.id}
                      {...learning.Data}
                    />
                  )
                }
              })}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={"Articles"}
          action={{
            onPress: () => {
              handleMore('Articles')
            }
          }}
        >
          <Carousel>
            {articles && articles.length ? (
              articles.map((learning, key) => (
                <Card
                  type={'article'}
                  setReadLater={() => {
                    setReadLater(learning)
                  }}
                  key={'article_' + key + '_' + learning.id}
                  {...learning}
                />
              ))
            ) : (
              <View>
                <Text>No article was found using this filter.</Text>
              </View>
            )}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={'Courses'}
          action={{
            onPress: () => {
              handleMore('Courses')
            }
          }}
        >
          <Carousel>
            {courses && courses.length ? (
              courses.map((learning, key) => (
                <Card
                  type={'course'}
                  setReadLater={() => {
                    dispatch(
                      actionCourse.toggleReadLaterAction(
                        { id: learning.id },
                        () => { }
                      )
                    )
                  }}
                  brandingSetting={branding}
                  key={'course_' + key + '_' + learning.id}
                  {...learning}
                />
              ))
            ) : (
              <Text>No course was found using this filter.</Text>
            )}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={'Events'}
          action={{
            onPress: () => {
              handleMore('Events')
            }
          }}
        >
          <Carousel>
            {events && events.length ? (
              events.map((learning, key) => (
                <Card
                  type={'event'}
                  setReadLater={() => {
                    dispatch(
                      actionEvent.toggleReadLaterAction(
                        { id: learning.id },
                        () => { }
                      )
                    )
                  }}
                  brandingSetting={branding}
                  key={'event_' + key + '_' + learning.id}
                  {...learning}
                />
              ))
            ) : (
              <Text>No course was found using this filter.</Text>
            )}
          </Carousel>
        </ContentBlockV1>
      </PageContent>
    </>
  )
}

export default Learning
