import styled from 'styled-components/native'
import Button from '@/components/Button'
import { ScreenSize, isMobile } from '@/styles'

const screenSizeContainerPadding = (left) => ({
  screenSize,
  invertedContent,
}) =>
  screenSize < ScreenSize.Medium
    ? '0px'
    : left && invertedContent
      ? '0px'
      : '20px'

export const Container = styled.View`
  width: 100%;
  margin-bottom: 20px;
  padding-top: ${screenSizeContainerPadding(false)};
  padding-left: ${screenSizeContainerPadding(true)};
	position: relative;
  align-items: center;
  flex-direction: row;
  padding-bottom: 20px;
  justify-content: ${({ screenSize, withBackButton, justifyContent }) =>
    withBackButton
      ? 'space-between'
      : screenSize >= ScreenSize.Medium && !justifyContent
        ? 'flex-start'
        : 'flex-end' || justifyContent};
`

export const BackButton = styled(Button)`
  height: 30px;
`

export const ImageLogo = styled.Image`
	width: ${isMobile ? 26 : 60}px;
	height: ${isMobile ? 26 : 60}px;
`

export const BackButtonText = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 16px;
  text-align: center;
  margin-left: 5px;
`
