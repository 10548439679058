import React, { useCallback, useEffect, useState } from 'react'
import {
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Image,
  StyleSheet,
  Pressable,
} from 'react-native'
import Toast from 'react-native-toast-message'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as selectors from '@/redux/selectors/roles'
import { actions as brandSetingsActions } from '@/redux/reducers/branding'
import { Avatar } from '@/components'
import {
  Link,
  CardView,
  Role,
  AvatarButton,
  AvatarWrapper,
  CircleCamera,
  TextField,
} from '../styles'
import Logo from '@/assets/Icon_1024.png'
import * as Yup from 'yup'
import { Icon } from '@/components'
import { colors } from './BrandingData'
import { ScreenSize, useScreenSize, isMobile } from '@/styles'
import { openGallery } from '@/utils/gallery'
import * as profileSelectors from '@/redux/selectors/profile'
import * as brandSelectors from '@/redux/selectors/branding'
import ApiHandler from '@/api/ApiHandler'
import ColorPicker from "./color-pocker/web";
import themes from '@/styles/themes/black'

const DEFAULT_COLOR = "#F05343";

const BrandingWeb = ({ brandSettings }) => {

  const dispatch = useDispatch()
  const loading = useSelector(selectors.loading)
  // const roles = useSelector(selectors.roles)
  const screenSize = useScreenSize()
  const userProfile = useSelector(profileSelectors.getProfile)
  const branding = useSelector(brandSelectors.getSettings);
  const [colorPicker, setColorPicker] = useState("");
  const [brandColors, setBrandColors] = useState({
    color_scheme_main_navigation_background: "#1B1B1B",
    color_scheme_main_navigation_text: "#FFFFFF",
    color_scheme_primary_button_background: "#1B1B1B",
    color_scheme_primary_button_text: "#FFFFFF",
    color_scheme_accents: "#F05343",
    color_scheme_page_background: "#959595",
    color_scheme_page_topbar: "#FFFFFF",
  })
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setBrandColors({
      ...brandColors,
      color_scheme_main_navigation_background: branding?.color_scheme_main_navigation_background ?? "#1B1B1B",
      color_scheme_main_navigation_text: branding?.color_scheme_main_navigation_text ?? "#FFFFFF",
      color_scheme_primary_button_background: branding?.color_scheme_primary_button_background ?? "#1B1B1B",
      color_scheme_primary_button_text: branding?.color_scheme_primary_button_text ?? "#FFFFFF",
      color_scheme_accents: branding?.color_scheme_accents ?? "#F05343",
      color_scheme_page_background: branding?.color_scheme_page_background ?? "#959595",
      color_scheme_page_topbar: branding?.color_scheme_page_topbar ?? "#FFFFFF",
    })

  }, [branding])

  const { member, group, team } = branding.taxonomies;

  const taxonomyInitialValues = {
    member: member?.singular ?? '',
    members: member?.plural ?? '',
    team: team?.singular ?? '',
    teams: team?.plural ?? '',
    group: group?.singular ?? '',
    groups: group?.plural ?? ''
  };
  const taxonomyValidationSchema = Yup.object().shape({
    member: Yup.string().required(),
    members: Yup.string().required(),
    team: Yup.string().required(),
    teams: Yup.string().required(),
    group: Yup.string().required(),
    groups: Yup.string().required(),
  });


  const initialValues = {
    profileImageUri: '',
  }


  const getBrandingSettings = async () => {

    try {
      const response = await new ApiHandler().getBrandingSettings()
      if (response?.data) {
        const settings = response?.data?.data || {}
        dispatch(brandSetingsActions.updateSettings(settings));
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const uploadLogo = React.useCallback(async (values) => {

    setLoading(true)
    const data = {
      logo: values.profileImageUri ?? '',
    }
    try {
      const response = await new ApiHandler().updateWorkspaceLogo(data)
      if (response?.data) {

        Toast.show({
          type: response?.data?.success ? 'success' : 'error',
          position: 'top',
          text1: response?.data?.message,
        })
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      getBrandingSettings()
      setLoading(false);
    }
  })

  const resetBrandingSettings = async () => {
    setLoading(true);

    try {
      const response = await new ApiHandler().resetWorkspaceColorScheme()
      if (response?.data) {

        Toast.show({
          type: response?.data?.success ? 'success' : 'error',
          position: 'top',
          text1: response?.data?.message,
        })
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      getBrandingSettings()
      setLoading(false);
    }
  }
  const resetLogo = async () => {
    setLoading(true);

    try {
      const response = await new ApiHandler().removeWorkspaceLogo()
      if (response?.data) {


        Toast.show({
          type: response?.data?.success ? 'success' : 'error',
          position: 'top',
          text1: response?.data?.message,
        })
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      getBrandingSettings()
      setLoading(false);
    }

  }

  const saveColorScheme = async () => {
    setLoading(true)

    const colorsArr = Object.keys(brandColors);

    const idx = colorsArr.findIndex(item => {
      if (brandColors[item] != DEFAULT_COLOR) {
        return item
      }
    })

    if (idx > -1) {

      try {
        const response = await new ApiHandler().updateWorkspaceColorScheme(brandColors)
        if (response?.data) {

          Toast.show({
            type: response?.data?.success ? 'success' : 'error',
            position: 'top',
            text1: response?.data?.message,
          })
        }
      } catch (error) {
        console.log("error", error)
      } finally {
        getBrandingSettings()
        setLoading(false);
      }
    }

  }

  const colorScheme = ({ item, index }) => {

    const bg = brandColors[item.key]

    return (
      <Pressable
        onPress={() => { setColorPicker(item) }}
        style={styles.checkBoxView}
        key={item.key}
      >
        <View style={{ justifyContent: 'center' }}>
          <View style={styles.checkBoxBorder}>
            <View
              style={{
                height: 16,
                width: 16,
                backgroundColor: bg,
              }}
            />
          </View>
        </View>
        <View style={styles.checkBoxDesc}>
          <Text>{item.description}</Text>
        </View>
      </Pressable>
    )
  }

  const primaryButton = (btnStyles, onPress, label) => {
    const {
      color_scheme_primary_button_background,
      color_scheme_primary_button_text
    } = brandSettings;
    return (
      <TouchableOpacity
        style={
          [
            btnStyles,
            {
              backgroundColor: color_scheme_primary_button_background,
            }
          ]
        }
        onPress={onPress}
      >
        <Text style={{ color: color_scheme_primary_button_text, fontSize: 16 }}>
          {label}
        </Text>
      </TouchableOpacity>
    )
  }

  const uploadTaxonomy = async (values) => {
    try {
      const data = {
        member: {
          singular: values.member,
          plural: values.members
        },
        team: {
          singular: values.team,
          plural: values.teams
        },
        group: {
          singular: values.group,
          plural: values.groups
        }
      };
      const response = await new ApiHandler().updateTaxonomies(data);
      if (response?.data) {
        const { success, message } = response?.data;
        Toast.show({
          type: success ? 'success' : 'error',
          position: 'top',
          text1: message,
        });
      }
    } catch (error) {
      console.log('UPDATE TAXONOMY ERROR', error);
    } finally {
      getBrandingSettings();
    }
  };

  const resetTaxonomies = async () => {
    try {
      const response = await new ApiHandler().resetTaxonomies();
      const { success, message } = response?.data;
      Toast.show({
        type: success ? 'success' : 'error',
        position: 'top',
        text1: message,
      });
    } catch (error) {
      console.log('RESET TAXONOMY ERROR', error);
    } finally {
      getBrandingSettings();
    }
  };

  return (
    <>
      <ColorPicker
        colorPicker={colorPicker}
        setColorPicker={setColorPicker}
        brandColors={brandColors}
        setBrandColors={setBrandColors}
      />
      <View style={[styles.container, { flexDirection: screenSize <= ScreenSize.Large ? 'column' : 'row', }]}>
        <View style={[styles.colorSchemeView, { width: screenSize >= ScreenSize.Large ? 650 : "100%" }]}>
          <CardView style={{ marginBottom: 0 }}>
            <TouchableOpacity style={{ width: '80%' }}>
              <Text style={styles.title}>Color Scheme</Text>
              <Text style={styles.description}>
                Customize the color scheme within your community.
              </Text>
            </TouchableOpacity>
          </CardView>
          <View style={{ backgroundColor: '#fff', marginBottom: 10 }}>
            <FlatList
              data={colors}
              renderItem={colorScheme}
              keyExtractor={(item) => item.id}


              numColumns={screenSize >= ScreenSize.Medium && 2}

            />
            <View style={styles.colorBtn}>
              <TouchableOpacity style={styles.resetButton} onPress={resetBrandingSettings} >
                <Text style={{ fontSize: 16 }}>Reset</Text>
              </TouchableOpacity>

              {primaryButton(styles.uploadButton, saveColorScheme, "Upload")}

            </View>
          </View>
        </View>
        <Formik
          enableReinitialize
          validateOnMount
          onSubmit={uploadLogo}
          initialValues={initialValues}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <View style={[styles.logoView, {
              width: screenSize >= ScreenSize.Medium ? 420 : "100%",
              height: screenSize >= ScreenSize.Medium ? 395 : 430
            }]}>
              <CardView style={{}}>
                <View style={{ width: '90%' }}>
                  <Text style={styles.title}>Logo</Text>
                  <Text style={styles.description}>
                    Upload a custom logo for your community.
                  </Text>
                </View>
              </CardView>
              <View style={{ alignItems: 'center' }}>
                <AvatarButton
                  onPress={async () => {
                    try {
                      const imageUri = await openGallery()
                      handleChange('profileImageUri')(imageUri)
                    } catch (e) {
                      console.log('Error opening gallery')
                    }
                  }}
                >
                  <AvatarWrapper screenSize={screenSize}>
                    <Avatar
                      style={{ backgroundColor: '#F1F1F1' }}
                      size={screenSize <= ScreenSize.Medium ? 90 : 90}
                      source={values.profileImageUri ? { uri: values.profileImageUri } : null}
                    />
                    <CircleCamera isMobile={isMobile}>
                      <Icon
                        icon="addPhoto"
                        color="#323232"
                        height={isMobile ? 16 : 22}
                        width={isMobile ? 16 : 22}
                      />
                    </CircleCamera>
                  </AvatarWrapper>
                </AvatarButton>
                <View style={styles.logoButton}>
                  <TouchableOpacity style={styles.resetButton} onPress={resetLogo} >
                    <Text style={{ fontSize: 16 }}>Reset</Text>
                  </TouchableOpacity>
                  {primaryButton(styles.uploadButton, handleSubmit, "Upload")}
                </View>
              </View>
            </View>
          )}
        </Formik>
      </View>

      {/* Taxonomy commented for now */}
      <View style={[styles.taxonomyView, { height: screenSize >= ScreenSize.Medium ? 367 : 700 }]}>
        <CardView style={{ paddingHorizontal: 5 }}>
          <View>
            <Text style={styles.title}>Taxonomy</Text>
            <Text style={styles.description}>
              Customize the taxonomy throughout {screenSize <= ScreenSize.Small && '\n'} your workspace.
            </Text>
          </View>
        </CardView>
        <Formik
          enableReinitialize
          validateOnMount
          onSubmit={uploadTaxonomy}
          initialValues={taxonomyInitialValues}
          validationSchema={taxonomyValidationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <>
              <View style={styles.taxonomyFormView}>
                <View style={[styles.taxonomyFormRow, { flexDirection: screenSize >= ScreenSize.Medium && 'row' }]}>
                  <Text style={styles.taxonomyFormFieldTitle}>
                    Members
                  </Text>
                  <TextField
                    label="Member"
                    value={values?.member}
                    autoCapitalize="none"
                    keyboardType="default"
                    onChangeText={handleChange('member')}
                    style={styles.taxonomyFormField}
                  />
                  <TextField
                    label="Members"
                    value={values?.members}
                    autoCapitalize="none"
                    keyboardType="default"
                    onChangeText={handleChange('members')}
                    style={styles.taxonomyFormField}
                  />
                </View>
                <View style={[styles.taxonomyFormRow, { flexDirection: screenSize >= ScreenSize.Medium && 'row' }]}>
                  <Text style={styles.taxonomyFormFieldTitle}>Teams</Text>
                  <TextField
                    label="Team"
                    value={values?.team}
                    autoCapitalize="none"
                    keyboardType="default"
                    onChangeText={handleChange('team')}
                    style={styles.taxonomyFormField}
                  />
                  <TextField
                    label="Teams"
                    value={values?.teams}
                    autoCapitalize="none"
                    keyboardType="default"
                    onChangeText={handleChange('teams')}
                    style={styles.taxonomyFormField}
                  />
                </View>
                <View style={[styles.taxonomyFormRow, { flexDirection: screenSize >= ScreenSize.Medium && 'row' }]}>
                  <Text style={styles.taxonomyFormFieldTitle}>Groups</Text>
                  <TextField
                    label="Group"
                    value={values?.group}
                    autoCapitalize="none"
                    keyboardType="default"
                    onChangeText={handleChange('group')}
                    style={styles.taxonomyFormField}
                  />
                  <TextField
                    label="Groups"
                    value={values?.groups}
                    autoCapitalize="none"
                    keyboardType="default"
                    onChangeText={handleChange('groups')}
                    style={styles.taxonomyFormField}
                  />
                </View>
              </View>
              <View style={styles.taxonomyBtnsView}>
                <TouchableOpacity style={styles.resetButton} onPress={resetTaxonomies}>
                  <Text style={{ fontSize: 16 }}>Reset</Text>
                </TouchableOpacity>
                {primaryButton(styles.uploadButton, handleSubmit, "Upload")}
              </View>
            </>
          )}
        </Formik>
      </View>
    </>
  )
}
export default BrandingWeb

const styles = StyleSheet.create({
  checkBoxView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    width: '60%',
  },
  checkBoxBorder: {
    height: 24,
    width: 24,
    borderWidth: 2,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
    borderRadius: 5,
    // backgroundColor: this.state.isCheck ? '#000' : '#FFF'
  },
  checkBoxDesc: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    marginStart: 20,
  },
  container: {
    flex: 1,
    marginBottom: 10,
    paddingRight: 30,
  },
  title: {
    fontSize: 22,
  },
  description: {
    fontSize: 16,
    paddingTop: 5,
  },
  colorSchemeView: {
    flex: 1,
    marginEnd: 15,

    height: 300,
  },
  colorBtn: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: 120,
    paddingBottom: 15,
    paddingRight: 10,
    flexDirection: 'row',
  },
  resetButton: {
    width: 120,
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 3,
    marginEnd: 10,
  },
  uploadButton: {
    backgroundColor: '#000',
    width: 120,
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  logoView: {
    backgroundColor: '#fff',
    marginBottom: 10,
    // width: 420,
    // height: 395,
  },
  logoButton: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    height: 120,
    // paddingBottom: 10,
    flexDirection: 'row',
    marginTop: 25,
    paddingBottom: 10,
  },
  logoUpload: {
    backgroundColor: '#000',
    width: 120,
    height: '28%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  taxonomyView: {
    backgroundColor: '#fff',
    marginBottom: 10,
    // height: 337,
    marginRight: 30,
  },
  taxonomyFormView: {
    paddingHorizontal: 20
  },
  taxonomyFormRow: {
    // flexDirection: 'row',
    justifyContent: 'space-between',
  },
  taxonomyFormFieldTitle: {
    paddingTop: 25,
    fontSize: 15,
    width: 91,
    fontFamily: 'WorkSans_400Regular',
    color: themes.basics.black_text,
    lineHeight: 20
  },
  taxonomyFormField: {
    flex: 1,
    marginHorizontal: 4
  },
  taxonomyBtnsView: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 120,
    paddingBottom: 15,
    paddingRight: 10,
    flexDirection: 'row',
  },
})
