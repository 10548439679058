import styled from 'styled-components/native';

export const ButtonContainer = styled.View`
    width: 191px;
    height: 40px;
    border-radius: 3px;
    background-color: #F05343;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.View`
    margin-top: 70px;

    align-items: center;
    justify-content: center;
`;    

export const Title = styled.Text`
 font-size: 17px;
 font-family: 'Work Sans';
 color: #FFFFFF;
`;