import styled from 'styled-components/native';

import themes from '@/styles/themes/black'
import { Paragraph2 } from '@/styles/fonts';
import { shadow1 } from '@/styles/shadows';

export const CardBottom = styled.View`
    margin-top: 1px;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 20px;

    padding-top: 8px;
    padding-left: 14px;
    padding-right: 8px;

    background: ${themes.basics.background_white};
    /* shadow 1 */
    border-Bottom-right-radius: 5px;
    border-Bottom-left-radius: 5px;
    ${shadow1}
    
    flex-direction: row;
`;

export const FooterButton = styled.TouchableOpacity`
    flex: 1;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    margin-bottom: 8px;
`;

export const ButtonText = styled(Paragraph2)`
    text-align: center;
    color: ${themes.basics.black_text};
    margin-left: 8px;
`;
