import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { View, ScrollView, Dimensions } from 'react-native'
import { Formik } from 'formik'
// import JoditEditor from 'jodit-react'
import JoditEditorWeb from '../JoditEditorWeb'
import { BackArrow, TextField } from '@/components'
import ImagesAreaUploader from '@/components/ImagesAreaUploader/index.web'
import { isMobile, ScreenSize, useScreenSize } from '@/styles'
import { ActivityIndicator, ProgressBar, TextInput } from 'react-native-paper'
import { openGallery } from '@/utils/gallery'
import { actions } from '@/redux/reducers/article'
import { useDispatch, useSelector } from 'react-redux'
import Toast from 'react-native-toast-message'
import * as selectors from '@/redux/selectors/article'
import { useNavigation } from '@react-navigation/native'
import SimpleSelect from '@/components/SimpleSelect'
import UploadArea from '@/components/UploadArea'
import theme from '@/styles/themes/black'
import {
  CentralizedContainer,
  Actions,
  Title,
  Header,
  TitleWrapper,
  CreateButton,
  styles,
  TextButton,
  ContentText,
} from '../styles'
import * as brandSelectors from '@/redux/selectors/branding'
import moment from 'moment'
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import ApiHandler from '@/api/ApiHandler'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const window = Dimensions.get("window");

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  excerpt: Yup.string(),
  content: Yup.string(),
  // employee_lifecycle_stage_id: Yup.number(),
  // five_s_category_id: Yup.number(),
})

const CreateArticle = ({ route }) => {
  const branding = useSelector(brandSelectors.getSettings)
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const screenSize = useScreenSize()
  const editor = useRef(null)
  const formikRef = useRef(null)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [uploaded, setUploaded] = useState(false)
  const loading = useSelector(selectors.loading)
  const lifecycles = useSelector(selectors.lifecycles)
  const categories = useSelector(selectors.categories)
  // const article = useSelector(selectors.article)
  const articleData = useSelector(selectors.article)
  const articleSelector = useSelector(selectors.article)
  // const article = useSelector(selectors.article)
  // const [article, setArticle] = useState(articleSelector?.data)
  const [article, setArticle] = useState([])

  const isCreate = !route?.params?.id
  // const languageId = route.params && route.params.language_id?route?.params?.language_id:40;
  const languageId = 40;

  const scheduledPublishTimeInput = useRef()
  const [isOpenDatePicker, setDatePicker] = useState(false)
  const [isOpenTimePickerModal, setTimePickerModal] = useState(false)
  const [scheduleDate, setScheduleDate] = useState('')

  const [lifecyclesData, setLifecyclesData] = useState(null)
  const [categoriesData, setCategories] = useState(null)
  const [CategoryCulturalMaturityScoresData, setCategoryCulturalMaturityScores] = useState(null)
  const [TotalCulturalMaturityScoresData, setTotalCulturalMaturityScores] = useState(null)
  const [SegmentsData, setSegments] = useState(null)
  const [isLoading, setloading] = useState(false)

  const [joditIsAutofocus, setJoditIsAutofocus] = useState(true)
  const [screenContentHeight, setScreenContentHeight] = useState(false)
  const titleRef = useRef()
  const [languagesData, setLanguagesData] = useState(null)
  const [languagesValue, setLanguageVal] = useState(null)

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getTags();
      getLanguages();
      setLanguageVal(languageId)
      formikRef.current?.setValues({
        id: article?.id,
        name: '',
        content: '',
        excerpt: '',
        image: null,
        employee_lifecycle_stage_id: null,
        five_s_category_id: null,
        category_cultural_maturity_score: null,
        total_cultural_maturity_score: null,
        segment_id: null,
        language_id: 40,
      })
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (titleRef?.current) {
        titleRef.current?.focus();
        titleRef.current?.blur();
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setloading(false);
    // route?.params?.language_id
    if (articleSelector && articleSelector.data && !isCreate) {
      if (Object.keys(article).length === 0) {
        setLanguageVal(articleSelector?.data?.language_id)
        setArticle(articleSelector?.data)
      }
      else {
        if (languagesValue == articleSelector?.data?.language_id) {
          setArticle(articleSelector?.data)
        }
        else {
          const articles = articleSelector?.data
          articles.language_id = languagesValue
          setArticle(articles)
        }
      }
    } else {
      if (isCreate) {
        setArticle(articleSelector)
      }

    }

    console.log('article select change', articleSelector)
  }, [articleSelector])

  useEffect(() => {
    if (languagesValue) {
      if (route.params.id) {
        dispatch(actions.show({ id: route.params.id, language_id: languagesValue }, () => { }))
      }
    }

  }, [languagesValue])

  useEffect(() => {
    if (scheduleDate) {
      setDatePicker(false);
      setTimePickerModal(true);
    }


  }, [scheduleDate])

  const selectFile = useCallback(
    async (handleChange) => {
      try {
        const imageUri = await openGallery()
        handleChange('image')(imageUri)
        setUploaded(true)
      } catch (e) {
        console.log('Error opening gallery')
      }
    },
    [openGallery, setUploaded],
  )

  useEffect(() => {
    dispatch(actions.dataprovider(() => { }))
    if (typeof route?.params?.id === Number) {
      // dispatch(actions.show({ id: route.params.id }, () => { }))
      setLanguageVal(languageId)
      dispatch(actions.show({ id: route.params.id, language_id: languageId }, () => { }))
    }
  }, [route?.params?.id, dispatch])

  const getTags = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getTagsLearning();
      if (response?.data) {
        setloading(false);
        const EmployeeLifecycleStages = response?.data.map((item) => item.EmployeeLifecycleStages)
        const FiveSCategories = response?.data.map((item) => item.FiveSCategories)
        const CategoryCulturalMaturityScores = response?.data.map((item) => item.CategoryCulturalMaturityScores)
        const TotalCulturalMaturityScores = response?.data.map((item) => item.TotalCulturalMaturityScores)
        const Segments = response?.data.map((item) => item.Segments)

        const lcd = EmployeeLifecycleStages[0] && EmployeeLifecycleStages[0].length > 0 ? EmployeeLifecycleStages[0] : null;
        const lcdData = lcd ? [{ id: '', name: '' }, ...lcd] : lcd;
        setLifecyclesData(lcdData);
        const cat = FiveSCategories[0] && FiveSCategories[0].length > 0 ? FiveSCategories[0] : null;
        const catData = cat ? [{ id: '', name: '' }, ...cat] : cat;
        setCategories(catData);
        const catMatScore = CategoryCulturalMaturityScores[0] && CategoryCulturalMaturityScores[0].length > 0 ? CategoryCulturalMaturityScores[0].map((ms) => { return { id: ms, name: ms.toString() } }) : null;
        const catMatScoreData = catMatScore ? [{ id: '', name: '' }, ...catMatScore] : catMatScore;
        setCategoryCulturalMaturityScores(catMatScoreData);
        const totMatScore = TotalCulturalMaturityScores[0] && TotalCulturalMaturityScores[0].length > 0 ? TotalCulturalMaturityScores[0].map((ts) => { return { id: ts, name: ts.toString() } }) : null;
        const totMatScoreData = totMatScore ? [{ id: '', name: '' }, ...totMatScore] : totMatScore;
        setTotalCulturalMaturityScores(totMatScoreData);
        const seg = Segments[0] && Segments[0].length > 0 ? Segments[0].map((s) => { return { id: s.id, name: s.segment_name } }) : null;
        const segData = seg ? [{ id: '', name: '' }, ...seg] : seg;
        setSegments(segData);
      }
    } catch (error) {
      setloading(false);
    }
  }

  const getLanguages = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getAllLanguages();

      if (response?.data) {
        setloading(false);
        const languages = response?.data.map((l) => { return { id: l.id, name: l.display_name } })
        setLanguagesData(languages);
      }

    } catch (error) {
      setloading(false);
    }
  }

  const setArticleData = async (languageID) => {
    setLanguageVal(languageID)
    dispatch(actions.show({ id: route.params.id, language_id: languageID }, () => {
      // if(articleSelector?.data.language_id == languageID){
      //   setArticle(articleSelector?.data)
      // }

    }))
  }

  // const handleSubmit = useCallback(
  //   (values) => {
  //     values.employee_lifecycle_stage_id = values.employee_lifecycle_stage_id == '' ? null : values.employee_lifecycle_stage_id;
  //     values.five_s_category_id = values.five_s_category_id == '' ? null : values.five_s_category_id;
  //     values.category_cultural_maturity_score = values.category_cultural_maturity_score == '' ? null : values.category_cultural_maturity_score;
  //     values.total_cultural_maturity_score = values.total_cultural_maturity_score == '' ? null : values.total_cultural_maturity_score;
  //     values.segment_id = values.segment_id == '' ? null : values.segment_id;
  //     values.language_id = values.language_id ? values.language_id : 40;

  //     if (loading) {
  //       return
  //     }
  //     if (!uploaded && values?.image === '') {
  //       values.image = null
  //     }
  //     dispatch(actions.update({ ...values, id: article?.id }, onSubmit))
  //   },
  //   [article?.id],
  // )

  const handleSubmit = useCallback((values) => {
    // values.employee_lifecycle_stage_id = values.employee_lifecycle_stage_id == '' ? null : values.employee_lifecycle_stage_id;
    // values.five_s_category_id = values.five_s_category_id == '' ? null : values.five_s_category_id;
    //values.category_cultural_maturity_score = values.category_cultural_maturity_score == '' ? null : values.category_cultural_maturity_score;
    //values.total_cultural_maturity_score = values.total_cultural_maturity_score == '' ? null : values.total_cultural_maturity_score;
    //values.segment_id = values.segment_id == '' ? null : values.segment_id;
    values.employee_lifecycle_stage_id = values.employee_lifecycle_stage_id ? values.employee_lifecycle_stage_id : null;
    values.five_s_category_id = values.five_s_category_id ? values.five_s_category_id : null;
    values.category_cultural_maturity_score = values.category_cultural_maturity_score ? values.category_cultural_maturity_score : null;
    values.total_cultural_maturity_score = values.total_cultural_maturity_score ? values.total_cultural_maturity_score : null;
    values.segment_id = values.segment_id ? values.segment_id : null;
    values.language_id = values.language_id ? values.language_id : 40;

    if (loading) {
      return
    }
    if (!uploaded) {
      values.image = null
    }

    if (articleSelector?.draft) {
      dispatch(actions.update({ ...values, id: articleSelector?.id }, onSubmit))
      return
    }
    else {
      if (article.id) {
        dispatch(actions.update({ ...values, id: article?.id }, onSubmit))
        return
      }
    }

    // if (values.id) {
    //   dispatch(actions.update(values, onSubmit))
    //   return
    // }
    dispatch(actions.store(values, onSubmit))
  })

  const updateFilesAction = useCallback(
    (values, callback) => {
      return actions.storeImages(
        { files: values, article_id: article?.id },
        (err, success) => {
          callback(err, success)
          if (route?.params?.id) {
            dispatch(actions.show({ id: route.params.id, language_id: languageId }, () => { }))
          }
        },
      )
    },
    [actions.storeImages, article],
  )

  const deleteFileAction = useCallback(
    (id, callback) => {
      return actions.deleteImage(
        { id, articleId: article?.id },
        (err, success) => {
          callback(err, success)
          if (route?.params?.id) {
            dispatch(actions.show({ id: route.params.id, language_id: languageId }, () => { }))
          }
        },
      )
    },
    [actions.deleteImage],
  )

  const onSubmit = (error, success) => {
    Toast.show({
      type: error ? 'error' : 'success',
      position: 'bottom',
      text1: error ? error.message : success.message,
    })
    if (success) {
      navigation.navigate('Learning')
    }
  }

  const initialValues = article
    ? { ...article, language_id: article.language_id ? article.language_id : 40, scheduled_publish: article.scheduled_publish ? moment(article.scheduled_publish).format('MM/DD/YYYY hh:mm a') : '' }
    : {
      id: article?.id,
      name: '',
      content: '',
      excerpt: '',
      image: null,
      employee_lifecycle_stage_id: null,
      five_s_category_id: null,
      category_cultural_maturity_score: null,
      total_cultural_maturity_score: null,
      segment_id: null,
      scheduled_publish: '',
      language_id: 40,
    }

  return (
    <>
      <PageHeader
        title={isCreate ? 'Create Article' : 'Update Article'}
        icon={false}
        backEnabled={true}
      />

      <PageContent>
        <View
          style={{
            width: '100%',
            maxWidth: 700,
            marginHorizontal: 'auto'
          }}
        >
          <ContentBlockV1>
            <Formik
              enableReinitialize
              ref={formikRef}
              validateOnMount
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {({ values, isValid, handleChange, handleSubmit, setValues, errors }) => (
                <>
                  <View>
                    <SimpleSelect
                      list={languagesData}
                      label="Select A Language"
                      value={values.language_id}
                      //setValue={handleChange('language_id')}
                      setValue={(e) => {
                        setValues({ ...values, language_id: e })
                        //setArticleData(e)
                        setLanguageVal(e)

                      }}
                    />


                    <TextField
                      label="Title"
                      value={values.name}
                      autoCapitalize="none"
                      keyboardType="default"
                      onChangeText={handleChange('name')}
                    />
                    {/* <TextField
                  label="Excerpt"
                  value={values.excerpt}
                  autoCapitalize="none"
                  keyboardType="default"
                  multiline={true}
                  numberOfLines={5}
                  onChangeText={handleChange('excerpt')}
                  style={{ marginBottom: 20 }}
                /> */}
                    <TextInput
                      label="Excerpt"
                      value={values.excerpt}
                      autoCapitalize="none"
                      keyboardType="default"
                      multiline={true}
                      numberOfLines={5}
                      onChangeText={handleChange('excerpt')}
                      maxHeight={window.height * 0.20}
                      style={{ paddingHorizontal: 0, marginBottom: 20, backgroundColor: '#fff' }}
                    />
                    <UploadArea
                      mediaType={1}
                      topText="Drag/Drop a Cover Image here or"
                      buttonText="Select A Cover Image"
                      bottomText=" "
                      onClick={() => {
                        selectFile(handleChange)
                      }}
                      files={values?.image ? [values.image] : []}
                      brandingSetting={branding}
                    />
                    <ContentText>Images Content</ContentText>
                    {/* ANCHOR */}
                    <ImagesAreaUploader
                      images={article?.article_images}
                      updateFilesAction={updateFilesAction}
                      deleteFileAction={deleteFileAction}
                      brandingSetting={branding}
                    />
                    <ContentText>Content</ContentText>
                    {/* <JoditEditor
                  ref={editor}
                  value={values.content}
                  tabIndex={1}
                  onChange={handleChange('content')}
                /> */}
                    <JoditEditorWeb
                      // placeholder="Content"
                      ref={editor}
                      tabIndex={2}
                      value={values.content}
                      onChange={handleChange('content')}
                      config={{ autofocus: joditIsAutofocus, height: window.height * 0.30 }}
                    />
                    <View
                      style={{
                        flexDirection: isMobile ? 'column' : screenSize <= ScreenSize.Small ? 'column' : 'row',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <SimpleSelect
                        list={lifecyclesData}
                        label="Lifecycle Stage"
                        value={values.employee_lifecycle_stage_id}
                        setValue={handleChange('employee_lifecycle_stage_id')}
                      />
                      <SimpleSelect
                        list={categoriesData}
                        label="Category"
                        value={values.five_s_category_id}
                        setValue={handleChange('five_s_category_id')}
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: isMobile ? 'column' : screenSize <= ScreenSize.Small ? 'column' : 'row',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >

                      <SimpleSelect
                        list={CategoryCulturalMaturityScoresData}
                        label="Category Cultural Maturity Score"
                        value={values.category_cultural_maturity_score}
                        setValue={handleChange('category_cultural_maturity_score')}
                      />

                      <SimpleSelect
                        list={TotalCulturalMaturityScoresData}
                        label="Total Cultural Maturity Score"
                        value={values.total_cultural_maturity_score}
                        setValue={handleChange('total_cultural_maturity_score')}
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: isMobile ? 'column' : 'row',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <SimpleSelect
                        list={SegmentsData}
                        label="Segment"
                        value={values.segment_id}
                        setValue={handleChange('segment_id')}
                      />
                    </View>

                    {/* Schedule start */}
                    <ContentText>Schedule</ContentText>
                    <View
                      style={{
                        flexDirection: isMobile ? 'column' : 'row',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <TextInput
                        ref={scheduledPublishTimeInput}
                        label="Date time"
                        value={values.scheduled_publish ? values.scheduled_publish : ''}
                        onChangeText={handleChange('scheduled_publish')}
                        autoCapitalize="none"
                        keyboardType="default"
                        onFocus={() => {
                          setDatePicker(true);
                          scheduledPublishTimeInput.current.blur()
                        }}
                        style={{ backgroundColor: 'transparent', paddingHorizontal: 0, flex: 1 }}
                        right={
                          <TextInput.Icon name='calendar' color='#8B77E8' />
                        }
                      />
                      <DatePickerModal
                        locale="en"
                        mode="single"
                        visible={isOpenDatePicker}
                        onDismiss={() => {
                          setScheduleDate('')
                          setDatePicker(false);
                        }}
                        date={values.scheduled_publish ? new Date(values.scheduled_publish) : new Date()}
                        onConfirm={(d) => {
                          const dateStr = moment(d.date).format('MM/DD/YYYY')
                          setScheduleDate(dateStr)
                          // setDatePicker(false);
                          //  setTimePickerModal(true);
                        }}
                        saveLabel={'Pick Time'}
                        label="Schedule publish"
                        uppercase={false}
                      />
                      <TimePickerModal
                        locale="en"
                        visible={isOpenTimePickerModal}
                        onDismiss={() => {
                          setTimePickerModal(false);
                          setDatePicker(true);
                        }}
                        date={values.scheduled_publish ? new Date(values.scheduled_publish) : new Date()}
                        onConfirm={(t) => {
                          const tempdate = scheduleDate + ' ' + t.hours + ':' + t.minutes;
                          const schedulePublish = moment(tempdate).format('MM/DD/YYYY hh:mm a');
                          setValues({ ...values, scheduled_publish: schedulePublish })
                          setTimePickerModal(false);
                        }}
                        saveLabel={'Confirm'}
                        label="Schedule publish"
                        uppercase={false}
                      />
                    </View>
                    {/* Schedule end */}

                  </View>
                  <Actions>
                    <CreateButton
                      onPress={handleSubmit}
                      color={
                        color_scheme_primary_button_background
                          ? color_scheme_primary_button_background
                          : 'black'
                      }
                      disabled={!isValid}
                    >
                      {loading && (
                        <ActivityIndicator
                          size={20}
                          animating
                          color={theme.basics.background_white}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      <TextButton color={color_scheme_primary_button_text}>
                        {isCreate ? 'Create' : 'Update'} Article
                      </TextButton>
                    </CreateButton>
                  </Actions>
                </>
              )}
            </Formik>
          </ContentBlockV1>
        </View>
      </PageContent>
    </>
  )
}

CreateArticle.propTypes = {
  route: PropTypes.any,
}

export default CreateArticle
