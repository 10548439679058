import React, { Children, useEffect } from 'react'
import { RemoteParticipantView } from './styles'

const Wrapper = ({ children, setCount }) => {
    useEffect(() => {
        setCount(Children.count(children));
    }, [children])
    return (
        <RemoteParticipantView>
            {children}
        </RemoteParticipantView>
    )
}

export default Wrapper;