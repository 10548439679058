import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  TouchableOpacity,
  View,
  Image,
  StyleSheet,
  Modal,
  ActivityIndicator,
  SafeAreaView,
  Text,
  Platform,
} from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation } from "@react-navigation/native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { actions } from "@/redux/reducers/posts";
import { getMember } from "@/redux/selectors/auth";
import Avatar from "../../../Avatar";
import FeedCardAuthorInfo from "../../FeedCardAuthorInfo";
import DeletePostModal from "../DeletePostModal";
import { CommentList, Icon, CreatePost } from "@/components";
import theme from "@/styles/themes/black";
import { isMobile } from "@/styles";
import {
  Container,
  AuthorContainer,
  AuthorInfoContainer,
  ContentCard,
  LikeLove,
  InteractionsContainer,
  ButtonLinkGray,
  Like,
  LikeCommentsContainer,
  ActionButton,
  ActionButtonContainer,
  ActionButtonText,
  SectionAction,
  ButtonNavigation,
  TimeAgo,
  PostImageContainer
} from "./styles";
import { PostInterface } from "@/interfaces/post";
import ExpansiveMenu from "@/components/ExpansiveMenu";
import canAccess from "@/helpers/permissions";
import { permissions } from "@/helpers/permissionConstants";
import { actions as teamActions } from "@/redux/reducers/teams";
import { actions as groupActions } from "@/redux/reducers/groups";
import * as S from "../../../Learning/ArticleCard/styles";
import { ScreenSize, useScreenSize } from "@/styles";
import { Ionicons } from "@expo/vector-icons";
import { Video } from "expo-av";
import ImageViewer from "react-native-image-zoom-viewer";
import MentionsTextView from "@/components/MentionTextView";
import { formatFeedCardDate } from "@/utils/format";

const AppPostComponent = ({
  post,
  idPresent,
  postType,
  feedType,
  onCloseEdit,
  onCloseDelete,
  onClosePostComment,
  handleDeleteComment,
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const user = useSelector(getMember);
  const isOwner = post?.creator_id == user.id;

  const [isCommentsVisible, setCommentsVisible] = useState(false);
  const [isDeletePostVisible, setDeletePostVisible] = useState(false);
  const [isEditPostVisible, setEditPostVisible] = useState(false);
  const [videoStatus, setVideoStatus] = useState(false);
  const [zoomImageViewer, setZoomImageViewer] = useState(false);

  const commentsCount = post?.comments?.length;
  const likesCount = post?.likes?.length;

  const screenSize = useScreenSize();
  const isDesktop = screenSize > ScreenSize.Medium;

  const video = React.useRef(null);

  const handleComments = useCallback(() => {
    if (isMobile) {
      navigation.navigate("CommentPost", {
        post_id: post.id,
        post_type: postType,
        id_present: idPresent,
        contentType: "App\\Post",
      });
      return;
    }
    setCommentsVisible(!isCommentsVisible);
  });

  const handleLike = useCallback(() => {
    dispatch(
      actions.like(
        {
          id: post.id,
          user,
          postType: "App\\Post",
        },
        () => {
          // if (postType == "team") {
          //   dispatch(teamActions.like({ id: idPresent, page:1}));
          // } else if (postType == "group") {
          //   dispatch(groupActions.like({ id: idPresent, page:1}));
          // }
        }
      )
    );
  }, [dispatch, post]);

  const handleDelete = useCallback(() => {
    setDeletePostVisible(true);
  }, [setDeletePostVisible]);

  const handleEdit = useCallback(() => {
    setEditPostVisible(true);
  }, [setEditPostVisible]);
  const CrossButton = ({ onPress }) => {
    return (
      <TouchableOpacity onPress={onPress} style={styles.crossButton}>
        <Icon icon="close" height={20} width={20} color={"white"} />
      </TouchableOpacity>
    );
  };
  const expansiveChildrens = isOwner
    ? [
      { text: "Edit post", icon: "pencil", onPress: handleEdit },
      { text: "Delete Post", icon: "delete", onPress: handleDelete },
    ]
    : canAccess(permissions.MANAGE_TEAMS) ||
      canAccess(permissions.MANAGE_GROUPS)
      ? [
        { text: "Edit post", icon: "pencil", onPress: handleEdit },
        { text: "Delete Post", icon: "delete", onPress: handleDelete },
      ]
      : canAccess(permissions.DELETE_POSTS)
        ? [{ text: "Delete Post", icon: "delete", onPress: handleDelete }]
        : [];

  const [aspectRatio, setAspectRatio] = useState();
  Image.getSize(post?.file, (width, height) => {
    let aspectRatio = width / height;
    setAspectRatio(aspectRatio);
  });

  return (
    <>
      <Container>
        <AuthorContainer>
          <ButtonNavigation
            onPress={() =>
              navigation.navigate("Profile", { id: post?.creator?.id })
            }
          >
            <Avatar
              size={45}
              source={
                post?.creator?.avatar ? { uri: post?.creator?.avatar } : null
              }
            />
          </ButtonNavigation>
          <AuthorInfoContainer>
            <FeedCardAuthorInfo author={post?.creator} />
            <TimeAgo>{formatFeedCardDate(post?.created_at)}</TimeAgo>
          </AuthorInfoContainer>
          {isOwner ||
            canAccess(
              permissions.DELETE_POSTS ||
              canAccess(permissions.MANAGE_TEAMS) ||
              canAccess(permissions.MANAGE_GROUPS)
            ) ? (
            <ExpansiveMenu
              mainIconColor="#000"
              mainIconBackground="transparent"
              expansiveChildrens={expansiveChildrens}
            />
          ) : null}
        </AuthorContainer>
        {/* <ContentCard>{post?.text} </ContentCard> */}
        {
          Platform.OS == "android" ?
            <MentionsTextView
              feedType={feedType}
              str={post?.text}
              startword={"##"}
              endword={"@@"}
              innerFormatStart={"["}
              innerFormatEnd={"]"}
            />
            :
            <ContentCard>
              <MentionsTextView
                feedType={feedType}
                str={post?.text}
                startword={"##"}
                endword={"@@"}
                innerFormatStart={"["}
                innerFormatEnd={"]"}
              />
            </ContentCard>
        }
        <>
          {post?.file?.length > 1 && post?.file_type === "file-image" && (
            <PostImageContainer
              style={
                !isDesktop
                  ? [styles.postImageContainer, { maHeight: 180 }]
                  : { maHeight: 180 }
              }
              isDesktop={isDesktop}
              onPress={() => setZoomImageViewer(true)}
            >
              <Image
                source={{ uri: post?.file }}
                style={[
                  styles.postImageStyle,
                  {
                    height: undefined,
                    aspectRatio: aspectRatio || 1,
                    width: "100%",
                    resizeMode: "cover",
                  },
                  isDesktop && {
                    height: undefined,
                    aspectRatio: aspectRatio || 1,
                    width: "100%",
                    resizeMode: "cover",
                  },
                ]}
              />
            </PostImageContainer>
          )}
          {post?.file?.length > 1 && post?.file_type === "file-video" && (
            <View>
              <Video
                ref={video}
                style={[
                  styles.postVideo,
                  !isDesktop && {
                    height: undefined,
                    aspectRatio: aspectRatio || 1,
                    width: "100%",
                  }
                ]}
                source={{ uri: post?.file }}
                useNativeControls
                resizeMode={Video.RESIZE_MODE_COVER}
              />
            </View>
          )}
        </>
        <InteractionsContainer>
          <LikeLove>
            <Like>{likesCount}</Like>
            <MaterialCommunityIcons
              size={13}
              name="thumb-up-outline"
              color={theme.support.standard_high}
            />
          </LikeLove>
          <TouchableOpacity onPress={handleComments}>
            <ButtonLinkGray>{commentsCount} comments</ButtonLinkGray>
          </TouchableOpacity>
        </InteractionsContainer>
        <LikeCommentsContainer
          commentsCount={commentsCount}
          isCommentsVisible={isCommentsVisible}
        >
          <SectionAction>
            <ActionButton onPress={handleLike}>
              <ActionButtonContainer>
                <Icon
                  icon="like"
                  height={22}
                  width={19}
                  color={post?.liked ? theme.info.standard : theme.basics.brand}
                />
                <ActionButtonText like={post?.liked}>Like</ActionButtonText>
              </ActionButtonContainer>
            </ActionButton>
          </SectionAction>
          <SectionAction>
            <ActionButton onPress={handleComments}>
              <ActionButtonContainer>
                <Icon
                  icon="comment"
                  height={22}
                  width={19}
                  color={theme.basics.brand}
                />
                <ActionButtonText>Comments</ActionButtonText>
              </ActionButtonContainer>
            </ActionButton>
          </SectionAction>
        </LikeCommentsContainer>
        <CommentList
          post={post}
          contentType="App\Post"
          postType={postType}
          idPresent={idPresent}
          onClosePostComment={onClosePostComment}
          isCommentsVisible={isCommentsVisible}
          handleDeleteComment={handleDeleteComment}
        />
      </Container>
      <Modal visible={zoomImageViewer} transparent={true}>
        <ImageViewer
          enableSwipeDown
          onSwipeDown={() => setZoomImageViewer(false)}
          imageUrls={[{ url: post?.file }]}
          loadingRender={() => <ActivityIndicator size="small" color="white" />}
          renderHeader={() => (
            <CrossButton onPress={() => setZoomImageViewer(false)} />
          )}
        />
      </Modal>
      <DeletePostModal
        post={post}
        isVisible={isDeletePostVisible}
        setVisible={setDeletePostVisible}
        onClose={onCloseDelete}
      />
      <CreatePost
        post={post}
        isVisible={isEditPostVisible}
        setVisible={setEditPostVisible}
        onClose={onCloseEdit}
        file={post.file}
        fileType={post.file_type == "file-video" ? "video" : "image"}
      />
    </>
  );
};

const styles = StyleSheet.create({
  postImageStyle: {
    width: "100%",
    alignSelf: "center",
  },
  postImageContainer: {
    width: "100%",
    justifyContent: "center",
    borderWidth: 0,
  },
  postVideo: {
    width: "100%",
    marginBottom: 0,
    marginTop: isMobile ? 12 : 20,
    //aspectRatio:16/9,
  },
  postVideoDesktop: {
    height: 300,
  },
  crossButton: {
    position: "absolute",
    left: 10,
    top: 30,
    width: 50,
    height: 50,
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center",
  },
});

AppPostComponent.propTypes = {
  // post
  post: PostInterface,
  onCloseEdit: PropTypes.func,
  onCloseDelete: PropTypes.func,
  onClosePostComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
  isCreatePostVisible: PropTypes.bool,
  postType: PropTypes.string,
  idPresent: PropTypes.number,
};

export default AppPostComponent;
