import React from 'react'
import PropTypes from 'prop-types'
import { StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import {
  Container,
  ContentWrapper,
  DecorationWrapper,
  DecorationContainer,
} from './styles'
import { ScreenSize, useScreenSize } from '@/styles'

function OnboardingPage({ header, invert, children, decoration, styleDecorationWrapper = {} }) {
  const screenSize = useScreenSize()

  return (
    <SafeAreaView style={styles.container}>
      <Container invert={invert} screenSize={screenSize}>
        <ContentWrapper invert={invert} screenSize={screenSize}>
          {header}
          {children}
        </ContentWrapper>
        {screenSize >= ScreenSize.Medium && (
          <DecorationContainer>
            <DecorationWrapper style={styleDecorationWrapper}>{decoration}</DecorationWrapper>
          </DecorationContainer>
        )}
      </Container>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 15,
    backgroundColor: 'white',
  },
})

OnboardingPage.propTypes = {
  header: PropTypes.node,
  invert: PropTypes.bool,
  children: PropTypes.node,
  decoration: PropTypes.node,
  styleDecorationWrapper: PropTypes.object
}

export default OnboardingPage
