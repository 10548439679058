/* eslint-disable */
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import { getMember } from "@/redux/selectors/auth";
import { Entypo } from "@expo/vector-icons";
import React, { useState } from "react";
import { Dimensions, Text, TouchableOpacity, View } from 'react-native'
import { useSelector } from "react-redux";
import { Menu } from 'react-native-paper'
import AddReaction from "../Icons/AddReaction";
const { width } = Dimensions.get('screen');

const Reactions = {
    HEART: "heart",
    THUMBS_UP: "thumbs_up",
    LAUGH: "laugh",
    SAD: "sad",
    POUTING: "pouting",
    THUMBS_DOWN: "thumbs_down",
}

const reactionsExist = (reactions) =>
    reactions &&
    (reactions[Reactions.HEART]?.length ||
        reactions[Reactions.THUMBS_DOWN]?.length ||
        reactions[Reactions.THUMBS_UP]?.length ||
        reactions[Reactions.POUTING]?.length ||
        reactions[Reactions.SAD]?.length ||
        reactions[Reactions.LAUGH]?.length);

const ReactionBox = ({ reactions = {}, onReactionsChanged, reverse }) => {
    const user = useSelector(getMember);
    const color = UseBrandingAccentColor();
    const [menuVisible, setMenuVisible] = useState(false);

    const onUpdateReaction = (reaction) => {
        setMenuVisible(false);
        const reactionUsers = reactions?.[reaction] ?? [];

        onReactionsChanged({
            ...reactions,
            [reaction]: reactions?.[reaction]?.includes(user.id)
                ? reactionUsers.filter((participant) => participant != user.id)
                : [...reactionUsers, user.id],
        });
    };

    const allReactionsByCurrentUser = () =>
        Object.keys(reactions)
            .filter((reaction) => reactions[reaction]?.length)
            .every((reaction) => reactions[reaction]?.includes(user.id));

    const ReactionItem = ({
        emoji,
        count,
        reactionId
    }) => (
        <TouchableOpacity
            onPress={() => {
                // hide the menu here
                setMenuVisible(false);
                onUpdateReaction(reactionId);
            }}
            style={{
                border: 0,
                padding: 2,
                marginHorizontal: 2,
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: 8,
                backgroundColor: reactions?.[reactionId]?.includes(user.id) && !count
                    ? "#f05343"
                    : "transparent"
            }}
        >
            <Text style={{ fontSize: 20, lineHeight: 28 }}>{emoji}{" "}</Text>
            <Text style={{ color: reactions?.[reactionId]?.includes(user.id) ? color : 'black' }}>{count}</Text>

        </TouchableOpacity>
    )

    const isReactionsByCurrentUser = allReactionsByCurrentUser();

    const reactionsBackgroundColor = isReactionsByCurrentUser
        ? "rgba(30, 144, 255, 0.2)"
        : "transparent";


    return (
        <View style={{ flexDirection: reverse ? "row-reverse" : 'row' }}>
            {reactionsExist(reactions) ? (
                <View style={{ flexDirection: 'row', marginTop: 4, marginHorizontal: 4, paddingHorizontal: 10, backgroundColor: reactionsBackgroundColor, borderWidth: 1, borderColor: '#f1f1f1' }}>
                    {reactions?.[Reactions.HEART]?.length ? (
                        <ReactionItem
                            emoji="&#129505;"
                            reactionId={Reactions.HEART}
                            count={reactions?.[Reactions.HEART]?.length}
                        />
                    ) : null}
                    {reactions?.[Reactions.THUMBS_UP]?.length ? (
                        <ReactionItem
                            emoji="&#128077;"
                            reactionId={Reactions.THUMBS_UP}
                            count={reactions?.[Reactions.THUMBS_UP]?.length}
                        />
                    ) : null}
                    {reactions?.[Reactions.LAUGH]?.length ? (
                        <ReactionItem
                            emoji="&#128514;"
                            reactionId={Reactions.LAUGH}
                            count={reactions?.[Reactions.LAUGH]?.length}
                        />
                    ) : null}
                    {reactions?.[Reactions.SAD]?.length ? (
                        <ReactionItem
                            emoji="&#128542;"
                            reactionId={Reactions.SAD}
                            count={reactions?.[Reactions.SAD]?.length}
                        />
                    ) : null}
                    {reactions?.[Reactions.POUTING]?.length ? (
                        <ReactionItem
                            emoji="&#128545;"
                            reactionId={Reactions.POUTING}
                            count={reactions?.[Reactions.POUTING]?.length}
                        />
                    ) : null}
                    {reactions?.[Reactions.THUMBS_DOWN]?.length ? (
                        <ReactionItem
                            emoji="&#128078;"
                            reactionId={Reactions.THUMBS_DOWN}
                            count={reactions?.[Reactions.THUMBS_DOWN]?.length}
                        />
                    ) : null}
                </View>
            ) : null}
            <Menu contentStyle={{ paddingHorizontal: 5, flexDirection: 'row', justifyContent: 'space-evenly', overflow: 'hidden' }} style={{ width: width / 1.4 }} anchor={
                <TouchableOpacity
                    onPress={() => setMenuVisible(!menuVisible)}
                    style={{
                        paddingVertical: 6,
                        paddingHorizontal: 10,
                        borderWidth: 1,
                        borderColor: "#8891AA",
                        maxWidth: 42,
                        maxHeight: 42,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 4
                    }}>
                    <AddReaction color="black" />
                </TouchableOpacity>} visible={menuVisible} onDismiss={() => setMenuVisible(false)}>
                <Menu.Item underlayColor="transparent" disabled style={{ opacity: 0 }} titleStyle={{ width: 25, margin: 0, padding: 0, backgroundColor: reactions?.[Reactions.HEART]?.includes(user.id) ? "rgba(81,135,200,0.1)" : "white" }} title="&#129505;" onPress={() => onUpdateReaction(Reactions.HEART)} />
                <Menu.Item underlayColor="transparent" disabled style={{ opacity: 0 }} titleStyle={{ width: 25, margin: 0, padding: 0, backgroundColor: reactions?.[Reactions.HEART]?.includes(user.id) ? "rgba(81,135,200,0.1)" : "white" }} title="&#129505;" onPress={() => onUpdateReaction(Reactions.HEART)} />
                <Menu.Item style={{ marginLeft: -15 }} underlayColor="transparent" titleStyle={{ borderRadius: 4, width: 35, textAlign: 'center', padding: 5, paddingVertical: 6, margin: 0, padding: 0, backgroundColor: reactions?.[Reactions.HEART]?.includes(user.id) ? color : "white" }} title="&#129505;" onPress={() => onUpdateReaction(Reactions.HEART)} />
                <Menu.Item underlayColor="transparent" titleStyle={{ width: 35, textAlign: 'center', borderRadius: 4, padding: 5, backgroundColor: reactions?.[Reactions.THUMBS_UP]?.includes(user.id) ? color : "white" }} title="&#128077;" onPress={() => onUpdateReaction(Reactions.THUMBS_UP)} />
                <Menu.Item underlayColor="transparent" titleStyle={{ width: 35, borderRadius: 4, textAlign: 'center', padding: 5, backgroundColor: reactions?.[Reactions.LAUGH]?.includes(user.id) ? color : "white" }} title="&#128514;" onPress={() => onUpdateReaction(Reactions.LAUGH)} />
                <Menu.Item underlayColor="transparent" titleStyle={{ width: 35, borderRadius: 4, textAlign: 'center', padding: 5, backgroundColor: reactions?.[Reactions.POUTING]?.includes(user.id) ? color : "white" }} title="&#128545;" onPress={() => onUpdateReaction(Reactions.POUTING)} />
                <Menu.Item underlayColor="transparent" titleStyle={{ width: 35, borderRadius: 4, textAlign: 'center', padding: 5, backgroundColor: reactions?.[Reactions.SAD]?.includes(user.id) ? color : "white" }} title="&#128542;" onPress={() => onUpdateReaction(Reactions.SAD)} />
                <Menu.Item underlayColor="transparent" titleStyle={{ width: 35, borderRadius: 4, textAlign: 'center', padding: 5, backgroundColor: reactions?.[Reactions.THUMBS_DOWN]?.includes(user.id) ? color : "white" }} title="&#128078;" onPress={() => onUpdateReaction(Reactions.THUMBS_DOWN)} />
            </Menu>


        </View >
    )

}

export default ReactionBox;
