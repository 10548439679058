
import { store } from '@/redux/store'

/**
 * Check if the logged user can access the feature
 * @param actions
 * @returns {boolean|*}
 */
const canAccess = (actions) => {
  const state = store.getState()
  const permissions = state.auth.member?.roles?.map(role => role?.permissions.map(permission => permission?.name)).flat()
  // If is an array and have at least one of these permissions
  if (typeof actions === 'object') {
    return actions?.some(action => permissions?.indexOf(action) >= 0)
  }
  return permissions?.indexOf(actions) >= 0
}

export default canAccess
