/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
  useEffect,
} from 'react'
import _ from 'lodash'
import { ScrollView, View, useWindowDimensions, TouchableOpacity, FlatList, RefreshControl } from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '@/redux/reducers/teams'
import * as selectors from '@/redux/selectors/teams'
import { useRoute } from '@react-navigation/native'
import { LinearGradient } from 'expo-linear-gradient'
import {
  Avatar,
  Icon,
  InviteMemberWeb,
  KebabMenuMobile,
  LeaveModalMobile,
  LeaveModalWeb,
  MemberListWeb,
  CreatePost,
  FeedPostCard,
  AvatarWriteButton,
  GroupTeamsMemberCard,
  BackArrow,
} from '@/components'
import * as S from './styles'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import { KebabeMenuMobileContext } from '@/contexts/Community/Group/kebabMenuMobile'
import CreateEditGroup from '@/pages/Community/CreateEditGroup'
import { store } from '@/redux/store'
import { ProgressBar } from 'react-native-paper'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import ApiHandler from '@/api/ApiHandler'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import ManageMemberModal from '@/components/ManageMemberModal/ManageMemberModal'
import moment from 'moment'
import FeedCard from '@/components/Feed/Card'
import PostComposer from '@/components/Feed/Card/Content/Post/Components/PostComposer'

import * as brandSelectors from '@/redux/selectors/branding'

const TeamPage = ({
  navigation
}) => {
  const dispatch = useDispatch()
  const team = useSelector(selectors.team)
  const posts = useSelector(selectors.posts)
  const current_page = useSelector(selectors.current_page)
  const loading = useSelector(selectors.loading)
  // const response = new ApiHandler().getCurrentUser()
  // const user = response?.data?.id
  const [isCreatePostVisible, setCreatePostVisible] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const [isLeaveWebVisible, setLeaveWebVisible] = useState(false)
  const [isInviteMemberWebVisible, setInviteMemberWebVisible] = useState(false)
  const [isLeaveMobileVisible, setLeaveMobileVisible] = useState(false)
  const [isDeleteMobileVisible, setDeleteMobileVisible] = useState(false)
  const [isDeleteWebVisible, setDeleteWebVisible] = useState(false)
  const [isCreateEditVisible, setCreateEditVisible] = useState(false)
  const [isMemberGroupVisible, setMemberGroupVisible] = useState(false)
  const [isManageMemberViewVisible, setManageMemberViewVisible] = useState(false)
  const [blockJoin, setBlockJoin] = useState(false)
  const can_load_more = useSelector(selectors.can_load_more)
  const next_page = useSelector(selectors.next_page)
  const memberLenght = (team?.members ?? []).length
  const members = team?.members ?? []
  const memberWord = memberLenght > 1 ? 'members' : 'member'
  const { height } = useWindowDimensions()
  const route = useRoute()
  const id = route?.params?.id

  const branding = useSelector(brandSelectors.getSettings)
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const user = store.getState().auth.member
  const isMember = members?.some(member => member.id === user?.id)
  const isOwner = team?.owner_id == user?.id
  useEffect(() => {
    if (!id) {
      navigation.navigate('Community')
    }
  }, [id])
  useEffect(() => {
    if (id) {
      dispatch(actions.show({ id }, (error) => {
        if (error) {
          navigation.navigate('Community')
        }
      }))
      dispatch(actions.posts({ id, current_page: 1 }))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (!loading && !isMember && members.length === 0) {
      setBlockJoin(false)
    } else {
      setBlockJoin(true)
    }
  }, [members])

  const handleListMember = useCallback(() => {
    if (isMobile) {

      navigation.navigate('GroupMembers', {
        // members: members.filter(member => member.id != user.id),
        isTeam: true,
        // handleRemoveMember: () => handleRemoveMember,
        isOwner: isOwner,
        onBack: () => setManageMemberViewVisible(false),
        team_id: id,
        id: id,

      })
      return
    }
    setManageMemberViewVisible(true)
  }, [navigation, isMobile, setMemberGroupVisible])

  const handleCreatePost = useCallback(() => {
    setCreatePostVisible(true)
  }, [setCreatePostVisible, isMobile])

  const handleClosePostActions = useCallback(() => {
    dispatch(actions.posts({ id, page: 1 }))
    setCreatePostVisible(false)
  }, [setCreatePostVisible, isMobile])

  const isCloseToBottom = useCallback(({ layoutMeasurement, contentOffset, contentSize }) => {
    const paddingToBottom = height * 0.5
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
  }, [])

  const updateInfinityScroll = useCallback(() => {
    if (can_load_more) {
      dispatch(actions.posts({ id, page: next_page }, () => { }))
    }
  }, [next_page, current_page, dispatch])
  const handleEditInvite = useCallback(() => {
    dispatch(actions.show({ id }, () => { }))
  }, [dispatch, setCreateEditVisible])

  const handleRemoveMember = useCallback((member_id) => {
    dispatch(actions.removeMember({ team_id: id, member_id: member_id }, () => {
      dispatch(actions.show({ id }))
    }))
  }, [dispatch])

  const handleJoin = useCallback(() => {
    if (!blockJoin) {
      dispatch(actions.join({ id }, () => {
        dispatch(actions.show({ id }))
      }))
      setBlockJoin(true)
    }
  }, [dispatch])

  const handleLeave = useCallback(() => {
    dispatch(actions.leave({ id }, () => {
      dispatch(actions.show({ id }))
    }))
    setLeaveMobileVisible(false)
    // navigation.navigate('Community')
    navigation.reset({
      index: 0,
      routes: [{ name: 'Community' }]
    })
  }, [dispatch, navigation])

  const handleDelete = useCallback(() => {
    dispatch(actions.remove({ id }, () => {
      // navigation.navigate('Community')
      navigation.reset({
        index: 0,
        routes: [{ name: 'Community' }]
      })
    }))
    setLeaveMobileVisible(false)
  }, [navigation, dispatch])
  const expansiveOwnerChildrens = [
    { icon: 'pencil', text: 'Edit information', onPress: () => { setCreateEditVisible(true) } },
    { icon: 'addUser', text: 'Invite members', onPress: () => { setInviteMemberWebVisible(true) } },
    { icon: 'community', text: 'Manage members', onPress: () => { setManageMemberViewVisible(true) } },
    { icon: "leave", text: "Delete Team", onPress: () => { setDeleteWebVisible(true) } },
  ]
  const expansiveMemberChildrens = [
    { icon: 'leave', text: 'Leave group', onPress: () => { setLeaveWebVisible(true) } }
  ]
  useEffect(() => {
    if (isMobile && isManageMemberViewVisible) {

      handleListMember()
    }
  }, [isManageMemberViewVisible])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // dispatch(actions.posts({id, page: next_page }, () => {}))
    })
    return unsubscribe
  }, [navigation])

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    dispatch(
      actions.posts({ id, page: 1 }, () => {
        setRefreshing(false)
      })
    )
  }, [])

  return (
    <>
      <ProgressBar indeterminate={true} visible={loading} />
      <S.Container>
        <ScrollView
          contentContainerStyle={S.styles.contentScrollView}
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              updateInfinityScroll()
            }
          }}
          scrollEventThrottle={10}
          bounces={false}
          scrollEnabled={true}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              title=""
            />
          }
        >
          <S.Header>
            <S.ImageCoverContainer>
              {isMobile ? (
                <S.MenuHeader>
                  <BackArrow color={theme.basics.background_white} />
                  <S.SectionMenuHeader>
                    <S.IconButton>
                      <Icon
                        icon="searchIcon"
                        color={theme.basics.background_white}
                        height={16}
                        width={16}
                      />
                    </S.IconButton>
                    <S.IconButton onPress={() => setModalVisible(true)}>
                      <Icon
                        icon="ellipsis"
                        color={theme.basics.background_white}
                        height={16}
                        width={16}
                      />
                    </S.IconButton>
                  </S.SectionMenuHeader>
                </S.MenuHeader>
              ) :
                <S.MenuHeader>
                  <BackArrow color={theme.basics.background_white} />
                </S.MenuHeader>
              }
              <>
                <LinearGradient
                  colors={['rgba(0,0,0,0.6)', 'transparent']}
                  style={S.styles.gradiente}
                />
                <S.CoverImage source={team?.image ? { uri: team?.image } : PlaceholderImageCover} resizeMode="cover" />
              </>
            </S.ImageCoverContainer>
            <S.SubHeader>
              <S.Title>{team?.name ?? ''}</S.Title>
              <S.Description>{team?.description}
                {"\n"}
                <S.TitleListMember>{moment(team?.created_at).format("MMM D, YYYY")}</S.TitleListMember>
              </S.Description>
            </S.SubHeader>
            <S.BottomHeader>
              <S.MembersContainer>
                <S.TitleListMember>{memberLenght} {memberWord}</S.TitleListMember>
                <S.MembersList>
                  {(members ?? [])?.length === 5 && _.slice(members, 0, 5).map((employee, key) => (
                    <TouchableOpacity onPress={handleListMember} key={key}>
                      <Avatar
                        style={{ marginRight: 12 }}
                        source={employee?.avatar ? { uri: employee?.avatar } : null}
                        size={isMobile ? 45 : 75}
                      />
                    </TouchableOpacity>
                  ))}
                  {(members)?.length < 5 && _.slice(members, 0, 4).map((employee, key) => (
                    <TouchableOpacity onPress={handleListMember}
                      key={key}>
                      <Avatar
                        style={{ marginRight: 12 }}
                        source={employee?.avatar ? { uri: employee?.avatar } : null}
                        size={isMobile ? 45 : 75}
                      />
                    </TouchableOpacity>
                  ))}
                  {(members ?? [])?.length > 5 && _.slice(members, 0, 4).map((employee, key) => (
                    // <TouchableOpacity onPress={() => navigation.navigate('Profile', { id: employee?.id })} key={key}>
                    <TouchableOpacity onPress={handleListMember} key={key}>
                      <Avatar
                        style={{ marginRight: 12 }}
                        source={employee?.avatar ? { uri: employee?.avatar } : null}
                        size={isMobile ? 45 : 75}
                      />
                    </TouchableOpacity>
                  ))}
                  {(members ?? []).length > 5 && (
                    <S.AvatarMoreContainer>
                      <Avatar
                        style={{ marginRight: 12 }}
                        key={_.last(members)?.uriImage}
                        source={{ uri: _.last(members)?.uriImage }}
                        size={isMobile ? 45 : 75}
                      />
                      <S.AvatarMoreButton onPress={handleListMember}>
                        <S.TextMoreButton>
                          +{(members ?? []).length - 5}
                        </S.TextMoreButton>
                      </S.AvatarMoreButton>
                    </S.AvatarMoreContainer>
                  )}
                </S.MembersList>
              </S.MembersContainer>
              {!isMobile ?
                <S.ContainerButtons>
                  {!isMember && (
                    <S.ParticipateButton color={color_scheme_primary_button_background} onPress={handleJoin} blockJoin={blockJoin}>
                      <S.ParticipateText color={color_scheme_primary_button_background} blockJoin={blockJoin}>Participate</S.ParticipateText>
                    </S.ParticipateButton>
                  )}
                  {(isOwner || canAccess(permissions.MANAGE_EMPLOYEES))
                    ? <ExpansiveMenu expansiveChildrens={expansiveOwnerChildrens} />
                    : <ExpansiveMenu expansiveChildrens={expansiveMemberChildrens} />
                  }
                </S.ContainerButtons>
                : null}
            </S.BottomHeader>
          </S.Header>
          {/* <GroupTeamsMemberCard
            isTeam={true}
            isOwner={isOwner}
            members={members.filter(member => member.id != user.id)}
            onBack={() => setManageMemberViewVisible(false)}
            isManageMemberViewVisible={isManageMemberViewVisible}
            handleRemoveMember={handleRemoveMember} /> */}
          {isMember && !isManageMemberViewVisible && (
            <S.Main>
              {isMemberGroupVisible ? (
                <MemberListWeb closeList={setMemberGroupVisible} />
              ) : (
                <View style={S.styles.flatListContent}>
                  <AvatarWriteButton
                    avatarSize={50}
                    text="Write a post"
                    avatarSource={user?.avatar}
                    onPress={handleCreatePost}
                  />
                  {(posts.data ?? []).map(post => (
                    <FeedCard
                      key={'teams' + post.id}
                      post={post}
                    />
                  ))}
                </View>
              )}
            </S.Main>
          )}
        </ScrollView>
        <KebabeMenuMobileContext.Provider value={{
          setLeaveMobileVisible,
          setDeleteMobileVisible,
          setInviteMemberWebVisible,
          setManageMemberViewVisible,
          title: team?.name ?? '',
          description: team?.description ?? '',
          source: team?.image,
          id: id,
          isOwner: isOwner
        }}>
          <KebabMenuMobile
            screenType={'Team'}
            isModalVisible={isModalVisible}
            setModalVisible={setModalVisible}
            handleDelete={handleDelete}
            handleLeave={handleLeave}
            setCreateEditVisible={setCreateEditVisible}
          />
        </KebabeMenuMobileContext.Provider>
        <LeaveModalWeb
          isLeaveWebVisible={isDeleteWebVisible}
          setLeaveWebVisible={setDeleteWebVisible}
          onClose={handleDelete}
          type="delete"
        />
        <LeaveModalWeb
          isLeaveWebVisible={isLeaveWebVisible}
          setLeaveWebVisible={setLeaveWebVisible}
          onClose={handleLeave}
          type="leave"
        />
        <LeaveModalMobile
          setLeaveMobileVisible={setDeleteMobileVisible}
          isLeaveMobileVisible={isDeleteMobileVisible}
          onClose={handleDelete}
          type="delete"
          screenType={'team'}
        />
        <LeaveModalMobile
          setLeaveMobileVisible={setLeaveMobileVisible}
          isLeaveMobileVisible={isLeaveMobileVisible}
          onClose={handleLeave}
          type="leave"
          screenType={'team'}
        />
        <InviteMemberWeb
          isVisible={isInviteMemberWebVisible}
          setVisible={setInviteMemberWebVisible}
          data={team}
          joinedMembers={members}
          type='team'
          onClose={handleEditInvite}
        />
        {!isMobile && //if platform is web modal will show else will navigate to another screen
          <ManageMemberModal
            isTeam={true}
            isOwner={isOwner}
            members={members.filter(member => member.id != user.id)}
            onBack={() => setManageMemberViewVisible(false)}
            isManageMemberViewVisible={isManageMemberViewVisible}
            setManageMemberViewVisible={setManageMemberViewVisible}
            handleRemoveMember={handleRemoveMember}
          // members={members.filter(member => member.id != user.id)}
          />}
        <PostComposer
          isVisible={isCreatePostVisible}
          setVisible={setCreatePostVisible}
          onClose={handleClosePostActions}
          attachmentId={team?.id}
          attachmentType='App\Team'
        />
        <CreateEditGroup
          type='team'
          data={team}
          isVisible={isCreateEditVisible}
          setVisible={setCreateEditVisible}
          onClose={handleEditInvite}
        />
      </S.Container>
    </>
  )
}

TeamPage.propTypes = {
  title: PropTypes.string,
  source: PropTypes.number,
  description: PropTypes.string,
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  })
}

export default TeamPage