import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/article'
import { actions } from '@/redux/reducers/article'
import { useNavigation, useRoute } from '@react-navigation/native'
import { BackArrow, Carousel, SearchWeb } from '@/components'
import ArticleCard from '@/components/Learning/ArticleCard'
import SimpleSelect from '@/components/SimpleSelect'
import { ProgressBar } from 'react-native-paper'
import { useIsMobile } from '@/styles'
import {
  Container,
  Title,
  HeadLine,
  SubTitle,
  Header,
  TitleWrapper,
  Select,
  styles,
  ClearButton,
  CreateButton,
  CreateText,
} from '../styles'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import * as brandSelectors from '@/redux/selectors/branding'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ModalV1 from '@/components/ModalV1'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import Card from '@/components/Learning/Card'

const Articles = () => {
  const branding = useSelector(brandSelectors.getSettings)
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const [filter, setFilter] = useState({
    employee_lifecycle_stage_id: null,
    five_s_category_id: null,
  })

  //Workaround to NONE as a logical unit
  const [filtered, setFiltered] = useState({
    employee_lifecycle_stage_id: null,
    five_s_category_id: null,
  })
  const [searching, setSearching] = useState(false)
  const [searchTxt, setSearchTxt] = useState('')

  const [featuredPage, setFeaturedPage] = useState(1)
  const [readLaterPage, setReadLaterPage] = useState(1)
  const [dataPage, setDataPage] = useState(1)

  const navigation = useNavigation()
  const route = useRoute()

  const lifecycles = useSelector(selectors.lifecycles)
  const categories = useSelector(selectors.categories)

  const featured = useSelector(selectors.featured)
  const featuredNextPage = useSelector(selectors.featuredNextPage)
  const featuredLoading = useSelector(selectors.featuredLoading)

  const readLater = useSelector(selectors.readLater)
  const readLaterNextPage = useSelector(selectors.readLaterNextPage)
  const readLaterLoading = useSelector(selectors.readLaterLoading)

  const [filterModalVisible, setFilterModalVisible] = useState(false)

  const data = useSelector(selectors.data)
  // const dataNextPage = useSelector(selectors.dataNextPage)
  // const dataLoading = useSelector(selectors.dataLoading)

  const loading = useSelector(selectors.loading)

  const featuredNext = () => {
    if (featuredLoading || !featuredNextPage) {
      return
    }
    dispatch(actions.featured({ page: featuredPage + 1 }, () => { }))
    setFeaturedPage(featuredPage + 1)
  }

  const readLaterNext = () => {
    if (readLaterLoading || !readLaterNextPage) {
      return
    }
    dispatch(actions.readLater({ page: readLaterPage + 1 }, () => { }))
    setReadLaterPage(readLaterPage + 1)
  }

  const setReadLater = (article) => {
    dispatch(actions.setReadLater(article, () => { }))
  }

  const handleFilterChange = (type, value) => {
    filtered[type] = value
    setFiltered({ ...filtered })
    setDataPage(1)
    index()
  }

  const index = () => {
    filter.employee_lifecycle_stage_id = filtered.employee_lifecycle_stage_id
    filter.five_s_category_id = filtered.five_s_category_id
    if (filtered.employee_lifecycle_stage_id == 0) {
      filter.employee_lifecycle_stage_id = null
    }
    if (filtered.five_s_category_id == 0) {
      filter.five_s_category_id = null
    }
    setFilter(filter)
    dispatch(
      actions.index({ page: dataPage, filter, search: searchTxt }, () => { }),
    )
  }

  useEffect(() => {
    navigation.addListener('focus', () => {
      dispatch(actions.dataprovider(() => { }))
      dispatch(actions.featured({ page: featuredPage }, () => { }))
      dispatch(actions.readLater({ page: readLaterPage }, () => { }))
      index()
    })
  }, [])

  useEffect(() => {
    dispatch(actions.dataprovider({}, () => { }))
    index()
  }, [filtered, searchTxt])

  const handleCreateArticle = useCallback(() => {
    dispatch(actions.clearArticle())
    dispatch(
      actions.storeDraft({}, (err, success) => {
        console.log(err, success)
      }),
    )
    navigation.navigate('CreateArticle', {
      previus_route_name: route?.name || '',
      id: null,
    })
  }, [])

  const {
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding
  return (
    <>
      <PageHeader
        title={'Articles'}
        icon={'education'}
        backEnabled={true}
        breadcrumbs={[
          { name: 'Learning', navigation: 'Learning' },
          { name: 'Articles' },
        ]}
        actions={
          [
            {
              title: 'Create',
              type: 'menu',
              permissions: [
                'CREATE_LEARNING_ARTICLES',
              ],
              menu: [
                {
                  title: 'Create Article',
                  icon: false,
                  permissions: [
                    'CREATE_LEARNING_ARTICLES'
                  ],
                  onPress: () => {
                    handleCreateArticle()
                  }
                }
              ]
            }
          ]
        }
      />

      <PageContent>
        <ContentBlockV1
          title={'Featured'}
        >
          <Carousel nextPage={featuredNext}>
            {featured?.map((article, key) => (
              <Card
                type={'article'}
                setReadLater={() => {
                  setReadLater(article)
                }}
                key={'feat_mobile_article_' + key + '_' + article.id}
                {...article}
              />
            ))}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={'Read Later'}
        >
          <Carousel nextPage={readLaterNext}>
            {readLater && readLater.length > 0 ? (
              readLater?.map((article, key) => (
                <Card
                  type={'article'}
                  setReadLater={() => {
                    setReadLater(article)
                  }}
                  key={'read_article_' + key + '_' + article.id}
                  {...article}
                />
              ))
            ) : (
              <Text>You don&apos;t have any read later article.</Text>
            )}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={'All Articles'}
          action={{
            onPress: () => {
              setFilterModalVisible(true)
            },
            icon: 'searchIcon'
          }}
        >
          <View style={{
            flexDirection: 'row',
            flex: 1,
            maxWidth: '100%',
            flexWrap: 'wrap',
          }}>
            {data && data.length ? (
              data.map((article, key) => (
                <Card
                  type={'article'}
                  setReadLater={() => {
                    setReadLater(article)
                  }}
                  key={'article_' + key + '_' + article.id}
                  {...article}
                />
              ))
            ) : (
              <View>
                <Text>No articles was found using this filter.</Text>
              </View>
            )}
          </View>
        </ContentBlockV1>
      </PageContent>

      <ModalV1
        isVisible={filterModalVisible}
        setVisibility={setFilterModalVisible}
        title={'Search'}
        subtitle={'Utilize these filters to find the articles that you are looking for.'}
      >
        <HeadLine>
          <SubTitle isMobile={isMobile}>I'm looking for</SubTitle>
          <SearchWeb
            onChange={(val) => {
              setSearchTxt(val)
            }}
            search={searchTxt}
          />
        </HeadLine>
        <View style={styles.dropDownView}>
          <Select>
            <SimpleSelect
              list={lifecycles}
              style={styles.select}
              label="Lifecycle Stage"
              value={filtered.employee_lifecycle_stage_id}
              setValue={(value) =>
                handleFilterChange('employee_lifecycle_stage_id', value)
              }
            />
          </Select>
          <Select>
            <SimpleSelect
              list={categories}
              style={styles.select}
              label="Categories"
              value={filtered.five_s_category_id}
              setValue={(value) =>
                handleFilterChange('five_s_category_id', value)
              }
            />
          </Select>
          <ClearButton
            onPress={() => {
              setSearchTxt('')
              setFiltered({
                employee_lifecycle_stage_id: null,
                five_s_category_id: null,
              })
            }}
          >
            <Text style={{ color: 'white' }}>Clear</Text>
          </ClearButton>
        </View>
      </ModalV1>
    </>
  )
}

export default Articles
