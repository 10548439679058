import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ImageUploading from 'react-images-uploading'
import { MaterialIcons } from '@expo/vector-icons'
import UploadImage from './UploadImage.web'
import SavedImage from './SavedImage.web'
import {
  UploadImageContainer,
  CreateButton,
  CreateButtonText
} from './styles.web'


const ImageAreaUploader = ({
  darggingText,
  text,
  maxNumber,
  images,
  updateFilesAction,
  deleteFileAction,
  brandingSetting
}) => {
  const [uploaderImages, setImages] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()


  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = brandingSetting


  const toggleDraggingText = darggingText ?? 'Drop here please'
  const toggleText = text ?? 'Drag and drop your images here'
  const maxImgsNumber = maxNumber ?? 10

  const handleSalveAllImages = useCallback((removeAll) => {
    const imagesUrl = uploaderImages.map(file => file.dataURL)
    setIsLoading(true)
    dispatch(updateFilesAction(imagesUrl, (err, success) => {
      if (success) {
        setIsLoading(false)
        removeAll()
        return
      }
      if (err) {
        setIsLoading(false)
      }
    }))
  }, [dispatch, updateFilesAction, setIsLoading, uploaderImages])

  return (
    <ImageUploading
      multiple
      isDragging
      value={uploaderImages}
      onChange={setImages}
      maxNumber={maxImgsNumber}
    >
      {({
        imageList,
        dragProps,
        isDragging,
        onImageUpload,
        onImageRemove,
        onImageRemoveAll,
      }) => (
        <UploadImageContainer
          isDragging={isDragging}
          hasImg={imageList?.length > 0}
        >
          <div className="main" {...dragProps}>
            <MaterialIcons
              name="cloud-upload"
              size={50}
              color="#1B1B1B"
            />
            <span>
              {isDragging ? toggleDraggingText : toggleText}
            </span>
            {!isDragging && (

              <CreateButton onClick={onImageUpload} bg={color_scheme_primary_button_background} >
                <CreateButtonText color={color_scheme_primary_button_text} >
                  Upload images
                </CreateButtonText>
              </CreateButton>
            )}
            {imageList?.length > 0 && (
              <CreateButton onClick={() => handleSalveAllImages(onImageRemoveAll)}>
                <CreateButtonText>Save all</CreateButtonText>
              </CreateButton>
            )}
          </div>
          <div className="imgs">
            {(imageList ?? [])?.map((image, index) => (
              <UploadImage
                key={index}
                source={image?.url ? image?.url : image?.dataURL}
                onImageRemove={() => onImageRemove(index)}
                updateFilesAction={updateFilesAction}
                isAllLoading={isLoading}
              />
            ))}
          </div>
          {(images ?? []).length > 0 && (
            <div className="imgs">
              {
                (images ?? []).map(image => (
                  <SavedImage
                    image={image}
                    key={image?.url}
                    deleteFileAction={deleteFileAction}
                  />
                ))
              }
            </div>
          )}
        </UploadImageContainer>
      )}
    </ImageUploading>
  )
}

ImageAreaUploader.propTypes = {
  darggingText: PropTypes.string,
  text: PropTypes.string,
  maxNumber: PropTypes.number,
  images: PropTypes.arrayOf(PropTypes.string),
  updateFilesAction: PropTypes.func,
  deleteFileAction: PropTypes.func,
}

export default ImageAreaUploader
