import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ArrowRight(props) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
        fill={props.color}
      />
    </Svg>
  )
}
