import React, { useState } from 'react'
import { View, Platform, Text, Dimensions, TouchableOpacity } from 'react-native'
import { CollapseButton, CollapseButtonMobile, Icon } from '@/components'
import theme from '@/styles/themes/black'
import { CollapseButtonContainer, CollapseButtonToggle, Button } from './styles'
import PropTypes from 'prop-types'
import { isMobile } from '@/styles'
import { Ionicons } from '@expo/vector-icons'
import { Layouts } from '../Main/Layouts'

const ExpansiveMenu = ({
  btnTextColor,
  desktopText,
  expansiveChildrens,
  mainIcon,
  mainIconColor,
  mainIconBackground,
  onToggle,
  showSecondaryButton,
  layout,
  ...params
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const onButtonMobileClicked = (onPress) => {
    setModalVisible(false)
    onPress()
  }
  // return isMobile ? (
  //   <View style={{
  //         position: 'absolute',
  //         height: 40,
  //         left: 0, 
  //         top: windowHeight - 165, 
  //         width: windowWidth-35,
  //   }}>
  //     <CollapseButtonToggle
  //       background={mainIconBackground}
  //       color={mainIconColor}
  //       {...params}
  //       style={{width:'100%'}}
  //     >
  //       <Button
  //         onPress={() => {
  //           setModalVisible(true)
  //         }}
  //       >
  //         <View style={{
  //                     flexDirection: "row",
  //                     paddingLeft:20,
  //                     paddingBottom:40
  //                   }}>
  //        <View style={{flex:0.8,marginLeft:30,color:'#fff'}}>
  //        <Text style={{width:'100%',color:theme.basics.background_white,textAlign:'center',fontSize:20}}>
  //           New
  //           </Text>
  //        </View>
  //        <View style={{flex:0.2,paddingTop:5,marginRight:30}}>
  //        <Icon
  //           color={theme.basics.background_white}
  //           icon={'chevronTop'}
  //           width={18}
  //           height={18}
  //         />
  //        </View>
  //         </View>


  //         {/* <Icon
  //           color={mainIconColor || theme.basics.background_white}
  //           icon={mainIcon || 'ellipsis'}
  //           width={18}
  //           height={18}
  //         /> */}
  //       </Button>
  //     </CollapseButtonToggle>
  //     <CollapseButtonMobile
  //       isCollapseVisible={isModalVisible}
  //       setCollapseVisible={setModalVisible}
  //     >
  //       {expansiveChildrens.map((item, index) => (
  //         <CollapseButtonMobile.Item
  //           key={index}
  //           color={theme.basics.black_text}
  //           iconSize={14}
  //           text={item.text}
  //           onPress={() => onButtonMobileClicked(item.onPress)}
  //         />
  //       ))}
  //     </CollapseButtonMobile>
  //   </View>
  // ) : (
  //   <CollapseButtonContainer >
  //     <CollapseButton
  //       icon={desktopText ? null : mainIcon || 'ellipsis'}
  //       btnAlign="flex-end"
  //       btnWidth={desktopText ? null : 38}
  //       iconWidth={4}
  //       iconHeight={16}
  //       btnPaddingHorizontal={0}
  //       btnText={desktopText}
  //       iconColor={mainIconColor || theme.basics.black_text}
  //       bgButtonColor={mainIconBackground}
  //       style={{zIndex:9999}}
  //       // btnTextColor={color_scheme_primary_button_text}
  //       {...params}
  //     >
  //       {expansiveChildrens.map((item, index) => (
  //         <CollapseButton.Item
  //           key={index}
  //           color={theme.basics.black_text}
  //           icon={item.icon}
  //           iconSize={14}
  //           text={item.text}
  //           onPress={() => onButtonMobileClicked(item.onPress)}
  //         />
  //       ))}
  //     </CollapseButton>
  //   </CollapseButtonContainer>
  // )
  return isMobile ? (
    <View style={{ zIndex: 9999 }}>
      <CollapseButtonToggle
        background={mainIconBackground}
        color={mainIconColor}
        {...params}
      >
        <Button
          onPress={() => {
            setModalVisible(true)
          }}
        >
          <Icon
            color={mainIconColor || theme.basics.background_white}
            icon={mainIcon || 'ellipsis'}
            width={18}
            height={18}
          />
        </Button>
      </CollapseButtonToggle>
      <CollapseButtonMobile
        isCollapseVisible={isModalVisible}
        setCollapseVisible={setModalVisible}
      >
        {expansiveChildrens.map((item, index) => (
          <CollapseButtonMobile.Item
            key={index}
            color={theme.basics.black_text}
            iconSize={14}
            text={item.text}
            onPress={() => onButtonMobileClicked(item.onPress)}
          />
        ))}
      </CollapseButtonMobile>
    </View>
  ) : (
    <CollapseButtonContainer style={{ backgroundColor: 'transparent' }}>

      {showSecondaryButton && (
        <TouchableOpacity onPress={onToggle} style={{ zIndex: 99999, marginRight: 40 }}>
          <Ionicons name={layout === Layouts.grid ? "list-outline" : "grid-outline"} size={20} color="black" />
        </TouchableOpacity>
      )}

      <CollapseButton
        icon={mainIcon || 'ellipsis'}
        btnAlign="flex-end"
        btnWidth={desktopText ? null : 38}
        iconWidth={4}
        iconHeight={16}
        btnPaddingHorizontal={0}
        iconColor={theme.basics.black_text}
        bgButtonColor="white"
        style={{ zIndex: 9999, marginHorizontal: 10 }}
        // btnTextColor={color_scheme_primary_button_text}
        {...params}
      >
        {expansiveChildrens.map((item, index) => (
          <CollapseButton.Item
            key={index}
            color={theme.basics.black_text}
            icon={item.icon}
            iconSize={14}
            text={item.text}
            onPress={() => onButtonMobileClicked(item.onPress)}
          />
        ))}
      </CollapseButton>
    </CollapseButtonContainer>
  )
}
{

}
{
  /* </> */
}


ExpansiveMenu.propTypes = {
  expansiveChildrens: PropTypes.array.isRequired,
  expansiveMains: PropTypes.array,
  mainIcon: PropTypes.string,
  mainIconColor: PropTypes.string,
  mainIconBackground: PropTypes.string,
  desktopText: PropTypes.string,
}

export default ExpansiveMenu
