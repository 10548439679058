import React, { useState } from 'react'
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    ScrollView,
    TouchableOpacity,
    SafeAreaView,
    Platform
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/native'
import Toast from 'react-native-toast-message'
import Modal from "react-native-modal";
import filter from 'lodash.filter'
import { Badge } from 'react-native-elements'

import * as brandSelectors from '@/redux/selectors/branding'

import { BackArrow, Icon, TextField } from '@/components'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import ButtonPrimary from '@/components/ButtonPrimary'
import DropDown from "react-native-paper-dropdown";

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import ApiHandler from '@/api/ApiHandler'

import NameAndTarget from './name_target';
import Questions from './questions';
import { ScreenSize, useScreenSize } from '@/styles'

import ContentBlockV1 from '@/components/ContentBlockV1'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

import * as DocumentPicker from 'expo-document-picker';

import {
    TYPE_CULTURE_MATURITY,
    TYPE_CUSTOM,
    TYPE_PREBUILT,
    isActiveAssessment

} from "../consts";
import { Loader } from '../loader'

const CreateAssessment = () => {

    const screenSize = useScreenSize()
    const navigation = useNavigation();

    const { params } = useRoute();
    const assessmentId = params?.id;

    const [loader, setLoader] = useState(false);


    const [confirmDelete, setConfirmDelete] = useState(false);

    const editable = (params?.id && params?.type) || false;

    const [isLoading, setLoading] = useState(false);

    const [name, setName] = useState("")


    const [lifeCycle, setLifecycle] = useState(null);
    const [category, setCategory] = useState(null);


    const [lifeCycleList, setLifeCycleList] = useState([]);

    const [categoryList, setCategoryList] = useState();


    const [teamsList, setTeamList] = useState();

    const [groupsList, setGroupList] = useState();


    const [assessmentInfo, setAssessmentInfo] = useState({});
    const [isActive, setActive] = useState(false);

    const [outboundEmail, setOutboundEmail] = useState("")
    const [outboundCSV, setOutboundCSV] = useState(false)
    const [outboundRecipients, setOutboundRecipients] = useState([]);
    const [outboundRecipientsFiltered, setOutboundRecipientsFiltered] = useState([]);
    const [outboundAssessmentLink, setOutboundAssessmentLink] = useState(null);


    const assessmentType = params?.type;
    let assessmentUpdateable = false;

    if (assessmentType) {
        if (assessmentType === TYPE_CUSTOM) {
            assessmentUpdateable = true;
        }
    } else {
        assessmentUpdateable = true;
    }



    React.useEffect(() => {

        const getLifeCycles = async () => {
            const response = await new ApiHandler().getLifecycles();
            if (response?.data) {
                setLifeCycleList(response?.data || [])
            }
        }


        const getCategories = async () => {
            const response = await new ApiHandler().getCategories();
            if (response?.data) {
                setCategoryList(response?.data || [])
            }
        }

        const getSquads = async () => {
            const response = await new ApiHandler().getSquads();
            if (response?.data) {
                setTeamList(response?.data || [])
            }
        }

        const getChapters = async () => {
            const response = await new ApiHandler().getChapters();
            if (response?.data) {
                setGroupList(response?.data || [])
            }
        }


        getLifeCycles();
        getCategories();
        getSquads();
        getChapters();

    }, [assessmentId])

    React.useEffect(() => {

        if (editable) {
            fetchAssessment();
        }

    }, [assessmentId])

    React.useEffect(() => {
        const isActive = isActiveAssessment(assessmentInfo)
        setActive(isActive);
    }, [assessmentInfo, assessmentInfo?.id])

    const fetchAssessment = async () => {

        setLoader(true)

        try {
            if (params?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().getCustomAssessment(params?.id);
                setAssessmentInfo(response?.data?.data || {});

            } else if (params?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().getCulturalMaturityAssessment(params?.id);
                setAssessmentInfo(response?.data?.data || {});
                getOutboundRecipients();
                getOutboundAssessmentLink();

            }
            else if (params?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().getPrebuiltAssessment(params?.id);
                setAssessmentInfo(response?.data?.data || {});

            }

        } catch (error) {
            console.log(">>>> assessment error", error)
        } finally {
            setLoader(false)
        }
    }

    const activateAssessment = async () => {
        setLoading(true)
        if (isLoading) {
            return
        }

        try {
            let message = ""
            if (params?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().activateCustomAssessment(assessmentInfo?.id);
                message = response?.data?.message;

            } else if (params?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().activateCulturalMaturityAssessment(assessmentInfo?.id);
                message = response?.data?.message;

            }
            else if (params?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().activatePrebuiltAssessment(assessmentInfo?.id);
                message = response?.data?.message;

            }

            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })

            setActive(true)

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }
    }
    const deActivateAssessment = async () => {
        setLoading(true)
        if (isLoading) {
            return
        }

        try {
            let message = ""
            if (params?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().deActivateCustomAssessment(assessmentInfo?.id);
                message = response?.data?.message;

            } else if (params?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().deActivateCulturalMaturityAssessment(assessmentInfo?.id);
                message = response?.data?.message;

            }
            else if (params?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().deActivatePrebuiltAssessment(assessmentInfo?.id);
                message = response?.data?.message;

            }

            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })

            setActive(false)
        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }
    }


    const createCustom = async () => {

        const data = {
            name,
            lifecycle_stage_id: lifeCycle,
            five_s_category_id: category
        }
        setLoading(true)

        try {
            const response = await new ApiHandler().createCustomAssessment(data);
            setAssessmentInfo(response?.data?.data || {});
        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }
    }
    const updateCustom = async () => {

        const data = {
            name,
            assessment_id: assessmentInfo?.id,
            lifecycle_stage_id: lifeCycle,
            five_s_category_id: category
        }

        setLoading(true)

        try {
            const response = await new ApiHandler().updateCustomAssessment(data);
            setAssessmentInfo(response?.data?.data || {});
        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }


    }

    const deletCustom = async () => {

        setLoading(true)

        try {
            const response = await new ApiHandler().deleteCustomAssessment(assessmentInfo?.id);

            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
            })
            setConfirmDelete(false)
            navigation?.goBack?.();

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }


    }

    const getOutboundRecipients = async () => {
        try {
            const response = await new ApiHandler().getOutboundRecipients(params?.type, params?.id);
            setOutboundRecipients(response?.data?.data || {});
            setOutboundRecipientsFiltered(response?.data?.data || {});
        } catch (error) {
            console.log("", error)
        } finally {
            setLoader(false)
        }
    }

    const sendOutbound = async () => {
        const data = {
            assessment_type: params?.type,
            assessment_id: assessmentInfo?.id,
            email: outboundEmail,
        }

        setLoading(true)

        try {
            const response = await new ApiHandler().sendOutboundAssessment(data);

            if (response) {
                Toast.show({
                    position: 'top',
                    text1: response?.data?.message
                });

                setOutboundEmail('');
                setOutboundCSV(false);
            }
        } catch (error) {
            console.log("Error Sending Outbound Assessment", error)
        } finally {
            getOutboundRecipients();
            setLoading(false)
        }
    }

    const resendOutbound = async (id) => {
        setLoading(true)

        try {
            const response = await new ApiHandler().resendOutboundAssessment(id);

            if (response) {
                Toast.show({
                    position: 'top',
                    text1: response?.data?.message
                });
            }
        } catch (error) {
            console.log("Error Resending Outbound Assessment", error)
        } finally {
            getOutboundRecipients();
            setLoading(false)
        }
    }

    const handleOutboundCSV = async () => {
        let uploadResponse = await DocumentPicker.getDocumentAsync({
            copyToCacheDirectory: false,
            type: '*/*',
        });

        let { name, size, uri } = uploadResponse;

        let nameParts = name.split('.');
        let fileType = nameParts[nameParts.length - 1];
        var fileToUpload = {
            name: name,
            size: size,
            uri: uri,
            type: "application/" + fileType
        };

        const data = {
            assessment_type: params?.type,
            assessment_id: assessmentInfo?.id,
            csv: fileToUpload,
        }

        setLoading(true)

        try {
            const response = await new ApiHandler().sendOutboundAssessment(data);

            if (response) {
                Toast.show({
                    position: 'top',
                    text1: response?.data?.message
                });

                setOutboundEmail('');
                setOutboundCSV(false);
            }
        } catch (error) {
            console.log("Error Sending Outbound Assessment", error)
        } finally {
            getOutboundRecipients();
            setLoading(false)
        }
    }

    const getOutboundAssessmentLink = async () => {
        try {
            const response = await new ApiHandler().getOutboundAssessmentLink(params?.type, params?.id);
            setOutboundAssessmentLink(response?.data?.data || NULL);
        } catch (error) {
            console.log("", error)
        } finally {
            setLoader(false)
        }
    }

    const createOutboundAssessmentLink = async () => {
        const data = {
            assessment_type: params?.type,
            assessment_id: assessmentInfo?.id,
        }

        setLoading(true)

        try {
            const response = await new ApiHandler().createOutboundAssessmentLink(data);

            if (response) {
                Toast.show({
                    position: 'top',
                    text1: response?.data?.message
                });
            }
        } catch (error) {
            console.log("Error Generating Outbound Assessment Link", error)
        } finally {
            getOutboundAssessmentLink();
            setLoading(false)
        }
    }


    const HorizontalDivider = () => {
        return <View style={styles.divider} />
    }

    const Header = () => {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <BackArrow />
                <Text
                    style={[styles.screenTitle, isMobile ? styles.mobScreenTitle : !isMobile && screenSize <= ScreenSize.Medium && styles.mobScreenTitle]}
                >
                    {assessmentInfo?.id ? "Assessment" : "Create an assessment"}
                </Text>
            </View>
        )
    }


    const DeleteConfirmModal = () => {
        return (

            <Modal isVisible={!!confirmDelete} style={styles.modal} >

                <View style={styles.modalBody} >

                    <Text style={styles.confirmDelete} >{`Are you sure you want to Delete Assessment?`}</Text>
                    <View style={{ flexDirection: "row", marginTop: 24 }} >

                        <ButtonPrimary
                            label={"Cancel"}
                            onPress={() => { setConfirmDelete(false) }}
                        />

                        <ButtonPrimary
                            label={"Confirm"}
                            customStyles={{
                                marginLeft: 12
                            }}
                            onPress={deletCustom}
                            loading={isLoading}
                        />

                    </View>
                </View>
            </Modal>
        )
    }


    return (
        <>
            {
                loader ?
                    <Loader /> :
                    <>
                        <PageHeader
                            title={assessmentInfo?.id ? "Assessment" : "Create an assessment"}
                            icon={false}
                            backEnabled={true}
                        />

                        <PageContent>
                            <ColumnsContainer>
                                <Column
                                    flexBasis={'60%'}
                                >
                                    <ContentBlockV1
                                        title={''}
                                    >
                                        <HorizontalDivider />
                                        <NameAndTarget
                                            editable={editable}
                                            routeParams={params}
                                            assessmentInfo={assessmentInfo}

                                            lifeCycleList={lifeCycleList}
                                            categoryList={categoryList}
                                            teamsList={teamsList}
                                            groupsList={groupsList}

                                            fetchAssessment={fetchAssessment}

                                            name={name}
                                            setName={setName}

                                            lifeCycle={lifeCycle}
                                            setLifecycle={setLifecycle}

                                            category={category}
                                            setCategory={setCategory}

                                            isLoading={isLoading}
                                            setLoading={setLoading}

                                            createCustom={createCustom}
                                        />

                                        <HorizontalDivider />
                                        {
                                            assessmentInfo?.id
                                            && params?.type != TYPE_CULTURE_MATURITY
                                            &&
                                            <Questions
                                                setAssessmentInfo={setAssessmentInfo}
                                                assessmentInfo={assessmentInfo}
                                                editable={editable}
                                                routeParams={params}
                                                fetchAssessment={fetchAssessment}
                                            />
                                        }
                                        {
                                            (() => {

                                                if (assessmentInfo?.id) {
                                                    return (
                                                        <View style={{ flexDirection: "row", justifyContent: "flex-end", alignItems: "center", marginTop: 20 }} >

                                                            <ButtonPrimary
                                                                label={isActive ? "Deactivate" : "Activate"}
                                                                customStyles={{
                                                                    marginRight: 12
                                                                }}
                                                                onPress={isActive ? deActivateAssessment : activateAssessment}
                                                                loading={isLoading}
                                                            />
                                                            {
                                                                assessmentUpdateable
                                                                &&
                                                                <ButtonPrimary
                                                                    label={"Save"}
                                                                    customStyles={{
                                                                        alignSelf: "flex-end"
                                                                    }}
                                                                    onPress={updateCustom}
                                                                    loading={isLoading}
                                                                />
                                                            }

                                                            {
                                                                assessmentType === TYPE_CUSTOM
                                                                &&
                                                                <ButtonPrimary
                                                                    label={"Remove"}
                                                                    customStyles={{
                                                                        marginLeft: 12
                                                                    }}
                                                                    onPress={() => { setConfirmDelete(true) }}
                                                                    loading={isLoading}
                                                                />
                                                            }

                                                        </View>
                                                    )
                                                }
                                            })()
                                        }
                                    </ContentBlockV1>
                                </Column>


                                {
                                    assessmentInfo?.id &&
                                    params?.type == TYPE_CULTURE_MATURITY &&
                                    <Column
                                        flexBasis={'40%'}
                                    >
                                        <ContentBlockV1
                                            title={'External (Email) Distribution'}
                                            subtitle={'Distribute your assessment to your users outside of the app, allowing them to respond without signing up or downloading the app.'}
                                        >
                                            <ContentBlockV1
                                                title={'Recipients'}
                                            >

                                                <View
                                                    style={{
                                                        marginBottom: 15
                                                    }}
                                                >
                                                    <TextField
                                                        onChangeText={(text) => {
                                                            let formattedQuery = text.toLowerCase()
                                                            let data = filter(outboundRecipients, outboundRecipient => {
                                                                return outboundRecipient.email_address.includes(formattedQuery);
                                                            })

                                                            setOutboundRecipientsFiltered(data);
                                                        }}
                                                        textStyle={{ color: '#000' }}
                                                        label="Search"
                                                        autoCapitalize="none"
                                                        editable={true}
                                                    />
                                                </View>

                                                <ScrollView
                                                    style={{
                                                        maxHeight: 200
                                                    }}
                                                >
                                                    <FlatList
                                                        numColumns={1}
                                                        data={outboundRecipientsFiltered}
                                                        keyExtractor={item => item.id}
                                                        renderItem={({ item, index }) => {
                                                            return (
                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        marginBottom: 10
                                                                    }}
                                                                >
                                                                    <View
                                                                        style={{
                                                                            flex: 2,
                                                                            flexDirection: 'column',
                                                                        }}
                                                                    >
                                                                        <Text
                                                                            style={{
                                                                                marginBottom: 5
                                                                            }}
                                                                        >
                                                                            {item?.email_address ?
                                                                                item?.email_address : item?.internal_id ?
                                                                                    item?.internal_id : null
                                                                            }

                                                                        </Text>
                                                                        <View
                                                                            style={{
                                                                                alignItems: 'flex-start'
                                                                            }}
                                                                        >
                                                                            {item?.status == 'completed' && (
                                                                                <Badge
                                                                                    value="Completed"
                                                                                    status="success"
                                                                                    badgeStyle={{
                                                                                        padding: 10
                                                                                    }}
                                                                                />
                                                                            )}

                                                                            {item?.status == 'started' && (
                                                                                <Badge
                                                                                    value="Started"
                                                                                    status="success"
                                                                                    badgeStyle={{
                                                                                        padding: 10
                                                                                    }}
                                                                                />
                                                                            )}

                                                                            {item?.status == 'not_started' && (
                                                                                <Badge
                                                                                    value="Not Started"
                                                                                    status="warning"
                                                                                    badgeStyle={{
                                                                                        padding: 10
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </View>
                                                                    </View>

                                                                    <View
                                                                        style={{
                                                                            flex: 1
                                                                        }}
                                                                    >
                                                                        {item?.email_address &&
                                                                            <ButtonPrimary
                                                                                label={"Resend"}
                                                                                onPress={() => {
                                                                                    resendOutbound(item?.id)
                                                                                }}
                                                                                loading={isLoading}
                                                                            />
                                                                        }
                                                                    </View>
                                                                </View>
                                                            )
                                                        }}
                                                    />
                                                </ScrollView>
                                            </ContentBlockV1>

                                            <ContentBlockV1
                                                title={'Distribute By Email'}
                                                subtitle={'Enter an email address to send the assessment, or upload a CSV file containing email addresses to send to multiple users at once.'}
                                            >
                                                <TextField
                                                    label="Email Address"
                                                    value={outboundEmail}
                                                    autoCapitalize="none"
                                                    onChangeText={(text) => setOutboundEmail(text)}
                                                    editable={true}
                                                />

                                                <ButtonPrimary
                                                    label={"Send To Email"}
                                                    customStyles={{
                                                        marginTop: 15
                                                    }}
                                                    onPress={sendOutbound}
                                                    loading={isLoading}
                                                />

                                                {Platform.OS == 'web' &&
                                                    <ButtonPrimary
                                                        label={"Upload CSV"}
                                                        customStyles={{
                                                            marginTop: 5
                                                        }}
                                                        onPress={handleOutboundCSV}
                                                        loading={isLoading}
                                                    />
                                                }

                                                <Text
                                                    style={{
                                                        fontSize: 11,
                                                        marginTop: 10
                                                    }}
                                                >
                                                    You will be charged $0.80 for each email address that you distribute the assessment to.
                                                </Text>
                                            </ContentBlockV1>

                                            <ContentBlockV1
                                                title={'Distribute By Link'}
                                                subtitle={'Generate a link that you can share with your users to access this assessment.'}
                                            >
                                                {outboundAssessmentLink &&
                                                    <Text>{outboundAssessmentLink?.link}</Text>
                                                }

                                                {!outboundAssessmentLink &&
                                                    <ButtonPrimary
                                                        label={"Generate Link"}
                                                        customStyles={{
                                                            marginTop: 15
                                                        }}
                                                        onPress={createOutboundAssessmentLink}
                                                        loading={isLoading}
                                                    />
                                                }

                                                <Text
                                                    style={{
                                                        fontSize: 11,
                                                        marginTop: 10
                                                    }}
                                                >
                                                    You will be charged $0.80 for each users that accesses and starts the assessment using the link.
                                                </Text>
                                            </ContentBlockV1>

                                        </ContentBlockV1>
                                    </Column>
                                }
                            </ColumnsContainer>
                        </PageContent>

                        <DeleteConfirmModal />
                    </>
            }
        </>
    )
}

export default CreateAssessment;


const styles = StyleSheet.create({
    subContainer: {
        maxWidth: 700,
        backgroundColor: "#FFF",
        paddingVertical: 15,
        paddingHorizontal: 40,
        marginTop: 26,
        borderRadius: 10,
    },
    container: {
        paddingHorizontal: 45,
        paddingVertical: 40,
        flex: 1,
    },
    mobContainer: {
        paddingHorizontal: 15,
        paddingVertical: 20,
        // marginBottom: 50,
        flex: 1,
    },

    screenTitle: {
        marginLeft: 10,
        fontFamily: 'WorkSans_400Regular',
        color: theme.basics.black_text,
        fontSize: 40,
        lineHeight: 40,
    },

    mobScreenTitle: {
        fontSize: 22,
        lineHeight: 25,
    },

    divider: {
        marginTop: 20
    },

    deactive: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 15,
        lineHeight: 18,
        marginRight: 12,
        color: "#1B1B1B"
    },
    modal: {
        paddingVertical: 16,
        paddingHorizontal: 12,
        alignItems: "center",
        justifyContent: "center",
    },
    modalBody: {
        alignItems: "center",
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 10,

    },

    confirmDelete: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 18,
        color: "#1B1B1B"
    }
})