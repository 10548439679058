import _ from 'lodash'

export const courses = (state) => _.get(state, 'course.courses')
export const course = (state) => _.get(state, 'course.course')
export const featured = (state) => _.get(state, 'course.featured')
export const readLater = (state) => _.get(state, 'course.readLater')

export const loading = (state) => _.get(state, 'course.loading')
export const readLaterLoading = (state) =>
  _.get(state, 'course.readLaterLoading')
export const featuredLoading = (state) => _.get(state, 'course.featuredLoading')
export const statusLoading = (state) => _.get(state, 'course.statusLoading')
