import React from "react";
import { StyleSheet, Image, Text, View } from "react-native";
import { isMobile } from "@/styles";
import Avatar from "@/components/Avatar/index";
import { ScreenSize, useScreenSize } from "@/styles";

export const TopPerformers = ({ image, badge, text }) => {
	return (
		<View style={{ marginEnd: 10, paddingLeft: 15, marginBottom: 5 }}>
			{/* <Image source={{ uri: image }} style={styles.image} /> */}
			<Avatar
				style={{ backgroundColor: "#F05343" }}
				source={image ? { uri: image } : undefined}
			/>
			<View style={styles.leftView}>
				<Text style={styles.text}>{text}</Text>
			</View>
			{/*<Image source={badge} style={styles.badge} />*/}
		</View>
	);
};
export const MembersTopPerformers = ({ image, badge, text }) => {
	const screenSize = useScreenSize();
	return (
		<View style={{ marginEnd: 25, marginTop: 10 }}>
			<Avatar
				style={{ backgroundColor: "#F05343" }}
				source={image ? { uri: image } : undefined}
			/>
			{/* <Image source={image ? { uri: image} : PlaceholderImageCover} style={styles.image} /> */}
			{/*<Image source={{ uri: badge }} style={styles.badge} />*/}
		</View>
	);
};

const styles = StyleSheet.create({
	image: {
		height: !isMobile ? 60 : 55,
		width: !isMobile ? 60 : 55,
		borderRadius: 35,
	},
	badge: {
		height: !isMobile ? 33 : 24,
		width: !isMobile ? 33 : 24,
		// borderRadius: 10,
		position: "absolute",
		left: !isMobile ? 53 : 43,
		top: !isMobile ? 33 : 30,
	},
	leftView:
	{
		height: 25,
		width: 25,
		borderRadius: 30,
		position: "absolute",
		backgroundColor: 'rgb(232, 232, 232)',
		top: 40,
		right: 42,
		alignItems: 'center',
		justifyContent: 'center'
	},
	text:
	{
		fontSize: 10,
		fontWeight: "500",
		color: "rgb(0, 177, 92)",
	}
});
