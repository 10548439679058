import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function downloadIcon(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M11.25 4.75H8.25V0.25H3.75V4.75H0.75L6 10L11.25 4.75ZM0.75 11.5V13H11.25V11.5H0.75Z" fill="#1B1B1B"/>
    </Svg>
  )
}

downloadIcon.propTypes = Svg.propTypes
