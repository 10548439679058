import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
const { width, height } = Dimensions.get('window')

export const Container = styled.View`
	width:${width}px;
	height: ${height}px;
	align-items: center;
	justify-content: center;
	background: rgba(0,0,0,0.4);
`
