import React from 'react'
import { Modal } from 'react-native'
import PropTypes from 'prop-types'
import { isMobile } from '@/styles'
import { Container } from './styles'

const SimpleModal = ({ isVisible, children, ...params }) => {

  if (!isMobile) {
    return (
      <Modal visible={isVisible} transparent {...params}>
        <Container>
          {children}
        </Container>
      </Modal>
    )
  }

  return (
    <Modal
      visible={isVisible}
      animationType="fade"
      statusBarTranslucent
    >
      <Container>
        {children}
      </Container>
    </Modal>
  )
}

SimpleModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default SimpleModal
