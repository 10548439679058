import React, { useCallback, useEffect, useState } from 'react'
import { Text, View, StyleSheet, Platform } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '@/redux/reducers/roles'
import { Formik } from 'formik'
import * as selectors from '@/redux/selectors/roles'
import {
  Link,
  Card,
  Role,
} from '../styles'
import { ProgressBar } from 'react-native-paper'
import { TextField } from '@/components'
import * as Yup from 'yup'
import EditRole from '@/pages/Settings/UserRoles/edit'
import ConfirmationModal from '@/components/ConfirmationModal'
import Toast from 'react-native-toast-message'


const Index = () => {
  const [role, setRole] = useState(null)
  const [isEditVisible, setEditVisible] = useState(false)
  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false)
  const [deleted, setDeleted] = useState({})
  const dispatch = useDispatch()
  const loading = useSelector(selectors.loading)
  const roles = useSelector(selectors.roles)

  useEffect(() => {
    index()
  }, [])

  const index = () => {
    dispatch(actions.index(() => { }))
  }

  const handleSubmit = useCallback((values) => {
    dispatch(actions.store(values, (error, success) => {
      Toast.show({
        type: error ? 'error' : 'success',
        position: 'bottom',
        text1: error ? error.message : success.message,
      })
      if (success) {
        editRole(success.data)
      }
    }))
  }, [])

  const handleDelete = () => {
    dispatch(actions.delete({ id: deleted.id }, (error, success) => {
      Toast.show({
        type: error ? 'error' : 'success',
        position: 'bottom',
        text1: error ? error.message : success.message,
      })
      dispatch(actions.index(() => { }))
    }))
  }

  const editRole = (role) => {
    setRole(role)
    setEditVisible(true)
  }

  const deleteRole = (role) => {
    setDeleted(role)
    setDeleteConfirmationVisible(true)
  }

  const initialValues = {
    name: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(1).max(255),
  })

  return (
    <View style={styles.container}>
      <ProgressBar indeterminate={true} visible={loading} />
      <>
        {roles.map(role => (
          <Role key={role.id}>
            <Text>{role.name}</Text>
            <View style={{ display: 'flex' }}>
              {role.company_id && (<Link
                onPress={() => { editRole(role) }}
                style={{ color: 'red' }}>
                <Text>{role.company_id ? 'Edit' : 'See'} permissions</Text>
              </Link>)}
              {role.company_id && (
                <Link style={{ color: 'red' }} onPress={() => { deleteRole(role) }}>
                  <Text>Delete Role</Text>
                </Link>)}
            </View>
          </Role>
        ))}
        <Card style={{ marginTop: 20 }}>
          <Formik
            enableReinitialize
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, isValid, handleChange, handleSubmit, }) => (
              <>
                <TextField
                  style={{ flex: 1 }}
                  value={values.name}
                  autoCapitalize="none"
                  onChangeText={handleChange('name')}
                  label='Create a custom user role' />
                <Link
                  disabled={values.name.length < 3}
                  onPress={() => handleSubmit()}><Text>Create</Text></Link>
              </>
            )}
          </Formik>
        </Card>
      </>
      <EditRole
        onClose={index}
        isVisible={isEditVisible}
        setVisible={setEditVisible}
        role={role} />
      <ConfirmationModal
        message="Are you sure you want to delete this role?"
        actionText="Confirm delete"
        setVisible={setDeleteConfirmationVisible}
        isVisible={isDeleteConfirmationVisible}
        onAction={handleDelete}
      />
    </View>
  )
}

export default Index

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    paddingRight: Platform.OS == "web" ? 30 : 0,
    flex: 1,
    width: Platform.OS !== "web" && "100%"
  },

})