import React from 'react'
import { View, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import { FontAwesome } from '@expo/vector-icons'
import { Avatar } from '@/components'
import * as S from './styles'
import { useIsMobile } from '@/styles'
import { useNavigation } from '@react-navigation/native'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

const ArticleCard = ({
  id,
  image,
  name = '',
  excerpt = '',
  creator,
  read_later,
  setReadLater,
  employee_lifecycle_stage,
  five_s_category,
  draft,
  featured,
  type,
  language_id,
}) => {
  const navigation = useNavigation()
  const isMobile = useIsMobile()
  const isDesktop = !isMobile
  const { width } = useWindowDimensions()

  const onPress = () => {
    //navigation.navigate('ViewArticle', { id })
    navigation.push('Main', {
      screen: 'ViewArticle', params: {
         id: id,
      }, key: 'viewarticle_' + id
    });
  }

  return (
    <S.Container isDesktop={isDesktop} width={width}>
      <S.Content onPress={onPress} style={{elevation: 3}}>
        <S.CoverContainer isDesktop={isDesktop}>
          <S.SaveButton onPress={setReadLater}>
            <FontAwesome
              name={read_later ? 'bookmark' : 'bookmark-o'}
              size={19}
              color={read_later ? '#fff' : '#fff'}
            />
          </S.SaveButton>
          <S.IMG
            source={image ? { uri: image } : PlaceholderImageCover}
            resizeMode="cover"
          />
        </S.CoverContainer>
        <S.Header>
        <S.Label>ARTICLE</S.Label>
          <View style={{height: 5}} />
          <S.Label>{employee_lifecycle_stage?.name}</S.Label>
          <S.Label>{five_s_category?.name}</S.Label>
          <S.Title>{name ?? ''}</S.Title>
          <S.Status>
            {canAccess(permissions.MANAGE_LEARNING_ARTICLES) ? (
              <S.PublishStatus>
                {draft ? 'Unpublished' : 'Published'}
              </S.PublishStatus>
            ) : null}

            {featured ? <S.FeatureStatus>Featured</S.FeatureStatus> : null}
          </S.Status>
        </S.Header>
        <S.OverScrollView>
          <S.Overview>{excerpt ? excerpt : ''}</S.Overview>
        </S.OverScrollView>
        <S.Footer isDesktop={isDesktop}>
          <S.AvatarContainer>
            <Avatar
              source={creator?.avatar ? { uri: creator?.avatar } : null}
              resizeMode="cover"
              size={45}
            />
          </S.AvatarContainer>
          <S.AuthorInfoContainer>
            <S.CreatorName>{`${creator?.first_name ?? ''} ${
              creator?.last_name ?? ''
            }`}</S.CreatorName>
            {/* <S.SubFooterInfo>
              <S.SubInfoText>{creator?.company?.name ?? ''}</S.SubInfoText>
            </S.SubFooterInfo> */}
          </S.AuthorInfoContainer>
        </S.Footer>
      </S.Content>
    </S.Container>
  )
}

ArticleCard.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  creator: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string,
    avatar: PropTypes.string,
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  read_later: PropTypes.any,
  employee_lifecycle_stage: PropTypes.any,
  five_s_category: PropTypes.any,
  setReadLater: PropTypes.func,
  type: PropTypes.string,
}

ArticleCard.defaultValues = {
  creator: {
    name: '',
  },
}

export default ArticleCard
