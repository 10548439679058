import styled from 'styled-components/native'
import { Dimensions, StatusBar, StyleSheet } from 'react-native'
import { Headline6 } from '@/styles/fonts'
import { TouchableOpacity } from 'react-native-gesture-handler'

const { height, width}  =Dimensions.get('window')

export const styles = StyleSheet.create({
  flatListContent: {
    width: '100%',
    paddingTop: 30,
    paddingBottom: height * 0.1,
  }
})

export const Container = styled.View`
	width: ${width}px;
	height: ${height}px;
	padding-top: 0${StatusBar.currentHeight}px;
	background-color: ${({ theme }) => theme.basics.background_white};
`

export const Header = styled.View`
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 16px 0 0;
	width: 100%;
`

export const TitleHeader = styled(Headline6)`
	color: ${({ theme }) => theme.basics.black_text};
	position: relative;
`

export const LeftIconButton = styled(TouchableOpacity)`
	margin: 0 0 0 16px;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`

export const IconButton = styled(TouchableOpacity)`
	margin: 0 16px 0 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`
