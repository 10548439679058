import ChatContext from '@/contexts/ChatContext'
import React, { useContext, useEffect, useState } from 'react'
import { Dimensions, FlatList, Text, View } from 'react-native';
import Conversation from './Conversation';
import Header from '@/components/Chat/Header';
import { useIsMobile } from '@/styles';
import { useNavigation } from '@react-navigation/native';
import { getLastMessage, getLastMessageTime, isMyMessage } from '../../helpers';
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth';
import ConversationTitleModal from '../modals/ConversationTitleModal';
import { addConversation } from '../../api';
import PageHeader from '@/components/Layout/Page/Header'

const { height } = Dimensions.get('screen');


const Conversations = () => {
    const { conversations, loadingStatus, client, addNotifications, updateCurrentConversation, updateParticipants, messages, typingData } = useContext(ChatContext);
    const isMobile = useIsMobile();
    const navigation = useNavigation();
    const user = useSelector(getMember);
    const [isModalOpen, setIsModalOpen] = useState(false);


    return (
        <View style={{ flex: 1 }}>
            {isMobile &&
                <PageHeader
                    title={'Chat'}
                    icon={'comment'}
                    backEnabled={false}
                    actions={
                        [
                            {
                                title: 'Create',
                                type: 'menu',
                                permissions: [

                                ],
                                menu: [
                                    {
                                        title: `New Conversation`,
                                        icon: 'searchIcon',
                                        permissions: [

                                        ],
                                        onPress: () => {
                                            setIsModalOpen(true)
                                        }
                                    },
                                ]
                            }
                        ]
                    }
                />
            }

            <FlatList
                showsVerticalScrollIndicator={false}
                style={{ flex: 1, paddingHorizontal: 10 }}
                data={conversations}
                ListEmptyComponent={() => (
                    <View style={{ flex: 1, minHeight: height / 1.2, justifyContent: 'center', alignItems: 'center' }}>
                        <Text style={{ fontSize: 16 }}>No Conversations Found</Text>
                    </View>
                )}
                ItemSeparatorComponent={() => (
                    <View style={{ paddingVertical: 5 }} />
                )}
                renderItem={
                    ({ item, index }) => (
                        <Conversation conversation={item}
                            lastMessage={getLastMessage(messages[item.sid] ?? [], typingData[item.sid] ?? [])}
                            lastUpdated={getLastMessageTime(messages?.[item.sid])}
                            myMessage={isMyMessage(messages?.[item.sid], user?.id?.toString())}
                        />
                    )} />
            <ConversationTitleModal
                title=""
                type="new"
                isModalOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onSave={async (title) => {
                    try {
                        const convo = await addConversation(
                            title,
                            updateParticipants,
                            client,
                            addNotifications
                        );
                        updateCurrentConversation(convo.sid);
                        setIsModalOpen(false);
                        navigation.navigate('messages');
                    } catch (error) {
                        console.log(error);
                    }
                }}
            />
        </View>
    )
}

export default Conversations;