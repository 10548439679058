import React from 'react'
import { TouchableOpacity } from 'react-native-gesture-handler'
import PropTypes from 'prop-types'
import {
  SocialContainer,
  SocialText,
  SocialIcon,
  SocialIconsContainer,
  RedText,
} from './styles'
import MicrosoftIcon from '@/assets/MicrosoftButton.png'
import SlackIcon from '@/assets/SlackButton.png'
import GoogleIcon from '@/assets/GoogleButton.png'

const SocialMediaContainer = ({ navigate, text, linkText }) => {
  return (
    <SocialContainer>
      <SocialText>Or login with these social profile:</SocialText>
      <SocialIconsContainer>
        <TouchableOpacity>
          <SocialIcon source={GoogleIcon} />
        </TouchableOpacity>
        <TouchableOpacity>
          <SocialIcon source={SlackIcon} />
        </TouchableOpacity>
        <TouchableOpacity>
          <SocialIcon source={MicrosoftIcon} />
        </TouchableOpacity>
      </SocialIconsContainer>
      <SocialText>
        {text}
        <RedText onPress={() => navigate()}>{linkText}</RedText>
      </SocialText>
    </SocialContainer>
  )
}

SocialMediaContainer.propTypes = {
  navigate: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
}

export default SocialMediaContainer
