import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'
import themes from '@/styles/themes/black'
import { shadow1 } from '@/styles/shadows'
import { Headline6Strong } from '@/styles/fonts'

export const styles = StyleSheet.create({
  gradiente: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 67,
    zIndex: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  }
})

export const Container = styled.TouchableOpacity`
	position: relative;
	width:  ${({ isMobile }) => isMobile ? 393 : 517}px;
	align-self: center;
	align-items: center;
	margin-left: ${({ isMobile }) => isMobile ? 10 : 8}px;
	margin-right: ${({ isMobile }) => isMobile ? 10 : 8}px;
	margin-top: ${({ isMobile }) => isMobile ? 16 : 20}px;
	margin-bottom: ${({ isMobile }) => isMobile ? 30 : 20}px;
	border-radius: 5px;
	border:1px solid ${themes.basics[300]};
	background-color: ${themes.basics.background_white};
	padding: 16px;
	${shadow1}
`

export const CoverContainer = styled.View`
	width: 100%;
	position: relative;
`

export const RowContainer = styled.View`
	width: 100%;
  flex-direction: row;
  align-items: center;
	position: relative;
`

export const MemberContainer = styled.View`
	width: 100%;
	position: relative;
`

export const Title = styled(Headline6Strong)`
	align-items: center;
	color: ${themes.basics[800]};
`

export const FakeInput = styled.View`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 16px;
  padding-right: 8px;
  padding-bottom: 7px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.basics[500]};
`

export const BackButton = styled.TouchableOpacity`
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
  margin-right: 8px;
`

