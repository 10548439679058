import React from 'react'
import { Modal,Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import { isMobile } from '@/styles'
import { Container } from './styles'

const { height,width } = Dimensions.get('window')

const SimpleModal = ({ isVisible, children, ...params }) => {

  if (!isMobile) {
    return (
      <Modal visible={isVisible} transparent {...params}>
        <Container>
          {children}
        </Container>
      </Modal>
    )
  }

  return (
    <Modal
    swipeDirection="down"
   
      transparent
      visible={isVisible}
      animationType="fade"
      //statusBarTranslucent
    >
      <Container   style={{ justifyContent: 'flex-end', margin: 0,zIndex:11 }}>
        {children}
      </Container>
    </Modal>
  )
}

SimpleModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default SimpleModal
