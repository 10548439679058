import request from '@/helpers/request'


export async function fetchReadNotifList() {
  return await request('notifications/retrieve/read', {
    method: 'get',
  })
}
export async function fetchUnReadNotifList() {
  return await request('notifications/retrieve/unread', {
    method: 'get',
  })
}