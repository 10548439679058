import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Avatar } from '@/components'
import { Paragraph1 } from '@/styles/fonts'
import theme from '@/styles/themes/black'
import { CreatePostWrapper, FakeInput } from './styles'
import { ScreenSize, useScreenSize } from '@/styles' 

const AvatarWriteButton = ({
  text,
  width,
  avatarSource,
  avatarSize,
  onPress
}) => {
const screenSize = useScreenSize()

  return (
    <CreatePostWrapper screenSize={screenSize} onPress={onPress} width={width}>
      <Avatar size={avatarSize} elevation={2} source={avatarSource ? {uri : avatarSource}: null} />
      <FakeInput>
        <Paragraph1 color={theme.basics[600]}>{text}</Paragraph1>
        <Icon icon="send" width={22} />
      </FakeInput>
    </CreatePostWrapper>
  )
}

AvatarWriteButton.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.number,
  avatarSource: PropTypes.string,
  avatarSize: PropTypes.any,
  onPress: PropTypes.func.isRequired
}

export default AvatarWriteButton
