import styled from 'styled-components/native'
import { Dimensions, TouchableOpacity } from 'react-native'
import { shadow1 } from '@/styles/shadows'
import { isMobile } from '@/styles'
import themes from '@/styles/themes/black'
import { Headline6Strong, Subtitle2, Paragraph2, Caption1 } from '@/styles/fonts'
import { vw } from 'react-native-expo-viewport-units' 
import { ScreenSize, useScreenSize } from '@/styles' 

const { width } = Dimensions.get('window')

export const Container = styled.TouchableOpacity`
	position: relative;
	width: ${isMobile ? width * 0.9 : !isMobile && width >= ScreenSize.Medium ? 521 : !isMobile && width <= ScreenSize.Medium && width * 0.73}px;
	margin: 10px 0;
	border-radius: 5px;
  	background: ${themes.basics.background_white};
  	${shadow1}
	z-index: -1;
`
export const Image = styled.Image`
	width: 100%;
	height: ${isMobile ? width * 0.9 * 0.53 : 276}px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`

export const ContentCard = styled.View`
  padding: 0 16px 0;
`

export const Title = styled(Subtitle2)`
	align-items: center;
	color: #F05343;
	margin-top: 16px;
`

export const Header = styled(Headline6Strong)`
	align-items: center;
	color: ${themes.basics.black_text};
	margin-top: 16px;
`

export const Excerpt = styled.Text`
	margin-top: 16px;
	padding-bottom: 15px;
  	border-bottom-width: ${!isMobile ? 1 : 0}px;
	border-bottom-color: ${!isMobile ? '#D9D9D9' : 'transparent'};
`

export const Divider = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.basics[300]};
`

export const AuthorContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px;
  position: relative;
  width: 100%;
`

export const AuthorInfoContainer = styled.View`
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
`

export const ButtonNavigation = styled.TouchableOpacity``

export const TimeAgo = styled(Paragraph2)`
	color: ${({ theme }) => theme.basics[400]};
	padding: 0 0 2px;
	margin: 10px 9px 0 0;
`

export const SaveButton = styled(TouchableOpacity)`
	z-index: 1;
	position: absolute;
	right: 16px;
	top: 16px;
	width: 30px;
	height: 30px;
	background: #1b1b1ba1;
    border-top-left-radius: 50;
	border-top-right-radius: 50;
	border-bottom-left-radius: 50;
	border-bottom-right-radius: 50;
	align-items: center;
	justify-content: center;
`

export const EventDateView = styled.View`
	z-index: 1;
	position: absolute;
	left: 16px;
	top: 16px;
	width: 75px;
	height: 24px;
	background: ${themes.support.optional_2};
    border-radius: 3px;
	align-items: center;
	justify-content: center;
`

export const EventDate = styled.Text`
    font-family: 'WorkSans_400Regular';
    font-size: 12px;
    line-height: 16px;
    color: ${themes.basics.background_white}
`

export const kebabContainer = styled.View`
`

export const MoreVert = styled.TouchableOpacity`
  height: 24px;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  opacity: 100;
`

export const ContentCard1 = styled(Paragraph2)`
  align-items: center;
  justify-content: center;
  color: ${themes.basics.black_text};
  padding: 0 16px 0;
`

export const InteractionsContainer = styled.View`
  padding: 8px 16px 0;
  flex-direction: row;
  justify-content: space-between;
	z-index: -1;

`

export const ButtonLinkGray = styled(Paragraph2)`
  color: ${themes.basics[500]};
`

export const LikeLove = styled.View`
  padding: 3px 5px 0;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-bottom: 14px;
  border-radius: 90px;
  background-color: ${themes.basics.background_button};
  padding-left: 10px;
  padding-right: 10px;
`

export const Like = styled(Caption1)`
  margin-right: 5px;
  color: ${themes.support.standard_high};
`

export const LikeCommentsContainer = styled.View`
	flex-direction: row;
	align-items: center;
	height: 40px;
	width: 100%;
	border-top-width: 1px;
	border-top-color: #D9D9D9;
	border-bottom-width: ${({ commentsCount, isCommentsVisible }) => commentsCount > 0 && isCommentsVisible && !isMobile ? 1 : 0}px;
	border-bottom-color: ${({ commentsCount, isCommentsVisible }) => commentsCount > 0 && isCommentsVisible && !isMobile ? '#D9D9D9' : 'transparent'};
	z-index: -1;

`

export const ActionButton = styled.TouchableOpacity`
`

export const ActionButtonContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.basics.background_white};
`

export const SectionAction = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
`

export const ActionButtonText = styled(Paragraph2)`
	color: ${({ theme, like }) => like ? theme.info.standard : theme.basics.brand};
	align-self: flex-end;
	margin: 0 0 0 10px;
`
