import styled from "styled-components/native";
import { StyleSheet, Dimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { isMobile } from "@/styles";
import { Headline6 } from "@/styles/fonts";
import { ScreenSize, useScreenSize } from "@/styles";
const { height, width } = Dimensions.get("window");
// const screenSize=useScreenSize()
export const styles = StyleSheet.create({
  keyboardAvoid: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  textInput: {
    flex: 1,
    fontSize: 15,
  },
  headerView: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  createPost: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  postBtn: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 7,
    // width: isMobile ? "20%" :!isMobile && width <=ScreenSize.Medium ? "20%": !isMobile && "auto",
    height: 40,
    // alignSelf: isMobile ? "flex-end" : !isMobile && width <=ScreenSize.Medium ? "flex-end" : "center",
  },
  btnText: {
    fontSize: 17,
    color: "white",
  },
  headerLine: { backgroundColor: "grey", height: 1, marginVertical: 8 },
  ExpMenu: { position: "absolute", right: 10, top: 90 },
});

export const Container = styled(SafeAreaView)`
	flex: 1;
	height: 100%;
	${isMobile &&
  `
  display: flex;
  align-items: center;
  justify-content: center;
  `}
`;

export const KeyboardDismiss = styled.TouchableWithoutFeedback``;

export const ContentContainer = styled.View`
	flex: 1;
	background-color: ${({ theme }) => theme.basics.background_white};
	padding: 15px;
	width: '100%',
`;

export const HeaderContainer = styled.View``;

export const HeaderLabel = styled(Headline6)`
	font-size: ${isMobile ? 18 : 24}px;
`;

export const AuthorContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	margin-bottom: 10px;
`;

export const AuthorInfoContainer = styled.View`
	flex: 1;
	display: flex;
	margin-left: 10px;
	flex-direction: column;
	justify-content: center;
`;

export const CloseButton = styled.TouchableOpacity`
	margin-top: 13px;
	align-self: flex-end;
`;

export const PostInputWrapper = styled.View`
	flex-grow: 1;
	flex-shrink: 1;
  min-height: 140px;
`;

export const PostButtonWrapper = styled.View`
	padding: 15px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${isMobile ? "center" : "flex-end"};
`;
export const PostButtonWrapperAttachment = styled.View`
	padding: 1px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: ${isMobile ? "center" : "flex-end"}; */
	justify-content: flex-end;
`;
