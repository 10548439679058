import React, { useEffect, useState } from 'react'
import { actions } from '@/redux/reducers/article'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from '@/styles'
import * as selectors from '@/redux/selectors/article'
import {
  Container,
  styles
} from './styles'
import { ScrollView, View, Platform } from 'react-native'
import { ProgressBar } from 'react-native-paper'
import Configuration from './Configuration';
import UserRoles from './UserRoles/index'
import Branding from './Branding/index';
import Integrations from './Integrations';
import TabsHeader from '@/pages/Settings/TabsHeader'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import Notifications from './Notifications'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const Index = () => {
  const dispatch = useDispatch()
  const loading = useSelector(selectors.loading)

  const [tab, setTab] = useState('configuration')

  useEffect(() => {
    dispatch(actions.dataprovider(() => { }))

    if (Platform.OS == "web") {
      setTab('integration')
    } else {
      setTab('integration')
    }

  }, [])

  return (
    <>
      <PageHeader
        title={'Settings'}
        icon={'settings'}
        backEnabled={false}
      />

      <PageContent>
        <TabsHeader setTab={setTab} tab={tab} />

        <ContentBlockV1
          title={tab == 'configuration' ? 'Configuration' : tab == 'integration' ? 'Integrations' : tab == 'notifications' ? 'Notifications' : tab == 'branding' ? 'Branding' : '-'}
        >
          {
            (
              tab === 'configuration') && (<Configuration tab={tab} setTab={setTab} />
            )
          }
          {/* {

            canAccess(permissions.MANAGE_CUSTOM_USER_ROLE) && tab === 'userRoles' && (
              <UserRoles tab={tab} setTab={setTab} />
            )
          } */}
          {
            (
              Platform.OS === 'web' && canAccess(permissions.MANAGE_BRANDING) && tab === 'branding') && (
              <Branding tab={tab} setTab={setTab} />
            )
          }

          {
            (tab === 'integration') && (
              <Integrations tab={tab} setTab={setTab} />
            )
          }
          {
            (
              tab === 'notifications') && (<Notifications tab={tab} setTab={setTab} />
            )
          }
        </ContentBlockV1>
      </PageContent>
    </>
  )
}

export default Index
