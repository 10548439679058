import styled from 'styled-components'
import theme from '@/styles/themes/black'


export const UploadImageContainer = styled.div`
	display: flex;
	min-height: 250px;
	border-radius: 5px;
	background: #E7E7E7;
	align-items: center;
	justify-content: center;
	flex-direction: column;
  font-family: 'WorkSans_400Regular';

	.main {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 80%;
		height: 200px;
		border:1px dashed ${({ isDragging }) => isDragging ? '#333' : 'transparent'};
		margin: 0 40px 20px;

		> span {
			font-size: 14px;
			margin: 10px;
		}
	}

	.imgs {
		border: 1px solid ${({ hasImg }) => hasImg ? '#fff' : 'tranparent'};
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-columns: repeat(3, 200px);
		row-gap: 15px;
		justify-content: space-around;
		padding: 10px;
		width: 100%;
	}
`

export const CreateButton = styled.button`
	background-color: ${({ bg }) => bg ? bg : theme.primary.standard};
	color: #FFF;
	width: 100%;
	max-width: 400px;
	height: 40px;
	align-self: center;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	border:none;
	border-radius: 3px;
	cursor: pointer;
	transition: 500ms;
	margin: 5px 0 0;

	:active {
		transition: 250ms;
		opacity: 0.4;
	}
`

export const SaveButton = styled.button`
	background-color: ${theme.primary.standard};
	opacity: ${({ disabled }) => disabled ? 0.8 : 1};
	color: #FFF;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	border-radius: 3px;
	cursor: pointer;
	transition: 500ms;

	:active {
		transition: 250ms;
		opacity: 0.4;
	}
`

export const SaveButtonText = styled.p`
	color:${theme.basics.background_white};
  font-family: 'WorkSans_400Regular';
	font-weight: 700;
	font-size: 18px;
`

export const CreateButtonText = styled.p`
color: ${({ color }) => color ? color : theme.basics.background_white};
  font-family: 'WorkSans_400Regular';
	font-weight: 700;
	margin: 0 10px 0 0;
	font-size: 18px;
`

export const ImageContainer = styled.div`
	position: relative;
	width: 200px;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.img-container{
		position: relative;
		> img {
			object-fit: contain;
			width: 150px;
			height: 150px;
		}
		.loading {
			position: absolute;
			top:0;
			left: 0;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: rgba(255,255,255, 0.4);
			opacity: ${({ disabled }) => disabled ? 1 : 0};
		}
	}

	> button:first-child {
			display: flex;
			align-self: flex-end;
			transition: 500ms;
			cursor: pointer;
			border: none;
			background: transparent;
			:hover {
				cursor: pointer;
			}
			:active {
				transition: 250ms;
				opacity: 0.5;
			}
		}

`

export const SavedImageContainer = styled.div`
	position: relative;
	width: 200px;
	min-height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	> button {
		align-self: flex-end;
	}
	> div {
		display: flex;
		width: 100%;
		justify-content: center;
		align-items: center;
	}
	> span {
		font-family: 'WorkSans_400Regular';
	}
	button {
		cursor: pointer;
		border: none;
		transition: 500ms;
		color: ${theme.primary.standard};
		font-family: 'WorkSans_400Regular';
		background-color: transparent;
		margin: 0 5px;
		:active {
			opacity: 0.5;
			transition: 250ms;
		}
	}
	> img {
			object-fit: contain;
			width: 150px;
			height: 150px;
		}

	.url{
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
		width: 100%;
		height: 100%;
		overflow: scroll;
		background-color: rgba(0,0,0,0.5);


		> button {
			cursor: pointer;
			border: none;
			background-color: transparent;
			transition: 500ms;
			color: ${theme.primary.standard};
			font-family: 'WorkSans_400Regular';
			margin: 0 5px;
			align-self: flex-end;
			:active {
				opacity: 0.5;
				transition: 250ms;
			}
		}
		> span {
			background-color: #fff;
			padding: 5px 10px;
			word-break: break-all
		}
	}

	.modal {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 2;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		> div {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-color: #fff;
			height: 80%;
			width: 95%;
			> span {
					text-align: center;
				}
			> div {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;

				> button {
					margin: 10px 5px 0;
					font-size: 16px
				}

			}
		}
	}
`
