import React, { useState, useEffect } from "react";
import {
    View,
    Text,
    Image,
    StyleSheet,
    ScrollView,
    TouchableOpacity,
    TextInput,
    FlatList,
    Dimensions,
    CheckBox,
    ActivityIndicator,
    Platform
} from "react-native";
import {
    MilestoneComponent,
    OrderComponent,
    RewardDetailsComponent,
} from "../components/MilestoneComponent";
import { isMobile } from "@/styles";
import { Icon } from "@/components";
import Modal from "react-native-modal";
import ApiHandler from "@/api/ApiHandler";
import { Ionicons } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
const deviceHeight = Dimensions.get("window").height;
import { ActiveCard } from "../components/ActiveCard";
import CalendarPicker from "react-native-calendar-picker";
import { TopPerformers } from "../components/TopPerformers";
import ManagerOrderModal from "../components/ManagerOrderModal";
import CreateRewardModal from "../components/CreateRewardModal";
import ManagerRewardModal from "../components/ManagerRewardModal";
import CreateMilestoneModal from "../components/CreateMilestoneModal";
import ManagerMilestoneModal from "../components/ManagerMilestoneModal";
import SendManualRewardModal from "../components/SendManualReward";

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ModalV2 from '@/components/ModalV2'

import Milestone from '../components/Milestone'

const members = [
    {
        id: 1,
        photo: require("@/assets/Article.png"),
        name: "Angela Schrute",
        role: "CEO | Technology",
        updateDate: "last update 26 weeks ago",
        value: false,
    },
    {
        id: 2,
        photo: require("@/assets/Article.png"),
        name: "Bangela Schrute",
        role: "CEO | Technology",
        updateDate: "last update 26 weeks ago",
        value: false,
    },
    {
        id: 3,
        photo: require("@/assets/Article.png"),
        name: "Angela Schrute",
        role: "CEO | Technology",
        updateDate: "last update 26 weeks ago",
        value: false,
    },
    {
        id: 4,
        photo: require("@/assets/Article.png"),
        name: "Zangela Schrute",
        role: "CEO | Technology",
        updateDate: "last update 26 weeks ago",
        value: false,
    },
    {
        id: 5,
        photo: require("@/assets/Article.png"),
        name: "Aabbb Schrute",
        role: "CEO | Technology",
        updateDate: "last update 26 weeks ago",
        value: false,
    },
];

const selectRewards = [
    {
        id: 1,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 2,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 3,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 4,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 5,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 6,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 5,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
    {
        id: 6,
        photo: require("@/assets/Article.png"),
        title: "$10 Gift card",
        description:
            "Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
        awarded: "24 Awardees",
        imported: "Imported",
        value: false,
    },
];

const Management = ({ Order, navigation }) => {
    const [isMilestonesModalVisible, setMilestonesModalVisible] = useState(false);
    const [isRewardsModalVisible, setRewardsModalVisible] = useState(false);
    const [isOrdersModalVisible, setOrdersModalVisible] = useState(false);

    const [isModalVisible, setModalVisible] = useState(false);
    const [isRewardModalVisible, setRewardModalVisible] = useState(false);
    const [isMilestoneModalVisible, setMilestoneModalVisible] = useState(false);
    const [rewardsData, setRewardsData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [rewardOrders, setRewardOrders] = useState([]);
    const [getMilestone, setGetMilestone] = useState([]);
    const [workSpaceId, setWorkSpaceId] = useState([1]);
    const [isMonth, setMonth] = useState(false);
    const [isCalender, setCalender] = useState(false);
    const [milestoneListModal, setMilestoneListModal] = useState(false);
    const [rewardListModal, setRewardListModal] = useState(false);
    const [orderListModal, setOrderListModal] = useState(false);
    const [isImportRewardModalVisible, setImportRewardModalVisible] =
        useState(false);
    const [topPerformer, setTopPerformer] = useState([]);
    const [lastTopPerformer, setLastTopPerformer] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState("");
    const [selectedEndDate, setSelectedEndDate] = useState("");
    const [loading, setLoading] = useState(false);

    const toggleMilestonesModal = () => {
        setMilestonesModalVisible(!isMilestonesModalVisible);
    };

    const toggleRewardsModal = () => {
        setRewardsModalVisible(!isRewardsModalVisible);
    };

    const toggleOrdersModal = () => {
        setOrdersModalVisible(!isOrdersModalVisible);
    };

    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };
    const toggleRightModal = () => {
        setMilestoneModalVisible(!isMilestoneModalVisible);
    };
    const toggleRewardModal = () => {
        setRewardModalVisible(!isRewardModalVisible);
    };
    const toggleImportRewardModal = () => {
        setImportRewardModalVisible(!isImportRewardModalVisible);
    };

    const toggleCalender = () => {
        setCalender(!isCalender);
        setShowDropDown(false);
    };

    const toggleOrderModal = () => {
        setOrderListModal(!orderListModal);
    };
    const toggleMilestoneListModal = () => {
        setMilestoneListModal(!milestoneListModal);
    };
    const toggleRewardListModal = () => {
        setRewardListModal(!rewardListModal);
    };

    // useEffect(() => {
    // 	getLatestRewards();
    // 	getRewardActivity();
    // 	getRewardOrders();
    // 	getLatestMilestone();
    // 	getTopPerformer();
    // 	getLastTopPerformer();
    // }, []);

    React.useEffect(() => {
        const unsubscribe = navigation.addListener("focus", () => {
            // The screen is focused
            // Call any action
            getLatestRewards();
            getRewardActivity();
            getRewardOrders();
            getLatestMilestone();
            getTopPerformer();
            getLastTopPerformer();
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [
        navigation,
        isModalVisible,
        isRewardModalVisible,
        isMilestoneModalVisible,
    ]);

    const getLatestRewards = async () => {
        setLoading(true);
        try {
            let queryParams = "";
            queryParams = `workspace_id=${workSpaceId}&&latest=${true}`;
            const response = await new ApiHandler().getLatestRewards(queryParams);
            if (response?.data) {
                setRewardsData(response?.data?.data);
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };
    const getRewardActivity = async () => {
        setLoading(true);
        try {
            let queryParams = "";
            if (activityFilter == "custom") {
                queryParams = `timeframe=${activityFilter}&&start_date=${selectedStartDate}&&end_date=${selectedEndDate}`;
            } else {
                queryParams = `timeframe=${activityFilter}`;
            }
            const response = await new ApiHandler().getRewardActivities(queryParams);
            // console.log("********getActivityResponse****", response?.data?.data);
            if (response?.data) {
                setActivityData(response?.data?.data);
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };
    const getRewardOrders = async () => {
        setLoading(true);
        try {
            const queryParams = `latest=${true}`;
            const response = await new ApiHandler().getRewardOrders(queryParams);
            // console.log("********getRewardOrder Response****", response?.data?.data);
            setRewardOrders(response?.data?.data);
            setLoading(false);
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };
    const getLatestMilestone = async () => {
        setLoading(true);
        try {
            let latest = `latest=${true}`;
            const response = await new ApiHandler().getLatestMilestones(latest);
            console.log("********getMilestone Response****", response?.data?.data);
            if (response?.data) {
                setGetMilestone(response?.data?.data);
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };

    const getTopPerformer = async () => {
        try {
            let queryParams = `timeframe=current`;
            const response = await new ApiHandler().getTopPerformers(queryParams);
            // console.log("********getLeaderBoard Response****", response?.data?.data);
            if (response?.data) {
                setTopPerformer(response?.data?.data);
            }
        } catch (error) {
            console.log("ERROR", error);
        } finally {
        }
    };

    const getLastTopPerformer = async () => {
        try {
            let queryParams = `timeframe=previous_month`;
            const response = await new ApiHandler().getLastTopPerformers(queryParams);
            // console.log(
            // 	"******** LAST     getLeaderBoard Response****",
            // 	response?.data?.data
            // );
            if (response?.data) {
                setLastTopPerformer(response?.data?.data);
            }
        } catch (error) {
            console.log("ERROR", error);
        } finally {
        }
    };

    const ImportRewardModal = () => {
        const [activeTab, setActiveTab] = useState("import");
        const [arrayData, setArrayData] = useState(selectRewards);
        return (
            <View style={{}}>
                <Modal
                    isVisible={isImportRewardModalVisible}
                    scrollVertical="true"
                    propagateSwipe={true}
                    animationIn={"slideInRight"}
                    animationOut={"slideOutRight"}
                    deviceHeight={deviceHeight}
                    style={[styles.rightSideModal, { width: "48%" }]}
                >
                    <View style={{ flex: 1 }}>
                        <ScrollView scrollVertical>
                            <View style={{ paddingHorizontal: 25 }}>
                                <TouchableOpacity
                                    onPress={toggleImportRewardModal}
                                    style={styles.closeBtn}
                                >
                                    <Ionicons name="close-outline" size={35} />
                                </TouchableOpacity>
                                <View>
                                    <Text style={styles.rewardModalTitle}>
                                        {"Create A Reward For Your Workspace"}
                                    </Text>
                                    <Text style={styles.rewardModalDes}>
                                        Create rewards within your workspace's reward library, which are then available for rewarding to your community members.
                                    </Text>
                                </View>
                                <View style={styles.rewardTabView}>
                                    <TouchableOpacity
                                        onPress={() => setActiveTab("create")}
                                        style={[
                                            styles.rewardTab,
                                            {
                                                borderBottomColor:
                                                    activeTab === "create" ? "#FB6758" : "#E8E8E8",
                                            },
                                        ]}
                                    >
                                        <Text style={styles.rewardBtnTxt}>{"Create A Reward"}</Text>
                                    </TouchableOpacity>
                                    {/*
									<TouchableOpacity
										onPress={() => setActiveTab("import")}
										style={[
											styles.rewardTab,
											{
												borderBottomColor:
													activeTab === "import" ? "#FB6758" : "#E8E8E8",
											},
										]}
									>
										<Text>{"Import From the Community"}</Text>
									</TouchableOpacity>
									*/}
                                </View>
                                {activeTab === "create" && (
                                    <>
                                        <View style={styles.imgContainer}>
                                            <View style={{ flex: 0.45 }}>
                                                <Text style={styles.titleText}>{"Title"}</Text>
                                                <View style={styles.giftCard}>
                                                    <Text style={styles.giftCardTxt}>
                                                        {"i.e $10 Starbucks Gift Card"}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ flex: 0.45 }}>
                                                <Text style={styles.titleText}>{"Upload image"}</Text>
                                                <View style={[styles.giftImgCard]}>
                                                    <Text
                                                        style={[styles.giftCardTxt, { color: "#FB6758" }]}
                                                    >
                                                        {"Upload"}{" "}
                                                        <Text style={styles.imgSizeTxt}>
                                                            (2mb file, png, jpeg)
                                                        </Text>
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ marginVertical: 20 }}>
                                            <Text style={styles.titleText}>Description</Text>
                                            <View style={styles.rewardTxtInput}>
                                                <TextInput
                                                    placeholder="Provide a description of this reward."
                                                    placeholderTextColor="#989898"
                                                    multiline
                                                    numberOfLines={5}
                                                />
                                            </View>
                                        </View>
                                    </>
                                )}
                                {activeTab === "import" && (
                                    <>
                                        <View style={{ marginBottom: "8%" }}>
                                            <FlatList
                                                indicatorStyle={{ backgroundColor: "#F05343" }}
                                                numColumns={2}
                                                data={selectRewards}
                                                renderItem={({ item, index }) => {
                                                    return (
                                                        <View style={styles.importView}>
                                                            <View
                                                                style={[
                                                                    styles.selectedContainer,
                                                                    styles.rewardModalSelectionList,
                                                                ]}
                                                            >
                                                                <View style={styles.selectedCardView}>
                                                                    <View style={styles.selectedCardHeader}>
                                                                        <CheckBox
                                                                            color={"#F05343"}
                                                                            style={{
                                                                                alignSelf: "center",
                                                                                marginEnd: 5,
                                                                            }}
                                                                            onValueChange={() => {
                                                                                let array = [...arrayData];
                                                                                array[index].value =
                                                                                    !array[index].value;
                                                                                setArrayData(array);
                                                                                for (
                                                                                    let i = 0;
                                                                                    i < arrayData.length;
                                                                                    i++
                                                                                ) {
                                                                                    if (i == index)
                                                                                        arrayData[i].value = true;
                                                                                }
                                                                            }}
                                                                            value={item.value}
                                                                        />
                                                                        <Image
                                                                            source={item.photo}
                                                                            style={styles.cardImg}
                                                                        />
                                                                        <Text style={styles.selectedCardTitle}>
                                                                            {item.title}
                                                                        </Text>
                                                                    </View>
                                                                </View>
                                                                <View style={{ paddingHorizontal: 20 }}>
                                                                    <Text style={styles.selectedCardDes}>
                                                                        {item.description}
                                                                    </Text>
                                                                    <View style={styles.cardBottom}>
                                                                        <Text style={styles.awardTxt}>
                                                                            {item.awarded}
                                                                        </Text>
                                                                        <Text style={styles.ipmTxt}>
                                                                            {item.imported}
                                                                        </Text>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    );
                                                }}
                                            />
                                        </View>
                                    </>
                                )}
                            </View>
                        </ScrollView>
                        {activeTab === "create" && (
                            <View style={styles.bottomView}>
                                {/* <Text style={styles.saveCreateTxt}>
									{"Save & Create another"}
								</Text> */}
                                <View
                                    style={{
                                        alignItems: "center",
                                    }}
                                >
                                    <TouchableOpacity style={styles.rewardModalBtn}>
                                        <Text style={styles.createTxt}>{"Create"}</Text>
                                    </TouchableOpacity>
                                    <Text style={styles.saveCreateTxt}>{"Send as a Reward"}</Text>
                                </View>
                            </View>
                        )}
                        {activeTab === "import" && (
                            <TouchableOpacity style={styles.workspaceBtn}>
                                <Text style={styles.createTxt}>{"Add To Workspace"}</Text>
                            </TouchableOpacity>
                        )}
                    </View>
                </Modal>
            </View>
        );
    };
    const MemberCalenderModal = () => {
        const [selectedStartDate, setSelectedStartDate] = useState("");
        const [selectedEndDate, setSelectedEndDate] = useState("");
        const onDateChange = (date, type) => {
            if (type === "END_DATE") {
                setSelectedEndDate(date?.utc().format());
                // toggleCalender();
            } else {
                setSelectedStartDate(date?.utc().format());
            }
        };

        const getRewardActivity = async () => {
            try {
                let queryParams = "";
                if (activityFilter == "custom") {
                    queryParams = `timeframe=${activityFilter}&&start_date=${selectedStartDate}&&end_date=${selectedEndDate}`;
                } else {
                    queryParams = `timeframe=${activityFilter}`;
                }
                const response = await new ApiHandler().getRewardActivities(
                    queryParams
                );
                console.log("********getActivityResponse****", response?.data?.data);
                if (response?.data) {
                    setActivityData(response?.data?.data);
                }
            } catch (error) {
                console.log("ERROR", error);
            } finally {
            }
        };

        return (
            <View>
                <Modal
                    isVisible={isCalender}
                    backdropOpacity={0.4}
                    animationIn="slideInUp"
                    animationOut="slideOutDown"
                    style={{ alignSelf: "center" }}
                >
                    <View style={{ flex: 1 }}>
                        <TouchableOpacity
                            onPress={toggleCalender}
                            style={styles.calenderView}
                        >
                            <CalendarPicker
                                height={isMobile ? 340 : 380}
                                width={isMobile ? 340 : 350}
                                startFromMonday={true}
                                allowRangeSelection={true}
                                allowBackwardRangeSelect={true}
                                todayBackgroundColor="#000"
                                todayTextStyle={{ color: "#fff" }}
                                selectedDayColor="#2D91F9"
                                selectedDayTextColor="#fff"
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                                onDateChange={onDateChange}
                            />

                            <View style={styles.calender}>
                                <TouchableOpacity
                                    onPress={toggleCalender}
                                    style={styles.cancelBtnView}
                                >
                                    <Text style={styles.cancelCalenderBtn}>Cancel</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        getRewardActivity();
                                        toggleCalender();
                                    }}
                                    style={styles.applyBtnView}
                                >
                                    <Text style={styles.applyTxt}>Apply</Text>
                                </TouchableOpacity>
                            </View>
                        </TouchableOpacity>
                    </View>
                </Modal>
            </View>
        );
    };

    const MilestonesModal = () => {
        const [milestones, setMilestones] = useState([]);
        const [loading, setLoading] = useState(false);

        const getMilestone = async () => {
            setLoading(true);
            try {
                const response = await new ApiHandler().getMilestones();
                if (response?.data) {
                    setMilestones(response?.data?.data?.data);
                    setLoading(false);
                }
            } catch (error) {
                console.log("ERROR", error);
                setLoading(false);
            } finally {
            }
        };

        useEffect(() => {
            getMilestone();
        }, []);

        return (
            <View>
                <ModalV2
                    isVisible={isMilestonesModalVisible}
                    setVisibility={toggleMilestonesModal}
                    title={'Milestones'}
                >
                    <View>
                        <FlatList
                            data={milestones}
                            renderItem={({ item, index }) => {
                                return (
                                    <View style={{ marginTop: 3 }}>
                                        <Milestone
                                            id={item?.id}
                                            type={'management'}
                                            name={item?.name}
                                            description={item?.description}
                                            criteria={item?.criteria_type_label}
                                        />
                                    </View>
                                );
                            }}
                            keyExtractor={(item) => item.id}
                        />
                    </View>
                </ModalV2>
            </View>
        );
    };

    const RewardsModal = () => {
        const [rewardsData, setRewardsData] = useState([]);
        const [workSpaceId, setWorkSpaceId] = useState([1]);
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            getRewards();
        }, []);

        const getRewards = async () => {
            setLoading(true);
            try {
                let queryParams = "";
                queryParams = `workspace_id=${workSpaceId}`;
                const response = await new ApiHandler().getRewards(queryParams);
                console.log("RESPONSE OF SEE ALL MILESTONES", response?.data?.data);
                if (response?.data) {
                    setRewardsData(response?.data?.data?.data);
                    setLoading(false);
                }
            } catch (error) {
                console.log("ERROR", error);
                setLoading(false);
            } finally {
            }
        };

        return (
            <ModalV2
                title={'Rewards'}
                isVisible={isRewardsModalVisible}
                setVisibility={toggleRewardsModal}
            >
                <FlatList
                    vertical
                    // data={rewardOrders}
                    data={rewardsData}
                    renderItem={({ item, index }) => {
                        return (
                            <RewardDetailsComponent
                                width={'100%'}
                                background="#FFFFFF"
                                profileImg={item?.image}
                                title={item?.title}
                                description={item?.description}
                                number_of_distributions={item?.reward_orders?.length}
                                index={index}
                                rewardId={item?.id}
                            />
                        );
                    }}
                    keyExtractor={(item) => item.id}
                />
            </ModalV2>
        )
    };

    const OrdersModal = ({ orders }) => {
        return (
            <ModalV2
                title={'Orders'}
                isVisible={isOrdersModalVisible}
                setVisibility={toggleOrdersModal}
            >
                <FlatList
                    vertical
                    data={orders}
                    renderItem={({ item, index }) => {
                        return (
                            <OrderComponent
                                width={"100%"}
                                background="#FFFFFF"
                                id={item?.id}
                                order_id={item?.order_id}
                                status={item?.status_taxonomy?.default_label}
                            />
                        )
                    }}
                    keyExtractor={item => item.id}
                />
            </ModalV2>
        )
    };

    const [showDropDown, setShowDropDown] = useState(false);
    const [activityFilter, setActivityFilter] = useState("week");

    const onChangeActivityFilter = (filter) => {
        setActivityFilter("");
        setActivityFilter(filter);
        setShowDropDown(false);
        if (activityFilter == "custom") {
            toggleCalender();
        } else {
            getRewardActivity();
        }
    };

    return (
        <>
            <PageHeader
                title={'Rewards Management'}
                icon={'rewards'}
                backEnabled={false}
                actions={
                    [
                        {
                            title: 'Create',
                            type: 'menu',
                            permissions: [

                            ],
                            menu: [
                                {
                                    title: 'Create Milestone',
                                    icon: 'community',
                                    permissions: [

                                    ],
                                    onPress: () => {
                                        toggleRightModal()
                                    }
                                },
                                {
                                    title: 'Create Reward',
                                    icon: 'community',
                                    permissions: [

                                    ],
                                    onPress: () => {
                                        toggleRewardModal()
                                    }
                                }
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <ContentBlockV1
                    title={'Activity'}
                >
                    <View style={styles.cardView}>
                        <FlatList
                            data={activityData}
                            renderItem={({ item, index }) => {
                                return (
                                    <ActiveCard
                                        countTxt={item?.value}
                                        subTitleTxt={item?.label}
                                        index={index}
                                        background="#f9675a"
                                    />
                                );
                            }}
                            numColumns={3}
                            keyExtractor={(item) => item.id}
                        />
                    </View>
                </ContentBlockV1>

                <ContentBlockV1
                    title={'Milestones'}
                    action={{
                        icon: 'chevronRight',
                        onPress: () => {
                            toggleMilestonesModal()
                        }
                    }}
                >
                    <FlatList
                        horizontal={Platform.OS == 'web' ? true : false}
                        data={getMilestone}
                        renderItem={({ item, index }) => {
                            return (
                                <Milestone
                                    id={item?.id}
                                    type={'management'}
                                    name={item?.name}
                                    description={item?.description}
                                    criteria={item?.criteria_type_label}
                                />
                            );
                        }}
                        keyExtractor={(item) => item.id}
                    />
                </ContentBlockV1>

                <ContentBlockV1
                    title={'Rewards'}
                    action={{
                        icon: 'chevronRight',
                        onPress: () => {
                            toggleRewardsModal()
                        }
                    }}
                >
                    <FlatList
                        horizontal={Platform.OS == 'web' ? true : false}
                        data={rewardsData}
                        renderItem={({ item, index }) => {
                            return (
                                <RewardDetailsComponent
                                    width={'100%'}
                                    background="#FFFFFF"
                                    profileImg={item?.image}
                                    title={item?.title}
                                    description={item?.description}
                                    number_of_distributions={item?.reward_orders?.length}
                                    index={index}
                                    rewardId={item?.id}
                                />
                            );
                        }}
                        keyExtractor={(item) => item.id}
                    />
                </ContentBlockV1>

                <ContentBlockV1
                    title={'Orders'}
                    action={{
                        icon: 'chevronRight',
                        onPress: () => {
                            toggleOrdersModal()
                        }
                    }}
                >
                    <FlatList
                        horizontal={Platform.OS == 'web' ? true : false}
                        data={rewardOrders}
                        renderItem={({ item, index }) => {
                            return (
                                <OrderComponent
                                    background="#FFFFFF"
                                    id={item?.id}
                                    order_id={item?.order_id}
                                    status={item?.status_taxonomy?.default_label}
                                />
                            );
                        }}
                        keyExtractor={(item) => item.id}
                    />
                </ContentBlockV1>
            </PageContent>

            <MilestonesModal />
            <RewardsModal />
            <OrdersModal
                orders={rewardOrders}
            />

            {
                isModalVisible
                &&
                <SendManualRewardModal
                    isModalVisible={isModalVisible}
                    toggleModal={toggleModal}
                />
            }
            {
                isMilestoneModalVisible
                &&
                <CreateMilestoneModal
                    isMilestoneModalVisible={isMilestoneModalVisible}
                    toggleRightModal={toggleRightModal}
                />
            }
            {
                isRewardModalVisible
                &&
                <CreateRewardModal
                    isRewardModalVisible={isRewardModalVisible}
                    toggleRewardModal={toggleRewardModal}
                />
            }
        </>
    );
};
export default Management;

const styles = StyleSheet.create({
    closeBtn: {
        position: "absolute",
        right: 8,
        top: 8,
    },
    headerTitle: {
        fontSize: 32,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    modalHeaderView: {
        flex: 0.15,
        flexDirection: "row",
        alignItems: "center",
    },
    modalProfilePic: {
        height: 22,
        width: 35,
        borderRadius: 4,
    },
    modalTitle: {
        marginLeft: 5,
        fontSize: 20,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    importedBtn: {
        backgroundColor: "#FFCF70",
        borderRadius: 4,
        height: 16,
        width: 45,
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 15,
    },
    importedBtnTxt: {
        fontSize: 8,
        fontWeight: "500",
        lineHeight: 10,
    },
    photoView: {
        flexDirection: "row",
        alignItems: "center",
    },
    photo: {
        height: 50,
        width: 50,
        borderRadius: 33,
        marginEnd: 8,
    },

    imgCountModal: {
        fontSize: 15,
        lineHeight: 20,
        color: "#fff",
        fontWeight: "400",
        position: "absolute",
        top: 15,
        left: 10,
    },
    awardeesTxt: {
        fontSize: 12,
        fontWeight: "400",
        lineHeight: 16,
    },
    input: {
        borderBottomWidth: 1,
        borderBottomColor: "#959595",
        marginTop: 25,
        marginBottom: 10,
        height: 40,
        padding: 5,
    },
    searchIcon: {
        position: "absolute",
        right: 20,
        bottom: 20,
    },
    descriptionView: {
        flex: 0.45,
        paddingVertical: 10,
    },
    selectCheck: {
        flexDirection: "row",
        marginVertical: 8,
        alignItems: "center",
    },
    memberName: {
        fontSize: 15,
        lineHeight: 20,
        fontWeight: "400",
    },
    memberPost: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: "400",
        color: "#B5B5B5",
    },
    editBtn: {
        backgroundColor: "#000",
        height: 40,
        width: 133,
        borderRadius: 3,
        justifyContent: "center",
        alignItems: "center",
        borderColor: "#000",
        borderWidth: 1,
    },
    cancelBtn: {
        marginEnd: 10,
        backgroundColor: "#fff",
    },
    editBtnTxt: {
        fontSize: 18,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
        color: "#fff",
    },

    milestoneModalHeader: {
        flex: 1,
        paddingHorizontal: 25,
    },

    rightSideModalTitle: {
        fontSize: 20,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    rightSideModalDec: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: "400",
    },
    dropDownView: {
        marginTop: 20,
        paddingVertical: 15,
    },
    rightSideModalPointsTitle: {
        fontSize: 18,
        lineHeight: 21.09,
        fontWeight: "400",
    },
    selectHeader: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 20,
    },
    selectReward: {
        fontSize: 16,
        lineHeight: 20,
        fontWeight: "500",
        color: "#FB6758",
    },
    selectedContainer: {
        backgroundColor: "#E8E8E8",
        height: 117,
        // width: "35,",
        borderRadius: 4,
        paddingHorizontal: 12,
        marginVertical: 5,
        paddingVertical: 5,
    },
    selectedCardView: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    selectedCardHeader: {
        flexDirection: "row",
        alignItems: "center",
    },
    radioBtn: {
        height: 12,
        width: 12,
        borderRadius: 6,
        borderWidth: 1,
        borderColor: "#000",
        marginEnd: 5,
    },
    cardImg: {
        height: 22,
        width: 35,
        borderRadius: 3,
        marginEnd: 5,
    },
    selectedCardTitle: {
        fontSize: 20,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    criteriaTxt: {
        fontSize: 14,
        lineHeight: 18,
        fontWeight: "500",
        letterSpacing: -0.8,
        color: "#FB6758",
    },
    selectedCardDes: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: "400",
        color: "#000",
        marginVertical: 5,
    },
    cardBottom: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
    },
    awardTxt: {
        fontSize: 10,
        lineHeight: 13,
        fontWeight: "500",
        color: "#00B15C",
    },
    ipmTxt: {
        fontSize: 10,
        lineHeight: 13,
        fontWeight: "500",
        color: "#000",
    },
    rightModalBtn: {
        backgroundColor: "#FB6758",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        height: 40,
        marginVertical: 10,
    },
    rightModalBtnText: {
        fontSize: 14,
        lineHeight: 18,
        fontWeight: "500",
        letterSpacing: -0.8,
        color: "#fff",
    },
    rightSideModal: {
        backgroundColor: "#fff",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        alignSelf: "flex-end",
        paddingVertical: 15,
        margin: 0,
        width: "40%",
        // height: "100%",
    },
    closeBtn: {
        position: "absolute",
        right: 8,
        top: 8,
    },
    rewardModalTitle: {
        fontSize: 20,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    rewardModalDes: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: "400",
        color: "#141414",
    },
    modal: {
        height: "70%",
        width: "55%",
        backgroundColor: "#fff",
        borderRadius: 20,
        alignSelf: "center",
        paddingHorizontal: 20,
        paddingVertical: 15,
    },
    rewardTabView: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    rewardTab: {
        justifyContent: "center",
        alignItems: "center",
        borderBottomWidth: 2,
        paddingVertical: 5,
        width: "48%",
        flex: 0.5,
        marginHorizontal: 2,
        marginVertical: 20,
    },
    rewardBtnTxt: {
        fontSize: 16,
        lineHeight: 20,
        fontWeight: "400",
        color: "#141414",
    },
    imgContainer: {
        flex: 1,
        justifyContent: "space-between",
        flexDirection: "row",
        marginVertical: 10,
    },
    titleText: {
        fontSize: 18,
        lineHeight: 21,
        fontWeight: "400",
        color: "#141414",
        marginVertical: 5,
    },
    giftCard: {
        backgroundColor: "#E8E8E8",
        height: 30,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        paddingHorizontal: 10,
    },

    giftImgCard: {
        backgroundColor: "#E8E8E8",
        height: 30,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        borderColor: "#FB6758",
        borderWidth: 1,
        borderStyle: "dashed",
    },
    giftCardTxt: {
        fontSize: 16,
        lineHeight: 20,
        fontWeight: "500",
    },
    imgSizeTxt: {
        fontSize: 12,
        lineHeight: 15,
        fontWeight: "500",
        color: "gray",
    },

    rewardTxtInput: {
        padding: 10,
        height: 100,
        marginVertical: 10,
        backgroundColor: "#F9F9F9",
    },
    importView: {
        flex: 1,
        alignItems: "center",
        alignSelf: "center",
    },

    rewardModalSelectionList: { height: 138, width: "98%", marginEnd: 5 },

    bottomView: {
        justifyContent: "space-between",
        flexDirection: "row",
        // marginTop: 150,
        position: "absolute",
        bottom: 5,
        right: 20,
        left: 20,
    },
    saveCreateTxt: {
        fontSize: 18,
        lineHeight: 40,
        fontWeight: "500",
        color: "#FB6758",
    },
    btnAlignment: {
        alignItems: "center",
    },
    rewardModalBtn: {
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FB6758",
        height: 40,
        width: 180,
        borderRadius: 4,
    },
    createTxt: {
        fontSize: 18,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
        color: "#fff",
    },

    workspaceBtn: {
        backgroundColor: "#FB6758",
        height: 40,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
        position: "absolute",
        width: 227,
        alignSelf: "flex-end",
        bottom: 5,
        right: 20,
    },

    container: {
        padding: 15,
        //backgroundColor: "#fff",
        flex: 1,
    },
    rewardTitle: {
        fontSize: 36,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    subTitleView: {
        flexDirection: "row",
    },
    activityTxt: {
        fontSize: 24,
        lineHeight: 40,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    monthView: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: 20,
        marginTop: 10,
        marginEnd: 8,
    },
    monthTxt: {
        fontSize: 14,
        lineHeight: 18.3,
        fontWeight: "500",
        letterSpacing: -0.8,
        marginEnd: 5,
    },
    cardView: {
        flexDirection: "row",
    },
    topPerformersView: { flex: 0.4, paddingLeft: 15 },

    lastMonthTxt: {
        color: "#909090",
        fontSize: 14,
        lineHeight: 18.23,
        fontWeight: "400",
        marginTop: 40,
    },
    headerTitleBtnView: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 30,
        marginBottom: 10,
        justifyContent: "space-between",
    },
    titleView: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20,
    },

    Button: {
        backgroundColor: "#FB6758",
        paddingHorizontal: 50,
        paddingVertical: 1.2,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
    },

    BtnText: {
        color: "#fff",
        fontSize: 18,
        lineHeight: 35,
        fontWeight: "500",
        letterSpacing: -0.8,
    },
    btnView: {
        flex: 0.1,
        flexDirection: "row",
        alignSelf: "flex-end",
    },
    monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
    calenderView: {
        width: !isMobile ? 410 : null,
        height: !isMobile ? 390 : null,
        borderRadius: 10,
        backgroundColor: "#FFFFFF",
        margin: 10,
        marginTop: 10,
        paddingVertical: 10,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    dropDownModal: {
        backgroundColor: "#fff",
        width: 167,
        height: 183,
        borderRadius: 10,
        marginTop: "10%",
        marginLeft: "8%",
        paddingHorizontal: 16,
        paddingVertical: 20,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        position: "absolute",
        top: -20,
        left: 10,
    },
    dropDownModalContainer: {
        flex: 1,
        flexDirection: "column",
    },
    monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
    dropDown: {
        height: 187,
        width: 167,
        backgroundColor: "#fff",
        zIndex: 999,
        position: "absolute",
        top: 35,
        left: 20,
        borderRadius: 8,
        paddingVertical: 20,
        paddingHorizontal: 16,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    inputField: {
        height: 40,
        width: "100%",
        backgroundColor: "#F9F9F9",
        borderRadius: 8,
        marginTop: 5,
        fontSize: 16,
        paddingHorizontal: 10,
    },
    calender: {
        flexDirection: "row",
        justifyContent: "space-between",
        position: "absolute",
        bottom: 8,
        left: 15,
        right: 15,
    },
    cancelBtnView: {
        borderRadius: 4,
        borderWidth: 1,
        width: 150,
        height: 40,
        borderColor: "#000",
        justifyContent: "center",
        alignItems: "center",
    },
    cancelCalenderBtn: {
        fontSize: 17,
        fontWeight: "600",
        lineHeight: 20,
        textAlign: "center",
    },
    applyBtnView: {
        backgroundColor: "#FB6758",
        width: 150,
        height: 40,
        borderRadius: 4,
        borderColor: "#000",
        justifyContent: "center",
        alignItems: "center",
    },
    applyTxt: {
        fontSize: 17,
        fontWeight: "600",
        lineHeight: 20,
        textAlign: "center",
        color: "#fff",
    },
});
