import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Home(props) {
  return (
    <Svg width="20" height="17" viewBox="0 0 20 17" fill="none" {...props}>
      <Path
        d="M17 6.3V1H14V3.6L10 0L0 9H3V17H8V11H12V17H17V9H20L17 6.3ZM8 7C8 5.9 8.9 5 10 5C11.1 5 12 5.9 12 7H8Z"
        fill={props.color || '#323232'}
      />
    </Svg>
  )
}

Home.propTypes = Svg.propTypes
