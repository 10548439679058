import React from 'react'
import { Text, TouchableOpacity, ActivityIndicator } from 'react-native'
import { useSelector } from 'react-redux'


import * as brandSelectors from '@/redux/selectors/branding'
const ButtonPrimary = ({ loading = false, _disabled = false, onPress, label, customStyles = {}, textStyles = {}, textStyle = {} }) => {


    const branding = useSelector(brandSelectors.getSettings);
    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text
    } = branding;
    return (
        <TouchableOpacity
            style={
                [
                    {
                        backgroundColor: '#000',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 5,
                        // marginBottom: 10,
                        // paddingVertical: 10,
                        paddingHorizontal: 30,

                        minHeight: 45,
                        minWidth: 64,
                    },
                    {
                        backgroundColor: color_scheme_primary_button_background,
                    },
                    _disabled && {
                        backgroundColor: '#0005',
                    },
                    customStyles
                ]
            }
            onPress={onPress}
            disabled={_disabled}
        >

            {
                loading
                    ?
                    (
                        <ActivityIndicator
                            size={20}
                            animating
                            color="white"
                            style={{ marginRight: 10 }}
                        />
                    )
                    :
                    <Text
                        style={[
                            {
                                color: color_scheme_primary_button_text,
                                fontSize: 16,
                                fontFamily: "WorkSans_500Medium",
                            },
                            textStyles,
                            textStyle
                        ]}
                    >
                        {label}
                    </Text>
            }


        </TouchableOpacity>
    )


}

export default ButtonPrimary;
