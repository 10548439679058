import { createContext, useState } from "react";
import { Layouts } from "../Main/Layouts";

export const LayoutContext = createContext({ layout: Layouts.list, setLayout: (layout) => { } });

const LayoutProvider = ({ children }) => {
    const [layout, setLayout] = useState(Layouts.list);

    return (
        <LayoutContext.Provider value={{ layout, setLayout }}>
            {children}
        </LayoutContext.Provider>
    )
}

export default LayoutProvider;