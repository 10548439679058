import React, { useState } from 'react'
import { StyleSheet, Text, View, Pressable } from "react-native";
import { SketchPicker } from 'react-color';
import Modal from "react-native-modal";

const ColorPicker = ({ colorPicker, setColorPicker, brandColors, setBrandColors }) => {

    const [background, setBg] = useState("#fff");


    const handleChangeComplete = (color) => {
        setBg(color.hex)


        setBrandColors(
            {
                ...brandColors,
                [colorPicker.key]: color.hex,
            }
        )
    };


    const closeModal = () => {
        setColorPicker("");
    }

    return (
        <>
            <Modal isVisible={!!colorPicker} style={styles.modal} >
                <View style={styles.modalBody}>

                    <Text style={styles.description}>Select {colorPicker.description}</Text>

                    <SketchPicker
                        color={background}
                        onChangeComplete={handleChangeComplete}
                    />

                    <Pressable style={styles.taxonomyUpload} onPress={closeModal}>
                        <Text style={{ color: '#fff', fontSize: 16 }}>{"Done"}</Text>
                    </Pressable>


                </View>
            </Modal>
        </>
    )
}

const styles = StyleSheet.create({
    modal: {
        alignItems: "center"
    },
    modalBody: {
        alignItems: "center",
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 10,

    },
    description: {
        fontSize: 16,
        paddingVertical: 5,
    },
    taxonomyUpload: {
        marginTop: 20,
        backgroundColor: '#000',
        width: 120,
        height: 35,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
    },
});


export default ColorPicker;