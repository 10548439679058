import styled from 'styled-components/native'
import { SafeAreaView, StyleSheet } from 'react-native'
import { isMobile } from '@/styles'
import { Paragraph1, Paragraph2, Caption1 } from '@/styles/fonts'

export const Container = styled(SafeAreaView)`
  flex: 1;
`
export const Commenter = styled(Paragraph1)`
  color: black;
`
export const Comment = styled(Caption1)`
  color: black;
`

export const CreatedOn = styled(Caption1)`
  color: ${({ theme }) => theme.basics[400]};
`


export const styles = StyleSheet.create({
    flatListContent: {
        flexDirection: 'column',
        paddingVertical: isMobile ? 20 : 30,
        paddingHorizontal: isMobile ? 0 : 30,
        width: '100%',
        maxWidth: 580,
        alignSelf: 'center'
    },
    flatList: {
        flex: 1,
        width: '100%',
    }
})