// @flow

import * as React from 'react'
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Text,
} from 'react-native'
import PropTypes from 'prop-types'

export const labelPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.func,
])

class PopoverTooltipItem extends React.PureComponent {
  render() {
    const label = this.props.label
    return (
      <View style={[styles.itemContainer, this.props.containerStyle]}>
        <TouchableOpacity onPress={this.props.onPress(this.props.onPressUserCallback)}>
          <Text>{label}</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

PopoverTooltipItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  onPressUserCallback: PropTypes.func.isRequired,
  label: labelPropType.isRequired,
  containerStyle: PropTypes.any,
  labelStyle: PropTypes.any,
}

const styles = StyleSheet.create({
  itemContainer: {
    padding: 10,
  },
})

export default PopoverTooltipItem
