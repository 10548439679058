import { Paragraph1 } from '@/styles/fonts'
import { Animated } from 'react-native'
import styled from 'styled-components/native'

export const Container = styled(Animated.View)`
	padding: 15px;
	min-width: 272px;
	height: 22px;
	background-color: ${({theme}) => theme.basics.background_white};
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`

export const Message = styled(Paragraph1)`
  font-size: 14px;
  margin-left: 10px;
  color: ${({ theme, type }) =>
    type === 'success' ? theme.success[900] : theme.danger.standard};
  flex-grow: 1;
  flex-shrink: 1;
	padding: 0 0 0 10px;
`
