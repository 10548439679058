import React, { useState, useCallback, useEffect } from "react";
import {
	View,
	Text,
	Image,
	ScrollView,
	Dimensions,
	TouchableOpacity,
	StyleSheet,
	FlatList,
	ActivityIndicator,
	Platform
} from "react-native";
import {
	MemberMilestoneComponent,
	MemberRewardDetailsComponent,
} from "../components/MilestoneComponent";
import { isMobile } from "@/styles";
import { Icon } from "@/components";
import Modal from "react-native-modal";
import ApiHandler from "@/api/ApiHandler";
import { Ionicons } from "@expo/vector-icons";
import DotedLine from "../components/DotedLine";
import { formatUserName } from "@/utils/format";
import { getMember } from "@/redux/selectors/auth";
const deviceHeight = Dimensions.get("window").height;
import ShareLinkModal from "../components/ShareModal";
import { useDispatch, useSelector } from "react-redux";
import { ProgressBar, Colors } from "react-native-paper";
import CalendarPicker from "react-native-calendar-picker";
import { ActiveMemberCard } from "../components/ActiveCard";
import * as profileSelectors from "@/redux/selectors/profile";
import { MembersTopPerformers } from "../components/TopPerformers";
import { actions as profileActions } from "@/redux/reducers/profile";
import LeaderBoardComponent from "../components/LeaderBoardComponent";

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ModalV2 from '@/components/ModalV2'

import Milestone from '../components/Milestone'

const Member = ({ id }) => {
	const [isMilestonesModalVisible, setMilestonesModalVisible] = useState(false);
	const [isRewardsModalVisible, setRewardsModalVisible] = useState(false);
	const [isLeaderboardModalVisible, setLeaderboardModalVisible] = useState(false);

	const [loading, setLoading] = useState(false);
	const [topPoints, setTopPoints] = useState([]);
	const [badgePoints, setBadgePoints] = useState([]);
	const [leaderBoard, setLeaderBoard] = useState([]);
	const [memberRewards, setMemberRewards] = useState([]);
	const [categorizedPoints, setCategorizedPoints] = useState([]);
	const [memberMilestones, setMemberMilestones] = useState([]);
	const [selectFilterPoints, setSelectFilterPoints] = useState([]);
	const [memberInfo, setMemberInfo] = useState([]);

	const [selectedStartDate, setSelectedStartDate] = useState("");
	const [selectedEndDate, setSelectedEndDate] = useState("");

	let userProfile = useSelector(profileSelectors.getProfile);

	let summedPoints = 0;
	for (let i = 0; i < userProfile; i++) {
		if (userProfile) {
			summedPoints =
				userProfile.total_reward_points +
				userProfile.engagement_points +
				userProfile.tural_maturity_points;
		} else {
			null;
		}
	}

	// currentUser
	const user = useSelector(getMember);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user?.id == id) {
			userProfile = user;
			dispatch(profileActions.fetchProfile(id));
			dispatch(profileActions.fetchRecentRewards());
			dispatch(profileActions.fetchPreferredTopics());
		} else {
			dispatch(profileActions.fetchProfile(id));
		}
	}, [id, dispatch]);

	const toggleMilestonesModal = () => {
		setMilestonesModalVisible(!isMilestonesModalVisible);
	};
	const toggleRewardsModal = () => {
		setRewardsModalVisible(!isRewardsModalVisible);
	};
	const toggleLeaderboardModal = () => {
		setLeaderboardModalVisible(!isLeaderboardModalVisible);
	};


	const getBadgePoints = async () => {
		setLoading(true);
		try {
			let queryParams = "";
			queryParams = `latest=${true}`;
			const response = await new ApiHandler().getBadgePoints(queryParams);
			console.log("********getBadgePoints Response****", response?.data?.data);
			if (response?.data) {
				setBadgePoints(response?.data?.data?.badges);
				setTopPoints(response?.data?.data);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log("ERROR", error);
		} finally {
		}
	};

	const getLatestLeaderBoard = async () => {
		try {
			let queryParams = "";
			queryParams = `latest=${true}`;
			const response = await new ApiHandler().getLatestLeaderBoard(queryParams);
			console.log("********getLeaderBoard Response****", response?.data?.data);

			if (response?.data) {
				setLeaderBoard(response?.data?.data);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	const getCategorizedPoints = async () => {
		try {
			let queryParams = "";
			if (activityFilter == "custom") {
				queryParams = `timeframe=${activityFilter}&&start_date=${selectedStartDate}&&end_date=${selectedEndDate}`;
			} else {
				queryParams = `timeframe=${activityFilter}`;
			}
			const response = await new ApiHandler().getCategorizedPoints(queryParams);
			// console.log("******timeframe******", response?.data?.data);
			if (response?.data) {
				setCategorizedPoints(response?.data?.data);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	const getLatestMemberMilestones = async () => {
		setLoading(true);
		try {
			let latest = `latest=${true}`;
			const response = await new ApiHandler().getLatestMemberMilestones(latest);

			if (response?.data) {
				setMemberMilestones(response?.data?.data);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.log("ERROR", error);
		} finally {
		}
	};

	const getLatestMemberRewards = async () => {
		setLoading(true);
		try {
			let queryParams = "";
			queryParams = `workspace_id=${1}&&latest=${true}`;
			const response = await new ApiHandler().getLatestMemberRewards(
				queryParams
			);
			console.log(
				"******getLatestMemberRewards:::::::::====",
				response?.data?.data
			);

			if (response?.data) {
				setLoading(false);
				setMemberRewards(response?.data?.data);
			}
		} catch (error) {
			setLoading(false);
			console.log("ERROR", error);
		} finally {
		}
	};

	const getMemberInfo = async () => {
		setLoading(true);
		try {
			const response = await new ApiHandler().getMemberInfo();
			console.log("******getMemberInfo:::::::::====", response);

			if (response?.data) {
				setLoading(false);
				setMemberInfo(response?.data?.data);
			}
		} catch (error) {
			setLoading(false);
			console.log("ERROR", error);
		} finally {
		}
	};

	useEffect(() => {
		getMemberInfo();
		getBadgePoints();
		getLatestLeaderBoard();
		getCategorizedPoints();
		getLatestMemberMilestones();
		getLatestMemberRewards();
	}, []);

	const MilestonesModal = () => {
		const [memberMilestones, setMemberMilestones] = useState([]);
		const [loading, setLoading] = useState(false);

		const getMemberMilestones = async () => {
			setLoading(true);
			try {
				const response = await new ApiHandler().getMemberMilestones();
				if (response?.data) {
					setMemberMilestones(response?.data?.data?.data);
					setLoading(false);
				}
			} catch (error) {
				setLoading(false);
				console.log("ERROR", error);
			} finally {
			}
		};
		useEffect(() => {
			getMemberMilestones();
		}, []);
		return (
			<View>
				<ModalV2
					isVisible={isMilestonesModalVisible}
					setVisibility={toggleMilestonesModal}
					title={'Milestones'}
				>
					<View>
						<FlatList
							data={memberMilestones}
							renderItem={({ item, index }) => {
								return (
									<View style={{ marginTop: 3 }}>
										<Milestone
											id={item?.id}
											type={'member'}
											name={item?.name}
											description={item?.description}
											criteria={item?.criteria_type_label}
										/>
									</View>
								);
							}}
							keyExtractor={(item) => item.id}
						/>
					</View>
				</ModalV2>
			</View>
		);
	};

	const RewardsModal = () => {
		const [memberRewards, setMemberRewards] = useState([]);
		const [loading, setLoading] = useState(false);

		const getMemberRewards = async () => {
			setLoading(true);
			try {
				let queryParams = "";
				queryParams = `workspace_id=${1}`;
				const response = await new ApiHandler().getMemberRewards(queryParams);
				if (response?.data) {
					setLoading(false);
					setMemberRewards(response?.data?.data?.data);
				}
			} catch (error) {
				setLoading(false);
				console.log("ERROR", error);
			} finally {
			}
		};
		useEffect(() => {
			getMemberRewards();
		}, []);

		return (
			<View>
				<ModalV2
					isVisible={isRewardsModalVisible}
					setVisibility={toggleRewardsModal}
					title={'Rewards Received'}
				>
					<FlatList
						scrollVertical
						data={memberRewards}
						renderItem={({ item, index }) => {
							return (
								<MemberRewardDetailsComponent
									background={"#f1f0f0"}
									width={"100%"}
									profileImg={item?.reward?.image}
									title={item?.reward?.title}
									description={item?.reward?.description}
									memberRewardId={item?.reward?.id}
								/>
							);
						}}
						keyExtractor={(item) => item.id}
					/>
				</ModalV2>
			</View>
		);
	};

	const LeaderboardModal = () => {
		const [leaderBoardAll, setLeaderBoardAll] = useState([]);
		const [loading, setLoading] = useState(false);

		const getLeaderBoard = async () => {
			setLoading(true);
			try {
				const response = await new ApiHandler().getLeaderBoard();
				if (response?.data) {
					setLoading(false);
					setLeaderBoardAll(response?.data?.data);
				}
			} catch (error) {
				setLoading(false);
				console.log("ERROR", error);
			} finally {
			}
		};
		useEffect(() => {
			getLeaderBoard();
		}, []);
		return (
			<View>
				<ModalV2
					isVisible={isLeaderboardModalVisible}
					setVisibility={toggleLeaderboardModal}
					title={'Leaderboard'}
				>
					<FlatList
						data={leaderBoardAll}
						renderItem={({ item }) => {
							return (
								<LeaderBoardComponent
									points={item?.points}
									image={item?.member?.avatar}
									badge={item?.badge_image_url}
									badges={item?.badges}
									percent={item?.percent}
									member={item?.member}
								/>
							);
						}}
					/>
				</ModalV2>
			</View>
		);
	};


	const [activityFilter, setActivityFilter] = useState("current");

	return (
		<>
			<PageHeader
				title={'Rewards'}
				icon={'rewards'}
				backEnabled={false}
			/>

			<PageContent>
				{badgePoints.length > 0 ? (
					<ColumnsContainer>
						<Column
							flexBasis={'100%'}
						>
							<ContentBlockV1
								title={'Your Badges'}
							>
								<View style={styles.badgesCard}>
									<View style={styles.pointsCardHeader}>
										<Text style={styles.pointsCounterTxt}>
											{topPoints?.points}
											<Text style={styles.pointsTxt}>{" Points"}</Text>
										</Text>
									</View>

									<View style={styles.progressBar}>
										<View
											style={[
												styles.progressBarBlue,
												{
													flex: topPoints?.points / badgePoints[badgePoints.length - 1]?.value ? topPoints?.points / badgePoints[badgePoints.length - 1]?.value : 0,
												},
											]}
										/>
										<View style={styles.progressBarGrey} />
									</View>
									<DotedLine />

									<View style={styles.badgesView}>
										<View style={[styles.pointCardBadges]}>
											<Image
												source={{ uri: badgePoints[0]?.badge_image_url }}
												style={styles.badgeSize}
											/>
											<Text style={styles.numOfBadges}> {badgePoints[0]?.value}</Text>
										</View>
										<View style={[styles.pointCardBadges]}>
											<Image
												source={{ uri: badgePoints[1]?.badge_image_url }}
												style={styles.badgeSize}
											/>
											<Text style={styles.numOfBadges}> {badgePoints[1]?.value}</Text>
										</View>
										<View style={[styles.pointCardBadges]}>
											<Image
												source={{ uri: badgePoints[2]?.badge_image_url }}
												style={styles.badgeSize}
											/>
											<Text style={styles.numOfBadges}> {badgePoints[2]?.value}</Text>
										</View>
									</View>
								</View>
							</ContentBlockV1>
						</Column>
					</ColumnsContainer>
				) : null}

				<ColumnsContainer>
					<Column
						flexBasis={'50%'}
					>
						<ContentBlockV1
							title={'Your Points'}
						>
							<View style={styles.cardView}>
								<ActiveMemberCard
									background="#f9675a"
									countTxt={categorizedPoints[0]?.value ? categorizedPoints[0]?.value : 0}
									subTitleTxt={categorizedPoints[0]?.label ? categorizedPoints[0]?.label : 'Points'}
								// index={index}
								/>
								<ActiveMemberCard
									background="#f9675a"
									countTxt={categorizedPoints[1]?.value ? categorizedPoints[1]?.value : 0}
									subTitleTxt={categorizedPoints[1]?.label ? categorizedPoints[1]?.label : 'Points'}
								// index={index}
								/>
								<ActiveMemberCard
									background="#f9675a"
									countTxt={categorizedPoints[2]?.value ? categorizedPoints[2]?.value : 0}
									subTitleTxt={categorizedPoints[2]?.label ? categorizedPoints[2]?.label : 'Points'}
								// index={index}
								/>
							</View>
						</ContentBlockV1>
					</Column>

					<Column
						flexBasis={'50%'}
					>
						<ContentBlockV1
							title={'Leaderboard'}
							action={{
								icon: 'chevronRight',
								onPress: () => {
									toggleLeaderboardModal()
								}
							}}
						>
							<FlatList
								horizontal
								data={leaderBoard}
								renderItem={({ item }) => {
									return (
										<MembersTopPerformers
											image={item?.member?.avatar}
											badge={item?.badge_image_url}
										/>
									);
								}}
							/>
						</ContentBlockV1>
					</Column>
				</ColumnsContainer>

				<ContentBlockV1
					title={'Milestones'}
					action={{
						icon: 'chevronRight',
						onPress: () => {
							toggleMilestonesModal()
						}
					}}
				>
					<FlatList
						horizontal={Platform.OS == 'web' ? true : false}
						data={memberMilestones}
						renderItem={({ item, index }) => {
							return (
								<Milestone
									id={item?.id}
									type={'member'}
									name={item?.name}
									description={item?.description}
									criteria={item?.criteria_type_label}
								/>
							);
						}}
						keyExtractor={(item) => item.id}
					/>
				</ContentBlockV1>

				<ContentBlockV1
					title={'Rewards Received'}
					action={{
						icon: 'chevronRight',
						onPress: () => {
							toggleRewardsModal()
						}
					}}
				>
					<FlatList
						horizontal={Platform.OS == 'web' ? true : false}
						data={memberRewards}
						renderItem={({ item, index }) => {
							return (
								<MemberRewardDetailsComponent
									order={item}
									background={"#FFFFFF"}
									width={!isMobile ? 255 : 350}
									profileImg={item?.reward?.image}
									title={item?.reward?.title}
									description={item?.reward?.description}
									memberRewardId={item?.reward?.id}
								/>
							);
						}}
						keyExtractor={(item) => item.id}
					/>
				</ContentBlockV1>
			</PageContent>

			<MilestonesModal />
			<RewardsModal />
			<LeaderboardModal />
		</>
	);
};

export default Member;

const styles = StyleSheet.create({
	rightSideModal: {
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderBottomLeftRadius: 20,
		alignSelf: "flex-end",
		padding: 20,
		margin: 0,
		width: "32%",
		// height: "100%",
	},
	shareModal: {
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 20,
		margin: 0,
		width: "22%",
		paddingBottom: 20,
	},
	copyView: {
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
	},
	input: {
		height: 30,
		width: "100%",
		alignSelf: "center",
		marginTop: 10,
		paddingHorizontal: 15,
		paddingVertical: 5,
		backgroundColor: "#E1E9F0",
		fontSize: 8,
		color: "grey",
		alignItems: "center",
	},
	cancelButton: {
		height: 25,
		width: 80,
		borderWidth: 1,
		borderColor: "black",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 2,
	},
	shareButton: {
		height: 25,
		width: 80,
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 10,
		backgroundColor: "#FB6758",
		borderRadius: 2,
	},
	mainView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 12,
	},
	buttonView: {
		flexDirection: "row",
		alignSelf: "flex-end",
		marginTop: 30,
	},
	headView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		alignSelf: "center",
	},
	closeBtn: {
		position: "absolute",
		left: -2,
		top: 1,
	},
	modalHeaderView: {
		flexDirection: "row",
		alignItems: "center",
		// justifyContent: "space-between",
	},
	mobContainerHeader: {
		flexDirection: "row",
		paddingTop: isMobile ? 30 : null,
		alignItems: "center",
		justifyContent: !isMobile ? "space-between" : null,
	},
	headerBadge: {
		height: isMobile ? 30 : 80,
		width: isMobile ? 30 : 80,
		marginEnd: 5,
	},
	mobTitle: { flexDirection: "row", alignItems: "center" },
	titleName: {
		fontSize: isMobile ? 20 : 24,
		lineHeight: isMobile ? 26 : 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginEnd: !isMobile ? 10 : null,
	},
	subTitle: {
		fontSize: 12,
		lineHeight: 15.62,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#C80101",
		marginEnd: 10,
	},
	share: {
		height: 19,
		width: 19,
		borderRadius: 10,
	},
	xStyle: {
		fontSize: 12,
		lineHeight: 15.62,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#C80101",
	},
	xView: {
		height: 25,
		width: 25,
		borderRadius: 13,
		backgroundColor: "#fff",
		marginEnd: 3,
		justifyContent: "center",
		alignItems: "center",
	},
	badgesCard: {
		// height:98,
		width: !isMobile ? "65%" : null,
		backgroundColor: isMobile ? "#F2F2F2" : "#fff",
		borderRadius: 7,
		marginTop: 10,
		paddingVertical: 10,
		paddingHorizontal: 10,
	},
	pointsCardHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	pointsCounterTxt: {
		fontSize: 20,
		lineHeight: 22,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	pointsTxt: {
		fontSize: 12,
		lineHeight: 22,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "gray",
	},
	unlockTxt: {
		fontSize: 10,
		lineHeight: 12,
		fontWeight: "400",
		letterSpacing: -0.8,
		color: "gray",
		// textAlign:'right'
		marginTop: 5,
	},
	progressBar: {
		flex: 1,
		borderRadius: 6,
		backgroundColor: "#E7E7E7",
		height: 12,
		marginVertical: 7,
		flexDirection: "row",
	},
	progressBarBlue: { backgroundColor: "#624AD1", borderRadius: 6, height: 12 },
	progressBarGrey: {
		flex: 0.1,
		backgroundColor: "#E7E7E7",
		borderRadius: 6,
		height: 12,
	},
	badgesView: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	pointCardBadges: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 10,
	},
	badgeSize: {
		height: 30,
		width: 30,
	},
	numOfBadges: {
		color: "#A0A0A0",
		fontSize: 12,
		lineHeight: 15,
		fontWeight: "500",
	},
	trackerBadgeBorder: {
		height: 80,
		width: 80,
		borderRadius: 50,
		borderWidth: 5,
		borderColor: "#000",
		justifyContent: "center",
		alignItems: "center",
		position: isMobile ? "absolute" : null,
		right: isMobile ? 10 : null,
		top: isMobile ? 1 : null,
		marginVertical: isMobile ? null : 10,
	},
	trackerBadge: {
		height: 63,
		width: 63,
		borderRadius: 35,
		backgroundColor: "#F05343",
		justifyContent: "center",
		alignItems: "center",
	},
	pointsTracker: {
		fontSize: 8,
		lineHeight: 9,
		fontWeight: "400",
		textAlign: "center",
		color: "#fff",
		marginTop: 5,
	},
	pointsTrackCounter: {
		fontSize: 20,
		lineHeight: 22,
		fontWeight: "700",
		textAlign: "center",
		color: "#fff",
	},
	pointsViews: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	pointsTitle: {
		fontSize: 24,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		textAlign: "left",
		// marginTop: 5,
	},
	monthView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 20,
		marginTop: 10,
		marginEnd: 8,
	},
	monthTxt: {
		fontSize: 14,
		lineHeight: 18.3,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginEnd: 5,
	},
	cardView: {
		flexDirection: "row",
	},
	pointsTitle: {
		fontSize: 24,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		textAlign: "left",
		// marginTop: 5,
	},
	allMember: {
		fontSize: 14,
		lineHeight: 16,
		fontWeight: "400",
		letterSpacing: -0.8,
		marginTop: 8,
		textAlign: "right",
	},
	leaderBoardView: {
		flex: 1,
		flexDirection: "row",
		marginTop: 10,
	},
	memberCardTitleView: {
		marginTop: 30,
		flexDirection: "row",
	},
	memberCardTitle: {
		fontSize: 18,
		lineHeight: 21.09,
		fontWeight: "400",
		marginEnd: 12,
		textAlign: "left",
	},
	memberCardSubTitle: {
		fontSize: 14,
		lineHeight: 17,
		fontWeight: "400",
		marginTop: 3,
		// textAlign: "left",
	},
	titleView: {
		paddingHorizontal: 5,
	},
	milestoneModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginTop: 2,
	},
	text: {
		fontSize: 10,
		marginLeft: 10,
	},
	buttonText: {
		fontSize: 10,
		fontWeight: "500",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	calenderView: {
		width: !isMobile ? 410 : null,
		height: !isMobile ? 390 : null,
		borderRadius: 10,
		backgroundColor: "#FFFFFF",
		margin: 10,
		marginTop: 10,
		paddingVertical: 10,
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	dropDownModal: {
		backgroundColor: "#fff",
		width: 167,
		height: 183,
		borderRadius: 10,
		marginTop: "10%",
		marginLeft: "8%",
		paddingHorizontal: 16,
		paddingVertical: 20,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		position: "absolute",
		top: 15,
		left: 15,
	},
	dropDownModalContainer: {
		flex: 1,
		flexDirection: "column",
	},
	dropDown: {
		height: 187,
		width: 167,
		backgroundColor: "#fff",
		zIndex: 999,
		position: "absolute",
		top: 35,
		left: 20,
		borderRadius: 8,
		paddingVertical: 20,
		paddingHorizontal: 16,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	calender: {
		flexDirection: "row",
		justifyContent: "space-between",
		position: "absolute",
		bottom: 8,
		left: 15,
		right: 15,
	},
	cancelBtnView: {
		borderRadius: 4,
		borderWidth: 1,
		width: 150,
		height: 40,
		borderColor: "#000",
		justifyContent: "center",
		alignItems: "center",
	},
	cancelCalenderBtn: {
		fontSize: 17,
		fontWeight: "600",
		lineHeight: 20,
		textAlign: "center",
	},
	applyBtnView: {
		backgroundColor: "#FB6758",
		width: 150,
		height: 40,
		borderRadius: 4,
		borderColor: "#000",
		justifyContent: "center",
		alignItems: "center",
	},
	applyTxt: {
		fontSize: 17,
		fontWeight: "600",
		lineHeight: 20,
		textAlign: "center",
		color: "#fff",
	},
});
