import styled from 'styled-components/native';
import { TouchableOpacity } from 'react-native';

export const SheetBtn = styled(TouchableOpacity)`
	align-items: center;
	justify-content: center;
	background-color: ${({ background }) => background};
	width: 100px;
	height: 100px;
	border-radius: 50px;
`;

export const Title = styled.Text`
	font-weight: bold;
	color: white;
`;