export const notificationTypes = {
    fileSharedWithYou: `App\\Notifications\\WorkspaceApp\\FileSharedWithYou`,
    invitedToAGroup: `App\\Notifications\\WorkspaceApp\\InvitedToAGroup`,
    invitedToATeam: `App\\Notifications\\WorkspaceApp\\InvitedToATeam`,
    newArticlePublished: `App\\Notifications\\WorkspaceApp\\NewArticlePublished`,
    newCoursePublished: `App\\Notifications\\WorkspaceApp\\NewCoursePublished`,
    newEventPublished: `App\\Notifications\\WorkspaceApp\\NewEventPublished`,
    newCalendarInvite: `App\\Notifications\\WorkspaceApp\\NewCalendarInvite`,
    calendarInviteUpdated: `App\\Notifications\\WorkspaceApp\\CalendarInviteUpdated`,
    newPostComment: `App\\Notifications\\WorkspaceApp\\NewPostComment`,
    newCommentLike: `App\\Notifications\\WorkspaceApp\\NewCommentLike`,
    newPostLike: `App\\Notifications\\WorkspaceApp\\NewPostLike`,
    newWorkspaceMember: `App\\Notifications\\WorkspaceApp\\NewWorkspaceMember`,
    calendarEntryDeleted: `App\\Notifications\\WorkspaceApp\\CalendarEntryDeleted`,
}