import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    AnimatedContainer: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingHorizontal: 20,
        paddingVertical: 20,
    },
    Header: {
        ...Platform.select({
            ios: {
                marginBottom: 30
            },
            android: {
                marginBottom: 30
            },
            web: {
                marginBottom: 80
            }
        })
    },
    HeaderIcon: {
        alignSelf: 'flex-start',

        ...Platform.select({
            ios: {
                width: 40,
                height: 40
            },
            android: {
                width: 40,
                height: 40
            },
            web: {
                width: 60,
                height: 60
            }
        })
    },
    Body: {

    }
});