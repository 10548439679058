import styled from 'styled-components/native'
import { Headline6SemiBold } from '@/styles/fonts'
import { Image } from 'react-native'
import { isMobile } from '@/styles'

export const Container = styled.TouchableOpacity`
  width: 100%;
  height: 400px;
  background: #e7e7e7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Header = styled.View`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`

export const Footer = styled.View`
  font-size: 12px;
  margin-top: 16px;
`

export const CreateButton = styled.TouchableOpacity`
  background-color: ${({ theme, bg }) => (bg ? bg : theme.primary.standard)};
  color: #fff;
  width: ${isMobile ? '195px' : '100%'};
  max-width: 400px;
  height: 40px;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 3px;
`

export const CreateButtonText = styled(Headline6SemiBold)`
  color: ${({ theme, color }) =>
    color ? color : theme.basics.background_white};
  margin: 0 10px 0 0;
`

export const UploadedImage = styled(Image)`
  height: 100%;
  width: 100%;
`
