import React from 'react'
import PropTypes from 'prop-types'
import { Label, Ruler, Container } from './styles'

import { useSelector } from 'react-redux'
import * as brandSelectors from '@/redux/selectors/branding'

export default function DrawerSection({ title }) {

  const brandSettings = useSelector(brandSelectors.getSettings);


  const {
    color_scheme_main_navigation_text
  } = brandSettings
  return (
    <Container>
      {title ? (<Label color={color_scheme_main_navigation_text} >{title}</Label>) : <Ruler />}
    </Container>
  )
}

DrawerSection.propTypes = {
  title: PropTypes.string,
}
