import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { constants } from '@/redux/reducers/course'
import * as api from '@/redux/api/course'

// STORE
function* getList(action) {
  try {
    const payload = yield call(api.getList, action.payload)
    yield put({ type: constants.GET_LIST.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.GET_LIST.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchGetList() {
  yield takeLatest(constants.GET_LIST.ACTION, getList)
}

function* getCourseWithId(action) {
  try {
    const payload = yield call(api.getCourseWithId, action.payload)
   
    yield put({ type: constants.GET_COURSE_WITH_ID.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    
    yield put({
      type: constants.GET_COURSE_WITH_ID.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchGetCourseWithId() {
  yield takeLatest(constants.GET_COURSE_WITH_ID.ACTION, getCourseWithId)
}

function* createCourse(action) {
  try {
    const payload = yield call(api.createCourse, action.payload)
    yield put({ type: constants.CREATE_COURSE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.CREATE_COURSE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchCreateCourse() {
  yield takeLatest(constants.CREATE_COURSE.ACTION, createCourse)
}

function* updateCourse(action) {
  try {
    const payload = yield call(api.updateCourse, action.payload)
    
    yield put({ type: constants.UPDATE_COURSE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
   
    yield put({
      type: constants.UPDATE_COURSE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchUpdateCourse() {
  yield takeLatest(constants.UPDATE_COURSE.ACTION, updateCourse)
}

function* getFeaturedCourses(action) {
  try {
    const payload = yield call(api.getList, action.payload)
    yield put({ type: constants.GET_FEATURED_COURSE.SUCCESS, payload })
    // action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.GET_FEATURED_COURSE.FAILED,
      message: error.message || error,
    })
    // action.next && action.next(error)
  }
}

function* watchGetFeaturedCourse() {
  yield takeLatest(constants.GET_FEATURED_COURSE.ACTION, getFeaturedCourses)
}

function* getReadLaterCourses(action) {
  try {
    const payload = yield call(api.getList, action.payload)
    yield put({ type: constants.GET_READ_LATER.SUCCESS, payload })
    // action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.GET_READ_LATER.FAILED,
      message: error.message || error,
    })
    // action.next && action.next(error)
  }
}

function* watchGetReadLaterCourse() {
  yield takeLatest(constants.GET_READ_LATER.ACTION, getReadLaterCourses)
}

function* toggleFeaturedCourse(action) {
  try {
    const payload = yield call(api.toggleFeatured, action.payload)
    yield put({ type: constants.TOGGLE_FEATURED_COURSE.SUCCESS })
    action.next && action.next('success')
  } catch (error) {
    yield put({
      type: constants.TOGGLE_FEATURED_COURSE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error.message || 'error')
  }
}

function* watchToggleFeatureCourse() {
  yield takeLatest(
    constants.TOGGLE_FEATURED_COURSE.ACTION,
    toggleFeaturedCourse,
  )
}

function* toggleStatusCourse(action) {
  try {
    yield call(api.toggleStatus, action.payload)
    yield put({ type: constants.TOGGLE_STATUS_COURSE.SUCCESS })
    action.next && action.next('success')
  } catch (error) {
    yield put({
      type: constants.TOGGLE_STATUS_COURSE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error.message || 'error')
  }
}

function* watchToggleStatusCourse() {
  yield takeLatest(constants.TOGGLE_STATUS_COURSE.ACTION, toggleStatusCourse)
}

function* toggleReadLaterCourse(action) {
  try {
    const payload = yield call(api.toggleReadLater, action.payload)
    yield put({ type: constants.TOGGLE_READ_LATER_COURSE.SUCCESS })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.TOGGLE_READ_LATER_COURSE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchToggleReadLaterCourse() {
  yield takeLatest(
    constants.TOGGLE_READ_LATER_COURSE.ACTION,
    toggleReadLaterCourse,
  )
}

function* deleteCourse(action) {
  try {
    const payload = yield call(api.deleteCourse, action.payload)
    yield put({ type: constants.DELETE_COURSE.SUCCESS })
    action.next && action.next('success')
  } catch (error) {
    yield put({
      type: constants.DELETE_COURSE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error.message)
  }
}

function* watchDeleteCourse() {
  yield takeLatest(constants.DELETE_COURSE.ACTION, deleteCourse)
}


// TRACK TIME
function* trackTime(action) {
  try {
   const {response,error} = yield call(api.trackActiveCourseTime, action)
   yield put({ type: constants.TRACKING_TIME_COURSE.SUCCESS })
   action.next && action.next(undefined, payload)
 } catch (error) {
   yield put({
     type: constants.TRACKING_TIME_COURSE.FAILED,
     message: error.message || error,
   })
   action.next && action.next(error)
 }
}

function* watchTrackTime() {
 yield takeLatest(constants.TRACKING_TIME_COURSE.ACTION, trackTime)
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetCourseWithId),
    fork(watchCreateCourse),
    fork(watchUpdateCourse),
    fork(watchGetFeaturedCourse),
    fork(watchGetReadLaterCourse),
    fork(watchToggleFeatureCourse),
    fork(watchToggleReadLaterCourse),
    fork(watchDeleteCourse),
    fork(watchToggleStatusCourse),
    fork(watchTrackTime),
  ])
}
