import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import { constants as authConstants } from './auth'

// Constants
export const constants = {
  PROFILE_FETCH: createSagaAction('PROFILE_FETCH'),
  PROFILE_FETCH_RECENT_REWARDS: createSagaAction(
    'PROFILE_FETCH_RECENT_REWARDS',
  ),
  PROFILE_FETCH_PREFERRED_TOPICS: createSagaAction(
    'PROFILE_FETCH_PREFERRED_TOPICS',
  ),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  fetchProfile: (id, next) => ({
    id, 
    type: constants.PROFILE_FETCH.ACTION,
    next,
  }),
  fetchRecentRewards: (next) => ({
    type: constants.PROFILE_FETCH_RECENT_REWARDS.ACTION,
    next,
  }),
  fetchPreferredTopics: (next) => ({
    type: constants.PROFILE_FETCH_PREFERRED_TOPICS.ACTION,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // AUTH_LOGOUT
  [authConstants.AUTH_LOGOUT]: () => initialState,

  // PROFILE_FETCH
  [constants.PROFILE_FETCH.SUCCESS]: (state, { payload }) => ({
    ...state,
    profile: payload.data,
  }),

  // PROFILE_FETCH_RECENT_REWARDS
  [constants.PROFILE_FETCH_RECENT_REWARDS.SUCCESS]: (state, { payload }) => ({
    ...state,
    recentRewards: payload.data,
  }),

  // PROFILE_FETCH_PREFERRED_TOPICS
  [constants.PROFILE_FETCH_PREFERRED_TOPICS.SUCCESS]: (state, { payload }) => ({
    ...state,
    preferredTopics: payload.data,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  isLoading: false,
  profile: undefined,
  recentRewards: undefined,
  preferredTopics: undefined,
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
