import React from "react";
import { View, Dimensions } from 'react-native';
import { Container, PageContentWrapper } from "./styles";
import { createStackNavigator } from "@react-navigation/stack";
import Drawer from "@/components/Layout/Page/Layouts/LeftSideDrawer/components/Drawer";
import LeftSideDrawerLayout from "@/components/Layout/Page/Layouts/LeftSideDrawer/index";
import Home from "@/pages/Home";
import Profile from "@/pages/profile";
import Community from "@/pages/Community";
import Employee from "@/pages/Community/Employee";
import Teams from "@/pages/Community/Teams";
import Groups from "@/pages/Community/Groups";
import PostView from "@/components/Feed/Post/View"

import Learning from "@/pages/Learning";

import Articles from "@/pages/Learning/Articles/articles";
import CreateArticle from "@/pages/Learning/Articles/create";
import ViewArticle from "@/pages/Learning/Articles/View";

import Courses from "@/pages/Learning/Courses/courses";
import CreateCourse from "@/pages/Learning/Courses/create";
import ViewCourse from "@/pages/Learning/Courses/View";

import Events from "@/pages/Learning/Events/events";
import CreateEvent from "@/pages/Learning/Events/create";
import ViewEvent from "@/pages/Learning/Events/View";

import TeamPage from "@/pages/Community/TeamPage";
import GroupPage from "@/pages/Community/GroupPage";
import TeamsAndGroupsView from "@/pages/Community/TeamsAndGroups/View";

import Settings from "@/pages/Settings/index";

//ANALYTICS IMPORTS
import Analytics from "@/pages/Analytics/index";
import CommunityAnalytics from "@/pages/Analytics/CommunityAnalytics/index";
import MemberAnalytics from "@/pages/Analytics/MemberAnalytics/index";
import PublishingAnalytics from "@/pages/Analytics/PublishingAnalytics/index";
import LearningAnalytics from "@/pages/Analytics/LearningAnalytics/index";
import EventAnalytics from "@/pages/Analytics/EventAnalytics/index";
import AssessmentAnalytics from "@/pages/Analytics/AssessmentAnalytics/index";
import CultureAnalytics from "@/pages/Analytics/AssessmentAnalytics/CulturalAnalytics/index";
import ResultAnalytics from "@/pages/Analytics/AssessmentAnalytics/ResultAnalytics/index";
import Notifications from "@/pages/Notifications";
import Support from "@/pages/Support/Support";
import Assessments from "@/pages/Assessments/assessments";
import CreateAssessment from "@/pages/Assessments/create";
import CalendarAndEvents from "@/pages/CalendarAndEvents";
import EventCreate from "@/pages/CalendarAndEvents/EventCreate";
import EventEdit from "@/pages/CalendarAndEvents/EventEdit";
import EventDuplicate from "@/pages/CalendarAndEvents/EventDuplicate";
import EventDetail from "@/pages/CalendarAndEvents/EventDetail";
import { useIsMobile } from "@/styles/screenSize";
import ChatStack from "../ChatStack";
import TwilioConversations from "@/pages/TwilioConversations";
import RewardsManagement from "@/pages/Rewards/Management";
import RewardsMember from "@/pages/Rewards/Member";

import SharedFolders from "@/pages/SharedFolders";
import SubFolders from "@/pages/SharedFolders/SubFolders";
import Workspaces from "@/pages/Workspaces";
import Meeting from "@/pages/Meeting";
import SuccessMessage from "@/pages/Subscribe/SuccessMessage";
import Welcome from "@/pages/onboarding/welcome";
import WelcomTutorialWeb from '@/pages/onboarding/guidedTutorial/index.web'
import Onboarding from "@/routes/onboarding/welcome";
import Subscribe from "@/pages/Subscribe";

import { ScreenSize, useScreenSize } from '@/styles'
import ChatWrapper from "@/pages/TwilioConversations/Mobile/ChatWrapper";
import useSideBarContext from "@/contexts/SidebarContext/useSideBarContext";

const { height, width } = Dimensions.get("window");
const Stack = createStackNavigator();

export default function MainStackNavigator() {
  const screenSize = useScreenSize()
  const isMobile = useIsMobile();
  const { sideBarHidden } = useSideBarContext();

  return (
    <View style={{ flex: 1, flexDirection: screenSize <= ScreenSize.Small ? 'column' : 'row' }}>
      {!sideBarHidden && <Drawer />}

      <Stack.Navigator headerMode="none" initialRouteName="Home">
        <Stack.Screen name="Home" options={{ title: "Reframe | Home" }}>
          {(props) => (
            <LeftSideDrawerLayout scrollDisabled={true}>
              <Home {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        {/* <Stack.Screen name="Subscribe" options={{ title: "Reframe | Subscribe" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Subscribe {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen> */}
        {/* <Stack.Screen name="SubscriptionSuccess" options={{ title: "Reframe | Subscribe" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <SuccessMessage {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen> */}
        <Stack.Screen
          name="guidedTutorial"
          options={{ title: "Reframe | guidedTutorial" }}
        >
          {() => (
            <LeftSideDrawerLayout>
              <WelcomTutorialWeb />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="Community"
          options={{ title: "Reframe | Community" }}
        >
          {() => (
            <LeftSideDrawerLayout>
              <Community />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Employee" options={{ title: "Reframe | Employee" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Employee {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Teams" options={{ title: "Reframe | Teams" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Teams {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Groups" options={{ title: "Reframe | Groups" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Groups {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="TeamPage"
          options={{ title: "Reframe | Team Page" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <TeamsAndGroupsView {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="GroupPage"
          options={{ title: "Reframe | Group Page" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <TeamsAndGroupsView {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Profile" options={{ title: "Reframe | Profile" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Profile {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Learning" options={{ title: "Reframe | Learning" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Learning {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="SharedFolders"
          options={{ title: "Reframe | Storage & File Sharing" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <SharedFolders {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="SubFolders"
          options={{ title: "Reframe | Sub Folders" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <SubFolders {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen name="Articles" options={{ title: "Reframe | Articles" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Articles {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="CreateArticle"
          options={{ title: "Reframe | Create Article" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CreateArticle {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="ViewArticle"
          options={{ title: "Reframe | Article" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <ViewArticle {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Courses" options={{ title: "Reframe | Courses" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Courses {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="CreateCourse"
          options={{ title: "Reframe | Create Course" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CreateCourse {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="ViewCourse"
          options={{ title: "Reframe | ViewCourse" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <ViewCourse {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen name="Events" options={{ title: "Reframe | Events" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Events {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="CreateEvent"
          options={{ title: "Reframe | Create Event" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CreateEvent {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="ViewEvent"
          options={{ title: "Reframe | ViewEvent" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <ViewEvent {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen name="Settings" options={{ title: "Reframe | Settings" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Settings {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        {/* ANALYTICS ROUTES */}
        <Stack.Screen
          name="Analytics"
          options={{ title: "Reframe | Analytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout
              title={'Analytics'}
              icon={'analytics'}
              backEnabled={false}
            >
              <Analytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="CommunityAnalytics"
          options={{ title: "Reframe | CommunityAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CommunityAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="PublishingAnalytics"
          options={{ title: "Reframe | PublishingAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <PublishingAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="MemberAnalytics"
          options={{ title: "Reframe | MemberAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <MemberAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="LearningAnalytics"
          options={{ title: "Reframe | LearningAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <LearningAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="EventAnalytics"
          options={{ title: "Reframe | EventAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <EventAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="AssessmentAnalytics"
          options={{ title: "Reframe | AssessmentAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <AssessmentAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="cultureAnalytics"
          options={{ title: "Reframe | CultureAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CultureAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="resultAnalytics"
          options={{ title: "Reframe | ResultAnalytics" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <ResultAnalytics {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="Assessments"
          options={{ title: "Reframe | Assessments" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <Assessments {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="assessment"
          options={{ title: "Reframe | Create Assessments" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CreateAssessment {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="Calendar"
          options={{ title: "Reframe | Calendar & Events" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <CalendarAndEvents {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="EventCreate"
          options={{ title: "Reframe | Create Event" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <EventCreate {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="EventEdit"
          options={{ title: "Reframe | Edit Event" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <EventEdit {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="EventDuplicate"
          options={{ title: "Reframe | Duplicate Event" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <EventDuplicate {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="EventDetail"
          options={{ title: "Reframe | Event Detail" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <EventDetail {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>

        <Stack.Screen
          name="Notifications"
          options={{ title: "Reframe | Notifications" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <Notifications {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="Support" options={{ title: "Reframe | Support" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <Support {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen name="chat" options={{ title: "Reframe | Chat" }}>
          {(props) => (
            <LeftSideDrawerLayout>
              <ChatWrapper>
                <>
                  <TwilioConversations {...props} />
                </>
              </ChatWrapper>
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="ManagerWeb"
          options={{ title: "Reframe | ManagerWeb" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <RewardsManagement {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="MemberWeb"
          options={{ title: "Reframe | RewardScreen" }}
        >
          {(props) => (
            <LeftSideDrawerLayout
              title={'Rewards'}
              icon={'rewards'}
              backEnabled={false}
            >
              <RewardsMember {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="meeting"
          options={{ title: "Reframe | Meeting" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <Meeting {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="CommentPost"
          options={{ title: "Reframe | Comment Post" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <PostView {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
        <Stack.Screen
          name="Workspaces"
          options={{ title: "Reframe | Workspaces" }}
        >
          {(props) => (
            <LeftSideDrawerLayout>
              <Workspaces {...props} />
            </LeftSideDrawerLayout>
          )}
        </Stack.Screen>
      </Stack.Navigator>
    </View>
  );
}
