import React from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

import Welcome from '@/pages/onboarding/welcome'
import PersonalInformation from '@/pages/onboarding/personalInformation'
import ResetPassword from '@/pages/onboarding/resetPassword'
import WelcomTutorial from '@/pages/onboarding/guidedTutorial'
import WelcomTutorialWeb from '@/pages/onboarding/guidedTutorial/index.web'
import { isMobile } from '@/styles'
import CommunityGuidelines from '@/pages/onboarding/CommunityGuidelines'
import { getSubscriptionFlow } from '@/redux/selectors/auth'
import SuccessMessage from '@/pages/Subscribe/SuccessMessage'

const Stack = createStackNavigator()

export default function Onboarding() {
  const isSubscriptionFlow = useSelector(getSubscriptionFlow)
  return (
    <Stack.Navigator headerMode="none" initialRouteName="ResetPassword">
      { isSubscriptionFlow ? 

      <Stack.Screen name="SubscriptionSuccess" component={SuccessMessage} />
      :
      <Stack.Screen name="ResetPassword" component={ResetPassword} />
}
      <Stack.Screen name="Welcome" component={Welcome} />
      <Stack.Screen
        name="PersonalInformation"
        component={PersonalInformation}
      />
      <Stack.Screen
        name="CommunityGuidelines"
        component={CommunityGuidelines}
      />
      <Stack.Screen
        name="guidedTutorial"
        component={isMobile ? WelcomTutorial : WelcomTutorialWeb}
      />
      {/* <Stack.Screen name="OpenEmailApp" component={OpenEmailApp} />
      <Stack.Screen name="ForgotPassword" component={ForgotPassword} /> */}
    </Stack.Navigator>
  )
}
