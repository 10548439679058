import React, { useContext } from 'react'
import { Modal, View, SafeAreaView, FlatList, TouchableOpacity, Text, Dimensions, Image } from 'react-native';
import Button from '@/components/Chat/Button';
import { Ionicons } from '@expo/vector-icons';
import ChatContext from '@/contexts/ChatContext';
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth';
import { formatUserName } from '@/utils/format';
import { useIsMobile } from '@/styles';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate';
import ModalV2 from '@/components/ModalV2'

const { width, height } = Dimensions.get('window');

const InviteUsersModal = ({ selectedUsers, invitableUsers, selectWorkSpaceMember, onAddMembers, visible, setVisible }) => {
    const user = useSelector(getMember);
    const isMobile = useIsMobile();
    const accentColor = UseBrandingAccentColor();

    return (
        <ModalV2
            isVisible={visible}
            setVisibility={setVisible}
            title={'Add Participants'}
        >
            <View>
                <FlatList ItemSeparatorComponent={() => <View style={{ borderBottomColor: "#aaa", borderBottomWidth: 1, width: '100%' }} />} contentContainerStyle={{ paddingBottom: height / 6 }} ListHeaderComponent={() => (
                    <TouchableOpacity disabled={!selectedUsers.length} onPress={onAddMembers} style={{ backgroundColor: !selectedUsers?.length ? "rgba(240, 83, 67, 0.5)" : accentColor, padding: 15, width: isMobile ? width / 1.1 : width / 3, borderRadius: 8, alignItems: 'center', justifyContent: 'center', alignSelf: 'center', marginVertical: 20 }}>
                        <Text style={{ color: 'white', fontWeight: '600' }}>Save</Text>
                    </TouchableOpacity>
                )} removeClippedSubviews scrollEventThrottle={1600} data={invitableUsers?.filter(member => member.id !== user.id)} renderItem={({ item, index }) => (
                    <TouchableOpacity onPress={() => selectWorkSpaceMember(item.id)} style={[{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: isMobile ? 15 : 10 }, selectedUsers?.includes(item.id?.toString()) && { backgroundColor: 'rgba(150,150,150,0.1)' }]}>
                        <View style={{ flexDirection: "row", alignItems: 'center' }}>
                            {item.avatar ? (
                                <Image source={{ uri: item.avatar }} style={{ width: 40, height: 40, borderRadius: 50 }} />
                            ) : (
                                <AvatarTemplate letter={item?.first_name?.charAt(0)?.toUpperCase()} />
                            )}
                            <Text style={{ marginLeft: 15 }}>{formatUserName(item?.first_name, item?.last_name)}</Text>
                        </View>
                        {selectedUsers.includes(item.id?.toString()) && (
                            <Ionicons name="ios-checkmark" size={24} color="#000" />
                        )}
                    </TouchableOpacity>
                )} />
            </View>
        </ModalV2>
    )
}

export default InviteUsersModal;