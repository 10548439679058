import ChatContext, { MessageStatus } from '@/contexts/ChatContext';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import { Ionicons } from '@expo/vector-icons';
import React, { useContext, useState } from 'react'
import { View, Dimensions, Text, TouchableOpacity, Alert, StyleSheet } from 'react-native'
import { Menu, Provider, DarkTheme, ThemeProvider, ActivityIndicator } from 'react-native-paper'
import Delivered from '../Icons/DeliveredIcon';
import Failed from '../Icons/Failed';
import Read from '../Icons/Read';
import Sending from '../Icons/Sending';
import Reactions from './Reactions'

const { width, height } = Dimensions.get('window');

const Sender = ({ reactions, updateAttributes, children, author, status, messageTime, onDeleteMessage }) => {
    const accentColor = UseBrandingAccentColor();
    const { participants, sid } = useContext(ChatContext);
    const [menuVisible, setMenuVisible] = useState(false);


    return (
        <View style={{ width: '100%', position: "relative", marginVertical: 10, alignItems: 'flex-end', justifyContent: 'center' }}>
            <View style={{ padding: 10, borderRadius: 8, minWidth: width / 4, maxWidth: width / 1.8, overflow: 'hidden', backgroundColor: accentColor }}>
                {children}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ paddingTop: 10, marginRight: 10 }}>
                        <Text style={{ color: 'white' }}>{messageTime}</Text>
                    </View>
                    {status[MessageStatus.Delivered] ? (
                        <>
                            <Delivered color='white' style={{ bottom: -6, ...styles.statusStyle, ...styles.stayusIconStyle }} />
                            {participants[sid]?.length > 2 && (
                                <Text style={[styles.statusStyle, { bottom: -6, }]}>
                                    {status[MessageStatus.Delivered]}
                                </Text>
                            )}
                        </>
                    ) : null}
                    {status[MessageStatus.Sending] ? (
                        <Sending color="white" style={{ bottom: -6, ...styles.statusStyle, ...styles.stayusIconStyle }} />
                    ) : null}

                    {status[MessageStatus.Failed] ?
                        <>
                            <Failed color='white' style={{ bottom: -6, ...styles.statusStyle, ...styles.stayusIconStyle }} />
                            {participants[sid]?.length > 2 && (
                                <Text style={[styles.statusStyle, { bottom: -5 }]}>
                                    {status[MessageStatus.Failed]}
                                </Text>
                            )}
                        </> : null}


                    {status[MessageStatus.Read] ? (
                        <>
                            <Read color='white' style={{ bottom: -6, ...styles.statusStyle, ...styles.stayusIconStyle }} />
                            {participants[sid]?.length > 2 && (
                                <Text style={[styles.statusStyle, { bottom: -6 }]}>
                                    {status[MessageStatus.Read]}
                                </Text>
                            )}
                        </>
                    ) : null}
                    <ThemeProvider>
                        <Menu anchor={
                            <TouchableOpacity
                                style={{ bottom: -6, marginLeft: 10 }}
                                onPress={() => setMenuVisible(!menuVisible)}>
                                <Ionicons name="ellipsis-vertical" color="white" size={16} />
                            </TouchableOpacity>} visible={menuVisible} onDismiss={() => setMenuVisible(false)}>
                            <Menu.Item style={{ height: 30 }} titleStyle={{ color: 'red' }} onPress={() => {
                                onDeleteMessage();
                                setMenuVisible(false);
                            }} title="Delete Message" />
                        </Menu>
                    </ThemeProvider>
                </View>
            </View>
            <Reactions
                reactions={reactions}
                onReactionsChanged={(reactions) =>
                    updateAttributes({ reactions })
                }
            />

        </View>
    )
}

export default Sender;


const styles = StyleSheet.create({
    statusStyle: {
        marginLeft: 4,
        color: "white"
    },
    stayusIconStyle: {
        marginLeft: 10
    }
})