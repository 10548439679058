import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    Button: {
        backgroundColor: '#000',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        paddingHorizontal: 30,
        minHeight: 45,
        minWidth: 64,
    },
    Label: {
        color: '#FFFFFF',
        fontSize: 16,
        fontFamily: "WorkSans_500Medium",
    }
});
