import React, { useState, useEffect } from 'react'
import {
    Text,
    View,
    Modal,
    Button,
    Platform,
    TouchableOpacity,
    ScrollView
} from 'react-native'
import { Icon } from '@/components'

import styles from './styles.js';

const ModalV1 = ({
    isVisible,
    setVisibility,
    title,
    subtitle,
    children
}) => {
    useEffect(() => {

    }, [])

    let animationType = 'none';
    if (Platform.OS == 'web') {
        animationType = 'fade';
    } else if (Platform.OS == 'ios' || Platform.OS == 'android') {
        animationType = 'slide';
    }

    return (
        <Modal
            visible={isVisible}
            animationType={animationType}
            transparent={true}
        >
            <View style={styles.Container}>
                <View style={styles.Inner}>
                    <View style={styles.InnerHeader}>
                        <View style={styles.InnerHeaderLeft}>
                            <Text style={styles.InnerHeaderTitle}>{title}</Text>
                            <Text style={styles.InnerHeaderSubtitle}>{subtitle}</Text>
                        </View>
                        <View style={styles.InnerHeaderRight}>
                            <TouchableOpacity
                                onPress={() => {
                                    setVisibility(false)
                                }}
                            >
                                {
                                    Platform.OS == 'web' ? (
                                        <Icon
                                            icon="close"
                                            color={'#1b1b1b'}
                                            height={17}
                                            width={16}
                                        />
                                    ) : (
                                        <Icon
                                            icon="chevronDown"
                                            color={'#1b1b1b'}
                                            height={17}
                                            width={16}
                                        />
                                    )
                                }
                            </TouchableOpacity>
                        </View>
                    </View>

                    <ScrollView>
                        {children}
                    </ScrollView>
                </View>
            </View>
        </Modal>
    )

}

export default ModalV1