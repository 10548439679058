import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import _ from 'lodash'
import {updateInfiniteScroll, updateInfiniteScrollTeamPost} from '@/redux/helper'

// Constants
export const constants = {
  STORE: createSagaAction('TEAMS_STORE'),
  UPDATE: createSagaAction('TEAMS_UPDATE'),
  INDEX: createSagaAction('TEAMS_INDEX'),
  READ_LATER: createSagaAction('TEAMS_READ_LATER'),
  FEATURED: createSagaAction('TEAMS_FEATURED'),
  SET_READ_LATER: createSagaAction('TEAMS_SET_READ_LATER'),
  SHOW: createSagaAction('TEAMS_SHOW'),
  REMOVE: createSagaAction('TEAMS_REMOVE'),
  OTHER_TEAMS: createSagaAction('TEAMS_OTHER_TEAMS'),
  MEMBERS: createSagaAction('TEAMS_MEMBERS'),
  REMOVE_MEMBER: createSagaAction('TEAMS_REMOVE_MEMBER'),
  INVITE: createSagaAction('TEAMS_INVITE'),
  POSTS: createSagaAction('TEAMS_POSTS'),
  JOIN: createSagaAction('TEAMS_JOIN'),
  LEAVE: createSagaAction('TEAMS_LEAVE'),
  COMMENT_LIST: createSagaAction('COMMENT_LIST'),
  LIKE: createSagaAction('POSTS_LIKE'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  store: (object, next) => ({
    type: constants.STORE.ACTION,
    ...object,
    next,
  }),
  update: (object, next) => ({
    type: constants.UPDATE.ACTION,
    ...object,
    next,
  }),
  index: (params, next) => ({
    type: constants.INDEX.ACTION,
    ...params,
    next,
  }),
  otherTeams: (params, next) => ({
    type: constants.OTHER_TEAMS.ACTION,
    ...params,
    next,
  }),
  show: (params, next) => ({
    type: constants.SHOW.ACTION,
    loading: true,
    ...params,
    next,
  }),
  remove: (params, next) => ({
    type: constants.REMOVE.ACTION,
    loading: true,
    ...params,
    next,
  }),
  invite: ({ id, employees }, next) => ({
    type: constants.INVITE.ACTION,
    loading: true,
    id,
    employees,
    next,
  }),
  members: (params, next) => ({
    type: constants.MEMBERS.ACTION,
    loading: true,
    ...params,
    next,
  }),
  removeMember: (params, next) => ({
    type: constants.REMOVE_MEMBER.ACTION,
    loading: true,
    ...params,
    next,
  }),
  posts: (params, next) => ({
    type: constants.POSTS.ACTION,
    loading: true,
    ...params,
    next,
  }),
  join: ({ id }, next) => ({
    type: constants.JOIN.ACTION,
    id,
    next,
  }),
  leave: ({ id }, next) => ({
    type: constants.LEAVE.ACTION,
    id,
    next,
  }),
 comment_list: ({ id, postType }, next) => ({
    type: constants.COMMENT_LIST.ACTION,
    id,
    postType,
    next,
  }),
like: ({ id, user, postType, post_id }, next) => ({
    type: constants.LIKE.ACTION,
    id,
    user,
    postType,
    post_id,
    next,
  }),
}
const updatedFeed = (state, { action, payload }) => {
  if (action.postType === 'App\\Post' || action.postType === 'App\\Article') {
    return state.posts.data
      .map(post => {
        if (post.ID === action?.id) {
          if (payload.data !== undefined) {
            post.Data.likes.push(payload.data)
            post.Data.liked = payload.data
            return post
          }
          post.Data.likes = post.Data.likes.filter(like => like?.author_id !== action.user?.id)
          post.Data.liked = null
          return post
        }
        return post
      })
  }

  if (action.post_type === 'App\\CustomAssessment') {
    const allPosts = state.posts.data.slice()
    const index = state.posts.data.findIndex(p => p.ID == action.post_id)
    if (index > -1) {
      allPosts.splice(index, 1)
    }
    return allPosts
  }

  if (action.postType === 'App\\Comment') {
    const postIndex = state.posts.data.findIndex((post) => post.id === action.post_id)
    return state.posts.data
      .map((post, index) => {
        if (index === postIndex)
          return {
            ...post,
            comments: payload?.data ? (
              post.Data.comments.map((comment, idx) => {
                if (comment.id === action.id) {
                  post.Data.comments[idx].likes.push(payload.data)
                  return post.Data.comments[idx]
                }
                return post.Data.comments[idx]
              })
            ) : (
              post.Data.comments.map((comment, idx) => {
                if (comment.id === action.id) {
                  post.Data.comments[idx].likes = post.Data.comments[idx].likes
                    .filter(({ author_id }) => author_id !== action?.user?.id)
                  return post.Data.comments[idx]
                }
                return post.Data.comments[idx]
              })
            )
          }
        return post
      })
  }
  // COMMENTS LIKE
  return state.posts.data
}

const updatedFeedAfterCommentOnMobile = (state, { action, payload }) => {
  return state.posts.data
    .map(post => {
      if (post.ID === action?.id) {
        if (payload.data !== undefined) {
          post.Data.comments = payload.data
          return post
        }
        return post
      }
      return post
    })
}
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
[constants.LIKE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      posts: {
        ...state.posts,
        data: updatedFeed(state, action)
      }
    }
  },
  [constants.STORE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.STORE.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: state.data?.data?.concat([action.payload.data]),
      loading: false,
      error: null,
    }
  },
  [constants.UPDATE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.UPDATE.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: state.data?.data?.map(team => team.id === action.payload.data.id ? action.payload.data : team),
      loading: false,
      error: null,
      team: state.data.data.concat([action.payload.data]),
    }
  },
  [constants.INDEX.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      dataLoading: true
    }
  },
  [constants.INDEX.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: updateInfiniteScroll(state.data, action),
      loading: false,
    }
  },
  [constants.OTHER_TEAMS.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      allDataLoading: true
    }
  },
 [constants.COMMENT_LIST.SUCCESS]: (state, action) => ({
    ...state,
    posts: {
      ...state.posts,
      data: updatedFeedAfterCommentOnMobile(state, action)
    }
  }),
  [constants.OTHER_TEAMS.SUCCESS]: (state, action) => {
    return {
      ...state,
      otherTeams: updateInfiniteScroll(state.otherTeams, action),
      loading: false,
    }
  },
  [constants.SHOW.ACTION]: (state) => {
    return { ...state, error: null, loading: true, team: null }
  },
  [constants.SHOW.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      team: action.payload,
    }
  },
  [constants.REMOVE.ACTION]: (state) => {
    return { ...state, error: null, loading: true, team: null }
  },
  [constants.REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: state.data?.filter(team => team.id !== action.action.id),
      loading: false,
    }
  },
  [constants.MEMBERS.ACTION]: (state) => {
    return { ...state, error: null, loading: true, members: [] }
  },
  [constants.MEMBERS.SUCCESS]: (state, action) => {
    return {
      ...state,
      members: action.payload,
      loading: false,
    }
  },
  [constants.INVITE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.INVITE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.POSTS.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.POSTS.SUCCESS]: (state, action) => {
    return {
      ...state,
      posts: updateInfiniteScroll(state.posts, action),
      loading: false,
      page: action?.payload.current_page,
      next_page_url: action?.payload.next_page_url,
      last_page: action?.payload.last_page,
      per_page: action?.payload.per_page,  
    }
  },
  [constants.JOIN.ACTION]: (state) => {
    return { ...state, loading: true }
  },
  [constants.JOIN.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.LEAVE.ACTION]: (state) => {
    return { ...state, loading: true }
  },
  [constants.LEAVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  team: {},
  loading: false,
  data: {
    data: [],
    loading: false,
    page: 1,
  },
  otherTeams: {
    data: [],
    loading: false,
    page: 1,
  },
  posts: {
    data: [],
    loading: false,
    page: 1,
    last_page: 1,
    next_page_url: null,
    // per_page: 10,
  },

  members: [],
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})

