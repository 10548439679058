import React, { useState, useEffect } from 'react'
import { View, Text, StyleSheet, FlatList, ScrollView, TouchableOpacity, SafeAreaView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import Toast from 'react-native-toast-message'

import * as brandSelectors from '@/redux/selectors/branding'

import { Icon, Checkbox, TextField, PasswordTextField } from '@/components'

import ButtonPrimary from '@/components/ButtonPrimary'

import SimpleSelect from '@/components/SimpleSelect'

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import ApiHandler from '@/api/ApiHandler'
import { ScreenSize, useScreenSize } from '@/styles'
import {
    TYPE_CULTURE_MATURITY,
    TYPE_CUSTOM,
    TYPE_PREBUILT
} from "../consts";

import ContentBlockV1 from '@/components/ContentBlockV1'

const FILTER_TYPE_ENTIRE_COMPANY = `App\\Company`;
const FILTER_TYPE_ALL_TEAMS = `App\\Team`;
const FILTER_TYPE_ALL_GROUPS = `App\\Group`;

export default function NameAndTarget(
    {
        name, setName,
        lifeCycle, setLifecycle,
        category, setCategory,
        isLoading, setLoading,

        createCustom,

        editable,
        assessmentInfo,
        lifeCycleList,
        categoryList,
        teamsList,
        groupsList,

        routeParams,

        fetchAssessment
    }) {


    const branding = useSelector(brandSelectors.getSettings);
    const {
        color_scheme_primary_button_background,
    } = branding;
    const screenSize = useScreenSize()
    const assessmentType = routeParams?.type;

    let nameLifeCycleCategoryEditable = false;
    let targetingEditable = false;

    if (assessmentType) {
        if (assessmentType === TYPE_CUSTOM) {
            nameLifeCycleCategoryEditable = true;
            targetingEditable = true;
        } else if (assessmentType === TYPE_PREBUILT || assessmentType === TYPE_CULTURE_MATURITY) {
            nameLifeCycleCategoryEditable = false;
            targetingEditable = true;
        }
    } else {
        nameLifeCycleCategoryEditable = true;
        targetingEditable = true;
    }

    const [team, setTeam] = useState(null);
    const [groups, setGroups] = useState(null);

    const [selectedTeams, setSelectedTeams] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);


    const [targetEntireCompany, setTargetEntireCompany] = useState(false)

    React.useEffect(() => {
        if (editable) {
            setName(assessmentInfo?.name || "");
            setLifecycle(assessmentInfo?.lifecycle_stage_id);
            setCategory(assessmentInfo?.five_s_category_id);

            const targetEntireCompany = assessmentInfo?.assessment_target_entries?.find((item) => {
                return item?.target_type === FILTER_TYPE_ENTIRE_COMPANY;
            })

            if (targetEntireCompany?.id) {
                setTargetEntireCompany(true);
            }


            const teams = assessmentInfo?.assessment_target_entries?.filter((item) => {
                return item?.target_type === FILTER_TYPE_ALL_TEAMS;
            })

            if (teams?.length) {
                setSelectedTeams(teams);
            }
            const groups = assessmentInfo?.assessment_target_entries?.filter((item) => {
                return item?.target_type === FILTER_TYPE_ALL_GROUPS;
            })

            if (groups?.length) {
                setSelectedGroups(groups);
            }

        }
    }, [])


    const addRemoveTargetEntireCompany = async (add = false) => {

        setLoading(true)
        try {

            let message = "";
            if (assessmentType === TYPE_CUSTOM) {

                const response = add
                    ?
                    await new ApiHandler().addCustomWorkspaceTarget(assessmentInfo?.id)
                    :
                    await new ApiHandler().removeCustomWorkspaceTarget(assessmentInfo?.id)

                message = response?.data?.message

            } else if (assessmentType === TYPE_PREBUILT) {

                const response = add
                    ?
                    await new ApiHandler().addPrebuiltWorkspaceTarget(assessmentInfo?.id)
                    :
                    await new ApiHandler().removePrebuiltWorkspaceTarget(assessmentInfo?.id)

                message = response?.data?.message
            }
            else if (assessmentType === TYPE_CULTURE_MATURITY) {

                const response = add
                    ?
                    await new ApiHandler().addCulturalMaturityWorkspaceTarget(assessmentInfo?.id)
                    :
                    await new ApiHandler().removeCulturalMaturityWorkspaceTarget(assessmentInfo?.id)

                message = response?.data?.message
            }

            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (team) {
            updateTeam(team)
        }
    }, [team])

    const updateTeam = async (value) => {

        setLoading(true)
        try {

            let message = "";
            if (routeParams?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().addCustomTeamTarget(assessmentInfo?.id, value)
                message = response?.data?.message;

            } else if (routeParams?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().addCulturalMaturityTeamTarget(assessmentInfo?.id, value)
                message = response?.data?.message;

            }
            else if (routeParams?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().addPrebuiltTeamTarget(assessmentInfo?.id, value)
                message = response?.data?.message;

            }
            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })

            const _team = teamsList.find(team => {
                if (team?.id == value) {
                    return team;
                }
            })
            if (_team) {
                const teams = [...selectedTeams, _team];
                setSelectedTeams(teams);
            }

        } catch (error) {
            console.log(">>>> add assessment team target error", error)
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        if (groups) {
            updateGroups(groups)
        }
    }, [groups])
    const updateGroups = async (value) => {

        setLoading(true)
        try {
            let message = "";
            if (routeParams?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().addCustomGroupTarget(assessmentInfo?.id, value)
                message = response?.data?.message;

            } else if (routeParams?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().addCulturalMaturityGroupTarget(assessmentInfo?.id, value)
                message = response?.data?.message;

            }
            else if (routeParams?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().addPrebuiltGroupTarget(assessmentInfo?.id, value)
                message = response?.data?.message;

            }
            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })

            const _group = groupsList.find(group => {
                if (group?.id == value) {
                    return group;
                }
            })
            if (_group) {
                const groups = [...selectedGroups, _group];
                setSelectedGroups(groups);
            }


        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }
    }

    const removeTeam = async (team) => {
        const team_id = team?.target_id || team?.id;

        setLoading(true)
        try {

            let message = "";
            if (routeParams?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().removeCustomTeamTarget(assessmentInfo?.id, team_id)
                message = response?.data?.message;

            } else if (routeParams?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().removeCulturalMaturityTeamTarget(assessmentInfo?.id, team_id)
                message = response?.data?.message;

            }
            else if (routeParams?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().removePrebuiltTeamTarget(assessmentInfo?.id, team_id)
                message = response?.data?.message;

            }
            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })

            const _teams = selectedTeams.filter(team => {
                return team?.target_id != team_id && team?.id != team_id
            })
            setSelectedTeams(_teams);


        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }

    }
    const removeGroup = async (group) => {

        const group_id = group?.target_id || group?.id;

        setLoading(true)
        try {

            let message = "";
            if (routeParams?.type === TYPE_CUSTOM) {

                const response = await new ApiHandler().removeCustomGroupTarget(assessmentInfo?.id, group_id)
                message = response?.data?.message;

            } else if (routeParams?.type === TYPE_CULTURE_MATURITY) {

                const response = await new ApiHandler().removeCulturalMaturityGroupTarget(assessmentInfo?.id, group_id)
                message = response?.data?.message;

            }
            else if (routeParams?.type === TYPE_PREBUILT) {

                const response = await new ApiHandler().removePrebuiltGroupTarget(assessmentInfo?.id, group_id)
                message = response?.data?.message;

            }
            Toast.show({
                type: 'success',
                position: 'top',
                text1: message,
            })


            const _groups = selectedGroups.filter(group => {
                return group?.target_id != group_id && group?.id != group_id
            })
            setSelectedGroups(_groups);

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }

    }


    const HorizontalDivider = () => {
        return <View style={styles.horizontalDivider} />
    }

    const Divider = () => {
        return <View style={styles.divider} />
    }

    const toggleCheck = () => {
        addRemoveTargetEntireCompany(!targetEntireCompany);
        setTargetEntireCompany(!targetEntireCompany);
    }

    const Name = React.useMemo(() => {
        return (
            <>
                <TextField
                    label="Name"
                    value={name}
                    autoCapitalize="none"
                    onChangeText={(text) => setName(text)}
                    editable={nameLifeCycleCategoryEditable}
                />

                <View
                    style={{
                        flex: 1,
                        flexDirection: !isMobile && screenSize >= ScreenSize.Medium ? "row" : "column",
                        marginTop: 24
                    }}
                >
                    <View style={styles.view} >
                        <SimpleSelect
                            list={lifeCycleList || []}
                            style={styles.select}
                            label="Lifecycle"
                            value={lifeCycle}
                            setValue={(value) => setLifecycle(value)}
                            enabled={nameLifeCycleCategoryEditable}
                        />
                    </View>

                    <Divider />

                    <View style={styles.view} >

                        <SimpleSelect
                            list={categoryList || []}
                            style={styles.select}
                            label="Category"
                            value={category}
                            setValue={(value) => setCategory(value)}
                            enabled={nameLifeCycleCategoryEditable}
                        />

                    </View>

                </View>
            </>
        )
    }, [
        lifeCycle,
        lifeCycleList,
        setLifecycle,

        category,
        categoryList,
        setCategory,

        name,

        assessmentInfo,
        assessmentInfo?.id,

        nameLifeCycleCategoryEditable
    ])


    const Target = React.useMemo(() => {
        return <>
            <ContentBlockV1
                title={'Internal (In-app) Distribution'}
            >
                <View
                    style={{
                        flex: 1,
                        flexDirection: !isMobile && screenSize >= ScreenSize.Medium ? "row" : "column",
                        marginTop: 12,
                        marginBottom: 36
                    }}
                >


                    <View style={styles.view} >
                        <SimpleSelect
                            list={teamsList || []}
                            style={styles.select}
                            label="All Teams"
                            value={team}
                            setValue={(value) => {
                                setTeam(value)
                            }}
                        />
                        <View style={{ flex: 1, flexWrap: "wrap", flexDirection: "row" }} >
                            {
                                selectedTeams?.map((team, i) => <Badge
                                    key={i}
                                    label={team?.name || team?.target?.name}
                                    _onPress={() => {
                                        removeTeam(team)
                                    }}
                                />
                                )
                            }
                        </View>
                    </View>

                    <Divider />

                    <View style={styles.view} >

                        <SimpleSelect
                            list={groupsList || []}
                            style={styles.select}
                            label="All Groups"
                            value={groups}
                            setValue={(value) => {
                                setGroups(value)
                            }}
                        />
                        <View style={{ flex: 1, flexWrap: "wrap", flexDirection: "row" }} >
                            {
                                selectedGroups?.map((group, i) => <Badge
                                    key={i}
                                    label={group?.name || group?.target?.name}
                                    _onPress={() => {
                                        removeGroup(group)
                                    }}
                                />
                                )
                            }
                        </View>
                    </View>

                </View>

                <View style={{ flexDirection: "row", alignItems: "center", marginTop: 20 }} >
                    <Checkbox onPress={toggleCheck} color={color_scheme_primary_button_background} status={targetEntireCompany ? "checked" : "unchecked"} />
                    <Text style={[styles.targetCheckbox, { color: color_scheme_primary_button_background }]} onPress={toggleCheck}>{"Target to my entire company"}</Text>
                </View>
            </ContentBlockV1>
        </>
    }, [
        team,
        teamsList,
        setTeam,
        groups,
        groupsList,
        setGroups,
        targetEntireCompany,
        assessmentInfo,
        assessmentInfo?.id,

        selectedTeams,
        selectedGroups,
        selectedTeams.length,
        selectedGroups.length
    ])

    let disabled = true;
    if (name && lifeCycle && category) {
        disabled = false
    }

    return (
        <SafeAreaView style={{ flex: 1 }} >
            {Name}
            <HorizontalDivider />

            {
                assessmentInfo?.id
                    ?
                    Target
                    :
                    <ButtonPrimary
                        _disabled={disabled}
                        label={"Save"}
                        customStyles={{
                            alignSelf: "flex-end"
                        }}
                        onPress={createCustom}
                        loading={isLoading}
                    />

            }
        </SafeAreaView>
    )
}


const Badge = ({ label, _onPress }) => {
    return (
        <View style={styles.badge} >
            <Text style={styles.badgeTxt} >{label}</Text>
            <TouchableOpacity
                onPress={_onPress}
            >
                <Icon
                    icon="close"
                    color={"#F05343"}
                    width={12}

                />
            </TouchableOpacity>

        </View>
    )
}


const styles = StyleSheet.create({
    name: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 15,
        lineHeight: 20,
        color: "#6D6D6D",
        paddingBottom: 12,
        borderBottomColor: "#959595",
        borderBottomWidth: 1
    },
    targeting: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 15,
        lineHeight: 20,
        color: "#6D6D6D",
        paddingTop: 20,
    },
    view: {
        flex: 1,
    },
    dropDown: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 20,
        height: 50,

    },
    dropDownContainer: {
        backgroundColor: "#fff",
        flex: 1,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,

    },
    itemContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 20,
        paddingVertical: 10,
        height: 50,
        // zIndex: 999,
        borderWidth: 0
    },
    listItemLabelStyle: {
        backgroundColor: "#ff0",
        // zIndex: 9999999
    },
    divider: {
        height: 10,
        width: 20
    },
    horizontalDivider: {
        marginTop: 20
    },

    select: {
        backgroundColor: "#fff",
        flex: 1,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },

    targetCheckbox: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 12,
        lineHeight: 15,
        color: "#222B45",
    },

    badge: {
        height: 20,
        marginHorizontal: 2,
        marginVertical: 2,
        flexDirection: "row",
        paddingHorizontal: 8,
        paddingVertical: 4,
        backgroundColor: "#a0a0a0",

        borderRadius: 4,
        alignItems: "center",

    },

    badgeTxt: {
        fontFamily: "WorkSans_500Medium",
        fontSize: 11,
        lineHeight: 15,
        color: "#fff",
        marginRight: 6
    }

})
