import React, { useEffect } from 'react'
import { View, Platform } from 'react-native'
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import {
  Title,
  Header,
  TitleWrapper,
  Tabs,
  Tab,
  TabTitle,
} from './styles'
import PropTypes from 'prop-types'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import themes from '@/styles/themes/black'

const TabsHeader = ({ setTab, tab }) => {
  useEffect(() => {
    setTab('integration')
  }, [])
  return (
    <>
      <Tabs horizontal={true} style={{ height: isMobile ? 60 : 60 }}>
        {(
          canAccess(permissions.MANAGE_BRANDING) &&
          Platform.OS === 'web'
        ) &&
          (
            <Tab onPress={() => { setTab('configuration') }} >
              <TabTitle selected={tab === 'configuration'} isMobile={true} >Configuration</TabTitle>
              <View style={{ height: 2, width: 35, backgroundColor: tab === 'configuration' ? '#F05343' : null, marginLeft: 25 }}></View>
            </Tab>
          )}
        {(
          canAccess(permissions.MANAGE_BRANDING) &&
          Platform.OS === 'web'
        ) &&
          (
            <Tab onPress={() => { setTab('branding') }}>
              <TabTitle isMobile={true} >Branding</TabTitle>
              <View style={{ height: 2, width: 35, backgroundColor: tab === 'branding' ? '#F05343' : null, marginLeft: 25 }}></View>
            </Tab>
          )}
        {

          <Tab onPress={() => { setTab('integration') }}>
            <TabTitle isMobile={true} >Integrations</TabTitle>
            <View style={{ height: 2, width: 35, backgroundColor: tab === 'integration' ? '#F05343' : null, marginLeft: 25 }}></View>
          </Tab>
        }

        {
          <Tab onPress={() => { setTab('notifications') }}>
            <TabTitle selected={tab === 'notifications'} isMobile={true} >Notifications</TabTitle>
            <View style={{ height: 2, width: 35, backgroundColor: tab === 'notifications' ? '#F05343' : null, marginLeft: 25 }}></View>
          </Tab>
        }

        {/* <Tab onPress={() => {setTab('integrations')}}>
          <TabTitle selected={tab === 'integrations'}>Integrations</TabTitle>
        </Tab>
        <Tab onPress={() => {setTab('billing')}}>
          <TabTitle selected={tab === 'billing'}>Billing</TabTitle>
        </Tab>
        <Tab onPress={() => {setTab('notifications')}}>
          <TabTitle selected={tab === 'notifications'}>Notifications</TabTitle>
        </Tab> */}

        {/* {canAccess(permissions.MANAGE_CUSTOM_USER_ROLE) ?
          <Tab onPress={() => { setTab('userRoles') }}>
            <TabTitle isMobile={true}>User Roles</TabTitle>
            <View style={{ height: 2, width: 38, backgroundColor: tab === 'userRoles' ? '#F05343' : null, marginLeft: 25 }}></View>
          </Tab>
          :
          null
        } */}
      </Tabs>
    </>
  )
}

TabsHeader.propTypes = {
  setTab: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
}

export default TabsHeader
