import PropTypes from 'prop-types'
import {UserInterface} from '@/interfaces/user'

export const CommentInterface = PropTypes.shape({
  author: UserInterface,
  likes: PropTypes.arrayOf(UserInterface),
  comment: PropTypes.string,
})

export const PostInterface = PropTypes.shape({
  id: PropTypes.number,
  text: PropTypes.string,
  liked: UserInterface,
  likes: PropTypes.arrayOf(UserInterface),
  comments: PropTypes.arrayOf(CommentInterface),
  creator: UserInterface,
})
