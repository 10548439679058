import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { formatDistance } from 'date-fns'
import { Avatar, Icon, } from '@/components'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import theme from '@/styles/themes/black'
import { getMember } from '@/redux/selectors/auth'

import {
  Container,
  InfoContainer,
  AuthorName,
  AuthorOffice,
  CommentInfoContainer,
  CommentText,
  TimeAgo,
  LikesContainer,
  LikeContainer,
  LikeButton,
  ButtonText,
  ButtonTextLike,
  ButtonNavigation,
  MenuContainer,
} from './styles'
import { actions } from '@/redux/reducers/posts'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import DeleteCommentModal from './DeleteCommentModal'
import { actions as teamActions } from '@/redux/reducers/teams'
import { actions as groupActions } from '@/redux/reducers/groups'
import { Platform, Text } from 'react-native'

import MentionsTextView from '@/components/MentionTextView'

const Comment = ({
  id,
  author_id,
  authorSource,
  creatorName,
  authorOffice,
  comment,
  createdAt,
  likesCount,
  post_id,
  contentType,
  handleDeleteComment,
  onCloseMobile,
  postType,
  idPresent,
  fetchPost
}) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const user = useSelector(getMember)
  const isOwner = author_id == user.id

  const [isDeleteCommentVisible, setDeleteCommentVisible] = useState(false)

  const handleLike = () => {
    dispatch(actions.like({ id, user, postType: 'App\\Comment', post_id }, (error, success) => {
      if (error) {
        dispatch(actions.comment_list({ postType: contentType, id: post_id }))
      }
      if (success) {
        if (Platform.OS !== 'web') {
          fetchPost();
        }
        onCloseMobile()
        // dispatch(actions.feeds_index({ page: 1 }, () => {}))
        if (postType == 'team') {
          dispatch(teamActions.comment_list({ postType: contentType, id: post_id }))
        } else if (postType == 'group') {
          dispatch(groupActions.comment_list({ postType: contentType, id: post_id }))
        } else {
          dispatch(actions.comment_list({ postType: contentType, id: post_id }))
        }
      }
    }))
  }

  const handleDelete = useCallback(() => {
    setDeleteCommentVisible(true)
  }, [setDeleteCommentVisible])

  const expansiveChildrens = [
    { text: 'Delete Comment', icon: 'delete', onPress: handleDelete }
  ]

  return (
    <Container>
      {authorSource ? (
        <ButtonNavigation onPress={() => navigation.navigate('Profile', { id: author_id })}>
          <Avatar source={authorSource ? { uri: authorSource } : null} size={45} />
        </ButtonNavigation>)
        :
        (<ButtonNavigation onPress={() => navigation.navigate('Profile', { id: author_id })}>
          <Avatar source={null} size={45} />
        </ButtonNavigation>
        )}
      <InfoContainer>
        <CommentInfoContainer>
          <ButtonNavigation onPress={() => navigation.navigate('Profile', { id: author_id })}>
            <AuthorName>{creatorName ?? ''}</AuthorName>
          </ButtonNavigation>
          <AuthorOffice>{authorOffice ?? ''}</AuthorOffice>
          {/* <CommentText>{comment ?? ''}</CommentText> */}
          <CommentText>
            <MentionsTextView str={comment} startword={'##'} endword={'@@'} innerFormatStart={'['} innerFormatEnd={']'} />
          </CommentText>
        </CommentInfoContainer>
        <LikesContainer>
          <TimeAgo>{String(formatDistance(new Date(createdAt), Date.now())) ?? ''}</TimeAgo>
          <LikeButton onPress={handleLike}>
            <LikeContainer>
              <ButtonText>
                Like
              </ButtonText>
              <Icon
                icon="like"
                height={13}
                width={15}
                color={theme.info.standard}
              />
              <ButtonTextLike>{likesCount}</ButtonTextLike>
            </LikeContainer>
          </LikeButton>
        </LikesContainer>
      </InfoContainer>
      {(isOwner || canAccess(permissions.DELETE_POST_COMMENTS))
        && (
          <>
            <ExpansiveMenu
              mainIconColor='#000'
              mainIconBackground='transparent'
              expansiveChildrens={expansiveChildrens} />
          </>
        )
      }
      <DeleteCommentModal
        id={id}
        contentType={contentType}
        post_id={post_id}
        isVisible={isDeleteCommentVisible}
        setVisible={setDeleteCommentVisible}
        onClose={handleDeleteComment}
        onCloseMobile={onCloseMobile}
        fetchPost={fetchPost}
      />
    </Container>
  )
}

Comment.propTypes = {
  id: PropTypes.number.isRequired,
  author_id: PropTypes.number.isRequired,
  authorSource: PropTypes.number,
  creatorName: PropTypes.string.isRequired,
  authorOffice: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  likesCount: PropTypes.number,
  post_id: PropTypes.number,
  handleDeleteComment: PropTypes.func,
  onCloseMobile: PropTypes.func,
  contentType: PropTypes.string,
  postType: PropTypes.string,
  idPresent: PropTypes.number,
}

export default Comment