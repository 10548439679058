import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ChevronRight(props) {
  return (
    <Svg width="4" height="6" viewBox="0 0 4 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M0.295044 5.295L2.58504 3L0.295044 0.705L1.00004 0L4.00004 3L1.00004 6L0.295044 5.295Z" />
    </Svg>
  )
}

ChevronRight.propTypes = Svg.propTypes
