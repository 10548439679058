import styled from 'styled-components/native'

import themes from '@/styles/themes/black'
import { shadowLearningCard } from '@/styles/shadows'
import {
  Headline6Strong,
  Paragraph2,
  Subtitle2,
  Paragraph1,
} from '@/styles/fonts'
import { TouchableOpacity, View, Image, Text } from 'react-native'

export const ModuleContainer = styled(TouchableOpacity)`
  ${shadowLearningCard};
`

export const TopView = styled(View)`
  flex-direction: row;
`
export const RightView = styled(View)`
  justify-content: center;
  padding: 20px;
  flex: 1;
`

export const ModuleImageView = styled(View)`
  flex: 1;
`

export const ModuleImage = styled(Image)`
  height: 142px;
  width: 100%;
`

export const ModuleDetail = styled(View)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const PlayButtonView = styled(View)`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

export const DetailText = styled(Text)`
  font-size: 12px;
  font-weight: 400;
  color: #6d6d6d;
`

export const NameView = styled(View)``

export const NameText = styled(Text)`
  font-size: 12px;
  font-weight: 500;
`

export const ModuleDetailBottom = styled(View)`
  padding: 15px 5px;
`
