import { useSelector } from "react-redux";


import ConversationView from "./ConversationView";

import { unexpectedErrorNotification, getLastMessage, isMyMessage, updateCurrentConvo } from "../../../helpers";
import { getMember } from "@/redux/selectors/auth";
import { useContext } from "react";
import ChatContext from "@/contexts/ChatContext";
import { View, Text, Dimensions } from 'react-native'




function setUnreadMessagesCount(
  currentconvoSid,
  convoSid,
  unreadMessages,
  updateUnreadMessages
) {
  if (currentconvoSid == convoSid && unreadMessages[convoSid] !== 0) {
    updateUnreadMessages(convoSid, 0);
    return 0;
  }
  if (currentconvoSid == convoSid) {
    return 0;
  }
  return unreadMessages[convoSid];
}

const ConversationsList = () => {
  const user = useSelector(getMember);
  const { typingData, participants, unreadMessages, messages, conversations, sid, updateCurrentConversation, updateParticipants, updateUnreadMessages, setLastReadIndex, addNotifications } = useContext(ChatContext);
  if (conversations === undefined || conversations === null) {
    return <div className="empty" />;
  }

  return (
    <div id="conversation-list">
      {!!conversations.length ? conversations.map((convo) => (
        <ConversationView
          key={convo.sid}
          convoId={convo.sid}
          setSid={updateCurrentConversation}
          currentConvoSid={sid}
          lastMessage={getLastMessage(
            messages?.[convo.sid],
            typingData[convo.sid] ?? []
          )}
          messages={messages?.[convo.sid] ?? []}
          typingInfo={typingData[convo.sid] ?? []}
          myMessage={isMyMessage(messages?.[convo.sid], user?.id?.toString())}
          unreadMessagesCount={setUnreadMessagesCount(
            sid,
            convo.sid,
            unreadMessages,
            updateUnreadMessages
          )}
          updateUnreadMessages={updateUnreadMessages}
          participants={participants[convo.sid] ?? []}
          convo={convo}
          onClick={async () => {
            try {
              setLastReadIndex(convo.lastReadMessageIndex ?? -1);
              await updateCurrentConvo(
                updateCurrentConversation,
                convo,
                updateParticipants
              );
              //update unread messages
              updateUnreadMessages(convo.sid, 0);
              //set messages to be read
              const lastMessage =
                messages?.[convo.sid].length &&
                messages?.[convo.sid][messages?.[convo.sid].length - 1];
              if (lastMessage && lastMessage.index !== -1) {
                await convo.updateLastReadMessageIndex(lastMessage.index);
              }
            } catch (error) {
              alert(error);
              unexpectedErrorNotification(addNotifications);
            }
          }}
        />
      )) : (
        <View style={{ flex: 1, minHeight: Dimensions.get('window').height, minWidth: Dimensions.get('window').width / 4.7, justifyContent: 'center', alignItems: 'center' }}>
          <Text>No Conversations Found</Text>
        </View>
      )}
    </div>
  );
};

export default ConversationsList;
