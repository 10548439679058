import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatDistance } from 'date-fns'
import { Avatar, Icon, } from '@/components'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import theme from '@/styles/themes/black'
import { getMember } from '@/redux/selectors/auth'
import * as brandSelectors from '@/redux/selectors/branding'

import { actions } from '@/redux/reducers/posts'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/native'
import Hyperlink from 'react-native-hyperlink'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import DeleteCommentModal from '@/components/Feed/Card/Content/Components/Comments/Comment/Delete'
import { actions as teamActions } from '@/redux/reducers/teams'
import { actions as groupActions } from '@/redux/reducers/groups'
import { Platform, Text, View, TouchableOpacity } from 'react-native'

import MentionsTextView from '@/components/MentionTextView'

import styles from './styles.js'

const Comment = ({
    id,
    author_id,
    authorSource,
    creatorName,
    authorOffice,
    comment,
    createdAt,
    likesCount,
    post_id,
    contentType,
    handleDeleteComment,
    onCloseMobile,
    postType,
    idPresent,
    fetchPost
}) => {
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const route = useRoute();
    const user = useSelector(getMember)
    const branding = useSelector(brandSelectors.getSettings)
    const isOwner = author_id == user.id

    const [isDeleteCommentVisible, setDeleteCommentVisible] = useState(false)
    const [interactiveTextColor, setInteractiveTextColor] = React.useState('rgb(240, 83, 67)');

    useEffect(() => {
        if (branding && branding.color_scheme_accents) {
            setInteractiveTextColor(branding.color_scheme_accents)
        }
    }, [])

    const toggleLike = () => {
        dispatch(actions.like_article({ id, user, postType: 'App\\Comment', post_id }, (error, success) => {
            dispatch(actions.comment_list({ postType: contentType, id: post_id }))

            if (route.name == 'CommentPost') {
                navigation.setParams({ stamp: Math.random() })
            }
        }))
    }

    const handleDelete = useCallback(() => {
        setDeleteCommentVisible(true)
    }, [setDeleteCommentVisible])

    const renderTextView = (str) => {
        const startword = "##"
        const endword = "@@"
        const innerFormatStart = "["
        const innerFormatEnd = "]"

        if (str && str.length) {
            let result = [];
            let string = str.split(startword).map(el => el.split(endword)).reduce((acc, curr) => acc.concat(curr))

            string.map((substr) => {
                let trimmedStr = substr.replace(/(^"|"$)/g, '')
                let strArray = trimmedStr.split(innerFormatStart).map(el => el.split(innerFormatEnd)).reduce((acc, curr) => acc.concat(curr));
                if (strArray.length >= 3) {
                    let mentionArray = strArray[1].split(',');

                    result.push(
                        <Text
                            onPress={() => {
                                navigation.navigate('Profile', { id: mentionArray[0] })
                            }}
                            style={{
                                color: interactiveTextColor,
                                fontWeight: 'bold',
                                lineHeight: 20,
                            }}
                        >
                            @{mentionArray[1].replace(/['"]+/g, '')}
                        </Text>
                    )
                } else {
                    result.push(
                        <Text>
                            {substr}
                        </Text>
                    )
                }
            })

            return (
                <Hyperlink
                    linkDefault={true}
                    linkStyle={{
                        color: interactiveTextColor
                    }}
                >
                    <Text
                        style={{
                            lineHeight: 20,
                            color: '#000000'
                        }}
                    >
                        {result}
                    </Text>
                </Hyperlink>
            )

        } else {
            return (
                <Hyperlink
                    linkDefault={true}
                    linkStyle={{
                        color: interactiveTextColor
                    }}
                >
                    <Text>
                        {str}
                    </Text>
                </Hyperlink>
            )
        }
    }

    const expansiveChildrens = [
        { text: 'Delete Comment', icon: 'delete', onPress: handleDelete }
    ]

    return (
        <>
            <View style={styles.Container}>
                <TouchableOpacity
                    style={styles.AvatarButton}
                    onPress={() => navigation.navigate('Profile', { id: author_id })}
                >
                    <Avatar source={authorSource ? { uri: authorSource } : null} size={45} />
                </TouchableOpacity>

                <View style={styles.CommentContainer}>
                    <View style={styles.CommentBubbleContainer}>
                        <TouchableOpacity
                            style={styles.CommentBubbleAuthorButton}
                            onPress={() => navigation.navigate('Profile', { id: author_id })}
                        >
                            <Text style={styles.CommentBubbleAuthorButtonName}>{creatorName ?? ''}</Text>

                            {authorOffice ? (
                                <Text style={styles.CommentBubbleAuthorButtonRole}>{authorOffice}</Text>
                            ) : null}
                        </TouchableOpacity>
                        <View style={styles.CommentBubbleCommentContainer}>
                            {renderTextView(comment)}
                        </View>
                    </View>
                    <View style={styles.CommentInteractionsContainer}>
                        <Text style={styles.CommentTimestamp}>{String(formatDistance(new Date(createdAt), Date.now())) ?? ''}</Text>
                        <TouchableOpacity
                            style={styles.CommentLikeButtonContainer}
                            onPress={toggleLike}
                        >
                            <View style={styles.CommentLikeButton}>
                                <Text style={styles.CommentLikeButtonLabel}>
                                    Like
                                </Text>
                                <Icon
                                    icon="like"
                                    height={13}
                                    width={15}
                                    color={'#1B1B1B'}
                                />
                                <Text style={styles.CommentLikeButtonCount}>{likesCount}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={styles.MenuContainer}>
                    {(isOwner || canAccess(permissions.DELETE_POST_COMMENTS))
                        && (
                            <>
                                <ExpansiveMenu
                                    mainIconColor='#000'
                                    mainIconBackground='transparent'
                                    expansiveChildrens={expansiveChildrens}
                                />
                            </>
                        )
                    }
                </View>
            </View>

            <DeleteCommentModal
                id={id}
                contentType={contentType}
                post_id={post_id}
                isVisible={isDeleteCommentVisible}
                setVisible={setDeleteCommentVisible}
            />
        </>
    )
}

Comment.propTypes = {
    id: PropTypes.number.isRequired,
    author_id: PropTypes.number.isRequired,
    authorSource: PropTypes.number,
    creatorName: PropTypes.string.isRequired,
    authorOffice: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    likesCount: PropTypes.number,
    post_id: PropTypes.number,
    handleDeleteComment: PropTypes.func,
    onCloseMobile: PropTypes.func,
    contentType: PropTypes.string,
    postType: PropTypes.string,
    idPresent: PropTypes.number,
}

export default Comment