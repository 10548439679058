import request from '@/helpers/request'

export async function getFeatured() {
  return await request('learning/main/getFeatured', {
    method: 'GET',
  })
}

export async function getFilters() {
  return await request('learning/main/getFilters', {
    method: 'GET',
  })
}

export async function getArticles({
  search,
  five_s_category_id,
  lifecycle_stage_id,
}) {
  const params = { search, five_s_category_id, lifecycle_stage_id }
  return await request('learning/main/getArticles', {
    method: 'GET',
    qsParams: params,
  })
}

export async function getCourses({
  search,
  five_s_category_id,
  lifecycle_stage_id,
}) {
  const params = { search, five_s_category_id, lifecycle_stage_id }
  return await request('learning/main/getCourses', {
    method: 'GET',
    qsParams: params,
  })
}

export async function getEvents({
  search,
  five_s_category_id,
  lifecycle_stage_id,
}) {
  const params = { search, five_s_category_id, lifecycle_stage_id }
  return await request('learning/main/getEvents', {
    method: 'GET',
    qsParams: params,
  })
}

export async function readLater(article) {
  return await request('articles/read_later', {
    method: 'PUT',
    body: article,
  })
}
