import request from '@/helpers/request'

export async function store(object) {
  return await request('community/teams/store', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    body: object,
  })
}

export async function update(object) {
  return await request('community/teams/update', {
    method: 'PUT',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    body: object,
  })
}

export async function index({ page, ...params }) {
  const filter = 'employee'
  return await request('community/teams/index', {
    method: 'GET',
    qsParams: { page, filter },
  })
}

export async function other({ page, ...params }) {
  const filter = 'other'
  return await request('community/teams/index', {
    method: 'GET',
    qsParams: { page, filter },
  })
}

export async function like({ id, postType }) {
  return await request('posts/like', {
    method: 'PUT',
    body: JSON.stringify(
      {
        id: id,
        type: postType
      }
    ),
  })
}
export async function join(object) {
  return await request('community/teams/join', {
    method: 'PUT',
    body: object,
  })
}

export async function leave(object) {
  return await request('community/teams/leave', {
    method: 'PUT',
    body: object,
  })
}

export async function show({ id }) {
  return await request('community/teams/show', {
    method: 'GET',
    qsParams: { id }
  })
}

export async function members({ id }) {
  return await request('community/teams/members', {
    method: 'GET',
    qsParams: { id }
  })
}

export async function removeMember({ team_id, member_id	 }) {
  return await request('community/teams/remove-member', {
    method: 'GET',
    qsParams: { team_id, member_id	 }
  })
}

export async function remove({ id }) {
  return await request('community/teams/delete', {
    method: 'DELETE',
    qsParams: { id }
  })
}

export async function invite({ id, employees }) {
  return await request('community/teams/invite', {
    method: 'PUT',
    qsParams: { id, employees }
  })
}

export async function posts({ id, page }) {
  return await request('home/feed/getItems', {
    method: 'GET',
    qsParams: { attachment_id: id, page, attachment_type: 'App\\Team' }
  })
}
export async function comment_list({ id, postType }) {
  return await request('common/comments/getComments', {
    method: 'GET',
    qsParams: {
      id,
      type: postType
    }
  })
}
