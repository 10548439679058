import React, { useState } from 'react'

import {
    TouchableOpacity,
    View,
    Image,
    StyleSheet,
    Modal,
    ActivityIndicator,
    SafeAreaView,
    Text,
    Platform,
} from "react-native";

import AssessmentCard from '@/components/Feed/Card/Assessment'
import PostContentCard from '@/components/Feed/Card/Content/Post'
import LearningContentCard from '@/components/Feed/Card/Content/Learning'

import styles from './styles.js';

const cardOutput = (post) => {
    if (post?.Type == 'App\\Article') {
        return (
            <>
                <LearningContentCard
                    post={post.Data}
                    type={post.Type}
                />
            </>
        )
    }

    if (post?.Type == 'App\\Post') {
        return (
            <>
                <PostContentCard
                    post={post.Data}
                    type={post.Type}
                />
            </>
        )
    }

    if (post?.Type == 'App\\AssessmentQuestion') {
        return (
            <>
                <AssessmentCard
                    post={post.Data}
                />
            </>
        )
    }

    if (post?.Type == 'App\\Course') {
        return (
            <>
                <LearningContentCard
                    post={post.Data}
                    type={post.Type}
                />
            </>
        )
    }

    if (post?.Type == 'App\\Event') {
        return (
            <>
                <LearningContentCard
                    post={post.Data}
                    type={post.Type}
                />
            </>
        )
    }
}

const Card = ({
    post
}) => {
    return (
        <View style={styles.Container}>
            {cardOutput(post)}
        </View>
    )
}

export default Card