import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { Ionicons, AntDesign } from '@expo/vector-icons'
import theme from '@/styles/themes/black'
import { SavedImageContainer } from './styles.web'

const SavedImage = ({ image: { url, id }, deleteFileAction }) => {
  const [showUrl, setShowUrl] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const dispatch = useDispatch()

  const copy = useCallback(() => {
    navigator.clipboard.writeText(url)
  }, [])

  const handleDelete = useCallback(() => {
    dispatch(deleteFileAction(id, (err, success) => {
      if (success) {
        setModalVisible(false)
      }
      if(err) {
        setModalVisible(false)
      }
    }))
  }, [])

  return (
    <SavedImageContainer>
      <button onClick={() => setModalVisible(true)}>
        <AntDesign name="closecircleo" size={24} color={theme.primary.standard} />
      </button>
      <img src={url} />
      <span>url: {_.slice(url, 0, 10)}...</span>
      <div>
        <button onClick={copy}>
					Copy url
        </button>
        <button onClick={() => setShowUrl(true)}>
					Show url
        </button>
      </div>
      {
        showUrl && (
          <div className="url">
            <button onClick={() => setShowUrl(false)}>
              <Ionicons name="close" size={24} color="#fff" />
            </button>
            <span>{url}</span>
          </div>
        )
      }
      {
        isModalVisible && (
          <div className="modal">
            <div>
              <span>Do you really wants delete this image?</span>
              <div>
                <button onClick={handleDelete}>
									Yes
                </button>
                <button onClick={() => setModalVisible(false)}>
									No
                </button>
              </div>
            </div>
          </div>
        )
      }
    </SavedImageContainer>
  )
}

SavedImage.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    id: PropTypes.number
  }),
  deleteFileAction: PropTypes.func,
}

export default SavedImage
