import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import { useNavigation } from '@react-navigation/native';
import { isMobile } from '@/styles';

const BackArrow = (props) => {
    const navigation = useNavigation();
    const {color = theme.basics[900]} = props || {};
    return (
        <TouchableOpacity 
            onPress={() => props.onPress?props.onPress():navigation?.goBack?.()} 
            style={{marginVertical: 3, marginHorizontal: isMobile ? 18 : 5, }}>
            <Icon
                icon="arrowLeft"
                color={color}
                height={17}
                width={16}
            />
        </TouchableOpacity>
    );
};

export default BackArrow;