import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import _ from 'lodash'

// Constants
export const constants = {
  GET_LIST: createSagaAction('GET_LIST'),
  GET_COURSE_WITH_ID: createSagaAction('GET_COURSE_WITH_ID'),
  CREATE_COURSE: createSagaAction('CREATE_COURSE'),
  UPDATE_COURSE: createSagaAction('UPDATE_COURSE'),
  GET_FEATURED_COURSE: createSagaAction('GET_FEATURED_COURSE'),
  GET_READ_LATER: createSagaAction('GET_READ_LATER'),
  TOGGLE_FEATURED_COURSE: createSagaAction('TOGGLE_FEATURED_COURSE'),
  TOGGLE_STATUS_COURSE: createSagaAction('TOGGLE_STATUS_COURSE'),
  TOGGLE_READ_LATER_COURSE: createSagaAction('TOGGLE_READ_LATER_COURSE'),
  DELETE_COURSE: createSagaAction('DELETE_COURSE'),
  TRACKING_TIME_COURSE: createSagaAction('TRACKING_TIME_COURSE')
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  getList: (object) => ({
    type: constants.GET_LIST.ACTION,
    payload: object,
  }),
  getCourseWithIdAction: (object, next) => ({
    type: constants.GET_COURSE_WITH_ID.ACTION,
    payload: { ...object },
    next,
  }),
  createCourseAction: (object, next) => ({
    type: constants.CREATE_COURSE.ACTION,
    payload: object,
    next,
  }),
  updateCourseAction: (object, next) => ({
    type: constants.UPDATE_COURSE.ACTION,
    payload: object,
    next,
  }),
  getFeaturedAction: (object, next) => ({
    type: constants.GET_FEATURED_COURSE.ACTION,
    payload: object,
    next,
  }),
  getReadLaterAction: (object, next) => ({
    type: constants.GET_READ_LATER.ACTION,
    payload: object,
    next,
  }),
  toggleFeaturedAction: (object, next) => ({
    type: constants.TOGGLE_FEATURED_COURSE.ACTION,
    payload: object,
    next,
  }),
  toggleStatusAction: (object, next) => ({
    type: constants.TOGGLE_STATUS_COURSE.ACTION,
    payload: object,
    next,
  }),
  toggleReadLaterAction: (object, next) => ({
    type: constants.TOGGLE_READ_LATER_COURSE.ACTION,
    payload: object,
    next,
  }),
  deleteCourseAction: (object, next) => ({
    type: constants.DELETE_COURSE.ACTION,
    payload: object,
    next,
  }),
  trackActiveTime: (params, next) => ({
    type: constants.TRACKING_TIME_COURSE.ACTION,
    ...params,
    next,
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.GET_LIST.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_LIST.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      // courses: action.payload.data.data,
      courses: action.payload.data,
    }
  },
  [constants.GET_COURSE_WITH_ID.ACTION]: (state) => {
    return { ...state, error: null, loading: true, course: null }
  },
  [constants.GET_COURSE_WITH_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      course: action.payload.data,
    }
  },
  [constants.CREATE_COURSE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.CREATE_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    }
  },

  [constants.UPDATE_COURSE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.UPDATE_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    }
  },

  [constants.GET_FEATURED_COURSE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_FEATURED_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      featured: action.payload.data,
      loading: false,
      error: null,
    }
  },

  [constants.GET_READ_LATER.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_READ_LATER.SUCCESS]: (state, action) => {
    return {
      ...state,
      readLater: action.payload.data,
      loading: false,
      error: null,
    }
  },

  [constants.TOGGLE_FEATURED_COURSE.ACTION]: (state) => {
    return { ...state, error: null, featuredLoading: true }
  },
  [constants.TOGGLE_FEATURED_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      featuredLoading: false,
      error: null,
    }
  },

  [constants.TOGGLE_STATUS_COURSE.ACTION]: (state) => {
    return { ...state, error: null, statusLoading: true }
  },
  [constants.TOGGLE_STATUS_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      statusLoading: false,
      error: null,
    }
  },

  [constants.TOGGLE_READ_LATER_COURSE.ACTION]: (state) => {
    return { ...state, error: null, readLaterLoading: true }
  },
  [constants.TOGGLE_READ_LATER_COURSE.SUCCESS]: (state, action) => {
    return {
      ...state,
      readLaterLoading: false,
      error: null,
    }
  },

  [constants.DELETE_COURSE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.DELETE_COURSE.SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    }
  },
  [constants.TRACKING_TIME_COURSE.ACTION]: (state, params) => {
    return {
      ...state,
      errors: null,
      loading: false,
      timeTracked: params.timeTracked,
      courseId: params.courseId,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: false,
  readLaterLoading: false,
  statusLoading: false,
  featuredLoading: false,
  statusLoading: false,
  courses: [],
  course: null,
  featured: [],
  readLater: [],
  timeTracked: 0,
  courseId: 0,
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})
