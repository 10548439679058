import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Milestone_MilestoneContainer: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        paddingHorizontal: 10,
        paddingVertical: 10,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 8,
        elevation: 3,
        ...Platform.select({
            ios: {
                width: '100%',
                marginBottom: 10,
                marginTop: 2,
                marginHorizontal: 2
            },
            android: {
                flex: 1,
                marginBottom: 10,
                marginTop: 2,
                marginHorizontal: 2
            },
            web: {
                margin: 5,
                width: 350,
                minHeight: 150
            }
        })
    },
    Milestone_MilestoneCard: {
        width: '100%',
        ...Platform.select({
            ios: {

            },
            android: {

            },
            web: {
                height: '100%'
            }
        })
    },
    Milestone_MilestoneCardContentContainer: {
        marginBottom: 20
    },
    Milestone_MilestoneCardContentContainerHeaderContainer: {
    },
    Milestone_MilestoneCardContentContainerHeaderContainerTitleContainer: {
        marginBottom: 10
    },
    Milestone_MilestoneCardContentContainerHeaderContainerTitleContainerTitle: {
        fontSize: 13,
        fontWeight: "500",
        fontFamily: 'WorkSans_400Regular',
        lineHeight: 17
    },
    Milestone_MilestoneCardContentContainerHeaderContainerCriteriaContainer: {
        marginBottom: 10,
        alignItems: 'flex-start',
    },
    Milestone_MilestoneCardContentContainerHeaderContainerCriteriaContainerBadge: {
        backgroundColor: "#f9675a",
        paddingHorizontal: 6,
        paddingVertical: 3,
        borderRadius: 4,
        justifyContent: "center",
        alignItems: "center",
    },
    Milestone_MilestoneCardContentContainerHeaderContainerCriteriaContainerBadgeText: {
        fontSize: 10,
        lineHeight: 12,
        color: "#FFFFFF",
    },
    Milestone_MilestoneCardContentContainerDescriptionContainer: {

    },
    Milestone_MilestoneCardContentContainerDescriptionContainerText: {
        fontSize: 12,
        lineHeight: 17,
        fontWeight: "400",
        marginVertical: 5,
        width: '100%',
    },
    Milestone_MilestoneCardProgressContainer: {
        position: "absolute",
        bottom: 2,
        right: 4,
        left: 4,
        flex: 1,
        backgroundColor: "#fff",
        flexDirection: "row",
        height: 12,
    },
    Milestone_MilestoneCardProgressBarContainer: {
        flex: 0.8,
        flexDirection: "row",
        alignItems: "center"
    },
    Milestone_MilestoneCardProgressBar: {
        borderRadius: 7,
        backgroundColor: "#00B15C",
        height: 12,
    },
    Milestone_MilestoneCardProgressPercentageContainer: {
        flex: 0.2,
    },
    Milestone_MilestoneCardProgressPercentageText: {
        textAlign: "right",
        position: "absolute",
        right: 0,
        bottom: 0,
        fontSize: 12,
        width: 100
    },


    MilestoneModal_DescriptionText: {
        fontSize: 12,
        fontWeight: "400",
        lineHeight: 16,
    },
    MilestoneModal_ProgressPointsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    MilestoneModal_ProgressPointsText: {
        fontSize: 20,
        fontWeight: "500",
        lineHeight: 40,
        lineHeight: -0.8,
    },
    MilestoneModal_ProgressPointsLabel: {
        fontSize: 10,
        fontWeight: "400",
        lineHeight: 13,
    },
    MilestoneModal_ProgressBarContainer: {
        flex: 1,
        borderRadius: 7,
        backgroundColor: "#fff",
        flexDirection: "row",
        height: 12,
        marginTop: 15
    },
    MilestoneModal_ProgressBarHighlightContainer: {
        flex: 0.6,
        flexDirection: "row",
        alignItems: "center"
    },
    MilestoneModal_ProgressBarHighlight: {
        borderRadius: 7,
        backgroundColor: "#00B15C",
        height: 12,
    },
    MilestoneModal_ProgressBarCountContainer: {
        flex: 0.4
    },
    MilestoneModal_ProgressBarCount: {
        textAlign: "right",
        position: "absolute",
        right: 0,
        bottom: 0,
        fontSize: 12,
        width: 100
    },
    MilestoneModal_RewardImage: {
        height: 150,
        width: 250,
        borderRadius: 4,
        marginBottom: 5
    },
    MilestoneModal_RewardTitle: {
        fontSize: 14,
        fontWeight: "400",
        flex: 1,
    },
    MilestoneModal_AnalyticsContainer: {
        flexDirection: "row",
        zIndex: -999
    },
    MilestoneModal_AnalyticsItemContainer: {
        width: '33%'
    },
    MilestoneModal_AnalyticsItemMembersContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginEnd: 15,
    },
    MilestoneModal_AnalyticsItemMemberPhoto: {
        height: 35,
        width: 35,
        borderRadius: 33,
        marginEnd: 8,
    },
    MilestoneModal_AnalyticsItemMembersCount: {
        fontSize: 15,
        lineHeight: 20,
        color: "#fff",
        fontWeight: "400",
        position: "absolute",
        top: 10,
        left: 6,
    },
    MilestoneModal_AnalyticsItemLabel: {
        fontSize: 12,
        lineHeight: 16,
        fontWeight: "400",
        marginTop: 5,
    },
    MilestoneModal_CriteriaContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    MilestoneModal_CriteriaItemContainer: {

    },
    MilestoneModal_CriteriaItemPoints: {
        fontSize: 20,
        fontWeight: "500",
        lineHeight: 40,
        lineHeight: -0.8,
    },
    MilestoneModal_CriteriaItemLabel: {
        fontSize: 10,
        fontWeight: "400",
        lineHeight: 13,
    }

});
