import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {actions} from '@/redux/reducers/roles'
import {Text, ScrollView, View} from 'react-native'
import * as selectors from '@/redux/selectors/roles'
import {ProgressBar} from 'react-native-paper'
import {
  Container,
  CheckboxContainer,
  BackButton,
} from '../styles'
import {Button, Checkbox, Icon, SimpleModal} from '@/components'
import {Header, Title} from '@/pages/Community/CreateEditGroup/styles'
import theme from '@/styles/themes/black'
import PropTypes from 'prop-types'
import {RoleInterface} from '@/interfaces/role'
import _ from 'lodash'
import {CaptionMedium} from '@/styles/fonts'
import Toast from 'react-native-toast-message'
import {isMobile} from '@/styles'


const EditRole = ({ isVisible, setVisible, role, onClose }) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectors.loading)
  const permissions = useSelector(selectors.permissions)
  const [selected, setSelected] = useState([])

  useEffect(() => {
    dispatch(actions.dataprovider(() => {}))
    setSelected(role?.permissions)
  }, [role])

  const handleSubmit = () => {
    dispatch(actions.update({
      id: role.id,
      permissions: selected.map(permission => permission.id),
      name: role.name,
    }, (error, success) => {
      Toast.show({
        type: error ? 'error' : 'success',
        position: 'bottom',
        text1: error ? error.message : success.message,
      })
      if (!success) {
        return
      }
      setVisible(false)
      if (onClose) {
        onClose(true)
      }
    }))
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleChange = useCallback((permission) => {
    const newSelection = _.clone(selected)
    if (newSelection.some(item => item.id === permission.id)) {
      newSelection.splice(newSelection.findIndex(item => item.id === permission.id), 1)
    } else {
      newSelection.push(permission)
    }
    setSelected(newSelection)
  }, [selected])


  const renderPermission = useCallback((permission) => {
    const status = selected?.some(sel => sel.id === permission.id) ? 'checked' : 'unchecked'
    return (
      <CheckboxContainer
        key={permission.id}
        disabled={!role?.company_id}
        onPress={() => handleChange(permission)}>
        <Text style={{ lineHeight: 36, flex: 1 }}>{permission.name}</Text>
        <Checkbox status={status} />
      </CheckboxContainer>
    )
  }, [selected, handleChange])

  return (
    <SimpleModal isVisible={isVisible}>
      <ProgressBar indeterminate={true} visible={loading} />
      <Container style={{ maxWidth: 700, maxHeight: '100%', width: '100%', background:'#FFF', padding: 20 }}>
        <Header>
          <Title style={{ textAlign: 'left' }}>{ role?.name }</Title>
          <BackButton onPress={handleCancel}>
            <Icon icon="close" height={14} width={14} color={theme.basics[900]} />
          </BackButton>
        </Header>
        <ScrollView>
          <View style={{ display: 'flex', flexDirection: !isMobile ? 'row' : 'column' }}>
            <View style={{ flex: 1 }}>
              {permissions?.map((permission, i) => i < permissions.length / 2 ? (renderPermission(permission)) : null)}
            </View>
            <View style={{ flex: 1, marginLeft: !isMobile ? 20 : 0, marginTop: isMobile ? 20 : 0 }}>
              {permissions?.map((permission, i) => i >= permissions.length / 2 ? (renderPermission(permission)) : null)}
            </View>
          </View>
        </ScrollView>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20 }}>
          <Button
            style={{ flex: isMobile ? 1 : 0, marginRight: 10 }}
            color="white"
            onPress={handleCancel}
          >
            <CaptionMedium color="black" style={{ fontSize: 17 }}>
							Cancel
            </CaptionMedium>
          </Button>
          <Button
            style={{ flex: isMobile ? 1 : 0 }}
            color="black"
            disabled={!role?.company_id}
            onPress={handleSubmit}
          >
            <CaptionMedium color="white" style={{ fontSize: 17 }}>
							Save
            </CaptionMedium>
          </Button>
        </View>
      </Container>
    </SimpleModal>
  )
}

EditRole.propTypes = {
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  role: RoleInterface,
  onClose: PropTypes.func,
}

export default EditRole
