import React, { useEffect, useState } from 'react'
import { View, Text, ScrollView, useWindowDimensions, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import * as brandSelectors from '@/redux/selectors/branding'
import {
  LineChart,
  BarChart,
  PieChart,
  ProgressChart,
  ContributionGraph,
  StackedBarChart,
} from 'react-native-chart-kit'
import { Dimensions } from 'react-native'
import { isMobile } from '@/styles'
import PropTypes from 'prop-types'
import { useSideDrawer } from '@/hooks/sideDrawer'
const screenWidth = Dimensions.get('window').width
import { ScreenSize, useScreenSize } from '@/styles'

const chartConfig = {
  backgroundGradientFrom: '#fff',
  backgroundGradientTo: '#fff',
  color: (opacity = 0.1) => `rgba(54, 54, 54, ${opacity})`,
  decimalPlaces: 0,
  strokeWidth: 2, // optional, default 3

  withVerticalLines: false,
}
export const PieChartGraph = ({ dataObj }) => {
  const screenSize = useScreenSize()
  const branding = useSelector(brandSelectors.getSettings);
  const { member } = branding?.taxonomies;
  console.log('dataObj', dataObj.length)
  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  const [chartContainerWidth, setChartContainerWidth] = useState(0);

  var chartWidth = 0;
  var chartHeight = 0;
  if (Platform.OS == 'ios' || Platform.OS == 'android') {
    chartWidth = chartContainerWidth
    chartHeight = chartContainerWidth / 2.5
  } else if (Platform.OS == 'web') {
    var ratio = 2.5
    var defaultWidth = 500
    var defaultHeight = defaultWidth / ratio

    if (defaultWidth > chartContainerWidth) {
      chartWidth = chartContainerWidth
      chartHeight = chartWidth / ratio
    } else {
      chartWidth = defaultWidth
      chartHeight = defaultHeight
    }
  }

  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  return (
    <View
      onLayout={({ nativeEvent }) => setChartContainerWidth(nativeEvent.layout.width)}
    >
      <PieChart
        data={dataObj}
        width={chartWidth}
        height={chartHeight}
        chartConfig={chartConfig}
        accessor={'population'}
        backgroundColor={'transparent'}
        center={[10, 10]}
        absolute
        paddingLeft={'-35'}
        style={{
          fontFamily: "WorkSans_400Regular"
        }}
      />
    </View>
  )
}

PieChartGraph.propTypes = {
  dataObj: PropTypes.objectOf,
}

// export default PieChartGraph;
export const ResponsesPieChartGraph = ({
  dataObj,
  firstQuestion,
  secondQuestion,
}) => {

  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  const screenSize = useScreenSize()
  return (
    <View
      style={{
        //width: isMobile ? screenWidth/ 1.07 : screenWidth / 2.2,
        width: isMobile ? screenWidth / 1.07 : window.width > 915 ? isOpenDrawer ? window.width - 250 : window.width / 2.2 : screenSize <= ScreenSize.Medium ? "100%" : window.width - 140,
        alignItems: isMobile ? 'auto' : window.width > 915 ? 'auto' : 'center',

        borderRadius: 4,
        borderRadius: 6,
        //    // alignSelf: 'center',
        //    shadowOffset: {
        //        width: 0,
        //        height: 2,
        //    },
        //    shadowOpacity: 0.25,
        //    shadowRadius: 3.84,
        //    elevation: 5,
        backgroundColor: 'white',
      }}
    >
      <Text style={{ padding: 10, fontSize: 15, lineHeight: 16, fontFamily: "WorkSans_400Regular", }}>{firstQuestion}</Text>
      <Text style={{ paddingLeft: 10, fontSize: 15, lineHeight: 16, fontFamily: "WorkSans_400Regular" }}>{secondQuestion}</Text>
      <PieChart
        data={dataObj}
        // width={isMobile ? screenWidth/ 1.07 : screenWidth / 2.2}
        // height={isMobile ? 230 : 260}
        width={isMobile ? screenWidth / 1.07 : window.width > 915 ? window.width / 2.3 : !isMobile && screenSize <= ScreenSize.Medium ? screenWidth / 1.07 : window.width - 300}
        height={isMobile ? 230 : window.width > 915 ? 260 : !isMobile && screenSize <= ScreenSize.Medium ? 230 : window.height - 400}
        // height={260}
        chartConfig={chartConfig}
        accessor={'population'}
        backgroundColor={'transparent'}
        center={[10, 10]}
        absolute
        style={{
          fontFamily: 'WorkSans_400Regular'
        }}
      />
    </View>
  )
}

ResponsesPieChartGraph.propTypes = {
  dataObj: PropTypes.objectOf,
}
