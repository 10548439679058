import _ from 'lodash'

export const getError = (state) => _.get(state, 'auth.error')

export const getUser = (state) => _.get(state, 'auth.user')

export const getMember = (state) => _.get(state, 'auth.member')

export const getWorkspace = (state) => _.get(state, 'auth.workspace')

export const getAuthToken = (state) => _.get(state, 'auth.token')

export const getAuthFirstAccess = (state) => _.get(state, 'auth.first_access')

export const getHasMultipleWorkspaces = (state) => _.get(state, 'auth.has_multiple_workspaces')

export const getSelectedWorkspace = (state) => _.get(state, 'auth.selectedWorkspace');

export const getSubscriptionFlow = (state) => _.get(state, 'auth.subscription_flow')

