import React, { useCallback } from 'react'
import { View } from 'react-native'

import { vw } from 'react-native-expo-viewport-units'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import PropTypes from 'prop-types'
import { Entypo } from '@expo/vector-icons'
import { useScreenSize } from '@/styles'
import {
  TextButton,
  SubmitButton,
  Instructions,
  ForgotPasswordPageContent,
} from './styles'
import OnboardingPage from '@/components/OnboardingPage'
import ForgotPasswordDecoration from '@/assets/onboarding/ForgotPasswordDecoration'

function OpenEmailApp({ navigation }) {
  const screenSize = useScreenSize()

  const handleBackToLogin = useCallback(() => {
    navigation.navigate('Login')
  }, [navigation])

  return (
    <OnboardingPage
      invert
      header={<OnboardingHeader withBackButton invertedContent />}
      decoration={<ForgotPasswordDecoration width={vw(45)} height="auto" />}
    >
      <ForgotPasswordPageContent screenSize={screenSize}>
        <OnboardingFormWrapper title={'Check your\nemail'}>
          <View style={{ flexGrow: 1, flexShrink: 1 }}>
            <Instructions>
              We have sent you password recovery instructions to your email.
            </Instructions>

            <SubmitButton color="black" onPress={handleBackToLogin}>
              <TextButton>Back to login</TextButton>
              <Entypo name="chevron-right" size={24} color="white" />
            </SubmitButton>
          </View>
        </OnboardingFormWrapper>
        {/* <View style={{ width: '100%', height: 50, backgroundColor: 'green' }} /> */}
      </ForgotPasswordPageContent>
    </OnboardingPage>
  )
}

OpenEmailApp.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default OpenEmailApp
