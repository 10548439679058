import React, { useEffect, useState } from 'react'
import {
    Text,
    StyleSheet,
    View,
    FlatList,
    TouchableOpacity,
    SafeAreaView,
    ScrollView,
    useWindowDimensions,
    Platform
} from 'react-native'
import { useSelector } from 'react-redux'

import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ContentBlockV1 from '@/components/ContentBlockV1'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

import { isMobile } from '@/styles'
import ApiHandler from '@/api/ApiHandler'
import { BackArrow } from '@/components'
import { useSideDrawer } from '@/hooks/sideDrawer'

import styles from './styles.js';

import { getSubscriptionMeta } from '@/redux/selectors/subscription'

const AssessmentAnalytics = ({ navigation }) => {
    //States for getting data
    const [prebuiltAssessments, setPrebuiltAssessments] = useState(null)
    const [customAssessments, setCustomAssessments] = useState(null)
    const [culturalMaturityAssessments, setCulturalMaturityAssessments] = useState(null)

    const { open } = useSideDrawer(true)
    const [isOpenDrawer, setDrawer] = useState(false)
    const [noOfColumns, setNoOfColumns] = useState(3)
    const [columnWidth, setColumnWidth] = useState('32%')
    const window = useWindowDimensions();

    const subscriptionMeta = useSelector(getSubscriptionMeta)

    const platformOSType = Platform.OS == 'ios' || Platform.OS == 'android' ? 'Mobile' : 'Web'

    useEffect(() => {
        if (window.width < 800) {
            setNoOfColumns(2);
            setColumnWidth('49%');
        }
        if (window.width < 600) {
            setNoOfColumns(1);
            setColumnWidth('99%');
        }
        if (window.width > 800) {
            setNoOfColumns(3);
            setColumnWidth('32%');
        }
    }, [window])

    useEffect(() => {
        setDrawer(open)
    }, [open, setDrawer])

    useEffect(() => {
        getAssessments()
    }, [])

    const AssessmentCard = (type, onPress) => {

    }

    const getAssessments = async () => {
        try {
            const response = await new ApiHandler().getAssesmentPrebuildList()
            if (response?.data) {
                setPrebuiltAssessments(response?.data?.data)
            }
        } catch (error) {
            console.log('Error', error)
        }

        try {
            const response = await new ApiHandler().getAssesmentCustomList()
            if (response.data) {
                setCustomAssessments(response?.data?.data)
            }
        } catch (error) {
            console.log('Error', error)
        }

        if (subscriptionMeta?.trial?.active === false) {
            try {
                const response = await new ApiHandler().getAssesmentCulturalList()
                if (response?.data) {
                    setCulturalMaturityAssessments(response?.data?.data)
                }
            } catch (error) {
                console.log('Error', error)
            }
        }
    }

    return (
        <>
            <PageHeader
                title={'Assessment Analytics'}
                icon={false}
                backEnabled={true}
                breadcrumbs={[
                    { name: 'Analytics', navigation: 'Analytics' },
                    { name: 'Assessment Analytics' }
                ]}
            />

            <PageContent>
                <ColumnsContainer>
                    {subscriptionMeta?.trial?.active === false && (
                        <Column
                            flexBasis={'50%'}
                        >
                            <ContentBlockV1
                                title={'Cultural Maturity Assessment(s)'}
                            >
                                <FlatList
                                    data={culturalMaturityAssessments}
                                    renderItem={({ item }) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    navigation.navigate('cultureAnalytics', {
                                                        scopeId: item.id,
                                                        name: item?.name,
                                                    })
                                                }}
                                                style={styles.AssessmentCard}
                                            >
                                                <Text style={styles.AssessmentCardTitle}>
                                                    {item?.name}
                                                </Text>

                                                <Text style={styles.AssessmentCardType}>
                                                    CULTURAL MATURITY
                                                </Text>

                                                {
                                                    item?.lifecycle_stage?.name ?
                                                        (
                                                            <Text style={styles.AssessmentCardLifecycleStage}>
                                                                {`Lifecycle Stage: ${item?.lifecycle_stage?.name} `}
                                                            </Text>
                                                        ) : null
                                                }

                                                {
                                                    item?.five_s_category?.name ?
                                                        (
                                                            <Text style={styles.AssessmentCardCategory}>
                                                                {`5S Category: ${item?.five_s_category?.name}`}
                                                            </Text>
                                                        ) : null
                                                }


                                                <Text style={styles.AssessmentCardQuestionsCount}>
                                                    {`${item?.assessment_questions?.length} Question(s)`}{' '}
                                                </Text>
                                            </TouchableOpacity>
                                        )
                                    }}
                                    keyExtractor={(item) => item.id}
                                    numColumns={1}
                                    showsVerticalScrollIndicator={false}
                                    key={'culturalmaturity'}
                                />
                            </ContentBlockV1>
                        </Column>
                    )}

                    <Column
                        flexBasis={'50%'}
                    >
                        <ContentBlockV1
                            title={'Prebuilt Assessments'}
                        >
                            <FlatList
                                data={prebuiltAssessments}
                                renderItem={({ item }) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                navigation.navigate('resultAnalytics', {
                                                    id: item?.id,
                                                    name: item?.name,
                                                    type: 'prebuilt',
                                                })
                                            }}
                                            style={styles.AssessmentCard}
                                        >
                                            <Text style={styles.AssessmentCardTitle}>
                                                {item?.name}
                                            </Text>

                                            <Text style={styles.AssessmentCardType}>
                                                PREBUILT
                                            </Text>

                                            {
                                                item?.lifecycle_stage?.name ?
                                                    (
                                                        <Text style={styles.AssessmentCardLifecycleStage}>
                                                            {`Lifecycle Stage: ${item?.lifecycle_stage?.name} `}
                                                        </Text>
                                                    ) : null
                                            }

                                            {
                                                item?.five_s_category?.name ?
                                                    (
                                                        <Text style={styles.AssessmentCardCategory}>
                                                            {`5S Category: ${item?.five_s_category?.name}`}
                                                        </Text>
                                                    ) : null
                                            }


                                            <Text style={styles.AssessmentCardQuestionsCount}>
                                                {`${item?.assessment_questions?.length} Question(s)`}{' '}
                                            </Text>
                                        </TouchableOpacity>
                                    )
                                }}
                                keyExtractor={(item) => item.id}
                                numColumns={platformOSType == 'Mobile' ? 1 : 2}
                                showsVerticalScrollIndicator={false}
                                key={'prebuilt'}
                            />
                        </ContentBlockV1>
                    </Column>

                    <Column
                        flexBasis={'50%'}
                    >
                        <ContentBlockV1
                            title={'Custom Assessments'}
                        >
                            <FlatList
                                data={customAssessments}
                                renderItem={({ item }) => {
                                    return (
                                        <TouchableOpacity
                                            onPress={() => {
                                                navigation.navigate('resultAnalytics', {
                                                    id: item?.id,
                                                    name: item?.name,
                                                    type: 'custom',
                                                })
                                            }}
                                            style={styles.AssessmentCard}
                                        >
                                            <Text style={styles.AssessmentCardTitle}>
                                                {item?.name}
                                            </Text>

                                            <Text style={styles.AssessmentCardType}>
                                                CUSTOM
                                            </Text>

                                            {
                                                item?.lifecycle_stage?.name ?
                                                    (
                                                        <Text style={styles.AssessmentCardLifecycleStage}>
                                                            {`Lifecycle Stage: ${item?.lifecycle_stage?.name} `}
                                                        </Text>
                                                    ) : null
                                            }

                                            {
                                                item?.five_s_category?.name ?
                                                    (
                                                        <Text style={styles.AssessmentCardCategory}>
                                                            {`5S Category: ${item?.five_s_category?.name}`}
                                                        </Text>
                                                    ) : null
                                            }


                                            <Text style={styles.AssessmentCardQuestionsCount}>
                                                {`${item?.assessment_questions?.length} Question(s)`}{' '}
                                            </Text>
                                        </TouchableOpacity>
                                    )
                                }}
                                keyExtractor={(item) => item.id}
                                numColumns={platformOSType == 'Mobile' ? 1 : 4}
                                showsVerticalScrollIndicator={false}
                                key={'custom_assessments_' + noOfColumns}
                            />
                        </ContentBlockV1>
                    </Column>
                </ColumnsContainer>
            </PageContent>
        </>
    )
}
export default AssessmentAnalytics
