import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import _ from 'lodash'

// Constants
export const constants = {
  GET_FEATURED: createSagaAction('GET_FEATURED'),
  GET_FILTERED: createSagaAction('GET_FILTERED'),
  GET_ARTICLES: createSagaAction('GET_ARTICLES'),
  GET_COURSES: createSagaAction('GET_COURSES'),
  GET_EVENTS: createSagaAction('GET_EVENTS'),
  SET_READLATER: createSagaAction('SET_READLATER'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  featured: (object, next) => ({
    type: constants.GET_FEATURED.ACTION,
    ...object,
    next,
  }),
  filtered: (object, next) => ({
    type: constants.GET_FILTERED.ACTION,
    ...object,
    next,
  }),
  articles: (object, next) => ({
    type: constants.GET_ARTICLES.ACTION,
    ...object,
    next,
  }),
  setReadLater: (object, next) => ({
    type: constants.SET_READLATER.ACTION,
    ...object,
    next,
  }),
  coursesAction: (object, next) => ({
    type: constants.GET_COURSES.ACTION,
    payload: object,
    next,
  }),
  eventsAction: (object, next) => ({
    type: constants.GET_EVENTS.ACTION,
    payload: object,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.GET_FEATURED.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_FEATURED.SUCCESS]: (state, action) => {
    return {
      ...state,
      featured: action.payload,
      loading: false,
      error: null,
    }
  },
  [constants.GET_FILTERED.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_FILTERED.SUCCESS]: (state, action) => {
    return {
      ...state,
      filtered: action.payload,
      loading: false,
      error: null,
    }
  },
  [constants.GET_ARTICLES.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_ARTICLES.SUCCESS]: (state, action) => {
    return {
      ...state,
      articles: action.payload,
      loading: false,
      error: null,
    }
  },

  [constants.GET_COURSES.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_COURSES.SUCCESS]: (state, action) => {
    return {
      ...state,
      courses: action.payload,
      loading: false,
      error: null,
    }
  },

  [constants.GET_EVENTS.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_EVENTS.SUCCESS]: (state, action) => {
    return {
      ...state,
      events: action.payload,
      loading: false,
      error: null,
    }
  },

  [constants.SET_READLATER.ACTION]: (state) => {
    return { ...state, error: null, loading: true, readLaterLoading: true }
  },
  [constants.SET_READLATER.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      readLaterLoading: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: false,
  featured: null,
  filtered: null,
  articles: [],
  courses: [],
  events: [],
  readLaterLoading: false,
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, loading: false }
})
