import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        width: '100%',
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        padding: 20,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
        marginBottom: 10,
    },
    Header: {
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    HeaderLeft: {
        flexDirection: 'column',
        flex: 1
    },
    HeaderRight: {
    },
    HeaderTitle: {
        fontFamily: 'WorkSans_400Regular',
        fontWeight: "600",
        color: '#f1524f',
        textTransform: 'uppercase',
        letterSpacing: 0.6,

        ...Platform.select({
            ios: {
                fontSize: 13,
            },
            android: {
                fontSize: 13,
            },
            web: {
                fontSize: 16,
            }
        })
    },
    HeaderSubtitle: {
        fontFamily: 'WorkSans_400Regular',
        fontWeight: "500",
        color: '#1b1b1b',
        fontSize: 14,
        marginTop: 3
    },
    ActionContainer: {
        justifyContent: 'center',
        backgroundColor: '#1b1b1b',
        borderRadius: 50,

        ...Platform.select({
            ios: {
                width: 25,
                height: 25,
            },
            android: {
                width: 25,
                height: 25,
            },
            web: {
                width: 30,
                height: 30,
                alignItems: 'center',
            }
        })
    },
    Body: {

    }
});
