import styled from 'styled-components/native';
import { Appbar } from 'react-native-paper';

export const Header = styled(Appbar.Header)`
    background-color: transparent;
    box-shadow: none;
    padding-top: 18px;
`;
export const BackAction = styled(Appbar.BackAction)`
    margin-right: 0px;
`;
export const Action = styled(Appbar.Action)``;

export const Content = styled(Appbar.Content)``;