import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Column: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto',
        marginBottom: 20,

        ...Platform.select({
            ios: {
                width: '100%',
            },
            android: {
                width: '100%',
            },
            web: {
                width: '50%',
                paddingHorizontal: 10
            }
        })
    }
});
