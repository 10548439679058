import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from "react-redux";
import {
   View,
   Text,
   SafeAreaView,
   StyleSheet,
   Image,
   TouchableOpacity,
   ScrollView,
   Dimensions,
   ActivityIndicator,
} from "react-native";

import {
   Elements,
   useStripe,
   useElements,
   CardElement,
   PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Formik } from "formik";
import * as Yup from "yup";
import Toast from 'react-native-toast-message'
import { Entypo } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import { useNavigation } from "@react-navigation/native";

import getEnv from "../../../environment";
import ApiHandler from "@/api/ApiHandler";
import { Button, Icon } from "@/components";
import ReframeRoundLogo from "@/assets/brand/ReframeRoundLogo.png";
import { TextField, PasswordTextField } from "@/components";
import { subscribe } from "@/redux/actions";
import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'

import styles from './styles.js';

const Content = () => {
   const options = {
      clientSecret: '{{CLIENT_SECRET}}',
      appearance: {/*...*/ },
   };

   const Width = Dimensions.get("window").width;

   const initialValues = {
      firstName: "",
      lastName: "",
      emailAddress: "",
      password: "",
      companyName: "",
      noOfEmployees: null,
      creditCardNumber: null,
      creditCardExpirationDate: "",
      creditCardSecurityCode: null,
   };

   const validationSchema = Yup.object().shape({
      firstName: Yup.string().required().min(1).max(255),
      lastName: Yup.string().required().min(1).max(255),
      email: Yup.string().email().required(),
      password: Yup.string().min(6).required(),
      companyName: Yup.string().required(),
      noOfEmployees: Yup.number().required(),
      creditCardNumber: Yup.number().required(),
      creditCardExpirationDate: Yup.string().required(),
      creditCardSecurityCode: Yup.number().required(),
   });

   const [showSummary, setShowSummary] = useState(false);
   const [queryParams, setQueryParams] = useState();
   const [isPaymentLoading, setPaymentLoading] = useState(false);
   const [subscription, setSubscription] = useState();
   const [planSummary, setPlanSummary] = useState();
   const [stripPlans, setStripePlans] = useState();
   const [paymentMethodId, setPaymentMethodId] = useState(false);
   const [loading, setLoading] = useState(false);
   const navigation = useNavigation()
   const [summary, setSummary] = useState();
   const [urlCode, setUrlCode] = useState();
   const [billingTerm, setBillingTerm] = useState();
   const [trial, setTrial] = useState();
   const stripe = useStripe();
   const elements = useElements();
   const dispatch = useDispatch();

   const CARD_ELEMENT_OPTIONS = {
      style: {
         base: {
            color: "#32325d",
            //   fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            fontWeight: "500",
            "::placeholder": {
               color: "#E3E3E3",
            },
         },
         invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
         },
      },
   };

   const handleSubmit = useCallback(async (values) => {
      if (values?.firstName === "" || values?.firstName === undefined) {
         Toast.show({
            type: 'error',
            position: 'bottom',
            text1: "The 'First Name' field is required.",
         })
         return
      }
      else if (values?.lastName === "" || values?.lastName === undefined) {
         Toast.show({
            type: 'error',
            position: 'bottom',
            text1: "The 'Last Name' field is required.",
         })
         return
      }
      else if (values?.email === "" || values?.email === undefined) {
         Toast.show({
            type: 'error',
            position: 'bottom',
            text1: "The 'Email' field is required.",
         })
         return
      }
      else if (values?.password === "" || values?.password === undefined) {
         Toast.show({
            type: 'error',
            position: 'bottom',
            text1: "The 'Password' field is required.",
         })
         return
      }
      else if (values?.companyName === "" || values?.companyName === undefined) {
         Toast.show({
            type: 'error',
            position: 'bottom',
            text1: "The 'Company Name' field is required.",
         })
         return
      }
      else if (values?.noOfEmployees === null || values?.noOfEmployees === undefined) {
         Toast.show({
            type: 'error',
            position: 'bottom',
            text1: "The 'Number Of Employees' field is required.",
         })
         return
      }

      setLoading(true)

      if (trial == 0) {
         if (!stripe || !elements) {
            return;
         }

         const card = elements.getElement(CardElement);
         stripe.createPaymentMethod('card', card).then(async (result) => {
            if (result?.paymentMethod?.id) {
               const data = {
                  first_name: values?.firstName,
                  last_name: values?.lastName,
                  email: values?.email,
                  password: values?.password,
                  company_name: values?.companyName,
                  number_of_employees: parseInt(values?.noOfEmployees),
                  payment_method_id: result?.paymentMethod?.id,
                  code: urlCode,
                  billing_term: billingTerm,
                  trial: trial
               };

               try {
                  const response = await new ApiHandler().stripeSubscription(data);
                  if (response?.data) {
                     setSubscription(response?.data);
                     if (response?.status === 200) {
                        setLoading(false);
                        dispatch(subscribe(response?.data))
                     }
                  } else if (response?.data?.message === "Validator Error.") {
                     setLoading(false)
                     Toast.show({
                        type: 'error',
                        position: 'bottom',
                        text1: 'Fix validation errors!'
                     })
                  }
               } catch (error) {
                  setLoading(false)
                  console.log("ERROR", error);
                  Toast.show({
                     type: 'error',
                     position: 'bottom',
                     text1: "Something went wrong!",
                  })
               } finally {
                  setLoading(false)
               }
            } else {
               Toast.show({
                  type: 'error',
                  position: 'bottom',
                  text1: "There was an issue with the card provided. Please try again.",
               })
            }
         })
      } else {
         const data = {
            first_name: values?.firstName,
            last_name: values?.lastName,
            email: values?.email,
            password: values?.password,
            company_name: values?.companyName,
            number_of_employees: parseInt(values?.noOfEmployees),
            code: urlCode,
            billing_term: billingTerm,
            trial: trial
         };

         try {
            const response = await new ApiHandler().stripeSubscription(data);
            if (response?.data) {
               setSubscription(response?.data);
               if (response?.status === 200) {
                  setLoading(false);
                  dispatch(subscribe(response?.data))
               }
            } else if (response?.data?.message === "Validator Error.") {
               setLoading(false)
               Toast.show({
                  type: 'error',
                  position: 'bottom',
                  text1: 'Fix validation errors!'
               })
            }
         } catch (error) {
            setLoading(false)
            console.log("ERROR", error);
            Toast.show({
               type: 'error',
               position: 'bottom',
               text1: "Something went wrong!",
            })
         } finally {
            setLoading(false)
         }
      }
   });

   const getPlanSummary = async (code, billingTerm) => {
      try {
         //let code = "starter" // professional
         //const billingTerm = "monthly"
         const response = await new ApiHandler().getPlanSummary(code, billingTerm, trial);
         if (response?.status === 200) {
            console.log("*****...getPlanSummary...!!!!!!!", response);
            setSummary(response?.data?.data)
         }
      } catch (error) {
         console.log("ERROR", error);
      } finally {
      }
   };


   const handleUrl = ({ url }) => {
      let { queryParams } = Linking.parse(url);
      const billingTerm = JSON.stringify(queryParams.billing_term)?.replace(/"/g, '')
      const urlCode = JSON.stringify(queryParams.plan)?.replace(/"/g, '')
      const trial = JSON.stringify(queryParams.trial)?.replace(/"/g, '')
      if (urlCode !== undefined && billingTerm !== undefined && trial !== undefined) {
         setBillingTerm(billingTerm)
         setUrlCode(urlCode)
         setTrial(trial)
      }
   };

   useEffect(() => {
      Linking.addEventListener("url", handleUrl);
      // return () => {
      //   Linking.removeEventListener("url");
      // };
   }, []);

   useEffect(() => {
      if (urlCode !== undefined && billingTerm !== undefined && trial !== undefined) {
         getPlanSummary(urlCode, billingTerm, trial);
      }
      // getStripePlans();
   }, [urlCode, billingTerm, trial])

   return (
      <TheaterLayout>
         <View style={styles.container}>
            <View style={styles.formContainer}>
               <ContentBlockV1>
                  <View style={styles.form}>
                     <Text style={styles.title}>You are almost there!</Text>
                     <Formik
                        enableReinitialize
                        validateOnMount
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                     //validationSchema={validationSchema}
                     //validator={() => ({})}
                     >
                        {({ values, isValid, handleChange, handleSubmit, }) => (
                           <View>
                              <Text style={styles.formTitle}>Personal Information</Text>
                              <TextField
                                 label="First Name"
                                 value={values?.firstName}
                                 autoCapitalize="words"
                                 autoCompleteType="name"
                                 onChangeText={handleChange('firstName')}
                                 style={styles.textField}
                              />
                              <TextField
                                 label="Last Name"
                                 value={values?.lastName}
                                 autoCapitalize="words"
                                 autoCompleteType="name"
                                 onChangeText={handleChange("lastName")}
                                 style={styles.textField}
                              />
                              <TextField
                                 label="Email Address"
                                 value={values?.email}
                                 autoCapitalize="none"
                                 autoCompleteType="email"
                                 keyboardType="email-address"
                                 onChangeText={handleChange("email")}
                                 style={styles.textField}
                              />
                              <PasswordTextField
                                 name="password"
                                 value={values?.password}
                                 onChangeText={handleChange("password")}
                                 style={styles.textField}
                              />

                              <Text style={styles.formTitle}>Company Information</Text>
                              <TextField
                                 label="Company Name"
                                 value={values?.companyName}
                                 autoCapitalize="words"
                                 autoCompleteType="name"
                                 onChangeText={handleChange("companyName")}
                                 style={styles.textField}
                              />
                              <TextField
                                 label="Number Of Employees"
                                 value={values?.noOfEmployees}
                                 onChangeText={handleChange("noOfEmployees")}
                                 style={styles.textField}
                              />

                              {trial == 0 ? (
                                 <>
                                    <Text style={styles.formTitle}>Payment Information</Text>
                                    <View style={styles.creditCard}>
                                       <CardElement options={CARD_ELEMENT_OPTIONS} />
                                    </View>
                                 </>
                              ) : null}

                              <View style={styles.submitBtnView}>
                                 <Button color="#000000" onPress={handleSubmit}>
                                    {loading === true ? <ActivityIndicator /> :
                                       <View style={{ flexDirection: 'row' }}>
                                          <Text style={styles.submitBtnText}>Subscribe</Text>
                                          <View>
                                             <Entypo name="chevron-right" size={24} color="white" />
                                          </View>
                                       </View>
                                    }
                                 </Button>
                              </View>
                           </View>
                        )}
                     </Formik>
                  </View>
               </ContentBlockV1>
            </View>
            <View style={styles.summaryDetailsContainer}>
               <ContentBlockV1
                  title={'Summary'}
               >
                  <View style={styles.summaryDetailsContent}>
                     <ContentBlockV1>
                        <View style={styles.softwareTools}>
                           <View style={styles.softwareToolsHead}>
                              <Image
                                 source={ReframeRoundLogo}
                                 width={26}
                                 height={26}
                                 style={styles.softwareToolsHeadIcon}
                              />
                              <View>
                                 <Text style={styles.softwareToolsHeadTitle}>
                                    {summary?.plan_name}
                                 </Text>
                                 <Text style={styles.softwareToolsHeadSubTitle}>
                                    {summary?.billing_term}
                                 </Text>
                              </View>
                           </View>
                        </View>
                     </ContentBlockV1>
                     <View style={styles.horizontalLine} />

                     <View style={styles.summaryDetailsHead}>
                        <Text style={[
                           styles.summaryDetailsValue,
                           { fontSize: 18, fontWeight: "600" },
                        ]}>
                           {"Total Due Today"}
                        </Text>
                        <Text
                           style={[
                              styles.summaryDetailsValue,
                              { fontSize: 18, fontWeight: "600" },
                           ]}
                        >
                           {summary?.total_due_today}
                        </Text>
                     </View>
                     <View style={{ paddingVertical: 15 }}>
                        <Text style={[styles.summaryDetailsTitle, { fontSize: 16 }]}>
                           {summary?.additional_note}
                        </Text>
                     </View>
                  </View>
               </ContentBlockV1>
            </View>
         </View>
      </TheaterLayout>
   )
}

const Subscribe = () => {
   const { stripePublishableKey } = getEnv()
   const stripePromise = loadStripe(stripePublishableKey.toString())

   return (
      <Elements stripe={stripePromise} >
         <Content />
      </Elements>
   )
};

export default Subscribe;