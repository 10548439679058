import { DefaultTheme } from 'react-native-paper'
import theme from '@/styles/themes/black'

export const defaultTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: theme.primary.standard,
    accent: theme.basics.brand,
    background: '#f6f6f6',
    surface: 'white',
    error: '#B00020',
    text: 'black',
    onSurface: '#000000',
    disabled: '#ccc',
    placeholder: '#ccc',
    backdrop: '#ccc',
    notification: '#ccc',
    secondary: theme.basics.brand,
  },
  fonts: 'regular',
}

// export { default as Radio } from './Radio'
export { default as Card } from './Card'
export { default as Chip } from './Chip'
export { default as Checkbox } from './Checkbox'
export { default as Radio } from './Radio'
export { default as TextField } from './TextField'
export { default as PasswordTextField } from './PasswordTextField'
export { default as Searchbar } from './Searchbar'
export { default as SearchHeader } from './SearchHeader'
export { default as SearchWeb } from './SearchWeb'

// export { default as Avatar } from './Avatar'
// export { default as Searchbar } from './Searchbar'

// export { default as Separator } from './Separator'
export { default as Icon } from './Icon'
export { default as Button } from './Button'
export { default as BackArrow } from './BackArrow'
// export { default as SwiperNavigation, SwiperSlide } from './SwiperNavigation'
// export { default as PostCard } from './PostCard'
// export { default as Comment } from './Comment'
// export { default as Paper } from './Paper'
export { default as Switch } from './Switch'

export { default as HeaderWithBack } from './HeaderWithBack'
export { default as ListViewEmployee } from './ListViewEmployee'
export { default as FloatingChatButton } from './FloatingChatButton'
// export { default as SubMenu } from './SubMenu'
export { default as LayoutCard } from './LayoutCard'
export { default as CourseModuleCard } from './CourseModuleCard'
export { default as Avatar } from './Avatar'

export { default as TopTabBar } from './TopTabBar'
export { default as CollapseButton } from './CollapseButton'
export { default as CollapseButtonMobile } from './CollapseButton/index.native'
export { default as EditProfile } from './EditProfile'
export { default as SimpleModal } from './SimpleModal'
export { default as GroupTeamsCard } from './GroupTeamsCard'
export { default as GroupTeamsMemberCard } from './GroupTeamsMemberCard'
export { default as PopoverTooltip } from './ToolTip'
export { default as Carousel } from './Carousel'
export { default as AnimatedModal } from './AnimatedModal'
// page
export { default as LeaveModalWeb } from './Page/LeaveModalWeb'
export { default as KebabMenuMobile } from './Page/KeababMenuMobile'
export { default as LeaveModalMobile } from './Page/LeaveModalMobile'
export { default as KebabMenuWeb } from './Page/KebabMenuWeb'
export { default as InviteMemberWeb } from './Page/InviteMemberWeb'
export { default as MemberWeb } from './Page/Member'
export { default as MemberMobile } from './Page/Member/index.native'
export { default as EditInformation } from './Page/EditInformation'
export { default as MemberListWeb } from './Page/MemberListWeb'
export { default as MemberList } from './Page/MemberList'
// home
export { default as CreatePostComment } from './CreatePostComment'
export { default as FeedPostCard } from './FeedCard/FeedPostCard'
export { default as FeedCardAuthorInfo } from './FeedCard/FeedCardAuthorInfo'
export { default as CreatePost } from './CreatePost'
export { default as AvatarWriteButton } from './AvatarWriteButton'
export { default as Comment } from './Comment'
export { default as CommentList } from './CommentsList'

export { default as Breadcrumb } from './Breadcrumb'
