import { TouchableOpacity, Dimensions } from 'react-native'
import styled from 'styled-components/native'

const { width } = Dimensions.get('window');

export const UIButton = styled(TouchableOpacity)`
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50px;
    marginHorizontal: 15px;
    background-color: ${props => props.active ? "#ea4335" : props.bg ? props.bg : 'white'};
    border-color: ${props => props.active ? "#ea4335" : 'white'};
`