import _ from 'lodash'

export const loading = (state) => _.get(state, 'groups.loading')

export const data = (state) => _.get(state, 'groups.data')
export const otherGroups = (state) => _.get(state, 'groups.otherGroups')
export const group = (state) => _.get(state, 'groups.group')
export const posts = (state) => _.get(state, 'groups.posts')
export const members = (state) => _.get(state, 'groups.members')
export const can_load_more = (state) => _.get(state, 'groups.page') < _.get(state, 'groups.last_page')
export const next_page = (state) => _.get(state, 'groups.page') + 1
export const current_page = (state) => _.get(state, 'groups.page')