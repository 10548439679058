import React, { useCallback, useEffect, useState } from 'react'
import {
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  LayoutAnimation,
  StyleSheet,
  UIManager,
  Platform,
} from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import * as brandSelectors from '@/redux/selectors/branding'
import { actions as brandSetingsActions } from '@/redux/reducers/branding'

import { actions } from '@/redux/reducers/roles'
import { Formik } from 'formik'
import * as selectors from '@/redux/selectors/roles'
import { Avatar } from '@/components'
import {
  CardView,
  AvatarButton,
  AvatarWrapper,
  CircleCamera,
  TextField,
} from '../styles'
import Logo from '@/assets/Icon_1024.png'
import { ProgressBar } from 'react-native-paper'
// import { TextField as TF  } from '@/components'
import * as Yup from 'yup'
import Icon from '@/components/Icon'
import { colors } from './BrandingData'
import { ScreenSize, useScreenSize, isMobile } from '@/styles'
import BrandingWeb from './Branding.web'
import { openGallery } from '@/utils/gallery'
import * as profileSelectors from '@/redux/selectors/profile'
import { ActivityIndicator } from 'react-native-paper'
import ApiHandler from '@/api/ApiHandler'
import * as FileSystem from 'expo-file-system';
import { actions as profileActions } from '@/redux/reducers/profile'
import Toast from 'react-native-toast-message'
import themes from '@/styles/themes/black'

if (
  Platform.OS === 'android' &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true)
}

const Index = (userId) => {
  const dispatch = useDispatch()
  const loading = useSelector(selectors.loading)
  const screenSize = useScreenSize()
  // const [arrayData, setArrayData]= useState()
  const [color, setColor] = useState()
  const [logo, setLogo] = useState()
  const [taxonomy, setTaxonomy] = useState()
  const [isChecked, setIsChecked] = useState()
  const [showLogo, setShowLogo] = useState()
  const userProfile = useSelector(profileSelectors.getProfile)

  const branding = useSelector(brandSelectors.getSettings);

  const { member, group, team } = branding.taxonomies;

  const taxonomyInitialValues = {
    member: member?.singular ?? '',
    members: member?.plural ?? '',
    team: team?.singular ?? '',
    teams: team?.plural ?? '',
    group: group?.singular ?? '',
    groups: group?.plural ?? ''
  };

  const taxonomyValidationSchema = Yup.object().shape({
    member: Yup.string().required(),
    members: Yup.string().required(),
    team: Yup.string().required(),
    teams: Yup.string().required(),
    group: Yup.string().required(),
    groups: Yup.string().required(),
  });

  const validationSchema = Yup.object().shape({
    logo: Yup.string(),
  })
  const initialValues = {
    logo: '',
  }

  const getBrandingSettings = async () => {
    try {
      const response = await new ApiHandler().getBrandingSettings()
      if (response?.data) {
        const settings = response?.data?.data || {}
        dispatch(brandSetingsActions.updateSettings(settings));
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleSubmit = useCallback(async (values) => {
    // setLoading(true)
    const data = {
      logo: values.logo ?? '',

    }
    try {
      const response = await new ApiHandler().updateWorkspaceLogo(data)
      if (response?.data) {

        // setLoading(false)
        // cancel()
      }
    } catch (error) {
      console.log("error", error)
    } finally {
      // setLoading(false);
    }
  })
  const getLogo = async () => {

    try {
      const response = await new ApiHandler().getWorkspaceLogo()

      setShowLogo({ ...response?.data });


    } catch (error) {
      console.log("error", error)
    } finally {
      // setLoading(false);
    }
  }

  useEffect(() => {
    getLogo()

  }, [])

  const colorScheme = ({ item, index }) => {
    return (
      <View onPress={{}} style={styles.checkBoxView}>
        <View style={{ justifyContent: 'center' }}>
          <TouchableOpacity
            style={styles.check}
            onPress={() => {
              setIsChecked(!isChecked)
            }}
          >
            <View
              style={{
                height: 24,
                width: 24,
                backgroundColor: isChecked == true ? '#F05343' : '#000',
                borderRadius: 5,
              }}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.checkBoxDecs}>
          <Text>{item.description}</Text>
        </View>
      </View>
    )
  }


  const primaryButton = (btnStyles, onPress, label) => {
    const {
      color_scheme_primary_button_background,
      color_scheme_primary_button_text
    } = branding;
    return (
      <TouchableOpacity
        style={
          [
            btnStyles,
            {
              backgroundColor: color_scheme_primary_button_background,
            }
          ]
        }
        onPress={onPress}
      >
        <Text style={{ color: color_scheme_primary_button_text, fontSize: 16 }}>
          {label}
        </Text>
      </TouchableOpacity>
    )
  }

  const uploadTaxonomy = async (values) => {
    try {
      const data = {
        member: {
          singular: values.member,
          plural: values.members
        },
        team: {
          singular: values.team,
          plural: values.teams
        },
        group: {
          singular: values.group,
          plural: values.groups
        }
      };
      const response = await new ApiHandler().updateTaxonomies(data);
      if (response?.data) {
        const { success, message } = response?.data;
        Toast.show({
          type: success ? 'success' : 'error',
          position: 'top',
          text1: message,
        });
      }
    } catch (error) {
      console.log('UPDATE TAXONOMY ERROR', error);
    } finally {
      getBrandingSettings();
    }
  };

  const resetTaxonomies = async () => {
    try {
      const response = await new ApiHandler().resetTaxonomies();
      const { success, message } = response?.data;
      Toast.show({
        type: success ? 'success' : 'error',
        position: 'top',
        text1: message,
      });
    } catch (error) {
      console.log('RESET TAXONOMY ERROR', error);
    } finally {
      getBrandingSettings();
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ values, isValid, handleChange, handleSubmit }) => (
          <>
            {isMobile ? (
              <>
                <View style={{ marginBottom: 10 }}>
                  <TouchableOpacity
                    onPress={() => {
                      setColor(!color)
                      LayoutAnimation.configureNext(
                        LayoutAnimation.Presets.linear,
                      )
                    }}
                  >
                    <CardView style={{ marginBottom: 0 }}>
                      <View style={{ width: '90%' }}>
                        <Text style={styles.title}>Color Scheme</Text>
                        <Text style={styles.description}>
                          Customize the color Scheme within your workspace
                        </Text>
                      </View>
                      <View>
                        <Icon
                          icon={color === true ? 'chevronTop' : 'chevronDown'}
                          width={16}
                        />
                      </View>
                    </CardView>
                  </TouchableOpacity>
                  {color == true && (
                    <View style={{ backgroundColor: '#fff', marginBottom: 10 }}>
                      <FlatList
                        data={colors}
                        renderItem={colorScheme}
                        keyExtractor={(item) => item.id}
                      />
                      <View style={styles.colorScheme}>


                        {primaryButton(styles.uploadButton, () => { }, "Upload")}

                        <TouchableOpacity style={styles.resetUpload}>
                          <Text>Reset</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </View>

                <View style={styles.logoView}>
                  <TouchableOpacity
                    onPress={() => {
                      setLogo(!logo)
                      LayoutAnimation.configureNext(
                        LayoutAnimation.Presets.linear,
                      )
                    }}
                  >
                    <CardView style={{}}>
                      <View style={{ width: '90%' }}>
                        <Text style={styles.title}>Logo</Text>
                        <Text style={styles.description}>
                          Upload a custom logo for your workspace
                        </Text>
                      </View>
                      <View>
                        <Icon
                          icon={logo === true ? 'chevronTop' : 'chevronDown'}
                          width={16}
                        />
                      </View>
                    </CardView>
                  </TouchableOpacity>
                  {logo == true && (
                    <View style={{ alignItems: 'center' }}>
                      <AvatarButton
                        onPress={async () => {
                          try {
                            const imageUri = await openGallery()
                            if (isMobile) {
                              handleChange('logo')('data:image/jpeg;base64,' + await FileSystem.readAsStringAsync(imageUri, { encoding: 'base64' }))
                              return
                            }
                            handleChange('logo')(imageUri)
                          } catch (e) {
                            console.log('Error opening gallery')
                          }
                        }}
                      >
                        <AvatarWrapper screenSize={screenSize}>
                          <Avatar
                            style={{ backgroundColor: '#F1F1F1' }}
                            size={120}
                            source={values?.logo ? { uri: values.logo } : null}
                          />
                          <CircleCamera isMobile={isMobile}>
                            <Icon
                              icon="addPhoto"
                              color="#323232"
                              height={isMobile ? 16 : 22}
                              width={isMobile ? 16 : 22}
                            />
                          </CircleCamera>
                        </AvatarWrapper>
                      </AvatarButton>
                      <View style={styles.logoBtns}>

                        {primaryButton(styles.logoUpload, handleSubmit, "Upload")}

                        <TouchableOpacity style={styles.logoReset}>
                          <Text style={{ fontSize: 16 }}>Reset</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  )}
                </View>

                {/* Taxonomy commented for now */}
                <View style={styles.taxonomy}>
                  <TouchableOpacity
                    onPress={() => {
                      setTaxonomy(!taxonomy)
                      LayoutAnimation.configureNext(
                        LayoutAnimation.Presets.linear,
                      )
                    }}
                  >
                    <CardView>
                      <View>
                        <Text style={styles.title}>Taxonomy</Text>
                        <Text style={styles.description}>
                          Customize the taxonomy throughout your workspace.
                        </Text>
                      </View>
                      <View>
                        <Icon
                          icon={
                            taxonomy === true ? 'chevronTop' : 'chevronDown'
                          }
                          width={16}
                        />
                      </View>
                    </CardView>
                  </TouchableOpacity>
                  {taxonomy == true && (
                    <Formik
                      enableReinitialize
                      validateOnMount
                      onSubmit={uploadTaxonomy}
                      initialValues={taxonomyInitialValues}
                      validationSchema={taxonomyValidationSchema}
                    >
                      {({ values, isValid, handleChange, handleSubmit }) => (
                        <View style={styles.taxonomyFormView}>
                          <Text style={[styles.taxonomyFormFieldTitle, { marginTop: 0 }]}>Members</Text>
                          <TextField
                            label="Member"
                            value={values?.member}
                            autoCapitalize="none"
                            keyboardType="default"
                            onChangeText={handleChange('member')}
                          />
                          <TextField
                            label="Members"
                            value={values?.members}
                            autoCapitalize="none"
                            keyboardType="default"
                            onChangeText={handleChange('members')}
                          />
                          <Text style={styles.taxonomyFormFieldTitle}>Teams</Text>
                          <TextField
                            label="Team"
                            value={values?.team}
                            autoCapitalize="none"
                            keyboardType="default"
                            onChangeText={handleChange('team')}
                          />
                          <TextField
                            label="Teams"
                            value={values?.teams}
                            autoCapitalize="none"
                            keyboardType="default"
                            onChangeText={handleChange('teams')}
                          />
                          <Text style={styles.taxonomyFormFieldTitle}>Groups</Text>
                          <TextField
                            label="Group"
                            value={values?.group}
                            autoCapitalize="none"
                            keyboardType="default"
                            onChangeText={handleChange('group')}
                          />
                          <TextField
                            label="Groups"
                            value={values?.groups}
                            autoCapitalize="none"
                            keyboardType="default"
                            onChangeText={handleChange('groups')}
                          />
                          <View style={styles.colorScheme}>
                            <TouchableOpacity style={styles.uploadButton} onPress={handleSubmit}>
                              <Text style={{ color: '#fff', fontSize: 16 }}>
                                Upload
                              </Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.resetUpload} onPress={resetTaxonomies}>
                              <Text>Reset</Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      )}
                    </Formik>
                  )}
                </View>
              </>
            ) : (
              <BrandingWeb brandSettings={branding} />
            )}
          </>
        )}
      </Formik>
    </>
  )
}

export default Index

const styles = StyleSheet.create({
  title: {
    fontSize: 22,
  },
  description: {
    fontSize: 15,
    paddingTop: 5,
  },
  checkBoxView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    width: '60%',
  },
  colorScheme: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 150,
    paddingBottom: 10,
  },
  uploadButton: {
    backgroundColor: '#000',
    width: '100%',
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: 10,
  },
  resetUpload: {
    width: '100%',
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
  },
  logoView: {
    backgroundColor: '#fff',
    marginBottom: 10
  },
  logoBtns: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 150,
    paddingBottom: 10,
  },
  logoUpload: {
    backgroundColor: '#000',
    width: 350,
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginBottom: 10,
  },
  logoReset: {
    width: 350,
    height: '30%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
  },
  taxonomy: {
    backgroundColor: '#fff',
    marginBottom: 10,
  },
  taxonomyFormView: {
    paddingHorizontal: 20
  },
  taxonomyFormFieldTitle: {
    marginTop: 26,
    fontSize: 15,
    fontFamily: 'WorkSans_400Regular',
    color: themes.basics.black_text,
    lineHeight: 20
  },
  check: {
    height: 24,
    width: 24,
    borderWidth: 2,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 10,
    // backgroundColor: this.state.isCheck ? '#000' : '#FFF'
    borderRadius: 5,
  },
  checkBoxDecs: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    marginStart: 20,
  },
})