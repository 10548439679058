import styled from 'styled-components/native'
import {
  Paragraph1,
  Caption1
} from '@/styles/fonts'
import themes from '@/styles/themes/black'
import { ScreenSize } from '@/styles'

export const Container = styled.View`
    flex: 1;
    margin-left: 10px;
		justify-content: center;
		width: 100%;
    ${({screenSize}) => screenSize <= ScreenSize.Medium && 'flex-direction:row;'}
    ${({screenSize}) => screenSize <= ScreenSize.Medium && 'justify-content: space-between;'}
`

export const ViewMobile = styled.View`
    flex: 1;
    ${({screenSize}) => screenSize <= ScreenSize.Medium && 'align-items: flex-end;'}
`

export const Title = styled(Paragraph1)`
		width: 175px;
		text-align: left;
    color: ${themes.basics.black_text};
`

export const SubTitle = styled(Caption1)`
		margin: 4px 0%;
    color: ${themes.basics[400]};
`


export const LastActivity = styled(Caption1)`
    color: ${themes.basics[400]};
`

export const Deactivated = styled(Caption1)`
    color: ${themes.danger.standard};
`

export const ButtonNavigation = styled.TouchableOpacity`
`
