import React from 'react'
import {
    Text,
    TouchableOpacity,
} from 'react-native'
import { useNavigation, useIsFocused } from '@react-navigation/native'

import styles from './styles.js';

const AssessmentCard = ({
    index,
    item,
    type
}) => {

    const navigation = useNavigation()

    return (
        <TouchableOpacity
            key={index}
            onPress={() => {
                navigation.navigate(
                    "assessment",
                    { id: item.id, type }
                );
            }}
            style={styles.AssessmentCard}
        >
            <Text style={styles.AssessmentCardTitle}>
                {item?.name}
            </Text>

            <Text style={styles.AssessmentCardType}>
                {
                    type == 'custom' ? 'Custom' :
                        type == 'prebuilt' ? 'Prebuilt' :
                            type == 'culture-maturity' ? 'Cultural Maturity' :
                                null
                }
            </Text>

            {
                item?.lifecycle_stage?.name ?
                    (
                        <Text style={styles.AssessmentCardLifecycleStage}>
                            {`Lifecycle Stage: ${item?.lifecycle_stage?.name} `}
                        </Text>
                    ) : null
            }

            {
                item?.five_s_category?.name ?
                    (
                        <Text style={styles.AssessmentCardCategory}>
                            {`5S Category: ${item?.five_s_category?.name}`}
                        </Text>
                    ) : null
            }


            <Text style={styles.AssessmentCardQuestionsCount}>
                {`${item?.assessment_questions?.length} Question(s)`}{' '}
            </Text>
        </TouchableOpacity>
    )

}

export default AssessmentCard