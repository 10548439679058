import styled from 'styled-components/native'
import { Subtitle1 } from '@/styles/fonts'

export const SocialContainer = styled.View`
  align-items: center;
  flex-direction: column;
`
export const SocialText = styled(Subtitle1)`
  color: #6d6d6d;
`
export const SocialIconsContainer = styled.View`
  flex-direction: row;
`

export const SocialIcon = styled.Image`
  margin: 10px;
`
export const RedText = styled(Subtitle1)`
  color: ${({ theme }) => theme.danger.standard};
`
