import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Avatar, Checkbox } from '@/components'
import { useNavigation } from '@react-navigation/native'
import {
  Container,
  MemberContainer,
  MemberInfo,
  Name,
  Office,
  Activity,
  ButtonNavigation,
} from './styles'
import { MembersContext } from '@/contexts/Community/Group/invite'

const MemberWeb = ({
  id,
  avatar,
  first_name,
  last_name,
  company,
  role,
  updated_at,
}) => {
  const [statusCheckbox, setStatusCheckbox] = useState('unchecked')
  const { setMembers, setVisible } = useContext(MembersContext)
  const navigation = useNavigation()

  const removeMember = useCallback(
    (members) => (members ?? [])?.filter(memberId => memberId !== id),
    [id]
  )

  const handleNavigation = useCallback(() => {
    setVisible(false)
    navigation.navigate('Profile', { id })
  }, [navigation])

  useEffect(() => {
    if (statusCheckbox === 'checked') {
      setMembers(members => {
        members.push(id)
        return members
      })
      return
    }
    setMembers(removeMember)
  }, [statusCheckbox])

  const handleCheckbox = useCallback(() => {
    if (statusCheckbox === 'checked') {
      setStatusCheckbox('unchecked')
      return
    }
    setStatusCheckbox('checked')
  }, [statusCheckbox, setStatusCheckbox])

  return (
    <Container>
      <Checkbox status={statusCheckbox} onPress={handleCheckbox} />
      <MemberContainer>
        <ButtonNavigation>
          <Avatar size={50} source={avatar ? { uri: avatar } : null} />
        </ButtonNavigation>
        <MemberInfo>
          <ButtonNavigation>
            <Name>{first_name} {last_name}</Name>
          </ButtonNavigation>
          <Office>{role ?? ''}</Office>
        </MemberInfo>
      </MemberContainer>
    </Container>
  )
}

MemberWeb.propTypes = {
  id: PropTypes.number,
  avatar: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  company: PropTypes.any,
  updated_at: PropTypes.string,
}

export default MemberWeb
