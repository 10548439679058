import React, { useCallback } from 'react'
import { ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { Icon, TextField, SimpleModal, Button } from '@/components'
import theme from '@/styles/themes/black'
import { Container, Header, BackButton, Title } from './styles'
import { ActivityIndicator, ProgressBar } from 'react-native-paper'
import { Formik } from 'formik'
import { actions as groupActions } from '@/redux/reducers/groups'
import { actions as teamActions } from '@/redux/reducers/teams'
import Toast from 'react-native-toast-message'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/employees'
import * as Yup from 'yup'
import { TextButton } from '@/pages/Community/styles/innerPages'
import { isMobile } from '@/styles'
import * as FileSystem from 'expo-file-system';
import {
  ContainerCoverImage,
  CoverButton,
  CoverImage,
  CoverWrapper,
  styles,
} from './styles'
import { openGallery } from '@/utils/gallery'
import { useNavigation, useRoute } from '@react-navigation/native'
import ModalV2 from '@/components/ModalV2'


import * as brandSelectors from '@/redux/selectors/branding'

const CreateEditGroup = ({ onClose, isVisible, setVisible, data, type }) => {



  const branding = useSelector(brandSelectors.getSettings);
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const dispatch = useDispatch()
  const loading = useSelector(selectors.loading)
  const isCreate = !data
  const navigation = useNavigation()
  const handleSubmit = useCallback((values) => {
    if (loading) {
      return
    }
    if (type === 'group') {

      if (isCreate) {
        dispatch(groupActions.store(values, onSubmit))
        return
      }
      dispatch(groupActions.update(values, onSubmit))
      return
    }
    if (isCreate) {
      dispatch(teamActions.store(values, onSubmit))
      return
    }
    dispatch(teamActions.update(values, onSubmit))
    setVisible(false)

    navigation.navigate('Teams')

  })

  const onSubmit = (error, success) => {
    Toast.show({
      type: error ? 'error' : 'success',
      position: 'bottom',
      text1: error ? error.message : success.message,
    })
    if (setVisible) {
      setVisible(false)
    }
    if (success && onClose) {
      if (type === 'group')
        dispatch(groupActions.index({ filter: 'employee' }, (err) => { console.log('err', err) }))
      else
        dispatch(teamActions.index({ filter: 'employee' }, (err) => { console.log('err', err) }))
      onClose(true)
    }
  }

  const initialValues = isCreate ? {
    name: '',
    description: '',
    image: '',
    image_data: '',
  } : data

  const handleCoverImage = useCallback(async (handleChange) => {
    try {
      const imageUri = await openGallery()
      handleChange('image')(imageUri)
      // if there is image need to read (if mobile)
      if (isMobile) {
        handleChange('image')('data:image/jpeg;base64,' + await FileSystem.readAsStringAsync(imageUri, { encoding: 'base64' }))
      }
    } catch (e) {
      console.log('Error opening gallery')
    }
  }, [])

  const handleOnlyClose = useCallback(() => {

    if (setVisible) {
      setVisible(false)
    }
    if (onClose) {
      onClose(false)
    }

  })

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(1).max(255),
    description: Yup.string().required().min(1).max(255),
  })

  return (
    <ModalV2
      isVisible={isVisible}
      setVisibility={onClose}
    //title={isCreate ? 'Create' : 'Update' + ' ' + type === 'group' ? 'Group' : 'Team'}
    >
      <Container>
        <Formik
          enableReinitialize
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit, }) => (
            <ScrollView
              style={styles.scrollView}
              contentContainerStyle={styles.scrollViewContent}
            >
              <CoverWrapper>
                <ContainerCoverImage>
                  <CoverImage source={
                    isNaN(values?.image)
                      ? { uri: values?.image }
                      : values?.image
                  } resizeMode="cover" />
                  <CoverButton onPress={() => handleCoverImage(handleChange)}>
                    <Icon
                      icon="addPhoto"
                      color={theme.basics.brand}
                      width={15}
                      height={14}
                    />
                  </CoverButton>
                </ContainerCoverImage>
              </CoverWrapper>
              <TextField
                label={type === 'group' ? 'Group Name' : 'Team Name'}
                value={values.name}
                autoCapitalize="none"
                onChangeText={handleChange('name')}
              />
              <TextField
                selectionColor='blue'
                style={{ bottom: 10 }}
                label="Description"
                value={values.description}
                autoCapitalize="none"
                onChangeText={handleChange('description')}
                multiline
                numberOfLines={4}
              />
              <Button
                onPress={handleSubmit}
                color={color_scheme_primary_button_background ? color_scheme_primary_button_background : "black"}
                disabled={!isValid}>
                {loading && (
                  <ActivityIndicator
                    size={20}
                    animating
                    color={theme.basics.background_white}
                    style={{ marginRight: 10 }}
                  />
                )}
                <TextButton color={color_scheme_primary_button_text}>
                  {isCreate ? 'Create' : 'Update'} {type === 'team' ? 'Team' : 'Group'}
                </TextButton>
              </Button>
            </ScrollView>
          )}
        </Formik>
      </Container>
    </ModalV2>
  )
}

CreateEditGroup.propTypes = {
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  setVisible: PropTypes.func,
  data: PropTypes.any,
}

export default CreateEditGroup
