import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/profile'
import * as api from '@/redux/api/profile'

// PROFILE_FETCH
function* fetchProfile(action) {
  try {
    const payload = yield call(api.fetchProfile, action.id, action)
    yield put({ type: constants.PROFILE_FETCH.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.PROFILE_FETCH.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchFetchProfile() {
  yield takeLatest(constants.PROFILE_FETCH.ACTION, fetchProfile)
}

// PROFILE_FETCH_RECENT_REWARDS
function* fetchRecentRewards(action) {
  try {
    const payload = yield call(api.fetchRecentRewards, action)
    yield put({ type: constants.PROFILE_FETCH_RECENT_REWARDS.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.PROFILE_FETCH_RECENT_REWARDS.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* wartchFetchRecentRewards() {
  yield takeLatest(
    constants.PROFILE_FETCH_RECENT_REWARDS.ACTION,
    fetchRecentRewards,
  )
}

// PROFILE_FETCH_PREFERRED_TOPICS
function* fetchPreferredTopics(action) {
  try {
    const payload = yield call(api.fetchPreferredTopics, action)
    yield put({
      type: constants.PROFILE_FETCH_PREFERRED_TOPICS.SUCCESS,
      payload,
    })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.PROFILE_FETCH_PREFERRED_TOPICS.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchFetchPreferredTopics() {
  yield takeLatest(
    constants.PROFILE_FETCH_PREFERRED_TOPICS.ACTION,
    fetchPreferredTopics,
  )
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchFetchProfile),
    fork(wartchFetchRecentRewards),
    fork(watchFetchPreferredTopics),
  ])
}
