import styled from 'styled-components/native'

export const CollapseButtonContainer = styled.View`
  z-index: 10;
  position: absolute;
  width: 100%;
  padding-right: 1.5vw;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export const CollapseButtonToggle = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background: ${({ background }) => background || '#000'};
`

export const Button = styled.TouchableOpacity`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 8px 5px;
  border-radius: 10px;
  margin-left: 10px;
`
// export const Button = styled.TouchableOpacity`
//   width: 100%;
//   height: 35px;
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   padding: 30px 0px 15px 0px;
//   border-radius: 10px;
// `