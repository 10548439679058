import React from 'react'
import RBSheet from 'react-native-raw-bottom-sheet'
import { View } from 'react-native'
import ActionButton from '@/components/Chat/components/ActionButton'


const BottomSheet = ({
  setRef,
  onPressCamera,
  onPressImage,
  onPressVideo,
}) => {
  return (
    <RBSheet ref={setRef}>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <ActionButton
          onPress={onPressCamera}
          icon="camera"
          title="Camera"
        />
        <ActionButton
          onPress={onPressImage}
          icon="image"
          title="Image"
        />
        <ActionButton
          onPress={onPressVideo}
          icon="video"
          title="Video"
        />
      </View>
    </RBSheet>
  )
}

export default BottomSheet
