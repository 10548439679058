import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	Image,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	TextInput,
	FlatList,
	Dimensions,
	CheckBox,
	Platform,
	ActivityIndicator,
} from "react-native";
import Modal from "react-native-modal";
import BottomSheet from "./BottomSheet";
import ApiHandler from "@/api/ApiHandler";
import { Ionicons } from "@expo/vector-icons";
const deviceHeight = Dimensions.get("window").height;
import { MilestoneComponent } from "./MilestoneComponent";
import ModalV2 from "@/components/ModalV2"

const ManagerMilestoneModal = ({
	milestoneListModal,
	toggleMilestoneListModal,
	rewardOrders,
}) => {
	const [milestones, setMilestones] = useState([]);
	const [loading, setLoading] = useState(false);

	const getMilestone = async () => {
		setLoading(true);
		try {
			const response = await new ApiHandler().getMilestones();
			if (response?.data) {
				setMilestones(response?.data?.data?.data);
				setLoading(false);
			}
		} catch (error) {
			console.log("ERROR", error);
			setLoading(false);
		} finally {
		}
	};

	useEffect(() => {
		getMilestone();
	}, []);

	return Platform.OS == "web" ? (
		<View style={{ flex: 1 }}>
			<Modal
				isVisible={milestoneListModal}
				scrollVertical="true"
				propagateSwipe={true}
				animationIn={"slideInRight"}
				animationOut={"slideOutRight"}
				deviceHeight={deviceHeight}
				style={styles.rightSideModal}
			>
				<ScrollView scrollVertical showsVerticalScrollIndicator={false}>
					<TouchableOpacity
						onPress={toggleMilestoneListModal}
						style={styles.closeBtn}
					>
						<Ionicons name="close-outline" size={35} />
					</TouchableOpacity>
					<Text style={styles.rightSideModalTitle}>{"Milestones"}</Text>
					<View style={{ width: "100%", alignItems: "center" }}>
						{loading ? (
							<ActivityIndicator
								size={30}
								animating
								color="red"
								style={{ marginRight: 10 }}
							/>
						) : null}
						<FlatList
							vertical
							data={milestones}
							// data={rewardOrders}
							renderItem={({ item }) => {
								return (
									<MilestoneComponent
										width={"100%"}
										background="#f1f0f0"
										profileImg={item?.reward?.image}
										title={item?.name}
										description={item?.description}
										rewardedTxt="24 Completed"
										pendingTxt="10 Pending"
										startTxt="10 Yet to Start"
										milestoneId={item?.id}
										avatar={item?.avatar}
										awarded_employees={item?.awarded_employees}
										completed={item?.completed}
										pending={item?.pending}
										yet_to_start={item?.yet_to_start}
										criteriaTypeLabel={item?.criteria_type_label}
									/>
								);
							}}
							keyExtractor={(item) => item.id}
						/>
					</View>
				</ScrollView>
			</Modal>
		</View>
	) : (
		<>
			<ModalV2
				title={'Milestones'}
				isVisible={milestoneListModal}
				setVisibility={toggleMilestoneListModal}
			>
				<FlatList
					vertical
					data={milestones}
					// data={rewardOrders}
					renderItem={({ item }) => {
						return (
							<MilestoneComponent
								width={"100%"}
								background="#f1f0f0"
								profileImg={item?.reward?.image}
								title={item?.name}
								description={item?.description}
								rewardedTxt="24 Completed"
								pendingTxt="10 Pending"
								startTxt="10 Yet to Start"
								milestoneId={item?.id}
								avatar={item?.avatar}
								awarded_employees={item?.awarded_employees}
								completed={item?.completed}
								pending={item?.pending}
								yet_to_start={item?.yet_to_start}
								criteriaTypeLabel={item?.criteria_type_label}
							/>
						);
					}}
					keyExtractor={(item) => item.id}
				/>
			</ModalV2>
		</>
	);
};
export default ManagerMilestoneModal;

const styles = StyleSheet.create({
	closeBtn: {
		marginTop: 8,
		marginLeft: 8,
		alignItems: "flex-start",
		// backgroundColor:'red',
		width: "100%",
	},
	rightSideModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		marginLeft: 15,
	},
	headerTitle: {
		fontSize: 32,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginHorizontal: 16,
		marginTop: 10,
	},
	modalProfilePic: {
		height: 22,
		width: 35,
		borderRadius: 4,
	},
	modalTitle: {
		marginLeft: 5,
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	importedBtn: {
		backgroundColor: "#FFCF70",
		borderRadius: 4,
		height: 16,
		width: 45,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 15,
	},
	importedBtnTxt: {
		fontSize: 8,
		fontWeight: "500",
		lineHeight: 10,
	},
	photoView: {
		flexDirection: "row",
		alignItems: "center",
	},
	photo: {
		height: 50,
		width: 50,
		borderRadius: 33,
		marginEnd: 8,
	},

	imgCountModal: {
		fontSize: 15,
		lineHeight: 20,
		color: "#fff",
		fontWeight: "400",
		position: "absolute",
		top: 15,
		left: 10,
	},
	awardeesTxt: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
	},
	input: {
		borderBottomWidth: 1,
		borderBottomColor: "#959595",
		marginTop: 25,
		marginBottom: 10,
		height: 40,
		padding: 5,
	},
	searchIcon: {
		position: "absolute",
		right: 20,
		bottom: 20,
	},
	descriptionView: {
		flex: 0.45,
		paddingVertical: 10,
	},
	selectCheck: {
		flexDirection: "row",
		marginVertical: 8,
		alignItems: "center",
	},
	memberName: {
		fontSize: 15,
		lineHeight: 20,
		fontWeight: "400",
	},
	memberPost: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#B5B5B5",
	},
	editBtn: {
		backgroundColor: "#000",
		height: 40,
		width: 133,
		borderRadius: 3,
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#000",
		borderWidth: 1,
	},
	cancelBtn: {
		marginEnd: 10,
		backgroundColor: "#fff",
	},
	editBtnTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	milestoneModalHeader: {
		flex: 1,
		paddingHorizontal: 25,
	},

	rightSideModalDec: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
	},
	dropDownView: {
		marginTop: 20,
		paddingVertical: 15,
	},
	rightSideModalPointsTitle: {
		fontSize: 18,
		lineHeight: 21.09,
		fontWeight: "400",
	},
	selectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	selectReward: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
		color: "#FB6758",
	},
	selectedContainer: {
		backgroundColor: "#E8E8E8",
		height: 117,
		// width: "35,",
		borderRadius: 4,
		paddingHorizontal: 12,
		marginVertical: 5,
		paddingVertical: 5,
	},
	selectedCardView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	selectedCardHeader: {
		flexDirection: "row",
		alignItems: "center",
	},
	radioBtn: {
		height: 12,
		width: 12,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#000",
		marginEnd: 5,
	},
	cardImg: {
		height: 22,
		width: 35,
		borderRadius: 3,
		marginEnd: 5,
	},
	selectedCardTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	criteriaTxt: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#FB6758",
	},
	selectedCardDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#000",
		marginVertical: 5,
	},
	cardBottom: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 5,
	},
	awardTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#00B15C",
	},
	ipmTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#000",
	},
	rightModalBtn: {
		backgroundColor: "#FB6758",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		height: 40,
		marginVertical: 10,
	},
	rightModalBtnText: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	rightSideModal: {
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderBottomLeftRadius: 20,
		alignSelf: "flex-end",
		paddingVertical: 15,
		margin: 0,
		width: "20%",
		// height: "100%",
	},
	rewardModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rewardModalDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#141414",
	},
	modal: {
		height: "70%",
		width: "55%",
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 20,
		paddingVertical: 15,
	},
	rewardTabView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	rewardTab: {
		justifyContent: "center",
		alignItems: "center",
		borderBottomWidth: 2,
		paddingVertical: 5,
		width: "48%",
		flex: 0.5,
		marginHorizontal: 2,
		marginVertical: 20,
	},
	rewardBtnTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "400",
		color: "#141414",
	},
	imgContainer: {
		flex: 1,
		justifyContent: "space-between",
		flexDirection: "row",
		marginVertical: 10,
	},
	titleText: {
		fontSize: 18,
		lineHeight: 21,
		fontWeight: "400",
		color: "#141414",
		marginVertical: 5,
	},
	giftCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		paddingHorizontal: 10,
	},

	giftImgCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		borderColor: "#FB6758",
		borderWidth: 1,
		borderStyle: "dashed",
	},
	giftCardTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
	},
	imgSizeTxt: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: "500",
		color: "gray",
	},

	rewardTxtInput: {
		padding: 10,
		height: 100,
		marginVertical: 10,
		backgroundColor: "#F9F9F9",
	},
	importView: {
		flex: 1,
		alignItems: "center",
		alignSelf: "center",
	},

	rewardModalSelectionList: { height: 138, width: "98%", marginEnd: 5 },

	bottomView: {
		justifyContent: "space-between",
		flexDirection: "row",
		// marginTop: 150,
		position: "absolute",
		bottom: 5,
		right: 20,
		left: 20,
	},
	saveCreateTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		color: "#FB6758",
	},
	btnAlignment: {
		alignItems: "center",
	},
	rewardModalBtn: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#FB6758",
		height: 40,
		width: 180,
		borderRadius: 4,
	},
	createTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	workspaceBtn: {
		backgroundColor: "#FB6758",
		height: 40,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		position: "absolute",
		width: 227,
		alignSelf: "flex-end",
		bottom: 5,
		right: 20,
	},

	container: {
		padding: 15,
		flex: 1,
	},
	rewardTitle: {
		fontSize: 36,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	subTitleView: {
		flexDirection: "row",
	},
	activityTxt: {
		fontSize: 24,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	monthView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 20,
		marginTop: 10,
		marginEnd: 8,
	},
	monthTxt: {
		fontSize: 14,
		lineHeight: 18.3,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginEnd: 5,
	},
	cardView: {
		flexDirection: "row",
	},
	topPerformersView: { flex: 0.4, paddingLeft: 15 },

	lastMonthTxt: {
		color: "#909090",
		fontSize: 14,
		lineHeight: 18.23,
		fontWeight: "400",
		marginTop: 40,
	},
	headerTitleBtnView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 30,
		marginBottom: 10,
		justifyContent: "space-between",
	},
	titleView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},

	Button: {
		backgroundColor: "#FB6758",
		paddingHorizontal: 50,
		paddingVertical: 1.2,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
	},

	BtnText: {
		color: "#fff",
		fontSize: 18,
		lineHeight: 35,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	btnView: {
		flex: 0.1,
		flexDirection: "row",
		alignSelf: "flex-end",
	},
});
