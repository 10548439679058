import React, { useCallback, useEffect, useState, useRef } from 'react'
import { BackArrow, TextField } from '@/components'
import { isMobile } from '@/styles'
import { View, ScrollView, Platform, Dimensions } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ImagePicker from 'expo-image-picker'
import { ActivityIndicator, ProgressBar, TextInput } from 'react-native-paper'
import { openGallery, openCamera } from '@/utils/gallery'
import { actions } from '@/redux/reducers/course'
import { actions as actionCommon } from '@/redux/reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import Toast from 'react-native-toast-message'
import { FontAwesome } from '@expo/vector-icons'
import * as selectors from '@/redux/selectors/course'
import * as selectorsCommon from '@/redux/selectors/common'
import { useNavigation } from '@react-navigation/native'
import SimpleSelect from '@/components/SimpleSelect'
import UploadArea from '@/components/UploadArea'
import theme from '@/styles/themes/black'
import * as FileSystem from 'expo-file-system'
import {
  CentralizedContainer,
  Actions,
  Title,
  Header,
  TitleWrapper,
  CreateButton,
  styles,
  TextButton,
  ContentText,
  RouteName,
  RouteText,
} from '../styles'
import { ModuleButton, ModuleView, ModuleButtonText, AddButton } from './styles'
import * as brandSelectors from '@/redux/selectors/branding'
import { TouchableOpacity } from 'react-native-gesture-handler'
import moment from 'moment'
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import ApiHandler from '@/api/ApiHandler'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const window = Dimensions.get('window');

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  excerpt: Yup.string(),
  language_id: Yup.number(),
  // employee_lifecycle_stage_id: Yup.number(),
  // five_s_category_id: Yup.number(),
  modules: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().required(),
      content: Yup.string(),
    }),
  ),
})

const Module = ({
  values,
  setValues,
  item,
  branding,
  index,
  onClickImage,
  onClickVideo,
}) => {
  const [visible, setVisible] = useState(true)
  return (
    <View style={{ marginTop: 20 }}>
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <ModuleButton onPress={() => setVisible(!visible)}>
          <ModuleButtonText>
            {item.title ? item.title : 'Module'}
          </ModuleButtonText>
          <FontAwesome
            name={visible ? 'chevron-up' : 'chevron-down'}
            size={12}
            color="#fff"
          />
        </ModuleButton>
        {index != 0 && (
          <TouchableOpacity
            style={{ marginLeft: 20 }}
            onPress={() => {
              values.modules.splice(index, 1)
              setValues({ ...values })
            }}
          >
            <FontAwesome name="trash" size={30} color="#f00" />
          </TouchableOpacity>
        )}
      </View>
      {visible && (
        <>
          <TextField
            value={item.title}
            autoCapitalize="none"
            keyboardType="default"
            label="Write the module title here"
            onChangeText={(val) => {
              values.modules[index].title = val
              setValues({ ...values })
            }}
          />
          <ContentText>Module Description</ContentText>
          {/* <JoditEditor
            ref={editor}
            value={item.content}
            tabIndex={1}
            onChange={(val) => {
              values.modules[index].content = val
              setValues({ ...values })
            }}
          /> */}
          <TextInput
            label="Content"
            value={item.content}
            autoCapitalize="none"
            keyboardType="default"
            multiline={true}
            numberOfLines={5}
            maxHeight={window.height * 0.20}
            style={{ paddingBottom: 20, paddingHorizontal: 0, backgroundColor: '#fff' }}
            onChangeText={(val) => {
              values.modules[index].content = val
              setValues({ ...values })
            }}
          />
          {/* <TextField
            label="Content"
            value={item.content}
            autoCapitalize="none"
            keyboardType="default"
            multiline={true}
            numberOfLines={5}
            onChangeText={(val) => {
              values.modules[index].content = val
              setValues({ ...values })
            }}
          /> */}
          <View style={{ height: 20 }} />
          <UploadArea
            topText="Drag/Drop a Cover Image here or"
            buttonText="Select A Cover Image"
            bottomText=" "
            onClick={onClickImage}
            files={item?.image ? [item?.image] : []}
            brandingSetting={branding}
            mediaType={1}
          />
          <View style={{ height: 20 }} />
          <UploadArea
            topText="Drag/Drop a Video here or"
            buttonText="Select A Video"
            bottomText=" "
            onClick={onClickVideo}
            files={item?.video ? [item?.video] : []}
            brandingSetting={branding}
            mediaType={2}
          />
        </>
      )}
    </View>
  )
}

const CreateCourse = ({ route }) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const loading = useSelector(selectors.loading)
  const lifecycles = useSelector(selectorsCommon.lifecycles)
  const categories = useSelector(selectorsCommon.categories)
  // const course = route?.params?.course

  const courseDetail = route?.params?.course
  const courseSelector = useSelector(selectors.course)
  // const [course, setCourse] = useState(courseSelector)
  const [course, setCourse] = useState(null)
  // const languageId = courseDetail && courseDetail.language_id?courseDetail?.language_id:40;
  const languageId = 40;
  const isCreate = !route?.params?.course
  const branding = useSelector(brandSelectors.getSettings)
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const scheduledPublishTimeInput = useRef()
  const [isOpenDatePicker, setDatePicker] = useState(false)
  const [isOpenTimePickerModal, setTimePickerModal] = useState(false)
  const [scheduleDate, setScheduleDate] = useState('')

  const [isLoading, setloading] = useState(false)
  const [lifecyclesData, setLifecyclesData] = useState(null)
  const [categoriesData, setCategories] = useState(null)
  const [CategoryCulturalMaturityScoresData, setCategoryCulturalMaturityScores] = useState(null)
  const [TotalCulturalMaturityScoresData, setTotalCulturalMaturityScores] = useState(null)
  const [SegmentsData, setSegments] = useState(null)
  const [languagesData, setLanguagesData] = useState(null)
  const [languagesValue, setLanguageVal] = useState(null)

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      setScheduleDate(false);
      setDatePicker(false);
      setTimePickerModal(false);
      getTags();
      getLanguages();
      setLanguageVal(languageId)
      if (courseDetail?.id) {
        // setLanguageVal(languageId)
        dispatch(actions.getCourseWithIdAction({ id: courseDetail?.id, language_id: languageId }, () => { }))
      }
      dispatch(actionCommon.getFiveSCategoriesAction(() => { }))
      dispatch(actionCommon.getLifecycleStagesAction(() => { }))
    })
    return unsubscribe
  }, [])

  useEffect(() => {
    if (scheduleDate) {
      setDatePicker(false);
      setTimePickerModal(true);
    }


  }, [scheduleDate])

  useEffect(() => {
    setloading(false);
    // route?.params?.language_id
    if (courseSelector && !isCreate) {
      if (Object.keys(courseSelector).length === 0) {
        setLanguageVal(courseSelector?.language_id)
        setCourse(courseSelector)
      }
      else {
        if (languagesValue == courseSelector?.language_id) {
          setCourse(courseSelector)
        }
        else {
          const courses = courseSelector
          courses.language_id = languagesValue
          setCourse(courses)
        }
      }
    }

  }, [courseSelector])

  useEffect(() => {
    if (languagesValue) {
      dispatch(actions.getCourseWithIdAction({ id: courseDetail?.id, language_id: languagesValue }, () => { }))
    }

  }, [languagesValue])

  const setCourseData = async (languageID) => {
    setLanguageVal(languageID)
    dispatch(actions.getCourseWithIdAction({ id: courseDetail?.id, language_id: languageID }, () => { }))
  }

  const getTags = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getTagsLearning();
      if (response?.data) {
        setloading(false);
        const EmployeeLifecycleStages = response?.data.map((item) => item.EmployeeLifecycleStages)
        const FiveSCategories = response?.data.map((item) => item.FiveSCategories)
        const CategoryCulturalMaturityScores = response?.data.map((item) => item.CategoryCulturalMaturityScores)
        const TotalCulturalMaturityScores = response?.data.map((item) => item.TotalCulturalMaturityScores)
        const Segments = response?.data.map((item) => item.Segments)

        const lcd = EmployeeLifecycleStages[0] && EmployeeLifecycleStages[0].length > 0 ? EmployeeLifecycleStages[0] : null;
        const lcdData = lcd ? [{ id: '', name: '' }, ...lcd] : lcd;
        setLifecyclesData(lcdData);
        const cat = FiveSCategories[0] && FiveSCategories[0].length > 0 ? FiveSCategories[0] : null;
        const catData = cat ? [{ id: '', name: '' }, ...cat] : cat;
        setCategories(catData);
        const catMatScore = CategoryCulturalMaturityScores[0] && CategoryCulturalMaturityScores[0].length > 0 ? CategoryCulturalMaturityScores[0].map((ms) => { return { id: ms, name: ms.toString() } }) : null;
        const catMatScoreData = catMatScore ? [{ id: '', name: '' }, ...catMatScore] : catMatScore;
        setCategoryCulturalMaturityScores(catMatScoreData);
        const totMatScore = TotalCulturalMaturityScores[0] && TotalCulturalMaturityScores[0].length > 0 ? TotalCulturalMaturityScores[0].map((ts) => { return { id: ts, name: ts.toString() } }) : null;
        const totMatScoreData = totMatScore ? [{ id: '', name: '' }, ...totMatScore] : totMatScore;
        setTotalCulturalMaturityScores(totMatScoreData);
        const seg = Segments[0] && Segments[0].length > 0 ? Segments[0].map((s) => { return { id: s.id, name: s.segment_name } }) : null;
        const segData = seg ? [{ id: '', name: '' }, ...seg] : seg;
        setSegments(segData);
      }
    } catch (error) {
      setloading(false);
    }
  }

  const getLanguages = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getAllLanguages();
      //console.log('language data',response)
      if (response?.data) {
        setloading(false);
        const languages = response?.data.map((l) => { return { id: l.id, name: l.display_name } })
        setLanguagesData(languages);
      }

    } catch (error) {
      setloading(false);
    }
  }

  const handleSubmit = useCallback(async (values) => {
    // values.employee_lifecycle_stage_id = values.employee_lifecycle_stage_id == '' ? null : values.employee_lifecycle_stage_id;
    // values.five_s_category_id = values.five_s_category_id == '' ? null : values.five_s_category_id;
    // values.category_cultural_maturity_score = values.category_cultural_maturity_score == '' ? null : values.category_cultural_maturity_score;
    // values.total_cultural_maturity_score = values.total_cultural_maturity_score == '' ? null : values.total_cultural_maturity_score;
    // values.segment_id = values.segment_id == '' ? null : values.segment_id;
    // values.language_id = values.language_id ? values.language_id : 40;

    values.employee_lifecycle_stage_id = values.employee_lifecycle_stage_id ? values.employee_lifecycle_stage_id : null;
    values.five_s_category_id = values.five_s_category_id ? values.five_s_category_id : null;
    values.category_cultural_maturity_score = values.category_cultural_maturity_score ? values.category_cultural_maturity_score : null;
    values.total_cultural_maturity_score = values.total_cultural_maturity_score ? values.total_cultural_maturity_score : null;
    values.segment_id = values.segment_id ? values.segment_id : null;
    values.language_id = values.language_id ? values.language_id : 40;

    if (loading) {
      return
    }

    if (values.image && !values.image.substring(0, 10).includes('http')) {
      values.image = values.image.split(',')[1]
    }

    for (let index = 0; index < values.modules.length; index++) {
      const element = values.modules[index]
      element.module_language_id = values.language_id ? values.language_id : 40;
      element.language_id = values.language_id ? values.language_id : 40;
      if (!element.image.substring(0, 10).includes('http')) {
        element.image = element.image.split(',')[1]
      }
      if (!element.video.substring(0, 10).includes('http')) {
        element.video = element.video.split(',')[1]
      }
    }


    //  if (course.id) {
    //   dispatch(actions.updateCourseAction({ ...values, id: course?.id }, onSubmit))
    //   return
    // }

    // dispatch(actions.createCourseAction({ ...values }, onSubmit))

    if (isCreate) {
      dispatch(actions.createCourseAction({ ...values }, onSubmit))
    } else {
      dispatch(
        actions.updateCourseAction(
          {
            id: course.id,
            name: values.name,
            excerpt: values.excerpt,
            employee_lifecycle_stage_id: values.employee_lifecycle_stage_id,
            five_s_category_id: values.five_s_category_id,
            category_cultural_maturity_score: values.category_cultural_maturity_score,
            total_cultural_maturity_score: values.total_cultural_maturity_score,
            segment_id: values.segment_id,
            language_id: values.language_id,
            image: values.image,
            modules: values.modules,
            duration: values.duration,
            scheduled_publish: values.scheduled_publish,
          },
          onSubmit,
        ),
      )
    }
  })

  const onSubmit = (error, success) => {
    Toast.show({
      type: error ? 'error' : 'success',
      position: 'bottom',
      text1: error ? error.message : success.message,
    })
    navigation.navigate('Courses')
  }
  const [initVal, setinitVal] = useState({
    name: '',
    excerpt: '',
    image: '',
    employee_lifecycle_stage_id: null,
    five_s_category_id: null,
    category_cultural_maturity_score: null,
    total_cultural_maturity_score: null,
    segment_id: null,
    language_id: 40,
    duration: '100',
    modules: [
      {
        title: '',
        content: '',
        image: '',
        video: '',
        length: '10',
        language_id: 40,
        module_language_id: 40,
      },
    ],
  })
  useEffect(() => {
    const initialValues = course
      ? { ...course, language_id: course.language_id ? course.language_id : 40, scheduled_publish: course.scheduled_publish ? moment(course.scheduled_publish).format('MM/DD/YYYY hh:mm a') : '' }
      : {
        name: '',
        excerpt: '',
        image: '',
        employee_lifecycle_stage_id: null,
        five_s_category_id: null,
        category_cultural_maturity_score: null,
        total_cultural_maturity_score: null,
        segment_id: null,
        language_id: 40,
        duration: '100',
        modules: [
          {
            title: '',
            content: '',
            image: '',
            video: '',
            length: '10',
            language_id: 40,
            module_language_id: 40,
          },
        ],
        scheduled_publish: '',
      }
    setinitVal(initialValues)
  }, [course])

  return (
    <>
      <PageHeader
        title={isCreate ? 'Create Course' : 'Update Course'}
        icon={false}
        backEnabled={true}
      />

      <PageContent>
        <View
          style={{
            width: '100%',
            maxWidth: 700,
            marginHorizontal: 'auto'
          }}
        >
          <ContentBlockV1>
            <Formik
              enableReinitialize
              validateOnMount
              onSubmit={handleSubmit}
              initialValues={initVal}
              validationSchema={validationSchema}
            >
              {({
                values,
                isValid,
                handleChange,
                handleSubmit,
                setValues,
                errors,
              }) => {
                return (
                  <>
                    <View>

                      <SimpleSelect
                        list={languagesData}
                        label="Select A Language"
                        value={values.language_id}
                        //setValue={handleChange('language_id')}
                        setValue={(e) => {
                          setValues({ ...values, language_id: e })
                          //setCourseData(e)
                          setLanguageVal(e)

                        }}
                      //setValue={(e) => setLanguage(e,handleChange)}
                      />

                      <TextField
                        label="Title"
                        value={values.name}
                        autoCapitalize="none"
                        keyboardType="default"
                        onChangeText={handleChange('name')}
                      />
                      {/* <TextField
    label="Excerpt"
    value={values.excerpt}
    autoCapitalize="none"
    keyboardType="default"
    multiline={true}
    numberOfLines={5}
    onChangeText={handleChange('excerpt')}
    style={{ marginBottom: 20 }}
  /> */}
                      <TextInput
                        label="Excerpt"
                        value={values.excerpt}
                        autoCapitalize="none"
                        keyboardType="default"
                        multiline={true}
                        numberOfLines={5}
                        onChangeText={handleChange('excerpt')}
                        maxHeight={window.height * 0.20}
                        style={{ marginBottom: 20, paddingHorizontal: 0, backgroundColor: '#fff' }}
                      />
                      <UploadArea
                        topText="Drag/Drop a Cover Image here or"
                        buttonText="Select A Cover Image"
                        bottomText=" "
                        onClick={async () => {
                          try {
                            const imageUri = await openGallery()
                            if (isMobile) {
                              const data =
                                'data:image/jpeg;base64,' +
                                (await FileSystem.readAsStringAsync(imageUri, {
                                  encoding: 'base64',
                                }))
                              values.image = data
                            } else {
                              values.image = imageUri
                            }
                            setValues({ ...values })
                          } catch (e) {
                            console.log(e)
                            console.log('Error opening gallery')
                          }
                        }}
                        files={values?.image ? [values?.image] : []}
                        brandingSetting={branding}
                        mediaType={1} // image type = 1, video type = 2, image&video type = 0 or undefined
                      />
                      <ModuleView>
                        {values.modules && values.modules.map((item, index) => {
                          return (
                            <Module
                              item={item}
                              values={values}
                              setValues={setValues}
                              index={index}
                              branding={branding}
                              key={index}
                              onClickImage={async () => {
                                try {
                                  const imageUri = await openGallery()
                                  if (isMobile) {
                                    const data =
                                      'data:image/jpeg;base64,' +
                                      (await FileSystem.readAsStringAsync(
                                        imageUri,
                                        {
                                          encoding: 'base64',
                                        },
                                      ))
                                    values.modules[index].image = data
                                  } else {
                                    values.modules[index].image = imageUri
                                  }
                                  setValues({ ...values })
                                } catch (e) {
                                  console.log('Error opening gallery')
                                }
                              }}
                              onClickVideo={async () => {
                                try {
                                  const videoPicerkOption = {
                                    mediaTypes: ImagePicker.MediaTypeOptions.Videos,
                                  }
                                  let videoUri = await openGallery(
                                    videoPicerkOption,
                                  )

                                  if (isMobile) {
                                    const data =
                                      'data:video/mp4;base64,' +
                                      (await FileSystem.readAsStringAsync(
                                        videoUri,
                                        {
                                          encoding: 'base64',
                                        },
                                      ))
                                    values.modules[index].video = data
                                  } else {
                                    values.modules[index].video = videoUri
                                  }
                                  setValues({ ...values })
                                } catch (e) {
                                  console.log('Error opening gallery')
                                }
                              }}
                            />
                          )
                        })}
                      </ModuleView>
                      <AddButton
                        onPress={() => {
                          values.modules.push({
                            title: '',
                            content: '',
                            image: '',
                            video: '',
                            length: 0,
                          })
                          setValues({ ...values })
                        }}
                      >
                        <ModuleButtonText>Add a module</ModuleButtonText>
                        <FontAwesome name={'plus-circle'} size={16} color="#fff" />
                      </AddButton>
                      {isMobile
                        ? <View
                          style={{
                            flexDirection: isMobile ? 'column' : 'row',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            marginTop: 20,
                          }}
                        >
                          <SimpleSelect
                            list={lifecyclesData}
                            label="Lifecycle Stage"
                            value={values.employee_lifecycle_stage_id}
                            setValue={handleChange('employee_lifecycle_stage_id')}
                          />
                          <SimpleSelect
                            list={categoriesData}
                            label="Category"
                            value={values.five_s_category_id}
                            setValue={handleChange('five_s_category_id')}
                          />

                          <SimpleSelect
                            list={CategoryCulturalMaturityScoresData}
                            label="Category Cultural Maturity Score"
                            value={values.category_cultural_maturity_score}
                            setValue={handleChange('category_cultural_maturity_score')}
                          />

                          <SimpleSelect
                            list={TotalCulturalMaturityScoresData}
                            label="Total Cultural Maturity Score"
                            value={values.total_cultural_maturity_score}
                            setValue={handleChange('total_cultural_maturity_score')}
                          />

                          <SimpleSelect
                            list={SegmentsData}
                            label="Segment"
                            value={values.segment_id}
                            setValue={handleChange('segment_id')}
                          />

                        </View>
                        : <>
                          <View
                            style={{
                              flexDirection: isMobile ? 'column' : 'row',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between',
                            }}
                          >
                            <SimpleSelect
                              list={lifecyclesData}
                              label="Lifecycle Stage"
                              value={values.employee_lifecycle_stage_id}
                              setValue={handleChange('employee_lifecycle_stage_id')}
                            />
                            <SimpleSelect
                              list={categoriesData}
                              label="Category"
                              value={values.five_s_category_id}
                              setValue={handleChange('five_s_category_id')}
                            />
                          </View>

                          <View
                            style={{
                              flexDirection: isMobile ? 'column' : 'row',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between',
                            }}
                          >

                            <SimpleSelect
                              list={CategoryCulturalMaturityScoresData}
                              label="Category Cultural Maturity Score"
                              value={values.category_cultural_maturity_score}
                              setValue={handleChange('category_cultural_maturity_score')}
                            />

                            <SimpleSelect
                              list={TotalCulturalMaturityScoresData}
                              label="Total Cultural Maturity Score"
                              value={values.total_cultural_maturity_score}
                              setValue={handleChange('total_cultural_maturity_score')}
                            />
                          </View>

                          <View
                            style={{
                              flexDirection: isMobile ? 'column' : 'row',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between',
                            }}
                          >
                            <SimpleSelect
                              list={SegmentsData}
                              label="Segment"
                              value={values.segment_id}
                              setValue={handleChange('segment_id')}
                            />
                          </View>
                        </>

                      }
                      {/* <View
    style={{
      flexDirection: isMobile ? 'column' : 'row',
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      marginTop: 20,
    }}
  >
    <SimpleSelect
      list={lifecyclesData}
      label="Lifecycle Stage"
      value={values.employee_lifecycle_stage_id}
      setValue={handleChange('employee_lifecycle_stage_id')}
    />
    <SimpleSelect
      list={categoriesData}
      label="Category"
      value={values.five_s_category_id}
      setValue={handleChange('five_s_category_id')}
    />

  <SimpleSelect
      list={CategoryCulturalMaturityScoresData}
      label="Category Cultural Maturity Score"
      value={values.category_cultural_maturity_score}
      setValue={handleChange('category_cultural_maturity_score')}
    />

    <SimpleSelect
      list={TotalCulturalMaturityScoresData}
      label="Total Cultural Maturity Score"
      value={values.total_cultural_maturity_score}
      setValue={handleChange('total_cultural_maturity_score')}
    />

    <SimpleSelect
      list={SegmentsData}
      label="Segment"
      value={values.segment_id}
      setValue={handleChange('segment_id')}
    />

  </View> */}

                      {/* Schedule start */}
                      <ContentText>Schedule</ContentText>
                      <View
                        style={{
                          flexDirection: isMobile ? 'column' : 'row',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <TextInput
                          ref={scheduledPublishTimeInput}
                          label="Date time"
                          value={values.scheduled_publish ? values.scheduled_publish : ''}
                          onChangeText={handleChange('scheduled_publish')}
                          autoCapitalize="none"
                          keyboardType="default"
                          onFocus={() => {
                            setDatePicker(true);
                            scheduledPublishTimeInput.current.blur()
                          }}
                          style={{ backgroundColor: 'transparent', paddingHorizontal: 0, flex: 1 }}
                          right={
                            <TextInput.Icon name='calendar' color='#8B77E8' />
                          }
                        />
                        <DatePickerModal
                          locale="en"
                          mode="single"
                          visible={isOpenDatePicker}
                          onDismiss={() => {
                            setScheduleDate('')
                            setDatePicker(false);
                          }}
                          date={values.scheduled_publish ? new Date(values.scheduled_publish) : new Date()}
                          onConfirm={(d) => {
                            const dateStr = moment(d.date).format('MM/DD/YYYY')
                            setScheduleDate(dateStr)
                            // setDatePicker(false);
                            //  setTimePickerModal(true);
                          }}
                          saveLabel={'Pick Time'}
                          label="Schedule publish"
                          uppercase={false}
                        />
                        <TimePickerModal
                          locale="en"
                          visible={isOpenTimePickerModal}
                          onDismiss={() => {
                            setTimePickerModal(false);
                            setDatePicker(true);
                          }}
                          date={values.scheduled_publish ? new Date(values.scheduled_publish) : new Date()}
                          onConfirm={(t) => {
                            const tempdate = scheduleDate + ' ' + t.hours + ':' + t.minutes;
                            const schedulePublish = moment(tempdate).format('MM/DD/YYYY hh:mm a');
                            setValues({ ...values, scheduled_publish: schedulePublish })
                            setTimePickerModal(false);
                          }}
                          saveLabel={'Confirm'}
                          label="Schedule publish"
                          uppercase={false}
                        />
                      </View>
                      {/* Schedule end */}

                    </View>

                    <View style={{ marginVertical: 30 }}>
                      <Actions>
                        <CreateButton
                          onPress={handleSubmit}
                          color={
                            color_scheme_primary_button_background
                              ? color_scheme_primary_button_background
                              : 'black'
                          }
                          disabled={!isValid}
                        >
                          {loading && (
                            <ActivityIndicator
                              size={20}
                              animating
                              color={theme.basics.background_white}
                              style={{ marginRight: 10 }}
                            />
                          )}
                          <TextButton color={color_scheme_primary_button_text}>
                            {isCreate ? 'Create' : 'Update'} Course
                          </TextButton>
                        </CreateButton>
                      </Actions>
                    </View>
                  </>
                )
              }}
            </Formik>
          </ContentBlockV1>
        </View>
      </PageContent>
    </>
  )
}

export default CreateCourse
