import React from 'react'
import {
    Text,
    TouchableOpacity,
    ActivityIndicator
} from 'react-native'

import styles from './styles.js';

const ButtonDefault = ({
    label,
    isLoading,
    disabled,
    onPress
}) => {

    return (
        <TouchableOpacity
            style={styles.Button}
            onPress={onPress}
            disabled={isLoading || disabled}
        >
            {
                isLoading
                    ?
                    (
                        <ActivityIndicator
                            size={20}
                            color="#FFFFFF"
                        />
                    )
                    :
                    <Text style={styles.Label}>
                        {label}
                    </Text>
            }
        </TouchableOpacity>
    )

}

export default ButtonDefault