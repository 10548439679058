import React, { useContext, useEffect, useLayoutEffect, useRef, useState, useReducer } from "react";
import { Dimensions, Text, View, Image } from "react-native";
import Participant from "../Participant";
import { RoomView } from './styles'
import Wrapper from './Wrapper';
import { useSelector } from 'react-redux'
import { getMember } from '@/redux/selectors/auth'
import ApiHandler from "@/api/ApiHandler";
import { ParticipantView } from "../Participant/styles";
import VideoContext from "../context";

const { width, height } = Dimensions.get('window');

const Room = ({ setIsRecording, setUnreadMessages, setFloatingMessage, selectedAudioDeviceId, roomName, room, handleLogout, messages, setMessages }) => {
    const [participants, setParticipants] = useState([]);
    const [layoutSize, setLayoutSize] = useState(width / 2.25);
    const [extraUsers, setExtraUsers] = useState([]);
    const [count, setCount] = useState(0);
    const user = useSelector(getMember);
    const { addParticipant, getParticipant, presentationId } = useContext(VideoContext);
    const [activeSpeaker, setActiveSpeaker] = useState();
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);

    useLayoutEffect(() => {
        const size = getLayoutSize();
        setLayoutSize(size);
    }, [count, participants])



    const getLayoutSize = () => {
        let multiplier = width <= 800 ? 1.3 : width > 800 && width <= 2000 ? 1 : 0.8;

        // tiles sizing
        // 4 per row --> width / 4.7  multiplier_required=true max_users=12
        // 3 per row --> width / 4.3  multiplier_required=true max_users=9  
        // 3 per row --> width / 3.4  multiplier_required=true max_users=6
        // 2 per row --> width / 2.75  multiplier_required=false max_users = 4
        // 2 per row --> width / 2.25  multiplier_required=true max_users = 2

        if (count < 2) {
            return (width / 2.25) * multiplier;
        }

        if (count >= 2 && count <= 4) {
            return width / 2.75;
        }

        if (count > 4 && count <= 6) {
            return (width / 3.4) * multiplier;
        }

        if (count > 6 && count <= 9) {
            return (width / 4.3) * multiplier;
        }

        if (count > 9) {
            return (width / 4.7) * multiplier;
        }

    }

    const getUserInfo = async (participant) => {
        const { identity } = participant;

        console.log("getting info for participant " + identity);

        const _user = getParticipant(identity);

        if (!_user) {
            const res = await new ApiHandler().getParticipant({ id: identity });
            const result = res?.data?.data ?? {};
            addParticipant(identity, result);

            // force re-render the UI so that we can have the information of the new participant
            setTimeout(() => {
                forceUpdate();
            }, 800)
        }

    }

    useEffect(() => {
        // check if extra users contains the active speaker
        const found = extraUsers.find(elem => elem.identity == activeSpeaker?.identity);

        if (found) {
            let local = participants;
            let localExtra = extraUsers;
            // swap the activeSpeaker with participant's last element
            const lastElem = local.pop();
            local.push(activeSpeaker);
            localExtra.push(lastElem);
            setParticipants(local);
            setExtraUsers(localExtra);
        }
    }, [activeSpeaker])

    useEffect(() => {
        const participantConnected = async (participant) => {
            const message = {
                sender: participant,
                message: "have joined the room"
            }
            setFloatingMessage(message);
            // setParticipants((prevParticipants) => [...prevParticipants, participant]);

            getUserInfo(participant).then(() => {

                // max length of participants array is 10

                if (participants.length < 10) {
                    setParticipants((prevParticipants) => [...prevParticipants, participant]);
                } else {
                    setExtraUsers(prevUsers => [...prevUsers, participant])
                }
            })
        };

        const participantDisconnected = (participant) => {
            const message = {
                sender: participant,
                message: "have left the room"
            }
            setFloatingMessage(message);
            setParticipants((prevParticipants) =>
                prevParticipants.filter((p) => p !== participant)
            );

            // if the disconnected participant is the active speaker, set it to null
            setActiveSpeaker(prevParticipant => {
                return prevParticipant == participant ? null : prevParticipant
            })

            setExtraUsers(prev => prev.filter((p) => p !== participant));
        };

        const handleActiveSpeaker = (participant) => {
            if (participant) {
                setActiveSpeaker(participant);
            } else {
                setActiveSpeaker(null);
            }
        }

        room?.on("participantConnected", participantConnected);
        room?.on("participantDisconnected", participantDisconnected);
        room?.on("dominantSpeakerChanged", handleActiveSpeaker);
        room.participants.forEach(participantConnected);
        return () => {
            //room?.off("participantConnected", participantConnected);
            //room?.off("participantDisconnected", participantDisconnected);
            //room?.off("dominantSpeakerChanged", handleActiveSpeaker);
        };
    }, [room]);

    const remoteParticipants = participants.filter(part => part.identity !== presentationId).map((participant) => (
        <Participant
            setIsRecording={setIsRecording}
            isActiveSpeaker={participant?.sid == activeSpeaker?.sid}
            selectedAudioOutputDeviceId={selectedAudioDeviceId}
            setUnreadMessages={setUnreadMessages}
            setFloatingMessage={setFloatingMessage}
            messages={messages}
            setMessages={setMessages}
            key={participant.sid}
            participant={participant}
            size={presentationId ? participants.length >= 3 ? width / 8 : width / 5 : layoutSize} />
    ));

    const findPresenterInParticipants = () => {

        let participant = null;
        if (presentationId == user.id) {
            participant = room.localParticipant;
        } else participant = participants.find(part => part.identity == presentationId)

        return participant;
    }

    return (
        <RoomView>
            {presentationId ? (
                <View style={{ flexDirection: 'row', height: height, justifyContent: 'space-between', paddingHorizontal: '3%' }}>
                    <View style={{ width: '80%', justifyContent: 'center', height: "100%", paddingRight: "3%" }}>
                        <Participant
                            isScreenshare={true}
                            setIsRecording={setIsRecording}
                            isActiveSpeaker={false}
                            selectedAudioOutputDeviceId={selectedAudioDeviceId}
                            setUnreadMessages={setUnreadMessages}
                            setFloatingMessage={setFloatingMessage}
                            messages={messages}
                            setMessages={setMessages}
                            key={presentationId}
                            participant={findPresenterInParticipants()}
                            size={layoutSize} />
                    </View>
                    <View style={{ width: '20%', height: "100%", paddingVertical: '5%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                        {presentationId !== room.localParticipant.identity && (
                            <Participant
                                key={"presentation_cam"}
                                isScreenshare={true}
                                participant={findPresenterInParticipants()}
                                setUnreadMessages={setUnreadMessages}
                                messages={messages}
                                userIsPresenter
                                selectedAudioOutputDeviceId={selectedAudioDeviceId}
                                setMessages={setMessages}
                                isActiveSpeaker={false}
                                setFloatingMessage={setFloatingMessage}
                                size={remoteParticipants.length < 3 ? width / 5 : width / 8}
                                setIsRecording={setIsRecording}
                            />
                        )}
                        <Participant
                            key={room.localParticipant.sid}
                            isScreenshare={false}
                            participant={room.localParticipant}
                            setUnreadMessages={setUnreadMessages}
                            messages={messages}
                            userIsPresenter
                            selectedAudioOutputDeviceId={selectedAudioDeviceId}
                            setMessages={setMessages}
                            isActiveSpeaker={false}
                            setFloatingMessage={setFloatingMessage}
                            size={remoteParticipants.length < 3 ? width / 5 : width / 8}
                            setIsRecording={setIsRecording}
                        />
                        {remoteParticipants}
                        {extraUsers.length && (
                            <>
                                {extraUsers.length == 1 ? (
                                    <Participant setIsRecording={setIsRecording} isScreenshare={false} isActiveSpeaker={extraUsers[0]?.sid == activeSpeaker?.sid} selectedAudioOutputDeviceId={selectedAudioDeviceId} setUnreadMessages={setUnreadMessages} setFloatingMessage={setFloatingMessage} messages={messages} setMessages={setMessages} key={extraUsers[0]?.sid} participant={extraUsers[0]} size={layoutSize} />
                                ) : (
                                    <ParticipantView style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size={layoutSize}>
                                        {extraUsers.length <= 3 ? (
                                            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                                {extraUsers.map((_user, index) => (
                                                    <Image source={{ uri: getParticipant(_user.identity)?.avatar_url }} style={{ marginRight: index !== 0 ? -10 : 0, width: width / 32, height: width / 32, borderRadius: 50 }} />
                                                ))}
                                            </View>
                                        ) : (
                                            <Text style={{ color: "white", fontSize: 24, fontWeight: '600' }}>+{extraUsers.length}</Text>
                                        )}
                                    </ParticipantView>
                                )}
                            </>
                        )}
                    </View>
                </View>
            ) : (
                <Wrapper setCount={setCount}>
                    <Participant
                        key={room.localParticipant.sid}
                        isScreenshare={false}
                        participant={room.localParticipant}
                        setUnreadMessages={setUnreadMessages}
                        setIsRecording={setIsRecording}
                        messages={messages}
                        selectedAudioOutputDeviceId={selectedAudioDeviceId}
                        setMessages={setMessages}
                        isActiveSpeaker={false}
                        setFloatingMessage={setFloatingMessage}
                        size={layoutSize}
                    />
                    {remoteParticipants}
                    {extraUsers.length && (
                        <>
                            {extraUsers.length == 1 ? (
                                <Participant isScreenshare={false} setIsRecording={setIsRecording} isActiveSpeaker={extraUsers[0]?.sid == activeSpeaker?.sid} selectedAudioOutputDeviceId={selectedAudioDeviceId} setUnreadMessages={setUnreadMessages} setFloatingMessage={setFloatingMessage} messages={messages} setMessages={setMessages} key={extraUsers[0]?.sid} participant={extraUsers[0]} size={layoutSize} />
                            ) : (
                                <ParticipantView style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} size={layoutSize}>
                                    {extraUsers.length <= 3 ? (
                                        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            {extraUsers.map((_user, index) => (
                                                <Image source={{ uri: getParticipant(_user.identity)?.avatar_url }} style={{ marginRight: index !== 0 ? -10 : 0, width: width / 32, height: width / 32, borderRadius: 50 }} />
                                            ))}
                                        </View>
                                    ) : (
                                        <Text style={{ color: "white", fontSize: 24, fontWeight: '600' }}>+{extraUsers.length}</Text>
                                    )}
                                </ParticipantView>
                            )}
                        </>
                    )}
                </Wrapper>
            )}
        </RoomView >
    )
};

export default Room;