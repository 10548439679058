import React, { useState, useEffect } from "react";
import {
    StyleSheet,
    Text,
    View,
    Image,
    TouchableOpacity,
    Dimensions,
    Platform,
    FlatList,
    TextInput,
} from "react-native";

import ApiHandler from "@/api/ApiHandler";

import PlaceholderImageCover from "@/assets/cover_placeholder.png";
import ModalV2 from '@/components/ModalV2'
import ContentBlockV1 from '@/components/ContentBlockV1'
import DefaultButton from '@/components/Buttons/Default'
import CreateMilestoneModal from "../CreateMilestoneModal";

import styles from './styles.js';

export const Milestone = ({
    type,
    id,
    name,
    description,
    criteria
}) => {
    const [isViewModalVisible, setViewModalVisible] = useState(false);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);

    const [milestone, setMilestone] = useState([]);
    const [completeEmployees, setCompleteEmployees] = useState([]);
    const [pendingEmployees, setPendingEmployees] = useState([]);
    const [yetToStart, setYetToStart] = useState([]);

    const toggleViewModal = () => {
        setViewModalVisible(!isViewModalVisible);
    };

    const toggleEditModal = () => {
        setEditModalVisible(!isEditModalVisible);
    };

    const toggleDeleteModal = () => {
        setDeleteModalVisible(!isDeleteModalVisible);
    };

    const getMemberMilestoneById = async () => {
        try {
            const response = await new ApiHandler().getMemberMilestoneById(id);
            if (response?.data) {
                setMilestone(response?.data?.data);
                setCompleteEmployees(response?.data?.data?.completed_employees);
                setPendingEmployees(response?.data?.data?.pending_employees);
                setYetToStart(response?.data?.data?.yet_to_start_employees);
            }
        } catch (error) {
            console.log("ERROR", error);
        } finally {
        }
    };

    const getManagementMilestoneById = async () => {
        try {
            const response = await new ApiHandler().getMilestoneById(id);

            if (response?.data) {
                setMilestone(response?.data?.data);
                setCompleteEmployees(response?.data?.data?.completed_employees);
                setPendingEmployees(response?.data?.data?.pending_employees);
                setYetToStart(response?.data?.data?.yet_to_start_employees);
            }
        } catch (error) {
            console.log("ERROR", error);
        } finally {
        }
    };

    useEffect(() => {
        if (type == 'member') {
            getMemberMilestoneById();
        }

        if (type == 'management') {
            getManagementMilestoneById()
        }
    }, []);

    let actions = null
    if (type == 'management') {
        actions = [
            {
                title: 'Manage',
                type: 'menu',
                permissions: [

                ],
                menu: [
                    {
                        title: 'Edit',
                        icon: false,
                        permissions: [

                        ],
                        onPress: () => {
                            toggleEditModal()
                        }
                    },
                    {
                        title: 'Delete',
                        icon: false,
                        permissions: [

                        ],
                        onPress: () => {
                            toggleDeleteModal()
                        }
                    }
                ]
            }
        ]
    }

    const ViewModal = () => {
        return (
            <>
                <ModalV2
                    isVisible={isViewModalVisible}
                    setVisibility={setViewModalVisible}
                    title={milestone?.name}
                    actions={actions}
                >


                    {milestone?.description &&
                        <ContentBlockV1
                            title={'More About This Milestone'}
                        >
                            <Text style={styles.MilestoneModal_DescriptionText}>
                                {milestone &&
                                    milestone?.description}
                            </Text>
                        </ContentBlockV1>
                    }

                    {type == 'member' && (
                        <ContentBlockV1
                            title={'Your Progress'}
                            subtitle={"Track your progress towards completing this milestone."}
                        >
                            <View>
                                <View style={styles.MilestoneModal_ProgressPointsContainer}>
                                    <View>
                                        <Text style={styles.MilestoneModal_ProgressPointsText}>
                                            {[milestone ? milestone?.member_engagement_points : 0, '/', milestone?.engagement_points ? milestone?.engagement_points : "-"]}
                                        </Text>
                                        <Text style={styles.MilestoneModal_ProgressPointsLabel}>{"Engagement Points"}</Text>

                                        <View style={styles.MilestoneModal_ProgressBarContainer}>
                                            <View
                                                style={styles.MilestoneModal_ProgressBarHighlightContainer}
                                            >
                                                <View
                                                    style={[
                                                        styles.MilestoneModal_ProgressBarHighlight,
                                                        { flex: milestone?.member_engagement_percent_completed ? milestone?.member_engagement_percent_completed / 100 : 0 },
                                                    ]}
                                                />
                                            </View>
                                            <View style={styles.MilestoneModal_ProgressBarCountContainer}>
                                                <Text style={styles.MilestoneModal_ProgressBarCount}>
                                                    {[milestone?.member_engagement_percent_completed ? milestone?.member_engagement_percent_completed : '-', "%"]}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>

                                    <View>
                                        <Text style={styles.MilestoneModal_ProgressPointsText}>
                                            {[milestone ? milestone?.member_cultural_maturity_points : 0, '/', milestone?.cultural_maturity_points ? milestone?.cultural_maturity_points : "-"]}
                                        </Text>
                                        <Text style={styles.MilestoneModal_ProgressPointsLabel}>{"Cultural Maturity Points"}</Text>

                                        <View style={styles.MilestoneModal_ProgressBarContainer}>
                                            <View
                                                style={styles.MilestoneModal_ProgressBarHighlightContainer}
                                            >
                                                <View
                                                    style={[
                                                        styles.MilestoneModal_ProgressBarHighlight,
                                                        { flex: milestone?.member_cultural_maturity_percent_completed ? milestone?.member_cultural_maturity_percent_completed / 100 : 0 },
                                                    ]}
                                                />
                                            </View>
                                            <View style={styles.MilestoneModal_ProgressBarCountContainer}>
                                                <Text style={styles.MilestoneModal_ProgressBarCount}>
                                                    {[milestone?.member_cultural_maturity_percent_completed ? milestone?.member_cultural_maturity_percent_completed : '-', "%"]}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>

                                    <View>
                                        <Text style={styles.MilestoneModal_ProgressPointsText}>
                                            {[milestone ? milestone?.member_total_points : 0, '/', milestone?.total_points ? milestone?.total_points : "-"]}
                                        </Text>
                                        <Text style={styles.MilestoneModal_ProgressPointsLabel}>{"Total Points"}</Text>

                                        <View style={styles.MilestoneModal_ProgressBarContainer}>
                                            <View
                                                style={styles.MilestoneModal_ProgressBarHighlightContainer}
                                            >
                                                <View
                                                    style={[
                                                        styles.MilestoneModal_ProgressBarHighlight,
                                                        { flex: milestone?.member_total_percent_completed ? milestone?.member_total_percent_completed / 100 : 0 },
                                                    ]}
                                                />
                                            </View>
                                            <View style={styles.MilestoneModal_ProgressBarCountContainer}>
                                                <Text style={styles.MilestoneModal_ProgressBarCount}>
                                                    {[milestone?.member_total_percent_completed ? milestone?.member_total_percent_completed : '-', "%"]}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </ContentBlockV1>
                    )}

                    {type == 'management' && (
                        <ContentBlockV1
                            title={'Analytics'}
                            subtitle={"Keep track of how your members are tracking towards completing this milestone."}
                        >
                            <View style={styles.MilestoneModal_AnalyticsContainer}>
                                <View style={styles.MilestoneModal_AnalyticsItemContainer}>
                                    <View style={styles.MilestoneModal_AnalyticsItemMembersContainer}>
                                        <Image
                                            source={{ uri: completeEmployees[0]?.avatar }}
                                            style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                        />
                                        <Image
                                            source={{ uri: completeEmployees[1]?.avatar }}
                                            style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                        />
                                        <View>
                                            <Image
                                                source={{ uri: completeEmployees[2]?.avatar }}
                                                style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                            />
                                            <Text style={styles.MilestoneModal_AnalyticsItemMembersCount}>
                                                {"+"}
                                                {
                                                    completeEmployees &&
                                                    completeEmployees?.completed?.length
                                                }
                                            </Text>
                                        </View>
                                    </View>
                                    <Text style={styles.MilestoneModal_AnalyticsItemLabel}>{"Completed"}</Text>
                                </View>

                                <View style={styles.MilestoneModal_AnalyticsItemContainer}>
                                    <View style={styles.MilestoneModal_AnalyticsItemMembersContainer}>
                                        <Image
                                            source={{
                                                uri: pendingEmployees[0]?.avatar,
                                            }}
                                            style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                        />
                                        <Image
                                            source={{
                                                uri: pendingEmployees[1]?.avatar,
                                            }}
                                            style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                        />
                                        <View>
                                            <Image
                                                source={{
                                                    uri: pendingEmployees[2]?.avatar,
                                                }}
                                                style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                            />
                                            <Text style={styles.MilestoneModal_AnalyticsItemMembersCount}>
                                                {"+"}
                                                {milestone &&
                                                    milestone?.pending_employees?.length}
                                            </Text>
                                        </View>
                                    </View>
                                    <Text style={styles.MilestoneModal_AnalyticsItemLabel}>{"Pending"}</Text>
                                </View>

                                <View style={styles.MilestoneModal_AnalyticsItemContainer}>
                                    <View style={styles.MilestoneModal_AnalyticsItemMembersContainer}>
                                        <Image
                                            source={{
                                                uri: yetToStart[0]?.avatar,
                                            }}
                                            style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                        />
                                        <Image
                                            source={{
                                                uri: yetToStart[1]?.avatar,
                                            }}
                                            style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                        />
                                        <View>
                                            <Image
                                                source={{
                                                    uri: yetToStart[2]?.avatar,
                                                }}
                                                style={styles.MilestoneModal_AnalyticsItemMemberPhoto}
                                            />
                                            <Text style={styles.MilestoneModal_AnalyticsItemMembersCount}>
                                                {"+"}
                                                {milestone &&
                                                    milestone?.yet_to_start_employees?.length}
                                            </Text>
                                        </View>
                                    </View>
                                    <Text style={styles.MilestoneModal_AnalyticsItemLabel}>{"Yet To Start"}</Text>
                                </View>
                            </View>
                        </ContentBlockV1>
                    )}

                    {type == 'management' && (
                        <ContentBlockV1
                            title={'Criteria'}
                            subtitle={"The specified criterias below must be met by a member to consider the milestone completed by that member."}
                        >
                            <View style={styles.MilestoneModal_CriteriaContainer}>
                                <View style={styles.MilestoneModal_CriteriaItemContainer}>
                                    <Text style={styles.MilestoneModal_CriteriaItemPoints}>
                                        {milestone && milestone?.engagement_points ? milestone?.engagement_points : '-'}
                                    </Text>
                                    <Text style={styles.MilestoneModal_CriteriaItemLabel}>{"Engagement Points"}</Text>
                                </View>

                                <View style={styles.MilestoneModal_CriteriaItemContainer}>
                                    <Text style={styles.MilestoneModal_CriteriaItemPoints}>
                                        {milestone && milestone?.cultural_maturity_points ? milestone?.cultural_maturity_points : '-'}
                                    </Text>
                                    <Text style={styles.MilestoneModal_CriteriaItemLabel}>{"Cultural Maturity Points"}</Text>
                                </View>

                                <View style={styles.MilestoneModal_CriteriaItemContainer}>
                                    <Text style={styles.MilestoneModal_CriteriaItemPoints}>
                                        {milestone && milestone?.total_points ? milestone?.total_points : '-'}
                                    </Text>
                                    <Text style={styles.MilestoneModal_CriteriaItemLabel}>{"Total Points"}</Text>
                                </View>
                            </View>
                        </ContentBlockV1>
                    )}

                    <ContentBlockV1
                        title={'Milestone Reward'}
                        subtitle={type == 'member' ? "You will receive the reward below upon completing this milestone." : type == 'management' ? "The reward below will be automatically rewarded to a member upon their completion of the milestone." : null}
                    >
                        <View>
                            <Image
                                source={milestone && milestone?.reward?.image ? { uri: milestone?.reward?.image } : PlaceholderImageCover}
                                style={styles.MilestoneModal_RewardImage}
                            />
                            <Text style={styles.MilestoneModal_RewardTitle}>{milestone?.reward?.title ? milestone?.reward?.title : 'No Reward Associated'}</Text>
                        </View>
                    </ContentBlockV1>

                    {type == 'management' && (
                        <>
                            <DeleteModal />

                            <CreateMilestoneModal
                                selectedMilestoneId={milestone && milestone?.id}
                                isMilestoneModalVisible={isEditModalVisible}
                                toggleRightModal={toggleEditModal}
                                update={true}
                            />
                        </>
                    )}
                </ModalV2>
            </>
        )
    };

    const DeleteModal = () => {
        return (
            <View style={{ flex: 1 }}>
                <ModalV2
                    isVisible={isDeleteModalVisible}
                    setVisibility={toggleDeleteModal}
                    title={'Delete Milestone'}
                >
                    <Text style={styles.deleteModalDescription}>
                        Do you want to delete the milestone?
                    </Text>
                    <View style={styles.deleteModalButtons}>
                        <DefaultButton
                            label={'Delete'}
                            onPress={() => {
                                deleteMilestone();
                                toggleDeleteModal();
                                toggleViewModal();
                            }}
                        />
                    </View>
                </ModalV2>
            </View>
        );
    };

    return (
        <View style={styles.Milestone_MilestoneContainer}>
            <TouchableOpacity
                onPress={toggleViewModal}
                style={styles.Milestone_MilestoneCard}
            >
                <View style={styles.Milestone_MilestoneCardContentContainer}>
                    <View
                        style={styles.Milestone_MilestoneCardContentContainerHeaderContainer}
                    >
                        <View
                            style={styles.Milestone_MilestoneCardContentContainerHeaderContainerTitleContainer}
                        >
                            <Text style={styles.Milestone_MilestoneCardContentContainerHeaderContainerTitleContainerTitle}>{name}</Text>
                        </View>
                        <View style={styles.Milestone_MilestoneCardContentContainerHeaderContainerCriteriaContainer}>
                            <TouchableOpacity
                                style={styles.Milestone_MilestoneCardContentContainerHeaderContainerCriteriaContainerBadge}
                            >
                                <Text
                                    numberOfLines={1}
                                    ellipsizeMode="tail"
                                    style={styles.Milestone_MilestoneCardContentContainerHeaderContainerCriteriaContainerBadgeText}
                                >
                                    {criteria}
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View
                        style={styles.Milestone_MilestoneCardContentContainerDescriptionContainer}
                    >
                        <Text
                            style={styles.Milestone_MilestoneCardContentContainerDescriptionContainerText}
                            adjustsFontSizeToFit={false}
                            numberOfLines={3}
                            ellipsizeMode={'tail'}
                        >
                            {description}
                        </Text>
                    </View>
                </View>

                {type == 'member' && (
                    <View style={styles.Milestone_MilestoneCardProgressContainer}>
                        <View
                            style={styles.Milestone_MilestoneCardProgressBarContainer}
                        >
                            <View
                                style={[
                                    styles.Milestone_MilestoneCardProgressBar,
                                    { flex: milestone?.member_overall_percent_completed ? milestone?.member_overall_percent_completed / 100 : 0 },
                                ]}
                            />
                        </View>
                        <View style={styles.Milestone_MilestoneCardProgressPercentageContainer}>
                            <Text style={styles.Milestone_MilestoneCardProgressPercentageText}>
                                {[milestone?.member_overall_percent_completed ? milestone?.member_overall_percent_completed : '-', "%"]}
                            </Text>
                        </View>
                    </View>
                )}
            </TouchableOpacity>

            <ViewModal />
        </View>
    );
};

export default Milestone;