/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import HTML from 'react-native-render-html'
import { TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesome, MaterialCommunityIcons } from '@expo/vector-icons'
import { formatDistance } from 'date-fns'
import Avatar from '../../../Avatar'
import FeedCardAuthorInfo from '../../FeedCardAuthorInfo'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import { isMobile } from '@/styles'
import {
  Container,
  Image,
  Title,
  Header,
  ContentCard,
  ContentCard1,
  Excerpt,
  AuthorContainer,
  AuthorInfoContainer,
  ButtonNavigation,
  TimeAgo,
  SaveButton,
  LikeLove,
  InteractionsContainer,
  ButtonLinkGray,
  Like,
  LikeCommentsContainer,
  ActionButton,
  ActionButtonContainer,
  ActionButtonText,
  SectionAction,
} from './styles'
import {
  CommentList,
  Icon,
  CreatePost
} from '@/components'
import { PostInterface } from '@/interfaces/post'
import { actions } from '@/redux/reducers/posts'
import { getMember } from '@/redux/selectors/auth'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import theme from '@/styles/themes/black'
import { actions as articleActions } from '@/redux/reducers/article'
import { formatFeedCardDate } from '@/utils/format'

const ArticleComponent = ({
  post,
  idPresent,
  postType,
  onClosePostComment,
  handleDeleteComment,
  handleReleateLaterArticle,
}) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const user = useSelector(getMember)

  const setReadLater = () => {
    dispatch(articleActions.setReadLater(post, () => {
      handleReleateLaterArticle()
    }))
  }

  const onPress = () => {
    // navigation.navigate('ViewArticle', { id: post?.id })
    navigation.navigate('ViewArticle', { id: post?.id })
  }

  const [isCommentsVisible, setCommentsVisible] = useState(false)

  const commentsCount = post?.comments?.length
  const likesCount = post?.likes?.length

  const handleComments = useCallback(() => {
    if (isMobile) {
      navigation.navigate('CommentPost', {
        post_id: post.id,
        contentType: 'App\\Article',
      })
      return
    }
    setCommentsVisible(!isCommentsVisible)
  })

  const handleLike = useCallback(() => {
    dispatch(actions.like_article({
      id: post.id,
      user,
      postType: 'App\\Article'
    }, () => { }))
  }, [dispatch, post])

  const [aspectRatio, setAspectRatio] = useState();
  Image.getSize(post?.image, (width, height) => {
    let aspectRatio = width / height;
    setAspectRatio(aspectRatio);
  });

  return (
    <>
      <Container onPress={onPress}>
        <SaveButton onPress={setReadLater}>
          <FontAwesome name={post?.read_later ? 'bookmark' : 'bookmark-o'} size={19} color={post?.read_later ? '#fff' : '#fff'} />
        </SaveButton>
        {post?.image ?
          <Image style={{
            height: undefined,
            aspectRatio: aspectRatio || 1,
            width: "100%",
            resizeMode: "cover",
          }} source={{ uri: post?.image }} />
          :
          <Image resizeMode="cover" source={PlaceholderImageCover} />
        }
        <ContentCard>
          <Title>{post?.five_s_category?.name}</Title>
          <Header>{post?.name}</Header>
          <TimeAgo>{formatFeedCardDate(post?.created_at)}</TimeAgo>
          <Excerpt>{post?.excerpt}</Excerpt>
          <AuthorContainer>
            <ButtonNavigation
              onPress={() => navigation.navigate('Profile', { id: post?.creator?.id })}
            >
              <Avatar size={45} source={post?.creator?.avatar ? { uri: post?.creator?.avatar } : null} />
            </ButtonNavigation>
            <AuthorInfoContainer>
              <FeedCardAuthorInfo author={post?.creator} />
            </AuthorInfoContainer>
          </AuthorContainer>
        </ContentCard>
        <InteractionsContainer>
          <LikeLove>
            <Like>{likesCount}</Like>
            <MaterialCommunityIcons
              size={13}
              name="thumb-up-outline"
              color={theme.support.standard_high}
            />
          </LikeLove>
          <TouchableOpacity onPress={handleComments}>
            <ButtonLinkGray>{commentsCount} comments</ButtonLinkGray>
          </TouchableOpacity>
        </InteractionsContainer>
        <LikeCommentsContainer
          commentsCount={commentsCount}
          isCommentsVisible={isCommentsVisible}
        >
          <SectionAction>
            <ActionButton onPress={handleLike}>
              <ActionButtonContainer>
                <Icon
                  icon="like"
                  height={22}
                  width={19}
                  color={post?.liked ? theme.info.standard : theme.basics.brand}
                />
                <ActionButtonText like={post?.liked}>Like</ActionButtonText>
              </ActionButtonContainer>
            </ActionButton>
          </SectionAction>
          <SectionAction>
            <ActionButton onPress={handleComments}>
              <ActionButtonContainer>
                <Icon icon="comment" height={22} width={19} color={theme.basics.brand} />
                <ActionButtonText>Comments</ActionButtonText>
              </ActionButtonContainer>
            </ActionButton>
          </SectionAction>
        </LikeCommentsContainer>
        <CommentList
          post={post}
          contentType='App\Article'
          onClosePostComment={onClosePostComment}
          isCommentsVisible={isCommentsVisible}
          handleDeleteComment={handleDeleteComment}
        />
      </Container>
    </>
  )
}


ArticleComponent.propTypes = {
  // post
  post: PostInterface,
  onCloseEdit: PropTypes.func,
  onCloseDelete: PropTypes.func,
  onClosePostComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
  handleReleateLaterArticle: PropTypes.func,
  postType: PropTypes.string,
  idPresent: PropTypes.number,
}

export default ArticleComponent