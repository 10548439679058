import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, useWindowDimensions } from 'react-native'

import ApiHandler from '@/api/ApiHandler'
import CategoryView from '@/pages/Analytics/Components/Views/Category'
import { BackArrow } from '@/components'
import { useSideDrawer } from '@/hooks/sideDrawer'

import styles from './styles.js';

const CommunityAnalytics = () => {
    const { open } = useSideDrawer(true)
    const [isOpenDrawer, setDrawer] = useState(false)
    const window = useWindowDimensions();
    useEffect(() => {

    }, [window])

    useEffect(() => {
        setDrawer(open)
    }, [open, setDrawer])

    const items = {
        number_of_members: {
            label: 'Members',
            code: 'number_of_members',
            type: 'chart',
            type_variant: 'line',
            type_datasets: {
                number_of_members: {
                    key: 'number_of_members',
                    label: 'Members'
                }
            },
        },
        number_of_teams: {
            label: 'Teams',
            code: 'number_of_teams',
            type: 'chart',
            type_variant: 'line',
            type_datasets: {
                number_of_teams: {
                    key: 'number_of_teams',
                    label: 'Teams'
                }
            },
        },
        number_of_groups: {
            label: 'Groups',
            code: 'number_of_groups',
            type: 'chart',
            type_variant: 'line',
            type_datasets: {
                number_of_groups: {
                    key: 'number_of_groups',
                    label: 'Groups'
                }
            },
        }
    };

    const filters = {
        scope: false
    }

    return (
        <CategoryView
            title={'Community Analytics'}
            apiHandlerFunction={new ApiHandler().getCommunityAnalysis}
            items={items}
            filters={filters}
        />
    )
}

export default CommunityAnalytics
