import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function AlignLeft(props) {
    return (
        <Svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
            <Path d="M12 12H0V14H12V12ZM12 4H0V6H12V4ZM0 10H18V8H0V10ZM0 18H18V16H0V18ZM0 0V2H18V0H0Z" fill={props.color || '#323232'} />

        </Svg>
    )
}

AlignLeft.propTypes = Svg.propTypes