import React, { useState, useCallback, useEffect } from 'react'
import {
    ScrollView,
    Text,
    KeyboardAvoidingView,
    View,
    TouchableOpacity,
    FlatList,
    StyleSheet,
    Dimensions,
} from "react-native";
import defaultStyle from './defaultStyle'
// Add Actions - replace 'Your' with whatever your reducer is called :)
// import YourActions from '../Redux/YourRedux'
import { getMember } from '@/redux/selectors/auth'
import ApiHandler from '@/api/ApiHandler'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from '@/styles'
import { MentionsInput, Mention } from 'react-mentions'
const { width, height } = Dimensions.get("window");
const screenWidth = width < height ? width : height;
const screenHeight = width < height ? height : width;


const MentionsTextBoxWeb = ({ placeholder, text, setText }) => {

    const [initialValue, setInitialVal] = useState(text)
    const [userList, setUserList] = useState([]);
    const user = useSelector(getMember);

    useEffect(() => {
        getUserListAsync()
    }, [])

    const getUserListAsync = async () => {
        try {
            const response = await new ApiHandler().getEmployees();
            if (response?.data) {
                let responseData = response?.data ? response?.data.filter(x => x.first_name && x.id != user?.id).map((user) => {
                    let userData = { id: user.id, display: user.first_name + ' ' + user.last_name };
                    return userData;
                }) : [];

                setUserList(responseData);
            }

        } catch (error) {

        }
    }

    const onChange = (ev, newValue) => {
        setText(newValue);
        setInitialVal(newValue)
    };

    const onBlur = () => (ev, clickedOnSuggestion) => {
        if (!clickedOnSuggestion) {
            console.log("finished editing");
        }
    };

    const onAdd = () => (...args) => console.log("added a new mention", ...args);



    return (
        <MentionsInput
            value={initialValue}
            onChange={onChange}
            onBlur={onBlur}
            // markup="{{__display__}}"
            markup="##[__id__,'__display__']@@"
            style={defaultStyle}
            // style={defaultStyle, { height: '100%', border: '1px solid #bfbcbc', }}
            // displayTransform={display => `@${display}`}
            displayTransform={(id, display) => `@${display}`}
            allowSuggestionsAboveCursor={false}
            placeholder={placeholder ? placeholder : ''}
        >
            <Mention
                //trigger={/(<<(.*))$/}
                trigger="@"
                // data={search => userList.length && userList.filter(user => user.display.includes(search))}
                data={search => userList.length && userList.filter(user => user.display.toLowerCase().startsWith(search))}
                onAdd={onAdd}
                style={{ backgroundColor: '#D3D3D3', marginLeft: 0, fontSize: 15, fontWeight: "normal", fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', }}
            //style={{ color: '#D3D3D3', overflow: 'auto', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif', }}
            />
        </MentionsInput>

    )



}

export default MentionsTextBoxWeb;