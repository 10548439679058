import styled from 'styled-components/native'
import { Paragraph1 } from '@/styles/fonts'

export const Container = styled.View`
  width: 100%;
  padding: 15px;
  max-width: 300px;
  border-radius: 5px;
  background-color: ${({ theme, type }) =>
    type === 'success' ? theme.success[50] : theme.danger[50]};

  display: flex;
  align-items: center;
  flex-direction: row;
`

export const Message = styled(Paragraph1)`
  font-size: 14px;
  margin-left: 10px;
  color: ${({ theme, type }) =>
    type === 'success' ? theme.success[900] : theme.danger.standard};

  flex-grow: 1;
  flex-shrink: 1;
`
