import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'
import { shadow2 } from '@/styles/shadows'
import { ScreenSize } from '@/styles'
import { Button, TextField as TF } from '@/components'


export const styles = StyleSheet.create({
	scrollContent: {
		width: '100%',
		height: '110%',
		backgroundColor: 'white',
		paddingBottom: '50%',
		paddingTop: '10%',
	},
	keyboardStyle: {
		flex: 1
	},
})

export const TextField = styled(TF)`
margin: 5px 0;
`

export const Container = styled.View`
	height:100%;
	width: 100%;
	padding: 0 20px;
`

export const AvatarWrapper = styled.View`
	align-self: center;
  position: relative;
  width: ${({ screenSize }) => (screenSize <= ScreenSize.Medium ? 90 : 120)}px;

`


export const CircleCamera = styled.View`
	position: absolute;
	bottom: ${({ isMobile }) => isMobile ? -5 : -5}px;
	right: ${({ isMobile }) => isMobile ? -5 : -15}px;
	width: ${({ isMobile }) => isMobile ? 32 : 50}px;
	height: ${({ isMobile }) => isMobile ? 32 : 50}px;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	background-color: #fff;
	${shadow2};
	elevation: 3;
`

export const EditButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  border: 1px solid ${({ cancel, theme }) => cancel ? theme.basics[700] : 'transparent'};
`

export const EditButtonText = styled.Text`
  color: ${({ theme, cancel, color }) => cancel ? theme.basics[700] : color ? color : theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`
export const ConatinerButtons = styled.View`
	justify-content: space-around;
	align-self: center;
	align-items: center;
	padding-bottom:15px;
	top: 25px;
	position: relative;
	flex-direction: ${({ direction }) => direction == 1 ? "row" : "column"};
	width: 100%;

`

export const AvatarButton = styled.TouchableOpacity`
  max-width: ${({ screenSize }) => (screenSize <= ScreenSize.Medium ? 90 : 120)}px;
	align-self: center;
`
