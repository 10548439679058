import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Image(props) {
    return (
        <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M21 19V5C21 3.9 20.1 3 19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19ZM8.5 13.5L11 16.51L14.5 12L19 18H5L8.5 13.5Z" fill={props.color} />
        </Svg>
    )
}

Image.propTypes = SvgProps

export default Image;
