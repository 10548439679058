import React from 'react'
import { Avatar as MaterialAvatar } from 'react-native-paper'
import { View } from 'react-native'
import Image from 'react-native-scalable-image';

// const Avatar = ({ size, elevation, ...props }) => {
const Avatar = ({ size, color, style, source }) => {
  if (source) {
    return (
      <View
        style={[
          style,
          {
            width: size ? size : 45,
            height: size ? size : 45,
            borderRadius: size ? size / 2 : 45 / 2,
            position: 'relative',
            overflow: 'hidden'
          }
        ]}
      >
        <Image
          source={source}
          width={size ? size : 45}
          style={{
          }}
        />
      </View>
    )
  }

  return (
    <MaterialAvatar.Icon
      size={size}
      color={color}
      icon="account"
      style={style}
    />
  )
}

Avatar.propTypes = MaterialAvatar.propTypes

export default Avatar
