import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import PropTypes from 'prop-types'
import {
  Icon,
  Carousel,
  Breadcrumb,
  GroupTeamsCard,
  BackArrow,
} from '@/components'
import theme from '@/styles/themes/black'
import { useScreenSize, isMobile } from '@/styles'
import canAccess from '@/helpers/permissions'
import { useDispatch, useSelector } from 'react-redux'
import * as groupsSelectors from '@/redux/selectors/groups'
import { actions as groupsActions } from '@/redux/reducers/groups'
import { ProgressBar } from 'react-native-paper'
import {
  HeadLine,
  SubTitle,
  Container,
  Header,
  LeftIconButton,
  SectionHeader,
  TitleHeader,
  SectionTitle,
} from '@/pages/Community/styles/innerPages'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import CreateEditGroup from '@/pages/Community/CreateEditGroup'
import { permissions } from '@/helpers/permissionConstants'
import * as brandSelectors from '@/redux/selectors/branding'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const Groups = ({ navigation }) => {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [isCreateEditVisible, setIsCreateEditVisible] = useState(false)

  const groups = useSelector(groupsSelectors.data)
  const otherGroups = useSelector(groupsSelectors.otherGroups)

  const loading = groups.loading || otherGroups.loading

  const branding = useSelector(brandSelectors.getSettings);
  const { group } = branding?.taxonomies;

  useEffect(() => {
    dispatch(groupsActions.index({}, (err) => { console.log('err', err) }))
    dispatch(groupsActions.otherGroups({}, (err) => { console.log('err', err) }))
  }, [])

  const groupsNextPage = () => {
    if (groups.loading || !groups.nextPage) {
      return
    }
    dispatch(groupsActions.index({ page: groups.page + 1 }, () => { }))
  }

  const otherGroupsNextPage = () => {
    if (otherGroups.data || !otherGroups.nextPage) {
      return
    }
    dispatch(groupsActions.otherGroups({ page: otherGroups.page + 1 }, () => { }))
  }

  const breadcrumbs = [
    { name: 'Community', navigation: 'Community' },
    { name: 'Groups' },
  ]

  const expansiveMenuItems = [
    { text: `Create a ${group?.singular}`, onPress: () => { setIsCreateEditVisible(true) } },
  ]

  return (
    <>
      <PageHeader
        title={group?.plural}
        icon={'community'}
        backEnabled={true}
        breadcrumbs={[
          { name: 'Community', navigation: 'Community' },
          { name: 'Groups' },
        ]}
        actions={
          [
            {
              title: 'Create',
              type: 'menu',
              permissions: [
                'CREATE_GROUPS',
              ],
              menu: [
                {
                  title: `Create ${group?.singular}`,
                  icon: 'community',
                  permissions: [
                    'CREATE_GROUPS'
                  ],
                  onPress: () => {
                    setIsCreateEditVisible(true)
                  }
                },
              ]
            }
          ]
        }
      />

      <PageContent>
        <ContentBlockV1
          title={`My ${group?.plural}`}
        >
          <Carousel nextPage={groupsNextPage}>
            {groups?.data?.map((group, key) => <GroupTeamsCard
              key={group?.id ?? key}
              label="Groups"
              type="groups"
              {...group} />)}
          </Carousel>
        </ContentBlockV1>

        {otherGroups?.data?.length > 0 &&
          <ContentBlockV1
            title={`Other ${group?.plural}`}
          >
            <Carousel nextPage={otherGroupsNextPage}>
              {otherGroups?.data?.map((group, key) => <GroupTeamsCard
                key={group?.id ?? key}
                label="Groups"
                type="groups"
                {...group} />)}
            </Carousel>
          </ContentBlockV1>
        }
      </PageContent>

      <CreateEditGroup
        isVisible={isCreateEditVisible}
        onClose={() => { setIsCreateEditVisible(false) }}
        type='group'
      />
    </>
  )
}

Groups.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  })
}

export default Groups
