import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'
import themes from '@/styles/themes/black'
import { Headline6, Subtitle1, Caption1 } from '@/styles/fonts'
import { isMobile } from '@/styles'

export const styles = StyleSheet.create({
  gradiente: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 67,
    zIndex: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  }
})

export const Container = styled.View`
	position: relative;
	width: 100%;
`

export const AuthorContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

export const AvatarContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px;
  position: relative;
`

export const RightContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  position: relative;
`

export const AuthorInfo = styled.View`
  display: flex;
  align-items: flex-start;
  padding: 2px 16px;
  position: relative;
`

export const Title = styled(Headline6)`
	align-items: center;
	color: ${themes.basics[800]};
`

export const AuthorOffice = styled(Caption1)`
	color: ${({ theme }) => theme.basics[400]};
`

export const ButtonWrapper = styled.View`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  background-color: ${({ theme }) => theme.basics.black_text};
  border-radius: 5px;
  margin-right: ${isMobile ? 10 : 20}px;
`

export const ButtonTextChat = styled.Text`
	margin: 0 4px ;
	padding: 0 0 ${isMobile ? 0 : 2}px;
	font-family: 'WorkSans_400Regular';
	font-size: 12px;
	color: ${({ theme }) => theme.basics.background_white};
`

export const MenuText = styled(Subtitle1)`
	color: ${themes.basics['black_text']};
  padding: 5px;
`

export const ExpandButtonWrapper = styled.View`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
`
export const OptionIconWrapper = styled.TouchableOpacity`
`
