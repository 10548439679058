import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { Icon } from "@/components";
import { isMobile } from "@/styles";
import badge from "@/assets/badge.png";
import groupIcon from "@/assets/groupIcon.png";
import { ScreenSize, useScreenSize } from '@/styles'

export const ActiveCard = ({
	subTitleTxt,
	countTxt,
	background,
	index,
}) => {
	const screenSize = useScreenSize()
	return (
		<View
			style={[
				styles.container,
				{
					width: !isMobile && screenSize >= ScreenSize.Medium ? "32%" : "100%",
					backgroundColor: background
				},
			]}
		>
			<View>
				<Text style={styles.countTxt}>{countTxt}</Text>
				<Text style={styles.subTitleTxt}>{subTitleTxt}</Text>
			</View>
			{/*
			<Image
				source={badge}
				style={{ height: !isMobile ? 59 : 35, width: !isMobile ? 59 : 35 }}
			/>
			*/}
		</View>
	);
};
export const ActiveMemberCard = ({
	subTitleTxt,
	countTxt,
	marginEnd,
	index,
	background,
}) => {
	const screenSize = useScreenSize()
	return (
		<View
			style={[
				styles.containerMobCard,
				{
					backgroundColor: background,
					marginEnd: 7,
				},
			]}
		>
			{/*
			{isMobile ? (
				<Icon icon="groupMob" color={"#000"} />
			) : !isMobile && screenSize >= ScreenSize.Medium ? (
				<Image
					source={{ uri: groupIcon }}
					style={{ height: 55, width: 35, marginEnd: 10 }}
				/>
			) : null}
			*/}
			<View>
				<Text style={styles.countTxtMob}>{countTxt}</Text>
				<Text style={styles.subTitleTxtMob}>{subTitleTxt}</Text>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		// backgroundColor: "#E8E8E8",
		height: !isMobile ? 95 : 54,
		// width: !isMobile && screenSize >= ScreenSize.Medium ? "32%" : "100%",
		borderRadius: 4,
		justifyContent: "space-between",
		alignItems: "center",
		flexDirection: "row",
		padding: 15,
		margin: !isMobile ? 5 : 0,
		marginVertical: isMobile && 5,
	},
	containerMobCard: {
		// backgroundColor: "#E8E8E8",
		height: isMobile ? 65 : 95,
		width: isMobile ? "32%" : "32%",
		borderRadius: 4,
		alignItems: "center",
		flexDirection: "row",
		paddingVertical: 10,
		paddingHorizontal: 15,
		marginTop: 15,
	},
	countTxt: {
		fontSize: !isMobile ? 32 : 22,
		lineHeight: !isMobile ? 41.66 : 29,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	countTxtMob: {
		fontSize: isMobile ? 20 : 32,
		lineHeight: isMobile ? 26.04 : 41,
		fontWeight: "500",
		letterSpacing: -0.8,
		textAlign: "left",
		color: "#fff",
	},
	subTitleTxt: {
		fontSize: !isMobile ? 16 : 10,
		lineHeight: !isMobile ? 20 : 13,
		fontWeight: "400",
		color: "#fff",
	},
	subTitleTxtMob: {
		textAlign: "left",
		fontSize: isMobile ? 7 : 12,
		lineHeight: isMobile ? 10 : 15,
		fontWeight: "400",
		color: "#fff",
	},
});
