import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Leave(props) {
  return (
    <Svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M11.25 8.25L6.75 12.75L5.685 11.685L8.3775 9H0V0H1.5V7.5H8.3775L5.685 4.815L6.75 3.75L11.25 8.25Z"  />
    </Svg>
  )
}

Leave.propTypes = SvgProps

export default Leave
