import _ from 'lodash'

export const events = (state) => _.get(state, 'event.events')
export const event = (state) => _.get(state, 'event.event')
export const featured = (state) => _.get(state, 'event.featured')
export const readLater = (state) => _.get(state, 'event.readLater')

export const loading = (state) => _.get(state, 'event.loading')
export const readLaterLoading = (state) =>
  _.get(state, 'event.readLaterLoading')
export const featuredLoading = (state) => _.get(state, 'event.featuredLoading')
export const statusLoading = (state) => _.get(state, 'event.statusLoading')