import _ from 'lodash'

export const posts = (state) => _.get(state, 'posts.posts.data')

export const loading = (state) => _.get(state, 'posts.loading')

export const can_load_more = (state) => _.get(state, 'posts.page') < _.get(state, 'posts.last_page')

export const next_page = (state) => _.get(state, 'posts.page') + 1

export const current_page = (state) => _.get(state, 'posts.page')
