import React from 'react'
import { useNavigation } from '@react-navigation/native'
import {
  Title,
  Subtitle,
  Accessory,
  Container,
  SubtitleContainer,
  ButtonNavigation,
} from './styles'
import { ScreenSize, useScreenSize } from '@/styles'
import { UserInterface } from '@/interfaces/user'

const FeedCardAuthorInfo = ({ author }) => {
  const screenSize = useScreenSize()
  const navigation = useNavigation()

  return (
    <Container screenSize={screenSize}>
      <ButtonNavigation onPress={() => navigation.navigate('Profile', { id: author?.id })}>
        <Title>{author?.first_name} {author?.last_name}</Title>
      </ButtonNavigation>
      {author?.role && (
        <SubtitleContainer screenSize={screenSize}>
          <Subtitle>{author?.role}</Subtitle>
        </SubtitleContainer>
      )}
    </Container>
  )
}

FeedCardAuthorInfo.propTypes = {
  author: UserInterface
}

export default FeedCardAuthorInfo
