import styled from 'styled-components/native'
import Button from '@/components/Button'
import { ScreenSize } from '@/styles'
import { vh } from 'react-native-expo-viewport-units'

export const CreatePasswordPageContent = styled.View`
  height: 100%;
  padding-top: ${({ screenSize }) =>
    screenSize >= ScreenSize.Medium ? `${vh(5)}` : '0'}px;
	width: 100%;
`


export const SubmitButton = styled(Button)`
  margin: 30px 0 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TextButton = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`
