import React, { useState } from "react";
import FloatingModal from "@/components/FloatingModal";
import { View, Text, TouchableOpacity, Platform } from "react-native";
import { TextField } from "@/components";
import UseBrandingAccentColor from "@/hooks/useBrandingAccentColor";
import { ClearButton } from "../styles";
import ButtonPrimary from "@/components/ButtonPrimary";

const NewFolderModal = ({ visible, onDismiss, createFolder, errorTextCreate }) => {
    const [foldername, setFolderName] = useState("");
    const accentColor = UseBrandingAccentColor();

    return (
        <FloatingModal visible={visible} onDismiss={onDismiss}>
            <View style={{ flex: 1, justifyContent: 'center' }}>
                <Text style={{ fontSize: 30, fontWeight: "500" }}>New Folder</Text>
            </View>
            <View style={{ flex: 2, justifyContent: "center" }}>
                <TextField value={foldername} onChangeText={(text) => setFolderName(text)} placeholder='Untitled Folder' />
                <Text style={{ color: "red" }}>{errorTextCreate}</Text>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                {Platform.OS == 'web' && <View style={{ flex: 2 }} />}
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}>
                    {Platform.OS == 'web' ? (
                        <TouchableOpacity style={{ backgroundColor: "white", alignItems: 'center', justifyContent: 'center' }} onPress={onDismiss}>
                            <Text style={{ fontSize: 16, fontWeight: '600' }}>Cancel</Text>
                        </TouchableOpacity>
                    ) : (
                        <ButtonPrimary onPress={onDismiss} customStyles={{ backgroundColor: 'transparent' }} textStyle={{ color: "black" }} label="Cancel" />
                    )}
                    {Platform.OS == "web" ? (
                        <ClearButton onPress={() => createFolder(foldername)}>
                            <Text style={{ color: "white", fontSize: 16, fontWeight: '600' }}>Create</Text>
                        </ClearButton>
                    ) : (
                        <ButtonPrimary customStyles={{ backgroundColor: 'black' }} onPress={() => createFolder(foldername)} label="Create" />
                    )}
                </View>
            </View>
        </FloatingModal>
    )
}

export default NewFolderModal;