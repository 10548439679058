import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, Platform, FlatList, TouchableOpacity, Dimensions, Alert } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '@/redux/reducers/sharedfolders'
import { useNavigation, useRoute, NavigationActions } from '@react-navigation/native'
import { Icon } from '@/components'
import { ProgressBar, Avatar, Card, Badge } from 'react-native-paper'
import ExpansiveMenu from '@/pages/SharedFolders/ExpansiveMenu'
import theme from '@/styles/themes/black'
import { useIsMobile } from '@/styles'
import { Ionicons } from '@expo/vector-icons'

import {
  Container,
  Title,
  Header,
  TitleWrapper,
  ScrollView,
} from '../styles'
import ApiHandler from '@/api/ApiHandler'
import Toast from 'react-native-toast-message'
import {
  MenuProvider,
} from 'react-native-popup-menu';

import SideMenusMobile from '../Menu/SideMenu.mobile'
import SideMenusWeb from '../Menu/SideMenu.web'
import { WebView } from 'react-native-webview';
import { openDocument } from '../UplodFile/document'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor'
import NewFolderModal from '../NewFolderModal'
import { Layouts } from '../Main/Layouts'
import { FlatGrid } from 'react-native-super-grid'
import FilePreviewModal from '../FilePreviewModal'
import FileIcon from '../Icons'
import useLayoutContext from '../Context/useLayout'
const { height, width } = Dimensions.get('window')

const SubFolders = () => {
  const [isCreateModalVisible, setCreateModalVisible] = useState(false)
  const [isRenameModalVisible, setRenameModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [nodeVal, setNode] = useState(null)
  const [deleteNode, setDeleteNode] = useState(null)
  const [isInfoModalVisible, setInfoModalVisible] = useState(false)
  const [getFolderInfo, setFolderInfo] = useState(null)
  const [errorTextCreate, setCreateError] = useState('')
  const { layout, setLayout } = useLayoutContext();
  const [currentItem, setCurrentItem] = useState(null);

  const accentColor = UseBrandingAccentColor();

  // const [tab, setTab] = useState('myfolders')

  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const { goBack } = useNavigation()
  const navigation = useNavigation()
  const route = useRoute()
  const [foldersList, setFoldersList] = useState([])
  const [data, setData] = useState([]);
  const sharedfolders = useSelector((state) => state.sharedfolders);
  const auth = useSelector((state) => state.auth);
  const [loading, setloading] = useState(false)
  const [folder, setFolder] = useState('')

  const [isUploadImageModalVisible, setUploadImageModalVisible] = useState(false)
  const [communityGroup, setCommunityGroup] = useState([]);
  const [communityTeam, setCommunityTeam] = useState([]);
  const [communityEmployee, setCommunityEmployee] = useState([]);

  const [isFileView, setIsFileView] = useState(false);
  const [previewFIle, setPreviewFIle] = useState([]);


  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      if (isMobile) {
        setLayout(route.params.layout);
      }
    })

    return () => {
      unsubscribe();
    }
  }, [navigation])

  // useEffect(() => {

  //   navigation.addListener('focus', () => {
  //     init();
  //      //getSubFolders();
  //   })

  //   navigation.addListener('blur', () => {
  //     init();
  //      //getSubFolders();
  //   })
  //   // navigation.addListener('beforeRemove', () => {
  //   //   init();
  //   // })
  // }, [])

  // useEffect(() => {
  //   // Anything in here is fired on component mount.
  //   return () => {
  //     // Anything in here is fired on component unmount.
  //     window.location.reload(false);
  //   }
  // }, [])

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      const { id } = route.params;
      init();
      getWorkspaceGroupAsync();
      getWorkspaceTeamAsync();
      getWorkspaceEmployeeAsync();
      //Put your Data loading function here instead of my loadData()
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    setFoldersList(sharedfolders.subfolders)
    setData(sharedfolders.subfolders);
  }, [sharedfolders.subfolders])

  const init = () => {
    setFolder('');
    setFoldersList([]);
    dispatch(actions.subfolders([]));
    setTimeout(() => {
      showFolders().then(() => { getSubFolders(); }).catch((err) => console.log(err));

    }, 1000);
  }

  const getSubFolders = async () => {
    try {
      setloading(true);
      const { id, shared } = route.params;
      const response = await new ApiHandler().getSubFolders(id, shared);
      console.log(response)
      if (response?.data) {
        setloading(false);
        if (response?.data.length) {
          let myfiles = response?.data.length ? response?.data.slice().reverse() : [];
          dispatch(actions.subfolders(myfiles));
        }
        else {
          setFoldersList([])
          dispatch(actions.subfolders([]));
        }

      }
    } catch (error) {
      setloading(false);
    }
  }


  const showFolders = async () => {
    try {
      setloading(true);
      const { id } = route.params;
      const response = await new ApiHandler().showFolders(id);
      console.log(response)
      if (response?.data) {
        setloading(false);
        setFolder(response?.data);
      }

    } catch (error) {
      setloading(false);
    }
  }




  const createFolder = async (foldername) => {
    try {
      setCreateError('');
      if (foldername) {
        const { id } = route.params;
        let data = {
          name: foldername,
          node_id: id
        }

        const response = await new ApiHandler().createFolder(data)

        setCreateModalVisible(false)
        init();
      }
      else {
        setCreateError('Please input folder name');
      }

    } catch (error) {
      setCreateError('Internet error');
    }
  }


  const handleRenameFolder = useCallback((val) => {
    setCreateError('');
    setNode(val);
    setRenameModalVisible(true)
  }, [])

  // const renameFolder = async () => {
  //   try {
  //     setCreateError('');
  //     if (foldername && nodeVal) {
  //       let data = {
  //         name: foldername,
  //       }
  //       const response = await new ApiHandler().renameFolder(nodeVal.id, data)
  //       console.log(response)
  //       setRenameModalVisible(false)
  //       getSubFolders();
  //     }
  //     else {
  //       setCreateError('Please input folder name');
  //     }
  //   } catch (error) {

  //   }
  // }


  const deleteFolderConfirm = async (id) => {
    setDeleteNode(id);
    setDeleteModalVisible(true);
  }


  const deleteFolder = async () => {
    try {
      if (deleteNode) {
        const response = await new ApiHandler().deleteFolder(deleteNode)
        setDeleteModalVisible(false);
        getSubFolders();
      }
      else {
        setDeleteModalVisible(false);
      }
    } catch (error) {
      setDeleteModalVisible(false);
    }
  }

  const getFolderInfoAsync = async (id) => {
    try {
      setloading(true);
      const response = await new ApiHandler().showFolders(id);
      console.log(response)
      if (response?.data) {
        setloading(false);
        setFolderInfo(response.data);
        setInfoModalVisible(true);
      }

    } catch (error) {

    }
  }

  /**
   * @param {type : string}
   */
  const handleCreateFolder = useCallback((type) => {
    setCreateError('');
    setCreateModalVisible(true)

  }, [])

  const handleUploadFile = useCallback(async (type) => {
    const document = await openDocument();
    uploadDocument(document);

  }, [])

  const goBackToMain = useCallback(() => {
    const { previus_route_name, id } = route.params;
    navigation.push(previus_route_name, {
      previus_route_name: route?.name || '',
      id: id
    })
  }, [])


  const expansiveChildrens = [
    {
      text: 'Create A Folder',
      onPress: handleCreateFolder,
      icon: 'createfolder',
    },
    {
      text: 'Upload A File',
      onPress: handleUploadFile,
      icon: "uploadfile"
    },
    // {
    //   text: 'Event',
    //   onPress: handleCreateLearning,
    // },
  ]

  // useEffect(() => {
  //   if(previewFIle) setIsFileView(true);
  //   else setIsFileView(false);

  // }, [previewFIle])

  const handlePreviewFile = (item) => {
    setCurrentItem(item);
    // let uri;
    // if (item.type == 'file-image') uri = item.link
    // else uri = 'http://docs.google.com/gview?embedded=true&url=' + item.link;
    setPreviewFIle(item.link);
    setIsFileView(true);
  }


  const handleFolderView = useCallback((item) => {
    if (item.type == 'folder') {
      const { shared } = route.params;
      if (isMobile) {
        navigation.push('Main', {
          screen: 'SubFolders', params: {
            previus_route_name: route?.name || '',
            id: item.id,
            shared: shared,
          }, key: 'subsubfolder' + item.id
        });
      }
      else {
        navigation.push('SubFolders', {
          previus_route_name: route?.name || '',
          //sid:id,
          id: item.id,
          shared: shared,
          // tab:tab,
          //folder:name,
          //type,
        })
      }

    }
    else {
      handlePreviewFile(item);
    }


  }, [])

  const goBackToMainView = useCallback(() => {
    //navigation.dispatch(CommonActions.goBack());
    //goBack();
    navigation.dispatch(NavigationActions.back())
    const { previus_route_name, id, shared } = route.params;
    navigation.navigate(previus_route_name, {
      previus_route_name: route?.name || '',
      id: id,
      shared: shared
    })
  }, [])

  // const handleMoreFolders = useCallback(() => {
  //   navigation.navigate('Articles', {
  //     previus_route_name: route?.name || '',
  //   })
  // }, [])

  const expansiveChildrensMoreBtn = [
    {
      text: 'Move to',
      //onPress: handleCreateFolder,
      icon: 'createfolder',
    },
    {
      text: 'Rename',
      //onPress: handleUploadFile,
      icon: "uploadfile"
    },
    {
      text: 'Download',
      icon: 'createfolder',
      // onPress: handleFolderView,
    },
    {
      text: 'Informations',
      icon: 'createfolder',
      // onPress: handleFolderView,
    },
  ]

  const DATA = [
    {
      id: 'bd7acbea-c1b1-46c2-aed5-3ad53abb28ba',
      title: 'Profile pictures',
    },
    {
      id: '3ac68afc-c605-48d3-a4f8-fbd91aa97f63',
      title: 'Photos gallery',
    },
  ];

  //   const formatDate = (date) => {
  //     var d = new Date(date),
  //         month = '' + (d.getMonth() + 1),
  //         day = '' + d.getDate(),
  //         year = d.getFullYear();

  //     if (month.length < 2) 
  //         month = '0' + month;
  //     if (day.length < 2) 
  //         day = '0' + day;

  //       return [month, day,year].join('/');
  //     // return [year, month, day].join('-');
  // }
  const formatDate = (date) => {
    //  var d = new Date(date),
    var d = new Date(date.replace(' ', 'T')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
    // return [year, month, day].join('-');
  }


  // const renderItem = ({ item }) => (
  //     <Card.Title
  //     title={<TouchableOpacity onPress={(e) => handleFolderView(e)}><Text>{item.name}</Text></TouchableOpacity>}
  //     subtitle={`Last modify: ${formatDate(item.last_modified_at)}`}
  //     left={(props) => <Avatar.Icon color={'#000'} style={{backgroundColor:'#fff'}}  {...props} icon="folder" />}
  //    // right={(props) => <IconButton {...props} icon="more-vert" onPress={() => {}} />}
  //     //right={(props) =><Icon icon="ellipsis" color={theme.basics.brand} height={20} width={20} />}
  //     right={(props) => <ExpansiveMenu
  //       mainIconColor={'#000'}
  //       mainIconBackground={'transparent'}
  //       desktopText=""
  //       expansiveChildrens={expansiveChildrensMoreBtn}

  //       // icon={'sharedfolderleft'}
  //     />}
  //     rightStyle={{marginTop:isMobile?0:-40}}
  //     style={{backgroundColor:'#fff',marginTop:20,marginRight:20,zIndex:11}}
  //   />
  //   );


  const getWorkspaceGroupAsync = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getWorkspaceGroup();
      console.log(response)
      setloading(false);
      if (response?.data) {
        let responseData = response?.data.length ? response?.data.filter(x => x.id != auth.user.id) : [];
        setCommunityGroup(responseData);
      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getWorkspaceTeamAsync = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getWorkspaceTeam();
      console.log(response)
      setloading(false);
      if (response?.data) {
        let responseData = response?.data.length ? response?.data.filter(x => x.id != auth.user.id) : [];
        setCommunityTeam(responseData);
      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getWorkspaceEmployeeAsync = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getWorkspaceEmployee();
      console.log(response)
      setloading(false);
      if (response?.data) {
        let responseData = response?.data.length ? response?.data.filter(x => x.id != auth.user.id) : [];
        setCommunityEmployee(responseData);
      }

    } catch (error) {
      setloading(false);
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const renderItem = ({ item }) => (
    <Card.Title
      title={<TouchableOpacity onPress={() => handleFolderView(item)}>
        <Text style={{ fontSize: 15 }} >
          {item.name.length < 25
            ? `${item.name}`
            : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
        </Text>
      </TouchableOpacity>}
      subtitle={<View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {item.is_shared && <Icon icon="communityIcon" color="black" width={14} />}
        <Text style={{ color: '#666' }}> Last Modified: {formatDate(item.last_modified_at)} </Text>
      </View>}


      left={(props) => <FileIcon type={item.type} shared={item.is_shared} />}

      right={(props) => <SideMenusMobile auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getSubFolders} />}
      rightStyle={{ marginTop: isMobile ? 0 : -40 }}
      style={{ backgroundColor: '#fff', marginTop: 5, marginRight: 20, paddingRight: 10, paddingTop: Platform.OS === 'ios' ? 10 : 0, zIndex: 11 }}
    />
  );

  const renderGridItemWeb = ({ item }) => (
    <View style={{ flexDirection: 'row', position: 'relative', justifyContent: 'center', padding: 10, borderRadius: 5, borderColor: '#cfcfcf', borderWidth: 1 }}>
      <TouchableOpacity onPress={() => { handleFolderView(item, 0) }} style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flex: 0.2, marginTop: -5, justifyContent: "center", }}>
          <FileIcon shared={item.is_shared} type={item.type} />
        </View>
        <View style={{ flex: 0.8, paddingRight: 10 }}>
          <Text numberOfLines={1}>
            {item.name.length < 25
              ? `${item.name}`
              : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
          </Text>
        </View>
      </TouchableOpacity>
      <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', justifyContent: 'flex-end' }}>
        <SideMenusWeb padded auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getSubFolders} />
      </View>
    </View>
  )

  const renderItemWeb = ({ item }) => (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: '#fff',
        width: '100%',
        zIndex: -1,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd'
      }}
    >
      <View style={{ flex: 0.4 }} >
        <TouchableOpacity onPress={() => handleFolderView(item)}>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <View style={{ flex: 0.2, height: 40, justifyContent: 'center' }}>
              <FileIcon type={item.type} shared={item.is_shared} />
            </View>
            <View style={{ flex: 0.8 }}>
              <Text style={{ paddingTop: 12 }} numberOfLines={1}>
                {item.name.length < 25
                  ? `${item.name}`
                  : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>

      <View style={{ flex: 0.2 }} >
        <Text style={{ paddingTop: 12 }}>{formatDate(item.last_modified_at)}</Text>
      </View>
      <View style={{ flex: 0.1 }} >
        <Text style={{ paddingTop: 12 }}>{item.file_size}</Text>
      </View>
      <View style={{ flex: 0.1 }} >
        <Text style={{ paddingTop: 12 }}>{item.is_shared ? <Badge>Shared</Badge> : ''}</Text>
      </View>
      <View style={{ flex: 0.2, alignItems: 'flex-end' }} >
        <View style={{ paddingRight: 20 }}>
          <SideMenusWeb auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getSubFolders} />
        </View>

      </View>
    </View>

  );

  const uploadDocument = async (doc) => {
    console.log(doc)
    try {
      // let baseDoc = doc.replace("data:image/png;base64,", "");
      // let baseDoc = doc.split(',')[1];
      // let baseDoc = doc.uri.split(',')[1];
      // if (Platform.OS === 'android') baseDoc = doc.uri;

      // if (Platform.OS === 'ios') baseDoc = doc.uri;

      let baseDoc;
      if (Platform.OS === 'android') {
        baseDoc = doc.uri;
      } else if (Platform.OS === 'ios') {
        baseDoc = doc.uri;
      }
      else {
        baseDoc = doc.uri.split(',')[1];
      }

      // let data = JSON.stringify({
      //   file:baseDoc
      // });
      let data = {
        file: baseDoc,
        file_name: doc.fileName
      };
      const { id } = route.params;
      const response = await new ApiHandler().uploadFile(id, data)
      console.log(response)
      setUploadImageModalVisible(false)
      init()
    } catch (error) {
      setUploadImageModalVisible(false)
      init()
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const renderGridItem = ({ item }) => (
    <View>
      <View style={{ position: 'relative', justifyContent: 'center', padding: 10, borderRadius: 5, borderColor: '#cfcfcf', borderWidth: 1, minHeight: height / 10, maxWidth: width / 2.4 }}>
        <TouchableOpacity onPress={() => { handleFolderView(item, 0) }} style={{ flex: 1, marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flex: 1, justifyContent: "center", alignItems: 'center' }}>
            <FileIcon type={item.type} shared={item.is_shared} />
          </View>
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 5, paddingRight: 20, alignItems: 'center' }}>
        <Text style={{ fontSize: item.name.length > 15 ? 12 : 13, width: '85%' }}>
          {item.name.length < 25
            ? `${item.name}`
            : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
        </Text>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SideMenusMobile auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getSubFolders} />
        </View>
      </View>
    </View>
  )



  //const { folder } = route.params;
  return (
    <>
      <MenuProvider customStyles={{
        backdrop: {
          backgroundColor: '#7a7d7f',
          opacity: 0.5,
        }
      }}>
        <ProgressBar indeterminate={true} visible={loading} />
        <Container style={{ backgroundColor: 'white', zIndex: -10 }} isMobile={isMobile}>
          {!isMobile && <TouchableOpacity onPress={goBack} style={{ marginBottom: -25, paddingTop: 30 }}><Icon icon="arrowLeft" color={theme.basics.brand} height={20} width={20} /></TouchableOpacity>}
          <Header isMobile={isMobile}>
            <TitleWrapper>
              {!isMobile && <Icon icon="sharedfolderright" color={theme.basics.brand} height={40} width={40} />}
              {/* {isMobile && <TouchableOpacity  onPress={(e) => goBackToMain(e)} style={{paddingRight:10}}><Icon icon="arrowLeft" color={theme.basics.brand} height={20} width={20} /></TouchableOpacity>} */}
              {/* {!isMobile && <TouchableOpacity onPress={goBack}  style={{paddingRight:10}}><Icon icon="arrowLeft" color={theme.basics.brand} height={20} width={20} /></TouchableOpacity>} */}
              {isMobile && <TouchableOpacity onPress={goBack} style={{ paddingRight: 10 }}><Icon icon="arrowLeft" color={theme.basics.brand} height={20} width={20} /></TouchableOpacity>}


              <Title style={{ width: width - 150 }} isMobile={isMobile}>{folder ? folder.name : ''}</Title>
            </TitleWrapper>

            {isMobile && (
              <TouchableOpacity onPress={() => layout === Layouts.grid ? setLayout(Layouts.list) : setLayout(Layouts.grid)} style={{ position: 'absolute', right: 60 }}>
                <Ionicons name={layout === Layouts.grid ? "list-outline" : "grid-outline"} size={20} color="black" />
              </TouchableOpacity>
            )}

            <ExpansiveMenu
              mainIconColor={
                isMobile ? theme.basics.brand : theme.basics.background_white
              }
              mainIconBackground={
                isMobile ? 'transparent' : theme.basics.brand
              }
              desktopText="New"
              expansiveChildrens={expansiveChildrens}
              showSecondaryButton
              layout={layout}
              onToggle={() => layout == Layouts.grid ? setLayout(Layouts.list) : setLayout(Layouts.grid)}
            // icon={'sharedfolderleft'}
            />
          </Header>


          {/*       
      <ScrollView
         // contentContainerStyle={styles.scroll}
         contentContainerStyle={{flex:1,height:'100%',paddingTop:50}}
          nestedScrollEnabled
        > */}
          <>
            {/* <View style={{flexDirection: 'row',paddingBottom:20}}>
        <Text style={{flex:0.05,fontSize:12,color:'#959595'}}>Myfolder</Text>
        <Text style={{flex:0.02,fontSize:12}}><Icon icon="arrowRight" color={theme.basics.brand} height={10} width={10} /></Text>
        <Text style={{flex:0.05,fontSize:12}}>Myfolder</Text>
        </View> */}

            {!isMobile
              ? <>
                {layout === Layouts.list ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        width: '100%',
                        paddingBottom: 20,
                        marginTop: 20,
                      }}
                    >
                      <View style={{ flex: 0.08 }} />
                      <View style={{ flex: 0.2 }} >
                        <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', paddingLeft: 20 }}>Name</Text>
                      </View>
                      <View style={{ flex: 0.12 }}>
                        <Text></Text>
                      </View>
                      <View style={{ flex: 0.2 }} >
                        <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', }}>Last Modified</Text>
                      </View>
                      <View style={{ flex: 0.1 }} >
                        <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', }}>Total Size</Text>
                      </View>
                      <View style={{ flex: 0.1 }} >
                        <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', }}></Text>
                      </View>
                      <View style={{ flex: 0.2 }} >
                        <Text></Text>
                      </View>
                    </View>

                    <FlatList
                      data={foldersList}
                      renderItem={renderItemWeb}
                      ListHeaderComponent={() => (!foldersList.length ?
                        <Text style={{ textAlign: 'center', padding: 50, backgroundColor: '#fff', marginRight: 20 }}>No files/folders found.</Text>
                        : null)}
                      keyExtractor={item => item.id}
                      contentContainerStyle={{
                        justifyContent: "center",
                        zIndex: -2
                      }}
                    />
                  </>
                ) : (
                  <ScrollView nestedScrollEnabled style={{ flex: 1 }}>
                    <FlatGrid
                      data={data.filter(item => item.type == "folder")}
                      spacing={10}
                      ListHeaderComponent={() => (
                        <View style={{ padding: 5, marginVertical: 10 }}>
                          <Text style={{ fontSize: 24, fontWeight: '600' }}>Folders</Text>
                        </View>
                      )}
                      itemDimension={width / 7}
                      renderItem={renderGridItemWeb}
                    />
                    <FlatGrid
                      style={{ marginTop: 10 }}
                      data={data.filter(item => item.type !== "folder")}
                      spacing={10}
                      itemDimension={width / 7}
                      ListHeaderComponent={() => (
                        <View style={{ padding: 5, marginVertical: 10 }}>
                          <Text style={{ fontSize: 24, fontWeight: '600' }}>Files</Text>
                        </View>
                      )}
                      renderItem={renderGridItemWeb}
                    />
                  </ScrollView>
                )}
              </>
              : (
                <>
                  {layout === Layouts.list ? (
                    <ScrollView
                      // contentContainerStyle={styles.scroll}
                      // contentContainerStyle={{flex:1}}
                      nestedScrollEnabled
                    >
                      <FlatList
                        data={foldersList}
                        renderItem={renderItem}
                        ListHeaderComponent={() => (!foldersList.length ?
                          <Text style={{ textAlign: 'center', padding: 50, backgroundColor: '#fff', marginRight: 20 }}>No files/folders found.</Text>
                          : null)}
                        keyExtractor={item => item.id}
                        contentContainerStyle={{
                          justifyContent: 'center', flex: 1,
                        }}
                      />
                    </ScrollView>
                  ) : (
                    <>
                      <ScrollView style={{ marginTop: -20 }}>
                        <FlatGrid
                          data={foldersList.filter(item => item.type === 'folder')}
                          ListHeaderComponent={(
                            <View style={{ padding: 15 }}>
                              <Text style={{ fontSize: 18, fontWeight: '600' }}>Folders</Text>
                            </View>
                          )}
                          ListEmptyComponent={(
                            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                              <Text>No Folders to Show</Text>
                            </View>
                          )}
                          renderItem={renderGridItem}
                        />
                        <FlatGrid
                          data={foldersList.filter(item => item.type !== 'folder')}
                          ListHeaderComponent={(
                            <View style={{ padding: 15 }}>
                              <Text style={{ fontSize: 18, fontWeight: '600' }}>Files</Text>
                            </View>
                          )}
                          renderItem={renderGridItem}
                        />
                      </ScrollView>
                    </>
                  )}
                </>
              )
            }
          </>
          {/* <FlatList
            data={foldersList}
            renderItem={renderItem}
            keyExtractor={item => item.id}
            contentContainerStyle={{
              display: "flex",
              flexGrow: 1,
            }}
          /> */}
          {/* </ScrollView> */}

          <NewFolderModal visible={isCreateModalVisible}
            createFolder={(folderName) => createFolder(folderName)}
            errorTextCreate={errorTextCreate}
            onDismiss={() => setCreateModalVisible(false)}
          />






          {/* <SimpleModal isVisible={isFileView} >
            <CreateFolderContainer style={{ height: isMobile ? height - 480 : '155px', justifyContent: 'center', alignItems: 'center' }}>
              <HeaderCreateFolder>
                <CloseButtonCreateFolder style={{ right: 0 }} onPress={() => setIsFileView(false)}>
                  <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
                </CloseButtonCreateFolder>
              </HeaderCreateFolder>

              <Modal visible={isFileView} transparent={true}>
                <ImageViewer imageUrls={previewFIle} />
              </Modal>
            </CreateFolderContainer>
          </SimpleModal> */}

          {/* <Modal style={{
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
          }} visible={isFileView} transparent={true} presentationStyle={'overFullScreen'}>
            <View style={{ width: width / 2, height: height / 2, position: 'relative', alignSelf: 'center', justifyContent: 'space-between', padding: '0 20px 5%' }}>
              <ImageViewer imageUrls={previewFIle} />
            </View>
          </Modal> */}

          <FilePreviewModal
            visible={isFileView}
            isMobile={isMobile}
            communityEmployee={communityEmployee}
            communityGroup={communityGroup}
            communityTeam={communityTeam}
            item={currentItem}
            getMyFolders={getSubFolders}
            onDismiss={() => { setIsFileView(false); setPreviewFIle(null) }}
          >
            {isMobile
              ? <WebView
                bounces={false}
                source={{ uri: previewFIle, cache: false }}
                style={{ flex: 1 }}
                originWhitelist={["*"]}
                useWebKit
              />
              : <iframe
                id="frame"
                style={{ width: '100%', height: '100%', zoom: 0.75 }}
                width={'100%'}
                height={'100%'}
                src={previewFIle}
                frameborder='0'
                allow='autoplay; encrypted-media'
                allowfullscreen
              />
            }
          </FilePreviewModal>




        </Container>
      </MenuProvider>
    </>
  )
}

export default SubFolders