import React, { useState } from 'react'
import { View, Platform, ScrollView } from 'react-native'
import { CollapseButton, CollapseButtonMobile, Icon } from '@/components'
import theme from '@/styles/themes/black'
import { CollapseButtonContainer, CollapseButtonToggle, Button } from './styles'
import PropTypes from 'prop-types'
import { isMobile } from '@/styles'
import { useSelector } from 'react-redux'

import * as brandSelectors from '@/redux/selectors/branding'

const ExpansiveMenu = ({
  btnTextColor,
  desktopText,
  expansiveChildrens,
  mainIcon,
  mainIconColor,
  mainIconBackground,
  ...params
}) => {
  const [isModalVisible, setModalVisible] = useState(false)

  const branding = useSelector(brandSelectors.getSettings)

  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const onButtonMobileClicked = (item) => {
    setModalVisible(false)
    item.onPress(item.routeName)
  }

  return isMobile ? (
    <View>
      <CollapseButtonToggle
        background={mainIconBackground}
        color={mainIconColor}
        {...params}
      >
        <Button
          onPress={() => {
            setModalVisible(true)
          }}
        >
          <Icon
            color={mainIconColor || theme.basics.background_white}
            icon={mainIcon || 'ellipsis'}
            width={18}
            height={18}
          />
        </Button>
      </CollapseButtonToggle>
      <CollapseButtonMobile
        isCollapseVisible={isModalVisible}
        setCollapseVisible={setModalVisible}
      >
        <ScrollView>
          {expansiveChildrens.map((item, index) => (
            <CollapseButtonMobile.Item
              key={index}
              color={theme.basics.black_text}
              iconSize={14}
              text={item.text}
              onPress={() => onButtonMobileClicked(item)}
            />
          ))}
        </ScrollView>
      </CollapseButtonMobile>
    </View>
  ) : (
    <CollapseButtonContainer>
      <CollapseButton
        icon={desktopText ? null : mainIcon || 'ellipsis'}
        btnAlign="flex-end"
        btnWidth={desktopText ? null : 38}
        iconWidth={4}
        iconHeight={16}
        btnPaddingHorizontal={0}
        btnText={desktopText}
        iconColor={mainIconColor || theme.basics.background_white}
        bgButtonColor={mainIconBackground}
        // btnTextColor={color_scheme_primary_button_text}
        {...params}
      >
        {expansiveChildrens.map((item, index) => (
          <CollapseButton.Item
            key={index}
            color={theme.basics.black_text}
            icon={item.icon}
            iconSize={14}
            text={item.text}
            onPress={() => onButtonMobileClicked(item)}
          />
        ))}
      </CollapseButton>
    </CollapseButtonContainer>
  )
}
{
  /* <CollapseButtonContainer>
      <CollapseButton
        icon={desktopText ? null : (mainIcon || 'ellipsis')}
        btnAlign="flex-end"
        btnWidth={desktopText ? null : 38}
        iconWidth={4}
        iconHeight={16}
        btnPaddingHorizontal={0}
        btnText={desktopText}
        iconColor={mainIconColor || theme.basics.black_text}
        bgButtonColor={mainIconBackground}
        {...params}
        // isCollapseVisible={isModalVisible}
        // setCollapseVisible={setModalVisible}
      >
        {expansiveChildrens.map((item, index) => (
          <CollapseButton.Item
            key={index}
            color={theme.basics.black_text}
            icon={item.icon}
            iconSize={14}
            text={item.text}
            onPress={() => onButtonMobileClicked(item.onPress)}
          />
        ))}
      </CollapseButton>
    </CollapseButtonContainer> */
}
{
  /* </> */
}
// <View style={{ zIndex: 10000 }}>
//   <CollapseButtonToggle
//     background={mainIconBackground}
//     color={mainIconColor}
//     {...params}
//   >
//     {/* SEARCH ICON HERE <Button>
//       <Icon
//         color={mainIconColor || theme.basics.background_white}
//         icon={mainIcon || 'ellipsis'}
//         width={25}
//         height={25}
//       />
//     </Button>*/}
//     <Button onPress={() => { setModalVisible(true) }}
//     >
//       <Icon
//         color={mainIconColor || theme.basics.background_white}
//         icon={mainIcon || 'ellipsis'}
//         width={18}
//         height={18}
//       />
//     </Button>
//   </CollapseButtonToggle>
//   <CollapseButtonMobile
//     isCollapseVisible={isModalVisible}
//     setCollapseVisible={setModalVisible}
//   >
//     {expansiveChildrens.map((item, index) => (
//       <CollapseButtonMobile.Item
//         key={index}
//         color={theme.basics.black_text}
//         icon={item.icon}
//         iconSize={14}
//         text={item.text}
//         onPress={() => onButtonMobileClicked(item.onPress)}
//       />
//     ))}
//   </CollapseButtonMobile>
// </View>
// ) : (
//   <View style={{ zIndex: 10000 }}>
//     <CollapseButtonToggle
//       background={mainIconBackground}
//       color={mainIconColor}
//       {...params}
//     >
//       {/* SEARCH ICON HERE <Button>
//         <Icon
//           color={mainIconColor || theme.basics.background_white}
//           icon={mainIcon || 'ellipsis'}
//           width={25}
//           height={25}
//         />
//       </Button>*/}
//       <Button onPress={() => { setModalVisible(true) }}
//       >
//         <Icon
//           color={mainIconColor || theme.basics.background_white}
//           icon={mainIcon || 'ellipsis'}
//           width={18}
//           height={18}
//         />
//       </Button>
//     </CollapseButtonToggle>
//     <CollapseButtonMobile
//       isCollapseVisible={isModalVisible}
//       setCollapseVisible={setModalVisible}
//     >
//       {expansiveChildrens.map((item, index) => (
//         <CollapseButtonMobile.Item
//           key={index}
//           color={theme.basics.black_text}
//           icon={item.icon}
//           iconSize={14}
//           text={item.text}
//           onPress={() => onButtonMobileClicked(item.onPress)}
//         />
//       ))}
//     </CollapseButtonMobile>
//   </View>
// )

ExpansiveMenu.propTypes = {
  expansiveChildrens: PropTypes.array.isRequired,
  expansiveMains: PropTypes.array,
  mainIcon: PropTypes.string,
  mainIconColor: PropTypes.string,
  mainIconBackground: PropTypes.string,
  desktopText: PropTypes.string,
}

export default ExpansiveMenu
