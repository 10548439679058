import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Send(props) {
  return (
    <Svg width="18" height="15" viewBox="0 0 18 15" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={props.color || '#6D6D6D'}
        d="M17.9084 0.0627437C17.8282 -0.00329226 17.7142 -0.018613 17.6176 0.0235064L0.155693 7.64706C0.0616842 7.68812 0.00114611 7.77625 1.60703e-05 7.87364C-0.00111397 7.97102 0.057433 8.06037 0.150527 8.1033L3.6 9.56611C3.69062 9.60793 10.8 6.08753 10.8 6.08753L6.43749 10.6798C6.34922 10.7727 6.3 10.896 6.3 11.0242V14.4303C6.3 14.8781 6.84325 15.1001 7.15685 14.7806L9.44099 12.4532L13.0421 14.1658C13.1125 14.1974 13.194 14.1987 13.2655 14.1695C13.337 14.1402 13.3915 14.0833 13.4148 14.0134L17.9876 0.328556C18.0196 0.232991 17.9884 0.12878 17.9084 0.0627437Z"
      />
    </Svg>
  )
}

Send.propTypes = Svg.propTypes
