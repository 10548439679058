import React, { useState, useEffect } from 'react';
import { View, Text, Image, TouchableOpacity, ScrollView, Platform } from 'react-native';
import OnboardingPage from '@/components/OnboardingPage';
import OnboardingHeader from '@/components/OnboardingHeader';
import LoginDecoration from '@/assets/onboarding/LoginDecoration';
import { vw } from 'react-native-expo-viewport-units';
import ReframeRoundLogo from '@/assets/brand/ReframeRoundLogo.png';
import ApiHandler from '@/api/ApiHandler';
import { useDispatch } from 'react-redux';
import { actions as authActions } from '@/redux/reducers/auth'
import { actions as brandSetingsActions } from '@/redux/reducers/branding'
import { actions as subscriptionActions } from '@/redux/reducers/subscription'

const MultiWorkspace = () => {
    const dispatch = useDispatch();
    const [listWorkspaces, setListWorkspaces] = useState([]);

    useEffect(async () => {
        try {
            const response = await new ApiHandler().getWorkspaces();
            if (response?.data) {
                setListWorkspaces(response?.data?.data);
            }
        } catch (error) {
            console.log('GET WORKSPACES ERROR', error);
        }
    }, []);

    const selectedWorkspace = async (workspaceId) => {
        try {
            const data = {
                workspace_id: workspaceId
            };
            const response = await new ApiHandler().selectWorkspace(data);
            if (response?.data) {
                dispatch(authActions.selectedWorkspace(response?.data?.data));

                try {
                    const response = await new ApiHandler().getBrandingSettings()
                    if (response?.data) {
                        const settings = response?.data?.data || {}
                        dispatch(brandSetingsActions.updateSettings(settings));
                    }
                } catch (error) {

                }
            }
        } catch (error) {
            console.log('SELECT WORKSPACE ERROR', error);
        }
    }

    return (
        Platform.OS !== 'web'
            ?
            <OnboardingPage
                header={<OnboardingHeader withBackButton={false} />}
                decoration={<LoginDecoration width={vw(45)} height="auto" />}
            >
                <ScrollView style={{ flex: 1, marginHorizontal: 16, width: '100%' }}>
                    <Text style={{
                        fontSize: 25,
                        fontFamily: 'WorkSans_400Regular',
                        color: '#000000'
                    }}>
                        Select A Workspace
                    </Text>
                    <Text style={{
                        fontSize: 20,
                        fontFamily: 'WorkSans_400Regular',
                        color: '#6D6D6D',
                        paddingTop: 10,
                        paddingBottom: 40,
                    }}>
                        Your account is associated with multiple workspaces. Select the workspace that you’d like to access.
                    </Text>
                    {
                        listWorkspaces.map((item, index) => {
                            return (
                                <TouchableOpacity
                                    key={index}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 25,
                                        height: 44,
                                    }}
                                    onPress={() => selectedWorkspace(item.id)}
                                >
                                    <View
                                        style={{
                                            width: 45,
                                            height: 45,
                                            padding: 10,
                                            borderRadius: 50,
                                            borderWidth: 1,
                                            borderColor: '#e5e5e5'
                                        }}
                                    >
                                        <Image
                                            source={item.icon == null ? ReframeRoundLogo : { uri: item.icon }}
                                            style={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </View>

                                    <Text
                                        style={{
                                            paddingLeft: 15,
                                            color: '#000000',
                                            fontSize: 20,
                                            fontFamily: 'WorkSans_400Regular',
                                        }}>
                                        {item.name}
                                    </Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </ScrollView>
            </OnboardingPage>
            :
            <ScrollView style={{
                flex: 1,
            }}>
                <Image
                    source={ReframeRoundLogo}
                    style={{
                        width: 60,
                        height: 60,
                        marginTop: 52,
                        marginLeft: 44
                    }} />
                <View style={{
                    width: 618,
                    height: 100,
                    alignSelf: 'center',
                    marginTop: 49
                }}>
                    <Text style={{
                        fontSize: 40,
                        fontFamily: 'WorkSans_400Regular',
                        color: '#000000',
                        textAlign: 'center'
                    }}>
                        Select A Workspace
                    </Text>
                    <Text style={{
                        fontSize: 20,
                        fontFamily: 'WorkSans_400Regular',
                        color: '#6D6D6D',
                        paddingTop: 10,
                        paddingBottom: 40,
                        textAlign: 'center',
                        width: '90%',
                        alignSelf: 'center'
                    }}>
                        Your account is associated with multiple workspaces. Select the workspace that you’d like to access.
                    </Text>
                    {
                        listWorkspaces.map((item, index) => {
                            return (
                                <TouchableOpacity
                                    key={index}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 25,
                                        height: 44,
                                    }}
                                    onPress={() => selectedWorkspace(item.id)}>


                                    <View
                                        style={{
                                            width: 50,
                                            height: 50,
                                            padding: 10,
                                            borderRadius: 50,
                                            borderWidth: 1,
                                            borderColor: '#e5e5e5',
                                            backgroundColor: item.icon_background ? item.icon_background : 'transparent'
                                        }}
                                    >
                                        <Image
                                            source={item.icon == null ? ReframeRoundLogo : { uri: item.icon }}
                                            style={{
                                                width: '100%',
                                                height: '100%'
                                            }}
                                        />
                                    </View>

                                    <Text
                                        style={{
                                            paddingLeft: 15,
                                            color: '#000000',
                                            fontSize: 20,
                                            fontFamily: 'WorkSans_400Regular',
                                        }}>
                                        {item.name}
                                    </Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>
            </ScrollView>
    );
};

export default MultiWorkspace;