import styled from 'styled-components/native'
import { StatusBar, StyleSheet } from 'react-native'
import {
	Headline4,
	Headline5,
	Headline6,
	Headline6SemiBold,
	Paragraph2,
} from '@/styles/fonts'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { isMobile } from '@/styles'

export const styles = StyleSheet.create({
	contentScrollView: {
		width: '100%',
		flexGrow: 1,
		paddingBottom: isMobile ? '5%' : '1%'
	},
	gradiente: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 320,
		zIndex: 1,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	},
	flatListContent: {
		alignItems: 'center',
		flexDirection: 'column',
		paddingVertical: isMobile ? 20 : 30,
		paddingHorizontal: isMobile ? 0 : 30,
		width: '100%',
		maxWidth: 700,
		alignSelf: 'center',
	},
	flatList: {
		flex: 1,
		width: '100%',
	}
})


export const Container = styled.View`
	padding-top: 0${StatusBar.currentHeight}px;
	height: 100%;
	width: 100%;
`

export const Title = styled(Headline5)`
	color: ${({ theme }) => theme.basics.black_text};
	margin: 15px 0;
`

export const Description = styled(Headline6)`
	color: ${({ theme }) => theme.basics.black_text};
`

export const CoverImage = styled.Image`
	width: 100%;
	height: 100%;
`

export const IconButton = styled(TouchableOpacity)`
	margin: 0 10px 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	left: -4px;
`
export const LeftIconButton = styled(TouchableOpacity)`
	margin: 0 0 0 15px;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	position: relative;
`

export const ImageCoverContainer = styled.View`
	height: ${isMobile ? 460 : 500}px;
`

export const Header = styled.View`
	background: ${({ theme }) => theme.basics.background_white};
	padding: 0 0 30px;
	margin: 0 0 16px;
`


export const SectionMenuHeader = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

`

export const MenuHeader = styled.View`
	flex-direction: row;
	align-items: center;
	z-index: 2;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	top: 20px;

`

export const SubHeader = styled.View`
	padding: 0 16px;
	justify-content: flex-start;
`

export const BottomHeader = styled.View`
	flex-direction: ${isMobile ? 'column' : 'row'};
	justify-content: ${isMobile ? 'center' : 'space-between'};
	align-items: center;
	padding: 0 ${!isMobile ? 45 : 0}px 0 0;
`

export const MembersContainer = styled.View`
	justify-content: center;
	padding: 20px 24px 20px ${!isMobile ? 16 : 24}px;
	/* width: ${isMobile ? '100%' : '185px'}; */
`

export const MembersList = styled.View`
	width: 100%;
	margin: 8px 0 0;
	padding: 0 24px 0 ${!isMobile ? 0 : 24}px;
	flex-direction: row;
	justify-content: space-around;
`

export const ParticipateButton = styled.TouchableOpacity`
	align-items: center;
	justify-content: center;
	width: 288px;
	height: 40px;
	background-color: ${({ theme, blockJoin }) => blockJoin ? theme.basics[400] : theme.basics.brand};
	border-radius: 3px;
	position: relative;
	right: ${isMobile ? 0 : 58}px;
	z-index: 1;
`

export const ContainerButtons = styled.View`
	position: relative;
	flex-direction: row;
	align-items: center;
	align-self: center;
`

export const CollapseButtonContainer = styled.View`
	right: 0;
	position: absolute;
`

export const ParticipateText = styled(Headline6)`
	color: ${({ theme, blockJoin }) => blockJoin ? theme.basics[500] : theme.basics.background_white};
`

export const AvatarMoreContainer = styled.View`
	position: relative;
`

export const AvatarMoreButton = styled.TouchableOpacity`
	background-color: rgba(0,0,0,0.4);
	height: ${isMobile ? 45 : 75}px;
	width: ${isMobile ? 45 : 75}px;
	align-items: center;
	justify-content: center;
	border-radius: ${isMobile ? 45 : 75}px;
	position: absolute;
	top: 0;
	left: 0;
`

export const TextMoreButton = styled(Headline6)`
	color: ${({ theme }) => theme.basics.background_white};
`

export const TitleListMember = styled(Paragraph2)`
	color: ${({ theme }) => theme.basics.black_text};
`

export const LeaveCard = styled.View`
	position: relative;
	width: 521px;
	height: 184px;
	justify-content: flex-start;
	align-items: center;
	background-color: ${({ theme }) => theme.basics.background_white};
	border-radius: 5px;
`

export const CloseLeaveModalButton = styled.TouchableOpacity`
	align-self: flex-end;
	margin: 13px 13px 0 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`

export const LeaveText = styled(Headline4)`
	color: ${({ theme }) => theme.basics.brand};
	text-align: center;
	padding: 0 50px 20px;
`

export const ButtonsContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

export const LeaveButton = styled.TouchableOpacity`
	background-color: ${({ theme, cancel }) => cancel ? theme.basics.background_white : theme.basics.brand};
	align-items: center;
	justify-content: center;
	width: ${({ width }) => width || 205}px;
	height: 40px;
	margin: 0 8px;
	border: 1px solid ${({ theme }) => theme.basics.brand};
	border-radius: 3px;
`

export const LeaveButtonText = styled(Headline6SemiBold)`
	width: 100%;
	text-align: center;
	color: ${({ theme, cancel }) => cancel ? theme.basics.brand : theme.basics.background_white};
`

export const Main = styled.View`
	flex: 1;
	flex-grow: 1;
	width: 100%;
	align-items: center;
	justify-content: flex-start;
	z-index: -1;
`
