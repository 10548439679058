import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function CreateFolder(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M14 1.5H8L6.5 0H2C1.1675 0 0.5075 0.6675 0.5075 1.5L0.5 10.5C0.5 11.3325 1.1675 12 2 12H14C14.8325 12 15.5 11.3325 15.5 10.5V3C15.5 2.1675 14.8325 1.5 14 1.5ZM13.25 7.5H11V9.75H9.5V7.5H7.25V6H9.5V3.75H11V6H13.25V7.5Z" fill="#1B1B1B"/>
    </Svg>


  )
}

CreateFolder.propTypes = Svg.propTypes
