import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function AddUserIcon(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path color={props.color} d="M9.75 3C9.75 1.3425 8.4075 0 6.75 0C5.0925 0 3.75 1.3425 3.75 3C3.75 4.6575 5.0925 6 6.75 6C8.4075 6 9.75 4.6575 9.75 3ZM11.25 4.5V6H13.5V8.25H15V6H17.25V4.5H15V2.25H13.5V4.5H11.25ZM0.75 10.5V12H12.75V10.5C12.75 8.505 8.7525 7.5 6.75 7.5C4.7475 7.5 0.75 8.505 0.75 10.5Z" fill="#1B1B1B" />
    </Svg>
  )
}

AddUserIcon.propTypes = SvgProps

export default AddUserIcon
