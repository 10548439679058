import { FontAwesome, FontAwesome5, Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'
import React from 'react'
import { Platform, TouchableOpacity } from 'react-native'

const Button = ({ onPress, bg, icon, BtnSize, type, size, style, iconColor = "black", radius, children }) => (
  <TouchableOpacity style={[{ justifyContent: 'center', alignItems: 'center', position: 'relative' }, radius && { borderRadius: radius }, BtnSize && { width: BtnSize, height: BtnSize }, bg && { backgroundColor: bg }, style, Platform.OS == 'android' && { height: 35 }]} onPress={onPress}>
    {type == 'Ionicons' ? (
      <Ionicons name={icon} size={size || 24} color="black" />
    ) : type == "fontawesome" ? (
      <FontAwesome name={icon} size={size || 22} color={iconColor} />
    ) : type == "fontawesome5" ? (
      <FontAwesome5 name={icon} size={size || 22} color={iconColor} />
    ) : (
      <MaterialCommunityIcons name={icon} size={size || 24} color={iconColor} />
    )}
    {children}
  </TouchableOpacity>
)

export default Button
