import styled from 'styled-components/native'

const Text = styled.Text`
  text-align: ${({ textAlign }) => textAlign || 'left'};
  color: ${({ color, theme }) => color || theme.basics.brand};
`

export const Headline1 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 36px;
  line-height: 37px;
`
export const Headline2 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 32px;
  line-height: 36px;
`
export const Headline3 = styled(Text)`
  font-family: 'WorkSans_600SemiBold';
  font-size: 30px;
  line-height: 33px;
`
export const Headline4 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 26px;
  line-height: 30px;
`
export const Headline5 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 22px;
  line-height: 25px;
`
export const Headline6Strong = styled(Text)`
  font-family: 'WorkSans_500Medium';
  font-size: 18px;
  line-height: 20px;
`
export const Headline6 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 18px;
  line-height: 20px;
`
export const Headline6SemiBold = styled(Text)`
  font-family: 'WorkSans_600SemiBold';
  font-size: 18px;
  line-height: 20px;
`

export const Subtitle1 = styled(Text)`
  font-family: 'WorkSans_500Medium';
  font-size: 15px;
  line-height: 16px;
`

export const Subtitle1SemiBold = styled(Text)`
  font-family: 'WorkSans_600SemiBold';
  font-size: 15px;
  line-height: 16px;
`

export const Subtitle2 = styled(Text)`
  font-family: 'WorkSans_500Medium';
  font-size: 13px;
  line-height: 15px;
`
export const Paragraph1 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 15px;
  line-height: 20px;
`
export const Paragraph2 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 13px;
  line-height: 18px;
`
export const Caption1 = styled(Text)`
  font-family: 'WorkSans_400Regular';
  font-size: 12px;
  line-height: 16px;
`


export const CaptionMedium = styled(Text)`
  flex: 1;
  flex-wrap: wrap;
  font-family: 'WorkSans_500Medium';
  font-size: 12px;
  line-height: 16px;
`
