import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';
import { View, TouchableOpacity, Text, Linking, StyleSheet, Dimensions } from 'react-native'

const { width } = Dimensions.get('screen');

const PermissionsScreen = ({ camera, mic }) => {
    const navigation = useNavigation();
    const addPermissions = async () => await Linking.openURL('App-Prefs:Privacy')

    const goBack = useCallback(() => {
        navigation.navigate('conversation');
    }, [])

    const capitalise = (str) => [str.charAt(0).toUpperCase(), ...str.slice(1, str.length)].join("")

    return (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <View style={{ flex: 2, alignItems: 'center', justifyContent: 'center' }}>
                <Text style={{ marginVertical: 20, fontSize: 30, alignSelf: 'center' }}>Required Permissions Were Not Granted!</Text>
                <View style={[styles.permission, { borderTopWidth: 1, borderTopColor: 'black', marginTop: '10%' }]}>
                    <Text>Camera</Text>
                    <Text style={styles[camera]}>{capitalise(camera)}</Text>
                </View>
                <View style={styles.permission}>
                    <Text>Microphone</Text>
                    <Text style={styles[mic]}>{capitalise(mic)}</Text>
                </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around', width: '100%' }}>
                <TouchableOpacity onPress={goBack} style={styles.button}>
                    <Text style={styles.buttonText}>Go Back</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={addPermissions} style={styles.button}>
                    <Text style={styles.buttonText}>Allow Manually</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    buttonText: {
        color: '#fff',
    },
    button: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        backgroundColor: 'black',
        height: 40,
        alignItems: 'center',
        justifyContent: 'center'
    },
    permission: {
        padding: 20,
        flexDirection: 'row',
        width: width / 1.1,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomColor: 'black',
        borderBottomWidth: 1
    },
    blocked: {
        color: 'red'
    },
    granted: {
        color: 'green'
    }
})

export default PermissionsScreen; 