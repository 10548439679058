import React, { useState } from 'react'
import DropDown from 'react-native-paper-dropdown'
import PropTypes from 'prop-types'

const SimpleSelect = ({ enabled = true, list, label, value, setValue, valueAttribute, labelAttribute, ...params }) => {
  const [showDropDown, setShowDropDown] = useState(false)

  if (!valueAttribute) {
    valueAttribute = 'id'
  }

  if (!labelAttribute) {
    labelAttribute = 'name'
  }

  const handleSetValue = (e) => {
    setShowDropDown(false)
    setValue(e)
  }

  const mappedList = (list ?? [])?.map((item) => {
    return {
      value: item[valueAttribute].toString(),
      label: item[labelAttribute],
    }
  })

  value = value ? value.toString() : null

  return (
    <>
      <DropDown
        label={label}
        mode={'outlined'}
        visible={showDropDown}
        showDropDown={() => { if (enabled) { setShowDropDown(true) } }}
        onDismiss={() => setShowDropDown(false)}
        setValue={handleSetValue}
        value={value}
        {...params}
        list={mappedList}
        inputProps={{
          style: { backgroundColor: 'white', height: 45, width: 288 },
        }}
      />
    </>
  )
}

SimpleSelect.propTypes = {
  valueAttribute: PropTypes.string,
  labelAttribute: PropTypes.string,
  list: PropTypes.array.isRequired,
  setValue: PropTypes.func,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
}

export default SimpleSelect
