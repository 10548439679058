import { actions } from "./reducers/auth"

export function updateInfiniteScroll(currentData, action) {
  return {
    data: action.payload.current_page > 1 ? currentData.data.concat(action.payload.data) : action.payload.data,
    page: action.payload.current_page,
    nextPage: action.payload.next_page_url,
    loading: false,
  }
}

export function updateInfiniteScrollTeamPost(currentData, action) {
  const data = action.payload.data.map(post => {
    return {
      Data: post,
      ID: post?.id,
      Type: 'App\\Post',
      UpdatedAt: post?.updated_at,
    }
  })
  return {
    data: action.payload.current_page > 1 ? currentData.data.concat(data) : data,
    page: action.payload.current_page,
    nextPage: action.payload.next_page_url,
    loading: false,
  }
}

export function updateInfiniteScrollDetailPage(currentData, perPage, action) {
  const current_page = action.payload.current_page
  if (current_page == 1) {
    return {
      data: action.payload.data,
      page: action.payload.current_page,
      nextPage: action.payload.next_page_url,
      loading: false,
    }
  }
  return {
    data: currentData.data,
    loading: false,
  }
}

export function updateFeeds(currentData, action) {
  return {
    data: action.payload.current_page > 1 ? currentData.data.concat(action.payload.data) : action.payload.data,
    page: action.payload.current_page,
    nextPage: action.payload.next_page_url,
    loading: false,
  }
}

export function updateAfterUpdate(currentData, action) {
  return {
    ...currentData,
    data: currentData.data?.map(item => item.id === action.payload.data.id ? action.payload.data : item),
  }
}
