import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'

export const constants = {
    STORE_TOKEN: createSagaAction('STORE_TOKEN'),
    REMOVE_TOKEN: createSagaAction('REMOVE_TOKEN')
}


// ------------------------------------
// Constants
// ------------------------------------

export const actions = {

    storeToken: (token, next) => {

        return {
            token,
            type: constants.STORE_TOKEN.ACTION,
            next,
        }
    },
    // removeToken: (token, next) => ({
    //     token,
    //     type: constants.REMOVE_TOKEN.ACTION,
    //     next,
    // })
    removeToken: (token, next) => ({
        token,
        type: constants.REMOVE_TOKEN.ACTION,
        next,
    }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
    //FOR STORING TOKEN
    [constants.STORE_TOKEN.ACTION]: (state, payload) => {

        return {
            ...state,
            token: payload.token,
            loading: false,
        }
    },
    [constants.STORE_TOKEN.SUCCESS]: (state, { action }) => {
        return {
            ...state,
            token: action.token,
            loading: false,

        }
    },
    [constants.REMOVE_TOKEN.ACTION]: (state, payload) => {
        return {
            ...state,
            token: null,
            loading: false,
        }
    },
    [constants.REMOVE_TOKEN.SUCCESS]: (state, action) => {
        return {
            ...state,
            token: action.payload.token,
            loading: false,

        }
    }

    //FOR REMOVING TOKEN
    //     [constants.REMOVE_TOKEN.ACTION]: (state, payload) => {
    //         return {
    //             ...state,
    //             token: payload.token,
    //             loading: true,

    //         }
    //     },
    //     [constants.REMOVE_TOKEN.SUCCESS]: (state, action) => {
    //         return {
    //             ...state,
    //             token: action.payload.token,
    //             loading: false,
    //         }
    //     },
    // },
    // [constants.REMOVE_TOKEN.ACTION]: (state) => {
    //     return { ...state, loading: true, token: null }
    // },
    // [constants.REMOVE_TOKEN.SUCCESS]: (state, action) => {
    //     return {
    //         ...state,
    //         token: action.action.token,
    //         loading: false,
    //     }
    // },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
    error: null,
    isLoading: false,
    token: undefined,
}

export default createReducer(initialState, (state, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : { ...state, isLoading: false }
})