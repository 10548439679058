import { persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createBlacklistFilter } from 'redux-persist-transform-filter'

const authTransform = createBlacklistFilter(
  'auth', // which reducer
  ['error', 'isLoading', 'loggingIn'], // what not to save
)

export function persistRootReducer(reducer) {
  return persistReducer(
    {
      key: 'root',
      whitelist: ['auth'],
      storage: AsyncStorage,
      transforms: [authTransform],
    },
    reducer,
  )
}
