import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Images(props) {
    return (
        <Svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path fill={props.color} d="M20 14V2C20 0.9 19.1 0 18 0H6C4.9 0 4 0.9 4 2V14C4 15.1 4.9 16 6 16H18C19.1 16 20 15.1 20 14ZM9 10L11.03 12.71L14 9L18 14H6L9 10ZM0 4V18C0 19.1 0.9 20 2 20H16V18H2V4H0Z" />
        </Svg>
    )
}

Images.propTypes = Svg.propTypes
