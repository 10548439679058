import React from 'react'
import {
    Text,
    View,
    TouchableOpacity,
    Platform
} from 'react-native'

import Icon from "@/components/Icon";

import styles from './styles.js';

const Block = ({
    title,
    subtitle,
    action,
    children
}) => {

    return (
        <View style={[
            styles.Container,
            {}
        ]}>
            {title || subtitle || action ? (
                <View style={[
                    styles.Header,
                    {}
                ]}>
                    <View style={[
                        styles.HeaderLeft,
                        {}
                    ]}>
                        <Text style={[
                            styles.HeaderTitle,
                            {}
                        ]}>
                            {title}
                        </Text>
                        {
                            subtitle ? (
                                <Text style={[
                                    styles.HeaderSubtitle,
                                    {}
                                ]}>
                                    {subtitle}
                                </Text>
                            ) : null
                        }
                    </View>

                    <View style={[
                        styles.HeaderRight,
                        {}
                    ]}>
                        {action && action?.onPress ? (
                            <TouchableOpacity
                                onPress={action?.onPress}
                                style={[
                                    styles.ActionContainer,
                                    {}
                                ]}
                            >
                                {action?.icon ? (
                                    <Icon
                                        icon={action?.icon}
                                        color={'#FFF'}
                                        height={Platform.OS == 'web' ? 13 : 10}
                                    />
                                ) : (
                                    <Icon
                                        icon="chevronRight"
                                        color={'#FFF'}
                                        height={Platform.OS == 'web' ? 13 : 10}
                                    />
                                )}
                            </TouchableOpacity>
                        ) : null}
                    </View>

                </View>
            ) : null}
            <View style={[
                styles.Body,
                {}
            ]}>
                {children}
            </View>
        </View>
    )

}

export default Block