import styled from 'styled-components/native'
import { Button as MuiButton } from 'react-native-paper'
import { UIColors } from '@/constants/colors'

export const Button = styled(MuiButton).attrs(() => ({
  activeOpacity: 1,
}))`
  height: 45px;
  border-radius: 3px;
  align-items: center;
  flex-direction: row;
  text-transform: none;
  justify-content: center;
  background-color: ${({ color }) => color || '#fff'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  border-width: ${props => props.outline ? 2 : 0}px;
  border-color: ${UIColors.border};
`
