import React, { useState, useEffect } from 'react'
import { Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

import { ScreenSize, useScreenSize } from '@/styles/screenSize'
import { SideDrawerContextProvider } from '@/hooks/sideDrawer'
import ApiHandler from '@/api/ApiHandler'

import OnboardingNavigator from './onboarding'
import MainTabNavigator from './main/mainTabNavigator'
import MainStackNavigator from './main/mainStackNavigator'
import { isMobile } from '@/styles/screenSize'
import { getAuthToken, getAuthFirstAccess, getUser, getMember, getWorkspace, getHasMultipleWorkspaces, getSelectedWorkspace } from '@/redux/selectors/auth'
import { getSubscriptionMeta } from '@/redux/selectors/subscription'
import { actions as brandSetingsActions } from '@/redux/reducers/branding'
import { actions as subscriptionActions } from '@/redux/reducers/subscription'
import MultiWorkspace from '@/pages/MultiWorkspace'
import VideoContext from '@/pages/Meeting/context'
import * as Linking from "expo-linking"
import { actions as authActions } from '@/redux/reducers/auth'
import MobileWebBrowser from '@/pages/onboarding/mobileWebBrowserScreen/mobileWebBrowserScreen';
import Paywall from '@/pages/Paywall';
import * as Device from 'expo-device';
import LayoutProvider from '@/pages/SharedFolders/Context/LayourProvider';
import SideBarProvider from '@/contexts/SidebarContext/SideBarContext';

const Stack = createStackNavigator()

export default function AppRoutes() {
  const screenSize = useScreenSize()
  const authToken = useSelector(getAuthToken)
  const first_access = useSelector(getAuthFirstAccess)
  const isLoggedIn = !!authToken && !first_access
  const user = useSelector(getUser)
  const member = useSelector(getMember)
  const workspace = useSelector(getWorkspace)
  const hasMultipleWorkspaces = useSelector(getHasMultipleWorkspaces)
  const selectedWorkspace = useSelector(getSelectedWorkspace);

  const subscriptionMeta = useSelector(getSubscriptionMeta)

  const [paywall, setPaywall] = useState(false);
  const [participantsInfo, setParticipantsInfo] = useState(new Map());

  const dispatch = useDispatch();

  useEffect(() => {
    if (Platform.OS === 'web') {
      Linking.addEventListener("url", loginWithQueryParams);
    }
    return () => {
      Linking.removeEventListener("url");
    }
  }, []);

  // Login user from a URL
  const loginWithQueryParams = (event) => {
    const { queryParams } = Linking.parse(event.url);
    // If URL have query params
    if (Object.keys(queryParams).length !== 0) {
      const { email, password, workspace_id } = queryParams;
      let data = {
        email,
        remember: 'checked',
        password,
        workspace_id
      };
      dispatch(
        authActions.login(data, (error) => {
          if (error) {
            //
          } else {
            dispatch(authActions.selectedWorkspace({}));
          }
        },
        ),
      )
    }
  };

  const addParticipant = (id, data) => {
    setParticipantsInfo(new Map([...participantsInfo, [id, data]]));
  }

  const getParticipant = (id) => {
    const user = participantsInfo.get(id);
    return user;
  }

  // const isTutorialDone = false;


  React.useEffect(() => {
    const logoutIfStateInvalid = async () => {
      if (!user?.id || !member?.id || !workspace?.id) {
        console.log('State Is Invalid');
        console.log(user?.id);
        console.log(member?.id);
        console.log(workspace?.id);

        dispatch(authActions.logout());
      } else {
        console.log('State Is Valid');
      }
    }

    const getBrandingSettings = async () => {
      try {
        const response = await new ApiHandler().getBrandingSettings()
        if (response?.data) {
          const settings = response?.data?.data || {}
          dispatch(brandSetingsActions.updateSettings(settings));
        }
      } catch (error) {
        console.log("error", error)
      } finally {

      }
    }

    const getSubscriptionMeta = async () => {
      try {
        const response = await new ApiHandler().getSubscriptionMeta()
        if (response?.data) {
          const meta = response?.data?.data || {}
          dispatch(subscriptionActions.updateMeta(meta));
        }
      } catch (error) {
        console.log("error", error)
      } finally {

      }
    }

    if (isLoggedIn) {
      logoutIfStateInvalid();
      getBrandingSettings();
      setPaywall(false)
      getSubscriptionMeta();
    }
  }, [isLoggedIn])

  React.useEffect(() => {
    const getSubscriptionMeta = async () => {
      try {
        const response = await new ApiHandler().getSubscriptionMeta()
        if (response?.data) {
          const meta = response?.data?.data || {}
          dispatch(subscriptionActions.updateMeta(meta));
        }
      } catch (error) {
        console.log("error", error)
      } finally {

      }
    }

    if (isLoggedIn) {
      setPaywall(false)
      getSubscriptionMeta();
    }
  }, [workspace])

  React.useEffect(() => {
    if (isLoggedIn) {
      setPaywall(subscriptionMeta?.paywall);
    }
  }, [subscriptionMeta])

  const detectedWebMobileDevice = (Platform.OS === 'web' && (Device.osName === "Android" || Device.osName === "iOS"))
  if (detectedWebMobileDevice) {
    return (
      <Stack.Navigator headerMode="none" initialRouteName={'MobileWebBrowser'}>
        <>
          <Stack.Screen name="MobileWebBrowser" component={MobileWebBrowser} />
        </>
      </Stack.Navigator>

    )
  }

  const mainStack = () => {
    return (
      <Stack.Navigator headerMode="none">
        <Stack.Screen name="Main">
          {(props) => (
            <SideBarProvider>
              <SideDrawerContextProvider>
                <VideoContext.Provider value={{ participantsInfo, addParticipant, getParticipant }}>
                  <LayoutProvider>
                    {isMobile ? (
                      <MainTabNavigator {...props} />
                    ) : (
                      <MainStackNavigator {...props} />
                    )}
                  </LayoutProvider>
                </VideoContext.Provider>
              </SideDrawerContextProvider>
            </SideBarProvider>
          )}
        </Stack.Screen>
      </Stack.Navigator>
    )
  }

  const workspacesStack = () => {
    return (
      <Stack.Navigator headerMode="none">
        <Stack.Screen name="MultiWorkspace" component={MultiWorkspace} />
      </Stack.Navigator>
    )
  }

  const onboardingStack = () => {
    return (
      <Stack.Navigator headerMode="none">
        <Stack.Screen name="Onboarding" component={OnboardingNavigator} />
      </Stack.Navigator>
    )
  }

  const paywallStack = () => {
    return (
      <Stack.Navigator headerMode="none" itialRouteName={'Paywall'}>
        <Stack.Screen name="Paywall" component={Paywall} />
      </Stack.Navigator>
    )
  }

  if (isLoggedIn) {
    if (hasMultipleWorkspaces && selectedWorkspace === false) {
      return workspacesStack()
    } else {
      if (paywall) {
        return paywallStack()
      } else {
        return mainStack()
      }
    }
  } else {
    return onboardingStack()
  }
}