import React from 'react';
import { TouchableOpacity, Text, StyleSheet } from 'react-native';

const Button = (props) => {
    const { label = '', onPress = () => {}, containerStyle = {} } = props || {};

    return (
        <TouchableOpacity onPress={onPress} style={[styles.button, containerStyle]}>
            <Text style={styles.label}>{label}</Text>
        </TouchableOpacity>
    );
};

const styles = StyleSheet.create({
    button: {
        height: 44,
        backgroundColor: "#FB6758",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
        marginTop: 30
    },
    label: {
        color: "#fff",
		fontSize: 18,
		lineHeight: 35,
		fontWeight: "500",
		letterSpacing: -0.8,
    }
});

export default Button;