import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'
import themes from '@/styles/themes/black'
import { shadow1 } from '@/styles/shadows'
import { Headline6Strong, Paragraph2, Subtitle2, Paragraph1 } from '@/styles/fonts'
import { TouchableOpacity } from 'react-native'

export const styles = StyleSheet.create({
	gradiente: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 67,
		zIndex: 1,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	}
})

export const Container = styled.TouchableOpacity`
	position: relative;
	height: ${({ isMobile }) => isMobile ? 344 : 350}px;
	width:  ${({ isMobile }) => isMobile ? 260 : 342}px;
	align-self: center;
	align-items: center;
	margin-left: ${({ isMobile }) => isMobile ? 10 : 8}px;
	margin-right: ${({ isMobile }) => isMobile ? 10 : 8}px;
	margin-top: ${({ isMobile }) => isMobile ? 16 : 20}px;
	margin-bottom: ${({ isMobile }) => isMobile ? 30 : 20}px;
	border-radius: 5px;
	border:1px solid ${themes.basics[300]};
	background-color: ${themes.basics.background_white};
	${shadow1}
`

export const CoverContainer = styled.View`
	width: 100%;
	position: relative;
	aspect-ratio: 2;
`
export const Gradiente = styled.View`
`

export const Description = styled(Paragraph1)`
	margin: 8px 20px 12px;
	text-align: left;
	color: ${({ theme }) => theme.basics[500]};
	flex: 1;
	align-self: flex-start;
`

export const SaveButton = styled(TouchableOpacity)`
	z-index: 1;
	position: absolute;
	right: 16px;
	top: 16px;
	width: 20px;
	height: 20px;
	align-items: center;
	justify-content: center;
`

export const Image = styled.Image`
	width: 100%;
	height: 100%;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
`

export const Header = styled.View`
	justify-content: center;
	align-items: flex-start;
	align-self: flex-start;
	margin-left: ${({ isMobile }) => isMobile ? 16 : 20}px;
	margin-right: ${({ isMobile }) => isMobile ? 16 : 20}px;
	margin-top: 8px;
`

export const SubHeader = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 8px 0 0;
`

export const Label = styled(Subtitle2)`
	align-items: center;
	color: ${themes.primary.standard};
`

export const Title = styled(Headline6Strong)`
	align-items: center;
	color: ${themes.basics[800]};
	margin-top: 8px;
`

export const SubTitle = styled(Paragraph2)`
	align-items: center;
	color: ${themes.basics[500]};
`
