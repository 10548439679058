import { useNavigation } from '@react-navigation/native'
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Label, Container, IconContainer } from './styles'
import { useSideDrawer } from '@/hooks/sideDrawer'
import Icon from '@/components/Icon'
import theme from '@/styles/themes/black'

export default function DrawerItem({
  icon,
  label,
  onPress,
  iconSize,
  selected,
  destinationRoute,
}) {
  const navigation = useNavigation()
  const { setOpen } = useSideDrawer()

  const handlePress = useCallback(() => {
    setOpen(false)

    if (destinationRoute) {
      navigation.navigate(destinationRoute)
    }
    if (onPress) onPress()
  }, [setOpen, onPress, navigation, destinationRoute])

  return (
    <Container onPress={handlePress}>
      <Label selected={selected}>{label}</Label>
      <IconContainer>
        {icon && (
          <Icon
            icon={icon}
            width={iconSize || 20}
            color={selected ? theme.primary.standard : theme.basics[900]}
          />
        )}
      </IconContainer>
    </Container>
  )
}

DrawerItem.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.number,

  onPress: PropTypes.func,
  selected: PropTypes.bool,
  label: PropTypes.string.isRequired,
  destinationRoute: PropTypes.string,
}
