import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	Image,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	TextInput,
	FlatList,
	Dimensions,
	CheckBox,
	Platform,
	ActivityIndicator,
} from "react-native";
import { isMobile } from "@/styles";
import Modal from "react-native-modal";
import ApiHandler from "@/api/ApiHandler";
import { Ionicons } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import { RadioButton } from "react-native-paper";
import BottomSheet from "./BottomSheet";
import Button from "./Button";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import { TrendingUpRounded } from "@material-ui/icons";
import ModalV2 from '@/components/ModalV2'

const deviceHeight = Dimensions.get("window").height;

const CreateMilestoneModal = ({
	isMilestoneModalVisible,
	toggleRightModal,
	selectedMilestoneId,
	update,
}) => {
	const [name, setName] = useState();
	const [description, setDescription] = useState();
	const [engagementPoints, setEngagementPoints] = useState();
	const [maturityPoints, setMaturityPoints] = useState();
	const [rewardPoints, setRewardPoints] = useState();
	const [workSpaceId, setWorkSpaceId] = useState([1]);
	const [checkRadioButton, setCheckRadioButton] = useState(reward);
	const [milestones, setMilestones] = useState([]);
	const [reward, setReward] = useState([]);
	const [selectedMilestone, setSelectedMilestone] = useState([]);
	const [rewardId, setRewardId] = useState();
	const [loading, setLoading] = useState(false);

	const createMilestones = async () => {
		try {
			const data = {
				name: name,
				description: description,
				reward_id: rewardId,
				engagement_points: parseInt(engagementPoints),
				cultural_maturity_points: parseInt(maturityPoints),
				total_points: parseInt(rewardPoints),
			};
			console.log();
			const response = await new ApiHandler().createMilestones(data);
			console.log("createMilestoneResponse......", response?.data);
			if (response?.data) {
				Toast.show({
					type: "success",
					position: "bottom",
					text1: "Milestone Created Successfully",
				});
			}
		} catch (error) {
			console.log("error", error);
			Toast.show({
				type: "error",
				position: "bottom",
				text1: error,
			});
		} finally {
		}
	};
	const updateMilestone = async () => {
		try {
			const data = {
				name: name,
				description: description,
				reward_id: rewardId,
				engagement_points: parseInt(engagementPoints),
				cultural_maturity_points: parseInt(maturityPoints),
				total_points: parseInt(rewardPoints),
			};
			console.log("Data Response Payload **********", data);
			const response = await new ApiHandler().updateMilestones(
				selectedMilestoneId,
				data
			);
			console.log("updateMilestoneResponse......", response?.data);
			if (response?.data) {
				Toast.show({
					type: "success",
					position: "bottom",
					text1: "Milestone Updated Successfully",
				});
			}
		} catch (error) {
			console.log("error", error);
			Toast.show({
				type: "error",
				position: "bottom",
				text1: error,
			});
		} finally {
		}
	};

	const getRewards = async () => {
		setLoading(true);
		try {
			let queryParams = "";
			queryParams = `workspace_id=${workSpaceId}`;
			const response = await new ApiHandler().getRewards(queryParams);
			// console.log(">>>>>>>>>GetRewards>>>>>>>>>", response?.data?.data?.data);
			if (response?.data) {
				setReward(response?.data?.data?.data);
				setLoading(false);
			}
		} catch (error) {
			console.log("ERROR", error);
			setLoading(false);
		} finally {
		}
	};

	const getMilestoneById = async () => {
		try {
			const response = await new ApiHandler().getMilestoneById(
				selectedMilestoneId
			);
			console.log("Milestone by id.......", response?.data);
			if (response?.data) {
				setSelectedMilestone(response?.data?.data);
				setRewardId(response?.data?.data?.reward?.id);
				setName(response?.data?.data?.name.toString());
				setDescription(response?.data?.data?.description?.toString());
				setEngagementPoints(response?.data?.data?.engagement_points?.toString());
				setMaturityPoints(
					response?.data?.data?.cultural_maturity_points?.toString()
				);
				setRewardPoints(response?.data?.data?.total_points?.toString());
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};
	useEffect(() => {
		getRewards();
		// if (selectedMilestoneId) {
		getMilestoneById();
		// }
	}, []);
	return Platform.OS == "web" ? (
		<ModalV2
			isVisible={isMilestoneModalVisible}
			setVisibility={toggleRightModal}
			title={update ? "Update Milestone" : "Create Milestone"}
		>
			<>
				<View style={styles.dropDownView}>
					<View style={{ marginTop: 20 }}>
						<Text style={styles.rightSideModalPointsTitle}>
							{"Name"}
						</Text>

						<TextInput
							value={name}
							//keyboardType="text"
							onChangeText={(text) => setName(text)}
							style={styles.inputField}
						/>
					</View>

					<View style={{ marginTop: 20 }}>
						<Text style={styles.rightSideModalPointsTitle}>
							{"Description"}
						</Text>

						<TextInput
							placeholder="Provide a description of this milestone."
							placeholderTextColor="#989898"
							multiline
							numberOfLines={5}
							value={description}
							//keyboardType="text"
							onChangeText={(text) => setDescription(text)}
							style={styles.inputField}
						/>
					</View>

					<View style={{ marginTop: 20 }}>
						<Text style={styles.rightSideModalPointsTitle}>
							{"Engagement Points"}
						</Text>

						<TextInput
							value={engagementPoints}
							keyboardType="numeric"
							onChangeText={(text) => setEngagementPoints(text)}
							style={styles.inputField}
						/>
					</View>
					<View style={{ marginTop: 20 }}>
						<Text style={styles.rightSideModalPointsTitle}>
							{"Cultural Maturity Points"}
						</Text>
						<TextInput
							value={maturityPoints}
							keyboardType="numeric"
							onChangeText={(text) => setMaturityPoints(text)}
							style={styles.inputField}
						/>
					</View>
					<View style={{ marginTop: 20 }}>
						<Text style={styles.rightSideModalPointsTitle}>
							{"Total Reward Points"}
						</Text>
						<TextInput
							value={rewardPoints}
							keyboardType="numeric"
							onChangeText={(text) => setRewardPoints(text)}
							style={styles.inputField}
						/>
					</View>
				</View>
				<View style={styles.selectHeader}>
					<Text style={styles.rightSideModalPointsTitle}>
						{"Select Reward"}
					</Text>
					{/* <Text style={styles.selectReward}>{"Create a Reward"}</Text> */}
				</View>
				<View style={{ height: 350, marginVertical: 10 }}>
					{loading ? (
						<ActivityIndicator
							size={30}
							animating
							color="red"
							style={{ marginRight: 10 }}
						/>
					) : null}
					<FlatList
						indicatorStyle={{ backgroundColor: "red" }}
						data={reward}
						renderItem={({ item, index }) => {
							return (
								<View style={{ flex: 1 }}>
									<View style={styles.selectedContainer}>
										<View style={styles.selectedCardView}>
											<View style={styles.selectedCardHeader}>
												<RadioButton
													color={"#F05343"}
													style={{
														alignSelf: "center",
														marginEnd: 5,
													}}
													status={
														checkRadioButton == index
															? "checked"
															: "unchecked"
													}
													onPress={() => {
														setCheckRadioButton(index);
														console.log("Reward ID *****", item);
														setRewardId(item?.id);
													}}
													value={item.value}
												/>

												<Image source={item.image} style={styles.cardImg} />
												<Text style={styles.selectedCardTitle}>
													{item.title}
												</Text>
											</View>
											<Text style={styles.criteriaTxt}>
												{"See Criteria"}
											</Text>
										</View>
										<View style={{ paddingHorizontal: 20 }}>
											<Text style={styles.selectedCardDes}>
												{item.description}
											</Text>
											<View style={styles.cardBottom}>
												<Text style={styles.awardTxt}>{item.awarded}</Text>
												<Text style={styles.ipmTxt}>{item.type}</Text>
											</View>
										</View>
									</View>
								</View>
							);
						}}
					/>
				</View>
				<TouchableOpacity
					style={styles.rightModalBtn}
					onPress={() => {
						{
							update == true
								? (updateMilestone(), toggleRightModal())
								: (createMilestones(), toggleRightModal());
						}
					}}
				>
					<Text style={styles.rightModalBtnText}>
						{update ? "Update" : "Create"}
					</Text>
				</TouchableOpacity>
			</>
		</ModalV2>
	) : (
		<ModalV2
			isVisible={isMilestoneModalVisible}
			setVisibility={toggleRightModal}
			title={update ? "Update Milestone" : "Create Milestone"}
		>
			<>
				<View style={styles.dropDownView}>
					<Text style={styles.modalInputLabel}>{"Name"}</Text>
					<TextInput
						style={styles.modalTextInput}
						placeholder=""
						placeholderTextColor="#989898"
						value={name}
						onChangeText={(text) => {
							setName(text);
						}}
					//keyboardType="number-pad"
					/>

					<Text style={styles.modalInputLabel}>{"Description"}</Text>
					<TextInput
						style={styles.modalTextInput}
						placeholder="Provide a description of this milestone."
						placeholderTextColor="#989898"
						multiline
						numberOfLines={5}
						value={description}
						onChangeText={(text) => {
							setDescription(text);
						}}
					//keyboardType="number-pad"
					/>

					<Text style={styles.modalInputLabel}>{"Engagement Points"}</Text>
					<TextInput
						style={styles.modalTextInput}
						placeholder="400"
						placeholderTextColor="#989898"
						value={engagementPoints}
						onChangeText={(text) => {
							setEngagementPoints(text);
						}}
						keyboardType="number-pad"
					/>
					<Text style={styles.modalInputLabel}>
						{"Cultural Maturity Points"}
					</Text>
					<TextInput
						style={styles.modalTextInput}
						placeholder="400"
						placeholderTextColor="#989898"
						value={maturityPoints}
						onChangeText={(text) => {
							setMaturityPoints(text);
						}}
						keyboardType="number-pad"
					/>
					<Text style={styles.modalInputLabel}>{"Total Reward Points"}</Text>
					<TextInput
						style={styles.modalTextInput}
						placeholder="400"
						placeholderTextColor="#989898"
						value={rewardPoints}
						onChangeText={(text) => {
							setRewardPoints(text);
						}}
						keyboardType="number-pad"
					/>
				</View>
				<View style={styles.selectHeader}>
					<Text style={styles.modalInputLabel}>{"Select Reward"}</Text>
				</View>
				<View style={{ height: 350, marginVertical: 10 }}>
					<FlatList
						data={reward}
						renderItem={({ item, index }) => {
							return (
								<View style={{ flex: 1 }}>
									<View style={styles.selectedContainer}>
										<View style={styles.selectedCardView}>
											<View style={styles.selectedCardHeader}>
												<RadioButtonGroup
													containerStyle={{ marginBottom: 40 }}
													selected={item.id == rewardId}
													onSelected={(value) => {
														setCheckRadioButton(item.id);
														setRewardId(item?.id);
													}}
													radioBackground={
														item.id == rewardId ? "#FB6758" : "#F5F5F5"
													}
												>
													<RadioButtonItem value="" label="" />
												</RadioButtonGroup>
												<Image
													source={{ uri: item.image }}
													style={styles.cardImg}
												/>
												<Text style={styles.selectedCardTitle}>
													{item.title}
												</Text>
											</View>
										</View>
										<View style={{ paddingHorizontal: 20 }}>
											<Text style={styles.selectedCardDes}>
												{item.description}
											</Text>
											<View style={styles.cardBottom}>
												<Text style={styles.awardTxt}>{item.awarded}</Text>
												<Text style={styles.ipmTxt}>{item.type}</Text>
											</View>
										</View>
									</View>
								</View>
							);
						}}
						keyExtractor={(item) => item.id}
					/>
				</View>
				<View style={styles.modalCreateBtn}>
					<Button
						label={update ? "Update" : "Create"}
						onPress={() => {
							{
								update == true
									? (updateMilestone(), toggleRightModal())
									: (createMilestones(), toggleRightModal());
							}
						}}
					/>
				</View>
			</>
		</ModalV2>
	);
};
export default CreateMilestoneModal;

const styles = StyleSheet.create({
	closeBtn: {
		position: "absolute",
		right: 8,
		top: 8,
	},
	headerTitle: {
		fontSize: 32,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	modalHeaderView: {
		flex: 0.15,
		flexDirection: "row",
		alignItems: "center",
	},
	modalProfilePic: {
		height: 22,
		width: 35,
		borderRadius: 4,
	},
	modalTitle: {
		marginLeft: 5,
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	importedBtn: {
		backgroundColor: "#FFCF70",
		borderRadius: 4,
		height: 16,
		width: 45,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 15,
	},
	importedBtnTxt: {
		fontSize: 8,
		fontWeight: "500",
		lineHeight: 10,
	},
	photoView: {
		flexDirection: "row",
		alignItems: "center",
	},
	photo: {
		height: 50,
		width: 50,
		borderRadius: 33,
		marginEnd: 8,
	},

	imgCountModal: {
		fontSize: 15,
		lineHeight: 20,
		color: "#fff",
		fontWeight: "400",
		position: "absolute",
		top: 15,
		left: 10,
	},
	awardeesTxt: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
	},
	input: {
		borderBottomWidth: 1,
		borderBottomColor: "#959595",
		marginTop: 25,
		marginBottom: 10,
		height: 40,
		padding: 5,
	},
	searchIcon: {
		position: "absolute",
		right: 20,
		bottom: 20,
	},
	descriptionView: {
		flex: 0.45,
		paddingVertical: 10,
	},
	selectCheck: {
		flexDirection: "row",
		marginVertical: 8,
		alignItems: "center",
	},
	memberName: {
		fontSize: 15,
		lineHeight: 20,
		fontWeight: "400",
	},
	memberPost: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#B5B5B5",
	},
	editBtn: {
		backgroundColor: "#000",
		height: 40,
		width: 133,
		borderRadius: 3,
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#000",
		borderWidth: 1,
	},
	cancelBtn: {
		marginEnd: 10,
		backgroundColor: "#fff",
	},
	editBtnTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	milestoneContainer: {
		flex: 1,
		marginHorizontal: 16,
	},
	modalCloseBtn: {
		alignSelf: "flex-end",
		marginTop: 20,
	},
	milestoneModalTitle: {
		fontSize: 18,
		lineHeight: 20,
		fontFamily: "WorkSans_400Regular",
		letterSpacing: -0.8,
		color: "#000000",
	},
	milestoneModalDescription: {
		fontSize: 12,
		lineHeight: 16,
		fontFamily: "WorkSans_400Regular",
		color: "#141414",
		paddingTop: 8,
		paddingBottom: 16,
	},
	modalInputLabel: {
		fontSize: 12,
		lineHeight: 16,
		fontFamily: "WorkSans_400Regular",
		color: "#000000",
		marginBottom: 7,
	},
	modalTextInput: {
		height: 40,
		backgroundColor: "#F9F9F9",
		borderRadius: 8,
		paddingLeft: 15,
		marginBottom: 16,
	},
	modalCreateBtn: {
		marginBottom: 30,
	},
	milestoneModalHeader: {
		flex: 1,
		paddingHorizontal: 25,
	},
	rightSideModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rightSideModalDec: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
	},
	dropDownView: {
		marginTop: 20,
		paddingVertical: 15,
	},
	rightSideModalPointsTitle: {
		fontSize: 18,
		lineHeight: 21.09,
		fontWeight: "400",
	},
	selectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	selectReward: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
		color: "#FB6758",
	},
	selectedContainer: {
		backgroundColor: "#E8E8E8",
		height: 117,
		// width: "35,",
		borderRadius: 4,
		paddingHorizontal: 12,
		marginVertical: 5,
		paddingVertical: 5,
	},
	selectedCardView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	selectedCardHeader: {
		flexDirection: "row",
		alignItems: "center",
	},
	radioBtn: {
		height: 12,
		width: 12,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#000",
		marginEnd: 5,
	},
	cardImg: {
		height: 22,
		width: 35,
		borderRadius: 3,
		marginEnd: 5,
	},
	selectedCardTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	criteriaTxt: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#FB6758",
	},
	selectedCardDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#000",
		marginVertical: 5,
	},
	cardBottom: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 5,
	},
	awardTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#00B15C",
	},
	ipmTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#000",
	},
	rightModalBtn: {
		backgroundColor: "#FB6758",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		height: 40,
		marginVertical: 10,
	},
	rightModalBtnText: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	rightSideModal: {
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderBottomLeftRadius: 20,
		alignSelf: "flex-end",
		paddingVertical: 15,
		margin: 0,
		width: "40%",
		// height: "100%",
	},
	closeBtn: {
		position: "absolute",
		right: 8,
		top: 8,
	},
	rewardModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rewardModalDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#141414",
	},
	modal: {
		height: "70%",
		width: "55%",
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 20,
		paddingVertical: 15,
	},
	rewardTabView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	rewardTab: {
		justifyContent: "center",
		alignItems: "center",
		borderBottomWidth: 2,
		paddingVertical: 5,
		width: "48%",
		flex: 0.5,
		marginHorizontal: 2,
		marginVertical: 20,
	},
	rewardBtnTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "400",
		color: "#141414",
	},
	imgContainer: {
		flex: 1,
		justifyContent: "space-between",
		flexDirection: "row",
		marginVertical: 10,
	},
	titleText: {
		fontSize: 18,
		lineHeight: 21,
		fontWeight: "400",
		color: "#141414",
		marginVertical: 5,
	},
	giftCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		paddingHorizontal: 10,
	},

	giftImgCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		borderColor: "#FB6758",
		borderWidth: 1,
		borderStyle: "dashed",
	},
	giftCardTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
	},
	imgSizeTxt: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: "500",
		color: "gray",
	},

	rewardTxtInput: {
		padding: 10,
		height: 100,
		marginVertical: 10,
		backgroundColor: "#F9F9F9",
	},
	importView: {
		flex: 1,
		alignItems: "center",
		alignSelf: "center",
	},

	rewardModalSelectionList: { height: 138, width: "98%", marginEnd: 5 },

	bottomView: {
		justifyContent: "space-between",
		flexDirection: "row",
		// marginTop: 150,
		position: "absolute",
		bottom: 5,
		right: 20,
		left: 20,
	},
	saveCreateTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		color: "#FB6758",
	},
	btnAlignment: {
		alignItems: "center",
	},
	rewardModalBtn: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#FB6758",
		height: 40,
		width: 180,
		borderRadius: 4,
	},
	createTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	workspaceBtn: {
		backgroundColor: "#FB6758",
		height: 40,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		position: "absolute",
		width: 227,
		alignSelf: "flex-end",
		bottom: 5,
		right: 20,
	},

	container: {
		padding: 15,
		flex: 1,
	},
	rewardTitle: {
		fontSize: 36,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	subTitleView: {
		flexDirection: "row",
	},
	activityTxt: {
		fontSize: 24,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	monthView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 20,
		marginTop: 10,
		marginEnd: 8,
	},
	monthTxt: {
		fontSize: 14,
		lineHeight: 18.3,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginEnd: 5,
	},
	cardView: {
		flexDirection: "row",
	},
	topPerformersView: { flex: 0.4, paddingLeft: 15 },

	lastMonthTxt: {
		color: "#909090",
		fontSize: 14,
		lineHeight: 18.23,
		fontWeight: "400",
		marginTop: 40,
	},
	headerTitleBtnView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 30,
		marginBottom: 10,
		justifyContent: "space-between",
	},
	titleView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},

	Button: {
		backgroundColor: "#FB6758",
		paddingHorizontal: 50,
		paddingVertical: 1.2,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
	},

	BtnText: {
		color: "#fff",
		fontSize: 18,
		lineHeight: 35,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	btnView: {
		flex: 0.1,
		flexDirection: "row",
		alignSelf: "flex-end",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	calenderView: {
		width: !isMobile ? 410 : null,
		height: !isMobile ? 390 : null,
		borderRadius: 10,
		backgroundColor: "#FFFFFF",
		margin: 10,
		marginTop: 10,
		paddingVertical: 10,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	dropDownModal: {
		backgroundColor: "#fff",
		width: 167,
		height: 183,
		borderRadius: 10,
		marginTop: "10%",
		marginLeft: "8%",
		paddingHorizontal: 16,
		paddingVertical: 20,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		position: "absolute",
		top: -20,
		left: 10,
	},
	dropDownModalContainer: {
		flex: 1,
		flexDirection: "column",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	dropDown: {
		height: 187,
		width: 167,
		backgroundColor: "#fff",
		zIndex: 999,
		position: "absolute",
		top: 35,
		left: 20,
		borderRadius: 8,
		paddingVertical: 20,
		paddingHorizontal: 16,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	inputField: {
		height: 40,
		width: "100%",
		backgroundColor: "#F9F9F9",
		borderRadius: 8,
		marginTop: 5,
		fontSize: 16,
		paddingHorizontal: 10,
	},
});
