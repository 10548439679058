import React, { useEffect } from 'react'
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
  AppState
} from 'react-native'
import * as Linking from 'expo-linking';
import Toast from 'react-native-toast-message'

import { useSelector } from 'react-redux'
import { getMember } from '@/redux/selectors/auth'

import ApiHandler from '@/api/ApiHandler';
import { Loader } from '@/components/Loader';

import getEnv from "../../../../environment";
const { backendBaseURL } = getEnv();


const googleCal = require("@/assets/calendar-google.png")
const outlookCal = require("@/assets/calendar-outlook.png")
const googleIcon = require("@/assets/google-icon.png")
const microIcon = require("@/assets/microsoft-icon.png")


const { width } = Dimensions.get('window')

const Integrations = () => {

  const user = useSelector(getMember)
  const currentUserId = user?.id || -1;

  const [googleStatus, setGoogleStatus] = React.useState({});
  const [outlookStatus, setOutlookStatus] = React.useState({});

  const [loading, setLoading] = React.useState(true);

  // npx uri-scheme open exp://192.168.43.193:19000 --android
  // npx uri-scheme open exp://127.0.0.1:19000 --ios

  useEffect(() => {
    fetchLinkedAccounts();
  }, [])

  useEffect(() => {
    let subscription;
    if (Platform.OS !== "web") {
      subscription = AppState.addEventListener("change", nextAppState => {
        if (nextAppState === "active") {
          fetchLinkedAccounts();
        }
      });
    }
    return () => {
      subscription?.remove?.();
    };
  }, []);

  const fetchLinkedAccounts = async () => {

    setLoading(true)

    try {
      const response = await new ApiHandler().getLinkedAccounts();

      const data = response?.data?.data;

      const _googleStatus = data?.find(item => {
        return item?.service == "google"
      })

      if (_googleStatus && _googleStatus?.status == 1) {
        setGoogleStatus(_googleStatus)
      } else {
        setGoogleStatus({})
      }

      const _outlookStatus = data?.find(item => {
        return item?.service == "outlook"
      })

      if (_outlookStatus && _outlookStatus?.status == 1) {
        setOutlookStatus(_outlookStatus)
      } else {
        setOutlookStatus({})
      }

    } catch (error) {
      console.log(">>>>>> ")
    } finally {
      setLoading(false)
    }


  }


  const calendarCard = (params) => {
    return (
      <View
        style={[styles.eventListItem, styles.shadows, params?.space && { marginRight: 16 }]}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }} >

          <Image style={styles.tinyLogo} source={params.icon} />
          <Text style={styles.cardTitle} >{params.title}</Text>

        </View>

        <Text style={styles.infoTxt} >{params.info}</Text>


        {
          params?.child
          &&
          params?.child?.()
        }

      </View>
    )
  }

  const GoogleBtn = () => {

    const platform = Platform.OS == "web" ? "web" : "mobile"

    const url = backendBaseURL + 'calendar/google/' + currentUserId + '/' + platform;

    return (
      <TouchableOpacity
        style={styles.googleBtn}
        onPress={() => {

          const openURL = async () => {
            const canOpen = await Linking.canOpenURL(url);
            if (canOpen) {
              Linking.openURL(url);
            }
          }
          openURL()

        }}
      >
        <View style={styles.googleBox} >
          <Image source={googleIcon} style={styles.googleIcon} />
        </View>
        <Text style={styles.googleLogin} >{"Sign in with Google"}</Text>

      </TouchableOpacity>
    )
  }
  const DisconnectGoogleBtn = () => {

    return (
      <TouchableOpacity
        style={styles.googleBtn}
        onPress={() => {
          removeAccount(googleStatus?.id)
        }}

      >
        <View style={styles.googleBox} >
          <Image source={googleIcon} style={styles.googleIcon} />
        </View>
        <Text style={styles.googleLogin} >{"Disconnect"}</Text>

      </TouchableOpacity >
    )
  }

  const MicrosoftBtn = () => {

    const platform = Platform.OS == "web" ? "web" : "mobile"

    const url = backendBaseURL + 'calendar/outlook/' + currentUserId + '/' + platform;
    return (
      <TouchableOpacity
        style={styles.microBtn}
        onPress={() => {

          const openURL = async () => {
            const canOpen = await Linking.canOpenURL(url);
            if (canOpen) {
              Linking.openURL(url);
            }
          }
          openURL()

        }}
      >
        <View style={styles.microBox} >
          <Image source={microIcon} style={styles.microIcon} />
        </View>
        <Text style={styles.microLogin} >{"Sign in with Microsoft"}</Text>

      </TouchableOpacity>
    )
  }
  const DisconnectMicrosoftBtn = () => {
    return (
      <TouchableOpacity
        style={styles.microBtn}
        onPress={() => {
          removeAccount(outlookStatus?.id)
        }}
      >
        <View style={styles.microBox} >
          <Image source={microIcon} style={styles.microIcon} />
        </View>
        <Text style={styles.microLogin} >{"Disconnect"}</Text>

      </TouchableOpacity>
    )
  }


  const removeAccount = async (accountId) => {
    setLoading(true)

    try {
      const response = await new ApiHandler().removeLinkedAccount(accountId);

      Toast.show({
        type: 'success',
        position: 'top',
        text1: response?.data?.message || "",
        visibilityTime: 2000
      })

      if (response?.data?.success) {

        fetchLinkedAccounts();
      }


    } catch (error) {

    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <View style={{ flex: 1, paddingLeft: 12 }}  >

        <View style={{ flexDirection: "row", flexWrap: "wrap" }} >
          {
            calendarCard(
              {
                icon: googleCal,
                title: "Google Calendar",
                info: "Keep your Google Calendar updated with all events on your Reframe Calendar.",
                child:
                  loading
                    ?
                    Loader
                    :
                    googleStatus?.id
                      ?
                      DisconnectGoogleBtn
                      :
                      GoogleBtn,
                space: true,
              }
            )
          }
          {
            calendarCard(
              {
                icon: outlookCal,
                title: "Outlook Calendar",
                info: "Keep your Outlook Calendar updated with all events on your Reframe Calendar.",
                child: loading
                  ?
                  Loader
                  :
                  outlookStatus?.id
                    ?
                    DisconnectMicrosoftBtn
                    :
                    MicrosoftBtn
              }
            )
          }

        </View>

      </View>
    </>
  )
}

export default Integrations

const styles = StyleSheet.create({

  tinyLogo: {
    width: 50,
    height: 50,
    marginRight: 20,
  },

  eventListItem: {

    borderRadius: 5,
    minHeight: 160,
    marginVertical: 12,
    backgroundColor: "#fff",
    padding: 20,
    maxWidth: 380,
    width: "100%"
  },

  shadows: {
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.15,
    shadowRadius: 10,
    backgroundColor: "#fff",
    borderRadius: 6,
  },

  cardTitle: {
    fontSize: 17,
    textAlign: "center",
    fontFamily: 'WorkSans_600SemiBold',
    color: "#5A5A5A",
  },

  infoTxt: {
    marginTop: 8,
    fontSize: 13,
    fontFamily: 'WorkSans_600SemiBold',
    color: "#5A5A5A",
  },

  googleBox: {
    width: 50,
    height: 50,
    marginRight: 20,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center"
  },
  googleIcon: {
    width: 30,
    height: 30,
  },
  googleBtn: {
    marginTop: 28,
    borderColor: "#2D91F9",
    borderWidth: 1,
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#2D91F9",
    paddingRight: 8,
    borderRadius: 1,
  },
  googleLogin: {
    flex: 1,
    fontSize: 14,
    // fontFamily: 'WorkSans_400Regular',
    fontFamily: 'WorkSans_600SemiBold',
    color: "#fff",
  },

  microBox: {
    width: 50,
    height: 50,
    marginRight: 20,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
  },
  microIcon: {
    width: 30,
    height: 30,
  },
  microBtn: {
    marginTop: 28,
    borderColor: "#a0a0a0",
    borderWidth: 1,
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    paddingRight: 8,
    borderRadius: 4,
  },
  microLogin: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'WorkSans_600SemiBold',
    color: "#000",
  }



});
