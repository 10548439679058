import React, { useState, useCallback, useEffect } from "react";
import {
  TextInput,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  View,
  Image,
  TouchableWithoutFeedback,
  KeyboardAwareScrollView,
  TouchableOpacity,
  Text,
  ScrollView,
  Dimensions
} from "react-native";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigation, useRoute } from '@react-navigation/native'
import { ActivityIndicator } from "react-native-paper";
import * as profileSelectors from "@/redux/selectors/profile";
import * as brandSelectors from "@/redux/selectors/branding";
import { actions } from "@/redux/reducers/posts";
import { actions as teamActions } from "@/redux/reducers/teams";
import { actions as groupActions } from "@/redux/reducers/groups";
import { getMember } from "@/redux/selectors/auth";
import {
  Icon,
  Avatar,
  Button,
  FeedCardAuthorInfo,
  SimpleModal,
} from "@/components";
import { CaptionMedium } from "@/styles/fonts";
import theme from "@/styles/themes/black";
import { isMobile } from "@/styles";
import {
  HeaderLabel,
  CloseButton,
  AuthorContainer,
  ContentContainer,
  PostInputWrapper,
  PostButtonWrapper,
  AuthorInfoContainer,
  KeyboardDismiss,
  HeaderContainer,
  styles,
  PostButtonWrapperAttachment,
} from "./styles";
import { PostInterface } from "@/interfaces/post";
import { Ionicons } from "@expo/vector-icons";
import { SubTitle, Title } from "@/components/Group/styles";
import { openGallery } from "@/utils/gallery";
import * as ImagePicker from "expo-image-picker";
import { ScreenSize, useScreenSize } from "@/styles";
import PlaceholderImageCover from "@/assets/cover_placeholder.png";
import * as S from "@/components/Learning/ArticleCard/styles";
import { Video } from "expo-av";
import ExpansiveMenu from "@/components/ExpansiveMenu";
import * as FileSystem from "expo-file-system";
const { height, width } = Dimensions.get("window");
import MentionsTextBox from "@/components/MentionsTextBox";
import { vw } from 'react-native-expo-viewport-units'
import ModalV2 from '@/components/ModalV2'

const PostComposer = ({
  onClose,
  attachmentType,
  attachmentId,
  post,
  isVisible,
  setVisible,
  file,
  fileType,
}) => {
  const dispatch = useDispatch();
  const route = useRoute();
  const navigation = useNavigation();
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState();
  const member = useSelector(getMember);
  const screenSize = useScreenSize();
  const isDesktop = screenSize > ScreenSize.Medium;
  const mobile = screenSize < ScreenSize.Medium;
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const [mediaType, setMediaType] = useState("");
  const [desktopMediaViewer, setDesktopMediaViewer] = useState(false);

  const branding = useSelector(brandSelectors.getSettings);
  const {
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding;

  const handleClose = useCallback(() => {
    setMediaType("");
    setMedia();
    setText("");
    setLoading(false);
    setVisible(false);

    console.log(attachmentType)

    if (attachmentType == 'App\\Team') {
      dispatch(teamActions.posts({ id: attachmentId }))
    } else if (attachmentType == 'App\\Group') {
      dispatch(groupActions.posts({ id: attachmentId }))
    } else {
      dispatch(
        actions.feeds_index({ page: 1 }, (err) => {
          console.log('err', err)
        })
      )
    }

    if (route.name == 'CommentPost') {
      navigation.setParams({ stamp: Math.random() })
    }
  }, []);

  useEffect(() => {
    if (post?.text && post?.text !== "") {
      setText(post?.text);
    }
  }, [post]);

  useEffect(() => {
    setMedia(file);
    setMediaType(fileType);
  }, [isVisible]);

  const handleSubmit = useCallback(
    async (fileBase64) => {
      setLoading(true);
      if (post?.id) {
        if (fileBase64.substring(0, 4) === "http") {
          dispatch(
            actions.update({ id: post.id, text }, () => {
              handleClose();
            })
          );
          return;
        } else {
          dispatch(
            actions.update({ id: post.id, text, file: fileBase64 }, () => {
              handleClose();
            })
          );
          return;
        }
      }
      dispatch(
        actions.store(
          { text, attachmentType, attachmentId, file: fileBase64 },
          () => {
            handleClose();
          }
        )
      );

    },
    [dispatch, text, handleClose]
  );

  async function handleBase64Submission() {
    const b64 = await ConvertToBase64(media);
    handleSubmit(b64);
  }

  const disabled = () => {
    return !text || text.length < 10;
  };

  async function openImage() {
    const imagePickerOptions = {
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
    };
    try {
      let imageUri = await openGallery(imagePickerOptions);
      setMediaType("image");
      if (
        !imageUri.substring(0, 10).includes("http") &&
        Platform.OS === "web"
      ) {
        imageUri = await imageUri.split(",")[1];
      }
      return imageUri;
    } catch (e) {
      console.log("Error opening gallery");
    }
  }

  async function openVideo() {
    const videoPickerOptions = {
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
    };
    try {
      let videoUri = await openGallery(videoPickerOptions);
      setMediaType("video");
      if (
        !videoUri.substring(0, 10).includes("http") &&
        Platform.OS === "web"
      ) {
        videoUri = await videoUri.split(",")[1];
      }
      return videoUri;
    } catch (e) {
      console.log("Error opening gallery");
    }
  }

  async function ConvertToBase64(file) {
    let b = "";
    if (isMobile) {
      try {
        b = await FileSystem.readAsStringAsync(file, { encoding: "base64" });
      } catch (error) {
        console.log("Error::", error);
      }
    } else {
      b = file;
    }
    return b;
  }

  async function handleVideo() {
    const uri = await openVideo();
    setMedia(uri);
    setMediaType("video");
  }

  async function handleImage() {
    const uri = await openImage();
    setMedia(uri);
    setMediaType("image");
  }

  const expansiveChildren = [
    { text: "Choose Image", icon: "images", onPress: handleImage },
    { text: "Choose Video", icon: "videoPlayButton", onPress: handleVideo },
  ];

  return (
    <ModalV2
      isVisible={isVisible}
      setVisibility={setVisible}
      title={post?.id ? 'Edit Post' : 'Write A Post'}
    >
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            paddingVertical: 15,
            zIndex: 999,
          }}
        >
          <AuthorContainer>
            <Avatar
              style={{ marginTop: 12 }}
              size={50}
              source={member?.avatar ? { uri: member?.avatar } : null}
            />
            <AuthorInfoContainer>
              <FeedCardAuthorInfo author={member} />
            </AuthorInfoContainer>
          </AuthorContainer>

          <PostButtonWrapperAttachment
            style={isDesktop && [{ marginTop: 0, }]}
          >
            <View style={isDesktop && [{ marginEnd: 22 }]}>
              <Title>Choose Attachment</Title>
              <SubTitle>Max 100mb</SubTitle>
            </View>
            <ExpansiveMenu
              mainIconColor="#000"
              mainIconBackground="transparent"
              expansiveChildrens={expansiveChildren}
            />
          </PostButtonWrapperAttachment>
        </View>
        <PostInputWrapper>
          <MentionsTextBox
            placeholder='Tip: You can include links in your post, and mention other members of your community by typing "@".'
            text={text}
            setText={setText}
          />
          {/* <TextInput
              multiline
              textAlignVertical="top"
              value={text}
              placeholder="Write a post"
              style={[styles.textInput]}
              onChangeText={setText}
            /> */}
        </PostInputWrapper>
        {mediaType === "image" && media ? (
          <View style={{
            width: '100%',
            marginTop: 15,
          }}>
            <Image
              style={{ width: "100%", aspectRatio: 1, borderRadius: 10 }}
              source={
                media
                  ? media?.substring(0, 4) == "http"
                    ? { uri: media }
                    : {
                      uri: isMobile
                        ? media
                        : "data:image/jpeg;base64," + media,
                    }
                  : PlaceholderImageCover
              }
              resizeMode="cover"
            />
          </View>
        ) : null}
        {mediaType === "video" && media ? (
          <View style={{
            width: '100%',
            maxHeight: 500,
            marginTop: 15
          }}>
            <Video
              ref={video}
              style={{ width: "100%", borderRadius: 10 }}
              source={
                media
                  ? media?.substring(0, 4) == "http"
                    ? { uri: media }
                    : {
                      uri:
                        Platform.OS == "web"
                          ? "data:video/mp4;base64," + media
                          : media,
                    }
                  : null
              }
              resizeMode={Video.RESIZE_MODE_COVER}
              useNativeControls
              onPlaybackStatusUpdate={(status) => setStatus(() => status)}
            />
          </View>
        ) : null}
        <PostButtonWrapper>
          <Button
            color={
              color_scheme_primary_button_background
                ? color_scheme_primary_button_background
                : "black"
            }
            onPress={handleBase64Submission}
            disabled={disabled() || loading}
            style={{
              width: isMobile ? "95%" : "auto",
              alignSelf: isMobile ? "flex-end" : "center",
            }}
          >
            {loading && (
              <ActivityIndicator
                size={20}
                animating
                color="white"
                style={{ marginRight: 10 }}
              />
            )}
            <CaptionMedium
              color={
                color_scheme_primary_button_text
                  ? color_scheme_primary_button_text
                  : "white"
              }
              style={{ fontSize: 17 }}
            >
              Publish
            </CaptionMedium>
          </Button>
        </PostButtonWrapper>
      </View>
    </ModalV2>
  )
};

PostComposer.propTypes = {
  post: PostInterface,
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  attachmentType: PropTypes.string,
  attachmentId: PropTypes.number,
};

export default PostComposer;
