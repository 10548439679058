import styled from 'styled-components/native'
import { shadow2 } from '@/styles/shadows'

export const Container = styled.View`
  height: 110px;
  padding: 0 13px;

  border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;

  ${shadow2}
  background-color: ${({ color, theme }) => color || theme.primary.standard};
`

export const ContentWrapper = styled.View`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  padding-left: 13px;

  display: flex;
  align-items: center;
  flex-direction: row;
`
