import React from 'react'
import { Platform } from 'react-native'
import { Input, InputWeb } from './styles'

const InputField = ({ width, ...props }) => Platform.OS == 'web' ? (
    <InputWeb {...props} />
) : (
    <Input width={width} {...props} />
)

export default InputField;