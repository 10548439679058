import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    CoverContainer: {
        position: 'relative',
        width: '100%',
        aspectRatio: 1.5,
        borderRadius: 10,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
        marginBottom: 10
    },
    CoverImage: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
    },
    DetailsContainer: {
    },
    DetailsName: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 18,
        marginBottom: 8
    },
    DetailsDescription: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 13
    },
    MembersContainer: {
        width: '100%',
        flexDirection: 'row',
        //justifyContent: 'space-around',
        alignItems: 'center'
    },
    FeedContainer: {
        width: '100%',
        flexDirection: 'column',
        maxWidth: 700,
        //marginHorizontal: 'auto',
    },
    MemberCardContainer: {
        flexDirection: 'row',
        marginBottom: 15,
        paddingRight: 15,
        alignItems: 'center'
    },
    MemberCardAvatarContainer: {
        marginRight: 15,
    },
    MemberCardDetailsContainer: {
    },
    MemberCardDetailsName: {
        fontSize: 13,
        fontWeight: '500'
    },
    MemberCardDetailsRole: {
        fontSize: 12
    },
    MemberCardRemoveContainer: {
        flex: 1,
        alignItems: 'flex-end'
    }
})