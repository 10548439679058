import styled from 'styled-components/native'
import themes from '@/styles/themes/black'
import { Headline6 } from '@/styles/fonts'

export const Container = styled.View`
	flex: 1;
	position: relative;
	background-color: ${themes.basics.background_button};
	padding: 15px 0 0 ${({ isMobile }) => isMobile ? 16 : 44}px;
`

export const LinkButton = styled.TouchableOpacity``

export const IconView = styled.View`
	margin: 0 8px;
`

export const FilterContainer = styled.View`
	flex-direction: row;
	align-self: flex-start;
	align-items: center;
	left: -8px;
`
export const TextFilter = styled.Text`
	font-size: 15px;
	font-family: 'WorkSans_400Regular';
	line-height: 20px;
	color:${themes.basics[900]};
`

export const NavigationContainer = styled.View`
	flex-direction: row;
	align-self: flex-start;
	align-items: center;
	margin: 20px 0 24px;
`

export const TextLink = styled.Text`
	font-size: 12px;
	font-family: 'WorkSans_400Regular';
	line-height: 16px;
	font-weight: normal;
	color: ${({ last_link }) => last_link ? themes.basics[900] : themes.basics[500]};
`

export const TitleHeader = styled(Headline6)`
	color: ${themes.basics.black_text};
	font-size: ${({ isMobile }) => isMobile ? 18 : 40}px;
	line-height: ${({ isMobile }) => isMobile ? 20 : 40}px;
	font-family: 'WorkSans_400Regular';
`


export const WrapperCollapse = styled.View`
	align-self: flex-end;
	margin: 0 44px 0 0;
`

export const Header = styled.View`
	position: relative;
	flex-direction: column;
	width: 100%;
	margin: ${({ isMobile }) => isMobile ? 20 : 40}px 0 0;
	padding-right: ${({ isMobile }) => isMobile ? 0 : 20}px;
`

export const ContainerCollapse = styled.View`
`

export const SectionHeader = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`

export const SectionTitle = styled.View`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: center
`

export const IconButton = styled.TouchableOpacity`
	margin: 0 10px 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	display: flex;
`

export const LeftIconButton = styled.TouchableOpacity`
	margin: 0 8px 0 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	position: relative;
	display: ${({ isMobile }) => isMobile ? 'flex' : 'none'};
`

export const HeadLine = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding-right:${({ isMobile }) => isMobile ? 15 : 56}px;
`

export const SubTitle = styled.Text`
	font-family: 'WorkSans_500Medium';
	font-size: ${({ isMobile }) => isMobile ? 18 : 24}px;
	line-height: ${({ isMobile }) => isMobile ? 20 : 27}px;
	color: ${themes.basics.black_text};
	padding: 0 0 8px;
`


export const TextButton = styled.Text`
  color: ${({ color, theme }) => color ? color : theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`
