import Send from '@/assets/common/Send'
import Close from '@/assets/common/Close'
import ArrowRight from '@/assets/common/ArrowRight'
import ArrowLeft from '@/assets/common/ArrowLeft'
import ChevronLeft from '@/assets/common/ChevronLeft'
import ChevronRight from '@/assets/common/ChevronRight'
import ChevronDown from '@/assets/common/ChevronDown'
import ChevronTop from '@/assets/common/ChevronTop'
import CheckOutline from '@/assets/common/CheckOutline'
import CloseOutline from '@/assets/common/CloseOutline'
import SearchIcon from '@/assets/common/SearchIcon'
import HorizontalKebab from '@/assets/svgs/HorizontalKebab'
import Pencil from '@/assets/svgs/Pencil'
import Like from '@/assets/svgs/Like'
import Comment from '@/assets/svgs/Comment'
import Star from '@/assets/svgs/Star'

import Home from '@/assets/drawer/Home'
import Profile from '@/assets/drawer/Profile'
import Rewards from '@/assets/drawer/Rewards'
import Calendar from '@/assets/drawer/Calendar'
import Settings from '@/assets/drawer/Settings'
import Ellipsis from '@/assets/drawer/Ellipsis'
import Analytics from '@/assets/drawer/Analytics'
import Community from '@/assets/drawer/Community'
import Education from '@/assets/drawer/Education'
import Assessments from '@/assets/drawer/Assessments'
import Notifications from '@/assets/drawer/Notifications'
import LocationPin from '@/assets/svgs/LocationPin'
import AlignLeft from '@/assets/svgs/AlignLeft'
import Copy from '@/assets/svgs/Copy'
import Video from '@/assets/svgs/Video'
import Refresh from '@/assets/svgs/Refresh'

import LoginDecoration from '@/assets/onboarding/LoginDecoration'
import SignupDecoration from '@/assets/onboarding/SignupDecoration'
import ForgotPasswordDecoration from '@/assets/onboarding/ForgotPasswordDecoration'

import CommunityIcon from '@/assets/svgs/CommunityIcon'
import GroupIcon from '@/assets/svgs/GroupIcon'
import EmailIcon from '@/assets/svgs/EmailIcon'
import ImportExportIcon from '@/assets/svgs/ImportExport'
import OpenedEyeIcon from '@/assets/svgs/OpenedEye'
import AddUserIcon from '@/assets/svgs/AddUser'
import DownloadIcon from '@/assets/svgs/Download'
import AddPhoto from '@/assets/svgs/AddPhoto'
import Leave from '@/assets/svgs/Leave'
import Workspace from '@/assets/svgs/Workspace'
import LogOut from '@/assets/svgs/LogOut'
import GroupMob from '@/assets/GroupMob'

import ReframeHorizontalLogo from '@/assets/brand/ReframeHorizontalLogo'
import ReframeRoundLogo from '@/assets/brand/ReframeRoundIcon'
import Delete from '@/assets/svgs/Delete'
import DrawerLogo from '@/assets/svgs/DrawerLogo'
import Help from '@/assets/svgs/Help'
import Play from '@/assets/svgs/Play'
import Import from '@/assets/svgs/Import'

import SharedFolderRight from '@/assets/drawer/SharedFolderRight'
import SharedFolderLeft from '@/assets/drawer/SharedFolderLeft'
import CreateFolder from '@/assets/drawer/CreateFolder'
import SharedFoldersWithMe from '@/assets/drawer/SharedFoldersWithMe'
import UploadFile from '@/assets/drawer/UploadFile'
import movedTo from '@/assets/drawer/movedTo'
import rename from '@/assets/drawer/rename'
import downloadIcon from '@/assets/drawer/downloadIcon'
import informationIcon from '@/assets/drawer/informationIcon'
import deleteIcon from '@/assets/drawer/deleteIcon'
import shareIcon from '@/assets/drawer/shareIcon'

import ArrowDown from '@/assets/svgs/ArrowDown'
import ArrowUp from '@/assets/svgs/ArrowUp'
import ListView from '@/assets/svgs/ListView'
import GridView from '@/assets/svgs/GridView'

import VideoPlayButton from '@/assets/svgs/VideoPlayButton'
import Images from '@/assets/svgs/Images'

export default {
  //brand
  reframeHorizontalLogo: ReframeHorizontalLogo,
  reframeRoundLogo: ReframeRoundLogo,
  draweLogo: DrawerLogo,

  // common
  send: Send,
  close: Close,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronDown: ChevronDown,
  chevronTop: ChevronTop,
  checkOutline: CheckOutline,
  closeOutline: CloseOutline,
  searchIcon: SearchIcon,
  horizontalKebab: HorizontalKebab,
  pencil: Pencil,
  star: Star,
  import: Import,

  // drawer
  home: Home,
  profile: Profile,
  rewards: Rewards,
  calendar: Calendar,
  ellipsis: Ellipsis,
  settings: Settings,
  analytics: Analytics,
  community: Community,
  education: Education,
  sharedfolderleft: SharedFolderLeft,
  createfolder: CreateFolder,
  sharedfolderwithme: SharedFoldersWithMe,
  uploadfile: UploadFile,
  movedto: movedTo,
  rename: rename,
  downloadicon: downloadIcon,
  informationicon: informationIcon,
  deleteicon: deleteIcon,
  shareicon: shareIcon,
  sharedfolderright: SharedFolderRight,
  assessments: Assessments,
  notifications: Notifications,
  logOut: LogOut,
  workspace: Workspace,
  help: Help,
  locationPin: LocationPin,
  alignLeft: AlignLeft,
  copy: Copy,
  video: Video,
  refresh: Refresh,
  groupMob: GroupMob,

  // onboarding
  loginDecoration: LoginDecoration,
  signupDecoration: SignupDecoration,
  forgotPasswordDecoration: ForgotPasswordDecoration,
  addPhoto: AddPhoto,

  // community page
  communityIcon: CommunityIcon,
  group: GroupIcon,
  leave: Leave,

  // employee page
  email: EmailIcon,
  importExport: ImportExportIcon,
  openedEye: OpenedEyeIcon,
  addUser: AddUserIcon,
  download: DownloadIcon,

  // post
  like: Like,
  comment: Comment,
  delete: Delete,

  // svgs
  play: Play,
  import: Import,
  arrowDown: ArrowDown,
  arrowUp: ArrowUp,
  listView: ListView,
  gridView: GridView,

  videoPlayButton: VideoPlayButton,
  images: Images
}
