const { createContext, useState } = require("react");

export const SideBarContext = createContext({
    sideBarHidden: false,
    setSidebarHidden: (value) => { }
})

const SideBarProvider = ({ children }) => {
    const [sideBarHidden, setSidebarHidden] = useState(false);

    return (
        <SideBarContext.Provider value={{ sideBarHidden, setSidebarHidden }}>
            {children}
        </SideBarContext.Provider>
    )
}

export default SideBarProvider;