const BellMuted = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.63086 10.7505C4.59968 11.0847 4.55717 11.4102 4.47455 11.6581C4.37106 11.9686 4.23216 12.2546 4.07835 12.5182C3.93917 12.7567 3.633 12.8372 3.39449 12.698C3.15599 12.5589 3.07547 12.2527 3.21465 12.0142C3.34218 11.7956 3.44889 11.5728 3.52586 11.3419C3.56824 11.2148 3.60386 10.9934 3.63518 10.6577C3.6563 10.4313 3.67356 10.1787 3.69273 9.89821C3.70135 9.77221 3.71034 9.64057 3.72025 9.50312C3.78299 8.63255 3.87896 7.57824 4.14107 6.56256C4.40271 5.54869 4.84047 4.52975 5.61743 3.76162C6.40811 2.97993 7.5077 2.5 9.00021 2.5C11.0382 2.5 12.314 3.39448 13.0851 4.65583C13.2291 4.89144 13.1549 5.19919 12.9193 5.34322C12.6837 5.48725 12.3759 5.41301 12.2319 5.17741C11.6375 4.2051 10.6804 3.5 9.00021 3.5C7.74271 3.5 6.9048 3.89507 6.32048 4.47276C5.72244 5.064 5.3477 5.88881 5.10934 6.81244C4.87146 7.73426 4.77992 8.7112 4.71766 9.575C4.70849 9.70224 4.69988 9.82822 4.69145 9.95149C4.67181 10.2388 4.65317 10.5113 4.63086 10.7505ZM13.6613 8.2013C13.9361 8.17391 14.1811 8.37446 14.2085 8.64924C14.2525 9.09118 14.2818 9.51954 14.3078 9.89944C14.3184 10.055 14.3285 10.2024 14.3388 10.3393C14.3765 10.8395 14.4161 11.1664 14.4745 11.3419C14.624 11.7904 14.8832 12.2024 15.1836 12.61C15.2834 12.7455 15.3953 12.8895 15.5092 13.036C15.7112 13.296 15.919 13.5634 16.0751 13.8035C16.3412 14.2128 16.306 14.6746 16.0451 15.0156C15.8041 15.3304 15.4077 15.5 15.0002 15.5H6.50044C6.22429 15.5 6.00044 15.2761 6.00044 15C6.00044 14.7239 6.22429 14.5 6.50044 14.5H15.0002C15.145 14.5 15.227 14.4391 15.251 14.4077C15.2557 14.4016 15.2577 14.3976 15.2584 14.3957C15.2577 14.3906 15.2541 14.3752 15.2367 14.3485C15.0989 14.1365 14.9419 13.9354 14.7637 13.7072C14.6449 13.555 14.5167 13.3907 14.3785 13.2032C14.0565 12.7662 13.7247 12.2546 13.5259 11.6581C13.4208 11.3428 13.377 10.8844 13.3416 10.4144C13.3304 10.265 13.3198 10.1102 13.3089 9.95024C13.2833 9.57614 13.2558 9.17418 13.2134 8.74843C13.186 8.47365 13.3865 8.22869 13.6613 8.2013Z"
      fill="#606B85"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 14.5H11V16C11 17.1046 10.1046 18 9.00001 18C7.89544 18 7.00001 17.1046 7.00001 16V14.5ZM8.00001 15.5V16C8.00001 16.5523 8.44773 17 9.00001 17C9.5523 17 10 16.5523 10 16V15.5H8.00001Z"
      fill="#606B85"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00001 2.5C7.00001 1.39543 7.89544 0.5 9.00001 0.5C10.1046 0.5 11 1.39543 11 2.5V3H10V2.5C10 1.94772 9.5523 1.5 9.00001 1.5C8.44773 1.5 8.00001 1.94772 8.00001 2.5V3H7.00001V2.5Z"
      fill="#606B85"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8831 3.67869C17.0606 3.89027 17.0329 4.20564 16.8213 4.3831L1.32132 17.3831C1.10974 17.5605 0.794368 17.5329 0.616916 17.3213C0.439463 17.1097 0.467128 16.7944 0.678706 16.6169L16.1787 3.6169C16.3903 3.43945 16.7057 3.46712 16.8831 3.67869Z"
      fill="#606B85"
    />
  </svg>
);

export default BellMuted;
