export const permissions = {
  CREATE_CUSTOM_USER_ROLE: "CreateCustomUserRole",
  EDIT_CUSTOM_USER_ROLE: "EditCustomUserRole",
  DELETE_CUSTOME_USER_ROLE: "DeleteCustomUserRole",
  MANAGE_CUSTOM_USER_ROLE: "ManageCustomUserRole",
  VIEW_ALL_EMPLOYEES: "ViewAllEmployees",
  INVITE_EMPLOYEES: "InviteEmployees",
  DEACTIVATE_EMPLOYEES: "DeactivateEmployees",
  CREATE_TEAMS: "CreateTeams",
  EDIT_TEAMS: "EditTeams",
  DELETE_TEAMS: "DeleteTeams",
  VIEW_ALL_TEAMS: "ViewAllTeams",
  INVITE_TEAMS: "InviteTeams",
  CREATE_GROUPS: "CreateGroups",
  EDIT_GROUPS: "EditGroups",
  DELETE_GROUPS: "DeleteGroups",
  VIEW_ALL_GROUPS: "ViewAllGroups",
  INVITE_GROUPS: "InviteGroups",
  VIEW_ALL_LEARNING_ARTICLES: "ViewAllLearningArticles",
  CREATE_LEARNING_ARTICLES: "CreateLearningArticles",
  EDIT_LEARNING_ARTICLES: "EditLearningArticles",
  DELETE_LEARNING_ARTICLES: "DeleteLearningArticles",
  VIEW_ALLL_LEARNING_COURSES: "ViewAllLearningCourses",
  CREATE_LEARNING_COURSES: "CreateLearningCourses",
  EDIT_LEARNING_COURSES: "EditLearningCourses",
  DELETE_LEARNING_COURSES: "DeleteLearningCourses",
  VIEW_ALL_LEARNING_EVENTS: "ViewAllLearningEvents",
  CREATE_LEARNING_EVENTS: "CreateLearningEvents",
  EDIT_LEARNING_EVENTS: "EditLearningEvents",
  DELETE_LEARNING_EVENTS: "DeleteLearningEvents",
  VIEW_ALL_ANALYTICS: "ViewAllAnalytics",
  CREATE_ASSESSMENTS: "CreateAssessments",
  EDIT_ASSESSMENTS: "EditAssessments",
  DELETE_ASSESSMENTS: "DeleteAssessments",
  EDIT_EMPLOYESS: "EditEmployees",
  DELETE_POSTS: "DeletePosts",
  DELETE_POST_COMMENTS: "DeletePostComments",
  MANAGE_EMPLOYEES: "ManageEmployees",
  MANAGE_TEAMS: "ManageTeams",
  MANAGE_GROUPS: "ManageGroups",
  MANAGE_LEARNING_ARTICLES: "ManageLearningArticles",
  MANAGE_BRANDING: "ManageBranding"
}