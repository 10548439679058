import styled from 'styled-components/native'
import { Dimensions, StyleSheet } from 'react-native'
import { Headline6 } from '@/styles/fonts'

const { width, height } = Dimensions.get('window')

export const styles = StyleSheet.create({
	contentScroll: {
		width,
		height,
		alignSelf: 'center'
	},
	scroll: {
		width,
		height,
	}
})

export const Container = styled.View`
	height: ${height}px;
	width: 100%;
	padding: 0 20px 0;
	background: ${({ theme }) => theme.basics.background_white};
`
export const Header = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 16px 0 24px;
	z-index: 1;
	position: relative;
	background: ${({ theme }) => theme.basics.background_white};

`
export const BackButton = styled.TouchableOpacity`
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	left: 0;
	position: absolute;
`
export const Title = styled(Headline6)``
