import { TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import { Headline6 } from '@/styles/fonts'

export const Container = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0;
  position: relative;
`

export const IconContainer = styled.View`
  width: 25px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Label = styled(Headline6)`
  flex-grow: 1;
  text-align: right;
  color: ${({ theme, selected }) =>
    selected ? theme.primary.standard : '#323232'};
`
