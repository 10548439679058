import React from 'react'
import { View, Image, TouchableOpacity, Text } from 'react-native'
import moment from 'moment'

import styles from './styles.js';

import PropTypes from 'prop-types'
import { FontAwesome } from '@expo/vector-icons'
import { Avatar } from '@/components'
import { useNavigation } from '@react-navigation/native'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

const Card = ({
    type,
    id,
    image,
    name = '',
    excerpt = '',
    creator,
    read_later,
    setReadLater,
    employee_lifecycle_stage,
    five_s_category,
    draft,
    featured,
    language_id,
    start_date
}) => {
    const navigation = useNavigation()

    const onPress = () => {
        if (type == 'article') {
            navigation.push('Main', {
                screen: 'ViewArticle', params: {
                    id: id,
                }, key: 'viewarticle_' + id
            });
        }

        if (type == 'event') {
            navigation.push('Main', {
                screen: 'ViewEvent', params: {
                    id: id,
                }, key: 'viewevent_' + id
            });
        }

        if (type == 'course') {
            navigation.push('Main', {
                screen: 'ViewCourse', params: {
                    id: id,
                }, key: 'viewcourse_' + id
            });
        }
    }

    return (
        <>
            <View style={styles.Container}>
                <TouchableOpacity
                    style={styles.Card}
                    onPress={onPress}
                >
                    <View style={styles.CoverContainer}>
                        <TouchableOpacity
                            style={styles.CoverButton}
                            onPress={setReadLater}
                        >
                            <FontAwesome
                                name={read_later ? 'bookmark' : 'bookmark-o'}
                                size={19}
                                color={read_later ? '#fff' : '#fff'}
                            />
                        </TouchableOpacity>

                        {type == 'event' ? (
                            <View style={styles.CoverDateContainer}>
                                <Text style={styles.CoverDate}>{moment(start_date).format('MMM DD')}</Text>
                            </View>
                        ) : null}

                        <Image
                            style={styles.CoverImage}
                            source={image ? { uri: image } : PlaceholderImageCover}
                            resizeMode="cover"
                        />
                    </View>

                    <View style={styles.MetaContainer}>
                        <View style={styles.TitleContainer}>
                            <Text style={styles.Title}>{name ?? ''}</Text>
                        </View>

                        <View style={styles.ExcerptContainer}>
                            <Text numberOfLines={2} style={styles.Excerpt}>{excerpt ? excerpt : ''}</Text>
                        </View>

                        {employee_lifecycle_stage || five_s_category ? (
                            <View style={styles.TagsContainer}>
                                {employee_lifecycle_stage?.name ? (
                                    <Text style={styles.Tag}>{employee_lifecycle_stage?.name}</Text>
                                ) : null}

                                {five_s_category?.name ? (
                                    <Text style={styles.Tag}>{five_s_category?.name}</Text>
                                ) : null}
                            </View>
                        ) : null}

                        <View style={styles.AuthorContainer}>
                            <View style={styles.AuthorAvatarContainer}>
                                <Avatar
                                    source={creator?.avatar ? { uri: creator?.avatar } : null}
                                    resizeMode="cover"
                                    size={45}
                                />
                            </View>
                            <View style={styles.AuthorDetailsContainer}>
                                <Text style={styles.AuthorName}>{`${creator?.first_name ?? ''} ${creator?.last_name ?? ''}`}</Text>

                                {creator?.role ? (
                                    <Text style={styles.AuthorRole}>{`${creator?.role ?? ''}`}</Text>
                                ) : null}
                            </View>
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        </>
    )
}

Card.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    creator: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string,
        avatar: PropTypes.string,
        company: PropTypes.shape({
            name: PropTypes.string,
        }),
    }),
    read_later: PropTypes.any,
    employee_lifecycle_stage: PropTypes.any,
    five_s_category: PropTypes.any,
    setReadLater: PropTypes.func,
    type: PropTypes.string,
}

Card.defaultValues = {
    creator: {
        name: '',
    },
}

export default Card
