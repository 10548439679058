import React, { useCallback, useEffect, useState } from 'react'
import { FlatList, ScrollView, Text } from 'react-native'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import { SimpleModal, Icon, MemberWeb } from '@/components'
import {
  Card,
  CloseButton,
  Title,
  ContainerButtons,
  Button,
  ButtonText,
  styles,
} from './styles'
import { View } from 'react-native'
import { MembersContext } from '@/contexts/Community/Group/invite'
import { useDispatch, useSelector } from 'react-redux'
import * as groupSelectors from '@/redux/selectors/groups'
import * as teamSelectors from '@/redux/selectors/teams'
import { actions as groupActions } from '@/redux/reducers/groups'
import { actions as teamsActions } from '@/redux/reducers/teams'

import ModalV2 from '@/components/ModalV2'

const InviteMemberMobile = ({
  isVisible,
  setVisible,
  onClose,
  type,
  data,
  joinedMembers,
}) => {
  const membersList = (
    type === 'group'
      ? useSelector(groupSelectors.members)
      : useSelector(teamSelectors.members)
  )?.filter((item) => !joinedMembers.some((m) => m.id === item.id))
  const dispatch = useDispatch()
  const [members, setMembers] = useState([])

  const renderItem = useCallback(
    ({ item }) => (
      <MembersContext.Provider value={{ setMembers, setVisible }}>
        <MemberWeb {...item} />
      </MembersContext.Provider>
    ),
    []
  )

  useEffect(() => {
    if (type === 'group') {
      dispatch(groupActions.members({}, () => { }))
      return
    }
    dispatch(teamsActions.members({}, () => { }))
  }, [])

  const handleSendMembers = () => {
    if (type === 'group') {
      dispatch(
        groupActions.invite({ id: data.id, employees: members }, handleSuccess)
      )
      return
    }
    dispatch(
      teamsActions.invite({ id: data.id, employees: members }, handleSuccess)
    )
  }

  const handleSuccess = (error, success) => {
    setVisible(false)
    if (onClose) {
      onClose(true)
    }
  }

  return (
    <ModalV2
      isVisible={isVisible}
      setVisibility={setVisible}
      title={'Invite Members'}
    >
      <ScrollView>
        <FlatList
          keyExtractor={(item) => item?.id}
          renderItem={renderItem}
          data={membersList}
          style={styles.flatList}
          contentContainerStyle={styles.flatListContent}
        />

        <ContainerButtons>
          <Button onPress={handleSendMembers}>
            <ButtonText>Invite</ButtonText>
          </Button>
        </ContainerButtons>
      </ScrollView>
    </ModalV2>
  )
}

InviteMemberMobile.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  joinedMembers: PropTypes.array,
  onClose: PropTypes.func,
}

export default InviteMemberMobile
