import { Dimensions, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'

const { height } = Dimensions.get('window');

export const RoomView = styled.View`
    position: relative;
    margin-bottom: 2em;

`

export const Button = styled(TouchableOpacity)`
    position: absolute;
    top: 0;
    right: 20px;
`

export const H3 = styled.Text`
    font-size: ${props => props.small ? "14px" : "1.2em"};
    text-align: center;
    font-weight: 600;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : "1em"};
    color: ${props => props.color ?? "black"};
`

export const H2 = styled.Text`
    font-size: 1.17em;
    font-weight: 300;
    margin-bottom: 1em;
    text-align: center;
    color: white;
`

export const LocalParticipantView = styled.View`
    text-align: center;
    margin-bottom: 1em;

`

export const RemoteParticipantView = styled.View`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: stretch;
    min-height: ${height / 1.15}px;
`