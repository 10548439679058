import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'


// Constants
export const constants = {
    AUTH_LOGOUT: 'AUTH_LOGOUT',
    BRAND_SETTINGS: createSagaAction('BRAND_SETTINGS'),

}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {

    updateSettings: (settings) => {
        return {
            type: constants.BRAND_SETTINGS.ACTION,
            payload: settings,
        }
    }

}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
    branding: {
        'color_scheme_main_navigation_background': "#1B1B1B",
        'color_scheme_main_navigation_text': "#FFFFFF",
        'color_scheme_primary_button_background': "#1B1B1B",
        'color_scheme_primary_button_text': "#FFFFFF",
        'color_scheme_accents': "#F05343",
        'color_scheme_page_background': "#959595",
        'color_scheme_page_topbar': "#FFFFFF",
        taxonomies: {
            team: {
                singluar: 'Team',
                plural: 'Teams'
            },
            group: {
                singluar: 'Group',
                plural: 'Groups'
            },
            member: {
                singluar: 'Member',
                plural: 'Members'
            }
        }
    }
}

export default createReducer(initialState, (state, action) => {
    switch (action.type) {
        case constants.BRAND_SETTINGS.ACTION: {
            return {
                ...state,
                branding: action.payload
            }
        }

        case constants.AUTH_LOGOUT: {
            return initialState
        }

        default: {
            return state;
        }
    }
})
