import React, { useState, useEffect } from 'react'
import {
    Text,
    View,
} from 'react-native'
import { RadioButton } from 'react-native-paper';

import styles from './styles.js';

const RadioInput = ({
    items,
    selectedValue,
    valueSelector
}) => {
    const [output, setOutput] = useState([]);

    useEffect(() => {
        prepareElements()
    }, [items, selectedValue])

    const prepareElements = async () => {
        setOutput([]);

        Object.values(items).map((item, index) => {
            setOutput(current => [
                ...current,
                (
                    <View style={styles.ItemContainer}>
                        <RadioButton
                            style={styles.Button}
                            value={item.value}
                            status={selectedValue === item.value ? 'checked' : 'unchecked'}
                            onPress={() => {
                                valueSelector(item.value)
                            }}
                        />
                        <Text style={styles.Label}>{item.label}</Text>
                    </View>
                )
            ])
        });
    }

    return (
        <View>
            {output}
        </View>
    )

}

export default RadioInput