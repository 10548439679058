import { useNavigation } from '@react-navigation/native'
import React, { useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Animated } from 'react-native'
import { Label, Container, IconContainer } from './styles'
import { useSideDrawer } from '@/hooks/sideDrawer'
import Icon from '@/components/Icon'

import { useSelector } from 'react-redux'
import * as brandSelectors from '@/redux/selectors/branding'
import UnreadBadge from '@/components/Chat/components/UnreadBadge'

export default function DrawerItem({
  icon,
  label,
  onPress,
  children,
  iconSize,
  selected,
  destinationRoute,
  style,
}) {
  const { open } = useSideDrawer()
  const navigation = useNavigation()
  const animatedOpacity = useRef(new Animated.Value(open ? 1 : 0)).current


  const brandSettings = useSelector(brandSelectors.getSettings);


  const {
    color_scheme_main_navigation_text
  } = brandSettings


  useEffect(() => {
    Animated.timing(animatedOpacity, {
      duration: 200,
      toValue: open ? 1 : 0,
      useNativeDriver: false,
    }).start()
  }, [open])

  const handlePress = useCallback(() => {
    if (destinationRoute) {
      navigation.navigate(destinationRoute)
    }
    if (onPress) onPress()
  }, [onPress, navigation, destinationRoute])

  return (
    <Container onPress={handlePress} style={style}>
     {icon == 'comment' ? <UnreadBadge>
     <IconContainer>
        {
          icon
          &&
          <Icon
            icon={icon}
            color={color_scheme_main_navigation_text ? color_scheme_main_navigation_text : "white"}
            width={iconSize || 22}
          />
        }
      </IconContainer>
     </UnreadBadge> :  <IconContainer>
        {
          icon
          &&
          <Icon
            icon={icon}
            color={color_scheme_main_navigation_text ? color_scheme_main_navigation_text : "white"}
            width={iconSize || 22}
          />
        }
      </IconContainer>}
      {label && (
        <Animated.View style={{ opacity: animatedOpacity }}>
          <Label
            selected={selected}
            color={color_scheme_main_navigation_text}
          >
            {label}
          </Label>
        </Animated.View>
      )}
      {children}
    </Container>
  )
}

DrawerItem.propTypes = {
  icon: PropTypes.string,
  iconSize: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.string, PropTypes.number),
  label: PropTypes.string,
  onPress: PropTypes.func,
  children: PropTypes.node,
  selected: PropTypes.bool,
  destinationRoute: PropTypes.string,
}
