export const MONTHS_NAME = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]


export const LIST_TYPE = [
    { title: "Day", key: 0 },
    { title: "Week", key: 1 },
    { title: "Month", key: 2 },
    { title: "Schedule", key: 3 },
]


export const EVENT_MODE = {
    create: 1,
    edit: 2,
    duplicate: 3
}

export const INVITEE_TYPE = {
    team: "App\\Team",
    group: "App\\Group",
    employee: "App\\WorkspaceMember"
}
export const ATTEND_STATUS = {
    going: 1,
    maybe: 2,
    not_going: 3,
}