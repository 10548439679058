import React, { useState, useCallback, useEffect } from 'react'
import {
    ScrollView,
    Text,
    KeyboardAvoidingView,
    View,
    TouchableOpacity,
    FlatList,
    StyleSheet,
    Dimensions,
} from "react-native";
// Add Actions - replace 'Your' with whatever your reducer is called :)
// import YourActions from '../Redux/YourRedux'
import { isMobile } from '@/styles'
import MentionsTextBoxWeb from './web.mentionsTaxBox'
import MentionsTextBoxMobile from './mobile.mentionsTextBox'
const { width, height } = Dimensions.get("window");
const screenWidth = width < height ? width : height;
const screenHeight = width < height ? height : width;

// Styles
import styles from "./Styles";


const MentionsTextBox = ({ placeholder, text, setText }) => {


    return (
        <>
            {isMobile
                ? <MentionsTextBoxMobile placeholder={placeholder} text={text} setText={setText} />
                : <MentionsTextBoxWeb placeholder={placeholder} text={text} setText={setText} />

            }
        </>
    )




}

export default MentionsTextBox;
