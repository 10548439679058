import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native';
import Toast from 'react-native-toast-message'
import { vh, vw } from 'react-native-expo-viewport-units'
import { TextField } from '@/components'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { actions as authActions } from '@/redux/reducers/auth'
import { ActivityIndicator } from 'react-native-paper'
import { Entypo } from '@expo/vector-icons'
import * as Yup from 'yup'
import { isMobile, useScreenSize } from '@/styles'
import {
  TextButton,
  SubmitButton,
  CreatePasswordPageContent,
} from './styles'
import OnboardingPage from '@/components/OnboardingPage'
import ResetPasswordDecoration from '@/assets/onboarding/ResetPasswordDecoration'
import ToastWeb from '@/components/ToastWeb'
import ApiHandler from '@/api/ApiHandler'

const initialValues = {
  workspaceCode: '',
  email: '',
};

const validationSchema = Yup.object({
    workspaceCode: Yup.string().required(),
    email: Yup.string().email().required(),
});

function SignupWorkspaceCode({ navigation }) {
  const dispatch = useDispatch();
  const screenSize = useScreenSize();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values) => {
    try {
        setLoading(true);
        const data = {
            workspace_code: values.workspaceCode,
            email: values.email
        };
        const response = await new ApiHandler().signupUsingWorkspaceCode(data);
        if (response) {
            const status = response?.status;
            const {message} = response?.data;
            if (status === 400) {
                if (isMobile) {
                    Toast.show({
                      type: 'error',
                      position: 'bottom',
                      text1: message,
                    });
                } else {
                    setErrorMessage(message);
                    setError(true);
                }
                setLoading(false);
            } else {
              dispatch(
                authActions.signUpUsingWorkspaceCode(response?.data, (error) => {
                    setLoading(false);
                    if (error) {
                      if (isMobile) {
                        Toast.show({
                          type: 'error',
                          position: 'bottom',
                          text1: 'Invalid credentials, try again.',
                        })
                        return
                      }
                      setErrorMessage('Invalid credentials, try again.');
                      setError(true);
                    }
                  },
                ),
              )
            }
        }
    } catch (error) {
        setLoading(false);
        console.log('SIGNUP USING WORKSPACE CODE ERROR', error);
    }
  };

  return (
    <OnboardingPage
      invert
      styleDecorationWrapper={{ left: 200 }}
      header={<OnboardingHeader justifyContent="flex-end" />}
      decoration={<ResetPasswordDecoration width={vw(45)} height={vh(100)} />}
    >
      <CreatePasswordPageContent screenSize={screenSize}>
        <Formik
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <OnboardingFormWrapper title={'Sign Up Using A Workspace Code'}>
              <TextField
                label="Workspace Code"
                style={{ paddingTop: 46 }}
                value={values.password}
                autoCapitalize="none"
                onChangeText={handleChange('workspaceCode')}
              />
              <TextField
                style={{ paddingTop: 17 }}
                label="Email"
                value={values.password_confirmation}
                autoCapitalize="none"
                keyboardType="email-address"
                onChangeText={handleChange('email')}
              />
              <SubmitButton
                color="black"
                disabled={!isValid}
                style={{ marginTop: 24 }}
                onPress={handleSubmit}
              >
                {loading && (
                  <ActivityIndicator
                    size={20}
                    animating
                    color="white"
                    style={{ marginRight: 10 }}
                  />
                )}
                <TextButton>Continue</TextButton>
                <Entypo name="chevron-right" size={24} color="white" />
              </SubmitButton>
              <View style={{ marginTop: 10}}>
                <ToastWeb
                    text={errorMessage}
                    visible={error}
                    setVisible={setError}
                    duration={3500}
                />
              </View>
            </OnboardingFormWrapper>
          )}
        </Formik>
      </CreatePasswordPageContent>
    </OnboardingPage>
  )
}

SignupWorkspaceCode.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default SignupWorkspaceCode
