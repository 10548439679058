import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { View, Text } from 'react-native'
import { vw } from 'react-native-expo-viewport-units'
import OnboardingHeader from '@/components/OnboardingHeader'
import OnboardingFormWrapper from '@/components/OnboardingFormWrapper'
import { Checkbox, TextField, PasswordTextField } from '@/components'
import Toast from 'react-native-toast-message'

import { Formik } from 'formik'
import { Entypo } from '@expo/vector-icons'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { actions as authActions } from '@/redux/reducers/auth'
import { ActivityIndicator } from 'react-native-paper'
import OnboardingPage from '@/components/OnboardingPage'
import {
  ForgetText,
  TextButton,
  SubmitButton,
  LoginPageContent,
  CheckboxContainer,
  WorkspaceCodeText,
  GetStartedText,
} from './styles'
import { isMobile, useScreenSize } from '@/styles'
import { Subtitle1 } from '@/styles/fonts'
import LoginDecoration from '@/assets/onboarding/LoginDecoration'
// import SocialMediaContainer from '@/components/SocialMediaContainer'
import ToastWeb from '@/components/ToastWeb'

const initialValues = {
  email: '',
  password: '',
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required(),
})
function Login({ navigation }) {
  const dispatch = useDispatch()
  const screenSize = useScreenSize()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [rememberMe, setRememberMe] = useState('checked')

  const rememberUser = () =>
    setRememberMe((previousState) => {
      return previousState === 'checked' ? 'unchecked' : 'checked'
    })

  const handleSubmit = useCallback((values) => {
    setLoading(true)

    dispatch(
      authActions.login(
        {
          email: values.email,
          remember: rememberMe,
          password: values.password,
        },
        (error) => {
          setLoading(false)

          if (error) {
            if (isMobile) {
              Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Invalid credentials, try again.',
              })
              return
            }
            setError(true)
          }
        },
      ),
    )
  })

  return (
    <OnboardingPage
      header={<OnboardingHeader />}
      decoration={<LoginDecoration width={vw(45)} height="auto" />}
    >
      <LoginPageContent screenSize={screenSize}>
        <OnboardingFormWrapper title={'Welcome\nback!'}>
          <Formik
            validateOnMount
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          >
            {({ values, isValid, handleChange, handleSubmit }) => (
              <View>
                <View>
                  <TextField
                    label="E-mail"
                    value={values?.email}
                    autoCapitalize="none"
                    autoCompleteType="email"
                    keyboardType="email-address"
                    onChangeText={handleChange('email')}
                  />
                  <PasswordTextField
                    name="password"
                    value={values?.password}
                    onChangeText={handleChange('password')}
                  />
                </View>
                <ForgetText
                  onPress={() => navigation.navigate('ForgotPassword')}
                >
									Forgot password?
                </ForgetText>
                <CheckboxContainer>
                  <Checkbox onPress={rememberUser} status={rememberMe} />
                  <Subtitle1 onPress={rememberUser}>Remember me</Subtitle1>
                </CheckboxContainer>
                <SubmitButton
                  color="black"
                  disabled={!isValid}
                  onPress={handleSubmit}
                >
                  {loading && (
                    <ActivityIndicator
                      size={20}
                      animating
                      color="white"
                      style={{ marginRight: 10 }}
                    />
                  )}
                  <TextButton>Let&apos;s Go</TextButton>
                  <Entypo name="chevron-right" size={24} color="white" />
                </SubmitButton>
                <WorkspaceCodeText>
                  Workspace Code?{" "}
                  <GetStartedText onPress={() => navigation.navigate('SignupWorkspaceCode')}>
                    Get Started
                  </GetStartedText>
                </WorkspaceCodeText>
                {/* <SocialMediaContainer
                  linkText=" Sign up"
                  text="Don't have an account?"
                  navigate={() => navigation.navigate('SignUp')}
                /> */}
              </View>
            )}
          </Formik>
          <ToastWeb
            text="Invalid credentials, try again"
            visible={error}
            setVisible={setError}
            duration={3500}
          />
        </OnboardingFormWrapper>
      </LoginPageContent>
    </OnboardingPage>
  )
}

Login.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
  }).isRequired,
}

export default Login