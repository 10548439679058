import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    CategoryActions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingHorizontal: 10,
        marginBottom: 20
    },
    ChartTimePeriod: {
        width: '100%',
        fontFamily: 'WorkSans_400Regular',
        fontSize: 12,
        fontWeight: '600',
        color: '#1b1b1b',
        textAlign: 'right',
        marginTop: 5
    },
    LikesAndViewsItem: {
        marginBottom: 15,
        justifyContent: 'space-between',

        ...Platform.select({
            ios: {
                flexDirection: 'column',
            },
            android: {
                flexDirection: 'column',
            },
            web: {
                flexDirection: 'row',
            }
        })

    },
    LikesAndViewsItemName: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 15,
        fontWeight: '600',
        color: '#1b1b1b',
        display: 'flex',
        flexBasis: '60%'
    },
    LikesAndViewsItemCount: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 15,
        fontWeight: '600',
        color: '#f1524f',
        display: 'flex',
        flexBasis: '40%',
        justifyContent: 'flex-end',
    },
    FiltersSection: {
        marginBottom: 20
    },
    FiltersSectionTitle: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 15,
        fontWeight: '600',
        color: '#f1524f',
        marginBottom: 5
    },
    FiltersSectionBody: {

    }
});
