export const data = [
  {
    id: '0',
    PearsonName: 'Angela Schrute',
    subTitle: 'CEO | Technology',
    AvatarImage: require('@/assets/Avatars/image.png'),
    describe: '',
    contentText:
      'Woke up feeling like a Sunday in Barbados! If you know, then you know that’s the best feeling in the world! Wish I was home to celebrate our 54th year of independence yesterday!',
    likeNumber: 1,
    loveNumber: 23,
    commentsNumber: 19,
    articleContent: {},
  },
  {
    id: '1',
    PearsonName: 'Angela Schrute',
    subTitle: 'CEO | Technology',
    AvatarImage: require('@/assets/Avatars/image.png'),
    describe: '',
    contentText: "Hey guys! Take a look at this article! It's fantastic!",
    likeNumber: 1,
    loveNumber: 23,
    commentsNumber: 19,
    articleContent: {
      imageSource: require('@/assets/Article.png'),
      title: 'Color theory in\nUX/UI design',
      subTitle: '10 min • Design',
    },
  },
  {
    id: '2',
    PearsonName: 'Angela Schrute',
    subTitle: 'CEO | Technology',
    AvatarImage: require('@/assets/Avatars/image.png'),
    describe: 'Published on Diversity group',
    contentText:
      'Woke up feeling like a Sunday in Barbados! If you know, then you know that’s the best feeling in the world! Wish I was home to celebrate our 54th year of independence yesterday!',
    likeNumber: 1,
    loveNumber: 23,
    commentsNumber: 19,
    articleContent: {},
  },
]
