import React from 'react'
import { Platform } from 'react-native';
import * as S from './styles'

const Dropdown = ({ hidden, label, data, labelField = 'label', valueField = "value", ...props }) => {
    return (
        <S.Container hidden={hidden}>
            <S.Text>{label}</S.Text>
            <S.Dropdown containerStyle={{ marginTop: -235 }} dropdownPosition="bottom" keyboardAvoiding={Platform.OS !== 'web'} data={data} labelField={labelField} valueField={valueField} maxHeight={200} {...props} />
        </S.Container>
    )
}

export default Dropdown;