import React from 'react'
import {
    View,
} from 'react-native'

import styles from './styles.js';

const Column = ({
    children,
    flexBasis
}) => {

    return (
        <View style={[
            styles.Column,
            {
                flexBasis: flexBasis
            }
        ]}>
            {children}
        </View>
    )

}

export default Column