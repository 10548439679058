import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ChevronDown(props) {
  return (
    <Svg width="12" height="8" viewBox="0 0 12 8" fill="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M10.59 0.295044L6 4.87504L1.41 0.295044L0 1.70504L6 7.70504L12 1.70504L10.59 0.295044Z" />
    </Svg>
  )
}

ChevronDown.propTypes = Svg.propTypes
