import styled from 'styled-components/native'
import { Headline6, Subtitle1, Headline4 } from '@/styles/fonts'
import { Card, Button } from '@/components'
import { Dimensions, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import { isMobile, ScreenSize } from '@/styles';

const { width, height } = Dimensions.get('screen');

export const Wrapper = styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    paddingVertical: 20,
  },
}))`
  flex: 1;
  width: 100%;
`

export const SpaceIcon = styled.View`
  width: 12%;
`

export const LeftContainer = styled.View`
  margin-left: 17px;
`

export const RightContainer = styled.View`
  margin-right: 8px;
`

export const AvatarContainer = styled.View`
  height: 100px;
  width: 100%;

  align-items: center;
  justify-content: center;

  margin-top: 45px;
`

export const TitleAvatar = styled.Text`
  margin-top: 16px;
  margin-bottom: 8px;
  font-size: 22px;
`

export const DadosContainer = styled.View`
  width: 100%;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 16px;
`

export const SubTitleText = styled(Subtitle1)`
  margin-bottom: 4px;
  color: ${({ theme }) => theme.basics[500]};
  text-align: center;
  padding : 0px 12px;
`

export const Divider = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.basics[300]};
`

export const TopicsTitle = styled(Headline6)`
  margin-top: 20px;
  margin-left: 17px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.basics.black_text};
`

export const CarrouselContainer = styled.View`
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`

export const RewardsContainer = styled.View`
  padding: 0 18px;
`

export const HorizontalRewardsContainer = styled.View`
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  margin-bottom: 13px;
  justify-content: space-between;
`

export const RewardCard = styled(Card)`
  width: 49%;
  padding: 20px 0;
`
export const EditProfileContainer = styled.View`
	width: ${isMobile ? width : !isMobile && width >= ScreenSize.Medium ? 520 :!isMobile && width <= ScreenSize.Medium && width}px;
  height: ${isMobile ? height + 'px': !isMobile && width >= ScreenSize.Medium ? "100%" : !isMobile && width <= ScreenSize.Medium && height + 'px'};
	justify-content: space-between;
	padding: 0 20px 5%;
	background: ${({ theme }) => theme.basics.background_white};
  align-self: ${Platform.OS == 'web' ? 'center' : "flex-start"};
`

export const HeaderEditProfile = styled.View`
	padding: 30px;
  align-items: flex-end;
  justify-content: space-between;
	width: 100%;
  height: ${Platform.OS == 'web' ? undefined : height / 8}px;
  flex-direction: row;
`

export const Offset = styled.View`
  width: 3%;
`

export const CloseButton = styled.TouchableOpacity`
	z-index: 1;
	width: 15px;
	height: 15px;
`

export const HeaderTitle = styled(Headline4)`
	color: ${({ theme }) => theme.basics.black_text};
`
export const EditeProfileButton = styled(Button)`
	width: 90%;
	background: ${({ theme, color }) => color ? color : theme.basics.brand};
	margin: 30px 0;
	align-self: center;
`

export const EditText = styled.Text`
  color: ${({ theme, cancel, color }) => cancel ? theme.basics[700] : color ? color : theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`

export const SendMessageButton = styled.TouchableOpacity`
	width: 191px;
	height: 40px;
	background-color: ${({ theme }) => theme.danger.standard};
	align-self: center;
	align-items: center;
	justify-content: center;
	border-radius: 3px;
	margin: 30px 0 0;
`

export const SendButtonText = styled(Headline6)`
	width: 100%;
	color: ${({ theme }) => theme.basics.background_white};
	text-align: center;
`

export const ReferAFriendView = styled.View`
  width: 100%;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 16px;
`

export const ReferAFriendTitle = styled.Text`
  height: 20px;
  font-family: 'WorkSans_400Regular';
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.basics.black_text};
`

export const ReferAFriendSubTitle = styled.Text`
  width: 100%;
  font-family: 'WorkSans_400Regular';
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.basics.black_text};
  paddingVertical: 6px;
  text-align: center;
  padding : 0px 56px;
`

export const ReferAFriendContainer = styled.View`
  position: relative;
	width: 380px;
	height: 220px;
	justify-content: space-between;
	padding: 0 20px 5%;
	background: ${({ theme }) => theme.basics.background_white};
  align-self: center;
`
