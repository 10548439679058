import React from 'react'
import { ScrollView, View } from 'react-native'
import Input from '@/components/Chat/RTM/Input';
import CallMessage from "../CallMessage"

const MessagesModal = ({ messages, messageText, user, setMessageText, sendMessage }) => {

    const onKeyPress = (e) => {
        const key = e.nativeEvent.key;
        const shift = e.nativeEvent.shiftKey;

        if (key == "Enter" && !shift) {
            e.preventDefault();
            sendMessage();
        }
    }

    return (
        <>
            <ScrollView style={{ flex: 1 }}>
                {Array.from(messages, ([timeStamp, message]) => (
                    <CallMessage key={timeStamp} message={message.message} sender={message.sender} />
                ))}
            </ScrollView>
            <View style={{ width: '100%', bottom: 5, left: 0 }}>
                <Input avatar={user.avatar} value={messageText} letter={user?.first_name?.charAt(0)} onKeyPress={onKeyPress} hideAttachment multiline onChangeText={setMessageText} onSendMessage={sendMessage} />
            </View>
        </>
    )
}

export default MessagesModal;