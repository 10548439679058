import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Rewards(props) {
  return (
    <Svg width="10" height="20" viewBox="0 0 10 20" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M10 8.43V0H0V8.43C0 8.78 0.18 9.11 0.49 9.29L4.67 11.8L3.68 14.14L0.27 14.43L2.86 16.67L2.07 20L5 18.23L7.93 20L7.15 16.67L9.74 14.43L6.33 14.14L5.34 11.8L9.52 9.29C9.82 9.11 10 8.79 10 8.43ZM4 9.07L2 7.87V2H4V9.07ZM8 7.87L6 9.07V2H8V7.87Z"
      />
    </Svg>
  )
}

Rewards.propTypes = Svg.propTypes
