import React from "react";
import { View, Text } from "react-native";


const Horizon = ({ amount }) => {
    return (
        <View
            style={{
                backgroundColor: "rgba(0,0,255,0.2)",
                padding: 2,
                margin: 16,
                alignItems: 'center',
                borderRadius: 20
            }}
        >
            <Text style={{ color: 'white', fontWeight: "bold", fontSize: 14, lineHeight: 20 }} >
                {amount} new {amount > 1 ? "messages" : "message"}
            </Text>
        </View>
    )
}

export default Horizon;
