import React, { useEffect } from "react";
import {
	StyleSheet,
	Text,
	View,
	Image,
	Dimensions,
	ScrollView,
	Platform
} from "react-native";
import ReframeHorizontalLogo from "@/assets/brand/reframe-brand.png";
import Button from "./components/button";
const Width = Dimensions.get("window").width;
import * as Linking from "expo-linking";
import Icon from "@/components/Icon";
import { useNavigation } from "@react-navigation/native";

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'

const SuccessMessage = () => {
	const navigation = useNavigation();
	const AppStore = () => {
		let href = "https://apps.apple.com/us/app/id1584877948";
		window.open(href, "_blank");
	};
	const GooglePlay = () => {
		let href =
			"https://play.google.com/store/apps/details?id=com.getreframe.crossplatform";
		window.open(href, "_blank");
	};

	return (
		<TheaterLayout>
			<View style={styles.Container}>
				<ContentBlockV1>
					<View style={styles.HeaderContainer}>
						<Text style={styles.WaveIcon}>{"👋 "}</Text>
						<Text style={styles.Headline}>{"Your Reframe Workspace Is Ready!"}</Text>
					</View>

					<View style={styles.ContentContainer}>
						<ContentBlockV1
							title={"Download Reframe’s Mobile App"}
							subtitle={'Our app is available on the App Store and Google Play. Download the app today, and login with your email address and password provided during signup.'}
						>
							<Button
								source={require("../../assets/apple-app-store.png")}
								title={"Get It On The App Store"}
								onPress={AppStore}
							/>
							<Button
								source={require("../../assets/google-play-logo.png")}
								title={"Get It On Google Play"}
								onPress={GooglePlay}
							/>
						</ContentBlockV1>

						<ContentBlockV1
							title={"Prefer To Continue In Your Web Browser?"}
							subtitle={'You can continue in your web browser, and download the app at a later time.'}
						>
							<Button
								title={"Continue On Web"}
								onPress={() => navigation.navigate("WelcomeFlow", { screen: "Welcome", params: { Message: "SuccessMessage" } })}
								Icon={
									<Icon
										icon={"chevronRight"}
										width={7}
										height={12}
										color={"#fff"}
									/>
								}
							/>
						</ContentBlockV1>
					</View>
				</ContentBlockV1>
			</View>
		</TheaterLayout>
	);
};

export default SuccessMessage;

const styles = StyleSheet.create({
	Container: {
		flex: 1,
		maxWidth: 600,
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 0,
		marginHorizontal: 'auto',

		...Platform.select({
			ios: {

			},
			android: {

			},
			web: {
				minWidth: 600
			}
		})
	},
	HeaderContainer: {
		alignItems: 'center',
		marginTop: 25
	},
	WaveIcon: {
		fontSize: 50,
		marginBottom: 20
	},
	Headline: {
		fontFamily: 'WorkSans_600SemiBold',
		fontSize: 32,
		textAlign: 'center',
		marginBottom: 20,
		maxWidth: 400
	},
	ContentContainer: {

	}
});
