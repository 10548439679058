import React, { useEffect, useState } from 'react'
import { Text, View, Dimensions, ScrollView, FlatList, useWindowDimensions } from 'react-native'

import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ContentBlockV1 from '@/components/ContentBlockV1'

import {
  PieChartGraph,
  ResponsesPieChartGraph,
} from '../../Components/resultPieChart'
import { isMobile, ScreenSize, useScreenSize } from '@/styles'
import ApiHandler from '@/api/ApiHandler'
import { SafeAreaView } from 'react-native'
import { BackArrow } from '@/components'
import { useSideDrawer } from '@/hooks/sideDrawer'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

import { Q_TYPE_FREE_TEXT } from "@/pages/Assessments/consts"

import styles from './styles.js';

const screenWidth = Dimensions.get('window').width
const Card = ({ title, title2 }) => {

  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])
  const screenSize = useScreenSize()

  return (
    <View
      style={{
        backgroundColor: '#fff',
        height: 95,
        width: isMobile ? screenWidth / 1.07 : window.width > 915 ? isOpenDrawer ? window.width - 250 : window.width / 3 : screenSize <= ScreenSize.Medium ? "100%" : window.width - 140,
        marginBottom: 8,

        borderRadius: 3,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        padding: 10,
        marginEnd: 10,
      }}
    >
      <Text style={{ fontFamily: 'WorkSans_400Regular' }}>{title}</Text>
      <Text
        style={{
          color: '#F05343',
          alignSelf: 'center',
          fontSize: 18,
          marginVertical: 10,
          fontFamily: 'WorkSans_400Regular'
        }}
      >
        {title2}
      </Text>
    </View>
  )
}
const Button = ({ title, value }) => {

  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  return (
    <View
      style={{
        backgroundColor: '#fff',
        height: 70,
        width: 140,
        padding: 10,
        margin: 8,
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
      }}
    >
      <Text style={{ fontFamily: 'WorkSans_400Regular' }}>{title}</Text>
      <Text
        style={{
          color: '#F05343',
          alignSelf: 'center',
          fontSize: 20,
          fontWeight: 'bold',
          // marginVertical: 8,
          fontFamily: 'WorkSans_400Regular'
        }}
      >
        {value}
      </Text>
    </View>
  )
}

const FreeTextResponses = ({ question }) => {

  return (
    <View style={[isMobile ? {} : { height: 250, overflow: "scroll" }]} >
      {
        question?.responses.map((response) => {
          return (
            <Text
              style={{
                width: '95%',
                paddingVertical: 10,
                fontSize: 14,
                alignSelf: 'flex-start',
                fontFamily: "WorkSans_400Regular",
                borderBottomWidth: 1,
                borderBottomColor: "#e6e6e6"
              }}
              key={response.id}
            >
              {response?.text}
            </Text>
          )
        })
      }

    </View>
  )

}

const ResultAnalytics = ({ route }) => {
  const [assessmentData, setAssessmentData] = useState(null)
  const [responding, setResponding] = useState([])
  const { id, type, name } = route?.params
  let Assessment = []

  useEffect(() => {
    getAssesmentData()
  }, [type, id])

  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  const getAssesmentData = async () => {
    try {
      const response = await new ApiHandler().getAssesmentPrebuildData(type, id)
      if (response?.data) {
        setAssessmentData(response?.data) // setting data in state
        dataEvalutation(response?.data) // sending data to function as an parameter
      }
    } catch (error) {
      console.log('Error', error)
    }
  }

  const dataEvalutation = async (data) => {
    //Each push shows single data on pie chart
    Assessment.push({
      name: 'Non-Respondent(s)',
      population: data?.number_of_nonrespondents,
      color: 'rgba(240, 83, 67, 0.5)',
      legendFontColor: 'rgba(240, 83, 67, 0.5)',
      legendFontSize: 14,
      //legendFontFamily: 'WorkSans_400Regular',
    })
    Assessment.push({
      name: 'Respondent(s)',
      population: data?.number_of_respondents,
      color: 'rgba(240, 83, 67, 1)',
      legendFontColor: 'rgba(240, 83, 67, 1)',
      legendFontSize: 14,
      //legendFontFamily: 'WorkSans_400Regular',
    })
    setResponding(Assessment)
  }

  return (
    <>

      <PageHeader
        title={name}
        icon={false}
        backEnabled={true}
        breadcrumbs={[
          { name: 'Analytics', navigation: 'Analytics' },
          { name: 'Assessment Analytics', navigation: 'Analytics' }
        ]}
      />

      <PageContent>
        <ColumnsContainer>
          <Column
            flexBasis={'50%'}
          >
            <ContentBlockV1
              title={'Total Number Of Targeted Members'}
            >
              <Text>{assessmentData?.number_of_targeted_members}</Text>
            </ContentBlockV1>

            <ContentBlockV1
              title={'Lifecycle Stage'}
            >
              <Text>{assessmentData?.lifecycle_stage}</Text>
            </ContentBlockV1>

            <ContentBlockV1
              title={'Category'}
            >
              <Text>{assessmentData?.five_s_category}</Text>
            </ContentBlockV1>
          </Column>

          <Column
            flexBasis={'50%'}
          >
            <ContentBlockV1
              title={'Respondents'}
              subtitle={'How many of the targeted members have began responding to this assessment?'}
            >
              <PieChartGraph dataObj={responding} />
            </ContentBlockV1>
          </Column>
        </ColumnsContainer>

        <ContentBlockV1
          title={'Questions & Responses'}
        >
          {assessmentData?.questions?.map((question) => {
            let pieChartWidth = 0;
            const pieChartData = [];
            pieChartData.push({
              name: 'Non-Respondent(s)',
              population: question?.number_of_nonrespondents,
              color: 'rgba(240, 83, 67, 0.5)',
              legendFontColor: 'rgba(240, 83, 67, 0.5)',
              legendFontSize: 14,
            })
            pieChartData.push({
              name: 'Respondent(s)',
              population: question?.number_of_respondents,
              color: 'rgba(240, 83, 67, 1)',
              legendFontColor: 'rgba(240, 83, 67, 1)',
              legendFontSize: 14,
            })

            return (
              <ContentBlockV1
                title={question?.text}
              >
                <ColumnsContainer>
                  <Column
                    flexBasis={'50%'}
                  >
                    <PieChartGraph
                      dataObj={pieChartData}
                    />
                  </Column>

                  <Column
                    flexBasis={'50%'}
                  >
                    {
                      question?.format == Q_TYPE_FREE_TEXT ? (
                        <ContentBlockV1
                          title={'Responses'}
                        >
                          <FlatList
                            data={question?.responses}
                            numColumns={1}
                            renderItem={({ item }) => (
                              <Text
                                key={item.id}
                                style={{
                                  fontFamily: 'WorkSans_400Regular',
                                  fontWeight: "500",
                                  color: '#1b1b1b',
                                  fontSize: 14,
                                  marginBottom: 8
                                }}
                              >
                                {'\u2B24'} {item?.text}
                              </Text>
                            )}
                            keyExtractor={(item) => item.id}
                          />
                        </ContentBlockV1>
                      ) : (
                        <ContentBlockV1
                          title={'Responses'}
                        >
                          {question?.options && (
                            <FlatList
                              data={question?.options}
                              numColumns={1}
                              renderItem={({ item }) => (
                                <View
                                  key={item.id}
                                  style={{
                                    marginBottom: 8
                                  }}
                                >
                                  <Text style={{
                                    fontFamily: 'WorkSans_400Regular',
                                    fontWeight: "500",
                                    color: '#1b1b1b',
                                    fontSize: 14,
                                  }}>
                                    <Text style={{
                                      fontWeight: "700",
                                      color: '#f1524f',
                                    }}>
                                      {item?.count}
                                    </Text>

                                    <Text> Responded With </Text>

                                    <Text style={{
                                      color: '#f1524f',
                                    }}>
                                      "{item?.text}"
                                    </Text>
                                  </Text>
                                </View>
                              )}
                              keyExtractor={(item) => item.id}
                            />
                          )}
                        </ContentBlockV1>
                      )
                    }
                  </Column>
                </ColumnsContainer>
              </ContentBlockV1>
            )
          })}
        </ContentBlockV1>
      </PageContent>
    </>
  )
}

export default ResultAnalytics
