import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function EmailIcon(props) {
  return (
    <Svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fill={props.color}
        d="M14 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0ZM14 3L8 6.75L2 3V1.5L8 5.25L14 1.5V3Z"
      />
    </Svg>
  )
}

EmailIcon.propTypes = SvgProps

export default EmailIcon
