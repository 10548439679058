
import bell from './bell.png';
import calendar from './calendar.png';
import chat from './chat.png';
import folder from './folder.png';
import group from './group.png';
import hat from './hat.png';
import home from './home.png';
import reward from './reward.png';
import support from './support.png';
import video from './video.png';

import left from './left.png';
import right from './right.png';



export const images = {
    bell,
    calendar,
    chat,
    folder,
    group,
    hat,
    home,
    reward,
    support,
    video,
    left,
    right

}