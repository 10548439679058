import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  AuthorContainer,
  AuthorInfo,
  AvatarContainer,
  Title,
  AuthorOffice,
  ButtonWrapper,
  ButtonTextChat,
  RightContainer,
  MenuText,
  ExpandButtonWrapper,
  OptionIconWrapper
} from './styles'
import { Icon } from '@/components'
import Avatar from '../Avatar'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
} from 'react-native-popup-menu'
import { Platform } from 'react-native';
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

const GroupTeamsMemberItem = ({ member, isTeam, isOwner, handleRemoveMember, seIsDeleteModalVisible, setDeletingUserId }) => {
  const word = isTeam ? 'team' : 'group'
  return (
    <Container>
      <AuthorContainer>
        <AvatarContainer>
          <Avatar size={isMobile ? 50 : 80} source={member?.avatar ? { uri: member?.avatar } : null} />
          <AuthorInfo>
            <Title>{`${member?.first_name} ${member?.last_name}`}</Title>
            <AuthorOffice>{`${member?.role}`}</AuthorOffice>
          </AuthorInfo>
        </AvatarContainer>
        <RightContainer>
          {/* <ButtonWrapper><ButtonTextChat>{'Chat'}</ButtonTextChat></ButtonWrapper> */}
          {(isOwner ||
            canAccess(permissions.MANAGE_TEAMS) ||
            canAccess(permissions.MANAGE_GROUPS)) && Platform.OS !== 'web' && (
              <Menu>
                <MenuTrigger>
                  <ExpandButtonWrapper>
                    <Icon icon={'ellipsis'} color={theme.basics.black_text} height={16} width={4} />
                  </ExpandButtonWrapper>
                </MenuTrigger>
                <MenuOptions>
                  <MenuOption onSelect={() => handleRemoveMember(member?.id)}>
                    <MenuText>{`Remove from ${word}`}</MenuText>
                  </MenuOption>
                  {/* <MenuOption onSelect={() => alert('Add as admin')} >
                  <MenuText>Add as admin</MenuText>
                </MenuOption> */}
                </MenuOptions>
              </Menu>
            )}
          {(isOwner ||
            canAccess(permissions.MANAGE_TEAMS) ||
            canAccess(permissions.MANAGE_GROUPS)) && Platform.OS === 'web' && (
              <Menu>
                <MenuTrigger>
                  <OptionIconWrapper onPress={() => {
                    setDeletingUserId(member?.id)
                    seIsDeleteModalVisible(true)
                  }}>
                    <Icon icon={'ellipsis'} color={theme.basics.black_text} height={16} width={4} />
                  </OptionIconWrapper>
                </MenuTrigger>
                {/* <MenuOptions> */}
                {/* <MenuOption onSelect={() => handleRemoveMember(member?.id)}>
                  <MenuText>{`Remove from ${word}`}</MenuText>
                </MenuOption> */}
                {/* <MenuOption onSelect={() => alert('Add as admin')} >
                  <MenuText>Add as admin</MenuText>
                </MenuOption> */}
                {/* </MenuOptions> */}
              </Menu>
            )}
        </RightContainer>
      </AuthorContainer>
    </Container>
  )
}

GroupTeamsMemberItem.propTypes = {
  member: PropTypes.object,
  isTeam: PropTypes.bool,
  isOwner: PropTypes.bool,
  handleRemoveMember: PropTypes.func.isRequired
}

GroupTeamsMemberItem.defaultProps = {
  member: {},
  isTeam: true,
  isOwner: false,
}

export default GroupTeamsMemberItem
