import styled from 'styled-components/native';

export const Container = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalArea = styled.View`
  justify-content: flex-end;
`;

export const GoBackModal = styled.TouchableOpacity`
  flex: 1;
`;

export const ModalContainer = styled.View`
  background-color: white;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 16px;
`;

export const HeaderModal = styled.View`
  margin-bottom: 12px;
  align-items: flex-end;
  justify-content: center;
`;

export const Button = styled.TouchableOpacity`
  height: 24px;
  width: 24px;

  justify-content: center;
  align-items: center;
`;

export const ContentModal = styled.View``;
