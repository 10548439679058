import React, { useState, useCallback } from 'react'
import {
    TextInput,
    Keyboard,
    KeyboardAvoidingView
} from 'react-native'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from "@react-navigation/native";
import { useRoute } from "@react-navigation/native";
import { ActivityIndicator } from 'react-native-paper'
import * as brandSelectors from '@/redux/selectors/branding'
import * as selectors from '@/redux/selectors/posts'
import { getMember } from '@/redux/selectors/auth'
import { actions } from '@/redux/reducers/posts'
import {
    Icon,
    Avatar,
    Button,
    FeedCardAuthorInfo, SimpleModal
} from '@/components'
import { CaptionMedium } from '@/styles/fonts'
import theme from '@/styles/themes/black'
import { ScreenSize, useScreenSize } from '@/styles/screenSize'
import {
    HeaderLabel,
    CloseButton,
    AuthorContainer,
    ContentContainer,
    PostInputWrapper,
    PostButtonWrapper,
    AuthorInfoContainer,
    KeyboardDismiss,
    HeaderContainer,
    styles,
} from './styles'
import { isMobile } from '@/styles'

import MentionsTextBox from '@/components/MentionsTextBox'
import ModalV2 from '@/components/ModalV2'

const CreatePostComment = ({ contentId, contentType, isVisible, setVisible }) => {


    const branding = useSelector(brandSelectors.getSettings);

    const {
        color_scheme_primary_button_background,
        color_scheme_primary_button_text,
    } = branding

    const dispatch = useDispatch()
    const route = useRoute();
    const navigation = useNavigation();

    const screenSize = useScreenSize()
    const [comment, setComment] = useState('')

    const loading = useSelector(selectors.loading)
    const member = useSelector(getMember)

    const handleSubmit = useCallback(() => {
        dispatch(actions.comment_in_article({
            comment,
            contentType,
            contentId
        }, () => {
            setVisible(false)
            dispatch(actions.comment_list({ postType: contentType, id: contentId }))
        }))

        setComment('')

        if (route.name == 'CommentPost') {
            navigation.setParams({ stamp: Math.random() })
        }
    }, [dispatch, comment])

    return (
        <ModalV2
            isVisible={isVisible}
            setVisibility={setVisible}
            title={'Write A Comment'}
        >
            <PostInputWrapper style={styles.PostInputWrapper}>
                <MentionsTextBox placeholder='Tip: You can include links in your comment, and mention other members of your community by typing "@".' text={comment} setText={setComment} />
            </PostInputWrapper>
            <PostButtonWrapper>
                <Button
                    color={color_scheme_primary_button_background ? color_scheme_primary_button_background : "black"}
                    onPress={handleSubmit}
                    disabled={!comment || loading}
                    style={{
                        width: screenSize <= ScreenSize.Medium ? '100%' : undefined,
                    }}
                >
                    {loading && (
                        <ActivityIndicator
                            size={20}
                            animating
                            color="white"
                            style={{ marginRight: 10 }}
                        />
                    )}
                    <CaptionMedium
                        color={color_scheme_primary_button_text ? color_scheme_primary_button_text : "white"}
                        style={{ fontSize: 17 }}
                    >
                        Publish
                    </CaptionMedium>
                </Button>
            </PostButtonWrapper>
        </ModalV2>
    )
}

CreatePostComment.propTypes = {
    isVisible: PropTypes.bool,
    setVisible: PropTypes.func,
    contentId: PropTypes.number.isRequired,
    contentType: PropTypes.string.isRequired
}

export default CreatePostComment
