import React, { useState } from 'react'
import { View, Text, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native'
import { TextInput } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import Toast from 'react-native-toast-message'

import * as brandSelectors from '@/redux/selectors/branding'

import { Icon, Checkbox, TextField, PasswordTextField, Radio } from '@/components'

import ExpansiveMenu from '@/components/ExpansiveMenu'
import ButtonPrimary from '@/components/ButtonPrimary'
import DropDown from "react-native-paper-dropdown";

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import ApiHandler from '@/api/ApiHandler'


import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import { ButtonRed } from '@/components/ButtonRed';

import {
    TYPE_CULTURE_MATURITY,
    TYPE_CUSTOM,
    TYPE_PREBUILT,

    Q_TYPE_FREE_TEXT,
    Q_TYPE_MULTIPLE,
    Q_TYPE_SINGLE
} from "../consts";

const DEFAULT_OPTIONS = ["", ""]

export default function Questions({
    assessmentInfo,
    routeParams,

    fetchAssessment
}) {



    const branding = useSelector(brandSelectors.getSettings);
    const {
        color_scheme_primary_button_background,
    } = branding;

    const assessmentId = routeParams?.id;
    const assessmentType = routeParams?.type;

    const [questionType, setQuestionType] = useState(Q_TYPE_SINGLE);
    const [question, setQuestion] = useState("");
    const [options, setOptions] = useState(DEFAULT_OPTIONS);

    const [isLoading, setLoading] = useState(false);
    const [addOptionLoader, setAddOptionLoader] = useState(false);

    const assessment_questions = assessmentInfo?.assessment_questions || [];
    const [assessmentOptions, setAssessmentOptions] = useState([]);

    let questionEditable = false;

    if (assessmentType) {
        if (assessmentType === TYPE_CUSTOM) {
            questionEditable = true;
        } else if (assessmentType === TYPE_PREBUILT || assessmentType === TYPE_CULTURE_MATURITY) {
            questionEditable = false;
        }
    } else {
        questionEditable = true;
    }

    React.useEffect(() => {
        if (questionEditable && assessment_questions.length > 0) {
            const data = [];
            for (let i = 0; i < assessment_questions.length; i++) {
                data.push("");
            }
            setAssessmentOptions(data);
        }
    }, [assessment_questions, assessment_questions.length, questionEditable])


    const addQuestion = async () => {


        if (question === "") {
            Toast.show({
                type: 'error',
                position: 'top',
                text1: "Please enter question",
            })

            return;
        }


        const data = {

            "assessment_id": assessmentInfo?.id,
            "text": question,
            "format": questionType
        }
        if (questionType !== Q_TYPE_FREE_TEXT) {
            data.options = options.filter(item => { return item != "" })
        }

        setLoading(true)
        try {
            const response = await new ApiHandler().createCustomAssessmentQuestion(data)

            fetchAssessment?.();
            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
            })
        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }

    }

    const removeQuestion = async (question) => {


        setLoading(true)
        try {
            const question_id = question?.id;
            const response = await new ApiHandler().removeCustomAssessmentQuestion(assessmentInfo?.id, question_id);

            fetchAssessment?.();
            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
            })

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }


    }

    const addNewOption = async (option) => {

        setAddOptionLoader(true)
        try {
            const question_id = option?.question_id;
            const option_text = option?.text;

            const response = await new ApiHandler().addNewOption(assessmentInfo?.id, question_id, option_text);

            fetchAssessment?.();
            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
            })

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setAddOptionLoader(false)
        }

    }

    const removeOption = async (option) => {

        setLoading(true)
        try {
            const question_id = option?.question_id;
            const option_id = option?.id;

            const response = await new ApiHandler().removeCustomAssessmentOption(assessmentInfo?.id, question_id, option_id);

            fetchAssessment?.();
            Toast.show({
                type: 'success',
                position: 'top',
                text1: response?.data?.message,
            })

        } catch (error) {
            console.log(">>>> createCustom assessment error", error)
        } finally {
            setLoading(false)
        }

    }


    const Divider = () => {
        return <View style={styles.divider} />
    }



    const ViewQuestionsView = React.useMemo(() => {
        return (
            <View style={{ marginBottom: 50 }} >
                {
                    assessment_questions?.map((item, index) => {

                        const q_type = item?.format;
                        let qTypeText = "Single Select";
                        if (q_type === Q_TYPE_FREE_TEXT) {
                            qTypeText = "Free Text"
                        } else if (q_type === Q_TYPE_MULTIPLE) {
                            qTypeText = "Multi Select"
                        }
                        return (
                            <View
                                style={{ marginTop: index === 0 ? 0 : 40 }}
                                key={index}
                            >
                                <TextField
                                    label="Question"
                                    value={item.text}
                                    autoCapitalize="none"
                                    editable={false}
                                    right={questionEditable && <TextInput.Icon
                                        name="close"
                                        size={24}
                                        onPress={() => {
                                            if (questionEditable) {
                                                removeQuestion(item)
                                            }
                                        }}
                                    />}
                                />
                                {
                                    <Text
                                        style={[styles.addOption, { alignSelf: "flex-start", color: color_scheme_primary_button_background }]}
                                    >
                                        {qTypeText}
                                    </Text>
                                }

                                {
                                    item?.assessment_question_options?.map((option, idx) => {
                                        return (
                                            <TextField
                                                key={idx}
                                                label={`Option ${idx + 1}`}
                                                value={option?.text}
                                                autoCapitalize="none"
                                                editable={false}
                                                right={questionEditable && <TextInput.Icon
                                                    name="close"
                                                    size={20}
                                                    onPress={() => {
                                                        if (questionEditable) {
                                                            removeOption(option)
                                                        }
                                                    }}
                                                />}
                                            />

                                        )
                                    })
                                }
                                {
                                    q_type !== Q_TYPE_FREE_TEXT && questionEditable
                                    &&
                                    <>
                                        <TextField
                                            key={index}
                                            label={`Option ${item?.assessment_question_options?.length + 1}`}
                                            value={assessmentOptions[index]}
                                            onChangeText={(txt) => {

                                                const optionsList = [...assessmentOptions];
                                                optionsList[index] = txt;
                                                setAssessmentOptions(optionsList)

                                            }}
                                            autoCapitalize="none"
                                        />
                                        <TouchableOpacity
                                            style={[styles.addNewOption, { backgroundColor: color_scheme_primary_button_background }]}
                                            onPress={() => {

                                                const option = {
                                                    question_id: item?.id,
                                                    text: assessmentOptions[index],
                                                }
                                                addNewOption(option);

                                            }}
                                        >
                                            {
                                                addOptionLoader
                                                    ?
                                                    <ActivityIndicator
                                                        size={20}
                                                        animating
                                                        color="white"
                                                        style={{ marginRight: 10 }}
                                                    />
                                                    :
                                                    <Text style={styles.addQuestionTxt} >Add Option</Text>
                                            }
                                        </TouchableOpacity>
                                    </>
                                }

                                <Divider />
                            </View>
                        )
                    })

                }
            </View>
        )
    }, [assessmentOptions, assessmentOptions.length, addOptionLoader])

    const AddQuestionsView = React.useMemo(() => {
        return (

            <>
                <TextField
                    label="Question"
                    value={question}
                    autoCapitalize="none"
                    onChangeText={(text) => {
                        setQuestion(text);
                    }}
                />

                <View style={{ alignItems: "flex-start" }} >

                    <View style={{ flexDirection: "row", alignItems: "center" }} >
                        <Radio
                            onClick={() => { setQuestionType(Q_TYPE_SINGLE) }}
                            checked={questionType === Q_TYPE_SINGLE}
                            color={color_scheme_primary_button_background}
                        />
                        <Text
                            onPress={() => { setQuestionType(Q_TYPE_SINGLE) }}
                            style={[styles.radioText, { color: color_scheme_primary_button_background }]}>
                            {"Single Select"}
                        </Text>
                    </View>

                    <View style={{ flexDirection: "row", alignItems: "center" }} >
                        <Radio
                            onClick={() => { setQuestionType(Q_TYPE_MULTIPLE) }}
                            checked={questionType === Q_TYPE_MULTIPLE}
                            color={color_scheme_primary_button_background}
                        />
                        <Text
                            onPress={() => { setQuestionType(Q_TYPE_MULTIPLE) }}
                            style={[styles.radioText, { color: color_scheme_primary_button_background }]}>
                            {"Multi Select"}
                        </Text>
                    </View>

                    <View style={{ flexDirection: "row", alignItems: "center" }} >
                        <Radio
                            onClick={() => {
                                setQuestionType(Q_TYPE_FREE_TEXT)
                                setOptions(DEFAULT_OPTIONS)
                            }}
                            checked={questionType === Q_TYPE_FREE_TEXT}
                            color={color_scheme_primary_button_background}
                        />
                        <Text
                            onPress={() => {
                                setQuestionType(Q_TYPE_FREE_TEXT)
                                setOptions(DEFAULT_OPTIONS)
                            }}
                            style={[styles.radioText, { color: color_scheme_primary_button_background }]}>
                            {"Free Text Entry"}
                        </Text>
                    </View>


                </View>

                {
                    questionType !== Q_TYPE_FREE_TEXT
                    &&
                    <>
                        {
                            options?.map((option, idx) => {
                                return (
                                    <TextField
                                        key={idx}
                                        label={`Option ${idx + 1}`}
                                        value={option}
                                        autoCapitalize="none"
                                        onChangeText={(text) => {

                                            const optionsList = [...options];
                                            optionsList[idx] = text;
                                            setOptions(optionsList)
                                        }}
                                    />
                                )
                            })
                        }
                        <Text
                            style={[styles.addOption, { color: color_scheme_primary_button_background }]}
                            onPress={() => {
                                options.push("");
                                setOptions([...options])
                            }}
                        >
                            {"Add another option"}
                        </Text>
                    </>
                }
                <Divider />
            </>



        )
    }, [options, options.length, question, questionType])


    return (
        <>
            {assessment_questions.length > 0 && ViewQuestionsView}

            {
                questionEditable &&
                <>
                    {AddQuestionsView}


                    <TouchableOpacity
                        style={[styles.addQuestion, { backgroundColor: color_scheme_primary_button_background }]}
                        onPress={() => {
                            addQuestion();
                        }}
                    >{
                            isLoading
                                ?
                                <ActivityIndicator
                                    size={20}
                                    animating
                                    color="white"
                                    style={{ marginRight: 10 }}
                                />
                                :
                                <Text style={styles.addQuestionTxt} >Add question</Text>
                        }
                    </TouchableOpacity>
                </>
            }
        </>
    )
}


const styles = StyleSheet.create({
    divider: {
        height: 10,
        width: 20
    },
    targetCheckbox: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 12,
        lineHeight: 15,
        color: "#222B45",
    },

    addOption: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 13,
        lineHeight: 15,
        color: "#F05343",
        fontWeight: "500",
        paddingVertical: 8,
        alignSelf: "flex-end"
    },

    addQuestion: {
        width: 119,
        backgroundColor: "#F05343",
        borderRadius: 1.5,
        paddingVertical: 8,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 12
    },
    addQuestionTxt: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 12,
        lineHeight: 15,
        fontWeight: "600",
        color: "#fff"
    },
    addNewOption: {
        width: 100,
        backgroundColor: "#F05343",
        borderRadius: 2.5,
        paddingVertical: 4,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 6,
        alignSelf: "flex-end"
    },

    radioText: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 12,
        lineHeight: 15,
    },

})
