import auth from './auth'
import posts from './posts'
import profile from './profile'
import article from './article'
import teams from './teams'
import groups from './groups'
import employees from './employees'
import roles from './roles'
import pushNotifications from './pushNotifications'
import notifications from './notifications'
import branding from './branding'
import learning from './learning'
import sharedfolders from './sharedfolders'
import course from './course'
import event from './event'
import common from './common'
import subscription from './subscription'


export default {
  auth,
  posts,
  profile,
  article,
  teams,
  groups,
  employees,
  roles,
  pushNotifications,
  notifications,
  branding,
  learning,
  sharedfolders,
  course,
  event,
  common,
  subscription
}
