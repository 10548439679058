import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Like(props) {
  return (
    <Svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M0.899902 19.5935H4.4999V8.24083H0.899902V19.5935ZM20.6999 9.18689C20.6999 8.14622 19.8899 7.29477 18.8999 7.29477H13.2209L14.0759 2.97128L14.1029 2.66855C14.1029 2.28066 13.9499 1.92116 13.7069 1.66572L12.7529 0.672363L6.8309 6.90689C6.4979 7.24747 6.2999 7.7205 6.2999 8.24083V17.7014C6.2999 18.7421 7.1099 19.5935 8.0999 19.5935H16.1999C16.9469 19.5935 17.5859 19.1205 17.8559 18.4393L20.5739 11.7696C20.6549 11.552 20.6999 11.325 20.6999 11.079V9.18689Z" />
    </Svg>
  )
}

Like.propTypes = SvgProps

export default Like
