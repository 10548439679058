import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Folder(props) {
    return (
        <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M10 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V8C22 6.9 21.1 6 20 6H12L10 4Z" fill={props.color} />
        </Svg>
    )
}

Folder.propTypes = SvgProps

export default Folder;
