import React, { useContext, useEffect, useState } from 'react'
import { View, Text, Dimensions, Image } from 'react-native'
import ChatContext from '@/contexts/ChatContext';
import { formatUserName } from '@/utils/format';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate';
import { useIsMobile } from '@/styles';
import Reactions from './Reactions'



const { width } = Dimensions.get('window');

const Receiver = ({ reactions, updateAttributes, children, author, messageTime }) => {
    const { getParticipant } = useContext(ChatContext);
    const [userInfo, setUserInfo] = useState(null);
    const accentColor = UseBrandingAccentColor();
    const isMobile = useIsMobile();

    useEffect(() => {
        if (!userInfo) {
            const user = getParticipant(author);
            setUserInfo(user);
        }
    }, [])

    return (
        <View style={{ alignItems: "flex-start" }}>
            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <View>
                    <Text style={{ paddingVertical: 5, fontWeight: '600' }}>{formatUserName(userInfo?.first_name, userInfo?.last_name)}</Text>
                    <View style={{ padding: 10, borderRadius: 8, minWidth: width / 4, maxWidth: width / 1.8, overflow: 'hidden', backgroundColor: "#ccc" }}>
                        {children}
                        <View style={{ paddingTop: 10 }}>
                            <Text>{messageTime}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <Reactions
                reverse
                reactions={reactions}
                onReactionsChanged={(reactions) =>
                    updateAttributes({ reactions })
                }
            />
        </View>

    )
}

export default Receiver;