import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import { REHYDRATE } from 'redux-persist/lib/constants'

// Constants
export const constants = {
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  AUTH_LOGIN: createSagaAction('AUTH_LOGIN'),
  AUTH_SIGNUP: createSagaAction('AUTH_SIGNUP'),
  AUTH_FORGOT_PASSWORD: createSagaAction('AUTH_FORGOT_PASSWORD'),
  AUTH_RESET_PASSWORD: createSagaAction('AUTH_RESET_PASSWORD'),
  AUTH_SEND_CODE_PASSWORD: createSagaAction('AUTH_SEND_CODE_PASSWORD'),
  AUTH_TOGGLE_FIRST_ACCESS: createSagaAction('AUTH_TOGGLE_FIRST_ACCESS'),

  // onboarding pages
  AUTH_ONBOARDING_UPDATE_PROFILE: 'AUTH_ONBOARDING_UPDATE_PROFILE',
  AUTH_SIGNUP_USING_WORKSPACE_CODE: 'AUTH_SIGNUP_USING_WORKSPACE_CODE',
  AUTH_SELECTED_WORKSPACE: 'AUTH_SELECTED_WORKSPACE'
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  logout: () => ({
    type: constants.AUTH_LOGOUT,
  }),
  toggleFirstAccess: () => ({
    type: constants.AUTH_TOGGLE_FIRST_ACCESS.ACTION
  }),
  login: ({ email, password, remember, workspace_id }, next) => ({
    type: constants.AUTH_LOGIN.ACTION,
    email,
    password,
    remember,
    workspace_id,
    next,
  }),
  signUp: ({ name, email, password }, next) => ({
    type: constants.AUTH_SIGNUP.ACTION,
    name,
    email,
    password,
    next,
  }),
  forgotPassword: ({ email }, next) => ({
    type: constants.AUTH_FORGOT_PASSWORD.ACTION,
    email,
    next,
  }),
  resetPassword: ({ password, password_confirmation }, next) => ({
    type: constants.AUTH_RESET_PASSWORD.ACTION,
    password,
    password_confirmation,
    next,
  }),
  sendCodePassword: ({ code }, next) => ({
    type: constants.AUTH_SEND_CODE_PASSWORD.ACTION,
    code,
    next,
  }),
  // onboarding pages
  onboardingUpdateProfile: (data) => ({
    type: constants.AUTH_ONBOARDING_UPDATE_PROFILE,
    payload: data
  }),
  signUpUsingWorkspaceCode: (data) => ({
    type: constants.AUTH_SIGNUP_USING_WORKSPACE_CODE,
    payload: data
  }),
  selectedWorkspace: (data) => ({
    type: constants.AUTH_SELECTED_WORKSPACE,
    payload: data
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // REHYDRATE
  [REHYDRATE]: (state, action) => {
    const { payload: { auth: persisted } = {} } = action

    return {
      ...state,
      user: persisted?.user || initialState.user,
      member: persisted?.member || initialState.member,
      workspace: persisted?.workspace || initialState.workspace,
      token: (persisted?.token && !persisted?.first_access && persisted?.token) || initialState.token,
      selectedWorkspace: persisted?.selectedWorkspace,
      has_multiple_workspaces: persisted?.has_multiple_workspaces || initialState.has_multiple_workspaces
    }
  },

  // AUTH_LOGOUT
  [constants.AUTH_LOGOUT]: () => initialState,

  // AUTH_LOGIN
  [constants.AUTH_LOGIN.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.AUTH_TOGGLE_FIRST_ACCESS.ACTION]: (state) => {
    return { ...state, first_access: false }
  },
  [constants.AUTH_LOGIN.SUCCESS]: (state, action) => {
    const { access_token, first_access, user, member, workspace, has_multiple_workspaces } = action.payload
    return {
      ...state,
      first_access: first_access ?? false,
      isLoading: false,
      token: access_token,
      user: user,
      member: member,
      workspace: workspace,
      has_multiple_workspaces: has_multiple_workspaces
    }
  },
  [constants.AUTH_LOGIN.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // AUTH_SIGNUP
  [constants.AUTH_SIGNUP.SUCCESS]: (state, action) => {
    const { access_token, first_access, user, member, workspace } = action.payload
    return {
      ...state,
      first_access: first_access ?? false,
      isLoading: false,
      token: access_token,
      user: user,
      member: member,
      workspace: workspace
    }
  },

  // RESET_PASSWORD
  [constants.AUTH_RESET_PASSWORD.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      user: payload?.user ?? {}
    }
  },

  [constants.AUTH_RESET_PASSWORD.FAILED]: (state, action) => {
    return {
      ...state, error: action.message,
    }
  },

  [constants.AUTH_ONBOARDING_UPDATE_PROFILE]: (state, action) => {
    const { member } = action.payload
    return {
      ...state,
      member: member
    }
  },

  [constants.AUTH_SIGNUP_USING_WORKSPACE_CODE]: (state, action) => {
    const { access_token, first_access, user, member, workspace } = action.payload
    return {
      ...state,
      first_access: first_access ?? false,
      isLoading: false,
      token: access_token,
      user: user,
      member: member,
      workspace: workspace
    }
  },
  [constants.AUTH_SELECTED_WORKSPACE]: (state, action) => {
    if (action.payload == false) {
      return {
        ...state,
        selectedWorkspace: false,
        member: {},
        workspace: {},
        has_multiple_workspaces: state.has_multiple_workspaces
      }
    } else {
      const { member, workspace } = action.payload
      return {
        ...state,
        selectedWorkspace: true,
        member: member ?? state.member,
        workspace: workspace ?? state.workspace,
        has_multiple_workspaces: state.has_multiple_workspaces
      }
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  isLoading: false,
  token: null,
  first_access: false,
  user: {},
  member: {},
  workspace: {},
  roles: [],
  has_multiple_workspaces: false,
  selectedWorkspace: false,
  subscription_flow: false
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]
  switch (action.type) {
    case 'AUTH_SUBSCRIBE':
      const { access_token, first_access, user, member, workspace } = action.payload
      //console.log("ACTION PAYLOADDDDDD *************", action.payload)
      return {
        ...state,
        first_access: first_access ?? false,
        isLoading: false,
        token: access_token,
        user: user,
        member: member,
        workspace: workspace,
        subscription_flow: true
      }
  }
  return handler ? handler(state, action) : { ...state, isLoading: false }
})
