import { useEffect, useMemo, useRef, useContext, useState } from "react";
import {
    Client,
} from "@twilio/conversations";

import { getConversationParticipants, getToken } from "../../api";
import { handlePromiseRejection } from "../../helpers";

import ChatContext from "@/contexts/ChatContext";
import { useSelector } from "react-redux";
import { getMember } from "@/redux/selectors/auth";
import { View } from 'react-native'
import Conversations from './Conversations'
import ApiHandler from "@/api/ApiHandler";
import { useNavigation } from "@react-navigation/native";

async function loadUnreadMessagesCount(
    convo,
    updateUnreadMessages
) {
    const count = await convo.getUnreadMessagesCount();
    updateUnreadMessages(convo.sid, count ?? 0);
}

async function handleParticipantsUpdate(
    participant,
    updateParticipants
) {
    const result = await getConversationParticipants(participant.conversation);
    updateParticipants(result, participant.conversation.sid);
}

async function updateConvoList(
    client,
    conversation,
    setConvos,
    addMessages,
    updateUnreadMessages
) {
    if (conversation.status === "joined") {
        const messages = await conversation.getMessages();
        addMessages(conversation.sid, messages.items);
    } else {
        addMessages(conversation.sid, []);
    }

    loadUnreadMessagesCount(conversation, updateUnreadMessages);

    const subscribedConversations = await client.getSubscribedConversations();
    setConvos(subscribedConversations.items);
}

const AppContainerMobile = () => {
    /* eslint-disable */
    const sidRef = useRef("");
    sidRef.current = sid;
    const user = useSelector(getMember);
    const navigation = useNavigation();

    const {
        token,
        conversations,
        sid,
        client,
        setClient,
        addMessages,
        updateToken,
        updateLoadingState,
        updateParticipants,
        updateUnreadMessages,
        startTyping,
        endTyping,
        listConversations,
        removeMessages,
        removeConversation,
        updateCurrentConversation,
        addNotifications,
        workspaceMembers,
    } = useContext(ChatContext);


    const updateTypingIndicator = async (
        participant,
        sid,
        callback
    ) => {
        const { identity, } = participant;

        if (identity === user?.id?.toString()) {
            return;
        }

        let userInfo = workspaceMembers?.find(member => member.id == identity);

        if (!userInfo) {
            const res = await new ApiHandler().getChatParticipant({ id: identity });
            userInfo = res?.data?.data;
        }


        callback(sid, userInfo?.first_name);
    };
    useEffect(() => {
        const client = new Client(token);
        setClient(client);

        client.on("conversationAdded", async (conversation) => {
            conversation.on("typingStarted", (participant) => {
                handlePromiseRejection(
                    () =>
                        updateTypingIndicator(participant, conversation.sid, startTyping),
                    addNotifications
                );
            });

            conversation.on("typingEnded", (participant) => {
                handlePromiseRejection(
                    () => updateTypingIndicator(participant, conversation.sid, endTyping),
                    addNotifications
                );
            });

            handlePromiseRejection(async () => {
                if (conversation.status === "joined") {
                    const result = await getConversationParticipants(conversation);
                    updateParticipants(result, conversation.sid);
                }

                updateConvoList(
                    client,
                    conversation,
                    listConversations,
                    addMessages,
                    updateUnreadMessages
                );
            }, addNotifications);
        });

        client.on("conversationRemoved", (conversation) => {
            updateCurrentConversation("");
            navigation.goBack();
            handlePromiseRejection(() => {
                removeConversation(conversation.sid);
                updateParticipants([], conversation.sid);
            }, addNotifications);
        });

        client.on("conversationJoined", (conversation) => {
            updateConvoList(
                client,
                conversation,
                listConversations,
                addMessages,
                updateUnreadMessages,
            )
        })


        client.on("conversationLeft", (conversation) => {
            updateConvoList(client, conversation, listConversations, addMessages, updateUnreadMessages);
        })



        client.on("messageAdded", (message) => {
            if (message.author !== user?.id?.toString()) {
                addMessage(message, addMessages, updateUnreadMessages);
            }
        });
        client.on("participantLeft", (participant) => {
            handlePromiseRejection(
                () => handleParticipantsUpdate(participant, updateParticipants),
                addNotifications
            );
        });
        client.on("participantUpdated", (event) => {
            handlePromiseRejection(
                () => handleParticipantsUpdate(event.participant, updateParticipants),
                addNotifications
            );
        });
        client.on("participantJoined", (participant) => {
            handlePromiseRejection(
                () => handleParticipantsUpdate(participant, updateParticipants),
                addNotifications
            );
        });
        client.on("conversationUpdated", ({ conversation }) => {
            updateConvoList(
                client,
                conversation,
                listConversations,
                addMessages,
                updateUnreadMessages
            )
        });

        client.on("messageUpdated", ({ message }) => {
            handlePromiseRejection(
                () =>
                    updateConvoList(
                        client,
                        message.conversation,
                        listConversations,
                        addMessages,
                        updateUnreadMessages
                    ),
                addNotifications
            );
        });

        client.on("messageRemoved", (message) => {
            handlePromiseRejection(
                () => removeMessages(message.conversation.sid, [message]),
                addNotifications
            );
        });

        // client.on("pushNotification", (event) => {
        //   // @ts-ignore
        //   if (event.type != "twilio.conversations.new_message") {
        //     return;
        //   }

        //   if (Notification.permission === "granted") {
        //     showNotification(event);
        //   } else {
        //     console.log("Push notification is skipped", Notification.permission);
        //   }
        // });

        client.on("tokenExpired", () => {
            getToken(user.id).then(token => {
                updateToken(token);
            })
        });

        updateLoadingState(false);

        return () => {
            client?.removeAllListeners();
        };
    }, []);

    function addMessage(
        message,
        addMessages,
        updateUnreadMessages
    ) {
        //transform the message and add it to redux
        handlePromiseRejection(() => {
            if (sidRef.current === message.conversation.sid) {
                message.conversation.updateLastReadMessageIndex(message.index);
            }
            addMessages(message.conversation.sid, [message]);
            loadUnreadMessagesCount(message.conversation, updateUnreadMessages);
        }, addNotifications);
    }

    const openedConversation = useMemo(
        () => conversations.find((convo) => convo.sid === sid),
        [sid, conversations]
    );



    return (
        <View style={{ flex: 1, minHeight: '100%' }}>
            <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1, borderRightColor: '#ccc', borderRightWidth: 1 }}>
                    <Conversations />
                </View>
            </View>
        </View>
    )
}

export default AppContainerMobile;
