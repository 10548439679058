import React, { useEffect, useState } from 'react'
import * as BrandingSelectors from '@/redux/selectors/branding';
import { useSelector } from 'react-redux';


const initialBrandingColor = "#F05343";

const UseBrandingAccentColor = () => {
    const [accent, setAccent] = useState(initialBrandingColor);
    const branding = useSelector(BrandingSelectors.getSettings);

    const {color_scheme_accents} = branding;

    const getAccentColor = async () => {
        try {
            setAccent(color_scheme_accents);
        } catch(error){
            console.log(error);
        }
    }

    useEffect(() => {
         getAccentColor();
    }, [])

    return accent;
}

export default UseBrandingAccentColor;