import React, { useState } from 'react'
import { InputText, Container } from './styles'
const SearchWeb = ({ onChange, search }) => {
  return (
    <Container>
      <InputText
        value={search}
        onChangeText={onChange}
        placeholder="a tag ..."
      />
    </Container>
  )
}

export default SearchWeb
