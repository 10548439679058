import { getMember } from '@/redux/selectors/auth';
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Receiver from './Receiver';
import Sender from './Sender';
import { Text, Image, View, Modal, TouchableOpacity, Dimensions, SafeAreaView, Alert, Share, Platform, ScrollView } from 'react-native'
import { getFileUrl } from '../../../api';
import { Video } from 'expo-av';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Autolink from 'react-native-autolink';
import ChatContext from '@/contexts/ChatContext';
import AvatarTemplate from '@/components/Chat/components/AvatarTemplate';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import { formatUserName } from '@/utils/format';
import * as FileSystem from 'expo-file-system'
import * as MediaLibrary from 'expo-media-library';
import { ActivityIndicator } from 'react-native-paper';

const { width, height } = Dimensions.get('screen');

const Message = ({ message, getStatus, messageTime, onDeleteMessage, reactions, updateAttributes }) => {
    const user = useSelector(getMember);
    const [mediaUrl, setMediaUrl] = useState();
    const [status, setStatus] = useState({});
    const [preview, setPreview] = useState(null);
    const { workspaceMembers } = useContext(ChatContext);
    const [previewAuthor, setPreviewAuthor] = useState(null);
    const accentColor = UseBrandingAccentColor();
    const [loading, setLoading] = useState(false);
    const [permissionStatus, requestPermission] = MediaLibrary.usePermissions();
    const [preloadingImage, setPreloadingImage] = useState(false);


    const isImage = message.media?.contentType?.includes("image") ?? false;
    const isVideo = message.media?.contentType?.includes("video") ?? false;
    const unknownFile = message.media?.contentType?.includes("octet-stream") ?? false;

    useEffect(() => {
        if (preview && previewAuthor == null) {
            const author = preview?.author == user.id ? user : workspaceMembers.find(member => member.id == preview.author);
            setPreviewAuthor(author);
        }
    }, [preview])


    useEffect(() => {
        getStatus.then((value) => {
            setStatus(value)
        });
    }, [getStatus, message]);

    useEffect(() => {
        if (message.media) {
            getFileUrl(message.media).then(url => setMediaUrl(url));
        }
    }, [])

    const DownloadMedia = async (media, type) => {
        setLoading(true);
        try {
            const { uri } = await FileSystem.downloadAsync(media, FileSystem.documentDirectory + `file.${type == 'image' ? "jpg" : "mp4"}`);
            console.log("Finished Downloading to " + uri);
            setLoading(false);
            if (Platform.OS == 'ios') {
                await Share.share({
                    url: uri,
                    title: "",
                })
            } else {
                await saveFile(uri);
            }
        } catch (error) {
            setLoading(false);
            Alert.alert(error.message ?? error);
        }
    }

    const saveFile = async (fileUri) => {
        let finalStatus;
        const { status } = await MediaLibrary.getPermissionsAsync();
        finalStatus = status;
        if (finalStatus !== "granted") {
            const { status } = await MediaLibrary.requestPermissionsAsync();
            finalStatus = status;
        }

        if (finalStatus !== "granted") {
            console.log("Permission was denied for media library");
            return;
        }

        const asset = await MediaLibrary.saveToLibraryAsync(fileUri);


    }

    return (
        <>
            {message.author == user.id.toString() ? (
                <>
                    <Sender reactions={reactions} updateAttributes={updateAttributes} author={message.author} status={status} messageTime={messageTime} onDeleteMessage={onDeleteMessage}>
                        {isImage ? (
                            <TouchableOpacity onPress={() => setPreview({ type: "image", uri: mediaUrl, author: message.author })}>
                                <Image source={{ uri: mediaUrl }} resizeMode="cover" style={{ width: 160, aspectRatio: 16 / 9 }} />
                            </TouchableOpacity>
                        ) : isVideo ? (
                            <TouchableOpacity onPress={() => setPreview({ type: "video", uri: mediaUrl, author: message.author })}>
                                <Video isMuted shouldPlay source={{ uri: mediaUrl }} resizeMode="contain" style={{ width: 160, aspectRatio: 16 / 9 }} />
                            </TouchableOpacity>
                        ) : unknownFile ? (
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '90%', alignItems: 'center' }}>
                                <MaterialCommunityIcons name="file" size={30} color="#cccccc" />
                                <Text style={{ color: '#cccccc' }}>Unknown File Type</Text>
                            </View>
                        ) : (
                            <Autolink textProps={{ style: { color: 'white' } }} text={message.body} />
                        )}
                    </Sender>
                </>
            ) : (
                <>
                    <Receiver reactions={reactions} updateAttributes={updateAttributes} author={message.author} messageTime={messageTime}>
                        {isImage ? (
                            <TouchableOpacity onPress={() => setPreview({ type: "image", uri: mediaUrl, author: message.author })}>
                                <Image source={{ uri: mediaUrl }} resizeMode="cover" style={{ width: 160, aspectRatio: 16 / 9 }} />
                            </TouchableOpacity>
                        ) : isVideo ? (
                            <TouchableOpacity onPress={() => setPreview({ type: "video", uri: mediaUrl, author: message.author })}>
                                <Video isMuted shouldPlay source={{ uri: mediaUrl }} resizeMode="contain" style={{ width: 160, aspectRatio: 16 / 9 }} />
                            </TouchableOpacity>
                        ) : unknownFile ? (
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '90%', alignItems: 'center' }}>
                                <MaterialCommunityIcons name="file" size={30} color="#cccccc" />
                                <Text style={{ color: '#cccccc' }}>Unknown File Type</Text>
                            </View>
                        ) : (
                            <Autolink text={message.body} />
                        )}
                    </Receiver>
                </>
            )}
            <Modal visible={!!preview}>
                <View style={{ flex: 1 }}>
                    <SafeAreaView />
                    <View style={{ marginBottom: 10, flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 20, marginTop: 10, alignItems: 'center' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            {previewAuthor?.avatar ? (
                                <Image source={{ uri: previewAuthor?.avatar }} style={{ width: 40, height: 40, borderRadius: 50, backgroundColor: accentColor }} />
                            ) : <AvatarTemplate letter={previewAuthor?.first_name?.charAt(0)?.toUpperCase()} />}
                            <Text style={{ fontSize: 16, marginLeft: 10 }}>{formatUserName(previewAuthor?.first_name, previewAuthor?.last_name)}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <TouchableOpacity onPress={() => DownloadMedia(preview?.uri, preview.type)} style={{ flexDirection: 'row', padding: 5, borderWidth: 1, borderColor: '#555', borderRadius: 8, marginRight: 20 }}>
                                {!loading && (
                                    <MaterialCommunityIcons name="download" size={20} color="#555" />
                                )}
                                {!loading && (
                                    <Text style={{ color: '#555', fontSize: 16 }}>Download</Text>
                                )}
                                {loading && (
                                    <ActivityIndicator color={accentColor} />
                                )}
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setPreview(prev => {
                                setPreviewAuthor(null);
                                return null;
                            })}>
                                <MaterialCommunityIcons name="close" size={24} color='black' />
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{ flex: 1, position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                        {preloadingImage && (
                            <ActivityIndicator style={{ zIndex: 5000, position: 'absolute', top: width / 2, height: height / 2 }} color={accentColor} animating={preview?.type == 'image' && preloadingImage} />
                        )}
                        {preview?.type == 'image' ? (
                            <ScrollView>
                                <Image source={{ uri: preview?.uri }} onLoadStart={() => setPreloadingImage(true)} onLoadEnd={() => setPreloadingImage(false)} resizeMode="contain" style={{ width: width / 1.05, aspectRatio: 9 / 16 }} />
                            </ScrollView>
                        ) : (
                            <Video source={{ uri: preview?.uri }} resizeMode="contain" useNativeControls style={{ backgroundColor: 'black', width: width / 1.05, aspectRatio: 16 / 9 }} />
                        )}
                    </View>
                </View>
            </Modal>
        </>
    )
}

export default Message;