import PropTypes from 'prop-types'

export const PermissionInterface = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
})

export const RoleInterface = PropTypes.shape({
  name: PropTypes.string,
  permissions: PropTypes.arrayOf(PermissionInterface)
})
