import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, useWindowDimensions } from 'react-native'

import ApiHandler from '@/api/ApiHandler'
import CategoryView from '@/pages/Analytics/Components/Views/Category'
import { BackArrow } from '@/components'
import { useSideDrawer } from '@/hooks/sideDrawer'

import styles from './styles.js';

const LearningAnalytics = () => {
    const { open } = useSideDrawer(true)
    const [isOpenDrawer, setDrawer] = useState(false)
    const window = useWindowDimensions();
    useEffect(() => {

    }, [window])

    useEffect(() => {
        setDrawer(open)
    }, [open, setDrawer])

    const items = {
        average_engagement_time: {
            label: 'Average Course Engagement Time',
            code: 'average_engagement_time',
            type: 'chart',
            type_variant: 'line',
            type_datasets: {
                average_engagement_time: {
                    key: 'average_engagement_time',
                    label: 'Minutes',
                    dataTransformation: 'SecondsToMinutes'
                }
            },
            type_config: {
                yAxisPrecision: 2
            }
        },
        number_of_engagements: {
            label: 'Number Of Course Engagements',
            code: 'number_of_engagements',
            type: 'chart',
            type_variant: 'line',
            type_datasets: {
                number_of_engagements: {
                    key: 'number_of_engagements',
                    label: 'Engagements'
                }
            },
        },
        top_five_liked: {
            label: 'Top 5 Liked Courses',
            code: 'top_five_liked',
            type: 'list',
            type_variant: 'education_likes'
        },
        top_five_viewed: {
            label: 'Top 5 Viewed Courses',
            code: 'top_five_viewed',
            type: 'list',
            type_variant: 'education_views'
        },
    };

    const filters = {
        scope: true
    }

    return (
        <CategoryView
            title={'Learning Analytics'}
            apiHandlerFunction={new ApiHandler().getLearningAnalytics}
            items={items}
            filters={filters}
        />
    )
}

export default LearningAnalytics