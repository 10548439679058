import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function UploadFile(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M14.5125 4.53C14.0025 1.9425 11.73 0 9 0C6.8325 0 4.95 1.23 4.0125 3.03C1.755 3.27 0 5.1825 0 7.5C0 9.9825 2.0175 12 4.5 12H14.25C16.32 12 18 10.32 18 8.25C18 6.27 16.4625 4.665 14.5125 4.53ZM10.5 6.75V9.75H7.5V6.75H5.25L9 3L12.75 6.75H10.5Z" fill="#1B1B1B"/>
    </Svg>

  )
}

UploadFile.propTypes = Svg.propTypes
