import { NOTIFICATION_TIMEOUT, UNEXPECTED_ERROR_MESSAGE } from "./constants";
import moment from 'moment'

export async function updateCurrentConvo(
    setSid,
    convo,
    updateParticipants
) {
    setSid(convo.sid);

    try {
        const participants = await convo.getParticipants();
        updateParticipants(participants, convo.sid);
    } catch (e) {
        alert(e)
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
}

export function getMessageTime(message) {
    const dateCreated = message.dateCreated;
    const today = new Date();
    const diffInDates = Math.floor(today.getTime() - dateCreated.getTime());
    const dayLength = 1000 * 60 * 60 * 24;
    const diffInDays = Math.floor(diffInDates / dayLength);
    const minutesLessThanTen = dateCreated.getMinutes() < 10 ? "0" : "";
    if (diffInDays === 0) {
        return moment(dateCreated).format('hh:mm a');
    }
    return moment(dateCreated).format("MMMM Do, yyyy");
}

export function getLastMessageTime(messages) {
    if (messages === undefined || messages === null || messages.length === 0) {
        return "";
    }
    const lastMessageDate = messages[messages.length - 1].dateCreated;

    const today = new Date();
    const diffInDates = Math.floor(today.getTime() - lastMessageDate.getTime());
    const dayLength = 1000 * 60 * 60 * 24;
    const weekLength = dayLength * 7;
    const yearLength = weekLength * 52;
    const diffInDays = Math.floor(diffInDates / dayLength);
    const diffInWeeks = Math.floor(diffInDates / weekLength);
    const diffInYears = Math.floor(diffInDates / yearLength);
    if (diffInDays < 0) {
        return "";
    }
    if (diffInDays === 0) {
        return moment(lastMessageDate).format("h:mm A");
    }
    return moment(lastMessageDate).format("MMMM Do, yyyy");
}

export function getLastMessage(messages, typingData) {
    if (messages === undefined || messages === null) {
        return "Loading...";
    }
    if (typingData.length) {
        return getTypingMessage(typingData);
    }
    if (messages.length === 0) {
        return "No messages";
    }
    if (!!messages[messages.length - 1].media) {
        return "Media message";
    }
    return messages[messages.length - 1].body;
}

export function isMyMessage(messages, userId) {
    if (messages === undefined || messages === null || messages.length === 0) {
        return false;
    }
    return messages[messages.length - 1].author === userId
        ? messages[messages.length - 1]
        : false;
}

export const getTypingMessage = (typingData) =>
    typingData.length > 1
        ? `${typingData.length + " participants are typing..."}`
        : `${typingData[0] + " is typing..."}`;

export const pushNotification = (messages, func) => {
    // if (func) {
    //   func(
    //     messages.map(({ variant, message }) => ({
    //       variant,
    //       message,
    //       id: new Date().getTime(),
    //       dismissAfter: NOTIFICATION_TIMEOUT,
    //     }))
    //   );
    // }
};

export const successNotification = ({ message, addNotifications, }) => {


};

export const unexpectedErrorNotification = (addNotifications) => { };

export const handlePromiseRejection = async (
    func,
    addNotifications
) => {
    try {
        await func();
    } catch {
        return Promise.reject(UNEXPECTED_ERROR_MESSAGE);
    }
};
