import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Play(props) {
  return (
      <Svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <Path d="M2.76435 4.24487L8.65435 8.00017L2.76435 11.7555V4.24487ZM0.529053 0.176636V15.8237L12.8232 8.00017L0.529053 0.176636Z" fill={props.color} />
      </Svg>
    )
}

Play.propTypes = SvgProps

export default Play