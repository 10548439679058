import React from 'react'
import PropTypes from 'prop-types'
import { formatDistance } from 'date-fns'
import {
  Container,
  ViewMobile,
  Title,
  SubTitle,
  LastActivity,
  Deactivated,
  ButtonNavigation,
} from './styles'
import { ScreenSize, useScreenSize } from '@/styles'

const Group = ({ title, internalID, subTitle, updatedAt, deactivated, handleProfileButton }) => {
  const screenSize = useScreenSize()
  const idDesktop = screenSize >= ScreenSize.Medium
  const lastActivity = formatDistance(new Date(updatedAt), new Date())

  return (
    <Container screenSize={screenSize} >
      <ButtonNavigation onPress={handleProfileButton}>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </ButtonNavigation>
      <ViewMobile screenSize={screenSize} >
        <SubTitle>{subTitle}</SubTitle>
      </ViewMobile>
    </Container>
  )
}

Group.propTypes = {
  title: PropTypes.string,
  internalID: PropTypes.string,
  subTitle: PropTypes.string,
  updatedAt: PropTypes.string,
  deactivated: PropTypes.bool,
  handleProfileButton: PropTypes.func
}

export default Group
