import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
import { shadow1 } from '@/styles/shadows'
import { isMobile } from '@/styles'
import themes from '@/styles/themes/black'
import { Headline6Strong, Subtitle2, Paragraph2, Caption1 } from '@/styles/fonts'

const { width } = Dimensions.get('window')
import { ScreenSize, useScreenSize } from '@/styles' 

export const Container = styled.View` 
	position: relative;
	width: ${isMobile ? width * 0.9 : !isMobile && width >= ScreenSize.Medium ? 521 : !isMobile && width <= ScreenSize.Medium && width * 0.73}px;
	margin: 10px 0;
	border-radius: 5px;
  	background: ${themes.basics.background_white};
  	${shadow1}
	z-index: -1;
	padding-left: 16px;
	padding-bottom: 16px;
	padding-right: 10px;
`

export const Title = styled(Subtitle2)`
	align-items: center;
	color: #F05343;
	margin-top: 16px;
`

export const Header = styled(Headline6Strong)`
	align-items: center;
	color: ${themes.basics.black_text};
	margin-top: 16px;
	margin-bottom: 15px;
`

export const OptionView = styled.View`
	
`

export const OptionIndex = styled.TouchableOpacity`
	position: relative;
	width: 100%;
	border:2px solid ${({ selected, color }) => selected ? color : 'rgba(0, 0, 0, 0.2)'};
	margin-bottom: 12px;
    padding: 10px;
`

export const ContentCard1 = styled.Text`
`

export const SubmitButton = styled.TouchableOpacity`
	position: relative;
	width: 80px;
	height: 30px;
	justify-content: center;
	align-items: center;
	border:1px solid ${({ selected }) => selected ? themes.basics['black_text'] : themes.primary['alpha_8']};
	border-radius: 10px;
`

export const PostButtonWrapper = styled.View`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-end'};
`

export const PrecedingQuestion = styled(Caption1)`
	color: ${themes.basics.black_text};
	margin-top: 16px;
	font-size: 14px;
	line-height: 21px;
`
