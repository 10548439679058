import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { TouchableOpacity, Keyboard, View, StyleSheet, Image } from "react-native";
// import { useDispatch } from 'react-redux'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Avatar, FeedCardAuthorInfo, CommentList, Icon } from "@/components";
import theme from "@/styles/themes/black";
import themes from '@/styles/themes/black'
// import { actions as feedActions } from '@/redux/reducers/feed'
import { ScreenSize, useScreenSize } from "@/styles"
import { useRoute, useFocusEffect } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { posts } from "@/redux/selectors/posts";
import * as teamSelectors from "@/redux/selectors/teams";
import * as groupSelectors from "@/redux/selectors/groups";
import MentionsTextView from "@/components/MentionTextView";
import { isMobile } from "@/styles";
import { ScrollView } from 'react-native-gesture-handler';
import ApiHandler from '@/api/ApiHandler'
import { ActivityIndicator, ProgressBar } from "react-native-paper";
import PostContentCard from '@/components/Feed/Card/Content/Post'
import LearningContentCard from '@/components/Feed/Card/Content/Learning'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

import styles from './styles.js';

const PostView = ({ navigation }) => {
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const route = useRoute();
    const { post_id, contentType, navigatedFrom } = route.params;

    const ref = React.useRef()

    const getPostById = async () => {
        setLoading(true);
        try {
            const response = await new ApiHandler().getCommonPosts(post_id);
            if (response?.data) {
                setData(response?.data?.data)
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };

    const getArticleById = async () => {
        setLoading(true);
        try {
            const response = await new ApiHandler().getArticleById(post_id);
            if (response?.data) {
                setData(response?.data?.data)
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };

    const getEventById = async () => {
        setLoading(true);
        try {
            const response = await new ApiHandler().getEventById(post_id);
            if (response?.data) {
                setData(response?.data?.data)
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };

    const getCourseById = async () => {
        setLoading(true);
        try {
            const response = await new ApiHandler().getCourseById(post_id);
            if (response?.data) {
                setData(response?.data?.data)
                setLoading(false);
            }
        } catch (error) {
            console.log("ERROR", error);
            setLoading(false);
        } finally {
        }
    };

    useEffect(() => {
        console.log('Use Effect Fired')
        console.log(navigation)
        if (contentType == 'App\\Article') {
            getArticleById()
        } else if (contentType == 'App\\Event') {
            getEventById()
        } else if (contentType == 'App\\Course') {
            getCourseById()
        } else {
            getPostById()
        }
    }, [route])

    useFocusEffect(
        React.useCallback(() => {
            if (navigatedFrom == "notifications") {
                getPostById()
            } else {
                if (contentType == 'App\\Article') {
                    getArticleById()
                } else if (contentType == 'App\\Event') {
                    getEventById()
                } else if (contentType == 'App\\Course') {
                    getCourseById()
                } else {
                    getPostById()
                }
            }
            //navigation.setParams({ navigatedFrom: null })
        }, [post_id])
    )

    const cardOutput = (type) => {
        if (type == 'App\\Article') {
            return (
                <>
                    <LearningContentCard
                        post={data}
                        type={type}
                    />
                </>
            )
        }

        if (type == 'App\\Post') {
            return (
                <>
                    <PostContentCard
                        post={data}
                        type={type}
                    />
                </>
            )
        }

        if (type == 'App\\Course') {
            return (
                <>
                    <LearningContentCard
                        post={data}
                        type={type}
                    />
                </>
            )
        }

        if (type == 'App\\Event') {
            return (
                <>
                    <LearningContentCard
                        post={data}
                        type={type}
                    />
                </>
            )
        }
    }

    return (
        <>
            <PageHeader
                title={'Post'}
                icon={false}
                backEnabled={true}
            />

            <PageContent>
                <View style={styles.Container}>
                    <View style={styles.Card}>
                        {cardOutput(contentType)}
                    </View>
                </View>
            </PageContent>
        </>
    );
};

PostView.propTypes = {
    navigation: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        navigate: PropTypes.func.isRequired,
    }),
};

export default PostView;