import React from 'react';
import { Switch as RNToggleSwitch } from 'react-native';

const Switch = (props) => {
  const {
    isOn = false,
    width = 52,
    height = 32,
    onColor = "#F05343",
    offColor = "#C5CEE0",
    thumbSize = 28,
    onToggle = () => { } } = props || {};

  return (
      <RNToggleSwitch
        value={isOn}
        width={width}
        height={height}
        onColor={onColor}
        offColor={offColor}
        thumbSize={thumbSize}
        onValueChange={onToggle}
        trackColor={{ false: offColor, true: onColor }}
        thumbColor={'#fff'}
      />
  )
};

export default Switch;