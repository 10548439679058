import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigation, useRoute } from "@react-navigation/native";
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import { SimpleModal, Icon } from '@/components'
import {
    LeaveCard,
    CloseLeaveModalButton,
    LeaveText,
    ButtonsContainer,
    LeaveButton,
    LeaveButtonText,
} from './styles'
import { actions } from '@/redux/reducers/posts'
import { actions as teamActions } from "@/redux/reducers/teams";
import { actions as groupActions } from "@/redux/reducers/groups";
import { PostInterface } from '@/interfaces/post'

const DeletePostModal = ({
    setVisible,
    isVisible,
    post,
    onClose,
    attachmentType,
    attachmentId
}) => {
    const dispatch = useDispatch()
    const route = useRoute();
    const navigation = useNavigation();

    const handleOnlyClose = useCallback(() => {
        setVisible(false)
        if (onClose) {
            onClose(false)
        }
    }, [onClose])

    const handleDelete = useCallback(() => {
        dispatch(actions.delete({ id: post.id, }, () => {
            setVisible(false)

            if (attachmentType == 'App\\Team') {
                dispatch(teamActions.posts({ id: attachmentId }))
            } else if (attachmentType == 'App\\Group') {
                dispatch(groupActions.posts({ id: attachmentId }))
            } else {
                dispatch(
                    actions.feeds_index({ page: 1 }, (err) => {

                    })
                )
            }

            if (route.name == 'CommentPost') {
                navigation.navigate("Home");
            }

        }))
    }, [post, setVisible])

    return (
        <SimpleModal isVisible={isVisible}>
            <LeaveCard>
                <CloseLeaveModalButton onPress={handleOnlyClose}>
                    <Icon
                        icon="close"
                        color={theme.basics.brand}
                        height={14}
                        width={14}
                    />
                </CloseLeaveModalButton>
                <LeaveText>
                    Are you sure you want to delete this post?
                </LeaveText>
                <ButtonsContainer>
                    <LeaveButton cancel onPress={handleOnlyClose}>
                        <LeaveButtonText cancel >
                            Cancel
                        </LeaveButtonText>
                    </LeaveButton>
                    <LeaveButton onPress={handleDelete}>
                        <LeaveButtonText>
                            Delete the post
                        </LeaveButtonText>
                    </LeaveButton>
                </ButtonsContainer>
            </LeaveCard>
        </SimpleModal>
    )
}

DeletePostModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    setVisible: PropTypes.func.isRequired,
    post: PostInterface.isRequired,
    onClose: PropTypes.func,
}

export default DeletePostModal
