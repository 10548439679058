import React from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'
import { getAuthFirstAccess } from '@/redux/selectors/auth'
import Login from '@/pages/onboarding/login'
import SignUp from '@/pages/onboarding/signUp'
import OpenEmailApp from '@/pages/onboarding/openEmailApp'
import ForgotPassword from '@/pages/onboarding/forgotPassword'
import SendPasswordCode from '@/pages/onboarding/sendPasswordCode'
import Welcome from '@/routes/onboarding/welcome'
import SignupWorkspaceCode from '@/pages/onboarding/SignupWorkspaceCode'
import Subscribe from '@/pages/Subscribe'
import MobileWebBrowser from '@/pages/onboarding/mobileWebBrowserScreen/mobileWebBrowserScreen'

const Stack = createStackNavigator()

export default function Onboarding() {
  const isFirstAcces = useSelector(getAuthFirstAccess)

  return (
    <Stack.Navigator headerMode="none" initialRouteName={'Login'}>
      {isFirstAcces ? (
        <Stack.Screen name="WelcomeFlow" component={Welcome} />
      ) : (
        <>
          {/* <Stack.Screen name="WelcomeFlow" component={Welcome} /> */}
          <Stack.Screen name="MobileWebBrowser" component={MobileWebBrowser} />
          <Stack.Screen name="Login" component={Login} />
          <Stack.Screen name="SignUp" component={SignUp} />
          <Stack.Screen name="OpenEmailApp" component={OpenEmailApp} />
          <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
          <Stack.Screen name="SendPasswordCode" component={SendPasswordCode} />
          <Stack.Screen name="SignupWorkspaceCode" component={SignupWorkspaceCode} />
          <Stack.Screen name="Subscribe" component={Subscribe} />
        </>
      )}
    </Stack.Navigator>
  )
}
