import styled from 'styled-components/native'
import { StyleSheet, Platform, Dimensions } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import theme from '@/styles/themes/black'
import { Paragraph1, Paragraph2, Subtitle2, Headline4 } from '@/styles/fonts'
import Button from '../../../components/Button'
import { shadow2 } from '@/styles/shadows'
import { ScreenSize } from '@/styles'
import { TextField as TF } from '@/components'
import { isMobile } from '@/styles'

const { height, width } = Dimensions.get('window')

export const styles = StyleSheet.create({
	scroll: {
		width: '100%',
		flexGrow: 1,
		marginTop: 0,
		paddingHorizontal: Platform.OS !== "web" ? 12 : 0
	},
	otherArticles: {
		flexDirection: 'row',
		flex: 1,
		maxWidth: '100%',
		flexWrap: 'wrap',
		marginTop: 45,
		marginBottom: 45
	},
	select: {
		marginRight: 20,
	}
})

export const Container = styled.View`
	flex: 1;
	background-color: ${theme.basics.background_button};
	padding-left: ${({ isMobile }) => isMobile ? 0 : 45}px;
	align-items:  ${isMobile ? 'flex-start ' : null};
`

export const CheckboxContainer = styled.TouchableOpacity`
	display: flex;
	flex-flow: row;
`

export const BackButton = styled.TouchableOpacity`
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	right: 0;
	position: absolute;
`

export const CentralizedContainer = styled.View`
	flex: 1;
	margin-left: auto;
	margin-right: auto;
	background-color: ${theme.basics.background_button};
	width: 100%;
	padding:  ${({ isMobile }) => isMobile ? 15 : 0}px;
	max-width: 700px;
`
export const Actions = styled.View`
	flex: 1;
	background-color: ${theme.basics.background_button};
	width: 100%;
	max-width: 700px;
	margin-top: 15px;
	padding-bottom: 20px;
`

export const ScrollView = styled.ScrollView``

export const HeadLine = styled.View`
	flex-direction: row;
	justify-content: space-between;
	padding-right:${({ isMobile }) => isMobile ? 15 : 56}px;
`
export const Publish = styled.Text`
	font-size: 14px;
	margin-top: 20px;
	text-align: ${({ isMobile }) => isMobile ? 'center' : 'left'};
	color: ${theme.basics.special_gray};
`

export const TitleWrapper = styled.View`
    flex:0.8;
	flex-direction: row;
	align-items: center;
`

export const Header = styled.View`
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 40px 0 28px;
	z-index: 1;
`

export const SubTitle = styled.Text`
	font-family: 'WorkSans_500Medium';
	font-size: ${({ isMobile }) => isMobile ? 18 : 24}px;
	line-height: ${({ isMobile }) => isMobile ? 20 : 27}px;
	color: ${theme.basics.black_text};
	padding: 0 0 8px;
`

export const Title = styled.Text`
	margin: 0 0 0 ${({ isMobile }) => isMobile ? 0 : 10}px;
	font-family: 'WorkSans_400Regular';
	font-size: ${({ isMobile }) => isMobile ? 22 : 40}px;
	line-height: ${({ isMobile }) => isMobile ? 25 : 40}px;
	color: ${theme.basics.black_text};
	margin-left:10px;
`

export const LinkButton = styled.TouchableOpacity`
	padding: 0 ${({ isMobile }) => isMobile ? 16 : 0}px 0 0;
	align-items: center;
	justify-content: center;
`

export const LinkText = styled(Subtitle2)`
	color: ${theme.danger.standard};
`

export const SearchButton = styled(TouchableOpacity)`
	height: 24px;
	width: 24px;
`

export const WrapperCollapse = styled.View`
	align-self: flex-end;
	margin: 0 44px 0 0;
`

export const Select = styled.View`
	margin-right: 15px;
`

export const SubmitButton = styled(Button)`
  margin: 15px 0;
`

export const CreateButton = styled(Button)`
`

export const TextButton = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`

export const TopImage = styled.Image`
	width: 100%;
	height: 300px;
`

export const TopButtons = styled.View`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: center;
	position: absolute;
	right: 20px;
	top: 20px;
`

export const Category = styled.Text`
	flex: 1;
	border: 1px solid ${({ theme }) => theme.basics[300]};
	border-radius: 5px;
	padding: 3px;
	text-align: center;
	margin-right: 10px;
`

export const AuthorInfoContainer = styled.View`
	padding: 16px 0 0;
	align-items: flex-start;
	justify-content: flex-start;
`

export const AvatarContainer = styled.View`
	align-items: center;
	justify-content: center;
	margin: 16px;
	position: relative;
`

export const CreateFolderContainer = styled.View`
	position: relative;
	width:  ${isMobile ? width : '280px'};
	height: ${isMobile ? height - 300 : '220px'};
	justify-content: space-between;
	padding: 0 20px 5%;
	box-shadow: 13px 13px 13px #ccc;
	background: ${({ theme }) => theme.basics.background_white};
  align-self: center;
`
export const HeaderCreateFolder = styled.View`
	padding:30px 0px 0px 0px;
	position: relative;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 100%;
`
export const CloseButtonCreateFolder = styled.TouchableOpacity`
	z-index: 1;
	position: absolute;
	width: 15px;
	height: 15px;
	top: 8px;
	right: -12px;
`
export const HeaderTitleCreateFolder = styled(Headline4)`
	color: ${({ theme }) => theme.basics.black_text};
`


export const AuthorName = styled(Paragraph1)`
`

export const Footer = styled.View`
	width: 100%;
	flex-direction: row;
	align-content: center;
`

export const SubFooterInfo = styled.View`
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`

export const SubInfoText = styled(Paragraph2)`
	color: ${({ theme }) => theme.basics[400]};
`

export const CompanyImage = styled.Image`
	width:22px;
	height: 22px;
	border-radius: 22px;
	position: absolute;
	right: ${({ isMobile }) => isMobile ? -5 : 5}px;
	bottom: ${({ isMobile }) => isMobile ? -5 : 5}px;
`

export const TopButton = styled.TouchableOpacity`
	display: flex;
	margin-left: 5px;
	height: 40px;
	width: 40px;
	padding: 10px;
	align-content: center;
	justify-content: center;
	border-radius: 50%;
	background: ${({ theme }) => theme.basics.black_text};
`


export const Tabs = styled.View`
	display: flex;
	flex-direction: row;
	margin-bottom: 0px;
`

export const Tab = styled.TouchableOpacity`
	margin-right: 10px;
	
	
	

`

export const TabTitle = styled.Text`
	font-size: 18px;
	padding-left: ${({ isMobile }) => isMobile ? 15 : 40}px;
	padding-right: ${({ isMobile }) => isMobile ? 15 : 40}px;
	color: ${({ selected }) => selected ? '#FFF' : '#959595'};
	background-color: ${({ selected }) => selected ? '#F05343' : '#FFFFFF'};
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	padding-bottom: 10px;
	padding-top: 10px;
	
`

export const Role = styled.View`
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	padding: 15px;
	background: #fff;
`

export const Link = styled.TouchableOpacity`
	color: ${theme.primary.standard};
`

export const Card = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: #fff;
	padding: 15px;
	width:100%;
	padding-left:20px;
	padding-right: 20px;
`
export const CardView = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: #fff;
	padding: 15px;
	margin-bottom: 25px;
	height:100px;
	width:100%;
`
export const AvatarWrapper = styled.View`
justify-content: center;
align-items: center;
  position: relative;
  border-width:1px;
  border-radius:120px;
  width: ${({ screenSize }) => (screenSize <= ScreenSize.Medium ? 150 : 120)}px;
  height: ${({ screenSize }) => (screenSize <= ScreenSize.Medium ? 150 : 120)}px;
`


export const CircleCamera = styled.View`
	position: absolute;
	bottom: ${({ isMobile }) => isMobile ? -5 : -5}px;
	right: ${({ isMobile }) => isMobile ? -5 : -15}px;
	width: ${({ isMobile }) => isMobile ? 38 : 50}px;
	height: ${({ isMobile }) => isMobile ? 38 : 50}px;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	background-color: #fff;
	${shadow2};
	elevation: 3;
`
export const AvatarButton = styled.TouchableOpacity`
  max-width: ${({ screenSize }) => (screenSize <= ScreenSize.Medium ? 90 : 120)}px;
	align-self: center;
`

export const TextField = styled(TF)`

`
