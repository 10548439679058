import { all, put, call, fork, takeLatest } from 'redux-saga/effects'
import { constants } from '@/redux/reducers/event'
import * as api from '@/redux/api/event'

// STORE
function* getEvents(action) {
  try {
    const payload = yield call(api.getEventList, action.payload)
    yield put({ type: constants.GET_EVENT_LIST.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.GET_EVENT_LIST.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchGetEvents() {
  yield takeLatest(constants.GET_EVENT_LIST.ACTION, getEvents)
}

function* getEventWithId(action) {
  try {
    const payload = yield call(api.getEventWithId, action.payload)
    yield put({ type: constants.GET_EVENT_WITH_ID.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    console.log('event payload error',error)
    yield put({
      type: constants.GET_EVENT_WITH_ID.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchGetEventWithId() {
  yield takeLatest(constants.GET_EVENT_WITH_ID.ACTION, getEventWithId)
}

function* createEvent(action) {
    try {
      const payload = yield call(api.createEvent, action.payload)
      yield put({ type: constants.CREATE_EVENT.SUCCESS, payload })
      action.next && action.next(undefined, payload)
    } catch (error) {
      yield put({
        type: constants.CREATE_EVENT.FAILED,
        message: error.message || error,
      })
      action.next && action.next(error)
    }
  }
  
function* watchCreateEvent() {
  yield takeLatest(constants.CREATE_EVENT.ACTION, createEvent)
}

function* updateEvent(action) {
  try {
    const payload = yield call(api.updateEvent, action.payload)
    yield put({ type: constants.UPDATE_EVENT.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.UPDATE_EVENT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchUpdateEvent() {
  yield takeLatest(constants.UPDATE_EVENT.ACTION, updateEvent)
}

function* getFeaturedEvents(action) {
  try {
    const payload = yield call(api.getEventList, action.payload)
    yield put({ type: constants.GET_FEATURED_EVENT.SUCCESS, payload })
    // action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.GET_FEATURED_EVENT.FAILED,
      message: error.message || error,
    })
    // action.next && action.next(error)
  }
}

function* watchGetFeaturedEvent() {
  yield takeLatest(constants.GET_FEATURED_EVENT.ACTION, getFeaturedEvents)
}

function* getReadLaterEvents(action) {
  try {
    const payload = yield call(api.getEventList, action.payload)
    yield put({ type: constants.GET_READ_LATER_EVENT.SUCCESS, payload })
    // action.next && action.next(undefined, payload)
  } catch (error) {
    console.log('event error',error)
    yield put({
      type: constants.GET_READ_LATER_EVENT.FAILED,
      message: error.message || error,
    })
    // action.next && action.next(error)
  }
}

function* watchGetReadLaterEvent() {
  yield takeLatest(constants.GET_READ_LATER_EVENT.ACTION, getReadLaterEvents)
}

function* toggleFeaturedEvent(action) {
  try {
    const payload = yield call(api.toggleFeatured, action.payload)
    yield put({ type: constants.TOGGLE_FEATURED_EVENT.SUCCESS })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.TOGGLE_FEATURED_EVENT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error.message || 'error')
  }
}

function* watchToggleFeatureEvent() {
  yield takeLatest(
    constants.TOGGLE_FEATURED_EVENT.ACTION,
    toggleFeaturedEvent,
  )
}

function* toggleStatusEvent(action) {
  try {
    const payload = yield call(api.toggleStatus, action.payload)
    yield put({ type: constants.TOGGLE_STATUS_EVENT.SUCCESS })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.TOGGLE_STATUS_EVENT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error.message || 'error')
  }
}

function* watchToggleStatusEvent() {
  yield takeLatest(constants.TOGGLE_STATUS_EVENT.ACTION, toggleStatusEvent)
}

function* toggleReadLaterEvent(action) {
  try {
    const payload = yield call(api.toggleReadLater, action.payload)
    yield put({ type: constants.TOGGLE_READ_LATER_EVENT.SUCCESS })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.TOGGLE_READ_LATER_EVENT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchToggleReadLaterEvent() {
  yield takeLatest(
    constants.TOGGLE_READ_LATER_EVENT.ACTION,
    toggleReadLaterEvent,
  )
}

function* deleteEvent(action) {
  try {
    const payload = yield call(api.deleteEvent, action.payload)
    yield put({ type: constants.DELETE_EVENT.SUCCESS })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.DELETE_EVENT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error.message)
  }
}

function* watchDeleteEvent() {
  yield takeLatest(constants.DELETE_EVENT.ACTION, deleteEvent)
}

// TRACK TIME
function* trackTime(action) {
  try {
   const {response,error} = yield call(api.trackActiveEventTime, action)
   yield put({ type: constants.TRACKING_TIME_EVENT.SUCCESS })
   action.next && action.next(undefined, payload)
 } catch (error) {
   yield put({
     type: constants.TRACKING_TIME_EVENT.FAILED,
     message: error.message || error,
   })
   action.next && action.next(error)
 }
}

function* watchTrackTime() {
 yield takeLatest(constants.TRACKING_TIME_EVENT.ACTION, trackTime)
}

export function* rootSaga() {
  yield all([
    fork(watchCreateEvent),
    fork(watchGetEvents),
    fork(watchGetEventWithId),
    fork(watchUpdateEvent),
    fork(watchGetFeaturedEvent),
    fork(watchGetReadLaterEvent),
    fork(watchToggleFeatureEvent),
    fork(watchToggleStatusEvent),
    fork(watchToggleReadLaterEvent),
    fork(watchDeleteEvent),
    fork(watchTrackTime),
  ])
}
  