import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function CommunityIcon(props) {
  return (
    <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M3.66547 3.59235V0.814575H5.51732V3.59235H8.2951V5.4442H5.51732V8.22198H3.66547V5.4442H0.887695V3.59235H3.66547ZM6.44325 9.14791V6.37013H9.22103V3.59235H15.7025L17.397 5.4442H20.3321C21.3507 5.4442 22.184 6.27754 22.184 7.29606V18.4072C22.184 19.4257 21.3507 20.259 20.3321 20.259H5.51732C4.49881 20.259 3.66547 19.4257 3.66547 18.4072V9.14791H6.44325ZM12.9247 17.4812C15.4803 17.4812 17.5544 15.4072 17.5544 12.8516C17.5544 10.2961 15.4803 8.22198 12.9247 8.22198C10.3692 8.22198 8.2951 10.2961 8.2951 12.8516C8.2951 15.4072 10.3692 17.4812 12.9247 17.4812ZM9.96177 12.8516C9.96177 14.4905 11.2858 15.8146 12.9247 15.8146C14.5636 15.8146 15.8877 14.4905 15.8877 12.8516C15.8877 11.2127 14.5636 9.88865 12.9247 9.88865C11.2858 9.88865 9.96177 11.2127 9.96177 12.8516Z" fill={props.color} />
    </Svg>
  )
}

CommunityIcon.propTypes = SvgProps

export default CommunityIcon
