import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/course'
import * as selectorsCommon from '@/redux/selectors/common'
import { actions } from '@/redux/reducers/course'
import { actions as actionsCommon } from '@/redux/reducers/common'
import { useNavigation, useRoute } from '@react-navigation/native'
import { BackArrow, Carousel, SearchWeb } from '@/components'
import CourseCard from '@/components/Learning/CourseCard'
import SimpleSelect from '@/components/SimpleSelect'
import { ProgressBar } from 'react-native-paper'
import { useIsMobile } from '@/styles'
import {
  Container,
  Title,
  HeadLine,
  SubTitle,
  Header,
  TitleWrapper,
  Select,
  styles,
  ClearButton,
  CreateButton,
  CreateText,
} from '../styles'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import * as brandSelectors from '@/redux/selectors/branding'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ModalV1 from '@/components/ModalV1'
import Card from '@/components/Learning/Card'

const Courses = () => {
  const [searchTxt, setSearchTxt] = useState('')
  const [filtered, setFiltered] = useState({
    employee_lifecycle_stage_id: null,
    five_s_category_id: null,
  })
  const isMobile = useIsMobile()
  const courses = useSelector(selectors.courses)
  const featured = useSelector(selectors.featured)
  const readLater = useSelector(selectors.readLater)
  const readLaterLoading = useSelector(selectors.readLaterLoading)
  const featuredLoading = useSelector(selectors.featuredLoading)
  const statusLoading = useSelector(selectors.statusLoading)

  const loading =
    useSelector(selectors.loading) ||
    readLaterLoading ||
    featuredLoading ||
    statusLoading

  const lifecycles = useSelector(selectorsCommon.lifecycles)
  const categories = useSelector(selectorsCommon.categories)
  const navigation = useNavigation()
  const route = useRoute()
  const branding = useSelector(brandSelectors.getSettings)

  const [filterModalVisible, setFilterModalVisible] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    navigation.addListener('focus', () => {
      dispatch(actions.getFeaturedAction({ featured: 1 }, () => { }))
      dispatch(actions.getReadLaterAction({ read_later: 1 }, () => { }))
      dispatch(actions.getList({ ...filtered, search: searchTxt }, () => { }))
      dispatch(actionsCommon.getFiveSCategoriesAction({}, () => { }))
      dispatch(actionsCommon.getLifecycleStagesAction({}, () => { }))
    })
  }, [])

  useEffect(() => {
    if (!readLaterLoading) {
      dispatch(actions.getFeaturedAction({ featured: 1 }, () => { }))
      dispatch(actions.getReadLaterAction({ read_later: 1 }, () => { }))
      dispatch(actions.getList({ ...filtered, search: searchTxt }, () => { }))
    }
  }, [readLaterLoading])

  useEffect(() => {
    dispatch(actions.getFeaturedAction({ featured: 1 }, () => { }))
    dispatch(actions.getReadLaterAction({ read_later: 1 }, () => { }))
    dispatch(actions.getList({ ...filtered, search: searchTxt }, () => { }))
  }, [filtered, searchTxt])

  const handleCreateCourse = useCallback(() => {
    navigation.navigate('CreateCourse', {
      previus_route_name: route?.name || '',
      id: null,
      course: null
    })
  }, [])

  const handleFilterChange = (type, value) => {
    filtered[type] = value
    setFiltered({ ...filtered })
  }

  console.log('courses', courses)
  console.log('featured', featured)
  console.log('readLater', readLater)
  return (
    <>
      <PageHeader
        title={'Courses'}
        icon={'education'}
        backEnabled={true}
        breadcrumbs={[
          { name: 'Learning', navigation: 'Learning' },
          { name: 'Courses' },
        ]}
        actions={
          [
            {
              title: 'Create',
              type: 'menu',
              permissions: [
                'CREATE_LEARNING_ARTICLES',
              ],
              menu: [
                {
                  title: 'Create Course',
                  icon: false,
                  permissions: [
                    'CREATE_LEARNING_ARTICLES'
                  ],
                  onPress: () => {
                    handleCreateCourse()
                  }
                }
              ]
            }
          ]
        }
      />

      <PageContent>
        <ContentBlockV1
          title={'Featured'}
        >
          <Carousel>
            {featured.map((course, key) => (
              <Card
                type={'course'}
                setReadLater={() => {
                  dispatch(
                    actions.toggleReadLaterAction(
                      { id: course.id },
                      () => { },
                    ),
                  )
                }}
                brandingSetting={branding}
                key={'feat_course_' + key + '_' + course.id}
                {...course}
              />
            ))}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={'Read Later'}
        >
          <Carousel>
            {readLater.map((course, key) => (
              <Card
                type={'course'}
                setReadLater={() => {
                  dispatch(
                    actions.toggleReadLaterAction(
                      { id: course.id },
                      () => { },
                    ),
                  )
                }}
                brandingSetting={branding}
                key={'feat_course_' + key + '_' + course.id}
                {...course}
              />
            ))}
          </Carousel>
        </ContentBlockV1>

        <ContentBlockV1
          title={'All Courses'}
          action={{
            onPress: () => {
              setFilterModalVisible(true)
            },
            icon: 'searchIcon'
          }}
        >
          <View style={{
            flexDirection: 'row',
            flex: 1,
            maxWidth: '100%',
            flexWrap: 'wrap',
          }}>
            {courses && courses.length ? (
              courses.map((course, key) => (
                <Card
                  type={'course'}
                  setReadLater={() => {
                    dispatch(
                      actions.toggleReadLaterAction(
                        { id: course.id },
                        () => { },
                      ),
                    )
                  }}
                  key={'course_' + key + '_' + course.id}
                  {...course}
                />
              ))
            ) : (
              <Text>No course was found using this filter.</Text>
            )}
          </View>
        </ContentBlockV1>
      </PageContent>

      <ModalV1
        isVisible={filterModalVisible}
        setVisibility={setFilterModalVisible}
        title={'Search'}
        subtitle={'Utilize these filters to find the courses that you are looking for.'}
      >
        <HeadLine>
          <SubTitle isMobile={isMobile}>I'm looking for</SubTitle>
          <SearchWeb
            onChange={(val) => {
              setSearchTxt(val)
            }}
            search={searchTxt}
          />
        </HeadLine>
        <View style={styles.dropDownView}>
          <Select>
            <SimpleSelect
              list={lifecycles}
              style={styles.select}
              label="Lifecycle Stage"
              value={filtered.employee_lifecycle_stage_id}
              setValue={(value) =>
                handleFilterChange('employee_lifecycle_stage_id', value)
              }
            />
          </Select>
          <Select>
            <SimpleSelect
              list={categories}
              style={styles.select}
              label="Categories"
              value={filtered.five_s_category_id}
              setValue={(value) =>
                handleFilterChange('five_s_category_id', value)
              }
            />
          </Select>
          <ClearButton
            onPress={() => {
              setSearchTxt('')
              setFiltered({
                employee_lifecycle_stage_id: null,
                five_s_category_id: null,
              })
            }}
          >
            <Text style={{ color: 'white' }}>Clear</Text>
          </ClearButton>
        </View>
      </ModalV1>
    </>
  )
}

export default Courses
