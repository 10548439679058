import styled from 'styled-components/native'
import { Dimensions, StyleSheet } from 'react-native'
import { Headline5, Headline6SemiBold, Subtitle1SemiBold } from '@/styles/fonts'
import { shadow1 } from '@/styles/shadows'
import { isMobile } from '@/styles'

const { width, height } = Dimensions.get('window')

export const styles = StyleSheet.create({
  contentScroll: {
    width,
    height,
    alignSelf: 'center'
  },
  scroll: {
    width,
    height,
  }
})

export const Container = styled.View`
	height: ${isMobile ? height : 588}px;
	width: ${isMobile ? width : 341}px;
	padding: 0 20px 20px;
	background: ${({ theme }) => theme.basics.background_white};
`

export const Header = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 16px 0 24px;
	z-index: 1;
	position: relative;
	background: ${({ theme }) => theme.basics.background_white};
`

export const CloseButton = styled.TouchableOpacity`
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
	right: 0;
	position: absolute;
`

export const Title = styled(Headline5)``

export const ContainerRoles = styled.View`

`

export const RolesTitle = styled.Text`
	font-size: 15px;
	line-height: 20px;
	color: #6d6d6d;
	margin: 38px 0 0;
`

export const RolesContainer = styled.View`
	flex-direction: row;
	align-content: center;
	margin: 16px 0 0;
	flex-direction: column;
`


export const RolesRow = styled.View`
	flex-direction: row;
	align-content: center;
`


export const RoleText = styled(Subtitle1SemiBold)`
	text-align: center;
	color: ${({ theme }) => theme.basics.brand};
	align-items: center;
	align-self: center;
`

export const Description = styled.Text`
	color: ${({ theme }) => theme.basics.brand};
	width: 276px;
	align-self: flex-end;
`

export const DeleteButton = styled.TouchableOpacity`
	width: 190px;
	align-self: ${isMobile ? 'center' : 'flex-end'};
	align-items: center;
	justify-content: center;
	margin: 16px 0 0;
`
export const DeleteButtonText = styled.Text`
	font-family: 'WorkSans_400Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	color: #F05343;
`

export const ContainerDeleteConfirmation = styled.View`
	position: relative;
	width: 377px;
	height: 184px;
	background: ${({ theme }) => theme.basics.background_white};
	border-radius: 5px;
	${shadow1}
	align-items: center;
`

export const ContainerDeleteConfirmationMobile = styled.View`
	position: relative;
	width: 377px;
	height: 200px;
	background: ${({ theme }) => theme.basics.background_white};
	border-radius: 5px;
	${shadow1}
	align-items: center;
`

export const CloseButtonDelete = styled.TouchableOpacity`
	align-self: flex-end;
	margin: 13px 13px 0 0;
	align-items: center;
	justify-content: center;
`

export const TextWarning = styled.Text`
	font-size: ${isMobile ? 18 : 24}px;
	line-height: ${isMobile ? 20 : 27}px;
	font-family: ${isMobile ? 'WorkSans_500Medium' : 'WorkSans_400Regular'};
	color: ${({ theme }) => theme.basics.brand};
	width: 290px;
	text-align: center;
`

export const ButtonsContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 35px 0 0;
`


export const LeaveButton = styled.TouchableOpacity`
	background-color: ${({ theme, cancel }) => cancel ? theme.basics.background_white : theme.basics.brand};
	align-items: center;
	justify-content: center;
	width: ${({ width }) => width || 164}px;
	height: 40px;
	margin: 10px 8px 6px;
	border: 1px solid ${({ theme }) => theme.basics.brand};
	border-radius: 3px;
`

export const LeaveButtonText = styled(Headline6SemiBold)`
	width: 100%;
	text-align: center;
	color: ${({ theme, cancel }) => cancel ? theme.basics.brand : theme.basics.background_white};
`

export const ContainerActivated = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 15px 0 0;
`


export const Label = styled.Text`
	font-family: 'WorkSans_600SemiBold';
	font-size: 12px;
	line-height: 22px;
	color: ${({ theme }) => theme.basics.brand};
	margin: 0 20px 0 0;
`
