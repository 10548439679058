import React, { useState, useEffect } from 'react'
import { View, Text, ScrollView, useWindowDimensions } from 'react-native'

import ApiHandler from '@/api/ApiHandler'
import CategoryView from '@/pages/Analytics/Components/Views/Category'
import { BackArrow } from '@/components'
import { useSideDrawer } from '@/hooks/sideDrawer'

import styles from './styles.js';

const MemberAnalytics = () => {
  const { open } = useSideDrawer(true)
  const [isOpenDrawer, setDrawer] = useState(false)
  const window = useWindowDimensions();
  useEffect(() => {

  }, [window])

  useEffect(() => {
    setDrawer(open)
  }, [open, setDrawer])

  const items = {
    average_engagement_time: {
      label: 'Average Engagement Time',
      code: 'average_engagement_time',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        average_engagement_time: {
          key: 'average_engagement_time',
          label: 'Minutes',
          dataTransformation: 'SecondsToMinutes'
        }
      },
      type_config: {
        yAxisPrecision: 2
      }
    },
    number_of_engagements: {
      label: 'Number Of Engagements',
      code: 'number_of_engagements',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        number_of_engagements: {
          key: 'number_of_engagements',
          label: 'Engagements'
        }
      },
    },
    number_of_logins: {
      label: 'Number Of Logins',
      code: 'number_of_logins',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        number_of_logins: {
          key: 'number_of_logins',
          label: 'Logins'
        }
      },
    },
    number_of_posts: {
      label: 'Number Of Posts',
      code: 'number_of_posts',
      type: 'chart',
      type_variant: 'line',
      type_datasets: {
        number_of_posts: {
          key: 'number_of_posts',
          label: 'Posts'
        }
      },
    }
  };

  const filters = {
    scope: true
  }

  return (
    <CategoryView
      title={'Member Engagement Analytics'}
      apiHandlerFunction={new ApiHandler().getMemberAnalytics}
      items={items}
      filters={filters}
    />
  )
}

export default MemberAnalytics
