import React from "react";
import {
	StyleSheet,
	Text,
	View,
	Dimensions,
	TouchableOpacity,
	Image,
} from "react-native";
const Width = Dimensions.get("window").width;

const Button = ({ title, onPress, Icon, source }) => {
	return (
		<TouchableOpacity onPress={onPress} style={styles.button}>
			<Image source={source} style={{ height: 25, width: 25, marginEnd: 8 }} />
			<Text style={styles.title}>{title}</Text>
			{Icon}
		</TouchableOpacity>
	);
};

export default Button;

const styles = StyleSheet.create({
	button: {
		flexDirection: "row",
		height: Width >= 600 ? 45 : 50,
		width: Width >= 600 ? 280 : "100%",
		backgroundColor: "#000",
		justifyContent: Width <= 600 ? "center" : null,
		paddingHorizontal: 20,
		alignItems: "center",
		marginTop: 10,
		borderRadius: 4,
	},
	title: {
		fontWeight: "700",
		fontSize: 16,
		lineHeight: 18,
		color: "#fff",
		marginEnd: 8,
	},
});
