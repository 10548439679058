import React, { useEffect, useState } from 'react'
import { View, ScrollView, Text, Image, useWindowDimensions, SafeAreaView, RefreshControl, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/article'
import { actions as articleActions } from '@/redux/reducers/article'
import { actions as eventActions } from '@/redux/reducers/event'
import { actions as courseActions } from '@/redux/reducers/course'
import { getMember } from '@/redux/selectors/auth'
import { Avatar, BackArrow } from '@/components'
import { ProgressBar } from 'react-native-paper'
import { WebView } from 'react-native-webview'
import HTML from 'react-native-render-html';
import tableRenderers, { useHtmlTableProps, HTMLTable } from '@native-html/heuristic-table-plugin';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import Toast from 'react-native-toast-message'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import ApiHandler from '@/api/ApiHandler'

import ContentBlockV1 from '@/components/ContentBlockV1'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

import styles from './styles.js';

const LearningView = ({ id, type, image, title, excerpt, employee_lifecycle_stage, five_s_category, creator, created_at, children }) => {
    const navigation = useNavigation()
    const dispatch = useDispatch()

    let startTime = new Date()

    const storeTracking = () => {
        const endTime = new Date()
        const elapsed = endTime - startTime
        const seconds = elapsed / 1000

        if (type == 'article') {
            dispatch(
                articleActions.trackActiveTime({
                    timeTracked: seconds,
                    articleId: id,
                }),
            )
        }

        if (type == 'event') {
            dispatch(
                eventActions.trackActiveTime({
                    timeTracked: seconds,
                    eventId: id,
                }),
            )
        }

        if (type == 'course') {
            dispatch(
                courseActions.trackActiveTime({
                    timeTracked: seconds,
                    courseId: id,
                }),
            )
        }
    }

    useEffect(() => {
        navigation.addListener('blur', (e) => {
            storeTracking();
        })

        navigation.addListener('focus', (e) => {
            startTime = new Date()
        })

        navigation.addListener('beforeRemove', (e) => {
            storeTracking();
        })
    }, [])

    return (
        <>
            <View style={styles.Container}>
                <View style={styles.ImageContainer}>
                    <Image
                        style={styles.Image}
                        source={image ? { uri: image } : PlaceholderImageCover}
                        resizeMode="cover"
                    />
                </View>

                <ContentBlockV1>
                    <View style={styles.TitleContainer}>
                        <Text style={styles.Title}>{title}</Text>
                    </View>

                    {employee_lifecycle_stage || five_s_category ? (
                        <View style={styles.TagsContainer}>
                            {employee_lifecycle_stage ? (
                                <Text style={styles.Tag}>{employee_lifecycle_stage}</Text>
                            ) : null}

                            {five_s_category ? (
                                <Text style={styles.Tag}>{five_s_category}</Text>
                            ) : null}
                        </View>
                    ) : null}

                    {excerpt && (
                        <View style={styles.ExcerptContainer}>
                            <Text style={styles.Excerpt}>{excerpt}</Text>
                        </View>
                    )}

                    <View style={styles.AuthorContainer}>
                        <View style={styles.AuthorAvatarContainer}>
                            <Avatar
                                source={creator?.avatar ? { uri: creator?.avatar } : null}
                                resizeMode="cover"
                                size={45}
                            />
                        </View>
                        <View style={styles.AuthorDetailsContainer}>
                            <Text style={styles.AuthorName}>{`${creator?.first_name ?? ''} ${creator?.last_name ?? ''}`}</Text>

                            {creator?.role ? (
                                <Text style={styles.AuthorRole}>{`${creator?.role ?? ''}`}</Text>
                            ) : null}
                        </View>
                    </View>

                    <View style={styles.ContentContainer}>
                        {children}
                    </View>

                    <View style={styles.DateContainer}>
                        <Text style={styles.Date}>Published {created_at && format(new Date(created_at), 'MM/dd/yyyy')}</Text>
                    </View>
                </ContentBlockV1>
            </View>
        </>
    )
}

export default LearningView
