import { StyleSheet, Text, View, Image, TouchableOpacity, Linking } from 'react-native'
import React from 'react'
import Button from '../../Subscribe/components/button'
import AppStoreImg from '../../../assets/apple-store.png'
import GooglePlayImg from '../../../assets/google-play.png'
import ReframeImg from '../../../assets/brand/Icon_96.png'

import TheaterLayout from '@/components/Layout/Page/Layouts/Theater'
import ContentBlockV1 from '@/components/ContentBlockV1'

const MobileWebBrowser = () => {
	const AppStore = () => {
		let href = "https://apps.apple.com/us/app/id1584877948";
		window.open(href, "_blank");
	};
	const GooglePlay = () => {
		let href =
			"https://play.google.com/store/apps/details?id=com.getreframe.crossplatform";
		window.open(href, "_blank");
	};

	return (
		<TheaterLayout>
			<View style={styles.container}>
				<ContentBlockV1>
					<View style={styles.textContainer}>
						{/*<Text style={styles.topText}>
						{"Get the Reframe app"}
					</Text>*/}
						<Text style={styles.titleText}>
							{"It Looks Like You Are Using A Mobile Device"}
						</Text>
						<Text style={styles.subTitleText}>
							{"Download the Reframe App for the best experience!"}
						</Text>
					</View>
					<View style={styles.buttonContainer}>
						<TouchableOpacity onPress={AppStore}>
							<Image source={AppStoreImg} style={{ height: 56, width: 210 }} />
						</TouchableOpacity>
						<TouchableOpacity onPress={GooglePlay}>
							<Image source={GooglePlayImg} style={{ height: 56, width: 202, marginTop: 10 }} />
						</TouchableOpacity>
					</View>
				</ContentBlockV1>

				<View style={styles.alreadyContainer}>
					<View style={styles.alreadyContentContainer}>
						<View style={styles.textSpacing}>
							<Text style={styles.text}>{"Already Have The App?"}</Text>
						</View>

						<View>
							<Button
								source={ReframeImg}
								title={"Launch Reframe"}
								onPress={() => {
									Linking.canOpenURL("com.getreframe.crossplatform://expo-development-client").then(supported => {
										if (supported) {
											Linking.openURL("com.getreframe.crossplatform://expo-development-client");
										} else {
											console.log("Don't know how to open URI: " + this.props.url);
										}
									});
								}}
							/>

						</View>
					</View>
				</View>
			</View>
		</TheaterLayout>
	)
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	textContainer: {
		justifyContent: "center",
		alignItems: "center",
	},
	text: {
		fontSize: 14,
		fontWeight: "bold",
	},
	topText: {
		fontSize: 30,
		fontWeight: "bold",
		textAlign: 'center',
		paddingHorizontal: 20,
		paddingBottom: 20,
		lineHeight: 30,
	},
	titleText: {
		fontSize: 30,
		fontWeight: "100",
		textAlign: 'center',
		paddingHorizontal: 20,
		marginBottom: 35,
	},
	subTitleText: {
		fontSize: 16,
		paddingHorizontal: 20,
		textAlign: 'center',
		fontWeight: "400",
	},

	buttonContainer: {
		justifyContent: 'space-between',
		alignItems: "center",
		marginTop: 20,
		marginHorizontal: 40,
	},
	textSpacing: {
		paddingTop: 5,
	},
	alreadyContainer: {

	},
	alreadyContentContainer: {
		alignItems: "center",
		justifyContent: 'center',
	}
});

export default MobileWebBrowser;