import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function SharedFoldersWithMe(props) {
  return (
    <Svg width="22" height="18" viewBox="0 0 22 18" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M18 2H10L8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2ZM13 5C14.1 5 15 5.9 15 7C15 8.1 14.1 9 13 9C11.9 9 11 8.1 11 7C11 5.9 11.9 5 13 5ZM17 13H9V12C9 10.67 11.67 10 13 10C14.33 10 17 10.67 17 12V13Z" fill="#1B1B1B"/>
    </Svg>
    
  )
}

SharedFoldersWithMe.propTypes = Svg.propTypes
