import * as React from 'react'
import * as S from './styles'
import {useNavigation} from '@react-navigation/native'
import PropTypes from 'prop-types'
import {Icon} from '@/components'
import theme from '@/styles/themes/black'

const Breadcrumb = ({ routes }) => {
  const navigation = useNavigation()

  return (
    <S.NavigationContainer>
      {routes.map((route, i) => (
        <>
          <S.LinkButton onPress={() => route.navigation && navigation.navigate(route.navigation)}>
            <S.TextLink>{ route.name }</S.TextLink>
          </S.LinkButton>
          {i < routes.length - 1 && (
            <S.IconView>
              <Icon
                icon="chevronRight"
                color={theme.basics[900]}
                height={6}
                width={3.7}
              />
            </S.IconView>
          )}
        </>
      ))}
    </S.NavigationContainer>
  )
}

Breadcrumb.propTypes = {
  routes: PropTypes.array.isRequired
}

export default Breadcrumb
