import { Platform, Dimensions } from 'react-native'
import styled from 'styled-components/native'
import { MaterialTopTabBar as TopTabBarBase } from '@react-navigation/material-top-tabs'

export const TopTabBar = styled(TopTabBarBase).attrs(({ theme }) => ({
  labelStyle: {
    textTransform: 'none',
    fontSize: 13,
    color: theme.basics.brand,
    fontFamily: 'WorkSans_400Regular',
  },
  indicatorStyle: {
    height: 4,
    width: 25,
    maxWidth: 25,
    borderRadius: 2,
    alignSelf: 'center',
    backgroundColor: theme.primary.standard,
    left:
      Platform.OS === 'web'
        ? 'calc(25% - 25px/2)'
        : Math.floor(Dimensions.get('window').width * 0.25 - 25 / 2),
  },
}))`
  width: 100%;
  height: 45px;
  background-color: transparent;
`
