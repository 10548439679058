
import { store } from '@/redux/store'

/**
 * Check if the logged user can access the feature
 * @param actions
 * @returns {boolean|*}
 */
const hasPlan = (allowedPlanCodes) => {
  const state = store.getState()
  const subscriptionMeta = state.subscription.subscription

  const planCode = subscriptionMeta?.plan?.code

  if (allowedPlanCodes.includes(planCode)) {
    return true;
  } else {
    return false;
  }
}

export default hasPlan
