import React, { Component } from 'react'
import { Text, StyleSheet, View } from 'react-native'
import { isMobile } from '@/styles'

const LineCustom = ({noOfLines}) => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />
      <View style={styles.line} />

      {!isMobile ? (
        <>
        {noOfLines && Number.isInteger(noOfLines)
         ?[...Array(noOfLines)].map((e, i) => <View style={styles.line} />)
         :<>
         <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
          <View style={styles.line} />
         </>
        }
          
        </>
      ) : null}
      <View
        style={{
          top: 22,
          width: 8,
          height: 8,
          backgroundColor: '#000',
          borderRadius: 4,
        }}
      />
    </View>
  )
}
export default LineCustom
const styles = StyleSheet.create({
  line: {
    top: 25,
    width: 4,
    height: 2,
    backgroundColor: '#000',
    borderRadius: 10,
    marginHorizontal: 2,
  },
})
