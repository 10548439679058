import styled from 'styled-components/native'

export const Container = styled.View`
  width: ${({ size }) => (size ? size + 'px' : '40px')};
  height: ${({ size }) => (size ? size + 'px' : '40px')};
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 4%;
  align-self: center;
`

export const Letter = styled.Text`
  font-size: ${({ fontSize }) => (fontSize ? fontSize + 'px' : '20px')};
  color: white;
  font-weight: bold;
`
