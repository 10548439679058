// twilio video call module

import React, { Component, useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as Twilio from 'react-native-twilio-video-webrtc';
import { TouchableOpacity, AppState, View, PermissionsAndroid, Button, StyleSheet, Text, TextInput, Dimensions, Platform, ScrollView, Modal, FlatList, Alert } from 'react-native'
import { useSelector } from 'react-redux';
import { getMember } from '@/redux/selectors/auth';
import { CommonActions, useFocusEffect, useNavigation } from '@react-navigation/native';
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';
import { Entypo, Feather, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'
import { formatUserName } from '@/utils/format';
import CallMessage from './components/Message';
import Input from "@/components/Chat/RTM/Input"
import { Menu, Provider, DarkTheme, ThemeProvider, ActivityIndicator } from 'react-native-paper'
import ParticipantView from '../Meeting/VideoChat/ParticipantView';
import ApiHandler from '@/api/ApiHandler';
import VideoContext from '../Meeting/context';
import { useRoute } from '@react-navigation/native'
import { request, PERMISSIONS, check } from 'react-native-permissions'

import KeepAwake from 'react-native-keep-awake';
import PermissionsScreen from '@/components/Chat/components/PermissionsScreen';
import RemoteVideo from './RemoteVideo';
import { recordingEndEvent, recordingStartEvent } from '@/constants/recordingEvent';
import DefaultButton from '@/components/Buttons/Default'
import ModalV2 from '@/components/ModalV2'

const { width, height } = Dimensions.get('screen');

const TwilioVideoLocalView = Platform.OS == 'web' ? View : Twilio.TwilioVideoLocalView;
const TwilioVideo = Platform.OS == 'web' ? View : Twilio.TwilioVideo;

const tile_width = width / 1.25;

const VideoCall = (props) => {
    const [isAudioEnabled, setIsAudioEnabled] = useState(true);
    const [isVideoEnabled, setIsVideoEnabled] = useState(false);
    const [status, setStatus] = useState('disconnected');
    const [participants, setParticipants] = useState(new Map());
    const [videoTracks, setVideoTracks] = useState(new Map());
    const [roomId, setRoomId] = useState("");
    const twilioRef = useRef(null);
    const user = useSelector(getMember);
    const [_refresh, setRefresh] = useState(false);
    const navigation = useNavigation();
    const color = UseBrandingAccentColor();
    const [messages, setMessages] = useState(new Map());
    const [messageModalVisible, setMessageModalVisible] = useState(false);
    const [haveUnreadMessages, setUnreadMessages] = useState(false);
    const [messageText, setMessageText] = useState("");
    const [menuVisible, setMenuVisible] = useState(false);
    const [showingParticipantModal, setParticipantModal] = useState(false);
    const { addParticipant } = useContext(VideoContext);
    const route = useRoute();
    const [activeSpeaker, setActiveSpeaker] = useState();
    const [appState, setAppState] = useState("active");
    const [permissionsBlocked, setPermissionsBlocked] = useState(null);
    const [error, setError] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [screenShareTrack, setScreenShareTrack] = useState(null);
    const [token, setToken] = useState();

    // const tileWidthWorkaround = (ms = 800) => {
    //     // setTimeout(() => {
    //     //     setTileWidth(width / 1.255); // slightly change with width of the video
    //     //     setTimeout(() => {
    //     //         setTileWidth(width / 1.25);  // revert it back to original width
    //     //     }, ms)
    //     // }, 200)
    // }

    const refresh = () => setRefresh(prev => !_refresh);

    const fetchToken = async () => {
        try {

            const res = await new ApiHandler().authenticateWithTwilio({ meeting_id: route.params.meeting_id ?? roomId })

            const result = res.data;

            return result.data;
        } catch (error) {
            console.log(error);
        }
    }

    const getCameraPermissions = async () => {
        let status;
        status = await check(Platform.OS == 'android' ? PERMISSIONS.ANDROID.CAMERA : PERMISSIONS.IOS.CAMERA);
        if (status !== 'granted') {
            status = await request(Platform.OS == 'android' ? PERMISSIONS.ANDROID.CAMERA : PERMISSIONS.IOS.CAMERA);
            return status;
        } else {
            return status;
        }
    }

    const getMicrophonePermission = async () => {
        let status;
        status = await check(Platform.OS == 'android' ? PERMISSIONS.ANDROID.RECORD_AUDIO : PERMISSIONS.IOS.MICROPHONE);
        if (status !== 'granted') {
            status = await request(Platform.OS == 'android' ? PERMISSIONS.ANDROID.RECORD_AUDIO : PERMISSIONS.IOS.MICROPHONE);
            return status;
        } else {
            return status;
        }
    }

    useEffect(() => {
        const unsubscribe = AppState.addEventListener("change", (state) => {
            setAppState(state);
        })

        return unsubscribe;
    }, [])


    useEffect(() => {
        if (appState == 'background' && status == 'connected') {
            _onMuteVideoPress();
        }
    }, [appState])

    useEffect(() => {
        if (status !== 'connected') {
            joinRoom();
        }
    }, [])


    const joinRoom = () => {
        if (route.params.meeting_id) {
            fetchToken().then(token => {
                if (token) {
                    setToken(token);

                    // tweak to enable local video on preview screen
                    _onMuteVideoPress();
                }
            })
        }
    }


    // clear the unread message badge
    useEffect(() => {
        if (messageModalVisible) {
            setUnreadMessages(false);
        }
    }, [messageModalVisible])


    const _onConnectButtonPress = async () => {
        const cameraStatus = await getCameraPermissions();
        const micStatus = await getMicrophonePermission();

        if (cameraStatus == 'blocked' || micStatus == 'blocked') {
            setPermissionsBlocked({ mic: micStatus, camera: cameraStatus });
            return;
        } else if (cameraStatus == 'granted' && micStatus == 'granted') {
            setPermissionsBlocked(null);
        }

        console.log({ cameraStatus, micStatus });

        twilioRef.current.connect({ accessToken: token, dominantSpeakerEnabled: true })

        setStatus('connecting');
    }

    const _onEndButtonPress = () => {
        twilioRef.current.disconnect();
        navigation.dispatch(CommonActions.reset({ routes: [{ name: "Home" }], index: 0 }))
    };

    const _onMuteButtonPress = useCallback(() => {
        twilioRef.current.setLocalAudioEnabled(!isAudioEnabled).then(isEnabled => setIsAudioEnabled(isEnabled))
    }, [twilioRef, isAudioEnabled, setIsAudioEnabled])

    const _onMuteVideoPress = useCallback(() => {
        twilioRef.current?.setLocalVideoEnabled(!isVideoEnabled).then(isEnabled => setIsVideoEnabled(prev => isEnabled));
    }, [twilioRef, isVideoEnabled, setIsVideoEnabled])

    const _onFlipButtonPress = useCallback(() => {
        twilioRef.current?.flipCamera();
    }, [twilioRef])

    const _onRoomDidConnect = async ({ roomName, error }) => {
        console.log('onRoomDidConnect: ', roomName);

        setTimeout(async () => {
            await twilioRef.current.setLocalVideoEnabled(isVideoEnabled);
            await twilioRef.current.setLocalAudioEnabled(isAudioEnabled);
        }, 1500)

        const res = await new ApiHandler().getParticipant({ id: user.id?.toString() });
        const data = res.data?.data ?? {};

        addParticipant(user.id?.toString(), data);

        setStatus('connected');
    };

    const _onRoomDidDisconnect = ({ roomName, error }) => {
        console.log('[Disconnect]ERROR: ', error);
        setError(error)

        setStatus('disconnected');
    };

    const _onRoomDidFailToConnect = error => {
        console.log('[FailToConnect]ERROR: ', error);

        Alert.alert("Failed To Connect", error);

        setStatus('disconnected');
    };

    const _onRoomParticipantDisconnect = participant => {
        console.log("PARTICIPANT DISCONNECTED --> " + JSON.stringify(participant));

        setVideoTracks(
            new Map([
                ...videoTracks
            ].filter(track => track.participant?.identity !== participant.identity)),
        );

    }

    const _onParticipantAddedVideoTrack = ({ participant, track }) => {
        console.log('onParticipantAddedVideoTrack: ', participant, track);

        if (track.trackName == 'screen') {
            setScreenShareTrack([track.trackSid, { audioEnabled: true, videoEnabled: true, isScreenShare: true, participantSid: participant.sid, videoTrackSid: track.trackSid, identity: participant.identity }])
        } else {

            const participantsResult = participants.set(participant.sid, { ...participant, audioEnabled: true, videoEnabled: track.enabled })
            const videoTracksResult = videoTracks.set(track.trackSid, { audioEnabled: true, videoEnabled: true, participantSid: participant.sid, videoTrackSid: track.trackSid, identity: participant.identity })

            setParticipants(prev => participantsResult);
            setVideoTracks(prev => videoTracksResult)

            setTimeout(() => {
                setRefresh(!refresh);
            }, 700)
        }


        // setTimeout(() => {
        //     setRefresh(!refresh);
        // }, 300)

    };

    const _onParticipantRemovedVideoTrack = ({ participant, track }) => {
        console.log('onParticipantRemovedVideoTrack: ', participant, track);

        if (track.trackName == "screen") {
            setScreenShareTrack(null)
        }

        const videoTracksLocal = videoTracks;
        videoTracksLocal.delete(track.trackSid);

        setVideoTracks(videoTracksLocal);
    };

    const _onParticipantDisabledAudioTrack = ({ track, participant }) => {
        const current = participants.get(participant.sid) || null;
        const result = participants.set(participant.sid, { ...participant, videoEnabled: current ? current.videoEnabled : true, audioEnabled: false, dataTrack: current?.dataTrack });
        setParticipants(prev => result);

        refresh();

    }

    const _onParticipantEnabledAudioTrack = ({ track, participant }) => {
        const current = participants.get(participant.sid) || null;
        const result = participants.set(participant.sid, { ...participant, videoEnabled: current ? current.videoEnabled : true, audioEnabled: true, dataTrack: current?.dataTrack });
        setParticipants(prev => result);

        refresh();

    }

    const _onParticipantConnect = async ({ participant }) => {
        try {
            const { identity } = participant;

            const res = await new ApiHandler().getParticipant({ id: identity });
            const data = res.data?.data ?? {};

            const result = participants.set(participant.sid, { ...participant, audioEnabled: false, videoEnabled: false });

            setParticipants(prev => result)


            addParticipant(identity, data);

        } catch (error) {
            console.log(error);
        }
    }

    const _onParticipantAddedAudioTrack = data => {
        console.log("ADDED AUDIO TRACK");
    }

    const _onParticipantDisabledVideoTrack = ({ track, participant }) => {
        const current = participants.get(participant.sid) || null;
        const result = participants.set(participant.sid, { ...participant, videoEnabled: false, audioEnabled: current ? current.audioEnabled : true, dataTrack: current?.dataTrack });
        setParticipants(prev => result);

        refresh();

    }

    const _onParticipantEnabledVideoTrack = ({ track, participant }) => {
        const current = participants.get(participant.sid) || null;
        const result = participants.set(participant.sid, { ...participant, videoEnabled: true, audioEnabled: current ? current.audioEnabled : true, dataTrack: current?.dataTrack });
        setParticipants(prev => result);

        refresh();
    }

    const _onDataTrackAdded = ({ participant, track }) => {
        console.log("DATA TRACK ADDED");

        const localParticipants = participants;

        const _participant = participants.get(participant.sid);

        localParticipants.set(participant.sid, { ..._participant, dataTrack: track.trackSid });

        setParticipants(localParticipants)
    }


    const _onDataTrackRemoved = ({ participant, track }) => {
        console.log("DATA TRACK WAS REMOVED");
    }

    const _onMessageReceived = ({ message, trackSid }) => {
        const localParticipants = participants;

        if (message == recordingStartEvent) {
            setIsRecording(true);
        } else if (message == recordingEndEvent) {
            setIsRecording(false);
        } else {
            for (let value of localParticipants.values()) {
                if (value?.dataTrack == trackSid) {
                    const msg = {
                        message,
                        sender: value?.identity
                    }

                    setMessages(prev => {
                        setUnreadMessages(true);
                        return new Map([...messages, [Date.now(), msg]])
                    });
                }
            }
        }


    }

    const sendMessage = useCallback(() => {
        if (messageText.trim() !== "") {
            const message = [
                Date.now(),
                {
                    sender: user.id,
                    message: messageText
                }
            ]

            setMessages(new Map([...messages, message]));

            twilioRef.current?.sendString(messageText);

            setMessageText("");
        }
    }, [messageText, user, setMessages, messages, twilioRef, setMessageText])

    const _onDominantSpeakerChanged = ({ participant }) => {
        if (participant) {
            setActiveSpeaker(participant)
        } else {
            setActiveSpeaker(null)
        }
    }

    return permissionsBlocked !== null ? <PermissionsScreen camera={permissionsBlocked?.camera} mic={permissionsBlocked?.mic} /> : (
        <View style={{
            width: '100%',
            flex: 1
        }}>
            {error && status == "disconnected" && (
                <View style={{ zIndex: 5000, flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ fontSize: 20, color: "white" }}>{error}</Text>
                    <TouchableOpacity onPress={() => navigation.goBack()} style={[styles.button, { backgroundColor: color }]}>
                        <Text style={{ color: 'white' }}>Go Back</Text>
                    </TouchableOpacity>
                </View>
            )}

            {status === 'disconnected' &&
                <View style={{
                    width: '100%'
                }}>
                    <View style={{
                        height: height / 1.4,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginHorizontal: 'auto',
                        marginTop: 30,
                        marginBottom: 30,
                        //display: 'flex-block',
                        borderRadius: 8,
                        overflow: 'hidden',
                        width: '100%',
                        aspectRatio: 9 / 16,
                        backgroundColor: '#202124',
                        alignSelf: 'center'
                    }}>
                        {isVideoEnabled && (
                            <TwilioVideoLocalView
                                enabled={true}
                                style={{
                                    objectFit: 'cover',
                                    aspectRatio: 9 / 16,
                                    height: '100%',
                                    width: '100%',
                                }}
                                scaleType="fill"
                            />
                        )}

                        <View style={{
                            position: 'absolute',
                            bottom: 20,
                            left: 0,
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            width: '100%'
                        }}>
                            <TouchableOpacity
                                style={[
                                    styles.optionButton,
                                    {
                                        backgroundColor: !isAudioEnabled ? '#ea4335' : 'transparent',
                                        borderColor: !isAudioEnabled ? '#ea4335' : '#FFFFFF',
                                        borderWidth: 2
                                    }
                                ]}
                                onPress={_onMuteButtonPress}
                            >
                                <MaterialIcons name={isAudioEnabled ? "mic" : "mic-off"} size={20} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    styles.optionButton,
                                    {
                                        backgroundColor: !isVideoEnabled ? '#ea4335' : 'transparent',
                                        borderColor: !isVideoEnabled ? '#ea4335' : '#FFFFFF',
                                        borderWidth: 2
                                    }
                                ]}
                                onPress={_onMuteVideoPress}>
                                <MaterialIcons name={isVideoEnabled ? "videocam" : "videocam-off"} size={20} color="white" />
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[
                                    styles.optionButton,
                                    {
                                        backgroundColor: 'transparent',
                                        borderColor: '#FFFFFF',
                                        borderWidth: 2
                                    }
                                ]}
                                onPress={_onFlipButtonPress}
                            >
                                <MaterialIcons name="flip-camera-ios" size={20} color="white" />
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={{
                        marginTop: 25,
                        paddingHorizontal: 40
                    }}>
                        <DefaultButton
                            label={'Join Meeting'}
                            onPress={() => {
                                _onConnectButtonPress()
                            }}
                        />
                    </View>
                </View>
            }

            {status === 'connected' ?
                <View style={{
                    flex: 1,
                    position: "absolute",
                    bottom: 0,
                    top: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#000'
                }}>

                    {isRecording && (
                        <View style={{
                            width: '100%',
                            backgroundColor: '#3c4043',
                            paddingHorizontal: 10,
                            paddingVertical: 5
                        }}>
                            <Text style={{
                                color: '#FFFFFF',
                                fontSize: 11,
                                textAlign: 'center'
                            }}>
                                {'This meeting is currently being recorded.'}
                            </Text>
                        </View>
                    )}

                    <FlatList
                        scrollEventThrottle={1600}
                        contentContainerStyle={{ paddingBottom: 100 }}
                        keyExtractor={(item, index) => item?.[0]}
                        data={Array.from(videoTracks)}
                        stickyHeaderIndices={[0]}
                        ListHeaderComponent={() => screenShareTrack ? (
                            <RemoteVideo activeSpeaker={null} trackSid={screenShareTrack[0]} trackIdentifier={screenShareTrack[1]} tile_width={tile_width} user={participants.get(screenShareTrack[1].participantSid)} />
                        ) : <View />}
                        ListFooterComponent={() => (
                            <View style={{
                                marginVertical: 10,
                                backgroundColor: '#1b1b1b',
                                alignSelf: 'center',
                                alignItems: 'center',
                                justifyContent: "center",
                                borderRadius: 8,
                                overflow: 'hidden',
                                width: '95%',
                                aspectRatio: 16 / 9,
                                backgroundColor: '#202124',
                            }}>
                                {isVideoEnabled ? (
                                    <TwilioVideoLocalView
                                        enabled={isVideoEnabled}
                                        style={{
                                            //objectFit: 'cover',
                                            aspectRatio: 16 / 9,
                                            height: '100%',
                                            width: '100%',
                                        }}
                                        scaleType="fit"
                                    />
                                ) : (
                                    <View style={[styles.remoteVideo, { width: tile_width, aspectRatio: 16 / 9, alignItems: 'center', justifyContent: 'center' }]}>
                                        <View style={{ width: 60, height: 60, backgroundColor: 'rgba(255,255,255,0.1)', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }}>
                                            <Text style={{ fontSize: 40, color: 'white' }}>{user.first_name?.charAt(0).toUpperCase()}</Text>
                                        </View>
                                    </View>
                                )}

                                <View style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingHorizontal: 15,
                                    paddingVertical: 10,
                                    alignItems: 'center'
                                }}>
                                    <Text style={{
                                        color: '#FFFFFF',
                                        fontFamily: 'WorkSans_400Regular',
                                        fontSize: 15,
                                        fontWeight: '600'
                                    }}>
                                        {formatUserName(user.first_name, user.last_name)}
                                    </Text>

                                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        {!isAudioEnabled && (
                                            <TouchableOpacity
                                                style={{
                                                    width: 30,
                                                    height: 30,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    borderRadius: 50,
                                                    //marginHorizontal: 15,
                                                    backgroundColor: !isAudioEnabled ? '#ea4335' : '#3c4043',
                                                    borderColor: !isAudioEnabled ? "#ea4335" : 'white'
                                                }}
                                            >
                                                <MaterialIcons name={isAudioEnabled ? "mic" : "mic-off"} color="#FFFFFF" size={15} />
                                            </TouchableOpacity>
                                        )}

                                        {/*<MaterialIcons name={isVideoEnabled ? "videocam" : "videocam-off"} color="white" size={20} />*/}
                                    </View>
                                </View>
                            </View>
                        )}
                        renderItem={({ item, index }) => {
                            const [trackSid, trackIdentifier] = item;
                            if (trackIdentifier.isScreenShare) {
                                return <View />
                            }

                            return (
                                <RemoteVideo activeSpeaker={activeSpeaker?.sid == participants.get(trackIdentifier.participantSid).sid} trackSid={trackSid} trackIdentifier={trackIdentifier} tile_width={tile_width} user={participants.get(trackIdentifier.participantSid)} />
                            )
                        }}
                    />

                    <View
                        style={{
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            right: 0,
                            height: 100,
                            //backgroundColor: "#383838",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-evenly"
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                width: 60,
                                height: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                borderRadius: 50,
                                marginHorizontal: 10,
                                backgroundColor: '#3c4043',
                                borderColor: 'white'
                            }}
                            onPress={_onEndButtonPress}>
                            <MaterialIcons name="call-end" size={22} color="#FFFFFF" />
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{
                                width: 60,
                                height: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                borderRadius: 50,
                                marginHorizontal: 10,
                                backgroundColor: !isAudioEnabled ? '#ea4335' : '#3c4043',
                                borderColor: !isAudioEnabled ? "#ea4335" : 'white'
                            }}
                            onPress={_onMuteButtonPress}>
                            <MaterialIcons name={isAudioEnabled ? "mic" : "mic-off"} size={22} color="#FFFFFF" />
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{
                                width: 60,
                                height: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                borderRadius: 50,
                                marginHorizontal: 10,
                                backgroundColor: !isVideoEnabled ? '#ea4335' : '#3c4043',
                                borderColor: !isVideoEnabled ? "#ea4335" : 'white'
                            }}
                            onPress={_onMuteVideoPress}>
                            <MaterialIcons name={isVideoEnabled ? "videocam" : "videocam-off"} size={22} color="#FFFFFF" />
                        </TouchableOpacity>

                        <TouchableOpacity
                            style={{
                                width: 60,
                                height: 60,
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                                borderRadius: 50,
                                marginHorizontal: 10,
                                backgroundColor: '#3c4043',
                                borderColor: 'white'
                            }}
                            onPress={() => { setMessageModalVisible(true) }}>
                            <MaterialCommunityIcons name="comment" color="#FFFFFF" size={22} />
                            {haveUnreadMessages && (<View style={{ position: 'absolute', top: 0, right: 5, backgroundColor: "red", borderRadius: 50, width: 10, height: 10 }} />)}
                        </TouchableOpacity>

                        <ThemeProvider theme={DarkTheme}>
                            <Menu
                                style={{
                                    width: width / 2,
                                    maxHeight: height / 4,
                                    bottom: -160
                                }}
                                anchor={
                                    <TouchableOpacity
                                        style={{
                                            width: 60,
                                            height: 60,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            position: 'relative',
                                            borderRadius: 50,
                                            marginHorizontal: 10,
                                            backgroundColor: '#3c4043',
                                            borderColor: 'white'
                                        }}
                                        onPress={() => setMenuVisible(!menuVisible)}
                                    >
                                        <MaterialCommunityIcons name="dots-horizontal" color="#FFFFFF" size={22} />
                                    </TouchableOpacity>
                                }
                                visible={menuVisible}
                                onDismiss={() => setMenuVisible(false)}
                            >
                                <Menu.Item icon="camera-switch" onPress={_onFlipButtonPress} title="Switch Camera" />
                                <Menu.Item icon="account-multiple" onPress={() => setParticipantModal(true)} title="Participants" />
                            </Menu>
                        </ThemeProvider>
                    </View>
                </View>
                : status == 'connecting' ? (
                    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                        <ActivityIndicator color={color} />
                        <Text style={{ marginTop: 10, color: "white" }}>Connecting...</Text>
                    </View>
                ) : null}

            <TwilioVideo
                ref={twilioRef}
                onRoomDidConnect={_onRoomDidConnect}
                onRoomDidDisconnect={_onRoomDidDisconnect}
                onParticipantDisabledAudioTrack={_onParticipantDisabledAudioTrack}
                onParticipantAddedDataTrack={_onDataTrackAdded}
                onParticipantRemovedDataTrack={_onDataTrackRemoved}
                onParticipantEnabledAudioTrack={_onParticipantEnabledAudioTrack}
                onParticipantAddedAudioTrack={_onParticipantAddedAudioTrack}
                onParticipantDisabledVideoTrack={_onParticipantDisabledVideoTrack}
                onDataTrackMessageReceived={_onMessageReceived}
                onDominantSpeakerDidChange={_onDominantSpeakerChanged}
                onParticipantEnabledVideoTrack={_onParticipantEnabledVideoTrack}
                onRoomParticipantDidDisconnect={_onRoomParticipantDisconnect}
                onRoomDidFailToConnect={_onRoomDidFailToConnect}
                onRoomParticipantDidConnect={_onParticipantConnect}
                onParticipantAddedVideoTrack={_onParticipantAddedVideoTrack}
                onParticipantRemovedVideoTrack={_onParticipantRemovedVideoTrack}
            />

            <ModalV2
                isVisible={messageModalVisible}
                setVisibility={setMessageModalVisible}
                title={'Messages'}
            >
                <View style={{ flex: 1 }}>
                    <ScrollView
                        scrollEventThrottle={1600}
                        showsVerticalScrollIndicator={false}
                        style={{
                            paddingBottom: 50
                        }}
                    >
                        {Array.from(messages, ([timeStamp, msg]) => (
                            <CallMessage message={msg.message} sender={msg.sender} timeStamp={timeStamp} key={timeStamp} />
                        ))}
                    </ScrollView>
                    <View>
                        <Input onSendMessage={sendMessage} value={messageText} onChangeText={setMessageText} hideAttachment letter={user?.first_name?.charAt(0)} avatar={user?.avatar} theme="light" />
                    </View>
                </View>
            </ModalV2>

            <ModalV2
                isVisible={showingParticipantModal}
                setVisibility={setParticipantModal}
                title={'Participants'}
            >
                <View style={{ flex: 1 }}>
                    <ScrollView scrollEventThrottle={1600} showsVerticalScrollIndicator={false}>
                        {Array.from(participants, ([sid, participant]) => (
                            <ParticipantView isMobile key={sid} onPress={() => console.log(participant)} participant={participant} />
                        ))}
                    </ScrollView>
                </View>
            </ModalV2>

            <KeepAwake />
        </View>
    );
}

export default VideoCall;


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "black",
    },
    callContainer: {
        flex: 1,
        position: "absolute",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
    },
    welcome: {
        fontSize: 30,
        textAlign: "center",
        paddingTop: 40,
        color: 'white'
    },
    input: {
        height: 50,
        borderWidth: 1,
        marginRight: 70,
        marginLeft: 70,
        marginTop: 50,
        textAlign: "center",
        backgroundColor: "white",
    },
    button: {
        marginTop: 100,
        width: 240,
        padding: 5,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        alignSelf: 'center'
    },
    localVideo: {
        flex: 1,
        width: 150,
        height: 250,
        position: "absolute",
        right: 10,
        bottom: 10,
    },
    remoteGrid: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    remoteVideo: {
        // marginTop: 20,
        // marginLeft: 10,
        // marginRight: 10,
        // width: width / 1.2,
        // maxWidth: width / 1.2,
        aspectRatio: 16 / 9
    },
    optionsContainer: {
        position: "absolute",
        left: 0,
        bottom: 0,
        right: 0,
        height: 100,
        backgroundColor: "#383838",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-evenly"
    },
    optionButton: {
        width: 60,
        height: 60,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: 100 / 2,
        justifyContent: "center",
        alignItems: "center",
    },
    fullView: {
        width: 200, height: 200, transform: [{ rotateZ: '90deg' }]
    }
})