import React, { useRef, useEffect, useCallback } from "react";
import { Animated, TouchableOpacity, Text, Linking, Platform, View, ScrollView, Image, StatusBar } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { ProgressBar } from 'react-native-paper'
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useRoute, useNavigation } from "@react-navigation/native";
import Icon from "@/components/Icon";
import { actions as authActions } from "@/redux/reducers/auth";
import { useSideDrawer } from "@/hooks/sideDrawer";
import DrawerItem from "./components/DrawerItem";
import DrawerSection from "./components/DrawerSection";
import {
    Container,
    CloseButton,
    PageContentWrapper,
    RightSideMenuWrapper,
} from "./styles";
import canAccess from "@/helpers/permissions";
import hasPlan from "@/helpers/subscription";
import { permissions } from "@/helpers/permissionConstants";

import * as brandSelectors from '@/redux/selectors/branding'
import * as authSelectors from '@/redux/selectors/auth'
import ReframeHorizontalLogo from '@/assets/brand/reframe-brand.png'
import { shadow2 } from '@/styles/shadows'
import theme from '@/styles/themes/black'

const SIDE_DRAWER_WIDTH = 250;

export default function RightSideDrawerLayout({
    scrollDisabled,
    children
}) {
    const route = useRoute();
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const { open, setOpen } = useSideDrawer();

    const animatedRight = useRef(new Animated.Value(0)).current;

    const branding = useSelector(brandSelectors.getSettings)
    const hasMultipleWorkspaces = useSelector(authSelectors.getHasMultipleWorkspaces)
    const { color_scheme_page_topbar, logo } = branding

    const handleCloseMenu = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleLogout = useCallback(() => {
        dispatch(authActions.logout());
    }, [dispatch]);

    useEffect(() => {
        Animated.timing(animatedRight, {
            duration: 200,
            useNativeDriver: false,
            toValue: open ? SIDE_DRAWER_WIDTH : 0,
        }).start();
    }, [open]);

    return (
        <SafeAreaView
            style={{
                flex: 1,
            }}
        >
            <View style={{
                flex: 1,
            }}>
                <ScrollView style={{
                    flex: 1,
                    paddingTop: 13,
                    paddingLeft: 13,
                    paddingBottom: 0,
                    paddingRight: 13,
                    alignSelf: 'flex-end',
                    backgroundColor: '#f2f2f2',
                    width: SIDE_DRAWER_WIDTH
                }}>
                    <TouchableOpacity
                        onPress={handleCloseMenu}
                        style={{
                            paddingTop: 0,
                            paddingLeft: 3,
                            paddingBottom: 0,
                            paddingRight: 3,
                            marginBottom: 30,
                            alignSelf: 'flex-end'
                        }}
                    >
                        <Icon icon="arrowRight" width={16} />
                    </TouchableOpacity>
                    {hasMultipleWorkspaces ? (
                        <DrawerItem
                            iconSize={24}
                            label="Select Workspace"
                            icon="workspace"
                            destinationRoute={"Workspaces"}
                            selected={route.name === "Workspaces"}
                        />
                    ) : null}
                    <DrawerItem
                        label="Home"
                        icon="home"
                        destinationRoute="Home"
                        selected={route.name === "Home"}
                    />
                    {hasPlan(['collaboration_hub', 'change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerItem
                            iconSize={19}
                            icon="comment"
                            label="Chat"
                            destinationRoute="chat"
                            selected={route.name === "chat"}
                        />
                    )}

                    {hasPlan(['collaboration_hub', 'change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerItem
                            iconSize={19}
                            icon="community"
                            label="Community"
                            destinationRoute="Community"
                            selected={route.name === "Community"}
                        />
                    )}

                    {hasPlan(['collaboration_hub', 'change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerItem
                            iconSize={19}
                            icon="calendar"
                            label="Calendar"
                            destinationRoute="Calendar"
                            selected={route.name === "Calendar"}
                        />
                    )}

                    {hasPlan(['change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerItem
                            iconSize={21}
                            icon="education"
                            label="Learning"
                            destinationRoute="Learning"
                            selected={route.name === "Learning"}
                        />
                    )}

                    {hasPlan(['personalization_hub']) && (
                        <DrawerItem
                            icon="rewards"
                            label="Rewards"
                            destinationRoute="MemberMobile"
                            selected={route.name === 'MemberMobile'}
                        />
                    )}

                    {hasPlan(['collaboration_hub', 'change_and_growth_hub', 'personalization_hub']) && (
                        <DrawerItem
                            iconSize={21}
                            icon="sharedfolderright"
                            label={`Storage & File ${"\n"}  Sharing`}
                            destinationRoute="SharedFolders"
                            selected={route.name === "SharedFolders"}
                        />
                    )}

                    <DrawerItem
                        iconSize={20}
                        label="Support"
                        icon="help"
                        destinationRoute={"Support"}
                        selected={route.name === "Support"}
                    />
                    {
                        canAccess(permissions.CREATE_ASSESSMENTS) &&
                        canAccess(permissions.VIEW_ALL_ANALYTICS) && hasPlan(['change_and_growth_hub', 'personalization_hub']) && (
                            <DrawerSection title="Admin" />
                        )
                    }
                    {
                        canAccess(permissions.CREATE_ASSESSMENTS) && hasPlan(['change_and_growth_hub', 'personalization_hub']) && (
                            <DrawerItem
                                iconSize={21}
                                icon="assessments"
                                label="Assessments"
                                destinationRoute="Assessments"
                                selected={route.name === "Assessments"}
                            />
                        )
                    }
                    {
                        canAccess(permissions.VIEW_ALL_ANALYTICS) && hasPlan(['personalization_hub']) && (
                            <DrawerItem
                                iconSize={20}
                                icon="analytics"
                                label="Analytics"
                                destinationRoute="Analytics"
                                selected={route.name === "Analytics"}
                            />
                        )
                    }
                    {
                        canAccess(permissions.VIEW_ALL_ANALYTICS) && hasPlan(['personalization_hub']) && (
                            <DrawerItem
                                icon="rewards"
                                label="Rewards"
                                destinationRoute="ManagerMobile"
                                selected={route.name === 'ManagerMobile'}
                            />
                        )
                    }
                    <DrawerSection />
                    <DrawerItem
                        iconSize={18}
                        icon="profile"
                        label="Profile"
                        destinationRoute="Profile"
                        selected={route.name === "Profile"}
                    />
                    <DrawerItem
                        iconSize={20}
                        icon="settings"
                        label="Settings"
                        destinationRoute="Settings"
                        selected={route.name === "Settings"}
                    />
                    <DrawerItem
                        iconSize={23}
                        icon="notifications"
                        label="Notifications"
                        destinationRoute="Notifications"
                        selected={route.name === "Notifications"}
                    />
                    <DrawerSection />
                    <DrawerItem
                        iconSize={20}
                        label="Logout"
                        icon="logOut"
                        onPress={handleLogout}
                    />
                </ScrollView>

                <Animated.View
                    open={open}
                    style={{
                        top: 0,
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        position: 'absolute',
                        backgroundColor: '#f2f2f2',
                        right: animatedRight,
                        flex: 1,
                        height: '100%'
                    }}
                >
                    {scrollDisabled ? (
                        children
                    ) : (
                        <ScrollView>
                            {children}
                        </ScrollView>
                    )}
                </Animated.View>
            </View>
            {/*
            <StatusBar hidden={true} />
            */}
        </SafeAreaView>
    );
}

RightSideDrawerLayout.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    backEnabled: PropTypes.bool,
    children: PropTypes.node,
};