import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Shared(props) {
    return (
        <Svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12ZM6 10V7H4V10H1V12H4V15H6V12H9V10H6ZM15 14C12.33 14 7 15.34 7 18V20H23V18C23 15.34 17.67 14 15 14Z" fill={props.color} />
        </Svg>
    )
}

Shared.propTypes = SvgProps

export default Shared;
