import styled from 'styled-components/native'
import { Animated, Dimensions } from 'react-native'
import { shadow2 } from '@/styles/shadows'
import { Headline6SemiBold } from '@/styles/fonts'

const { width, height } = Dimensions.get('window')

export const Main = styled(Animated.View)`
  height: ${height}px;
  width: ${width}px;
  align-items: flex-end;
  justify-content: flex-end;
`
export const CloseButton = styled.TouchableOpacity`
  width: 30px;
  height: 30px;
  margin: 17px 17px 0 0;
  align-self: flex-end;
`

export const MainBottom = styled.View`
  height: 200px;
  width: ${width}px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  background-color: #fff;
  position: relative;
  padding: 0 0 0 17px;
  z-index: 1;
`

export const Container = styled.View`
  align-items: center;
  width: ${(props) => props.width}px;
  position: relative;
  z-index: 1;
`

export const ButtonItem = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 0 0 20px;
`

export const Card = styled(Animated.View)`
  align-self: center;
  min-width: 200px;
  border-radius: 5px;
  flex-direction: column;
  align-items: flex-start;
  ${shadow2}
  background-color: ${({ theme }) => theme.basics.background_white};
  padding: 20px 0 0 17px;
  position: absolute;
  top: ${({ cardTopPosition }) => cardTopPosition}px;
  border: 1px solid #1b1b1b14;
  box-shadow: 0px 0px 30px rgb(82 111 176 / 15%);
  right: 0;
`

export const ItemText = styled.Text`
  font-size: ${(props) => props.fontSize ? props.fontSize + "px" : "15px"};
  color: ${(props) => props.btnTextColor};
  padding: 0 0 0 10px;
`

export const Button = styled.TouchableOpacity`
  position: relative;
  height: 40px;
  flex-direction: row;
  align-self: ${({ btnAlign }) => btnAlign};
  align-items: center;
  justify-content: center;
  padding-left: ${({ paddingHorizontal }) =>
    paddingHorizontal ? paddingHorizontal : 25}px;
  padding-right: ${({ paddingHorizontal }) =>
    paddingHorizontal ? paddingHorizontal : 25}px;
  width: ${({ width }) => `${width}px` || 'auto'};
  background-color: ${(props) => props.bgButtonColor};
  border-radius: 3px;
`

export const ContainerCard = styled(Animated.View)`
  margin: 7px 0 0;
`

export const ButtonText = styled(Headline6SemiBold)`
  font-size: 17px;
  color: ${(props) => props.btnTextColor};
`

export const Rotate = styled(Animated.View)`
  height: 7px;
  width: 12px;
`

export const IconView = styled.View`
  padding: 0 0 0 10px;
  height: 7px;
  width: 12px;
`

export const ContainerModal = styled.View`
  align-items: center;
  justify-content: center;
  height: ${height}px;
  width: ${width}px;
  background-color: rgba(0, 0, 0, 0.4);
`
