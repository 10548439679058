import request from '@/helpers/request'


export async function login({ email, password, workspace_id }) {
  return await request('auth/login', {
    method: 'POST',
    body: JSON.stringify({ email, password, workspace_id }),
  })
}

export async function avatarImage({ avatarImageUri, fileName }) {
  const img = await fetch(avatarImageUri)
  const blob = await img.blob()
  const fd = new FormData()
  fd.append('image', blob, fileName)
  return await request('onboarding/avatar', {
    method: 'PUT',
    body: fd,
  }, {
    'Content-Type': 'multipart/form-data',
  })
}


export async function signUp({ name, email, password }) {
  return await request('register', {
    method: 'POST',
    body: JSON.stringify({
      name,
      email,
      password,
      // TODO: fix this
      company_name: 'whatever',
      number_of_employees: '1',
    }),
  })
}

export async function forgotPassword({ email }) {
  return await request('auth/password/reset/create', {
    method: 'POST',
    body: JSON.stringify({
      email,
    }),
  })
}

export async function resetPassword({ password, password_confirmation }) {
  return await request('auth/password/reset', {
    method: 'POST',
    body: JSON.stringify({
      password,
      password_confirmation
    }),
  })
}

export async function sendCodePassword({ code }) {
  return await request('password/code', {
    method: 'POST',
    body: JSON.stringify({
      code,
    }),
  })
}

// onboarding pages

export async function onboardingUpdateProfile({
  name,
  last_name,
  role,
  city,
  occupation,
}) {
  return await request('onboarding/profile', {
    method: 'PUT',
    body: JSON.stringify({
      name,
      last_name,
      role,
      city,
      occupation,
    }),
  })
}
