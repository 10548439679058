import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function ImportExportIcon(props) {
  return (
    <Svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M3.75 0.25L0.75 3.2425H3V8.5H4.5V3.2425H6.75L3.75 0.25ZM9 10.7575V5.5H7.5V10.7575H5.25L8.25 13.75L11.25 10.7575H9Z" />
    </Svg>
  )
}

ImportExportIcon.propTypes = SvgProps

export default ImportExportIcon
