import styled from 'styled-components/native'

// height: 24px;
export const Chip = styled.View`
  padding: 4px 15px;

  border-radius: 3px;

  align-self: flex-start;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.primary.standard};
`
