import request from '@/helpers/request'

export async function fetchProfile(id) {
  if(id !== 'undefined') {
    return await request('employee/profile/'+id, { method: 'GET' })
  } else {
    return await request('employee/profile', { method: 'GET' })
  }
}

export async function fetchRecentRewards() {
  return await request('employee/recentRewards', { method: 'GET' })
}

export async function fetchPreferredTopics() {
  return await request('employee/topics', { method: 'GET' })
}
