import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    AssessmentCard: {
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#F1524F',
        borderStyle: 'solid',
        padding: 20,
        flex: 1,
        marginBottom: 20,

        ...Platform.select({
            ios: {
            },
            android: {
            },
            web: {
                marginLeft: 10,
                marginRight: 10,
            }
        })
    },
    AssessmentCardTitle: {
        fontWeight: "700",
        fontSize: 15,
        color: '#1B1B1B',
        textAlign: 'left',
        fontFamily: "WorkSans_400Regular",
        marginBottom: 3,
    },
    AssessmentCardType: {
        fontSize: 13,
        color: '#F05343',
        lineHeight: 15,
        marginBottom: 5,
        fontFamily: 'WorkSans_400Regular'
    },
    AssessmentCardLifecycleStage: {
        fontSize: 13,
        lineHeight: 18,
        color: 'grey',
        fontFamily: 'WorkSans_400Regular'
    },
    AssessmentCardCategory: {
        fontSize: 13,
        color: 'grey',
        lineHeight: 18,
        fontFamily: 'WorkSans_400Regular'
    },
    AssessmentCardQuestionsCount: {
        fontSize: 13,
        lineHeight: 18,
        color: 'grey',
        fontFamily: 'WorkSans_400Regular'
    },
});
