import React, { useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import * as brandSelectors from '@/redux/selectors/branding'
import PropTypes from 'prop-types'
import { LinearGradient } from 'expo-linear-gradient'
import { isMobile } from '@/styles'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import {
  Container,
  CoverContainer,
  Header,
  Title,
  Label,
  SubHeader,
  SubTitle,
  Description,
  Image,
  styles
} from './styles'
import moment from 'moment'

const GroupTeamsMemberCard = ({
  image,
  name = '',
  label,
  description = '',
  members,
  type,
  id,
  created_at,
}) => {
  const navigation = useNavigation()
  const membersWord = members.length > 1 ? 'members' : 'member'

  const branding = useSelector(brandSelectors.getSettings);
  const { team, group, member } = branding?.taxonomies;

  const navigate = useCallback(() => {
    const path = type === 'teams' ? 'TeamPage' : 'GroupPage'
    navigation.navigate(path, { type, id })
  }, [navigation])

  return (
    <Container isMobile={isMobile} onPress={navigate}>
      <CoverContainer isMobile={isMobile}>
        <LinearGradient
          colors={['rgba(0,0,0,0.6)', 'transparent']}
          style={styles.gradiente}
        />
        <Image source={image ? { uri: image } : PlaceholderImageCover} resizeMode="cover" />
      </CoverContainer>
      <Header>
        <Label>{type == 'teams' ? team?.plural : group?.plural ?? ''}</Label>
        <Title>{name ?? ''}</Title>
        <SubHeader>
          <SubTitle>
            {members?.length ?? ''}{' '}{membersWord}
          </SubTitle>
        </SubHeader>
      </Header>
      <Description>
        {description ?? ''}
      </Description>
    </Container>
  )
}

GroupTeamsMemberCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  members: PropTypes.array,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
}

GroupTeamsMemberCard.defaultProps = {
  members: [],
}

export default GroupTeamsMemberCard
