import React, { useEffect, useState } from 'react'
import { View, ScrollView, Image, Text, Platform, TouchableOpacity, Linking, useWindowDimensions, SafeAreaView, RefreshControl } from 'react-native'
import Toast from 'react-native-toast-message'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from '@/redux/selectors/event'
import { actions } from '@/redux/reducers/event'
import { Avatar, BackArrow, Icon } from '@/components'
import { Button } from 'react-native-paper'
import { ProgressBar } from 'react-native-paper'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import { useIsMobile } from '@/styles'
import theme from '@/styles/themes/black'
import PlaceholderImageCover from '@/assets/cover_placeholder.png'
import { Video } from 'expo-av'
import * as Clipboard from 'expo-clipboard';
import isUrl from 'is-url';
import { isMobile } from '@/styles'
import HTML from 'react-native-render-html';
import tableRenderers, { useHtmlTableProps, HTMLTable } from '@native-html/heuristic-table-plugin';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import { WebView } from 'react-native-webview'

import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import { Feather } from '@expo/vector-icons'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import LearningView from '@/pages/Learning/Components/View'

import styles from './styles.js';

const ViewEvent = ({ route }) => {
    const isWeb = Platform.OS == "web";
    const window = useWindowDimensions();
    const navigation = useNavigation()
    const _event = useSelector(selectors.event)
    const [event, setEvent] = useState(_event)
    const loadingMain = useSelector(selectors.loading)
    const featuredLoading = useSelector(selectors.featuredLoading)
    const statusLoading = useSelector(selectors.statusLoading)
    const loading = loadingMain || featuredLoading || statusLoading
    const isMobile = useIsMobile()
    const isDesktop = !isMobile
    const dispatch = useDispatch()
    const video = React.useRef(null)

    const languageId = route.params && route.params.language_id ? route?.params?.language_id : 40;

    const [refreshing, setRefreshing] = React.useState(false);

    useEffect(() => {
        // dispatch(actions.getEventWithIdAction({ id: route?.params?.id,language_id:languageId }, () => { }))
        dispatch(actions.getEventWithIdAction({ id: route?.params?.id }, () => { }))
    }, [route?.params?.id])

    useEffect(() => {
        if (!loading) {
            console.log('_event', _event)
            setEvent(_event)
        }
    }, [loading])

    useEffect(() => {
        setRefreshing(false)
    }, [event])

    const updateEvent = () => {
        console.log('event view', event)
        navigation.navigate('CreateEvent', { event })
    }
    const toggleStatus = () => {
        dispatch(
            actions.toggleStatusAction({ id: route?.params?.id }, (error, success) => {
                Toast.show({
                    type: error ? 'error' : 'success',
                    position: 'bottom',
                    text1: success?.message,
                })
            }),
        )
    }
    const toggleFeatured = () => {
        dispatch(
            actions.toggleFeaturedAction({ id: route?.params?.id }, (error, success) => {
                Toast.show({
                    type: error ? 'error' : 'success',
                    position: 'bottom',
                    text1: success?.message,
                })
            }),
        )
    }
    const deleteEvent = () => {
        dispatch(
            actions.deleteEventAction({ id: route?.params?.id }, (message) => {
                console.log('response message of Delete Event', message)
                navigation.navigate('Events')
            }),
        )
    }

    const expansiveMenuItems = [
        { text: 'Edit Event', icon: 'pencil', onPress: updateEvent },
        { text: 'Publish/Unpublish', icon: 'send', onPress: toggleStatus },
        { text: 'Feature/Unfeature', icon: 'star', onPress: toggleFeatured },
        { text: 'Delete Event', icon: 'close', onPress: deleteEvent },
    ]

    const ContentData = event?.content ?? ''

    const TableRenderer = (props) => {
        const tableProps = useHtmlTableProps(props, {/* config */ });
        tableProps.layout.totalWidth = window.width - 24.95
        // Do customize the props here; wrap with your own container...
        return <HTMLTable {...tableProps} />;
    };

    const renderers = {
        ...tableRenderers,
        table: TableRenderer,
        iframe: IframeRenderer,
    }

    const customHTMLElementModels = {
        iframe: iframeModel
    };

    const htmlProps = {
        WebView,
        renderers: renderers,
        renderersProps: {
            table: {
                // Put the table config here
                getStyleForCell(cell) {
                    cell.width = window.width - 25
                    cell.height = '100%'
                    return cell;
                }
            },
            iframe: {
                scalesPageToFit: true,
                webViewProps: {
                    /* Any prop you want to pass to iframe WebViews */
                }
            }
        },
        tagsStyles: {
            table: { height: '100%', width: '95%', paddingBottom: 50, overFlowX: 'hidden' },
        }
    };

    return (
        <>
            <PageHeader
                title={'Event'}
                icon={false}
                backEnabled={true}
                actions={
                    [
                        {
                            title: 'Manage',
                            type: 'menu',
                            permissions: [
                                'MANAGE_LEARNING_ARTICLES'
                            ],
                            menu: [
                                {
                                    title: 'Edit',
                                    icon: 'pencil',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        updateEvent()
                                    }
                                },
                                {
                                    title: 'Publish/Unpublish',
                                    icon: 'send',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        toggleStatus()
                                    }
                                },
                                {
                                    title: 'Feature/Unfeature',
                                    icon: 'star',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        toggleFeatured()
                                    }
                                },
                                {
                                    title: 'Delete',
                                    icon: 'close',
                                    permissions: [
                                        'MANAGE_LEARNING_ARTICLES'
                                    ],
                                    onPress: () => {
                                        deleteEvent()
                                    }
                                }
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <LearningView
                    id={route?.params?.id}
                    type={'event'}
                    image={event?.image}
                    title={event?.name}
                    excerpt={event?.excerpt}
                    employee_lfecycle_stage={event?.employee_lifecycle_stage?.name}
                    five_s_category={event?.five_s_category?.name}
                    creator={event?.creator}
                    created_at={event?.created_at}
                >
                    <View style={styles.DateAndLocationContainer}>
                        <View style={styles.DateContainer}>
                            <Feather name='calendar' color='#8B77E8' size={25} />
                            <View style={{ marginLeft: 15 }}>
                                <Text style={{ fontSize: 12, fontWeight: '500' }}>{'Date & Time'}</Text>
                                <Text>{moment(event?.start_date).format('MMM DD, YYYY h:mm A') + ' - ' + moment(event?.end_date).format('MMM DD, YYYY h:mm A')}</Text>
                            </View>
                        </View>

                        <View style={styles.LocationContainer}>
                            <Feather name='map-pin' color='#8B77E8' size={25} />
                            <View style={{ marginLeft: 15, flex: 1 }}>
                                <Text style={{ fontSize: 12, fontWeight: '500' }}>{'Location'}</Text>
                                {
                                    isUrl(event?.location)
                                        ?
                                        <TouchableOpacity
                                            onPress={() => {
                                                Linking.canOpenURL(event?.location).then(supported => {
                                                    if (supported) {
                                                        Linking.openURL(event?.location);
                                                    }
                                                })

                                            }}
                                        >
                                            <Text style={{ color: "#8B77E8" }} >{event?.location?.toLowerCase()}</Text>
                                        </TouchableOpacity>
                                        :
                                        <Text style={{}} >{event?.location}</Text>
                                }
                            </View>
                            <View style={{ marginLeft: 15, flex: 1, alignItems: 'flex-end', maxWidth: 20 }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        Clipboard.setString(event.location);
                                        Toast.show({
                                            type: 'success',
                                            position: 'top',
                                            text1: "Copied to clipboard",
                                            visibilityTime: 2000
                                        })
                                    }}
                                >
                                    <Icon
                                        icon="copy"
                                        width={17}
                                        height={25}
                                    />
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>

                    {Platform.OS == 'web' ? (
                        <div dangerouslySetInnerHTML={{
                            __html: `
                                    <html>
                                    <head>
                                    <meta content="width=width, initial-scale=1, maximum-scale=1" name="viewport"></meta>
                                    <style>
                                    table {width: '100%' !important;white-space: inherit !important;}
                                    tr {width: '100%' !important;white-space: inherit !important;}
                                    td {width: '100%' !important;white-space: inherit !important;}
                                    </style>
                                    </head>
                                    <body >
                                    ${ContentData}
                                    </body>
                                    </html>
                                    `}}
                        />
                    ) : (
                        <HTML baseStyle={{ width: '100%', height: '100%' }} contentWidth={'100%'} source={{ html: ContentData }} customHTMLElementModels={customHTMLElementModels} {...htmlProps} originWhitelist={['*']} />
                    )}

                    {event?.video?.length > 0 ? (
                        <View style={{ marginTop: 20, marginBottom: 20 }}>
                            <Video
                                ref={video}
                                source={{ uri: event?.video }}
                                isLooping
                                resizeMode={Video.RESIZE_MODE_CONTAIN}
                                useNativeControls
                                style={{ width: '100%', aspectRatio: 1 }}
                            />
                        </View>
                    ) : null}
                </LearningView>
            </PageContent>
        </>
    )
}

export default ViewEvent