import React from 'react'
import {
    View,

    ScrollView,

} from 'react-native'

import Modal from 'react-native-modal'

import Event from './Event';
import { EVENT_MODE } from "./consts";


const ModalDuplicate = ({ showModal, setShowModal, setReloading, eventInfo = {} }) => {
    return (

        <Modal
            isVisible={showModal}
            onDismiss={() => { setShowModal(false) }}
            backdropOpacity={0.05}
            onBackdropPress={() => { setShowModal(false) }}
            animationIn={"slideInRight"}
            animationOut="slideOutRight"
            style={{
                borderRadius: 2,
                margin: 0

            }}
        >
            <View
                style={{
                    borderRadius: 8,
                    flex: 1,
                    backgroundColor: "#fff",
                    maxWidth: 380,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    bottom: 0
                }}
            >
                <ScrollView>
                    <Event
                        title={"Duplicate Event"}
                        mode={EVENT_MODE.duplicate}
                        showModal={setShowModal}
                        eventInfo={eventInfo}
                        setReloading={setReloading}
                    />
                </ScrollView>



            </View>

        </Modal>
    )
}

export default ModalDuplicate
