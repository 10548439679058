import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/article'
import * as api from '@/redux/api/article'

// STORE
function* store(action) {
  try {
    const payload = yield call(api.store, action)
    console.log('article create payload',payload)
    yield put({ type: constants.STORE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    console.log('article create error',error)
    yield put({
      type: constants.STORE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchStore() {
  yield takeLatest(constants.STORE.ACTION, store)
}

// STORE
function* storeDraft(action) {
  try {
    const payload = yield call(api.storeDraft, action)
    yield put({ type: constants.STORE_DRAFT.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.STORE_DRAFT.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchStoreDraft() {
  yield takeLatest(constants.STORE_DRAFT.ACTION, storeDraft)
}

// UPDATE
function* update(action) {
  try {
    const payload = yield call(api.update, action)
    yield put({ type: constants.UPDATE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.UPDATE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchUpdate() {
  yield takeLatest(constants.UPDATE.ACTION, update)
}

// DATAPROVIDER
function* dataprovider(action) {
  try {
    const payload = yield call(api.dataprovider, action)

    yield put({ type: constants.DATAPROVIDER.SUCCESS, payload })
    // action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.DATAPROVIDER.FAILED,
      message: error.message || error,
    })
    // action.next && action.next(error)
  }
}

function* watchDataprovider() {
  yield takeLatest(constants.DATAPROVIDER.ACTION, dataprovider)
}

// INDEX
function* index(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.INDEX.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.INDEX.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchIndex() {
  yield takeLatest(constants.INDEX.ACTION, index)
}

// INDEX
function* featured(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.FEATURED.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.FEATURED.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchFeatured() {
  yield takeLatest(constants.FEATURED.ACTION, featured)
}

// INDEX
function* readLater(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.READ_LATER.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.READ_LATER.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchReadLater() {
  yield takeLatest(constants.READ_LATER.ACTION, readLater)
}

// SET READ LATER
function* setReadLater(action) {
  try {
    const payload = yield call(api.readLater, action)
    yield put({ type: constants.SET_READ_LATER.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.SET_READ_LATER.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchSetReadLater() {
  yield takeLatest(constants.SET_READ_LATER.ACTION, setReadLater)
}

// SHOW
function* show(action) {
  try {
    console.log('action',action)
    const payload = yield call(api.show, action)
    console.log('payload',payload)
    yield put({ type: constants.SHOW.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    console.log('error',error)
    yield put({
      type: constants.SHOW.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchShow() {
  yield takeLatest(constants.SHOW.ACTION, show)
}

// REMOVE
function* remove(action) {
  try {
    const payload = yield call(api.remove, action)
    yield put({ type: constants.REMOVE.SUCCESS, payload, action })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.REMOVE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchRemove() {
  yield takeLatest(constants.REMOVE.ACTION, remove)
}

// STORE IMAGES
function* storeImages(action) {
  try {
    const payload = yield call(api.storeImages, action)
    console.log('store image payload',payload)
    yield put({ type: constants.STORE_IMAGES.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.STORE_IMAGES.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchStoreImages() {
  yield takeLatest(constants.STORE_IMAGES.ACTION, storeImages)
}

// REMOVE IMAGE
function* deleteImage(action) {
  try {
    yield call(api.deleteImage, action)
    const payload = action?.id
    yield put({ type: constants.REMOVE_IMAGE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.REMOVE_IMAGE.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchDeleteImage() {
  yield takeLatest(constants.REMOVE_IMAGE.ACTION, deleteImage)
}

// TRACK TIME
function* trackTime(action) {
   try {
    const {response,error} = yield call(api.trackActiveArticleTime, action)
    yield put({ type: constants.ARTICLE_TRACKING_TIME.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (error) {
    yield put({
      type: constants.ARTICLE_TRACKING_TIME.FAILED,
      message: error.message || error,
    })
    action.next && action.next(error)
  }
}

function* watchTrackTime() {
  yield takeLatest(constants.ARTICLE_TRACKING_TIME.ACTION, trackTime)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchStore),
    fork(watchStoreDraft),
    fork(watchUpdate),
    fork(watchDataprovider),
    fork(watchIndex),
    fork(watchFeatured),
    fork(watchReadLater),
    fork(watchSetReadLater),
    fork(watchShow),
    fork(watchRemove),
    fork(watchStoreImages),
    fork(watchDeleteImage),
    fork(watchTrackTime),
  ])
}
