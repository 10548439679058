import styled from 'styled-components/native'
import Button from '@/components/Button'
import { ScreenSize } from '@/styles'
import { vh } from 'react-native-expo-viewport-units'
import { Subtitle1, Subtitle2 } from '@/styles/fonts'

export const ForgotPasswordPageContent = styled.View`
  height: 100%;
  padding-top: ${({ screenSize }) =>
    screenSize >= ScreenSize.Medium ? `${vh(5)}` : '0'}px;
`
export const Instructions = styled(Subtitle1)`
  color: #6d6d6d;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: justify;
  margin-right: 20px;
`

export const SubmitButton = styled(Button)`
  margin: 30px 0 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const TextButton = styled.Text`
  color: ${({ theme }) => theme.basics.background_white};
  font-family: 'WorkSans_600SemiBold';
  font-size: 17px;
  text-align: center;
`

export const Skip = styled(Subtitle1)`
  color: #000000;
  text-align: center;
`
export const ChangeEmailContainer = styled.View`
  flex: 1;
  margin-bottom: 10px;
  justify-content: flex-end;
  align-items: center;
`
export const ChangeEmailText = styled(Subtitle2)`
  text-align: center;
`
export const ChangeEmailLink = styled(Subtitle2)`
  color: ${({ theme }) => theme.danger.standard};
`
