import { isMobile } from '@/styles'
import styled from 'styled-components/native'

export const Container = styled.View`
    position: relative;
    justify-content: center;
    align-items: center;
`

export const BadgeContainer = styled.View`
    position: absolute;
    top: ${isMobile ? "-5px" : 0};
    right: ${({forMeeting}) => forMeeting ? "-8px" : isMobile ? "-8px" : 0};
    width: ${({forMeeting}) => forMeeting ? '25px' : "15px"};
    height: ${({forMeeting}) => forMeeting ? '25px' : "15px"};
    background-color: ${({accentColor}) => accentColor ? accentColor : undefined};
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    z-index: 500;
`
export const BadgeText = styled.Text`
    color: white;
    font-weight: bold;
`