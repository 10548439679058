import React, { useRef, useEffect, useCallback } from "react";
import { Animated, Platform, View, ScrollView, SafeAreaView, Image } from "react-native";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useRoute, useNavigation } from "@react-navigation/native";

import Icon from '@/assets/brand/ReframeRoundLogo.png'

import styles from './styles.js';

export default function TheaterLayout({
    children
}) {
    const route = useRoute();
    const navigation = useNavigation();
    const dispatch = useDispatch();

    useEffect(() => {
        fadeIn()
    }, []);

    const fadeAnimation = useRef(new Animated.Value(0)).current;

    const fadeIn = () => {
        Animated.timing(fadeAnimation, {
            toValue: 1,
            duration: 1000
        }).start();
    };

    return (
        <SafeAreaView
            style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <ScrollView style={{
                width: '100%',
                height: '100%'
            }}>
                <Animated.View
                    style={[
                        styles.AnimatedContainer,
                        {
                            opacity: fadeAnimation
                        }
                    ]}
                >
                    <View style={styles.Header}>
                        <Image source={Icon} resizeMode="cover" style={styles.HeaderIcon} />
                    </View>

                    <View style={styles.Body}>
                        {children}
                    </View>
                </Animated.View>
            </ScrollView>
        </SafeAreaView>
    );
}

TheaterLayout.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    backEnabled: PropTypes.bool,
    children: PropTypes.node,
};