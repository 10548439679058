import { all, put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/auth'
import * as api from '@/redux/api/auth'
import { persistor } from '@/redux/store'

// LOGIN
function* login(action) {
  try {
    const payload = yield call(api.login, action)
    yield put({ type: constants.AUTH_LOGIN.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.AUTH_LOGIN.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchLogin() {
  yield takeEvery(constants.AUTH_LOGIN.ACTION, login)
}

// SIGNUP
function* signUp(action) {
  try {
    const payload = yield call(api.signUp, action)
    yield put({ type: constants.AUTH_SIGNUP.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.AUTH_SIGNUP.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchSignUp() {
  yield takeEvery(constants.AUTH_SIGNUP.ACTION, signUp)
}

// FORGOT_PASSWORD
function* forgotPassword(action) {
  try {
    const payload = yield call(api.forgotPassword, action)
    yield put({ type: constants.AUTH_FORGOT_PASSWORD.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.AUTH_FORGOT_PASSWORD.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchForgotPassword() {
  yield takeEvery(constants.AUTH_FORGOT_PASSWORD.ACTION, forgotPassword)
}

// RESET_PASSWORD
function* resetPassword(action) {
  try {
    const payload = yield call(api.resetPassword, action)
    yield put({ type: constants.AUTH_RESET_PASSWORD.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.AUTH_RESET_PASSWORD.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchResetPassword() {
  yield takeLatest(constants.AUTH_RESET_PASSWORD.ACTION, resetPassword)
}

// AUTH_SEND_CODE_PASSWORD
function* sendCodePassword(action) {
  try {
    const payload = yield call(api.sendCodePassword, action)
    yield put({ type: constants.AUTH_SEND_CODE_PASSWORD.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.AUTH_SEND_CODE_PASSWORD.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchSendCodePassword() {
  yield takeEvery(constants.AUTH_SEND_CODE_PASSWORD.ACTION, sendCodePassword)
}


// LOGOUT
function* logout() {
  yield put({ type: 'LOGOUT' })
  yield call(persistor.purge)
}

function* watchLogout() {
  yield takeLatest(constants.AUTH_LOGOUT, logout)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchSignUp),
    fork(watchForgotPassword),
    fork(watchSendCodePassword),
    fork(watchResetPassword),
    fork(watchLogout),
  ])
}
