import React, { useEffect, useRef } from "react";
import { View, TouchableOpacity, Platform, Dimensions } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";

import RightSideDrawerLayout from "@/components/Layout/Page/Layouts/RightSideDrawer/index";
import { useSideDrawer } from "@/hooks/sideDrawer";
import theme from "@/styles/themes/black";
import Icon from "@/components/Icon";
import Home from "@/pages/Home";
import Profile from "@/pages/profile";
import Community from "@/pages/Community";
import Teams from "@/pages/Community/Teams";
import Groups from "@/pages/Community/Groups";
import TeamPage from "@/pages/Community/TeamPage";
import GroupPage from "@/pages/Community/GroupPage";
import EditProfile from "@/pages/profile/EditProfile";
import InviteMemberMobile from "@/pages/Community/InviteMemberMobile";
import Employee from "@/pages/Community/Employee";
import GroupMembers from "@/pages/Community/GroupMembers";
import TeamsAndGroupsView from "@/pages/Community/TeamsAndGroups/View";

import Learning from "@/pages/Learning/Main";
import CreateArticle from "@/pages/Learning/Articles/create";
import PostView from "@/components/Feed/Post/View"
import ViewArticle from "@/pages/Learning/Articles/View";

import Courses from "@/pages/Learning/Courses/courses";
import CreateCourse from "@/pages/Learning/Courses/create";
import ViewCourse from "@/pages/Learning/Courses/View";

import Events from "@/pages/Learning/Events/events";
import CreateEvent from "@/pages/Learning/Events/create";
import ViewEvent from "@/pages/Learning/Events/View";

import Settings from "@/pages/Settings/index";
import Notifications from "@/pages/Notifications";
import Support from "@/pages/Support/Support";

import Analytics from "@/pages/Analytics/index";
import CommunityAnalytics from "@/pages/Analytics/CommunityAnalytics/index";
import MemberAnalytics from "@/pages/Analytics/MemberAnalytics/index";
import EventAnalytics from "@/pages/Analytics/EventAnalytics/index";
import LearningAnalytics from "@/pages/Analytics/LearningAnalytics/index";
import AssessmentAnalytics from "@/pages/Analytics/AssessmentAnalytics/index";
import CultureAnalytics from "@/pages/Analytics/AssessmentAnalytics/CulturalAnalytics/index";
import PublishingAnalytics from "@/pages/Analytics/PublishingAnalytics/index";
import ResultAnalytics from "@/pages/Analytics/AssessmentAnalytics/ResultAnalytics/index";

import Assessments from "@/pages/Assessments/assessments";
import CreateAssessment from "@/pages/Assessments/create";
import Articles from "@/pages/Learning/Articles/articles";
import CalendarAndEvents from "@/pages/CalendarAndEvents";
import EventCreate from "@/pages/CalendarAndEvents/EventCreate";
import EventEdit from "@/pages/CalendarAndEvents/EventEdit";
import EventDuplicate from "@/pages/CalendarAndEvents/EventDuplicate";
import EventDetail from "@/pages/CalendarAndEvents/EventDetail";
import ChatStack from "./ChatStack";
import Conversation from "@/pages/TwilioConversations";
import UnreadBadge from "@/components/Chat/components/UnreadBadge";
import SharedFolders from "@/pages/SharedFolders/Main";
import SubFolders from "@/pages/SharedFolders/SubFolders";
import * as Notificationss from "expo-notifications";
import RewardsManagement from "@/pages/Rewards/Management";
import RewardsMember from "@/pages/Rewards/Member";
import Workspaces from "@/pages/Workspaces";
import { ScreenSize } from "@/styles";
import ChatWrapper from "@/pages/TwilioConversations/Mobile/ChatWrapper";

const Tabs = createBottomTabNavigator();
const Stack = createStackNavigator();

const renderTabIcon = (icon, size, focused) => (
  <Icon
    icon={icon}
    width={size}
    color={focused ? theme.basics[900] : theme.basics[400]}
  />
);

export default function MainTabNavigator({ navigation }) {
  const { open, setOpen } = useSideDrawer();
  const notificationListener = useRef();
  const responseListener = useRef();

  const notificationListenerFun = async () => {
    // notification listening part goes here
    responseListener.current =
      await Notificationss.addNotificationResponseReceivedListener(
        (response) => {
          console.log(
            "Notification Response *********",
            response?.notification?.request?.content?.data
          );
          // navigation.navigate('CommentPost', {
          //   post_id: 323,
          //   // post_type: 187,
          //   // id_present: 187,
          //   contentType: 'AppPost',
          // })

          // uncomment this for testing
          // const data = {
          //   'action': {
          //     'type': 'ViewMemberProfile',
          //     'target': 18
          //   }
          // }
          notificationRouter(
            response?.notification?.request?.content?.data?.action
          );
        }
      );
  };

  const notificationRouter = (action) => {
    // action = {
    //     'type': 'ViewTeam',
    //     'target': 6
    // }
    switch (action?.type) {
      case "ViewFeedPost":
        if (action?.target && Platform.OS != "web") {
          console.log("**** TYPE: ViewFeedPost", action.target);
          navigation.navigate("CommentPost", {
            //post_id: 323,
            post_id: action.target,
            // post_type: 187,
            // id_present: 187,
            contentType: "AppPost",
          });
        } else {
          navigation.navigate("Home");
        }
        break;
      case "ViewMemberProfile":
        if (action?.target) {
          console.log("**** TYPE: ViewMemberProfile", action.target);
          navigation.navigate("Profile", {
            id: action.target,
          });
        } else {
          navigation.navigate("Settings");
        }
        break;
      case "ViewArticle":
        if (action?.target) {
          console.log("**** TYPE: ViewArticle", action.target);
          navigation.navigate("ViewArticle", {
            id: action.target,
          });
        } else {
          navigation.navigate("Learning");
        }
        break;
      case "ViewCourse":
        if (action?.target) {
          console.log("**** TYPE: ViewCourse", action.target);
          navigation.navigate("ViewCourse", {
            id: action.target,
          });
        } else {
          navigation.navigate("Learning");
        }
        break;
      case "ViewEvent":
        if (action?.target) {
          console.log("**** TYPE: ViewEvent", action.target);
          navigation.navigate("ViewEvent", {
            id: action.target,
          });
        } else {
          navigation.navigate("Community");
        }
        break;
      case "ViewTeam":
        if (action?.target) {
          console.log("**** TYPE: ViewTeam", action.target);
          navigation.navigate("TeamPage", {
            id: action.target,
          });
        } else {
          navigation.navigate("Community");
        }
        break;
      case "ViewGroup":
        if (action?.target) {
          console.log("**** TYPE: ViewGroup", action.target);
          navigation.navigate("GroupPage", {
            id: action.target,
          });
        } else {
          navigation.navigate("Community");
        }
        break;
      case "ViewGroup":
        if (action?.target) {
          console.log("**** TYPE: ViewGroup", action.target);
          navigation.navigate("GroupPage", {
            id: action.target,
          });
        } else {
          navigation.navigate("Community");
        }
        break;
      case "ViewMessenger":
        console.log("**** TYPE: ViewMessenger", action);
        navigation.navigate("chat");
        break;
      case "ViewCalendarEntry":
        console.log("**** TYPE: ViewCalendarEntry", action);
        navigation.navigate("Calendar");
        break;
      default:
        // ViewFeed action type
        navigation.navigate("Home");
    }
  };

  useEffect(() => {
    notificationListenerFun();
    //This Listener will called if app is running
    // notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
    //   console.log("***********NNNNNNN", notification)
    // });
    //Called when notification recieved is pressed
    // navigation.navigate('CommentPost', {
    //   post_id: 187,
    //   // post_type: 187,
    //   // id_present: 187,
    //   contentType: 'AppPost',
    // })
  }, []);

  const renderTabButton = (opts) => (
    <TouchableOpacity
      {...opts}
      onPress={() => {
        if (open) setOpen(false);
        opts.onPress();
      }}
    />
  );

  return (
    <Tabs.Navigator
      initialRouteName="Home"
      tabBarOptions={{ showLabel: false }}
    >
      <Tabs.Screen
        name="Home"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) => renderTabIcon("home", 21, focused),
        }}
      >
        {(props) => (
          <RightSideDrawerLayout scrollDisabled={true}>
            <Home {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="chat"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) => (
            <UnreadBadge>{renderTabIcon("comment", 21, focused)}</UnreadBadge>
          ),
        }}
      >
        {(props) => (
          <ChatWrapper>
            <ChatStack {...props} />
          </ChatWrapper>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Community"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) => renderTabIcon("community", 22, focused),
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Community {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="Calendar"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) => renderTabIcon("calendar", 22, focused),
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CalendarAndEvents {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="EventCreate"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <EventCreate {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="EventEdit"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <EventEdit {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="EventDuplicate"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <EventDuplicate {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="EventDetail"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <EventDetail {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="Teams"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Teams {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="TeamPage"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <TeamsAndGroupsView {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="GroupPage"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <TeamsAndGroupsView {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Employee"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Employee {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="GroupMembers"
        component={GroupMembers}
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      />
      <Tabs.Screen
        name="Groups"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Groups {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="InviteMemberMobile"
        component={InviteMemberMobile}
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      />
      <Tabs.Screen
        name="CommentPost"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <PostView {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="cultureAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CultureAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      {/* <Tabs.Screen
        name="Calendar"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) =>
            renderTabIcon('calendar', 20, focused),
        }}
      >
        {() => (
          <RightSideDrawerLayout>
            <View
              style={{ flex: 1, backgroundColor: 'red', padding: 50 }}
            />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen> */}
      <Tabs.Screen
        name="Assessments"
        options={{ title: "Reframe | Assessments", tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Assessments {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="assessment"
        options={{ title: "Reframe | Assessments", tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CreateAssessment {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="Learning"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) => renderTabIcon("education", 22, focused),
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Learning {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="SharedFolders"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <SharedFolders {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="SubFolders"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <SubFolders {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="Articles"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Articles {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="CreateArticle"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CreateArticle {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="ViewArticle"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <ViewArticle {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Courses"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Courses {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="CreateCourse"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CreateCourse {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="ViewCourse"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <ViewCourse {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="Events"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Events {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="CreateEvent"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CreateEvent {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="ViewEvent"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <ViewEvent {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Profile"
        options={{ tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Profile {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      {/* ANALYSIS ROUTES */}
      <Tabs.Screen
        name="Analytics"
        options={{ tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Analytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="CommunityAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <CommunityAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="MemberAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <MemberAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="EventAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <EventAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="LearningAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <LearningAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="AssessmentAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <AssessmentAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="PublishingAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <PublishingAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="resultAnalytics"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <ResultAnalytics {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Settings"
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Settings {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Support"
        component={Support}
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      />
      <Tabs.Screen
        name="EditProfile"
        component={EditProfile}
        options={{
          tabBarIcon: () => null,
          tabBarButton: () => null,
        }}
      />
      <Tabs.Screen
        name="MemberMobile"
        options={{ tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <RewardsMember {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="ManagerMobile"
        options={{ tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <RewardsManagement {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
      <Tabs.Screen
        name="Notifications"
        options={{
          tabBarButton: renderTabButton,
          tabBarIcon: ({ focused }) =>
            renderTabIcon("notifications", 21, focused),
        }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Notifications {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>

      <Tabs.Screen
        name="Home5"
        component={View}
        options={{
          tabBarIcon: ({ focused }) => renderTabIcon("ellipsis", 18, focused),
          tabBarButton: (props) => (
            <TouchableOpacity
              {...props}
              onPress={(e) => {
                setOpen(!open);
              }}
            />
          ),
        }}
      />
      <Tabs.Screen
        name="Workspaces"
        options={{ tabBarIcon: () => null, tabBarButton: () => null }}
      >
        {(props) => (
          <RightSideDrawerLayout>
            <Workspaces {...props} />
          </RightSideDrawerLayout>
        )}
      </Tabs.Screen>
    </Tabs.Navigator>
  );
}