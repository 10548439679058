import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	Image,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	TextInput,
	FlatList,
	Dimensions,
	CheckBox,
	Platform,
	ActivityIndicator,
} from "react-native";
import { isMobile } from "@/styles";
import Modal from "react-native-modal";
import ApiHandler from "@/api/ApiHandler";
import { Ionicons } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
const deviceHeight = Dimensions.get("window").height;
import BottomSheet from "./BottomSheet";
import OutlineButton from "./OutlineButton";
import Button from "./Button";
import Checkbox from "expo-checkbox";

const members = [
	{
		id: 1,
		photo: require("@/assets/Article.png"),
		name: "Angela Schrute",
		role: "CEO | Technology",
		updateDate: "last update 26 weeks ago",
		value: false,
	},
	{
		id: 2,
		photo: require("@/assets/Article.png"),
		name: "Bangela Schrute",
		role: "CEO | Technology",
		updateDate: "last update 26 weeks ago",
		value: false,
	},
	{
		id: 3,
		photo: require("@/assets/Article.png"),
		name: "Angela Schrute",
		role: "CEO | Technology",
		updateDate: "last update 26 weeks ago",
		value: false,
	},
	{
		id: 4,
		photo: require("@/assets/Article.png"),
		name: "Zangela Schrute",
		role: "CEO | Technology",
		updateDate: "last update 26 weeks ago",
		value: false,
	},
	{
		id: 5,
		photo: require("@/assets/Article.png"),
		name: "Aabbb Schrute",
		role: "CEO | Technology",
		updateDate: "last update 26 weeks ago",
		value: false,
	},
];

const selectRewards = [
	{
		id: 1,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 2,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 3,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 4,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 5,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 6,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 5,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
	{
		id: 6,
		photo: require("@/assets/Article.png"),
		title: "$10 Gift card",
		description:
			"Velit suscipit id libero sed odio. Velit bibendum bibendum in vel quis metus mattis.",
		awarded: "24 Awardees",
		imported: "Imported",
		value: false,
	},
];

let Array = [];
const SendManualRewardModal = ({
	isModalVisible,
	rewardId = null,
	toggleModal,
}) => {
	const [arrayData, setArrayData] = useState(members);
	const [searchQuery, setSearchQuery] = useState("");
	const [results, setResults] = useState([]);
	const [selectedMembers, setSelectedMembers] = useState([]);
	const [selectedReward, setSelectedReward] = useState();
	const [loading, setLoading] = useState(false);

	const Filtering = () => {
		setResults([]);
		setLoading(true);
		Array = [];
		for (let i = 0; i < arrayData?.length; i++) {
			if (arrayData[i]?.first_name?.indexOf(searchQuery) >= 0) {
				Array.push(arrayData[i]);
				setResults(Array);
				setLoading(false);
			}
		}
	};
	useEffect(() => {
		Filtering();
		//getWorkSpaceMembers();
	}, [searchQuery]);

	useEffect(() => {
		getWorkSpaceMembers();
		getRewardById();
	}, []);

	const createRewardOrders = async () => {
		console.log(
			"EMPLOYEES ARRAY **********vvvvvvvvvvvvvvvvvvvv",
			selectedMembers
		);
		const data = {
			reward_id: rewardId,
			recipients: selectedMembers,
		};
		try {
			const response = await new ApiHandler().createRewardOrders(data);
			console.log("createRewardsOrder......", response?.data);
			if (response?.data) {
				// dispatch(actions.show({ id: route?.params?.id }, () => { }))
				toggleModal();
				Toast.show({
					type: "success",
					position: "bottom",
					text1: "The rewards orders were created.",
				});
			}
		} catch (error) {
			console.log("error", error);
			Toast.show({
				type: "error",
				position: "bottom",
				text1: error,
			});
		}
	};

	const getWorkSpaceMembers = async () => {
		setLoading(true);
		try {
			const { data } = await new ApiHandler().getWorkSpaceMembers();
			//   const arr = data.map((item) => item.id.toString())
			console.log("EMPLOYEEES DATA **********", data);
			data.map((item) => (item.checked = false));
			setResults(data);
			setArrayData(data);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const getRewardById = async () => {
		try {
			const response = await new ApiHandler().getRewardById(rewardId);
			if (response?.data) {
				console.log(
					"RESPONSE ***** ====== ^^^^^^^^ REWARD BY ID",
					response?.data
				);
				setSelectedReward(response?.data?.data);
			}
		} catch (error) {
			console.log("ERROR", error);
		} finally {
		}
	};

	return Platform.OS == "web" ? (
		<View style={{}}>
			<Modal
				isVisible={isModalVisible}
				animationIn={"slideInUp"}
				style={styles.modal}
			>
				<View style={{ flex: 1 }}>
					<TouchableOpacity onPress={toggleModal} style={styles.closeBtn}>
						<Ionicons name="close-outline" size={35} />
					</TouchableOpacity>
					<Text style={styles.headerTitle}>{"Send Reward To Members"}</Text>
					<View style={styles.modalHeaderView}>
						<Image
							source={{ uri: selectedReward?.image }}
							style={styles.modalProfilePic}
						/>
						<Text style={styles.modalTitle}>{selectedReward?.title}</Text>
					</View>
					<View style={{ flex: 0.3 }}>
						<View>
							<TextInput
								onChangeText={(text) => setSearchQuery(text)}
								placeholder="Search members"
								style={styles.input}
							/>
							<Ionicons
								name="search"
								color="#959595"
								size={18}
								style={styles.searchIcon}
							/>
						</View>
					</View>
					<View style={styles.descriptionView}>
						{loading ? (
							<ActivityIndicator
								size={30}
								animating
								color="red"
								style={{ marginRight: 10 }}
							/>
						) : null}
						<FlatList
							data={results}
							renderItem={({ item, index }) => {
								return (
									<View style={styles.selectCheck}>
										<CheckBox
											color={"#F05343"}
											style={{
												alignSelf: "center",
												marginEnd: 5,
											}}
											onValueChange={(value) => {
												let newResultsArray = [...results];
												newResultsArray[index].checked = value;
												const arrayData = newResultsArray
													.filter((item) => item.checked)
													.map((item) => item.id.toString());
												setResults(newResultsArray);
												setSelectedMembers(arrayData);
											}}
											value={item.checked}
										/>
										<Image
											source={{ uri: item?.avatar }}
											style={styles.photo}
										/>

										<View>
											<Text style={styles.memberName}>{item?.first_name}</Text>
											<Text style={styles.memberPost}>{item?.role}</Text>
											<Text style={styles.memberPost}>{item?.updated_at}</Text>
										</View>
									</View>
								);
							}}
							keyExtractor={(item) => item.id}
						/>
					</View>
					<View style={styles.btnView}>
						<TouchableOpacity style={[styles.editBtn, styles.cancelBtn]}>
							<Text style={[styles.editBtnTxt, { color: "#000" }]}>
								{"Cancel"}
							</Text>
						</TouchableOpacity>
						<TouchableOpacity
							onPress={createRewardOrders}
							style={styles.editBtn}
						>
							<Text style={styles.editBtnTxt}>{"Send"}</Text>
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
		</View>
	) : (
		<BottomSheet isVisible={isModalVisible} height={deviceHeight - 200}>
			<View style={styles.sendRewardContainer}>
				<View style={styles.sendRewardHead}>
					<Text style={[styles.sendRewardTitle, { flex: 1 }]}>
						{"Send as a Reward"}
					</Text>
					<TouchableOpacity onPress={toggleModal}>
						<Ionicons name="close-outline" size={35} />
					</TouchableOpacity>
				</View>
				<View style={styles.modalHeaderView}>
					<Image
						source={{ uri: selectedReward?.image }}
						style={styles.modalProfilePic}
					/>
					<Text style={styles.sendRewardMainTitle}>
						{selectedReward?.title}
					</Text>
					<TouchableOpacity style={styles.importedBtn}>
						<Text style={styles.importedBtnTxt}>{selectedReward?.type}</Text>
					</TouchableOpacity>
				</View>
				<View style={{ flex: 0.3 }}>
					<View style={styles.photoView}>
						<Image
							source={{ uri: selectedReward?.awardees[0]?.avatar }}
							style={styles.photo}
						/>
						<Image
							source={{ uri: selectedReward?.awardees[1]?.avatar }}
							style={styles.photo}
						/>
						<View>
							<Image
								source={{ uri: selectedReward?.awardees[2]?.avatar }}
								style={styles.photo}
							/>
							<Text style={styles.imgCountModal}>
								{selectedReward?.awardees?.length - 1}+
							</Text>
						</View>
						<Text style={styles.awardeesTxt}>{"Awardees"}</Text>
					</View>
					<View style={styles.searchInputView}>
						<TextInput
							onChangeText={(text) => setSearchQuery(text)}
							placeholder="Search members"
							style={styles.input}
						/>
						<Ionicons
							name="search"
							color="#959595"
							size={18}
							style={styles.searchIcon}
						/>
					</View>
				</View>
				<View style={styles.sendRewardDescriptionView}>
					<FlatList
						data={results}
						renderItem={({ item, index }) => {
							return (
								<View style={styles.selectCheck}>
									<Checkbox
										color={item.checked ? "#F05343" : "#D9D9D9"}
										style={{
											alignSelf: "center",
											marginEnd: 5,
										}}
										onValueChange={(value) => {
											let newResultsArray = [...results];
											newResultsArray[index].checked = value;
											const arrayData = newResultsArray
												.filter((item) => item.checked)
												.map((item) => item.id.toString());
											setResults(newResultsArray);
											setSelectedMembers(arrayData);
										}}
										value={item.checked}
									/>
									<Image source={{ uri: item?.avatar }} style={styles.photo} />

									<View>
										<Text style={styles.memberName}>{item?.first_name}</Text>
										<Text style={styles.memberPost}>{item?.role}</Text>
										<Text style={styles.memberPost}>{item?.updated_at}</Text>
									</View>
								</View>
							);
						}}
						keyExtractor={(item) => item.id}
					/>
				</View>
				<View style={styles.sendRewardBtns}>
					<OutlineButton
						label="Cancel"
						onPress={toggleModal}
						containerStyle={{
							borderColor: "#1B1B1B",
							flex: 1,
							marginTop: 0,
							marginRight: 5,
						}}
						labelStyle={{ color: "#000000" }}
					/>
					<Button
						onPress={createRewardOrders}
						label="Send"
						containerStyle={{ flex: 1, marginTop: 0, marginLeft: 5 }}
					/>
				</View>
			</View>
		</BottomSheet>
	);
};

const styles = StyleSheet.create({
	closeBtn: {
		position: "absolute",
		right: 8,
		top: 8,
	},
	headerTitle: {
		fontSize: 32,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	modalHeaderView: {
		flex: 0.15,
		flexDirection: "row",
		alignItems: "center",
	},
	modalProfilePic: {
		height: 22,
		width: 35,
		borderRadius: 4,
	},
	modalTitle: {
		marginLeft: 5,
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	importedBtn: {
		backgroundColor: "#FFCF70",
		borderRadius: 4,
		height: 16,
		width: 45,
		justifyContent: "center",
		alignItems: "center",
		marginLeft: 15,
	},
	importedBtnTxt: {
		fontSize: 8,
		fontWeight: "500",
		lineHeight: 10,
	},
	photoView: {
		flexDirection: "row",
		alignItems: "center",
	},
	photo: {
		height: 50,
		width: 50,
		borderRadius: 33,
		marginEnd: 8,
	},

	imgCountModal: {
		fontSize: 15,
		lineHeight: 20,
		color: "#fff",
		fontWeight: "400",
		position: "absolute",
		top: 15,
		left: 10,
	},
	awardeesTxt: {
		fontSize: 12,
		fontWeight: "400",
		lineHeight: 16,
	},
	input: {
		borderBottomWidth: 1,
		borderBottomColor: "#959595",
		marginTop: 25,
		marginBottom: 10,
		height: 40,
		padding: 5,
	},
	searchIcon: {
		position: "absolute",
		right: 20,
		bottom: 20,
	},
	descriptionView: {
		flex: 0.45,
		paddingVertical: 10,
	},
	selectCheck: {
		flexDirection: "row",
		marginVertical: 8,
		alignItems: "center",
	},
	memberName: {
		fontSize: 15,
		lineHeight: 20,
		fontWeight: "400",
	},
	memberPost: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#B5B5B5",
	},
	editBtn: {
		backgroundColor: "#000",
		height: 40,
		width: 133,
		borderRadius: 3,
		justifyContent: "center",
		alignItems: "center",
		borderColor: "#000",
		borderWidth: 1,
	},
	cancelBtn: {
		marginEnd: 10,
		backgroundColor: "#fff",
	},
	editBtnTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	milestoneModalHeader: {
		flex: 1,
		paddingHorizontal: 25,
	},

	rightSideModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rightSideModalDec: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
	},
	dropDownView: {
		marginTop: 20,
		paddingVertical: 15,
	},
	rightSideModalPointsTitle: {
		fontSize: 18,
		lineHeight: 21.09,
		fontWeight: "400",
	},
	selectHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 20,
	},
	selectReward: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
		color: "#FB6758",
	},
	selectedContainer: {
		backgroundColor: "#E8E8E8",
		height: 117,
		// width: "35,",
		borderRadius: 4,
		paddingHorizontal: 12,
		marginVertical: 5,
		paddingVertical: 5,
	},
	selectedCardView: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	selectedCardHeader: {
		flexDirection: "row",
		alignItems: "center",
	},
	radioBtn: {
		height: 12,
		width: 12,
		borderRadius: 6,
		borderWidth: 1,
		borderColor: "#000",
		marginEnd: 5,
	},
	cardImg: {
		height: 22,
		width: 35,
		borderRadius: 3,
		marginEnd: 5,
	},
	selectedCardTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	criteriaTxt: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#FB6758",
	},
	selectedCardDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#000",
		marginVertical: 5,
	},
	cardBottom: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: 5,
	},
	awardTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#00B15C",
	},
	ipmTxt: {
		fontSize: 10,
		lineHeight: 13,
		fontWeight: "500",
		color: "#000",
	},
	rightModalBtn: {
		backgroundColor: "#FB6758",
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		height: 40,
		marginVertical: 10,
	},
	rightModalBtnText: {
		fontSize: 14,
		lineHeight: 18,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},
	rightSideModal: {
		backgroundColor: "#fff",
		borderTopLeftRadius: 20,
		borderBottomLeftRadius: 20,
		alignSelf: "flex-end",
		paddingVertical: 15,
		margin: 0,
		width: "40%",
		// height: "100%",
	},
	closeBtn: {
		position: "absolute",
		right: 8,
		top: 8,
	},
	rewardModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	rewardModalDes: {
		fontSize: 12,
		lineHeight: 16,
		fontWeight: "400",
		color: "#141414",
	},
	modal: {
		height: "70%",
		width: "55%",
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 20,
		paddingVertical: 15,
	},
	rewardTabView: {
		flex: 1,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	rewardTab: {
		justifyContent: "center",
		alignItems: "center",
		borderBottomWidth: 2,
		paddingVertical: 5,
		width: "48%",
		flex: 0.5,
		marginHorizontal: 2,
		marginVertical: 20,
	},
	rewardBtnTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "400",
		color: "#141414",
	},
	imgContainer: {
		flex: 1,
		justifyContent: "space-between",
		flexDirection: "row",
		marginVertical: 10,
	},
	titleText: {
		fontSize: 18,
		lineHeight: 21,
		fontWeight: "400",
		color: "#141414",
		marginVertical: 5,
	},
	giftCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		paddingHorizontal: 10,
	},

	giftImgCard: {
		backgroundColor: "#E8E8E8",
		height: 30,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 8,
		borderColor: "#FB6758",
		borderWidth: 1,
		borderStyle: "dashed",
	},
	giftCardTxt: {
		fontSize: 16,
		lineHeight: 20,
		fontWeight: "500",
	},
	imgSizeTxt: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: "500",
		color: "gray",
	},

	rewardTxtInput: {
		padding: 10,
		height: 100,
		marginVertical: 10,
		backgroundColor: "#F9F9F9",
	},
	importView: {
		flex: 1,
		alignItems: "center",
		alignSelf: "center",
	},

	rewardModalSelectionList: { height: 138, width: "98%", marginEnd: 5 },

	bottomView: {
		justifyContent: "space-between",
		flexDirection: "row",
		// marginTop: 150,
		position: "absolute",
		bottom: 5,
		right: 20,
		left: 20,
	},
	saveCreateTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		color: "#FB6758",
	},
	btnAlignment: {
		alignItems: "center",
	},
	rewardModalBtn: {
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: "#FB6758",
		height: 40,
		width: 180,
		borderRadius: 4,
	},
	createTxt: {
		fontSize: 18,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		color: "#fff",
	},

	workspaceBtn: {
		backgroundColor: "#FB6758",
		height: 40,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
		position: "absolute",
		width: 227,
		alignSelf: "flex-end",
		bottom: 5,
		right: 20,
	},

	container: {
		padding: 15,
		flex: 1,
	},
	rewardTitle: {
		fontSize: 36,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	subTitleView: {
		flexDirection: "row",
	},
	activityTxt: {
		fontSize: 24,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	monthView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 20,
		marginTop: 10,
		marginEnd: 8,
	},
	monthTxt: {
		fontSize: 14,
		lineHeight: 18.3,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginEnd: 5,
	},
	cardView: {
		flexDirection: "row",
	},
	topPerformersView: { flex: 0.4, paddingLeft: 15 },

	lastMonthTxt: {
		color: "#909090",
		fontSize: 14,
		lineHeight: 18.23,
		fontWeight: "400",
		marginTop: 40,
	},
	headerTitleBtnView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 30,
		marginBottom: 10,
		justifyContent: "space-between",
	},
	titleView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},

	Button: {
		backgroundColor: "#FB6758",
		paddingHorizontal: 50,
		paddingVertical: 1.2,
		justifyContent: "center",
		alignItems: "center",
		borderRadius: 4,
	},

	BtnText: {
		color: "#fff",
		fontSize: 18,
		lineHeight: 35,
		fontWeight: "500",
		letterSpacing: -0.8,
	},
	btnView: {
		flex: 0.1,
		flexDirection: "row",
		alignSelf: "flex-end",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	calenderView: {
		width: !isMobile ? 410 : null,
		height: !isMobile ? 390 : null,
		borderRadius: 10,
		backgroundColor: "#FFFFFF",
		margin: 10,
		marginTop: 10,
		paddingVertical: 10,
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	dropDownModal: {
		backgroundColor: "#fff",
		width: 167,
		height: 183,
		borderRadius: 10,
		marginTop: "10%",
		marginLeft: "8%",
		paddingHorizontal: 16,
		paddingVertical: 20,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
		position: "absolute",
		top: -20,
		left: 10,
	},
	dropDownModalContainer: {
		flex: 1,
		flexDirection: "column",
	},
	monthDropTxt: { fontSize: 15, lineHeight: 20, fontWeight: "400" },
	dropDown: {
		height: 187,
		width: 167,
		backgroundColor: "#fff",
		zIndex: 999,
		position: "absolute",
		top: 35,
		left: 20,
		borderRadius: 8,
		paddingVertical: 20,
		paddingHorizontal: 16,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 5,
	},
	inputField: {
		height: 40,
		width: "100%",
		backgroundColor: "#F9F9F9",
		borderRadius: 8,
		marginTop: 5,
		fontSize: 16,
		paddingHorizontal: 10,
	},
	calender: {
		flexDirection: "row",
		justifyContent: "space-between",
		position: "absolute",
		bottom: 8,
		left: 15,
		right: 15,
	},
	cancelBtnView: {
		borderRadius: 4,
		borderWidth: 1,
		width: 150,
		height: 40,
		borderColor: "#000",
		justifyContent: "center",
		alignItems: "center",
	},
	cancelCalenderBtn: {
		fontSize: 17,
		fontWeight: "600",
		lineHeight: 20,
		textAlign: "center",
	},
	applyBtnView: {
		backgroundColor: "#FB6758",
		width: 150,
		height: 40,
		borderRadius: 4,
		borderColor: "#000",
		justifyContent: "center",
		alignItems: "center",
	},
	applyTxt: {
		fontSize: 17,
		fontWeight: "600",
		lineHeight: 20,
		textAlign: "center",
		color: "#fff",
	},
	sendRewardContainer: {
		flex: 1,
		marginHorizontal: 16,
	},
	sendRewardHead: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 12,
	},
	sendRewardTitle: {
		fontSize: 17,
		lineHeight: 40,
		color: "#141414",
		fontFamily: "WorkSans_500Medium",
	},
	sendRewardBtns: {
		height: 70,
		flexDirection: "row",
		justifyContent: "space-between",
	},
	sendRewardMainTitle: {
		fontSize: 18,
		lineHeight: 20,
		fontFamily: "WorkSans_400Regular",
		letterSpacing: -0.8,
		color: "#000000",
		paddingLeft: 3,
	},
	sendRewardDescriptionView: {
		flex: 1,
		paddingVertical: 0,
	},
});

export default SendManualRewardModal;
