import React from 'react'
import Svg, { Path, Circle } from 'react-native-svg'

export default function ReframeRoundIcon(props) {
  return (
    <Svg width="41" height="41" viewBox="0 0 41 41" fill="none" {...props}>
      <Circle cx="20.5" cy="20.5" r="20.5" fill="white" />
      <Path
        fill="#F05343"
        d="M13.1784 14.3843V28.1392H26.9267V14.3843H13.1784ZM20.0526 25.665C19.7156 25.665 19.3861 25.565 19.1059 25.3778C18.8257 25.1906 18.6074 24.9245 18.4784 24.6132C18.3494 24.3018 18.3157 23.9592 18.3814 23.6287C18.4472 23.2982 18.6095 22.9946 18.8477 22.7563C19.086 22.518 19.3896 22.3557 19.7201 22.29C20.0507 22.2243 20.3933 22.258 20.7046 22.387C21.0159 22.5159 21.282 22.7343 21.4693 23.0145C21.6565 23.2947 21.7564 23.6241 21.7564 23.9611C21.7573 24.1851 21.7138 24.4071 21.6285 24.6142C21.5431 24.8213 21.4177 25.0095 21.2593 25.1679C21.1009 25.3262 20.9127 25.4517 20.7056 25.537C20.4985 25.6224 20.2765 25.6658 20.0526 25.665ZM20.0526 20.2596C19.7156 20.2596 19.3861 20.1597 19.1059 19.9725C18.8257 19.7853 18.6074 19.5192 18.4784 19.2078C18.3494 18.8965 18.3157 18.5539 18.3814 18.2234C18.4472 17.8929 18.6095 17.5893 18.8477 17.351C19.086 17.1127 19.3896 16.9504 19.7201 16.8847C20.0507 16.8189 20.3933 16.8527 20.7046 16.9816C21.0159 17.1106 21.282 17.329 21.4693 17.6092C21.6565 17.8894 21.7564 18.2188 21.7564 18.5558C21.7573 18.7798 21.7138 19.0017 21.6285 19.2088C21.5431 19.416 21.4177 19.6041 21.2593 19.7625C21.1009 19.9209 20.9127 20.0464 20.7056 20.1317C20.4985 20.217 20.2765 20.2605 20.0526 20.2596Z"
      />
      <Path
        fill="black"
        d="M8.75891 9.96472V32.5588H31.353V9.96472H8.75891ZM28.6438 29.8496H11.4616V12.6674H28.6438V29.8496Z"
      />
    </Svg>
  )
}
