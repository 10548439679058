import styled from 'styled-components/native'
import { Animated } from 'react-native'

export const Container = styled(Animated.ScrollView)`
  height: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${({ bg, theme }) => bg ? bg : theme.basics.brand};
	position: relative;
`

export const ContainerLogo = styled.View`
	top: -20px;
	left: 25px;
	align-items: center;
	justify-content: center;
	position: absolute;
`

export const ConstainerToogleDrawer = styled(Animated.View)`
	right: 5px;
	top: -10px;
	align-items: center;
	justify-content: center;
	position: absolute;
`

export const ToggleButton = styled.TouchableOpacity`
	height: 15px;
	width: 15px;
	align-items: center;
	justify-content: center;
`


export const Header = styled.View`
  display: flex;
  flex-direction: row;
  padding: 0 ${({ open }) => (open ? '30' : '0')}px;
`

export const CloseIcon = styled(Animated.View)`
  top: 10px;
  right: 5px;
  position: absolute;
`

export const MainMenuWrapper = styled.View`
  flex-grow: 1;
  padding-top: 30px;
`
