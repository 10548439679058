import request from '@/helpers/request'

export async function storeToken({ token }) {


    return await request('expo/pushapi/store',
        {
            method: 'POST',
            body: JSON.stringify({ token: token.data })

        })
}
export async function removeToken(token) {
    return await request('expo/pushapi/delete', {
        method: 'DELETE',
        qsParams: {
            token
        },
    })
}