import React, { useState, useCallback, useEffect } from "react";
import {
	View,
	Text,
	Image,
	ScrollView,
	Dimensions,
	TouchableOpacity,
	TouchableWithoutFeedback,
	SafeAreaView,
	StyleSheet,
	StatusBar,
	TextInput,
} from "react-native";

import { isMobile } from "@/styles";
import Modal from "react-native-modal";
import ApiHandler from "@/api/ApiHandler";
import { Feather } from "@expo/vector-icons";
import { Ionicons } from "@expo/vector-icons";
import { FontAwesome } from "@expo/vector-icons";
import { MaterialIcons } from "@expo/vector-icons";
import { MaterialCommunityIcons } from "@expo/vector-icons";

const ShareLinkModal = ({ shareModalVisible, toggleShareModal }) => {
	const [showTimeline, setShowTimeline] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [showLink, setShowLink] = useState(false);
	const [share, setShare] = useState([]);

	// const getRewardsSharing = async () => {
	// 	try {
	// 		let queryParams = "";
	// 		queryParams = `medium=${"email"}`;
	// 		const response = await new ApiHandler().getRewardSharing(queryParams);
	// 		// console.log("******getReward   Sharing******::::", response);
	// 		if (response?.data) {
	// 			setShare(response?.data?.data);
	// 		}
	// 	} catch (error) {
	// 		console.log("ERROR", error);
	// 	} finally {
	// 	}
	// };

	// useEffect(() => {
	// 	getRewardsSharing();
	// }, []);

	return (
		<View style={{ flex: 1 }}>
			<Modal
				isVisible={shareModalVisible}
				animationIn="slideInUp"
				animationOut="slideOutDown"
			// style={styles.shareModal}
			>
				<View style={styles.shareModal}>
					<View style={styles.headView}>
						<Text style={styles.milestoneModalTitle}>Share</Text>
						<TouchableOpacity
							onPress={() => {
								toggleShareModal();
							}}
							style={{ marginLeft: 0 }}
						>
							<Ionicons name="close-outline" size={25} color={"grey"} />
						</TouchableOpacity>
					</View>
					<TouchableOpacity
						style={styles.mainView}
						onPress={() => setShowEmail(!showEmail)}
					>
						<MaterialIcons name="email" size={15} color="black" />
						<Text style={styles.text}>Via Email</Text>
					</TouchableOpacity>
					{showEmail ? (
						<TextInput
							style={styles.input}
							placeholder={"Enter Email"}
							placeholderTextColor={"grey"}
						/>
					) : null}
					<TouchableOpacity
						style={styles.mainView}
						onPress={() => setShowTimeline(!showTimeline)}
					>
						<MaterialCommunityIcons
							name="timeline-clock-outline"
							size={15}
							color="black"
						/>
						<Text style={styles.text}>Timeline</Text>
					</TouchableOpacity>
					{showTimeline ? (
						<TextInput
							style={styles.input}
							placeholder={"Enter Caption"}
							placeholderTextColor={"grey"}
						/>
					) : null}
					<TouchableOpacity
						style={styles.mainView}
						onPress={() => setShowLink(!showLink)}
					>
						<FontAwesome name="external-link" size={15} color="black" />
						<Text style={styles.text}>External Link</Text>
					</TouchableOpacity>
					{showLink ? (
						<View style={styles.copyView}>
							<TextInput
								style={styles.input}
								placeholder={"Enter Caption"}
								placeholderTextColor={"grey"}
							/>
							<Feather
								name="copy"
								size={18}
								color="orange"
								style={{ marginLeft: -30, marginTop: 10 }}
							/>
						</View>
					) : null}
					<View
						style={styles.buttonView}
						onPress={() => {
							toggleShareModal();
						}}
					>
						<TouchableOpacity
							onPress={() => {
								toggleShareModal();
							}}
							style={styles.cancelButton}
						>
							<Text style={styles.buttonText}>Cancel</Text>
						</TouchableOpacity>
						<TouchableOpacity style={styles.shareButton}>
							<Text style={[styles.buttonText, { color: "#fff" }]}>Share</Text>
						</TouchableOpacity>
					</View>
				</View>
			</Modal>
		</View>
	);
};

export default ShareLinkModal;

const styles = StyleSheet.create({
	shareModal: {
		backgroundColor: "#fff",
		borderRadius: 20,
		alignSelf: "center",
		paddingHorizontal: 20,
		margin: 0,
		width: 300,
		paddingBottom: 20,
	},
	copyView: {
		flexDirection: "row",
		width: "100%",
		alignItems: "center",
	},
	input: {
		height: 30,
		width: "100%",
		alignSelf: "center",
		marginTop: 10,
		paddingHorizontal: 15,
		paddingVertical: 5,
		backgroundColor: "#E1E9F0",
		fontSize: 8,
		color: "grey",
		alignItems: "center",
	},
	cancelButton: {
		height: 25,
		width: 80,
		borderWidth: 1,
		borderColor: "black",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 2,
	},
	shareButton: {
		height: 25,
		width: 80,
		alignItems: "center",
		justifyContent: "center",
		marginLeft: 10,
		backgroundColor: "#FB6758",
		borderRadius: 2,
	},
	mainView: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 12,
	},
	buttonView: {
		flexDirection: "row",
		alignSelf: !isMobile ? "flex-end" : "center",
		marginTop: 30,
	},
	text: {
		fontSize: 10,
		marginLeft: 10,
	},
	headView: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		width: "100%",
		alignSelf: "center",
	},
	milestoneModalTitle: {
		fontSize: 20,
		lineHeight: 40,
		fontWeight: "500",
		letterSpacing: -0.8,
		marginTop: 2,
	},
});
