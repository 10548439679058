/* eslint-disable */
import React, { useContext } from "react";
import { Box, Text, Tooltip } from "@twilio-paste/core";
import { useTheme } from "@twilio-paste/theme";
import { Menu, MenuButton, useMenuState } from "@twilio-paste/menu";
import AddReaction from "../icons/AddReaction";
import { useSelector } from "react-redux";
import { getMember } from "@/redux/selectors/auth";
import { Dimensions } from "react-native";
import ChatContext from "@/contexts/ChatContext";

const isWideScreen = () => width > 1300;

const { width } = Dimensions.get('window');

const Reactions = {
  HEART: "heart",
  THUMBS_UP: "thumbs_up",
  LAUGH: "laugh",
  SAD: "sad",
  POUTING: "pouting",
  THUMBS_DOWN: "thumbs_down",
}



const reactionsExist = (reactions) =>
  reactions &&
  (reactions[Reactions.HEART]?.length ||
    reactions[Reactions.THUMBS_DOWN]?.length ||
    reactions[Reactions.THUMBS_UP]?.length ||
    reactions[Reactions.POUTING]?.length ||
    reactions[Reactions.SAD]?.length ||
    reactions[Reactions.LAUGH]?.length);

export const ReactionsBox = ({
  reactions = {},
  onReactionsChanged,
}) => {
  const menu = useMenuState({
    placement: "top-start",
  });
  const user = useSelector(getMember).id;
  const theme = useTheme();
  const { getParticipant } = useContext(ChatContext);

  const getUserInformation = (users) => {
    if (users.length == 1) {
      if (users[0] == user) {
        return "You"
      } else {
        return getParticipant(users[0])?.first_name
      }
    } else if (users.length == 2) {
      if (users.includes(user)) {
        const participant = getParticipant(users[0])?.first_name == getParticipant(user)?.first_name ? getParticipant(users[1])?.first_name : getParticipant(users[0])?.first_name;
        return `You and ${participant}`
      } else {
        return `${getParticipant(users[0])?.first_name} and ${getParticipant(users[1])?.first_name}`
      }
    }
    else {
      if (users.includes(user)) {
        return `You and ${users.length - 1} others`
      } else {
        return `${getParticipant(users[0])?.first_name}, ${getParticipant(users[1])?.first_name} and ${users.length - 2} others`
      }
    }
  }


  const onUpdateReaction = (reaction) => {
    const reactionUsers = reactions?.[reaction] ?? [];

    onReactionsChanged({
      ...reactions,
      [reaction]: reactions?.[reaction]?.includes(user)
        ? reactionUsers.filter((participant) => participant != user)
        : [...reactionUsers, user],
    });
  };

  const allReactionsByCurrentUser = () =>
    Object.keys(reactions)
      .filter((reaction) => reactions[reaction]?.length)
      .every((reaction) => reactions[reaction]?.includes(user));

  const ReactionItem = ({
    emoji,
    count,
    reactionId,
  }) => (
    <button
      type="button"
      onClick={() => {
        menu.hide();
        onUpdateReaction(reactionId);
      }}
      style={{
        border: 0,
        padding: "2px 10px",
        margin: "0 2px",
        fontSize: 20,
        lineHeight: "28px",
        cursor: "pointer",
        borderRadius: 8,
        backgroundColor:
          reactions?.[reactionId]?.includes(user) && !count
            ? "rgb(240, 83, 67)"
            : "transparent",
      }}
    >
      {emoji}{" "}
      <Text
        as="span"
        color={
          reactions?.[reactionId]?.includes(user)
            ? "rgb(248, 83, 67)"
            : "colorText"
        }
      >
        {" "}
        {count}
      </Text>
    </button>
  );

  const isReactionsByCurrentUser = allReactionsByCurrentUser();

  const reactionsBorderColor = isReactionsByCurrentUser
    ? theme.textColors.colorTextLink
    : theme.textColors.colorTextIconInverse;

  const reactionsBackgroundColor = isReactionsByCurrentUser
    ? theme.backgroundColors.colorBackgroundPrimaryLighter
    : theme.backgroundColors.colorBackgroundBody;

  return (
    <Box
      style={{
        display: "flex",
      }}
    >
      {reactionsExist(reactions) ? (
        <Box
          style={{
            border: "1px solid " + reactionsBorderColor,
            borderRadius: 4,
            margin: "4px 4px 0 0",
            backgroundColor: reactionsBackgroundColor,
            display: 'flex'
          }}
        >
          {reactions?.[Reactions.HEART]?.length ? (
            <Tooltip text={getUserInformation(reactions?.[Reactions.HEART])}>
              <div>
                <ReactionItem
                  emoji="&#129505;"
                  reactionId={Reactions.HEART}
                  count={reactions?.[Reactions.HEART]?.length}
                />
              </div>
            </Tooltip>
          ) : null}
          {reactions?.[Reactions.THUMBS_UP]?.length ? (
            <Tooltip text={getUserInformation(reactions?.[Reactions.THUMBS_UP])}>
              <div>
                <ReactionItem
                  emoji="&#128077;"
                  reactionId={Reactions.THUMBS_UP}
                  count={reactions?.[Reactions.THUMBS_UP]?.length}
                />
              </div>
            </Tooltip>
          ) : null}
          {reactions?.[Reactions.LAUGH]?.length ? (
            <Tooltip text={getUserInformation(reactions?.[Reactions.LAUGH])}>
              <div>
                <ReactionItem
                  emoji="&#128514;"
                  reactionId={Reactions.LAUGH}
                  count={reactions?.[Reactions.LAUGH]?.length}
                />
              </div>
            </Tooltip>
          ) : null}
          {reactions?.[Reactions.SAD]?.length ? (
            <Tooltip text={getUserInformation(reactions?.[Reactions.SAD])}>
              <div>
                <ReactionItem
                  emoji="&#128542;"
                  reactionId={Reactions.SAD}
                  count={reactions?.[Reactions.SAD]?.length}
                />
              </div>
            </Tooltip>
          ) : null}
          {reactions?.[Reactions.POUTING]?.length ? (
            <Tooltip text={getUserInformation(reactions?.[Reactions.POUTING])}>
              <div>
                <ReactionItem
                  emoji="&#128545;"
                  reactionId={Reactions.POUTING}
                  count={reactions?.[Reactions.POUTING]?.length}
                />
              </div>
            </Tooltip>
          ) : null}
          {reactions?.[Reactions.THUMBS_DOWN]?.length ? (
            <Tooltip text={getUserInformation(reactions?.[Reactions.THUMBS_DOWN])}>
              <div>
                <ReactionItem
                  emoji="&#128078;"
                  reactionId={Reactions.THUMBS_DOWN}
                  count={reactions?.[Reactions.THUMBS_DOWN]?.length}
                />
              </div>
            </Tooltip>
          ) : null}
        </Box>
      ) : null}
      <Box
        style={{
          padding: "6px 10px",
          border: "1px solid #8891AA",
          borderRadius: 4,
          maxWidth: 42,
          maxHeight: 42,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "4px",
        }}
      >
        <MenuButton
          {...menu}
          variant="link"
          size="reset"
          style={{
            padding: 4,
          }}
        >
          <AddReaction />
        </MenuButton>
        <Menu
          {...menu}
          placement="top-start"
          aria-label="MessageReactions"
          style={{
            padding: "8px 4px",
            zIndex: 99,
            minWidth: isWideScreen() ? width / 5 : undefined
          }}
        >
          <div style={{ display: "flex", justifyContent: 'space-evenly' }}>
            <ReactionItem emoji="&#129505;" reactionId={Reactions.HEART} />
            <ReactionItem emoji="&#128077;" reactionId={Reactions.THUMBS_UP} />
            <ReactionItem emoji="&#128514;" reactionId={Reactions.LAUGH} />
            <ReactionItem emoji="&#128542;" reactionId={Reactions.SAD} />
            <ReactionItem emoji="&#128545;" reactionId={Reactions.POUTING} />
            <ReactionItem
              emoji="&#128078;"
              reactionId={Reactions.THUMBS_DOWN}
            />
          </div>
        </Menu>
      </Box>
    </Box>
  );
};

export default ReactionsBox;
