import * as ImagePicker from 'expo-image-picker'
import { Alert, Platform, PermissionsAndroid } from "react-native";
import * as DocumentPicker from 'expo-document-picker';
import * as FileSystem from 'expo-file-system';
import base64 from 'base64-js'

// const encode = (uri) => {
//   if (Platform.OS === 'android') return encodeURI(`file://${uri}`)
//   else return uri
// }

// const  stringToUint8Array = (str) => {
//   const length = str.length
//   const array = new Uint8Array(new ArrayBuffer(length))
//   for(let i = 0; i < length; i++) array[i] = str.charCodeAt(i)
//   return array
// }

// const fileToBase64 = async (uri) => {
//   try {
//       const content = await FileSystem.readAsStringAsync(uri)
//       return base64.fromByteArray(stringToUint8Array(content))
//   } catch(e) {
//       console.warn('fileToBase64()', e.message)
//       return ''
//   }
// }

export const openDocument = async (options) => {

  const result = await DocumentPicker.getDocumentAsync({});
  //   const result = await DocumentPicker.getDocumentAsync({
  //     readContent: true,
  //   type:'*/*',
  //   copyToCacheDirectory: false,
  // });
  console.log('doc', result)

  let name = result.name.split('.')[0];
  // console.log('encodeURI',encodeURI(`file://${result.uri}`))
  //  if (Platform.OS === 'android') return encodeURI(`file://${result.uri}`)
  //  else return result.uri
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    let url = encodeURI(`file://${result.uri}`);
    let options = { encoding: FileSystem.EncodingType.Base64 };
    let base64 = await FileSystem.readAsStringAsync(url, options);
    const data = { fileName: name, uri: base64 }
    return data
    //return base64;
    // let fileExtension = result.uri.substr(result.uri.lastIndexOf('.') + 1);
    // console.log(base64)
    // return "data:image/jpeg;base64," + base64;
  }
  const data = { fileName: name, uri: result.uri }
  return data
  // return result.uri

}

