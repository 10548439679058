import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function SharedFolderRight(props) {
  return (
    <Svg width="24" height="16" viewBox="0 0 24 16" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z"
        fill="white" stroke="#000000" stroke-width="2"/>
    </Svg>
  )
}

SharedFolderRight.propTypes = Svg.propTypes
