import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@/components/TextField'
import { TextInput } from 'react-native-paper'

const PasswordTextField = (props) => {
  const [view, setView] = React.useState(false)

  const handleVisible = () => {
    setView(!view)
  }

  return (
    <TextField
      label={props.label}
      secureTextEntry={!view}
      // right={
      //   <TextInput.Icon
      //     onPress={handleVisible}
      //     name={view ? 'eye-off' : 'eye'}
      //   />
      // }
      {...props}
    />
  )
}

PasswordTextField.defaultProps = {
  label: 'Password',
}

PasswordTextField.propTypes = {
  label: PropTypes.string,
}

export default PasswordTextField
