import React, { useState, useEffect } from 'react'
import {
    Text,
    View,
    Button,
    Platform,
    FlatList
} from 'react-native'
import CalendarPicker from 'react-native-calendar-picker'

import ApiHandler from '@/api/ApiHandler'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ContentBlockV1 from '@/components/ContentBlockV1'
import ModalV1 from '@/components/ModalV1'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import RadioInput from '@/components/Inputs/Radio'
import DefaultButton from '@/components/Buttons/Default'
import LineChartRender from '@/pages/Analytics/Components/Charts/LineChartRender'
import { Icon } from '@/components'

import styles from './styles.js';

const CategoryView = ({
    title,
    apiHandlerFunction,
    items,
    filters
}) => {
    const [output, outputSet] = useState([]);

    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [datePickerModalVisible, setDatePickerModalVisible] = useState(false);
    const [datePickerContainerWidth, setDatePickerContainerWidth] = useState(0);

    const [loading, setLoading] = useState(false);

    const [workspaceMembers, setWorkspaceMembers] = useState()
    const [workspaceTeams, setWorkspaceTeams] = useState()
    const [workspaceGroups, setWorkspaceGroups] = useState()

    const [selectedScope, setSelectedScope] = useState('company');
    const [selectedScopeID, setSelectedScopeID] = useState();
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();

    useEffect(() => {
        getAnalytics()
        getWorkspaceEntities()
    }, [])

    const datePickerUpdated = (date, type) => {
        if (type === 'END_DATE') {
            setSelectedEndDate(date)
        } else {
            setSelectedStartDate(date)
        }
    }

    const createValuesArrayFromBackendData = (backendData) => {
        let data = []

        if (typeof backendData === 'object') {
            for (const [index, item] of Object.entries(backendData)) {
                data.push(parseInt(item.value))
            }

            return data
        }

        if (backendData.isArray()) {
            backendData.forEach(function (item, index) {
                data.push(parseInt(item.value))
            });

            return data
        }
    }

    const createLabelsArrayFromBackendData = (backendData) => {
        let data = []

        if (typeof backendData === 'object') {
            for (const [index, item] of Object.entries(backendData)) {
                data.push(item.display_timestamp)
            }

            return data
        }

        if (backendData.isArray()) {
            backendData.forEach(function (item, index) {
                data.push(item.display_timestamp)
            });

            return data
        }
    }

    const getWorkspaceEntities = async () => {
        try {
            const response = await new ApiHandler().getEmployees()
            if (response?.data) {
                let jsonObjArray = []
                let item = {}
                for (var i = 0; i < response.data.length; i++) {
                    item = {}
                    item['label'] = response.data[i].first_name + ' ' + response.data[i].last_name
                    item['value'] = response.data[i].id
                    jsonObjArray.push(item)
                    if (response.data.length - 1 === i) {
                        setWorkspaceMembers(jsonObjArray)
                    }
                }
            }
        } catch (error) {
        }

        try {
            const response = await new ApiHandler().getTeams()
            if (response?.data) {
                let jsonObjArray = []
                let item = {}
                for (var i = 0; i < response.data.length; i++) {
                    item = {}
                    item['label'] = response.data[i].name
                    item['value'] = response.data[i].id
                    jsonObjArray.push(item)
                    if (response.data.length - 1 === i) {
                        setWorkspaceTeams(jsonObjArray)
                    }
                }
            }
        } catch (error) {
        }

        try {
            const response = await new ApiHandler().getGroups()
            if (response?.data) {
                let jsonObjArray = []
                response.data.map((item) =>
                    jsonObjArray.push({ label: item.name, value: item.id }),
                )
                setWorkspaceGroups(jsonObjArray)
            }
        } catch (error) {
            console.log('***error***', error)
        }
    }

    const getAnalytics = async () => {
        setLoading(true);

        try {
            let filters = ``;
            if (selectedScope) {
                filters += `scope=${selectedScope}`;
            }
            if (selectedScopeID) {
                filters += `&scope_id=${selectedScopeID}`;
            }
            if (selectedStartDate) {
                let startDate = selectedStartDate.utc().format()
                filters += `&selected_period_start=${startDate}`;
            }
            if (selectedEndDate) {
                let endDate = selectedEndDate.utc().format()
                filters += `&selected_period_end=${endDate}`;
            }


            const response = await apiHandlerFunction(filters)

            if (response?.data) {
                outputSet([]);

                if (items !== undefined) {
                    Object.values(items).map((item, index) => {

                        if (item.type == 'chart') {
                            if (item.type_variant == 'line') {

                                let chartDatasets = [];
                                let chartLabels = [];

                                Object.values(item.type_datasets).map((dataset, index) => {
                                    let values = createValuesArrayFromBackendData(response.data[dataset.key]?.selected_period)
                                    chartLabels = createLabelsArrayFromBackendData(response.data[dataset.key]?.selected_period)

                                    if (dataset?.dataTransformation) {
                                        let dataTransformation = dataset?.dataTransformation;
                                        if (dataTransformation == 'SecondsToMinutes') {
                                            for (var i = 0; i < values.length; i++) {
                                                if (values[i] > 0) {
                                                    values[i] = (values[i] / 60).toFixed(2)
                                                }
                                            }
                                        }
                                    }

                                    let color = '#F05343';
                                    if (dataset.color) {
                                        color = dataset.color
                                    }

                                    let subDataset = {
                                        data: values,
                                        legend: dataset.label,
                                        color: color
                                    };

                                    chartDatasets.push(subDataset);
                                });

                                outputSet(current => [
                                    ...current,
                                    (
                                        <Column
                                            flexBasis={'50%'}
                                        >
                                            <ContentBlockV1
                                                title={item.label}
                                            >
                                                <LineChartRender
                                                    width={300}
                                                    title={item.label}
                                                    labels={chartLabels}
                                                    datasets={chartDatasets}
                                                    yAxisMin={item.type_config?.yAxisMin ? item.type_config?.yAxisMin : null}
                                                    yAxisMax={item.type_config?.yAxisMax ? item.type_config?.yAxisMax : null}
                                                    yAxisPrecision={item.type_config?.yAxisPrecision ? item.type_config?.yAxisPrecision : null}
                                                />

                                                <Text style={styles.ChartTimePeriod}>
                                                    <Text>Showing </Text>

                                                    {
                                                        !selectedStartDate && !selectedEndDate ? (
                                                            <Text>{'Last 30 Days'}</Text>
                                                        ) : selectedStartDate && selectedEndDate ? (
                                                            <Text>{selectedStartDate.format('ll')} - {selectedEndDate.format('ll')}</Text>
                                                        ) : selectedStartDate ? (
                                                            <Text>{selectedStartDate.format('ll')}</Text>
                                                        ) : null
                                                    }
                                                </Text>
                                            </ContentBlockV1>
                                        </Column>
                                    )
                                ])
                            }
                        }

                        if (item.type == 'list') {
                            if (item.type_variant == 'education_likes' || item.type_variant == 'education_views') {
                                let counterLabel = '';
                                if (item.type_variant == 'education_likes') {
                                    counterLabel = 'Like(s)'
                                }
                                if (item.type_variant == 'education_views') {
                                    counterLabel = 'View(s)'
                                }

                                outputSet(current => [
                                    ...current,
                                    (
                                        <Column
                                            flexBasis={'50%'}
                                        >
                                            <ContentBlockV1
                                                title={item.label}
                                            >
                                                <FlatList
                                                    data={response.data[item.code]}
                                                    renderItem={({ item }) => (
                                                        <View
                                                            style={styles.LikesAndViewsItem}
                                                        >
                                                            <Text
                                                                style={styles.LikesAndViewsItemName}
                                                            >
                                                                {item.name}
                                                            </Text>
                                                            <Text
                                                                style={styles.LikesAndViewsItemCount}
                                                            >
                                                                {item.count} {counterLabel}
                                                            </Text>
                                                        </View>
                                                    )}
                                                    keyExtractor={item => item.name}
                                                />
                                            </ContentBlockV1>
                                        </Column>
                                    )
                                ])
                            }

                            /*
                            if (item.type_variant == 'education_views') {
                                outputSet(current => [
                                    ...current,
                                    (
                                        <Column
                                        >
                                            <ContentBlockV1
                                                title={item.label}
                                            >

                                            </ContentBlockV1>
                                        </Column>
                                    )
                                ])
                            }
                            */
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }

        setFilterModalVisible(false)
        setLoading(false);
    }

    return (
        <>
            <PageHeader
                title={title}
                icon={false}
                backEnabled={true}
                breadcrumbs={[
                    { name: 'Analytics', navigation: 'Analytics' },
                    { name: title }
                ]}
                actions={
                    [
                        {
                            title: 'Options',
                            type: 'menu',
                            permissions: [

                            ],
                            menu: [
                                {
                                    title: `Filter`,
                                    icon: 'searchIcon',
                                    permissions: [

                                    ],
                                    onPress: () => {
                                        setFilterModalVisible(true)
                                    }
                                },
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <ColumnsContainer>
                    {output}
                </ColumnsContainer>
            </PageContent>

            <ModalV1
                isVisible={filterModalVisible}
                setVisibility={setFilterModalVisible}
                title={'Filters'}
                subtitle={'Utilize these filters to find the exact data that you are looking for.'}
            >
                <View style={styles.FiltersSection}>
                    <Text style={styles.FiltersSectionTitle}>Time Period</Text>
                    <View style={styles.FiltersSectionBody}>
                        <View style={{
                            marginBottom: 10
                        }}>
                            {
                                !selectedStartDate && !selectedEndDate ? (
                                    <Text>{'Last 30 Days'}</Text>
                                ) : selectedStartDate && selectedEndDate ? (
                                    <Text>{selectedStartDate.format('ll')} - {selectedEndDate.format('ll')}</Text>
                                ) : selectedStartDate ? (
                                    <Text>{selectedStartDate.format('ll')}</Text>
                                ) : null
                            }
                        </View>

                        <DefaultButton
                            label={'Set'}
                            onPress={() => {
                                setDatePickerModalVisible(true)
                            }}
                        />
                    </View>
                </View>

                {
                    filters.scope ?
                        (
                            <View style={styles.FiltersSection}>
                                <Text style={styles.FiltersSectionTitle}>Scope</Text>
                                <View style={styles.FiltersSectionBody}>
                                    <RadioInput
                                        selectedValue={selectedScope}
                                        valueSelector={setSelectedScope}
                                        items={
                                            {
                                                company: {
                                                    value: 'company',
                                                    label: 'Workspace'
                                                },
                                                team: {
                                                    value: 'team',
                                                    label: 'Team'
                                                },
                                                group: {
                                                    value: 'group',
                                                    label: 'Group'
                                                },
                                                member: {
                                                    value: 'employee',
                                                    label: 'Member'
                                                },
                                            }
                                        }
                                    />
                                </View>
                            </View>
                        )
                        : null
                }

                {
                    filters.scope ?
                        selectedScope == 'employee' || selectedScope == 'team' || selectedScope == 'group' ? (
                            <View style={styles.FiltersSection}>
                                <Text style={styles.FiltersSectionTitle}>
                                    {
                                        selectedScope == 'employee' ?
                                            'Member' : selectedScope == 'team' ?
                                                'Team' : selectedScope == 'group' ?
                                                    'Group' : null
                                    }
                                </Text>
                                <View style={styles.FiltersSectionBody}>
                                    <RadioInput
                                        selectedValue={selectedScopeID}
                                        valueSelector={setSelectedScopeID}
                                        items={
                                            selectedScope == 'employee' ?
                                                workspaceMembers : selectedScope == 'team' ?
                                                    workspaceTeams : selectedScope == 'group' ?
                                                        workspaceGroups : null
                                        }
                                    />
                                </View>
                            </View>
                        ) : null
                        : null
                }

                <View style={styles.FiltersApply}>
                    <DefaultButton
                        label={'Apply Filters'}
                        isLoading={loading}
                        onPress={() => {
                            getAnalytics()
                        }}
                    />
                </View>

                <ModalV1
                    isVisible={datePickerModalVisible}
                    setVisibility={setDatePickerModalVisible}
                    title={'Select A Time Period'}
                >
                    <View
                        onLayout={({ nativeEvent }) => setDatePickerContainerWidth(nativeEvent.layout.width)}
                        style={{
                            marginBottom: 20
                        }}
                    >
                        <CalendarPicker
                            height={340}
                            width={datePickerContainerWidth}
                            //scaleFactor={datePickerContainerWidth}
                            startFromMonday={true}
                            allowRangeSelection={true}
                            allowBackwardRangeSelect={true}
                            //minDate={minDate}
                            maxDate={new Date()}
                            todayBackgroundColor="#F05343"
                            todayTextStyle={{ color: '#FFFFFF' }}
                            selectedDayColor="#F05343"
                            selectedDayTextColor="#FFFFFF"
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            scrollable={false}
                            previousComponent={(
                                <Icon
                                    icon="chevronLeft"
                                    color={'#1b1b1b'}
                                    height={17}
                                    width={16}
                                />
                            )}
                            nextComponent={(
                                <Icon
                                    icon="chevronRight"
                                    color={'#1b1b1b'}
                                    height={17}
                                    width={16}
                                />
                            )}
                            onDateChange={datePickerUpdated}
                        />
                    </View>

                    <View style={styles.FiltersApply}>
                        <DefaultButton
                            label={'Continue'}
                            isLoading={loading}
                            onPress={() => {
                                setDatePickerModalVisible(false)
                            }}
                        />
                    </View>
                </ModalV1>
            </ModalV1>
        </>
    )

}

export default CategoryView