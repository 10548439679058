import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
import { shadow1 } from '@/styles/shadows'
import { isMobile } from '@/styles'
import themes from '@/styles/themes/black'
import { Caption1, Paragraph2 } from '@/styles/fonts'
import { vw } from 'react-native-expo-viewport-units' 
import { ScreenSize, useScreenSize } from '@/styles' 
const { width } = Dimensions.get('window')
 
export const Container = styled.View`
	position: relative;
	width: ${isMobile ? width * 0.9 : !isMobile && width >= ScreenSize.Medium ? 521 : !isMobile && width <= ScreenSize.Medium && width * 0.73}px;
	margin: 10px 0;
	border-radius: 5px;
  background: ${themes.basics.background_white};
  ${shadow1}
	z-index: -1;
`

export const AuthorContainer = styled.View`
  display: flex;
  z-index:1;
  flex-direction: row;
  align-items: flex-start;
	padding: 10px 16px;
	position: relative;
	width: 100%;
`

export const TimeAgo = styled(Paragraph2)`
	color: ${({ theme }) => theme.basics[400]};
	padding: 0 0 2px;
	margin: 0px 9px 0 0;
`

export const kebabContainer = styled.View`
`

export const AuthorInfoContainer = styled.View`
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
`


export const MoreVert = styled.TouchableOpacity`
  height: 24px;
  margin-top: 4px;
  align-items: center;
  justify-content: center;
  opacity: 100;
`

export const ContentCard = styled(Paragraph2)`
  align-items: center;
  justify-content: center;
  color: ${themes.basics.black_text};
  padding: 0 16px 0;
`

export const InteractionsContainer = styled.View`
  padding: 8px 16px 0;
  flex-direction: row;
  justify-content: space-between;
	z-index: -1;

`

export const ButtonLinkGray = styled(Paragraph2)`
  color: ${themes.basics[500]};
`

export const LikeLove = styled.View`
  padding: 3px 5px 0;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-bottom: 14px;
  border-radius: 90px;
  background-color: ${themes.basics.background_button};
  padding-left: 10px;
  padding-right: 10px;
`

export const Like = styled(Caption1)`
  margin-right: 5px;
  color: ${themes.support.standard_high};
`

export const LikeCommentsContainer = styled.View`
	flex-direction: row;
	align-items: center;
	height: 40px;
	width: 100%;
	border-top-width: 1px;
	border-top-color: #D9D9D9;
	border-bottom-width: ${({ commentsCount, isCommentsVisible }) => commentsCount > 0 && isCommentsVisible && !isMobile ? 1 : 0}px;
	border-bottom-color: ${({ commentsCount, isCommentsVisible }) => commentsCount > 0 && isCommentsVisible && !isMobile ? '#D9D9D9' : 'transparent'};
	z-index: -1;

`

export const ActionButton = styled.TouchableOpacity`
`

export const ActionButtonContainer = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.basics.background_white};
`

export const SectionAction = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
`

export const ActionButtonText = styled(Paragraph2)`
	color: ${({ theme, like }) => like ? theme.info.standard : theme.basics.brand};
	align-self: flex-end;
	margin: 0 0 0 10px;
`
export const ButtonNavigation = styled.TouchableOpacity``

export const PostImageContainer = styled.View`
  width: ${({ isDesktop }) => (isDesktop ? '520px' : width - 0 + 'px')};
  align-self: center;
  align-items: center;
  margin-left: ${({ isDesktop }) => (isDesktop ? 16 : 0)}px;
  margin-right: ${({ isDesktop }) => (isDesktop ? 16 : 0)}px;
  margin-top: ${({ isDesktop }) => (isDesktop ? 20 : 12)}px;
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 20 : 30)}px;
  border-radius: 5px;
`
