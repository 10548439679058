import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function OpenedEyeIcon(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M9 0.375C5.25 0.375 2.0475 2.7075 0.75 6C2.0475 9.2925 5.25 11.625 9 11.625C12.75 11.625 15.9525 9.2925 17.25 6C15.9525 2.7075 12.75 0.375 9 0.375ZM9 9.75C6.93 9.75 5.25 8.07 5.25 6C5.25 3.93 6.93 2.25 9 2.25C11.07 2.25 12.75 3.93 12.75 6C12.75 8.07 11.07 9.75 9 9.75ZM9 3.75C7.755 3.75 6.75 4.755 6.75 6C6.75 7.245 7.755 8.25 9 8.25C10.245 8.25 11.25 7.245 11.25 6C11.25 4.755 10.245 3.75 9 3.75Z" />
    </Svg>
  )
}

OpenedEyeIcon.propTypes = SvgProps

export default OpenedEyeIcon
