import React, { } from 'react'
import PropTypes from 'prop-types'
import AppPostComponent from './AppPostComponent'
import ArticleComponent from './ArticleComponent'
import AssessmentQuestionComponent from './AssessmentQuestionComponent'
import { PostInterface } from '@/interfaces/post'
import CourseComponent from './CourseComponent'
import EventComponent from './EventComponent'

const FeedPostCard = ({
  post,
  idPresent,
  postType,
  onCloseEdit,
  onCloseDelete,
  onClosePostComment,
  handleDeleteComment,
  handleReleateLaterArticle,
}) => {
  if (post?.Type == 'App\\Article') {
    return (
      <>
        <ArticleComponent
          post={post.Data}
          idPresent={idPresent}
          postType={postType}
          onCloseEdit={onCloseEdit}
          onCloseDelete={onCloseDelete}
          onClosePostComment={onClosePostComment}
          handleReleateLaterArticle={handleReleateLaterArticle}
          handleDeleteComment={handleDeleteComment} />
      </>
    )
  }

  if (post?.Type == 'App\\Post') {
    return (
      <>
        <AppPostComponent post={post.Data}
          idPresent={idPresent}
          postType={postType}
          feedType={post?.Type}
          onCloseEdit={onCloseEdit}
          onCloseDelete={onCloseDelete}
          onClosePostComment={onClosePostComment}
          handleDeleteComment={handleDeleteComment}
        />
      </>
    )
  }

  if (post?.Type == 'App\\AssessmentQuestion') {
    return (
      <>
        <AssessmentQuestionComponent
          post={post.Data}
          post_id={post.ID}
          idPresent={idPresent}
          postType={postType} />
      </>
    )
  }

  if (post?.Type == 'App\\Course') {
    return (
      <>
        <CourseComponent
          post={post.Data}
          idPresent={idPresent}
          postType={postType}
          onCloseEdit={onCloseEdit}
          onCloseDelete={onCloseDelete}
          onClosePostComment={onClosePostComment}
          handleReleateLaterArticle={handleReleateLaterArticle}
          handleDeleteComment={handleDeleteComment}
        />
      </>
    )
  }

  if (post?.Type == 'App\\Event') {
    return (
      <>
        <EventComponent
          post={post.Data}
          idPresent={idPresent}
          postType={postType}
          onCloseEdit={onCloseEdit}
          onCloseDelete={onCloseDelete}
          onClosePostComment={onClosePostComment}
          handleReleateLaterArticle={handleReleateLaterArticle}
          handleDeleteComment={handleDeleteComment}
        />
      </>
    )
  }
}


FeedPostCard.propTypes = {
  post: PostInterface,
  postType: PropTypes.string,
  idPresent: PropTypes.number,
  onCloseEdit: PropTypes.func,
  onCloseDelete: PropTypes.func,
  onClosePostComment: PropTypes.func,
  handleDeleteComment: PropTypes.func,
  handleReleateLaterArticle: PropTypes.func,
  isCreatePostVisible: PropTypes.bool,
  wrapperData: PropTypes.bool,
}

export default FeedPostCard