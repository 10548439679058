import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function GroupIcon(props) {
  return (
    <Svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        fill={props.color}
        d="M9 5.5625C10.2225 5.5625 11.3025 5.855 12.18 6.2375C12.99 6.5975 13.5 7.4075 13.5 8.285V9.5H4.5V8.2925C4.5 7.4075 5.01 6.5975 5.82 6.245C6.6975 5.855 7.7775 5.5625 9 5.5625ZM3 5.75C3.825 5.75 4.5 5.075 4.5 4.25C4.5 3.425 3.825 2.75 3 2.75C2.175 2.75 1.5 3.425 1.5 4.25C1.5 5.075 2.175 5.75 3 5.75ZM3.8475 6.575C3.57 6.53 3.2925 6.5 3 6.5C2.2575 6.5 1.5525 6.6575 0.915 6.935C0.36 7.175 0 7.715 0 8.3225V9.5H3.375V8.2925C3.375 7.67 3.5475 7.085 3.8475 6.575ZM15 5.75C15.825 5.75 16.5 5.075 16.5 4.25C16.5 3.425 15.825 2.75 15 2.75C14.175 2.75 13.5 3.425 13.5 4.25C13.5 5.075 14.175 5.75 15 5.75ZM18 8.3225C18 7.715 17.64 7.175 17.085 6.935C16.4475 6.6575 15.7425 6.5 15 6.5C14.7075 6.5 14.43 6.53 14.1525 6.575C14.4525 7.085 14.625 7.67 14.625 8.2925V9.5H18V8.3225ZM9 0.5C10.245 0.5 11.25 1.505 11.25 2.75C11.25 3.995 10.245 5 9 5C7.755 5 6.75 3.995 6.75 2.75C6.75 1.505 7.755 0.5 9 0.5Z"
      />
    </Svg>
  )
}

GroupIcon.propTypes = SvgProps

export default GroupIcon
