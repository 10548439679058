import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './styles'
import { useScreenSize } from '@/styles'
import { Headline2 } from '@/styles/fonts'

const OnboardingFormWrapper = ({ title, children }) => {
  const screenSize = useScreenSize()

  return (
    <Container screenSize={screenSize}>
      <Headline2>{title}</Headline2>
      {children}
    </Container>
  )
}

OnboardingFormWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
}

export default OnboardingFormWrapper
