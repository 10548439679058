import { all, put, call, fork, takeLatest } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/roles'
import * as api from '@/redux/api/roles'


// FEED_POST
function* store(action) {
  try {
    const payload = yield call(api.store, action)
    yield put({ type: constants.STORE.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.STORE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchStore() {
  yield takeLatest(constants.STORE.ACTION, store)
}

// FEED_INDEX
function* index(action) {
  try {
    const payload = yield call(api.index, action)
    yield put({ type: constants.INDEX.SUCCESS, action, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.INDEX.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchIndex() {
  yield takeLatest(constants.INDEX.ACTION, index)
}

// DELETE_POST
function* remove(action) {
  try {
    const payload = yield call(api.remove, action)
    yield put({ type: constants.DELETE.SUCCESS, action })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.DELETE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchRemove() {
  yield takeLatest(constants.DELETE.ACTION, remove)
}

// EDIT_POST
function* update(action) {
  try {
    const payload = yield call(api.update, action)
    yield put({ type: constants.UPDATE.SUCCESS, action, payload  })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.UPDATE.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchUpdate() {
  yield takeLatest(constants.UPDATE.ACTION, update)
}

// EDIT_POST
function* dataprovider(action) {
  try {
    const payload = yield call(api.dataprovider, action)
    yield put({ type: constants.DATAPROVIDER.SUCCESS, action, payload  })
    action.next && action.next(undefined)
  } catch (e) {
    yield put({
      type: constants.DATAPROVIDER.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchDataprovider() {
  yield takeLatest(constants.DATAPROVIDER.ACTION, dataprovider)
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchStore),
    fork(watchIndex),
    fork(watchRemove),
    fork(watchUpdate),
		fork(watchDataprovider)
  ])
}
