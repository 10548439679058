import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ForgotPasswordDecoration(props) {
  return (
    <Svg width="643" height="542" viewBox="0 0 643 542" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M318.177 229.364C319.721 226.141 318.361 222.275 315.136 220.732C311.913 219.188 308.048 220.547 306.504 223.771C304.959 226.995 306.32 230.86 309.543 232.405C312.767 233.949 316.632 232.588 318.177 229.364Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M328.449 221.859L328.446 221.867C329.435 221.98 330.42 222.235 331.368 222.672C335.968 224.785 337.91 230.072 335.707 234.483C333.502 238.893 327.986 240.757 323.384 238.644L309.104 232.084L312.722 220.095L328.449 221.859Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.243 171.544L315.577 221.453L309.194 233.041L228.186 193.439L240.243 171.544Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M420.769 346.325L362.707 545.382H356.036L403.511 346.325H420.769Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M480.648 346.325L538.71 545.382H545.382L497.906 346.325H480.648Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.417 318.004C354.417 371.185 397.528 414.296 450.709 414.296C503.89 414.296 547 371.185 547 318.004C547 264.824 503.89 221.713 450.709 221.713C397.528 221.713 354.417 264.824 354.417 318.004Z"
        fill="#9E9FA2"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M402.967 343.257C402.967 349.497 404.177 355.439 406.367 360.89H493.433C495.62 355.439 496.831 349.497 496.831 343.257C496.831 317.239 475.824 296.157 449.899 296.157C423.989 296.157 402.967 317.239 402.967 343.257Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M438.571 344.707C438.571 351.856 444.369 357.654 451.518 357.654C458.667 357.654 464.465 351.856 464.465 344.707C464.465 337.557 458.667 331.76 451.518 331.76C444.369 331.76 438.571 337.557 438.571 344.707Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M327.375 275.385C322.759 266.707 326.052 255.93 334.731 251.313C343.409 246.697 354.187 249.99 358.802 258.667C363.419 267.348 360.125 278.124 351.447 282.74C342.768 287.357 331.991 284.062 327.375 275.385Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M427.243 243.481L352.719 283.21L333.379 254.014L398.885 200.675L427.243 243.481Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.058 232.252C399.772 244.876 415.449 249.665 428.072 242.951C440.696 236.237 445.484 220.561 438.771 207.938C432.055 195.315 416.381 190.524 403.758 197.238C391.134 203.952 386.344 219.629 393.058 232.252Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M470.938 242.231L540.518 291.302L563.183 264.064L504.17 202.293L470.938 242.231Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M508.406 234.836C500.265 246.59 484.136 249.521 472.38 241.38C460.626 233.238 457.695 217.11 465.836 205.354C473.978 193.598 490.106 190.668 501.862 198.81C513.618 206.951 516.548 223.08 508.406 234.836Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M462.846 360.891C462.846 370.721 470.815 378.692 480.646 378.692C490.478 378.692 498.45 370.721 498.45 360.891C498.45 351.059 490.478 343.089 480.646 343.089C470.815 343.089 462.846 351.059 462.846 360.891Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M422.388 343.089C412.557 343.089 404.586 351.059 404.586 360.891C404.586 370.72 412.557 378.692 422.388 378.692C432.22 378.692 440.189 370.72 440.189 360.891C440.189 351.059 432.22 343.089 422.388 343.089Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M398.135 213.531L404.542 344.707H500.111L506.519 213.531C507.033 203.012 498.679 194.201 488.193 194.201H416.461C405.975 194.201 397.621 203.012 398.135 213.531Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M402.967 343.089L403.83 360.891L403.872 361.763C404.332 371.244 412.053 378.692 421.422 378.692H478.378C487.747 378.692 495.468 371.244 495.928 361.763L495.969 360.891L496.831 343.089H402.967Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M402.967 531.624C402.967 537.434 407.678 542.145 413.487 542.145C419.297 542.145 424.006 537.434 424.006 531.624C424.006 525.814 419.297 521.106 413.487 521.106C407.678 521.106 402.967 525.814 402.967 531.624Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.586 364.126C404.586 372.172 411.107 378.692 419.151 378.692C427.196 378.692 433.716 372.172 433.716 364.126C433.716 356.081 427.196 349.562 419.151 349.562C411.107 349.562 404.586 356.081 404.586 364.126Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M425.844 530.817L433.716 364.127H404.586L404.587 530.817H425.844Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M393.37 543.763H427.243V530.816H404.702L391.766 537.66C390.691 538.231 390.021 539.324 390.021 540.514C390.021 542.308 391.521 543.763 393.37 543.763Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.989 377.685C336.517 379.633 333.661 385.648 335.609 391.119C337.557 396.591 343.572 399.448 349.043 397.501C354.515 395.552 357.372 389.537 355.423 384.065C353.476 378.594 347.461 375.739 341.989 377.685Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M496.803 324.516C489.226 327.213 485.272 335.542 487.968 343.116C490.666 350.694 498.995 354.648 506.571 351.952C514.145 349.254 518.103 340.926 515.403 333.351C512.708 325.774 504.38 321.818 496.803 324.516Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M348.859 398.112L508.16 350.332L498.035 322.05L341.47 377.477L348.859 398.112Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M339.481 412.15L328.524 381.39L340.651 377.074L347.944 397.544L345.717 411.573C345.533 412.739 344.724 413.713 343.609 414.108C341.928 414.706 340.08 413.831 339.481 412.15Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M432.583 194.201L466.083 192.312L463.981 156.979L430.479 158.87L432.583 194.201Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.743 195.137C434.261 204.06 441.914 210.874 450.835 210.357C459.758 209.839 466.573 202.188 466.055 193.265C465.54 184.343 457.886 177.526 448.963 178.046C440.041 178.562 433.228 186.213 433.743 195.137Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M493.866 372.219L516.251 344.788L494.093 323.669L467.701 347.317L493.866 372.219Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M536.007 280.159C537.897 289.806 547.249 296.094 556.897 294.203C566.542 292.311 572.83 282.959 570.94 273.314C569.049 263.667 559.697 257.38 550.051 259.271C540.403 261.16 534.117 270.512 536.007 280.159Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.456 294.538H438.571V288.065H375.456V294.538Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M378.692 294.538H253.387L252.474 274.959L249.225 205.53H374.53L377.793 275.244L378.692 294.538Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.877 250.034C306.105 254.95 310.271 258.935 315.182 258.935C320.092 258.935 323.887 254.95 323.659 250.034C323.43 245.119 319.264 241.133 314.354 241.133C309.443 241.133 305.648 245.119 305.877 250.034Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M378.692 292.92H252.462L377.786 275.118L378.692 292.92Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M412.179 132.342L416.697 132.704L419.151 102.368L414.635 102.005C406.215 101.331 398.841 107.575 398.163 115.952C397.485 124.33 403.761 131.667 412.179 132.342Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.899 167.312L461.84 168.268C468.434 168.797 474.209 163.906 474.741 157.344L475.793 144.329L451.915 142.414L449.899 167.312Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M416.224 134.867L428.845 135.941L432.098 93.2553L420.813 92.2938C413.109 91.6402 406.361 97.7089 405.741 105.851L404.627 120.463C404.067 127.826 409.258 134.274 416.224 134.867Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.398 176.23L460.47 179.636L464.377 132.007C465.564 117.519 454.568 104.829 439.815 103.66C425.564 102.534 412.922 112.59 411.086 126.517L406.306 162.781C405.413 169.554 410.468 175.682 417.398 176.23Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M455.454 102.243L451.888 101.956L448.281 146.688L475.644 148.888L477.333 127.92C478.393 114.796 468.597 103.301 455.454 102.243Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M461.744 152.1L454.754 151.517L455.319 144.257C455.629 140.247 459.01 137.261 462.87 137.583C466.73 137.905 469.606 141.417 469.297 145.427C468.985 149.436 465.604 152.423 461.744 152.1Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M407.823 119.228L434.798 121.313C445.79 122.162 455.457 114.27 456.342 103.717C456.763 98.6964 452.863 94.2978 447.631 93.8938C442.399 93.4886 437.816 97.231 437.394 102.253C437.351 102.779 436.871 103.17 436.323 103.128L409.348 101.042L407.823 119.228Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M416.547 110.591L429.942 111.636C435.164 112.043 439.737 108.273 440.158 103.215C440.578 98.1578 436.687 93.7276 431.466 93.3194L418.07 92.2757C412.849 91.8688 408.274 95.6382 407.854 100.695C407.433 105.753 411.325 110.184 416.547 110.591Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M404.666 173.262C403.965 178.897 407.939 184 413.396 184.457L432.349 186.044C442.882 186.925 452.108 178.768 452.956 167.823L454.754 144.63L447.605 144.032C447.253 148.594 443.407 151.991 439.016 151.626L407.695 149.005L404.666 173.262Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M419.544 282.103C421.049 276.958 426.441 274.006 431.586 275.512C436.733 277.016 439.683 282.408 438.178 287.554C436.675 292.699 431.282 295.651 426.136 294.145C420.99 292.641 418.039 287.249 419.544 282.103Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M442.103 283.594C440.973 287.454 443.187 291.498 447.046 292.625C450.906 293.755 454.95 291.541 456.079 287.682C457.206 283.821 454.993 279.777 451.134 278.65C447.275 277.521 443.232 279.733 442.103 283.594Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M432.098 294.53L449.43 294.539L451.518 279.98L434.88 275.118L432.098 294.53Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M548.421 258.935L446.663 278.871L448.175 294.531H453.517L461.411 294.528L551.855 294.539L548.421 258.935Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.709 294.538L448.281 279.632L454.751 278.355L454.754 294.538H449.709Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M496.831 273.5H511.396V258.935H496.831V273.5Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.355 278.355L462.846 275.699L522.215 263.79L522.725 266.444L463.355 278.355Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M506.705 265.408L490.358 254.489L491.814 252.461L508.16 263.381L506.705 265.408Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M386.289 265.408L385.166 263.254L405.081 252.461L406.204 254.614L386.289 265.408Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M377.818 294.538L373.837 205.652L376.33 205.53L380.311 294.416L377.818 294.538Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M390.785 386.784L390.021 384.346L453.991 365.746L454.754 368.185L390.785 386.784Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M460.313 365.745L459.609 363.892L465.378 362.509L466.083 364.362L460.313 365.745Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.336 3.13637L201.039 1.61841L178.018 93.5811L196.098 97.9394C211.764 101.715 227.319 91.3698 229.549 75.6923L234.312 42.1917C236.855 24.3119 225.203 7.44197 207.336 3.13637Z"
        fill="#0F0F0F"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.074 223.492C181.304 240.244 165.478 251.578 148.726 248.808C131.974 246.037 120.64 230.211 123.411 213.459C126.181 196.708 142.008 185.373 158.759 188.145C175.511 190.915 186.846 206.741 184.074 223.492Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.692 128.871C202.234 137.688 193.905 143.654 185.087 142.195C176.271 140.737 170.305 132.407 171.764 123.59C173.223 114.773 181.552 108.807 190.368 110.267C199.186 111.725 205.151 120.053 203.692 128.871Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M245.176 188.306C242.28 194.35 235.034 196.903 228.99 194.007C222.946 191.113 220.393 183.864 223.289 177.82C226.184 171.776 233.432 169.225 239.476 172.12C245.52 175.016 248.071 182.262 245.176 188.306Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201 117L244.5 175L225.026 189.346L176.399 136.171L201 117Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.994 532.435C122.994 536.904 126.617 540.527 131.086 540.527C135.555 540.527 139.178 536.904 139.178 532.435C139.178 527.966 135.555 524.343 131.086 524.343C126.617 524.343 122.994 527.966 122.994 532.435Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.372 532.023C155.143 536.486 158.576 540.289 163.039 540.516C167.501 540.746 171.305 537.313 171.534 532.849C171.761 528.386 168.329 524.582 163.867 524.354C159.404 524.125 155.599 527.559 155.372 532.023Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.532 129.676L183.619 223.331L123.459 213.35L114.939 110.112C114.375 103.285 120.365 97.7461 127.105 98.8629L189.643 109.24C199.236 110.832 205.561 120.137 203.532 129.676Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.814 114.28C170.215 120.308 169.751 130.543 175.777 137.143C181.805 143.744 192.04 144.208 198.641 138.18C205.241 132.153 205.705 121.917 199.678 115.318C193.651 108.718 183.415 108.254 176.814 114.28Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.779 218.476H158.011H124.252L120.99 221.078C110.003 229.843 103.574 243.33 103.574 257.613V270.977C103.574 281.308 111.732 289.683 121.796 289.683H174.361C184.425 289.683 192.583 281.308 192.583 270.977V252.678C192.582 240.812 189.911 229.107 184.779 218.476Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.719 158.955C211.336 156.81 210.571 154.841 209.536 153.079L209.553 153.067L190.947 121.376L161.834 139.489L180.441 171.182L180.458 171.171C184.01 177.221 190.923 180.7 198.075 179.345C207.31 177.593 213.418 168.466 211.719 158.955Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.399 136.44L208.004 171.544L229.805 153.851L201 116.5L176.399 136.44Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.79 270.767C154.516 281.02 163.414 288.741 173.667 288.018C183.92 287.292 191.643 278.392 190.917 268.139C190.192 257.889 181.293 250.166 171.04 250.89C160.787 251.616 153.064 260.516 153.79 270.767Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.956 269.454C101.956 279.733 110.288 288.065 120.567 288.065C130.845 288.065 139.177 279.733 139.177 269.454C139.177 259.176 130.845 250.843 120.567 250.843C110.288 250.843 101.956 259.176 101.956 269.454Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.321 108.429L154.5 103L162.386 66.3521L190.965 73.3498L182.321 108.429Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.024 532.435H122.994V543.763H146.854C148.871 543.763 150.506 542.193 150.506 540.255C150.506 539.002 149.808 537.84 148.675 537.214L140.024 532.435Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.178 364.126C139.178 372.171 132.656 378.692 124.612 378.692C116.569 378.692 110.047 372.171 110.047 364.126C110.047 356.084 116.569 349.562 124.612 349.562C132.656 349.562 139.178 356.084 139.178 364.126Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.178 364.127V532.435H122.436L110.047 364.127H139.178Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.176 270.263L139.177 364.635L111 377L101.956 271.639L139.176 270.263Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.472 363.383C163.061 371.415 169.24 378.262 177.273 378.673C185.306 379.082 192.151 372.905 192.563 364.872C192.974 356.838 186.796 349.992 178.763 349.581C170.73 349.169 163.884 355.348 163.472 363.383Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M190 366.5L171.458 533.797L154.16 532.929L160.5 365.5L190 366.5Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.743 271.466L160.541 365.745L190.611 364.773L190.965 270.263L153.743 271.466Z"
        fill="#FF2424"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.482 12.6478L204.251 12.0993C190.015 8.6088 175.639 17.3005 172.143 31.5093L160.216 79.9821L194.986 88.5066C204.255 90.7801 213.615 85.1206 215.893 75.8661L224.244 41.9247C227.439 28.9433 219.486 15.834 206.482 12.6478Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.146 48.7707L171.43 50.1687L172.975 44.4788C173.827 41.3377 171.703 38.1644 168.233 37.3921C164.762 36.6212 161.256 38.5418 160.405 41.6841C159.551 44.8264 161.675 47.9997 165.146 48.7707Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M166.335 92.2456L154.311 89.316C142.749 86.4987 136.265 74.2952 140.459 63.2413L155.674 23.1401C162.095 6.2154 180.255 -3.27652 197.958 1.03621L202.293 2.09319L196.282 26.3671C194.66 32.9165 191.233 38.8904 186.384 43.6181L175.278 54.4449C165.194 64.2774 161.713 78.989 166.335 92.2456Z"
        fill="#0F0F0F"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.346 6.47339L200.809 30.5946C204.917 39.2385 212.682 45.5775 221.961 47.864L217.826 64.7337L224.294 38.347C227.264 26.2266 219.867 13.9878 207.772 11.0084L189.346 6.47339Z"
        fill="#0F0F0F"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.449 110.284C180.531 118.095 172.644 122.869 164.835 120.953C157.024 119.033 152.248 111.147 154.166 103.337C156.085 95.5285 163.972 90.7511 171.781 92.6692C179.591 94.59 184.367 102.476 182.449 110.284Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M610.115 294.539L611.422 307.644L636.194 545.382H642.482L623.89 294.539H610.115Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4587 294.539L39.1522 307.644L14.3799 545.382H8.0918L26.6845 294.539H40.4587Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.811 279.972C98.2362 279.891 95.4036 276.929 95.4838 273.354C95.5641 269.781 98.5262 266.948 102.101 267.028C105.675 267.109 108.508 270.072 108.427 273.646C108.347 277.22 105.384 280.053 101.811 279.972Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.315 130.962C125.291 134.992 135.023 131.793 139.053 123.818C143.083 115.843 139.885 106.11 131.909 102.08C123.934 98.0497 114.201 101.248 110.171 109.224C106.141 117.199 109.34 126.932 117.315 130.962Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.7307 198.996C103.357 199.176 108.848 192.832 108.997 184.827C109.146 176.821 103.895 170.183 97.2693 170.004C90.6446 169.824 85.1517 176.168 85.0031 184.176C84.8545 192.181 90.1047 198.818 96.7307 198.996Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111 112L85.5 180L111 184.5L134.5 122.5L111 112Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.778 122.279L117.993 166.689L92.2456 155.452L112.701 105.171L147.778 122.279Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87.3906 183.927L96.8244 273.5L109.675 272.942L111 183.927L87.3906 183.927Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3284 307.485H639.246V294.539H11.3284V307.485Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.6295 288.252C90.6507 287.195 90.8466 286.185 91.171 285.245L91.1622 285.243L96.3364 270.263L109.5 273L108.092 288.672C107.984 293.813 103.987 297.888 99.1649 297.773C94.3413 297.657 90.5209 293.396 90.6295 288.252Z"
        fill="#65523C"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3284 307.485H119.757V305.867H11.3284V307.485Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.468 307.485H132.704V305.867H129.468V307.485Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.491 307.485H334.997V305.867H184.491V307.485Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M341.47 307.485H425.624V305.867H341.47V307.485Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M432.098 307.485H571.275V305.867H432.098V307.485Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M584.222 307.485H640.864V305.867H584.222V307.485Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.423 166.689L118.139 165.599L128.802 145.651L131.086 146.742L120.423 166.689Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.373 181.255C190.286 181.255 187.22 180.484 184.491 178.995L185.763 176.643C188.995 178.408 192.619 178.984 196.246 178.315C200.444 177.539 204.09 175.168 206.513 171.637C208.936 168.105 209.844 163.837 209.071 159.621C208.724 157.732 208.033 155.919 207.019 154.233L206.335 153.097L206.352 153.085L196.867 137.326L199.144 135.941L209.998 153.973L209.942 154.008C210.778 155.637 211.364 157.356 211.69 159.137C212.591 164.057 211.531 169.035 208.705 173.153C205.879 177.273 201.627 180.041 196.727 180.947C195.616 181.153 194.493 181.255 193.373 181.255Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M633.515 414.296L626.299 319.013L628.792 318.813L636.009 414.096L633.515 414.296Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M635.125 430.479L634.391 420.965L636.893 420.769L637.627 430.283L635.125 430.479Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M526.857 470.938L509.778 392.244L512.118 391.639L529.198 470.33L526.857 470.938Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M530.252 490.358L529.198 486.011L531.382 485.503L532.435 489.85L530.252 490.358Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1118 524.343L14.5652 524.055L26.5836 411.059L29.1303 411.347L17.1118 524.343Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.3135 377.074L30.7485 376.795L33.0386 356.036L35.6036 356.315L33.3135 377.074Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M427.243 156.259C426.943 159.576 423.444 162.066 419.427 161.817C415.411 161.57 412.398 158.678 412.698 155.361L427.243 156.259Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.911 75.027C202.91 79.203 198.89 81.7326 194.93 80.6777C190.971 79.6227 188.573 75.3815 189.573 71.207L203.911 75.027Z"
        fill="white"
      />
    </Svg>
  )
}
