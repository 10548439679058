import React, { useEffect, useRef, useState } from 'react'
import { ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import { Container, ContainerButton, Button, styles } from './styles'

const Carousel = ({ children, nextPage }) => {
  const [startScroll, setStartScroll] = useState(true)
  const [endScroll, setEndScroll] = useState(false)
  const scrollRef = useRef(null)
  const contentWidth = useRef(null)
  const stepWidth = useRef(0)
  const stepAcc = useRef(0)
  const steps = children ? Array.from(children).length : 0

  useEffect(() => {}, [stepAcc.current])

  const handleNext = () => {
    if (stepAcc.current >= contentWidth.current) {
      stepAcc.current = contentWidth.current
      setEndScroll(true)
      return
    } else {
      setStartScroll(false)
    }
    stepAcc.current += stepWidth.current
    scrollRef.current.scrollTo({ x: stepAcc.current })
    const percentage = stepAcc.current / contentWidth.current
    if (percentage <= 1 && percentage > 0.7 && nextPage) {
      nextPage()
    }
  }

  const handleBack = () => {
    if (stepAcc.current <= 0) {
      stepAcc.current = 0
      setStartScroll(true)
      return
    }
    else {
      setEndScroll(false)
    }
    stepAcc.current -= stepWidth.current
    scrollRef.current.scrollTo({ x: stepAcc.current })
  }

  return (
    <Container>
      <ScrollView
        scrollEventThrottle={16}
        contentContainerStyle={styles.scrollContent}
        horizontal
        nestedScrollEnabled
        onScroll={e => {
          const { width } = e.nativeEvent.contentSize
          const { width: measurement } = e.nativeEvent.layoutMeasurement
          contentWidth.current = width
          if (width > measurement) {
            contentWidth.current = width - measurement
          }
          stepWidth.current = width / steps
        }}
        showsHorizontalScrollIndicator={false}
        onLayout={e => {
          const { width } = e.nativeEvent.layout
          contentWidth.current = width
          stepWidth.current = width / steps
        }}
        ref={scrollRef}
      >
        {children}
      </ScrollView>
      {!isMobile && (
        <ContainerButton>
          <Button disabled={startScroll} onPress={handleBack}>
            <Icon icon="chevronLeft" width={7} height={12} color={theme.basics.brand} />
          </Button>
          <Button disabled={endScroll} onPress={handleNext}>
            <Icon icon="chevronRight" width={7} height={12} color={theme.basics.brand} />
          </Button>
        </ContainerButton>
      )}
    </Container>
  )
}

Carousel.propTypes = {
  children: PropTypes.node,
  nextPage: PropTypes.func,
}

export default Carousel
