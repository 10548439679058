import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Comment(props) {
  return (
    <Svg width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
      <Path fill={props.color} d="M15.6371 16.3804C15.0349 16.3804 14.5462 16.8691 14.5462 17.4713V21.8182H2.18182V2.18182H14.5462V6.55709C14.5462 7.15927 15.0349 7.648 15.6371 7.648C16.2393 7.648 16.728 7.15927 16.728 6.55709V1.09091C16.728 0.488727 16.2393 0 15.6371 0H1.09091C0.488727 0 0 0.488727 0 1.09091V22.9091C0 23.5113 0.488727 24 1.09091 24H15.6371C16.2393 24 16.728 23.5113 16.728 22.9091V17.4713C16.728 16.8691 16.2393 16.3804 15.6371 16.3804Z" />
      <Path fill={props.color} d="M24.7244 11.9978C24.7207 11.8793 24.6975 11.7658 24.6582 11.6589C24.6451 11.6204 24.6291 11.5855 24.6116 11.5498C24.5709 11.4676 24.5207 11.392 24.4618 11.3236C24.4407 11.2982 24.432 11.2669 24.4087 11.2436L19.3324 6.168C18.9062 5.74182 18.216 5.74182 17.7898 6.168C17.3636 6.59418 17.3636 7.28436 17.7898 7.71054L21.0196 10.9396H9C8.39782 10.9396 7.90909 11.4284 7.90909 12.0305C7.90909 12.6327 8.39782 13.1215 9 13.1215H21.0036L17.7891 16.3353C17.3629 16.7615 17.3629 17.4516 17.7891 17.8778C18.0022 18.0909 18.2815 18.1971 18.5607 18.1971C18.84 18.1971 19.1193 18.0909 19.3324 17.8778L24.4087 12.8022C24.5098 12.7011 24.5905 12.5796 24.6458 12.4458C24.648 12.44 24.6487 12.4342 24.6509 12.4291C24.6989 12.3055 24.728 12.1716 24.728 12.0313C24.728 12.0276 24.7273 12.024 24.7273 12.0204C24.7273 12.0131 24.7251 12.0058 24.7244 11.9978Z" />
    </Svg>
  )
}

Comment.propTypes = SvgProps

export default Comment
