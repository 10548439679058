import { all, put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects'

import { constants } from '@/redux/reducers/notifications'
import * as api from '@/redux/api/notifications'
import { persistor } from '@/redux/store'

// get read notifications
function* getReadNotifList(action) {
  try {

    const payload = yield call(api.fetchReadNotifList, action)
    yield put({ type: constants.NOTIFI_LIST_READ.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.NOTIFI_LIST_READ.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}

function* watchReadList() {

  yield takeEvery(constants.NOTIFI_LIST_READ.ACTION, getReadNotifList)
}

function* getUnReadNotifList(action) {
  try {

    const payload = yield call(api.fetchUnReadNotifList, action)
    yield put({ type: constants.NOTIFI_LIST_UNREAD.SUCCESS, payload })
    action.next && action.next(undefined, payload)
  } catch (e) {
    yield put({
      type: constants.NOTIFI_LIST_UNREAD.FAILED,
      message: e.message || e,
    })
    action.next && action.next(e)
  }
}


function* watchUnReadList() {

  yield takeEvery(constants.NOTIFI_LIST_UNREAD.ACTION, getUnReadNotifList)
}





// LOGOUT
function* logout() {
  yield put({ type: 'LOGOUT' })
  yield call(persistor.purge)
}

function* watchLogout() {
  yield takeLatest(constants.AUTH_LOGOUT, logout)
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([
    fork(watchReadList),
    fork(watchUnReadList),
    fork(watchLogout),
  ])
}
