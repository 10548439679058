import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    HeaderContainer: {
        flexDirection: 'row',
        marginBottom: 15,
        alignItems: 'flex-start',
        zIndex: 1,
        marginHorizontal: 15
    },
    AuthorAvatarContainer: {
        marginRight: 15,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 40
    },
    AuthorDetailsContainer: {
        justifyContent: 'center',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 'auto'
    },
    AuthorName: {
        fontSize: 13,
        fontWeight: '500'
    },
    AuthorRole: {
        fontSize: 12
    },
    AuthorTimestamp: {
        fontSize: 11,
        marginTop: 5
    },
    MenuContainer: {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 0
    },
    Body: {

    },
    InteractionsStatsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
        alignItems: 'center',
        marginHorizontal: 15
    },
    InteractionsStatContainer: {

    },
    InteractionsStatButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    InteractionsStatButtonLabel: {
        marginLeft: 5,
        fontFamily: 'WorkSans_400Regular',
        fontSize: 13,
        fontWeight: '500',
        color: '#1B1B1B'
    },
    InteractionsActionsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 10,
        marginHorizontal: 15,

        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 10,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
    },
    InteractionsActionContainer: {

    },
    InteractionsActionButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    InteractionsActionButtonIcon: {
    },
    InteractionsActionButtonLabel: {
        marginLeft: 5,
        fontFamily: 'WorkSans_400Regular',
        fontSize: 13,
        fontWeight: '500',
        color: '#1B1B1B'
    },
    CommentsContainer: {
        marginTop: 10,
        marginHorizontal: 15,
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 10,
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
    },
});