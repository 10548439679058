import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    formContainer: {
        flex: 0.61,
    },
    reframeLogo: {
        width: 60,
        height: 60,
        marginTop: 52,
        marginLeft: 44,
    },
    form: {
        paddingLeft: 0,
    },
    title: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 40,
        lineHeight: 40,
        color: "#000000",
    },
    formTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 20,
        lineHeight: 23,
        color: "#6D6D6D",
        paddingTop: 48,
        paddingBottom: 38,
    },
    textField: {
        width: 288,
    },
    submitBtnView: {
        marginTop: 40,
        marginBottom: 64,
        width: 288,
    },
    submitBtnText: {
        fontFamily: "WorkSans_600SemiBold",
        fontSize: 17,
        lineHeight: 20,
        color: "#ffffff",
    },
    summaryDetailsContainer: {
        flex: 0.4,
        paddingBottom: 50,
        paddingLeft: 50,
    },
    summaryDetailsContent: {
        width: "100%",
        paddingHorizontal: 15,
    },
    summaryText: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 20,
        lineHeight: 24,
        color: "#33475B",
        paddingLeft: 16,
    },
    horizontalLine: {
        marginTop: 23,
        marginBottom: 23,
        width: "100%",
        borderWidth: 1,
        borderColor: "#E8E8E8",
    },
    summaryDetailsHead: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingTop: 13,
    },
    summaryDetailsTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 16,
        lineHeight: 24,
        color: "#2A3039",
    },
    summaryDetailsValue: {
        fontFamily: "WorkSans_500Medium",
        fontSize: 18,
        lineHeight: 20,
        color: "#2A3039",
    },
    softwareTools: {
        backgroundColor: "#ffffff",
    },
    softwareToolsHead: {
        flexDirection: "row",
    },
    softwareToolsHeadIcon: {
        width: 23,
        height: 23,
        marginLeft: 22,
        marginRight: 10,
    },
    softwareToolsHeadTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 18,
        lineHeight: 24,
        color: "#33475B",
    },
    softwareToolsHeadSubTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 15,
        lineHeight: 23,
        color: "#33475B",
    },
    softwareToolsTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 18,
        lineHeight: 24,
        color: "#33475B",
        paddingLeft: 28,
        paddingTop: 21,
    },
    packages: {
        marginLeft: 29,
        marginTop: 24,
        paddingBottom: 30,
    },
    packagesHead: {
        flexDirection: "row",
        alignItems: "center",
    },
    circle: {
        width: 10,
        height: 10,
        borderRadius: 10 / 2,
        backgroundColor: "#000000",
    },
    packagesTitle: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 16,
        lineHeight: 24,
        color: "#33475B",
        paddingLeft: 6,
    },
    category: {
        paddingHorizontal: 6,
        height: 16,
    },
    categorySelected: {
        backgroundColor: "#E5F5F8",
        borderRadius: 18,
    },
    categoryTxt: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 11,
        color: "#000000",
    },
    categoryTxtSelected: {
        color: "#33475B",
    },
    packagesDescription: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 16,
        color: "#000000",
        paddingTop: 33,
        paddingBottom: 24,
    },
    creditCard: {
        borderColor: "#E3E3E3",
        borderBottomWidth: 3,
        paddingVertical: 7,
        paddingHorizontal: 3,
        width: 288,
    },
});
