import { ModalBody, Box } from "@twilio-paste/core";
import AddParticipantFooter from "./addParticipantFooter";
import { ActionName } from "../../types";
import ConvoModal from "./ConvoModal";
import { FlatList, TouchableOpacity, Text, View, Image } from "react-native";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { getMember } from "@/redux/selectors/auth";
import { formatUserName } from "@/utils/format";
import { Ionicons } from "@expo/vector-icons";
import ChatContext from "@/contexts/ChatContext";
import AvatarTemplate from "@/components/Chat/components/AvatarTemplate";



const AddChatParticipantModal = (
  props
) => {
  const { workspaceMembers } = useContext(ChatContext);
  const user = useSelector(getMember);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const pressHandler = (id) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(prev => selectedUsers.filter(usr => usr !== id))
    } else {
      setSelectedUsers(prev => [...prev, id])
    }

  }

  return (
    <>
      <ConvoModal
        handleClose={() => props.handleClose()}
        isModalOpen={props.isModalOpen}
        title={props.title}
        modalBody={
          <ModalBody>
            <h3>Add Chat participant</h3>
            <Box as="form">
              <FlatList data={workspaceMembers?.filter(usr => usr.id !== user.id)} ItemSeparatorComponent={() => <View style={{ borderBottomColor: "#aaa", borderBottomWidth: 1, width: '100%' }} />} keyExtractor={item => item?.id?.toString()} renderItem={({ item, index }) => (
                <TouchableOpacity onPress={() => pressHandler(item.id)} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                  <View style={{ width: '80%', flexDirection: 'row', alignItems: 'center' }}>
                    {item?.avatar ? (
                      <Image source={{ uri: item.avatar }} style={{ marginRight: '4%', width: 40, height: 40, borderRadius: 50 }} />
                    ) : (
                      <AvatarTemplate letter={item?.first_name?.charAt(0)?.toUpperCase()} />
                    )}
                    <Text>{formatUserName(item?.first_name, item?.last_name)}</Text>
                  </View>
                  {selectedUsers.includes(item.id) && (
                    <Ionicons name="ios-checkmark" size={24} color="#aaa" />
                  )}
                </TouchableOpacity>
              )} />
            </Box>
          </ModalBody>
        }
        modalFooter={
          <AddParticipantFooter
            isSaveDisabled={!selectedUsers.length || !!props.error}
            actionName={ActionName.Save}
            onBack={() => {
              props.onBack();
            }}
            action={() => props.action(selectedUsers)}
          />
        }
      />
    </>
  );
};

export default AddChatParticipantModal;
