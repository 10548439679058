import React, { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-native'
import PropTypes from 'prop-types'
import { Icon } from '@/components'
import Item from './Item'
import theme from '@/styles/themes/black'
import { Container, Button, ButtonText, IconView, Rotate, Card } from './styles'

const CollapseButton = ({
  btnText,
  style,
  btnWidth = 205,
  btnPaddingHorizontal,
  btnAlign = 'center',
  btnTextColor = theme.basics.background_white,
  bgButtonColor = theme.basics.brand,
  iconColor = theme.basics.background_white,
  icon,
  cardTopPosition = 50,
  iconHeight = 12,
  iconWidth = 7,
  width = 'auto',
  children,
}) => {
  const [visible, setVisible] = useState(false)
  const animation = useRef(new Animated.Value(0)).current

  useEffect(() => {
    if (visible) {
      Animated.timing(animation, {
        toValue: 1,
        duration: 250,
        useNativeDriver: false,
      }).start()
      return
    }
    Animated.timing(animation, {
      toValue: 0,
      duration: 250,
      useNativeDriver: false,
    }).start(0)
  }, [visible])

  return (
    <Container width={width} style={style}>
      <Button
        btnAlign={btnAlign}
        paddingHorizontal={btnPaddingHorizontal}
        width={btnWidth}
        bgButtonColor={bgButtonColor}
        onPress={() => setVisible((prevState) => !prevState)}
        style={{ paddingLeft: btnText && 20, paddingRight: btnText && 20 }}
      >
        {btnText && (
          <ButtonText btnTextColor={btnTextColor} style={{ marginRight: 20 }}>
            {btnText}
          </ButtonText>
        )}
        {icon && (
          <Icon
            icon={icon}
            color={iconColor}
            height={iconHeight}
            width={iconWidth}
          />
        )}
        {!icon && (
          <IconView>
            <Rotate
              style={{
                transform: [
                  {
                    rotate: animation.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['0deg', '180deg'],
                    }),
                  },
                ],
              }}
            >
              <Icon
                icon="chevronDown"
                color={iconColor}
                height={7}
                width={12}
              />
            </Rotate>
          </IconView>
        )}
      </Button>
      {visible && (
        <Card
          color={btnTextColor}
          style={{ opacity: animation }}
          cardTopPosition={cardTopPosition}
        >
          {children}
        </Card>
      )}
    </Container>
  )
}

CollapseButton.Item = Item

CollapseButton.propTypes = {
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  iconColor: PropTypes.string,
  icon: PropTypes.string,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  btnTextColor: PropTypes.string,
  bgButtonColor: PropTypes.string,
  btnText: PropTypes.string,
  btnAlign: PropTypes.string,
  btnPaddingHorizontal: PropTypes.number,
  bgColo: PropTypes.string,
  width: PropTypes.number,
  btnWidth: PropTypes.number,
  cardTopPosition: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default CollapseButton
