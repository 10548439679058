import React, { useCallback, useState } from 'react'
import { ActivityIndicator } from 'react-native'
import { useDispatch } from 'react-redux'
import { AntDesign } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import {
  ImageContainer,
  SaveButton,
  SaveButtonText,
} from './styles.web'

const UploadImage = ({
  source,
  onImageRemove,
  updateFilesAction,
  isAllLoading,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleSave = useCallback(() => {
    setIsLoading(true)
    dispatch(updateFilesAction([source], (err, success) => {
      if (success) {
        setIsLoading(false)
        onImageRemove()
        return
      }
      if (err) {
        setIsLoading(false)
      }
    }))
  }, [setIsLoading, updateFilesAction, dispatch])

  return (
    <ImageContainer disabled={isAllLoading || isLoading}>
      <button onClick={onImageRemove} disabled={isAllLoading || isLoading}>
        <AntDesign name="closecircleo" size={24} color={theme.primary.standard} />
      </button>
      <div className="img-container" disabled={isAllLoading || isLoading}>
        <img src={source} />
        <div className="loading">
          <ActivityIndicator
            color={theme.primary.standard}
            size="small"
          />
        </div>
      </div>
      <SaveButton
        onClick={handleSave}
        disabled={isAllLoading || isLoading}
      >
        <SaveButtonText>Save</SaveButtonText>
      </SaveButton>
    </ImageContainer>
  )
}

UploadImage.propTypes = {
  source: PropTypes.string,
  onImageRemove: PropTypes.func,
  updateFilesAction: PropTypes.func,
  isAllLoading: PropTypes.bool,
}

export default UploadImage
