import React, { useState, useCallback, useEffect } from "react";
import {
    Text,
    View,
    StyleSheet,
    ScrollView,
    FlatList,
    TouchableOpacity,
    Pressable,
    SafeAreaView,
    KeyboardAvoidingView,
    Dimensions,
    Platform,
    useRef,
    Keyboard,
} from "react-native";
import {
    MentionInput,
    MentionSuggestionsProps,
    Suggestion,
    replaceMentionValues,
} from "react-native-controlled-mentions";
import { getMember } from "@/redux/selectors/auth";
import ApiHandler from "@/api/ApiHandler";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "@/styles";
import defaultStyle from './defaultStyle'

import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const { width, height } = Dimensions.get("window");
const screenWidth = width < height ? width : height;
const screenHeight = width < height ? height : width;

const MentionsTextEditorMobile = ({ placeholder, text, setText }) => {
    const [formattedTextStr, setFormattedTextStr] = useState("");
    const [value, setValue] = React.useState("");

    const [suggestedUsers, setUserList] = useState([]);
    const user = useSelector(getMember);
    const [isKeyboardVisible, setKeyboardVisible] = useState(false);

    const [inputHeight, setInputHeight] = useState(150);

    useEffect(() => {
        const formattedText = getUserMentionString(text);
        const formattedTextStr = "" + formattedText.toString() + "";
        setValue(formattedTextStr);
        getUserListAsync();
        const keyboardDidShowListener = Keyboard.addListener(
            "keyboardDidShow",
            () => {
                setKeyboardVisible(true); // or some other action

                //setContainerHeight('auto')
            }
        );
        const keyboardDidHideListener = Keyboard.addListener(
            "keyboardDidHide",
            () => {
                setKeyboardVisible(false); // or some other action

                //setContainerHeight('80%')
            }
        );

        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, []);

    useEffect(() => {
        if (isKeyboardVisible) setInputHeight(50);
        else setInputHeight(150);
    }, [isKeyboardVisible]);

    useEffect(() => {
        console.log("value", value);
        let replacedVal = replaceMentionValues(
            value,
            ({ id, name }) => `##[${id},'${name}']@@`
        );
        console.log("replacedVal", replacedVal);
        setText(replacedVal);
    }, [value]);

    useEffect(() => {
        console.log("text", text);
    }, [text]);

    const renderSuggestions =
        (suggestions) =>
            ({ keyword, onSuggestionPress }) => {
                if (keyword == null) {
                    return null;
                }

                return (
                    <View
                        style={{
                            maxHeight: Platform.OS == "ios" ? 150 : 100,
                            overflow: "scroll",
                            borderWidth: 1,
                            borderColor: "#D3D3D3",
                        }}
                    >
                        <ScrollView keyboardShouldPersistTaps={"handled"}>
                            {suggestions
                                // .filter(one => one.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
                                .filter((one) =>
                                    one.name
                                        .toLocaleLowerCase()
                                        .startsWith(keyword.toLocaleLowerCase())
                                )
                                .map((one) => (
                                    <Pressable
                                        key={one.id}
                                        onPress={() => onSuggestionPress(one)}
                                        style={{
                                            padding: 12,
                                            borderBottomWidth: 1,
                                            borderBottomColor: "rgba(0,0,0,0.15)",
                                        }}
                                    >
                                        <Text style={{ fontSize: 15 }}>{one.name}</Text>
                                    </Pressable>
                                ))}
                        </ScrollView>
                    </View>
                );
            };

    const renderMentionSuggestions = renderSuggestions(suggestedUsers);

    const getUserMentionString = (str) => {
        let resultString = "";
        let string = str
            .split("##")
            .map((el) => el.split("@@"))
            .reduce((acc, curr) => acc.concat(curr));
        string &&
            string.length &&
            string.map((substr) => {
                // let trimmedStr = substr.replaceAll("^\"|\"$", "");
                let trimmedStr = substr.replace(/(^"|"$)/g, "");
                //let strArray = trimmedStr.split();
                let strArray = trimmedStr
                    .split("[")
                    .map((el) => el.split("]"))
                    .reduce((acc, curr) => acc.concat(curr));
                // console.log('strArray', strArray)
                if (strArray.length >= 3) {
                    let mentionArray = strArray[1].split(",");
                    resultString +=
                        "@[" +
                        mentionArray[1].replace(/['"]+/g, "") +
                        `](${mentionArray[0]})`;
                } else {
                    resultString += substr;
                }
            });
        return resultString;
    };

    const getUserListAsync = async () => {
        try {
            const response = await new ApiHandler().getEmployees();
            if (response?.data) {
                let responseData = response?.data
                    ? response?.data
                        .filter((x) => x.first_name && x.id != user?.id)
                        .map((user) => {
                            let userData = {
                                id: user.id,
                                name: user.first_name + " " + user.last_name,
                            };
                            return userData;
                        })
                    : [];

                setUserList(responseData);
            }
        } catch (error) { }
    };

    return (
        <View style={{ flex: 1, justifyContent: "flex-end", zIndex: -1 }}>
            <MentionInput
                value={value}
                onChange={setValue}
                blurOnSubmit={true}
                autogrow
                autoFocus={
                    Platform.OS == "ios" || Platform.OS == "android" ? true : false
                }
                multiline
                scrollEnabled
                partTypes={[
                    {
                        trigger: "@",
                        renderSuggestions: renderMentionSuggestions,
                        textStyle: { backgroundColor: "#D3D3D3" },
                    },
                ]}
                placeholder={placeholder}
                //style={{ padding: 10, borderWidth: 1, borderColor: '#D3D3D3', textAlignVertical: 'top', height: inputHeight, fontFamily: 'WorkSans_400Regular' }}
                style={{
                    padding: 10,
                    textAlignVertical: "top",
                    minHeight: 50,
                    fontFamily: "WorkSans_400Regular",
                    borderRadius: 10,
                    border: "1px solid silver",
                }}
            />
        </View>
    );
};

export default MentionsTextEditorMobile;

const styles = StyleSheet.create({
    container: {
        padding: 10,
        paddingBottom: 0,
        flex: 1,
        justifyContent: "center",
    },
    input: {
        padding: 5,
        borderWidth: 1,
        borderColor: "rgba(0,0,0,0.15)",
        // height: Platform.OS === "ios" ? '80%' : '80%',
        textAlignVertical: "top",
    },
    userSuggestionContainer: {
        padding: 5,
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: "rgba(0,0,0,0.15)",
        fontSize: 15,
        marginBottom: 5,
    },
    userSuggestionItem: {
        padding: 5,
        borderBottomWidth: 1,
        borderColor: "rgba(0,0,0,0.15)",
    },
    userText: {
        fontWeight: "bold",
        color: "#000",
        backgroundColor: "#D3D3D3",
    },
});
