import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import { ButtonItem, ItemText } from './styles'

const Item = ({ onPress, icon, color = theme.basics.black_text, iconSize, text, fontSize = 15 }) => (
  <ButtonItem onPress={onPress}>
    {icon && <Icon icon={icon} color={color} height={iconSize} width={iconSize} />}
    <ItemText fontSize={fontSize} btnTextColor={color}>{text}</ItemText>
  </ButtonItem>
)

Item.propTypes = {
  onPress: PropTypes.func.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  iconSize: PropTypes.number,
  fontSize: PropTypes.number
}

export default Item
