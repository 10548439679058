import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'
import { shadow2 } from '@/styles/shadows'
import { Headline4 } from '@/styles/fonts'

export const styles = StyleSheet.create({
  flatListContent: {
    marginHorizontal: 16
  }
})

export const Container = styled.View`
	width: 517px;
	height: 596px;
	background: ${({ theme }) => theme.basics.background_white};
	${shadow2}
	border-radius: 5px;
	/* margin: 0 0 15px; */
`

export const WrapperTitle = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

export const Title = styled(Headline4)`
	color: ${({theme}) => theme.basics.brand};
`

export const Header = styled.View`
	height: 85px;
	justify-content: space-between;
	margin: 18px 16px 20px;
`

export const WrapperField = styled.View`
	position: relative;
	width: 100%;
`

export const IconView = styled.View`
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 6px;
	bottom: 6px;
`

export const CoseButton = styled.TouchableOpacity`
	height: 20px;
	width: 20px;
	align-items: center;
	justify-content: center;
`
