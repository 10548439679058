import React from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import { Text } from 'react-native-paper'
import { Video } from 'expo-av'
import {
  Container,
  Header,
  Footer,
  CreateButton,
  CreateButtonText,
  UploadedImage,
} from './styles'

const UploadArea = ({
  brandingSetting,
  onClick,
  files,
  mediaType = 0,
  topText,
  bottomText,
  buttonText,
  ...rest
}) => {
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = brandingSetting
  const textTop = topText ? topText :
    mediaType == 1
      ? 'Drag and drop your image here or'
      : mediaType == 2
      ? 'Drag and drop your video here or'
      : 'Drag and drop your image or video here or'
  const textBottom = bottomText ? bottomText :
    mediaType == 1
      ? 'Files must be up to 3 mb and in JPG and PNG'
      : mediaType == 2
      ? 'Files must be up to 3 mb and in MP4 AND MOV'
      : 'Files must be up to 3 mb and in JPG, PNG, MP4 AND MOV'
  const video = React.useRef(null)

  return (
    <Container onPress={onClick} {...rest}>
      <>
        {files.length === 0 && (
          <>
            <MaterialIcons name="cloud-upload" size={50} color="#1B1B1B" />
            <Header>
              <Text>{textTop}</Text>
            </Header>
            <CreateButton
              onPress={onClick}
              bg={color_scheme_primary_button_background}
            >
              <CreateButtonText color={color_scheme_primary_button_text}>
                {buttonText ? buttonText : 'Upload a cover'}
              </CreateButtonText>
            </CreateButton>
            <Footer>
              <Text>{textBottom}</Text>
            </Footer>
          </>
        )}
        {files?.map((file, i) => {
          if (mediaType == 2) {
            return (
              <Video
                key={i}
                ref={video}
                source={{ uri: file }}
                isLooping
                resizeMode={Video.RESIZE_MODE_CONTAIN}
                useNativeControls
                style={{ width: '100%', height: 400 }}
              />
            )
          } else {
            return <UploadedImage key={i} source={{ uri: file }} />
          }
        })}
      </>
    </Container>
  )
}

UploadArea.propTypes = {
  onClick: PropTypes.func.isRequired,
  files: PropTypes.array,
}

export default UploadArea
