import React, { useCallback, useState, useEffect } from 'react'
import { ScrollView, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { actions as employeesActions } from '@/redux/reducers/employees'
import * as selectors from '@/redux/selectors/employees'
import {
  Icon,
  TextField,
  Button,
  SimpleModal,
  AnimatedModal,
} from '@/components'
import { ActivityIndicator, ProgressBar, RadioButton } from 'react-native-paper'
import Toast from 'react-native-toast-message'
import { TextButton } from '@/pages/Community/styles/innerPages'
import theme from '@/styles/themes/black'
import { Switch } from 'react-native-paper'
import descriptions from './descriptions'
import {
  Container,
  Header,
  CloseButton,
  Title,
  RolesRow,
  ContainerRoles,
  RolesTitle,
  RoleText,
  RolesContainer,
  Description,
  DeleteButton,
  DeleteButtonText,
  ContainerDeleteConfirmation,
  CloseButtonDelete,
  TextWarning,
  ButtonsContainer,
  LeaveButton,
  LeaveButtonText,
  Label,
  ContainerActivated
} from './styles'
import data from './data'
import { isMobile } from '@/styles'


import * as brandSelectors from '@/redux/selectors/branding'

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required().min(1).max(255),
  last_name: Yup.string().required().min(1).max(255),
  internal_id: Yup.string(),
  email: Yup.string().required().min(3).max(200),
  role_txt: Yup.string(),
  city: Yup.string(),
  role_id: Yup.string(),
})

const InviteEmployee = ({
  onBack,
  isVisible,
  edit,
  editableEmployee,
  setEditableEmployee,
}) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(true)
  const [isModalMobileVisible, setModalMobileVisible] = useState(false)
  const [employeeActive, setEmployeeActive] = useState(1)
  const { width } = useWindowDimensions()
  const loading = useSelector(selectors.loading)
  const roles = data.roles


  const branding = useSelector(brandSelectors.getSettings);
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding
  const { member } = branding?.taxonomies;

  useEffect(() => {
    if (editableEmployee) {
      if (editableEmployee?.employee?.status_id === 1) {
        setIsActive(true)
        return
      }
      setIsActive(false)
    }
  }, [editableEmployee?.employee?.status_id])

  useEffect(() => {
    if (isMobile) {
      if (isModalMobileVisible) {
        setEditableEmployee(prevState => ({
          ...prevState,
          confirmation: true
        }))
      }
      if (!isModalMobileVisible && setEditableEmployee) {
        setEditableEmployee(prevState => ({
          ...prevState,
          confirmation: false
        }))
      }
    }
  }, [isModalMobileVisible, editableEmployee?.confirmation])

  useEffect(() => {
    if (employeeActive) {
      setEmployeeActive(1)
      return
    } else
      setEmployeeActive(2)
  }, [employeeActive])

  useEffect(() => {
    dispatch(employeesActions.dataprovider(() => { }))
  }, [])

  const handleSubmit = useCallback((values) => {
    if (loading) {
      return
    }
    if (edit) {
      const status_id = isActive ? 1 : 2
      dispatch(employeesActions.edit({ ...values, id: editableEmployee?.employee?.id, status_id }, onSubmit))
      return
    }
    dispatch(employeesActions.invite(values, onSubmit))
  })

  const onSubmit = (error, success) => {
    Toast.show({
      type: error ? 'error' : 'success',
      position: 'bottom',
      text1: error ? error.message : success.message,
    })
    if (success) {
      dispatch(employeesActions.index({}, (err) => { console.log('err', err) }))
      if (isMobile) {
        setModalMobileVisible(false)
      }
      onBack()
    }
  }

  const deleteEmployee = useCallback(() => {
    dispatch(employeesActions.deleteEmployee({ id: editableEmployee?.employee?.id }, onSubmit))
  }, [dispatch, editableEmployee?.employee?.id])

  const openDeleteEmployeeModal = useCallback(() => {
    if (isMobile) {
      setModalMobileVisible(true)
      return
    }
    setEditableEmployee(prevState => {
      return {
        ...prevState,
        confirmation: true
      }
    })
  }, [setEditableEmployee])

  const descriptionsText = (id) => {
    if (descriptions[id]) {
      return (
        <Description>
          {descriptions[id]}
        </Description>
      )
    }

    return null
  }

  const initialValues = edit ? {
    first_name: editableEmployee?.employee?.first_name ?? '',
    last_name: editableEmployee?.employee?.last_name ?? '',
    internal_id: editableEmployee?.employee?.internal_id ?? '',
    email: editableEmployee?.employee?.user?.email ?? '',
    role_txt: editableEmployee?.employee?.role ?? '',
    city: editableEmployee?.employee?.city ?? '',
    role_id: (editableEmployee?.employee?.roles ?? []).length > 0 ? editableEmployee?.employee?.roles[0]?.id : '',
  } : {
    first_name: '',
    last_name: '',
    internal_id: '',
    email: '',
    role_txt: '',
    city: '',
    role_id: null,
  }

  const rolesRadios = ({ values, handleChange }) => {
    return roles
      .map((role, i) => (
        <RolesContainer key={i}>
          <RolesRow>
            <RadioButton
              color="#f05343"
              key={role.id}
              value={role.id.toString()}
              status={String(values.role_id) === role.id.toString() ? 'checked' : 'unchecked'}
              onPress={() => { handleChange('role_id')(role.id.toString()) }} />
            <RoleText>{role.name}</RoleText>
          </RolesRow>
          {descriptionsText(i)}
        </RolesContainer>
      ))
  }

  if (editableEmployee?.confirmation) {
    if (isMobile) {
      return (
        <AnimatedModal
          visible={isModalMobileVisible}
          setModalVisible={setModalMobileVisible}
          height={250}
        >
          <ContainerDeleteConfirmation>
            <TextWarning>Are you sure you want to delete {member?.singular}?</TextWarning>
            <LeaveButton width={width * 0.9} onPress={deleteEmployee}>
              <LeaveButtonText>Delete</LeaveButtonText>
            </LeaveButton>
            <LeaveButton cancel onPress={() => setModalMobileVisible(false)} width={width * 0.9}>
              <LeaveButtonText cancel>Cancel</LeaveButtonText>
            </LeaveButton>
          </ContainerDeleteConfirmation>
        </AnimatedModal>
      )
    }
    return (
      <SimpleModal isVisible={isVisible}>
        <ContainerDeleteConfirmation>
          <CloseButtonDelete onPress={onBack}>
            <Icon
              icon="close"
              color={theme.basics.brand}
              height={14}
              width={14}
            />
          </CloseButtonDelete>
          <TextWarning>Are you sure you want to delete {member?.singular}?</TextWarning>
          <ButtonsContainer>
            <LeaveButton cancel onPress={onBack}>
              <LeaveButtonText cancel>Cancel</LeaveButtonText>
            </LeaveButton>
            <LeaveButton onPress={deleteEmployee}>
              <LeaveButtonText>Delete</LeaveButtonText>
            </LeaveButton>
          </ButtonsContainer>
        </ContainerDeleteConfirmation>
      </SimpleModal>
    )
  }

  return (
    <SimpleModal isVisible={isVisible}>
      <ProgressBar indeterminate={true} visible={loading} />
      <Container>
        <Header>
          <CloseButton onPress={onBack}>
            <Icon icon="close" height={14} width={14} color={theme.basics[900]} />
          </CloseButton>
          <Title>{edit ? 'Edit' : 'Invite'} {member?.singular}</Title>
        </Header>
        <Formik
          enableReinitialize
          validateOnMount
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, isValid, handleChange, handleSubmit, }) => (
            <>
              <ScrollView>
                <TextField
                  label="First name"
                  value={values.first_name}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('first_name')} />
                <TextField
                  label="Last name"
                  value={values.last_name}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('last_name')} />
                <TextField
                  label="Internal ID"
                  value={values.internal_id}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('internal_id')} />
                <TextField
                  label="Work email address"
                  value={values.email}
                  disabled={edit}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('email')} />
                <TextField
                  label="Role"
                  value={values.role_txt}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('role_txt')} />
                <TextField
                  label="City"
                  value={values.city}
                  autoCapitalize="none"
                  keyboardType="default"
                  onChangeText={handleChange('city')} />
                <ContainerRoles>
                  <RolesTitle>Role &amp; Permissions</RolesTitle>
                  {rolesRadios({ values, handleChange })}
                </ContainerRoles>
              </ScrollView>
              {edit ? (
                <ContainerActivated>
                  <Label>Active</Label>
                  <Switch
                    value={isActive}
                    onValueChange={setIsActive}
                    color="#F05343"
                  />
                </ContainerActivated>
              ) : null}
              <Button
                onPress={handleSubmit}
                color={color_scheme_primary_button_background ? color_scheme_primary_button_background : "black"}
                disabled={!isValid}
                style={{
                  width: isMobile ? '90%' : 190,
                  alignSelf: isMobile ? 'center' : 'flex-end',
                  marginTop: 20
                }}
              >
                {loading && (
                  <ActivityIndicator
                    size={20}
                    animating
                    color={theme.basics.background_white}
                    style={{ marginRight: 10 }}
                  />
                )}
                <TextButton color={color_scheme_primary_button_text} >{edit ? 'Save modifications' : 'Invite Employee'}</TextButton>
              </Button>
              {edit ? (
                <DeleteButton onPress={openDeleteEmployeeModal}>
                  <DeleteButtonText>Delete {member?.singular}</DeleteButtonText>
                </DeleteButton>
              ) : null}
            </>
          )}
        </Formik>
      </Container>
    </SimpleModal>
  )
}

InviteEmployee.defaultValues = {
  edit: false,
}

InviteEmployee.propTypes = {
  onBack: PropTypes.func,
  isVisible: PropTypes.bool,
  edit: PropTypes.bool,
  editableEmployee: PropTypes.objectOf(PropTypes.any),
  setEditableEmployee: PropTypes.func
}

export default InviteEmployee
