import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import {
    TouchableOpacity,
    View,
    Image,
    StyleSheet,
    Modal,
    ActivityIndicator,
    SafeAreaView,
    Text,
    Platform,
} from "react-native";
import { Video } from "expo-av";
import { useNavigation } from '@react-navigation/native'
import Hyperlink from 'react-native-hyperlink'

import MentionsTextView from "@/components/MentionTextView";
import GenericContentCard from '@/components/Feed/Card/Content'
import PostComposer from '@/components/Feed/Card/Content/Post/Components/PostComposer'
import Icon from "@/components/Icon";

import canAccess from "@/helpers/permissions";
import { permissions } from "@/helpers/permissionConstants";

import { getMember } from "@/redux/selectors/auth";
import * as brandSelectors from '@/redux/selectors/branding'

import {
    ContentCard,
    PostImageContainer
} from "@/components/FeedCard/FeedPostCard/AppPostComponent/styles.js";

import DeletePostModal from "@/components/Feed/Card/Content/Post/Components/Delete";

import styles from './styles.js';

const PostContentCard = ({
    post
}) => {
    const navigation = useNavigation()

    const video = React.useRef(null);

    const user = useSelector(getMember);
    const branding = useSelector(brandSelectors.getSettings)

    const [isDeletePostVisible, setDeletePostVisible] = useState(false);
    const [isEditPostVisible, setEditPostVisible] = useState(false);
    const [isViewerModalVisible, setViewerModalVisible] = useState(false);
    const [interactiveTextColor, setInteractiveTextColor] = React.useState('rgb(240, 83, 67)');

    useEffect(() => {
        if (branding && branding.color_scheme_accents) {
            setInteractiveTextColor(branding.color_scheme_accents)
        }
    }, [])

    const handleDelete = useCallback(() => {
        setDeletePostVisible(true);
    }, [setDeletePostVisible]);

    const handleEdit = useCallback(() => {
        setEditPostVisible(true);
    }, [setEditPostVisible]);

    const getMenu = () => {
        let menu = new Array();

        if (post?.creator_id == user?.id) {
            menu.push(
                {
                    text: "Edit", icon: "pencil", onPress: handleEdit
                }
            )

            menu.push(
                {
                    text: "Delete", icon: "delete", onPress: handleDelete
                }
            )

            return menu
        }

        if (canAccess(permissions.DELETE_POSTS)) {
            menu.push(
                {
                    text: "Delete", icon: "delete", onPress: handleDelete
                }
            )

            return menu
        }
    }

    const renderTextView = (str) => {
        const startword = "##"
        const endword = "@@"
        const innerFormatStart = "["
        const innerFormatEnd = "]"

        if (str && str.length) {
            let result = [];
            let string = str.split(startword).map(el => el.split(endword)).reduce((acc, curr) => acc.concat(curr))

            string.map((substr) => {
                let trimmedStr = substr.replace(/(^"|"$)/g, '')
                let strArray = trimmedStr.split(innerFormatStart).map(el => el.split(innerFormatEnd)).reduce((acc, curr) => acc.concat(curr));
                if (strArray.length >= 3) {
                    let mentionArray = strArray[1].split(',');

                    result.push(
                        <Text
                            onPress={() => {
                                navigation.navigate('Profile', { id: mentionArray[0] })
                            }}
                            style={{
                                color: interactiveTextColor,
                                fontWeight: 'bold',
                                lineHeight: 20,
                            }}
                        >
                            @{mentionArray[1].replace(/['"]+/g, '')}
                        </Text>
                    )
                } else {
                    result.push(
                        <Text>
                            {substr}
                        </Text>
                    )
                }
            })

            return (
                <Hyperlink
                    linkDefault={true}
                    linkStyle={{
                        color: interactiveTextColor
                    }}
                >
                    <Text
                        style={{
                            lineHeight: 20,
                            color: '#000000'
                        }}
                    >
                        {result}
                    </Text>
                </Hyperlink>
            )

        } else {
            return (
                <Hyperlink
                    linkDefault={true}
                    linkStyle={{
                        color: interactiveTextColor
                    }}
                >
                    <Text>
                        {str}
                    </Text>
                </Hyperlink>
            )
        }
    }

    return (
        <>
            <GenericContentCard
                type={'App\\Post'}
                post={post}
                menu={getMenu()}
            >
                <View style={styles.TextContainer}>
                    {renderTextView(post?.text)}
                </View>

                {post?.file?.length > 1 && post?.file_type === "file-image" && (
                    <TouchableOpacity
                        style={styles.ImageContainer}
                        onPress={() => setViewerModalVisible(true)}
                    >
                        <Image
                            source={{ uri: post?.file }}
                            style={styles.Image}
                        />
                    </TouchableOpacity>
                )}

                {post?.file?.length > 1 && post?.file_type === "file-video" && (
                    <View style={styles.VideoContainer}>
                        <Video
                            ref={video}
                            style={styles.Video}
                            source={{ uri: post?.file }}
                            useNativeControls
                            resizeMode={Video.RESIZE_MODE_COVER}
                        />
                    </View>
                )}
            </GenericContentCard>

            <DeletePostModal
                post={post}
                isVisible={isDeletePostVisible}
                setVisible={setDeletePostVisible}
                attachmentType={post?.attachment_type}
                attachmentId={post?.attachment_id}
            />

            <PostComposer
                post={post}
                isVisible={isEditPostVisible}
                setVisible={setEditPostVisible}
                file={post.file}
                fileType={post.file_type == "file-video" ? "video" : "image"}
                attachmentType={post?.attachment_type}
                attachmentId={post?.attachment_id}
            />

            <Modal
                style={styles.ViewerModal}
                animationType="fade"
                visible={isViewerModalVisible}
            >
                <SafeAreaView style={{ flex: 1 }}>
                    <View style={{
                        flex: 1,
                    }}>
                        <View style={styles.ViewerContainer}>
                            <View style={styles.ViewerHeaderContainer}>
                                <View style={styles.ViewerDismissContainer}>
                                    <TouchableOpacity
                                        onPress={() => {
                                            setViewerModalVisible(false)
                                        }}
                                        style={[
                                            styles.ViewerDismissButton,
                                            {}
                                        ]}
                                    >
                                        <Icon
                                            icon="arrowLeft"
                                            color={'#1b1b1b'}
                                            height={Platform.OS == 'web' ? 13 : 10}
                                        />
                                    </TouchableOpacity>
                                </View>

                                <View style={styles.ViewerTitleContainer}>
                                    <Text style={styles.ViewerTitle}></Text>
                                </View>
                            </View>

                            <View style={styles.ViewerImageContainer}>
                                {post?.file?.length > 1 && post?.file_type === "file-image" && (
                                    <Image
                                        source={{ uri: post?.file }}
                                        style={styles.ViewerImage}
                                    />
                                )}
                            </View>
                        </View>
                    </View>
                </SafeAreaView>
            </Modal>
        </>
    )
}

export default PostContentCard