import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Calendar(props) {
  return (
    <Svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M16 2H15V0H13V2H5V0H3V2H2C0.9 2 0 2.9 0 4V18C0 19.1 0.9 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V8H16V18ZM2 6V4H16V6H2ZM4 10H14V12H4V10ZM4 14H11V16H4V14Z"
      />
    </Svg>
  )
}

Calendar.propTypes = Svg.propTypes
