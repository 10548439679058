import React from 'react'

import { useRoute } from '@react-navigation/native'

import Event from './Event';
import { EVENT_MODE } from "./consts";



const EventEdit = () => {

    const { params } = useRoute();

    return <Event title={"Edit Event"} mode={EVENT_MODE.edit} eventInfo={params || {}} />
}

export default EventEdit