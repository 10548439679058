import React from 'react';
import {SheetBtn, Title} from './styles'
import {MaterialCommunityIcons} from '@expo/vector-icons'
import UseBrandingAccentColor from '@/hooks/useBrandingAccentColor';


const ActionButton = ({ icon, onPress, title }) => {
    const accentColor = UseBrandingAccentColor();

    return (
        <SheetBtn background={accentColor} onPress={onPress}>
          <MaterialCommunityIcons size={50} color="white" name={icon} />
          <Title>{title}</Title>
        </SheetBtn>
      )
}


  export default ActionButton;