import styled from 'styled-components/native'
import { StyleSheet } from 'react-native'
import { isMobile } from '@/styles'
import { shadow2 } from '@/styles/shadows'

export const styles = StyleSheet.create({
  scrollContent: {
    flexGrow: 1,
    alignItems: 'flex-start'
  }
})

export const Container = styled.View`
	position: relative;
	padding-bottom: ${!isMobile ? 44 : 0}px;
	margin: 0 0 ${!isMobile ? 50 : 0}px;
`
export const Button = styled.TouchableOpacity`
	height: 42px;
	width: 42px;
	border-radius: 42px;
	align-items: center;
	justify-content: center;
	background: ${({ disabled, theme }) => disabled ? theme.basics[300] : theme.basics.background_white};
	${shadow2}
	elevation: 3;

`
export const ContainerButton = styled.View`
	flex-direction: row;
	justify-content: space-between;
	width: 100px;
	right: 45px;
	bottom: 0;
	position: absolute;
`
