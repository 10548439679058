import React, { useEffect, useState } from 'react'
import { ScrollView, RefreshControl, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/native'
import * as teamsSelectors from '@/redux/selectors/teams'
import * as groupsSelectors from '@/redux/selectors/groups'
import * as employeesSelectors from '@/redux/selectors/employees'
import { actions as teamsActions } from '@/redux/reducers/teams'
import { actions as groupsActions } from '@/redux/reducers/groups'
import { actions as employeesActions } from '@/redux/reducers/employees'
import { StatusBar } from 'react-native'
import { ListViewEmployee, Icon, GroupTeamsCard, Carousel } from '@/components'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import {
    Container,
    Title,
    HeadLine,
    LinkButton,
    LinkText,
    SubTitle,
    Header,
    TitleWrapper,
    styles,
} from './styles'
import { ProgressBar } from 'react-native-paper'
import CreateEditGroup from '@/pages/Community/CreateEditGroup'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

import * as brandSelectors from '@/redux/selectors/branding'
import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const Community = () => {
    const dispatch = useDispatch()
    const navigation = useNavigation()
    const route = useRoute()
    const [createEditType, setCreateEditType] = useState('team')
    const [isCreateEditVisible, setIsCreateEditVisible] = useState(false)
    const [refreshing, setRefreshing] = useState(false)

    const wait = (timeout) => {
        return new Promise((resolve) => setTimeout(resolve, timeout))
    }

    const onRefresh = React.useCallback(() => {
        setRefreshing(true)
        dispatch(
            teamsActions.index({ page: teams.page + 1, filter: 'employee' }, () => { })
        )
        dispatch(
            groupsActions.index(
                { page: groups.page + 1, filter: 'employee' },
                () => { }
            )
        )
        dispatch(
            employeesActions.index(
                { page: employees.page + 1, filter: 'employee' },
                () => { }
            )
        )
        wait(1000).then(() => setRefreshing(false))
    }, [])

    const teams = useSelector(teamsSelectors.data)
    const groups = useSelector(groupsSelectors.data)
    const employees = useSelector(employeesSelectors.data)

    const branding = useSelector(brandSelectors.getSettings)
    const { team, group, member } = branding?.taxonomies

    const loading =
        (teams && teams.loading) ||
        (groups && groups.loading) ||
        (employees && employees.loading)

    useEffect(() => {
        dispatch(
            teamsActions.index({ filter: 'employee' }, (err) => {
                console.log('err', err)
            })
        )
        dispatch(
            groupsActions.index({ filter: 'employee' }, (err) => {
                console.log('err', err)
            })
        )
        dispatch(
            employeesActions.index({}, (err) => {
                console.log('err', err)
            })
        )
    }, [])
    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            teamsNextPage()
        })
        return unsubscribe
    }, [navigation])

    const teamsNextPage = () => {
        if (teams.loading || !teams.nextPage) {
            return
        }
        dispatch(
            teamsActions.index({ page: teams.page + 1, filter: 'employee' }, () => { })
        )
    }

    const groupsNextPage = () => {
        if (groups.loading || !groups.nextPage) {
            return
        }
        dispatch(
            groupsActions.index(
                { page: groups.page + 1, filter: 'employee' },
                () => { }
            )
        )
    }

    const employeesNextPage = () => {
        if (employees.loading || !employees.nextPage) {
            return
        }
        dispatch(
            employeesActions.index(
                { page: employees.page + 1, filter: 'employee' },
                () => { }
            )
        )
    }

    const isCloseToBottom = ({
        layoutMeasurement,
        contentOffset,
        contentSize,
    }) => {
        const paddingToBottom = 100
        return (
            layoutMeasurement.height + contentOffset.y >=
            contentSize.height - paddingToBottom
        )
    }

    const openTeamGroupModal = (teamGroupData, type) => {
        setCreateEditType(type)
        setIsCreateEditVisible(true)
    }

    return (
        <>
            <PageHeader
                title={'Community'}
                icon={'community'}
                backEnabled={false}
                actions={
                    [
                        {
                            title: 'Create',
                            type: 'menu',
                            permissions: [
                                'CREATE_TEAMS',
                                'CREATE_GROUPS'
                            ],
                            menu: [
                                {
                                    title: 'Create Team',
                                    icon: 'community',
                                    permissions: [
                                        'CREATE_TEAMS'
                                    ],
                                    onPress: () => {
                                        openTeamGroupModal(null, 'team')
                                    }
                                },
                                {
                                    title: 'Create Group',
                                    icon: 'community',
                                    permissions: [
                                        'CREATE_GROUPS'
                                    ],
                                    onPress: () => {
                                        openTeamGroupModal(null, 'group')
                                    }
                                }
                            ]
                        }
                    ]
                }
            />

            <PageContent>
                <ContentBlockV1
                    title={team?.plural}
                    action={{
                        onPress: () => {
                            navigation.navigate('Teams')
                        }
                    }}
                >
                    <Carousel nextPage={teamsNextPage}>
                        {teams &&
                            teams.data &&
                            (teams.data ?? [])?.map((team, key) => (
                                <GroupTeamsCard
                                    key={team?.id ?? key}
                                    type="teams"
                                    label="Teams"
                                    {...team}
                                />
                            ))}
                    </Carousel>
                </ContentBlockV1>

                <ContentBlockV1
                    title={group?.plural}
                    action={{
                        onPress: () => {
                            navigation.navigate('Groups')
                        }
                    }}
                >
                    <Carousel nextPage={groupsNextPage}>
                        {groups.data &&
                            (groups.data ?? [])?.map((group, key) => (
                                <GroupTeamsCard
                                    key={group?.id ?? key}
                                    label="Groups"
                                    type="groups"
                                    {...group}
                                />
                            ))}
                    </Carousel>
                </ContentBlockV1>

                {canAccess(permissions.MANAGE_EMPLOYEES) && (
                    <ContentBlockV1
                        title={member?.plural}
                        action={{
                            onPress: () => {
                                navigation.navigate('Employee')
                            }
                        }}
                    >
                        <ListViewEmployee
                            employeeListData={employees.data}
                            scrollEnabled={false}
                        />
                    </ContentBlockV1>
                )}
            </PageContent>

            <CreateEditGroup
                isVisible={isCreateEditVisible}
                onClose={() => {
                    setIsCreateEditVisible(false)
                }}
                type={createEditType}
            />
        </>
    )
}

export default Community
