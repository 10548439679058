import styled from 'styled-components/native'
import { Image, TouchableOpacity, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { shadow2 } from '@/styles/shadows'
import { isMobile } from '@/styles/screenSize'

export const styles = StyleSheet.create({
  flatListContent: {
    alignItems: 'center',
    flexDirection: 'column',
    paddingVertical: isMobile ? 0 : 20,
    paddingHorizontal: isMobile ? 5 : 30,
    width: '100%',
    maxWidth: 700,
    alignSelf: 'center',
    alignItems: 'stretch'
  },
  flatList: {
    flex: 1,
    width: '100%',
  }
})

export const Container = styled(SafeAreaView)`
  flex: 1;
	align-items: center;
`

export const ReframeHeader = styled.View`
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, color }) => color ? color : theme.basics.background_white};
  ${shadow2}
`

export const ReframeLogo = styled(Image)`
  width: 100%;
  height: 45px;
`
export const ScrollViewContent = styled.View`
  width: 100%;
  max-width: 700px;
`
