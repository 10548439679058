import _ from 'lodash'
import ApiHandler from '@/api/ApiHandler'
// Constants
export const constants = {
  GET_SHARED_FOLDERS: 'GET_SHARED_FOLDERS',
  GET_MY_FOLDERS: 'GET_MY_FOLDERS',
  GET_SUB_FOLDERS: 'GET_SUB_FOLDERS',
  RESET_STORAGE_FILES: 'RESET_STORAGE_FILES',
}

// ------------------------------------
// Actions
// ------------------------------------
export const actions = {
  sharedfolders: (data) => ({
    type: constants.GET_SHARED_FOLDERS,
    sharedfolders: data
  }),
  myfolders: (data) => ({
    type: constants.GET_MY_FOLDERS,
    myfolders: data
  }),
  subfolders: (data) => ({
    type: constants.GET_SUB_FOLDERS,
    subfolders: data
  }),
  resetstoragefiles: () => ({
    type: constants.RESET_STORAGE_FILES,
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: true,
  myfolders: [],
  sharedfolders: [],
  subfolders: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.GET_MY_FOLDERS:
      return {
        ...state,
        myfolders: action.myfolders,
        loading: false,
        error: null,
      };
    case constants.RESET_STORAGE_FILES:
      return {
        myfolders: [],
        sharedfolders: [],
        subfolders: [],
        loading: false,
        error: null,
      };
    case constants.GET_SHARED_FOLDERS:
      return {
        ...state,
        sharedfolders: action.sharedfolders,
        loading: false,
        error: null,
      };
    case constants.GET_SUB_FOLDERS:
      return {
        ...state,
        subfolders: action.subfolders,
        loading: false,
        error: null,
      };
  }
  return state
}
