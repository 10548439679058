import { isMobile } from '@/styles'
import { Paragraph1 } from '@/styles/fonts'
import { StyleSheet, Dimensions } from 'react-native'
import styled from 'styled-components/native'

const { width, height } = Dimensions.get('window')

export const style = StyleSheet.create({
  contentFlatList: {
    paddingHorizontal: 16,
  },
  contentFlatListMobile: {
    paddingBottom: height * 0.1
  }
})


export const SendButton = styled.TouchableOpacity`
	padding: 10px;
`

export const Container = styled.ScrollView`
	margin: 14px 0 0;
`

export const MoreButton = styled.TouchableOpacity`
	align-self: center;
`

export const MoreButtonText = styled(Paragraph1)`
	color: ${({ theme }) => theme.basics.brand};
	width: 100%;
`

export const ContainerMobile = styled.View`
	width: ${width}px;
	align-items: center;
	background-color: ${({ theme }) => theme.basics.background_white};
	flex:1;
	height: 100%;

`

export const CreateCommentWrapper = styled.View`
  padding: 15px;
	margin: 0 0 10px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.basics.background_white};
  align-items: center;
  flex-direction: row;
	width: ${isMobile ? width * 0.9 : 521}px;
	height: 69px;
`

export const TextInput = styled.TextInput`
	color: ${({theme}) => theme.basics.brand};
`

export const TextInputWrapper = styled.View`
	margin: 0 0 0 8px;
	flex: 1;
	border-bottom-width: 1px;
	border-bottom-color: ${({theme}) => theme.basics[500]};

`

export const ButtonNavigation = styled.TouchableOpacity``
