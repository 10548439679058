import styled from 'styled-components/native'
import { Dropdown as DD } from 'react-native-element-dropdown'

export const Container = styled.View`
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom-width: 1px;
    border-bottom-color: lightgray;
    display: ${props => props.hidden ? 'none' : 'flex'};
`

export const Dropdown = styled(DD)`
    
`

export const Text = styled.Text`
    color: lightgray;
`

