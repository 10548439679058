import React, { useState, useRef, useEffect } from 'react';
import { View, Text, StyleSheet, ScrollView } from 'react-native';
import { CardView } from '../styles';
import theme from '@/styles/themes/black';
import JoditEditor from 'jodit-react';
import ButtonPrimary from '@/components/ButtonPrimary';
import Switch from '@/components/Switch';
import ApiHandler from '@/api/ApiHandler';
import Toast from 'react-native-toast-message';

const CommunityGuidelines = () => {
    const editor = useRef(null);
    const [status, setStatus] = useState(false);
    const [content, setContent] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);

    useEffect(() => {
        getCommunityGuidelines();
    }, []);

    const getCommunityGuidelines = async () => {
        try {
            const response = await new ApiHandler().getCommunityGuidelines();
            if (response?.data) {
                const { status, content } = response?.data?.data;
                setStatus(status == 1 ? true : false);
                setContent(content);
            }
        } catch (error) {
            console.log('GET COMMUNITY GUIDELINES ERROR', error);
        }
    };

    const updateContent = async () => {
        setBtnLoading(true);
        try {
            const data = {
                status,
                content
            }
            const response = await new ApiHandler().updateCommunityGuidelines(data);
            setBtnLoading(false);
            if (response?.data) {
                Toast.show({
                    position: 'top',
                    text1: response?.data?.message
                });
            }
        } catch (error) {
            setBtnLoading(false);
            console.log('COMMUNITY GUIDELINES UPDATE RESPONSE', error);
        }
    }

    return (
        <CardView style={styles.cardView}>
            <Text style={styles.title}>Community Guidelines</Text>
            <View style={styles.toggleSwitchView}>
                <Switch
                    isOn={status}
                    onToggle={setStatus}
                />
                <Text style={styles.toggleSwitchText}>Display Community Guidelines During Onboarding</Text>
            </View>
            <View style={{ overflow: 'hidden', height: 630 }}>
                <Text style={styles.subTitle}>Content</Text>
                <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1}
                    onChange={newContent => setContent(newContent)}
                />
            </View>
            <View>
                <ButtonPrimary
                    label="Update"
                    onPress={() => updateContent()}
                    loading={btnLoading}
                    customStyles={{ width: 130, marginTop: 29, alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                />
            </View>
        </CardView>
    );
};

export default CommunityGuidelines;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginRight: 38
    },
    cardView: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        height: 826
    },
    title: {
        fontSize: 24,
        fontFamily: 'WorkSans_400Regular',
        lineHeight: 27,
        color: theme.basics.black_text
    },
    toggleSwitchView: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 38,
        marginBottom: 29
    },
    toggleSwitchText: {
        fontSize: 15,
        fontFamily: 'WorkSans_400Regular',
        lineHeight: 20,
        color: theme.basics.black_text,
        paddingLeft: 19,
        display: 'flex',
        alignItems: 'center'
    },
    subTitle: {
        fontSize: 13,
        fontFamily: 'WorkSans_400Regular',
        lineHeight: 18,
        color: theme.basics[600],
        paddingBottom: 12
    },
    btnStyle: {
        display: 'flex',
        alignItems: 'flex-end'
    },
});