import styled from 'styled-components/native'
import { Subtitle2 } from '@/styles/fonts'
import themes from '@/styles/themes/black'

export const NavigationContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-self: flex-start;
	align-items: center;
	margin: 5px 0 5px;
`

export const LinkButton = styled.TouchableOpacity``

export const LinkText = styled(Subtitle2)`
	color: ${themes.danger.standard};
`

export const TextLink = styled.Text`
	font-size: 12px;
	font-family: 'WorkSans_400Regular';
	line-height: 16px;
	font-weight: normal;
	color: ${({ last_link }) => last_link ? themes.basics[900] : themes.basics[500]};
`

export const IconView = styled.View`
	margin: 0 8px;
`
