import React from 'react'
import PropTypes from 'prop-types'
import { Label, Ruler, Container } from './styles'

export default function DrawerSection({ title }) {
  return (
    <Container>
      <Ruler />
      {title && <Label>{title}</Label>}
    </Container>
  )
}

DrawerSection.propTypes = {
  title: PropTypes.string,
}
