import React, { useContext } from 'react'
import { CollapseButton } from '@/components'
import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'
import { CollapseButtonContainer } from './styles'
import { KebabeMenuWebContext } from '@/contexts/Community/Group/kebabMenuWeb'
import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'

const KebabMenuWeb = () => {
  const {
    setDeleteWebVisible,
    setLeaveWebVisible,
    setInviteMemberWebVisible,
    setCreateEditVisible,
    setManageMemberViewVisible,
    isOwner,
  } = useContext(KebabeMenuWebContext)

  if (!isMobile) {
    return (
      <CollapseButtonContainer>
        <CollapseButton
          icon="ellipsis"
          btnAlign="flex-end"
          btnWidth={38}
          iconWidth={4}
          iconHeight={16}
          btnPaddingHorizontal={0}
        >
          {(isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS))
              ? <CollapseButton.Item
                  color={theme.basics.black_text}
                  icon="pencil"
                  iconSize={14}
                  text="Edit information"
                  onPress={() => setCreateEditVisible(true)}
                />
              : null }
          { (isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS)) 
              ? <CollapseButton.Item
                  color={theme.basics.black_text}
                  icon="addUser"
                  iconSize={20}
                  text="Invite members"
                  onPress={() => setInviteMemberWebVisible(true)}
                />
              : null }
          { (isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS)) 
            ? <CollapseButton.Item
              color={theme.basics.black_text}
              icon="community"
              iconSize={20}
              text="Manage members"
              onPress={() => setManageMemberViewVisible(true)}
            />
            : null }
          {(!isOwner && !canAccess(permissions.MANAGE_TEAMS) || !canAccess(permissions.MANAGE_GROUPS)) 
              ? <CollapseButton.Item
                  color={theme.basics.black_text}
                  icon="leave"
                  iconSize={14}
                  text="Leave group"
                  onPress={() => setLeaveWebVisible(true)}
                />
              : null }
          { (isOwner || canAccess(permissions.MANAGE_TEAMS) || canAccess(permissions.MANAGE_GROUPS)) 
              ? <CollapseButton.Item
                  color={theme.basics.black_text}
                  icon="leave"
                  iconSize={14}
                  text="Delete group"
                  onPress={() => setDeleteWebVisible(true)}
                />
              : null }
          
        </CollapseButton>
      </CollapseButtonContainer>
    )
  }
  return null
}

export default KebabMenuWeb
