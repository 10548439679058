import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function SignupDecoration(props) {
  return (
    <Svg width="653" height="588" viewBox="0 0 653 588" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M385.602 425.963H651.119V0H385.602V425.963Z"
        fill="#E1E1E1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M516.229 586.409H519.069V424.543H516.229V586.409Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M430.059 587.829L517.649 499.302L605.239 587.829L607.102 585.948L517.649 495.537L428.198 585.948L430.059 587.829Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M385.602 383.367V424.543H651.119L385.602 383.367Z"
        fill="#DADADB"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M563.088 45.436V106.017L615.971 136.308C621.17 127.407 624.142 117.049 624.142 106.017C624.142 94.9844 621.17 84.6279 615.971 75.7268C605.415 57.6106 585.69 45.436 563.088 45.436Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.552 135.598C521.038 153.856 540.632 166.126 563.087 166.126C585.541 166.126 605.136 153.856 615.623 135.598L563.087 105.071L510.552 135.598Z"
        fill="#FF5454"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M502.029 106.017C502.029 117.049 505 127.407 510.202 136.308L563.084 106.017V45.436C540.481 45.436 520.757 57.6106 510.202 75.7268C505 84.6279 502.029 94.9844 502.029 106.017Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M555.986 198.783H619.881V195.943H555.986V198.783Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M555.986 205.882H619.881V203.042H555.986V205.882Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M555.986 214.402H619.881V211.562H555.986V214.402Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M577.284 221.501H621.3V218.661H577.284V221.501Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M577.284 230.02H621.3V227.18H577.284V230.02Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.159 59.6349H475.053V56.7952H411.159V59.6349Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.159 68.1542H475.053V65.3145H411.159V68.1542Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.159 75.2536H475.053V72.4138H411.159V75.2536Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.04 83.7729H475.056V80.9331H431.04V83.7729Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.04 90.872H475.056V88.0322H431.04V90.872Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M409.739 220.081H511.97V195.943H409.739V220.081Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M409.74 220.081H511.972V195.943H409.74V220.081ZM411.053 218.811H510.663V197.213H411.053V218.811Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M409.739 256.998H511.97V232.86H409.739V256.998Z"
        fill="#FF5454"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M409.739 293.915H511.97V269.777H409.739V293.915Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.696 136.308L617.043 134.012L564.36 104.07V44.0161H561.667V105.601L615.696 136.308Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.46 136.308L563.087 105.95L561.759 103.651L509.132 134.01L510.46 136.308Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M649.697 425.963H652.537V0H649.697V425.963Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M382.762 425.963H585.804V423.306H385.425V281.136H382.762V425.963Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M384.182 269.777H387.022V261.257H384.182V269.777Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.839 218.502C217.547 213.296 221.255 208.214 221.255 200.681C221.255 182.119 212.971 181.402 213.715 162.84C214.459 144.277 228.241 145.008 228.985 126.444C229.083 124.047 228.863 121.941 228.407 120.067C226.96 117.631 225.733 115.056 224.741 112.37C224.563 111.902 224.395 111.419 224.246 110.953C222.648 106.188 221.792 101.091 221.792 95.8049C221.792 69.4326 243.195 48.0474 269.589 48.0474C295.996 48.0474 317.397 69.4326 317.397 95.8049C317.397 101.091 316.544 106.188 314.945 110.953C314.793 111.419 314.627 111.874 314.461 112.343L314.449 112.356C313.456 115.056 312.216 117.645 310.768 120.096C310.327 121.968 310.107 124.061 310.205 126.444C310.949 145.008 324.717 144.277 325.46 162.84C326.205 181.402 317.922 182.119 317.922 200.681C317.922 208.214 321.629 213.296 325.349 218.502C329.043 223.722 332.752 229.065 332.752 237.078C332.752 260.076 314.105 278.708 291.087 278.708H248.089C225.072 278.708 206.438 260.076 206.438 237.078C206.438 229.065 210.146 223.722 213.839 218.502Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M302.549 269.783C302.549 286.087 289.332 299.302 273.03 299.302C256.726 299.302 243.511 286.087 243.511 269.783C243.511 253.48 256.726 240.264 273.03 240.264C289.332 240.264 302.549 253.48 302.549 269.783Z"
        fill="#FF0000"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M427.486 196.53C427.597 192.74 424.615 189.58 420.823 189.467C417.035 189.356 413.874 192.338 413.762 196.128C413.651 199.917 416.633 203.08 420.421 203.191C424.213 203.301 427.375 200.319 427.486 196.53Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.038 174.361C308.038 183.462 300.663 190.837 291.562 190.837C282.464 190.837 275.086 183.462 275.086 174.361C275.086 165.262 282.464 157.885 291.562 157.885C300.663 157.885 308.038 165.262 308.038 174.361Z"
        fill="#FFAA00"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.808 184.373L433.809 184.382C434.724 184.095 435.696 183.947 436.706 183.976C441.615 184.122 445.479 188.231 445.333 193.159C445.19 198.085 441.094 201.962 436.185 201.817L420.948 201.369L419.251 188.909L433.808 184.373Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.537 220.983C352.224 226.839 349.654 233.766 343.798 236.453C337.94 239.139 331.016 236.57 328.327 230.714C325.641 224.857 328.211 217.929 334.069 215.243C339.926 212.556 346.849 215.126 349.537 220.983Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.57 163.377L347.857 218.265L330.154 234.772L279.209 186.094L303.57 163.377Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335.499 215.992L419.783 190.431L424.743 201.585L343.723 236.586L335.499 215.992Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.086 578.016C275.086 582.566 278.774 586.254 283.324 586.254C287.873 586.254 291.562 582.566 291.562 578.016C291.562 573.467 287.873 569.779 283.324 569.779C278.774 569.779 275.086 573.467 275.086 578.016Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M308.023 175.409L303.692 269.097H243.845L219.114 170.708C217.478 164.2 222.394 157.885 229.098 157.885H291.31C300.854 157.885 308.464 165.865 308.023 175.409Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.195 240.264L239.392 239.708L238.846 237.518L236.646 238.076L237.195 240.264Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.551 234.772L238.017 234.153L228.125 194.956L225.66 195.575L235.551 234.772Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M279.606 163.025C273.345 169.627 273.623 180.056 280.226 186.316C286.829 192.577 297.257 192.301 303.517 185.699C309.778 179.096 309.502 168.666 302.899 162.406C296.296 156.144 285.868 156.422 279.606 163.025Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.082 269.098L303.081 269.097H276.658H243.331L240.11 271.606C229.262 280.056 222.916 293.057 222.916 306.828V319.711C222.916 329.671 230.97 337.746 240.905 337.746H292.798C302.734 337.746 310.787 329.671 310.787 319.711L310.785 302.069C310.785 290.63 308.149 279.347 303.082 269.098Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.597 186.301L296.64 166.124L318.323 194.258L318.307 194.269C319.513 195.833 320.472 197.622 321.071 199.618C323.724 208.46 318.592 217.744 309.607 220.356C302.649 222.379 295.436 219.793 291.297 214.424L291.281 214.435L269.597 186.301Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M301.016 178.48L302.549 177.324L301.337 175.734L299.803 176.891L301.016 178.48Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.737 221.043C305.326 221.043 306.912 220.811 308.449 220.353C312.718 219.083 316.239 216.216 318.364 212.277C320.49 208.339 320.967 203.803 319.706 199.504C319.14 197.576 318.229 195.768 316.993 194.127L305.238 178.48L303.196 180.041L314.951 195.686C315.999 197.079 316.772 198.609 317.248 200.237C318.316 203.872 317.912 207.711 316.113 211.044C314.314 214.376 311.335 216.803 307.724 217.877C303.662 219.085 299.26 218.37 295.759 215.948L294.31 218.075C297.115 220.016 300.374 221.043 303.737 221.043Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M266.684 221.042C268.274 221.042 269.865 220.802 271.405 220.324C273.235 219.758 274.936 218.886 276.461 217.731L274.958 215.551C273.666 216.528 272.227 217.264 270.678 217.744C266.609 219.003 262.198 218.258 258.691 215.735L257.239 217.95C260.049 219.973 263.315 221.042 266.684 221.042Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.263 332.142C207.966 334.011 203.778 332.851 201.909 329.554C200.041 326.256 201.199 322.067 204.498 320.2C207.796 318.332 211.983 319.491 213.852 322.787C215.72 326.085 214.562 330.275 211.263 332.142Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M188.223 240.661L201.614 328.083L214.392 326.953L212.364 238.527L188.223 240.661Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.802 315.809L214.048 314.547L212.301 238.474L188.276 240.599L199.802 315.809Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.239 343.793C205.742 342.926 205.416 342.012 205.234 341.087L205.226 341.09L202.34 326.366L213.885 322.249L221.392 335.324C223.812 339.536 222.378 344.847 218.194 347.186C214.009 349.524 208.657 348.005 206.239 343.793Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.975 192.225C222.545 195.282 231.969 190.813 235.027 182.246C238.082 173.676 233.613 164.252 225.047 161.195C216.477 158.138 207.052 162.605 203.996 171.175C200.938 179.743 205.406 189.168 213.975 192.225Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.549 245.071C213.522 238.701 209.145 232.747 202.775 231.774C196.402 230.801 190.449 235.177 189.477 241.55C188.503 247.92 192.879 253.874 199.25 254.846C205.622 255.821 211.576 251.444 212.549 245.071Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.899 170.934L188.695 240.376L212.215 247.355L235.267 180.536L202.899 170.934Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.575 204.567L233.899 193.048L232.629 190.837L213.304 202.357L214.575 204.567Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M408.624 193.583L413.759 206.604L341.928 237.518L335.499 215.563L408.624 193.583Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.086 319.897C275.086 329.754 283.077 337.746 292.935 337.746C302.791 337.746 310.784 329.754 310.784 319.897C310.784 310.039 302.791 302.048 292.935 302.048C283.077 302.048 275.086 310.039 275.086 319.897Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M222.994 318.241C222.079 328.056 229.294 336.752 239.109 337.667C248.923 338.581 257.621 331.368 258.535 321.553C259.45 311.738 252.235 303.043 242.42 302.126C232.606 301.213 223.908 308.427 222.994 318.241Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M291.234 576.644H275.086V589H297.711C299.623 589 301.173 587.287 301.173 585.174C301.173 583.804 300.511 582.539 299.436 581.857L291.234 576.644Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.797 578.016C218.797 582.566 222.485 586.254 227.035 586.254C231.584 586.254 235.273 582.566 235.273 578.016C235.273 573.467 231.584 569.779 227.035 569.779C222.485 569.779 218.797 573.467 218.797 578.016Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M221.754 411.886L218.797 576.474L235.245 578.016L250.788 411.886H221.754Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M258.612 320.512L250.101 413.259L221.541 411.655L223.21 318.524L258.612 320.512Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.086 411.886C275.086 420.226 281.846 426.989 290.189 426.989C298.53 426.989 305.292 420.226 305.292 411.886C305.292 403.545 298.53 396.783 290.189 396.783C281.846 396.783 275.086 403.545 275.086 411.886Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.086 411.886V576.644H292.446L305.292 411.886H275.086Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.088 319.897L275.086 412.175L303.923 413.259L310.784 321.241L275.088 319.897Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.902 413.259H221.541L223.301 321.27H310.785L303.902 413.259Z"
        fill="#6B6C70"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M307.915 377.562L312.16 321.461L309.537 321.27L305.295 377.37L307.915 377.562Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M286.073 413.259H305.359L308.04 377.751L305.451 377.562L302.947 410.7H286.073V413.259Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M259.985 413.259H284.698V410.513H259.985V413.259Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M223.567 407.767L224.287 359.762L222.261 359.713L221.541 407.72L223.567 407.767Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.568 356.967L225.662 352.89L223.01 352.848L222.916 356.926L225.568 356.967Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.537 159.681C255.149 167.62 262.082 173.558 270.018 172.945C277.957 172.331 283.893 165.4 283.283 157.463C282.669 149.524 275.738 143.587 267.801 144.199C259.862 144.811 253.923 151.743 254.537 159.681Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M281.953 157.073L253.116 159.259L250.375 124.375L279.212 122.188L281.953 157.073Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M274.42 64.5935L272.247 64.7637C258.379 65.8468 248.005 78.1059 249.076 92.1483L252.727 140.037L279.029 137.982C292.239 136.95 302.121 125.271 301.101 111.898L299.129 86.0233C298.152 73.1981 287.089 63.6031 274.42 64.5935Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M249.927 111.187L255.867 110.728L254.95 98.8477L249.011 99.3063C245.729 99.5589 243.274 102.423 243.529 105.705C243.782 108.985 246.646 111.439 249.927 111.187Z"
        fill="#FF7070"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M248.715 100.128L247.529 100.22L244.884 65.8213L297.075 61.7771L298.43 79.402L297.242 79.4942C287.143 80.276 277.573 84.3462 269.983 91.084C264.064 96.3425 256.597 99.5179 248.715 100.128Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M219.126 576.644H235.274V589H212.649C210.738 589 209.187 587.287 209.187 585.174C209.187 583.804 209.85 582.539 210.924 581.857L219.126 576.644Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M276.461 117.784C276.789 121.263 280.121 123.838 283.906 123.537C287.688 123.237 290.491 120.173 290.165 116.696L276.461 117.784Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.186 246.499C122.255 260.698 127.4 274.369 131.506 288.304C135.734 302.648 139.998 317.24 139.947 332.19C139.879 351.638 132.148 370.957 118.774 385.099C113.533 390.64 107.414 395.481 103.376 401.949C99.3399 408.418 97.8525 417.301 102.36 423.449C108.864 432.322 122.778 430.399 132.289 435.942C144.264 442.92 145.928 460.705 139.1 472.753C132.272 484.799 101.182 519.268 56.8204 510.733C36.9661 506.913 10.0525 488.049 10.8208 467.88C11.6193 446.877 31.7105 429.864 31.0268 408.856C30.579 395.093 21.31 383.462 13.2873 372.262C5.26352 361.06 -2.2112 347.416 1.48284 334.148C6.92004 314.619 32.1155 308.587 44.3166 292.385C52.1733 281.95 53.904 268.227 54.3914 255.183C54.881 242.138 54.5459 228.771 58.9049 216.466C69.4766 186.616 107.011 190.177 119.186 246.499Z"
        fill="#BFC0C1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.8102 547.725H74.0382V321.578H71.8102V547.725Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.9559 387.306L74.0363 386.975C70.6269 366.893 47.0721 354.406 46.0698 353.885L45.0717 355.684C45.3089 355.808 68.7183 368.227 71.9559 387.306Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.7742 476.427C75.9502 462.112 91.7034 452.795 91.8626 452.701L90.9184 450.805C90.2358 451.202 74.1454 460.728 71.8102 476.077L73.7742 476.427Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.7 587.83H84.031C98.2001 587.83 109.685 576.435 109.685 562.378V541.041H35.0458V562.378C35.0458 576.435 46.5319 587.83 60.7 587.83Z"
        fill="#FFC654"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0458 548.839H109.685V546.611H35.0458V548.839Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.932 541.041H81.8349V538.812H33.932V541.041Z"
        fill="#313338"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.1893 584.488C105.202 579.906 110.8 570.952 110.8 561.115V553.295H108.68V561.115C108.68 570.188 103.516 578.447 95.2035 582.672L96.1893 584.488Z"
        fill="#313338"
      />
    </Svg>
  )
}
