import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import _ from 'lodash'

// Constants
export const constants = {
  GET_EVENT_LIST: createSagaAction('GET_EVENT_LIST'),
  GET_EVENT_WITH_ID: createSagaAction('GET_EVENT_WITH_ID'),
  CREATE_EVENT: createSagaAction('CREATE_EVENT'),
  UPDATE_EVENT: createSagaAction('UPDATE_EVENT'),
  GET_FEATURED_EVENT: createSagaAction('GET_FEATURED_EVENT'),
  GET_READ_LATER_EVENT: createSagaAction('GET_READ_LATER_EVENT'),
  TOGGLE_FEATURED_EVENT: createSagaAction('TOGGLE_FEATURED_EVENT'),
  TOGGLE_STATUS_EVENT: createSagaAction('TOGGLE_STATUS_EVENT'),
  TOGGLE_READ_LATER_EVENT: createSagaAction('TOGGLE_READ_LATER_EVENT'),
  DELETE_EVENT: createSagaAction('DELETE_EVENT'),
  TRACKING_TIME_EVENT: createSagaAction('TRACKING_TIME_EVENT')
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  getEventList: (object, next) => ({
    type: constants.GET_EVENT_LIST.ACTION,
    payload: object,
    next,
  }),
  getEventWithIdAction: (object, next) => ({
    type: constants.GET_EVENT_WITH_ID.ACTION,
    payload: { ...object },
    next,
  }),
  createEventAction: (object, next) => ({
    type: constants.CREATE_EVENT.ACTION,
    payload: object,
    next,
  }),
  updateEventAction: (object, next) => ({
    type: constants.UPDATE_EVENT.ACTION,
    payload: object,
    next,
  }),
  getFeaturedAction: (object, next) => ({
    type: constants.GET_FEATURED_EVENT.ACTION,
    payload: object,
    next,
  }),
  getReadLaterAction: (object, next) => ({
    type: constants.GET_READ_LATER_EVENT.ACTION,
    payload: object,
    next,
  }),
  toggleFeaturedAction: (object, next) => ({
    type: constants.TOGGLE_FEATURED_EVENT.ACTION,
    payload: object,
    next,
  }),
  toggleStatusAction: (object, next) => ({
    type: constants.TOGGLE_STATUS_EVENT.ACTION,
    payload: object,
    next,
  }),
  toggleReadLaterAction: (object, next) => ({
    type: constants.TOGGLE_READ_LATER_EVENT.ACTION,
    payload: object,
    next,
  }),
  deleteEventAction: (object, next) => ({
    type: constants.DELETE_EVENT.ACTION,
    payload: object,
    next,
  }),
  trackActiveTime: (params, next) => ({
    type: constants.TRACKING_TIME_EVENT.ACTION,
    ...params,
    next,
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.CREATE_EVENT.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.CREATE_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    }
  },

  [constants.UPDATE_EVENT.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.UPDATE_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
    }
  },

  [constants.GET_EVENT_LIST.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_EVENT_LIST.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      // events: action.payload.data.data,
      events: action.payload.data,
      error: null,
    }
  },

  [constants.GET_EVENT_WITH_ID.ACTION]: (state) => {
    return { ...state, error: null, loading: true, course: null }
  },
  [constants.GET_EVENT_WITH_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: null,
      event: action.payload.data,
    }
  },

  [constants.GET_FEATURED_EVENT.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_FEATURED_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      featured: action.payload?.data || [],
      loading: false,
      error: null,
    }
  },

  [constants.GET_READ_LATER_EVENT.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.GET_READ_LATER_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      readLater: action.payload?.data || [],
      loading: false,
      error: null,
    }
  },

  [constants.TOGGLE_FEATURED_EVENT.ACTION]: (state) => {
    return { ...state, error: null, featuredLoading: true }
  },
  [constants.TOGGLE_FEATURED_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      featuredLoading: false,
      error: null,
    }
  },

  [constants.TOGGLE_STATUS_EVENT.ACTION]: (state) => {
    return { ...state, error: null, statusLoading: true }
  },
  [constants.TOGGLE_STATUS_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      statusLoading: false,
      error: null,
    }
  },

  [constants.TOGGLE_READ_LATER_EVENT.ACTION]: (state) => {
    return { ...state, error: null, readLaterLoading: true }
  },
  [constants.TOGGLE_READ_LATER_EVENT.SUCCESS]: (state, action) => {
    return {
      ...state,
      readLaterLoading: false,
      error: null,
    }
  },

  [constants.DELETE_EVENT.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.DELETE_EVENT.SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
      error: null,
    }
  },
  [constants.TRACKING_TIME_EVENT.ACTION]: (state, params) => {
    return {
      ...state,
      errors: null,
      loading: false,
      timeTracked: params.timeTracked,
      eventId: params.eventId,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: false,
  readLaterLoading: false,
  statusLoading: false,
  featuredLoading: false,
  statusLoading: false,
  events: [],
  event: null,
  featured: [],
  readLater: [],
  timeTracked: 0,
  eventId: 0,
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})
