import React from 'react'
import Svg, { Path, Circle, SvgProps } from 'react-native-svg'

function DrawerLogo(props) {
  return (
    <Svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle cx="20.5" cy="20.5" r="20.5" fill="white" />
      <Path
        d="M13.1782 14.3843V28.1392H26.9266V14.3843H13.1782ZM20.0524 25.665C19.7154 25.665 19.386 25.565 19.1058 25.3778C18.8256 25.1906 18.6072 24.9245 18.4782 24.6132C18.3493 24.3018 18.3155 23.9592 18.3813 23.6287C18.447 23.2982 18.6093 22.9946 18.8476 22.7563C19.0859 22.518 19.3895 22.3557 19.72 22.29C20.0505 22.2243 20.3931 22.258 20.7044 22.387C21.0158 22.5159 21.2819 22.7343 21.4691 23.0145C21.6563 23.2947 21.7563 23.6241 21.7563 23.9611C21.7571 24.1851 21.7136 24.4071 21.6283 24.6142C21.543 24.8213 21.4175 25.0095 21.2591 25.1679C21.1007 25.3262 20.9126 25.4517 20.7055 25.537C20.4983 25.6224 20.2764 25.6658 20.0524 25.665ZM20.0524 20.2596C19.7154 20.2596 19.386 20.1597 19.1058 19.9725C18.8256 19.7853 18.6072 19.5192 18.4782 19.2078C18.3493 18.8965 18.3155 18.5539 18.3813 18.2234C18.447 17.8929 18.6093 17.5893 18.8476 17.351C19.0859 17.1127 19.3895 16.9504 19.72 16.8847C20.0505 16.8189 20.3931 16.8527 20.7044 16.9816C21.0158 17.1106 21.2819 17.329 21.4691 17.6092C21.6563 17.8894 21.7563 18.2188 21.7563 18.5558C21.7571 18.7798 21.7136 19.0017 21.6283 19.2088C21.543 19.416 21.4175 19.6041 21.2591 19.7625C21.1007 19.9209 20.9126 20.0464 20.7055 20.1317C20.4983 20.217 20.2764 20.2605 20.0524 20.2596Z"
        fill="#F05343"
      />
      <Path
        d="M8.75879 9.96484V32.5589H31.3528V9.96484H8.75879ZM28.6436 29.8497H11.4615V12.6675H28.6436V29.8497Z"
        fill="black"
      />
    </Svg>
  )
}

DrawerLogo.propTypes = SvgProps

export default DrawerLogo
