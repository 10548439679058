import React, { useCallback, useEffect } from 'react'
import { FlatList, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import theme from '@/styles/themes/black'
import { Icon, MemberList } from '@/components'
import { isMobile } from '@/styles'
import {
  Container,
  Header,
  LeftIconButton,
  TitleHeader,
  IconButton,
  styles,
  CoverContainer,
  MemberContainer,
  Title,
  FakeInput,
  BackButton,
  RowContainer,
} from './styles'
import { Paragraph1 } from '@/styles/fonts'
import { useSelector, useDispatch } from 'react-redux'
import GroupTeamsMemberItem from '../../../../src/components/GroupTeamsMemberItem'
import * as selector from '@/redux/selectors/teams'
import * as selectors from '@/redux/selectors/groups'
import { actions as action } from '@/redux/reducers/teams'
import { actions } from '@/redux/reducers/groups'
import { store } from '@/redux/store'
const GroupMembers = ({ navigation, route }) => {
  // const members = route?.params?.members
  const dispatch = useDispatch()
  const team = useSelector(selector.team)
  const group = useSelector(selectors.group)
  const isTeam = route?.params?.isTeam
  const team_id = route?.params?.team_id
  // const handleRemoveMember = route?.params?.handleRemoveMember
  // const isOwner = route?.params?.isOwner
  const user = store.getState().auth.user
  let members = isTeam ? team?.members : group?.members ?? []
  const isOwner = isTeam ? team?.owner_id == user?.id : group?.owner_id == user?.id
  const onBack = route?.params?.onBack
  const id = route?.params?.id
  members = members?.filter(member => member.id != user.id)
  const data = [members]
  const renderItem = useCallback(({ item }) => (<MemberList source={item.avatar} />))
  const handleRemoveTeamMember = useCallback((member_id) => {

    dispatch(action.removeMember({ team_id: id, member_id: member_id }, () => {
      dispatch(action.show({ id }))
    }))

  }, [dispatch])
  const handleRemoveGroupMember = useCallback((member_id) => {
    dispatch(actions.removeMember({ group_id: id, member_id: member_id }, () => {
      dispatch(actions.show({ id }))
    }))
  }, [dispatch])
  useEffect(() => {
    if (isTeam) {
      dispatch(action.show({ id }))
    } else {
      dispatch(actions.show({ id }))
    }
  }, [id, navigation])
  return (
    <Container isMobile={isMobile}>
      <CoverContainer isMobile={isMobile}>
        <RowContainer>
          <BackButton onPress={() => {
            navigation.goBack()
            onBack()
          }
          }>
            <Icon icon="arrowLeft" height={14} width={14} color={theme.basics[900]} />
          </BackButton>
          <Title>Members</Title>
        </RowContainer>
        {/* <FakeInput>
          <Paragraph1 color={theme.basics[600]}>{'Search members'}</Paragraph1>
          <Icon icon="searchIcon" width={22} />
        </FakeInput> */}
      </CoverContainer>

      <MemberContainer>
	  <ScrollView >
          {(members ?? []).map(member => (
            <GroupTeamsMemberItem
              key={member?.id}
              member={member}
              isTeam={isTeam}
              isOwner={isOwner}
              onBack={onBack}
              handleRemoveMember={isTeam ? handleRemoveTeamMember : handleRemoveGroupMember}
            />
          ))}
		 </ScrollView>
      </MemberContainer>
	 

      {/* <Container>
      <Header>
        <LeftIconButton onPress={() => navigation.goBack()}>
          <Icon
            icon="arrowLeft"
            color={theme.basics[900]}
            height={17}
            width={16}
          />
        </LeftIconButton>
        <TitleHeader>Members</TitleHeader>
        <IconButton>
          <Icon
            icon="searchIcon"
            color={theme.basics[900]}
            height={17}
            width={17}
          />
        </IconButton>
      </Header>
      <FlatList
        data={data}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        contentContainerStyle={styles.flatListContent}
      /> */}
    </Container>
  )
}

GroupMembers.propTypes = {
  navigation: PropTypes.shape({
    goBack: PropTypes.func
  })
}

export default GroupMembers
