import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, Platform, FlatList, TouchableOpacity, TouchableHighlight, StyleSheet, Dimensions, Linking } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard';
import { useDispatch } from 'react-redux'
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import ApiHandler from '@/api/ApiHandler'
import { TextInput } from 'react-native-paper';
import { CheckBox } from 'react-native-elements';
import SimpleModal from '@/pages/SharedFolders/SimpleModal'
import { ProgressBar, Avatar } from 'react-native-paper'
import Toast from 'react-native-toast-message'
import SearchableDropdown from 'react-native-searchable-dropdown'

import {
  ScrollView,
  styles,
  CreateFolderContainer,
  HeaderCreateFolder,
  CloseButtonCreateFolder,
  HeaderTitleCreateFolder,
  HeaderSubTitleCreateFolder,
  HeaderSubTitleInfo,
  HeaderTitleInfoFolder,
  Tabs,
  Tab,
  TabTitle,
} from './styles'
import { isMobile } from '@/styles'
import {
  Menu,
  MenuTrigger,
  MenuOptions,
  MenuOption,
  renderers,
} from 'react-native-popup-menu';
import { MultiSelect } from 'react-native-element-dropdown';
import * as MediaLibrary from 'expo-media-library';
import * as FileSystem from 'expo-file-system';
import getEnv from '../../../../environment'
import InputField from '@/components/Chat/components/InputField';
import ButtonPrimary from '@/components/ButtonPrimary';
import { formatUserName } from '@/utils/format';
const { apiUrl } = getEnv()

const { SlideInMenu } = renderers;
const { height, width } = Dimensions.get('window')


const SideMenusMobile = (props) => {

  console.log('props item....', props.item)
  const [nodeIdToshare, setNodeIdToshare] = useState([]);
  const [isNodeShared, setIsNodeShared] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState([]);
  const [communityGroup, setCommunityGroup] = useState([]);

  const [selectedTeam, setSelectedTeam] = useState([]);
  const [communityTeam, setCommunityTeam] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [communityEmployee, setCommunityEmployee] = useState([]);

  const [resizeModelHeight, setResizeModelHeight] = useState(200);

  const renderItem = (item) => {
    return (
      <View style={dropdownStyles.item}>
        <Text style={dropdownStyles.textItem}>{item.first_name} {item.last_name}</Text>
      </View>
    );
  };

  const dispatch = useDispatch()
  const [isRenameModalVisible, setRenameModalVisible] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [nodeVal, setNode] = useState(null)
  const [deleteNode, setDeleteNode] = useState(null)
  const [isInfoModalVisible, setInfoModalVisible] = useState(false)
  const [isMoveToModalVisible, setMoveToModalVisible] = useState(false)
  const [isShareModalVisible, setShareModalVisible] = useState(false)
  const [getFolderInfo, setFolderInfo] = useState(null)
  const [foldername, setFolderName] = useState('')
  const [errorTextCreate, setCreateError] = useState('')
  const [loading, setloading] = useState(false)

  const [getSelectedFolderToMove, setSelectedFolderToMove] = useState(null)
  const [getFoldersToMove, setFoldersToMove] = useState(null)
  const [isHighlighted, setHighlighted] = useState(null)

  const [tab, setTab] = useState('group')

  const [sharedWithEntireWorkspace, setSharedWithEntireWorkspace] = React.useState(false);

  useEffect(() => {
    setCommunityGroup(props.communityGroup);
    setCommunityTeam(props.communityTeam);
    setCommunityEmployee(props.communityEmployee);
  }, [])


  const formatDate = (date) => {
    var d = new Date(date.replace(' ', 'T')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const handleRenameFolder = useCallback((val) => {
    setCreateError('');
    setNode(val);
    setFolderName(val.name)
    setRenameModalVisible(true)
  }, [])

  const renameFolder = async () => {
    try {
      setCreateError('');
      if (foldername && nodeVal) {
        let data = {
          name: foldername,
        }
        const response = await new ApiHandler().renameFolder(nodeVal.id, data)
        console.log(response)
        setRenameModalVisible(false)
        props.getMyFolders();
      }
      else {
        setCreateError('Please input folder name');
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const deleteFolderConfirm = async (item) => {
    setDeleteNode(item);
    setDeleteModalVisible(true);
  }


  const deleteFolder = async () => {
    try {
      if (deleteNode) {
        const response = await new ApiHandler().deleteFolder(deleteNode.id)
        setDeleteNode(null);
        setDeleteModalVisible(false);
        props.getMyFolders();
      }
      else {
        setDeleteModalVisible(false);
        Toast.show({
          type: 'error',
          position: 'top',
          text1: 'Nothing is selected',
        })
      }
    } catch (error) {
      setDeleteModalVisible(false);
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getFolderInfoAsync = async (id) => {
    try {
      setloading(true);
      const response = await new ApiHandler().showFolders(id);
      console.log(response)
      if (response?.data) {
        setloading(false);
        setFolderInfo(response.data);
        setInfoModalVisible(true);
      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getFolderListToMoveAsync = async (id) => {
    try {
      setloading(true);
      // let requestData = JSON.stringify([id]);
      var requestData = id;
      const response = await new ApiHandler().folderListToMoveOn(id);
      console.log('moveto', response?.data)
      if (response?.data) {
        setloading(false);
        setFoldersToMove(response.data);
      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const handleMoveToFolder = useCallback(async (id) => {
    setMoveToModalVisible(true)
    setSelectedFolderToMove(id);
    getFolderListToMoveAsync(id);
  }, [])


  const moveFolderAsync = async (id) => {
    try {
      if (getSelectedFolderToMove && isHighlighted && isHighlighted.id) {
        setloading(true);
        let dataTomove = {
          node_id: getSelectedFolderToMove,
          move_to: isHighlighted.id
        }
        const response = await new ApiHandler().moveFolder(dataTomove);
        console.log(response)
        if (response?.success) {
          setloading(false);
          setFoldersToMove(null);
          setHighlighted(null);
          setSelectedFolderToMove(null);
          setMoveToModalVisible(false);
          props.getMyFolders();
        }
      }


    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getSharedMembersAsync = async (nodeId) => {
    try {
      setloading(true);
      const response = await new ApiHandler().getSharedMemebers(nodeId);
      setloading(false);
      if (response?.data) {
        let responseData = response.data.data;
        let employees = responseData && responseData.employees ? responseData.employees.map((e) => e.id) : [];
        let groups = responseData && responseData.groups ? responseData.groups.map((g) => g.id) : [];
        let teams = responseData && responseData.teams ? responseData.teams.map((t) => t.id) : [];
        setSelectedGroup(groups);
        setSelectedTeam(teams);
        setSelectedEmployee(employees);

      }

    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }

  const getSharedFolderFileInfoAsync = async (id) => {
    try {
      setloading(true);
      const response = await new ApiHandler().showFolders(id);
      console.log(response)
      if (response?.data) {
        setloading(false);
        const isSharedWithEntireWorkspace = response?.data.shared.length > 0 ? true : false;
        setSharedWithEntireWorkspace(isSharedWithEntireWorkspace);
      }

    } catch (error) {
      console.log('error')
    }
  }

  const getShareModalAsync = async (item) => {
    getSharedFolderFileInfoAsync(item.id);
    setSharedWithEntireWorkspace(false);
    setCreateError('')
    setIsNodeShared(false);
    setNodeIdToshare(item.id);
    setShareModalVisible(true)
    if (item.is_shared) {
      setIsNodeShared(true);
      getSharedMembersAsync(item.id);
    }

  }


  const shareFolderAsync = async (id) => {
    try {
      setCreateError('')
      if (isNodeShared) {
        setloading(true);
        const dataToshare = {
          "share":
          {
            "App\\Employee": selectedEmployee,
            "App\\Team": selectedTeam,
            "App\\Group": selectedGroup
          },
          "workspace": sharedWithEntireWorkspace == true ? 1 : 0,
          "update": 1
        }

        const nodeId = nodeIdToshare;
        const response = await new ApiHandler().shareFolder(nodeId, dataToshare);
        console.log(response)
        if (response?.data.success) {
          setloading(false);
          setShareModalVisible(false)
          setIsNodeShared(false);

          setSelectedGroup([]);
          setSelectedTeam([]);
          setSelectedEmployee([]);

          props.getMyFolders();
        }
        else {
          setloading(false);
          setCreateError(response?.data.message)
        }

      }
      else {
        if (selectedGroup.length || selectedTeam.length || selectedEmployee.length || sharedWithEntireWorkspace == true) {
          setloading(true);
          const dataToshare = {
            "share":
            {
              "App\\Employee": selectedEmployee,
              "App\\Team": selectedTeam,
              "App\\Group": selectedGroup
            },
            "workspace": sharedWithEntireWorkspace == true ? 1 : 0,
          }


          const nodeId = nodeIdToshare;
          const response = await new ApiHandler().shareFolder(nodeId, dataToshare);

          if (response?.data.success) {
            setloading(false);
            setShareModalVisible(false)
            setIsNodeShared(false);

            setSelectedGroup([]);
            setSelectedTeam([]);
            setSelectedEmployee([]);

            props.getMyFolders();
          }
          else {
            setloading(false);
            setCreateError(response?.data.message)
          }
        }
        else {
          setCreateError('Please Select Employee Team or Group')
        }
      }

    } catch (error) {
      // Toast.show({
      //   type: 'error',
      //   position: 'bottom',
      //   text1: error,
      // })
    }
  }


  const shareFolderAsyncOld = async (id) => {
    try {
      setCreateError('')
      if (selectedGroup.length || selectedTeam.length || selectedEmployee.length) {
        console.log('selectedGroup', selectedGroup)
        console.log('selectedTeam', selectedTeam)
        console.log('selectedEmployee', selectedEmployee)
        console.log('nodeIdToshare', nodeIdToshare)
        setloading(true);

        const dataToshare = {
          "share":
          {
            "App\\Employee": selectedEmployee,
            "App\\Team": selectedTeam,
            "App\\Group": selectedGroup
          }
        }
        const nodeId = nodeIdToshare;
        const response = await new ApiHandler().shareFolder(nodeId, dataToshare);
        console.log(response)
        if (response?.data.success) {
          setloading(false);
          setShareModalVisible(false)
          props.getMyFolders();
        }
        else {
          setloading(false);
          setCreateError(response?.data.message)
        }
      }
      else {
        setCreateError('Please Select Employee Team or Group')
      }


    } catch (error) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }


  const handleDownloadFile = (item) => {
    console.log(item)
    console.log(apiUrl + '/' + item.link)
    var fileName = item.link.substring(item.link.lastIndexOf('/') + 1);
    let fileExtension = item.link.substr(item.link.lastIndexOf('.') + 1);
    console.log('fileName', fileName)
    console.log('fileExtension', fileExtension)
    // const uri = apiUrl+'/'+item.link
    const uri = item.link
    let fileUri = FileSystem.documentDirectory + fileName;
    FileSystem.downloadAsync(uri, fileUri)
      .then(({ uri }) => {
        saveFile(uri);
      })
      .catch(error => {
        console.error(error);
      })
  }



  const saveFile = async (fileUri) => {
    await MediaLibrary.saveToLibraryAsync(fileUri)
    // const asset = await MediaLibrary.createAssetAsync(fileUri)
    // await MediaLibrary.createAlbumAsync("Download", asset, false)
    Toast.show({
      type: 'success',
      position: 'bottom',
      text1: 'File Downloaded',
      bottomOffset: 50
    })
    // const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
    // if (status === "granted") {
    //     const asset = await MediaLibrary.createAssetAsync(fileUri)
    //     await MediaLibrary.createAlbumAsync("Download", asset, false)
    // }
  }

  const handleDownloadFileIOS = async (item) => {
    console.log(item)
    console.log(apiUrl + '/' + item.link)
    var fileName = item.link.substring(item.link.lastIndexOf('/') + 1);
    let fileExtension = item.link.substr(item.link.lastIndexOf('.') + 1);
    console.log('fileName', fileName)
    console.log('fileExtension', fileExtension)
    let name = fileName.split('.')[0];
    // const uri = apiUrl+'/'+item.link
    const uri = item.link
    let fileUri = FileSystem.cacheDirectory + fileName;
    FileSystem.downloadAsync(uri, fileUri)
      .then(async ({ uri }) => {
        console.log(uri)
        let fileUriPath = FileSystem.documentDirectory + fileName;
        console.log('fileUriPath', fileUriPath)
        FileSystem.copyAsync({
          from: uri,
          to: fileUriPath
        }).then(() => {
          Toast.show({
            type: 'success',
            position: 'bottom',
            text1: 'File Downloaded',
            bottomOffset: 50
          })
        }).catch((error) => {
          console.log(error);
          Toast.show({
            type: 'error',
            position: 'bottom',
            text1: 'File Downloaded failed',
            bottomOffset: 50
          })
        });
        // const fileString = await FileSystem.readAsStringAsync(uri, { encoding: FileSystem.EncodingType.Base64 });
        // // const newFile = await StorageAccessFramework.createFileAsync(
        // //   uri,
        // //   name,
        // //   fileExtension
        // // );
        // await FileSystem.writeAsStringAsync(uri, fileString, { encoding: FileSystem.EncodingType.Base64 });

        // saveFileAsync(uri);
      })
      .catch(error => {
        console.error(error);
      })
  }

  const saveFileAsync = async (file_uri) => {
    try {
      const res = await MediaLibrary.requestPermissionsAsync();
      if (res.granted) {
        let file = await MediaLibrary.createAssetAsync(file_uri); //breaks down in this place 
        const folder = await MediaLibrary.getAlbumAsync('Download');
        if (folder) {
          await MediaLibrary.addAssetsToAlbumAsync([file], folder, false);
        } else {
          await MediaLibrary.createAlbumAsync('Download', file, false);
        }
        Toast.show({
          type: 'success',
          position: 'bottom',
          text1: 'File Downloaded',
          bottomOffset: 50
        })
      }
      else {
        Toast.show({
          type: 'error',
          position: 'bottom',
          text1: 'Permission Denied',
          bottomOffset: 50
        })
      }

    } catch (error) {
      console.log('ERR: saveFileAsync', error);
      //throw error;
    }
  }

  const saveIOs = async (fileUri) => {

    try {
      await MediaLibrary.saveToLibraryAsync(fileUri)
      // const asset = await MediaLibrary.createAssetAsync(fileUri)
      // await MediaLibrary.createAlbumAsync("Download", asset, false)
      Toast.show({
        type: 'success',
        position: 'bottom',
        text1: 'File Downloaded',
        bottomOffset: 50
      })

    } catch (e) {
      console.log(e)
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: 'Error downloading file',
        bottomOffset: 50
      })
    }
  }

  const PreviewItem = ({ item }) => (
    <View style={{ marginLeft: -10, marginBottom: 15, flexDirection: 'row', paddingVertical: 10, alignItems: 'center' }}>
      {item.is_shared
        ? item.type == 'folder' ? <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="folder-account" />
          : item.type == 'file-image' ? <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="image" />
            : <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="file" />
        : item.type == 'folder' ? <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="folder" />
          : item.type == 'file-image' ? <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="image" />
            : item.type == 'file-pdf' ? <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="file-pdf" /> : <Avatar.Icon {...props} color={'#000'} style={{ backgroundColor: 'transparent', display: "flex", justifyContent: 'flex-start' }} height={40} icon="file" />}
      <Text style={{ fontSize: 16 }}>{item.name.length < 25
        ? `${item.name}`
        : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}</Text>
    </View>
  )

  const copyToClipBoard = (link) => Clipboard.setString(link)

  return (
    <>
      <ProgressBar indeterminate={true} visible={loading} />
      <Menu onOpen={() => props.onOpen && props.onOpen()} renderer={SlideInMenu} backHandler={false} onSelect={value => console.log(value)}>
        <MenuTrigger customStyles={{ triggerTouchable: { activeOpacity: 2 } }}>
          <Icon
            color={'#000'}
            icon={'horizontalKebab'}
            width={18}
            height={18}
          />
        </MenuTrigger>

        <MenuOptions optionsContainerStyle={styles.boxWithShadow}>
          <PreviewItem item={props.item} />


          {props.item.owner_id == props.auth.member.id &&
            <MenuOption value={props.item.id} onSelect={(value) => handleMoveToFolder(value)} style={{ padding: 10 }}>
              <View style={{ flexDirection: "row", alignItems: 'center' }}>
                {/* <View style={{flex:0.3}}/> */}
                <View style={{ flex: 0.1 }}>
                  <Icon
                    color={'#000'}
                    icon={'movedto'}
                    width={24}
                    height={24}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.9 }}>
                  <Text style={{ fontSize: 16 }}>Move to   </Text>
                </View>
                {/* <View style={{flex:0.3}}/> */}
              </View>
            </MenuOption>
          }

          {props.item.owner_id == props.auth.member.id &&
            <MenuOption style={{ padding: 10 }} value={props.item} onSelect={(value) => handleRenameFolder(value)} >
              <View style={{ flexDirection: "row", alignItems: 'center' }}>
                <View style={{ flex: 0.1 }}>
                  <Icon
                    color={'#000'}
                    icon={'rename'}
                    width={24}
                    height={24}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.9 }}>
                  <Text style={{ fontSize: 16 }}>Rename   </Text>
                </View>
              </View>
            </MenuOption>
          }

          {props.item.type != 'folder' &&
            <MenuOption style={{ padding: 10 }} value={props.item} onSelect={(value) => Platform.OS === 'ios' ? handleDownloadFileIOS(value) : handleDownloadFile(value)}  >
              <View style={{ flexDirection: "row", alignItems: 'center' }}>
                <View style={{ flex: 0.1 }}>
                  <Icon
                    color={'#000'}
                    icon={'downloadicon'}
                    width={24}
                    height={24}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.9 }}>
                  <Text style={{ fontSize: 16 }}>Download   </Text>
                </View>
              </View>
            </MenuOption>
          }

          {props.item.owner_id == props.auth.member.id &&
            <MenuOption style={{ padding: 10 }} value={props.item} onSelect={(val) => deleteFolderConfirm(val)}>
              <View style={{ flexDirection: "row", alignItems: 'center' }}>
                <View style={{ flex: 0.1 }}>
                  <Icon
                    color={'#000'}
                    icon={'deleteicon'}
                    width={24}
                    height={24}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.9 }}>
                  <Text style={{ fontSize: 16 }}>Delete   </Text>
                </View>
              </View>
            </MenuOption>
          }
          {props.item.owner_id == props.auth.member.id &&
            <MenuOption style={{ padding: 10 }} value={props.item} onSelect={(val) => getShareModalAsync(val)}  >
              <View style={{ flexDirection: "row", alignItems: 'center' }}>
                <View style={{ flex: 0.1 }}>
                  <Icon
                    color={'#000'}
                    icon={'shareicon'}
                    width={24}
                    height={24}
                    style={{ marginTop: 15 }}
                  />
                </View>
                <View style={{ flex: 0.9 }}>
                  <Text style={{ fontSize: 16 }}>Share   </Text>
                </View>
              </View>
            </MenuOption>
          }
          <MenuOption style={{ padding: 10 }} value={props.item.id} onSelect={(val) => getFolderInfoAsync(val)}>
            <View style={{ flexDirection: "row", alignItems: 'center' }}>
              <View style={{ flex: 0.1 }}>
                <Icon
                  color={'#000'}
                  icon={'informationicon'}
                  width={24}
                  height={24}
                  style={{ marginTop: 15 }}
                />
              </View>
              <View style={{ flex: 0.9 }}>
                <Text style={{ fontSize: 16 }}>Information   </Text>
              </View>
            </View>
          </MenuOption>
        </MenuOptions>
      </Menu>

      {/* .............Models.......... */}
      <SimpleModal isVisible={isRenameModalVisible}>
        <CreateFolderContainer style={{ height: isMobile ? Platform.OS === 'ios' ? height - 580 : height - 450 : '200px' }}>
          <HeaderCreateFolder>
            <CloseButtonCreateFolder style={{ right: 0 }} onPress={() => setRenameModalVisible(false)}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleCreateFolder><Text>{nodeVal && nodeVal.type == 'folder' ? 'Rename Folder' : 'Rename File'}</Text></HeaderTitleCreateFolder>
          </HeaderCreateFolder>
          <TextInput
            style={{ backgroundColor: '#fff', paddingHorizontal: 0, paddingVertical: 0 }}
            placeholder="Folder name"
            onChangeText={(v) => setFolderName(v)}
            value={foldername}
          />
          <Text style={{ color: "red" }}>{errorTextCreate}</Text>
          <View style={styles.createfolderfooter}>
            <ButtonPrimary onPress={() => renameFolder()} customStyles={{ width: '100%', marginBottom: 40, backgroundColor: 'black' }} label="Rename" />
          </View>

        </CreateFolderContainer>


      </SimpleModal>

      <SimpleModal isVisible={isDeleteModalVisible}>
        <CreateFolderContainer style={{ height: isMobile ? Platform.OS === 'ios' ? height - 580 : height - 450 : '200px' }}>
          <HeaderCreateFolder>
            <CloseButtonCreateFolder style={{ right: 0 }} onPress={() => setDeleteModalVisible(false)}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleCreateFolder><Text>{deleteNode && deleteNode.type == 'folder' ? 'Delete Folder' : 'Delete File'}</Text></HeaderTitleCreateFolder>
            <HeaderSubTitleCreateFolder><Text style={{ width: '100%' }}>{deleteNode && deleteNode.type == 'folder' ? 'Are you sure you want to delete this folder?' : 'Are you sure you want to delete this file?'}</Text></HeaderSubTitleCreateFolder>
          </HeaderCreateFolder>
          <Text style={{ color: "red" }}>{errorTextCreate}</Text>
          <View style={styles.createfolderfooter}>
            <ButtonPrimary onPress={() => deleteFolder()} customStyles={{ width: '100%', marginBottom: 40, backgroundColor: 'black' }} label="Delete" />
          </View>

        </CreateFolderContainer>


      </SimpleModal>

      <SimpleModal isVisible={isInfoModalVisible}>
        <CreateFolderContainer style={{ width: width, height: Platform.OS === 'ios' ? height < 700 ? height - 400 : height - 580 : height - 450, zIndex: 50 }}>
          <HeaderCreateFolder>
            <CloseButtonCreateFolder style={{ right: 0 }} onPress={() => setInfoModalVisible(false)}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleInfoFolder><Text style={{ textAlign: 'left', width: '100%' }}>Information</Text></HeaderTitleInfoFolder>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Created: {getFolderInfo && formatDate(getFolderInfo.created_at)}</Text></HeaderSubTitleInfo>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Last updated: {getFolderInfo && formatDate(getFolderInfo.last_modified_at)}</Text></HeaderSubTitleInfo>
            <HeaderSubTitleInfo><Text style={{ width: '100%' }}>Owner: {getFolderInfo && getFolderInfo.owner.first_name} {getFolderInfo && getFolderInfo.owner.last_name}</Text></HeaderSubTitleInfo>
            {props.item.type != 'folder' &&
              // <HeaderSubTitleInfo>
              <View style={{ flexDirection: "row", paddingTop: 10, paddingLeft: 5, width: '100%' }}>
                <View style={{ flex: 1 }}>
                  <View style={{ width: '100%' }}>
                    <Text style={{ flexDirection: 'column' }}>
                      <TouchableOpacity ><Text style={{ color: '#3B3B3B' }}> File:</Text></TouchableOpacity>  <TouchableOpacity onPress={() => Linking.openURL(props.item.link)} style={{ marginTop: Platform.OS == 'android' ? -3 : 0, padding: 0 }}><Text  >{props.item && props.item.name.length <= 10 ? `${props.item.name}` : `${props.item.name.substring(0, 10)}(..)`}.{props.item && props.item.extension}</Text></TouchableOpacity>      <TouchableOpacity onPress={() => copyToClipBoard(props.item.link)} style={{ flexDirection: 'column', marginTop: Platform.OS == 'android' ? -2 : 0, padding: 0 }}><Icon color={'#000'} icon={'copy'} width={15} height={20} style={{ marginTop: 5 }} /></TouchableOpacity>
                    </Text>
                  </View>
                </View>
              </View>

              // </HeaderSubTitleInfo>
            }
          </HeaderCreateFolder>

        </CreateFolderContainer>


      </SimpleModal>

      <SimpleModal isVisible={isMoveToModalVisible} >
        <CreateFolderContainer style={{ height: Platform.OS === 'ios' ? height - 250 : height - 300 }}>
          <ProgressBar indeterminate={true} visible={loading} />
          <HeaderCreateFolder>
            <CloseButtonCreateFolder style={{ right: 0 }} onPress={() => { setMoveToModalVisible(false); setHighlighted(null); setSelectedFolderToMove(null); }}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleCreateFolder><Text>Move to</Text></HeaderTitleCreateFolder>
            <ScrollView style={{ width: '100%', height: isMobile ? Platform.OS === 'ios' ? height - 420 : height - 480 : '200px', marginTop: 20, paddingVertical: 5 }}>
              <FlatList
                data={getFoldersToMove}
                ListHeaderComponent={() => (getFoldersToMove && !getFoldersToMove.length ?
                  <Text style={{ textAlign: 'center', padding: 50, backgroundColor: '#fff', marginRight: 20 }}>No folders found</Text>
                  : null)}
                keyExtractor={item => item.id}
                contentContainerStyle={{
                  flex: 1,
                  marginBottom: 10,
                  justifyContent: 'center'

                }}
                renderItem={({ item, index, separators }) => (
                  <TouchableHighlight
                    key={item.id}
                    style={{ backgroundColor: isHighlighted && isHighlighted.id == item.id ? 'lightgrey' : 'transparent', marginBottom: 10, color: '#fff' }}
                    onPress={() => isHighlighted && isHighlighted.id == item.id ? setHighlighted(null) : setHighlighted({ id: item.id })}

                  >
                    <View >
                      {/* {item.is_shared
                        ? <Chip selectedColor={'#000'} textStyle={{color:'#000'}} style={{color:isHighlighted && isHighlighted.id==item.id?'#000':'#000',backgroundColor:'transparent',borderRadius:0}} icon="folder-account" onPress={() => console.log('Pressed')}>{item.name}</Chip>
                        : <Chip selectedColor={'#000'} textStyle={{color:'#000'}} style={{color:isHighlighted && isHighlighted.id==item.id?'#000':'#000',backgroundColor:'transparent',borderRadius:0}} icon="folder" onPress={() => console.log('Pressed')}>{item.name}</Chip>
                        } */}


                      {item.is_shared
                        ? <Text style={{ color: isHighlighted && isHighlighted.id == item.id ? '#000' : '#000' }}><Avatar.Icon color={'#000'} style={{ backgroundColor: 'transparent' }} size={25} height={18} icon="folder-account" />  <Text >{item.name}</Text></Text>
                        : <Text style={{ color: isHighlighted && isHighlighted.id == item.id ? '#000' : '#000' }}><Avatar.Icon color={'#000'} style={{ backgroundColor: 'transparent' }} size={25} height={18} icon="folder" />   <Text >{item.name}</Text></Text>
                      }
                    </View>
                  </TouchableHighlight>
                )}
              />
            </ScrollView>
          </HeaderCreateFolder>
          <Text style={{ color: "red" }}>{errorTextCreate}</Text>
          <View style={styles.createfolderfooter}>
            {/* <TouchableOpacity style={styles.createFolderCancel}  onPress={() =>{setMoveToModalVisible(false);setHighlighted(null);setSelectedFolderToMove(null);}} >
            <Text style={{ fontSize: 16,color:'#000' }}>Cancel</Text>
          </TouchableOpacity> */}

            <ButtonPrimary onPress={() => moveFolderAsync()} customStyles={{ width: '100%', marginBottom: 40, backgroundColor: 'black' }} label="Move" />

          </View>
        </CreateFolderContainer>
      </SimpleModal>




      <SimpleModal isVisible={isShareModalVisible} >
        <CreateFolderContainer style={{ width: width, height: height - resizeModelHeight, zIndex: 50 }}>
          <ProgressBar indeterminate={true} visible={loading} />
          <HeaderCreateFolder>
            <CloseButtonCreateFolder onPress={() => { setShareModalVisible(false); }} style={{ right: 10 }}>
              <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
            </CloseButtonCreateFolder>
            <HeaderTitleCreateFolder><Text>Sharing</Text></HeaderTitleCreateFolder>
            <HeaderSubTitleCreateFolder style={{ textAlign: 'center', color: '#6D6D6D' }}><Text style={{ width: '100%', textAlign: 'center' }}>Manage sharing with Members, Teams, and Groups.</Text></HeaderSubTitleCreateFolder>

          </HeaderCreateFolder>

          <View
            style={{
              flexDirection: "row",
              paddingBottom: 10,
              marginTop: 20,
            }}
          >
            <Tabs horizontal={true} style={{ height: isMobile ? 30 : 20, alignItems: 'center', justifyContent: 'center', flex: 1 }}>

              <Tab onPress={() => { setTab('group') }} >
                <TabTitle selected={tab === 'group'} isMobile={true} >Group</TabTitle>
                <View style={{ backgroundColor: tab === 'group' ? '#F05343' : null, marginLeft: 25 }}>
                </View>
              </Tab>

              <Tab onPress={() => { setTab('team') }}>
                <TabTitle selected={tab === 'team'} isMobile={true} >Team</TabTitle>
                <View style={{ height: 2, backgroundColor: tab === 'team' ? '#F05343' : null, marginLeft: 25 }}></View>
              </Tab>

              <Tab onPress={() => { setTab('memeber') }}>
                <TabTitle selected={tab === 'memeber'} isMobile={true} >Member</TabTitle>
                <View style={{ height: 2, backgroundColor: tab === 'memeber' ? '#F05343' : null, marginLeft: 25 }}></View>
              </Tab>

            </Tabs>
          </View>
          <ScrollView
            nestedScrollEnabled
            contentContainerStyle={{ flexGrow: 1 }}
          >
            <View
              style={{
                flexDirection: "row",
                paddingBottom: 20
              }}
            >
              {tab === 'group' &&
                // <View  style={{alignItems: 'center',justifyContent:'center',flex:1,zIndex:100}}>
                <View style={dropdownStyles.container}>
                  <SearchableDropdown
                    items={communityGroup}
                    selectedItems={selectedGroup}
                    defaultIndex={2}
                    onRemoveItem={item => {
                      const items = selectedGroup.filter(file => file.id !== item.id);
                      setSelectedGroup(items);
                    }}
                    onItemSelect={item => {
                      const items = selectedGroup;
                      items.push(item);
                      setSelectedGroup(items);
                      if (selectedGroup.length > 5) setResizeModelHeight(50)
                      else if (selectedTeam.length > 5) setResizeModelHeight(50)
                      else if (selectedEmployee.length > 5) setResizeModelHeight(50)
                      else setResizeModelHeight(200)
                    }}
                    chip={true}
                    multi
                    resetValue={false}
                    itemStyle={{
                      padding: 10,
                      backgroundColor: '#fff',
                      borderColor: '#bbb',
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                    }}
                    listProps={
                      {
                        nestedScrollEnabled: true,
                      }
                    }
                    textInputProps={
                      {
                        placeholder: "Search Group",
                        underlineColorAndroid: "transparent",
                        style: {
                          padding: 12,
                          borderWidth: 1,
                          width: 350,
                          borderColor: '#ccc',
                          borderRadius: 5,
                        },
                        // onTextChange: text => alert(text)
                      }
                    }
                  />
                </View>

              }

              {tab === 'team' &&
                // <View  style={{alignItems: 'center',justifyContent:'center',flex:1,width:width-100,zIndex:100}}>
                <View style={dropdownStyles.container}>
                  <SearchableDropdown
                    items={communityTeam}
                    selectedItems={selectedTeam}
                    defaultIndex={2}
                    onRemoveItem={item => {
                      const items = selectedTeam.filter(file => file.id !== item.id);
                      setSelectedTeam(items);
                    }}
                    onItemSelect={item => {
                      const items = selectedTeam;
                      items.push(item);
                      setSelectedTeam(items);
                      if (selectedGroup.length > 5) setResizeModelHeight(50)
                      else if (selectedTeam.length > 5) setResizeModelHeight(50)
                      else if (selectedEmployee.length > 5) setResizeModelHeight(50)
                      else setResizeModelHeight(200)
                    }}
                    chip={true}
                    multi
                    resetValue={false}
                    itemStyle={{
                      padding: 10,
                      backgroundColor: '#fff',
                      borderColor: '#bbb',
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                    }}
                    listProps={
                      {
                        nestedScrollEnabled: true,
                      }
                    }
                    textInputProps={
                      {
                        placeholder: "Search Team",
                        underlineColorAndroid: "transparent",
                        style: {
                          padding: 12,
                          borderWidth: 1,
                          width: 350,
                          borderColor: '#ccc',
                          borderRadius: 5,
                        },
                        // onTextChange: text => alert(text)
                      }
                    }
                  />
                </View>
              }

              {tab === 'memeber' &&
                // <View  style={{alignItems: 'center',justifyContent:'center',flex:1,width:width-100,zIndex:100}}>
                <View style={dropdownStyles.container}>
                  <SearchableDropdown
                    items={communityEmployee.map(employee => ({ ...employee, name: formatUserName(employee.first_name, employee.last_name) }))}
                    selectedItems={selectedEmployee}
                    defaultIndex={2}
                    onRemoveItem={item => {
                      const items = selectedEmployee.filter(file => file.id !== item.id);
                      setSelectedEmployee(items);
                    }}
                    onItemSelect={item => {
                      const items = selectedEmployee;
                      items.push(item);
                      setSelectedEmployee(items);

                      if (selectedGroup.length > 5) setResizeModelHeight(50)
                      else if (selectedTeam.length > 5) setResizeModelHeight(50)
                      else if (selectedEmployee.length > 5) setResizeModelHeight(50)
                      else setResizeModelHeight(200)
                    }}
                    chip={true}
                    multi
                    resetValue={false}
                    itemStyle={{
                      padding: 10,
                      backgroundColor: '#fff',
                      borderColor: '#bbb',
                      borderLeftWidth: 1,
                      borderRightWidth: 1,
                    }}
                    listProps={
                      {
                        nestedScrollEnabled: true,
                      }
                    }
                    textInputProps={
                      {
                        placeholder: "Search Group",
                        underlineColorAndroid: "transparent",
                        style: {
                          padding: 12,
                          borderWidth: 1,
                          width: 350,
                          borderColor: '#ccc',
                          borderRadius: 5,
                        },
                        // onTextChange: text => alert(text)
                      }
                    }
                  />
                </View>
              }

            </View>
          </ScrollView>

          <Text style={{ color: "red", textAlign: 'center' }}>{errorTextCreate}</Text>
          <View style={{ width: '100%', padding: 0, marginBottom: 40, }}>
            <CheckBox
              center={true}
              checkedColor={'#000'}
              containerStyle={{ backgroundColor: 'transparent', borderWidth: 0, padding: 0, margin: 0 }}
              wrapperStyle={{ padding: 0, margin: 0 }}
              textStyle={{ marginTop: -4 }}
              //containerStyle={{width:'100%',backgroundColor:'transparent',borderWidth:0,padding:0,margin:0,marginLeft:-1}}
              // wrapperStyle={{padding:0,width:'100%',margin:0}}
              title="Share with entire workspace"
              checked={sharedWithEntireWorkspace}
              onPress={() => setSharedWithEntireWorkspace(!sharedWithEntireWorkspace)}
            />
          </View>
          <View style={[styles.createfolderfooter]}>

            <ButtonPrimary onPress={() => shareFolderAsync()} customStyles={{ width: '100%', marginBottom: 40, backgroundColor: 'black' }} label="Send" />
          </View>
        </CreateFolderContainer>
      </SimpleModal>

    </>
  )
}

export default SideMenusMobile

const dropdownStyles = StyleSheet.create({
  container: {
    padding: 16, width: '100%',
    alignItems: 'center', justifyContent: 'center', flex: 1, zIndex: 999
  },
  dropdown: {
    height: 50,
    backgroundColor: 'transparent',
    borderBottomColor: 'gray',
    borderBottomWidth: 0.5,
    width: '100%',
    zIndex: 1001,
  },
  placeholderStyle: {
    fontSize: 16,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    zIndex: 1000,
    margin: 0,
    height: 25,
    fontSize: 16,
  },
  icon: {
    marginRight: 5,
  },
  selectedStyle: {
    backgroundColor: '#F05343',
    borderColor: '#F05343',
    color: '#fff',
    width: '100%',
    zIndex: 9999,
    //borderRadius: 12,
  },
  selectedTextStyle: {
    fontSize: 14,
    color: '#fff',
    zIndex: 9999
  },
  icon: {
    marginRight: 5,
  },
  item: {
    padding: 17,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 9999
  },
  textItem: {
    flex: 1,
    fontSize: 16,
    color: '#000'
  },
});

