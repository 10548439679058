import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function Copy(props) {
    return (
        <Svg width="18" height="20" viewBox="0 0 18 20" fill="none" {...props}>
            <Path d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM17 4H6C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V6C19 4.9 18.1 4 17 4ZM17 20H6V6H17V20Z" fill={props.color || '#323232'} />

        </Svg>
    )
}

Copy.propTypes = Svg.propTypes