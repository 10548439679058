import React, { useEffect, useState } from 'react'
import {
  ScrollView,
  SafeAreaView,
  View,
  Text,
  FlatList,
  TouchableOpacity,
  Platform,
  RefreshControl,
} from 'react-native'
import { ProgressBar } from 'react-native-paper'

import { useDispatch, useSelector } from 'react-redux'
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from '@react-navigation/native'

import ApiHandler from '@/api/ApiHandler'
import { Headline2, Headline6 } from '@/styles/fonts'
import Icon from '@/components/Icon'
import { isMobile } from '@/styles'

import { notificationTypes } from './notificationTypes'
import { Container, styles } from './styles'
import {
  fileSharedWithYou,
  invitedToAGroup,
  invitedToATeam,
  newArticlePublished,
  newCoursePublished,
  newEventPublished,
  newCalendarInvite,
  calendarInviteUpdated,
  newPostComment,
  newCommentLike,
  newPostLike,
  newWorkspaceMember,
  otherActivity,
  calendarEntryDeleted
} from './notifications'

import * as brandSelectors from '@/redux/selectors/branding'
import { actions as notifActions } from '@/redux/reducers/notifications'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

let unreadCurrentPage = 1
let unreadLastPage = 1

let readCurrentPage = 1
let readLastPage = 1

const Notifications = () => {
  const branding = useSelector(brandSelectors.getSettings)
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const dispatch = useDispatch()
  const navigation = useNavigation()

  const [loading, setLoading] = useState(false)
  const [unReadNotifs, setUnReadNotifs] = useState([])
  const [readNotifs, setReadNotifs] = useState([])
  const [refreshing, setRefreshing] = useState(false)

  const wait = (timeout) => {
    return new Promise((resolve) => setTimeout(resolve, timeout))
  }

  const onRefresh = React.useCallback(() => {
    setRefreshing(true)
    dispatch(notifActions.getReadNotifs({}, () => { }))
    dispatch(notifActions.getUnReadNotifs({}, () => { }))

    wait(1000).then(() => setRefreshing(false))
  }, [])

  // const notificationState = useSelector(state => state.notifications);

  // useEffect(() => {
  // dispatch(notifActions.getReadNotifs({}, (err) => { console.log('---err', err) }))
  // dispatch(notifActions.getUnReadNotifs({}, (err) => { console.log('---err', err) }))
  // }, [])

  useFocusEffect(
    React.useCallback(() => {
      setLoading(true)

      unreadCurrentPage = 1
      readCurrentPage = 1

      fetchUnreadNotifs()
      fetchReadNotifs()
    }, [])
  )

  const markNotificationsRead = async () => {
    try {
      await new ApiHandler().markNotiicationsAsRead()
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }
  const fetchUnreadNotifs = async () => {
    try {
      const response = await new ApiHandler().getUnreadNotiications({
        page: unreadCurrentPage,
      })
      if (response?.data) {
        unreadLastPage = response?.data?.last_page

        setUnReadNotifs([...unReadNotifs, ...response?.data?.data])
        if (response?.data?.total > 0) {
          markNotificationsRead()
        }
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchReadNotifs = async () => {
    try {
      const response = await new ApiHandler().getReadNotiications({
        page: readCurrentPage,
      })
      if (response?.data) {
        readLastPage = response?.data?.last_page

        setReadNotifs([...readNotifs, ...response?.data?.data])
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setLoading(false)
    }
  }

  const renderUnNotificationList = () => {
    return (
      <>
        <FlatList
          ListHeaderComponent={() => {
            if (unReadNotifs.length > 0) {
              return (
                <View style={{ marginBottom: 10 }}>
                  <Headline6>{'New'}</Headline6>
                </View>
              )
            }
            return <></>
          }}
          data={unReadNotifs}
          keyExtractor={(item) => item.id}
          renderItem={_renderUnReadNotification}
          contentContainerStyle={styles.flatListContent}
        />
        {unReadNotifs.length > 0 && unreadCurrentPage < unreadLastPage && (
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: 'WorkSans_500Medium',
              fontSize: 13,
              lineHeight: 18,
              color: color_scheme_primary_button_background
                ? color_scheme_primary_button_background
                : '#de614e',
            }}
            onPress={() => {
              unreadCurrentPage = unreadCurrentPage + 1
              fetchUnreadNotifs()
            }}
          >
            {'See More'}
          </Text>
        )}
      </>
    )
  }

  const _renderUnReadNotification = ({ item, index }) => {
    const showTopBorderRadius = index === 0

    const showBottomBorderRadius = index === unReadNotifs.length - 1

    return renderNotificationContainer(
      item,
      showTopBorderRadius,
      showBottomBorderRadius
    )
  }

  const renderNotificationList = () => {
    return (
      <>
        <FlatList
          ListHeaderComponent={() => {
            if (readNotifs.length > 0) {
              return (
                <View style={{ marginBottom: 10 }}>
                  <Headline6>{'Archived'}</Headline6>
                </View>
              )
            }
            return <></>
          }}
          data={readNotifs}
          keyExtractor={(item) => item.id}
          renderItem={_renderReadNotification}
          contentContainerStyle={styles.flatListContent}
        />
        {readNotifs.length > 0 && readCurrentPage < readLastPage && (
          <Text
            style={{
              alignSelf: 'center',
              fontFamily: 'WorkSans_500Medium',
              fontSize: 13,
              lineHeight: 18,
              color: color_scheme_primary_button_background
                ? color_scheme_primary_button_background
                : '#de614e',
            }}
            onPress={() => {
              readCurrentPage = readCurrentPage + 1
              fetchReadNotifs()
            }}
          >
            {'See More'}
          </Text>
        )}
      </>
    )
  }

  const _renderReadNotification = ({ item, index }) => {
    const showTopBorderRadius = index === 0

    const showBottomBorderRadius = index === readNotifs.length - 1

    return renderNotificationContainer(
      item,
      showTopBorderRadius,
      showBottomBorderRadius
    )
  }

  const notificationRouter = (action) => {
    // action = {
    //     'type': 'ViewMessenger',
    //     'target': 6
    // }
    switch (action?.type) {

      case 'ViewFeedPost':
        if (action?.target && action.target !== undefined) {
          setLoading(true)
          navigation.navigate("CommentPost", {
            post_id: action.target,
            contentType: "App\\Post",
            navigatedFrom: "notifications"
          })
        } else {
          navigation.navigate('Home')
        }
        break
      case 'ViewMemberProfile':
        if (action?.target) {
          console.log('**** TYPE: ViewMemberProfile', action.target)
          navigation.navigate('Profile', {
            id: action.target,
          })
        } else {
          navigation.navigate('Settings')
        }
        break
      case 'ViewArticle':
        if (action?.target) {
          console.log('**** TYPE: ViewArticle', action.target)
          navigation.navigate('ViewArticle', {
            id: action.target,
          })
        } else {
          navigation.navigate('Learning')
        }
        break
      case 'ViewCourse':
        if (action?.target) {
          console.log('**** TYPE: ViewCourse', action.target)
          navigation.navigate('ViewCourse', {
            id: action.target,
          })
        } else {
          navigation.navigate('Learning')
        }
        break
      case 'ViewEvent':
        if (action?.target) {
          console.log('**** TYPE: ViewEvent', action.target)
          navigation.navigate('ViewEvent', {
            id: action.target,
          })
        } else {
          navigation.navigate('Community')
        }
        break
      case 'ViewTeam':
        if (action?.target) {
          console.log('**** TYPE: ViewTeam', action.target)
          navigation.navigate('TeamPage', {
            id: action.target,
          })
        } else {
          navigation.navigate('Community')
        }
        break
      case 'ViewGroup':

        if (action?.target) {
          console.log('**** TYPE: ViewGroup', action.target)
          navigation.navigate('GroupPage', {
            id: action.target,
          })
        } else {
          navigation.navigate('Community')
        }
        break
      case 'ViewMessenger':
        console.log('**** TYPE: ViewMessenger', action)
        navigation.navigate('chat')
        break
      case 'ViewCalendarEntry':
        if (action?.target) {
          console.log('**** TYPE: ViewCalendarEntry', action.target)
          navigation.navigate('EventDetail', {
            id: action.target,
          });
        } else {
          navigation.navigate('Calendar')
        }
        break
      default:
        // ViewFeed action type
        navigation.navigate('Home')
    }
  }

  const renderNotificationContainer = (
    item,
    showTopBorderRadius,
    showBottomBorderRadius
  ) => {
    return (
      <TouchableOpacity
        onPress={() => notificationRouter(item?.data?.action)}
        key={item.id}
        style={{
          paddingVertical: 20,
          paddingHorizontal: 14,
          backgroundColor: 'white',

          borderTopEndRadius: showTopBorderRadius ? 6 : 0,
          borderTopStartRadius: showTopBorderRadius ? 6 : 0,

          borderBottomEndRadius: showBottomBorderRadius ? 6 : 0,
          borderBottomStartRadius: showBottomBorderRadius ? 6 : 0,

          borderBottomColor: !showBottomBorderRadius
            ? '#00000055'
            : 'transparent',
          borderBottomWidth: 1,
        }}
      >
        {renderNotificationType(item)}
      </TouchableOpacity>
    )
  }

  const renderNotificationType = (item) => {
    const { type, data, created_at } = item

    if (type === notificationTypes.fileSharedWithYou) {
      return fileSharedWithYou(data, created_at)
    } else if (type === notificationTypes.invitedToAGroup) {
      return invitedToAGroup(data, created_at)
    } else if (type === notificationTypes.invitedToATeam) {
      return invitedToATeam(data, created_at)
    } else if (type === notificationTypes.newArticlePublished) {
      return newArticlePublished(data, created_at)
    } else if (type === notificationTypes.newCoursePublished) {
      return newCoursePublished(data, created_at)
    } else if (type === notificationTypes.newEventPublished) {
      return newEventPublished(data, created_at)
    } else if (type === notificationTypes.newCalendarInvite) {
      return newCalendarInvite(data, created_at)
    } else if (type === notificationTypes.calendarInviteUpdated) {
      return calendarInviteUpdated(data, created_at)
    } else if (type === notificationTypes.calendarEntryDeleted) {
      return calendarEntryDeleted(data, created_at)
    } else if (type === notificationTypes.newPostComment) {
      return newPostComment(data, created_at)
    } else if (type === notificationTypes.newCommentLike) {
      return newCommentLike(data, created_at)
    } else if (type === notificationTypes.newPostLike) {
      return newPostLike(data, created_at)
    } else if (type === notificationTypes.newWorkspaceMember) {
      return newWorkspaceMember(data, created_at)
    } else {
      return otherActivity(data, created_at)
    }
  }

  return (
    <>
      <PageHeader
        title={'Notifications'}
        icon={'notifications'}
        backEnabled={false}
      />

      <PageContent>
        {renderUnNotificationList()}
        {renderNotificationList()}
      </PageContent>
    </>
  )
}

export default Notifications