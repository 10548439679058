import React, { useEffect, useState } from 'react'
import { isMobile } from '@/styles'
import { ScrollView, View } from 'react-native'
import canAccess from '@/helpers/permissions'
import { useDispatch, useSelector } from 'react-redux'
import * as teamsSelectors from '@/redux/selectors/teams'
import { actions as teamsActions } from '@/redux/reducers/teams'
import { ProgressBar } from 'react-native-paper'
import PropTypes from 'prop-types'
import ContentBlockV1 from '@/components/ContentBlockV1'
import {
  Icon,
  Carousel,
  GroupTeamsCard, Breadcrumb, BackArrow,
} from '@/components'
import theme from '@/styles/themes/black'
import {
  HeadLine,
  SubTitle,
  Container,
  Header,
  LeftIconButton,
  SectionHeader,
  TitleHeader,
  SectionTitle
} from '@/pages/Community/styles/innerPages'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import CreateEditGroup from '@/pages/Community/CreateEditGroup'
import { permissions } from '@/helpers/permissionConstants'
import * as brandSelectors from '@/redux/selectors/branding'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'

const Teams = ({ navigation }) => {
  const dispatch = useDispatch()
  const [isCreateEditVisible, setIsCreateEditVisible] = useState(false)

  const teams = useSelector(teamsSelectors.data)
  const otherTeams = useSelector(teamsSelectors.otherTeams)

  const loading = (teams && teams.loading) || (otherTeams && otherTeams.loading)

  const branding = useSelector(brandSelectors.getSettings);
  const { team } = branding?.taxonomies;

  useEffect(() => {
    dispatch(teamsActions.index({}, (err) => { console.log('err', err) }))
    dispatch(teamsActions.otherTeams({}, (err) => { console.log('err', err) }))
  }, [])

  const teamsNextPage = () => {
    if (teams.loading || !teams.nextPage) {
      return
    }
    dispatch(teamsActions.index({ page: teams.page + 1 }, () => { }))
  }

  const otherTeamsNextPage = () => {
    if (otherTeams.loading || !otherTeams.nextPage) {
      return
    }
    dispatch(teamsActions.otherTeams({ page: otherTeams.page + 1 }, () => { }))
  }

  const expansiveMenuItems = [
    { text: `Create a ${team?.singular}`, onPress: () => { setIsCreateEditVisible(true) } },
  ]

  const breadcrumbs = [
    { name: 'Community', navigation: 'Community' },
    { name: 'Teams' },
  ]

  return (
    <>
      <PageHeader
        title={team?.plural}
        icon={'community'}
        backEnabled={true}
        breadcrumbs={[
          { name: 'Community', navigation: 'Community' },
          { name: 'Teams' },
        ]}
        actions={
          [
            {
              title: 'Create',
              type: 'menu',
              permissions: [
                'CREATE_TEAMS',
              ],
              menu: [
                {
                  title: `Create ${team?.singular}`,
                  icon: 'community',
                  permissions: [
                    'CREATE_TEAMS'
                  ],
                  onPress: () => {
                    setIsCreateEditVisible(true)
                  }
                },
              ]
            }
          ]
        }
      />

      <PageContent>
        <ContentBlockV1
          title={`My ${team?.plural}`}
        >
          <Carousel nextPage={teamsNextPage}>
            {teams?.data?.map((team, key) => <GroupTeamsCard key={key}
              type="teams"
              label="Teams" {...team} />)}
          </Carousel>
        </ContentBlockV1>

        {otherTeams?.data?.length > 0 &&
          <ContentBlockV1
            title={`Other ${team?.plural}`}
          >
            <Carousel nextPage={otherTeamsNextPage}>
              {otherTeams?.data?.map((team, key) => <GroupTeamsCard key={key}
                type="teams"
                label="Teams"
                {...team} />)}
            </Carousel>
          </ContentBlockV1>
        }
      </PageContent>

      <CreateEditGroup
        isVisible={isCreateEditVisible}
        onClose={() => setIsCreateEditVisible(false)}
        type='team'
      />
    </>
  )
}

Teams.propTypes = {
  navigation: PropTypes.shape({
    navigate: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
  })
}

export default Teams
