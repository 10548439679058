import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    ColumnsContainer: {
        flexWrap: 'wrap',

        ...Platform.select({
            ios: {
                flexDirection: "column",
            },
            android: {
                flexDirection: "column",
            },
            web: {
                flexDirection: "row",
            }
        })
    }
});
