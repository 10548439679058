import { all, fork } from 'redux-saga/effects'

import { rootSaga as auth } from './auth'
import { rootSaga as posts } from './posts'
import { rootSaga as profile } from './profile'
import { rootSaga as article } from './article'
import { rootSaga as teams } from './teams'
import { rootSaga as groups } from './groups'
import { rootSaga as employees } from './employees'
import { rootSaga as roles } from './roles'
import { rootSaga as pushNotifications } from './pushNotifications'
import { rootSaga as notifications } from './notifications'
import { rootSaga as learning } from './learning'
import { rootSaga as course } from './course'
import { rootSaga as event } from './event'
import { rootSaga as common } from './common'

function* rootSaga() {
  yield all([
    fork(auth),
    fork(posts),
    fork(profile),
    fork(article),
    fork(teams),
    fork(groups),
    fork(employees),
    fork(roles),
    fork(pushNotifications),
    fork(notifications),
    fork(learning),
    fork(course),
    fork(event),
    fork(common)
  ])
}

export default rootSaga
