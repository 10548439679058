import styled from 'styled-components/native'
import { StyleSheet, Platform, Dimensions } from 'react-native'
import { Headline5, Headline6SemiBold } from '@/styles/fonts'
import { isMobile } from '@/styles'
const { width, height } = Dimensions.get('window')




export const styles = StyleSheet.create({
	flatList: {
		width: '100%',
		marginRight: 20
	},
	flatListContent: {
		flexGrow: 1,
		height: '100%',
		width: '100%',
		marginHorizontal: 20
	}
})
export const MemberContainer = styled.View`
	width: 100%;
	position: relative;
`
export const Card = styled.View`
	justify-content: flex-start;
   	max-height: ${height - 50}px;
	background-color: ${({ theme }) => theme.basics.background_white};
	box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	padding:40px;
`

export const CloseButton = styled.TouchableOpacity`
	align-self: flex-end;
`
export const IconContainer = styled.View`
margin-top:-20px;
align-self:flex-end;
`

export const Title = styled(Headline5)`
	color: ${({ theme }) => theme.basics.black_text};
`

export const TitleText = styled(Headline5)`
	color: ${({ theme }) => theme.basics.black_text};
	padding-vertical:20px;
`

export const ContainerButtons = styled.View`
	align-items: center;
	justify-content: flex-end;
	flex-direction: row;
	padding: 0 20px 20px 0;
	width: 100%;
`

export const Button = styled.TouchableOpacity`
	background-color: ${({ theme, cancel }) => cancel ? theme.basics.background_white : theme.basics.brand};
	align-items: center;
	justify-content: center;
	width: 127px;
	height: 40px;
	margin: 0 6px;
	border: 1px solid ${({ theme }) => theme.basics.brand};
	border-radius: 3px;
`
export const ButtonContainer = styled.View`
align-items:center;
justify-content:center;
align-self:center;
margin-top:15px;
`
export const ButtonText = styled(Headline6SemiBold)`
	width: 100%;
	text-align: center;
	color: ${({ theme, cancel }) => cancel ? theme.basics.brand : theme.basics.background_white};
`
