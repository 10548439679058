import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import _ from 'lodash'

// Constants
export const constants = {
  STORE: createSagaAction('ARTICLE_STORE'),
  STORE_DRAFT: createSagaAction('STORE_DRAFT'),
  STORE_IMAGES: createSagaAction('ARTICLE_STORE_IMAGES'),
  REMOVE_IMAGE: createSagaAction('ARTICLE_REMOVE_IMAGE'),
  UPDATE: createSagaAction('ARTICLE_UPDATE'),
  DATAPROVIDER: createSagaAction('ARTICLE_DATAPROVIDER'),
  INDEX: createSagaAction('ARTICLE_INDEX'),
  READ_LATER: createSagaAction('ARTICLE_READ_LATER'),
  FEATURED: createSagaAction('ARTICLE_FEATURED'),
  SET_READ_LATER: createSagaAction('ARTICLE_SET_READ_LATER'),
  SHOW: createSagaAction('ARTICLE_SHOW'),
  REMOVE: createSagaAction('ARTICLE_REMOVE'),
  CLEAR_ARTICLE: createSagaAction('CLEAR_ARTICLE'),
  ARTICLE_TRACKING_TIME: createSagaAction('ARTICLE_TRACKING_TIME'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  store: (object, next) => ({
    type: constants.STORE.ACTION,
    ...object,
    next,
  }),
  storeDraft: (object, next) => ({
    type: constants.STORE_DRAFT.ACTION,
    ...object,
    next,
  }),
  storeImages: (object, next) => ({
    type: constants.STORE_IMAGES.ACTION,
    ...object,
    next,
  }),
  deleteImage: (object, next) => ({
    type: constants.REMOVE_IMAGE.ACTION,
    ...object,
    next,
  }),
  update: (object, next) => ({
    type: constants.UPDATE.ACTION,
    ...object,
    next,
  }),
  dataprovider: (next) => ({
    type: constants.DATAPROVIDER.ACTION,
    next,
  }),
  index: (params, next) => ({
    type: constants.INDEX.ACTION,
    ...params,
    next,
  }),
  featured: (params, next) => ({
    type: constants.FEATURED.ACTION,
    featured: true,
    ...params,
    next,
  }),
  readLater: (params, next) => ({
    type: constants.READ_LATER.ACTION,
    read_later: true,
    ...params,
    next,
  }),
  setReadLater: (params, next) => ({
    type: constants.SET_READ_LATER.ACTION,
    loading: true,
    ...params,
    next,
  }),
  show: (params, next) => ({
    type: constants.SHOW.ACTION,
    loading: true,
    ...params,
    next,
  }),
  remove: (params, next) => ({
    type: constants.REMOVE.ACTION,
    loading: true,
    ...params,
    next,
  }),
  clearArticle: () => ({
    type: constants.CLEAR_ARTICLE.ACTION,
  }),
  trackActiveTime: (params, next) => ({
    type: constants.ARTICLE_TRACKING_TIME.ACTION,
    ...params,
    next,
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.STORE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.STORE.SUCCESS]: (state, action) => {
    return {
      ...state,
      featured: state.featured?.concat([action.payload.data]),
      data: state.data?.concat([action.payload.data]),
      loading: false,
      error: null,
    }
  },
  [constants.STORE_DRAFT.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.STORE_DRAFT.SUCCESS]: (state, action) => {
    return {
      ...state,
      article: {
        ...state.article,
        ...action.payload.data
      },
      loading: false,
      error: null,
    }
  },
  [constants.UPDATE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.UPDATE.SUCCESS]: (state, action) => {
    return {
      ...state,
      featured: state.featured?.map(article => article.id === action.payload.data.id ? action.payload.data : article),
      data: state.data?.map(article => article.id === action.payload.data.id ? action.payload.data : article),
      readLater: state.readLater?.map(article => article.id === action.payload.data.id ? action.payload.data : article),
      loading: false,
      error: null,
      article: null,
    }
  },
  [constants.DATAPROVIDER.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      loading: true
    }
  },
  [constants.DATAPROVIDER.SUCCESS]: (state, action) => {
    return {
      ...state,
      lifecycles: action.payload.lifecycles,
      categories: action.payload.categories,
      loading: false,
    }
  },
  [constants.INDEX.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      dataLoading: true
    }
  },
  [constants.INDEX.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: action.payload.current_page > 1 ? state.data.concat(action.payload.data) : action.payload.data,
      dataNextPage: action.payload.next_page_url,
      dataLoading: false,
      loading: false,
    }
  },
  [constants.FEATURED.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      featuredLoading: true
    }
  },
  [constants.FEATURED.SUCCESS]: (state, action) => {
    return {
      ...state,
      // featured: state.featured.concat(action.payload.data),
      featured: action.payload.data,
      featuredNextPage: action.payload.next_page_url,
      featuredLoading: false,
    }
  },
  [constants.READ_LATER.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      readLaterLoading: true
    }
  },
  [constants.READ_LATER.SUCCESS]: (state, action) => {
    return {
      ...state,
      // readLater: state.readLater.concat(action.payload.data),
      readLater: action.payload.data,
      readLaterNextPage: action.payload.next_page_url,
      readLaterLoading: false,
    }
  },
  [constants.SET_READ_LATER.ACTION]: (state) => {
    return {
      ...state,
    }
  },
  [constants.SET_READ_LATER.SUCCESS]: (state, action) => {
    let readLater = _.clone(state.readLater)
    const article = action.action
    article.read_later = !article.read_later
    if (!article.read_later) {
      readLater = readLater.filter(row => row.id !== article.id)
    } else {
      readLater.push(article)
    }
    state.data = _.clone(state.data).map(row => row.id === article.id ? article : row)
    state.featured = _.clone(state.featured).map(row => row.id === article.id ? article : row)
    return {
      ...state,
      loading: false,
      readLater
    }
  },
  [constants.SHOW.ACTION]: (state) => {
    return { ...state, error: null, loading: true, article: null }
  },
  [constants.SHOW.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      article: action.payload,
    }
  },
  [constants.REMOVE.ACTION]: (state) => {
    return { ...state, error: null, loading: true, article: null }
  },
  [constants.REMOVE.SUCCESS]: (state, action) => {
    return {
      ...state,
      featured: state.featured?.filter(article => article.id !== action.action.id),
      data: state.data?.filter(article => article.id !== action.action.id),
      readLater: state.readLater?.filter(article => article.id !== action.action.id),
      loading: false,
    }
  },
  [constants.STORE_IMAGES.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.STORE_IMAGES.SUCCESS]: (state, action) => {
    if (state?.article?.article_images) {
      action.payload.forEach(img => state?.article?.article_images.push(img))
    }
    return {
      ...state,
      loading: false,
    }
  },
  [constants.REMOVE_IMAGE.ACTION]: (state) => {
    return { ...state, error: null, loading: true }
  },
  [constants.REMOVE_IMAGE.SUCCESS]: (state, action) => {
    return {
      ...state,
      article: {
        ...state?.article,
        article_images: state?.article?.article_images?.filter(image => image?.id !== action?.payload)
      },
      loading: false,
    }
  },
  [constants.CLEAR_ARTICLE.ACTION]: (state) => {
    return {
      ...state,
      article: {
        article_images: []
      },
      loading: false,
    }
  },
  [constants.ARTICLE_TRACKING_TIME.ACTION]: (state, params) => {
    return {
      ...state,
      errors: null,
      loading: false,
      timeTracked: params.timeTracked,
      articleId: params.articleId,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,

  article: {
    article_images: [],
  },

  timeTracked: 0,
  articleId: 0,

  data: [],
  dataNextPage: null,
  loading: false,

  featured: [],
  featuredNextPage: null,
  featuredLoading: false,

  readLater: [],
  readLaterNextPage: null,
  readLaterLoading: false,

  lifecycles: [],
  categories: [],
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})

