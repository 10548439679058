import * as Updates from 'expo-updates';
import { API_URL } from '@env';
import { STRIPE_PUBLISHABLE_KEY } from '@env';
import { BACKEND_BASE_URL } from '@env';
import { FRONTEND_BASE_URL } from '@env';

const ENV = {
    file: {
        frontendBaseURL: FRONTEND_BASE_URL,
        backendBaseURL: BACKEND_BASE_URL,
        apiUrl: API_URL,
        stripePublishableKey: STRIPE_PUBLISHABLE_KEY
    },
    local: {
        frontendBaseURL: "http://localhost:19006/",
        backendBaseURL: "http://192.168.1.37:8000/",
        apiUrl: "http://192.168.1.37:8000/workspaceapp",
        stripePublishableKey: 'pk_test_IeJx93EpZt13fVC9pM6D7yI0'
    },
    development: {
        frontendBaseURL: "https://develop.d2pbhicy2ypo2i.amplifyapp.com/",
        backendBaseURL: "https://app-backend-development.getreframe.com/",
        apiUrl: "https://app-backend-development.getreframe.com/workspaceapp",
        stripePublishableKey: 'pk_test_IeJx93EpZt13fVC9pM6D7yI0'
    },
    staging: {
        frontendBaseURL: "https://staging.d2pbhicy2ypo2i.amplifyapp.com/",
        backendBaseURL: "https://app-backend-staging.getreframe.com/",
        apiUrl: "https://app-backend-staging.getreframe.com/workspaceapp",
        stripePublishableKey: 'pk_test_IeJx93EpZt13fVC9pM6D7yI0'
    },
    production: {
        frontendBaseURL: "https://app.getreframe.com/",
        backendBaseURL: "https://platform.getreframe.com/",
        apiUrl: "https://platform.getreframe.com/workspaceapp",
        stripePublishableKey: 'pk_live_51Cl5poKkYVC0g88qoBFZWSN8Lr2RQACCkgeuws5BPQ9HHiMTdlF2CaMGIfQVI1IjmwNDNWMEDLlwKAwCOBCZaXtG00itJ6YefJ'
    }
};

const getEnv = (env = Updates.releaseChannel) => {
    // What is __DEV__ ?
    // This variable is set to true when react-native is running in Dev mode.
    // __DEV__ is true when run locally, but false when published.
    if (__DEV__) {
        return ENV.file;
    } else if (env.includes('pr-')) {
        return ENV.development;
    } else if (env === 'development') {
        return ENV.development;
    } else if (env === 'staging') {
        return ENV.staging;
    } else if (env.includes('production-')) {
        return ENV.production;
    } else {
        return ENV.file;
    }
};

export default getEnv;