import styled from 'styled-components/native';
import { Appbar } from 'react-native-paper';
import themes from '@/styles/themes/black';
import { shadow1 } from '@/styles/shadows';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';

export const Search = styled.View`
    flex: 1;
    flex-direction: row;
    height: 40px;

    align-items: center;
    
    margin-left: 25px;
    margin-right: 16px;
    
    border-radius: 3px;

    background-color: ${themes.basics.background_white};
    ${shadow1}
`;

export const IconSearch = styled(MaterialIcons)`
    margin: 13px;
`;

export const IconClose = styled(MaterialCommunityIcons)`
    margin: 10px;
`;

export const InputSearch = styled.TextInput`
    flex: 1;

    margin: 10px;
    margin-left: 0px;
`;


export const BackAction = styled(Appbar.BackAction)`
    margin-right: 0px;
`;