import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ChevronTop(props) {
  return (
    <Svg width="12" height="8" viewBox="0 0 12 8" fill="#000" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M1.41 7.70508L6 3.12508L10.59 7.70508L12 6.29508L6 0.295078L1.23266e-07 6.29508L1.41 7.70508Z" />
    </Svg>
  )
}

ChevronTop.propTypes = Svg.propTypes
