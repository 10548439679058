import React, { useCallback, useRef, useContext, forwardRef, useImperativeHandle } from 'react'
import {
  Modal,
  Animated,
  useWindowDimensions,
  Easing,
} from 'react-native'
import PropTypes from 'prop-types'
import { ThemeContext } from 'styled-components'
import { Icon } from '@/components'
import { CloseButton, ContainerModal, Main, Card } from './styles'

function AnimatedModal({
  isModalVisible,
  setModalVisible,
  modalBackgroundColor,
  height: cardHeight,
  alignItems,
  justifyContent,
  flexDirection,
  style,
  children
}, ref) {
  const { height } = useWindowDimensions()
  const translateY = useRef(new Animated.Value(height)).current
  const theme = useContext(ThemeContext)

  const open = useCallback(() => {
    Animated.timing(translateY, {
      useNativeDriver: true,
      delay: 500,
      easing: Easing.inOut(Easing.ease),
      toValue: 0
    }).start()
  }, [isModalVisible, setModalVisible])

  const close = useCallback(() => {
    Animated.timing(translateY, {
      useNativeDriver: true,
      delay: 500,
      toValue: height / 2
    }).start(() => setModalVisible(false))
  }, [isModalVisible, setModalVisible])

  useImperativeHandle(ref, () => ({ close }))

  return (
    <Modal
      visible={isModalVisible}
      transparent
      animationType="fade"
      onShow={open}
    >
      <ContainerModal backgroundColor={modalBackgroundColor}>
        <Main style={{
          transform: [{ translateY }]
        }}>
          <Card
            height={cardHeight}
            alignItems={alignItems}
            justifyContent={justifyContent}
            flexDirection={flexDirection}
            style={style}
          >
            <CloseButton onPress={close}>
              <Icon icon="close" color={theme?.basics[900]} width={14} height={14} />
            </CloseButton>
            {children}
          </Card>
        </Main>
      </ContainerModal>
    </Modal>
  )
}

AnimatedModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  modalBackgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  flexDirection: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]))
}

export default forwardRef(AnimatedModal)
