import styled from 'styled-components/native'
import { Dimensions, StatusBar, StyleSheet } from 'react-native'
import { Headline6 } from '@/styles/fonts'
import { TouchableOpacity } from 'react-native-gesture-handler'
import themes from '@/styles/themes/black'
import { shadow1 } from '@/styles/shadows'
import { Headline6Strong } from '@/styles/fonts'

const { height, width } = Dimensions.get('window')

export const styles = StyleSheet.create({
	flatListContent: {
		width: '100%',
		paddingTop: 30,
		paddingBottom: height * 0.1,
		paddingHorizontal: 15,
	},
	gradiente: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: 67,
		zIndex: 1,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
	}
})

// export const Container = styled.View`
// 	width: ${width}px;
// 	height: ${height}px;
// 	padding-top: 0${StatusBar.currentHeight}px;
// 	background-color: ${({ theme }) => theme.basics.background_white};
// `

export const Header = styled.View`
	position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	align-items: center;
	margin: 16px 0 0;
	width: 100%;
	padding: 0 0 15px;
`

export const TitleHeader = styled(Headline6)`
	color: ${({ theme }) => theme.basics.black_text};
	position: relative;
`

export const LeftIconButton = styled(TouchableOpacity)`
	margin: 0 0 0 16px;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`

export const IconButton = styled(TouchableOpacity)`
	margin: 0 16px 0 0;
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
`




export const Container = styled.SafeAreaView`
	position: relative;
	width:  100%;
	height:100%;
	align-self: center;
	align-items: center;
	margin-left: ${({ isMobile }) => isMobile ? 10 : 8}px;
	margin-right: ${({ isMobile }) => isMobile ? 10 : 8}px;
	margin-bottom: ${({ isMobile }) => isMobile ? 30 : 20}px;
	border-radius: 5px;
	border:1px solid ${themes.basics[300]};
	background-color: ${themes.basics.background_white};
	padding: 16px;
	${shadow1}
`

export const CoverContainer = styled.View`
	width: 100%;
	position: relative;
`

export const RowContainer = styled.View`
	width: 100%;
  flex-direction: row;
  align-items: center;
	position: relative;
`

export const MemberContainer = styled.View`
	width: 100%;
	position: relative;
	padding-horizontal:20px;
	margin-top:10px;
`

export const Title = styled(Headline6Strong)`
	align-items: center;
	color: ${themes.basics[800]};
`

export const FakeInput = styled.View`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 16px;
  padding-right: 8px;
  padding-bottom: 7px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.basics[500]};
`

export const BackButton = styled.TouchableOpacity`
	height: 24px;
	width: 24px;
	align-items: center;
	justify-content: center;
  margin-right: 8px;
`

