import React, { useCallback, useEffect, useState, useRef } from 'react'
import { BackArrow, TextField } from '@/components'
import { useIsMobile } from '@/styles'
import { View, ScrollView, Text, Dimensions, Platform } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as ImagePicker from 'expo-image-picker'
import moment, { months } from 'moment'
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';
import { ActivityIndicator, ProgressBar, TextInput } from 'react-native-paper'
import { openGallery, openCamera } from '@/utils/gallery'
import { actions } from '@/redux/reducers/event'
import { actions as actionCommon } from '@/redux/reducers/common'
import { useDispatch, useSelector } from 'react-redux'
import Toast from 'react-native-toast-message'
import * as selectors from '@/redux/selectors/event'
import * as selectorsCommon from '@/redux/selectors/common'
import { useNavigation } from '@react-navigation/native'
import SimpleSelect from '@/components/SimpleSelect'
import UploadArea from '@/components/UploadArea'
import theme from '@/styles/themes/black'
import * as FileSystem from 'expo-file-system'
import {
  CentralizedContainer,
  Actions,
  Title,
  Header,
  TitleWrapper,
  CreateButton,
  styles,
  TextButton,
  ContentText,
  RouteName,
  RouteText,
  RowView,
  WrapView
} from '../styles'
import * as brandSelectors from '@/redux/selectors/branding'
import JoditEditorWeb from '../JoditEditorWeb'
import ApiHandler from '@/api/ApiHandler'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const window = Dimensions.get('window');

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  excerpt: Yup.string().required(),
  // employee_lifecycle_stage_id: Yup.number().required(),
  // five_s_category_id: Yup.number().required(),
  content: Yup.string().required(),
  location: Yup.string().required(),
  language_id: Yup.string().required()
})



const CreateEvent = ({ route }) => {
  const startTimeInput = useRef()
  const endTimeInput = useRef()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const loading = useSelector(selectors.loading)
  const lifecycles = useSelector(selectorsCommon.lifecycles)
  const categories = useSelector(selectorsCommon.categories)
  // const event = route?.params?.event

  const eventDetail = route?.params?.event
  const _event = useSelector(selectors.event)
  const [event, setEvent] = useState(null)
  // const languageId = eventDetail && eventDetail.language_id?eventDetail?.language_id:40;
  const languageId = 40;
  const scheduledPublishTimeInput = useRef()
  const [isOpenDatePicker, setDatePicker] = useState(false)
  const [isOpenTimePickerModal, setTimePickerModal] = useState(false)
  const [scheduleDate, setScheduleDate] = useState('')

  const [isLoading, setloading] = useState(false)
  const [lifecyclesData, setLifecyclesData] = useState(null)
  const [categoriesData, setCategories] = useState(null)
  const [CategoryCulturalMaturityScoresData, setCategoryCulturalMaturityScores] = useState(null)
  const [TotalCulturalMaturityScoresData, setTotalCulturalMaturityScores] = useState(null)
  const [SegmentsData, setSegments] = useState(null)
  const [languagesData, setLanguagesData] = useState(null)
  const [languagesValue, setLanguageVal] = useState(null)

  const editor = useRef(null)
  const [joditIsAutofocus, setJoditIsAutofocus] = useState(true)
  const [screenContentHeight, setScreenContentHeight] = useState(false)
  const titleRef = useRef()
  const formikRef = useRef(null)

  const isCreate = !route?.params?.event
  const branding = useSelector(brandSelectors.getSettings)
  const [openList, setOpenList] = useState([false, false, false, false])
  const {
    color_scheme_accents,
    color_scheme_main_navigation_background,
    color_scheme_main_navigation_text,
    color_scheme_page_background,
    color_scheme_primary_button_background,
    color_scheme_primary_button_text,
  } = branding

  const _initialValues = _event
    ? { ..._event, language_id: languageId, start_date: Math.floor(new Date(_event.start_date.replace(" ", "T")).getTime() / 1000), end_date: Math.floor(new Date(_event.end_date.replace(" ", "T")).getTime() / 1000), scheduled_publish: _event.scheduled_publish ? moment(_event.scheduled_publish).format('MM/DD/YYYY hh:mm a') : '' }
    : {
      name: '',
      excerpt: '',
      content: '',
      image: '',
      video: '',
      employee_lifecycle_stage_id: null,
      five_s_category_id: null,
      category_cultural_maturity_score: null,
      total_cultural_maturity_score: null,
      segment_id: null,
      language_id: 40,
      location: '',
      start_date: Math.floor(new Date().getTime() / 1000),
      end_date: Math.floor(new Date().getTime() / 1000),
      scheduled_publish: '',
    }



  const [initVal, setinitVal] = useState(_initialValues);
  // const [initVal, setinitVal] = useState({
  //   name: '',
  //   excerpt: '',
  //   content: '',
  //   image: '',
  //   video: '',
  //   employee_lifecycle_stage_id: null,
  //   five_s_category_id: null,
  //   location: '',
  //   start_date: Math.floor(new Date().getTime() / 1000),
  //   end_date: Math.floor(new Date().getTime() / 1000),
  //   scheduled_publish: '',
  // })

  useEffect(() => {
    navigation.addListener('focus', () => {
      getTags();
      getLanguages();
      formikRef.current?.setValues({
        name: '',
        excerpt: '',
        content: '',
        image: '',
        video: '',
        employee_lifecycle_stage_id: null,
        five_s_category_id: null,
        category_cultural_maturity_score: null,
        total_cultural_maturity_score: null,
        segment_id: null,
        language_id: 40,
        location: '',
        start_date: Math.floor(new Date().getTime() / 1000),
        end_date: Math.floor(new Date().getTime() / 1000),
        scheduled_publish: '',
      })

      setScheduleDate(false);
      setDatePicker(false);
      setTimePickerModal(false);
      setLanguageVal(languageId)
      if (eventDetail?.id) {
        //setLanguageVal(languageId)
        dispatch(actions.getEventWithIdAction({ id: eventDetail?.id, language_id: languageId }, () => { }))
      }
      dispatch(actionCommon.getFiveSCategoriesAction(() => { }))
      dispatch(actionCommon.getLifecycleStagesAction(() => { }))
    })
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (titleRef?.current) {
        titleRef.current?.focus();
        titleRef.current?.blur();
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    setloading(false);
    // route?.params?.language_id
    if (_event && !isCreate) {
      if (_event && Object.keys(_event).length === 0) {
        setLanguageVal(_event?.language_id)
        setEvent(_event)
      }
      else {
        if (languagesValue == _event?.language_id) {
          setEvent(_event)
        }
        else {
          const events = _event
          events.language_id = languagesValue
          setEvent(events)
        }
      }
    }
  }, [_event])

  useEffect(() => {
    if (scheduleDate) {
      setDatePicker(false);
      setTimePickerModal(true);
    }


  }, [scheduleDate])


  useEffect(() => {
    const _initialValues = event
      ? { ...event, language_id: event.language_id ? event.language_id : 40, start_date: Math.floor(new Date(event.start_date.replace(" ", "T")).getTime() / 1000), end_date: Math.floor(new Date(event.end_date.replace(" ", "T")).getTime() / 1000), scheduled_publish: event.scheduled_publish ? moment(event.scheduled_publish).format('MM/DD/YYYY hh:mm a') : '' }
      : {
        name: '',
        excerpt: '',
        content: '',
        image: '',
        video: '',
        employee_lifecycle_stage_id: null,
        five_s_category_id: null,
        category_cultural_maturity_score: null,
        total_cultural_maturity_score: null,
        segment_id: null,
        language_id: 40,
        location: '',
        start_date: Math.floor(new Date().getTime() / 1000),
        end_date: Math.floor(new Date().getTime() / 1000),
        scheduled_publish: '',
      }
    setinitVal(_initialValues)

  }, [event])

  useEffect(() => {
    if (languagesValue) {
      dispatch(actions.getEventWithIdAction({ id: eventDetail?.id, language_id: languagesValue }, () => { }))
    }

  }, [languagesValue])

  const setEventData = async (languageID) => {
    setLanguageVal(languageID)
    dispatch(actions.getEventWithIdAction({ id: eventDetail?.id, language_id: languageID }, () => { }))
  }

  const getTags = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getTagsLearning();
      if (response?.data) {
        setloading(false);
        const EmployeeLifecycleStages = response?.data.map((item) => item.EmployeeLifecycleStages)
        const FiveSCategories = response?.data.map((item) => item.FiveSCategories)
        const CategoryCulturalMaturityScores = response?.data.map((item) => item.CategoryCulturalMaturityScores)
        const TotalCulturalMaturityScores = response?.data.map((item) => item.TotalCulturalMaturityScores)
        const Segments = response?.data.map((item) => item.Segments)

        const lcd = EmployeeLifecycleStages[0] && EmployeeLifecycleStages[0].length > 0 ? EmployeeLifecycleStages[0] : null;
        const lcdData = lcd ? [{ id: '', name: '' }, ...lcd] : lcd;
        setLifecyclesData(lcdData);
        const cat = FiveSCategories[0] && FiveSCategories[0].length > 0 ? FiveSCategories[0] : null;
        const catData = cat ? [{ id: '', name: '' }, ...cat] : cat;
        setCategories(catData);
        const catMatScore = CategoryCulturalMaturityScores[0] && CategoryCulturalMaturityScores[0].length > 0 ? CategoryCulturalMaturityScores[0].map((ms) => { return { id: ms, name: ms.toString() } }) : null;
        const catMatScoreData = catMatScore ? [{ id: '', name: '' }, ...catMatScore] : catMatScore;
        setCategoryCulturalMaturityScores(catMatScoreData);
        const totMatScore = TotalCulturalMaturityScores[0] && TotalCulturalMaturityScores[0].length > 0 ? TotalCulturalMaturityScores[0].map((ts) => { return { id: ts, name: ts.toString() } }) : null;
        const totMatScoreData = totMatScore ? [{ id: '', name: '' }, ...totMatScore] : totMatScore;
        setTotalCulturalMaturityScores(totMatScoreData);
        const seg = Segments[0] && Segments[0].length > 0 ? Segments[0].map((s) => { return { id: s.id, name: s.segment_name } }) : null;
        const segData = seg ? [{ id: '', name: '' }, ...seg] : seg;
        setSegments(segData);
      }
    } catch (error) {
      setloading(false);
    }
  }

  const getLanguages = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getAllLanguages();
      if (response?.data) {
        setloading(false);
        const languages = response?.data.map((l) => { return { id: l.id, name: l.display_name } })
        setLanguagesData(languages);
      }

    } catch (error) {
      setloading(false);
    }
  }

  const handleSubmit = useCallback(async (values) => {
    values.employee_lifecycle_stage_id = values.employee_lifecycle_stage_id == '' ? null : values.employee_lifecycle_stage_id;
    values.five_s_category_id = values.five_s_category_id == '' ? null : values.five_s_category_id;
    values.category_cultural_maturity_score = values.category_cultural_maturity_score == '' ? null : values.category_cultural_maturity_score;
    values.total_cultural_maturity_score = values.total_cultural_maturity_score == '' ? null : values.total_cultural_maturity_score;
    values.segment_id = values.segment_id == '' ? null : values.segment_id;
    values.language_id = values.language_id ? values.language_id : 40;

    if (loading) {
      return
    }

    if (!values.image.substring(0, 10).includes('http')) {
      values.image = values.image.split(',')[1]
    }

    if (!values.video.substring(0, 10).includes('http')) {
      values.video = values.video.split(',')[1]
    }

    if (isCreate) {
      dispatch(actions.createEventAction({ ...values }, onSubmit))
    } else {
      dispatch(
        actions.updateEventAction(
          {
            id: event.id,
            name: values.name,
            excerpt: values.excerpt,
            employee_lifecycle_stage_id: values.employee_lifecycle_stage_id,
            five_s_category_id: values.five_s_category_id,
            category_cultural_maturity_score: values.category_cultural_maturity_score,
            total_cultural_maturity_score: values.total_cultural_maturity_score,
            segment_id: values.segment_id,
            language_id: values.language_id,
            image: values.image,
            video: values.video,
            location: values.location,
            start_date: values.start_date,
            end_date: values.end_date,
            content: values.content,
            scheduled_publish: values.scheduled_publish,
          },
          onSubmit,
        ),
      )
    }
  })

  const onSubmit = (error, success) => {
    Toast.show({
      type: error ? 'error' : 'success',
      position: 'bottom',
      text1: error ? error.message : success.message,
    })
    navigation.navigate('Events')
  }
  const addZero = (str) => {
    const t = ("0" + str).slice(-2)
    return t;
  }



  return (
    <>
      <PageHeader
        title={isCreate ? 'Create Event' : 'Update Event'}
        icon={false}
        backEnabled={true}
      />

      <PageContent>
        <View
          style={{
            width: '100%',
            maxWidth: 700,
            marginHorizontal: 'auto'
          }}
        >
          <ContentBlockV1>
            <Formik
              enableReinitialize={true}
              ref={formikRef}
              validateOnMount
              onSubmit={handleSubmit}
              initialValues={initVal}
              validationSchema={validationSchema}
            >
              {({
                values,
                isValid,
                handleChange,
                handleSubmit,
                setValues,
                errors,
              }) => {
                return (
                  <>
                    <View>
                      <SimpleSelect
                        list={languagesData}
                        label="Select A Language"
                        value={values.language_id}
                        //setValue={handleChange('language_id')}
                        setValue={(e) => {
                          setValues({ ...values, language_id: e })
                          //setEventData(e)
                          setLanguageVal(e)

                        }}
                      />

                      <TextField
                        label="Title"
                        value={values.name}
                        autoCapitalize="none"
                        keyboardType="default"
                        onChangeText={handleChange('name')}
                      />
                      <RowView>
                        <TextField
                          label="Start Date"
                          value={moment(values.start_date * 1000).format('MM/DD/YYYY')}
                          autoCapitalize="none"
                          keyboardType="default"
                          onFocus={() => {
                            openList[0] = true; setOpenList([...openList]);
                          }}
                          right={
                            <TextInput.Icon name='calendar' color='#8B77E8' />
                          }
                        />
                        <DatePickerModal
                          locale="en"
                          mode="single"
                          visible={openList[0]}
                          onDismiss={() => {
                            openList[0] = false;
                            setOpenList([...openList]);
                          }}
                          date={new Date(values.start_date * 1000)}
                          onConfirm={(d) => {
                            openList[0] = false;
                            setOpenList([...openList]);
                            const timeStr = moment.unix(values.start_date).format('HH:mm:ss')
                            const dateStr = moment(d.date).format('YYYY-MM-DD')
                            const dateTime = moment(dateStr + "T" + timeStr).unix();
                            setValues({ ...values, start_date: dateTime })
                          }}
                        />
                        <TextInput
                          ref={startTimeInput}
                          label="Start Time"
                          value={moment(values.start_date * 1000).format('hh:mm a')}
                          autoCapitalize="none"
                          keyboardType="default"
                          onFocus={() => {
                            openList[1] = true;
                            setOpenList([...openList]);
                            startTimeInput.current.blur()
                          }}
                          style={{ backgroundColor: 'transparent', paddingHorizontal: 0 }}
                          right={
                            <TextInput.Icon name='calendar' color='#8B77E8' />
                          }
                        />
                        <TimePickerModal
                          locale="en"
                          visible={openList[1]}
                          onDismiss={() => {
                            openList[1] = false;
                            setOpenList([...openList]);
                          }}
                          date={new Date(values.start_date * 1000)}
                          onConfirm={(t) => {
                            openList[1] = false;
                            setOpenList([...openList]);
                            const dateStr = moment.unix(values.start_date).format('YYYY-MM-DD')
                            const tempdate = '2021-12-03' + 'T' + addZero(t.hours) + ':' + addZero(t.minutes) + ':00'
                            const timeStr = moment(tempdate).format('HH:mm')
                            const dateTime = moment(dateStr + "T" + timeStr).unix();
                            setValues({ ...values, start_date: dateTime })
                          }}
                        />
                      </RowView>
                      <RowView>
                        <TextField
                          label="End Date"
                          value={moment(values.end_date * 1000).format('MM/DD/YYYY')}
                          autoCapitalize="none"
                          keyboardType="default"
                          onFocus={() => {
                            openList[2] = true; setOpenList([...openList]);
                          }}
                          right={
                            <TextInput.Icon name='calendar' color='#8B77E8' />
                          }
                        />
                        <DatePickerModal
                          locale="en"
                          mode="single"
                          visible={openList[2]}
                          onDismiss={() => {
                            openList[2] = false;
                            setOpenList([...openList]);
                          }}
                          date={new Date(values.end_date * 1000)}
                          onConfirm={(d) => {
                            openList[2] = false;
                            setOpenList([...openList]);
                            const timeStr = moment.unix(values.end_date).format('HH:mm:ss')
                            const dateStr = moment(d.date).format('YYYY-MM-DD')
                            const dateTime = moment(dateStr + "T" + timeStr).unix();
                            setValues({ ...values, end_date: dateTime })
                          }}
                        />
                        <TextInput
                          ref={endTimeInput}
                          label="End Time"
                          value={moment(values.end_date * 1000).format('hh:mm a')}
                          autoCapitalize="none"
                          keyboardType="default"
                          onFocus={() => {
                            openList[3] = true;
                            setOpenList([...openList]);
                            endTimeInput.current.blur()
                          }}
                          style={{ backgroundColor: 'transparent', paddingHorizontal: 0 }}
                          right={
                            <TextInput.Icon name='calendar' color='#8B77E8' />
                          }
                        />
                        <TimePickerModal
                          locale="en"
                          visible={openList[3]}
                          onDismiss={() => {
                            openList[3] = false;
                            setOpenList([...openList]);
                          }}
                          date={new Date(values.end_date * 1000)}
                          onConfirm={(t) => {
                            openList[3] = false;
                            setOpenList([...openList]);
                            const dateStr = moment.unix(values.end_date).format('YYYY-MM-DD')
                            const tempdate = '2021-12-03' + 'T' + addZero(t.hours) + ':' + addZero(t.minutes) + ':00'
                            const timeStr = moment(tempdate).format('HH:mm')
                            const dateTime = moment(dateStr + " " + timeStr).unix();
                            setValues({ ...values, end_date: dateTime })
                          }}
                        />
                      </RowView>
                      <TextField
                        label="Location"
                        value={values.location}
                        autoCapitalize="none"
                        keyboardType="default"
                        onChangeText={handleChange('location')}
                        right={
                          <TextInput.Icon name='map' color='#8B77E8' />
                        }
                      />
                      {/* <TextField
                    label="Excerpt"
                    value={values.excerpt}
                    autoCapitalize="none"
                    keyboardType="default"
                    multiline={true}
                    numberOfLines={5}
                    onChangeText={handleChange('excerpt')}
                    style={{ marginBottom: 20 }}
                  /> */}
                      <TextInput
                        label="Excerpt"
                        value={values.excerpt}
                        autoCapitalize="none"
                        keyboardType="default"
                        multiline={true}
                        numberOfLines={5}
                        onChangeText={handleChange('excerpt')}
                        maxHeight={window.height * 0.20}
                        style={{ marginBottom: 20, paddingHorizontal: 0, backgroundColor: '#fff' }}
                      />
                      <UploadArea
                        topText="Drag/Drop a Cover Image here or"
                        buttonText="Select A Cover Image"
                        bottomText=" "
                        onClick={async () => {
                          try {
                            const imageUri = await openGallery()
                            if (isMobile) {
                              const data =
                                'data:image/jpeg;base64,' +
                                (await FileSystem.readAsStringAsync(imageUri, {
                                  encoding: 'base64',
                                }))
                              values.image = data
                            } else {
                              values.image = imageUri
                            }
                            setValues({ ...values })
                          } catch (e) {
                            console.log(e)
                            console.log('Error opening gallery')
                          }
                        }}
                        files={values?.image ? [values?.image] : []}
                        brandingSetting={branding}
                        mediaType={1} // image type = 1, video type = 2, image&video type = 0 or undefined
                      />
                      {/* <TextField
                    label="Content"
                    value={values.content}
                    autoCapitalize="none"
                    keyboardType="default"
                    multiline={true}
                    numberOfLines={5}
                    onChangeText={handleChange('content')}
                  /> */}
                      <ContentText>Content</ContentText>
                      <JoditEditorWeb
                        //  placeholder="Start wri"
                        ref={editor}
                        tabIndex={2}
                        value={values.content}
                        onChange={handleChange('content')}
                        config={{ autofocus: joditIsAutofocus, height: window.height * 0.30 }}
                      />

                      <View style={{ height: 30 }} />
                      <UploadArea
                        topText="Drag/Drop a Past Recording Video here or"
                        buttonText="Select A Past Recording Video"
                        bottomText=" "
                        onClick={async () => {
                          try {
                            const videoPicerkOption = {
                              mediaTypes: ImagePicker.MediaTypeOptions.Videos,
                            }
                            let videoUri = await openGallery(
                              videoPicerkOption,
                            )

                            if (isMobile) {
                              const data =
                                'data:video/mp4;base64,' +
                                (await FileSystem.readAsStringAsync(
                                  videoUri,
                                  {
                                    encoding: 'base64',
                                  },
                                ))
                              values.video = data
                            } else {
                              values.video = videoUri
                            }
                            setValues({ ...values })
                          } catch (e) {
                            console.log('Error opening gallery', e)
                          }
                        }}
                        files={values?.video ? [values?.video] : []}
                        brandingSetting={branding}
                        mediaType={2}
                      />
                      <View
                        style={{
                          flexDirection: isMobile ? 'column' : 'row',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <SimpleSelect
                          list={lifecyclesData}
                          label="Lifecycle Stage"
                          value={values.employee_lifecycle_stage_id}
                          setValue={handleChange('employee_lifecycle_stage_id')}
                        />
                        <SimpleSelect
                          list={categoriesData}
                          label="Category"
                          value={values.five_s_category_id}
                          setValue={handleChange('five_s_category_id')}
                        />
                      </View>

                      <View
                        style={{
                          flexDirection: isMobile ? 'column' : 'row',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >

                        <SimpleSelect
                          list={CategoryCulturalMaturityScoresData}
                          label="Category Cultural Maturity Score"
                          value={values.category_cultural_maturity_score}
                          setValue={handleChange('category_cultural_maturity_score')}
                        />

                        <SimpleSelect
                          list={TotalCulturalMaturityScoresData}
                          label="Total Cultural Maturity Score"
                          value={values.total_cultural_maturity_score}
                          setValue={handleChange('total_cultural_maturity_score')}
                        />
                      </View>

                      <View
                        style={{
                          flexDirection: isMobile ? 'column' : 'row',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <SimpleSelect
                          list={SegmentsData}
                          label="Segment"
                          value={values.segment_id}
                          setValue={handleChange('segment_id')}
                        />
                      </View>
                      {/* <View
                    style={{
                      flexDirection: isMobile ? 'column' : 'row',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: 20,
                    }}
                  >
                    <SimpleSelect
                      list={lifecyclesData}
                      label="Lifecycle Stage"
                      value={values.employee_lifecycle_stage_id}
                      setValue={handleChange('employee_lifecycle_stage_id')}
                    />
                    <SimpleSelect
                      list={categoriesData}
                      label="Category"
                      value={values.five_s_category_id}
                      setValue={handleChange('five_s_category_id')}
                    />

                    <SimpleSelect
                      list={CategoryCulturalMaturityScoresData}
                      label="Category Cultural Maturity Score"
                      value={values.category_cultural_maturity_score}
                      setValue={handleChange('category_cultural_maturity_score')}
                    />

                    <SimpleSelect
                      list={TotalCulturalMaturityScoresData}
                      label="Total Cultural Maturity Score"
                      value={values.total_cultural_maturity_score}
                      setValue={handleChange('total_cultural_maturity_score')}
                    />

                    <SimpleSelect
                      list={SegmentsData}
                      label="Segment"
                      value={values.segment_id}
                      setValue={handleChange('segment_id')}
                    />
                  </View> */}

                      {/* Schedule start */}
                      <ContentText>Schedule</ContentText>
                      <View
                        style={{
                          flexDirection: isMobile ? 'column' : 'row',
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}
                      >
                        <TextInput
                          ref={scheduledPublishTimeInput}
                          label="Date time"
                          value={values.scheduled_publish ? values.scheduled_publish : ''}
                          onChangeText={handleChange('scheduled_publish')}
                          autoCapitalize="none"
                          keyboardType="default"
                          onFocus={() => {
                            setDatePicker(true);
                            scheduledPublishTimeInput.current.blur()
                          }}
                          style={{ backgroundColor: 'transparent', paddingHorizontal: 0, flex: 1 }}
                          right={
                            <TextInput.Icon name='calendar' color='#8B77E8' />
                          }
                        />
                        <DatePickerModal
                          locale="en"
                          mode="single"
                          visible={isOpenDatePicker}
                          onDismiss={() => {
                            setScheduleDate('')
                            setDatePicker(false);
                          }}
                          date={values.scheduled_publish ? new Date(values.scheduled_publish) : new Date()}
                          onConfirm={(d) => {
                            const dateStr = moment(d.date).format('MM/DD/YYYY')
                            setScheduleDate(dateStr)
                            // setDatePicker(false);
                            //  setTimePickerModal(true);
                          }}
                          saveLabel={'Pick Time'}
                          label="Schedule publish"
                          uppercase={false}
                        />
                        <TimePickerModal
                          locale="en"
                          visible={isOpenTimePickerModal}
                          onDismiss={() => {
                            setTimePickerModal(false);
                            setDatePicker(true);
                          }}
                          date={values.scheduled_publish ? new Date(values.scheduled_publish) : new Date()}
                          onConfirm={(t) => {
                            const tempdate = scheduleDate + ' ' + t.hours + ':' + t.minutes;
                            const schedulePublish = moment(tempdate).format('MM/DD/YYYY hh:mm a');
                            setValues({ ...values, scheduled_publish: schedulePublish })
                            setTimePickerModal(false);
                          }}
                          saveLabel={'Confirm'}
                          label="Schedule publish"
                          uppercase={false}
                        />
                      </View>
                      {/* Schedule end */}

                    </View>

                    <View style={{ marginVertical: 30 }}>
                      <Actions>
                        <CreateButton
                          onPress={handleSubmit}
                          color={
                            color_scheme_primary_button_background
                              ? color_scheme_primary_button_background
                              : 'black'
                          }
                          disabled={!isValid}
                        >
                          {loading && (
                            <ActivityIndicator
                              size={20}
                              animating
                              color={theme.basics.background_white}
                              style={{ marginRight: 10 }}
                            />
                          )}
                          <TextButton color={color_scheme_primary_button_text}>
                            {isCreate ? 'Create' : 'Update'} Event
                          </TextButton>
                        </CreateButton>
                      </Actions>
                    </View>
                  </>
                )
              }}
            </Formik>
          </ContentBlockV1>
        </View>
      </PageContent>
    </>
  )
}

export default CreateEvent
