import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    ExcerptContainer: {
        marginBottom: 20
    },
    Excerpt: {

    },
    EpisodesContainer: {
        marginBottom: 20
    },
    EpisodesHeadline: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 17,
        marginBottom: 10
    },
    EpisodesList: {
        flexDirection: 'column'
    },
    EpisodeCardContainer: {
        width: '100%',
        marginBottom: 10,
        borderRadius: 10,
    },
    EpisodeCard: {
        width: '100%',
        flexDirection: 'row',
        shadowColor: 'rgba(2, 2, 2, 1)',
        shadowOffset: { width: 2, height: 1 },
        shadowOpacity: 0.10,
        shadowRadius: 20,
        elevation: 3,
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
    },
    EpisodeImageContainer: {
        position: 'relative',
        width: '40%',
        aspectRatio: 1.5,
        borderRadius: 10,
        padding: 10
    },
    EpisodeImage: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
    },
    EpisodeImageOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    EpisodeDetailsContainer: {
        position: 'relative',
        width: '60%',
        padding: 10
    },
    EpisodeNumber: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 13,
        fontWeight: '500',
        marginBottom: 5
    },
    EpisodeTitle: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 15,
        marginBottom: 5
    },
    EpisodeContent: {
        fontFamily: 'WorkSans_500Medium',
        color: '#000000',
        fontSize: 12,
    },
    EpisodeViewerContainer: {
        padding: 20,
        backgroundColor: 'rgb(27, 27, 27)',
        flex: 1
    },
    EpisodeViewerHeaderContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 20
    },
    EpisodeViewerDismissContainer: {
        marginRight: 15,
    },
    EpisodeViewerDismissButton: {
        backgroundColor: '#FFFFFF',
        borderRadius: 50,
        justifyContent: 'center',

        ...Platform.select({
            ios: {
                width: 25,
                height: 25,
            },
            android: {
                width: 25,
                height: 25,
            },
            web: {
                width: 30,
                height: 30,
                alignItems: 'center',
            }
        })
    },
    EpisodeViewerTitleContainer: {

    },
    EpisodeViewerTitle: {
        fontFamily: "WorkSans_400Regular",
        color: '#FFFFFF',
        ...Platform.select({
            ios: {
                fontSize: 25,
                lineHeight: 25,
            },
            android: {
                fontSize: 25,
                lineHeight: 25,
            },
            web: {
                fontSize: 30,
                lineHeight: 30,
            }
        })
    },
    EpisodeViewerVideoContainer: {
        flex: 1
    },
    EpisodeViewerVideo: {
        borderRadius: 10,
        width: '100%',
        height: '100%'
    }

});
