import React, { useEffect, useState, useRef } from 'react'
import {
    View,
    Text,
    StyleSheet,
    FlatList,
    ScrollView,
    TouchableOpacity,
    SafeAreaView,
    Dimensions,
    ActivityIndicator,
    Platform
} from 'react-native'
import moment from "moment";
import { TextInput } from 'react-native-paper'
import Modal from 'react-native-modal'
import Toast from 'react-native-toast-message'
import { AntDesign } from '@expo/vector-icons'
import DropDownPicker from 'react-native-dropdown-picker'
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
} from 'react-native-popup-menu'

import DateTimePickerModal from "react-native-modal-datetime-picker";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useRoute, useIsFocused } from '@react-navigation/native'
import ApiHandler from '@/api/ApiHandler'

import * as brandSelectors from '@/redux/selectors/branding'

import { Icon, Checkbox, ArticleCard, TextField } from '@/components'
import ExpansiveMenu from '@/components/ExpansiveMenu'
import ButtonPrimary from '@/components/ButtonPrimary'

import { isMobile } from '@/styles'
import theme from '@/styles/themes/black'

import canAccess from '@/helpers/permissions'
import { permissions } from '@/helpers/permissionConstants'
import { Loader } from '@/components/Loader'

import DateTimePicker from "./dateTimePicker"
import { EVENT_MODE, INVITEE_TYPE } from "./consts";

import { validateEmail } from "@/utils/email-validator"

import { getMember } from '@/redux/selectors/auth'

import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import ContentBlockV1 from '@/components/ContentBlockV1'

const { width } = Dimensions.get('window')

const REPEAT_NONE = -1;
const REPEAT_DAILY = 1;
const REPEAT_WEEKLY_ON_DAY = 2
const REPEAT_MONTHLY = 4
const REPEAT_ANNUALLY = 5


const PICKER_TYPE_START = 1;
const PICKER_TYPE_END = 2;


const today = new Date();
const todayInFifteen = today.getTime() + (1000 * 60 * 15)



const STATIC_RECURRING_TYPES = [
    {
        repeat_type: REPEAT_NONE,

        label: "Does Not Repeat",
        value: REPEAT_NONE
    },
    {
        repeat_type: REPEAT_DAILY,

        label: "Daily",
        value: REPEAT_DAILY
    }
]

const DATETIME_INPUT_WEB_FORMAT = "yyyy-MM-DDTHH:mm";

const Event = ({
    title = "Creating Event",
    mode = EVENT_MODE.create,
    showModal = false,
    setReloading,
    eventInfo
}) => {

    const member = useSelector(getMember)

    const isWeb = Platform.OS == "web";

    const isFocused = useIsFocused();
    const navigation = useNavigation();
    const { params } = useRoute();

    const startRef = useRef();

    const [isLoading, setLoading] = useState(false);

    const [eventTitle, setEventTitle] = useState("")
    const [description, setDescription] = useState("")
    const [location, setLocation] = useState("")
    const [height, setHeight] = useState(100)
    const [selectedCategory, setSelectedCategory] = useState("");


    const [dateTimePickerVisible, setDateTimePickerVisibility] = useState(false);
    const [pickerType, setPickerType] = useState(PICKER_TYPE_START);

    const [meetingStartTime, setMeetingStartTime] = useState();
    const [meetingEndTime, setMeetingEndTime] = useState();

    const [selectedTeams, setSelectedTeams] = useState([]);
    const [teamsList, setTeamList] = useState([]);

    const [isModalVisible, setModalVisible] = useState(false)

    const [recurringModal, showRecurringModal] = useState(false)


    const [selectedGroups, setSelectedGroups] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [isGroupsModalVisible, setGroupsModalVisible] = useState(false)


    const [selectedMembers, setSelectedMembers] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [isMemberModalVisible, setMemberModalVisible] = useState(false)

    const [guestEmail, setGuestEmail] = useState("")
    const [guestName, setGuestName] = useState("")
    const [invitedGuests, setInvitedGuestList] = useState([]);
    const [isGuestModalVisible, setGuestModalVisible] = useState(false)



    const [calendarEntries, setCalendarEntries] = useState([]);

    const [recurringEntries, setRecurringEntries] = useState([]);
    const [repeatType, setRepeatType] = useState()
    const [repeatTypeCheck, setRepeatTypeCheck] = useState()


    const [openDropDown, setOpenDropDown] = useState(false)
    const [dropDownValue, setDropdownValue] = useState()

    const [videoConfStatus, setVideoConfStatus] = useState(false)


    const [inviteAllMembers, setInviteAllMembers] = useState(false)
    const branding = useSelector(brandSelectors.getSettings);
    const {
        color_scheme_primary_button_background,
    } = branding;


    useEffect(() => {
        if (dropDownValue) {

            const type = recurringEntries.find((item) => {
                return item.repeat_type == dropDownValue
            })

            if (type) {
                setRepeatType(type)
            }
        }
    }, [dropDownValue])

    // console.clear();
    // console.log(">>>>>> eventInfo", eventInfo);


    useEffect(() => {

        const eventTypes = [...STATIC_RECURRING_TYPES];


        const currentDay = moment(meetingStartTime).format("dddd");
        eventTypes.push({
            repeat_type: REPEAT_WEEKLY_ON_DAY,

            label: `Weekly On ${currentDay}`,
            value: REPEAT_WEEKLY_ON_DAY
        });

        const currentDate = moment(meetingStartTime).format("DD");
        eventTypes.push({
            repeat_type: REPEAT_MONTHLY,

            label: `Monthly On ${currentDate}`,
            value: REPEAT_MONTHLY
        });

        const currentMonthDate = moment(meetingStartTime).format("DD MMM");
        eventTypes.push({
            repeat_type: REPEAT_ANNUALLY,

            label: `Annually On ${currentMonthDate}`,
            value: REPEAT_ANNUALLY
        });

        if (!repeatType) {
            setRepeatType(eventTypes[0])
            setDropdownValue(eventTypes[0].value)
        }
        setRecurringEntries(eventTypes);

    }, [meetingStartTime])

    useEffect(() => {
        const getSquads = async () => {
            const response = await new ApiHandler().getSquads();
            if (response?.data) {
                setTeamList(response?.data || [])
            }
        }


        const getGroups = async () => {
            const response = await new ApiHandler().getChapters();
            if (response?.data) {
                setGroupList(response?.data || [])
            }
        }
        const getWorkspaceEmployees = async () => {

            const response = await new ApiHandler().getEmployees()
            if (response?.data) {
                setMemberList(response?.data || []);
            }

        }

        const getCalendarEntries = async () => {
            const response = await new ApiHandler().getCalendarEntryTypes();
            if (response?.data) {
                setCalendarEntries(response?.data?.data || []);
            }
        }


        getCalendarEntries();
        getSquads();
        getGroups();
        getWorkspaceEmployees();
    }, [])

    useEffect(() => {
        if (isFocused && mode != EVENT_MODE.create) {

            const eventData = params?.calendar_entry || eventInfo?.calendar_entry || {}
            const invite_all_members = params?.invite_all_members || eventInfo?.invite_all_members || false

            const {
                calendar_entry_type_id,
                created_at,
                description,
                end,
                id,
                location,
                meeting_id,
                organizer_id,
                organizer_type,
                recurring_calendar_entry_id,
                start,
                title,
                external_guests,
                guests,
                recurring_calendar_entry,
                video_conferencing
            } = eventData

            const teams = guests?.filter((item => {
                return item.guest_type == INVITEE_TYPE.team
            })).map(item => {
                return item.guest
            })

            const groups = guests?.filter((item => {
                return item.guest_type == INVITEE_TYPE.group
            })).map(item => {
                return item.guest
            })

            const members = guests?.filter((item => {
                return item.guest_type == INVITEE_TYPE.employee
            })).map(item => {
                return item.guest
            })

            const externalGuests = external_guests?.map(item => {
                return { email: item?.email_address, name: item?.name || "" }
            }) || [];

            setInviteAllMembers(invite_all_members);
            setEventTitle(title)
            setDescription(description)
            setLocation(location)
            setSelectedCategory({ id: calendar_entry_type_id })

            setMeetingStartTime(new Date(start));
            setMeetingEndTime(new Date(end));
            if (video_conferencing?.id) {
                setVideoConfStatus(true)
            }
            if (teams?.length > 0) {
                setSelectedTeams(teams)
            }

            if (groups?.length > 0) {
                setSelectedGroups(groups)
            }
            if (members?.length > 0) {
                setSelectedMembers(members)
            }

            if (recurring_calendar_entry) {
                if (recurring_calendar_entry?.interval_type == "days") {

                    const data = recurringEntries.find(item => {
                        return item.repeat_type == REPEAT_DAILY
                    })
                    if (data?.label) {
                        setRepeatType(data)
                        setRepeatTypeCheck(data);
                        setDropdownValue(data.value)
                    }

                } else if (recurring_calendar_entry?.interval_type == "weeks") {

                    const data = recurringEntries.find(item => {
                        return item.repeat_type == REPEAT_WEEKLY_ON_DAY
                    })
                    if (data?.label) {
                        setRepeatType(data)
                        setRepeatTypeCheck(data);
                        setDropdownValue(data.value)
                    }

                } else if (recurring_calendar_entry?.interval_type == "months") {

                    const data = recurringEntries.find(item => {
                        return item.repeat_type == REPEAT_MONTHLY
                    })
                    if (data?.label) {
                        setRepeatType(data)
                        setRepeatTypeCheck(data);
                        setDropdownValue(data.value)
                    }

                } else if (recurring_calendar_entry?.interval_type == "years") {

                    const data = recurringEntries.find(item => {
                        return item.repeat_type == REPEAT_ANNUALLY
                    })
                    if (data?.label) {
                        setRepeatType(data)
                        setRepeatTypeCheck(data);
                        setDropdownValue(data.value)
                    }

                }
            } else {
                setDropdownValue(STATIC_RECURRING_TYPES[0].value)
                setRepeatType(STATIC_RECURRING_TYPES[0])
                setRepeatTypeCheck(STATIC_RECURRING_TYPES[0]);
            }

            if (externalGuests?.length > 0) {
                setInvitedGuestList(externalGuests)
            }

        }
    }, [
        params,
        isFocused,
        mode,
        teamsList,
        groupList,
        memberList,
        calendarEntries
    ])

    useEffect(() => {

        return () => {

            setLoading(false)
            setEventTitle("")
            setDescription("")
            setLocation("")
            setHeight(100)
            setSelectedCategory("");

            setDateTimePickerVisibility(false)
            setPickerType(PICKER_TYPE_START)

            setMeetingStartTime()
            setMeetingEndTime()

            setSelectedTeams([])
            setTeamList([])

            setModalVisible(false)
            showRecurringModal(false);

            setSelectedGroups([])
            setGroupList([])
            setGroupsModalVisible(false)

            setSelectedMembers([])
            setMemberList([])
            setMemberModalVisible(false)

            setGuestEmail("")
            setGuestName("")
            setInvitedGuestList([])
            setGuestModalVisible(false)

            setCalendarEntries([])
            setRecurringEntries()
            setRepeatType()
            setOpenDropDown(false)
            setDropdownValue()
            setVideoConfStatus(false)

            setInviteAllMembers(false)

        }
    }, [])

    useEffect(() => {
        console.log(meetingStartTime)
    }, [meetingStartTime])

    const hideDatePicker = () => {
        setDateTimePickerVisibility(false);
    };

    const handleConfirm = (date) => {

        setDateTimePickerVisibility(false);
        if (pickerType === PICKER_TYPE_START) {
            setMeetingStartTime(date);
        } else if (pickerType === PICKER_TYPE_END) {
            setMeetingEndTime(date);
        }
    };

    const Header = () => {
        return (
            <TouchableOpacity
                style={{ flexDirection: "row", alignItems: "center" }}
                onPress={() => { navigation?.goBack?.() }}
                disabled={isWeb}
            >
                {
                    !isWeb
                    &&
                    <Icon
                        icon="arrowLeft"
                        color={theme?.basics[900]}
                        width={18}
                        height={18}
                    />
                }
                <Text style={[styles.headTitle, isWeb && { paddingLeft: 0 }]} >{title}</Text>
            </TouchableOpacity>

        )
    }

    const InputSection = React.useMemo(() => {

        return (
            <>
                <TextField
                    label="Title"
                    value={eventTitle}
                    onChangeText={(txt) => { setEventTitle(txt) }}
                />


                <TextField
                    label="Description"
                    value={description}
                    onChangeText={(txt) => { setDescription(txt) }}
                    multiline
                    onContentSizeChange={(event) => {
                        setHeight(event.nativeEvent.contentSize.height)
                    }}
                    style={{
                        height
                    }}
                />

                <TextField
                    label="Location"
                    value={location}
                    onChangeText={(txt) => { setLocation(txt) }}

                />


            </>
        )
    }, [eventTitle, description, location]);

    const Category = React.useMemo(() => {

        return (
            <>
                <Text
                    style={[styles.categoryTxt, { color: "#6D6D6D", marginTop: 30, marginBottom: 8 }]}
                >
                    {"Category"}
                </Text>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }} >
                    {
                        calendarEntries?.map((item, i) => {
                            return (
                                <TouchableOpacity
                                    key={i}
                                    onPress={() => { setSelectedCategory(item) }}
                                    style={
                                        [
                                            styles.category,
                                            { marginRight: 8, marginTop: 12 },
                                            selectedCategory?.id == item?.id && styles.categorySelected
                                        ]
                                    }
                                >
                                    <Text
                                        style={[styles.categoryTxt, selectedCategory?.id == item?.id && styles.categoryTxtSelected]}
                                    >{item?.name}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                </View>
            </>
        )
    }, [selectedCategory, calendarEntries])

    const StartAndEnd = React.useMemo(() => {

        return (
            <>

                <Text style={styles.startEndTxt} >{"Starts"}</Text>
                {
                    Platform.OS == "web"
                        ?
                        <View
                            style={{
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >
                            <TouchableOpacity onPress={() => {
                                // console.clear()
                                // console.log(">>>>>>", startRef.current)
                                // startRef?.current?.onClick?.()
                            }}
                                disabled={true}>

                                <Icon
                                    icon={"calendar"}
                                    color={theme?.basics[900]}
                                    width={26}
                                    height={26}
                                />
                            </TouchableOpacity>

                            <input
                                ref={startRef}
                                type="datetime-local"
                                value={moment(meetingStartTime).format(DATETIME_INPUT_WEB_FORMAT)}
                                onChange={(e) => {
                                    setMeetingStartTime(e.target.value)
                                }}
                                style={{
                                    fontFamily: 'WorkSans_400Regular',
                                    fontSize: 15,
                                    color: theme.basics['black_text'],

                                    borderWidth: 0,
                                    border: 0,
                                    marginLeft: 8
                                }}
                            />

                        </View>
                        :
                        <TouchableOpacity
                            onPress={() => {
                                setPickerType(PICKER_TYPE_START)
                                setDateTimePickerVisibility(true)
                            }}
                            style={{
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >

                            <Icon
                                icon={"calendar"}
                                color={theme?.basics[900]}
                                width={26}
                                height={26}
                            />
                            <Text style={styles.dateTimeStr} >{moment(meetingStartTime).format("dddd, DD MMMM YYYY - HH:mm")}</Text>
                        </TouchableOpacity>
                }
                <Text style={{ fontSize: 12, fontFamily: 'WorkSans_400Regular', marginTop: 5 }}>{member?.timezone?.display_name}</Text>

                <Text style={styles.startEndTxt} >{"Ends"}</Text>
                {
                    Platform.OS == "web"
                        ?
                        <View
                            style={{
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >

                            <Icon
                                icon={"calendar"}
                                color={theme?.basics[900]}
                                width={26}
                                height={26}
                            />

                            <input
                                type="datetime-local"
                                value={moment(meetingEndTime).format(DATETIME_INPUT_WEB_FORMAT)}
                                onChange={(e) => {
                                    setMeetingEndTime(e.target.value)
                                }}
                                style={{
                                    fontFamily: 'WorkSans_400Regular',
                                    fontSize: 15,
                                    color: theme.basics['black_text'],

                                    borderWidth: 0,
                                    border: 0,
                                    marginLeft: 8
                                }}
                            />

                        </View>
                        :
                        <TouchableOpacity
                            onPress={() => {
                                setPickerType(PICKER_TYPE_END)
                                setDateTimePickerVisibility(true)
                            }}
                            style={{
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center"
                            }}
                        >

                            <Icon
                                icon={"calendar"}
                                color={theme?.basics[900]}
                                width={26}
                                height={26}
                            />
                            <Text style={styles.dateTimeStr} >{moment(meetingEndTime).format("dddd, DD MMMM YYYY - HH:mm")}</Text>
                        </TouchableOpacity>

                }
                <Text style={{ fontSize: 12, fontFamily: 'WorkSans_400Regular', marginTop: 5 }}>{member?.timezone?.display_name}</Text>

                <View style={{ height: 16 }} />

                <DateTimePicker
                    isDatePickerVisible={dateTimePickerVisible}
                    setDatePickerVisibility={setDateTimePickerVisibility}
                    handleConfirm={handleConfirm}
                    hideDatePicker={hideDatePicker}
                    dateTime={pickerType === PICKER_TYPE_START ? new Date(meetingStartTime) : new Date(meetingEndTime)}

                />
                {
                    isWeb
                        ?
                        <View
                            style={{ zIndex: 9999, flexDirection: "row", flex: 1 }}
                        >
                            <Icon
                                icon={"refresh"}
                                color={'#000'}
                                width={26}
                                height={26}
                            />
                            <DropDownPicker
                                open={openDropDown}
                                value={dropDownValue}
                                items={recurringEntries}
                                setOpen={setOpenDropDown}

                                setValue={(value) => {
                                    setDropdownValue(value)
                                }}

                                setItems={setRecurringEntries}
                                placeholder="Repeat Type"
                                itemSeparator={true}
                                // listMode="MODAL"
                                listMode="SCROLLVIEW"
                                scrollViewProps={{
                                    nestedScrollEnabled: true,
                                    decelerationRate: 'fast',
                                }}
                                selectedItemContainerStyle={{
                                    flexDirection: 'row',
                                }}
                                dropDownContainerStyle={{
                                    flex: 1,
                                    justifyContent: 'space-around',
                                    flexDirection: 'row',
                                    backgroundColor: '#fff',
                                    borderWidth: 0,
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.25,
                                    shadowRadius: 3.84,
                                    elevation: 5,

                                    paddingVertical: 10,
                                    paddingHorizontal: 15,
                                    zIndex: 999,

                                    fontFamily: 'WorkSans_400Regular',
                                    fontSize: 14,
                                    color: "#1B1B1B",
                                    // marginTop: 8

                                }}
                                itemSeparatorStyle={{
                                    backgroundColor: 'lightgray',
                                    marginVertical: 7,
                                }}
                                style={{
                                    backgroundColor: '#fff',
                                    zIndex: 99999,
                                    flex: 1,

                                    borderRadius: 4,
                                    marginBottom: 15,

                                    borderColor: '#fff',
                                    borderWidth: 0,
                                    flexDirection: 'row',
                                    paddingTop: 8,
                                    paddingBottom: 20,
                                    paddingHorizontal: 5,


                                    fontFamily: 'WorkSans_400Regular',
                                    fontSize: 18,
                                    color: "#1B1B1B",

                                }}
                            />
                        </View>
                        :
                        <Menu>
                            <MenuTrigger>
                                <View style={{ flexDirection: "row", alignItems: "center" }} >
                                    {
                                        (() => {
                                            const value = recurringEntries.find(item => {
                                                return item.repeat_type === repeatType.repeat_type
                                            })

                                            return <TouchableOpacity
                                                disabled={true}
                                                style={{
                                                    flex: 1,
                                                    flexDirection: "row",
                                                    alignItems: "center"
                                                }}
                                            >

                                                <Icon
                                                    icon={"refresh"}
                                                    color={theme?.basics[900]}
                                                    width={26}
                                                    height={26}
                                                />
                                                <Text style={styles.dateTimeStr} >{value?.label}</Text>
                                            </TouchableOpacity>

                                        })()
                                    }

                                    <Icon
                                        icon={'chevronDown'}
                                        color={theme?.basics[900]}
                                        width={14}
                                        height={14}
                                    />
                                </View>
                            </MenuTrigger>


                            <MenuOptions>
                                {
                                    recurringEntries.map((item, i) => {
                                        return (

                                            <MenuOption
                                                key={i}
                                                onSelect={() => setRepeatType(item)}
                                            >
                                                <Text style={styles.dropDownMenuItem} >{item?.label}</Text>
                                            </MenuOption>
                                        )
                                    })
                                }


                            </MenuOptions>
                        </Menu>
                }


            </>
        )
    }, [repeatType, dateTimePickerVisible, handleConfirm, hideDatePicker, recurringEntries]);

    const VideoConference = React.useMemo(() => {

        return (
            <TouchableOpacity
                style={[styles.addConfBtn, videoConfStatus && styles.videoConfActive]}
                onPress={() => {
                    setVideoConfStatus(!videoConfStatus)
                }}
            >
                <Text style={[styles.videoConf, videoConfStatus && styles.videoConfActive]} >{videoConfStatus ? "Disable video conference" : "Enable video conference"}</Text>
                <View style={{ marginTop: 10 }} >
                    <Icon
                        icon="video"
                        width={24}
                        height={28}
                        color={videoConfStatus ? "#fff" : "#000"}
                    />
                </View>
            </TouchableOpacity>
        )

    }, [videoConfStatus])

    const toggleCheck = () => {
        setInviteAllMembers(!inviteAllMembers);
    }

    const InviteAllMembers = React.useMemo(() => {
        return (
            <View style={{ flexDirection: "row", alignItems: "center", marginVertical: 10 }} >
                <Checkbox onPress={toggleCheck} color={color_scheme_primary_button_background} status={inviteAllMembers ? "checked" : "unchecked"} />
                <Text style={[styles.inviteAllCheckbox, { color: color_scheme_primary_button_background }]} onPress={toggleCheck}>{"Invite All Members"}</Text>
            </View>
        )
    }, [inviteAllMembers])

    const Teams = React.useMemo(() => {
        return (
            <>
                <Text style={[styles.startEndTxt]} >
                    {"Invite Teams"}
                </Text>


                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: "#F2F2F2",
                        paddingVertical: 14,
                        paddingHorizontal: 8,
                        borderRadius: 4,
                        zIndex: 1

                    }}
                >
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }} >
                        {selectedTeams?.map((team, i) => {
                            return (

                                <View
                                    key={i}
                                    style={[styles.category, styles.categorySelected, {
                                        flexDirection: "row",
                                        marginTop: 12,
                                        marginRight: 8,
                                        alignItems: "center"
                                    }]}
                                >
                                    <Text
                                        style={[styles.categoryTxt, styles.categoryTxtSelected]}
                                    >
                                        {team?.name}
                                    </Text>

                                    <TouchableOpacity
                                        onPress={() => {
                                            const _teams = [...selectedTeams];

                                            _teams.splice(i, 1);
                                            setSelectedTeams(_teams)
                                        }}
                                        style={{ marginLeft: 8 }}
                                    >
                                        <Icon icon={'close'} color={"#fff"} height={12} width={12} />
                                    </TouchableOpacity>

                                </View>


                            )
                        })}

                    </View>

                    <TouchableOpacity onPress={() => { setModalVisible(true) }} >
                        <Icon icon={'chevronDown'} color={theme.basics.black_text} height={16} width={16} />
                    </TouchableOpacity>
                </View>

                <Modal
                    isVisible={isModalVisible}
                    onDismiss={() => { setModalVisible(false) }}
                    // backdropOpacity={0.3}
                    animationIn="slideInUp"
                    animationOut="slideOutDown"
                    style={{ alignSelf: 'center', borderRadius: 8 }}
                >
                    <View
                        style={{
                            borderRadius: 8,
                            maxHeight: 460,
                            backgroundColor: "#fff",
                            width: 320
                        }}
                    >
                        <ScrollView>
                            {
                                teamsList?.map((item, i) => {

                                    let isSelected = false;

                                    const checkTeamIndex = selectedTeams.findIndex(_team => {
                                        if (_team?.id === item?.id) {
                                            return _team;
                                        }
                                    })
                                    if (checkTeamIndex > -1) {
                                        isSelected = true
                                    }

                                    return (
                                        <TouchableOpacity
                                            key={i}
                                            onPress={() => {
                                                const _teams = [...selectedTeams];

                                                if (isSelected) {
                                                    _teams.splice(checkTeamIndex, 1);
                                                } else {
                                                    _teams.push(item);
                                                }

                                                setSelectedTeams(_teams)
                                            }}
                                            style={styles.modalItem}
                                        >
                                            <Text
                                                style={styles.categoryTxt}
                                            >
                                                {item?.name}
                                            </Text>

                                            {
                                                isSelected
                                                &&
                                                <AntDesign name="check" size={16} color={theme.basics.black_text} />
                                            }
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </ScrollView>


                        <ButtonPrimary
                            label={"Done"}
                            customStyles={{ marginTop: 30 }}
                            onPress={() => { setModalVisible(false) }}
                        />

                    </View>

                </Modal>
            </>
        )
    }, [teamsList, selectedTeams, isModalVisible])


    const Groups = React.useMemo(() => {
        return (
            <>
                <Text style={[styles.startEndTxt]} >
                    {"Invite Groups"}
                </Text>


                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: "#F2F2F2",
                        paddingVertical: 14,
                        paddingHorizontal: 8,
                        borderRadius: 4

                    }}
                >
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }} >
                        {selectedGroups?.map((group, i) => {
                            return (

                                <View
                                    key={i}
                                    style={[styles.category, styles.categorySelected, {
                                        flexDirection: "row",
                                        marginTop: 12,
                                        marginRight: 8,
                                        alignItems: "center"
                                    }]}
                                >
                                    <Text
                                        style={[styles.categoryTxt, styles.categoryTxtSelected]}
                                    >
                                        {group?.name}
                                    </Text>

                                    <TouchableOpacity
                                        onPress={() => {
                                            const groups = [...selectedGroups];

                                            groups.splice(i, 1);
                                            setSelectedGroups(groups)
                                        }}
                                        style={{ marginLeft: 8 }}
                                    >
                                        <Icon icon={'close'} color={"#fff"} height={12} width={12} />
                                    </TouchableOpacity>

                                </View>


                            )
                        })}

                    </View>

                    <TouchableOpacity onPress={() => { setGroupsModalVisible(true) }} >
                        <Icon icon={'chevronDown'} color={theme.basics.black_text} height={16} width={16} />
                    </TouchableOpacity>
                </View>

                <Modal
                    isVisible={isGroupsModalVisible}
                    onDismiss={() => { setGroupsModalVisible(false) }}
                    animationIn="slideInUp"
                    animationOut="slideOutDown"
                    style={{ alignSelf: 'center', borderRadius: 8 }}
                >
                    <View
                        style={{
                            borderRadius: 8,
                            maxHeight: 460,
                            backgroundColor: "#fff",
                            width: 320
                        }}
                    >
                        <ScrollView>
                            {
                                groupList?.map((item, i) => {

                                    let isSelected = false;

                                    const checkIndex = selectedGroups.findIndex(_group => {
                                        if (_group?.id === item?.id) {
                                            return _group;
                                        }
                                    })
                                    if (checkIndex > -1) {
                                        isSelected = true
                                    }

                                    return (
                                        <TouchableOpacity
                                            key={i}
                                            onPress={() => {
                                                const _groups = [...selectedGroups];

                                                if (isSelected) {
                                                    _groups.splice(checkIndex, 1);
                                                } else {
                                                    _groups.push(item);
                                                }

                                                setSelectedGroups(_groups)
                                            }}
                                            style={styles.modalItem}
                                        >
                                            <Text
                                                style={styles.categoryTxt}
                                            >
                                                {item?.name}
                                            </Text>

                                            {
                                                isSelected
                                                &&
                                                <AntDesign name="check" size={16} color={theme.basics.black_text} />
                                            }
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </ScrollView>


                        <ButtonPrimary
                            label={"Done"}
                            customStyles={{ marginTop: 30 }}
                            onPress={() => { setGroupsModalVisible(false) }}
                        />

                    </View>

                </Modal>
            </>
        )
    }, [groupList, selectedGroups, isGroupsModalVisible])


    const Members = React.useMemo(() => {
        return (
            <>
                <Text style={[styles.startEndTxt]} >
                    {"Invite Members"}
                </Text>


                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: "#F2F2F2",
                        paddingVertical: 14,
                        paddingHorizontal: 8,
                        borderRadius: 4

                    }}
                >
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }} >
                        {selectedMembers?.map((member, i) => {

                            const name = `${member?.first_name || ""} ${member?.last_name || ""}`.trim();

                            return (

                                <View
                                    key={i}
                                    style={[styles.category, styles.categorySelected, {
                                        flexDirection: "row",
                                        marginTop: 12,
                                        marginRight: 8,
                                        alignItems: "center"
                                    }]}
                                >
                                    <Text
                                        style={[styles.categoryTxt, styles.categoryTxtSelected]}
                                    >
                                        {name}
                                    </Text>

                                    <TouchableOpacity
                                        onPress={() => {
                                            const _members = [...selectedMembers];

                                            _members.splice(i, 1);
                                            setSelectedMembers(_members)
                                        }}
                                        style={{ marginLeft: 8 }}
                                    >
                                        <Icon icon={'close'} color={"#fff"} height={12} width={12} />
                                    </TouchableOpacity>

                                </View>


                            )
                        })}

                    </View>

                    <TouchableOpacity onPress={() => { setMemberModalVisible(true) }} >
                        <Icon icon={'chevronDown'} color={theme.basics.black_text} height={16} width={16} />
                    </TouchableOpacity>
                </View>

                <Modal
                    isVisible={isMemberModalVisible}
                    onDismiss={() => { setMemberModalVisible(false) }}
                    animationIn="slideInUp"
                    animationOut="slideOutDown"
                    style={{ alignSelf: 'center', borderRadius: 8 }}
                >
                    <View
                        style={{
                            borderRadius: 8,
                            maxHeight: 460,
                            backgroundColor: "#fff",
                            width: 320
                        }}
                    >
                        <ScrollView>
                            {
                                memberList?.map((item, i) => {

                                    let isSelected = false;

                                    const checkIndex = selectedMembers.findIndex(_team => {
                                        if (_team?.id === item?.id) {
                                            return _team;
                                        }
                                    })
                                    if (checkIndex > -1) {
                                        isSelected = true
                                    }

                                    const name = `${item?.first_name || ""} ${item?.last_name || ""}`.trim();

                                    return (
                                        <TouchableOpacity
                                            key={i}
                                            onPress={() => {
                                                const _members = [...selectedMembers];

                                                if (isSelected) {
                                                    _members.splice(checkIndex, 1);
                                                } else {
                                                    _members.push(item);
                                                }

                                                setSelectedMembers(_members)
                                            }}
                                            style={styles.modalItem}
                                        >
                                            <Text
                                                style={styles.categoryTxt}
                                            >
                                                {name}
                                            </Text>

                                            {
                                                isSelected
                                                &&
                                                <AntDesign name="check" size={16} color={theme.basics.black_text} />
                                            }
                                        </TouchableOpacity>
                                    )
                                })
                            }
                        </ScrollView>


                        <ButtonPrimary
                            label={"Done"}
                            customStyles={{ marginTop: 30 }}
                            onPress={() => { setMemberModalVisible(false) }}
                        />

                    </View>

                </Modal>
            </>
        )
    }, [memberList, selectedMembers, isMemberModalVisible])

    const Guests = React.useMemo(() => {

        return (
            <>

                <Text style={[styles.startEndTxt]} >
                    {"Invite Guests"}
                </Text>


                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: "#d0d0d0",
                        paddingVertical: 14,
                        paddingHorizontal: 8,
                        borderRadius: 4

                    }}
                >
                    <View style={{ flexDirection: "row", flexWrap: "wrap", flex: 1 }} >
                        {invitedGuests?.map((guest, i) => {

                            return (

                                <View
                                    key={i}
                                    style={[styles.category, styles.categorySelected, {
                                        flexDirection: "row",
                                        marginTop: 12,
                                        marginRight: 8,
                                        alignItems: "center"
                                    }]}
                                >
                                    <Text
                                        style={[styles.categoryTxt, styles.categoryTxtSelected]}
                                    >
                                        {guest?.name}
                                    </Text>

                                    <TouchableOpacity
                                        onPress={() => {

                                            const _guests = [...invitedGuests];
                                            _guests.splice(i, 1);
                                            setInvitedGuestList(_guests)

                                        }}
                                        style={{ marginLeft: 8 }}
                                    >
                                        <Icon icon={'close'} color={"#fff"} height={12} width={12} />
                                    </TouchableOpacity>

                                </View>


                            )
                        })}

                    </View>

                    <TouchableOpacity style={{ paddingVertical: 0 }} onPress={() => { setGuestModalVisible(true) }} >

                        <AntDesign name="pluscircleo" size={22} color={theme.basics.black_text} />

                    </TouchableOpacity>

                </View>

                <Modal
                    isVisible={isGuestModalVisible}
                    onDismiss={() => { setGuestModalVisible(false) }}
                    animationIn="slideInUp"
                    animationOut="slideOutDown"
                    style={{
                        borderRadius: 8,
                        justifyContent: "flex-start",
                        alignSelf: "center",
                        paddingTop: 24,
                        marginTop: 140
                    }}
                >
                    <View
                        style={{
                            borderRadius: 8,
                            maxHeight: 460,
                            backgroundColor: "#fff",
                            width: isWeb ? 340 : width - 40,
                            paddingVertical: 16,
                            paddingHorizontal: 8
                        }}
                    >

                        <TextField
                            label="Enter Guest Name"
                            value={guestName}
                            onChangeText={(txt) => { setGuestName(txt) }}
                        />
                        {guestName == "" && <Text style={styles.errorMsg} >{"Name cannot be empty"}</Text>}
                        <TextField
                            label="Enter Guest Email"
                            value={guestEmail}
                            onChangeText={(txt) => { setGuestEmail(txt) }}
                            keyboardType={"email-address"}
                        />

                        {!validateEmail(guestEmail) && <Text style={styles.errorMsg} >{"Email is not valid"}</Text>}

                        <View
                            style={{
                                flexDirection: "row",
                                paddingVertical: 14,
                                marginTop: 30,

                            }}
                        >
                            <ButtonPrimary
                                label={"Cancel"}
                                customStyles={{ flex: 1, marginRight: 4 }}
                                onPress={() => { setGuestModalVisible(false) }}
                            />
                            <ButtonPrimary
                                label={"Add"}
                                customStyles={{ flex: 1, marginLeft: 4 }}
                                onPress={() => {

                                    const _guests = [...invitedGuests, { email: guestEmail, name: guestName }];

                                    setInvitedGuestList(_guests)
                                    setGuestEmail("");
                                    setGuestName("")
                                    setGuestModalVisible(false)
                                }}
                                _disabled={guestEmail == "" || !validateEmail(guestEmail) || guestName == ""}
                            />
                        </View>
                    </View>

                </Modal>
            </>
        )

    }, [isGuestModalVisible, guestName, guestEmail, invitedGuests])

    const RecurringModal = React.useMemo(() => {

        return (
            <Modal
                onDismiss={() => { showRecurringModal(false) }}
                // backdropOpacity={0.3}
                animationIn="slideInUp"
                animationOut="slideOutDown"
                isVisible={recurringModal} style={styles.modal}
            >


                <View style={styles.modalBody} >

                    <Text style={styles.confirmation} >{`Update the Current Event only\nor\nEntire Series?`}</Text>
                    <View style={{ flexDirection: "row", marginTop: 24 }} >


                        <ButtonPrimary
                            label={"Current Event"}
                            customStyles={{
                                marginLeft: 12
                            }}
                            onPress={() => { updateEventNow(0) }}
                            loading={isLoading}
                        />

                        <ButtonPrimary
                            label={"Entire Series"}
                            customStyles={{
                                marginLeft: 12
                            }}
                            onPress={() => { updateEventNow(1) }}
                            loading={isLoading}
                        />

                    </View>


                </View>

            </Modal>
        )
    }, [recurringModal, isLoading])

    const createEventNow = async () => {


        let message = ""
        if (eventTitle.trim() === "") {
            message = "Title is required"
        } else if (description.trim() === "") {
            message = "Description is required"
        } else if (location.trim() === "") {
            message = "Location is required"
        } else if (!selectedCategory?.id) {
            message = "Category is required"
        } else {

            const format = "X";

            setLoading(true);

            try {

                let interval = {};

                if (repeatType.repeat_type === REPEAT_NONE) {
                    interval = { interval_type: "dont_repeat" };

                } else if (repeatType.repeat_type === REPEAT_DAILY) {

                    interval = {
                        interval_type: "days",
                        rdays: 1,
                    }

                }
                else if (repeatType.repeat_type === REPEAT_WEEKLY_ON_DAY) {


                    interval = {
                        rweekday: moment(meetingStartTime).format("dddd").toLowerCase(),
                        rdays: 1,
                        interval_type: "weeks"
                    }

                } else if (repeatType.repeat_type === REPEAT_MONTHLY) {

                    interval = {
                        rmonth: moment(meetingStartTime).format("YYYY-MM-DD"),
                        rdays: 1,
                        interval_type: "months"
                    }

                } else if (repeatType.repeat_type === REPEAT_ANNUALLY) {


                    interval = {
                        ryear: moment(meetingStartTime).format("YYYY-MM-DD"),
                        rdays: 1,
                        interval_type: "years"
                    }

                }

                const data = {
                    title: eventTitle,
                    start_time: moment(meetingStartTime).format(format),
                    end_time: moment(meetingEndTime).format(format),
                    video_conferencing: videoConfStatus ? 1 : 0,

                    if_invite_all_members: inviteAllMembers ? 1 : 0,

                    calendar_entry_type_id: selectedCategory?.id,
                    description: description,
                    location: location,

                    teams: selectedTeams.map(item => item.id),
                    groups: selectedGroups.map(item => item.id),
                    employees: selectedMembers.map(item => item.id),

                    external: invitedGuests.map(item => item),

                    ...interval
                }

                const response = await new ApiHandler().createCalendarEvent(data);

                Toast.show({
                    type: 'success',
                    position: 'top',
                    text1: response?.data?.message || "",
                    visibilityTime: 2000
                })

                if (mode == EVENT_MODE.duplicate) {
                    if (isWeb) {
                        showModal(false)
                        setReloading(true)
                    } else {
                        const eventId = response?.data?.data?.id;
                        navigation.navigate("EventDetail", { id: eventId });
                    }

                } else {
                    if (isWeb) {
                        showModal(false)
                        setReloading(true)
                    } else {
                        navigation.goBack();
                    }
                }



            } catch (error) {

            } finally {
                setLoading(false);
            }

            return
        }

        Toast.show({
            type: 'error',
            position: 'top',
            text1: message,
        })

    }

    const updateEventNow = async (recurringChangeType = -1) => {
        let message = ""
        if (eventTitle.trim() === "") {
            message = "Title is required"
        } else if (description.trim() === "") {
            message = "Description is required"
        } else if (location.trim() === "") {
            message = "Location is required"
        } else if (!selectedCategory?.id) {
            message = "Category is required"
        } else {

            const format = "X";

            setLoading(true);

            try {


                let interval = {};

                if (repeatType.repeat_type === REPEAT_NONE) {

                    interval = { interval_type: "dont_repeat" };

                } else if (repeatType.repeat_type === REPEAT_DAILY) {

                    interval = {
                        interval_type: "days",
                        rdays: 1,
                    }

                }

                else if (repeatType.repeat_type === REPEAT_WEEKLY_ON_DAY) {


                    interval = {
                        rweekday: moment(meetingStartTime).format("dddd").toLowerCase(),
                        rdays: 1,
                        interval_type: "weeks"
                    }

                } else if (repeatType.repeat_type === REPEAT_MONTHLY) {

                    interval = {
                        rmonth: moment(meetingStartTime).format("YYYY-MM-DD"),
                        rdays: 1,
                        interval_type: "months"
                    }

                } else if (repeatType.repeat_type === REPEAT_ANNUALLY) {


                    interval = {
                        ryear: moment(meetingStartTime).format("YYYY-MM-DD"),
                        rdays: 1,
                        interval_type: "years"
                    }

                }


                const eventData = params?.calendar_entry || eventInfo?.calendar_entry

                const {
                    id
                } = eventData

                const eventId = id;
                const data = {
                    title: eventTitle,
                    start_time: moment(meetingStartTime).format(format),
                    end_time: moment(meetingEndTime).format(format),
                    video_conferencing: videoConfStatus ? 1 : 0,

                    calendar_entry_type_id: selectedCategory?.id,
                    description: description,
                    location: location,

                    teams: selectedTeams.map(item => item.id),
                    groups: selectedGroups.map(item => item.id),
                    employees: selectedMembers.map(item => item.id),

                    external: invitedGuests.map(item => item),

                    ...interval,
                    if_interval_changed: repeatTypeCheck.repeat_type == repeatType.repeat_type ? 0 : 1,

                }

                if (recurringChangeType != -1) {
                    data["if_change_recurring"] = recurringChangeType
                }

                const response = await new ApiHandler().editCalendarEvent(eventId, data);
                showRecurringModal(false)

                Toast.show({
                    type: 'success',
                    position: 'top',
                    text1: response?.data?.message || "",
                    visibilityTime: 2000
                })
                if (isWeb) {
                    showModal(false)
                    setReloading(true)
                } else {
                    navigation.goBack();
                }

            } catch (error) {

            } finally {
                setLoading(false);
            }

            return
        }

        Toast.show({
            type: 'error',
            position: 'top',
            text1: message,
            visibilityTime: 2000
        })

    }
    const showRecurringUpdateModal = () => {
        showRecurringModal(true);
    }

    const ActionButtons = () => {

        let btnLabel = "Create";
        let btnAction = createEventNow;

        if (mode === EVENT_MODE.duplicate) {
            btnLabel = "Duplicate";
        } else if (mode === EVENT_MODE.edit) {
            btnLabel = "Save Changes";

            // if (repeatTypeCheck?.repeat_type == REPEAT_NONE) {
            //     btnAction = updateEventNow;
            // } else

            if (
                repeatTypeCheck?.repeat_type == repeatType?.repeat_type
                &&
                repeatTypeCheck?.repeat_type == REPEAT_NONE
            ) {
                btnAction = updateEventNow;
            } else {
                btnAction = showRecurringUpdateModal
            }
        }

        return (
            <View
                style={{
                    width: '100%',
                    paddingHorizontal: 12,
                    paddingTop: 16,
                    paddingBottom: 8,
                    zIndex: 999,
                    backgroundColor: "#fff"
                }}
            >


                <ButtonPrimary
                    loading={isLoading}
                    label={btnLabel}
                    textStyles={styles.actionBtnTxt}
                    onPress={() => { if (btnAction) btnAction() }}
                />


            </View>
        )
    }

    return (
        <>

            <PageHeader
                title={title}
                icon={false}
                backEnabled={isWeb ? false : true}
            />

            <PageContent>
                <ContentBlockV1>
                    {InputSection}

                    {Category}

                    {StartAndEnd}

                    {VideoConference}

                    {InviteAllMembers}

                    {Teams}

                    {Groups}

                    {Members}

                    {Guests}

                    <ActionButtons />
                </ContentBlockV1>
            </PageContent>

            {RecurringModal}
        </>
    )
}

export default Event

const styles = StyleSheet.create({
    spacer: {
        paddingHorizontal: 45,
        paddingVertical: 40,
    },
    spacerMobile: {
        paddingHorizontal: 15,
        paddingVertical: 20,
    },
    container: {
        flex: 1,
        maxWidth: 700
    },
    mobContainer: {
        flex: 1,
    },

    headTitle: {

        color: theme.basics['black_text'],
        paddingLeft: 15,
        fontFamily: 'WorkSans_400Regular',
        fontSize: 18
    },
    dropDownMenuItem: {

        color: theme.basics['black_text'],
        padding: 5
    },

    actionBtnTxt: {
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 19,
        lineHeight: 20,
        textAlign: "center",
        color: "#fff"

    },

    category: {
        borderWidth: 1,
        borderColor: "#a0a0a0",
        borderRadius: 4,
        paddingHorizontal: 14,
        paddingVertical: 6,
    },
    categorySelected: {
        backgroundColor: "#F05343",
        borderColor: "#a0a0a000",
    },
    categoryTxt: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
        color: theme.basics['black_text'],
    },
    categoryTxtSelected: {
        color: "#fff"
    },

    modalItem: {
        paddingVertical: 12,
        paddingHorizontal: 14,
        borderBottomWidth: 1,
        borderColor: "#a0a0a0",
        flexDirection: "row",
        justifyContent: "space-between"
    },

    startEndTxt: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 13,
        lineHeight: 18,
        color: "#6D6D6D",
        marginVertical: 14
    },

    dateTimeStr: {
        flex: 1,
        fontFamily: 'WorkSans_400Regular',
        fontSize: 15,
        lineHeight: 20,
        color: theme.basics['black_text'],
        marginLeft: 12
    },

    modal: {
        paddingVertical: 16,
        paddingHorizontal: 12,
        alignItems: "center",
        justifyContent: "center",
    },
    modalBody: {
        alignItems: "center",
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 10,

    },
    confirmation: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 18,
        color: "#1B1B1B",
        textAlign: "center"
    },

    errorMsg: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
        color: "#F05343",
        marginTop: 4,
        marginBottom: 8

    },

    addConfBtn: {
        backgroundColor: "#F2F2F2",
        alignItems: "center",
        justifyContent: "center",
        height: 50,
        borderRadius: 4,
        marginVertical: 18,
        flexDirection: "row",
    },
    videoConfActive: {
        backgroundColor: "#F05343",
        color: "#fff",
    },

    videoConf: {
        fontFamily: 'WorkSans_600SemiBold',
        fontSize: 17,
        lineHeight: 18,
        color: "#5A5A5A",
        marginRight: 8
    },


    inviteAllCheckbox: {
        fontFamily: "WorkSans_400Regular",
        fontSize: 12,
        lineHeight: 15,
        color: "#222B45",
    },

})
