import React, { useContext } from 'react'
import { View, Text, TouchableHighlight } from 'react-native';
import ChatContext from '@/contexts/ChatContext';
import { useIsMobile } from '@/styles';
import { useNavigation } from '@react-navigation/native';
import { updateCurrentConvo } from '../../helpers';

const Conversation = ({ conversation, lastMessage, isMyMessage, lastUpdated }) => {
    const { updateCurrentConversation, addNotifications, updateParticipants, messages, setLastReadIndex, updateUnreadMessages, } = useContext(ChatContext);
    const isMobile = useIsMobile();
    const navigation = useNavigation();


    const _getMessages = async () => {
        try {
            setLastReadIndex(conversation.lastReadMessageIndex ?? -1);
            await updateCurrentConvo(
                updateCurrentConversation,
                conversation,
                updateParticipants
            );
            //update unread messages
            updateUnreadMessages(conversation.sid, 0);
            //set messages to be read
            const lastMessage =
                messages?.[conversation.sid].length &&
                messages?.[conversation.sid][messages?.[conversation.sid].length - 1];
            if (lastMessage && lastMessage.index !== -1) {
                await conversation.updateLastReadMessageIndex(lastMessage.index);
            }

            navigation.navigate('messages');

        } catch (error) {
            alert(error);
            unexpectedErrorNotification(addNotifications);
        }
    }

    return (
        <TouchableHighlight underlayColor="#e3e3e3" onPress={_getMessages}
            style={{
                backgroundColor: 'white',
                paddingVertical: 10,
                paddingHorizontal: 15,
                borderRadius: 5,
                shadowColor: 'rgba(2, 2, 2, 1)',
                shadowOffset: { width: 2, height: 1 },
                shadowOpacity: 0.10,
                shadowRadius: 20,
                elevation: 3,
            }}
        >
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 7 }}>
                    <Text style={{ fontWeight: "600", fontFamily: 'WorkSans_400Regular', fontSize: 15, color: "#f05343" }}>{conversation.friendlyName}</Text>
                    <Text numberOfLines={1} style={{ maxWidth: '80%', overflow: 'hidden', color: "#000000", paddingVertical: 5 }}>{lastMessage}</Text>
                </View>
                <View style={{ flex: 3, justifyContent: 'center', alignItems: 'flex-end' }}>
                    <Text style={{ fontSize: 12, color: "#969696" }}>{lastUpdated}</Text>
                </View>
            </View>
        </TouchableHighlight>
    )
}

export default Conversation;