import React from 'react'
import App from './Web';


const TwilioConversations = () => {
    return (
        <>
            <App />
        </>
    )
}

export default TwilioConversations;