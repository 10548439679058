import React from 'react'
import { ScrollView } from 'react-native'
import ParticipantView from '../ParticipantView'

const Participants = ({ participants }) => {
    const pressHandler = (participant) => { }
    return (
        <>
            <ScrollView style={{ flex: 1 }}>
                {Array.from(participants, ([sid, participant]) => (
                    <ParticipantView onPress={() => pressHandler(participant)} participant={participant} />
                ))}
            </ScrollView>
        </>
    )
}

export default Participants;