import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function ReframeHorizontalLogo(props) {
  return (
    <Svg width="183" height="43" viewBox="0 0 183 43" fill="none" {...props}>
      <Path
        fill="#F05343"
        d="M148.332 8.39598V34.5167H174.541V8.39598H148.332ZM161.437 29.821C160.795 29.8214 160.168 29.6322 159.634 29.2772C159.1 28.9222 158.684 28.4175 158.438 27.8268C158.192 27.2361 158.128 26.5861 158.253 25.9588C158.378 25.3316 158.686 24.7554 159.14 24.3031C159.594 23.8508 160.172 23.5427 160.801 23.4178C161.43 23.2929 162.082 23.3568 162.675 23.6014C163.268 23.846 163.775 24.2603 164.131 24.792C164.488 25.3237 164.678 25.9488 164.678 26.5882C164.678 27.4453 164.337 28.2672 163.729 28.8734C163.121 29.4796 162.296 29.8204 161.437 29.821ZM161.437 19.5551C160.795 19.5555 160.168 19.3663 159.634 19.0113C159.1 18.6563 158.684 18.1516 158.438 17.5609C158.192 16.9702 158.128 16.3202 158.253 15.693C158.378 15.0657 158.686 14.4895 159.14 14.0372C159.594 13.5849 160.172 13.2768 160.801 13.1519C161.43 13.027 162.082 13.0909 162.675 13.3355C163.268 13.5801 163.775 13.9945 164.131 14.5261C164.488 15.0578 164.678 15.6829 164.678 16.3224C164.678 17.1794 164.337 18.0013 163.729 18.6075C163.121 19.2137 162.296 19.5545 161.437 19.5551Z"
      />
      <Path
        fill="black"
        d="M25.5477 17.1404V20.5693H26.4398C27.5874 20.5693 28.0962 19.9599 28.0962 18.8434C28.0962 17.7999 27.6376 17.1404 26.3896 17.1404H25.5477ZM29.3967 29.2032L27.1036 24.1506H25.5477V29.2032H21.2758V13.5654H26.2199C30.6782 13.5654 32.877 15.7192 32.877 18.8935C32.877 20.4942 32.2655 21.8654 31.118 22.8296L34.1251 29.2032H29.3967ZM46.2896 17.1404H40.6251V19.5029H45.2886V23.0842H40.6251V25.6219H46.5639V29.2032H36.3448V13.5654H46.3084L46.2896 17.1404ZM59.2729 17.1404H53.6084V19.5029H58.2719V23.0842H53.6084V29.2032H49.3281V13.5654H59.2917L59.2729 17.1404ZM65.597 17.1404V20.5693H66.4891C67.6366 20.5693 68.1455 19.9599 68.1455 18.8434C68.1455 17.7999 67.6869 17.1404 66.4388 17.1404H65.597ZM69.4438 29.2032L67.1508 24.1506H65.597V29.2032H61.3355V13.5654H66.2796C70.7379 13.5654 72.9304 15.7254 72.9304 18.8998C72.9505 19.6451 72.8029 20.3854 72.4986 21.0665C72.1942 21.7476 71.7407 22.3521 71.1714 22.8358L74.1785 29.2095L69.4438 29.2032ZM82.7433 23.3889H84.9609L83.9097 18.8434L82.7433 23.3889ZM81.6962 13.5592H86.1817L91.6599 29.2116H86.9419L86.1776 26.9785H81.4889L80.7246 29.2116H76.2391L81.6962 13.5592ZM110.777 13.5654L111.797 29.2178H107.506L106.892 19.7972L103.504 29.2178H101.563L98.1494 19.745L97.5379 29.2178H93.2597L94.2795 13.5654H99.1211L102.61 22.8296L106.027 13.5654H110.777ZM124.807 17.1467H119.122V19.5029H123.785V23.0842H119.122V25.6219H125.06V29.2032H114.841V13.5654H124.807V17.1467ZM182.97 42.9189V0H0V42.9189H182.97ZM145.067 5.14442H177.806V37.7745H145.057L145.067 5.14442ZM5.1619 5.14442H139.905V37.7745H5.15143L5.1619 5.14442Z"
      />
    </Svg>
  )
}
