import styled from 'styled-components/native'
import { StyleSheet, Dimensions, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { isMobile } from '@/styles'
import { Headline6 } from '@/styles/fonts'
import { ScreenSize, useScreenSize } from '@/styles'

const { height, width } = Dimensions.get('window')

export const styles = StyleSheet.create({
  keyboardAvoid: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    flex: 1,
    fontSize: 15
  },
  PostInputWrapper: {
    ...Platform.select({
      ios: {
        minHeight: 140
      },
      android: {
        minHeight: 140
      },
      web: {
        minHeight: 140
      }
    })
  }
})

export const Container = styled(SafeAreaView)`
  flex: 1;
  height: 100%;
  ${isMobile && `
  display: flex;
  align-items: center;
  justify-content: center;
  `}
`

export const KeyboardDismiss = styled.TouchableWithoutFeedback``

// export const ContentContainer = styled.TouchableOpacity`
//   background-color: ${({ theme }) => theme.basics.background_white};
//   padding: 15px ${isMobile ? 15 : 20}px 0;
// 	max-height: 90%;
// 	width: ${isMobile ? 350 : 699}px;
// 	height: ${isMobile ? 334 : 334}px;
//   align-self: center;
// `



export const ContentContainer = styled.TouchableOpacity`
  background-color: ${({ theme }) => theme.basics.background_white};
  padding: 15px ${isMobile ? 15 : 20}px 0;
	max-height: 90%;
	width: ${isMobile ? 350 : !isMobile && width >= ScreenSize.Medium ? 699 : !isMobile && width <= ScreenSize.Medium && 350}px;
	height: ${isMobile ? Platform.OS == 'ios' ? height - 200 : height - 100 : !isMobile && width >= ScreenSize.Medium ? 434 : !isMobile && width <= ScreenSize.Medium && height - 100}px;
  align-self: center;
  max-height: ${isMobile ? Platform.OS == 'ios' ? height - 200 : height - 100 : !isMobile && width >= ScreenSize.Medium ? 334 : !isMobile && width <= ScreenSize.Medium && height - 100}px;
`

export const HeaderContainer = styled.View`

`

export const HeaderLabel = styled(Headline6)`
  font-size: ${isMobile ? 18 : 24}px;
`

export const AuthorContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-bottom: 10px;
`

export const AuthorInfoContainer = styled.View`
  flex: 1;
  display: flex;
  margin-left: 10px;
  flex-direction: column;
  justify-content: center;
`

export const CloseButton = styled.TouchableOpacity`
	margin-top: 13px;
	align-self: flex-end;
`

export const PostInputWrapper = styled.View`
	flex-grow: 1;
	flex-shrink: 1;
`

export const PostButtonWrapper = styled.View`
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${isMobile ? 'center' : 'flex-end'};
`
