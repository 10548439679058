import React, { useContext, useState } from "react";
import ConversationTitleModal from "../modals/ConversationTitleModal";
import { Button } from "@twilio-paste/button";
import { PlusIcon } from "@twilio-paste/icons/esm/PlusIcon";
import ChatContext from "@/contexts/ChatContext";
import { addConversation } from "@/pages/TwilioConversations/api";


const CreateConversationButton = (
  props
) => {
  const { updateCurrentConversation, addNotifications, updateParticipants } = useContext(ChatContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpen = () => setIsModalOpen(true);

  return (
    <>
      <Button fullWidth variant="secondary" onClick={handleOpen}>
        <PlusIcon decorative={false} title="Add convo" />
        {!props.collapsed ? "Create New Conversation" : null}
      </Button>
      <ConversationTitleModal
        title=""
        type="new"
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onSave={async (title) => {
          const convo = await addConversation(
            title,
            updateParticipants,
            props.client,
            addNotifications
          );
          updateCurrentConversation(convo.sid);
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default CreateConversationButton;
