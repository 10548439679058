import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import { updateInfiniteScroll } from '@/redux/helper'

// Constants
export const constants = {
  INDEX: createSagaAction('EMPLOYEES_INDEX'),
  SHOW: createSagaAction('EMPLOYEES_SHOW'),
  DATAPROVIDER: createSagaAction('DATAPROVIDER'),
  INVITE: createSagaAction('INVITE'),
  DELETE: createSagaAction('DELETE'),
  EDIT: createSagaAction('EDIT')
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  index: (params, next) => ({
    type: constants.INDEX.ACTION,
    ...params,
    next,
  }),
  show: (params, next) => ({
    type: constants.SHOW.ACTION,
    loading: true,
    ...params,
    next,
  }),
  dataprovider: (params, next) => ({
    type: constants.DATAPROVIDER.ACTION,
    loading: true,
    ...params,
    next,
  }),
  invite: (params, next) => ({
    type: constants.INVITE.ACTION,
    loading: true,
    ...params,
    next,
  }),
  deleteEmployee: (params, next) => ({
    type: constants.DELETE.ACTION,
    loading: true,
    ...params,
    next,
  }),
  edit: (params, next) => ({
    type: constants.EDIT.ACTION,
    loading: true,
    ...params,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.INDEX.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      dataLoading: true
    }
  },
  [constants.INDEX.SUCCESS]: (state, action) => {
    return {
      ...state,
      data: updateInfiniteScroll(state.data, action),
      loading: false,
    }
  },
  [constants.SHOW.ACTION]: (state) => {
    return { ...state, error: null, loading: true, group: null }
  },
  [constants.SHOW.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
      group: action.payload,
    }
  },
  [constants.DATAPROVIDER.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      loading: true
    }
  },
  [constants.DATAPROVIDER.SUCCESS]: (state, action) => {
    return {
      ...state,
      roles: action.payload.roles,
      loading: false,
      article: null,
    }
  },
  [constants.INVITE.ACTION]: (state) => {
    return {
      ...state,
      error: null,
      loading: true
    }
  },
  [constants.INVITE.SUCCESS]: (state) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.DELETE.SUCCESS]: (state, { action }) => {
    const data = state.data?.data?.filter(employee => employee?.id !== action?.id)
    return {
      ...state,
      data: {
        ...state.data,
        data,
      },
      loading: false,
    }
  },
  [constants.DELETE.FAILED]: (state) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.EDIT.SUCCESS]: (state, { payload }) => {
    const employeeUpdated = payload?.token
    const data = state?.data?.data?.filter(employee => employee?.id !== employeeUpdated?.id)
    data.push(employeeUpdated)
    return {
      ...state,
      data: {
        ...state.data,
        data: data
      },
      loading: false,
    }
  },
  [constants.EDIT.FAILED]: (state) => {
    return {
      ...state,
      loading: false,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,

  employee: {},

  roles: [],

  data: [],
  dataNextPage: null,
  loading: false,
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})

