import React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

function Import(props) {
  return (
    <Svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.color} d="M1.36957 1.0006C1.26781 1.01124 1.17352 1.05939 1.10522 1.13563C1.03693 1.21188 0.999432 1.31076 1.00001 1.41309V9.93758C1.00001 10.047 1.04345 10.1518 1.12086 10.2292C1.19816 10.3066 1.30309 10.3501 1.41253 10.3501H7.32505C7.43544 10.3516 7.54181 10.3088 7.62045 10.2313C7.6991 10.1539 7.74341 10.048 7.74341 9.93758C7.74341 9.82719 7.6991 9.72131 7.62045 9.64382C7.5418 9.56633 7.43543 9.52355 7.32505 9.52508H1.82503V1.82555H9.52505V7.32522C9.52352 7.43561 9.5663 7.54197 9.64379 7.6206C9.72129 7.69925 9.82718 7.74355 9.93758 7.74355C10.048 7.74355 10.1539 7.69925 10.2314 7.6206C10.3089 7.54196 10.3516 7.4356 10.3501 7.32522V1.41307C10.3501 1.30364 10.3067 1.19872 10.2292 1.12142C10.1518 1.04402 10.047 1.00058 9.93758 1.00058H1.41255C1.39826 0.999808 1.38387 0.999808 1.36958 1.00058L1.36957 1.0006ZM7.41516 7.04606C7.311 7.05718 7.215 7.10763 7.1466 7.18694C7.07821 7.26636 7.04263 7.36878 7.04694 7.47352C7.05136 7.57815 7.09548 7.67732 7.17029 7.75068L10.5948 11.175H9.24991C9.13951 11.1734 9.03305 11.2162 8.9544 11.2937C8.87585 11.3713 8.83154 11.4771 8.83154 11.5875C8.83154 11.6979 8.87585 11.8037 8.9544 11.8812C9.03305 11.9587 9.13952 12.0015 9.24991 12H11.5874C11.6968 12 11.8018 11.9565 11.8791 11.8791C11.9565 11.8018 11.9999 11.6969 11.9999 11.5875V9.25013C12.0022 9.13849 11.9592 9.03069 11.8807 8.95138C11.8021 8.87197 11.6947 8.82785 11.5831 8.8291C11.473 8.83015 11.3679 8.87532 11.2911 8.95445C11.2145 9.03347 11.1727 9.14003 11.1749 9.25013V10.5907L7.75454 7.17061C7.66659 7.07902 7.54133 7.03308 7.41501 7.04603L7.41516 7.04606Z" />
    </Svg>
  )
}

Import.propTypes = SvgProps

export default Import
