import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import { FlatList, View, TouchableOpacity, Text } from 'react-native'
import { useRoute } from '@react-navigation/native'
import {
    AvatarWriteButton,
    CreatePostComment,
    Avatar,
    Icon,
} from '@/components'
import Comment from '@/components/Feed/Card/Content/Components/Comments/Comment'
import CreateCommentModal from '@/components/Feed/Card/Content/Components/Comments/Create'
import theme from '@/styles/themes/black'
import { isMobile } from '@/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from "@react-navigation/native";
import { PostInterface } from '@/interfaces/post'
import PropTypes from 'prop-types'
import { actions } from '@/redux/reducers/posts'
import { actions as teamActions } from '@/redux/reducers/teams'
import { actions as groupActions } from '@/redux/reducers/groups'
import * as profileSelectors from '@/redux/selectors/profile'
import { getMember } from '@/redux/selectors/auth'

import ModalV1 from '@/components/ModalV1'

import styles from './styles.js'

const CommentList = ({
    post,
    postType,
    idPresent,
    contentType,
    onClosePostComment,
    handleDeleteComment,
    fetchPost
}) => {
    const dispatch = useDispatch()
    const navigation = useNavigation();
    const route = useRoute();

    const [isCreateCommentVisible, setCreateCommentVisible] = useState(false)

    const user = useSelector(getMember)

    const renderItem = useCallback(({ item }) => {
        return (
            <Comment
                id={item?.id}
                author_id={item?.author?.id}
                post_id={item?.post_id}
                contentType={contentType}
                authorSource={item?.author?.avatar}
                creatorName={`${item?.author?.first_name} ${item.author?.last_name}`}
                authorOffice={item?.author?.role}
                comment={item?.comment}
                likesCount={item?.likes?.length}
                createdAt={item?.created_at}
                handleDeleteComment={handleDeleteComment}
                postType={postType}
                idPresent={idPresent}
                onCloseMobile={() => {
                    fetchPost();
                    if (postType == 'team') {
                        dispatch(teamActions.comment_list({ postType: contentType, id: post?.id }))
                    } else if (postType == 'group') {
                        dispatch(groupActions.comment_list({ postType: contentType, id: post?.id }))
                    } else {
                        // dispatch(actions.feeds_index({ page: 1 }))
                        dispatch(actions.comment_list({ postType: contentType, id: post?.id }))
                    }
                }}
                fetchPost={fetchPost}
            />
        )
    }, [post?.comments])

    const navigateToPost = useCallback(() => {
        navigation.navigate("CommentPost", {
            post_id: post.id,
            contentType: postType
        });
    });

    const commentsDataWithPostId = post?.comments?.map((comment) => ({ ...comment, post_id: post?.id }))

    return (
        <>
            <View style={styles.Container}>
                <View>
                    <AvatarWriteButton
                        onPress={() => {
                            setCreateCommentVisible(true)
                        }}
                        avatarSize={45}
                        avatarSource={user?.avatar}
                        text="Write a comment"
                    />
                </View>
                <View>
                    <FlatList
                        keyExtractor={item => item.id}
                        renderItem={renderItem}
                        data={route.name == "CommentPost" ? commentsDataWithPostId : _.take(commentsDataWithPostId, 1)}
                        contentContainerStyle={styles.FlatListContentContainer}
                        showsVerticalScrollIndicator={false}
                        scrollEnabled={false}
                        listKey={post?.id}
                    />
                </View>
                {post?.comments?.length > 1 && route.name != "CommentPost" ? (
                    <TouchableOpacity
                        style={styles.MoreButtonContainer}
                        onPress={navigateToPost}
                    >
                        <Text style={styles.MoreButtonLabel}>Show More</Text>
                    </TouchableOpacity>
                ) : null}
            </View>

            <CreateCommentModal
                contentId={post?.id}
                contentType={postType}
                isVisible={isCreateCommentVisible}
                setVisible={setCreateCommentVisible}
            />
        </>
    )
}

CommentList.defaultProps = {
    isCommentsVisible: false
}

CommentList.propTypes = {
    post: PostInterface,
    contentType: PropTypes.string,
    postType: PropTypes.string,
    idPresent: PropTypes.number,
    onClosePostComment: PropTypes.func,
    handleDeleteComment: PropTypes.func,
    isCommentsVisible: PropTypes.bool
}

export default CommentList