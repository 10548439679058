import React, { useContext, useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";

import { useTheme } from "@twilio-paste/theme";

import { getBlobFile, getMessageStatus } from "../../../api";
import MessageView from "./MessageView";
import MessageFile from "./MessageFile";
import ImagePreviewModal from "../modals/ImagePreviewModal";
import Horizon from "./Horizon";
import {
  getMessageTime,
  successNotification,
  unexpectedErrorNotification,
} from "../../../helpers";
import ChatContext from "@/contexts/ChatContext";
import { useSelector } from "react-redux";
import { getMember } from "@/redux/selectors/auth";
import Autolink from "react-native-autolink";


const MessageList = (props) => {
  const { messages, conversation, lastReadIndex } = props;
  if (messages === undefined) {
    return <div className="empty" />;
  }

  const theme = useTheme();
  const myRef = useRef(null);
  const messagesLength = messages.length;


  const { attachments, workspaceMembers, addAttachment, addNotifications } = useContext(ChatContext);





  const [imagePreview, setImagePreview] = useState(null);
  const [fileLoading, setFileLoading] = useState({});

  const [horizonAmount, setHorizonAmount] = useState(0);
  const [showHorizonIndex, setShowHorizonIndex] = useState(0);
  const [scrolledToHorizon, setScrollToHorizon] = useState(false);
  const user = useSelector(getMember);

  useEffect(() => {
    if (scrolledToHorizon || !myRef.current) {
      return;
    }
    myRef.current.scrollIntoView({
      behavior: "smooth",
    });
    setScrollToHorizon(true);
  });

  useEffect(() => {
    if (lastReadIndex === -1 || horizonAmount) {
      return;
    }
    let showIndex = 0;

    setHorizonAmount(
      messages.filter(({ index }) => {
        if (index > lastReadIndex && !showIndex) {
          showIndex = index;
        }
        return index > lastReadIndex;
      }).length
    );

    setShowHorizonIndex(showIndex);
  }, [messages, lastReadIndex]);

  function setTopPadding(index) {
    if (
      props.messages[index] !== undefined &&
      props.messages[index - 1] !== undefined &&
      props.messages[index].author === props.messages[index - 1].author
    ) {
      return theme.space.space20;
    }
    return theme.space.space50;
  }

  const onDownloadAttachment = async (message) => {
    setFileLoading(Object.assign({}, fileLoading, { [message.sid]: true }));
    const blob = await getBlobFile(message.media, addNotifications);
    addAttachment(props.conversation.sid, message.sid, blob);
    setFileLoading(Object.assign({}, fileLoading, { [message.sid]: false }));
  };

  const onFileOpen = (file, { filename }) => {
    saveAs(file, filename);
  };

  return (
    <>
      {messages.map((message, index) => {
        const isImage = message.media?.contentType?.includes("image");
        const fileBlob = attachments[conversation.sid]?.[message.sid] ?? null;

        return (
          <div
            key={
              message.dateCreated.getTime() +
              message.body +
              message.media?.filename +
              message.sid
            }
          >
            {lastReadIndex !== -1 &&
              horizonAmount &&
              showHorizonIndex === message.index ? (
              <Horizon ref={myRef} amount={horizonAmount} />
            ) : null}
            <MessageView
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              reactions={message.attributes["reactions"]}
              message={
                message.body ? (
                  <Autolink textProps={{
                    style: message.author == user.id.toString() ? {
                      color: theme.backgroundColors.colorBackgroundBody,
                      fontSize: theme.fontSizes.fontSize30,
                      lineHeight: theme.lineHeights.lineHeight30,
                      fontWeight: theme.fontWeights.fontWeightNormal,
                    } : {
                      color: theme.textColors.colorText,
                      fontSize: theme.fontSizes.fontSize30,
                      lineHeight: theme.lineHeights.lineHeight30,
                      fontWeight: theme.fontWeights.fontWeightNormal,
                    }
                  }} text={message.body} />
                ) :
                  (message.media ? (
                    <MessageFile
                      key={message.sid}
                      media={message.media}
                      type="view"
                      onDownload={() => onDownloadAttachment(message)}
                      isImage={isImage}
                      file={fileBlob}
                      sending={message.index === -1}
                      loading={fileLoading[message.sid]}
                      onOpen={
                        isImage && fileBlob
                          ? () =>
                            setImagePreview({
                              message,
                              file: fileBlob,
                            })
                          : () =>
                            onFileOpen(
                              attachments[conversation.sid]?.[message.sid],
                              message.media
                            )
                      }
                    />
                  ) : (
                    ""
                  ))
              }
              author={message.author}
              getStatus={getMessageStatus(
                props.conversation,
                message,
                props.participants,
                user.id.toString()
              )}
              onDeleteMessage={async () => {
                try {
                  await message.remove();
                  successNotification({
                    message: "Message deleted.",
                    addNotifications,
                  });
                } catch {
                  unexpectedErrorNotification(addNotifications);
                }
              }}
              topPadding={setTopPadding(index)}
              lastMessageBottomPadding={index === messagesLength - 1 ? 16 : 0}
              sameAuthorAsPrev={setTopPadding(index) !== theme.space.space20}
              messageTime={getMessageTime(message)}
              updateAttributes={(attribute) =>
                message.updateAttributes({
                  ...message.attributes,
                  ...attribute,
                })
              }
            />
          </div>
        );
      })}
      {imagePreview
        ? (function () {
          const date = new Date(imagePreview?.message.dateCreated);
          const author = workspaceMembers?.find(member => member.id == imagePreview?.message.author);
          return (
            <ImagePreviewModal
              image={imagePreview.file}
              isOpen={!!imagePreview}
              author={author}
              date={
                date.toDateString() +
                ", " +
                date.getHours() +
                ":" +
                (date.getMinutes() < 10 ? "0" : "") +
                date.getMinutes()
              }
              handleClose={() => setImagePreview(null)}
              onDownload={() =>
                saveAs(imagePreview.file, imagePreview.message.media.filename)
              }
            />
          );
        })()
        : null}
    </>
  );
};

export default MessageList;