import styled from 'styled-components/native'

export const Container = styled.View`
  flex: 1;
  flex-direction: row;
`

export const PageWrapper = styled.View`
  flex: 1;
`

export const PageContentWrapper = styled.View`
  flex: 1;
`
