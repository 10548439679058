import request from '@/helpers/request'

export async function getFiveSCategories() {
  return await request('common/provider/getFiveSCategories', {
    method: 'GET',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export async function getLifecycleStages() {
  return await request('common/provider/getLifecycleStages', {
    method: 'GET',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
