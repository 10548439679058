import { StyleSheet, Platform } from 'react-native';

export default StyleSheet.create({
    Container: {
        flex: 1,
        alignItems: "center",

        ...Platform.select({
            ios: {
                justifyContent: "flex-end",
            },
            android: {
                justifyContent: "flex-end",
            },
            web: {
                justifyContent: "center",
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }
        })
    },
    Inner: {
        backgroundColor: "white",
        paddingHorizontal: 25,
        paddingVertical: 20,
        elevation: 5,

        ...Platform.select({
            ios: {
                width: '100%',
                height: '60%',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderTopWidth: 2,
                borderLeftWidth: 2,
                borderRightWidth: 2,
                borderColor: 'rgba(27, 27, 27, 0.15)'
            },
            android: {
                width: '100%',
                height: '60%',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                borderTopWidth: 2,
                borderLeftWidth: 2,
                borderRightWidth: 2,
                borderColor: 'rgba(27, 27, 27, 0.15)'
            },
            web: {
                maxWidth: 700,
                maxHeight: '90%',
                width: '90%',
                borderRadius: 10,
                shadowColor: 'rgba(2, 2, 2, 0.31)',
                shadowOffset: { width: 2, height: 1 },
                shadowOpacity: 0.2,
                shadowRadius: 20,
            }
        })
    },
    InnerHeader: {
        marginBottom: 15,
        flexDirection: 'row'
    },
    InnerHeaderLeft: {
        width: '80%'
    },
    InnerHeaderRight: {
        width: '20%',
        justifyContent: 'flex-start',
        alignItems: 'flex-end'
    },
    InnerHeaderTitle: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
        fontWeight: '600',
        color: '#f1524f',
        textTransform: 'uppercase',
        letterSpacing: 1,
        marginBottom: 5
    },
    InnerHeaderTitleSubtitle: {
        fontFamily: 'WorkSans_400Regular',
        fontSize: 14,
        fontWeight: '500',
        color: '#1b1b1b',
    },
    InnerBody: {

    }
});
