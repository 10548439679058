import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'

// Constants
export const constants = {
  STORE: createSagaAction('ROLES_STORE'),
  INDEX: createSagaAction('ROLES_INDEX'),
  DELETE: createSagaAction('ROLES_DELETE'),
  UPDATE: createSagaAction('ROLES_UPDATE'),
  DATAPROVIDER: createSagaAction('ROLES_DATAPROVIDER'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  store: ({ name, permissions }, next) => ({
    type: constants.STORE.ACTION,
    name,
    permissions,
    next,
  }),
  index: (next) => ({
    type: constants.INDEX.ACTION,
    next,
  }),
  delete: ({ id }, next) => ({
    type: constants.DELETE.ACTION,
    id,
    next,
  }),
  update: ({ id, name, permissions }, next) => ({
    type: constants.UPDATE.ACTION,
    id,
    name,
    permissions,
    next,
  }),
  dataprovider: (next) => ({
    type: constants.DATAPROVIDER.ACTION,
    next,
  }),
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [constants.INDEX.ACTION]: (state) => ({
    ...state,
    loading: true,
  }),
  [constants.INDEX.SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    roles: action.payload,
  }),
  [constants.DELETE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.UPDATE.SUCCESS]: (state, action) => {
    return {
      ...state,
      loading: false,
    }
  },
  [constants.DATAPROVIDER.SUCCESS]: (state, action) => {
    return {
      ...state,
      permissions: action.payload.permissions,
      loading: false,
    }
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  loading: false,
  roles: [],
  permissions: [],
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, loading: false }
})
