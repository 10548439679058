import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    ItemContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    Button: {

    },
    Label: {
        fontWeight: '600',
        fontSize: 13
    }
});
