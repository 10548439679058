import styled from 'styled-components/native'
import { Dimensions } from 'react-native'
import { isMobile } from '@/styles'
import { vw } from 'react-native-expo-viewport-units'
import { ScreenSize, useScreenSize } from '@/styles'

const { width } = Dimensions.get('window')


export const FakeInput = styled.View`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
  padding-right: 8px;
  padding-bottom: 7px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.basics[500]};
`

export const CreatePostWrapper = styled.TouchableOpacity`
  padding: 15px;
	margin: 0 0 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.basics.background_white};
  align-items: center;
  flex-direction: row;
	width: 100%;
	height: 69px;
`
