import React from "react";
import { Modal, View, Dimensions, Platform } from "react-native";
import { CloseButtonCreateFolder, CreateFolderContainer, HeaderCreateFolder } from "../styles";
import { Icon } from '@/components'
import theme from '@/styles/themes/black'
import SideMenusWeb from "../Menu/SideMenu.web";
import SideMenusMobile from "../Menu/SideMenu.mobile";
import { useSelector } from "react-redux";


const { height, width } = Dimensions.get('window')


const FilePreviewModal = ({ children, isMobile, visible, onDismiss, communityGroup, communityEmployee, communityTeam, item, getMyFolders }) => {
    const auth = useSelector(state => state.auth);

    return (
        <Modal visible={visible} transparent={true} >
            <View style={{ flex: 1, backgroundColor: 'rgba(0,0,0,0.4)', alignItems: 'center', justifyContent: 'center' }}>
                <CreateFolderContainer style={isMobile ? { height: height / 1.15, } : { borderRadius: 12, marginHorizontal: width - (width - 30), marginVertical: width - (width - 30), width: isMobile ? width : width / 1.06, height: isMobile ? height / 2 : height / 1.06, alignItems: 'center' }}>
                    <HeaderCreateFolder style={{ marginVertical: 20, alignItems: 'center', flexDirection: 'row' }}>
                        <CloseButtonCreateFolder style={{ right: 0 }} onPress={onDismiss}>
                            <Icon icon="close" color={theme.basics[900]} height={14} width={14} />
                        </CloseButtonCreateFolder>
                    </HeaderCreateFolder>
                    {children}
                </CreateFolderContainer>
            </View>
        </Modal>
    )
}

export default FilePreviewModal;