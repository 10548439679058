import { createSagaAction } from '@/helpers/sagas'
import { createReducer } from '@/helpers/reducers'
import { REHYDRATE } from 'redux-persist/lib/constants'

// Constants
export const constants = {
  AUTH_LOGOUT: 'AUTH_LOGOUT',

  NOTIFI_LIST_READ: createSagaAction('NOTIF_READ'),
  NOTIFI_LIST_UNREAD: createSagaAction('NOTIF_UNREAD'),
  NOTIF_MARK_ALL_READ: createSagaAction('NOTIF_MARK_ALL_READ'),
  NOTIFI_DELETE: createSagaAction('NOTIF_DELETE'),
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  logout: () => ({
    type: constants.AUTH_LOGOUT,
  }),
  getReadNotifs: ({ }, next) => {
    return {
      type: constants.NOTIFI_LIST_READ.ACTION,
      next,
    }
  },
  getUnReadNotifs: ({ }, next) => {
    return {
      type: constants.NOTIFI_LIST_UNREAD.ACTION,
      next,
    }
  },



}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {


  // AUTH_LOGOUT
  [constants.AUTH_LOGOUT]: () => initialState,


  [constants.NOTIFI_LIST_READ.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.NOTIFI_LIST_READ.SUCCESS]: (state, action) => {
    const user = action.payload
    return {
      ...state,
      isLoading: false,

      readNotifications: user,
    }
  },
  [constants.NOTIFI_LIST_READ.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },




  [constants.NOTIFI_LIST_UNREAD.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.NOTIFI_LIST_UNREAD.SUCCESS]: (state, action) => {
    const user = action.payload
    return {
      ...state,
      isLoading: false,

      unReadNotifications: user,
    }
  },
  [constants.NOTIFI_LIST_UNREAD.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },


}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  isLoading: false,

  readNotifications: [],
  unReadNotifications: [],
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})