import React, { useCallback, useEffect, useState } from 'react'
import { View, Text, Platform, FlatList, TouchableOpacity, Dimensions, Modal, Share, Alert, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from '@/redux/reducers/sharedfolders'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Icon } from '@/components'
import { ProgressBar, List, Avatar, Card, Badge } from 'react-native-paper'
import { useIsMobile } from '@/styles'
import {
  Container,
  ScrollView,
} from '../styles'
import TabsHeader from '@/pages/SharedFolders/Main/TabsHeader'
import ApiHandler from '@/api/ApiHandler'
import SideMenusMobile from '../Menu/SideMenu.mobile'
import SideMenusWeb from '../Menu/SideMenu.web'


import { WebView } from 'react-native-webview';

import {
  MenuProvider,
} from 'react-native-popup-menu';
import { SORT_BY, SORT_TYPES } from './SortTypes'
import { formatUserName } from '@/utils/format'
import { Layouts } from './Layouts'
import { FlatGrid } from 'react-native-super-grid'
import FilePreviewModal from '../FilePreviewModal'
import FileIcon, { Types } from '../Icons'
import { Video } from 'expo-av'
import useLayoutContext from '../Context/useLayout'

import ColumnsContainer from '@/components/Layout/ColumnsContainer'
import Column from '@/components/Layout/Column'
import ContentBlockV1 from '@/components/ContentBlockV1'
import PageHeader from '@/components/Layout/Page/Header'
import PageContent from '@/components/Layout/Page/Content'
import NewFolderModal from '../NewFolderModal'
import { openDocument } from '../UplodFile/document'

const { height, width } = Dimensions.get('window')

const SharedFolders = () => {
  const [tab, setTab] = useState('myfolders')
  const isMobile = useIsMobile()
  const dispatch = useDispatch()

  const navigation = useNavigation()

  const route = useRoute()

  const [myfolders, setMyfolders] = useState([])
  const [foldersSharedwithme, setfoldersSharedwithme] = useState([])
  const sharedfolders = useSelector((state) => state.sharedfolders);
  const auth = useSelector((state) => state.auth);

  const [loading, setloading] = useState(false)

  const [communityGroup, setCommunityGroup] = useState([]);
  const [communityTeam, setCommunityTeam] = useState([]);
  const [communityEmployee, setCommunityEmployee] = useState([]);

  const [isFileView, setIsFileView] = useState(false);
  const [previewFIle, setPreviewFIle] = useState(null);

  const [data, setData] = useState(myfolders);
  const [sharedData, setSharedData] = useState(foldersSharedwithme);
  const [sortType, setSortType] = useState(SORT_TYPES.asc);

  const { layout, setLayout } = useLayoutContext();
  const [currentItem, setCurrentItem] = useState(null);

  // const [previewFIle, setPreviewFIle] = useState([]);

  // useEffect(() => {SideMenusWeb
  //   navigation.addListener('focus', () => {

  //   })
  // }, [])

  const sortData = useCallback((sort, sortBy) => {
    let _data = [];
    let _sharedData = [];

    if (sort == SORT_TYPES.asc) {
      _data = data.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
      _sharedData = sharedData.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
    } else if (sort == SORT_TYPES.desc) {
      _data = data.sort((a, b) => a[sortBy].localeCompare(b[sortBy])).reverse();
      _sharedData = sharedData.sort((a, b) => a[sortBy].localeCompare(b[sortBy])).reverse();
    }

    setData(_data);
    setSharedData(_sharedData);
    setSortType(sort);

  }, [setData, data, sharedData, setSharedData])

  useEffect(() => {
    navigation.addListener('focus', () => {
      init();
      getMyFolders();
      getFoldersSharedWithMe();
      getWorkspaceGroupAsync();
      getWorkspaceTeamAsync();
      getWorkspaceEmployeeAsync();
    })
  }, [])

  useEffect(() => {
    setMyfolders(sharedfolders.myfolders)
    setData(sharedfolders.myfolders);
  }, [sharedfolders.myfolders])

  useEffect(() => {
    setfoldersSharedwithme(sharedfolders.sharedfolders);
    setSharedData(sharedfolders.sharedfolders);
  }, [sharedfolders.sharedfolders])


  const init = () => {
    setMyfolders([]);
    setfoldersSharedwithme([]);
    dispatch(actions.resetstoragefiles());
  }

  const setCurrentTab = (tab) => {
    setTab(tab);
    if (tab == 'myfolders') {
      init();
      getMyFolders(tab);
    }
    else {
      init();
      getFoldersSharedWithMe(tab);
    }
  }

  const getMyFolders = async (tab = null) => {
    try {
      setloading(true);
      const response = await new ApiHandler().getMyFolders()
      if (response?.data) {
        setloading(false);
        if (tab) {
          setTab(tab);
        }
        let myfiles = response?.data.length ? response?.data.slice().reverse() : [];
        dispatch(actions.myfolders(myfiles));
      }
    } catch (error) {
      setloading(false);
    }
  }

  const getFoldersSharedWithMe = async (tab = null) => {
    try {
      setloading(true);
      const response = await new ApiHandler().getFoldersSharedWithMe()
      if (response?.data) {
        setloading(false);
        if (tab) {
          setTab(tab);
        }
        let sharedfiles = response?.data.length ? response?.data.slice().reverse() : [];
        dispatch(actions.sharedfolders(sharedfiles));
      }
    } catch (error) {
      setloading(false);
    }
  }

  useEffect(() => {
    if (previewFIle) setIsFileView(true);
    else setIsFileView(false);

  }, [previewFIle])


  const handlePreviewFile = (item) => {
    setCurrentItem(item);
    // let uri;
    // if (item.type == 'file-image') uri = item.link
    // else if (item.type == 'file-video') uri = item.link
    // else uri = 'http://docs.google.com/gview?embedded=true&url=' + item.link;

    setPreviewFIle(item);
    // setIsFileView(true);
  }

  const handleFolderView = useCallback((item, shared) => {
    setCurrentItem(item);
    if (item.type == 'folder') {
      if (isMobile) {
        navigation.push('Main', {
          screen: 'SubFolders', params: {
            previus_route_name: route?.name || '',
            id: item.id,
            shared: shared,
            layout
          }, key: 'subfolder' + item.id
        });
      }
      else {
        navigation.push('SubFolders', {
          previus_route_name: route?.name || '',
          id: item.id,
          shared: shared,
        })
      }
    } else {
      handlePreviewFile(item);
    }
  }, [])

  const formatDate = (date) => {
    var d = new Date(date.replace(' ', 'T')),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const getWorkspaceGroupAsync = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getWorkspaceGroup();
      setloading(false);
      if (response?.data) {
        let responseData = response?.data.length ? response?.data.filter(x => x.id != auth.member.id) : [];
        setCommunityGroup(responseData);
      }

    } catch (error) {
      setloading(false);
    }
  }

  const getWorkspaceTeamAsync = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getWorkspaceTeam();
      setloading(false);
      if (response?.data) {
        let responseData = response?.data.length ? response?.data.filter(x => x.id != auth.member.id) : [];
        setCommunityTeam(responseData);
      }

    } catch (error) {
      setloading(false);
    }
  }

  const getWorkspaceEmployeeAsync = async () => {
    try {
      setloading(true);
      const response = await new ApiHandler().getWorkspaceEmployee();
      setloading(false);
      if (response?.data) {
        let responseData = response?.data.length ? response?.data.filter(x => x.id != auth.member.id) : [];
        setCommunityEmployee(responseData);
      }

    } catch (error) {
      setloading(false);
    }
  }

  const renderItem = ({ item }) => {
    return (
      <Card.Title
        title={<TouchableOpacity onPress={() => handleFolderView(item, 0)}>
          <Text style={{ fontSize: 15 }} >
            {item.name.length < 25
              ? `${item.name}`
              : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
          </Text>
        </TouchableOpacity>}
        subtitle={<View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {item.is_shared && <Icon icon="communityIcon" color="black" width={14} />}
          <Text style={{ color: '#666' }}> Last Modified: {formatDate(item.last_modified_at)} </Text>
        </View>}

        left={(props) => <FileIcon shared={item.is_shared} type={item.type} />}
        right={(props) => <SideMenusMobile auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getMyFolders} />}
        rightStyle={{ marginTop: isMobile ? 0 : -40 }}
        style={{ backgroundColor: '#fff', paddingVertical: 10, marginTop: 5, marginRight: 20, paddingRight: 10, zIndex: 11 }}
      />
    );
  }

  const renderGridItem = ({ item }) => (
    <View>
      <View style={{ position: 'relative', justifyContent: 'center', padding: 10, borderRadius: 5, borderColor: '#cfcfcf', borderWidth: 1, minHeight: height / 10, maxWidth: width / 2.4 }}>
        <TouchableOpacity onPress={() => { handleFolderView(item, 0) }} style={{ flex: 1, marginTop: 10, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <View style={{ flex: 1, justifyContent: "center", alignItems: 'center' }}>
            <FileIcon type={item.type} shared={item.is_shared} />
          </View>
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 5, paddingRight: 20, alignItems: 'center' }}>
        <Text numberOfLines={1} style={{ fontSize: item.name.length > 15 ? 12 : 13, width: '85%' }}>
          {item.name.length < 25
            ? `${item.name}`
            : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
        </Text>
        <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
          <SideMenusMobile auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getMyFolders} />
        </View>
      </View>
    </View>
  )

  const renderGridItemWeb = ({ item }) => (
    <View style={{ flexDirection: 'row', position: 'relative', justifyContent: 'center', padding: 10, borderRadius: 5, borderColor: '#cfcfcf', borderWidth: 1 }}>
      <TouchableOpacity onPress={() => { handleFolderView(item, 0) }} style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ flex: 0.2, marginTop: -5, justifyContent: "center", }}>
          <FileIcon shared={item.is_shared} type={item.type} />
        </View>
        <View style={{ flex: 0.8, paddingRight: 10 }}>
          <Text numberOfLines={1}>
            {item.name.length < 25
              ? `${item.name}`
              : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
          </Text>
        </View>
      </TouchableOpacity>
      <View style={{ position: 'absolute', top: 0, right: 0, flexDirection: 'row', justifyContent: 'flex-end' }}>
        <SideMenusWeb padded auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getMyFolders} />
      </View>
    </View>
  )

  const renderItemWeb = ({ item }) => (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: '#fff',
        width: '100%',
        zIndex: -1,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd'
      }}
    >
      <View style={{ flex: 0.4 }} >
        <TouchableOpacity onPress={() => { handleFolderView(item, 0) }}>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <View style={{ flex: 0.2, height: 40, justifyContent: 'center' }}>
              <FileIcon shared={item.is_shared} type={item.type} />
            </View>
            <View style={{ flex: 0.8 }}>
              <Text style={{ paddingTop: 12 }} >
                {item.name.length < 25
                  ? `${item.name}`
                  : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
      <View style={{ flex: 0.2 }} >
        <Text style={{ paddingTop: 12 }}>{formatDate(item.last_modified_at)}</Text>
      </View>
      <View style={{ flex: 0.1 }} >
        <Text style={{ paddingTop: 12, textAlign: 'center', width: '70%' }}>{item.file_size === "0 B" ? "-" : item.file_size}</Text>
      </View>
      <View style={{ flex: 0.1 }} >
        <Text style={{ paddingTop: 12 }}>{item.is_shared ? <Badge>Shared</Badge> : ''}</Text>
      </View>
      <View style={{ flex: 0.2, alignItems: 'flex-end' }} >
        <View style={{ paddingRight: 20 }}>
          <SideMenusWeb auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getMyFolders} />
        </View>
      </View>
    </View>
  );

  const renderItemSharedWithMe = ({ item }) => (
    <Card.Title
      title={<TouchableOpacity onPress={() => handleFolderView(item, 1)}>
        <Text style={{ fontSize: 15 }}  >
          {item.name.length < 25
            ? `${item.name}`
            : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
        </Text>
      </TouchableOpacity>}
      subtitle={(
        <View>
          <Text style={{ color: '#666' }}>Last Modified: {formatDate(item.last_modified_at)}</Text>
        </View>
      )}
      left={(props) => <FileIcon type={item.type} shared={item.is_shared} />}
      right={(props) => <SideMenusMobile auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getFoldersSharedWithMe} />}
      rightStyle={{ marginTop: isMobile ? 0 : -40 }}
      style={{ backgroundColor: '#fff', marginTop: 5, paddingVertical: 10, marginRight: 20, paddingRight: 10, paddingTop: Platform.OS === 'ios' ? 10 : 0, zIndex: 11 }}
    />
  );


  const renderItemSharedWithMeWeb = ({ item }) => (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: '#fff',
        width: '95%',
        alignSelf: 'center',
        zIndex: -1,
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd'
      }}
    >
      <View style={{ flex: 0.395 }} >
        <TouchableOpacity onPress={() => handleFolderView(item, 1)}>
          <View
            style={{
              flexDirection: "row",
            }}
          >
            <View style={{ flex: 0.2, height: 40, alignItems: 'center', justifyContent: 'center' }}>
              <FileIcon type={item.type} shared={item.is_shared} />
            </View>
            <View style={{ flex: 0.8 }}>
              <Text style={{ paddingTop: 12 }} >
                {item.name.length < 25
                  ? `${item.name}`
                  : `${item.name.substring(0, 25)}...`}{item.extension ? '.' + item.extension : ''}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>

      <View style={{ flex: 0.31 }} >
        <Text style={{ paddingTop: 12 }}>{formatUserName(item.owner?.first_name, item.owner?.last_name)}</Text>
      </View>
      <View style={{ flex: 0.3 }} >
        <Text style={{ paddingTop: 12 }}>{formatDate(item.last_modified_at)}</Text>
      </View>
      <View style={{ flex: 0.1 }} >
        <Text style={{ paddingTop: 12, textAlign: 'center', width: '70%' }}>{item.file_size === "0 B" ? "-" : item.file_size}</Text>
      </View>
      <View style={{ flex: 0.2, alignItems: 'flex-end' }} >
        <View style={{ paddingRight: 20 }}>
          <SideMenusWeb auth={auth} communityGroup={communityGroup} communityTeam={communityTeam} communityEmployee={communityEmployee} item={item} getMyFolders={getFoldersSharedWithMe} />
        </View>
      </View>
    </View>
  );

  const [isCreateModalVisible, setCreateModalVisible] = useState(false)
  const [errorTextCreate, setCreateError] = useState('')


  const createFolder = async (foldername) => {
    try {
      setCreateError('');
      if (foldername) {
        let data = {
          name: foldername
        }
        const response = await new ApiHandler().createFolder(data)
        console.log(response)
        setCreateModalVisible(false)
        setTab(tab)
      }
      else {
        setCreateError('Please input folder name');
        //   Toast.show({
        //   type: 'error',
        //   position: 'top',
        //   text1: 'Folder name required',
        //  })
      }

      //setMyfolders(response.data)
      // dispatch(actions.show({ id: route?.params?.id }, () => { }))

      // Toast.show({
      //   type: 'success',
      //   position: 'bottom',
      //   text1: response?.data?.message,
      // })
    } catch (error) {
      // Toast.show({
      //   type: 'error',
      //   position: 'bottom',
      //   text1: error,
      // })
    }
  }

  const handleCreateFolder = useCallback((type) => {
    setCreateError('');
    setCreateModalVisible(true)
    // navigation.navigate('CreateArticle', {
    //   previus_route_name: route?.name || '',
    //   id: null,
    //   type,
    // })
  }, [])

  const handleUploadFile = useCallback(async (type) => {
    if (Platform.OS === 'ios') {
      setTimeout(async () => {
        const document = await openDocument();
        uploadDocument(document);
      }, 1000)
    } else {
      const document = await openDocument();
      uploadDocument(document);
    }
    // navigation.navigate('CreateArticle', {
    //   previus_route_name: route?.name || '',
    //   id: null,
    //   type,
    // })
  }, [])

  const uploadDocument = async (doc) => {
    console.log(doc)
    try {
      //let baseDoc = doc.replace("data:image/png;base64,", "");
      // let baseDoc = doc.uri.split(',')[1];
      let baseDoc;
      if (Platform.OS === 'android') {
        baseDoc = doc.uri;
      } else if (Platform.OS === 'ios') {
        baseDoc = doc.uri;
      }
      else {
        baseDoc = doc.uri.split(',')[1];
      }

      // let data = JSON.stringify({
      //   file:baseDoc
      // });
      let data = {
        file: baseDoc,
        file_name: doc.fileName
      };
      const response = await new ApiHandler().uploadFile(null, data)
      //console.log(baseDoc)
      setTab(tab)
    } catch (error) {
      setTab(tab)
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: error,
      })
    }
  }


  return (
    <>
      <MenuProvider customStyles={{
        backdrop: {
          backgroundColor: '#7a7d7f',
          opacity: 0.5,
        }
      }}>
        <PageHeader
          title={'Storage & File Sharing'}
          icon={'sharedfolderright'}
          backEnabled={false}
          actions={
            [
              {
                title: 'Manage',
                type: 'menu',
                permissions: [

                ],
                menu: [
                  {
                    title: 'Create A Folder',
                    icon: false,
                    permissions: [

                    ],
                    onPress: () => {
                      handleCreateFolder()
                    }
                  },
                  {
                    title: 'Upload A File',
                    icon: false,
                    permissions: [

                    ],
                    onPress: () => {
                      handleUploadFile()
                    }
                  }
                ]
              }
            ]
          }
        />

        <PageContent>
          <ColumnsContainer>
            <Column
              flexBasis={'100%'}
            >
              <TabsHeader layout={layout} setTab={setCurrentTab} tab={tab} onLayout={(layout) => setLayout(layout)} />
            </Column>

            {tab === 'myfolders' &&
              <Column
                flexBasis={'100%'}
              >
                <ContentBlockV1
                  title={'My Files'}
                  action={{
                    onPress: () => {
                      layout === Layouts.grid ? setLayout(Layouts.list) : setLayout(Layouts.grid)
                    },
                    icon: layout === Layouts.grid ? 'gridView' : 'listView'
                  }}
                >
                  {!isMobile
                    ? <>
                      {layout == Layouts.list ? (
                        <>
                          <View
                            style={{
                              flexDirection: "row",
                              width: '100%',
                            }}
                          >

                            <View style={{ flex: 0.2 }} >
                              <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.name) : sortData(SORT_TYPES.asc, SORT_BY.name)} style={{ padding: 0, flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginRight: '2%' }}>Name</Text>
                                <Icon icon={sortType == SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                              </TouchableOpacity>
                            </View>

                            <View style={{ flex: 0.12 }}>
                              <Text></Text>
                            </View>

                            <View style={{ flex: 0.2 }} >
                              <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.date_modified) : sortData(SORT_TYPES.asc, SORT_BY.date_modified)} style={{ padding: 0, flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginRight: 5 }}>Last Modified</Text>
                                <Icon icon={sortType == SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                              </TouchableOpacity>
                            </View>
                            <View style={{ flex: 0.1 }} >
                              <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.total_size) : sortData(SORT_TYPES.asc, SORT_BY.total_size)} style={{ padding: 0, flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginRight: 5 }}>Total Size</Text>
                                <Icon icon={sortType == SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                              </TouchableOpacity>
                            </View>
                            <View style={{ flex: 0.1 }} >
                              <Text style={{ fontSize: 13, fontWeight: '600', color: '#000' }}></Text>
                            </View>
                            <View style={{ flex: 0.2 }} >
                              <Text></Text>
                            </View>
                          </View>
                          <FlatList
                            data={data}
                            renderItem={renderItemWeb}
                            ListHeaderComponent={() => (myfolders && !myfolders.length ?
                              <Text style={{ textAlign: 'center', padding: 50, backgroundColor: '#fff', marginRight: 20 }}>No files/folders found.</Text>
                              : null)}
                            keyExtractor={item => item.id}
                            contentContainerStyle={{
                              justifyContent: "center"
                            }}
                          />
                        </>
                      ) : (
                        <View style={{ flex: 1 }}>
                          <FlatGrid
                            data={data.filter(item => item.type == "folder")}
                            spacing={10}
                            ListHeaderComponent={() => (
                              <View style={{ padding: 5, marginVertical: 10 }}>
                                <Text style={{ fontSize: 24, fontWeight: '600' }}>Folders</Text>
                              </View>
                            )}
                            itemDimension={width / 7}
                            renderItem={renderGridItemWeb}
                          />
                          <FlatGrid
                            style={{ marginTop: 10 }}
                            data={data.filter(item => item.type !== "folder")}
                            spacing={10}
                            itemDimension={width / 7}
                            ListHeaderComponent={() => (
                              <View style={{ padding: 5, marginVertical: 10 }}>
                                <Text style={{ fontSize: 24, fontWeight: '600' }}>Files</Text>
                              </View>
                            )}
                            renderItem={renderGridItemWeb}
                          />
                        </View>
                      )}
                    </>
                    : (
                      <>
                        {layout === Layouts.list ? (
                          <FlatList
                            data={data}
                            style={{}}
                            renderItem={renderItem}
                            ListHeaderComponent={() => (myfolders && !myfolders.length ?
                              <Text style={{ textAlign: 'center', backgroundColor: '#fff', marginRight: 20 }}>No files/folders found.</Text>
                              : (
                                <View style={{ paddingHorizontal: 15 }}>
                                  <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.name) : sortData(SORT_TYPES.asc, SORT_BY.name)} style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ marginRight: 5 }}>Name</Text>
                                    <Icon icon={sortType === SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                                  </TouchableOpacity>
                                </View>
                              ))}
                            keyExtractor={item => item.id}
                            contentContainerStyle={{
                              flex: 1,

                              justifyContent: 'center'
                            }}
                          />
                        ) : (
                          <View>
                            <FlatGrid ListHeaderComponent={(
                              <View style={{ padding: 15 }}>
                                <Text style={{ fontSize: 18, fontWeight: '600' }}>Folders</Text>
                              </View>
                            )} data={data.filter(item => item.type === 'folder')} renderItem={renderGridItem} />
                            <FlatGrid ListHeaderComponent={(
                              <View style={{ padding: 15 }}>
                                <Text style={{ fontSize: 18, fontWeight: '600' }}>Files</Text>
                              </View>
                            )} data={data.filter(item => item.type !== "folder")} renderItem={renderGridItem} />
                          </View>
                        )}
                      </>
                    )
                  }
                </ContentBlockV1>
              </Column>
            }

            {tab === 'sharedwithme' &&
              <Column
                flexBasis={'100%'}
              >
                <ContentBlockV1
                  title={'Shared With Me'}
                >
                  {!isMobile
                    ? <>
                      {layout == Layouts.list ? (
                        <>
                          <View
                            style={{
                              flexDirection: "row",
                              width: '95%',
                              alignSelf: 'center',
                            }}
                          >

                            <View style={{ flex: 0.2 }} >
                              <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.name) : sortData(SORT_TYPES.asc, SORT_BY.name)} style={{ padding: 0, flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginRight: '2%' }}>Name</Text>
                                <Icon icon={sortType == SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                              </TouchableOpacity>
                            </View>

                            <View style={{ flex: 0.05 }}>
                              <Text></Text>
                            </View>

                            <View style={{ flex: 0.24 }} >
                              <Text style={{ fontSize: 13, fontWeight: '600', color: '#000' }}>Owner</Text>
                            </View>
                            <View style={{ flex: 0.23 }} >
                              <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.date_modified) : sortData(SORT_TYPES.asc, SORT_BY.date_modified)} style={{ padding: 0, flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginRight: 5 }}>Last Modified</Text>
                                <Icon icon={sortType == SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                              </TouchableOpacity>
                            </View>
                            <View style={{ flex: 0.1 }} >
                              <TouchableOpacity onPress={() => sortType == SORT_TYPES.asc ? sortData(SORT_TYPES.desc, SORT_BY.total_size) : sortData(SORT_TYPES.asc, SORT_BY.total_size)} style={{ padding: 0, flexDirection: 'row', alignItems: 'center' }}>
                                <Text style={{ fontSize: 13, fontWeight: '600', color: '#000', marginRight: 5 }}>Total Size</Text>
                                <Icon icon={sortType == SORT_TYPES.desc ? "arrowDown" : "arrowUp"} width={10} />
                              </TouchableOpacity>
                            </View>
                            <View style={{ flex: 0.15 }} >
                              <Text></Text>
                            </View>
                          </View>
                          <FlatList
                            data={sharedData}
                            renderItem={renderItemSharedWithMeWeb}
                            ListHeaderComponent={() => (foldersSharedwithme && !foldersSharedwithme.length ?
                              <Text style={{ textAlign: 'center', padding: 50, backgroundColor: '#fff', marginRight: 20 }}>No files/folders found.</Text>
                              : null)}
                            keyExtractor={item => item.id}
                            contentContainerStyle={{
                              justifyContent: 'center'
                            }}
                          />
                        </>
                      ) : (
                        <View style={{ flex: 1 }}>
                          <FlatGrid
                            data={sharedData.filter(item => item.type == "folder")}
                            spacing={10}
                            ListHeaderComponent={() => (
                              <View style={{ padding: 5, marginVertical: 10 }}>
                                <Text style={{ fontSize: 24, fontWeight: '600' }}>Folders</Text>
                              </View>
                            )}
                            itemDimension={width / 7}
                            renderItem={renderGridItemWeb}
                          />
                          <FlatGrid
                            style={{ marginTop: 10 }}
                            data={sharedData.filter(item => item.type !== "folder")}
                            spacing={10}
                            itemDimension={width / 7}
                            ListHeaderComponent={() => (
                              <View style={{ padding: 5, marginVertical: 10 }}>
                                <Text style={{ fontSize: 24, fontWeight: '600' }}>Files</Text>
                              </View>
                            )}
                            renderItem={renderGridItemWeb}
                          />
                        </View>
                      )}
                    </>
                    : (
                      <>
                        {layout === Layouts.list ? (
                          <FlatList
                            data={sharedData}
                            renderItem={renderItemSharedWithMe}
                            ListHeaderComponent={() => (foldersSharedwithme && !foldersSharedwithme.length ?
                              <Text style={{ textAlign: 'center', backgroundColor: '#fff', marginRight: 20 }}>No files/folders found.</Text>
                              : null)}
                            keyExtractor={item => item.id}
                            contentContainerStyle={{
                              flex: 1,
                              justifyContent: 'center'
                            }}
                          />
                        ) : (
                          <View>
                            <FlatGrid ListHeaderComponent={(
                              <View style={{ padding: 15 }}>
                                <Text style={{ fontSize: 18, fontWeight: '600' }}>Folders</Text>
                              </View>
                            )} data={sharedData.filter(item => item.type === 'folder')} renderItem={renderGridItem} />
                            <FlatGrid ListHeaderComponent={(
                              <View style={{ padding: 15 }}>
                                <Text style={{ fontSize: 18, fontWeight: '600' }}>Files</Text>
                              </View>
                            )} data={sharedData.filter(item => item.type !== "folder")} renderItem={renderGridItem} />
                          </View>
                        )}
                      </>
                    )
                  }
                </ContentBlockV1>
              </Column>
            }
          </ColumnsContainer>
        </PageContent>

        <FilePreviewModal
          visible={isFileView}
          isMobile={isMobile}
          communityEmployee={communityEmployee}
          communityGroup={communityGroup}
          communityTeam={communityTeam}
          item={currentItem}
          getMyFolders={getMyFolders}
          onDismiss={() => { setIsFileView(false); setPreviewFIle(null) }}
        >
          {
            isMobile
              ? <WebView
                bounces={false}
                source={{ uri: previewFIle?.link, cache: false }}
                style={{ flex: 1 }}
                originWhitelist={["*"]}
                useWebKit
              />
              :
              (
                <View style={{ width: '100%', height: '100%' }}>
                  {previewFIle?.type === "file-image" ? (
                    <Image source={{ uri: previewFIle?.link }} resizeMode="contain" style={{ height: '100%', aspectRatio: 16 / 9 }} />
                  ) : previewFIle?.type == "file-video" ? (
                    <Video useNativeControls source={{ uri: previewFIle?.link }} resizeMode="contain" style={{ height: '90%', aspectRatio: 16 / 9 }} />
                  ) : previewFIle?.type === "file-pdf" || previewFIle?.type == "file-word" || previewFIle?.type === "file-text" ? (
                    <iframe
                      style={{ width: '100%', height: '100%', marginBottom: 50 }}
                      width={'100%'}
                      height={'100%'}
                      src={previewFIle?.link}
                      frameborder='0'
                      allow='autoplay; encrypted-media'
                      allowfullscreen
                    />
                  ) : null}

                </View>
              )
          }
        </FilePreviewModal>

        <NewFolderModal
          visible={isCreateModalVisible}
          createFolder={(folderName) => createFolder(folderName)}
          errorTextCreate={errorTextCreate}
          onDismiss={() => setCreateModalVisible(false)}
        />
      </MenuProvider>
    </>
  )
}

export default SharedFolders

