import styled from 'styled-components/native'
import { Paragraph1, Paragraph2 } from '@/styles/fonts'

export const Container = styled.View`
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`

export const MemberContainer = styled.View`
	flex-direction: row;
	align-items: center;
	margin: 10px 0;
`

export const MemberInfo = styled.View`
	justify-content: flex-start;
	margin: 0 0 0 16px;
`

export const Name = styled(Paragraph1)`
	color: ${({ theme }) => theme.basics.brand};
`

export const Office = styled(Paragraph2)`
	color: ${({ theme }) => theme.basics[400]};
`

export const Button = styled.TouchableOpacity`
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.basics.brand};
	width: 68px;
	height: 22px;
	border-radius: 1.5px;
`

export const ButtonText = styled.Text`
	color: ${({ theme }) => theme.basics.background_white};
	font-family: 'WorkSans_600SemiBold';
  font-size: 12px;
  line-height: 16px;
	width: 100%;
	text-align: center;
`
