import React from 'react'
import Svg, { Path } from 'react-native-svg'

export default function movedTo(props) {
  return (
    <Svg width="18" height="12" viewBox="0 0 18 12" fill="none" {...props}>
      <Path
        fill={props.color || '#323232'}
        d="M14 1.5H8L6.5 0H2C1.175 0 0.5 0.675 0.5 1.5V10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V3C15.5 2.175 14.825 1.5 14 1.5ZM9.5 10.5V8.25H6.5V5.25H9.5V3L13.25 6.75L9.5 10.5Z" fill="#1B1B1B"/>
    </Svg>
  )
}

movedTo.propTypes = Svg.propTypes
