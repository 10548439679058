import React from 'react'
import { useNavigation } from '@react-navigation/native'
import {
  Keyboard,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native'
import PropTypes from 'prop-types'
import { AntDesign, Ionicons } from '@expo/vector-icons'
import { ScreenSize, useScreenSize } from '@/styles'
import ReframeRoundLogo from '@/assets/brand/ReframeRoundLogo.png'
import { Container, BackButton, BackButtonText, ImageLogo } from './styles'


const OnboardingHeader = ({ withBackButton = false, invertedContent, justifyContent }) => {
  const screenSize = useScreenSize()
  const navigation = useNavigation()

  const backButton = withBackButton ? (
    screenSize < ScreenSize.Medium ? (
      <TouchableOpacity onPress={navigation.goBack}>
        <AntDesign name="arrowleft" size={24} color="black" />
      </TouchableOpacity>
    ) : (
      <BackButton color="black" onPress={navigation.goBack}>
        <Ionicons name="chevron-back" size={20} color="white" />
        <BackButtonText>Back</BackButtonText>
      </BackButton>
    )
  ) : undefined

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
      <Container
        justifyContent={justifyContent}
        screenSize={screenSize}
        withBackButton={withBackButton}
        invertedContent={invertedContent}
      >
        {backButton}
        <ImageLogo source={ReframeRoundLogo} />
      </Container>
    </TouchableWithoutFeedback>
  )
}

OnboardingHeader.propTypes = {
  withBackButton: PropTypes.bool,
  invertedContent: PropTypes.bool,
  justifyContent: PropTypes.string,
}

export default OnboardingHeader
