import React, { useRef, useEffect } from "react";
import { Dimensions } from "react-native";
import RBSheet from "react-native-raw-bottom-sheet";

const BottomSheet = ({ children, isVisible, height = Dimensions.get('screen').height / 2} ) => {
    const refRBSheet = useRef();

    useEffect(() => {
        if (isVisible) {
            refRBSheet.current.open();
        } else {
            refRBSheet.current.close();
        }
    }, [isVisible]);

    return (
        <RBSheet
            ref={refRBSheet}
            closeOnDragDown={false}
            closeOnPressMask={false}
            height={height}
            customStyles={{
                container: {
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20
                }
            }}
        >
            {children}
        </RBSheet>
    );
};

export default BottomSheet;