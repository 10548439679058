import styled from 'styled-components/native'

import themes from '@/styles/themes/black'
import { shadowLearningCard } from '@/styles/shadows'
import {
  Headline6Strong,
  Paragraph2,
  Subtitle2,
  Paragraph1,
} from '@/styles/fonts'
import { TouchableOpacity, View, Image, ScrollView } from 'react-native'
import { ScreenSize } from '@/styles'

export const Container = styled(View)`
  padding-horizontal: 12px;
  margin-top: ${({ isDesktop }) => (isDesktop ? 20 : 12)}px;
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 20 : 30)}px;
  height: 645px;
  ${({ width }) => width >= 1450 ? `width: ${parseInt((width - 190) / 4)}px` :
    width >= ScreenSize.Large
      ? `width: ${parseInt((width - 180) / 3)}px`
      : width >= ScreenSize.Medium
        ? `width: ${parseInt((width - 90) / 2)}px`
        : `width: ${width - 60}px`};
`
export const Content = styled(TouchableOpacity)`
  align-self: center;
  align-items: center;
  margin-left: ${({ isDesktop }) => (isDesktop ? 16 : 0)}px;
  margin-right: ${({ isDesktop }) => (isDesktop ? 16 : 3)}px;
  border-radius: 5px;
  background-color: ${themes.basics.background_white};
  ${shadowLearningCard};
  width: 100%;
  height: 100%;
`

export const AvatarContainer = styled.View`
  align-items: center;
  justify-content: center;
  margin: 16px;
  position: relative;
`

export const CompanyImage = styled(Image)`
  width: 22px;
  height: 22px;
  border-radius: 22px;
  position: absolute;
  right: ${({ isDesktop }) => (isDesktop ? -5 : -5)}px;
  bottom: ${({ isDesktop }) => (isDesktop ? 5 : -5)}px;
`

export const CoverContainer = styled(View)`
  width: 100%;
  position: relative;
  aspect-ratio: 2;
  border-bottom-width: 1px;
  border-bottom-color: #d9d9d9;
`

export const OverScrollView = styled(ScrollView)`
  margin: 8px 16px 12px;
  text-align: left;
  color: ${({ theme }) => theme.basics[500]};
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;
  padding: 0px 20px;
`

export const Overview = styled(Paragraph1)``

export const AuthorInfoContainer = styled.View`
  padding: 16px 0 0;
  align-items: flex-start;
  justify-content: flex-start;
`
export const SubFooterInfo = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const SubInfoText = styled(Paragraph2)`
  color: ${({ theme }) => theme.basics[400]};
`

export const CreatorName = styled(Paragraph1)``

export const SaveButton = styled(TouchableOpacity)`
  z-index: 1;
  position: absolute;
  right: 16px;
  top: 16px;
  width: 30px;
  height: 30px;
  background: #1b1b1ba1;
  border-top-left-radius: 50;
  border-top-right-radius: 50;
  border-bottom-left-radius: 50;
  border-bottom-right-radius: 50;
  align-items: center;
  justify-content: center;
`

export const Pipe = styled(View)`
  width: 1px;
  height: 10px;
  margin: 0 5px;
  background: ${({ theme }) => theme.basics[400]};
`

export const Footer = styled.View`
  width: 100%;
  flex-direction: row;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.basics[300]};
  height: ${({ isDesktop }) => (isDesktop ? 88 : 72)}px;
`

export const IMG = styled(Image)`
  width: 100%;
  height: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const Header = styled.View`
  justify-content: center;
  align-items: flex-start;
  align-self: flex-start;
  margin-left: ${({ isDesktop }) => (isDesktop ? 20 : 16)}px;
  margin-right: ${({ isDesktop }) => (isDesktop ? 20 : 16)}px;
  margin-top: ${({ isDesktop }) => (isDesktop ? 27 : 12)}px;
  margin-bottom: ${({ isDesktop }) => (isDesktop ? 12 : 8)}px;
`

export const SubHeader = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px 0 0;
`

export const Label = styled(Subtitle2)`
  align-items: center;
  color: ${themes.primary.standard};
`

export const Title = styled(Headline6Strong)`
  align-items: center;
  color: ${themes.basics[800]};
  margin-top: 8px;
`

export const Status = styled.View`
  flex-direction: row;
  margin: 10px 0 0 0;
`

export const PublishStatus = styled.Text`
  background: #1b1b1bcf;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 11px;
  color: #fff;
  margin: 0 5px 0 0;
  font-weight: bold;
  text-align: center;
`

export const FeatureStatus = styled.Text`
  background: #1b1b1bcf;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 11px;
  color: #fff;
  margin: 0 0 0 0;
  font-weight: bold;
  text-align: center;
`

export const Dot = styled.View`
  background: ${themes.basics[500]};
  height: 5px;
  width: 5px;
  border-radius: 15px;
  margin: 0 5px;
`

export const SubTitle = styled(Paragraph2)`
  align-items: center;
  color: ${themes.basics[500]};
`
export const Play = styled(View)`
  width: 38px;
  height: 38px;
  background-color: #2d91f9;
  border-radius: 999px;
  position: absolute;
  bottom: -14px;
  right: 16px;
  align-items: center;
  justify-content: center;
`
